import { DialogContentText, makeStyles, Radio, Table, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import GetAppIcon from '@material-ui/icons/GetApp';
import ListIcon from '@material-ui/icons/List';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Autocomplete } from '@material-ui/lab';
import clientApi from 'api/clientApi';
import { ClientFileMap, ContactsDTO, Form4DTO, Form6DTO } from 'common/dto';
import { FormPresenter } from 'common/hooks';
import { isNonEmpty, multiLang } from 'common/utils';
import moment from 'moment';
import React, { useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';

const useStyles = makeStyles(() => ({
  root: {
    overflow: 'auto'
  },
  patchOutlineAutocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
      paddingLeft: 8,
    }
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    boxSizing: 'content-box',
    overflowWrap: 'break-word',
  },
  textArea: {
    width: 'auto'
  },
  table: {
    minWidth: 'auto'
  },
}));

export interface ClientSelectionDialogProps {
  open: boolean;
  onClose?: (contact?: ContactsDTO | undefined) => any;
  actionName?: string;
  description?: string;
  contactId?: number;
  clientFileForm?: FormPresenter<ClientFileMap> | undefined;
  onSelect?: (contact?: ContactsDTO | undefined) => any;
  onConfirm?: (formId: string | undefined) => any;
  type: string;
}


export default function ClientSelectionDialog(props: ClientSelectionDialogProps) {
  const classes = useStyles();
  const { langClientDetail, lang, locale, contactOptions, langPropertyStock, clientFormSelections } = useSelector((state: IRootState) => state.locale);
  const { chineseName, englishName } = useSelector((state: IRootState) => state.login);
  const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];
  const handleNoSelection = () => {
    props.onClose?.(undefined);
  };

  const [itemSelected, setItemSelected] = useState(-1);
  const [selectedFormId, setSelectedFormId] = useState(props.clientFileForm?.values?.[props.type as keyof Partial<ClientFileMap>]?.[0]?.id);
  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);

  const handleSelectedAndClose = () => {
    if (client) {
      props.onClose?.(client);
      props.onConfirm?.(selectedFormId);
    } else {
      setCheckError(true);
    }
  };

  const handleOnSelect = () => {
    if (client) {
      props.onSelect?.(client);
    }
  }
  /*const handleSelectFile = (type: string, i: number) => {
    const newList = [ ...fileForm.values[type] ];
    newList.splice(i, 1);
    confirmDialog.confirm('確認選擇買家和表格4?', lang.actionYes, lang.actionNo).then((confirmed: boolean) => {
      if (confirmed) {
        fileForm.updateValues(type, newList);
      }
    });
  }*/
  const formsOptions: { [key: string]: string } = {
    DUMMY: lang.actionUpload,
    FORM_4: langPropertyStock.form4,
    FORM_6: langPropertyStock.form6,
  };

  const historyTableColumnHeads = () => <TableRow>
    <TableCell align="left">{clientFormSelections.select}</TableCell>
    <TableCell align="left">{langPropertyStock.captionDate}</TableCell>
    <TableCell align="left">{langPropertyStock.captionFormProperty}</TableCell>
    <TableCell align="left">{lang.actionDownloadOrPreviewWithSpace}</TableCell>
  </TableRow>;


  const FormPropertyDialog = ({ object }: any) => {

    const { formProperty } = object;
    const { langPropertyStock, lang, locale } = useSelector((state: IRootState) => state.locale);

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <div>
        {(formProperty?.length ?? 0) > 0 ?
          <Button startIcon={<ListIcon />} color="default" onClick={handleClickOpen} />
          : null}
        <Dialog open={open} onClose={handleClose} aria-labelledby="FormPropertyDialog-title">
          <DialogTitle style={{ fontWeight: 'bold' }} id="form46-selection">{langPropertyStock.captionFormProperty}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {
                formProperty?.map((property: any, idx: number) => {
                  return (
                    <Typography style={{ margin: '3px' }}>{idx + 1}{'. '}{multiLang(locale, property.propertyAddress, property.propertyAddressEn)}</Typography>
                  )
                })
              }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {lang.actionClose}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  const form46Selection = (type: string, index: number) => {

    const file = props.clientFileForm?.values[type as keyof Partial<ClientFileMap>]?.[index];
    const src = `${BASE_URL}/files/${file?.filename}`;
    let date;
    if (type === 'FORM_4' || type === 'FORM_6') {
      const validityPeriodStart = file?.validityPeriodStart ? moment(file.validityPeriodStart).format(DISPLAY_DATE_FORMAT) : '';
      const validityPeriodEnd = file?.validityPeriodEnd ? moment(file.validityPeriodEnd).format(DISPLAY_DATE_FORMAT) : '';
      date = `(${langPropertyStock.captionValidityPeriod}: ${validityPeriodStart} - ${validityPeriodEnd})`;
    }


    return (

      <TableRow key={`form46-selection-${type}-${index}`}>
        <TableCell padding="checkbox">
          <Radio
            checked={itemSelected === index}
            onClick={() => {
              setItemSelected(index);
              setSelectedFormId(file?.id);

            }}
            inputProps={{ 'aria-labelledby': index.toString() }}
          />
        </TableCell>
        {/* ----- date ----- */}
        <TableCell align="left">
          {date}
        </TableCell>
        {/*----address---*/}
        <TableCell align="left">
          <FormPropertyDialog object={file} />
        </TableCell>
        {/* ----- download button ----- */}
        <TableCell align="left">
          <Button startIcon={<GetAppIcon />} color="default" onClick={() => window.open(src, "blank")} />
          <Button startIcon={<VisibilityIcon />} color="default" onClick={() => window.open(`${src}?inline=1`, "blank")} />
        </TableCell>
      </TableRow>
    )
  }



  // Autcomplete
  const [client, setClient] = useState<ContactsDTO | undefined | null>(undefined);
  const [clients, setClients] = useState<ContactsDTO[]>([]);
  const [clientListLoading, setClientListLoading] = useState(false);
  const [checkError, setCheckError] = useState(false);

  const token: string = useSelector((state: IRootState) => state.login.token ?? '');

  const fetchClients = (keyword: string) => {
    if (keyword.length < 2) { return; }
    setClientListLoading(true);
    clientApi.searchOwnContacts(keyword, token, props.contactId).then(result => {
      if (result.data) {
        setClients(result.data!.filter(c => c.clientType.includes('T') || c.clientType.includes('B')));
        setClientListLoading(false);
      }
    });
  }

  useLayoutEffect(() => {
    if (props.open && props.contactId) {
      clientApi.searchOwnContacts('', token, props.contactId).then(result => {
        if (result.data?.length) {
          setClients(result.data!);
          setClientListLoading(false);
          setClient(result.data![0]!);
        }
      });
    }
  }, [props.contactId, props.open, setClients, setClientListLoading]);
  return (
    <div>
      <Dialog fullWidth maxWidth='md' open={props.open} onClose={handleNoSelection} aria-labelledby="assign-client-dialog">
        <DialogTitle id="assign-client-dialog-title">{props.actionName}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {props.description ? <Typography variant="body2">{props.type === 'FORM_4' ? clientFormSelections.selectBuyerAndForm4 : clientFormSelections.selectTenantAndForm6}</Typography> : null}
          <Autocomplete
            popupIcon={''}
            style={{ width: '80%' }}
            options={clients}
            getOptionLabel={(contact: ContactsDTO) => `${multiLang(locale, contact.chineseName, contact.englishName) ?? ''} (${contactOptions[contact.type]}: ${contact.value})`}
            noOptionsText={''}
            loading={clientListLoading}
            loadingText={lang.msgLoading}

            renderOption={(contact: ContactsDTO) => <div>
              <div><Typography>{multiLang(locale, contact.chineseName, contact.englishName)}</Typography></div>
              <div><Typography variant="caption" style={{ display: isNonEmpty(contact.value) ? 'block' : 'none' }}>{contact.value} ({contactOptions[contact.type]})</Typography></div>
            </div>}
            renderInput={(params: any) => (
              <TextField
                {...params}
                error={!client && checkError}
                className={classes.patchOutlineAutocomplete}
                fullWidth
                label={langClientDetail.contactName}
                margin="dense"
                required
                variant="outlined"
                onChange={(ev) => {
                  fetchClients(ev.target.value);
                  setItemSelected(-1);
                }}
                onBlur={() => setCheckError(true)}
              />
            )}
            value={client ?? null}
            onChange={(_: any, val: ContactsDTO) => {
              setClient(val ?? undefined);
            }}
            onSelect={() => {
              handleOnSelect();
            }}
          />
          <Table className={classes.table} size="small" aria-label="document list table">
            <TableHead>
              {historyTableColumnHeads()}
            </TableHead>

            {props.type === 'FORM_4' ? props.clientFileForm?.values.FORM_4?.filter(f => (f.agentFullNameEn === englishName) || (f.agentFullName === (chineseName ?? englishName))).map((file: Form4DTO, index: number) => form46Selection(file.type, index))
              :
              props.clientFileForm?.values.FORM_6?.filter(f => (f.agentFullNameEn === englishName) || (f.agentFullName === (chineseName ?? englishName))).map((file: Form6DTO, index: number) => form46Selection(file.type, index))}
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNoSelection} color="primary">
            {lang.actionCancel}
          </Button>
          <Button onClick={handleSelectedAndClose} disabled={!(((props.clientFileForm?.values.FORM_4?.length ?? 0) > 0) && props.type === 'FORM_4' || ((props.clientFileForm?.values.FORM_6?.length ?? 0) > 0) && props.type !== 'FORM_4')} color="primary" variant="contained" autoFocus>
            {lang.actionConfirm}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}