import '@fortawesome/fontawesome-free/js/brands';
// import SaveIcon from '@material-ui/icons/Save';
// loadCSS('https://use.fontawesome.com/releases/v5.1.0/css/all.css');
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/regular';
import '@fortawesome/fontawesome-free/js/solid';
import { Button, ButtonGroup, ClickAwayListener, FormHelperText, Grow, makeStyles, MenuItem, MenuList, Paper, Popper, SvgIconProps, Tooltip } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import Icon, { IconProps } from '@material-ui/core/Icon';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MuiDeleteIcon from '@material-ui/icons/Delete';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import MuiSaveIcon from '@material-ui/icons/Save';
import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IRootState } from 'reducers';
const useStyles = makeStyles(() => ({
  fas: ({ sizeSpec }: any) => ({
    width: 'auto',
    '&:before': {
      fontSize: sizeSpec === 'small' ? 24 : sizeSpec === 'large' ? 30 : sizeSpec,
    },
  }),
}));

const FontAwesomeIcon = (props: IconProps & { icon: string }) => <Icon
  {...props}
  className={`${useStyles({ sizeSpec: props.fontSize }).fas} fas ${props.icon} ${props.className || ''}`}
/>;

export const SaveIcon = (props: SvgIconProps) => <MuiSaveIcon {...props} />;
export const DeleteIcon = (props: SvgIconProps) => <MuiDeleteIcon {...props} />;
export const AliasIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-link`} />;
export const AddIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-plus`} />;
export const InfoIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-info-circle`} />;
export const PropertyDetailIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-home`} />;
export const PropertySummaryIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-list-alt`} />;
export const PropertySaleInfoIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-hand-holding-usd`} />;
export const PropertyMgtInfoIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-file-invoice-dollar`} />;
export const PropertyHistoryIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-file-alt`} />
export const BuildingIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-building`} />
export const MemoIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-sticky-note`} />
export const ReportIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-file-invoice-dollar`} />

export const ClientAddIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-user-plus`} />;
export const ClientDetailIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-address-card`} />;
export const RoleMgtIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-users-cog`} />;
export const ActivityAuditingIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-user-clock`} />;
export const SystemSettingIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-cogs`} />;

export const FormIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-file-contract`} />;
export const PdfIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-file-pdf`} />;
export const SearchIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-search`} />;

export const PropertyMatchingIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-search-location`} />;
export const EditPsStatusIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-edit`} />;
export const ChangePasswordIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-key`} />;

export const ClientAppSettingIcon = (props: SvgIconProps) => <PhonelinkSetupIcon {...props} />;
export const HomePageFeaturedTagsIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-th-large`} />;

export const CloseDealIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-check-double`} />;

export const WeChatIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fab fa-weixin`} />;
export const KeyIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-key`} />;
export const ContactRemarksIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`far fa-sticky-note`} />
export const HotPickIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fab fa-hotjar`} />;

export const PDFIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-file-pdf`} />;
export const PSClientInfoIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-user-tie`} />;
export const PSAddClientInfoIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-user-plus`} />;
export const PSEditClientInfoIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-user-edit`} />;
export const ExternalLinkIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-external-link-alt`} />;

export const RemoveAgentIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-user-slash`} />;
export const AssignAgentIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-user-edit`} />;

export const MapIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-map-marked-alt`} />;
export const CloseIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-times`} />;
export const MapMarker = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-map-marker-alt`} />;

export const AddToCartIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-cart-plus`} />;
export const CheckUserIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-user-check`} />;

export const NotYetProcessedIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-hourglass-half`} />;
export const FilterIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-filter`} />;

export const ClockIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-clock`} />;
export const AgentRatingCommentApproveIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-user-check`} />;
export const AgentRatingCommentRejectIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-user-times`} />;
export const AgentCommentIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-comment-alt`} />;

export const StarSolidIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-star`} />;
export const StarWhiteIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`far fa-star`} />;
export const StarHalfIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-star-half`} />;
export const TagIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fa-tag`} />;
export const CornJobIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={`fas fa-user-clock`} />;

//export const lang = useSelector((state: IRootState) => state.locale);

export interface SplitButtonProps extends ButtonProps {
  options: string[];
}

// const UITextField = (props: TextFieldProps & { fixedLabel?: boolean }) => {
//   let { label, fixedLabel, ...rest } = props;
//   return <Fragment>
//     {fixedLabel ? <Typography>{label}</Typography> : null}
//     <TextField {...rest} label={!fixedLabel ? label : null} />
//   </Fragment>
// };

export default function SplitButton(props: SplitButtonProps) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClick = () => {
    console.info(`You clicked ${props.options[selectedIndex]}`);
  };

  const handleMenuItemClick = (event: React.MouseEvent<any>, index: number) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: React.ChangeEvent<any>) => {
    if (anchorRef.current && anchorRef.current!.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Fragment>
      <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
        <Button onClick={handleClick}>{props.options[selectedIndex]}</Button>
        <Button
          color="primary"
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper style={{ zIndex: 100000, }} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {props.options.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={event => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
}

export function tooltipHoc<P>(Comp: React.ComponentType<P>) {
  return (props: P & { tooltip?: string, tooltipFontSize?: string }) => {
    const { tooltip, tooltipFontSize } = props;
    return tooltip !== undefined ? <Tooltip title={<span style={{ fontSize: tooltipFontSize }}>{tooltip}</span>}>
      <span><Comp {...props} /></span>
    </Tooltip> : <Comp {...props} />;
  };
}

export function hideWhenHoc<P>(Comp: React.ComponentType<P>) {
  return (props: P & { hideWhen?: boolean }) => {
    const { hideWhen, ...rest } = props;
    return hideWhen ? null : <Comp {...(rest as P)} />;
  };
}

function _ScrollToTop({ history }: { history: any }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, []);

  return null;
}

export const HandleFormHelperText = (props: { isError: boolean | undefined, errorMessage: string }) => {
  if (props.isError) {
    return <FormHelperText>{props.errorMessage}</FormHelperText>;
  }
  else {
    return null;
  }

}



export const ScrollToTop = withRouter(_ScrollToTop);