import React, { useLayoutEffect, useRef, useState } from 'react';
import classes from './FloatingCaptionTile.module.scss';


export interface FloatingCaptionTileProps {
  caption: string;
  value: string;
  backgroundColor?: any; 
}

export default function FloatingCaptionTile(props: FloatingCaptionTileProps) {
  const { caption, value, backgroundColor } = props;
  const captionRef = useRef<HTMLDivElement>(null);
  
  const [ bgWidth, setBgWidth ] = useState(0);

  useLayoutEffect(() => {
    setBgWidth(captionRef.current?.offsetWidth ?? 0);
  }, [ caption, captionRef ]);

  return <div className={classes['outer']}>
    <div className={classes['root']} 
      style={{ minWidth: `${bgWidth + 16}px`, 
        backgroundColor: backgroundColor,
        border: '1px solid ' + backgroundColor,
      }}>
      <div className={classes['caption-bg']} style={{ width: `${bgWidth + 5}px` }}></div>
      <div className={classes['caption']} ref={captionRef}>{caption}</div>
      <div className={classes['value']}>{value}</div>
    </div>
  </div>
}