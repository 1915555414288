import { ApiError, ApiPageResult } from 'api';
import activityAuditingApi from 'api/activityAuditingApi';
import { ActivityAuditingDTO } from 'common/dto';
import { ActivityAuditingActions, IRootState } from 'reducers';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PASSagaContext } from 'sagas';
import { apiTaskWrapper } from './saga-commons';


export function* watchActivityAuditingListFetchRequested(context: PASSagaContext) {
  yield takeLatest(
    'ActivityAuditingList.FetchRequested',
    apiTaskWrapper(requestActivityAuditingList),
    context,
  );
}

export function* requestActivityAuditingList(context: PASSagaContext, action: Extract<ActivityAuditingActions, { type: 'ActivityAuditingList.FetchRequested' }>) {
  if (action.type !== 'ActivityAuditingList.FetchRequested') {
    return;
  }

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiPageResult<ActivityAuditingDTO> = yield call(activityAuditingApi.getList, action.payload, token);
  

  if (!result.error) {
    yield put<ActivityAuditingActions>({ type: 'ActivityAuditingList.Loaded', payload: result.data! });
  } else {
    throw ApiError.of(result.error);
  }
}