import { FormHelperText, Grid, makeStyles, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DistrictMultiSelect from 'common/elements/DistrictMultiSelect';
import { FormPresenter } from 'common/hooks';
import React from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';

const useStyles = makeStyles(() => ({
  root: {

  },
  patchOutlineAutocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
      paddingLeft: 8,
    }
  },
  dialogContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 350,
  },
  textArea: {
    width: '90%'
  }
}));

export interface CreateClientWarningDialogProps {
  cid? : string;
  originalAgentId?: string;
  preferenceForm : FormPresenter<any>;
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}

const CreateClientWarningDialog = ({ preferenceForm, open, handleClose, handleConfirm}: CreateClientWarningDialogProps) => {
  const classes = useStyles();
  const { langClientDetail, lang } = useSelector((state: IRootState) => state.locale);
  return (
    <div>
     
      <Dialog open={open} onClose={handleClose} aria-labelledby="assign-agent-dialog">
        <DialogContent  className={classes.dialogContent}>
          <Grid item container md={12} xs={12} spacing={1} >
            {/* <Grid
              item
              md={12}
              xs={12}
            >
              <Typography>{langClientDetail.msgWarningCreateDormantClient}</Typography>
              
            </Grid> */}
            {/* { (preferenceForm.values.district?.length ?? 0) > 0  && (preferenceForm.values.clientType?.includes('B') || preferenceForm.values.clientType?.includes('T'))  ? null : */}
              
              <Grid
                item
                md={12}
                xs={12}
              >
                <Typography style={{paddingBottom:'3px'}}>{(preferenceForm.values.district?.length ?? 0) > 0 ? langClientDetail.msgConfirmDistrictForDormantClient : langClientDetail.msgSelectDistrictForDormantClient}</Typography>
                <DistrictMultiSelect
                  // required
                  error={!!preferenceForm.hasError('district')}
                  value={preferenceForm.values.district?.split(',').map((n: string) => n.trim()) ?? []}
                  onChange={(values) => preferenceForm.updateValues('district', values.join(','))}
                />
                {preferenceForm.hasError('district') ? <FormHelperText error>{preferenceForm.hasError('district')}</FormHelperText> : null}
              </Grid>
              {/* } */}
          </Grid>

            
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {lang.actionCancel}
          </Button>
          <Button onClick={handleConfirm} color="primary" variant="contained" autoFocus>
            {lang.actionConfirm}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CreateClientWarningDialog;
