import {
    Button as MuiButton, Card, Checkbox, colors, Menu,
    MenuItem, Table,
    TableBody,
    TableCell, TableContainer, TableHead, TableRow,
    // TablePagination,
    Theme
} from '@material-ui/core';
import { CardProps } from '@material-ui/core/Card';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { hasPermission } from 'common/access-control';
import { useConfirmDialog } from 'common/ConfirmDialog';
import { CAAgentRatingCommentDTO, MasterDataDTO } from 'common/dto';
import TablePagination from 'common/elements/TablePagination';
import { useInfoDialog } from 'common/InfoDialog';
import { tooltipHoc } from 'common/ui';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState, PASDispatch } from 'reducers';
import AgentRatingCommentItem from './AgentRatingCommentItem';

const Button = tooltipHoc(MuiButton);

const useStyles = makeStyles((theme: Theme) => ({
	root: {},
	content: {
		padding: 0
	},
	inner: {
		minWidth: 1050
	},
	nameContainer: {
		display: 'flex',
		alignItems: 'center'
	},
	avatar: {
		marginRight: theme.spacing(2)
	},
	actions: {
		justifyContent: 'flex-start',
		background: colors.grey[50],
		flexWrap: 'wrap',
    '& > *': {
      wordBreak: 'keep-all',
      // marginBottom: '8px',
    },
    padding: '0px',
	},
	table: {
    // minWidth: 650,
	},
	tableRow: {
		cursor: 'pointer',
	},
	tableCell: {
		wordWrap: 'normal',
		wordBreak: 'keep-all',
		lineBreak: 'strict',
		whiteSpace: 'nowrap',
		// minWidth: 128,
	},
	tablePaginationIconButton:{
    '& .MuiIconButton-root':{
      padding: '6px',
    }
	},
	tablePaginationToolbar:{
    paddingRight: '12px',
  },
  clearSortingContainer: {
    padding: '3px',
  }
}));
  
type Header = {
	title: string | JSX.Element,
	overrideStyle?: any,
	sortDisabled?: boolean;
};

interface RoleTableProps extends CardProps {
  className?: string;
  agentRatingComments: CAAgentRatingCommentDTO[];

  page: number;
	totalPages: number;
	totalElements: number;
  limit: number;
  setPage: (page: number) => void;
  sortOrders?: { [k: string]: 'desc' | 'asc' };
  handleSort?: (key: string | null) => void;
}

const AgentRatingCommentTable = (props: RoleTableProps) => {
	const { className, agentRatingComments, sortOrders, handleSort, page, totalPages, totalElements, limit, setPage, ...rest } = props;
	const { langSystemSetting, lang, statusOptions, langAgentRatingCommentMgt, } = useSelector((state: IRootState) => state.locale);
  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);
  const dispatch = useDispatch() as PASDispatch;
	const confirmDialog = useConfirmDialog();
	const alertDialog = useInfoDialog();

	const { privileges } = useSelector((state: IRootState) => state.login);
	const canApprove = hasPermission(privileges, 'APPROVE', 'CLIENT_APP_AGENT_RATING_COMMENT') ;
	
	const classes = useStyles(0);
	
	const handlePageChange = (event: React.MouseEvent | null, page: number) => {
		setPage(page);
	};
	
  const [] = React.useState<MasterDataDTO>({});

  
	const [ selectedAgentRatingCommentIds, setSelectedAgentRatingCommentIds ] = useState<string[]>([]);


	const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedAgentRatingCommentIds(agentRatingComments.map(n => n.id!.toString()));
    } else {
      setSelectedAgentRatingCommentIds([]);
    }

	};

	const handleSelectOne = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const selectedIndex = selectedAgentRatingCommentIds.indexOf(id);
    let newSelectedNotifications: string[] = [];

    if (selectedIndex === -1) {
      newSelectedNotifications = newSelectedNotifications.concat(selectedAgentRatingCommentIds, id);
    } else if (selectedIndex === 0) {
      newSelectedNotifications = newSelectedNotifications.concat(selectedAgentRatingCommentIds.slice(1));
    } else if (selectedIndex === selectedAgentRatingCommentIds.length - 1) {
      newSelectedNotifications = newSelectedNotifications.concat(selectedAgentRatingCommentIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedNotifications = newSelectedNotifications.concat(
        selectedAgentRatingCommentIds.slice(0, selectedIndex),
        selectedAgentRatingCommentIds.slice(selectedIndex + 1)
      );
    }
    setSelectedAgentRatingCommentIds(newSelectedNotifications);
	};

	const hasApprovedRejectedComment = (agentRatingComments
		.filter(comment => selectedAgentRatingCommentIds.includes(comment.id!.toString()))
		.filter(comment => comment.reviewStatus !== 'PENDING')?.length ?? 0 ) > 0;
	

	const onBatchApproveAction = () => {
		handleCloseActionMenu();
		if (hasApprovedRejectedComment){
			alertDialog.confirm(langAgentRatingCommentMgt.msgCanOnlyHandlePendingAgentRatingComment, lang.actionConfirm, lang.actionClose).then(confirmed => {
				if (confirmed) {
				}
			});
		}else{
			confirmDialog.confirm(langAgentRatingCommentMgt.msgConfirmApprove, lang.actionConfirm, lang.actionCancel).then(confirmed => {
				if (confirmed) {
					dispatch({ type: 'AgentRatingComment.ApproveActionRequested', payload: { ids: selectedAgentRatingCommentIds } });
					setSelectedAgentRatingCommentIds([]);
				}
			});
		}
	};

	const onBatchRejectAction = () => {
		handleCloseActionMenu();
		if (hasApprovedRejectedComment){
			alertDialog.confirm(langAgentRatingCommentMgt.msgCanOnlyHandlePendingAgentRatingComment, lang.actionConfirm, lang.actionClose).then(confirmed => {
				if (confirmed) {
				}
			});
		}else{
			confirmDialog.confirm(langAgentRatingCommentMgt.msgConfirmReject, lang.actionConfirm, lang.actionCancel).then(confirmed => {
				if (confirmed) {
					dispatch({ type: 'AgentRatingComment.RejectActionRequested', payload: { ids: selectedAgentRatingCommentIds } });
					setSelectedAgentRatingCommentIds([]);
				}
			});
		}
	};

	//--------- action menu
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClickActionMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseActionMenu = () => {
    setAnchorEl(null);
  };

	const handlefilter2 = (agentRatingComments: CAAgentRatingCommentDTO[]):CAAgentRatingCommentDTO[] => {
    return agentRatingComments.filter((tag, index, array) => 
    array.findIndex(t => t.agentUserId == tag.agentUserId && t.customerId == tag.customerId) == index)
  }
 
  const handlefilter = (agentRatingComments.filter((tag, index, array) => 
    array.findIndex(t => t.agentUserId == tag.agentUserId && t.customerId == tag.customerId) == index)
  );
  var tmp:CAAgentRatingCommentDTO[] = handlefilter2(agentRatingComments);

	return ( 
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>	
			{confirmDialog.render()}
			{alertDialog.render()}
			{/* <PerfectScrollbar> */}
			{/* <div className={classes.inner}> */}
			<TableContainer style={{overflowX: 'unset'}}>
					<Table size="small"  aria-label="agent-rating-comment">
						<TableHead>
							<TableRow>
								{canApprove &&
									<TableCell padding="checkbox">
										<Checkbox
											checked={selectedAgentRatingCommentIds.length === agentRatingComments.length}
											onChange={handleSelectAll}
										/>
									</TableCell>
								}
								<TableCell style={{paddingBottom: '0px', paddingTop: '0px', paddingLeft: '16px'}}>
									<div style={{ display: 'flex', justifyContent: 'space-between' }}>
										{canApprove?
											<div>
												<Button style={{ height: '100%' }} onClick={handleClickActionMenu} endIcon={<ArrowDropDownIcon />} color="inherit">{langAgentRatingCommentMgt.captionAction}</Button>
												<Menu
													id="acition-menu"
													anchorEl={anchorEl}
													keepMounted
													open={Boolean(anchorEl)}
													onClose={handleCloseActionMenu}
												>
													<MenuItem onClick={onBatchApproveAction}>{langAgentRatingCommentMgt.actionApprove}</MenuItem>
													<MenuItem onClick={onBatchRejectAction}>{langAgentRatingCommentMgt.actionReject}</MenuItem>
												</Menu>
											</div>
										: <div></div>}
										<TablePagination
											classes={{
												actions: classes.tablePaginationIconButton,
												toolbar: classes.tablePaginationToolbar,
											}}
											// component="div"
											count={totalElements}
											onChangePage={handlePageChange}
											// onChangeRowsPerPage={handleRowsPerPageChange}
											page={page}
											totalPages={totalPages}
											rowsPerPage={limit}
											labelRowsPerPage={lang.rowsCount}
											rowsPerPageOptions={[20]}
										/>
									</div>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{
								//tmp
								// .filter((tag,index,array) => array.findIndex(e => e.agentUserId == tag.agentUserId && e.customerId == tag.customerId) ==index)
								agentRatingComments
								.map(agentRatingCommentItem => (
									<TableRow key={`agent-rating-comment-${agentRatingCommentItem.id}`}>
										{canApprove &&
											<TableCell padding="checkbox" style={{ position: 'relative' }}>

												<Checkbox
													checked={selectedAgentRatingCommentIds.indexOf(agentRatingCommentItem.id!.toString()) !== -1}
													onChange={(ev) => handleSelectOne(ev, agentRatingCommentItem.id!.toString())}></Checkbox>
											</TableCell>
										}
										<TableCell style={{paddingBottom: '0px', paddingTop: '0px', paddingLeft: '16px'}}>
												<AgentRatingCommentItem canApprove={canApprove}
													CAAgentRatingComment={agentRatingCommentItem}/>
										</TableCell> 
									</TableRow>
								))
							}
						</TableBody>
					</Table>
				</TableContainer>
				{/* </div> */}
				{/* </PerfectScrollbar> */}
		</Card>
	);
}

export default AgentRatingCommentTable;