import { ApiError, ApiPageResult, ApiResult } from 'api';
import transactionApi from 'api/transactionApi';
import { TransactionDetailDTO, TransactionListItemDTO, TransactionSearchDTO } from 'common/dto';
import { DEFAULT_FROM, DEFAULT_TO } from 'common/elements/MinMaxField';
import { priceFromView } from 'common/utils';
import { IRootState, TransactionActions } from 'reducers';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { PASSagaContext } from 'sagas';
import { apiTaskWrapper, itemActionMessage } from './saga-commons';


export function* watchTransactionListFetchRequested(context: PASSagaContext) {
  yield takeLatest(
    'TransactionList.FetchRequested',
    apiTaskWrapper(requestTransactionList),
    context,
  );
}

const toCriteria = (values: Partial<TransactionSearchDTO>, locale: string) => {
  if (Array.isArray(values.price)) {
    values = {
      ...values,
      price: [
        values.price[0] === DEFAULT_FROM ? DEFAULT_FROM : priceFromView(values.price[0], locale),
        values.price[1] === DEFAULT_TO ? DEFAULT_TO : priceFromView(values.price[1], locale),
      ],
    };
  }

  const newForm: Partial<TransactionSearchDTO> = {};
  const { isGross, isRent, disabledCriteriaList, ...formValues } = values;
  Object
    .keys(formValues)
    .filter((key) => !(disabledCriteriaList ?? {})[key] &&
      (isGross ? (key !== 'net') : (key !== 'gross')) &&
      (isRent ? (key !== 'price') : (key !== 'rent'))
    )
    .forEach((key) => (newForm as any)[key] = formValues[key as keyof typeof formValues]);

  return newForm;
};

export function* requestTransactionList(context: PASSagaContext, action: Extract<TransactionActions, { type: 'TransactionList.FetchRequested' }>) {
  if (action.type !== 'TransactionList.FetchRequested') {
    return;
  }

  const token: string = yield select((state: IRootState) => state.login.token ?? '');
  const locale = yield select((state: IRootState) => state.locale.locale);

  const searchParams = yield select((state: IRootState) => state.transaction.criteria);
  const result: ApiPageResult<TransactionListItemDTO> = yield call(transactionApi.getList, toCriteria(searchParams, locale), token);


  if (!result.error) {
    yield put<TransactionActions>({ type: 'TransactionList.Loaded', payload: result.data! });
  } else {
    throw ApiError.of(result.error!);
  }

}

export function* watchTransactionDetailFetchRequested() {
  yield takeEvery<TransactionActions['type']>(
    'Transaction.FetchRequested',
    apiTaskWrapper(requestTransactionDetail)
  )
}

export function* requestTransactionDetail(action: TransactionActions) {
  if (action.type != 'Transaction.FetchRequested') {
    return;
  }

  const { id } = action.payload;


  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<TransactionDetailDTO> = yield call(transactionApi.getDetail, id, token);

  if (!result.error) {
    yield put<TransactionActions>({ type: 'Transaction.Loaded', payload: result.data! });
  } else {
    throw ApiError.of(result.error!, null, "/transactions");
  }
}

// Transaction.UpdatePropertyDraftRequested

export function* watchTransactionUpdatePropertyDraftRequested() {
  yield takeEvery<TransactionActions['type']>(
    'Transaction.UpdatePropertyDraftRequested',
    apiTaskWrapper(requestUpdatePropertyDraft)
  )
}

export function* requestUpdatePropertyDraft(action: TransactionActions) {
  if (action.type != 'Transaction.UpdatePropertyDraftRequested') {
    return;
  }

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<TransactionDetailDTO> = yield call(transactionApi.updatePropertyDraft, action.payload.update, token);

  if (!result.error) {
    yield put<TransactionActions>({ type: 'Transaction.Loaded', payload: result.data! });
    yield* itemActionMessage('update', 'Transaction');
  } else {
    throw ApiError.of(result.error!);
  }
}


export function* watchTransactionColumnPreferenceRequested() {
  yield takeEvery(
    'Transaction.ColumnPreference.FetchRequested',
    apiTaskWrapper(requestTransactionColumnPreference),
  );
}

export function* requestTransactionColumnPreference(action: TransactionActions) {
  if (action.type != 'Transaction.ColumnPreference.FetchRequested') {
    return;
  }
  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<any> = yield call(transactionApi.getColumnPreference, token);

  if (!result.error) {
    yield put<TransactionActions>({ type: 'Transaction.ColumnPreference.Loaded', payload: JSON.parse(result.data) ?? {} })
  } else {
    throw ApiError.of(result.error);
  }

}