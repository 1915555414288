import { ApiError, ApiPageResult, ApiResult } from 'api';
import clientApi from 'api/clientApi';
import { ClientDetailDTO, ClientListItemDTO, MemoDTO, PropertyListItemDTO } from 'common/dto';
import { isNonEmpty } from 'common/utils';
import { ClientActions, ClientMergeActions, IRootState, PropertyMatchingActions } from 'reducers';
import { call, cancelled, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { PASSagaContext } from 'sagas';
import { isNullOrUndefined } from 'util';
import { apiTaskWrapper, itemActionMessage } from './saga-commons';


export function* watchClientListFetchRequested(context: PASSagaContext) {
  yield takeLatest(
    'ClientList.FetchRequested',
    apiTaskWrapper(requestClientList),
    context,
  );
}

export function* requestClientList(context: PASSagaContext, action: Extract<ClientActions, { type: 'ClientList.FetchRequested' }>) {
  if (action.type !== 'ClientList.FetchRequested') {
    return;
  }


  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiPageResult<ClientListItemDTO> = yield call(clientApi.getList, action.payload, token);


  if (!result.error) {

    yield put<ClientActions>({ type: 'ClientList.Loaded', payload: result.data! });
  } else {
    // yield put<PropertyActions>({ type: 'ClientList.Failed', payload: result.error });
    throw ApiError.of(result.error!);
  }


}

///////////////////////////////////////////////////////////////////
///////////////////// Client Detail Fetched ///////////////////////
///////////////////////////////////////////////////////////////////

export function* watchClientDetailFetchRequested(context: PASSagaContext) {
  yield takeEvery( //TODO need fix here
    'ClientDetail.FetchRequested',
    apiTaskWrapper(requestClientDetail, undefined, context),
    context
  )
}
export function* requestClientDetail(context: PASSagaContext, action: ClientActions) {
  if (action.type != 'ClientDetail.FetchRequested') {
    return;
  }

  const { cid } = action.payload;

  const token: string = yield select((state: IRootState) => state.login.token ?? '');
  //temp approah for handling creating new client
  if (cid == '0') {
    const dummyResult: ApiResult<ClientDetailDTO> = {
      data: { cid: '0' } as ClientDetailDTO,
      error: null
    };
    yield put<ClientActions>({ type: 'ClientDetail.Loaded', payload: dummyResult.data! });
    return;
  }

  const result: ApiResult<ClientDetailDTO> = yield call(clientApi.getDetail, cid, token);

  if (!result.error) {
    yield put<ClientActions>({ type: 'ClientDetail.Loaded', payload: result.data! });
  } else {
    if (result.error !== 'ERR_MOVED') {
      yield call(context.browserHistory.push as any, `/client-detail`, { bypassUnsavedCheck: true });
    }
    // console.log('ERR_MOVE', result)
    if (isNonEmpty(result.metadata?.objectId)) {
      yield put({ type: 'ClientDetail.FetchRequested', payload: { cid: result.metadata.objectId as string } });
      // yield call(context.browserHistory.push as any, `/client-detail/${result.metadata.objectId}`, { bypassUnsavedCheck: true });
    }
    throw ApiError.of(result.error, result.metadata);

  }
}

/////////////////////////////////////////////////////////////////////
/////////////////////// Client Detail Creation //////////////////////
/////////////////////////////////////////////////////////////////////

export function* watchClientDetailCreationRequested(context: PASSagaContext) {
  yield takeEvery(
    'ClientDetail.CreationRequested',
    apiTaskWrapper(createClientDetail),
    context,
  );
}

export function* createClientDetail(context: PASSagaContext, action: Extract<ClientActions, { type: 'ClientDetail.CreationRequested' }>) {


  const token: string = yield select((state: IRootState) => state.login.token ?? '');
  //TODO 
  const result: ApiResult<ClientDetailDTO> = yield call(clientApi.add, action.payload, token);

  //dummy data
  // const result : ApiResult < ClientDetailDTO >= {
  //   data: {...action.payload, cid: '99'} , 
  //   error: null
  // };

  if (!result.error) {
    yield* itemActionMessage('create', 'Client');
    const newClientId = result.data?.cid;
    //FIXME temp use only, remove later
    //yield put<ClientDetailActions>({ type: 'ClientDetail.UpdatedOrCreated', payload: result.data! });

    yield call(context.browserHistory.replace as any, `/client-detail/${newClientId}`);
  } else {
    throw ApiError.of(result.error!);
  }

}

/////////////////////////////////////////////////////////////////////
////////////////////////// Client Update ////////////////////////////
/////////////////////////////////////////////////////////////////////

export function* watchClientDetailUpdateRequested(context: PASSagaContext) {
  yield takeEvery(
    'ClientDetail.UpdateRequested',
    apiTaskWrapper(updateClientDetail),
    context,
  );
}

export function* updateClientDetail(context: PASSagaContext, action: Extract<ClientActions, { type: 'ClientDetail.UpdateRequested' }>) {


  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<ClientDetailDTO> = yield call(clientApi.update, action.payload, token);

  if (!result.error) {
    yield* itemActionMessage('update', 'Client');
    yield put<ClientActions>({ type: 'ClientDetail.Loaded', payload: result.data! });
    yield put<ClientActions>({ type: 'Client.Memo.FetchRequested', payload: { cid: result.data?.cid ?? '' } })
  } else {
    throw ApiError.of(result.error!);

  }

}

/////////////////////////////////////////////////////////////////////
////////////////////////// Stock Cart ////////////////////////////
/////////////////////////////////////////////////////////////////////
export function* watchStockCartFetchRequested() {
  yield takeEvery(
    'StockCart.FetchRequested',
    apiTaskWrapper(requestStockCart),
  );
}

export function* requestStockCart(action: Extract<ClientActions, { type: 'StockCart.FetchRequested' }>) {
  // if (action.type !== 'StockCart.FetchRequested') {
  //   return;
  // }


  try {

    const token: string = yield select((state: IRootState) => state.login.token ?? '');

    // yield delay(1000);

    const result: ApiPageResult<PropertyListItemDTO> = yield call(clientApi.getStockCart, action.cid, action.propertyStatus ?? '', action.payload, token);



    if (!result.error) {
      yield put<ClientActions>({ type: 'StockCart.Loaded', payload: result.data! });

      // //filter record for form 4 or 6
      // if (!isNullOrUndefined(action.propertyStatus)) {
      //   const filterList = result.data!.content.filter((p) => p.status === action.propertyStatus || p.status === 'BOTH');
      //   yield put<ClientActions>({ type: 'StockCart.RefreshForForm4And6', payload: { propertyList: filterList } });
      // }

    } else {
      // yield put<PropertyActions>({ type: 'PropertyList.Failed', payload: result.error });
      throw ApiError.of(result.error!);
    }

  } finally {
    if (yield cancelled()) {
      console.info('requestStockCart cancelled');
    }
  }

}

export function* watchAddStockCartRequested(context: PASSagaContext) {
  yield takeEvery(
    'PropertyMatching.AddStockCartRequested',
    apiTaskWrapper(addStockCart),
    context,
  );
}

export function* addStockCart(context: PASSagaContext, action: Extract<PropertyMatchingActions, { type: 'PropertyMatching.AddStockCartRequested' }>) {
  // 

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<any> = yield call(clientApi.addStockCart, action.payload, token);

  if (!result.error) {
    yield put<ClientActions>({ type: 'ClientDetail.TabIndexChanged', payload: { cid: action.payload.cid, tabIndex: 2 } });
    yield put<PropertyMatchingActions>({ type: 'PropertyMatching.StopMatching' });
    yield call(context.browserHistory.push as any, `/client-detail/${action.payload.cid}?tab=${encodeURIComponent('wishlist')}`);
  } else {
    throw ApiError.of(result.error!);

  }

}

export function* watchRemoveStockCartRequested(context: PASSagaContext) {
  yield takeEvery(
    'PropertyMatching.RemoveStockCartRequested',
    apiTaskWrapper(removeStockCart),
    context,
  );
}

export function* removeStockCart(context: PASSagaContext, action: Extract<PropertyMatchingActions, { type: 'PropertyMatching.RemoveStockCartRequested' }>) {
  // 

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<any> = yield call(clientApi.removeStockCart, action.payload, token);

  if (!result.error) {
    // yield call(context.browserHistory.push as any, `/client-detail/${action.payload.cid}`);
    yield put<ClientActions>({
      type: 'StockCart.FetchRequested',
      cid: action.payload.cid ?? '',
      payload: { page: 0, size: 10, sort: {} },
      propertyStatus: action.payload.propertyStatus,
    });
  } else {
    throw ApiError.of(result.error!);

  }

}

/////////////////////////////////////////////////////////////////////
///////////////////////// Memo List Fetched /////////////////////////
/////////////////////////////////////////////////////////////////////
export function* watchMemoFetchRequested() {
  yield takeLatest(
    'Client.Memo.FetchRequested',
    apiTaskWrapper(requestMemoList),
  );
}

export function* requestMemoList(action: ClientActions) {
  if (action.type !== 'Client.Memo.FetchRequested') {
    return;
  }

  const { cid } = action.payload;

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<MemoDTO[]> = yield call(clientApi.getMemoList, cid, token);

  if (!result.error) {
    yield put<ClientActions>({ type: 'Client.Memo.Loaded', payload: result.data! });
  } else {
    yield put<ClientActions>({ type: 'Client.Memo.Failed', payload: result.error });
  }

}

export function* watchMemoCreateRequested(context: PASSagaContext) {
  yield takeLatest(
    'Client.Memo.CreateRequested',
    apiTaskWrapper(createMemo),
    context
  );
}

export function* createMemo(context: PASSagaContext, action: ClientActions) {
  if (action.type !== 'Client.Memo.CreateRequested') {
    return;
  }

  const { cid, memo } = action.payload;

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<MemoDTO> = yield call(clientApi.addMemo, cid, memo, token);

  if (!result.error) {
    // yield call(context.browserHistory.push as any, `/properties/${id}`);
    yield put<ClientActions>({ type: 'Client.Memo.FetchRequested', payload: { cid: cid ?? '' } });
  } else {
    throw ApiError.of(result.error);
  }
}

export function* watchClaimClientRequested(context: PASSagaContext) {
  yield takeEvery(
    'Client.ClaimRequested',
    apiTaskWrapper(claimClient),
    context,
  );
}

export function* claimClient(context: PASSagaContext, action: Extract<ClientActions, { type: 'Client.ClaimRequested' }>) {
  // 

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  // const lastSearchCriteria  = yield select((state: IRootState) => state.clients.lastSearchCriteria as ClientSearchCritiera);
  const result: ApiResult<any> = yield call(clientApi.agentClaim, action.payload.cid ?? '', token);

  if (!result.error) {
    const { data: clientDetail }: ApiResult<ClientDetailDTO> = yield call(clientApi.getDetail, result.data, token ?? '');
    const { data: list }: ApiPageResult<ClientListItemDTO> = yield call(clientApi.getList, {
      matchAnyContact: clientDetail?.otherContacts.map(c => c.value),
      includeOwnedOnly: true,
      size: 100,
    }, token ?? '');
    const clientList = list?.content.filter(c => c.cid !== clientDetail?.cid) ?? [];
    if (clientList.length > 0) {
      yield put({
        type: 'ClientMerge.Reset', payload: {
          mergeCandidates: clientList,
          mergeClientA: clientDetail,
        }
      });
      yield put({ type: 'ClientMerge.DialogOpen', payload: true });
    } else {
      yield call(context.browserHistory.push as any, `/client-detail/${result.data}`);
    }
  } else {
    throw ApiError.of(result.error!);
  }

}

export function* watchClientMergeRequested(context: PASSagaContext) {
  yield takeEvery(
    'ClientMerge.MergeRequested',
    apiTaskWrapper(mergeClient),
    context,
  );
}

export function* mergeClient(context: PASSagaContext, action: Extract<ClientMergeActions, { type: 'ClientMerge.MergeRequested' }>) {

  const token: string = yield select((state: IRootState) => state.login.token ?? '');
  const { mergeClientA, mergeClientB } = yield select((state: IRootState) => state.clientMerge);

  const result: ApiResult<number> = yield call(clientApi.mergeClient, mergeClientA.cid, mergeClientB.cid, 'CLIENT_CLAIM', token);

  if (!result.error) {
    yield put({ type: 'ClientMerge.DialogOpen', payload: false });
    yield call(context.browserHistory.push as any, `/client-detail/${result.data}`);
  } else {
    throw ApiError.of(result.error!);
  }

}

export function* watchRemoveClientRequested(context: PASSagaContext) {
  yield takeEvery(
    'Client.RemoveRequested',
    apiTaskWrapper(removeClient),
    context,
  );
}

export function* removeClient(context: PASSagaContext, action: Extract<ClientActions, { type: 'Client.RemoveRequested' }>) {
  // 

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  // const lastSearchCriteria  = yield select((state: IRootState) => state.clients.lastSearchCriteria as ClientSearchCritiera);
  const result: ApiResult<any> = yield call(clientApi.agentRemove, action.payload.cid ?? '', token);

  if (!result.error) {
    // yield put<ClientActions>({ type: 'ClientList.FetchRequested', payload: { isCurrentUser: true }});
    // yield put<ClientActions>({ type: 'ClientDetail.TabIndexChanged', payload: { cid: action.payload.cid, tabIndex: 2}});
    // yield put<PropertyMatchingActions>({ type: 'PropertyMatching.StopMatching' });
    yield call(context.browserHistory.push as any, `/client-detail`);
  } else {
    throw ApiError.of(result.error!);

  }

}

/////////////////////////////////////////////////////////////////////
///////////////////////// Assign Agent Request //////////////////////
/////////////////////////////////////////////////////////////////////

export function* watchAssignAgentRequested(context: PASSagaContext) {
  yield takeEvery(
    'Client.AssignAgentRequested',
    apiTaskWrapper(assignAgent),
    context,
  );
}

export function* assignAgent(context: PASSagaContext, action: Extract<ClientActions, { type: 'Client.AssignAgentRequested' }>) {

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<any> = yield call(clientApi.assignAgent, action.payload.cid ?? '', action.payload.agentId ?? '', token);

  if (!result.error) {
    if (action.payload.shouldNotRedirect) {
      yield put<ClientActions>({ type: 'ClientDetail.FetchRequested', payload: { cid: action.payload.cid } });
      yield put<ClientActions>({ type: 'Client.Memo.FetchRequested', payload: { cid: action.payload.cid ?? '' } })
    } else {
      yield call(context.browserHistory.push as any, `/client-detail`);
    }
  } else {
    throw ApiError.of(result.error!);

  }

}


/////////////////////////////////////////////////////////////////////
///////////////////////// Remove Agent Request //////////////////////
/////////////////////////////////////////////////////////////////////

export function* watchRemoveAgentRequested(context: PASSagaContext) {
  yield takeEvery(
    'Client.RemoveAgentRequested',
    apiTaskWrapper(removeAgent),
    context,
  );
}

export function* removeAgent(context: PASSagaContext, action: Extract<ClientActions, { type: 'Client.RemoveAgentRequested' }>) {

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<any> = yield call(clientApi.removeAgent, action.payload.cid ?? '', token);

  if (!result.error) {
    yield call(context.browserHistory.push as any, `/client-detail`); //reload page to handle the access control
  } else {
    throw ApiError.of(result.error!);
  }

}

/////////////////////////////////////////////////////////////////////
////////////////////////// Seller Stock  ////////////////////////////
/////////////////////////////////////////////////////////////////////
export function* watchSellerStockFetchRequested() {
  yield takeEvery(
    'SellerStock.FetchRequested',
    apiTaskWrapper(requestSellerStock),
  );
}

export function* requestSellerStock(action: Extract<ClientActions, { type: 'SellerStock.FetchRequested' }>) {

  try {

    const token: string = yield select((state: IRootState) => state.login.token ?? '');
    const result: ApiPageResult<PropertyListItemDTO> = yield call(clientApi.getSellerStock, action.cid, action.payload, token);



    if (!result.error) {
      yield put<ClientActions>({ type: 'SellerStock.Loaded', payload: result.data! });
    } else {
      throw ApiError.of(result.error!);
    }

  } finally {
    if (yield cancelled()) {
      console.info('requestSellerStock cancelled');
    }
  }

}


export function* watchClientColumnPreferenceRequested() {
  yield takeEvery(
    'Client.ColumnPreference.FetchRequested',
    apiTaskWrapper(requestClientColumnPreference),
  );
}

export function* requestClientColumnPreference(action: ClientActions) {
  if (action.type != 'Client.ColumnPreference.FetchRequested') {
    return;
  }
  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<any> = yield call(clientApi.getColumnPreference, token);

  if (!result.error) {
    yield put<ClientActions>({ type: 'Client.ColumnPreference.Loaded', payload: JSON.parse(result.data) ?? {} })
  } else {
    throw ApiError.of(result.error);
  }

}