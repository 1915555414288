import { BuildingDetailDTO, /* FloorDefinitionDTO, */ IUniqueBuilding } from "common/dto";
import { FormValidations } from "common/hooks";
import { isNonEmpty } from "common/utils";

type Options = { [key: string]: string }

// const getInvalidFloorIndexArray=(formValues: any, key: string)=>{
//   const invalidFloor = formValues[key]?.map((fu: FloorDefinitionDTO, idx:string ) => isNonEmpty(fu.floor) ? -1 : idx).filter((idx:number) => idx> -1);
//   return invalidFloor ?? [];
// }

export const uniqueBuildingValidations = <T extends IUniqueBuilding>(langBuilding: Options): FormValidations<T> => [
  // (values) => values.usage ? null : [
  //   ['usage', langBuilding.pleaseSelect + langBuilding.usage]
  // ],
  (values) => values.district ? null : [
    ['district', langBuilding.pleaseSelect + langBuilding.district]
  ],
  (values) => values.buildingNameZh || values.buildingNameEn ? null : [
    ['buildingNameZh', langBuilding.msgPleaseEnterChinOrEngName],
    ['buildingNameEn', langBuilding.msgPleaseEnterChinOrEngName],
  ],
];

const buildingFormValidations = (
  langBuilding: Options
): FormValidations<BuildingDetailDTO> => {
  const checkFloorUnit = (values: Partial<BuildingDetailDTO>) => {
    let result:string[] = []
    values.floorDefinition?.forEach((item,idx) => {
      if (item.floor?.split(',').some(i => !i.trim()) || item.unit?.split(',').some(i => !i.trim())) {
        result.push(idx.toString())
      }
    })
    return result.join("_")
  }
  return [
    ...uniqueBuildingValidations(langBuilding),
    (values) => 
      checkFloorUnit(values) ? [['floorDefinition', checkFloorUnit(values)]] : null
    ,
    (values) =>
      values.expiryYear === null || !isNonEmpty(values.expiryYear)
        ? null
        : !isNaN(Number(values.expiryYear))
        ? null
        : [["expiryYear", langBuilding.msgInputExpiryYear]],
  ];
};

  // (values) => ((((values.floorDefinition ?? []).length ?? 0) > 0) || values.usage === 'V') ? null : [
  //   ['floorDefinition', langBuilding.msgPleaseEnterFloorDefinition],
  // ],
  // (values) => getInvalidFloorIndexArray(values, 'floorDefinition').length == 0 ? null : [
  //   ['invalidFloorDefinition', getInvalidFloorIndexArray(values, 'floorDefinition').join('_')],
  // ]
export default buildingFormValidations;