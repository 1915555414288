import { ApiPageResult, ApiResult } from 'api';
import { NotificationDTO } from 'common/dto';
import { objectToQuery } from 'common/utils';

const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];

export const notificationApi = {
	getList: async (token: string): Promise<ApiResult<NotificationDTO[]>> => {
		try {
			const response = await fetch(`${BASE_URL}/notifications/list`, {
				headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
			});
			return await response.json();
		} catch (e) {
			return { error: String(e) };
		}  
	},

	getPage: async (criteria: any = {}, token: string): Promise<ApiPageResult<NotificationDTO>> => {
		try {
			const response = await fetch(`${BASE_URL}/notifications?${objectToQuery(criteria)}`, {
				headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
			});
			return await response.json();
		} catch (e) {
			return { error: String(e) };
		}  
	},

	doneAction: async (notification: NotificationDTO, token: string): Promise<ApiResult<any>> => {
		try {
			const response = await fetch(`${BASE_URL}/notifications/done`, {
				method: 'PUT', body: JSON.stringify(notification),
				headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
			});
			return await response.json();
		} catch (e) {
			return { error: String(e) };
		}  
	},

	handleExtraAction: async (id: number, action: string, token: string): Promise<ApiResult<any>> => {
		const form = new FormData();
		form.append('id', String(id));
		form.append('action', action);
		try {
			const response = await fetch(`${BASE_URL}/notifications/extra-action`, {
				method: 'POST', body: form,
				headers: { 'Authorization': `Bearer ${token}` },
			});
			return await response.json();
		} catch (e) {
			return { error: String(e) };
		}  
	},

	deleteAction: async (payload: any, token: string): Promise<ApiResult<any>> => {
		const { ids } = payload;
		const formData = new FormData();
		formData.append('ids', ids);
		try {
			const response = await fetch(`${BASE_URL}/notifications/delete`, {
				method: 'PATCH', body: formData,
				headers: { 'Authorization': `Bearer ${token}` },
			});
			return await response.json();
		} catch (e) {
			return { error: String(e) };
		}  
	}, 

	readAction: async (payload: any, token: string): Promise<ApiResult<any>> => {
		const { ids, isRead } = payload;
		const formData = new FormData();
		formData.append('ids', ids);
		formData.append('isRead', isRead);
		try {
			const response = await fetch(`${BASE_URL}/notifications`, {
				method: 'PATCH', body: formData,
				headers: { 'Authorization': `Bearer ${token}` },
			});
			return await response.json();
		} catch (e) {
			return { error: String(e) };
		}  
	},
}

export default notificationApi;