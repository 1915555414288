import {
  Button as MuiButton, CardContent, CardHeader, CircularProgress, Dialog,
  DialogContent, FormHelperText, Grid, IconButton, InputAdornment, makeStyles, TextField, Tooltip, Typography
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ErrorIcon from '@material-ui/icons/Error';
import clientSignApi from 'api/clientSignApi';
import { EmailFormDTO } from 'common/dto';
import FormSigningInfo from 'common/elements/FormSigningInfo';
import FormSigningStepper from 'common/elements/FormSigningStepper';
import { SignaturePadCanvas, useSignaturePad } from 'common/elements/NewFormSigningDialog';
import { useForm } from 'common/hooks';
import { useInfoDialog } from 'common/InfoDialog';
import en from 'common/languages/en';
import zhHK from 'common/languages/zh_HK';
import { tooltipHoc } from 'common/ui';
import { checkIsHKID, isNonEmpty, multiLang } from 'common/utils';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { IRootState, PASDispatch } from 'reducers';
import { format } from 'util';

const successWord = '#33A64C';
const successBackground = '#E0F0D6';
const warningWord = '#D17212';
const warningBackground = '#FFCC99';
const Button = tooltipHoc(MuiButton);

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    background: '#fff',
    color: '#000',
  },
  dialogContent: {
    width: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    flexGrow: 1,
  },
  brand: {
    color: '#183B68',
    display: 'flex', flexDirection: 'row', alignItems: 'center',
    lineHeight: '40px',
    fontWeight: 'bold',
  },
  message: {
    borderRadius: '5px',
    padding: '10px',
    whiteSpace: 'pre-line'
  },
  signAreaActionButton: {
    marginLeft: '10px',
  },
  inputSectionWidth: {
    maxWidth: '500px',
  }
}));

const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export interface ClientSignPageProps {

}

const ClientSignPage = ({ }: ClientSignPageProps) => {
  const { langSignDialog, lang } = zhHK;
  const dispatch = useDispatch() as PASDispatch;
  const query = useQuery();
  const clientSignState = useSelector((state: IRootState) => state.clientSign);
  const { stage: status, filename: signedFormUrl, message, emailSent, activeStep, clientNameEn, clientName,
    showEmailInputBox, id, formName,
    ...rest } = useSelector((state: IRootState) => state.clientSign);
  const { langSignDialog: langSignDialogEn, lang: langEn } = en

  const [currentWindowWidth, setCurrentWindowWidth] = React.useState(window.innerWidth);
  const updateWidthAndHeight = () => {
    setCurrentWindowWidth(window.innerWidth);
  };
  React.useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });

  // const [status, setStatus] = useState<'signing' | 'generating' | 'success' | 'pendingContactVerification' | 'verifyingContact' | 'index'>('index'); 
  // const [formStatus, setFormStatus] = useState('');
  // const [signedFormUrl, setSignedFormUrl] = useState('');

  const token = query.get("key") ?? '';
  const infoDialog = useInfoDialog();

  const steps = [
    `${langSignDialog.captionStepVerification}\n${langSignDialogEn.captionStepVerification}`,
    `${langSignDialog.captionStepSignForm}\n${langSignDialogEn.captionStepSignForm}`,
    `${langSignDialog.captionStepDone}\n${langSignDialogEn.captionStepDone}`
  ];

  const classes = useStyles();

  // const [indexPageMsg , setIndexPageMsg ] = useState(langSignDialog.msgPleaseWait);

  const pageMsg = status === 'signed' ?
    langSignDialog.titleGenerated + ' ' + langSignDialogEn.titleGenerated
    : langSignDialog.msgPleaseWait + ' ' + langSignDialogEn.msgPleaseWait;

  const verifyTokenAndStatus = () => {
    dispatch({ type: 'ClientSign.VerifyTokenAndStatusRequested', payload: { token: token } });
    // clientSignApi.verifyToken(token ?? '').then(async result => {
    //   if (result.data) {
    //     if(!result.data.isValidToken){
    //       window.location.href = PUBLIC_URL + "/not-found";
    //     }else{
    //       if (result.data.formStatus === 'S'){
    //         setIndexPageMsg(langSignDialog.titleGenerated);
    //       }else{
    //         setStatus('pendingContactVerification');
    //       }
    //     }
    //   } else {
    //     dispatch({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgNetworkError, severity: 'error' } });
    //   }
    // });
  }

  useEffect(() => {
    if (status === 'index') {
      verifyTokenAndStatus();
    }
  }, []);


  const handleClientSign = (signs: { [key: string]: string }) => {
    // dispatch({ type: 'ClientSign.confirmSignRequested' , payload: {token: token, signature: signs['clientSign'] }});
    // setStatus('generating');
    // dispatch({ type: 'Layout.MaskPresentRequested' });
    // clientSignApi.confirmSign(token ?? '', signs['clientSign']).then(async result => {
    //   if (result.data) {
    //     dispatch({ type: 'Layout.MaskDismissRequested' });
    //     setSignedFormUrl(result.data.filename);
    //     setStatus('success');
    //     setFormStatus(result.data.formStatus);
    //   } else {
    //     setStatus('signing');
    //     if (result.error === 'ERR_FAILED_CLIENT_SIGN') {
    //       dispatch({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgFailedClientSignError, severity: 'error' } });
    //     }
    //   }
    //   dispatch({ type: 'Layout.MaskDismissRequested' });
    // });
  }




  const handleVerifyContact = () => {
    dispatch({ type: 'ClientSign.checkVerificationContactRequested', payload: { token: token, clientVerificationContact: clientContactform.values.clientVerificationContact } });
    // setStatus('verifyingContact');
    // dispatch({ type: 'Layout.MaskPresentRequested' }); 
    // clientSignApi.checkVerificationContact(token ?? '', clientVerificationform.values.clientVerificationContact).then(async result => {
    //   if (result.data) {
    //     dispatch({ type: 'Layout.MaskDismissRequested' });
    //     if (result.data.match){
    //       setStatus('signing');
    //     }else{
    //       setStatus('pendingContactVerification');
    //       dispatch({ type: 'Layout.AlertMessageAdded', payload: { message: langSignDialog.msgInvalidVerificationContact, severity: 'error' } });
    //     }
    //   } else {
    //     setStatus('pendingContactVerification');
    //     dispatch({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgNetworkError, severity: 'error' } });
    //   }
    //   dispatch({ type: 'Layout.MaskDismissRequested' });
    // });
  }

  const handleViewFormPdf = () => {
    window.open(`${BASE_URL}/clientSign/viewSignedForm?token=${token}`);
  }


  const confirmationSignSpecs = [
    { title: <b>{langSignDialog.msgClientConfirm}</b>, name: 'clientSign' },
  ];

  const indexPanel = () => (
    <CardContent>
      {(status === 'signed') ?
        getMessageNode(`${langSignDialog.msgClientAlreadySigned}\n${langSignDialogEn.msgClientAlreadySigned}`, true)
        :
        <Typography>{pageMsg}</Typography>
      }
    </CardContent>
  )

  const linkExpiredPanel = () => (
    <CardContent>
      {getMessageNode(`${langSignDialog.msgLinkExpired}\n${langSignDialogEn.msgLinkExpired}`, true)}
    </CardContent>
  )

  const loadingPanel = () => <Grid
    item
    md={12}
    sm={12}
    xs={12}
    style={{ display: 'flex', justifyContent: 'center' }}
  ><CardHeader
      avatar={<CircularProgress />}
      style={{ whiteSpace: 'pre-line' }}
      title={langSignDialog.msgGenerating + '\n' + langSignDialogEn.msgGenerating}
    /></Grid>;

  const verifyingPanel = () => <CardHeader
    avatar={<CircularProgress />}
    style={{ whiteSpace: 'pre-line' }}
    title={langSignDialog.msgVerifying + '\n' + langSignDialogEn.msgVerifying}
  />;

  const getMessageNode = (content: string, isWarning = false) => {
    return (<Typography className={classes.message}
      style={{ color: isWarning ? warningWord : successWord, backgroundColor: isWarning ? warningBackground : successBackground }}>
      {content}
    </Typography>);
  }

  const clientContactform = useForm<any>({},
    {
      validations: [
        (values) => !isNonEmpty(values.clientVerificationContact) ? [['clientVerificationContact', langSignDialog.msgPleaseInput + ' ' + langSignDialogEn.msgPleaseInput]] : null,
      ]
    })

  const clientIdentityDocNoform = useForm<any>({},
    {
      validations: [
        (values) => !isNonEmpty(values.clientIdentificationDocNo) ? [['clientIdentificationDocNo', langSignDialog.msgPleaseInput + ' ' + langSignDialogEn.msgPleaseInput]] : null,
      ]
    })

  const inputVerifiactionContactPanel = () => (
    <Grid
      item container spacing={1}
      md={12}
      sm={12}
      xs={12}
      style={{ display: 'flex', justifyContent: 'center' }}
    >
      <Grid
        item container spacing={1}
        md={12}
        sm={12}
        xs={12}
        className={classes.inputSectionWidth}
      // style={{display: 'flex', justifyContent: 'center'}}
      >

        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          style={{ marginTop: 5, marginBottom: 5 }}
        >
          <Typography>{langSignDialog.msgInputVerificationContact}</Typography>
          <Typography>{langSignDialogEn.msgInputVerificationContact}</Typography>
        </Grid>
        <Grid
          item container spacing={1}
          md={12}
          sm={12}
          xs={12}
          style={{ marginTop: 5, marginBottom: 5 }}
        >
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
          >
            <TextField
              fullWidth
              margin="dense"
              required
              variant="outlined"
              {...clientContactform.bind('clientVerificationContact')}
            />
          </Grid>
        </Grid>
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              if (!clientContactform.validate()) {
                return;
              }
              handleVerifyContact();
              dispatch({ type: 'ClientSign.UpdateMessage', payload: '' })
            }}
          >
            {langSignDialog.actionVerifyContact}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );


  const inputIdentityDocNoPanel = () => (
    <Grid
      item container spacing={1}
      md={12}
      sm={12}
      xs={12}
      style={{ display: 'flex', justifyContent: 'center' }}
    >
      <Grid
        item container spacing={1}
        md={12}
        sm={12}
        xs={12}
        className={classes.inputSectionWidth}
      >

        <Grid
          item
          md={12}
          sm={12}
          xs={12}
        >
          <Typography>{langSignDialog.captionIdentificationDocNo}</Typography>
          <Typography>{langSignDialogEn.captionIdentificationDocNo}</Typography>
        </Grid>
        <Grid
          item container spacing={1}
          md={12}
          sm={12}
          xs={12}
        >
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
          >
            <TextField
              fullWidth
              margin="dense"
              required
              variant="outlined"
              {...clientIdentityDocNoform.bind('clientIdentificationDocNo')}
              InputProps={{
                endAdornment: clientIdentityDocNoform.values.clientIdentificationDocNo && !checkIsHKID(clientIdentityDocNoform.values.clientIdentificationDocNo ?? '') ?
                  <InputAdornment position="end">
                    <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{`${langSignDialog.msgNonHKID}\n${langSignDialogEn.msgNonHKID}`}</span>} aria-label='hot-pick-expiry'>
                      <IconButton
                        style={{ color: '#FFBF00' }}
                        aria-label="hkid-indicator"
                        edge="end"
                      >
                        <ErrorIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment> : null
              }}
            />
            <FormHelperText>{`${langSignDialog.msgFormatForHKID}`}</FormHelperText>
            <FormHelperText>{`${langSignDialogEn.msgFormatForHKID}`}</FormHelperText>
          </Grid>
        </Grid>
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              if (!clientIdentityDocNoform.validate()) {
                return;
              }
              dispatch({ type: 'ClientSign.UpdateMessage', payload: '' })
              dispatch({ type: 'ClientSign.UpdateStage', payload: 'signing' })
            }}
          >
            {lang.actionContinue + ' ' + langEn.actionContinue}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  const successMsg = isNonEmpty(signedFormUrl) ? (langSignDialog.titleGenerated + ' ' + langSignDialogEn.titleGenerated)
    : (langSignDialog.titleAlreadySigned + ' ' + langSignDialogEn.titleAlreadySigned); //for accessing link again after the form has been signed

  const clientEmailForm = useForm<{ email: string }>({},
    {
      validations: [
        (values) => !isNonEmpty(values.email) ? [['email', langSignDialog.msgPleaseInput + ' ' + langSignDialogEn.msgPleaseInput]] : null,
      ]
    })

  const buttonNameSendEmail = langSignDialog.actionSendEmail + ' ' + langSignDialogEn.actionSendEmail;
  const buttonNameSending = langSignDialog.msgSending + ' ' + langSignDialogEn.msgSending;
  const [sendEmailButtonName, setSendEmailButtonName] = useState(buttonNameSendEmail);

  const sendCopyToClient = () => {
    // dispatch({ type: 'Layout.MaskPresentRequested' });
    setSendEmailButtonName(buttonNameSending);
    clientSignApi.sendCopyToClient({ id: id, filename: signedFormUrl, clientEmail: clientEmailForm.values.email, formName: formName } as EmailFormDTO, token ?? '').then(async result => {
      if (result.data) {
        setSendEmailButtonName(buttonNameSendEmail);

        infoDialog.confirm(<Typography style={{ whiteSpace: 'pre-line' }}>{`${langSignDialog.msgSuccessSentCopyToClient}\n\n${langSignDialogEn.msgSuccessSentCopyToClient}`}</Typography>, '', lang.actionClose + ' ' + langEn.actionClose).then((confirmed: any) => {
          // dispatch({ type: 'Layout.MaskDismissRequested' });
          if (confirmed) {
          }
        })
      } else {
        if (result.error === 'ERR_FAILED_TO_SEND_EMAIL') {
          setSendEmailButtonName(buttonNameSendEmail);
          infoDialog.confirm(<Typography style={{ whiteSpace: 'pre-line' }}>{`${lang.msgFailedToSendEmailError}\n\n${langEn.msgFailedToSendEmailError}`}</Typography>, '', lang.actionClose + ' ' + langEn.actionClose).then((confirmed: any) => {
            // dispatch({ type: 'Layout.MaskDismissRequested' });
            if (confirmed) {
            }
          })
        }
      }
    });
  }

  const successPanel = () =>
    <Grid container md={12} sm={12} xs={12} spacing={1}
      style={{ display: 'flex', justifyContent: 'center' }}
    >
      {showEmailInputBox ?
        <Grid
          item container spacing={1}
          md={12}
          sm={12}
          xs={12}
          style={{ display: 'flex', alignItems: 'center', paddingTop: '15px', justifyContent: 'center' }}
        >
          <Grid
            item
            md={4}
            sm={5}
            xs={12}
          >
            <TextField
              fullWidth
              margin="dense"
              required
              variant="outlined"
              label={`${langSignDialog.captionEmail} ${langSignDialogEn.captionEmail}`}
              {...clientEmailForm.bind('email')}
            />
          </Grid>

          {/* ----------  send copy to client ----------- */}
          <Grid
            item
            // md={4}
            // sm={4}
            // xs={12}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={() => {
                if (!clientEmailForm.validate()) {
                  return;
                }
                sendCopyToClient();
              }}
            >
              {sendEmailButtonName}
            </Button>
          </Grid>
        </Grid>
        : null}
    </Grid>
    ;


  const signaturePanel = () => <div>
    <div style={{ display: 'flex' }}>
      <CardHeader
        subheader={<span style={{ whiteSpace: 'pre-line' }}>{langSignDialog.msgSigningTipsShort + '\n' + langSignDialogEn.msgSigningTipsShort}</span>}
        title={<span style={{ whiteSpace: 'pre-line' }}>{format(langSignDialog.msgSignAreaTitle, multiLang('zh_HK', clientName, clientNameEn)) + '\n' + format(langSignDialogEn.msgSignAreaTitle, multiLang('en', clientName, clientNameEn))}</span>}
      />
      <div style={{ flexGrow: 2 }} />
    </div>
    <div style={{
      position: 'relative',
      width: currentWindowWidth * 0.9,
      height: currentWindowWidth * 0.9 * 9 / 16,
    }}>
      <div style={{ display: 'flex', position: 'absolute', right: '1%', top: '1%' }}>

        <Button
          color="primary"
          variant="contained"
          onClick={onSignature}
        // onClick={()=>{
        //    handleAgentConfirmSign();
        // }}
        >
          {lang.actionConfirm + ' ' + langEn.actionConfirm}
        </Button>
        {/* ------ clear button  ------*/}
        <Button
          className={classes.signAreaActionButton}
          color="primary"
          variant="outlined"
          onClick={() => { signaturePadBinding?.clear(); setCurrentSignBlob(null) }}
        >
          {langSignDialog.actionClear + ' ' + langSignDialogEn.actionClear}
        </Button>
      </div>
      {/* <canvas
        style={{ background: '#ffffbb' }}
        key={`sig-canvas-client-sign`}
        {...signaturePadBinding?.canvas()}
        // width={currentWindowWidth * 0.9}
        // height={currentWindowWidth * 0.9 * 9 / 16}
      /> */}
      <SignaturePadCanvas
        // onSignature={onSignature}
        onChange={(blobUrl) => setCurrentSignBlob(blobUrl)}
        signaturePadBindingRef={signaturePadBindingRef}
        currentWindowWidth={currentWindowWidth}
      />
    </div>
  </div>;

  // const canvasRef = useRef<HTMLCanvasElement>(null);
  // const signaturePadRef = useRef<SignaturePad | null>(null);
  // useLayoutEffect(() => {
  //   signaturePadRef.current?.clear();
  // }, [ signaturePadRef.current ]);

  const signaturePadBindingRef = useRef<ReturnType<typeof useSignaturePad> | null>(null);
  const signaturePadBinding = signaturePadBindingRef.current;
  const [currentSignBlob, setCurrentSignBlob] = useState<string | null>(null);

  const onSignature = async () => {

    if (!currentSignBlob) {
      return;
    }

    dispatch({ type: 'ClientSign.confirmSignRequested', payload: { token: token, signature: currentSignBlob, hasHKID: formName !== 'form1', clientIdentificationDocNo: clientIdentityDocNoform.values.clientIdentificationDocNo ?? '', isHKID: checkIsHKID(clientIdentityDocNoform.values.clientIdentificationDocNo ?? '') ? 'true' : 'false' } });

  };

  return (
    <Dialog
      keepMounted
      open={true}
      fullScreen
      // onClose={handleClose}
      aria-labelledby="client-sign-dialog"
      aria-describedby="client-sign-form"
    >
      {infoDialog.render()}
      <AppBar position="static" style={{ background: '#fff', color: '#000' }}>
        <Toolbar>
          <Typography className={classes.brand}>
            <img src="images/logo-max.png" style={{ height: 40, marginRight: 16 }} />

          </Typography>
          <Typography >
            {'客戶表格簽署系統 Client Form Signing System'}
          </Typography>
        </Toolbar>
      </AppBar>

      <DialogContent>
        <Grid
          item container spacing={1}
          md={12}
          sm={12}
          xs={12}
        >
          {/* --------------- stepper ------------- */}
          {
            !(status === 'linkExpired' || status === 'index' || status === 'signed') ?
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
              >
                <FormSigningStepper steps={steps} activeStep={activeStep} />

              </Grid>
              : null
          }
          {/* --------------- message ------------- */}
          {isNonEmpty(message) && status !== 'success' &&
            <Grid container spacing={1}
              item
              md={12}
              sm={12}
              xs={12}
            >
              <Grid container spacing={1}
                item
                md={12}
                sm={12}
                xs={12}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                {getMessageNode(message ?? '')}
              </Grid>
            </Grid>
          }
          {/* --------------- form info ------------- */}
          {(status === 'signing' || status === 'success') &&
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              style={{ paddingTop: '10px', display: 'flex', justifyContent: 'center' }}
            >
              <FormSigningInfo
                {...clientSignState}
                showEn={true}
                noteForDocument={status === 'success' ?
                  `${langSignDialog.captionClientSigned} ${langSignDialogEn.captionClientSigned}` :
                  `${langSignDialog.captionAgentSigned} ${langSignDialogEn.captionAgentSigned}`
                }
                documentUrl={signedFormUrl ? `${BASE_URL}/files/${signedFormUrl}` : `${BASE_URL}/clientSign/viewSignedForm?token=${token}`}
              />

            </Grid>
          }
          {/* --------------- message (after successful signing) ------------- */}
          {isNonEmpty(message) && status === 'success' &&
            <Grid container spacing={1}
              item
              md={12}
              sm={12}
              xs={12}
            >
              <Grid container spacing={1}
                item
                md={12}
                sm={12}
                xs={12}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                {getMessageNode(message ?? '')}
              </Grid>
            </Grid>
          }
          {/* --------------- main panel ------------- */}
          <Grid
            item container spacing={1}
            md={12}
            sm={12}
            xs={12}
            style={{ display: 'flex', justifyContent: 'center' }}
          >

            {/* {{
              signing: signaturePanel,
              generating: loadingPanel,
              success: successPanel,
              pendingContactVerification: pendingVerifiactionPanel,
              verifyingContact: verifyingPanel,
              index: indexPanel
            }[status]()} */}
            {status === 'signing' ? signaturePanel() : null}
            {status === 'generating' ? loadingPanel() : null}
            {status === 'success' ? successPanel() : null}
            {status === 'pendingContactVerification' ? inputVerifiactionContactPanel() : null}
            {status === 'verifyingContact' ? verifyingPanel() : null}
            {status === 'inputIdentityDocNo' ? inputIdentityDocNoPanel() : null}
            {status === 'index' || status === 'signed' ? indexPanel() : null}
            {status === 'linkExpired' ? linkExpiredPanel() : null}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ClientSignPage;
