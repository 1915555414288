import { Button as MuiButton, Card, CardContent, CardHeader, Divider } from '@material-ui/core';
import { tooltipHoc } from 'common/ui';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
// import _mockData from 'views/PropertyList/data';
// import clsx from 'clsx';
import { IRootState, PASDispatch } from 'reducers';
import { PropertiesTable } from 'views/PropertyList/components';

const Button = tooltipHoc(MuiButton);

// const mockData = _mockData.filter(n => !!n.status);

// const useStyles = makeStyles((theme) => ({
//   active: {
//     color: theme.palette.common.white,
//     background: theme.palette.primary.dark,
//     '&:hover': {
//       background: theme.palette.primary.dark,
//     }
//   },
// }));

const Wishlist = ({ formSigned, setFormSigned, fileForm, initialStockCartFilterOption, unsaved, onSave }: any) => {
  // const [ formSigned, setFormSigned ] = useState(false);
  // const theme = useTheme();
  // const classes = useStyles();
  const dispatch = useDispatch() as PASDispatch;

  const { cid }: any = useParams();
  const properties = useSelector((state: IRootState) => state.clients.stockCartList);
  const { totalCartPages, totalCartElements } = useSelector((state: IRootState) => state.clients);

  const [page, setPage] = useState(0);

  const [limit, setLimit] = useState(10);
  const handleRowsCount = (count: number) => {
    setLimit(count);
  }

  const [stockCartFilterOption, setStockCartFilterOption] = useState<string | undefined>(initialStockCartFilterOption);
  const handleDeleteStockCart = (id: string[]) => {
    if (id !== null && id.length > 0) {
      dispatch({
        type: 'PropertyMatching.RemoveStockCartRequested', payload: {
          cid: cid ?? '', pid: id ?? [], propertyStatus: stockCartFilterOption,
        }
      })
    }
  }

  const handleStockCartAfterSign = (sign4: any[], sign6: any[], status: string, fullpropertiesId: any[], fullproperties: any[]) => {
    var common4 = fullpropertiesId.filter(e => sign4.includes(e));
    var common6 = fullpropertiesId.filter(e => sign6.includes(e))
    if (status === 'RENT') {
      return fullproperties.map(e => e)
    } else {
      return fullproperties.map(e => e)
    }
  }

  useEffect(() => {
    dispatch({
      type: 'StockCart.FetchRequested', cid: cid ?? '', payload: {
        page: page, size: limit, sort: {}
      }, propertyStatus: initialStockCartFilterOption ?? undefined
    })
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  return <Card>
    <CardHeader
      title={<div style={{ display: 'flex', alignItems: 'center' }}>
        {/* 睇樓清單 */}
        {/* <TextField select value={"1"} variant="standard" style={{ marginLeft: 10 }}>
          <MenuItem value="1">心水盤</MenuItem>
          <MenuItem value="2">12/11/2019 (睇買)</MenuItem>
          <MenuItem value="3">11/11/2019 (睇租)</MenuItem>
        </TextField> */}
        {/* <ButtonGroup variant="outlined">
          <Button className={clsx({ [classes.active]: !formSigned })}>心水盤</Button>
          {formSigned ? <Button className={clsx({ [classes.active]: formSigned })}>睇買(12/18/2019)</Button> : null}
          <Button className={clsx({ [classes.active]: false })}>睇買(11/11/2019)</Button>
        </ButtonGroup> */}
      </div>}
      action={<div>
        {/* <Typography style={{ marginRight: 10, display: 'inline', color: formSigned ? (theme.palette as any).success.dark : theme.palette.text.primary }}>狀態: {formSigned ? '已簽睇樓紙' : '未簽睇樓紙'}</Typography> */}
        {!formSigned ?
          null :
          <Button variant="outlined" color="secondary" style={{ marginRight: 10 }}>下載已簽睇樓紙</Button>
        }
        {/* <Button disabled tooltip={'Coming Soon'} variant="outlined" color="secondary" style={{ marginRight: 10 }}>生成樓盤概要</Button> */}
      </div>}
    />
    <Divider />
    <CardContent>
      <PropertiesTable
        totalPages={totalCartPages ?? 1}
        totalElements={totalCartElements ?? 0}
        limit={limit}
        page={page}
        setPage={(page) => {
          setPage(page);
          dispatch({
            type: 'StockCart.FetchRequested', cid: cid ?? '', payload: {
              page: page, size: limit, sort: {}
            }, propertyStatus: stockCartFilterOption
          });
        }}
        rowsCountOptions={[10]}
        handleRowsCount={handleRowsCount}
        className=""
        handleDeleteStockCart={handleDeleteStockCart}
        properties={handleStockCartAfterSign(fileForm?.values?.FORM_4?.map((m: any) => m.formProperty.map((m: any) => m.propertyStockId)).flat() ?? ['']
          , fileForm?.values?.FORM_6?.map((m: any) => m.formProperty.map((m: any) => m.propertyStockId)).flat() ?? ['']
          , stockCartFilterOption ?? '', properties?.map((e: any) => e.id) ?? [''], properties ?? ['']) ?? []}
        // sortOrders={sortOrders}
        wishlistVariant
        formSigned={formSigned}
        setFormSigned={setFormSigned}
        cid={cid}
        clientuploadform4or6={true}
        fileForm={fileForm}
        unsaved={unsaved}
        onSave={onSave}
        initialStockCartFilterOption={initialStockCartFilterOption}
        stockCartFilterOption={stockCartFilterOption}
        setStockCartFilterOption={setStockCartFilterOption}
        isWishListOrSellerStock={true}
      />
    </CardContent>
  </Card>;
};

export default Wishlist;