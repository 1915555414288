import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';

const useStyles = makeStyles(theme => ({
  root: {},
  title: {
    paddingLeft: '20px'
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const NotificationsToolbar = (props: any) => {
  const { langNotification: lang } = useSelector((state: IRootState) => state.locale);
  const classes = useStyles();

  return (
    <div
      className={classes.root}
    >
      <div className={classes.row}>
        <Typography
          className={classes.title}
          variant="h2"
        >
          {lang.titleNotificationsCentre}
        </Typography>
      </div>
    </div>
  );
};

export default NotificationsToolbar;
