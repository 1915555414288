import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { TransitionProps } from '@material-ui/core/transitions';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useConfirmDialog } from 'common/ConfirmDialog';
import { AgreementClientDTO, PropertyStockDTO, SalePurchaseAgreementDTO, TenancyAgreementDTO, UserDTO } from 'common/dto';
import NavigationBlockerDialog from 'common/elements/NavigationBlockerDialog';
import { FormPresenter, useAppBarHeight, useForm } from 'common/hooks';
// import { Form3Validations, Form5Validations, Form2Validations, Form1Validations } from './form-validations';
import { autoFillCompanyInfo, filterUndefinedFields, genAddress, multiLang } from 'common/utils';
import deepEqual from 'deep-equal';
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState, PASDispatch, PropertyActions } from 'reducers';
import { SalePaAccessControls, TenancyPaAccessControls } from '../provisionalAgreement-access-controls';
import { SalePurchaseAgreementValidations, TenacnyAgreementValidations } from '../provisionalAgreement-validations';
import Documents from './Documents';
import SalePurchaseDialog, { salePurchaseInitial } from './SalePurchaseDialog';
import TenancyDialog, { tenancyInitial } from './TenancyDialog';

const EMPTY = {};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    dialogTitle: {
      marginLeft: theme.spacing(2),
      flex: 1,
      color: 'white',
      fontWeight: 'bold',
    },
    dialogRoot: {
      '& .MuiDialog-paper': {
        overflowX: 'hidden',
      }
    },
  }),
);


const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


interface DialogProps {
  open: boolean;
  handleClose: () => void;
  handleClickOpen: () => void;
  propertyStockId: string;
  selectedAgreementNo: number;
  form: FormPresenter<PropertyStockDTO>;
  isLeadAgent: boolean;
  canEditPa: boolean;
  fileForm: any;

  salePaAccessControls: SalePaAccessControls;
  tenanctPsAccessControls: TenancyPaAccessControls;
}

const ProvisionalAgreementDialog = ({ selectedAgreementNo, propertyStockId, form, open, handleClickOpen,
  handleClose, canEditPa, fileForm, salePaAccessControls, tenanctPsAccessControls }: DialogProps
) => {
  const classes = useStyles();
  const dispatch = useDispatch() as PASDispatch;
  const { iosStatusBarHeight } = useSelector((state: IRootState) => state.layout);
  const appBarHeight = useAppBarHeight();

  const {
    lang, langPropertyStock, districtHkiOptions, districtKltOptions, districtNtOptions, locale, langSalePurchaseAgreement, langTenancyAgreement
  } = useSelector((state: IRootState) => state.locale);

  const selectPaBtnMenuList = [langPropertyStock.titleProvisionalAgreement, langPropertyStock.titleSaleAndPurchase, langPropertyStock.titleTenancy];

  const { uid, chineseName, englishName, licenseNumber } = useSelector((state: IRootState) => state.login);

  //buyer agent
  const currentUid = useSelector((state: IRootState) => state.login.uid);
  const userDto = useSelector((state: IRootState) => state.user.fetchedDetails[currentUid!]) ?? EMPTY as Partial<UserDTO>;
  const { isBuyerAgent: isBuyerAgentCompanyStock } = userDto; //for company stock only

  //address
  const districtOptions = {
    ...districtHkiOptions,
    ...districtKltOptions,
    ...districtNtOptions,
  };


  // Sale Purchase & Access Control //
  const currentSalePurchaseAgreement = useSelector((state: IRootState) => state.property.salePurchaseAgreement ?? salePurchaseInitial);
  const salePurchaseAgreement = useForm<SalePurchaseAgreementDTO>({}, { validations: SalePurchaseAgreementValidations(langSalePurchaseAgreement), scrollToErrorOnValidate: true, dataKeyPrefix: 'salepurchase' });
  // const salePaAccessControls = useSelector(selectSalePaAccessControls(isLeadAgent, isCompanyStock, currentUid ?? ''));


  // Tenancy & Access Control //
  const currentTenancyAgreement = useSelector((state: IRootState) => state.property.tenancyAgreement ?? tenancyInitial);
  const tenancyAgreement = useForm<TenancyAgreementDTO>({}, { validations: TenacnyAgreementValidations(langTenancyAgreement), scrollToErrorOnValidate: true, dataKeyPrefix: 'tenancy' });
  // const tenanctPsAccessControls = useSelector(selectTenancyPaAccessControls(isLeadAgent, isCompanyStock, currentUid ?? ''));

  //company setting
  const companyInfoSystemSetting = useSelector((state: IRootState) =>
    state.systemSettings?.Company?.AGENCY_COMPANY_INFO) ?? {};





  useEffect(() => {
    if (form.values.propertyNo) {
      dispatch({ type: 'Property.FetchByPropertyNoRequested', payload: { propertyNo: form.values.propertyNo ?? '' } });
    }
  }, [currentSalePurchaseAgreement, currentTenancyAgreement])

  //auto-filled fields
  const salePurchaseAutoFilledFields = () => {
    return {
      sellerAgentType: 'I',
      sellerAgentUserId: uid,
      sellerAgentName: multiLang(locale, chineseName, englishName),
      sellerAgentNameZh: chineseName,
      sellerAgentNameEn: englishName,
      sellerAgentLicenseNumber: licenseNumber,
      buyerAgentType: 'I',
      agentFor: 'BOTH',
      premises: genAddress(locale,
        form.values.unit?.join(',') ?? '',
        form.values.floor?.join(',') ?? '',
        form.values.block ?? '', form.values.blockEn ?? '',
        form.values.building ?? '', form.values.buildingEn ?? '',
        form.values.street ?? '', form.values.streetEn ?? '',
        districtOptions[form.values.district ?? '']),
      vendors: [
        {
          agentUserId: Number(currentUid),
          type: 'SELLER',
          name: form.values.owner ?? '',
          identityType: '', identityNumber: '', address: '', identityFilename: null, idCardImage: []
        } as AgreementClientDTO,
      ],
    };
  };

  const tenancyAutoFilledFields = () => {
    return {
      landlordAgentType: 'I',
      landlordAgentUserId: uid,
      landlordAgentName: multiLang(locale, chineseName, englishName),
      landlordAgentNameZh: chineseName,
      landlordAgentNameEn: englishName,
      landlordAgentLicenseNumber: licenseNumber,
      tenancyAgentType: 'I',
      agentFor: 'BOTH',
      premises: genAddress(locale,
        form.values.unit?.join(',') ?? '',
        form.values.floor?.join(',') ?? '',
        form.values.block ?? '', form.values.blockEn ?? '',
        form.values.building ?? '', form.values.buildingEn ?? '',
        form.values.street ?? '', form.values.streetEn ?? '',
        districtOptions[form.values.district ?? '']),
      landlords: [
        {
          agentUserId: Number(currentUid),
          type: 'LANDLORD',
          name: form.values.owner ?? '',
          identityType: '', identityNumber: '', address: '', identityFilename: null, idCardImage: []
        } as AgreementClientDTO,
      ],
    };
  }

  const [initialSalePurchaseAgreement, setInitialSalePurchaseAgreement] = useState<any>({});
  const [initialTenancyAgreement, setInitialTenancyAgreement] = useState<any>({});
  const [initialFileForm, setInitialFileForm] = useState<any>({});

  useEffect(() => {
    if (!open) {
      return;
    }
    let initialForm: Partial<SalePurchaseAgreementDTO> = { ...currentSalePurchaseAgreement };
    salePurchaseAgreement.setValues({ ...currentSalePurchaseAgreement, agentFor: currentSalePurchaseAgreement.sellerAgentType === 'I' ? 'BOTH' : 'PURCHASER_ONLY', ...autoFillCompanyInfo('salePurchaseAgreement', currentSalePurchaseAgreement.agreementLocale ?? locale, companyInfoSystemSetting), });
    if (currentSalePurchaseAgreement.id == null) {
      initialForm = {
        ...salePurchaseInitial,
        ...salePurchaseAutoFilledFields(),
        ...autoFillCompanyInfo('salePurchaseAgreement', locale, companyInfoSystemSetting),
        agreementLocale: locale,
      }
      salePurchaseAgreement.setValues({ ...initialForm });
      setInitialSalePurchaseAgreement({ ...initialForm });
      setInitialFileForm({ ...fileForm.values });
      // salePurchaseAgreement.setValues({
      //   ...salePurchaseInitial,
      //   ...salePurchaseAutoFilledFields(),
      //   ...autoFillCompanyInfo('salePurchaseAgreement', locale, companyInfoSystemSetting ),
      //   agreementLocale: locale,
      // });
    } else {
      setInitialSalePurchaseAgreement({ ...initialForm, ...autoFillCompanyInfo('salePurchaseAgreement', currentSalePurchaseAgreement.agreementLocale ?? locale, companyInfoSystemSetting) });
      setInitialFileForm({
        ...fileForm.values,
        SUPPLEMENTAL_DOCUMENT: currentSalePurchaseAgreement.supplementalDoc
      })
    }
  }, [currentSalePurchaseAgreement, uid ?? EMPTY, locale, open])

  useEffect(() => {
    if (!open) {
      return;
    }
    let initialForm: Partial<TenancyAgreementDTO> = { ...currentTenancyAgreement };
    tenancyAgreement.setValues({ ...currentTenancyAgreement, agentFor: currentTenancyAgreement.landlordAgentType === 'I' ? 'BOTH' : 'LANDLORD_ONLY', ...autoFillCompanyInfo('tenancyAgreement', currentTenancyAgreement.agreementLocale ?? locale, companyInfoSystemSetting) });
    if (currentTenancyAgreement.id == null) {
      initialForm = {
        ...tenancyInitial,
        ...tenancyAutoFilledFields(),
        ...autoFillCompanyInfo('tenancyAgreement', locale, companyInfoSystemSetting),
        agreementLocale: locale,
      }

      tenancyAgreement.setValues({ ...initialForm });
      setInitialTenancyAgreement({ ...initialForm });
      setInitialFileForm({ ...fileForm.values });
      // tenancyAgreement.setValues({
      //   ...tenancyInitial, 
      //   ...tenancyAutoFilledFields(),
      //   ...autoFillCompanyInfo('tenancyAgreement', locale, companyInfoSystemSetting ),
      //   agreementLocale: locale,
      // });
    } else {
      setInitialTenancyAgreement({ ...initialForm, ...autoFillCompanyInfo('tenancyAgreement', currentTenancyAgreement.agreementLocale ?? locale, companyInfoSystemSetting) });
      setInitialFileForm({
        ...fileForm.values,
        SUPPLEMENTAL_DOCUMENT: currentTenancyAgreement.supplementalDoc
      })
    }
  }, [currentTenancyAgreement, uid ?? EMPTY, locale, open])

  //remove supplemental doc field in Provsional Agreement
  const filterSuppDoc = (tofilter: any) => {
    return (Object.keys(tofilter)
      .filter((key) => key !== 'SUPPLEMENTAL_DOCUMENT' && key !== 'supplementalDoc')
      .reduce((obj, key) => {
        return Object.assign(obj, {
          [key]: tofilter[key]
        });
      }, {}))
  }

  //removed supplemental doc in values to be use in unsavedStates
  const filteredFileForm = useMemo(() => filterSuppDoc(fileForm.values), [fileForm.values]);
  const filteredInitialFileForm = useMemo(() => filterSuppDoc(initialFileForm), [initialFileForm]);
  const filteredSalesPurchaseAgreementValues = useMemo(() => filterSuppDoc(filterUndefinedFields(salePurchaseAgreement.values)), [salePurchaseAgreement.values]);
  const filteredInitialSalePurchaseAgreement = useMemo(() => filterSuppDoc(filterUndefinedFields(initialSalePurchaseAgreement)), [initialSalePurchaseAgreement]);
  const filteredTenancyAgreementValues = useMemo(() => filterSuppDoc(filterUndefinedFields(tenancyAgreement.values)), [tenancyAgreement.values]);
  const filteredInitialTenancyAgreement = useMemo(() => filterSuppDoc(filterUndefinedFields(initialTenancyAgreement)), [initialTenancyAgreement]);



  const unsavedStates: { [index: number]: boolean } = {
    1: selectedAgreementNo !== 1 ? false :
      !deepEqual([filteredSalesPurchaseAgreementValues, filteredFileForm], [filteredInitialSalePurchaseAgreement, filteredInitialFileForm], { strict: true }),
    2: selectedAgreementNo !== 2 ? false : !deepEqual([filteredTenancyAgreementValues, filteredFileForm], [filteredInitialTenancyAgreement, filteredInitialFileForm], { strict: true }),
  }

  // 
  // 
  // 
  // 
  const isValidAgreement = () => {
    switch (selectedAgreementNo) {
      case 1: return (salePurchaseAgreement.validate()); break;
      case 2: return (tenancyAgreement.validate()); break;
      default:
        return false;
    }
  }

  const handleSave = (extraAction?: 'GENERATE_PDF' | 'SIGN_PDF') => {
    switch (selectedAgreementNo) {
      case 1: saveSalePurchaseAgreement(extraAction); break;
      case 2: saveTenancyAgreement(extraAction); break;
      default:
        return;
    }
  }

  const confirmDialog = useConfirmDialog();

  const saveSalePurchaseAgreement = async (extraAction?: 'GENERATE_PDF' | 'SIGN_PDF') => {
    const hasSignNowDocument = salePurchaseAgreement.values.hasSignNowDocument ?? false;
    const confirmed = extraAction == 'SIGN_PDF' && hasSignNowDocument ? await confirmDialog.confirm(langPropertyStock.msgConfirmPARegenerate, langPropertyStock.actionPARegenerate, langPropertyStock.actionPAContinueExisting) : undefined;
    const regenerate = confirmed || !hasSignNowDocument;

    if (salePurchaseAgreement.values.id != undefined && salePurchaseAgreement.values.id != null) {
      dispatch({
        type: 'SalePurchaseAgreement.UpdateRequested', payload: {
          ...salePurchaseAgreement.values,
          supplementalDoc: fileForm.values['SUPPLEMENTAL_DOCUMENT'],
          extraAction,
          regenerate,
        }
      } as PropertyActions);
    } else {
      dispatch({
        type: 'SalePurchaseAgreement.CreationRequested', payload: {
          ...salePurchaseAgreement.values,
          supplementalDoc: fileForm.values['SUPPLEMENTAL_DOCUMENT'],
          propertyStockId: propertyStockId,
          extraAction,
          regenerate,
        }
      } as PropertyActions);
    }
  };

  const saveTenancyAgreement = async (extraAction?: 'GENERATE_PDF' | 'SIGN_PDF') => {
    const hasSignNowDocument = tenancyAgreement.values.hasSignNowDocument ?? false;
    const confirmed = extraAction == 'SIGN_PDF' && hasSignNowDocument ? await confirmDialog.confirm(langPropertyStock.msgConfirmPARegenerate, langPropertyStock.actionPARegenerate, langPropertyStock.actionPAContinueExisting) : undefined;
    const regenerate = confirmed || !hasSignNowDocument;

    if (tenancyAgreement.values.id != undefined && tenancyAgreement.values.id != null) {
      dispatch({
        type: 'TenancyAgreement.UpdateRequested', payload: {
          ...tenancyAgreement.values,
          supplementalDoc: fileForm.values['SUPPLEMENTAL_DOCUMENT'],
          extraAction,
          regenerate,
        }
      } as PropertyActions);
    } else {
      dispatch({
        type: 'TenancyAgreement.CreationRequested', payload: {
          ...tenancyAgreement.values,
          supplementalDoc: fileForm.values['SUPPLEMENTAL_DOCUMENT'],
          propertyStockId: propertyStockId,
          extraAction,
          regenerate,
        }
      } as PropertyActions);
    }
  };

  const uploadDocOptions: { [key: string]: string } = {
    DUMMY: lang.actionUpload,
    SUPPLEMENTAL_DOCUMENT: langPropertyStock.provisionalAgreementSuppDoc,
  };

  return (
    <div>
      <NavigationBlockerDialog matchesUrl={`/properties-edit/${form.values?.propertyNo}/agreement-form-view`} unsaved={open ? unsavedStates[selectedAgreementNo] : false} goBack />
      <Button style={{ display: 'none' }} variant="outlined" color="primary" onClick={handleClickOpen}>
        Open full-screen dialog
      </Button>
      <Dialog style={{ paddingTop: appBarHeight }} className={classes.dialogRoot} fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar style={{ position: 'fixed', top: 0, paddingTop: iosStatusBarHeight }} className={classes.appBar}>
          <Toolbar >
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.dialogTitle}>
              {selectPaBtnMenuList[selectedAgreementNo]}
            </Typography>
            <Button disabled={form.values.companyStock ? false : !((selectedAgreementNo == 1 && salePaAccessControls.canEditSalePaData) || (selectedAgreementNo == 2 && tenanctPsAccessControls.canEditTenancyPaData))}
              autoFocus color="inherit" style={{ color: unsavedStates[selectedAgreementNo] ? '#FFBF00' : 'inherit', fontWeight: unsavedStates[selectedAgreementNo] ? 'bold' : 'inherit' }}
              onClick={() => handleSave()}
            >
              {`${(selectedAgreementNo == 1 ? currentSalePurchaseAgreement.id : currentTenancyAgreement.id) ? lang.actionSaveForm : lang.actionAddForm} ${unsavedStates[selectedAgreementNo] ? '*' : ''}`}
            </Button>
            <Button
              disabled={form.values.companyStock ? !(isBuyerAgentCompanyStock ?? false) : false}
              autoFocus color="inherit" onClick={() => {
                if (isValidAgreement()) {
                  confirmDialog.confirm(<span>
                    {langPropertyStock.msgSignGenerate}
                    <a target="_blank" href="https://app.signnow.com/">https://app.signnow.com/</a>
                  </span>, lang.actionGenerate, lang.actionCancel).then(confirmed => {
                    if (confirmed) {
                      handleSave('GENERATE_PDF');
                    }
                  });
                }
              }}>
              {lang.actionGenerateAndSign}
            </Button>
            {/* <Button autoFocus color="inherit" onClick={() => {
              if (isValidAgreement()) {
                handleSave('SIGN_PDF');
              }
            }}>
              {lang.actionGenerateAndSign}
            </Button> */}
          </Toolbar>
        </AppBar>

        {confirmDialog.render()}

        <div>
          {selectedAgreementNo == 1 ? <SalePurchaseDialog
            propertyStockId={propertyStockId ?? ''} form={salePurchaseAgreement}
            accessControls={salePaAccessControls}
          /> : null}
          {selectedAgreementNo == 2 ? <TenancyDialog
            propertyStockId={propertyStockId ?? ''} form={tenancyAgreement}
            accessControls={tenanctPsAccessControls}
          /> : null}

        </div>
      </Dialog>
    </div>
  );
}

export default ProvisionalAgreementDialog;