import {
  Card, CardContent, CardHeader, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid as MuiGrid, InputLabel, makeStyles, MenuItem, Select, TextField, Typography
} from '@material-ui/core';
import { RoleDTO, RolePrivilegeMap } from 'common/dto';
import { FormPresenter } from 'common/hooks';
import { HandleFormHelperText, hideWhenHoc } from 'common/ui';
import { initFormKeysAndBooleanMap, isNonEmpty, limitTextFieldLength } from 'common/utils';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';


// #region Base UI

const Grid = hideWhenHoc(MuiGrid);

const useStyles = makeStyles(() => ({
  root: {},
  patchOutlineAutocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
      paddingLeft: 8,
    }
  },
  changePasswordDialogBody: {
    padding: 8,
  },
  resourceName: {
    fontWeight: 'bold',
  }
}));

// #endregion Base UI
const operationTypeList = ['CREATE', 'READ', 'UPDATE', 'DELETE', 'APPROVE'];

const EMPTY = {};



export interface RolePrivilegeDetailProps {
  roleForm: FormPresenter<RoleDTO>;
  rolePrivilegeForm: FormPresenter<RolePrivilegeMap>;
  roleName: string;
  creating?: boolean;
}

const RolePrivilegeDetail = ({ creating, rolePrivilegeForm, roleName, roleForm }: RolePrivilegeDetailProps) => {
  const { statusOptions, lang, langRolePrivilege } = useSelector((state: IRootState) => state.locale);
  const currentRolePrivilegeMap = useSelector((state: IRootState) => state.rolePrivilege.fetchedDetails[roleName]?.privilegeTable) ?? EMPTY as RolePrivilegeMap;

  const resourceList = useSelector((state: IRootState) => state.rolePrivilege?.resource ?? []);

  const classes = useStyles();

  const checkboxCheced = (resource: string, operation: string) => {
    return (rolePrivilegeForm.values[resource]?.[operation] ?? -1) > -1;
    // isNonEmpty(rolePrivilegeForm.values[resource]?.[operation]);
  }

  const [keyBooleanMap, setKeyBooleanMap] = useState(initFormKeysAndBooleanMap(roleForm));

  useEffect(() => {
    setKeyBooleanMap(initFormKeysAndBooleanMap(roleForm));
  }, [Object.keys(roleForm.values).length])

  const updateRolePrivilege = (resource: string, operation: string) => {
    // 
    // 
    const existingPrivilegeId = currentRolePrivilegeMap[resource]?.[operation];
    let updatedResourcePrivilege = {};

    //remove
    if (checkboxCheced(resource, operation)) {
      updatedResourcePrivilege = { ...rolePrivilegeForm.values[resource], [operation]: -1 };
    }
    //no change
    else if (isNonEmpty(existingPrivilegeId)) {
      updatedResourcePrivilege = { ...rolePrivilegeForm.values[resource], [operation]: existingPrivilegeId };
    }
    //add  
    else {
      updatedResourcePrivilege = { ...rolePrivilegeForm.values[resource], [operation]: 0 };
    }

    rolePrivilegeForm.updateValues(resource, updatedResourcePrivilege);
    // 
  }

  return (
    <Grid item md={12} xs={12} container spacing={1}>
      {/* ------------  role name, status and description --------------- */}
      <Grid item md={12} xs={12} container>
        <Card>
          <CardHeader title={langRolePrivilege.titleRoleBasicInfo} />
          <Divider />
          <CardContent>
            <Grid container spacing={1}>
              <Grid item md={6} xs={6}>
                <TextField
                  fullWidth
                  InputProps={{ disabled: !creating }}
                  label={langRolePrivilege.captionRoleName}
                  margin="dense"
                  // required
                  variant="outlined"
                  {...roleForm.bind('roleName')}
                  // value={roleForm.values.roleName ?? ''}
                  // onChange={(e)=>{
                  //   roleForm.updateValues('roleName', e.target.value?.toUpperCase());
                  // }}
                  // error={!!roleForm.hasError('roleName')}
                  // helperText={roleForm.hasError('roleName')}
                  onChange={(e) => {
                    limitTextFieldLength(e, 255, 'roleName', roleForm, keyBooleanMap, setKeyBooleanMap);
                  }}
                />
                <HandleFormHelperText
                  isError={keyBooleanMap.get('roleName')}
                  errorMessage={lang.textOverLimit}
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <FormControl variant="outlined" margin="dense" fullWidth>
                  <InputLabel variant="outlined" style={{ background: '#fff' }}>{langRolePrivilege.captionDataStatus}</InputLabel>
                  <Select
                    error={!!roleForm.hasError('dataStatus')}
                    {...roleForm.bind('dataStatus')}
                  >
                    {Object.keys(statusOptions).filter(v => v === 'I' || v === 'A').map(status => (
                      <MenuItem key={status} value={status}>
                        {statusOptions[status]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {roleForm.hasError('dataStatus') ? <FormHelperText error>{roleForm.hasError('dataStatus')}</FormHelperText> : null}
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label={langRolePrivilege.captionDescriptionEN}
                  margin="dense"
                  // required
                  variant="outlined"
                  {...roleForm.bind('descriptionEN')}
                  onChange={(e) => {
                    limitTextFieldLength(e, 255, 'descriptionEN', roleForm, keyBooleanMap, setKeyBooleanMap);
                  }}
                />
                <HandleFormHelperText
                  isError={keyBooleanMap.get('descriptionEN')}
                  errorMessage={lang.textOverLimit}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label={langRolePrivilege.captionDescriptionTC}
                  margin="dense"
                  // required
                  variant="outlined"
                  {...roleForm.bind('descriptionTC')}
                  onChange={(e) => {
                    limitTextFieldLength(e, 255, 'descriptionTC', roleForm, keyBooleanMap, setKeyBooleanMap);
                  }}
                />
                <HandleFormHelperText
                  isError={keyBooleanMap.get('descriptionTC')}
                  errorMessage={lang.textOverLimit}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label={langRolePrivilege.captionDescriptionSC}
                  margin="dense"
                  // required
                  variant="outlined"
                  {...roleForm.bind('descriptionSC')}
                  onChange={(e) => {
                    limitTextFieldLength(e, 255, 'descriptionSC', roleForm, keyBooleanMap, setKeyBooleanMap);
                  }}
                />
                <HandleFormHelperText
                  isError={keyBooleanMap.get('descriptionSC')}
                  errorMessage={lang.textOverLimit}
                />
              </Grid>
            </Grid>
          </CardContent>

        </Card>
      </Grid>
      {/* ------------  role privileges --------------- */}
      <Grid item md={12} xs={12} container>
        <Card style={{ minWidth: '100%' }}>
          <CardHeader title={langRolePrivilege.titlePrivilegeSetting} />
          <Divider />
          <CardContent >
            <Grid container spacing={1}>
              {/* <Grid item container md={12} xs={12} spacing={1}>
            <Typography style={{ fontWeight: 'bold' }}>{langUser.titleBasicInfo}</Typography>
          </Grid> */}
              <Grid item container md={12} xs={12} spacing={1}>

                {resourceList.map((resource, idx) => (
                  <Grid key={resource + '-grid-' + idx} item container md={12} xs={12} spacing={1}>
                    {/* -----  resourceName ------- */}
                    <Grid item md={12} xs={12}>
                      <Typography className={classes.resourceName}>{resource.name}</Typography>
                    </Grid>
                    {/* -----  description ------- */}
                    <Grid item md={4} xs={12}>
                      <Typography>{resource.description}</Typography>
                    </Grid>
                    {/* ----- allowed operations ----- */}
                    <Grid item md={8} xs={12}>
                      {operationTypeList.map((operation: string) =>
                        resource.allowedOperations.includes(operation) ?

                          <FormControl key={resource.name + '-' + operation + '-formControl'} component="fieldset" >
                            <FormGroup aria-label="add-to-alias-checkbox" row>
                              <FormControlLabel
                                control={<Checkbox color="primary"
                                  checked={checkboxCheced(resource.name, operation)}
                                  onClick={() => { updateRolePrivilege(resource.name, operation); }} />}
                                label={operation}
                                labelPlacement="end"
                              />
                            </FormGroup>
                          </FormControl>

                          : null
                      )}

                    </Grid>

                    <Grid item md={12} xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                ))}


              </Grid>

            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default RolePrivilegeDetail;
