import { ApiError, ApiResult } from "api";
import { rolePrivilegeApi } from "api/rolePrivilegeApi";
import { ResourceDTO, RoleDTO } from "common/dto";
import { IRootState, LayoutActions, RolePrivilegeActions } from "reducers";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { PASSagaContext } from 'sagas';
import { apiTaskWrapper } from "./saga-commons";


/////////////////////////////////////////////////////////////////////
///////////////////////// Role Privilege Fetch //////////////////////
/////////////////////////////////////////////////////////////////////


export function* watchRolePrivilegeFetchRequested(context: PASSagaContext) {
  yield takeEvery(
    'RolePrivilege.FetchRequested',
    apiTaskWrapper(requestRolePrivilege),
    context
  )
}

export function* requestRolePrivilege(context: PASSagaContext, action: Extract<RolePrivilegeActions, { type: 'RolePrivilege.FetchRequested' }>) {
  if (action.type != 'RolePrivilege.FetchRequested') {
    return;
  }
  const { roleId } = action.payload;

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  yield put<RolePrivilegeActions>({ type: 'Resource.FetchRequested' });

  const result: ApiResult<RoleDTO> = yield call(rolePrivilegeApi.getRoleAndPrivilege, roleId, token);

  if (!result.error) {
    yield put<RolePrivilegeActions>({ type: 'RolePrivilege.Loaded', payload: { roleId: roleId, detail: result.data! } });
  } else {
    throw ApiError.of(result.error, null, '/roles');
  }

}

/////////////////////////////////////////////////////////////////////
///////////////////////// Resource Fetch ////////////////////////////
/////////////////////////////////////////////////////////////////////


export function* watchResourceFetchRequested(context: PASSagaContext) {
  yield takeEvery(
    'Resource.FetchRequested',
    apiTaskWrapper(requestResource),
    context
  )
}

export function* requestResource(context: PASSagaContext, action: Extract<RolePrivilegeActions, { type: 'Resource.FetchRequested' }>) {
  if (action.type != 'Resource.FetchRequested') {
    return;
  }

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<ResourceDTO[]> = yield call(rolePrivilegeApi.getResource, token);

  if (!result.error) {
    yield put<RolePrivilegeActions>({ type: 'Resource.Loaded', payload: result.data! });
  } else {
    throw ApiError.of(result.error);
  }

}


/////////////////////////////////////////////////////////////////////
///////////////////////// Create Role Privilege  ////////////////////
/////////////////////////////////////////////////////////////////////


export function* watchRolePrivilegeCreationRequested(context: PASSagaContext) {
  yield takeEvery(
    'RolePrivilege.CreationRequested',
    apiTaskWrapper(requestCreateRolePrivilege),
    context
  )
}

export function* requestCreateRolePrivilege(context: PASSagaContext, action: Extract<RolePrivilegeActions, { type: 'RolePrivilege.CreationRequested' }>) {
  if (action.type != 'RolePrivilege.CreationRequested') {
    return;
  }

  const token: string = yield select((state: IRootState) => state.login.token ?? '');
  const { langRolePrivilege } = yield select((state: IRootState) => state.locale);

  const result: ApiResult<string> = yield call(rolePrivilegeApi.addRoleAndPrivilege, action.payload, token);

  if (!result.error) {
    yield call(context.browserHistory.replace as any, `/roles`);
  } else {
    if (result.error === 'ERR_DUPLICATED_ROLE_NAME') {
      yield put<LayoutActions>({ type: 'Layout.AlertMessageAdded', payload: { message: langRolePrivilege.msgDuplicatedRoleName, severity: 'error' } })
    }
    else throw ApiError.of(result.error);
  }

}


/////////////////////////////////////////////////////////////////////
///////////////////////// Role Privilege Update //////////////////////
/////////////////////////////////////////////////////////////////////


export function* watchRolePrivilegeUpdateRequested(context: PASSagaContext) {
  yield takeEvery(
    'RolePrivilege.UpdateRequested',
    apiTaskWrapper(requestUpdateRolePrivilege),
    context
  )
}

export function* requestUpdateRolePrivilege(context: PASSagaContext, action: Extract<RolePrivilegeActions, { type: 'RolePrivilege.UpdateRequested' }>) {
  if (action.type != 'RolePrivilege.UpdateRequested') {
    return;
  }

  const token: string = yield select((state: IRootState) => state.login.token ?? '');
  const { langRolePrivilege } = yield select((state: IRootState) => state.locale);

  const result: ApiResult<boolean> = yield call(rolePrivilegeApi.editRoleAndPrivilege, action.payload, token);

  if (!result.error) {
    yield call(context.browserHistory.replace as any, `/roles`);
  } else {
    if (result.error === 'ERR_INACTIVE_ROLE_FORBIDDEN') {
      yield put<LayoutActions>({ type: 'Layout.AlertMessageAdded', payload: { message: langRolePrivilege.msgInactivateRoleForbidden, severity: 'error' } })
    }
    else throw ApiError.of(result.error);
  }

}


/////////////////////////////////////////////////////////////////////
///////////////////////// Roles Fetch ////////////////////////////
/////////////////////////////////////////////////////////////////////


export function* watchRoleListFetchRequested(context: PASSagaContext) {
  yield takeEvery(
    'RoleList.FetchRequested',
    apiTaskWrapper(requestRoleList),
    context
  )
}

export function* requestRoleList(context: PASSagaContext, action: Extract<RolePrivilegeActions, { type: 'RoleList.FetchRequested' }>) {
  if (action.type != 'RoleList.FetchRequested') {
    return;
  }

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<any> = yield call(rolePrivilegeApi.getRoleList, action.payload, token);

  if (!result.error) {
    yield put<RolePrivilegeActions>({ type: 'RoleList.Loaded', payload: result.data! });
  } else {
    throw ApiError.of(result.error);
  }

}