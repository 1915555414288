import { Button, Card, CardActions, CardContent, Checkbox as MuiCheckbox, FormControl, Grid, InputLabel, ListItemText, makeStyles, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { CardProps } from '@material-ui/core/Card';
import { TextFieldProps } from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker } from '@material-ui/pickers';
import userApi from 'api/userApi';
import { UserListItemDTO } from 'common/dto';
import { FormPresenter } from 'common/hooks';
import { HandleFormHelperText, tooltipHoc } from 'common/ui';
import { initFormKeysAndBooleanMap, isNonEmpty, limitTextFieldLength, multiLang } from 'common/utils';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AgentRatingCommentSearchCritiera, IRootState } from 'reducers';

//#region Common UI
const Checkbox = tooltipHoc(MuiCheckbox);

const disablePrevDates = (startDates: any) => {
  const startSeconds = Date.parse(startDates);
  return (date: any) => {
    return Date.parse(date) < startSeconds;
  }
}

const UITextField = (props: TextFieldProps & { fixedLabel?: boolean }) => {
  let { label, fixedLabel, ...rest } = props;
  fixedLabel = false;
  return <Fragment>
    {fixedLabel ? <Typography>{label}</Typography> : null}
    <TextField {...rest} label={!fixedLabel ? label : null} />
  </Fragment>
};

const InputGroup = (props: { children: React.ReactNode[], label?: string }) => {
  const { children } = props;
  const { langPropertyStock: lang } = useSelector((state: IRootState) => state.locale);
  return (
    // <Grid container xs={12} style={{ display: 'flex' }}>
    <Grid container style={{ display: 'flex' }}>
      <Grid
        item
        style={{ flex: 1 }}
      >
        {children[0]}
      </Grid>

      <Grid item style={{ width: 40, flexShrink: 1 }}>
        <TextField
          fullWidth
          InputProps={{
            style: { borderRadius: 0, borderLeft: 0, borderRight: 0 },
          }}
          inputProps={{
            style: { paddingLeft: 0, paddingRight: 0, textAlign: 'center', borderLeft: 0, borderRight: 0 },
          }}
          disabled value={lang.to}
          variant="outlined"
          margin="dense" />
      </Grid>

      <Grid
        item
        style={{ flex: 1 }}
      // md={5}
      // xs={5}
      >
        {children[1]}
      </Grid>
    </Grid>
  );
}

InputGroup.leftItem = () => ({ style: { borderTopRightRadius: 0, borderBottomRightRadius: 0 } });
InputGroup.rightItem = () => ({ style: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } });

//#endregion Common UI

const useStyles = makeStyles((theme) => ({
  patchOutlineAutocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
      paddingLeft: 8,
    }
  },

}));

interface AgentRatingCommentSearchProps extends CardProps {
  onsearch?(values: Partial<AgentRatingCommentSearchCritiera>): any;
  searchform: FormPresenter<AgentRatingCommentSearchCritiera>;
}


const AgentRatingCommentSearch = (props: AgentRatingCommentSearchProps) => {
  const { searchform } = props;
  const classes = useStyles();

  const { lang, langAgentRatingCommentMgt, langSystemSetting, locale, reviewStatusOptions } = useSelector((state: IRootState) => state.locale);

  const bind = searchform.bind;

  const reviewStatusSelect = () => (
    <FormControl variant="outlined" margin="dense" fullWidth>
      <InputLabel variant="outlined" style={{ background: '#fff' }}>{langAgentRatingCommentMgt.captionReviewStatus}</InputLabel>
      <Select
        multiple
        {...bind('reviewStatus')}
        renderValue={(selected) => (selected as string[]).map(key => reviewStatusOptions[key]).join(', ')}
      >
        {Object.keys(reviewStatusOptions).map(key => (
          <MenuItem key={key} value={key}>
            <Checkbox checked={searchform.values.reviewStatus!.indexOf(key) > -1} />
            <ListItemText primary={reviewStatusOptions[key]} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  //auto complete for agent
  const [agents, setAgents] = useState<UserListItemDTO[]>([]);
  const [agentListLoading, setAgentListLoading] = useState(false);
  const [agentUsername, setAgentUsername] = useState(agents.filter(agent => agent.username != 'companyStockAgent').map((agent) => agent.username));

  const token: string = useSelector((state: IRootState) => state.login.token ?? '');
  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);

  const fetchAgents = (keyword: string) => {
    if (keyword.length < 2) { return; }
    setAgentListLoading(true);
    userApi.getListForAutocomplete({ name: keyword, onlyHasCreateOwnClientPrivilege: true }, token).then(result => {
      if (result.data?.content) {
        setAgents(result.data!.content);
        setAgentListLoading(false);
        setAgentUsername(result.data!.content.filter(agent => agent.username != 'companyStockAgent').map((agent) => agent.username));
      }
    });
  }

  // const agentNames = agents.map((agent) => locale === 'en' ? agent.englishName : agent.englishName);
  // const agentMap: { [name: string]: UserListItemDTO } = {};
  // agents.forEach((agent) => {
  //   agentMap[agent.username] = agent;
  // })
  const agentMap: { [name: string]: UserListItemDTO } = {};
  agents.forEach((agent) => {
    agentMap[agent.username] = agent;
  })


  const [keywordOfAgent, setKeywordOfAgent] = useState('')

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchAgents(keywordOfAgent);
    }, 1000);
    return () => clearTimeout(timer);
  }, [keywordOfAgent]);

  const submitDateRange = () => (
    <div>
      <InputGroup>
        <KeyboardDatePicker autoOk={true}
          InputProps={InputGroup.leftItem()}
          // disableToolbar
          fullWidth
          variant="inline"
          inputVariant="outlined"
          format={DISPLAY_DATE_FORMAT}
          label={langAgentRatingCommentMgt.captionSubmitDate}
          margin="dense"
          // inputValue={searchform.values.submitDate?.[0] ?? ''}
          value={searchform.values.submitDate?.[0] ? moment(searchform.values.submitDate?.[0]) : null}
          onChange={(value) => {
            //searchForm.values.submitDate?.[1] ? null : null;
            searchform.setValues({
              ...searchform.values,
              submitDate: [value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? null, null]

            });
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />


        <KeyboardDatePicker autoOk={true}
          InputProps={InputGroup.rightItem()}
          // disableToolbar
          fullWidth
          variant="inline"
          inputVariant="outlined"
          format={DISPLAY_DATE_FORMAT}
          // label={lang.submitDate}
          margin="dense"
          shouldDisableDate={disablePrevDates(searchform.values.submitDate?.[0])}
          // inputValue={searchform.values.submitDate?.[1] ?? ''}
          value={searchform.values.submitDate?.[1] ? moment(searchform.values.submitDate?.[1]) : null}
          onChange={(value) => {
            searchform.setValues({
              ...searchform.values,
              submitDate: [searchform.values.submitDate?.[0] ?? null, value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? null]
            });
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </InputGroup>

    </div>
  );
  //console.log("searchform.values", searchform.values);
  const filteredSearchForm = () => {
    // searchform.values = {
    //   ...searchform.values,
    //   agentId: searchform.values.agentId?.trim(),
    //   customerName: searchform.values.customerName?.trim(),
    // }

    const newForm: Partial<AgentRatingCommentSearchCritiera> = {};
    Object
      .keys(searchform.values)
      // .filter((key) => !disabledCriterias[key])
      .forEach((key) => (newForm as any)[key] = searchform.values[key as keyof AgentRatingCommentSearchCritiera]);
    return newForm;
  };

  const [keyBooleanMap, setKeyBooleanMap] = useState(initFormKeysAndBooleanMap(searchform));
  return (
    <Card
      {...props}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardContent>
          <Grid
            container
            spacing={1}
          >
            <Grid item container md={12} xs={12} spacing={1}>

              <Grid
                item
                md={3}
                sm={3}
                xs={12}
              >
                <Autocomplete
                  // disabled={!editAgentEnabled}
                  popupIcon={''}
                  options={agentUsername}
                  noOptionsText={''}
                  filterOptions={(option) => option}
                  loading={agentListLoading}
                  loadingText={lang.msgLoading}
                  renderOption={(option) => <div>
                    <div><Typography >{`${agents.filter(agent => agent.username === option)?.[0]?.englishName ?? ''}${agents.filter(agent => agent.username === option)?.[0]?.chineseName ? '/' : ''}${agents.filter(agent => agent.username === option)?.[0]?.chineseName ?? ''}`}</Typography></div>
                    <div><Typography variant='caption'>{agents.filter(agent => agent.username === option)?.[0]?.mainContact}</Typography></div>
                  </div>}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      inputProps={{ ...params.inputProps, maxLength: 255 }}
                      className={classes.patchOutlineAutocomplete}
                      // error={!!form.hasError('building')}
                      // helperText={form.hasError('building')}
                      fullWidth
                      label={langAgentRatingCommentMgt.captionAgentName}
                      margin="dense"
                      variant="outlined"
                      onFocus={(ev) => {

                        setAgentUsername([]);
                        setKeywordOfAgent(ev.target.value);

                      }}
                      onChange={(ev) => {
                        // fetchAgents(ev.target.value)
                        setKeyBooleanMap(keyBooleanMap.set("agentName", ev.target.value.length === 255))
                        setAgents([]);
                        setAgentUsername([]);
                        setKeywordOfAgent(ev.target.value);
                        setAgentListLoading(true);
                        if (ev.target.value.length < 2 || !isNonEmpty(ev.target.value)) {
                          setAgentListLoading(false);
                        }
                      }}
                    />
                  )}

                  value={multiLang(locale === 'en' ? 'zh_HK' : 'en', searchform.values.agentNameZh, searchform.values.agentNameEn)}

                  onChange={(_: any, val: any) => {
                    if (agentMap[val]) {
                      const { id, chineseName, englishName } = agentMap[val];
                      searchform.setValues({
                        ...searchform.values,
                        agentId: id,
                        agentNameEn: englishName,
                        agentNameZh: chineseName,

                      })

                    } else {
                      searchform.setValues({
                        ...searchform.values,
                        agentId: undefined,
                        agentNameEn: undefined,
                        agentNameZh: undefined,

                      });
                    }
                  }}
                  getOptionLabel={() => multiLang(locale, searchform.values.agentNameZh, searchform.values.agentNameEn) ?? ''}
                />
                <HandleFormHelperText
                  isError={keyBooleanMap.get('agentName')}
                  errorMessage={lang.textOverLimit}
                />
              </Grid>

              <Grid
                item
                md={3}
                sm={3}
                xs={12}
              >
                <UITextField
                  label={langAgentRatingCommentMgt.captionCustomerName}
                  {...bind("customerName")}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  onChange={(e) => {
                    limitTextFieldLength(e, 255, 'customerName', searchform, keyBooleanMap, setKeyBooleanMap);
                  }}
                ></UITextField>
                <HandleFormHelperText
                  isError={keyBooleanMap.get('customerName')}
                  errorMessage={lang.textOverLimit}
                />
              </Grid>
              <Grid
                item
                md={3}
                sm={3}
                xs={12}
              >
                {reviewStatusSelect()}
              </Grid>

            </Grid>
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
            >
              <Grid
                item
                md={5}
                sm={6}
                xs={12}
              >
                {submitDateRange()}
              </Grid>
            </Grid>

          </Grid>
        </CardContent>
      </form>

      <CardActions style={{ flexWrap: 'wrap' }}>
        <div style={{ flexGrow: 1 }} />
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.onsearch?.(filteredSearchForm())}>
          {lang.actionSearch}
        </Button>
      </CardActions>
    </Card>
  );
}

export default AgentRatingCommentSearch;
