import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import { blue } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import buildingApi from 'api/buildingApi';
import { BuildingListItemDTO } from 'common/dto';
import { BuildingIcon, SearchIcon } from 'common/ui';
import { multiLang } from 'common/utils';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState, PASDispatch } from 'reducers';
import { format } from 'util';



type BUILDING_TYPE = BuildingListItemDTO;

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  searchField: {
    paddingLeft: 8,
    paddingRight: 8,
  },
  usageTag: {
    textAlign: 'right',
    borderRadius: 3,
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    borderStyle: 'solid',
    padding: 2,
    color: theme.palette.primary.main,
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap',
  },
  buildingPrimaryText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dialogRoot: {
    minWidth: 300,
  },
}));

interface BuildingMergeDialogProps {
  onClose: () => void;
  open: boolean;
  onSearch: (kw?: string) => void;
  buildings: BUILDING_TYPE[];

  onSelectBuilding: (building: BUILDING_TYPE) => void;
  onSwitchMergeDirection: () => void;
  onCancel: () => void;
  onMerge: () => void;
  stage: 'select' | 'merge';

  // selectedBuilding: BUILDING_TYPE | undefined;
  mergeBuildingA: BUILDING_TYPE | undefined;
  mergeBuildingB: BUILDING_TYPE | undefined;
  addToAlias: boolean;
  onAddToAliasChange: (val: boolean) => void;
}

interface BuildingMergeDialogContainerProps {
  open: boolean;
  onClose: () => void;
  // anotherBuilding: BUILDING_TYPE;
}

const BuildingMergeDialogContainer = (props: BuildingMergeDialogContainerProps) => {
  const { open, onClose } = props;
  // const [open, setOpen] = React.useState(false);
  // const [selectedBuilding, setSelectedBuilding] = React.useState<BUILDING_TYPE | null>(null);
  // const [mergeBuildingA, setMergeBuildingA] = React.useState<BUILDING_TYPE | null>(null);
  // const [mergeBuildingB, setMergeBuildingB] = React.useState<BUILDING_TYPE | null>(null);
  // const [ addToAlias, setAddToAlias ] = React.useState(false);
  const [buildings, setBuildings] = React.useState<BUILDING_TYPE[]>([]);

  const dispatch = useDispatch() as PASDispatch;

  const {
    mergeBuildingA, mergeBuildingB, addToAlias, stage
  } = useSelector((state: IRootState) => state.buildingMerge);

  const { token } = useSelector((state: IRootState) => state.login);

  const handleSelectBuilding = (value: BUILDING_TYPE) => {
    dispatch({ type: 'BuildingMerge.UpdateStage', payload: 'merge' });
    dispatch({ type: 'BuildingMerge.SelectB', payload: value });
  };

  const swapBuilding = () => {
    dispatch({ type: 'BuildingMerge.Swap' });
  };


  const handleSearch = async (kw = '') => {
    if (kw.length < 2) {
      return;
    }
    const { data } = await buildingApi.getList({
      size: 500, keyword: kw
    }, token ?? '');
    setBuildings(data?.content?.filter(n => n.id !== mergeBuildingA?.id) ?? []);
  };

  const handleMerge = () => {
    // TODO:
    // buildingApi.merge(mergeBuildingA.id, mergeBuildingB.id, addToAlias, token ?? '');
    dispatch({ type: 'BuildingMerge.MergeRequested' });
    onClose();
  };

  return <BuildingMergeDialog
    mergeBuildingA={mergeBuildingA}
    mergeBuildingB={mergeBuildingB}
    stage={stage}
    open={open}
    onClose={onClose}
    onSelectBuilding={handleSelectBuilding}
    onSwitchMergeDirection={swapBuilding}
    onCancel={onClose}
    onMerge={handleMerge}
    onSearch={handleSearch}
    addToAlias={addToAlias}
    onAddToAliasChange={(val) => dispatch({ type: 'BuildingMerge.UpdateAddToAlias', payload: val })}
    buildings={buildings}
  />
};

export default BuildingMergeDialogContainer;

function BuildingMergeDialog(props: BuildingMergeDialogProps) {
  const classes = useStyles();
  const { stage,
    onClose, open,
    onSearch, buildings,
    onSelectBuilding, onSwitchMergeDirection, onCancel, onMerge,
    mergeBuildingA, mergeBuildingB,
    addToAlias, onAddToAliasChange,
  } = props;
  const theme = useTheme();

  const { locale, langBuilding, lang, usageOptions, langSystemSetting } = useSelector((state: IRootState) => state.locale);
  // const allDistrictOptions = useSelector(selectAllDistrictOptions);

  const { districtHkiOptions: districtHkiOptionsEn, districtNtOptions: districtNtOptionsEn, districtKltOptions: districtKltOptionsEn } = useSelector((state: IRootState) => state.locale._bundle['en']);
  const { districtHkiOptions: districtHkiOptionsZhHK, districtNtOptions: districtNtOptionsZhHK, districtKltOptions: districtKltOptionsZhHK } = useSelector((state: IRootState) => state.locale._bundle['zh_HK']);

  const districtEnOptions = {
    ...districtHkiOptionsEn,
    ...districtNtOptionsEn,
    ...districtKltOptionsEn,
  };
  const districtZhHkOptions = {
    ...districtHkiOptionsZhHK,
    ...districtNtOptionsZhHK,
    ...districtKltOptionsZhHK,
  };

  const defaultSearchValue = multiLang(locale, mergeBuildingA?.buildingNameZh, mergeBuildingA?.buildingNameEn) ?? '';
  useEffect(() => {
    onSearch(defaultSearchValue);
  }, [defaultSearchValue, mergeBuildingA, open]);

  const handleListItemClick = (value: BUILDING_TYPE) => {
    onSelectBuilding(value);
  };

  const buildingPrimaryText = (building: BUILDING_TYPE | undefined) => {
    // const buildingName = multiLang(locale, building?.buildingNameZh, building?.buildingNameEn);
    const block = multiLang(locale, building?.blockZh, building?.blockEn);

    // <div className={classes.buildingPrimaryText}>
    return <div>
      <div>{multiLang('en', building?.buildingNameZh, building?.buildingNameEn) ?? ''} {!multiLang('en', building?.blockZh, building?.blockEn) ? '' : `(${block})`}</div>
      <div>{multiLang('zh_HK', building?.buildingNameZh, building?.buildingNameEn) ?? ''} {!multiLang('zh_HK', building?.blockZh, building?.blockEn) ? '' : `(${block})`}</div>
      {/* <div>
        <div className={classes.usageTag}>{usageOptions[building?.usage ?? '']}</div>
      </div> */
        ''}
    </div>;
  }



  const buildingSecondaryText = (building: BUILDING_TYPE | undefined) => {
    return <div>
      <div>
        <b>{districtEnOptions[building?.district ?? ''] ?? ''}</b>
      </div>
      <div>
        <b>{districtZhHkOptions[building?.district ?? ''] ?? ''}</b>
      </div>
      <div>{multiLang('en', building?.streetZh, building?.streetEn) ?? ''}</div>
      <div>{multiLang('zh_HK', building?.streetZh, building?.streetEn) ?? ''}</div>
      <div>
        <i>{building?.lot}&nbsp;</i>
      </div>
      <div>
        <i> {langSystemSetting.id + ': ' + building?.id ?? ''}</i>
      </div>
    </div>;
  }

  const selectionView = () => <React.Fragment>
    <DialogTitle>{langBuilding.titlePleaseSelectBuildingToMerge}</DialogTitle>

    <TextField
      className={classes.searchField}
      placeholder={langBuilding.captionSearchBuilding}
      defaultValue={multiLang(locale, mergeBuildingA?.buildingNameZh, mergeBuildingA?.buildingNameEn) ?? ''}
      onChange={(ev) => onSearch(ev.target.value)}
    />

    {
      buildings.length > 0 ? null : <Typography style={{ padding: 16, width: '100%', textAlign: 'center' }}>
        <SearchIcon style={{ fontSize: 35, color: '#f0f0f0' }} />
      </Typography>
    }

    <List style={{ overflow: 'scroll' }}>
      {buildings.map((building) => (
        <ListItem button onClick={() => handleListItemClick(building)} key={building.id}>
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              <BuildingIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={buildingPrimaryText(building)}
            secondary={buildingSecondaryText(building)}
          />
        </ListItem>
      ))}
    </List>

    <DialogActions>
      <Button onClick={onClose}>{lang.actionCancel}</Button>
    </DialogActions>
  </React.Fragment>

  const mergeCard = (building: BUILDING_TYPE | undefined, width: string, msgColor: string, msg: string) => <Card style={{ width }}>
    <CardContent style={{ padding: 8 }}>
      <ListItemText style={{ fontWeight: 'bold' }}
        primary={buildingPrimaryText(building)}
        secondary={buildingSecondaryText(building)}
      />
      <Typography style={{ color: msgColor }}>
        <b>{msg}</b>
      </Typography>
    </CardContent>
  </Card>

  const mergeView = () => <React.Fragment>
    <DialogTitle>{langBuilding.titlePleaseConfirmBuildingMerge}</DialogTitle>
    <div style={{ display: 'flex', justifyContent: 'center', paddingLeft: '8px', paddingRight: '8px', flexDirection: 'column', alignItems: 'center' }}>
      <Typography>{langBuilding.captionMergeFrom}</Typography>
      {mergeCard(
        mergeBuildingB, '80%', (theme.palette as any).error.main,
        langBuilding.captionBuildingWillBeDeleted
      )}

      <Typography>{langBuilding.captionMergeInto}</Typography>
      {mergeCard(
        mergeBuildingA, '90%', (theme.palette as any).success.main,
        langBuilding.captionBuildingWillBeRetained
      )}

      <Button style={{ fontWeight: 'bolder' }} color="primary" onClick={onSwitchMergeDirection}>
        {langBuilding.actionSwitchMergeDirection}
      </Button>

      <FormControl component="fieldset" >
        <FormGroup aria-label="add-to-alias-checkbox" row>
          <FormControlLabel
            // style={{ wordBreak: 'keep-all' }}
            control={<Checkbox color="primary" checked={addToAlias} onChange={(_, val) => onAddToAliasChange(val)} />}
            label={format(langBuilding.captionAddToAlias,
              multiLang(locale, mergeBuildingB?.buildingNameZh, mergeBuildingB?.buildingNameEn) ?? '?'
            )}
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      <Typography><b>{langBuilding.msgConfirmMerge}</b></Typography>
    </div>

    <DialogActions>
      <Button onClick={onCancel}>{lang.actionCancel}</Button>
      <Button color="primary" onClick={onMerge}>{lang.actionConfirm}</Button>
    </DialogActions>
  </React.Fragment>

  return (
    <Dialog onClose={onClose}
      open={open}
      PaperProps={{ className: classes.dialogRoot }}
    >
      {stage === 'select' ? selectionView() : mergeView()}
    </Dialog>
  );
}