import { Button, Card, CardActions, CardContent, CardHeader, Checkbox as MuiCheckbox, Chip, Collapse, Divider, FormControl, FormControlLabel, Grid, Hidden, IconButton, InputLabel, ListItemText, makeStyles, MenuItem, Select, Switch, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { CardProps } from '@material-ui/core/Card';
import { TextFieldProps } from '@material-ui/core/TextField';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker } from '@material-ui/pickers';
import buildingApi from 'api/buildingApi';
import clsx from 'clsx';
import { hasPermission } from 'common/access-control';
import { BuildingListItemDTO } from 'common/dto';
import DistrictMultiSelect from 'common/elements/DistrictMultiSelect';
import MinMaxField, { BLANK_RANGE, DEFAULT_FROM, DEFAULT_TO, isEmptyRange } from 'common/elements/MinMaxField';
import { FormPresenter } from 'common/hooks';
import { HandleFormHelperText, tooltipHoc } from 'common/ui';
import { getBuildingSortForAutoComplete, getBuilingListWithoutDuplicates, getStreetListWithoutDuplicates, getStreetSortForAutoComplete, initFormKeysAndBooleanMap, isNonEmpty, limitTextFieldLength, multiLang, priceFromView, priceToView } from 'common/utils';
import moment from 'moment';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState, LocaleStateRemote, PASDispatch } from 'reducers';
import { PropertyStockSearchFormValues, PS_DEFAULT_SIZE } from '../../PropertyList/PropertyList';







const Checkbox = tooltipHoc(MuiCheckbox);

const roomCountOptions = (() => {
  const seq = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const opt: { [key: string]: string } = {};
  seq.forEach(n => opt[String(n)] = String(n));
  return opt;
})();

interface PropertiesSearchProps extends CardProps, PAS.WishlistVariant {
  onSearch?(): any;
  // variant?: 'wishlist' | undefined;
  form: FormPresenter<PropertyStockSearchFormValues>;
  canReadUnownedProperty: boolean;
  canReadTeamProperty: boolean;
}

const UITextField = (props: TextFieldProps & { fixedLabel?: boolean }) => {
  let { label, fixedLabel, ...rest } = props;
  fixedLabel = false;
  return <Fragment>
    {fixedLabel ? <Typography>{label}</Typography> : null}
    <TextField {...rest} label={!fixedLabel ? label : null}

    />
  </Fragment>
};

const InputGroup = (props: { children: React.ReactNode[], label?: string }) => {
  const { children } = props;
  const { langPropertyStock: lang } = useSelector((state: IRootState) => state.locale);
  return (
    <Grid container xs={12} style={{ display: 'flex' }}>
      <Grid
        item
        style={{ flex: 1 }}
      >
        {children[0]}
      </Grid>

      <Grid item style={{ width: 40, flexShrink: 1 }}>
        <TextField
          fullWidth
          InputProps={{
            style: { borderRadius: 0, borderLeft: 0, borderRight: 0 },
          }}
          inputProps={{
            style: { paddingLeft: 0, paddingRight: 0, textAlign: 'center', borderLeft: 0, borderRight: 0 },
          }}
          disabled value={lang.to}
          variant="outlined"
          margin="dense" />
      </Grid>

      <Grid
        item
        style={{ flex: 1 }}
      // md={5} sm={5}
      // xs={5}
      >
        {children[1]}
      </Grid>
    </Grid>
  );
}

InputGroup.leftItem = () => ({ style: { borderTopRightRadius: 0, borderBottomRightRadius: 0 } });
InputGroup.rightItem = () => ({ style: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } });


const useStyles = makeStyles((theme) => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  patchOutlineAutocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
      paddingLeft: 8,
    }
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  toggleLeftLabel: {
    paddingRight: 14,
  },
  dropdownBesideSearchBtn: {
    // marginRight: 15,
    marginLeft: 8,
  }
}));

// function TextMaskCustom(props: MaskedInputProps & { inputRef?: any }) {
//   const { inputRef, ...other } = props;

//   return (
//     <MaskedInput
//       {...other}
//       ref={ref => {
//         inputRef(ref ? ref.inputElement : null);
//       }}
//       mask={['$', /\d+/, ' ', '-', ' ', '$', /\d+/]}
//       placeholderChar={'\u2000'}
//       showMask
//     />
//   );
// }

const PropertiesMatchingSearch = (props: PropertiesSearchProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const localeBundle = useSelector((state: IRootState) => state.locale);
  const isCurrentUserStock = useSelector((state: IRootState) => state.property.isCurrentUserList);
  const uid = useSelector((state: IRootState) => state.login.uid);
  const { locale, langPropertyStock: lang, lang: langCommon,
    districtHkiOptions, districtNtOptions, districtKltOptions, propertyStockStatusOptions, usageOptions, levelOptions, symbolOptions,
    vacantOptions, dormancyOptions } = localeBundle;
  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);
  const dispatch = useDispatch() as PASDispatch;
  const { districts } = useSelector((state: IRootState) => state.login);
  const { forceCancelPropertyMatching } = useSelector((state: IRootState) => state.property);
  const limitPref = useSelector((state: IRootState) => state.login.rowsCountPreference?.PROPERTY_STOCK) ?? PS_DEFAULT_SIZE;

  // handling display in mobile view
  const shouldCollapse = useMediaQuery('(max-width: 599px)');


  const searchForm = props.form;

  const [buildings, setBuildings] = useState<BuildingListItemDTO[]>([]);
  const [buildingListLoading, setBuildingListLoading] = useState(false);
  const [streets, setStreets] = useState<BuildingListItemDTO[]>([]);
  const [streetListLoading, setStreetListLoading] = useState(false);

  const token: string = useSelector((state: IRootState) => state.login.token ?? '');
  const fetchBuilding = (keyword: string) => {
    if (keyword.length < 2) { return; }
    setBuildingListLoading(true);
    buildingApi.getList({ building: keyword, sort: getBuildingSortForAutoComplete(locale) }, token).then(result => {
      if (result.data?.content) {
        setBuildings(getBuilingListWithoutDuplicates(result.data!.content));
        setBuildingListLoading(false);
      }
    });
  }


  const [keyBooleanMap, setKeyBooleanMap] = useState(initFormKeysAndBooleanMap(searchForm));

  useEffect(() => {
    setKeyBooleanMap(initFormKeysAndBooleanMap(searchForm));
  }, [Object.keys(searchForm.values).length])

  const fetchStreet = (keyword: string) => {
    if (keyword.length < 2) { return; }
    setStreetListLoading(true);
    buildingApi.getList({ street: keyword, sort: getStreetSortForAutoComplete(locale) }, token).then(result => {
      if (result.data?.content) {
        setStreets(getStreetListWithoutDuplicates(result.data!.content));
        setStreetListLoading(false);
      }
    });
  }

  // const buildingNamesWithDuplicate = buildings.map((building) => building.building);
  // const buildingNames = [...new Set(buildingNamesWithDuplicate)];
  // const buildingMap: { [name: string]: BuildingListItemDTO } = {};
  // buildings.forEach((building) => { buildingMap[building.building] = building });
  const buildingNames = buildings.map((building) => multiLang(locale, building.buildingNameZh, building.buildingNameEn));

  // const streetNamesWithDuplicate = streets.map((street) => street.street);
  // const streetNames = [...new Set(streetNamesWithDuplicate)];
  // const streetMap: { [name: string]: BuildingListItemDTO } = {};
  // streets.forEach((street) => { streetMap[street.street] = street });
  const streetNames = streets.map((street) => multiLang(locale, street.streetZh, street.streetEn));

  useEffect(() => {
    // const responsibleDistricts = userFeatures?.filter(f => f.type == 'DISTRICT').map(f => f.value) ?? [];
    searchForm.modify({
      // district: responsibleDistricts,
      isCurrentUser: isCurrentUserStock
    })
  }, [isCurrentUserStock]);

  const bind = searchForm.bind;

  let [expanded, setExpanded] = useState({
    other: false,
    history: false,
    features: false,
  });

  // const [ disabledCriteriaList, setDisabledCriteriaList ] = useState<{ [k: string]: boolean }>({
  //   // rent: true,
  // });
  const disabledCriteriaList = searchForm.values.disabledCriteriaList!;
  const setDisabledCriteriaList = (newList: { [k: string]: boolean }) =>
    searchForm.updateValues('disabledCriteriaList', newList);

  // const [ isGross, _setIsGross ] = useState(false);
  const isGross = searchForm.values.isGross ?? false;
  const setIsGross = (newVal: boolean) => {
    searchForm.updateValues('isGross', newVal);
    // swap
    if (newVal) {
      searchForm.modify({
        gross: searchForm.values.net,
        net: BLANK_RANGE,
      });
    } else {
      searchForm.modify({
        net: searchForm.values.gross,
        gross: BLANK_RANGE,
      });
    }
  };

  // const [ isRent, _setIsRent ] = useState(false);
  const isRent = searchForm.values.isRent ?? false;
  const setIsRent = (newVal: boolean) => {
    searchForm.updateValues('isRent', newVal);
    // swap
    if (newVal) {
      searchForm.modify({
        price: BLANK_RANGE,
        rent: BLANK_RANGE,
      });
    } else {
      searchForm.modify({
        rent: BLANK_RANGE,
        price: BLANK_RANGE,
      });
    }
  }


  // useEffect(() => {
  //   if (forceCancelPropertyMatching) {
  //     searchForm.setValues(getInitialPropertySearchFormValues(districts, undefined, undefined, locale));
  //     dispatch({
  //       type: 'PropertyList.FetchRequested',
  //       payload: {
  //         ...getInitialPropertySearchFormValues(districts, {}, {}, locale) as any,
  //         page: initialPage, size: limitPref, sort: initialSortOrder,
  //       }
  //     });
  //     dispatch({ type: 'PropertyList.ForceCancelPropertyMatching', payload: false })
  //   }

  // }, [forceCancelPropertyMatching])

  const previousLocale = useRef(locale);
  useEffect(() => {
    searchForm.modify({
      price: [
        searchForm.values.price?.[0] !== DEFAULT_FROM ?
          priceToView(priceFromView(searchForm.values.price?.[0] ?? 0, previousLocale.current), locale) : searchForm.values.price?.[0],
        searchForm.values.price?.[1] !== DEFAULT_TO ?
          priceToView(priceFromView(searchForm.values.price?.[1] ?? 0, previousLocale.current), locale) : searchForm.values.price?.[1],
      ] as [number, number]
    });
    previousLocale.current = locale;
  }, [locale]);

  const { privileges } = useSelector((state: IRootState) => state.login);
  const canReadHotPick = hasPermission(privileges, 'READ', 'PREMIUM_PROPERTY');

  return (
    <form
      autoComplete="off"
      noValidate
    >
      {/* <Grid container md={12} sm={12} xs={12} spacing={1}> */}
      <Grid container>
        <Grid item md={6} sm={12} xs={12}>
          <Card
            //{...props}
            style={{ height: 'auto' }}
          >
            <CardHeader title={lang.captionBasicInfo} />
            <Divider />
            <CardContent>
              <Grid container spacing={1}>
                <Grid
                  item
                  md={3} sm={3}
                  xs={6}
                >

                  <FormControl variant="outlined" margin="dense" fullWidth>
                    <InputLabel variant="outlined" style={{ background: '#fff' }}>{lang.usage}</InputLabel>
                    <Select
                      multiple
                      {...bind('usage')}
                      renderValue={(selected) => (selected as string[]).map(val => usageOptions[val]).join(', ')}
                    >
                      {Object.keys(usageOptions).map(key => (
                        <MenuItem key={key} value={key}>
                          <Checkbox checked={(searchForm.values.usage ?? []).indexOf(key) > -1} />
                          <ListItemText primary={usageOptions[key]} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  md={3} sm={3}
                  xs={6}
                >
                  <FormControl variant="outlined" margin="dense" fullWidth>
                    <InputLabel variant="outlined" style={{ background: '#fff' }}>{lang.status}</InputLabel>
                    <Select
                      multiple
                      {...bind('status')}
                      renderValue={(selected) => (selected as string[]).map(key => propertyStockStatusOptions[key]).join(', ')}
                    >
                      {Object.keys(propertyStockStatusOptions).filter(status => status !== 'UNKNOWN' && status !== 'BOTH').map(status => (
                        <MenuItem key={status} value={status}>
                          <Checkbox checked={searchForm.values.status!.indexOf(status) > -1} />
                          <ListItemText primary={propertyStockStatusOptions[status]} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  md={4} sm={4}
                  xs={12}
                >
                  <DistrictMultiSelect
                    value={searchForm.values.district ?? []}
                    onChange={(value) => searchForm.updateValues('district', value)}
                  />
                </Grid>

                <Grid
                  item
                  md={2} sm={2}
                  xs={6}
                >
                  <UITextField
                    label={lang.propertyNo}
                    {...bind('propertyNo')}
                    fullWidth
                    margin="dense"
                    variant="outlined"

                    onChange={(e) => {
                      limitTextFieldLength(e, 255, 'propertyNo', searchForm, keyBooleanMap, setKeyBooleanMap);
                    }}
                  ></UITextField>
                  <HandleFormHelperText
                    isError={keyBooleanMap.get('propertyNo')}
                    errorMessage={lang.textOverLimit}
                  />
                </Grid>

                <Grid
                  item
                  md={7} sm={7}
                  xs={12}
                >
                  {/* <UITextField
                    label={lang.building}
                    {...bind('building')}
                    fullWidth
                    margin="dense"
                    variant="outlined"
                  ></UITextField> */}
                  <Autocomplete
                    options={buildingNames}
                    noOptionsText={''}
                    loading={buildingListLoading}
                    loadingText={langCommon.msgLoading}
                    freeSolo={true}
                    disableClearable
                    renderInput={params => (
                      <TextField
                        {...params}
                        inputProps={{ ...params.inputProps, maxLength: 255 }}
                        className={classes.patchOutlineAutocomplete}
                        // error={!!form.hasError('building')}
                        // helperText={form.hasError('building')}
                        fullWidth
                        label={lang.buildingOrVillageName}
                        margin="dense"
                        variant="outlined"
                        onChange={(ev) => {
                          setKeyBooleanMap(keyBooleanMap.set("building", ev.target.value.length === 255))
                          searchForm.modify({
                            building: ev.target.value
                          })
                          fetchBuilding(ev.target.value)
                        }}
                        // value={form.values.building ?? ''}
                        value={searchForm.values.building ?? ''}
                      />
                    )}
                    value={searchForm.values.building ?? ''}
                    onChange={(_, val) => {
                      searchForm.modify({
                        building: val
                      })
                      // if(buildingMap[val]) {
                      //   const { building} = buildingMap[val];
                      //   searchForm.modify({
                      //     building: building
                      //   })
                      // }
                    }}
                  />
                  <HandleFormHelperText
                    isError={keyBooleanMap.get('building')}
                    errorMessage={lang.textOverLimit}
                  />
                </Grid>

                <Grid
                  item
                  md={5} sm={5}
                  xs={12}
                >
                  <UITextField
                    label={lang.block}
                    {...bind('block')}
                    fullWidth
                    margin="dense"
                    variant="outlined"

                    onChange={(e) => {
                      limitTextFieldLength(e, 255, 'block', searchForm, keyBooleanMap, setKeyBooleanMap);
                    }}
                  ></UITextField>
                  <HandleFormHelperText
                    isError={keyBooleanMap.get('block')}
                    errorMessage={lang.textOverLimit}
                  />
                </Grid>

                <Grid
                  item
                  md={8} sm={8}
                  xs={12}
                >
                  {/* <UITextField
                    label={lang.street}
                    {...bind('street')}
                    fullWidth
                    margin="dense"
                    variant="outlined"
                  ></UITextField> */}
                  <Autocomplete
                    options={streetNames}
                    noOptionsText={''}
                    loading={streetListLoading}
                    loadingText={langCommon.msgLoading}
                    freeSolo={true}
                    disableClearable
                    renderInput={params => (
                      <TextField
                        {...params}
                        inputProps={{ ...params.inputProps, maxLength: 255 }}
                        className={classes.patchOutlineAutocomplete}
                        // error={!!form.hasError('building')}
                        // helperText={form.hasError('building')}
                        fullWidth
                        label={lang.street}
                        margin="dense"
                        variant="outlined"
                        onChange={(ev) => {

                          setKeyBooleanMap(keyBooleanMap.set("street", ev.target.value.length === 255))
                          //console.log("testing", ev.target.value.length === 255, keyBooleanMap.get('street'))
                          searchForm.modify({
                            street: ev.target.value
                          });
                          fetchStreet(ev.target.value)
                        }}
                        // value={form.values.building ?? ''}
                        value={searchForm.values.street ?? ''}
                      />
                    )}
                    value={searchForm.values.street ?? ''}
                    onChange={(_, val) => {
                      searchForm.modify({
                        street: val
                      });
                      // if(streetMap[val]) {
                      //   const { street} = streetMap[val];
                      //   searchForm.modify({
                      //     street: street
                      //   })
                      // }
                    }}

                  />
                  <HandleFormHelperText
                    isError={keyBooleanMap.get('street')}
                    errorMessage={lang.textOverLimit}
                  />
                </Grid>

                <Grid
                  item
                  md={2} sm={2}
                  xs={6}
                >

                  <FormControl variant="outlined" margin="dense" fullWidth>
                    <InputLabel variant="outlined" style={{ background: '#fff' }}>{lang.level}</InputLabel>
                    <Select
                      multiple
                      {...bind('level')}
                      renderValue={(selected) => (selected as string[]).map(key => levelOptions[key]).join(', ')}
                    >
                      {Object.keys(levelOptions).map(key => (
                        <MenuItem key={key} value={key}>
                          <Checkbox checked={searchForm.values.level!.indexOf(key) > -1} />
                          <ListItemText primary={levelOptions[key]} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  md={2} sm={2}
                  xs={6}
                >
                  <UITextField
                    label={lang.unit}
                    {...bind('unit')}
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    onChange={(e) => {
                      limitTextFieldLength(e, 255, 'unit', searchForm, keyBooleanMap, setKeyBooleanMap);
                    }}
                  ></UITextField>
                  <HandleFormHelperText
                    isError={keyBooleanMap.get('unit')}
                    errorMessage={lang.textOverLimit}
                  />
                </Grid>
                <Grid
                  item
                  md={4} sm={4}
                  xs={12}
                >
                  <UITextField
                    label={lang.lot}
                    {...bind('lot')}
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    onChange={(e) => {
                      limitTextFieldLength(e, 255, 'lot', searchForm, keyBooleanMap, setKeyBooleanMap);
                    }}
                  ></UITextField>
                  <HandleFormHelperText
                    isError={keyBooleanMap.get('lot')}
                    errorMessage={lang.textOverLimit}
                  />
                </Grid>
                <Grid
                  item
                  md={4} sm={4}
                  xs={12}
                >
                  <KeyboardDatePicker autoOk={true}
                    // disableToolbar
                    fullWidth
                    variant="inline"
                    inputVariant="outlined"
                    format={DISPLAY_DATE_FORMAT}
                    label={lang.occupancyPermitDate}
                    margin="dense"
                    // inputValue={searchForm.values.occupancyPermitDate?.[0] ?? ''}
                    value={searchForm.values.occupancyPermitDate?.[0] ? moment(searchForm.values.occupancyPermitDate?.[0]) : null}
                    onChange={(value) => {
                      searchForm.modify({
                        occupancyPermitDate: [value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? null, null],
                      });
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>

                <Grid
                  item
                  md={4} sm={4}
                  xs={12}
                >
                  <KeyboardDatePicker autoOk={true}
                    // disableToolbar
                    fullWidth
                    variant="inline"
                    inputVariant="outlined"
                    format={DISPLAY_DATE_FORMAT}
                    label={lang.dateCreated}
                    margin="dense"
                    // inputValue={searchForm.values.dateCreated?.[0] ?? ''}
                    value={searchForm.values.dateCreated?.[0] ? moment(searchForm.values.dateCreated?.[0]) : null}
                    onChange={(value) => {
                      searchForm.modify({
                        dateCreated: [value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? null, null],
                      });
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {/* area */}
        <Grid item md={6} sm={12} xs={12} >
          <Card
            //{...props}
            style={{ height: 'auto' }}
          >
            <CardHeader title={lang.titleSaleInfo} />
            <Divider />
            <CardContent>
              <Grid container spacing={1}>
                <Grid container>
                  {
                    (shouldCollapse) ?
                      <Grid container item md={12} sm={12} xs={12}>
                        <Grid
                          item
                          md={2} sm={2}
                          xs={6}
                        >
                          <Typography style={{ fontWeight: 'bold', paddingTop: '10px' }}>{lang.captionArea}</Typography>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={6}
                          justify={'flex-end'}
                        >
                          <div style={{ marginLeft: theme.spacing(1), display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography>{lang.captionNet}</Typography>
                            <Switch
                              checked={isGross}
                              onChange={(_, val) => setIsGross(val)}
                            />
                            <Typography>{lang.captionGross}</Typography>
                          </div>
                        </Grid>
                      </Grid>
                      :
                      <Grid
                        item
                        md={2} sm={2}
                        xs={6}
                      >
                        <Typography style={{ fontWeight: 'bold', paddingTop: '10px' }}>{lang.captionArea}</Typography>
                      </Grid>
                  }

                  <Grid item md={6} sm={6} xs={12}>
                    <MinMaxField
                      range={isGross ? searchForm.values.gross! : searchForm.values.net!}
                      unit={langCommon.uFt}
                      onChange={(newRange) => {
                        const key = isGross ? 'gross' : 'net';

                        searchForm.modify({
                          [key]: newRange,
                        });
                      }}
                    />
                  </Grid>

                  {
                    (!shouldCollapse) ?
                      <Grid item md={4} sm={4}>
                        <div style={{ marginLeft: theme.spacing(1), display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <Typography>{lang.captionNet}</Typography>
                          <Switch
                            checked={isGross}
                            onChange={(_, val) => setIsGross(val)}
                          />
                          <Typography>{lang.captionGross}</Typography>
                        </div>
                      </Grid>
                      : null
                  }

                  {
                    (shouldCollapse) ?
                      <Grid container item md={12} sm={12} xs={12}>
                        <Grid
                          item
                          md={2} sm={2}
                          xs={6}
                        >
                          <Typography style={{ fontWeight: 'bold', paddingTop: '10px' }}>{lang.captionPriceOrRent}</Typography>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={6}
                          justify={'flex-end'}
                        >
                          <div style={{ marginLeft: theme.spacing(1), display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography>{lang.price}</Typography>
                            <Switch
                              checked={isRent}
                              onChange={(_, val) => setIsRent(val)}
                            />
                            <Typography>{lang.rent}</Typography>
                          </div>
                        </Grid>
                      </Grid>
                      :
                      <Grid
                        item
                        md={2} sm={2}
                        xs={6}
                      >
                        <Typography style={{ fontWeight: 'bold', paddingTop: '10px' }}>{lang.captionPriceOrRent}</Typography>
                      </Grid>
                  }

                  <Grid item md={6} sm={6} xs={12}>
                    <MinMaxField
                      range={isRent ? searchForm.values.rent! : searchForm.values.price!}
                      unit={!isRent ? (locale === 'en' ? langCommon.u1M : langCommon.u10k) : undefined}
                      onChange={(newRange) => {

                        const key = isRent ? 'rent' : 'price';
                        searchForm.modify({
                          [key]: newRange,
                        });
                      }}
                      digitLimit={isRent ? 12 : locale == 'en' ? 6 : 8}
                    />
                  </Grid>

                  {
                    (!shouldCollapse) ?
                      <Grid item md={4} sm={4}>
                        <div style={{ marginLeft: theme.spacing(1), display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <Typography>{lang.price}</Typography>
                          <Switch
                            checked={isRent}
                            onChange={(_, val) => setIsRent(val)}
                          />
                          <Typography>{lang.rent}</Typography>
                        </div>
                      </Grid>
                      : null
                  }
                </Grid>

                <Grid
                  item
                  md={6} sm={6}
                  xs={6}
                >
                  <UITextField
                    label={lang.owner}
                    {...bind('owner')}
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    onChange={(e) => {
                      limitTextFieldLength(e, 255, 'owner', searchForm, keyBooleanMap, setKeyBooleanMap);
                    }}
                  />
                  <HandleFormHelperText
                    isError={keyBooleanMap.get('owner')}
                    errorMessage={lang.textOverLimit}
                  />
                </Grid>

                <Grid
                  item
                  md={6} sm={6}
                  xs={6}
                >
                  <UITextField
                    label={lang.mainContact}
                    {...bind('mainContact')}
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    onChange={(e) => {
                      limitTextFieldLength(e, 255, 'mainContact', searchForm, keyBooleanMap, setKeyBooleanMap);
                    }}
                  />
                  <HandleFormHelperText
                    isError={keyBooleanMap.get('mainContact')}
                    errorMessage={lang.textOverLimit}
                  />
                </Grid>

                <Grid
                  item
                  md={2} sm={2}
                  xs={6}
                >
                  <FormControl variant="outlined" margin="dense" fullWidth>
                    <InputLabel variant="outlined" style={{ background: '#fff' }}>{lang.symbol}</InputLabel>
                    <Select
                      multiple
                      {...bind('symbol')}
                      renderValue={(selected) => (selected as string[]).map(key => symbolOptions[key]).join(', ')}
                    >
                      {Object.keys(symbolOptions).map(key => (
                        <MenuItem key={key} value={key}>
                          <Checkbox checked={searchForm.values.symbol!.indexOf(key) > -1} />
                          <ListItemText primary={symbolOptions[key]} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  md={2} sm={2}
                  xs={6}
                >
                  {/* <UITextField select
                    label={lang.vacant}
                    {...bind('vacant')}
                    fullWidth
                    margin="dense"
                    variant="outlined"
                  >
                    <MenuItem value={''}>&nbsp;</MenuItem>
                    {vacantOptions.map(v => <MenuItem value={v}>{v}</MenuItem>)}
                  </UITextField> */}

                  <FormControl variant="outlined" margin="dense" fullWidth>
                    <InputLabel variant="outlined" style={{ background: '#fff' }}>{lang.vacant}</InputLabel>
                    <Select
                      multiple
                      {...bind('vacant')}
                      renderValue={(selected) => (selected as string[]).map(key => vacantOptions[key]).join(', ')}
                    >
                      {Object.keys(vacantOptions).map(key => (
                        <MenuItem key={key} value={key}>
                          <Checkbox checked={searchForm.values.vacant!.indexOf(key) > -1} />
                          <ListItemText primary={vacantOptions[key]} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {canReadHotPick ?
                  <Grid
                    item
                    md={2} sm={2}
                    xs={6}
                  >
                    <UITextField select
                      label={lang.hotPick}
                      {...bind('hotPick')}
                      fullWidth
                      margin="dense"
                      variant="outlined"
                    >
                      <MenuItem value={''}>&nbsp;</MenuItem>
                      <MenuItem value={'Y'}>{lang.captionHotPick}</MenuItem>
                      <MenuItem value={'N'}>{lang.captionNonHotPick}</MenuItem>
                    </UITextField>

                  </Grid>
                  : null}
                <Grid
                  item
                  md={3} sm={3}
                  xs={6}
                >
                  <UITextField select
                    label={lang.key}
                    {...bind('key')}
                    fullWidth
                    margin="dense"
                    variant="outlined"
                  >
                    <MenuItem value={''}>&nbsp;</MenuItem>
                    <MenuItem value={'Y'}>{lang.captionWithKey}</MenuItem>
                    <MenuItem value={'N'}>{lang.captionWithoutKey}</MenuItem>
                  </UITextField>
                  {/* <FormControlLabel control={
                      <Checkbox
                        onChange={(_, val) => searchForm.updateValues('key', val)}
                        checked={searchForm.values.key}
                      />
                    }
                    label={lang.key}
                  /> */}
                </Grid>

                <Grid
                  item
                  md={3} sm={3}
                  xs={6}
                >
                  <KeyboardDatePicker autoOk={true}
                    // disableToolbar
                    fullWidth
                    variant="inline"
                    inputVariant="outlined"
                    format={DISPLAY_DATE_FORMAT}
                    label={lang.dateModified}
                    margin="dense"
                    // inputValue={searchForm.values.dateModified?.[0] ?? ''}
                    value={searchForm.values.dateModified?.[0] ? moment(searchForm.values.dateModified?.[0]) : null}
                    onChange={(value) => {
                      searchForm.modify({
                        dateModified: [value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? null, null],
                      });
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={12} sm={12} xs={12}>
          <Card
          //{...props}
          >
            <CardContent>
              {/* Card More*/}
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <IconButton
                      className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded.features,
                      })}
                      onClick={() => setExpanded({ ...expanded, features: !expanded.features })}
                      aria-expanded={expanded.features}
                      aria-label="show more">
                      <ExpandMore />
                    </IconButton>
                    {lang.captionMore}
                  </Grid>
                  <Collapse in={expanded.features} timeout="auto">
                    {renderFeatureSearch(searchForm, localeBundle)}
                  </Collapse>
                </Grid>

                {false ? <Grid item xs={12}>
                  <Grid item xs={12}>
                    <IconButton
                      className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded.history,
                      })}
                      onClick={() => setExpanded({ ...expanded, history: !expanded.history })}
                      aria-expanded={expanded.history}
                      aria-label="show more">
                      <ExpandMore />
                    </IconButton>
                    {lang.captionHistoryTrnx}
                  </Grid>
                  <Collapse in={expanded.history} timeout="auto">
                    <Grid container xs={12} spacing={1}>
                      {/* <Grid item xs={12}>
                        <FormControl margin="dense">
                          <FormControlLabel control={<Checkbox checked value="checkedC" />} label="顯示歷史成交記錄" />
                        </FormControl>
                      </Grid> */}

                      {/* <Grid item md={4} sm={4} xs={12}>
                        <InputGroup>
                          <UITextField
                            {...bind('dealDateFrom')}
                            label="成交日期"
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            InputProps={InputGroup.leftItem()}
                          >

                          </UITextField>

                          <UITextField
                            {...bind('dealDateTo')}
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            InputProps={InputGroup.rightItem()}
                          >

                          </UITextField>
                        </InputGroup>
                      </Grid> */}
                    </Grid>
                  </Collapse>
                </Grid> : null}
              </Grid>
            </CardContent>
            <CardActions>
              <div style={{ flexWrap: 'wrap', display: 'flex', alignItems: 'center' }}>
                <Typography>{lang.captionMatchingConditions}:{' '}</Typography>
                {
                  Object.keys(searchForm.values).filter((key) => {
                    let val = searchForm.values[key as keyof PropertyStockSearchFormValues];
                    // if (key === 'street' || key === 'building' || key === 'block') {
                    //   
                    //   
                    //   
                    //   
                    //   
                    // }
                    return key !== 'canClaimBy' && key !== 'size' && !isEmptyRange(searchForm.values[key as keyof PropertyStockSearchFormValues]) &&
                      (isGross ? (key !== 'net') : (key !== 'gross')) &&
                      (isRent ? (key !== 'price') : (key !== 'rent')) &&
                      (Array.isArray(val) ? (val[0] !== null || val[1] !== null) : true) &&
                      isNonEmpty(searchForm.values[key as keyof PropertyStockSearchFormValues]) &&
                      !!lang[key as keyof typeof lang];
                  }).map((key, idx) => {
                    return <Chip key={idx} style={{ marginBottom: theme.spacing(.5), marginLeft: theme.spacing(.5), background: disabledCriteriaList[key] ? 'inherit' : '#444' }} variant={disabledCriteriaList[key] ? 'outlined' : 'default'} color={disabledCriteriaList[key] ? 'default' : 'primary'} label={lang[key as keyof typeof lang]} onClick={() => setDisabledCriteriaList({ ...disabledCriteriaList, [key]: !disabledCriteriaList[key] })} />
                  })
                }
              </div>
            </CardActions>

            <CardActions>
              {/* <IconButton 
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={() => setExpanded(!expanded)}
                aria-expanded={expanded}
                aria-label="show more">
                <ExpandMore />
              </IconButton>
              其它 */}
              {/* <div style={{ flexGrow: 1 }} /> */}

              <Grid container justify="flex-end">
                <Hidden smDown><div style={{ flexGrow: 20 }}></div></Hidden>
                <Grid item container md xs={12} justify="flex-end">
                  {
                    // (props.canReadUnownedProperty || props.canReadTeamProperty) && 
                    !searchForm.values.isDormant &&
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={searchForm.values.isDormant ?? false}
                          checked={searchForm.values.isCurrentUser ?? false}
                          onChange={(_, checked) => {
                            searchForm.updateValues('isCurrentUser', checked ? true : false)
                            // searchForm.updateValues('isDormant', checked ? false : true)
                          }}
                        />
                      } label={<span style={{ wordBreak: 'keep-all' }}>{lang.captionOwnPropertyStock}</span>} />
                  }
                </Grid>

                <Grid item container md xs={12} justify="flex-end">
                  <Button variant="contained" color="primary" onClick={() => props.onSearch?.()}>{lang.actionMatch}</Button>
                </Grid>

              </Grid>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};

const renderFeatureSearch = (searchForm: FormPresenter<PropertyStockSearchFormValues>, locale: LocaleStateRemote) => {
  const bind = searchForm.bind;
  const { langPropertyStock: lang, facingOptions,
    decoOptions,
    viewOptions,
    otherFeatureOptions,
    clubHouseFacilitiesOptions,
    otherOptions,
    primarySchoolNetOptions,
    secondarySchoolNetOptions,
  } = locale;

  const multiSelectField = (key: keyof typeof searchForm.values, label: string, options: { [n: string]: string }) => {
    return <React.Fragment>
      <FormControl variant="outlined" margin="dense" fullWidth>
        <InputLabel variant="outlined" style={{ background: '#fff' }}>{label}</InputLabel>
        <Select
          multiple
          {...searchForm.bind(key)}
          renderValue={(selected) => (selected as string[]).map(key => options?.[key] ?? key).join(', ')}
        >
          {Object.keys(options ?? {}).map(val => (
            <MenuItem key={val} value={val}>
              <Checkbox checked={(searchForm.values[key]! as string[]).indexOf(val) > -1} />
              <ListItemText primary={options[val]} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </React.Fragment>
  };

  return <Fragment>
    <Grid
      container
      spacing={1}
    >
      <Grid
        item
        md={2} sm={2}
        xs={12}
      >
        <FormControl variant="outlined" margin="dense" fullWidth>
          <InputLabel variant="outlined" style={{ background: '#fff' }}>{lang.facing}</InputLabel>
          <Select
            multiple
            {...bind('facing')}
            renderValue={(selected) => (selected as string[]).map(key => facingOptions[key]).join(', ')}
          >
            {Object.keys(facingOptions).map(key => (
              <MenuItem key={key} value={key}>
                <Checkbox checked={searchForm.values.facing!.indexOf(key) > -1} />
                <ListItemText primary={facingOptions[key]} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid
        item
        md={2} sm={2}
        xs={12}
      >
        <FormControl variant="outlined" margin="dense" fullWidth>
          <InputLabel variant="outlined" style={{ background: '#fff' }}>{lang.deco}</InputLabel>
          <Select
            multiple
            {...bind('deco')}
            renderValue={(selected) => (selected as string[]).map(key => decoOptions[key]).join(', ')}
          >
            {Object.keys(decoOptions).map(key => (
              <MenuItem key={key} value={key}>
                <Checkbox checked={searchForm.values.deco!.indexOf(key) > -1} />
                <ListItemText primary={decoOptions[key]} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid
        item
        md={2} sm={2}
        xs={12}
      >
        {/* <Typography>景觀</Typography> */}
        <FormControl variant="outlined" margin="dense" fullWidth>
          <InputLabel variant="outlined" style={{ background: '#fff' }}>{lang.view}</InputLabel>
          <Select
            multiple
            {...bind('view')}
            renderValue={(selected) => (selected as string[]).map(key => viewOptions[key]).join(', ')}
          >
            {Object.keys(viewOptions).map(view => (
              <MenuItem key={view} value={view}>
                <Checkbox checked={searchForm.values.view!.indexOf(view) > -1} />
                <ListItemText primary={viewOptions[view]} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid
        item
        md={2} sm={2}
        xs={12}
      >
        {/* <Typography>特色</Typography> */}
        <FormControl variant="outlined" margin="dense" fullWidth>
          <InputLabel variant="outlined" style={{ background: '#fff' }}>{lang.otherFeature}</InputLabel>
          <Select
            multiple
            {...bind('otherFeatures')}
            renderValue={(selected) => (selected as string[]).map(key => otherFeatureOptions[key]).join(', ')}
          >
            {Object.keys(otherFeatureOptions).map(feature => (
              <MenuItem key={feature} value={feature}>
                <Checkbox checked={searchForm.values.otherFeatures!.indexOf(feature) > -1} />
                <ListItemText primary={otherFeatureOptions[feature]} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid
        item
        md={2} sm={2}
        xs={12}
      >
        <FormControl variant="outlined" margin="dense" fullWidth>
          <InputLabel variant="outlined" style={{ background: '#fff' }}>{lang.clubHouseFacilities}</InputLabel>
          <Select
            multiple
            {...bind('clubHouseFacilities')}
            renderValue={(selected) => (selected as string[]).map(key => clubHouseFacilitiesOptions[key]).join(', ')}
          >
            {Object.keys(clubHouseFacilitiesOptions).map(feature => (
              <MenuItem key={feature} value={feature}>
                <Checkbox checked={searchForm.values.clubHouseFacilities!.indexOf(feature) > -1} />
                <ListItemText primary={clubHouseFacilitiesOptions[feature]} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid
        item
        md={2} sm={2}
        xs={12}
      >
        <FormControl variant="outlined" margin="dense" fullWidth>
          <InputLabel variant="outlined" style={{ background: '#fff' }}>{lang.others}</InputLabel>
          <Select
            multiple
            {...bind('others')}
            renderValue={(selected) => (selected as string[]).map(key => otherOptions[key]).join(', ')}
          >
            {Object.keys(otherOptions).map(feature => (
              <MenuItem key={feature} value={feature}>
                <Checkbox checked={searchForm.values.others!.indexOf(feature) > -1} />
                <ListItemText primary={otherOptions[feature]} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid
        item
        md={1} sm={2}
        xs={6}
      >
        {multiSelectField('room', lang.room, roomCountOptions)}
      </Grid>

      <Grid
        item
        md={1} sm={2}
        xs={6}
      >
        {/* <Typography>套房</Typography> */}
        {multiSelectField('suite', lang.suite, roomCountOptions)}
      </Grid>

      {/* <Grid
        item
        md={2} sm={2}
        xs={12}
      >
        {multiSelectField('livingRooms', lang.livingRooms, livingRoomOptions)}
      </Grid> */}

      <Grid
        item
        md={1} sm={2}
        xs={6}
      >
        {/* <Typography>工人房</Typography> */}
        {multiSelectField('helperRoom', lang.helperRoom, roomCountOptions)}
      </Grid>

      <Grid
        item
        md={1} sm={2}
        xs={6}
      >
        {/* <Typography>浴室</Typography> */}
        {multiSelectField('bathroom', lang.bathroom, roomCountOptions)}
      </Grid>

      <Grid
        item
        md={1} sm={2}
        xs={6}
      >
        {/* <Typography>露台</Typography> */}
        {multiSelectField('balcony', lang.balcony, roomCountOptions)}
      </Grid>

      <Grid
        item
        md={3} sm={3}
        xs={12}
      >
        <FormControl variant="outlined" margin="dense" fullWidth>
          <InputLabel variant="outlined" style={{ background: '#fff' }}>{lang.primarySchoolNet}</InputLabel>
          <Select
            multiple
            {...bind('primarySchoolNet')}
            renderValue={(selected) => (selected as string[]).map(key => primarySchoolNetOptions[key]).join(', ')}
          >
            {Object.keys(primarySchoolNetOptions).map(key => (
              <MenuItem key={key} value={key}>
                <Checkbox checked={searchForm.values.primarySchoolNet!.indexOf(key) > -1} />
                <ListItemText primary={primarySchoolNetOptions[key]} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid
        item
        md={4} sm={4}
        xs={12}
      >
        <FormControl variant="outlined" margin="dense" fullWidth>
          <InputLabel variant="outlined" style={{ background: '#fff' }}>{lang.secondarySchoolNet}</InputLabel>
          <Select
            multiple
            {...bind('secondarySchoolNet')}
            renderValue={(selected) => (selected as string[]).map(key => secondarySchoolNetOptions[key]).join(', ')}
          >
            {Object.keys(secondarySchoolNetOptions).map(key => (
              <MenuItem key={key} value={key}>
                <Checkbox checked={searchForm.values.secondarySchoolNet!.indexOf(key) > -1} />
                <ListItemText primary={secondarySchoolNetOptions[key]} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

    </Grid>
  </Fragment>
}

export default PropertiesMatchingSearch;