import { FormValidations } from './hooks';
import { isNonEmpty } from './utils';

const checked = (value: boolean, options: any) => {
  if (value !== true) {
    return options.message || 'must be checked';
  }
};

export const numberFields = <T>(keys: Array<keyof T>, message: string): FormValidations<T> => {
  return keys.map(key => 
    (values: Partial<T>) => !isNonEmpty(values[key]) || !isNaN(Number(String(values[key]))) ? null : [[
      key, message
    ]]
  );
};

export default {
  checked
};
