import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import { FormPresenter } from 'common/hooks';
import React from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';

interface DialogProps {
  open: boolean;
  handleClose: () => void;
  handleClickOpen: () => void;
  selectedFloorDefinition: number;
  removeFloorDefinition: (i:number) => void;
  floorForm: FormPresenter<any>;
}

const useStyles = makeStyles(() => ({
	
	dialogContent: {
    width: 300,
    display: 'flex',
		alignItems: 'center',
	},
	
}));

export const ConfirmDeleteDialog = ({open, handleClose, selectedFloorDefinition, floorForm }: DialogProps) => {
  const classes = useStyles();

  const { lang, langBuilding } = useSelector((state: IRootState) => state.locale);

  const doRemove = () => {
    floorForm.updateValues('floorDefinition', floorForm.values.floorDefinition.filter((_: any, _i: number) => selectedFloorDefinition !== _i));
    handleClose();
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
       
        <DialogContent className={classes.dialogContent}>
          <DialogContentText id="alert-dialog-description">
            {langBuilding.msgConfirmDeleteFloor}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
          {lang.actionCancel}
          </Button>
          <Button onClick={doRemove} color="primary" variant="contained"  autoFocus>
            {lang.actionConfirm}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}