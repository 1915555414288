import {
  Card, CardContent, CardHeader, colors, DialogContent, DialogTitle,
  FormHelperText, Grid,
  IconButton as MuiIconButton, InputAdornment, Menu, MenuItem, TextareaAutosize, TextField,
  TextFieldProps,
  Tooltip, useMediaQuery, useTheme, withStyles
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { default as MuiButton } from '@material-ui/core/Button';
import { green, red } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import Slide from '@material-ui/core/Slide';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { TransitionProps } from '@material-ui/core/transitions';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import LinkIcon from '@material-ui/icons/Link';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { userApi } from 'api/userApi';
import { useConfirmDialog } from 'common/ConfirmDialog';
import {
  ClientDetailDTO,
  ClientFileMap, CloseTransactionAgentDTO, CloseTransactionClientDTO, CloseTransactionDTO, Form4DTO,
  Form6DTO, PropertyFileMap, PropertyStockDTO, UserListItemDTO
} from 'common/dto';
import ClientSelectionDialog from 'common/elements/ClientSelectionDialog';
import GenericSigningDialog from 'common/elements/GenericSigningDialog';
import NavigationBlockerDialog from 'common/elements/NavigationBlockerDialog';
import { useAppBarHeight, useForm } from 'common/hooks';
import { HandleFormHelperText, hideWhenHoc, tooltipHoc } from 'common/ui';
import {
  genAddress, initFormKeysAndBooleanMap, isNonEmpty, limitNumberFieldLength, limitTextFieldLength, multiLang,
  numericValue
} from 'common/utils';
import deepEqual from 'deep-equal';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { IMaskInput } from 'react-imask';
import { useDispatch, useSelector } from 'react-redux';
import { CloseTransactionActions, IRootState, PASDispatch } from 'reducers';
import closeTransactionFormValidations from '../close-transaction-validation';
import Documents from './Documents';
import { isEmpty } from 'lodash';
const Button = hideWhenHoc(MuiButton);
const IconButton = tooltipHoc(MuiIconButton);
const regex = new RegExp(/(^\d*\.?\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    dialogTitle: {
      marginLeft: theme.spacing(2),
      flex: 1,
      color: 'white',
      fontWeight: 'bold',
    },
    dialogRoot: {
      '& .MuiDialog-paper': {
        overflowX: 'hidden',
      }
    },
    patchOutlineAutocomplete: {
      '& .MuiAutocomplete-inputRoot': {
        padding: 0,
        paddingLeft: 8,
      }
    },
    fileItem: {
      '& .fileControl': {
        display: 'none',
      },
      '&:hover .fileControl': {
        display: 'inherit',
      },
    },
    rejectionDialogContent: {
      width: 600,
      display: 'flex',
      alignItems: 'center',
      color: '#e53935'
    },
    textArea: {
      width: '100%',
      margin: 'dense',
      padding: '8px',
      fontSize: '14px'
    },
    dollarField: {
      '& input': {
        textAlign: 'right',
      },
    },
  }),
);


const NumberField = withStyles({
  root: {
    '& input': {
      textAlign: 'right',
      '-moz-appearance': 'textfield',
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  },
},
)((props: TextFieldProps) => {
  return <TextField type={"text"}{...props} inputProps={{
    ...props.inputProps,
    min: 0,
    max: Math.max(),
    inputMode: 'decimal',
    pattern: "([^0-9]*)",
    step: 0.01
  }}
    onKeyDown={e => {
      // If the key pressed is not a number or a period, nothing is printed
      if (!e.key.match(regex)) {

        e.preventDefault();

      }
      // if (!e.key.match(regex) || (e.key === '.' && String(props.value).indexOf('.') > 0) || (e.key === '.' && String(props.value).length === 0) || e.keyCode === 229 || e.key === 'Unidentified') {

      //   e.preventDefault();

      // }

    }}

    onKeyPress={e => {
      // If the key pressed is not a number or a period, nothing is printed
      if (!e.key.match(regex)) {

        e.preventDefault();

      }
    }}


  />;
});

const MaskedInput = (props: TextFieldProps) => {
  return (
    <IMaskInput
      {...props}
      mask={Number}
      unmask={true}
      thousandsSeparator=","
      radix="."
    />
  );
}

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const errorColor = '#e53935';

interface DialogProps {
  open: boolean;
  handleClose: () => void;
  handleClickOpen: () => void;
  propertyStockId: string;
  propertyNo: string;
  formPaTable?: JSX.Element;
}

const BLANK = {};


const CloseTransactionDialog = ({ propertyStockId, propertyNo, open, handleClose, formPaTable }: DialogProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch() as PASDispatch;
  const { iosStatusBarHeight } = useSelector((state: IRootState) => state.layout);
  const myId = useSelector((state: IRootState) => state.login.uid);
  const myPrivileges = useSelector((state: IRootState) => state.login.privileges);
  const appBarHeight = useAppBarHeight();
  const isOfficeAdmin = isNonEmpty(myPrivileges) ? myPrivileges!.filter(p => p.role === "OFFICE_ADMIN").length > 0 : false;
  const isAdmin = isNonEmpty(myPrivileges) ? myPrivileges!.filter(p => p.role === "ADMIN").length > 0 : false;
  const { lang, langCloseTransaction, agentTypeOptions, langPropertyStock, districtHkiOptions, districtKltOptions, districtNtOptions, langSalePurchaseAgreement, langTenancyAgreement, locale, clientFormSelections } = useSelector((state: IRootState) => state.locale);
  const closeTransactionDto = useSelector((state: IRootState) => state.closeTransaction.fetchedDetails[propertyStockId!]) ?? BLANK as Partial<CloseTransactionDTO>;
  const propertyStockDto = useSelector((state: IRootState) => state.property.fetchedDetails[propertyNo!]) ?? BLANK as Partial<PropertyStockDTO>;
  const formCheckDisplayName: { [key: string]: string } = {
    FORM_1: langPropertyStock.titleForm1,
    FORM_2: langPropertyStock.titleForm2,
    FORM_3: langPropertyStock.titleForm3,
    FORM_4: langPropertyStock.titleForm4,
    FORM_5: langPropertyStock.titleForm5,
    FORM_6: langPropertyStock.titleForm6,
    LAND_SEARCH: langPropertyStock.landSearch,
    PROVISIONAL_AGREEMENT: langPropertyStock.provisionalAgreement,
  };

  const canReadForm3 = isOfficeAdmin || isAdmin;
  const canReadForm5 = isOfficeAdmin || isAdmin;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const form = useForm<Partial<CloseTransactionDTO>>({
    // id: string;
    // propertyStockId: string;
    rejectionRemarks: undefined,
    price: undefined,
    buyerComm: 0,
    buyerCommAgentShare: 0,
    sellerComm: 0,
    sellerCommAgentShare: 0,
    status: '',
    formCheckList: [],
    attachments: [],
    sellerClients: [],
    sellerAgents: [{
      type: 'I',
      clientType: closeTransactionDto.tranType === 'SALE' ? 'SELLER' : 'LANDLORD',
      name: '',
      agentId: 0, //User id
      contact: '',
      commission: null,
      remark: '',
      confirmed: 'N',
      shouldSign: false
    }],
    buyerClients: [],
    buyerAgents: [{
      type: 'I',
      clientType: closeTransactionDto.tranType === 'SALE' ? 'BUYER' : 'TENANT',
      name: '',
      agentId: 0, //User id
      contact: '',
      commission: null,
      remark: '',
      confirmed: 'N',
      shouldSign: false
    }],
  }, { validations: closeTransactionFormValidations(langCloseTransaction), scrollToErrorOnValidate: true, dataKeyPrefix: 'closetransaction' });

  const bind = form.bind;

  // Field access/edit control //
  const {
    canSave,
    canRequestForConfirmation,
    canConfirm,
    canRequestForApproval,
    canApprove,
    canEditSellerFields,
    canEditBuyerFields,
    allFieldsReadOnly,
  } = form.values;
  const canEditLinkedClientMaster = (agent: CloseTransactionAgentDTO) => canEditBuyerFields && agent.shouldSign && agent.agentId === +userId;
  const canDeleteBuyerAgent = (agent: CloseTransactionAgentDTO) => canEditBuyerFields && !agent.shouldSign;
  const canDeleteSellerAgent = (agent: CloseTransactionAgentDTO) => canEditSellerFields && !agent.shouldSign;

  const districtOptions = {
    ...districtHkiOptions,
    ...districtKltOptions,
    ...districtNtOptions,
  };

  const propertyAddress = genAddress(locale,
    propertyStockDto.unit?.join(',') ?? '',
    propertyStockDto.floor?.join(',') ?? '',
    propertyStockDto.block ?? '', propertyStockDto.blockEn ?? '',
    propertyStockDto.building ?? '', propertyStockDto.buildingEn ?? '',
    propertyStockDto.street ?? '', propertyStockDto.streetEn ?? '',
    districtOptions[propertyStockDto.district ?? '']);

  const isSale = form.values.tranType === 'SALE';
  const isBuyerAgent = isNonEmpty(form.values.buyerAgents) ? form!.values.buyerAgents!.filter(f => f.agentId?.toString() === myId && f.shouldSign === true).length > 0 : false;
  const isSellerAgent = isNonEmpty(form.values.sellerAgents) ? form!.values.sellerAgents!.filter(f => f.agentId?.toString() === myId && f.shouldSign === true).length > 0 : false;
  const canReadBuyerDetails = (isAdmin || isOfficeAdmin || isBuyerAgent);
  const canReadSellerrDetails = (isAdmin || isOfficeAdmin || isSellerAgent);
  const fileForm = useForm<PropertyFileMap>({
    CLOSE_TRNX_SUPP: form.values.attachments ?? []
  });

  const sumAgentCommission = (agents?: CloseTransactionAgentDTO[]) => {
    return agents?.map(ba => ba.commission ?? 0).reduce((a, b) => a + b, 0) ?? 0;
  }
  const [initialFormValues, setInitialFormValues] = useState<any>({});

  const unsaved = (!deepEqual([form.values, fileForm.values], initialFormValues, { strict: true }) || !isNonEmpty(closeTransactionDto.id));

  // PMS Client master linkage
  const [clientSelectionOpenForIdx, setClientSelectionOpenForIdx] = useState(-1);
  const [linkedClientMenuOpenIdx, setLinkedClientMenuOpenIdx] = useState<number>(-1);
  const linkedClientMenuButtonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (propertyStockId) {
      dispatch({ type: 'CloseTransaction.FetchRequested', payload: { pid: propertyStockId } } as CloseTransactionActions);
    }

  }, [propertyStockId]);

  useEffect(() => {
    if (open) {
      const initialForm = Object.assign({
        ...form.values,
        ...closeTransactionDto,
        premises: (closeTransactionDto.premises) ? closeTransactionDto.premises : propertyAddress,
        price: closeTransactionDto.price,
      });
      form.setValues({
        ...initialForm
      });

      fileForm.updateValues('CLOSE_TRNX_SUPP', closeTransactionDto.attachments ?? []);

      setInitialFormValues([initialForm, {
        'CLOSE_TRNX_SUPP': closeTransactionDto.attachments,
      }]);
    }

  }, [closeTransactionDto, locale, open]);

  const handleSave = (extraAction?: 'REQUEST_FOR_CONFIRMATION') => {

    if (form.values.id != undefined && form.values.id != null) {
      dispatch({
        type: 'CloseTransaction.UpdateRequested',
        payload: {
          ...form.values,
          attachments: fileForm.values['CLOSE_TRNX_SUPP'],
          premises: form.values.premises,
          price: form.values.price,
          extraAction
        } as CloseTransactionDTO
      });
    } else {
      dispatch({
        type: 'CloseTransaction.AddRequested',
        payload: {
          ...form.values,
          attachments: fileForm.values['CLOSE_TRNX_SUPP'],
          price: form.values.price,
          extraAction
        } as CloseTransactionDTO
      });
    };
  }

  //get linked buyer client
  const initCid: string | undefined = form.values.buyerAgents?.[0].linkedBuyerClientId?.toString();
  const clientDetailDto = useSelector((state: IRootState) => state.clients.fetchedDetails[initCid ?? '']) ?? BLANK as Partial<ClientDetailDTO>;
  //

  useEffect(() => {

    if (isNonEmpty(initCid) && form.values.buyerAgents![0].agentId?.toString() === myId) {
      dispatch({ type: 'ClientDetail.FetchRequested', payload: { cid: initCid! } });
    }
  }, [initCid]);



  const currentLinkedClientForm46 = useForm<ClientFileMap>({
    FORM_4: [],
    FORM_6: [],
  });

  //take form4/form6 when formProperty inclueds this propertyStockId or is empty
  useEffect(() => {

    const fileMap: ClientFileMap = {
      FORM_4: [],
      FORM_6: [],
    };
    clientDetailDto.form4Attachments?.filter(f => f.formProperty.filter(f => f.propertyStockId === propertyStockId).length > 0 || !isNonEmpty(f.formProperty)).forEach(attachment => {
      const type = 'FORM_4';
      if (fileMap[type] && attachment.dataStatus === 'A') {
        fileMap[type].push(attachment);
      }
    });

    clientDetailDto.form6Attachments?.filter(f => f.formProperty.filter(f => f.propertyStockId === propertyStockId).length > 0 || !isNonEmpty(f.formProperty)).forEach(attachment => {
      const type = 'FORM_6';
      if (fileMap[type] && attachment.dataStatus === 'A') {
        fileMap[type].push(attachment);
      }
    });
    currentLinkedClientForm46.setValues(fileMap);
  }, [clientDetailDto]);

  const canReadForm46 = (isOfficeAdmin || isAdmin || form.values.buyerAgents?.[0].agentId?.toString() === myId);

  const validForm46 = () => {
    let ctForm4: Form4DTO[] | undefined = [];
    let ctForm6: Form6DTO[] | undefined = [];
    if (closeTransactionDto.ctForm4 && canReadForm46) {
      ctForm4.push(closeTransactionDto.ctForm4!);
    };
    if (closeTransactionDto.ctForm6 && canReadForm46) {
      ctForm6.push(closeTransactionDto.ctForm6!);
    };
    let ctForm46 = {
      form4: ctForm4,
      form6: ctForm6,
    }
    return ctForm46;
  }

  const validForm = {
    ...formPaTable,
    props: {
      ...formPaTable?.props,
      showForm46: canReadForm46,
      fileForm: {
        values: {
          ...formPaTable?.props.fileForm.values,
          //FORM_3: canReadForm3 ? formPaTable?.props.fileForm.values.FORM_3 : formPaTable?.props.fileForm.values.FORM_3.filter((form: { agentUserId: string | undefined; }) => form.agentUserId?.toString() === myId),
          //FORM_5: canReadForm5 ? formPaTable?.props.fileForm.values.FORM_5 : formPaTable?.props.fileForm.values.FORM_5.filter((form: { agentUserId: string | undefined; }) => form.agentUserId?.toString() === myId),
        }
      },
      clientFileForm: {
        values: {
          ...formPaTable?.props.clientFileForm.values,
          FORM_4: validForm46().form4,
          FORM_6: validForm46().form6,
        }
      },
    }
  };

  type fileTypes = 'FORM_1' | 'FORM_2' | 'FORM_3' | 'FORM_4' | 'FORM_5' | 'FORM_6' | 'LAND_SEARCH' | 'PROVISIONAL_AGREEMENT';

  const filesCheckingForSale: Partial<Record<fileTypes, boolean>> = {
    FORM_1: isNonEmpty(validForm?.props.fileForm.values.FORM_1),
    FORM_3: isNonEmpty(formPaTable?.props.fileForm.values.FORM_3),
    FORM_4: isNonEmpty(closeTransactionDto.ctForm4),
    LAND_SEARCH: isNonEmpty(validForm?.props.fileForm.values.LAND_SEARCH),
    PROVISIONAL_AGREEMENT: isNonEmpty(validForm?.props.fileForm.values.PROVISIONAL_AGREEMENT),
  }

  const filesCheckingForRent: Partial<Record<fileTypes, boolean>> = {
    FORM_2: isNonEmpty(validForm?.props.fileForm.values.FORM_2),
    FORM_5: isNonEmpty(formPaTable?.props.fileForm.values.FORM_5),
    FORM_6: isNonEmpty(closeTransactionDto.ctForm6),
    LAND_SEARCH: isNonEmpty(validForm?.props.fileForm.values.LAND_SEARCH),
    PROVISIONAL_AGREEMENT: isNonEmpty(validForm?.props.fileForm.values.PROVISIONAL_AGREEMENT),
  }

  const filesChecking = isSale ? filesCheckingForSale : filesCheckingForRent;

  const [missingDoc, setMissingDoc] = useState(false);

  const missingDocs = () => {
    let result = {
      missing: false,
      msg: ''
    }
    if (isSale) {
      if (form.values.sellerAgents![0].type === 'E') {
        if (!isNonEmpty(filesChecking.FORM_1) || !isNonEmpty(filesChecking.FORM_4)) {
          result.missing = true;
          result.msg = langCloseTransaction.confirmForm14;
        }
      }
      else {
        result.missing = isSale ? !(filesChecking.FORM_1 && filesChecking.FORM_3 && (filesChecking.FORM_4 || !canReadForm46 || (form.values.sellerAgents?.[0].agentId?.toString() === myId && form.values.buyerAgents?.[0].agentId?.toString() !== myId))) : !(filesChecking.FORM_2 && filesChecking.FORM_5 && (filesChecking.FORM_6 || !canReadForm46 || (form.values.sellerAgents?.[0].agentId?.toString() === myId && form.values.buyerAgents?.[0].agentId?.toString() !== myId)));
        if (result.missing && canReadForm46) {
          result.msg = langCloseTransaction.confirmForm134;
        } else if (result.missing && !canReadForm46) {
          result.msg = langCloseTransaction.confirmForm13;
        }
      }
    }
    if (!isSale) {

      if (form.values.sellerAgents![0].type === 'E') {
        if (!isNonEmpty(filesChecking.FORM_2) || !isNonEmpty(filesChecking.FORM_6)) {
          result.missing = true;
          result.msg = langCloseTransaction.confirmForm26;
        }
      } else {
        result.missing = isSale ? !(filesChecking.FORM_1 && filesChecking.FORM_3 && (filesChecking.FORM_4 || !canReadForm46 || (form.values.sellerAgents?.[0].agentId?.toString() === myId && form.values.buyerAgents?.[0].agentId?.toString() !== myId))) : !(filesChecking.FORM_2 && filesChecking.FORM_5 && (filesChecking.FORM_6 || !canReadForm46 || (form.values.sellerAgents?.[0].agentId?.toString() === myId && form.values.buyerAgents?.[0].agentId?.toString() !== myId)));
        if (result.missing && canReadForm46) {
          result.msg = langCloseTransaction.confirmForm256;
        } else if (result.missing && !canReadForm46) {
          result.msg = langCloseTransaction.confirmForm25;
        }
      }
    }
    if (result.missing === false) {
      result.missing = (!filesChecking.LAND_SEARCH || !filesChecking.PROVISIONAL_AGREEMENT);
      if (result.missing) {
        result.msg = langCloseTransaction.confirmProvisionalAndLandSearch;
      }
    }
    return result;
  };


  const handleConfirmationRequest = () => {
    setMissingDoc(missingDocs().missing);
    if (!form.validate() || missingDocs().missing) {
      if (form.validate() && missingDocs().missing) {
        document.querySelector(`[data-key="field-closetransaction-missingDoc"]`)?.scrollIntoView();
      }
    }
    // handleSave('REQUEST_FOR_CONFIRMATION');
    else if (form.values.id !== undefined && form.values.id !== null) {
      dispatch({ type: 'CloseTransaction.RequestConfirmationRequested', payload: { id: form.values.id } });
    }
  }

  // ------ confirmation & agent signing ----- //


  const handleConfirmation = () => {
    setMissingDoc(missingDocs().missing);
    if (!form.validate() || missingDocs().missing) {
      if (form.validate() && missingDocs().missing) {
        document.querySelector(`[data-key="field-closetransaction-missingDoc"]`)?.scrollIntoView();
      }
    }
    else if (form.values.id !== undefined && form.values.id !== null) {
      setConfirmationSigningDialogOpen(true);
    }
  }

  const handleConfirmSigned = (signs: { [key: string]: string }) => {

    setConfirmationSigningDialogOpen(false);
    if (form.values.id !== undefined && form.values.id !== null) {
      dispatch({ type: 'CloseTransaction.ConfirmationRequested', payload: { id: form.values.id, signatureBlobUrl: signs['agentSign'] } });
    }
  }

  const [confirmationSigningDialogOpen, setConfirmationSigningDialogOpen] = useState(false);
  const confirmationSignSpecs = [
    { title: <b>{langCloseTransaction.msgAgentConfirm}</b>, name: 'agentSign' },
  ];

  // ----- approval ----- //

  const confirmApprovalDialog = useConfirmDialog();


  const handleApprove = () => {
    confirmApprovalDialog.confirm(langCloseTransaction.msgConfirmApproval, lang.actionConfirm, lang.actionCancel).then((confirmed) => {
      if (confirmed) {
        dispatch({ type: 'CloseTransaction.ApproveRequested', payload: { id: form.values.id, approverSignBlobUrl: '' } } as CloseTransactionActions);
      }
    })
    // setApprovalSigningDialogOpen(true);
  };

  const handleSignAndApprove = (signs: { [key: string]: string }) => {
    if (form.values.id !== undefined && form.values.id !== null) {
      dispatch({ type: 'CloseTransaction.ApproveRequested', payload: { id: form.values.id, approverSignBlobUrl: signs['approverSign'] } } as CloseTransactionActions);
    }
    setApprovalSigningDialogOpen(false);
  };

  const [approvalSigningDialogOpen, setApprovalSigningDialogOpen] = useState(false);
  const approvalSignSpecs = [
    { title: <b>{langCloseTransaction.msgApproverConfirm}</b>, name: 'approverSign' },
  ];

  const addAgent = (type: string) => {
    switch (type) {
      case 'SELLER':
        form.updateValues('sellerAgents',
          [...form.values.sellerAgents ?? [], {
            type: 'I',
            clientType: closeTransactionDto.tranType === 'SALE' ? 'SELLER' : 'LANDLORD',
            name: '',
            id: '', //User id
            contact: '',
            commission: null,
            remark: '',
            confirmed: 'N',
            shouldSign: false
          }]
        );
        break;
      case 'BUYER':
        form.updateValues('buyerAgents',
          [...form.values.buyerAgents ?? [], {
            type: 'I',
            clientType: closeTransactionDto.tranType === 'SALE' ? 'BUYER' : 'TENANT',
            name: '',
            id: '', //User id
            contact: '',
            commission: null,
            remark: '',
            confirmed: 'N',
            shouldSign: false
          }]
        );
        break;
      default:
        break;
    }
  }

  const [keyBooleanMap, setKeyBooleanMap] = useState(initFormKeysAndBooleanMap(form));

  useEffect(() => {
    setKeyBooleanMap(initFormKeysAndBooleanMap(form));
  }, [Object.keys(form.values).length])

  const removeSellerAgent = (i: number) => {
    form.updateValues('sellerAgents',
      form.values.sellerAgents?.filter((_: any, _i: number) => i !== _i)
    );
  }



  const removeBuyerAgent = (i: number) => {
    form.updateValues('buyerAgents',
      form.values.buyerAgents?.filter((_: any, _i: number) => i !== _i)
    );
  }

  const token: string = useSelector((state: IRootState) => state.login.token ?? '');
  const userId: string = useSelector((state: IRootState) => state.login.uid ?? '');
  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);
  //auto complete for agent
  const [agents, setAgents] = useState<UserListItemDTO[]>([]);
  const [agentListLoading, setAgentListLoading] = useState(false);
  const [agentUsername, setAgentUsername] = useState(agents.filter(agent => agent.username != 'companyStockAgent').map((agent) => agent.username));
  const fetchAgents = (keyword: string) => {
    if (keyword.length < 2) { return; }
    setAgentListLoading(true);
    userApi.getListForAutocomplete({ name: keyword, onlyHasCreateOwnClientPrivilege: true }, token).then(result => {
      if (result.data?.content) {
        setAgents(result.data!.content);
        setAgentListLoading(false);
        setAgentUsername(result.data!.content.filter(agent => agent.username != 'companyStockAgent').map((agent) => agent.username));
      }
    });
  }
  // const agentNames = agents.map((agent) => locale === 'en' ? agent.englishName : agent.englishName);
  const [agentMap, setAgentMap] = useState<{ [name: string]: UserListItemDTO }>({});
  useEffect(() => {
    let tempObj: { [name: string]: UserListItemDTO } = {};
    agents.forEach((agent) => {
      tempObj[agent.username] = agent;
    })
    setAgentMap(tempObj);
  }, [agents])

  const [keywordOfAgent, setKeywordOfAgent] = useState('')

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchAgents(keywordOfAgent);
    }, 1000);
    return () => clearTimeout(timer);
  }, [keywordOfAgent]);

  const confirmDeleteDialog = useConfirmDialog();
  const confirmUploadDialog = useConfirmDialog();

  // ------  handle rejection ------//
  const [rejectionDialogOpen, setRejectionDialogOpen] = useState(false);
  const showRejectionDialog = () => {
    setRejectionDialogOpen(true);
  };

  const closeRejectionDialog = () => {
    setRejectionDialogOpen(false);
  };

  const confirmRejection = () => {
    const rejectionRemarksForm = {
      rejectionRemarks: form.values.rejectionRemarks,
      id: form.values.id,
    } as Partial<CloseTransactionDTO>;
    dispatch({ type: 'CloseTransaction.RejectRequested', payload: { id: form.values.id, remarks: rejectionRemarksForm } } as CloseTransactionActions);
    setRejectionDialogOpen(false);
  }

  //validation 
  const getAgentValidationMsg = (agentClientType: string, infoType: string, value: any) => {
    const formHasError = agentClientType === 'BUYER' ? !!form.hasError('hasInvalidBuyerAgent')
      : !!form.hasError('hasInvalidSellerAgent');

    if (formHasError && infoType == 'commission' && numericValue(value) === undefined) {
      return langCloseTransaction.pleaseInput + langCloseTransaction.captionCommission
    }
    if (formHasError && !isNonEmpty(value)) {
      switch (infoType) {
        case 'type': return langCloseTransaction.pleaseSelect + langCloseTransaction.captionAgentType;
        case 'name': return langCloseTransaction.pleaseInput + langCloseTransaction.captionAgentName;
        case 'contact': return langCloseTransaction.pleaseInput + langCloseTransaction.captionAgentContact;
        // case 'commission': return langCloseTransaction.pleaseInput + langCloseTransaction.captionCommission;
        default: return '';
      }
    }
    return '';
  }

  const uploadDocOptions: { [key: string]: string } = {
    DUMMY: lang.actionUpload,
    CLOSE_TRNX_SUPP: langCloseTransaction.titleSupplementalDocuments,
  };

  //auto complete for completion
  useEffect(() => {
    //console.log("priceType", typeof (form.values.price), "initialDeposit", typeof (form.values.initialDeposit), "bigDeposit", typeof (form.values.bigDeposit), "completion", typeof (form.values.completion));
    //console.log("price", form.values.price, "initialDeposit", form.values.initialDeposit, "bigDeposit", form.values.bigDeposit, "completion", form.values.completion);
    if (form.values.tranType === 'SALE') {
      form.updateValues('completion',
        (form.values.price === null
          || form.values.initialDeposit === null
          || form.values.bigDeposit === null) ? 0 : ((Number(form.values.initialDeposit) ?? 0) + (Number(form.values.bigDeposit) ?? 0) <= (form.values.price ?? 0)) ?
          parseFloat(((form.values.price ?? 0) - (Number(form.values.initialDeposit) ?? 0) - (Number(form.values.bigDeposit) ?? 0)).toFixed(2)) : ((form.values.price ?? 0) - (form.values.price ?? 0))
      );
    }

  }, [form.values.price,
  form.values.initialDeposit,
  form.values.bigDeposit]);


  // useEffect(() => {
  // 	const timer = setTimeout(() => {
  // 		fetchAgents(keywordOfAgent);
  // 	}, 1000);
  // 	return () => clearTimeout(timer);
  // }, [keywordOfAgent]);

  return (
    <div>
      <Dialog style={{ paddingTop: appBarHeight }} className={classes.dialogRoot} fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        {confirmDeleteDialog.render()}
        {confirmUploadDialog.render()}
        {confirmApprovalDialog.render()}
        <NavigationBlockerDialog matchesUrl={`/properties-edit/${propertyNo}/close-transaction-view`} unsaved={(unsaved) && (canSave ?? false)} goBack />
        <GenericSigningDialog
          key="confirmation-sign"
          open={confirmationSigningDialogOpen}
          signSpecs={confirmationSignSpecs}
          onClose={() => setConfirmationSigningDialogOpen(false)}
          onSigned={handleConfirmSigned}
        />
        <GenericSigningDialog
          key="approval-sign"
          open={approvalSigningDialogOpen}
          signSpecs={approvalSignSpecs}
          onClose={() => setApprovalSigningDialogOpen(false)}
          onSigned={handleSignAndApprove}
        />
        <AppBar style={{ position: 'fixed', top: 0, paddingTop: iosStatusBarHeight }} className={classes.appBar}>
          <Toolbar >
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.dialogTitle}>
              {langCloseTransaction.titleCloseTransactionRequest}
            </Typography>
            {/*Permission: lead agent*/}
            <Button hideWhen={!canSave} autoFocus color="inherit"
              style={{ fontSize: locale === 'en' && isSmallScreen ? 'x-small' : 'inherit', color: unsaved ? '#FFBF00' : 'inherit', fontWeight: unsaved ? 'bold' : 'normal' }}
              onClick={() => handleSave()}
            >
              {lang.actionSave}{unsaved ? '*' : ''}
            </Button>
            {/*Permission: lead agents*/}
            <Button style={{ fontSize: locale === 'en' && isSmallScreen ? 'x-small' : 'inherit' }} hideWhen={!canRequestForConfirmation || unsaved} autoFocus color="inherit" onClick={handleConfirmationRequest} >
              {langCloseTransaction.actionRequestForConfirmation}
            </Button>
            {/*Permission: All internal agents*/}
            <Button style={{ fontSize: locale === 'en' && isSmallScreen ? 'x-small' : 'inherit' }} hideWhen={!canConfirm || unsaved} autoFocus color="inherit" onClick={handleConfirmation}>
              {langCloseTransaction.actionConfirm}
            </Button>
            {/*Permission: Admin*/}
            <Button
              hideWhen={!canApprove || unsaved}
              onClick={handleApprove}
              autoFocus color="inherit">
              {langCloseTransaction.actionApprove}
            </Button>
            {/*Permission: Admin*/}
            <Button
              hideWhen={!canApprove || unsaved}
              onClick={showRejectionDialog}
              autoFocus color="inherit">
              {langCloseTransaction.actionReject}
            </Button>
            {/*Permission: lead agents*/}
            {canRequestForApproval && !canApprove && <Typography style={{ fontStyle: 'italic', fontWeight: 'bold', color: colors.yellow[400] }}>{langCloseTransaction.captureSubmitted}</Typography>}
            {form.values.status === 'A' && <Typography style={{ fontStyle: 'italic', fontWeight: 'bold', color: colors.green[400] }}>{langCloseTransaction.captureApproved}</Typography>}
            {/* <Button hideWhen={!canRequestForApproval || unsaved} autoFocus color="inherit" onClick={() => {
              confirmApprovalRequestDialog.confirm(langCloseTransaction.msgConfirmApprovalRequest, lang.actionConfirm, lang.actionCancel).then((confirmed) => {
                if (confirmed) {
                    handleApprovalRequest();
                    }
                  })
                }}>
              {langCloseTransaction.actionSubmitForApproval}
              </Button> */}
          </Toolbar>
        </AppBar>
        <div style={{ paddingTop: '8px', paddingLeft: '8px' }}>
          <form
            autoComplete="off"
            noValidate
          >
            <div
              // disabled={allFieldsReadOnly} 
              style={{ border: 0 }}
            >
              <Grid
                md={12}
                sm={12}
                xs={12}

              >
                <Card style={{ border: "none", boxShadow: "none" }}>

                  <CardHeader
                    titleTypographyProps={{ variant: 'h4', display: 'initial' }}

                    title={isSale ? langCloseTransaction.salePurchaseContract : langCloseTransaction.tenancyContract}
                  />
                </Card>
              </Grid>
              <Grid container /*xs={12}*/ spacing={1}>
                {missingDocs().missing ? <Grid
                  item
                  md={12}
                  xs={12}
                  style={{ display: missingDoc ? 'block' : 'none' }}
                >
                  <Card>
                    <CardContent>
                      <Grid
                        item
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        <Typography>{langCloseTransaction.missingDocuments}</Typography>

                        <TextField
                          data-key={"field-closetransaction-missingDoc"}
                          InputProps={{
                            classes: { disabled: classes.rejectionDialogContent },
                            disabled: true
                          }}
                          fullWidth
                          margin="dense"
                          required
                          variant="outlined"
                          value={missingDocs().msg}
                        />
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid> : null}
                <Grid
                  item
                  md={12}
                  xs={12}
                  style={{ display: form.values.status !== 'A' && isNonEmpty(form.values.rejectionRemarks) ? 'block' : 'none' }}
                >
                  <Card>
                    <CardContent>
                      <Grid
                        item
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        <Typography>{langCloseTransaction.captionRejectionRemarks}</Typography>

                        <TextField
                          InputProps={{
                            classes: { disabled: classes.rejectionDialogContent },
                            disabled: true
                          }}
                          fullWidth
                          margin="dense"
                          required
                          variant="outlined"
                          value={form.values.rejectionRemarks ?? ''}
                        />
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>

                <div
                  // disabled={!canEditSellerFields} 
                  style={{ border: 0 }}
                >
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <Card>
                      <CardHeader
                        title={langCloseTransaction.titleGeneralInformation}
                      />
                      <Divider />
                      <CardContent>
                        <Grid item container md={12} sm={12} xs={11} spacing={1}>
                          {/* <Grid
                          item   tmptmpcancel
                          md={4}
                          sm={4}
                          xs={12}
                        >
                        </Grid> */}
                          <Grid
                            item
                            container
                            md={12}
                            sm={10}
                            xs={8}
                            spacing={1}
                            style={{ paddingTop: '10px' }}
                          >
                            {Object.keys(filesChecking).map((key, idx) => {
                              return (
                                <Grid
                                  item
                                  md={2}
                                  sm={2}
                                  xs={5}
                                  key={idx}
                                >
                                  <Typography style={{ display: 'flex', alignItems: 'center', flexGrow: 2, width: 200 }}>
                                    {filesChecking[key as keyof typeof filesChecking] ?
                                      <CheckCircleIcon style={{ color: green[500] }} />
                                      : <CancelIcon style={{ color: red[400] }} />}

                                    {'  '}
                                    {formCheckDisplayName[key]}
                                  </Typography>
                                </Grid>
                              )
                            })}
                          </Grid>

                          {validForm ? <Grid
                            md={12}
                            sm={10}
                            xs={6}
                          >
                            {validForm}
                          </Grid> : null}

                          <Grid item container md={12} sm={12} xs={12} spacing={1} style={{ paddingTop: '10px' }}>
                            {
                              form.values.tranType === 'SALE' ?
                                <Grid
                                  item
                                  md={4}
                                  sm={4}
                                  xs={3}
                                >

                                  <NumberField
                                    required
                                    fullWidth
                                    className={classes.dollarField}
                                    label={langCloseTransaction.captionPrice}
                                    margin="dense"
                                    variant="outlined"
                                    InputProps={{
                                      disabled: allFieldsReadOnly || !canEditSellerFields,
                                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    {...bind('price')}
                                    onChange={(e) => {
                                      if (!isNonEmpty(e.target.value)) {
                                        form.updateValues('price', '');
                                      } else if (isNaN(parseInt(e.target.value))) {
                                        e.preventDefault();
                                      } else if (e.target.value.indexOf('.') != -1) {
                                        e.preventDefault()
                                      } else {
                                        e.target.value.length > 12 ? e.preventDefault() : form.updateValues('price', parseInt(e.target.value))
                                      }
                                    }}
                                    // onChange={(e) => {
                                    //   if (!isNonEmpty(e.target.value)) {
                                    //     form.updateValues('price', '');
                                    //   } else if (e.target.value.indexOf('.') != -1) {
                                    //     e.preventDefault();
                                    //   } else {
                                    //     e.target.value.length > 12 ? e.preventDefault() : form.updateValues('price', parseInt(e.target.value));
                                    //     console.log("e.target.value", parseInt(e.target.value) == NaN);
                                    //   }
                                    // }}
                                    error={!!form.hasError('price')}
                                    helperText={form.hasError('price')}

                                  />
                                  {/* <TextField
                                    data-key={'field-closetransaction-price'}
                                    required
                                    fullWidth
                                    label={langCloseTransaction.captionPrice}
                                    className={classes.dollarField}
                                    margin="dense"
                                    variant="outlined"
                                    error={!!form.hasError('price')}
                                    helperText={form.hasError('price')}
                                    InputProps={{
                                      disabled: allFieldsReadOnly || !canEditSellerFields,
                                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                      inputComponent: MaskedInput as any,
                                      inputProps: {
                                        value: bind('price').value?.toString() ?? null,
                                        onAccept: (value: any) => {
                                          bind('price').onChange({ target: { value: numericValue(value) } });
                                        }
                                      }
                                    }}
                                  />*/}
                                </Grid> :
                                <Grid
                                  item
                                  md={4}
                                  sm={4}
                                  xs={3}
                                >
                                  <NumberField
                                    required
                                    fullWidth
                                    className={classes.dollarField}
                                    label={langCloseTransaction.captionRent}
                                    margin="dense"
                                    variant="outlined"
                                    InputProps={{
                                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    {...bind('price')}
                                    onChange={(e) => {
                                      limitNumberFieldLength(e, 'price', form);
                                    }}
                                    error={!!form.hasError('price')}
                                    helperText={form.hasError('price')}
                                  />
                                  {/* <TextField
                                    data-key={'field-closetransaction-price'}
                                    required
                                    fullWidth
                                    label={langCloseTransaction.captionRent}
                                    className={classes.dollarField}
                                    margin="dense"
                                    variant="outlined"
                                    error={!!form.hasError('price')}
                                    helperText={form.hasError('price')}
                                    InputProps={{
                                      disabled: allFieldsReadOnly || !canEditSellerFields,
                                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                      inputComponent: MaskedInput as any,
                                      inputProps: {
                                        value: bind('price').value?.toString() ?? null,
                                        onAccept: (value: any) => {
                                          bind('price').onChange({ target: { value: numericValue(value) } });
                                        }
                                      }
                                    }}
                                  />*/}
                                </Grid>
                            }
                            <Grid
                              item
                              md={4}
                              sm={4}
                              xs={3}
                            >
                              <KeyboardDatePicker
                                data-key={'field-closetransaction-transactionDate'}
                                autoOk={true}
                                variant="inline"
                                inputVariant="outlined"
                                fullWidth
                                required
                                format={DISPLAY_DATE_FORMAT}
                                error={!!form.hasError('transactionDate')}
                                helperText={form.hasError('transactionDate')}
                                label={langCloseTransaction.captionTransactionDate}
                                margin="dense"
                                disabled={allFieldsReadOnly || !canEditSellerFields}
                                value={form.values.transactionDate ? moment(form.values.transactionDate) : null}
                                onChange={(value) => {
                                  // setOccupancyPermitDateInput(value?.format(DISPLAY_DATE_FORMAT) ?? '');
                                  form.setValues({
                                    ...form.values,
                                    transactionDate: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? null,
                                    sellerCommReceivedDate: isNonEmpty(form.values.sellerCommReceivedDate) ? form.values.sellerCommReceivedDate : value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? null,
                                    buyerCommReceivedDate: isNonEmpty(form.values.buyerCommReceivedDate) ? form.values.buyerCommReceivedDate : value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? null
                                  });
                                }}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                      <Divider />

                      <CardContent>
                        <Grid item container md={12} sm={12} xs={12} spacing={1}>
                          <Grid
                            item
                            md={8}
                            sm={8}
                            xs={5}
                          >

                            <Typography
                              data-key={'field-closetransaction-premises'}
                              style={{ color: form.hasError('premises') ? errorColor : 'inherit' }} >{isSale ? langSalePurchaseAgreement.titlePremises : langTenancyAgreement.titlePremises}{'*'}</Typography>
                            <TextareaAutosize
                              className={classes.textArea}
                              rowsMin={2}
                              rowsMax={2}
                              {...bind('premises')}
                              onChange={(e) => {
                                limitTextFieldLength(e, 255, 'premises', form, keyBooleanMap, setKeyBooleanMap);
                              }}

                              disabled={allFieldsReadOnly || !canEditSellerFields}
                            //helperText={form.hasError('premises')}
                            />
                            {form.hasError('premises') ? <FormHelperText style={{ color: form.hasError('premises') ? errorColor : 'inherit' }}>{form.hasError('premises')}</FormHelperText> : null}
                            <HandleFormHelperText
                              isError={keyBooleanMap.get('premises')}
                              errorMessage={lang.textOverLimit}
                            />
                          </Grid>
                          {/* -------  initial deposit and due date------ */}

                          <Grid item container md={12} sm={12} xs={11} spacing={1}>
                            <Grid
                              item
                              md={4}
                              sm={4}
                              xs={3}
                            >
                              {form.values.tranType === 'SALE' ?
                                <NumberField
                                  required
                                  fullWidth
                                  className={classes.dollarField}
                                  margin="dense"
                                  variant="outlined"
                                  label={langCloseTransaction.captionInitialDeposit}
                                  InputProps={{
                                    disabled: allFieldsReadOnly || !canEditSellerFields,
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    // endAdornment: <InputAdornment position="start">{locale === 'en' ? lang.u1M : lang.u10k}</InputAdornment>,
                                  }}
                                  {...bind('initialDeposit')}
                                  onChange={(e) => {
                                    limitNumberFieldLength(e, 'initialDeposit', form)
                                  }}
                                  error={!!form.hasError('initialDeposit')}
                                  helperText={form.hasError('initialDeposit')}
                                />
                                :
                                <NumberField
                                  fullWidth
                                  className={classes.dollarField}
                                  margin="dense"
                                  variant="outlined"
                                  label={langCloseTransaction.captionInitialDeposit}
                                  InputProps={{
                                    disabled: allFieldsReadOnly || !canEditSellerFields,
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    // endAdornment: <InputAdornment position="start">{locale === 'en' ? lang.u1M : lang.u10k}</InputAdornment>,
                                  }}
                                  {...bind('initialDeposit')}
                                  onChange={(e) => {
                                    limitNumberFieldLength(e, 'initialDeposit', form);
                                  }}
                                  error={!!form.hasError('initialDeposit')}
                                  helperText={form.hasError('initialDeposit')}
                                />}
                              {/* <TextField
                                data-key={'field-closetransaction-initialDeposit'}
                                required
                                fullWidth
                                label={langCloseTransaction.captionInitialDeposit}
                                className={classes.dollarField}
                                margin="dense"
                                variant="outlined"
                                error={!!form.hasError('initialDeposit')}
                                helperText={form.hasError('initialDeposit')}
                                InputProps={{
                                  disabled: allFieldsReadOnly || !canEditSellerFields,
                                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                  inputComponent: MaskedInput as any,
                                  inputProps: {
                                    value: bind('initialDeposit').value?.toString() ?? null,
                                    onAccept: (value: any) => {
                                      bind('initialDeposit').onChange({ target: { value: numericValue(value) } });
                                    }
                                  }
                                }}
                              /> */}
                            </Grid>

                            <Grid
                              item
                              md={4}
                              sm={4}
                              xs={3}
                            >

                              <KeyboardDatePicker
                                data-key={'field-closetransaction-initialDepositDueDate'}
                                autoOk={true}
                                required={form.values.tranType === 'SALE' ? true : false}
                                fullWidth
                                variant="inline"
                                inputVariant="outlined"
                                format={DISPLAY_DATE_FORMAT}
                                margin="dense"
                                label={langCloseTransaction.date}
                                error={!!form.hasError('initialDepositDueDate')}
                                helperText={form.hasError('initialDepositDueDate')}
                                value={form.values.initialDepositDueDate ? moment(form.values.initialDepositDueDate) : null}
                                onChange={(value) => {
                                  form.setValues({
                                    ...form.values,
                                    initialDepositDueDate: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
                                  });
                                }}
                                disabled={allFieldsReadOnly || !canEditSellerFields}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                              />


                            </Grid>
                          </Grid>
                          {/* -------  big deposit and due date------ */}

                          <Grid item container md={12} sm={12} xs={11} spacing={1}>
                            <Grid
                              item
                              md={4}
                              sm={4}
                              xs={3}
                            >
                              {form.values.tranType === 'SALE' ?
                                <NumberField
                                  required
                                  fullWidth
                                  margin="dense"
                                  variant="outlined"
                                  className={classes.dollarField}
                                  label={langCloseTransaction.captionBigDeposit}
                                  InputProps={{
                                    disabled: allFieldsReadOnly || !canEditSellerFields,
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    // endAdornment: <InputAdornment position="start">{locale === 'en' ? lang.u1M : lang.u10k}</InputAdornment>,
                                  }}
                                  {...bind('bigDeposit')}
                                  onChange={(e) => {
                                    limitNumberFieldLength(e, 'bigDeposit', form);
                                  }}
                                  error={!!form.hasError('bigDeposit')}
                                  helperText={form.hasError('bigDeposit')}
                                />
                                :
                                <NumberField
                                  fullWidth
                                  margin="dense"
                                  variant="outlined"
                                  className={classes.dollarField}
                                  label={langCloseTransaction.captionBigDeposit}
                                  InputProps={{
                                    disabled: allFieldsReadOnly || !canEditSellerFields,
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    // endAdornment: <InputAdornment position="start">{locale === 'en' ? lang.u1M : lang.u10k}</InputAdornment>,
                                  }}
                                  {...bind('bigDeposit')}
                                  onChange={(e) => {
                                    limitNumberFieldLength(e, 'bigDeposit', form);
                                  }}
                                  error={!!form.hasError('bigDeposit')}
                                  helperText={form.hasError('bigDeposit')}
                                />}
                              {/* <TextField
                                data-key={'field-closetransaction-bigDeposit'}
                                required
                                fullWidth
                                label={langCloseTransaction.captionBigDeposit}
                                className={classes.dollarField}
                                margin="dense"
                                variant="outlined"
                                error={!!form.hasError('bigDeposit')}
                                helperText={form.hasError('bigDeposit')}
                                key='abcdef'
                                InputProps={{
                                  disabled: allFieldsReadOnly || !canEditSellerFields,
                                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                  inputComponent: MaskedInput as any,
                                  inputProps: {
                                    value: bind('bigDeposit').value?.toString() ?? null,
                                    onAccept: (value: any) => {
                                      bind('bigDeposit').onChange({ target: { value: numericValue(value) } });
                                    }
                                  }
                                }}
                              />*/}
                            </Grid>

                            <Grid
                              item
                              md={4}
                              sm={4}
                              xs={3}
                            >
                              <KeyboardDatePicker
                                data-key={'field-closetransaction-bigDepositDueDate'}
                                autoOk={true}
                                required={form.values.tranType === 'SALE' ? true : false}
                                fullWidth
                                variant="inline"
                                inputVariant="outlined"
                                format={DISPLAY_DATE_FORMAT}
                                margin="dense"
                                error={!!form.hasError('bigDepositDueDate')}
                                helperText={form.hasError('bigDepositDueDate')}
                                label={langCloseTransaction.date}
                                value={form.values.bigDepositDueDate ? moment(form.values.bigDepositDueDate) : null}
                                onChange={(value) => {
                                  form.setValues({
                                    ...form.values,
                                    bigDepositDueDate: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
                                  });
                                }}
                                disabled={allFieldsReadOnly || !canEditSellerFields}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                              />
                            </Grid>
                          </Grid>
                          {form.values.tranType === 'SALE' ?

                            <Grid item container md={12} sm={12} xs={11} spacing={1}>
                              <Grid
                                item
                                md={4}
                                sm={4}
                                xs={3}
                              >
                                {/* <NumberField
                                    required
                                    fullWidth
                                    margin="dense"
                                    variant="outlined"
                                    label={langCloseTransaction.captionCompletion}
                                    InputProps={{
                                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                      // endAdornment: <InputAdornment position="start">{locale === 'en' ? lang.u1M : lang.u10k}</InputAdornment>,
                                    }}
                                    InputProps={{
                                      disabled: allFieldsReadOnly || !canEditSellerFields,
                                    }}
                                    {...bind('completion')}
                                  /> */}
                                <TextField
                                  data-key={'field-closetransaction-completion'}
                                  required
                                  fullWidth
                                  label={langCloseTransaction.captionCompletion}
                                  className={classes.dollarField}
                                  margin="dense"
                                  variant="outlined"
                                  disabled={true}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    inputComponent: MaskedInput as any,
                                    inputProps: {
                                      value: bind('completion').value?.toString() ?? null,
                                      // onAccept: (value: any) => {
                                      //   bind('completion').onChange({ target: { value: numericValue(value) }});
                                      // }
                                    }
                                  }}
                                />
                              </Grid>

                              <Grid
                                item
                                md={4}
                                sm={4}
                                xs={3}
                              >

                                <KeyboardDatePicker
                                  data-key={'field-closetransaction-completionDueDate'}
                                  autoOk={true}
                                  required
                                  fullWidth
                                  variant="inline"
                                  inputVariant="outlined"
                                  format={DISPLAY_DATE_FORMAT}
                                  margin="dense"
                                  label={langCloseTransaction.date}
                                  value={form.values.completionDueDate ? moment(form.values.completionDueDate) : null}
                                  error={!!form.hasError('completionDueDate')}
                                  helperText={form.hasError('completionDueDate')}
                                  onChange={(value) => {
                                    form.setValues({
                                      ...form.values,
                                      completionDueDate: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
                                    });
                                  }}
                                  disabled={allFieldsReadOnly || !canEditSellerFields}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                />
                              </Grid>
                            </Grid>
                            : null}

                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </div>
                <div
                  // disabled={!canEditSellerFields} 
                  style={{ border: 0 }}
                >
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <Card>
                      <CardHeader
                        title={isSale ? langCloseTransaction.captionSellerSide : langCloseTransaction.captionLandlordSide}
                      />
                      <Divider />
                      <CardContent>
                        <Grid item container md={12} sm={12} xs={12} spacing={1}>
                          <Grid item container md={12} sm={12} xs={12} spacing={1}>
                            <Grid
                              item
                              md={4}
                              sm={4}
                              xs={12}
                            >
                              {/* plzplz */}
                              <NumberField
                                required
                                fullWidth
                                className={classes.dollarField}
                                label={langCloseTransaction.captionCommission}
                                disabled={allFieldsReadOnly || !canEditSellerFields}
                                margin="dense"
                                variant="outlined"
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                  // endAdornment: <InputAdornment position="start">{locale === 'en' ? lang.u1M : lang.u10k}</InputAdornment>,
                                }}
                                // InputProps={{ disabled: editDisabled }}
                                {...bind('sellerComm')}
                                onChange={(e) => {
                                  limitNumberFieldLength(e, 'sellerComm', form);
                                }}
                                error={!!form.hasError('sellerComm')}
                                helperText={!!form.hasError('sellerComm') ? form.hasError('sellerComm') : (sumAgentCommission(form.values.sellerAgents) < (form.values.sellerComm ?? 0)) ? langCloseTransaction.restCommission : ''}
                              />
                              {/* <TextField
                              data-key={'field-closetransaction-sellerComm'}
                              required
                              fullWidth
                              label={langCloseTransaction.captionCommission}
                              className={classes.dollarField}
                              margin="dense"
                              variant="outlined"
                              error={!!form.hasError('sellerComm')}
                              helperText={!!form.hasError('sellerComm') ? form.hasError('sellerComm') : (sumAgentCommission(form.values.sellerAgents) < (form.values.sellerComm ?? 0)) ? langCloseTransaction.restCommission : ''}
                              InputProps={{
                                disabled: allFieldsReadOnly || !canEditSellerFields,
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                inputComponent: MaskedInput as any,
                                inputProps: {
                                  value: bind('sellerComm').value?.toString() ?? null,
                                  onAccept: (value: any) => {
                                    bind('sellerComm').onChange({ target: { value: numericValue(value) } });
                                  }
                                }
                              }}
                            />*/}
                            </Grid>

                            {/* <Grid
                          item112
                          md={3}
                          sm={3}
                          xs={12}
                        >
                          <TextField 
                            required
                            fullWidth
                            label={langCloseTransaction.captionCommissionShareForAgents}
                            className={classes.dollarField}
                            margin="dense"
                            variant="outlined"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              inputComponent: MaskedInput as any,
                              inputProps: {
                                value: bind('sellerCommAgentShare').value?.toString() ?? null,
                                onAccept: (value: any) => {
                                  bind('sellerCommAgentShare').onChange({ target: { value: numericValue(value) }});
                                }
                              }
                            }}
                          />
                        </Grid> */}

                            <Grid
                              item
                              md={3}
                              sm={3}
                              xs={12}
                            >
                              <KeyboardDatePicker
                                data-key={'field-closetransaction-sellerCommReceiveDate'}
                                autoOk={true}
                                variant="inline"
                                inputVariant="outlined"
                                fullWidth
                                format={DISPLAY_DATE_FORMAT}
                                error={!!form.hasError('sellerCommReceivedDate')}
                                helperText={form.hasError('sellerCommReceivedDate')}
                                label={isSale ? langCloseTransaction.captionSellerCommReceivedDate : langCloseTransaction.captionLandlordCommReceivedDate}
                                margin="dense"
                                disabled={allFieldsReadOnly || !canEditSellerFields}
                                value={form.values.sellerCommReceivedDate ? moment(form.values.sellerCommReceivedDate) : null}
                                onChange={(value) => {
                                  // setOccupancyPermitDateInput(value?.format(DISPLAY_DATE_FORMAT) ?? '');
                                  form.setValues({
                                    ...form.values,
                                    sellerCommReceivedDate: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? null,
                                  });
                                }}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                              />
                            </Grid>

                            <Grid
                              item
                              md={5}
                              sm={5}
                              xs={12}
                            >
                              <TextField
                                fullWidth
                                label={langCloseTransaction.captionRemark}
                                margin="dense"
                                variant="outlined"
                                InputProps={{
                                  disabled: allFieldsReadOnly || !canEditSellerFields,
                                }}
                                {...bind('sellerRemarks')}
                                onChange={(e) => {
                                  limitTextFieldLength(e, 255, 'sellerRemarks', form, keyBooleanMap, setKeyBooleanMap);
                                }}
                              // helperText={form.hasError('sellerRemarks')}
                              />
                              <HandleFormHelperText
                                isError={keyBooleanMap.get('sellerRemarks')}
                                errorMessage={lang.textOverLimit}
                              />

                            </Grid>
                          </Grid>
                          <Grid
                            item
                            md={12}
                            sm={12}
                            xs={12}
                          >
                            {langCloseTransaction.titleClients}
                          </Grid>
                          {/* </Grid> */}
                          {/* <Grid item container md={12} sm={12} xs={12} spacing={1}> */}


                          {
                            form.values.sellerClients?.map((client: CloseTransactionClientDTO, idx: number) => {
                              return (
                                <Grid item container md={12} sm={12} xs={12} spacing={1}>
                                  <Grid
                                    item
                                    md={4}
                                    sm={4}
                                    xs={12}
                                  >
                                    <TextField
                                      value={client.name}
                                      label={langCloseTransaction.captionClientName}
                                      InputProps={{
                                        disabled: true,
                                      }}
                                      fullWidth
                                      margin="dense"
                                      variant="outlined"
                                    />
                                    <HandleFormHelperText
                                      isError={keyBooleanMap.get('sellerClients' + idx)}
                                      errorMessage={lang.textOverLimit}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    md={3}
                                    sm={3}
                                    xs={12}
                                  >
                                    {/* ~~~~~~~~~ */}
                                    <TextField
                                      value={(canReadSellerrDetails || canEditSellerFields) ? client.contact ?? '' : ''}
                                      label={langCloseTransaction.captionClientContact}
                                      onChange={(e) => {
                                        if (e.target.value.length > 255) {
                                          e.preventDefault();
                                          setKeyBooleanMap(keyBooleanMap.set('sellerClients' + idx, true));
                                          const updatedClient = { ...client, contact: e.target.value.substring(0, 255) };
                                          form.updateValues('sellerClients',
                                            form.values.sellerClients?.map((c: CloseTransactionClientDTO, i: number) => idx === i ? updatedClient : c)
                                          );

                                        } else {
                                          setKeyBooleanMap(keyBooleanMap.set('sellerClients' + idx, false))
                                          const updatedClient = { ...client, contact: e.target.value };
                                          form.updateValues('sellerClients',
                                            form.values.sellerClients?.map((c: CloseTransactionClientDTO, i: number) => idx === i ? updatedClient : c)
                                          );
                                        }
                                      }}
                                      InputProps={{
                                        disabled: allFieldsReadOnly || !canEditSellerFields,
                                      }}
                                      fullWidth
                                      margin="dense"
                                      variant="outlined"
                                    />


                                  </Grid>
                                  <Grid
                                    item
                                    md={5}
                                    sm={5}
                                    xs={12}
                                  >
                                    {/* hohohoho */}
                                    <TextField
                                      value={(canReadSellerrDetails || canEditSellerFields) ? client.address ?? '' : ''}
                                      label={langCloseTransaction.captionClientAddress}
                                      InputProps={{
                                        disabled: allFieldsReadOnly || !canEditSellerFields,
                                      }}
                                      onChange={(e) => {
                                        if (e.target.value.length > 255) {
                                          e.preventDefault();
                                          setKeyBooleanMap(keyBooleanMap.set('sellerClients' + idx, true));
                                          const updatedClient = { ...client, address: e.target.value.substring(0, 255) };
                                          form.updateValues('sellerClients',
                                            form.values.sellerClients?.map((c: CloseTransactionClientDTO, i: number) => idx === i ? updatedClient : c)
                                          );
                                        } else {
                                          setKeyBooleanMap(keyBooleanMap.set('sellerClients' + idx, false))
                                          const updatedClient = { ...client, address: e.target.value };
                                          form.updateValues('sellerClients',
                                            form.values.sellerClients?.map((c: CloseTransactionClientDTO, i: number) => idx === i ? updatedClient : c)
                                          );
                                        }
                                      }}
                                      fullWidth
                                      margin="dense"
                                      variant="outlined"
                                    />


                                  </Grid>
                                </Grid>
                              )
                            })
                          }
                          <Grid
                            item
                            md={12}
                            sm={12}
                            xs={12}
                          >
                            <Typography>
                              {langCloseTransaction.titleAgents}
                              {
                                (!allFieldsReadOnly && canEditSellerFields) ?
                                  <Tooltip title={lang.actionAdd}>
                                    <IconButton color="primary" onClick={() => addAgent('SELLER')}>
                                      <AddIcon />
                                    </IconButton>
                                  </Tooltip>
                                  : null
                              }
                            </Typography>
                          </Grid>
                          {
                            form.values.sellerAgents?.map((agent: CloseTransactionAgentDTO, idx: number) => {
                              return (
                                <Grid item container md={12} sm={12} xs={12} spacing={1} key={idx}>
                                  <Grid
                                    item
                                    style={{ maxWidth: "50px" }}
                                    md={1}
                                    sm={1}
                                    xs={12}
                                  >
                                    <Typography style={{ display: 'flex', alignItems: 'center', flexGrow: 2, width: 200 }}>
                                      {
                                        (!allFieldsReadOnly && canDeleteSellerAgent(agent)) ?
                                          <IconButton key={idx} onClick={() => {
                                            confirmDeleteDialog.confirm(langCloseTransaction.msgConfirmRemoveAgent, lang.actionConfirm, lang.actionCancel).then((confirmed) => {
                                              if (confirmed) {
                                                removeSellerAgent(idx);
                                              }
                                            })
                                          }}>
                                            <DeleteIcon />
                                          </IconButton> : null
                                      }
                                      {agent.confirmed === 'Y' ? <CheckCircleIcon style={{ color: green[500] }} /> : null}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    md={2}
                                    sm={2}
                                    xs={12}
                                  >
                                    <TextField
                                      data-key={'field-closetransaction-hasInvalidSellerAgent'}
                                      fullWidth
                                      label={langCloseTransaction.captionAgentType}
                                      margin="dense"
                                      required
                                      InputProps={{
                                        disabled: form.values.sellerAgents?.indexOf(agent, 0) === 0 || allFieldsReadOnly || !canEditSellerFields
                                      }}
                                      select
                                      variant="outlined"
                                      error={!!getAgentValidationMsg('SELLER', 'type', agent.type)}
                                      helperText={getAgentValidationMsg('SELLER', 'type', agent.type)}
                                      value={agent.type}
                                      onChange={(e) => {
                                        if (e.target.value !== agent.type) {
                                          const updatedAgent = { ...agent, type: e.target.value, agentId: null, name: null, contact: "" };
                                          form.updateValues('sellerAgents',
                                            form.values.sellerAgents?.map((a: any, i: number) => idx === i ? updatedAgent : a)
                                          )
                                        }
                                      }}
                                    >
                                      {
                                        Object.keys(agentTypeOptions ?? {}).map(key => (
                                          <MenuItem key={key} value={key}>{agentTypeOptions[key]}</MenuItem>
                                        ))
                                      }
                                    </TextField>
                                  </Grid>
                                  <Grid
                                    item
                                    md={3}
                                    sm={3}
                                    xs={12}
                                  >
                                    {agent.type === 'I' ?
                                      <Autocomplete
                                        // disabled={!editAgentEnabled}
                                        id="seller-agent-auto"
                                        popupIcon={""}
                                        options={agent.type === 'I' ? agentUsername : []}
                                        noOptionsText={''}
                                        loading={agentListLoading}
                                        loadingText={lang.msgLoading}
                                        // freeSolo={agent.type === 'E'}
                                        disabled={allFieldsReadOnly || !canEditSellerFields}
                                        renderOption={(option) => <div>
                                          <div><Typography >{`${agents.filter(agent => agent.username === option)?.[0]?.englishName ?? ''}${agents.filter(agent => agent.username === option)?.[0]?.chineseName ? '/' : ''}${agents.filter(agent => agent.username === option)?.[0]?.chineseName ?? ''}`}</Typography></div>
                                          <div><Typography variant="caption">{agents.filter(agent => agent.username === option)?.[0]?.mainContact}</Typography></div>
                                        </div>}
                                        filterOptions={(option) => option}
                                        renderInput={(params: any) => (
                                          <TextField
                                            {...params}
                                            className={classes.patchOutlineAutocomplete}
                                            error={!!getAgentValidationMsg('SELLER', 'name', agent.name)}
                                            helperText={getAgentValidationMsg('SELLER', 'name', agent.name)}
                                            fullWidth
                                            label={langCloseTransaction.captionAgentName}
                                            margin="dense"
                                            variant="outlined"
                                            onFocus={(ev) => {
                                              setAgentUsername([]);
                                              setKeywordOfAgent(ev.target.value);
                                            }}
                                            onChange={(ev) => {
                                              // fetchAgents(ev.target.value)
                                              setAgents([]);
                                              setAgentUsername([]);
                                              setKeywordOfAgent(ev.target.value);
                                              setAgentListLoading(true);
                                              if (ev.target.value.length < 2 || !isNonEmpty(ev.target.value)) {
                                                setAgentListLoading(false);
                                              }
                                            }
                                            }
                                          />
                                        )}
                                        // value={ multiLang(locale === 'en' ? 'zh_HK': 'en',searchForm.values.agentNameZh, searchForm.values.agentNameEn)}
                                        value={agent.name}
                                        onChange={(_: any, val: any) => {
                                          if (agentMap[val]) {
                                            const { id, chineseName, englishName, mainContact } = agentMap[val];
                                            const updatedAgent = { ...agent, agentId: id, name: multiLang(locale, chineseName, englishName), contact: mainContact };
                                            form.updateValues('sellerAgents',
                                              form.values.sellerAgents?.map((a: any, i: number) => idx === i ? updatedAgent : a)
                                            );
                                          } else {
                                            const updatedAgent = { ...agent, name: undefined };
                                            form.updateValues('sellerAgents',
                                              form.values.sellerAgents?.map((a: any, i: number) => idx === i ? updatedAgent : a)
                                            );
                                          }
                                          setAgents([]);
                                          setAgentUsername([]);
                                        }}
                                        //getOptionLabel={() => multiLang(locale, form.values.sellerAgents, form.values.sellerAgents) ?? ''}
                                        getOptionLabel={() => agent.name}
                                      />
                                      : <TextField
                                        fullWidth
                                        label={langCloseTransaction.captionAgentName}
                                        margin="dense"
                                        variant="outlined"
                                        value={agent.name}
                                        error={!!getAgentValidationMsg('SELLER', 'name', agent.name)}
                                        helperText={getAgentValidationMsg('SELLER', 'name', agent.name)}
                                        onChange={(e) => {
                                          if (e.target.value.length > 255) {
                                            e.preventDefault();
                                            setKeyBooleanMap(keyBooleanMap.set('sellerAgents' + idx, true));
                                            const updatedAgent = { ...agent, name: e.target.value.substring(0, 255) };
                                            form.updateValues('sellerAgents',
                                              form.values.sellerAgents?.map((a: any, i: number) => idx === i ? updatedAgent : a)
                                            );
                                          } else {
                                            setKeyBooleanMap(keyBooleanMap.set('sellerAgents' + idx, false));
                                            const updatedAgent = { ...agent, name: e.target.value };
                                            form.updateValues('sellerAgents',
                                              form.values.sellerAgents?.map((a: any, i: number) => idx === i ? updatedAgent : a)
                                            );
                                          }
                                        }}
                                        InputProps={{
                                          disabled: allFieldsReadOnly || !canEditSellerFields
                                        }}
                                      />
                                    }
                                  </Grid>
                                  <Grid
                                    item
                                    md={2}
                                    sm={2}
                                    xs={12}
                                  >
                                    <TextField
                                      fullWidth
                                      label={langCloseTransaction.captionAgentContact}
                                      margin="dense"
                                      InputProps={{ disabled: allFieldsReadOnly || !canEditSellerFields }}
                                      InputLabelProps={{
                                        shrink: true
                                      }}
                                      variant="outlined"
                                      error={!!getAgentValidationMsg('SELLER', 'contact', agent.contact)}
                                      helperText={getAgentValidationMsg('SELLER', 'contact', agent.contact)}
                                      value={agent.contact}
                                      required
                                      onChange={(e) => {
                                        if (e.target.value.length > 255) {
                                          e.preventDefault();
                                          setKeyBooleanMap(keyBooleanMap.set('sellerAgents' + idx, true));
                                          const updatedAgent = { ...agent, contact: e.target.value.substring(0, 255) };
                                          form.updateValues('sellerAgents',
                                            form.values.sellerAgents?.map((a: any, i: number) => idx === i ? updatedAgent : a)
                                          );
                                        } else {
                                          setKeyBooleanMap(keyBooleanMap.set('sellerAgents' + idx, false));
                                          const updatedAgent = { ...agent, contact: e.target.value };
                                          form.updateValues('sellerAgents',
                                            form.values.sellerAgents?.map((a: any, i: number) => idx === i ? updatedAgent : a)
                                          );
                                        }
                                      }}
                                    />
                                    <HandleFormHelperText
                                      isError={keyBooleanMap.get('sellerAgents' + idx)}
                                      errorMessage={lang.textOverLimit}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    md={3}
                                    sm={3}
                                    xs={12}
                                  >
                                    <TextField
                                      fullWidth
                                      label={langCloseTransaction.captionRemark}
                                      margin="dense"
                                      // InputProps={{ disabled: agent.type === 'I' }}
                                      InputProps={{
                                        disabled: allFieldsReadOnly || !canEditSellerFields,
                                      }}
                                      variant="outlined"
                                      value={agent.remark}
                                      onChange={(e) => {
                                        if (e.target.value.length > 255) {
                                          e.preventDefault();
                                          setKeyBooleanMap(keyBooleanMap.set('sellerAgents' + idx, true));
                                          const updatedAgent = { ...agent, remark: e.target.value.substring(0, 255) };
                                          form.updateValues('sellerAgents',
                                            form.values.sellerAgents?.map((a: any, i: number) => idx === i ? updatedAgent : a)
                                          );
                                        } else {
                                          setKeyBooleanMap(keyBooleanMap.set('sellerAgents' + idx, false));
                                          const updatedAgent = { ...agent, remark: e.target.value };
                                          form.updateValues('sellerAgents',
                                            form.values.sellerAgents?.map((a: any, i: number) => idx === i ? updatedAgent : a)
                                          );
                                        }
                                      }}
                                    />
                                  </Grid>
                                  <Grid
                                    item

                                    md={1}
                                    sm={1}
                                    xs={12}
                                  >
                                    <NumberField
                                      style={{ width: isLargeScreen ? "164%" : isMediumScreen ? "140%" : "100%" }}
                                      //style={{ width: window.innerWidth >= 992 ? "164%" : window.innerWidth >= 576 ? "122%" : "100%" }}
                                      fullWidth
                                      label={langCloseTransaction.captionCommission}
                                      margin="dense"
                                      variant="outlined"
                                      required
                                      InputProps={{
                                        disabled: allFieldsReadOnly || !canEditSellerFields,
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        // endAdornment: <InputAdornment position="start">{locale === 'en' ? lang.u1M : lang.u10k}</InputAdornment>,
                                      }}
                                      error={!!getAgentValidationMsg('SELLER', 'commission', agent.commission)}
                                      helperText={getAgentValidationMsg('SELLER', 'commission', agent.commission)}
                                      value={agent.commission}
                                      onChange={(e) => {
                                        if (isEmpty(e.target.value) || isNaN(parseFloat(e.target.value))) {
                                          const updatedAgent = { ...agent, commission: null };
                                          form.updateValues('sellerAgents',
                                            form.values.sellerAgents?.map((a: any, i: number) => idx === i ? updatedAgent : a)
                                          );
                                        } else if (e.target.value.match(/^\d*\.?\d*$/) == null) {
                                          e.preventDefault();
                                        } else {
                                          if ((e.target.value.indexOf(".") == -1 && e.target.value.length > 12) || (e.target.value.indexOf(".") != -1 && e.target.value.indexOf(".") > 12) || (e.target.value.indexOf('.') != -1 && e.target.value.match(/\./g)!.length >= 2)) {
                                            e.preventDefault();
                                          } else {
                                            form.updateValues('sellerAgents', form.values.sellerAgents?.map((a: any, i: number) => idx === i ? e.target.value.indexOf(".") == -1 ? { ...agent, commission: e.target.value } : (e.target.value.length - (e.target.value.indexOf(".") + 1)) > 2 ? { ...agent, commission: (Math.floor(parseFloat(e.target.value) * 100) / 100).toFixed(2) } : { ...agent, commission: e.target.value } : a));
                                          }
                                        }
                                      }}
                                    />
                                    {/* <TextField
                                      fullWidth
                                      label={langCloseTransaction.captionCommission}
                                      className={classes.dollarField}
                                      margin="dense"
                                      variant="outlined"
                                      InputProps={{
                                        disabled: allFieldsReadOnly || !canEditSellerFields,
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        inputComponent: MaskedInput as any,
                                        inputProps: {
                                          value: agent.commission?.toString(),
                                          onAccept: (value: any) => {
                                            const updatedAgent = { ...agent, commission: value ? Number(value) : value };
                                            form.updateValues('sellerAgents',
                                              form.values.sellerAgents?.map((a: any, i: number) => idx === i ? updatedAgent : a)
                                            );
                                          }
                                        }
                                      }}
                                      error={(agent.type === 'I' && !!getAgentValidationMsg('SELLER', 'commission', agent.commission))}
                                      helperText={((agent.type === 'I') ? getAgentValidationMsg('SELLER', 'commission', agent.commission) : '')}
                                      required={agent.type === 'I'}

                                    /> */}
                                  </Grid>
                                </Grid>
                              );
                            })
                          }
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </div>
                <div
                  // disabled={!canEditBuyerFields} 
                  style={{ border: 0 }}
                >
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <Card>
                      <CardHeader
                        title={<div className={'common-row'}>
                          <span>{isSale ? langCloseTransaction.captionBuyerSide : langCloseTransaction.captionTenantSide}</span>
                        </div>}
                      />
                      <Divider />
                      <CardContent>
                        <Grid item container md={12} sm={12} xs={12} spacing={1}>
                          <Grid item container md={12} sm={12} xs={12} spacing={1}>
                            <Grid
                              item
                              md={4}
                              sm={4}
                              xs={12}
                            >
                              <NumberField
                                required
                                fullWidth
                                className={classes.dollarField}
                                label={langCloseTransaction.captionCommission}
                                margin="dense"
                                variant="outlined"
                                error={!!form.hasError('buyerComm')}
                                helperText={!!form.hasError('buyerComm') ? form.hasError('buyerComm') : (sumAgentCommission(form.values.buyerAgents) < (form.values.buyerComm ?? 0)) ? langCloseTransaction.restCommission : ''}
                                InputProps={{
                                  disabled: allFieldsReadOnly || !canEditBuyerFields,
                                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                  // endAdornment: <InputAdornment position="start">{locale === 'en' ? lang.u1M : lang.u10k}</InputAdornment>,
                                }}
                                // InputProps={{ disabled: editDisabled }}
                                {...bind('buyerComm')}
                                onChange={(e) => {
                                  limitNumberFieldLength(e, 'buyerComm', form);
                                }}
                              />
                              {/* <TextField
                              data-key={'field-closetransaction-buyerComm'}
                              required
                              fullWidth
                              label={langCloseTransaction.captionCommission}
                              className={classes.dollarField}
                              margin="dense"
                              variant="outlined"
                              error={!!form.hasError('buyerComm')}
                              helperText={!!form.hasError('buyerComm') ? form.hasError('buyerComm') : (sumAgentCommission(form.values.buyerAgents) < (form.values.buyerComm ?? 0)) ? langCloseTransaction.restCommission : ''}
                              InputProps={{
                                disabled: allFieldsReadOnly || !canEditBuyerFields,
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                inputComponent: MaskedInput as any,
                                inputProps: {
                                  value: bind('buyerComm').value?.toString() ?? null,
                                  onAccept: (value: any) => {
                                    bind('buyerComm').onChange({ target: { value: numericValue(value) } });
                                  }
                                }
                              }}
                            /> */}
                            </Grid>
                            {/* <Grid
                          item
                          md={3}
                          sm={3}
                          xs={12}
                        >
                          <TextField 
                            required
                            fullWidth
                            label={langCloseTransaction.captionCommissionShareForAgents}
                            className={classes.dollarField}
                            margin="dense"
                            variant="outlined"
                            InputProps={{
                              disabled: allFieldsReadOnly || !canEditBuyerFields,
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              inputComponent: MaskedInput as any,
                              inputProps: {
                                value: bind('buyerCommAgentShare').value?.toString() ?? null,
                                onAccept: (value: any) => {
                                  bind('buyerCommAgentShare').onChange({ target: { value: numericValue(value) }});
                                }
                              }
                            }}
                          />
                        </Grid> */}
                            <Grid
                              item
                              md={3}
                              sm={3}
                              xs={12}
                            >
                              <KeyboardDatePicker
                                data-key={'field-closetransaction-buyerCommReceivedDate'}
                                autoOk={true}
                                variant="inline"
                                inputVariant="outlined"
                                fullWidth
                                format={DISPLAY_DATE_FORMAT}
                                error={!!form.hasError('buyerCommReceivedDate')}
                                helperText={form.hasError('buyerCommReceivedDate')}
                                label={isSale ? langCloseTransaction.captionBuyerCommReceivedDate : langCloseTransaction.captionTenantCommReceivedDate}
                                margin="dense"
                                disabled={allFieldsReadOnly || !canEditBuyerFields}
                                value={form.values.buyerCommReceivedDate ? moment(form.values.buyerCommReceivedDate) : null}
                                onChange={(value) => {
                                  // setOccupancyPermitDateInput(value?.format(DISPLAY_DATE_FORMAT) ?? '');
                                  form.setValues({
                                    ...form.values,
                                    buyerCommReceivedDate: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? null,
                                  });
                                }}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              md={5}
                              sm={5}
                              xs={12}
                            >
                              <TextField
                                fullWidth
                                label={langCloseTransaction.captionRemark}
                                margin="dense"
                                variant="outlined"
                                InputProps={{
                                  disabled: allFieldsReadOnly || !canEditBuyerFields,
                                }}
                                {...bind('buyerRemarks')}
                                onChange={(e) => {
                                  limitTextFieldLength(e, 255, 'buyerRemarks', form, keyBooleanMap, setKeyBooleanMap);
                                }}
                              />
                              <HandleFormHelperText
                                isError={keyBooleanMap.get('buyerRemarks')}
                                errorMessage={lang.textOverLimit}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            md={12}
                            sm={12}
                            xs={12}
                          >
                            {langCloseTransaction.titleClients}
                          </Grid>

                          {
                            form.values.buyerClients?.map((client: CloseTransactionClientDTO, idx: number) => {
                              return (
                                <Grid item container md={12} sm={12} xs={12} spacing={1}>
                                  <Grid
                                    item
                                    md={4}
                                    sm={4}
                                    xs={12}
                                  >
                                    <TextField
                                      value={client.name}
                                      label={langCloseTransaction.captionClientName}
                                      InputProps={{
                                        disabled: true,
                                      }}
                                      fullWidth
                                      margin="dense"
                                      variant="outlined"
                                    />
                                    <HandleFormHelperText
                                      isError={keyBooleanMap.get('buyerClients' + idx)}
                                      errorMessage={lang.textOverLimit}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    md={3}
                                    sm={3}
                                    xs={12}
                                  >
                                    <TextField
                                      value={(canReadBuyerDetails || canEditBuyerFields) ? client.contact ?? '' : ''}
                                      label={langCloseTransaction.captionClientContact}
                                      onChange={(e) => {
                                        if (e.target.value.length > 255) {
                                          e.preventDefault();
                                          setKeyBooleanMap(keyBooleanMap.set('buyerClients' + idx, true));
                                          const updatedClient = { ...client, contact: e.target.value.substring(0, 255) };
                                          form.updateValues('buyerClients',
                                            form.values.sellerClients?.map((c: CloseTransactionClientDTO, i: number) => idx === i ? updatedClient : c)
                                          );
                                        } else {
                                          setKeyBooleanMap(keyBooleanMap.set('buyerClients' + idx, false))
                                          const updatedClient = { ...client, contact: e.target.value };
                                          form.updateValues('buyerClients',
                                            form.values.buyerClients?.map((c: CloseTransactionClientDTO, i: number) => idx === i ? updatedClient : c)
                                          );
                                        }
                                      }}
                                      fullWidth
                                      margin="dense"
                                      variant="outlined"
                                      InputProps={{
                                        disabled: allFieldsReadOnly || !canEditBuyerFields,
                                      }}
                                    />

                                  </Grid>
                                  <Grid
                                    item
                                    md={5}
                                    sm={5}
                                    xs={12}
                                  >
                                    <TextField
                                      value={(canReadBuyerDetails || canEditBuyerFields) ? client.address ?? '' : ''}
                                      label={langCloseTransaction.captionClientAddress}
                                      InputProps={{
                                        disabled: allFieldsReadOnly || !canEditBuyerFields,
                                      }}
                                      onChange={(e) => {
                                        if (e.target.value.length > 255) {
                                          e.preventDefault();
                                          setKeyBooleanMap(keyBooleanMap.set('buyerClients' + idx, true));
                                          const updatedClient = { ...client, address: e.target.value.substring(0, 255) };
                                          form.updateValues('buyerClients',
                                            form.values.sellerClients?.map((c: CloseTransactionClientDTO, i: number) => idx === i ? updatedClient : c)
                                          );
                                        } else {
                                          setKeyBooleanMap(keyBooleanMap.set('buyerClients' + idx, false))
                                          const updatedClient = { ...client, address: e.target.value };
                                          form.updateValues('buyerClients',
                                            form.values.buyerClients?.map((c: CloseTransactionClientDTO, i: number) => idx === i ? updatedClient : c)
                                          );
                                        }
                                      }}
                                      fullWidth
                                      margin="dense"
                                      variant="outlined"
                                    />


                                  </Grid>
                                </Grid>
                              )
                            })
                          }
                          <Grid
                            item
                            md={12}
                            sm={12}
                            xs={12}
                          >
                            <Typography>
                              {langCloseTransaction.titleAgents}
                              {
                                (!allFieldsReadOnly && canEditBuyerFields) ?
                                  <Tooltip title={lang.actionAdd}>
                                    <IconButton color="primary" onClick={() => addAgent('BUYER')}>
                                      <AddIcon />
                                    </IconButton>
                                  </Tooltip>
                                  : null
                              }

                            </Typography>
                          </Grid>
                          <Menu open={linkedClientMenuOpenIdx !== -1} onClose={() => setLinkedClientMenuOpenIdx(-1)} anchorEl={linkedClientMenuButtonRef.current}>
                            <MenuItem onClick={() => {
                              setClientSelectionOpenForIdx(linkedClientMenuOpenIdx);
                              setLinkedClientMenuOpenIdx(-1);
                            }}>{langCloseTransaction.actionEditLinkage}</MenuItem>
                            {form.values.buyerAgents?.[linkedClientMenuOpenIdx]?.linkedBuyerClientId ? <MenuItem onClick={() => {
                              const idx = linkedClientMenuOpenIdx;
                              const updatedAgent: CloseTransactionAgentDTO = {
                                ...form.values.buyerAgents![idx]!,
                                linkedBuyerClientId: undefined,
                                linkedBuyerClientContactId: undefined,
                                linkedBuyerClientFormId: undefined,
                              };
                              form.updateValues('buyerAgents',
                                form.values.buyerAgents?.map((c: CloseTransactionAgentDTO, i: number) => idx === i ? updatedAgent : c)
                              );
                              setLinkedClientMenuOpenIdx(-1);
                            }}>{langCloseTransaction.actionRemoveLinkage}</MenuItem> : null}
                          </Menu>
                          <ClientSelectionDialog
                            open={clientSelectionOpenForIdx !== -1}
                            onClose={(client) => {
                              if (!client) {
                                setClientSelectionOpenForIdx(-1);
                                return;
                              }
                              const idx = clientSelectionOpenForIdx;
                              const updatedAgent: CloseTransactionAgentDTO = {
                                ...form.values.buyerAgents![idx]!,
                                linkedBuyerClientId: client?.cid ? +client?.cid : undefined,
                                linkedBuyerClientContactId: client?.id ? +client?.id : undefined,
                              };
                              form.updateValues('buyerAgents',
                                form.values.buyerAgents?.map((c: CloseTransactionAgentDTO, i: number) => idx === i ? updatedAgent : c)
                              );
                              setClientSelectionOpenForIdx(-1);
                            }
                            }
                            onSelect={(client) => {
                              if (client) {
                                const idx = clientSelectionOpenForIdx;
                                const updatedAgent: CloseTransactionAgentDTO = {
                                  ...form.values.buyerAgents![idx]!,
                                  linkedBuyerClientId: client?.cid ? +client?.cid : undefined,
                                  linkedBuyerClientContactId: client?.id ? +client?.id : undefined,
                                };
                                form.updateValues('buyerAgents',
                                  form.values.buyerAgents?.map((c: CloseTransactionAgentDTO, i: number) => idx === i ? updatedAgent : c)
                                );
                              }
                            }}
                            onConfirm={(formId) => {
                              if (formId) {
                                const idx = clientSelectionOpenForIdx;
                                const updatedAgent: CloseTransactionAgentDTO = {
                                  ...form.values.buyerAgents![idx]!,
                                  linkedBuyerClientFormId: parseInt(formId, 10),
                                };
                                form.updateValues('buyerAgents',
                                  form.values.buyerAgents?.map((c: CloseTransactionAgentDTO, i: number) => idx === i ? updatedAgent : c)
                                );
                              }
                            }}
                            clientFileForm={currentLinkedClientForm46}
                            actionName={form.values.tranType === 'SALE' ? langCloseTransaction.titleBuyerClientLinkage : langCloseTransaction.titleTenantClientLinkage}
                            description={form.values.tranType === 'SALE' ? langCloseTransaction.msgBuyerClientLinkage : langCloseTransaction.msgTenantClientLinkage}
                            contactId={form.values.buyerAgents?.[clientSelectionOpenForIdx]?.linkedBuyerClientContactId}
                            type={closeTransactionDto.tranType === 'SALE' ? 'FORM_4' : 'FORM_6'}
                          />
                          {
                            form.values.buyerAgents?.map((agent: CloseTransactionAgentDTO, idx: number) => {
                              return (
                                <Grid item container md={12} sm={12} xs={12} spacing={1} key={idx}>
                                  <Grid
                                    style={{ maxWidth: "50px" }}
                                    item
                                    md={1}
                                    sm={1}
                                    xs={12}
                                  >
                                    <Typography style={{ display: 'flex', alignItems: 'center', flexGrow: 2, width: 200 }}>
                                      {
                                        (!allFieldsReadOnly && canDeleteBuyerAgent(agent)) ?
                                          <IconButton key={idx} onClick={() => {
                                            // clickDeleteButton(idx, 'SELLER')
                                            confirmDeleteDialog.confirm(langCloseTransaction.msgConfirmRemoveAgent, lang.actionConfirm, lang.actionCancel).then((confirmed) => {
                                              if (confirmed) {
                                                removeBuyerAgent(idx);
                                              }
                                            })
                                          }}>
                                            <DeleteIcon />
                                          </IconButton> : null
                                      }
                                      {
                                        !allFieldsReadOnly && canEditLinkedClientMaster(agent) ? <IconButton
                                          color={form.values.buyerAgents?.[0]?.linkedBuyerClientId ? 'primary' : undefined}
                                          style={{ color: !!form.hasError(`invalidLinkedClientId-${agent.agentId}` as any) ? '#a00' : undefined }}
                                          tooltip={!!form.hasError(`invalidLinkedClientId-${agent.agentId}` as any) ? form.hasError(`invalidLinkedClientId-${agent.agentId}` as any) : form.values.buyerAgents?.[0]?.linkedBuyerClientId ? (form.values.tranType === 'SALE' ? clientFormSelections.buyerInfoLinked : clientFormSelections.tenantInfoLinked) : (form.values.tranType === 'SALE' ? clientFormSelections.linkBuyerInfo : clientFormSelections.linkTenantInfo)}
                                          onClick={(ev) => {
                                            if (form.values.buyerAgents?.[0]?.linkedBuyerClientId) {
                                              linkedClientMenuButtonRef.current = ev.target as HTMLButtonElement;
                                              setLinkedClientMenuOpenIdx(0);
                                            } else {
                                              setClientSelectionOpenForIdx(0);
                                            };
                                          }}>
                                          <LinkIcon />
                                        </IconButton> : null
                                      }
                                      {agent.confirmed === 'Y' ? <CheckCircleIcon style={{ color: green[500] }} /> : null}
                                    </Typography>
                                    <Typography
                                      data-key={`field-closetransaction-invalidLinkedClientId-${agent.agentId}`}
                                      style={{ color: errorColor }} variant="caption">
                                      {form.hasError(`invalidLinkedClientId-${agent.agentId}` as any)}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    md={2}
                                    sm={2}
                                    xs={12}
                                  >
                                    <TextField
                                      data-key={'field-closetransaction-hasInvalidBuyerAgent'}
                                      fullWidth
                                      label={langCloseTransaction.captionAgentType}
                                      margin="dense"
                                      required
                                      InputProps={{
                                        disabled: form.values.buyerAgents?.indexOf(agent, 0) === 0 || allFieldsReadOnly || !canEditBuyerFields
                                      }}
                                      select
                                      variant="outlined"
                                      error={!!getAgentValidationMsg('BUYER', 'type', agent.type)}
                                      helperText={getAgentValidationMsg('BUYER', 'type', agent.type)}
                                      value={agent.type}
                                      onChange={(e) => {
                                        if (e.target.value !== agent.type) {
                                          const updatedAgent = { ...agent, type: e.target.value, agentId: null, name: null, contact: "", email: null };
                                          form.updateValues('buyerAgents',
                                            form.values.buyerAgents?.map((a: any, i: number) => idx === i ? updatedAgent : a)
                                          )
                                        }
                                      }}
                                    >
                                      {
                                        Object.keys(agentTypeOptions ?? {}).map(key => (
                                          <MenuItem key={key} value={key}>{agentTypeOptions[key]}</MenuItem>
                                        ))
                                      }
                                    </TextField>
                                  </Grid>
                                  <Grid
                                    item
                                    md={3}
                                    sm={3}
                                    xs={12}
                                  >
                                    {agent.type === 'I' ?
                                      <Autocomplete
                                        id="buyer-agent-auto"
                                        popupIcon={""}
                                        options={agent.type === 'I' ? agentUsername : []}
                                        noOptionsText={''}
                                        loading={agentListLoading}
                                        loadingText={lang.msgLoading}
                                        // freeSolo={agent.type === 'E'}
                                        filterOptions={(option) => option}
                                        disabled={allFieldsReadOnly || !canEditBuyerFields}
                                        renderOption={(option) => <div>
                                          <div><Typography >{`${agents.filter(agent => agent.username === option)?.[0]?.englishName ?? ''}${agents.filter(agent => agent.username === option)?.[0]?.chineseName ? '/' : ''}${agents.filter(agent => agent.username === option)?.[0]?.chineseName ?? ''}`}</Typography></div>
                                          <div><Typography variant='caption'>{agents.filter(agent => agent.username === option)?.[0]?.mainContact}</Typography></div>

                                        </div>}
                                        renderInput={(params: any) => (
                                          <TextField
                                            {...params}
                                            className={classes.patchOutlineAutocomplete}
                                            error={!!getAgentValidationMsg('BUYER', 'name', agent.name)}
                                            helperText={getAgentValidationMsg('BUYER', 'name', agent.name)}
                                            fullWidth
                                            label={langCloseTransaction.captionAgentName}
                                            margin="dense"
                                            variant="outlined"
                                            onFocus={(ev) => {
                                              setAgentUsername([]);
                                              setKeywordOfAgent(ev.target.value);
                                            }}
                                            onChange={(ev) => {
                                              // fetchAgents(ev.target.value)
                                              setAgents([]);
                                              setAgentUsername([]);
                                              setKeywordOfAgent(ev.target.value);
                                              setAgentListLoading(true);
                                              if (ev.target.value.length < 2 || !isNonEmpty(ev.target.value)) {
                                                setAgentListLoading(false);
                                              }
                                            }}
                                          />
                                        )}

                                        // value={ multiLang(locale === 'en' ? 'zh_HK': 'en',searchForm.values.agentNameZh, searchForm.values.agentNameEn)}
                                        value={agent.name}
                                        onChange={(_: any, val: any) => {
                                          if (agentMap[val]) {
                                            const { id, chineseName, englishName, mainContact } = agentMap[val];
                                            const updatedAgent = { ...agent, agentId: id, name: multiLang(locale, chineseName, englishName), contact: mainContact };
                                            form.updateValues('buyerAgents',
                                              form.values.buyerAgents?.map((a: any, i: number) => idx === i ? updatedAgent : a)
                                            );

                                          } else {
                                            const updatedAgent = { ...agent, name: undefined };
                                            form.updateValues('buyerAgents',
                                              form.values.buyerAgents?.map((a: any, i: number) => idx === i ? updatedAgent : a)
                                            );
                                          }
                                          setAgents([]);
                                          setAgentUsername([]);
                                        }}
                                        //getOptionLabel={() => multiLang(locale, agent.name, agent.name) ?? ''}
                                        getOptionLabel={() => agent.name}
                                      />
                                      :
                                      <TextField
                                        fullWidth
                                        label={langCloseTransaction.captionAgentName}
                                        margin="dense"
                                        variant="outlined"
                                        value={agent.name}
                                        error={!!getAgentValidationMsg('BUYER', 'name', agent.name)}
                                        helperText={getAgentValidationMsg('BUYER', 'name', agent.name)}
                                        onChange={(e) => {
                                          if (e.target.value.length > 255) {
                                            e.preventDefault();
                                            setKeyBooleanMap(keyBooleanMap.set('buyerAgents' + idx, true));
                                            const updatedAgent = { ...agent, name: e.target.value.substring(0, 255) };
                                            form.updateValues('buyerAgents',
                                              form.values.buyerAgents?.map((a: any, i: number) => idx === i ? updatedAgent : a)
                                            );
                                          } else {
                                            setKeyBooleanMap(keyBooleanMap.set('buyerAgents' + idx, false));
                                            const updatedAgent = { ...agent, name: e.target.value };
                                            form.updateValues('buyerAgents',
                                              form.values.buyerAgents?.map((a: any, i: number) => idx === i ? updatedAgent : a)
                                            );
                                          }
                                        }}
                                        InputProps={{
                                          disabled: allFieldsReadOnly || !canEditBuyerFields,
                                        }}
                                      />
                                    }
                                  </Grid>
                                  <Grid
                                    item
                                    md={2}
                                    sm={2}
                                    xs={12}
                                  >
                                    <TextField
                                      required
                                      fullWidth
                                      label={langCloseTransaction.captionAgentContact}
                                      margin="dense"
                                      InputProps={{ disabled: allFieldsReadOnly || !canEditBuyerFields }}
                                      variant="outlined"
                                      error={!!getAgentValidationMsg('BUYER', 'contact', agent.contact)}
                                      helperText={getAgentValidationMsg('BUYER', 'contact', agent.contact)}
                                      value={agent.contact}
                                      onChange={(e) => {
                                        if (e.target.value.length > 255) {
                                          e.preventDefault();
                                          setKeyBooleanMap(keyBooleanMap.set('buyerAgents' + idx, true));
                                          const updatedAgent = { ...agent, contact: e.target.value.substring(0, 255) };
                                          form.updateValues('buyerAgents',
                                            form.values.buyerAgents?.map((a: any, i: number) => idx === i ? updatedAgent : a)
                                          );
                                        } else {
                                          setKeyBooleanMap(keyBooleanMap.set('buyerAgents' + idx, false));
                                          const updatedAgent = { ...agent, contact: e.target.value };
                                          form.updateValues('buyerAgents',
                                            form.values.buyerAgents?.map((a: any, i: number) => idx === i ? updatedAgent : a)
                                          );
                                        }
                                      }}
                                    />
                                    <HandleFormHelperText
                                      isError={keyBooleanMap.get('buyerAgents' + idx)}
                                      errorMessage={lang.textOverLimit}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    md={3}
                                    sm={3}
                                    xs={12}
                                  >
                                    <TextField
                                      fullWidth
                                      label={langCloseTransaction.captionRemark}
                                      margin="dense"
                                      // InputProps={{ disabled: agent.type === 'I' }}
                                      InputProps={{
                                        disabled: allFieldsReadOnly || !canEditBuyerFields,
                                      }}
                                      variant="outlined"
                                      value={agent.remark}
                                      onChange={(e) => {
                                        if (e.target.value.length > 255) {
                                          e.preventDefault();
                                          setKeyBooleanMap(keyBooleanMap.set('buyerAgents' + idx, true));
                                          const updatedAgent = { ...agent, remark: e.target.value.substring(0, 255) };
                                          form.updateValues('buyerAgents',
                                            form.values.buyerAgents?.map((a: any, i: number) => idx === i ? updatedAgent : a)
                                          );
                                        } else {
                                          setKeyBooleanMap(keyBooleanMap.set('buyerAgents' + idx, false));
                                          const updatedAgent = { ...agent, remark: e.target.value };
                                          form.updateValues('buyerAgents',
                                            form.values.buyerAgents?.map((a: any, i: number) => idx === i ? updatedAgent : a)
                                          );
                                        }
                                      }}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    md={1}
                                    sm={1}
                                    xs={12}
                                  >
                                    <NumberField
                                      style={{ width: isLargeScreen ? "164%" : isMediumScreen ? "140%" : "100%" }}
                                      required
                                      fullWidth
                                      label={langCloseTransaction.captionCommission}
                                      margin="dense"
                                      variant="outlined"
                                      InputProps={{
                                        disabled: allFieldsReadOnly || !canEditBuyerFields,
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                      }}
                                      error={!!getAgentValidationMsg('BUYER', 'commission', agent.commission)}
                                      helperText={getAgentValidationMsg('BUYER', 'commission', agent.commission)}
                                      value={agent.commission}
                                      onChange={(e) => {
                                        if (isEmpty(e.target.value) || isNaN(parseFloat(e.target.value))) {
                                          const updatedAgent = { ...agent, commission: null };
                                          form.updateValues('buyerAgents',
                                            form.values.buyerAgents?.map((a: any, i: number) => idx === i ? updatedAgent : a)
                                          );
                                        } else if (e.target.value.match(/^\d*\.?\d*$/) == null) {
                                          e.preventDefault();
                                        } else {
                                          if ((e.target.value.indexOf(".") == -1 && e.target.value.length > 12) || (e.target.value.indexOf(".") != -1 && e.target.value.indexOf(".") > 12) || (e.target.value.indexOf('.') != -1 && e.target.value.match(/\./g)!.length >= 2)) {
                                            e.preventDefault();
                                          } else {
                                            form.updateValues('buyerAgents', form.values.buyerAgents?.map((a: any, i: number) => idx === i ? e.target.value.indexOf(".") == -1 ? { ...agent, commission: e.target.value } : (e.target.value.length - (e.target.value.indexOf(".") + 1)) > 2 ? { ...agent, commission: (Math.floor(parseFloat(e.target.value) * 100) / 100).toFixed(2) } : { ...agent, commission: e.target.value } : a));
                                          }
                                        }
                                      }}
                                    />
                                    {/* <TextField
                                      fullWidth
                                      label={langCloseTransaction.captionCommission}
                                      className={classes.dollarField}
                                      margin="dense"
                                      variant="outlined"
                                      InputProps={{
                                        disabled: allFieldsReadOnly || !canEditBuyerFields,
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        inputComponent: MaskedInput as any,
                                        inputProps: {
                                          value: agent.commission?.toString(),
                                          onAccept: (value: any) => {
                                            const updatedAgent = { ...agent, commission: value ? Number(value) : value };
                                            form.updateValues('buyerAgents',
                                              form.values.buyerAgents?.map((a: any, i: number) => idx === i ? updatedAgent : a)
                                            );
                                          }
                                        }
                                      }}
                                      error={(agent.type === 'I' && !!getAgentValidationMsg('BUYER', 'commission', agent.commission))}
                                      helperText={(agent.type === 'I') ? getAgentValidationMsg('BUYER', 'commission', agent.commission) : ''}
                                      required={agent.type === 'I'}
                                    /> */}
                                  </Grid>
                                </Grid>
                              );
                            })
                          }

                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </div>

                {/* UploadPane */}
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <Documents expandedMode sectionTitle={langCloseTransaction.titleSupplementalDocuments} fileForm={fileForm} docTypeOptions={uploadDocOptions} elementIdPrefix={'ct-dialog'} isCtDialog={true} canUploadCloseTrnxSupp={!allFieldsReadOnly} canDownloadUnownedCloseTrnxSupp={propertyStockDto.canReadUnowned} hideRemarksInListTable={true} closeTransactionStatus={closeTransactionDto.status} />
                </Grid>
              </Grid>
            </div>
          </form>

        </div>
      </Dialog>
      {/* --------------------- rejection dialog ----------------------*/}
      <Dialog open={rejectionDialogOpen} onClose={closeRejectionDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title"><Typography style={{ fontWeight: 'bold' }}>{langCloseTransaction.actionReject}</Typography></DialogTitle>
        <DialogContent className={classes.rejectionDialogContent}>

          <TextField
            autoFocus
            margin="dense"
            variant="outlined"
            id="name"
            label={langCloseTransaction.captionRejectionRemarks}
            {...bind('rejectionRemarks')}
            onChange={(e) => {
              limitTextFieldLength(e, 255, 'rejectionRemarks', form, keyBooleanMap, setKeyBooleanMap);
            }}
            fullWidth
          />
          <FormHelperText>{keyBooleanMap.get('rejectionRemarks') ? lang.textOverLimit : ''}</FormHelperText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeRejectionDialog} color="primary">
            {lang.actionCancel}
          </Button>
          <Button onClick={confirmRejection} color="primary" variant="contained" autoFocus>
            {langCloseTransaction.actionReject}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default CloseTransactionDialog;