import { Button, Card, CardActions, CardContent, Checkbox as MuiCheckbox, FormControl, FormControlLabel, Grid, InputLabel, ListItemText, makeStyles, MenuItem, Select, Switch, TextField, Typography, useTheme } from '@material-ui/core';
import { CardProps } from '@material-ui/core/Card';
import { TextFieldProps } from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { userApi } from 'api/userApi';
import { ClientSearchDTO, UserListItemDTO } from 'common/dto';
import MinMaxField, { BLANK_RANGE, isEmptyRange } from 'common/elements/MinMaxField';
import { useForm } from 'common/hooks';
import { HandleFormHelperText, tooltipHoc } from 'common/ui';
import { initFormKeysAndBooleanMap, isNonEmpty, limitTextFieldLength, multiLang } from 'common/utils';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';



const Checkbox = tooltipHoc(MuiCheckbox);

export interface SearchFormValues extends ClientSearchDTO {

  district: string[];
  key: string;

  //client preference
  isGross: boolean;
  isRent: boolean;
  usage: string[];
  gross: [number, number];
  net: [number, number];
  price: [number, number];
  rent: [number, number];

  //client basic info
  clientName: string;
  mainContact: string;
  clientType: string[];
  clientStatus: string[];
  source: string[];

  agent: string;
  agentId: string;
  agentNameEn: string,
  agentNameZh: string,
  remarks: string;
  dateModified: (string | null)[];
  isDormant: boolean;
  isCurrentUser: boolean;
  canClaimBy: string;
  buyRentPreferenceInputted: boolean;
}

interface PropertiesSearchProps extends CardProps, PAS.WishlistVariant {
  form: Partial<SearchFormValues>;
  onSearch?(values: Partial<SearchFormValues>): any;
  matchingClient?: any;
  canReadUnownedClient: boolean;
  canReadTeamClient: boolean;
  canCreateOwnClient: boolean;
  // variant?: 'wishlist' | undefined;
  // form: FormPresenter<SearchFormValues>;
}

const UITextField = (props: TextFieldProps & { fixedLabel?: boolean }) => {
  let { label, fixedLabel, ...rest } = props;
  fixedLabel = false;
  return <Fragment>
    {fixedLabel ? <Typography>{label}</Typography> : null}
    <TextField {...rest} label={!fixedLabel ? label : null} />
  </Fragment>
};

const InputGroup = (props: { children: React.ReactNode[], label?: string }) => {
  const { children } = props;
  return (
    <div
    // style={{ display: 'flex' }}
    >
      <Grid
        item
      // style={{ flex: 1 }}
      >
        {children[0]}
      </Grid>

      <Grid item style={{
        // width: 40, flexShrink: 1, 
        display: 'none'
      }}>
        <TextField
          fullWidth
          InputProps={{
            //style: { borderRadius: 0, borderLeft: 0, borderRight: 0 },
          }}
          inputProps={{
            //style: { paddingLeft: 0, paddingRight: 0, textAlign: 'center', borderLeft: 0, borderRight: 0 },
          }}
          //disabled value="至" 
          variant="outlined"
          margin="dense" />
      </Grid>

      <Grid
        item
        style={{
          // flex: 1, 
          display: 'none'
        }}
      // md={5}
      // xs={5}
      >
        {children[1]}
      </Grid>
    </div>
  );
}

InputGroup.leftItem = () => ({
  // style: { borderTopRightRadius: 0, borderBottomRightRadius: 0 } 
});
InputGroup.rightItem = () => ({
  // style: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } 
});


const useStyles = makeStyles((theme) => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  patchOutlineAutocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
      paddingLeft: 8,
    }
  },
  toggleLeftLabel: {
    paddingRight: 14,
  },
  dropdownBesideSearchBtn: {
    marginRight: 15,
    marginLeft: -10,
  }
}));


const PropertiesSearch = (props: PropertiesSearchProps) => {
  const { langPropertyStock, lang: langCommon, lang, locale, langClientDetail, clientStatusOptions,
    clientTypeOptions, sourceOfClientOptions, usageOptions, dormancyOptions } = useSelector((state: IRootState) => state.locale);

  const classes = useStyles();
  const theme = useTheme();

  const searchForm = useForm<SearchFormValues>({
    clientStatus: [],
    district: [],
    usage: [],
    isGross: false,
    isRent: false,
    gross: BLANK_RANGE,
    net: BLANK_RANGE,
    price: BLANK_RANGE,
    rent: BLANK_RANGE,

    dateModified: [null, null],

    source: [],
    clientType: [],
    isDormant: false,
    isCurrentUser: props.canCreateOwnClient,
    canClaimBy: 'D',
    ...props.form
  });

  const bind = searchForm.bind;

  const [disabledCriterias] = useState<{ [k: string]: boolean }>({
    // rent: true,
  });
  const filteredSearchForm = () => {
    searchForm.values = {
      ...searchForm.values,
      clientName: searchForm.values.clientName?.trim(),
      mainContact: searchForm.values.mainContact?.trim(),
      remarks: searchForm.values.remarks?.trim(),
    }

    const newForm: Partial<SearchFormValues> = {};

    Object
      .keys(searchForm.values)
      .filter((key) => !disabledCriterias[key])
      .forEach((key) => (newForm as any)[key] = searchForm.values[key as keyof SearchFormValues]);


    return newForm;
  };

  const isGross = searchForm.values.isGross ?? false;
  const setIsGross = (newVal: boolean) => {
    searchForm.updateValues('isGross', newVal);
    // swap
    if (newVal) {
      searchForm.modify({
        gross: searchForm.values.net,
        net: BLANK_RANGE,
      });
    } else {
      searchForm.modify({
        net: searchForm.values.gross,
        gross: BLANK_RANGE,
      });
    }
  };

  const isRent = searchForm.values.isRent ?? false;
  const setIsRent = (newVal: boolean) => {
    searchForm.updateValues('isRent', newVal);
    // swap
    if (newVal) {
      searchForm.modify({
        price: BLANK_RANGE,
        rent: BLANK_RANGE,
      });
    } else {
      searchForm.modify({
        rent: BLANK_RANGE,
        price: BLANK_RANGE,
      });
    }
  }

  //auto complete for agent
  const [agents, setAgents] = useState<UserListItemDTO[]>([]);
  const [agentListLoading, setAgentListLoading] = useState(false);

  const token: string = useSelector((state: IRootState) => state.login.token ?? '');
  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);

  const fetchAgents = (keyword: string) => {
    if (keyword.length < 2) { return; }
    setAgentListLoading(true);
    userApi.getListForAutocomplete({ name: keyword, onlyHasCreateOwnClientPrivilege: true }, token).then(result => {
      if (result.data?.content) {
        setAgents(result.data!.content);
        setAgentListLoading(false);
        setAgentUsername(result.data!.content.filter(agent => agent.username != 'companyStockAgent').map((agent) => agent.username));

      }
    });
  }

  const [agentUsername, setAgentUsername] = useState(agents.filter(agent => agent.username != 'companyStockAgent').map((agent) => agent.username));
  // const agentNames = agents.map((agent) => locale === 'en' ? agent.englishName : agent.englishName);
  const agentMap: { [name: string]: UserListItemDTO } = {};
  agents.forEach((agent) => {
    agentMap[agent.username] = agent;
  })

  const [keywordOfAgent, setKeywordOfAgent] = useState('')

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchAgents(keywordOfAgent);
    }, 1000);
    return () => clearTimeout(timer);
  }, [keywordOfAgent]);

  const [buyRentPreferenceInputted, setBuyRentPreferenceInputted] = useState(false);

  useEffect(() => {
    if (isEmptyRange(searchForm.values.gross) && isEmptyRange(searchForm.values.net) && isEmptyRange(searchForm.values.price) && isEmptyRange(searchForm.values.rent) && !isNonEmpty(searchForm.values.usage)) {
      setBuyRentPreferenceInputted(false);
      searchForm.updateValues('buyRentPreferenceInputted', false);

    } else {
      setBuyRentPreferenceInputted(true);
      searchForm.setValues({
        ...searchForm.values,
        // clientType: ['B', 'T'],
        buyRentPreferenceInputted: true,
      });
    }
  }, [searchForm.values.gross, searchForm.values.net, searchForm.values.price, searchForm.values.rent, searchForm.values.usage])

  const [keyBooleanMap, setKeyBooleanMap] = useState(initFormKeysAndBooleanMap(searchForm));
  return (
    <Card
    // {...props}
    >
      <form
        autoComplete="off"
        noValidate
      >

        <CardContent>
          <Grid
            container
            spacing={1}
          >


            <Grid item container md={6} spacing={1}>
              {/* ----------- clientName ------------- */}
              <Grid
                item
                md={6}
                sm={6}
                xs={12}
              >
                <UITextField
                  label={langClientDetail.clientName}
                  {...bind('clientName')}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  onChange={(e) => {
                    limitTextFieldLength(e, 255, 'clientName', searchForm, keyBooleanMap, setKeyBooleanMap);
                  }}

                />
                <HandleFormHelperText
                  isError={keyBooleanMap.get('clientName')}
                  errorMessage={lang.textOverLimit}
                />
              </Grid>

              {/* ----------- mainContact ------------- */}
              <Grid
                item
                md={6}
                sm={6}
                xs={12}
              >
                <UITextField
                  label={langClientDetail.phoneOrEmail}
                  {...bind('mainContact')}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  onChange={(e) => {
                    limitTextFieldLength(e, 255, 'mainContact', searchForm, keyBooleanMap, setKeyBooleanMap);
                  }}
                />
                <HandleFormHelperText
                  isError={keyBooleanMap.get('mainContact')}
                  errorMessage={lang.textOverLimit}
                />
              </Grid>

            </Grid>


            <Grid item container md={6} spacing={1}>
              {/* ----------- clientType ------------- */}

              <Grid
                item
                md={3}
                sm={3}
                xs={12}
              >

                <FormControl variant="outlined" margin="dense" fullWidth>
                  <InputLabel variant="outlined" style={{ background: '#fff' }}>{langClientDetail.clientType}</InputLabel>
                  <Select
                    multiple
                    {...bind('clientType')}
                    renderValue={(selected) => (selected as string[]).map(key => clientTypeOptions[key]).join(', ')}
                  >
                    {Object.keys(clientTypeOptions).map(key => (
                      <MenuItem key={key} value={key} disabled={buyRentPreferenceInputted && (key === 'L' || key === 'S')}>
                        <Checkbox checked={searchForm.values.clientType!.indexOf(key) > -1} />
                        <ListItemText primary={clientTypeOptions[key]} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* ----------- clientStatus ------------- */}
              <Grid
                item
                md={3}
                sm={3}
                xs={12}
              >
                <FormControl variant="outlined" margin="dense" fullWidth>
                  <InputLabel variant="outlined" style={{ background: '#fff' }}>{langClientDetail.clientStatus}</InputLabel>
                  <Select
                    multiple
                    {...bind('clientStatus')}
                    renderValue={(selected) => (selected as string[]).map(key => clientStatusOptions[key]).join(', ')}
                  >
                    {Object.keys(clientStatusOptions ?? {}).map(key => (
                      <MenuItem key={key} value={key}>
                        <Checkbox checked={searchForm.values.clientStatus!.indexOf(key) > -1} />
                        <ListItemText primary={clientStatusOptions[key]} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* ----------- usage ------------- */}
              <Grid
                item
                md={3}
                sm={3}
                xs={12}
              >

                <FormControl variant="outlined" margin="dense" fullWidth>
                  <InputLabel variant="outlined" style={{ background: '#fff' }}>{langClientDetail.propertyUsage}</InputLabel>
                  <Select
                    multiple
                    {...bind('usage')}
                    renderValue={(selected) => (selected as string[]).map(val => usageOptions[val]).join(', ')}
                  >
                    {Object.keys(usageOptions).map(key => (
                      <MenuItem key={key} value={key}>
                        <Checkbox checked={(searchForm.values.usage ?? []).indexOf(key) > -1} />
                        <ListItemText primary={usageOptions[key]} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* ----------- source ------------- */}
              <Grid
                item
                md={3}
                sm={3}
                xs={12}
              >

                <FormControl variant="outlined" margin="dense" fullWidth>
                  <InputLabel variant="outlined" style={{ background: '#fff' }}>{langClientDetail.sourceOfClient}</InputLabel>
                  <Select
                    multiple
                    {...bind('source')}
                    renderValue={(selected) => (selected as string[]).map(key => sourceOfClientOptions[key]).join(', ')}
                  >
                    {Object.keys(sourceOfClientOptions).map(key => (
                      <MenuItem key={key} value={key}>
                        <Checkbox checked={searchForm.values.source!.indexOf(key) > -1} />
                        <ListItemText primary={sourceOfClientOptions[key]} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            {/* ----------- agent (HIDDEN) ------------- */}
            <Grid
              item
              md={3}
              xs={12}
              style={{ display: 'none' }}
            >


              <Autocomplete
                // disabled={!editAgentEnabled}
                options={agentUsername}
                noOptionsText={''}
                loading={agentListLoading}
                loadingText={langCommon.msgLoading}
                filterOptions={(option) => option}
                renderOption={(option) => <div>
                  <div><Typography >{`${agents.filter(agent => agent.username === option)?.[0]?.englishName ?? ''}${agents.filter(agent => agent.username === option)?.[0]?.chineseName ? '/' : ''}${agents.filter(agent => agent.username === option)?.[0]?.chineseName ?? ''}`}</Typography></div>
                  <div><Typography variant="caption">{multiLang(locale, agents.filter(agent => agent.username === option)?.[0]?.chineseName, agents.filter(agent => agent.username === option)?.[0]?.englishName)}</Typography></div>
                </div>}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    className={classes.patchOutlineAutocomplete}
                    // error={!!form.hasError('building')}
                    // helperText={form.hasError('building')}
                    fullWidth
                    label={langClientDetail.agent}
                    margin="dense"
                    variant="outlined"
                    onFocus={(ev) => {
                      setAgentUsername([]);
                      setKeywordOfAgent(ev.target.value);
                    }}
                    onChange={(ev) => {
                      // fetchAgents(ev.target.value)
                      setAgents([]);
                      setAgentUsername([]);
                      setKeywordOfAgent(ev.target.value);
                      setAgentListLoading(true);
                      if (ev.target.value.length < 2 || !isNonEmpty(ev.target.value)) {
                        setAgentListLoading(false);
                      }
                    }}
                  />
                )}

                value={multiLang(locale === 'en' ? 'zh_HK' : 'en', searchForm.values.agentNameZh, searchForm.values.agentNameEn)}

                onChange={(_: any, val: any) => {
                  if (agentMap[val]) {
                    const { id, chineseName, englishName } = agentMap[val];
                    searchForm.setValues({
                      ...searchForm.values,
                      agentId: id,
                      agentNameEn: englishName,
                      agentNameZh: chineseName,

                    })

                  } else {
                    searchForm.setValues({
                      ...searchForm.values,
                      agentId: undefined,
                      agentNameEn: undefined,
                      agentNameZh: undefined,

                    });
                  }
                }}
                getOptionLabel={() => multiLang(locale, searchForm.values.agentNameZh, searchForm.values.agentNameEn) ?? ''}
              />
            </Grid>

            <Grid item container md={12} xs={12} spacing={1}>
              {/* ----------- remarks ------------- */}
              <Grid
                item
                md={9}
                sm={8}
                xs={12}
              >
                <UITextField
                  label={langClientDetail.remarks}
                  {...bind('remarks')}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  onChange={(e) => {
                    limitTextFieldLength(e, 255, 'remarks', searchForm, keyBooleanMap, setKeyBooleanMap);
                  }}
                />
                <HandleFormHelperText
                  isError={keyBooleanMap.get('remarks')}
                  errorMessage={lang.textOverLimit}
                />
              </Grid>



              {/* ----------- dateModified ------------- */}
              <Grid
                item
                md={3}
                sm={4}
                xs={12}
              >
                {/* <UITextField
                      label={langTemp.dateModified}
                      {...bind('dateModified')}
                      fullWidth
                      margin="dense"
                      variant="outlined"
                    /> */}
                {/* <InputGroup> */}
                <KeyboardDatePicker autoOk={true}
                  // InputProps={InputGroup.leftItem()}
                  //disableToolbar
                  fullWidth
                  variant="inline"
                  inputVariant="outlined"
                  format={DISPLAY_DATE_FORMAT}
                  label={langCommon.dateModified}
                  margin="dense"
                  value={searchForm.values.dateModified?.[0] ? moment(searchForm.values.dateModified?.[0]) : null}
                  onChange={(value) => {
                    searchForm.setValues({
                      ...searchForm.values,
                      dateModified: [value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? null, searchForm.values.dateModified?.[1] ?? null]
                    });
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />

                <KeyboardDatePicker autoOk={true}
                  // InputProps={InputGroup.rightItem()}
                  //disableToolbar
                  variant="inline"
                  inputVariant="outlined"
                  format={DISPLAY_DATE_FORMAT}
                  style={{ display: 'none' }}
                  // label={langTemp.dateModified}
                  margin="dense"
                  value={searchForm.values.dateModified?.[1] ? moment(searchForm.values.dateModified?.[1], 'DD/MM/YYYY') : null}
                  onChange={(_, value) => {
                    searchForm.setValues({
                      ...searchForm.values,
                      dateModified: [searchForm.values.dateModified?.[0] ?? null, value ?? null]
                    });
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
            </Grid>

            {/* ----------- net/gross ------------- */}
            <Grid item md={6} sm={6} xs={12} style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }}>

              <Grid
                item
                md={12}
                sm={12}
                xs={12}
              >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Typography style={{ marginRight: theme.spacing(3), fontWeight: 'bold' }}>{langPropertyStock.captionArea}</Typography>
                  <Typography>{langPropertyStock.captionNet}</Typography>
                  <Switch
                    checked={isGross}
                    onChange={(_, val) => setIsGross(val)}
                  />
                  <Typography>{langPropertyStock.captionGross}</Typography>
                </div>
              </Grid>

              <Grid item md={12} sm={12} xs={12}>
                <MinMaxField
                  range={isGross ? searchForm.values.gross! : searchForm.values.net!}
                  unit={langCommon.uFt}
                  onChange={(newRange) => {
                    const key = isGross ? 'gross' : 'net';
                    searchForm.modify({
                      [key]: newRange,
                    });
                  }}
                />
              </Grid>
            </Grid>

            {/* ----------- price/rent ------------- */}
            <Grid item md={6} sm={6} xs={12} style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }}>
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
              >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Typography style={{ marginRight: theme.spacing(3), fontWeight: 'bold' }}>{langPropertyStock.captionPriceOrRent}</Typography>
                  <Typography>{langPropertyStock.price}</Typography>
                  <Switch
                    checked={isRent}
                    onChange={(_, val) => setIsRent(val)}
                  />
                  <Typography>{langPropertyStock.rent}</Typography>
                </div>
              </Grid>

              <Grid item md={12} sm={12} xs={12}>
                <MinMaxField
                  range={isRent ? searchForm.values.rent! : searchForm.values.price!}
                  unit={!isRent ? (locale === 'en' ? langCommon.u1M : langCommon.u10k) : undefined}
                  onChange={(newRange) => {
                    const key = isRent ? 'rent' : 'price';
                    searchForm.setValues({
                      ...searchForm.values,
                      [key]: newRange,
                    });
                  }}
                  digitLimit={isRent ? 12 : locale == 'en' ? 6 : 8}
                />
              </Grid>

            </Grid>



          </Grid>
        </CardContent>
      </form>

      <CardActions style={{ flexWrap: 'wrap' }}>

        {/* <Typography>{props.matchingClient ? '對盤條件' : '搜尋條件'}:{' '}</Typography>
        {
          Object.keys(searchForm.values).filter((key) => {
            return isNonEmpty(searchForm.values[key as keyof SearchFormValues]) && !!langTemp[key as keyof typeof langTemp];
          }).map((key) => {
            return <Chip style={{ marginBottom: theme.spacing(.5), background: disabledCriterias[key] ? 'inherit' : '#444' }} variant={disabledCriterias[key] ? 'outlined' : 'default'} color={disabledCriterias[key] ? 'default' : 'primary'} label={langTemp[key as keyof typeof langTemp]} onClick={() => setDisabledCriterias(val => ({ ...val, [key]: !val[key] }))} />
          })
        } */}
        <div style={{ flexGrow: 1 }} />

        {props.canReadUnownedClient &&
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: theme.spacing(-1) }}>
            <Typography className={classes.toggleLeftLabel}>{langClientDetail.nonDormantClients}</Typography>
            <FormControlLabel
              control={
                // <Checkbox
                <Switch
                  checked={searchForm.values.isDormant ?? false}
                  onChange={(_, checked) => {
                    searchForm.updateValues('isDormant', checked ? true : false)
                    searchForm.updateValues('isCurrentUser', !searchForm.values.isCurrentUser)
                  }
                  }
                />
              }
              label={langClientDetail.dormantClients}
            />
          </div>
        }

        {props.canReadUnownedClient && !!searchForm.values.isDormant &&
          <TextField
            className={classes.dropdownBesideSearchBtn}
            margin="dense"
            // required
            select
            variant="outlined"
            label={langClientDetail.canClaimBy}
            {...bind('canClaimBy')}
          >
            {
              Object.keys(dormancyOptions).filter((key) => key !== 'N').map((key) => (
                <MenuItem key={key} value={key}>{dormancyOptions[key]}</MenuItem>
              ))
            }
          </TextField>
        }

        {(props.canReadUnownedClient || props.canReadTeamClient) && !searchForm.values.isDormant &&
          <FormControlLabel control={<Checkbox disabled={searchForm.values.isDormant ?? false} checked={searchForm.values.isCurrentUser ?? false} onChange={(_, checked) => searchForm.updateValues('isCurrentUser', checked ? true : false)} />} label={langClientDetail.ownClients} />}
        <Button variant="contained" color="primary" onClick={() => props.onSearch?.(filteredSearchForm())}>{langPropertyStock.actionSearch}</Button>
      </CardActions>
    </Card>
  );
};


export default PropertiesSearch;