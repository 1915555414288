import { ClientDetailDTO, ClientPreferencesDTO } from "common/dto";
import { FormValidations } from "common/hooks";
import { checkValidEmail, checkValidPhoneNumber, isNonEmpty } from 'common/utils';
import { isNullOrUndefined } from "util";

type Options = { [key: string]: string }

export const getInvalidContactIndexArrayByPhoneNum=(formValues: any, key: string)=>{
  const invalidContacts = formValues[key]?.map((c:any, idx:string )=>  c.type === 'TEL' && !checkValidPhoneNumber(c.value) ? idx : -1 ).filter((idx:number) => idx> -1);
  return invalidContacts ?? [];
}

export const getInvalidContactIndexArrayByEmail=(formValues: any, key: string)=>{
  const invalidContacts = formValues[key]?.map((c:any, idx:string )=>  c.type === 'EMAIL' && !checkValidEmail(c.value) ? idx : -1 ).filter((idx:number) => idx> -1);
  return invalidContacts ?? [];
}

export const getEmptyContactValueIndexArray=(formValues: any, key: string)=>{
  const invalidContacts = formValues[key]?.map((c:any, idx:string )=> ( c.type !== 'TEL' && c.type !== 'EMAIL') && !isNonEmpty(c.value) ? idx : -1 ).filter((idx:number) => idx> -1);
  return invalidContacts ?? [];
}

export const CompactClientCreationValidations = (langClientDetail: Options): FormValidations<ClientDetailDTO> => [
  (values) => values.chineseName ? null: [
    [ 'chineseName', langClientDetail.msgPleaseEnterClientName ],
  ],
  (values) => (values.otherContacts?.filter(c => c.value?.length).length ?? 0) > 0 ? null : [
    [ 'otherContacts', '(AT_LEAST_ONE_CONTACT)' ],
  ],
  (values) => getInvalidContactIndexArrayByPhoneNum(values,'otherContacts').length  ==  0 ? null : [
    [ 'invalidPhoneNumber', getInvalidContactIndexArrayByPhoneNum(values,'otherContacts').join('_')  ],
  ],
  (values) => getInvalidContactIndexArrayByEmail(values,'otherContacts').length  ==  0 ? null : [
    [ 'invalidEmail', getInvalidContactIndexArrayByEmail(values,'otherContacts').join('_')  ], 
  ],
  (values) => getEmptyContactValueIndexArray(values,'otherContacts').length  ==  0 ? null : [
    [ 'emptyContactValue', getEmptyContactValueIndexArray(values,'otherContacts').join('_')  ], 
  ],
  // (values) => (values.source?.length ?? 0) > 0 ? null: [
  //   [ 'source', langClientDetail.msgInputSource ],
  // ],
  // (values) => isNonEmpty(values.clientFeatures?.clientType) ? null: [
  //   [ 'missingClientType', langClientDetail.msgInputClientType ],
  // ],
  (values) => values.agentFieldRequired !== true  ? null:  
    (  !isNullOrUndefined(values.agentId)  ?  null : ([ [ 'missingAgent', langClientDetail.msgInputAgent ], ] ))
  ,
];

export const ClientDetailFormValidations = (langClientDetail: Options): FormValidations<ClientDetailDTO> => [
  (values) => values.chineseName? null: [
    [ 'chineseName', langClientDetail.msgPleaseEnterClientName ],
  ],
  (values) => (isNullOrUndefined(values.showClientContact ) ||  values.showClientContact === true) && !((values.otherContacts?.filter(c => c.value?.length).length ?? 0) > 0) ?  [
    [ 'otherContacts', '(AT_LEAST_ONE_CONTACT)' ],
  ] : null,
  (values) => getInvalidContactIndexArrayByPhoneNum(values,'otherContacts').length  ==  0 ? null : [
    [ 'invalidPhoneNumber', getInvalidContactIndexArrayByPhoneNum(values,'otherContacts').join('_')  ],
  ],
  (values) => getInvalidContactIndexArrayByEmail(values,'otherContacts').length  ==  0 ? null : [
    [ 'invalidEmail', getInvalidContactIndexArrayByEmail(values,'otherContacts').join('_')  ], 
  ],
  (values) => getEmptyContactValueIndexArray(values,'otherContacts').length  ==  0 ? null : [
    [ 'emptyContactValue', getEmptyContactValueIndexArray(values,'otherContacts').join('_')  ], 
  ],
  // (values) => (values.source?.length ?? 0) > 0 ? null: [
  //   [ 'source', langClientDetail.msgInputSource ],
  // ],

  (values) => !isNullOrUndefined(values.clientPreferences?.clientType) &&  values.clientPreferences?.clientType !== "" ? null: [
    [ 'missingClientType', langClientDetail.msgInputClientType ],
  ],
  (values) => values.clientPreferences?.clientType?.includes('B') || values.clientPreferences?.clientType?.includes('T') ? 
  (   (values.clientPreferences?.usage?.length ?? 0) > 0  ?  null : ([ [ 'missingUsage', langClientDetail.msgInputUsage ], ] ))
  :  null,
  // (values) => values.clientPreferences?.clientType?.includes('B') || values.clientPreferences?.clientType?.includes('T') ? 
  // (   (values.clientPreferences?.district?.length ?? 0) > 0  ?  null : ([ [ 'missingDistrict', langClientDetail.msgInputDistrict ], ] ))
  // :  null,
];

export const tagFormValidations = (): FormValidations<{ [type: string]: string[] }> => [
  // (values) => (values.clientType?.length ?? 0) > 0 ? null: [
  //   [ 'clientType', langClientDetail.msgInputClientType ],
  // ],
  // (values) => values.clientType?.includes('B') || values.clientType?.includes('T') ? 
  //     (values.usage?.length ?? 0) > 0 ?  null : ([ [ 'usage', langClientDetail.msgInputUsage ], ] )
  // :  null,

];

export const MultiSelectFormValidations = (): FormValidations<{ [type: string]: string[] }> => [
  
];

export const PreferenceFormValidations = (langClientDetail: Options): FormValidations<ClientPreferencesDTO> => [
  // (values) => values.netAreaFrom? null: [
  //   [ 'netAreaFrom', langClientDetail.msgInputNet ],
  // ],
  // (values) => values.netAreaTo? null: [
  //   [ 'netAreaTo', langClientDetail.msgInputNet ],
  // ],
  // (values) => values.buyBudgetFrom? null: [
  //   [ 'buyBudgetFrom', langClientDetail.msgInputPrice ],
  // ],
  // (values) => values.buyBudgetTo? null: [
  //   [ 'buyBudgetTo', langClientDetail.msgInputPrice ],
  // ],
  // (values) => values.rentBudgetFrom? null: [
  //   [ 'rentBudgetFrom', langClientDetail.msgInputRent ],
  // ],
  // (values) => values.rentBudgetTo? null: [
  //   [ 'rentBudgetTo', langClientDetail.msgInputRent ],
  // ],

  // (values) => values.usage ? null: [
  //   [ 'usage', langClientDetail.msgInputUsage ],
   
  // ],

  (values) => (values.district?.length ?? 0) > 0  ? null: [
    [ 'district', langClientDetail.msgInputDistrict ],
   
  ],
];