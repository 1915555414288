import { ClickAwayListener, Grow, Paper, Popper, useMediaQuery, useTheme } from '@material-ui/core';
import React, { CSSProperties, useDebugValue, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { IRootState } from 'reducers';
import theme from 'theme';

interface IValuedEvent {
  target: { value: any };
}

export interface FormPresenter<T> {
  bind: (name: keyof T) => {
    onChange: (e: IValuedEvent) => any;
    value: any;
  };
  // bindBool: (name: keyof T) => any;
  // bindNumber: (name: keyof T) => any;
  // bindString: (name: keyof T) => any;
  values: Partial<T>;
  updateValues: (name: keyof T, value: T[keyof T], callback?: (newVal: Partial<T>) => any) => any;
  setValues: (values: Partial<T>) => any;
  modify: (values: Partial<T>) => any;
  // Validations
  hasValidationError: boolean;
  hasError: (name: keyof T) => string | undefined;
  validate: () => boolean;
  stopValidation: () => void;
  validationErrors: { [key: string]: string };
  isDisabled: (name: keyof T) => boolean;
}

interface Accessors<V> {
  toDisplay: (val: V) => any;
  toVal: (display: any) => V;
}

// export function useFormEx<T>(values: Partial<T>, setValues: (values: Partial<T>) => any): FormPresenter<T> {
//   const updateValues = (name: keyof T, value: T[typeof name]) => {
//     setValues({
//       ...values,
//       [name]: value,
//     });
//   };

//   return {
//     values, setValues, updateValues,
//     bind: (name: keyof T) => {
//       return {
//         onChange: (e: IValuedEvent) => updateValues(name, e.target.value as any),
//         value: values[name] || '',
//         key: `field-${options.dataKeyPrefix}-${name}`,
//       };
//     },
//     hasValidationError: false,
//     validate: () => true,
//     hasError: (name) => undefined,
//   };
// }

export type FormValidations<T, ErrorKeys = keyof T> = Array<(values: Partial<T>) => [ErrorKeys, string][] | null>;

export interface FormOptions<T, ErrorKeys = keyof T> {
  requiredHelperText?: string;
  validations?: FormValidations<T, ErrorKeys>;
  label?: string;
  getDisabledProps?: () => object;
  allDisabled?: boolean;
  disabledFields?: { [key: string]: boolean };
  scrollToErrorOnValidate?: boolean;
  dataKeyPrefix?: string;
}

export function useForm<T, ErrorKeys = keyof T>(initialValues: Partial<T> = {}, options: FormOptions<T, ErrorKeys> = {}): FormPresenter<T> {
  useDebugValue(options.label);
  const [values, _setValues] = useState(initialValues);
  const [touched, setTouched] = useState({} as { [key: string]: boolean });
  const [validateClicked, setValidateClicked] = useState(false);
  // For debuging errors in React Dev Tool
  const debugError = useRef<any>(null);

  const validationErrors: { [key: string]: string } = {};
  options.validations?.forEach(func => {
    let results = func(values);
    if (!results) return;

    results.forEach(([k, v]) => validationErrors[k as unknown as string] = v);

  });
  const hasValidationError = Object.keys(validationErrors).length > 0;

  useEffect(() => {
    if (process.env['NODE_ENV'] === 'development') {
      debugError.current = validationErrors;
    }
  }, [validationErrors]);

  const updateValues = (name: keyof T, value: T[typeof name], callback?: (newVal: Partial<T>) => any) => {
    _setValues((prevValues) => {
      const newVal = {
        ...prevValues,
        [name]: value,
      };
      callback?.(newVal);
      return newVal;
    });
  };

  const setValues = (newValues: Partial<T>) => _setValues(_ => newValues);
  const modify = (newValues: Partial<T>) => _setValues(prevValues => ({ ...prevValues, ...newValues }));

  const bindInternal = (name: keyof T) => {
    const shouldDisplayError = validateClicked || touched[name as string];
    return {
      // onChange: (e: IValuedEvent) => updateValues(name, e.target.value as any),
      // value: values[name] ?? '',
      key: `field-${options.dataKeyPrefix}-${name}`,
      inputProps: {
        ...theme.props?.MuiInputBase?.inputProps,
        'data-key': `field-${options.dataKeyPrefix}-${name}`,
      },
      'data-key': `field-${options.dataKeyPrefix}-${name}`,
      // Validations
      error: shouldDisplayError && validationErrors[name as string],
      helpertext: shouldDisplayError ? validationErrors[name as string] : undefined,
      onBlur: (e: React.FocusEvent) => {
        setTouched(touched => ({ ...touched, [name as string]: true }));
        // setValidateClicked(false);
      },
    };
  };

  const isDisabled = (name: keyof T) => {
    return options.allDisabled || options.disabledFields?.[name as string] || false;
  };

  return {
    values, setValues, updateValues, modify, hasValidationError,
    bind: (name: keyof T) => {
      return {
        ...bindInternal(name),
        onChange: (e: IValuedEvent) => updateValues(name, e.target.value as any),
        value: values[name] ?? '',
        ...(isDisabled(name) ? options.getDisabledProps?.() : {}),
      };
    },

    isDisabled,

    validate: () => {


      setValidateClicked(true);
      if (options.scrollToErrorOnValidate && hasValidationError) {
        document.querySelector(`[data-key="field-${options.dataKeyPrefix}-${Object.keys(validationErrors)[0]}"]`)?.scrollIntoView(true);
        window.scrollBy(0, -200)
      }
      return !hasValidationError;
    },
    stopValidation: () => {
      setTouched({});
      setValidateClicked(false);
    },
    validationErrors,
    hasError: (name) => (validateClicked || touched[name as string]) ? validationErrors[name as string] : undefined,
  };
}

export const useMenu = () => {
  const [opened, isOpened] = useState(false);
  const open = () => isOpened(true);
  const anchorEl = useRef<any>();
  const onClose = () => isOpened(false);

  return {
    onClose, opened, open, anchorEl,
    menuProps: () => ({
      anchorEl: anchorEl.current,
      open: opened,
      onClose,
    }),
    buttonProps: () => ({
      ref: anchorEl,
      onClick: open,
    }),
    withClosing: (action: (...args: any[]) => any) => (...args: any[]) => {
      action(...args);
      isOpened(false);
    }
  };
};

export const usePopup = () => {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const anchorRef = React.useRef<any>(null);

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const Popup = ({ children, style }: React.PropsWithChildren<{ style: CSSProperties }>) => (
    <Popper style={{ zIndex: 100000 }} placement="bottom-start" open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <Paper style={style}>
              {children}
            </Paper>
          </ClickAwayListener>
        </Grow>
      )}
    </Popper>
  );

  return { Popup, anchorRef, open, setOpen, toggle };
};

export const useDialog = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return {
    open: () => setOpen(true),
    close: () => setOpen(false),
    dialogProps: () => ({ open, onClose: handleClose }),
    buttonProps: () => ({ onClick: open }),
  }
};

export const useAppBarHeight = () => {
  const theme = useTheme();
  const { iosStatusBarHeight } = useSelector((state: IRootState) => state.layout);
  const largerAppBar = useMediaQuery(theme.breakpoints.up('sm'));
  const appBarHeight = (largerAppBar ? 64 : 56) + iosStatusBarHeight;
  return appBarHeight;
};

export const useRouteBasedState = (path: string, extraCondition = true) => {
  const history = useHistory();
  const openState = useRouteMatch(path) !== null && extraCondition;
  const setOpenState = (open: boolean) => {
    if (open && !openState) {
      history.push(path);
    } else if (openState) {
      history.goBack();
    }
  }

  return [openState, setOpenState] as const;
}