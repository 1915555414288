import { Card, CardContent } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
// import _mockData from 'views/PropertyList/data';
import { IRootState, PASDispatch } from 'reducers';
// import { useTheme, Button as MuiButton } from '@material-ui/core';
import { PropertiesTable } from 'views/PropertyList/components';
// import { tooltipHoc } from 'common/ui';

// const Button = tooltipHoc(MuiButton);
// const mockData = _mockData.filter(n => !!n.status);

// const useStyles = makeStyles((theme) => ({
//   active: {
//     color: theme.palette.common.white,
//     background: theme.palette.primary.dark,
//     '&:hover': {
//       background: theme.palette.primary.dark,
//     }
//   },
// }));

const SellerStockList = () => {
  // const theme = useTheme();
  // const classes = useStyles();
  const dispatch = useDispatch() as PASDispatch;

  const { cid }: any = useParams();
  const properties = useSelector((state: IRootState) => state.clients.sellerStockList);
  const { totalSellerStockPages, totalSellerStockElements } = useSelector((state: IRootState) => state.clients);

  const [page, setPage] = useState(0);

  const [limit, setLimit] = useState(10);
  const handleRowsCount = (count: number) => {
    setLimit(count);
  }

  useEffect(() => {
    dispatch({
      type: 'SellerStock.FetchRequested', cid: cid ?? '', payload: {
        page: page, size: limit, sort: {}
      }
    })
  }, []);

  return <Card>
    <CardContent>
      <PropertiesTable
        totalPages={totalSellerStockPages ?? 1}
        totalElements={totalSellerStockElements ?? 0}
        limit={limit}
        page={page}
        setPage={(page) => {
          setPage(page);
          dispatch({
            type: 'SellerStock.FetchRequested', cid: cid ?? '', payload: {
              page: page, size: limit, sort: {}
            }
          });
        }}
        rowsCountOptions={[10]}
        handleRowsCount={handleRowsCount}
        className=""
        properties={properties ?? []}
        isSellerStockPage={true}
        isWishListOrSellerStock={true}
      // sortOrders={sortOrders}
      />
    </CardContent>
  </Card>;
};

export default SellerStockList;