import {
  Button, Card, CardContent, CardHeader, Divider, Grid as MuiGrid, ListSubheader, makeStyles,
  Menu, MenuItem, Typography
} from '@material-ui/core';
import { hasPermission } from 'common/access-control';
import { useConfirmDialog } from 'common/ConfirmDialog';
import { PropertyStockDTO, WishlistGrantedAgentDTO } from 'common/dto';
import UserSelectionDialog from 'common/elements/UserSelectionDailog';
import { FormPresenter, useMenu } from 'common/hooks';
import { hideWhenHoc } from 'common/ui';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState, PASDispatch } from 'reducers';
import { store } from 'store';
import LeadAgentNameCard from 'views/PreviewPropertStock/components/LeadAgentNameCard';
import AddLeadAgentDialog from './AddLeadAgentDialog';

const Grid = hideWhenHoc(MuiGrid);

interface LeadAgentPanelProps {
  form: FormPresenter<PropertyStockDTO>;
  wishlistGrantedAgents: WishlistGrantedAgentDTO[];
  onWishlistGrantedAgentAdded: (grantedAgentUid: number) => void;
  onWishlistGrantedAgentRemoved: (grantedAgentUid: number) => void;
}

const COLORS = {
  textLeadAgent: '#665300',
  textBuyerAgent: '#005c66',
}

const useStyles = makeStyles(theme => ({
  sectionHeader: {
    paddingBottom: 8
  },
  sectionHeaderTextLeadAgent: {
    color: COLORS.textLeadAgent,
    fontSize: 14,
  },
  sectionHeaderTextBuyerAgent: {
    color: COLORS.textBuyerAgent,
    fontSize: 14,
  },
  leadAgentSection: {
    paddingRight: 8,
  },
  buyerSection: {
    paddingLeft: 8,
    borderLeft: '1px solid #ccc',
  },
}));

const LeadAgentPanel = (props: LeadAgentPanelProps) => {
  const { form } = props;
  const { lang, langPropertyStock, locale } = useSelector((state: IRootState) => state.locale);
  const dispatch = useDispatch() as PASDispatch;
  const classes = useStyles();

  const confirmRemoveAgentDialog = useConfirmDialog();

  const { uid, privileges } = useSelector((state: IRootState) => state.login);
  const canUpdateUnowned = hasPermission(privileges, 'UPDATE', 'UNOWNED_PROPERTY_STOCK');
  const canReadUnownedClient = hasPermission(privileges, 'READ', 'UNOWNED_CLIENT');

  const numOfLeadAgent = form.values.leadAgents?.length ?? 0;

  const handleRemoveLeadAgent = (agentId: string) => () => {
    dispatch({ type: 'Property.RemoveLeadAgentRequested', payload: { pid: form.values.id ?? '', agentId: agentId ?? '' } });
  }

  //for lead agent dialog
  const [openAddLeadAgentDialog, setOpenAddLeadAgentDialog] = React.useState(false);

  const handleClickOpenAddLeadAgentDialog = () => {
    setOpenAddLeadAgentDialog(true);
  };

  const handleCloseAddLeadAgentDialog = () => {
    setOpenAddLeadAgentDialog(false);
  };

  // add menu
  const addMenu = useMenu();

  // for wishlist granted agents
  const [openWishlistGratedAgentAddDialog, setOpenWishlistGratedAgentAddDialog] = React.useState(false);
  const handleOpenWishlistGratedAgentAddDialog = () => {
    setOpenWishlistGratedAgentAddDialog(true);
  };

  const handleCloseWishlistGratedAgentAddDialog = (selected: number | undefined) => {
    setOpenWishlistGratedAgentAddDialog(false);
    if (selected) {
      props.onWishlistGrantedAgentAdded(selected);
    }
  };

  const pid = form.values.id;
  const isLeadAgent = form.values.isLeadAgent;
  useEffect(() => {
    store.dispatch({ type: 'Property.WishlistGrantedAgents.Loaded', payload: [] })
    if (pid && isLeadAgent) {
      dispatch({ type: 'Property.WishlistGrantedAgents.FetchRequested', payload: { propertyStockId: Number(pid ?? '0') } });
    }
  }, [pid, isLeadAgent]);


  return <Card>
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <CardHeader
        title={<div>
          {langPropertyStock.titleRelatedAgentsInfo}
        </div>}
      />
      <div style={{ flexGrow: 1 }}></div>
      <Button {...addMenu.buttonProps()} color="primary">{lang.actionAdd}</Button>
      <Menu {...addMenu.menuProps()} >
        <ListSubheader>{lang.actionAdd}...</ListSubheader>
        {canUpdateUnowned && canReadUnownedClient ?
          <MenuItem onClick={addMenu.withClosing(handleClickOpenAddLeadAgentDialog)}>{langPropertyStock.actionAddLeadAgent}</MenuItem>
          : null}
        {isLeadAgent ? <MenuItem onClick={addMenu.withClosing(handleOpenWishlistGratedAgentAddDialog)}>{langPropertyStock.actionGrantedForForm46}</MenuItem> : null}
      </Menu>
      {canUpdateUnowned && canReadUnownedClient ?
        <AddLeadAgentDialog hideOpenButton open={openAddLeadAgentDialog} handleClickOpen={handleClickOpenAddLeadAgentDialog} handleClose={handleCloseAddLeadAgentDialog} stockForm={form} createPropertyStock={() => { return; }} />
        : null}
      <UserSelectionDialog
        actionName={langPropertyStock.actionGrantedForForm46}
        open={openWishlistGratedAgentAddDialog}
        onClose={handleCloseWishlistGratedAgentAddDialog}
        description={langPropertyStock.msgGrantFullAddress}
      />
    </div>

    <Divider />
    {confirmRemoveAgentDialog.render()}
    <CardContent>
      <Grid container direction="row">
        <Grid item className={classes.leadAgentSection} hideWhen={!form.values.leadAgents?.length}>
          <Grid item className={classes.sectionHeader}>
            <Typography variant="caption" className={classes.sectionHeaderTextLeadAgent}>{langPropertyStock.captionLeadAgent}</Typography>
          </Grid>

          <Grid item container>
            {form.values.leadAgents?.map((agent: PropertyStockDTO['leadAgents'][0]) => {
              return (
                <Grid
                  item
                  key={`${agent.userId}-name-card`}
                  style={{ padding: '0px 5px' }}
                >
                  <LeadAgentNameCard
                    propertyStockId={+(form.values.id ?? '0')}
                    clientId={agent.clientId}
                    altBorderColor={COLORS.textLeadAgent}
                    agentPhotoFilename={agent.photoFilename}
                    agentNameZh={agent.chineseName}
                    agentNameEn={agent.englishName}
                    agentContactType={agent.contacts[0]?.type ?? ''}
                    agentContactValue={agent.contacts[0]?.value ?? ''}

                    showClientContact={Boolean(agent.clientEnglishName) || Boolean(agent.clientChineseName)}
                    clientNameEn={agent.clientEnglishName}
                    clientNameZh={agent.clientChineseName}
                    clientContactValue={agent.mainContact}
                    clientContactRemarks={agent.mainContactRemarks}
                    clientContactType={agent.mainContactType}

                    showDeleteButton={(agent.userId?.toString() === uid && (form.values.leadAgents?.length ?? 0) > 1) || canUpdateUnowned}
                    handleRemoveLeadAgent={() => {
                      const msg = numOfLeadAgent > 1 ? langPropertyStock.msgConfirmRemoveAgent : langPropertyStock.msgConfirmRemoveLastAgent;
                      confirmRemoveAgentDialog.confirm(msg, lang.actionConfirm, lang.actionCancel).then((confirmed) => {
                        if (confirmed) {
                          handleRemoveLeadAgent(agent.userId?.toString())();
                        }
                      })
                    }}
                  />
                </Grid>
              )
            })}
          </Grid>

        </Grid>

        <Grid item className={classes.buyerSection} hideWhen={!props.wishlistGrantedAgents?.length}>
          <Grid item className={classes.sectionHeader}>
            <Typography variant="caption" className={classes.sectionHeaderTextBuyerAgent}>{langPropertyStock.captionGrantedForForm46}</Typography>
          </Grid>

          <Grid item container>
            {props.wishlistGrantedAgents?.map((agent) => {
              return (
                <Grid
                  item
                  key={`${agent.grantedAgentUserId}-name-card`}
                  style={{ padding: '0px 5px' }}
                >
                  <LeadAgentNameCard
                    propertyStockId={0}
                    clientId={0}
                    altBorderColor={COLORS.textBuyerAgent}
                    agentPhotoFilename={agent.photoFilename ?? ''}
                    agentNameZh={agent.chineseName ?? ''}
                    agentNameEn={agent.englishName ?? ''}
                    agentContactType={agent.contacts?.[0]?.type ?? ''}
                    agentContactValue={agent.contacts?.[0]?.value ?? ''}

                    showDeleteButton={true}
                    handleRemoveLeadAgent={() => {
                      if (agent.grantedAgentUserId) {
                        props.onWishlistGrantedAgentRemoved(agent.grantedAgentUserId);
                      } else {
                        console.warn('agent.grantedAgentUserId not present');
                      }
                    }}
                  />
                </Grid>
              )
            })}
          </Grid>

        </Grid>
      </Grid>

    </CardContent>
  </Card>
}

export default LeadAgentPanel;