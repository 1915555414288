import { makeStyles } from '@material-ui/core';
import { useForm } from 'common/hooks';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgentRatingCommentSearchCritiera, IRootState, PASDispatch } from 'reducers';
import AgentRatingCommentSearch from './components/AgentRatingCommentSearch';
import AgentRatingCommentTable from './components/AgentRatingCommentTable';
import AgentRatingCommentToolbar from './components/AgentRatingCommentToolbar';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  search: {
    marginBottom: theme.spacing(2)
  }
}));

const AgentRatingCommentList = () => {
	
	const classes = useStyles(0);

	const dispatch = useDispatch() as PASDispatch;
	const agentRatingComments = useSelector((state: IRootState) => state.agentRatingCommentList.currentList);
  const { totalPages, totalElements } = useSelector((state: IRootState) => state.agentRatingCommentList);
	
	const [ page, setPage ] = useState(0);
  const [ limit ] = useState(10);
	const [ sortOrders, setSortOrders ] = useState<{ [k: string]: 'desc' | 'asc' }>({
    dateModified: 'desc',
  });
  
  // const [ searhForm.values, setSearchFormValues ] = useState<Partial<AgentRatingCommentSearchCritiera>>({reviewStatus: ['PENDING']});
	
	const searchForm = useForm<AgentRatingCommentSearchCritiera>({reviewStatus: ['PENDING']});

  const handleSearch = (values: Partial<AgentRatingCommentSearchCritiera>) => {
		setPage(0);
		dispatch({ type: 'AgentRatingCommentList.FetchRequested', payload: {
			page, size: limit, sort: sortOrders, ...searchForm.values
    } });
	};
	
	
	const handleSort = (key: string | null) => {
    if (key === null) {
      setSortOrders({});
     
      return;
    }
    const newSortOrders = { [key]: sortOrders[key] === 'asc' ? 'desc' : 'asc' };
    setSortOrders(newSortOrders as any);
    
	}
	
	useEffect(() => {
    dispatch({ type: 'AgentRatingCommentList.FetchRequested', payload: {
			page, size: limit, sort: sortOrders, ...searchForm.values
    } });
    
	}, [ page, limit, sortOrders ]);

	
	return (
		<div className={classes.root}>
			<AgentRatingCommentToolbar/>
			<div className={classes.content}>				
      <AgentRatingCommentSearch onsearch={handleSearch} className={classes.search} searchform={searchForm}/>
				<AgentRatingCommentTable
					page={page}
					limit={limit}
					totalPages={totalPages}
					totalElements={totalElements}
					setPage={(page) => {
						setPage(page);
						// dispatch({ 
						// 	type: 'UserList.FetchRequested', 
						// 	payload: { page, size: limit }
            // });
					}}
					agentRatingComments={agentRatingComments}
					sortOrders={sortOrders}
					handleSort={handleSort}
				/>
			</div>
		</div>
	);
};

export default AgentRatingCommentList;