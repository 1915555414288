import { Grid, makeStyles, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import forgotPasswordApi from 'api/forgotPasswordApi';
import { useForm } from 'common/hooks';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState, PASDispatch } from 'reducers';

const useStyles = makeStyles(() => ({
  root: {

  },
  patchOutlineAutocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
      paddingLeft: 8,
    }
  },
  dialogContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60%',
  },
  textArea: {
    width: '90%'
  }
}));

interface ForgotPasswordDialogProps {
  // stockForm: FormPresenter<PropertyStockDTO>;

  // open: boolean;
  // handleClickOpen:  () => void;
  // handleClose:  () => void;

  // //for warning dialog in create page
  // isCreatePage?: boolean;
  // createPropertyStock: () => void; //create property stock
  // hasAssociatedClients? : boolean;
  // hideOpenButton?: boolean;
}


const forgotPasswordLang : {[key: string] : string} ={
  pleaseInput: '請輸入 Please input',
  msgInvalidUsernameOrEmail: '用戶名稱或聯繫電郵錯誤 Invalid username or contact email',
  msgEmailSent: '重設密碼的連結已發送到你的電子郵箱 \n Reset password link has been sent to your email address',
  msgPleaseWait: '請等候... Please wait...',
  buttonName: '忘記密碼 Forgot Password?',
  title: '忘記密碼 Forgot Password',
  captionUsername: '用戶名 Username',
  captionContactEmail: '聯繫電郵 Contact Email',
  actionConfirm: '確認 Confirm',
  actionCancel: '取消 Cancel',
  actionClose: '關閉 Close'

};

const ForgotPasswordDialog = ({ }: ForgotPasswordDialogProps) => {
  const dispatch = useDispatch() as PASDispatch;
  const { lang } = useSelector((state: IRootState) => state.locale);
  const [open, setOpen] = React.useState(false);

  const reset = () =>{
    setInvalidUsernameOrEmail(false);
    setEmailSent(false);
    form.setValues({});
    form.stopValidation();
  }

  const handleClickOpen = () => {
    setOpen(true);
    reset();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const form = useForm<any>({}, {
    validations: [
      (values) => values.username ? null : [[ 'username', forgotPasswordLang.pleaseInput ]]  ,
      (values) => values.contactEmail ? null : [[ 'contactEmail', forgotPasswordLang.pleaseInput ]]  ,
    ]
  });

  const [ invalidUsernameOrEmail, setInvalidUsernameOrEmail ] = useState(false);
  const [ emailSent, setEmailSent ] = useState(false);
  const [ isProcessing, setIsProcessing ] = useState(false);

  const doRequest = () =>{
    setInvalidUsernameOrEmail(false);
    if (!form.validate()){
      return;
    }

    // dispatch({ type: 'Layout.MaskPresentRequested' });
    setIsProcessing(true);
    forgotPasswordApi.request(form.values.username, form.values.contactEmail).then(async result => {
      if (result.data) {
        // dispatch({ type: 'Layout.MaskDismissRequested' });
        setInvalidUsernameOrEmail(result.data.invalidUsernameOrEmail);
        setEmailSent(result.data.emailSent);
        setIsProcessing(false);
        if (result.data.invalidUsernameOrEmail){
          dispatch({ type: 'Layout.AlertMessageAdded', payload: { message: forgotPasswordLang.msgInvalidUsernameOrEmail, severity: 'error' } });
        }
      } else {
        dispatch({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgNetworkError, severity: 'error' } });
      }
      // dispatch({ type: 'Layout.MaskDismissRequested' });
      setIsProcessing(false);
    });
  }

  const processingDialog = () => (
    <Grid item container spacing={1} md={12}>
      <Grid item md={12} xs={12}>
        <Typography style={{whiteSpace: 'pre-line'}}>{forgotPasswordLang.msgPleaseWait}</Typography>
      </Grid>
    </Grid>
  );

  const emailSentDialog = () => (
        <Grid item container spacing={1} md={12}>
          <Grid item md={12} xs={12}>
            <Typography style={{whiteSpace: 'pre-line'}}>{forgotPasswordLang.msgEmailSent}</Typography>
          </Grid>
        </Grid>
  );

  const requestDialog = () => (
        <Grid item container spacing={1} md={12}>
          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              label={forgotPasswordLang.captionUsername}
              margin="dense"
              {...form.bind('username')}
              variant="outlined"
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              label={forgotPasswordLang.captionContactEmail}
              margin="dense"
              {...form.bind('contactEmail')}
              variant="outlined"
            />
          </Grid>
          {/* {invalidUsernameOrEmail ?
              <Grid item md={12} xs={12}>
                <Typography style={{color: '#e53935', fontSize: '10pt'}}>{'用戶名稱或聯繫電郵錯誤 Invalid username or contact email'}</Typography>
              </Grid>
              : null} */}
        </Grid>


  );

  return (
    <div>
      <Button variant="text" color="primary" onClick={handleClickOpen}>
        {forgotPasswordLang.buttonName}
      </Button>
      <Dialog maxWidth="sm" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" style={{marginBottom: 3}}>{forgotPasswordLang.title}</DialogTitle>
        <DialogContent>
          {isProcessing ? processingDialog() : null}
          {isProcessing ? null : (emailSent ? emailSentDialog() : requestDialog())}

        </DialogContent>
        {isProcessing ? null :
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {emailSent ? forgotPasswordLang.actionClose : forgotPasswordLang.actionCancel}
            </Button>
            {emailSent ? null :
              <Button onClick={doRequest} color="primary" variant="contained" autoFocus>
                {forgotPasswordLang.actionConfirm}
              </Button>
            }

          </DialogActions>
        }
      </Dialog>
    </div>
  );
}

export default ForgotPasswordDialog;
