import { ApiError, ApiPageResult, ApiResult } from 'api';
import agentRatingCommentApi from 'api/agentRatingCommentApi';
import { CAAgentRatingCommentDTO } from 'common/dto';
import { AgentRatingCommentActions, IRootState } from 'reducers';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PASSagaContext } from 'sagas';
import { apiTaskWrapper } from './saga-commons';


////////////////////////////////////////////////////////////////////////
////////////////////////// List Requested///////////////////////////////
////////////////////////////////////////////////////////////////////////

export function* watchAgentRatingCommentListFetchRequested(context: PASSagaContext) {
  yield takeLatest(
    'AgentRatingCommentList.FetchRequested',
    apiTaskWrapper(requestAgentRatingCommentList),
    context,
  );
}

export function* requestAgentRatingCommentList(context: PASSagaContext, action: Extract<AgentRatingCommentActions, { type: 'AgentRatingCommentList.FetchRequested' }>) {
  if (action.type !== 'AgentRatingCommentList.FetchRequested') {
    return;
  }

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiPageResult<CAAgentRatingCommentDTO> = yield call(agentRatingCommentApi.getList, action.payload, token);
 
  if (!result.error) {
    yield put<AgentRatingCommentActions>({ type: 'AgentRatingCommentList.Loaded', payload: result.data! });
  } else {
    throw ApiError.of(result.error!);
  }

}


////////////////////////////////////////////////////////////////////////
////////////////////// Approve requested ///////////////////////////////
////////////////////////////////////////////////////////////////////////

export function* watchAgentRatingCommentApproveRequested() {
	yield takeLatest(
		'AgentRatingComment.ApproveActionRequested',
		apiTaskWrapper(requestApproveAgentRatingComment),
	);
}

export function* requestApproveAgentRatingComment(action: AgentRatingCommentActions) {
	if (action.type !== 'AgentRatingComment.ApproveActionRequested') {
		return;
	}

	const token: string = yield select((state: IRootState) => state.login.token ?? '');

	const result: ApiResult<any> = yield call(agentRatingCommentApi.approveAction, action.payload, token);
	
	const { lastSearchCriteria }: IRootState['agentRatingCommentList'] = yield select((state: IRootState) => state.agentRatingCommentList);

	if (!result.error) {
		yield put<AgentRatingCommentActions>({ type: 'AgentRatingCommentList.FetchRequested', payload: lastSearchCriteria ?? { page: 0, sort: {} } });
	} else {
		throw ApiError.of(result.error);
	}
}


////////////////////////////////////////////////////////////////////////
////////////////////// Reject requested ////////////////////////////////
////////////////////////////////////////////////////////////////////////

export function* watchAgentRatingCommentRejectRequested() {
	yield takeLatest(
		'AgentRatingComment.RejectActionRequested',
		apiTaskWrapper(requestRejectAgentRatingComment),
	);
}

export function* requestRejectAgentRatingComment(action: AgentRatingCommentActions) {
	if (action.type !== 'AgentRatingComment.RejectActionRequested') {
		return;
	}
 
	const token: string = yield select((state: IRootState) => state.login.token ?? '');

	const result: ApiResult<any> = yield call(agentRatingCommentApi.rejectAction, action.payload, token);
	
	const { lastSearchCriteria }: IRootState['agentRatingCommentList'] = yield select((state: IRootState) => state.agentRatingCommentList);

	if (!result.error) {
		yield put<AgentRatingCommentActions>({ type: 'AgentRatingCommentList.FetchRequested', payload: lastSearchCriteria ?? { page: 0, sort: {} } });
	} else {
		throw ApiError.of(result.error);
	}
}


