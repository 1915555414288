import { ApiError, ApiPageResult, ApiResult } from "api";
import userApi from "api/userApi";
import { hasPermission } from "common/access-control";
import { UserDTO } from "common/dto";
import { GlobalActions, IRootState, LayoutActions, LoginActions, NotificationActions, UserActions } from "reducers";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { PASSagaContext } from 'sagas';
import { apiTaskWrapper, itemActionMessage } from "./saga-commons";


type UserListItemDTO = any;

export function* watchUserListFetchRequested() {
  yield takeLatest(
    'UserList.FetchRequested',
    apiTaskWrapper(requestUserList)
  );
}

export function* requestUserList(action: Extract<UserActions, { type: 'UserList.FetchRequested' }>) {

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiPageResult<UserListItemDTO> = yield call(userApi.getList, action.payload, token);

  if (!result.error) {
    yield put<UserActions>({ type: 'UserList.Loaded', payload: result.data! });
  } else {
    throw ApiError.of(result.error!);
  }

}

export function* watchUserDetailFetchRequested() {
  yield takeEvery<UserActions['type']>(
    'User.FetchRequested',
    apiTaskWrapper(requestUserDetail)
  )
}

export function* requestUserDetail(action: UserActions) {
  if (action.type != 'User.FetchRequested') {
    return;
  }

  const { id } = action.payload;


  const token: string = yield select((state: IRootState) => state.login.token ?? '');
  // const uid: string = yield select((state: IRootState) => state.login.uid ?? '');

  const result: ApiResult<UserDTO> = yield call(userApi.getDetail, id, token);

  if (!result.error) {
    yield put<UserActions>({
      type: 'User.Loaded',
      payload: {
        ...result.data!,
        rowsCountPreference: JSON.parse(result.data?.rowsCountPreference),
        propertyStockColumnPreference: JSON.parse(result.data?.propertyStockColumnPreference),
        clientColumnPreference: JSON.parse(result.data?.clientColumnPreference),
        transactionColumnPreference: JSON.parse(result.data?.transactionColumnPreference),
      }
    });
  } else {
    yield put<UserActions>({ type: 'User.Failed', payload: result.error! });
    throw ApiError.of(result.error!, null, '/users');
  }

}

export function* watchUserCreationRequested(context: PASSagaContext) {
  yield takeEvery(
    'User.CreationRequested',
    apiTaskWrapper(createUser),
    context,
  );
}

export function* createUser(context: PASSagaContext, action: Extract<UserActions, { type: 'User.CreationRequested' }>) {
  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<UserDTO> = yield call(userApi.add, action.payload, token);

  const { langUser } = yield select((state: IRootState) => state.locale);

  if (!result.error) {
    // const newId = result.data?.id;
    // yield call(context.browserHistory.replace as any, `/users/${newId}`);
    yield* itemActionMessage('create', 'User');
    yield call(context.browserHistory.replace as any, `/users`);
  } else if (result.error === 'ERR_DUPLICATED_USERNAME') {
    yield put<LayoutActions>({ type: 'Layout.AlertMessageAdded', payload: { message: langUser.msgDuplicatedUsername, severity: 'error' } });
  } else {
    throw ApiError.of(result.error!);
  }
}

export function* watchUserUpdateRequested(context: PASSagaContext) {
  yield takeEvery(
    'User.UpdateRequested',
    apiTaskWrapper(updateUser),
    context,
  );
}

export function* updateUser(context: PASSagaContext, action: Extract<UserActions, { type: 'User.UpdateRequested' }>) {

  // const token: string = yield select((state: IRootState) => state.login.token ?? '');
  const { token, privileges } = yield select((state: IRootState) => state.login);
  const canUpdateUserInfo = hasPermission(privileges, 'UPDATE', 'USER');
  const result: ApiResult<UserDTO> = yield call(userApi.update, action.payload, token);

  const { isPref } = context.browserHistory.location.state ?? {};

  const { langUser } = yield select((state: IRootState) => state.locale);

  if (!result.error) {
    // yield put<UserActions>({ type: 'UserList.FetchRequested', payload: { page: '0', limit: '10'}}});
    yield* itemActionMessage('update', 'User');
    if (canUpdateUserInfo) {
      if (!isPref) {
        yield call(context.browserHistory.replace as any, `/users`, { bypassUnsavedCheck: true });
      }
      //Brute force
      yield call(context.browserHistory.replace as any, `/users`, { bypassUnsavedCheck: undefined });
      yield put({ type: 'Layout.DataReloadRequested' });
      yield put<UserActions>({
        type: 'User.Loaded',
        payload: {
          ...result.data!,
          rowsCountPreference: JSON.parse(result.data?.rowsCountPreference),
          propertyStockColumnPreference: JSON.parse(result.data?.propertyStockColumnPreference),
          clientColumnPreference: JSON.parse(result.data?.clientColumnPreference),
          transactionColumnPreference: JSON.parse(result.data?.transactionColumnPreference),
        }
      });
    } else {
      yield put({ type: 'Layout.DataReloadRequested' });
      yield put<UserActions>({
        type: 'User.Loaded',
        payload: {
          ...result.data!,
          rowsCountPreference: JSON.parse(result.data?.rowsCountPreference),
          propertyStockColumnPreference: JSON.parse(result.data?.propertyStockColumnPreference),
          clientColumnPreference: JSON.parse(result.data?.clientColumnPreference),
          transactionColumnPreference: JSON.parse(result.data?.transactionColumnPreference),
        }
      });
    }
  } else if (result.error === 'ERR_DUPLICATED_USERNAME') {
    yield put<LayoutActions>({ type: 'Layout.AlertMessageAdded', payload: { message: langUser.msgDuplicatedUsername, severity: 'error' } });
  } else {
    throw ApiError.of(result.error!);
  }

}

export function* watchResetPasswordRequested(context: PASSagaContext) {
  yield takeEvery(
    'User.ResetPasswordRequested',
    apiTaskWrapper(resetPassword),
    context,
  );
}

export function* resetPassword(context: PASSagaContext, action: Extract<UserActions, { type: 'User.ResetPasswordRequested' }>) {

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<UserDTO> = yield call(userApi.resetPassword, action.payload.id, token);

  if (!result.error) {
    // yield put<UserActions>({ type: 'UserList.FetchRequested', payload: { page: '0', limit: '10'}}});
    // yield put<UserActions>({ 
    //   type: 'User.Loaded', 
    //   payload: { 
    //     ...result.data!, 
    //     rowsCountPreference: JSON.parse(result.data?.rowsCountPreference),
    //     propertyStockColumnPreference: JSON.parse(result.data?.propertyStockColumnPreference),
    //     clientColumnPreference: JSON.parse(result.data?.clientColumnPreference),
    //     transactionColumnPreference: JSON.parse(result.data?.transactionColumnPreference),
    //   }
    // });
  } else {
    throw ApiError.of(result.error!);
  }

}


export function* watchUnlockRequested(context: PASSagaContext) {
  yield takeEvery(
    'User.UnlockRequested',
    apiTaskWrapper(unlockUser),
    context,
  );
}

export function* unlockUser(context: PASSagaContext, action: Extract<UserActions, { type: 'User.UnlockRequested' }>) {

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const lastSearchCriteria = yield select((state: IRootState) => state.user.lastSearchCriteria);

  const result: ApiResult<UserDTO> = yield call(userApi.unlock, action.payload.id, token);

  if (!result.error) {
    yield put<UserActions>({ type: 'UserList.FetchRequested', payload: lastSearchCriteria ?? { page: '0' } });
  } else {
    throw ApiError.of(result.error!);
  }

}

/////////////////////////////////////////////////////////////////////
///////////////////////// start impersonation ///////////////////////
/////////////////////////////////////////////////////////////////////


export function* watchStartImpersonationRequested(context: PASSagaContext) {
  yield takeEvery(
    'User.StartImpersonationRequested',
    apiTaskWrapper(startImpersonation),
    context,
  );
}

export function* startImpersonation(context: PASSagaContext, action: Extract<UserActions, { type: 'User.StartImpersonationRequested' }>) {

  const token: string = yield select((state: IRootState) => state.login.token ?? '');
  const { langUser } = yield select((state: IRootState) => state.locale);


  const result: ApiResult<any> = yield call(userApi.startImpersonation, action.payload.password, action.payload.notificationId, token);

  if (!result.error) {
    //save current token
    localStorage.setItem('impersonateUserToken', token);

    //login as beingImpersonatedUser
    yield put<LoginActions>({ type: 'Login.Success', payload: { token: result.data.token!, username: result.data.username!, uid: result.data.uid!, rowsCountPreference: {} } })
    yield put<GlobalActions>({ type: 'Global.ClearCacheRequested', payload: null }); // clear all data
    yield put<LayoutActions>({ type: 'Layout.Initialize' }); // re-initialize app
    yield call(context.browserHistory.push as any, `/dashboard`);

  } else {
    if (result.error === 'ERR_PASSWORD_MISMATCHED') {
      yield put<LayoutActions>({ type: 'Layout.AlertMessageAdded', payload: { message: langUser.msgIncorrectPassword, severity: 'error' } })
    }
    else if (result.error === 'ERR_IMPERSONATION_SESSION_EXPIRED') {
      yield put<LayoutActions>({ type: 'Layout.AlertMessageAdded', payload: { message: langUser.msgImpersonationSessionExpired, severity: 'error' } })
    }
    else throw ApiError.of(result.error!);
  }

}

/////////////////////////////////////////////////////////////////////
///////////////// Approver stop impersonation ///////////////////////
/////////////////////////////////////////////////////////////////////
export function* watchApproverStopImpersonationRequested(context: PASSagaContext) {
  yield takeEvery(
    'User.ApproverStopImpersonationRequested',
    apiTaskWrapper(approverStopImpersonation),
    context,
  );
}

export function* approverStopImpersonation(context: PASSagaContext, action: Extract<UserActions, { type: 'User.ApproverStopImpersonationRequested' }>) {

  const token: string = yield select((state: IRootState) => state.login.token ?? '');
  // const { langUser} = yield select((state: IRootState) => state.locale);
  const { lastSearchCriteria } = yield select((state: IRootState) => state.notification);
  const { beingImpersonatedUserId } = action.payload;
  const result: ApiResult<any> = yield call(userApi.approverStopImpersonation, action.payload.impersonatedUserId, beingImpersonatedUserId, token);

  if (!result.error) {
    yield put<NotificationActions>({ type: 'Notifications.FetchRequested' });
    yield put<NotificationActions>({ type: 'NotificationPage.FetchRequested', payload: lastSearchCriteria });
  } else {
    throw ApiError.of(result.error!);
  }
  localStorage.removeItem("impersonateUserToken");
  window.location.reload();

}


/////////////////////////////////////////////////////////////////////
////////////// Impersonate user stop impersonation //////////////////
/////////////////////////////////////////////////////////////////////
export function* watchImpersonateUserStopImpersonationRequested(context: PASSagaContext) {
  yield takeEvery(
    'User.ImpersonateUserStopImpersonationRequested',
    apiTaskWrapper(impersonateUserStopImpersonation),
    context,
  );
}

export function* impersonateUserStopImpersonation(context: PASSagaContext, action: Extract<UserActions, { type: 'User.ImpersonateUserStopImpersonationRequested' }>) {

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const { impersonationId, impersonateUsername } = action.payload;
  const result: ApiResult<any> = yield call(userApi.logoutImpersonation, impersonationId, token);

  if (!result.error) {
    const impersonateUserToken = localStorage.getItem('impersonateUserToken');
    yield put<LoginActions>({ type: 'Login.Success', payload: { token: impersonateUserToken!, username: impersonateUsername, uid: '', rowsCountPreference: {} } })
    yield put<GlobalActions>({ type: 'Global.ClearCacheRequested', payload: null }); // clear all data
    yield put<LayoutActions>({ type: 'Layout.Initialize' }); // re-initialize app
    yield call(context.browserHistory.push as any, `/dashboard`);
    localStorage.removeItem('impersonateUserToken');
  } else {
    throw ApiError.of(result.error!);
  }

}


/////////////////////////////////////////////////////////////////////
///////////////////////// request impersonation ///////////////////////
/////////////////////////////////////////////////////////////////////
export function* watchImpersonationRequested(context: PASSagaContext) {
  yield takeEvery(
    'User.ImpersonationRequested',
    apiTaskWrapper(requestImpersonation),
    context,
  );
}

export function* requestImpersonation(context: PASSagaContext, action: Extract<UserActions, { type: 'User.ImpersonationRequested' }>) {

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const { lastSearchCriteria } = yield select((state: IRootState) => state.notification);
  const result: ApiResult<any> = yield call(userApi.requestImpersonation, action.payload.beingImpersonatedUserId, token);
  const { langUser } = yield select((state: IRootState) => state.locale);

  if (!result.error) {

    yield put<LayoutActions>({ type: 'Layout.AlertMessageAdded', payload: { message: langUser.msgRequestSentSuccessfully, severity: 'error' } })
    yield put<NotificationActions>({ type: 'Notifications.FetchRequested' });
    yield put<NotificationActions>({ type: 'NotificationPage.FetchRequested', payload: lastSearchCriteria });

  } else {
    if (result.error === "ERR_ACTIVE_IMPERSONATION_SESSION_EXISTS") {
      yield put<LayoutActions>({ type: 'Layout.AlertMessageAdded', payload: { message: langUser.msgActiveImpersonationSessionExists, severity: 'error' } })
    } else throw ApiError.of(result.error!);
  }

}

/////////////////////////////////////////////////////////////////////
///////////////////////// approve impersonation /////////////////////
/////////////////////////////////////////////////////////////////////

export function* watchApproveImpersonationRequested() {
  yield takeLatest(
    'User.ApproveImpersonationRequested',
    apiTaskWrapper(requestApproveImpersonation),
  );
}

export function* requestApproveImpersonation(action: UserActions) {
  if (action.type !== 'User.ApproveImpersonationRequested') {
    return;
  }

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const { deviceId } = yield select((state: IRootState) => state.login);
  const result: ApiResult<any> = yield call(userApi.approveImpersonation, action.payload, deviceId, token);

  const { lastSearchCriteria }: IRootState['notification'] = yield select((state: IRootState) => state.notification);

  if (!result.error) {
    yield put<NotificationActions>({ type: 'NotificationPage.FetchRequested', payload: lastSearchCriteria ?? { page: 0, sort: {} } });
  } else {
    throw ApiError.of(result.error);
  }
}
