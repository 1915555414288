import { Capacitor } from '@capacitor/core';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, Grid as MuiGrid, makeStyles, TextField, Typography } from '@material-ui/core';
import { useForm } from 'common/hooks';
import { hideWhenHoc } from 'common/ui';
import { isNonEmpty } from 'common/utils';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { IRootState } from 'reducers';


// #region Base UI

const Grid = hideWhenHoc(MuiGrid);

const useStyles = makeStyles(() => ({
  root: {},
  patchOutlineAutocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
      paddingLeft: 8,
    }
  },
  changePasswordDialogBody: {
    padding: 8,
  }
}));

// #endregion Base UI

interface ChangePasswordDialogProps {
  open: boolean;
  onClose: () => any;
  onChangePassword: (data: { oldPassword: string, newPassword: string }) => any;

  isForgotPasswordPage?: boolean;
  isForceChangePasswordPage?: boolean;
  incorrectPassword: boolean;
}

const ChangePasswordDialog = ({ open, onClose, onChangePassword, isForgotPasswordPage, isForceChangePasswordPage, incorrectPassword }: ChangePasswordDialogProps) => {
  const classes = useStyles();
  const { langUser, lang } = useSelector((state: IRootState) => state.locale);

  const { langUser: langUserEn, lang: langEn } = useSelector((state: IRootState) => state.locale._bundle['en']);

  const history = useHistory();

  const form = useForm({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  }, {
    validations: [
      (values) => isForgotPasswordPage || values.oldPassword ? null : [
        ['oldPassword', langUser.msgPleaseInput + (isForgotPasswordPage ? ' ' + langUserEn.msgPleaseInput : '')],
      ],
      (values) => values.newPassword ? null : [
        ['newPassword', langUser.msgPleaseInput + (isForgotPasswordPage ? ' ' + langUserEn.msgPleaseInput : '')],
      ],
      (values) => !isNonEmpty(values.confirmNewPassword) ?

        [['confirmNewPassword', langUser.msgPleaseInput + (isForgotPasswordPage ? ' ' + langUserEn.msgPleaseInput : '')]]
        :
        (values.newPassword === values.confirmNewPassword ? null :
          [['confirmNewPassword', langUser.msgNewPasswordMismatch + (isForgotPasswordPage ? ' ' + langUserEn.msgNewPasswordMismatch : '')]]
        )
    ],
  });

  useEffect(() => {
    if (incorrectPassword === true) {
      form.updateValues('oldPassword', '');
      form.updateValues('newPassword', '');
      form.updateValues('confirmNewPassword', '');
    }

  }, [incorrectPassword]);


  const isPositiveNumber = (numberStr: string | undefined) => {
    if (!isNonEmpty(numberStr)) {
      return false;
    }
    return (Number(numberStr)) > 0;
  }

  //print password policy
  const { MIN_LENGTH, MIN_LENGTH_UPPER, MIN_LENGTH_LOWER, MIN_LENGTH_NUM, MIN_LENGTH_SPECIAL } = useSelector((state: IRootState) =>
    state.systemSettings?.System?.PASSWORD) ?? {};
  const printPasswordComplexityRequirement = (langUser: any) => {
    let resultStr = "";
    let containAtLeast = "";
    //min length
    if (isPositiveNumber(MIN_LENGTH)) {
      resultStr = langUser.pwMinLength + MIN_LENGTH;
    }
    if (isPositiveNumber(MIN_LENGTH_UPPER) || isPositiveNumber(MIN_LENGTH_LOWER) || isPositiveNumber(MIN_LENGTH_NUM) || isPositiveNumber(MIN_LENGTH_SPECIAL)) {
      containAtLeast = langUser.pwAtLeast;
    }
    let charRequirment = [];
    if (isPositiveNumber(MIN_LENGTH_UPPER)) {
      charRequirment.push(MIN_LENGTH_UPPER + langUser.pwUnit + langUser.pwUppercaseChar);
    }
    if (isPositiveNumber(MIN_LENGTH_LOWER)) {
      charRequirment.push(MIN_LENGTH_LOWER + langUser.pwUnit + langUser.pwLowercaseChar);
    }
    if (isPositiveNumber(MIN_LENGTH_NUM)) {
      charRequirment.push(MIN_LENGTH_NUM + langUser.pwUnit + langUser.pwNumber);
    }
    if (isPositiveNumber(MIN_LENGTH_SPECIAL)) {
      charRequirment.push(MIN_LENGTH_SPECIAL + langUser.pwUnit + langUser.pwSpecialChar);
    }
    if (isNonEmpty(charRequirment)) {
      containAtLeast = containAtLeast + charRequirment.join(', ');
    }
    if (isNonEmpty(resultStr) && isNonEmpty(containAtLeast)) {
      return resultStr + ", " + containAtLeast;
    } else if (isNonEmpty(resultStr)) {
      return resultStr;
    } else if (isNonEmpty(containAtLeast)) {
      return containAtLeast;
    }
  }

  const printDialogContent = () => (
    <Grid className={classes.changePasswordDialogBody} container md={12} xs={12} spacing={1}>
      {Capacitor.getPlatform() === "android" ? <Typography
        color={"secondary"}
        variant="subtitle1"
      >
        {langUser.msgThirdPartyKeyboard}
      </Typography> : null}
      {isForgotPasswordPage ? null :
        <Grid item md={12} xs={12}>
          <TextField type="password"
            fullWidth margin="dense" required variant="outlined"
            {...form.bind('oldPassword')}
            label={langUser.oldPassword + (isForgotPasswordPage ? ' ' + langUserEn.oldPassword : '')}
            helperText={form.hasError('oldPassword')}
          />
        </Grid>
      }
      <Grid item md={12} xs={12}>
        <TextField type="password"
          fullWidth margin="dense" required variant="outlined"
          {...form.bind('newPassword')}
          label={langUser.newPassword + (isForgotPasswordPage ? ' ' + langUserEn.newPassword : '')}
          helperText={form.hasError('newPassword')}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <TextField type="password"
          fullWidth margin="dense" required variant="outlined"
          {...form.bind('confirmNewPassword')}
          label={langUser.confirmNewPassword + (isForgotPasswordPage ? ' ' + langUserEn.confirmNewPassword : '')}
          helperText={form.hasError('confirmNewPassword')}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <FormHelperText style={{ color: 'inherit' }}>{printPasswordComplexityRequirement(langUser)}</FormHelperText>
        {isForgotPasswordPage ?
          <FormHelperText style={{ color: 'inherit' }}>{printPasswordComplexityRequirement(langUserEn)}</FormHelperText>
          : null}
      </Grid>
      {/* -------------- confirm button ---------------- */}
      {isForgotPasswordPage || isForceChangePasswordPage ?
        <Grid item md={isForgotPasswordPage ? 12 : 2} xs={isForgotPasswordPage ? 12 : 6}>
          {printConfirmButton()}
        </Grid>
        : null}
      {isForceChangePasswordPage && <Grid item md={2} xs={6}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            history.push('/');
          }}
        >{lang.actionSkip}</Button>
      </Grid>
      }

    </Grid>
  );


  const printConfirmButton = () => (
    <Button
      // variant={isForgotPasswordPage || isForceChangePasswordPage ? "outlined" : "contained"} 
      variant={isForgotPasswordPage ? "outlined" : "contained"}
      autoFocus={isForgotPasswordPage || isForceChangePasswordPage ? false : true}
      color="primary"
      onClick={() => {

        if (form.validate()) {
          onChangePassword(form.values as any);
        } else {
          form.updateValues('oldPassword', '');
          form.updateValues('newPassword', '');
          form.updateValues('confirmNewPassword', '');

        }
      }}>
      {lang.actionConfirm + (isForgotPasswordPage ? ' ' + langEn.actionConfirm : '')}
    </Button>
  );

  //------------- for change password in the user profile page
  const printwholeDialog = () => (
    <Dialog onClose={onClose} aria-labelledby="change-password-dialog" open={open}>
      <DialogTitle id="change-password-dialog">{langUser.titleChangePassword}</DialogTitle>
      <DialogContent>
        {printDialogContent()}
        {/* <Grid className={classes.changePasswordDialogBody} container md={12} xs={12} spacing={1}>
        {isForgotPasswordPage ? null :
          <Grid item md={12} xs={12}>
            <TextField type="password"
              fullWidth margin="dense" required variant="outlined"
              {...form.bind('oldPassword')}
              label={langUser.oldPassword} />
          </Grid>
         }
        <Grid item md={12} xs={12}>
          <TextField type="password"
            fullWidth margin="dense" required variant="outlined"
            {...form.bind('newPassword')}
            label={langUser.newPassword} />
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField type="password"
            fullWidth margin="dense" required variant="outlined"
            {...form.bind('confirmNewPassword')}
            label={langUser.confirmNewPassword} />
        </Grid>
        <Grid item md={12} xs={12}>
          <FormHelperText style={{ color: 'inherit' }}>{printPasswordComplexityRequirement(langUser)}</FormHelperText>
          {isForgotPasswordPage ? 
            <FormHelperText style={{ color: 'inherit' }}>{printPasswordComplexityRequirement(langUserEn)}</FormHelperText>
          : null}
        </Grid>

      </Grid> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {lang.actionCancel + (isForgotPasswordPage ? ' ' + langEn.actionCancel : '')}
        </Button>
        {printConfirmButton()}
        {/* <Button variant="contained" autoFocus color="primary" onClick={() => {
        if (form.validate()) {
          onChangePassword(form.values as any);
        }
      }}>
        {lang.actionConfirm + (isForgotPasswordPage ? langEn.actionConfirm: '')}
      </Button> */}
      </DialogActions>
    </Dialog>
  )

  return isForgotPasswordPage || isForceChangePasswordPage ? printDialogContent() : printwholeDialog();
  // <Dialog onClose={onClose} aria-labelledby="change-password-dialog" open={open}>
  //   <DialogTitle id="change-password-dialog">{langUser.titleChangePassword}</DialogTitle>
  //   <DialogContent>
  //     {printDialogContent()}
  //     {/* <Grid className={classes.changePasswordDialogBody} container md={12} xs={12} spacing={1}>
  //       {isForgotPasswordPage ? null :
  //         <Grid item md={12} xs={12}>
  //           <TextField type="password"
  //             fullWidth margin="dense" required variant="outlined"
  //             {...form.bind('oldPassword')}
  //             label={langUser.oldPassword} />
  //         </Grid>
  //        }
  //       <Grid item md={12} xs={12}>
  //         <TextField type="password"
  //           fullWidth margin="dense" required variant="outlined"
  //           {...form.bind('newPassword')}
  //           label={langUser.newPassword} />
  //       </Grid>
  //       <Grid item md={12} xs={12}>
  //         <TextField type="password"
  //           fullWidth margin="dense" required variant="outlined"
  //           {...form.bind('confirmNewPassword')}
  //           label={langUser.confirmNewPassword} />
  //       </Grid>
  //       <Grid item md={12} xs={12}>
  //         <FormHelperText style={{ color: 'inherit' }}>{printPasswordComplexityRequirement(langUser)}</FormHelperText>
  //         {isForgotPasswordPage ? 
  //           <FormHelperText style={{ color: 'inherit' }}>{printPasswordComplexityRequirement(langUserEn)}</FormHelperText>
  //         : null}
  //       </Grid>

  //     </Grid> */}
  //   </DialogContent>
  //   <DialogActions>
  //     <Button onClick={onClose} color="primary">
  //       {lang.actionCancel + (isForgotPasswordPage ? langEn.actionCancel: '')}
  //     </Button>
  //     {printConfirmButton()}
  //     {/* <Button variant="contained" autoFocus color="primary" onClick={() => {
  //       if (form.validate()) {
  //         onChangePassword(form.values as any);
  //       }
  //     }}>
  //       {lang.actionConfirm + (isForgotPasswordPage ? langEn.actionConfirm: '')}
  //     </Button> */}
  //   </DialogActions>
  // </Dialog>
}

export default ChangePasswordDialog;