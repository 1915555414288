import { LocaleState } from "reducers";

const zhHK: LocaleState = {
  locale: "zh_HK",
  langCompany: {
    address: "新界西貢西貢苑25號鋪",
    name: "邁房地產代理有限公司",
    businessReg: "69336684",
    licence: "C-012345",
  },
  langCreationUpdateMessages: {
    msgCreation: "%s新增成功",
    msgUpdate: "%s更新成功",
    msgSign: "%s簽署成功",
    msgConfirmRequest: "%s請求確認成功",
    msgConfirm: "%s確定成功",
    msgApprovalSubmit: "%s提交核准成功",
    msgApprove: "%s核准成功",
    msgReject: "%s駁回成功",
    itemBuilding: "大廈",
    itemPropertyStock: "樓盤",
    itemClient: "客戶",
    itemUser: "帳戶",
    itemForm1: "表格1",
    itemForm2: "表格2",
    itemForm3: "表格3",
    itemForm4: "表格4",
    itemForm5: "表格5",
    itemForm6: "表格6",
    itemSalePurchaseAgreement: "臨時買賣合約",
    itemTenancyAgreement: "臨時租賃合約",
    itemCloseTrnx: "成交",
    itemTransaction: "成交修正",
  },
  lang: {
    msgUserPwIncorrect: "用戶名或密碼錯誤",
    msgPwMaxFailAttempt: "登入錯誤次數過多，帳戶已鎖定",
    msgPwMaxFailAttemptReset: "帳戶已解除鎖定",
    msgNetworkError: "系統錯誤",
    msgFailedToConnect: "連接伺服器失敗",
    msgMaliciousError: "此檔案含有惡意內容, 請上傳另一份檔案",
    msgLoggedOut: "您已經登出, 請重新登入",
    msgSessionExpired: "使用期限已過, 請重新登入.",
    msgSignInAgain: "重新登入",
    msgPasswordExpired: "密碼已過期, 請修改密碼",
    msgDataNotFound: "找不到相關資料",
    msgSystemBusy: "系統繁忙，請重新嘗試",
    msgPermissionDenied: "您無權訪問相關資料",
    msgVersionMismatched: "另一位操作者修改了當前資料, 請重新加載",
    msgClientVersionMismatched: "客戶資料已被修改, 請重新加載",
    msgDuplicatedBuilding: "大廈檔案已存在",
    msgAlreadyAssignedClient: "客戶已被認領",
    msgAlreadyAssignedPropertyStock: "樓盤已被認領",
    msgFloorUnitValidationError: "請輸入正確的格式，例：G,1-2,3-10,11",
    msgFloorLevelValidationError: "部分樓層沒有被低中高層涵蓋",
    msgLevelOverlapError: "部分樓層被重複定義低中高層",
    msgFloorUnitInUseByProperty:
      "不能刪除現存樓盤所擁有的樓層，單位和低中高層範圍",
    msgFailedToSendEmailError: "發送失敗, 請重新嘗試。",
    msgFailedClientSignError: "簽署失敗, 請重新嘗試。",
    msgUnsupportedFileFormat: "不支援此文件格式: %s",
    msgBuildingAliasAlreadyExists: "大廈副地址已存在",
    msgFileSizeExceed: "此文件大小超過限制",
    msgConfirmDelete: "確認刪除?",
    msgConfirmDone: "確認已處理?",
    msgLoading: "載入中",
    msgWelcome: "歡迎",
    msgMustBeNumber: "須為數字",
    msgPleaseTryAgain: "請重新嘗試",
    msgUnsavedChanges: "未儲存本頁修改, 確定要離開 ?",
    u10k: "萬",
    u1M: "百萬",
    u100M: "億",
    u1B: "十億",
    uFt: "呎",
    to: "至",

    dateModified: "最後更新",
    modifiedBy: "最後更新帳戶",
    dateCreated: "新增日期",
    createdBy: "創建帳戶",
    remarks: "備註",

    memos: "備忘錄",
    content: "內容",
    author: "作者",
    actionAddMemo: "新增備忘",
    msgPleaseInputMemo: "請輸入備忘....",

    captionUploadedAt: "上載於",
    captionUnsaved: "尚未儲存",
    captionName: "姓名",
    captionEnglishName: "英文姓名",
    captionChineseName: "中文姓名",
    captionDeleted: "已刪除",
    captionMore: "更多",
    captionNone: "沒有",
    captionTotalPages: "總%s頁",

    actionUpload: "上載",
    actionConfirmAndUpload: "確認並上載",
    actionDownload: "下載",
    actionDownloadOrPreview: "下載/預覽",
    actionDownloadOrPreviewWithSpace: "下載 / 預覽",
    actionNextStep: "下一步",
    actionConfirm: "確認",
    actionSave: "儲存",
    actionApply: "套用",
    actionApplySave: "套用及儲存",
    actionAdd: "新增",
    actionDelete: "刪除",
    actionSaveForm: "儲存表格",
    actionAddForm: "新增表格",
    actionGenerate: "生成PDF檔案",
    actionGenerateAndSign: "簽署",
    actionUserProfile: "帳戶設定",
    actionLogout: "登出",
    actionYes: "是",
    actionNo: "否",
    actionSearch: "搜尋",
    actionClearSorting: "清除排列",
    actionClose: "關閉",
    actionCancel: "取消",
    actionGoBack: "返回",
    actionSkip: "略過",
    actionContinue: "繼續",
    actionRemoveSelected: "刪除所選",
    actionManage: "管理",
    actionView: "檢視",
    actionEdit: "編輯",
    actionEnd: "結束",

    captionHKI: "香港島",
    captionKLN: "九龍",
    captionNT: "新界",
    captionSearch: "搜尋...",

    // Quick Access Bar Lang
    navDashboard: "儀表板",
    navPropertyStockSearch: "樓盤搜尋",
    navTransaction: "成交紀錄",
    navClient: "客戶檔案",
    navBuilding: "大廈檔案",
    navMemoEnquiry: "備忘查閱",
    navSystemSetting: "系統管理",
    navCommissionReport: "銷售及分佣報告",
    navUserMgt: "帳戶管理",
    navRoleMgt: "角色管理",
    navSystemSettingListPage: "系統設定",
    navActivityAuditing: "使用記錄",
    navOptionEditor: "選項管理",
    navClientAppSetting: "客戶應用設定",
    navHomePageFeaturedTags: "主頁精選標籤",
    navAgentRatingComment: "代理評分管理",
    navJobMonitor: "系統排程工作",

    // notification
    titleNotifications: "通知",
    titleGotoNotificationCenter: "前往通知中心",
    captionIsRead: " (已讀)",
    captionExpired: "過期",
    actionDone: "完成",

    //language (SAME in all language file)
    traditionalChinese: "繁體中文",
    simplifiedChinese: "简体中文",
    english: "English",
    traditionalChineseAbbr: "繁",
    simplifiedChineseAbbr: "简",
    englishAbbr: "EN",

    legend: "圖例",
    rowsCount: "每頁列數",

    langSearchInCreatePage: "請儲存此新增樓盤後再請求土地查冊",

    downloadAPK: "下載安卓程式",
    downloadIOS: "前往App Store",

    textOverLimit: "字數長度超過限制",
  },
  langBuilding: {
    titleBuildingSearch: "大廈搜尋",
    titleBuildingNew: "新增大廈",
    titleBuildingDetail: "大廈詳情",
    titleBasicInfo: "大廈基本資料及主地址",
    titleBuildingFeature: "大廈特點",

    captionBasicInfo: "基本資料",
    captionLocation: "區域",
    captionFloorUnit: "樓層單位",

    actionSearch: "搜尋",
    actionNewBuilding: "新增大廈",
    actionAdd: "新增",
    actionCenterMap: "地圖置中",

    usage: "用途",
    district: "地區",
    street: "街道",
    streetZh: "街道(中文)",
    streetEn: "街道(英文)",
    latitude: "經度",
    longitude: "緯度",
    building: "大廈",
    buildingOrVillage: "大廈/村",
    buildingNameZh: "大廈名稱(中文)",
    buildingNameEn: "大廈名稱(英文)",
    villageNameZh: "村名(中文)",
    villageNameEn: "村名(英文)",
    buildingOrVillageZh: "大廈/村(中文)",
    buildingOrVillageEn: "大廈/村(英文)",
    block: "座",
    blockZh: "座(中文)",
    blockEn: "座(英文)",
    lot: "地號",
    occupancyPermitDate: "入伙日期",
    occupancyPermitAge: "入伙期(年)",
    expiryYear: "地契年期",
    developer: "發展商",
    floor: "樓層",
    unit: "單位",
    lowLevel: "低層",
    midLevel: "中層",
    highLevel: "高層",
    clubHouseFacilities: "會所設施",
    others: "其他設施",
    primarySchoolNet: "小學校網",
    secondarySchoolNet: "中學校網",
    remarks: "備註",
    locationScore: "地圖準確度",

    pleaseSelect: "請選擇",
    msgInputDistrict: "請選擇地區",
    msgInputStreetName: "請輸入街道名稱",
    msgInputBuildingName: "請輸入大廈名稱",
    msgPleaseEnterChinOrEngName: "請至少輸入中文或英文大廈名稱",
    msgPleaseEnterFloorDefinition: "請輸入樓層和單位",
    msgInputExpiryYear: "請輸入4個數字",

    titlePleaseSelectBuildingToMerge: "請選擇要合併的大廈",
    titlePleaseConfirmBuildingMerge: "請確認合併方式",
    titleBuildingAlias: "大廈副地址",
    titleAddAlias: "新增大廈副地址",

    captionSearchBuilding: "搜索大廈檔案...",
    captionMergeFrom: "將",
    captionMergeInto: "↓合入↓",
    captionBuildingWillBeDeleted: "將被刪除",
    captionBuildingWillBeRetained: "將被保留",
    captionAddToAlias: "將被刪除大廈(%s)加入副地址",

    msgConfirmMerge: "確認繼續?",
    msgConfirmDeleteFloor: "確定刪除這樓層?",

    actionMergeBuilding: "合併大廈",
    actionSwitchMergeDirection: "調轉合入方向",

    textOverLimit: "字數長度超過限制",
  },
  langPropertyStock: {
    titlePropertySearch: "樓盤搜尋",
    titlePropertyDetail: "樓盤詳情",
    titlePropertyMatching: "對盤",
    titleClaimPropertySummary: "樓盤概要",
    titleBasicInfo: "樓盤基本資料",
    titleSaleInfo: "銷售資料",
    titleContactInfo: "客戶資料",
    titleRelatedAgentsInfo: "關聯代理人資料",
    titlePropertyFeatures: "樓盤特點",
    titleMgtInfo: "物業資料",
    titleForm: "填寫表格",
    titleForm1: "表格1",
    titleForm2: "表格2",
    titleForm3: "表格3",
    titleForm4: "表格4",
    titleForm5: "表格5",
    titleForm6: "表格6",
    titleMemo: "備忘錄",
    titlePropertySummary: "樓盤概要 (廣告用)",
    titleProvisionalAgreement: "填寫臨時合約",
    titleSaleAndPurchase: "買賣",
    titleTenancy: "租賃",
    titleNewBuildingRequest: "新增大廈檔案請求",
    titleUploadFiles: "檢視文件",
    titleFile: "查冊",
    titleFormAndAgreement: "表格及臨時合約",

    captionBasicInfo: "基本資料",
    captionLocation: "區域",
    captionArea: "面積",
    captionNetOrGross: "實用/建築",
    captionPriceOrRent: "售價/租價",
    captionPrice: "售價",
    captionRent: "租價",
    captionNet: "實用",
    captionNetAbbr: "實",
    captionGross: "建築",
    captionGrossAbrr: "建",
    captionUnlimited: "不限",
    captionMore: "更多",
    captionFeature: "特點",
    captionOthers: "其他",
    captionSearchConditions: "搜尋條件",
    captionMatchingConditions: "對盤條件",
    captionCover: "封面",
    captionTransaction: "買賣/租售資料",
    captionNotApproved: "未核准",
    captionApproveStage1: "內部對盤",
    captionApproveStage2: "公開宣傳",
    captionWithPhoto: "* 帶相片",
    captionApproved: "樓盤已核准",
    captionFormLocale: "表格語言",
    captionAgreementLocale: "合約語言",
    captionRejectionReason: "樓盤核准被駁回, 原因: ",
    captionPleaseInputRejectionReason: "請輸入駁回理由以確認",
    captionInputRejectionReason: "駁回理由",
    captionRevertToStage1: "僅撤回公開宣傳",
    captionRevertToStage0: "撤回公開宣傳及内部對盤",
    captionAgent: "代理人",
    captionContact: "聯繫",
    captionClientName: "客户姓名",
    captionTransactionHistory: "成交紀錄",
    captionWithKey: "有匙",
    captionWithoutKey: "無匙",
    captionKeyNo: "持匙人",
    captionHotPick: "精選",
    captionNonHotPick: "非精選",
    captionHotPickExpiry: "到期日",
    captionGrantedForForm46: "已授權睇樓",
    captionLeadAgent: "經手代理人",
    captionHotPickValidUntil: "生效至",

    actionSearch: "搜尋",
    actionClearSorting: "清除排列",
    actionNewPropertyStock: "新增樓盤",
    actionNewBuildingRequest: "新增大廈請求",
    actionSetCover: "設為封面",
    actionChangeStatus: "更改狀態",
    actionApprove: "核准",
    actionReject: "駁回",
    actionUnapproved: "未核准",
    actionApproveStage1: "內部對盤",
    actionApproveStage2: "公開宣傳",
    actionApproveRevert: "撤回",
    actionRequestForApproval: "申請核准",
    actionClaimProperty: "認領",
    actionDeletePropertyStock: "移除樓盤",
    actionCloseTransaction: "成交",
    actionSendRequest: "傳送請求",
    actionCancel: "取消",
    actionMatch: "對盤",
    actionViewClientMaster: "檢視客戶資料",
    actionRemoveLeadAgent: "移除",
    actionPARegenerate: "重新簽署",
    actionPAContinueExisting: "前往現有檔案",
    actionGeneratePropertySummaryReport: "生成樓盤概要",
    actionGrantedForForm46: "授權睇樓代理",

    actionRemoveBuyer: "確認移除買方？",
    actionRemoveSeller: "確認移除賣方？",

    actionAddLeadAgent: "新增客戶及經手代理人",
    actionRevealSellerInfo: "披露客戶聯繫",
    msgSellerInfoRevealed: "此客戶的聯繫已經向你披露，經手代理人將會收到通知。",

    actionPrevPhoto: "上一張",
    actionNextPhoto: "下一張",

    actionBuildingUpdateReq: "大廈資料不正確？",
    titleBuildingUpdateReq: "大廈修正請求",
    megRequestBuildingUpdate_1: "您正在發起針對",
    megRequestBuildingUpdate_2:
      "的修正請求。您的修正建議會通過電郵傳送給行政人員。",
    msgInputBuildingUpdateSuggestion: "請輸入修正建議...",
    msgIfBuildingNotFound: "如果您找不到所需的大廈，請發起",

    internal: "本公司成交",
    labelStockByCurrentUser: "經手樓盤",
    // Messages
    msgDeletePropertyStock: "確定移除樓盤?",
    msgChangeStatus: "確定更改樓盤狀態?",
    msgApprovalOrReject: "批准或駁回核准請求",
    msgPleaseChooseToRevert: "請選擇撤回",
    msgBuildingSelect: "輸入地區及大廈名稱以搜尋大廈檔案",
    msgPhotoLimit: "只能上載不超過%d張圖片",
    msgFileLimit: "只能上載不超過%d個文件",
    msgFloorPlanLimit: "只能上載不超過%d張平面圖",
    msgPhotoHint: "請確保您的相片質素，提高樓盤吸引力。",
    msgFirst4PhotoForPropertySummary: "樓盤概要只會展示首4張相片",
    msgInputValidPeriodStartAndEnd: "生效日期應在屆滿日期之前",
    msgInputValidDate: "日期應在上載日期或之前",
    msgUploadRequirement: "檢視檔案上載要求",
    msgUploadRequirementTemplate:
      "檔案不得超過%sMB, 支援檔案類型: %s; 長寬比例: %s",
    msgUploadRequirementPhoto:
      "檔案不得超過6MB, 支援檔案類型: JPG, PNG, GIF; 長寬比例1:2",
    msgUploadRequirementFloorPlan:
      "檔案不得超過6MB, 支援檔案類型: JPG, PNG, GIF; 長寬比例不限",
    msgUploadRequirementFile:
      "檔案不得超過10MB, 支援檔案類型: DOC, DOCX, PDF, XLS, XLSX, JPG, PNG, GIF",
    msgRequireApproveAgain: "編輯樓盤詳情後須重新進行核准, 是否繼續?",
    msgConfirmApproval: "是否確認核准?",
    msgPublishedInClientAppAsPreviousVersion: "此樓盤仍以修改前版本公開宣傳中",
    msgConfirmClaimProperty: "確認認領此樓盤?",
    msgConfirmRequestForApproval: "確認申請核准?",
    msgConfirmDeleteFile: "確認刪除檔案？",
    msgConfirmRemoveAgent: "確認移除?",
    msgConfirmRemoveLastAgent:
      "此乃樓盤的最後一位經手代理人, 移除委任後, 此樓盤將不會與任何客戶有聯繫。",
    msgCannotAddCompanyClient: "此樓盤已有非公司客戶, 不能加入公司客戶。",
    msgWarningUpdateAsCompanyStock:
      '你正在加入公司客戶到此樓盤, 按"%s"後此樓盤將會變成公司世界盤。',
    msgWarningTheAgentIsAlreadyLeadAgent:
      '此客戶的代理人已是此樓盤的經手代理人, 如果你想用此客戶取代現有的聯繫客戶, 請按"確認"。',
    msgNoLeadAgent: "此樓盤沒有經手代理人。",
    msgWarningCurrentUserIsAlreadyLeadAgent:
      '你已是此樓盤的經手代理人, 如果你想以另一客戶取代現有的聯繫客戶, 請按"繼續"前往此樓盤詳情並更改客戶資料。',
    msgConfirmPARegenerate: "是否確認重新簽署？這將會取代之前的SignNow檔案。",
    msgWarningReplaceExistingClient: "此客戶將取代現有的聯繫客戶",
    msgWarningRequireApproveAgain: "編輯樓盤詳情後須重新進行核准",
    msgCannotRequestForApprovalStage2: "因以下原因未能申請核准:",
    msgCannotApproveApproval: "因以下原因未能核准:",
    msgLongLatNotDefined: "尚未確認樓盤的經度/緯度",
    msgNoValidForm35: "未有已簽署並且生效的表格3和/或表格5。",
    msgNoValidlatestForm3: "最新上載的表格3已被刪除或已過期",
    msgNoValidlatestForm5: "最新上載的表格5已被刪除或已過期",
    msgNoValidlatestForm35: "最新上載的表格3和/或表格5已被刪除或已過期",
    msgStatusNotForPublicListing: "當前樓盤狀態(%s)不能公開宣傳",
    msgStatusNotForInternalMatching: "當前樓盤狀態(%s)不能內部對盤",
    msgUnpublishedDueToForm35:
      "由於沒有表格3/5或表格3/5已過期，此樓盤已經暫時下架。重新電子簽署表格3/5後樓盤將自動重新上架。如你須手動上載表格3/5，請在上載之後重新提交核准。",
    msgUnpublishedDueToForm3Expired:
      "由於表格3已過期，此樓盤已經暫時下架。重新電子簽署表格3後樓盤將自動重新上架。如你須手動上載表格3，請在上載之後重新提交核准。",
    msgUnpublishedDueToForm5Expired:
      "由於表格5已過期，此樓盤已經暫時下架。重新電子簽署表格5後樓盤將自動重新上架。如你須手動上載表格5，請在上載之後重新提交核准。",
    msgUnpublishedDueToForm35Expired:
      "由於沒有表格3/5或表格3/5已過期，此樓盤已經暫時下架。重新電子簽署表格3/5後樓盤將自動重新上架。此樓盤將因應簽署的表格決定狀態為（租售／租／售）如你須手動上載表格3/5，請在上載之後重新提交核准。",
    msgStatusBothChangeToSales:
      "由於表格5已過期，此公開宣傳的樓盤暫時不能租。重新電子簽署表格5後公開宣傳的樓盤將自動轉回租售。如你須手動上載表格5，請在上載之後重新提交核准。",
    msgStatusBothChangeToRent:
      "由於表格3已過期，此公開宣傳的樓盤暫時不能售。重新電子簽署表格3後公開宣傳的樓盤將自動轉回租售。如你須手動上載表格3，請在上載之後重新提交核准。",
    msgHasUnapprovedForm3: "手動上載表格3後，需重新提交核准方能生效",
    msgHasUnapprovedForm5: "手動上載表格5後，需重新提交核准方能生效",
    msgHasUnapprovedForm35: "手動上載表格3及表格5後，需重新提交核准方能生效",

    msgInputAtLeastPriceOrRent: "請至少輸入售價或租金",
    msgBottomPriceExceedsPrice: "底價高於售價",
    msgBottomRentExceedsRent: "底租高於租金",
    msgInputNet: "請輸入實用面積",
    msgInputGross: "請輸入建築面積",
    msgInputClient: "輸入客戶名稱或聯繫以搜尋客戶資料",
    msgInputBuilding: "請選擇大廈檔案",
    msgInputFloor: "請選擇層數",
    msgInputUnit: "請選擇單位",
    msgInputKeyNo: "請輸入持匙人",
    msgInputNetOrGross: "請輸入實用面積或建築面積",
    msgInputHotPickExpiry: "請輸入到期日",
    msgInputUsage: "請輸入樓盤用途",
    msgInputOwner: "請輸入業主",

    msgInputPropertyInfo: "請輸入樓盤資料以確認刪除樓盤",
    msgInputBuildingDescription: "請輸入大廈描述",
    msgBuildingRequestSuccess: "新增大廈請求已送出。",
    msgContactNotFound: "找不到對應客戶聯繫",
    msgNewClient: "找不到?建立新客戶檔案",
    msgAtLeastOneClient: "請輸入至少一個%s",
    msgBuildingHelperText: "可用逗號(,)或分號(;)作分隔以搜尋多座大廈/屋苑",
    msgStreetHelperText: "可用逗號(,)或分號(;)作分隔以搜尋多個街道",

    msgRemarksForNonHKID: "* 表示表格的簽署人所提供的乃非香港身份證號碼",
    msgPleaseInputValidFloor: "請輸入正確的樓層，多個樓層以英文逗號(,)分隔",
    msgPleaseInputValidUnit: "請輸入正確的單位，多個單位以英文逗號(,)分隔",

    // for property stock deletion
    msgUnmatchedBuilding: "請輸入正確大廈名稱",
    msgUnmatchedBlock: "請輸入正確座名",
    msgUnmatchedFloor: "請輸入正確樓層",
    msgUnmatchedUnit: "請輸入正確單位",
    msgUnmatchedLot: "請輸入正確地號",

    // approval suggestions
    msgApprovalSuggestion: "編輯樓盤詳情後須重新進行核准，請選擇：",
    msgApprovalSuggestionFirstTime:
      "此樓盤符合公開宣傳條件，現可進行核准，請選擇：",
    msgApprovalSuggestionToStage1Pending: "重新提交核准予內部對盤",
    msgApprovalSuggestionToStage2Pending: "重新提交核准予內部對盤及公開宣傳",
    msgApprovalSuggestionToStage2PendingFirstTime:
      "提交核准予內部對盤及公開宣傳",
    msgApprovalSuggestionToStage1Resolve: "重新核准予內部對盤",
    msgApprovalSuggestionToStage2Resolve: "重新核准予內部對盤及公開宣傳",
    msgApprovalSuggestionToStage2ResolveFirstTime: "核准予內部對盤及公開宣傳",
    msgApprovalSuggestionNoActionPending: "暫不提交核准",
    msgApprovalSuggestionNoActionResolve: "暫不核准",
    msgPleaseSaveBeforeGenerateSummary: "確定要先儲存客戶詳情再生成樓盤概要 ?",

    // Lead Agent Panel
    msgGrantFullAddress:
      "經授權的代理可以看到此樓盤的完整地址，並且可以簽署表格4/表格6。",

    // Land Search Request
    msgLandSearchRequestRequired: "你可透過行政人員進行土地查冊。",
    msgLandSearchRequestOutOfCredit:
      "你的土地查冊次數已用完，請聯絡行政人員增加次數。",
    msgConfirmLandSearchRequest:
      '確認花費1次土地查冊請求次數，請點擊"請求土地查冊" (當前剩餘%s次)',
    msgManualUploadLandSearch:
      "你亦可選擇自行上載土地查冊，但你上載的資料需要經行政人員確認後才可生效。",
    msgLandSearchRequestSuccess: "已發送土地查冊請求",
    msgLandSearchRequestFailed: "土地查冊請求失敗，請聯絡行政人員。",
    titleLandSearchRequest: "土地查冊請求",
    msgLandSearchRequiredForForm35:
      "簽署表格3/5需要有效的土地查冊資料。\n一份有效土地查冊資料需由行政人員確認並且在%d天之內進行",
    actionRequestForLandSearch: "請求土地查冊",
    actionManualUploadLandSearch: "自行上載",
    captionConfirmLandSearch: "確認此土地查冊準確無誤",

    // property stock claim
    msgStockCountPerDistrict: "賣方名下的樓盤數量 (按地區)",
    msgClaimAllUnderTheClient: "你將同時認領此賣方客戶的所有樓盤。",

    // Form Upload Pane
    captionAgreementBy: "簽署人",
    captionValidityPeriod: "有效期",
    captionExpired: "已過期",
    captionRemarks: "備註",
    captionProvisionalAgreementType: "合約類別",
    captionSignDate: "簽署日期",
    captionBuyer: "買方",
    captionSeller: "賣方",
    captionTenant: "租客",
    captionLandlord: "業主",
    captionName: "姓名",
    captionSale: "買賣",
    captionRental: "租賃",
    captionFormProperty: "附表 1 - 物業",

    cationType: "類型",
    captionAuthorCol: "作者",
    captionClientCol: "客戶名稱",
    captionNameCol: "名稱", //??
    captionDate: "日期",
    captionNote: "備註",
    captionHistory: "上載紀錄",

    captionNameHidden: "(已隱藏)",

    // Upload pane
    landSearch: "土地查冊",
    saSearch: "實積查冊",
    occPermit: "入伙紙",
    businessReg: "公司查冊",
    otherDoc: "其他",
    form1: "表格1 (物業資料表格)",
    form2: "表格2 (出租資料表格)",
    form3: "表格3 (出售物業用的地產代理協議)",
    form4: "表格4 (購買物業用的地產代理協議)",
    form5: "表格5 (出租物業用的地產代理協議)",
    form6: "表格6 (承租物業用的地產代理協議)",
    provisionalAgreement: "臨時買賣/租賃合約",
    provisionalAgreementSuppDoc: "臨時買賣/租賃合約的補充文件",
    form46ClientAgentPrefix: "代理",

    // Features
    facing: "座向",
    deco: "裝修",
    view: "景觀",
    otherFeature: "特色",
    otherFeatures: "特色",
    room: "房間",
    suite: "套房",
    livingRoom: "廳",
    livingRooms: "廳",
    helperRoom: "工人房",
    balcony: "露台",
    balconyArea: "露台面積",
    bathroom: "浴室",
    remarks: "公開備註",
    privateRemarks: "私人備註",
    video: "影片",
    photo: "相片",
    floorPlan: "平面圖",
    heading: "標題",
    punchline: "簡介",
    clubHouseFacilities: "會所設施",
    others: "其他",
    garden: "花園",
    gardenArea: "花園面積",
    rooftop: "天台",
    rooftopArea: "天台面積",
    primarySchoolNet: "小學校網",
    secondarySchoolNet: "中學校網",
    hotPick: "精選",
    propertyNo: "樓盤編號",

    // Fields
    buildingName: "大廈名稱",
    buildingOrVillageName: "大廈名稱/村名",
    expiryYear: "地契年期",

    usage: "用途",
    stockNo: "樓盤編號",
    occupancyPermitDate: "入伙日期",
    occupancyPermitAge: "入伙期(年)",
    address: "地址",
    district: "地區",
    street: "街道",
    streetZh: "街道(中文)",
    streetEn: "街道(英文)",
    lot: "地號",
    building: "大廈",
    buildingOrVillage: "大廈/村",
    streetOrBuilding: "街道/大廈",
    block: "座",
    floor: "樓層",
    level: "樓層",
    unit: "單位",
    symbol: "符號",
    size: "面積(呎)",
    gross: "建(呎)",
    net: "實(呎)",
    status: "狀態",
    target: "目的",
    key: "鎖匙",
    vacant: "交吉",
    price: "售價(萬)",
    unitPrice: "呎價",
    pricePerNet: "實呎(售/租)",
    pricePerNetSorted: "實呎($售/租)",
    rentPerNetSorted: "實呎(售/$租)",
    pricePerGross: "建呎(售/租)",
    pricePerGrossSorted: "建呎($售/租)",
    rentPerGrossSorted: "建呎(售/$租)",
    bottomPrice: "底價",
    rent: "租金",
    unitRent: "呎租",
    bottomRent: "底租",
    currentRent: "當前租金",
    owner: "業主",
    sellerOrOwner: "賣方/業主",
    leaseCommencement: "當前租期",
    mainContact: "客戶聯繫",
    companyStock: "公司世界盤",
    dateCreated: "新增日期",
    dateModified: "最後更新",
    modifiedBy: "最後更新帳戶",
    to: "到",
    buyer: "買方",
    seller: "賣方",
    transactionDate: "成交日期",
    baseFloor: "地庫",
    groundFloor: "地下",
    mezzanineFloor: "閣樓",
    otherArea: "其他",
    baseFloorGross: "地庫建築面積",
    baseFloorNet: "地庫實用面積",
    groundFloorGross: "地下建築面積",
    groundFloorNet: "地下實用面積",
    mezzanineFloorGross: "閣樓建築面積",
    mezzanineFloorNet: "閣樓實用面積",
    otherAreaGross: "其他建築面積",
    otherAreaNet: "其他實用面積",
    rentFreePeriod: "免租期 (日)",
    _yield: "回報率",
    tenancyRemarks: "現租約備註",
    toilet: "廁所",
    backDoor: "後門",
    mezzanineFloorArea: "入則閣",
    storeFrontWidth: "門寬",
    storeFrontHeight: "門高",
    shopDepth: "舖深",

    developer: "發展商",
    assessmentNum: "估價編號",
    prn: "物業參考編號",
    ratesPropertyAddress: "差餉物業地址",
    quarterlyRates: "每季差餉",
    mgtCompanyRegNum: "公司註冊編號",
    annualGovRent: "每年地租",
    monthlyMgtFee: "每月管理費",
    mgtFeeIncl: "管理費種類 (用於工商舖)",
    mgtFeeGross: "管理費每呎(建築面積)",
    mgtFeeNet: "管理費每呎(實用面積)",
    mgtProfile: "聯絡身份",
    mgtCommission: "業主佣金",

    captionDormantPropertyStock: "閒置樓盤",
    captionNonDormantPropertyStock: "非閒置樓盤",
    captionOwnPropertyStock: "經手樓盤",
    canClaimBy: "開放認領予",
    captionHistoryTrnx: "歷史成交",
    captionPerGross: "建呎",
    captionPerNet: "實呎",

    // Property Stock Matching
    captionPropertyMatching1: "正在為",
    captionPropertyMatching2: "對盤",
    captionChosenClientsForMatching: "已選擇的客戶",
    actionAddToWishlist: "將所選樓盤加入睇樓清單",
    actionCancelMatching: "取消對盤",
    actionAddToMultiWishlists: "加入睇樓清單",
    actionAddToMultiClientWishlists: "加入此樓盤至客戶睇樓清單",
    msgAddToMultiClientWishlistsSuccess: "樓盤已成功至客戶睇樓清單",

    // Property Stock Release Status
    statusChangeHeading: "更改狀態",
    captionReleasePrefix: "由狀態",
    captionReleaseSuffix: "回復至售/租",

    //warning dialog for creating a duplicated property stock
    waringDialogTitle: "注意",
    propertyStock: "樓盤",
    msgDuplicatedPropertyStockAddress: "一個相同地址的%s已經存在: %s",
    msgCannotEditToExistingAddress:
      "無法更新當前樓盤地址，因為一個相同地址的已核準%s已經存在: %s",
    msgAddressNonEditable:
      "無法更新當前樓盤地址，因為當前樓盤有多於一個經手代理",
    msgPleaseAddClient:
      '如果你想成為此樓盤的其中一個經手代理人, 請按"繼續"以填寫客戶資料。',
    msgAddClientByAdmin:
      '如果你想將此樓盤的經手代理人加入到已核準的樓盤, 請按"繼續"。',
    msgConfirmAddAllAgentsAndClients:
      "確認將此樓盤的所有經紀及其客戶加入到樓盤",
    msgConfirmAsOneOfLeadAgent:
      '如果你想成為此樓盤的其中一個經手代理人, 請按"繼續"',
    actionRedirectToExistingProperty: "前往此公司樓盤",

    //preview property stock
    titleAgentAndClientDetail: "物業代理及客戶詳情",
    captionAssociatedClient: "所聯繫之客戶",
    captionPropertyStockAgent: "物業代理",
    captionCurrentLeaseRemarks: "現有租約備註",
    monthlyMgtFeePerNet: "/呎(實用)",
    monthlyMgtFeePerGross: "/呎(建築)",
    saleAbbr: "售",
    rentAbbr: "租",
    notSpecified: "未列明",
    msgWarningNoOwnerInfo: '閣下尚未有業主聯絡資料, 請按"繼續"開始登記。',

    //seller client dialog
    titleSellerClientDialog: "%s樓盤客戶資料", //%s is the action, e.g. 'Add' or 'Edit'

    // provisional agreement messages
    msgSaleAgreementExists:
      "另一位經手代理人已經發起買賣臨時合約。如您想發起，請同該代理人或行政人員聯絡。",
    msgTenancyAgreementExists:
      "另一位經手代理人已經發起租賃臨時合約。如您想發起，請同該代理人或行政人員聯絡。",
    msgSpecifiedRoleCanFillIn: "僅%s可以填寫此資料",
    msgConfirmDeleteProvisionalAgreemnt:
      "確認刪除此臨時合約嗎？刪除後資料將不可恢復。臨時合約刪除後，其他經手代理人將可以發起新的臨時合約。",
    msgDeleteExsitingPaBeforeUpload:
      "此樓盤存在已簽署的臨時合約。要上載新的臨時合約請先刪除已有的臨時合約。",
    msgSignGenerate:
      "如你需使用SignNow進行電子簽署，請在生成PDF之後于SignNow上載：",

    msgDeleteExisingForm3:
      "有一份表格3尚待審核, 如需重新電子簽署或上載新的表格3, 請先刪除最新上載的表格。",
    msgDeleteExisingForm5:
      "有一份表格5尚待審核, 如需重新電子簽署或上載新的表格5, 請先刪除最新上載的表格。",

    textOverLimit: "字數長度超過限制",

    noValidForm: "沒有生效的表格",
  },
  langCloseTransaction: {
    tenancyContract: "租賃合約",
    salePurchaseContract: "買賣合約",
    restCommission: "剩餘佣金將歸本公司所有",
    confirmForm134: "請確認已上傳 表格1, 表格3 及 表格4.",
    confirmForm256: "請確認已上傳 表格2, 表格5 及 表格6.",
    confirmForm13: "請確認已上傳 表格1 和 表格3.",
    confirmForm14: "請確認已上傳 表格1 和 表格4.",
    confirmForm25: "請確認已上傳 表格2 和 表格5.",
    confirmForm26: "請確認已上傳 表格2 和 表格6.",
    confirmProvisionalAndLandSearch: "請確認已上傳 臨時買賣/租賃合約及土地查冊",
    missingDocuments: "缺少文件",
    msgFileLimit: "只能上載不超過%d個文件",
    msgUploadRequirement: "檢視檔案上載要求",
    msgUploadRequirementFile:
      "檔案不得超過10MB, 支援檔案類型: DOC, DOCX, PDF, XLS, XLSX, JPG, PNG, GIF",
    msgAgentConfirm: "請代理人簽名確認",
    msgApproverConfirm: "請核准人簽名確認",
    msgConfirmRemoveAgent: "確認移除代理？",
    msgConfirmApprovalRequest: "確認提交？",
    msgConfirmApproval: "確認核准?",
    msgSpecifyBuyerClient: "本公司買方代理必須關聯買方客戶檔案",
    msgSpecifyTenantClient: "本公司租客代理必須關聯租方客戶檔案",
    actionEditLinkage: "修改關聯",
    actionRemoveLinkage: "刪除關聯",
    titleBuyerClientLinkage: "關聯買家客戶檔案",
    titleTenantClientLinkage: "關聯租方客戶檔案",
    msgBuyerClientLinkage: "請選擇本次成交的買家客戶檔案。",
    msgTenantClientLinkage: "請選擇本次成交的租方客戶檔案。",
    pleaseSelect: "請選擇",
    pleaseInput: "請輸入",
    msgBuyerAgentCommExceed: "買家代理分佣總額須不多於買方佣金",
    msgBuyerAgentCommNotEqual: "買家代理分佣總額應等於買方佣金",
    msgSellerAgentCommExceed: "賣家代理分佣總額須不多於賣方佣金",
    msgSellerAgentCommNotEqual: "賣家代理分佣總額應等於賣方佣金",
    msgTenantAgentCommExceed: "租客代理分佣總額須不多於租方佣金",
    msgTenantAgentCommNotEqual: "租客代理分佣總額應等於租方佣金",
    msgLandlordAgentCommExceed: "業主代理分佣總額須不多於業主方佣金",
    msgLandlordAgentCommNotEqual: "業主代理分佣總額應等於業主方佣金",
    msgInvalidInitialDepositAndBigDepositSum: "臨時訂金及大訂的總和不能大於成交價",

    titleCloseTransactionRequest: "成交",
    titleClients: "客戶",
    titleAgents: "代理人",
    titleGeneralInformation: "基本資料",
    titleSupplementalDocuments: "補充文件",

    captionConfirmed: "已確認",
    captionSubmitted: "已提交",
    captionRejectionRemarks: "駁回理由",
    captionFormChecklist: "文件檢查清單",
    captionPrice: "成交價",
    captionRent: "成交租金",
    captionBuyerCommReceivedDate: "買方佣金收款日期",
    captionSellerCommReceivedDate: "賣方佣金收款日期",
    captionTenantCommReceivedDate: "租客佣金收款日期",
    captionLandlordCommReceivedDate: "業主佣金收款日期",
    captionTransactionDate: "成交日期",
    captionBuyerSide: "買方",
    captionTenantSide: "租客",
    captionSellerSide: "賣方",
    captionLandlordSide: "業主",
    captionCommission: "佣金",
    captionCommissionShareForAgents: "代理人可分佣金",

    captionClientName: "客戶名稱",
    captionClientContact: "客戶聯繫",
    captionClientAddress: "客戶聯絡地址",

    captionAgentType: "代理人身份",
    captionInternal: "本公司",
    captionExternal: "非本公司",
    captionAgentName: "代理人名稱",
    captionAgentContact: "代理人聯繫",
    captionBranchCode: "分行編號",
    captionRemark: "備註",

    captionInitialDeposit: "臨時訂金",
    captionBigDeposit: "大訂",
    captionCompletion: "樓價餘額",
    date: "日期",

    actionRequestForConfirmation: "要求確認",
    actionConfirm: "確認及簽署",
    actionSubmitForApproval: "提交",
    actionApprove: "核准",
    actionReject: "駁回",
    captureSubmitted: "已提交核准",
    captureApproved: "已核准",

    //for validation msg
    titlePremises: "物業",
  },
  langTransaction: {
    titleTransactionDetail: "成交詳情",
    titleBasicInfo: "基本資料",
    titleSalesInfo: "買賣及銷售資料",
    titleTransactionInfo: "成交資料",
    titleTransactionSearch: "成交搜尋",
    // for property draft edit
    msgPropertyDraftUpdateRequired:
      '一項或多項資料需要修正。 請修正後點擊"修正資料"儲存。',
    msgPleaseInputValidBuildingName: "請至少輸入中文或英文的大廈/村名稱",
    msgPleaseInputValidUsage: "請輸入正確的用途",
    msgPleaseInputValidFloor: "請輸入正確的樓層，多個樓層以英文逗號(,)分隔",
    msgPleaseInputValidUnit: "請輸入正確的單位，多個單位以英文逗號(,)分隔",
    msgInvalidDistrict: "無法識別的地區編號: %s, 請從選項中選擇",
    msgInvalidUsage: "無法識別的用途編號: %s, 請從選項中選擇",
    msgAddDraftAsAlias: "是否將修正前的大廈地址列為大廈副地址？",

    captionShowInvalidOnly: "僅顯示須修正成交",
    captionShowValidOnly: "僅顯示有效成交",
    captionShowAllValidity: "顯示全部成交",

    usage: "用途",
    status: "狀態",
    lot: "地號",
    district: "地區",
    street: "街道",
    building: "大廈",
    buildingOrVillage: "大廈/村",
    buildingOrVillageName: "大廈名稱/村名",
    block: "座",
    floor: "樓層",
    level: "低中高層",
    unit: "單位",
    gross: "建(呎)",
    net: "實(呎)",
    price: "售價($)",
    rent: "租金($)",
    priceOrRent: "售價/租金($)",
    type: "成交",
    pricePerNet: "淨價($/呎)",
    pricePerNetAbbr: "$/呎",
    pricePerGross: "呎價($/呎)",
    rentPerNet: "淨租($/呎)",
    rentPerGross: "呎租($/呎)",
    priceOrRentPerNet: "淨價/淨租($/呎)",
    priceOrRentPerGross: "呎價/呎租($/呎)",
    buyer: "買方",
    buyerOrTenant: "買方/租客",
    buyerContact: "買方聯繫",
    seller: "賣方",
    sellerOrLandlord: "賣方/業主",
    sellerContact: "賣方聯繫",
    landlord: "業主",
    tenant: "租客",
    transactionDate: "成交日期",
    documentDate: "文書日期",
    buildingAge: "樓齡",
    buildingAgeAbbr: "樓齡",
    occupancyPermitDate: "入伙日期",
    internal: "本公司成交",

    clientType: "客戶類型",
    contactType: "聯繫方式",
    contactName: "姓名",
    contactRemarks: "備註",

    actionClearSorting: "清除排列",
    actionNewPropertyStock: "新增樓盤",
    actionUpdatePropertyDraft: "修正資料",
  },
  langSettings: {
    titleOptionSetting: "選項管理",

    captionOptionCode: "編號",

    msgDuplicatedOptionCode: "編號不能重複",
  },
  langForm1: {
    //General
    dateOfObtainingInfo: "取得資料的日期",
    source: "訂明來源",
    year: "年",
    month: "月",
    day: "日",
    HKDollar: "港幣$",
    captionFormLocale: "表格語言",
    part: "部分",
    statement: "陳述",
    is: "為",
    form: "表格",

    //Part 1
    titlePart1: "第 1 部 - 物業資料",
    //Part A
    formName: "物業資料表格 (表格1)",
    titlePartA: "A部 : 物業地址",
    propertyAddress: "物業地址",
    //Part B1
    titlePartB: "B部 : 物業的訂明資料",
    titlePartB1: "註冊於土地註冊處的當時的擁有權及有效的產權負擔的詳情 : ",
    ownerName: "擁有人的姓名或名稱",
    courtOrders: "法庭命令",
    buildingOrders: "建築命令",
    slopeMaintenanceOrders: "斜坡修葺令",
    lease: "租契",
    mortgageOrCharge: "按揭或押記",
    otherMattersRegisteredAsEncumbrances: "其他登記為產權負擔的項目",
    //Part B2
    titlePartB2: "物業的樓面面積",
    B2_optionA: "差餉物業估價署所提供的實用面積",
    B2_optionB: "在首次轉讓的買賣協議中述明的實用面積",
    B2_optionC: "不能從(a)及(b)段途徑取得有關資料",
    //Part B3
    titlePartB3: "落成年份",
    B3_description:
      "在佔用許可證或(如沒有佔用許可證)有關的「合格證明書」或「不反對入住書」上述明的物業(或物業所屬建築物)的落成年份",
    yearOfCompletion: "落成年份",
    //Part B4
    titlePartB4: "用途限制",
    B4_description: "佔用許可證所規定的用途",
    //Part B5
    titlePartB5: "政府租契",
    unexpiredTermOfGovLease: "租契年期尚餘的期間",
    rightOfRenewal: "續期權利",
    //Part B6
    titlePartB6: "如行將批給政府租契, 請述明所擬批給的租契年期",
    proposedLease: "租契年期",

    //Part C
    titlePartC: "C部 : 可提供關於物業的其他資料",
    annualGovRent: "每年的地租/地稅",
    quarterlyRates: "每季的差餉",
    monthlyManagementFee: "每月的管理費",
    ownersIncorporationEstablished: "業主立案法團經已成立",
    floorAreaAndSourceOfInfo: "物業的樓面面積及其資料來源是",
    propertyCategoriesSelect: "物業屬於以下其中一類別",
    tenantPurchaseScheme: "租者置其屋計劃",
    homeOwnershipScheme: "居者有其屋計劃",
    privateSectorParticipationScheme: "私人機構參建居屋計劃",
    sandwichClassHousing: "夾心階層住屋計劃",
    flatForSaleScheme: "住宅發售計劃(房屋協會)",
    propertyCategoriesRemarks:
      "(註：在以上各項計劃中，已補地價的單位不屬該類別。)",
    villageTypeHouse: "新界的“鄉村”式屋宇",
    others: "其他類別，請指明: ",

    //Part 2
    part2: "第2部分",
    titlePart2: "第 2 部 - 賣方的陳述",
    part2_1_description:
      "賣方或任何其他人於賣方擁有物業期間曾否、是否或會否就物業或物業所屬建築物的任何部分，進行結構上的增建或改建?",
    part2_2_description:
      "在賣方擁有物業之前，曾否有任何人就物業或物業所屬建築物的任何部分，進行結構上的增建或改建?",
    part2_3_description:
      "在賣方擁有物業期間，政府或物業所屬建築物的管理處或業主立案法團，曾否、是否或會否就物業或物業所屬建築物的任何部分，規定進行任何修復、補救、修葺或改善?",
    part2_4_description:
      "在賣方擁有物業之前，政府或物業所屬建築物的管理處或業主立案法團，曾否就物業或物業所屬建築物的任何部分，規定進行任何修復、補救、修葺或改善?",
    part2_5_description1:
      "政府或物業所屬建築物的管理處或業主立案法團，曾否、是否或會否就物業或物業所屬建築物的任何部分，規定或建議進行以下工程，而該項工程的全部或部分費用是須由物業的買方負擔的?",
    part2_5_description2:
      "請述明所規定進行或建議進行的工程(如有的話)的性質及買方所須負擔的費用/預算費用*：",
    part2_5_description2_v2:
      "請述明所規定進行或建議進行的工程(如有的話)的性質及買方所須負擔的",
    part2_5_description2_v2_part2: "",
    cost: "費用",
    estimatedCost: "預算費用",
    both: "費用/預算費用",
    part2_6_optionA: "物業將在可交出空置管有權(即俗稱交吉)的情況下出售",
    part2_6_optionB: "物業將連同以下租契/有特定年期的特許/法定租賃*出售",
    part2_6_optionB_sentence1: "物業將連同以下",
    part2_6_optionB_sentence2: "出售",
    pleaseSelect: "請選擇",
    leaseOption: "租賃資料",
    leaseOptions1: "租契",
    leaseOptions2: "有特定年期的特許",
    leaseOptions3: "法定租賃",
    monthlyRental: "每月租金",
    inclusiveOf: "包括",
    rates: "差餉",
    governmentRent: "地租/地稅",
    managementFee: "管理費",
    tenancyTerm: "年期",
    tenancyTermRemarks: "(首尾兩天包括在內)",
    dateFrom: "開始日期",
    dateTo: "屆滿日期",
    otherTerms: "其他條款",
    breakClause: "解約條款",
    rentalReview: "租金調整",
    optionToRenew: "續期權",
    otherSpecialTermsParticular: "其他特別條款詳情",
    otherSpecialTerms: "其他特別條款; 請指明: ",
    informationProvidedByVendor: "本表格第 2 部的資料由賣方所提供",
    vendorName: "賣方名稱",

    //Agnet details
    agentInfo: "代理資料",
    agentName: "代理名稱",
    nameAndLicenceNumberOfSignatory: "簽署人的姓名或名稱及牌照號碼",
    address: "地址",
    telephoneNumber: "電話號碼",
    faxNumber: "傳真號碼",
    date: "日期",

    helperTextParticulars: "如“有”，請述明詳情",

    //to be confirmed
    numberOfAgentStatement: "代理的營業詳情說明書號碼",

    //validation msg
    pleaseInput: "請輸入",
    particulars: "詳情",
    msgSelectDateOfObtainingInfo: "請選擇取得資料的日期",
    msgSelectionValidDate: "取得資料的日期應在填寫日期或之前",
    msgInputValidPhoneNumber: "請輸入有效的電話號碼",
    msgInputMissingFields: "請填妥以下資料",
    msgSelectAtLeastOneForPart6: "請選擇至少一個選項",
    msgInputSelectedFields: "請輸入類別",
  },
  form1SourceOptions: {
    A: "土地註冊處",
    B: "差餉物業估價署",
    C: "屋宇署",
  },
  form1CostOfPurchaserOptions: {
    a: "修復或補救工程",
    b: "修葺或改善工程",
    c: "斜坡維修工程",
    d: "其他",
    e: "沒有",
    f: "不知道",
  },
  langForm2: {
    formName: "出租資料表格",

    //General
    dateOfObtainingInfo: "取得資料的日期",
    source: "訂明來源",
    HKDollar: "港幣$",
    captionFormLocale: "表格語言",
    part: "部分",
    form: "表格",

    //part A

    titlePartA: "A部：物業地址",
    propertyAddress: "物業地址",

    //part B1
    titlePartB: "B部：物業的訂明資料",
    titlePartB1: "註冊於土地註冊處的當時的擁有權及有效的產權負擔的詳情",
    ownerName: "擁有人的姓名或名稱",
    possessionOrders: "管有權命令",
    mortgageOrCharge: "按揭或押記",

    //part B2
    titlePartB2: "物業的樓面面積",
    B2_optionA: "差餉物業估價署所提供的實用面積",
    B2_optionB: "在首次轉讓的買賣協議中述明的實用面積",
    B2_optionC: "不能從(a)及(b)段途徑取得有關資料。",

    //part B3
    titlePartB3: "落成年份",
    yearOfCompletion: "物業(或物業所屬建築物)的落成年份",

    //Part B4
    titlePartB4: "用途限制",
    B4_description: "佔用許可證所規定的用途",

    //part B5
    titlePartB5: "出租限制",
    B5_descripton: "法例/政府租契下的出租限制",

    //part C
    titlePartC: "C部：可提供關於物業的其他資料",
    annualGovRent: "每年的地租/地稅",
    quarterlyRates: "每季的差餉",
    monthlyManagementFee: "每月的管理費",
    annualGovRentnotneg: "每年的地租/地稅不能爲負數",
    quarterlyRatesnotneg: "每季的差餉不能爲負數",
    monthlyManagementFeenotneg: "每月的管理費不能為負數",
    subletParticulars: "分租詳情",
    toBeSublet: "物業現正受租契約束，並擬分租",
    toBeSubletSentence1: "物業現正受租契約束，",
    toBeSubletSentence2: "並擬分租",

    //Agnet details
    agentInfo: "代理資料",
    agentName: "代理名稱",
    nameAndLicenceNumberOfSignatory: "簽署人的姓名或名稱及牌照號碼",
    numberOfAgentStatement: "代理的營業詳情說明書號碼：",
    address: "地址",
    telephoneNumber: "電話號碼",
    faxNumber: "傳真號碼",
    date: "日期",

    helperTextParticulars: "如“有”，請提供詳情",

    //validation msg
    pleaseInput: "請輸入",
    pleaseSelect: "請選擇",
    particulars: "詳情",
    msgSelectDateOfObtainingInfo: "請選擇取得資料的日期",
    msgSelectionValidDate: "取得資料的日期應在填寫日期或之前",
    msgInputValidPhoneNumber: "請輸入有效的電話號碼",
    msgInputMissingFields: "請填妥以下資料",
  },
  form2SourceOptions: {
    A: "土地註冊處",
    B: "差餉物業估價署",
    C: "房屋協會",
    D: "房屋署",
    E: "屋宇署",
  },
  langForm3: {
    formName: "出售香港住宅物業用的地產代理協議",

    //general
    HKDollar: "港幣$",
    pleaseInput: "請輸入",
    pleaseSelect: "請選擇",
    captionFormLocale: "表格語言",
    form: "表格",

    //part 1
    titlePart1: "代理的委任及本協議的有效期",
    vendorName: "賣方姓名",
    agentName: "代理姓名",
    exclusiveAgent: "獨家代理",
    propertyAddress: "物業",
    validityPeriod: "有效期",
    validityPeriodStart: "生效日期",
    validityPeriodEnd: "屆滿日期",
    validityPeriodRemarks: "(首尾兩天包括在內)",

    //part 2
    titlePart2: "代理關係及代理的責任",
    relationship: "代理關係",
    singleAgency: "單邊代理",
    dualAgency: "雙邊代理",
    potentiallyDualAgency: "有可能代表雙方的代理",

    //part 3
    titlePart3: "放盤價",
    listPrice: "放盤價",

    //part 6
    titlePart6: "視察物業",
    allowViewing: "賣方同意容許代理/買方於雙方議定的時間查看物業。",
    allowViewingSentence1: "賣方同意容許",
    allowViewingSentence2: "於雙方議定的時間查看物業。",
    agent: "代理",
    purchaser: "買方",
    bothAgentAndPurchaser: "代理/買方",
    passKeyForSafeCustody:
      "賣方同意將物業的鎖匙交由代理妥為保管，以供查看物業之用。",
    passKeyToThirdParty:
      "賣方授權代理將鎖匙交予其他地產代理/人士，以供查看物業之用。",
    passKeyToThirdPartySentence1: "賣方授權代理將鎖匙交予其他",
    passKeyToThirdPartySentence2: "，以供查看物業之用。",

    estateAgent: "地產代理",
    otherPerson: "人士",
    bothEstateAgentOrPerson: "地產代理/人士",

    //part 7
    titlePart7: "分銷放盤",
    allowSubListing:
      "賣方授權代理將物業分銷放盤，並將由賣方提供的關於賣方及物業的資料交予其他地產代理以供分銷放盤之用。",

    //part 8
    titlePart8: "廣告宣傳",
    allowAdvertisement: "賣方授權代理發出關於物業的廣告。",

    //part 9
    titlePart9: "代理須披露權益",
    disclosureOfInterestByAgent:
      "代表代理簽署本協議的人現披露：他或其指明親屬，或其任何代名人，或其指明親屬的任何代名人，或代理或代理的任何僱員/大股東/合夥人/董事對物業擁有金錢上的或其他實益的權益: ",

    //part 10
    titlePart10: "賣方的確認",
    vendorAcknowledgement1:
      "已收取本協議第5 條規定提供的物業資料表格(包括賣方的陳述)。",
    vendorAcknowledgement2:
      "已同意於賣方與一名買方訂立具約束力的買賣協議之前收取物業資料表格(包括賣方的陳述)。",

    //part 13
    titlePart13: "額外條款",
    extraTermsCautions:
      "[注意：這些額外條款不得與本協議的其他條款有所抵觸，亦不得限制本協議的其他條款。]",

    //schedule 2
    titleSchedule2: "附表 2",
    titleCommission: "賣方須支付的佣金",
    commission: "佣金",
    commissionIn: "計算方式",
    amount: "金額",
    percentage: "物業成交價的百份比",
    paymentTime: "支付時間",
    paymentTimeOption1: "簽署買賣協議時",
    paymentTimeOption2: "買賣協議指明的物業交易完成時",

    titlePart2Point2: "交易未能完成時還予賣方的佣金",
    withInterest: "連同利息",
    withoutInterest: "不連同利息",

    //general info
    address: "地址",
    phoneNumber: "電話號碼",
    faxNumber: "傳真號碼",
    date: "日期",

    //vendor info
    vendorInfo: "賣方資料",
    HKID: "香港身分證號碼",
    ifApplicable: "(如適用的話)",
    whereVendorIsCompany: "如賣方是一間公司，請述明：",
    nameOfSignatoryOfVendor: "簽署人的姓名或名稱",
    positionOfSignatoryOfVendor: "簽署人的職位",
    businessRegistrationCertificateNumberOfVendor: "賣方的商業登記證號碼",

    //agent info
    agentInfo: "代理資料",
    nameAndLicenceNumberOfAgent: "簽署人的姓名或名稱及牌照號碼",
    numberOfStatementOfParticularsOfBusiness: "代理的營業詳情說明書號碼",

    helperTextParticulars: "如“有”，請述明詳情",

    msgInputVendorName: "請輸入賣方名稱",
    msgInputAgentName: "請輸入代理名稱",
    msgInputPropertyAddress: "請輸入物業",
    msgInputValidityPeriodStart: "請輸入生效日期",
    msgInputvalidityPeriodEnd: "請輸入屆滿日期",
    msgInputValidPeriodStartAndEnd: "生效日期應在屆滿日期之前",
    msgSelectRelationship: "請選擇代理關係",
    msgInputListPrice: "請輸入放盤價",
    msgInputInterestsInProperty: "請輸入代理須披露權益",
    msgSelectVendorAcknowledgementOption: "請選擇賣方的確認",
    msgSelectCommissionIn: "請選擇計算方式",
    msgInputCommissionAmount: "請輸入金額",
    msgInputCommissionRate: "請輸入百份比",
    msgSelectPaymentTime: "請選擇支付時間",
    msgSelectWithInterest: "請選擇連同利息/不連同利息",
    msgInputHKID: "請輸入香港身分證號碼",
    msgInputAddress: "請輸入地址",
    msgInputPhoneNumber: "請輸入電話號碼",
    msgInputFaxNumber: "請輸入傳真號碼",
    msgInputDate: "請輸入日期",
    msgIputNameOfSignatoryOfVendor: "請輸入簽署人的姓名或名稱",
    msgPositionOfSignatoryOfVendor: "請輸入簽署人的職位",
    msgIputBusinessRegistrationCertificateNumberOfVendor:
      "請輸入賣方的商業登記證號碼",
    msgIputNameAndLicenceNumberOfAgent: "請輸入簽署人的姓名或名稱及牌照號碼",
    msgIputNumberOfStatementOfParticularsOfBusiness:
      "請輸入代理的營業詳情說明書號碼",
    msgInputValidPhoneNumber: "請輸入有效的電話號碼",
    msgInputSignatoryInfoOrHKID: "請輸入香港身分證號碼或公司(代表)資料",
    msgSelectAtLeastOneForPart6: "請選擇至少一個選項",

    msgInputMissingFields: "請填妥以下資料",
  },
  langForm4: {
    formName: "購買香港住宅物業用的地產代理協議",

    pleaseInput: "請輸入",
    captionFormLocale: "表格語言",
    pleaseSelect: "請選擇",
    form: "表格",

    //part 1
    titlePart1: "代理的委任及本協議的有效期",
    purchaserName: "買方",
    agentName: "代理",
    validityPeriod: "有效期",
    validityPeriodStart: "生效日期",
    validityPeriodEnd: "屆滿日期",
    validityPeriodRemarks: "(首尾兩天包括在內)",

    //part 5
    titlePart5: "代理須披露權益",
    disclosureOfInterestByAgent:
      "代表代理簽署本協議的人現披露﹕他或其指明親屬，或其任何代名人，或其指明親屬的任何代名人，或代理或代理的任何僱員/大股東/合夥人/董事對物業擁有金錢上的或其他實益的權益",

    //part 9
    titlePart9: "額外條款",
    extraTermsCautions:
      "[注意﹕這些額外條款不得與本協議的其他條款有所抵觸，亦不得限制本協議的其他條款]:",

    //general info
    address: "地址",
    phoneNumber: "電話號碼",
    faxNumber: "傳真號碼",
    date: "日期",

    //purchaser info
    purchaserInfo: "買方資料",
    HKID: "香港身分證號碼",
    ifApplicable: "(如適用的話)",
    wherePurchaserIsCompany: "如買方是一間公司，請述明：",
    nameOfSignatoryOfPurchaser: "簽署人的姓名或名稱",
    positionOfSignatoryOfPurchaser: "簽署人的職位",
    businessRegistrationCertificateNumberOfPurchaser: "買方的商業登記證號碼",

    //agent info
    agentInfo: "代理資料",
    nameAndLicenceNumberOfAgent: "簽署人的姓名或名稱及牌照號碼",
    numberOfStatementOfParticularsOfBusiness: "代理的營業詳情說明書號碼",

    //schedule 1
    titleSchedule1: "附表 1",
    column1: "物業",
    column2: "視察日期",
    column3: "買方同意不視察物業",
    column4: "買方放棄收取物業資料表格(包括賣方的陳述)的權利",
    column5: "代理關係",
    column6: "賣方所須支付的佣金(如適用的話)的數額或收費率",
    column7: "買方所須支付的佣金的數額或收費率",

    property: "",
    dateOfInspection: "",
    notToInspect: "",
    waiveRight: "",
    agencyRelationShip: "",
    vendorCommissionAmountOrRate: "",
    agentCommissionAmountOrRate: "",

    singleAgency: "單邊代理",
    dualAgency: "雙邊代理",
    potentiallyDualAgency: "有可能代表雙方的代理",

    //schedule 2
    titleSchedule2: "附表 2",
    titleCommission: "買方須支付的佣金",
    paymentTime: "支付時間",
    paymentTimeOption1: "簽署買賣協議時",
    paymentTimeOption2: "買賣協議指明的物業交易完成時",

    titleSchedulePoint2: "物業交易未能完成時還予買方的佣金",
    withInterest: "連同利息",
    withoutInterest: "不連同利息",

    helperTextParticulars: "如“有”，請述明詳情",

    msgInputPurchaserName: "請輸入買方名稱",
    msgInputAgentName: "請輸入代理名稱",
    msgInputValidityPeriodStart: "請輸入生效日期",
    msgInputvalidityPeriodEnd: "請輸入屆滿日期",
    msgInputValidPeriodStartAndEnd: "生效日期應在屆滿日期之前",
    msgSelectRelationship: "請選擇代理關係",
    msgInputInterestsInProperty: "請輸入代理須披露權益",
    msgSelectPurchaserAcknowledgementOption: "請選擇買方的確認",
    msgSelectPaymentTime: "請選擇支付時間",
    msgSelectWithInterest: "請選擇連同利息/不連同利息",
    msgInputSignatoryInfoOrHKID: "請輸入香港身分證號碼或公司(代表)資料",
    msgInputHKID: "請輸入香港身分證號碼",
    msgInputAddress: "請輸入地址",
    msgInputPhoneNumber: "請輸入電話號碼",
    msgInputFaxNumber: "請輸入傳真號碼",
    msgInputDate: "請輸入日期",
    msgIputNameOfSignatoryOfPurchaser: "請輸入簽署人的姓名或名稱",
    msgPositionOfSignatoryOfPurchaser: "請輸入簽署人的職位",
    msgIputBusinessRegistrationCertificateNumberOfPurchaser:
      "請輸入買方的商業登記證號碼",
    msgIputNameAndLicenceNumberOfAgent: "請輸入簽署人的姓名或名稱及牌照號碼",
    msgIputNumberOfStatementOfParticularsOfBusiness:
      "請輸入代理的營業詳情說明書號碼",
    msgInputSchedul1Info: "請輸入所需資料",
    msgInputDateToInspect: "如非同意不視察物業, 請選擇視察日期",
    msgInputValidPhoneNumber: "請輸入有效的電話號碼",

    msgInputMissingFields: "請填妥以下資料",
  },
  langForm5: {
    formName: "出租香港住宅物業用的地產代理協議",

    //general
    HKDollar: "港幣$",
    pleaseInput: "請輸入",
    pleaseSelect: "請選擇",
    captionFormLocale: "表格語言",
    form: "表格",

    //part 1
    titlePart1: "代理的委任及本協議的有效期",
    landlordName: "業主",
    agentName: "代理",
    exclusiveAgent: "獨家代理",
    propertyAddress: "物業",
    validityPeriod: "有效期",
    validityPeriodStart: "生效日期",
    validityPeriodEnd: "屆滿日期",
    validityPeriodRemarks: "(首尾兩天包括在內)",

    //part 2
    titlePart2: "代理關係及代理的責任",
    relationship: "代理關係",
    singleAgency: "單邊代理",
    dualAgency: "雙邊代理",
    potentiallyDualAgency: "有可能代表雙方的代理",

    //part 3
    titlePart3: "放盤租金",
    listRental: "放盤租金",
    includeRatesAndMgtFee: "包括差餉及管理費",

    //part 6
    titlePart6: "視察物業",
    allowViewingSentence1: "業主同意容許",
    allowViewingSentence2: "於雙方議定的時間查看物業",
    agent: "代理",
    tenants: "租客",
    bothAgentAndPurchaser: "代理/租客",
    passKeyForSafeCustody:
      "業主同意將物業的鎖匙交由代理妥為保管， 以供查看物業之用",
    passKeyToThirdParty:
      "業主授權代理將鎖匙交予其他地產代理/人士， 以供查看物業之用",
    passKeyToThirdPartySentence1: "業主授權代理將鎖匙交予其他",
    passKeyToThirdPartySentence2: "， 以供查看物業之用",
    estateAgent: "地產代理",
    otherPerson: "人士",
    bothEstateAgentOrPerson: "地產代理/人士",

    //part 7
    titlePart7: "分銷放盤",
    allowSubListing:
      "業主授權代理將物業分銷放盤，並將由業主提供的關於業主及物業的資料交予其他地產代理以供分銷放盤之用",

    //part 8
    titlePart8: "廣告宣傳",
    allowAdvertisement: "業主授權代理發出關於物業的廣告",

    //part 9
    titlePart9: "代理須披露權益",
    disclosureOfInterestByAgent:
      "代表代理簽署本協議的人現披露︰ 他或其指明親屬， 或其任何代名人， 或其指明親屬的任何代名人，或代理或代理的任何僱員/大股東/合夥人/董事對物業擁有金錢上的或其他實益的權益",

    //part 10
    titlePart10: "業主的確認",
    landlordAcknowledgement1: "已收取本協議第5 條規定提供的出租資料表格",
    landlordAcknowledgement2:
      "已同意於業主與一名租客訂立具約束力的租契之前收取出租資料表格",

    //part 12
    titlePart12: "額外條款",
    extraTermsCautions:
      "[注意﹕這些額外條款不得與本協議的其他條款有所抵觸，亦不得限制本協議的其他條款。]：",

    //general info
    address: "地址",
    phoneNumber: "電話號碼",
    faxNumber: "傳真號碼",
    date: "日期",

    //schedule 2
    titleSchedule2: "附表 2",
    titleCommission: "業主須支付的佣金",
    commission: "佣金",
    commissionIn: "計算方式",
    amount: "金額",
    percentage: "成交租金的百份比",
    paymentTime: "支付時間",
    paymentTimeOption1: "簽署租契時",
    paymentTimeOption2: "租契指明的租賃期開始時",

    titleSchedulePoint2: "租契未能如期起租時還予賣方的佣金",
    withInterest: "連同利息",
    withoutInterest: "不連同利息",

    //landlord info
    landlordInfo: "業主資料",
    HKID: "香港身分證號碼",
    ifApplicable: "(如適用的話)",
    whereLandlordIsCompany: "如業主是一間公司，請述明：",
    nameOfSignatoryOfLandlord: "簽署人的姓名或名稱",
    positionOfSignatoryOfLandlord: "簽署人的職位",
    businessRegistrationCertificateNumberOfLandlord: "業主的商業登記證號碼",

    //agent info
    agentInfo: "代理資料",
    nameAndLicenceNumberOfAgent: "簽署人的姓名或名稱及牌照號碼",
    numberOfStatementOfParticularsOfBusiness: "代理的營業詳情說明書號碼",

    helperTextParticulars: "如“有”，請述明詳情",

    msgInputLandlordName: "請輸入業主名稱",
    msgInputAgentName: "請輸入代理名稱",
    msgInputPropertyAddress: "請輸入物業",
    msgInputValidityPeriodStart: "請輸入生效日期",
    msgInputvalidityPeriodEnd: "請輸入屆滿日期",
    msgInputValidPeriodStartAndEnd: "生效日期應在屆滿日期之前",
    msgSelectRelationship: "請選擇代理關係",
    msgInputListRental: "請輸入放盤租金",
    msgInputInterestsInProperty: "請輸入代理須披露權益",
    msgSelectLandlordAcknowledgementOption: "請選擇業主的確認",
    msgSelectCommissionIn: "請選擇計算方式",
    msgInputCommissionAmount: "請輸入金額",
    msgInputCommissionRate: "請輸入百份比",
    msgSelectPaymentTime: "請選擇支付時間",
    msgSelectWithInterest: "請選擇連同利息/不連同利息",
    msgInputHKID: "請輸入香港身分證號碼",
    msgInputAddress: "請輸入地址",
    msgInputPhoneNumber: "請輸入電話號碼",
    msgInputFaxNumber: "請輸入傳真號碼",
    msgInputDate: "請輸入日期",
    msgIputNameOfSignatoryOfLandlord: "請輸入簽署人的姓名或名稱",
    msgPositionOfSignatoryOfLandlord: "請輸入簽署人的職位",
    msgIputBusinessRegistrationCertificateNumberOfLandlord:
      "請輸入業主的商業登記證號碼",
    msgIputNameAndLicenceNumberOfAgent: "請輸入簽署人的姓名或名稱及牌照號碼",
    msgIputNumberOfStatementOfParticularsOfBusiness:
      "請輸入代理的營業詳情說明書號碼",
    msgInputValidPhoneNumber: "請輸入有效的電話號碼",
    msgInputSignatoryInfoOrHKID: "請輸入香港身分證號碼或公司(代表)資料",
    msgSelectAtLeastOneForPart6: "請選擇至少一個選項",

    msgInputMissingFields: "請填妥以下資料",
  },
  langForm6: {
    formName: "購買香港住宅物業用的地產代理協議",

    pleaseInput: "請輸入",
    captionFormLocale: "表格語言",
    pleaseSelect: "請選擇",
    form: "表格",

    //part 1
    titlePart1: "代理的委任及本協議的有效期",
    tenantName: "租客",
    agentName: "代理",
    validityPeriod: "有效期",
    validityPeriodStart: "生效日期",
    validityPeriodEnd: "屆滿日期",
    validityPeriodRemarks: "(首尾兩天包括在內)",

    //part 5
    titlePart5: "代理須披露權益",
    disclosureOfInterestByAgent:
      "代表代理簽署本協議的人現披露﹕他或其指明親屬，或其任何代名人，或其指明親屬的任何代名人，或代理或代理的任何僱員/大股東/合夥人/董事對物業擁有金錢上的或其他實益的權益",

    //part 9
    titlePart9: "額外條款",
    extraTermsCautions:
      "[注意﹕這些額外條款不得與本協議的其他條款有所抵觸，亦不得限制本協議的其他條款]:",

    //general info
    address: "地址",
    phoneNumber: "電話號碼",
    faxNumber: "傳真號碼",
    date: "日期",

    //tenant info
    tenantInfo: "租客資料",
    HKID: "香港身分證號碼",
    ifApplicable: "(如適用的話)",
    whereTenantIsCompany: "如租客是一間公司，請述明：",
    nameOfSignatoryOfTenant: "簽署人的姓名或名稱",
    positionOfSignatoryOfTenant: "簽署人的職位",
    businessRegistrationCertificateNumberOfTenant: "租客的商業登記證號碼",

    //agent info
    agentInfo: "代理資料",
    nameAndLicenceNumberOfAgent: "簽署人的姓名或名稱及牌照號碼",
    numberOfStatementOfParticularsOfBusiness: "代理的營業詳情說明書號碼",

    //schedule 1
    titleSchedule1: "附表 1",
    column1: "物業",
    column2: "視察日期",
    column3: "租客同意不視察物業",
    column4: "租客放棄收取出租資料表格的權利",
    column5: "代理關係",
    column6: "業主所須支付的佣金(如適用的話)的數額或收費率",
    column7: "租客所須支付的佣金的數額或收費率",

    property: "",
    dateOfInspection: "",
    notToInspect: "",
    waiveRight: "",
    agencyRelationShip: "",
    landlordCommissionAmountOrRate: "",
    agentCommissionAmountOrRate: "",

    singleAgency: "單邊代理",
    dualAgency: "雙邊代理",
    potentiallyDualAgency: "有可能代表雙方的代理",

    //schedule 2
    titleSchedule2: "附表 2",
    titleCommission: "租客須支付的佣金",
    paymentTime: "支付時間",
    paymentTimeOption1: "簽署買賣協議時",
    paymentTimeOption2: "買賣協議指明的物業交易完成時",

    titleSchedulePoint2: "租契未能如期起租時還予租客的佣金",
    withInterest: "連同利息",
    withoutInterest: "不連同利息",

    helperTextParticulars: "如“有”，請述明詳情",

    msgInputTenantName: "請輸入租客名稱",
    msgInputAgentName: "請輸入代理名稱",
    msgInputValidityPeriodStart: "請輸入生效日期",
    msgInputvalidityPeriodEnd: "請輸入屆滿日期",
    msgInputValidPeriodStartAndEnd: "生效日期應在屆滿日期之前",
    msgSelectRelationship: "請選擇代理關係",
    msgInputInterestsInProperty: "請輸入代理須披露權益",
    msgSelectTenantAcknowledgementOption: "請選擇租客的確認",
    msgSelectPaymentTime: "請選擇支付時間",
    msgSelectWithInterest: "請選擇連同利息/不連同利息",
    msgInputSignatoryInfoOrHKID: "請輸入香港身分證號碼或以下資料",
    msgInputHKID: "請輸入香港身分證號碼",
    msgInputAddress: "請輸入地址",
    msgInputPhoneNumber: "請輸入電話號碼",
    msgInputFaxNumber: "請輸入傳真號碼",
    msgInputDate: "請輸入日期",
    msgIputNameOfSignatoryOfTenant: "請輸入簽署人的姓名或名稱",
    msgPositionOfSignatoryOfTenant: "請輸入簽署人的職位",
    msgIputBusinessRegistrationCertificateNumberOfTenant:
      "請輸入租客的商業登記證號碼",
    msgIputNameAndLicenceNumberOfAgent: "請輸入簽署人的姓名或名稱及牌照號碼",
    msgIputNumberOfStatementOfParticularsOfBusiness:
      "請輸入代理的營業詳情說明書號碼",
    msgInputSchedul1Info: "請輸入所需資料",
    msgInputDateToInspect: "如非同意不視察物業, 請選擇視察日期",
    msgInputValidPhoneNumber: "請輸入有效的電話號碼",

    msgInputMissingFields: "請填妥以下資料",
  },
  langSalePurchaseAgreement: {
    captionAgreementLocale: "合約語言",

    formName: "物業臨時買賣合約",
    titleParties: "A. 合約三方",
    titleStatements: "B. 合約條款",

    titleAgreementDate: "合約訂立日期",
    date: "日期",

    titleVendor: "賣方",
    titlePurchaser: "買方",
    vendor: "賣家",
    purchaser: "買家",
    name: "名稱",
    identityType: "身份證明類別",
    identityNumber: "身份證明號碼",
    address: "地址",
    idCardImage: "身份證明副本",

    titleAgent: "代理",
    agentName: "名稱",
    agentBusinessRegNo: "商業登記證號碼",
    agentLicenceNo: "地產代理牌照(公司)號碼",
    agentAddress: "註冊地址",
    agentType: "代理人身份",
    agentTypeInternal: "本公司",
    agentTypeExternal: "非本公司",
    agentLicenseNumber: "牌照號碼",

    titleSellerAgent: "賣方代理人",
    sellerAgentName: "姓名",
    sellerAgentContact: "聯繫",
    sellerAgentHelpText:
      "若為本公司代理人, 該用戶將能夠進入本頁面填寫相關合約資料",

    titleBuyerAgent: "買方代理人",
    buyerAgentName: "姓名",
    buyerAgentContact: "聯繫",
    buyerAgentHelpText:
      "若為本公司代理人, 該用戶將能夠進入本頁面填寫相關合約資料. \n若為非本公司代理人, 合約應由該代理人準備",
    internalAgentHelpText:
      "若為本公司代理人, 該用戶將能夠進入本頁面填寫相關合約資料.",
    externalAgentHelpText: "若為非本公司代理人, 合約應由該代理人準備.",
    titlePremises: "物業",

    titleConsiderationPayment: "成交價及付款詳情",
    purchasePrice: "成交價",
    initialDeposit: "臨時訂金",
    formalAgreementSignDeadline: "簽署正式合約期限",
    fullDepositDate: "加付訂金期限",
    fullDeposit: "加付訂金",
    paymentCompletionDate: "完成交易期限",
    remainingSum: "樓價餘款",

    titleVacantPossessionTerm: "交吉條款",
    vacantPossessionTerm1: "買賣完成時，賣方須將該物業交吉予買方",
    vacantPossessionTerm2: "買方同意連同該物業現有之租約一起購入該物業",

    titleVendorAsConfirmor: "確認人",
    vendorIsSellingAsConfirmor: "賣方是以確認人身份售出該物業",
    titleSolicitor: "代表律師",
    vendorSolicitor: "賣方代表律師",
    purchaserSolicitor: "買方代表律師",

    titleAgentCommission: "代理佣金",
    vendorCommission: "賣方繳付佣金",
    purchaserCommission: "買方繳付佣金",
    commissionPaymentDeadline: "佣金繳付期限",

    titleCompensationToAgent: "賠償予代理",
    compensation: "賠償金額",

    residentialOrNot: "物業用途",
    residential: "住宅",
    nonResidential: "非住宅",

    titleAgentAppointment: "委任代理",
    agentFor: "代理身份",
    // both: '買賣雙方代表',
    // vendorOnly: '賣方代表',
    // purchaserOnly: '買方代表',

    titlePrevailingVersion: "合約詮釋",
    version: "合約文本以此語言為準",

    certificateOfValue: "總價值 (不超過)",
    remarks: "備註",

    titleInitialDepositPaymentMethod: "臨時訂金付款方式",
    cash: "現金",
    cheque: "支票",
    chequeNo: "支票號碼",
    chequeBank: "支票發行銀行",
    supplementalDoc: "C. 補充文件",

    //messages
    pleaseSelect: "請選擇",
    pleaseInput: "請輸入",
    msgAtLeastOneVendor: "請輸入至少一個賣家",
    msgAtLeastOnePurchaser: "請輸入至少一個買家",
    msgInputRequiredInfo: "請輸入所需資料",
    msgInvalidRemainingSum: "臨時訂金及加付訂金的總和不能大於成交價",
    msgInvalidBuyerOrSellerAgentType:
      "其中一個賣方代理或買方代理須是本公司代理",
    msgInvalidBuyerOrSellerAgent: "其中一個賣方代理或買方代理須是上載者",
  },
  langTenancyAgreement: {
    captionAgreementLocale: "合約語言",

    formName: "物業臨時租賃合約",
    titleParties: "A. 合約三方",
    titleStatements: "B. 合約條款",

    titleAgreementDate: "合約訂立日期",
    date: "日期",

    titleLandlord: "業主",
    titleTenant: "租方",
    landlord: "業主",
    tenant: "租客",
    name: "名稱",
    identityType: "身份證明類別",
    identityNumber: "身份證明號碼",
    address: "地址",
    idCardImage: "身份證明副本",

    titleAgent: "代理",
    agentName: "名稱",
    agentBusinessRegNo: "商業登記證號碼",
    agentLicenceNo: "地產代理牌照(公司)號碼",
    agentAddress: "註冊地址",
    agentType: "代理人身份",
    agentTypeInternal: "本公司",
    agentTypeExternal: "非本公司",
    agentLicenseNumber: "牌照號碼",

    titleLandlordAgent: "業主代理人",
    landlordAgentName: "姓名",
    landlordAgentContact: "聯繫",
    landlordAgentHelpText:
      "填寫業主代理人以讓該用家能進入本頁面填寫相關合約資料",

    titleTenantAgent: "租方代理人",
    tenantAgentName: "姓名",
    tenantAgentContact: "聯繫",
    tenantAgentHelpText: "填寫租方代理人以讓該用家能進入本頁面填寫相關合約資料",
    internalAgentHelpText:
      "若為本公司代理人, 該用戶將能夠進入本頁面填寫相關合約資料.",
    externalAgentHelpText: "若為非本公司代理人, 合約應由該代理人準備.",
    titlePremises: "物業地址",

    titleMonthlyRental: "每月租金",
    rental: "租金",
    inclusive: "包括地租、差餉及管理費",

    titleDeposit: "按金",
    titleInitialDeposit: "初步訂金",

    titleTenancy: "租期",
    // tenancy: 'Tenancy',
    from: "起租日期",
    to: "完租日期",

    titleBreakClause: "退租權",
    monthInAdvance: "租方可給予業主不少於以下月數的書面通知提早解除此租約:",
    rentInLieu: "租方可給予業主不少於以下月數的租金作代通知金提早解除此租約:",
    rentForAtleast: "此租約的終止日期不得早於由租期開始日起計的以下月數:",

    titleVacantPossessionDelivery: "交吉期限",
    titleDeliveryBefore: "業主須於以下日期或之前將物業交吉予租方",

    titlePremisesUsage: "物業用途",

    titleSigning: "簽署正式合約",
    formalAgreementSignDeadline: "簽署正式租賃合約期限",
    signAt: "雙方須於",
    solicitorOffice: "簽署正式租賃合約",

    titleAgentCommission: "代理佣金",
    landlordCommission: "業主繳付佣金",
    tenantCommission: "租方繳付佣金",
    commissionPaymentDeadline: "佣金繳付期限",

    titleCompensationToAgent: "賠償予代理",
    compensation: "賠償金額",

    titleAgentAppointment: "委任代理",
    agentFor: "代理身份",
    // both: 'Both',
    // vendorOnly: 'Vendor Only',
    // purchaserOnly: 'Purchaser Only',

    titlePrevailingVersion: "合約詮釋",
    version: "合約文本以此語言為準",

    remarks: "備註",

    titleInitialDepositPaymentMethod: "初步訂金付款方式",
    cash: "現金",
    cheque: "支票",
    chequeNo: "支票號碼",
    chequeBank: "支票發行銀行",
    supplementalDoc: "C. 補充文件",

    pleaseSelect: "請選擇",
    pleaseInput: "請輸入",
    msgAtLeastOneLandlord: "請輸入至少一個業主",
    msgAtLeastOneTenant: "請輸入至少一個租客",
    msgInputRequiredInfo: "請輸入所需資料",
    msgInvalidTenantOrLandlordAgentType:
      "其中一個業主代理或租方代理須是本公司代理",
  },
  langSignDialog: {
    actionSign: "簽署",
    actionClear: "清除",
    actionDownloadDoc: "下載檔案",
    actionGoBack: "返回",
    actionConfirmAndSign: "確認並簽署",
    actionSendCopyToClient: "發送",
    actionSendEmail: "發送電郵",
    actionInviteClientToSign: "發送電郵邀請客戶簽署",
    actionGenerateSigningLink: "生成簽署連結",
    actionVerifyContact: "驗證 Verify",
    actionClientSignNow: "客戶立即簽署",
    actionGenerateClientSigningLink: "生成客戶簽署連結",
    actionPreviewForm: "預覽表格",
    actionDocumentPreview: "文件預覽",
    actionCopyLink: "複製連結",

    captionStepSignByAgent: "代理簽署",
    captionStepSignByClient: "客戶簽署",
    captionStepInviteClientToSign: "邀請客戶簽署",
    captionStepDone: "完成",
    captionStepVerification: "驗證身份",
    captionStepSignForm: "簽署表格",
    captionFormType: "表格類別",

    captionAgentSigned: "代理已簽署",
    captionWaitForClientSign: "等待客戶簽署",
    captionClientSigned: "代理及客戶已簽署",

    captionClient: "客戶",
    captionProperty: "樓盤",
    captionVendor: "客戶",
    captionAgent: "代理",
    captionIdentificationDocNo: "身份證明文件號碼",
    captionClientEmail: "客戶電郵",
    captionClientVerificationContact: "客戶聯繫(驗證客戶身份用)",
    captionEmail: "電郵",

    titleGenerated: "表格簽署完成",
    titleGeneratedEn: "Document Signed",
    titleAlreadySigned: "表格已簽署",
    titleClientSign: "客戶表格簽署 Form Signing by Client",

    msgInputVerificationContact: "請輸入客戶聯繫以驗證身份",
    msgInputEmailToSendCopy: "請輸入客戶電郵以發送副本",
    msgSigningTips: "請於下方黃色區域簽署",
    msgGenerating: "正在生成簽署表格... 請勿退出當前頁面",
    msgGenerated: "表格已通過電郵發送給各簽署方。",
    msgSentSuccess: "已成功發送電郵",
    msgClientConfirm: "請客戶簽名確認 Client, please sign to confirm",
    msgVerifying: "驗證中... 請勿退出當前頁面",
    msgPleaseWait: "請等候...",
    msgInvalidVerificationContact: "客戶聯繫不符, 請重新輸入",
    msgSuccessAgentSign:
      "代理人簽署完成, 請填寫以下資料以透過電郵邀請客戶簽署表格。",
    msgPleaseInput: "請輸入",
    msgWarnSignedFormExist:
      "有一份已簽署並且有效期重叠的表格存在, 確定繼續簽署?",
    msgWarnSignedFormExistForForm46:
      "有一份已簽署、有效期重叠並且部分附表一物業相同的表格存在, 確定繼續簽署?",
    msgWarnInvitationSentAlready: "已邀請客戶簽署表格, 確定繼續簽署?",
    msgWarnLinkExpiry: "請注意此連結將於%s小時後失效",
    msgSuccessClientSignByEmail:
      "客戶表格簽署完成! 閣下將會收到附上已簽署表格的電郵以供參考。謝謝!",
    msgSignAreaTitle: "%s: 請於下方黃色區域簽署",
    msgSigningTipsShort: "簽名請盡量佔滿黃色區域",

    msgSuccessAgentSignAndSelect: "代理人簽署完成! 請選擇下一步以繼續簽署流程:",
    msgSuccessInvitationSent:
      '客戶簽署邀請電郵已發送! 按"結束"可返回樓盤詳情頁面',
    msgSuccessLinkGenerated:
      '客戶簽署連結成功生成! 連結已顯示在下方的文字框，客戶可以該連結訪問表格簽署頁面。\n按"結束"可返回樓盤詳情頁面。',
    msgSuccessClientSign: '客戶簽署完成!  按"結束"可返回樓盤詳情頁面。',
    msgWarningSignByUrl:
      "請注意: 離開此頁面後將無法再存取簽署連結，若要再取得連結，你將需要重頭開始簽署程序以生成新的連結。\n\n確認離開本頁面?",
    msgSuccessClientContactVerfification:
      "成功驗証身份! 請輸入您的身份證明文件號碼以繼續簽署流程:",
    msgSuccessClientSignByLink: "客戶表格簽署完成! 謝謝!",
    msgInputEmailToReceiveCopy:
      "若您希望收到已簽署表格的副本，您可以在下方輸入您的電郵地址:",
    msgSuccessSentCopyToClient:
      "已簽署的表格已成功發送至您提供的電郵地址。謝謝!",
    msgNonHKID: "非香港身份證號碼!",
    msgFormatForHKID: "香港身份證號碼格式: A123456(7)",
    msgSending: "發送中...",
    msgSuccessLinkCopied: "已複製連結",
    msgClientAlreadySigned: "表格已簽署, 不能重複簽署",

    msgWarnOverwrite:
      "已邀請客戶簽署表格, 此動作會更改表格內容並會令客戶的簽署連結失效,  確定繼續?",
    msgLinkExpired: "連結已失效",
  },
  identityTypeOptions: {
    H: "香港身份證",
    B: "商業登記證號碼",
  },
  agentAppointmentOptionsSale: {
    BOTH: "買賣雙方代表",
    VENDOR_ONLY: "賣方代表",
    PURCHASER_ONLY: "買方代表",
  },
  agentAppointmentOptionsTenancy: {
    BOTH: "雙方代表",
    LANDLORD_ONLY: "業主代表",
    TENANT_ONLY: "租方代表",
  },
  prevailingVersionOptions: {
    ENGLISH: "英文",
    CHINESE: "中文",
  },
  yesNoOptions: {
    Y: "有",
    N: "沒有",
    U: "不知道",
  },
  dormancyOptions: {
    D: "同地區代理",
    A: "所有代理",
    N: "無 (無客戶樓盤)",
  },
  langClientDetail: {
    //action
    actionNewClient: "新增客戶",
    actionRemoveClient: "移除客戶",
    actionStartMatching: "開始對盤",
    actionClaimClient: "認領",
    actionForm: "填寫表格",
    actionUploadForms: "上載表格...",
    actionFillForm4: "填寫表格4",
    actionFillForm6: "填寫表格6",
    actionForm4: "表格4",
    actionForm6: "表格6",
    actionGeneratePropertySummaryReport: "生成樓盤概要",
    actionGenerate: "生成",
    actionAssignAgent: "委任代理人",
    actionReassignAgent: "重新委任代理",
    actionRemoveAgent: "移除代理委任",

    //title
    titleNewClientDetail: "新增客戶",
    titleClientInfo: "檢視客戶資料",
    titleViewClientDetail: "客戶詳情",
    titleBasicInfo: "基本資料",
    titleSellerStock: "樓盤資料",
    titlePreference: "買/租樓偏好",
    titleWishlist: "睇樓清單",
    titleUploadForms: "檢視文件",
    titleSummary: "客戶概述",
    titleGeneratePropertySummaryReport: "生成樓盤概要",
    titleForm: "表格",

    //Message
    msgOnlyForBuyerOrTenant: "僅適用於買家或租客",
    msgPleaseEnterChinOrEngName: "請至少輸入中文名稱或英文名稱",
    msgPleaseEnterClientName: "請輸入客戶名稱",
    msgPleaseEnterRemarksHere: "輸入備註...",
    msgAtLeastOneContact: "請輸入至少一個聯繋",
    msgInputAgent: "請選擇代理",
    msgInputSource: "請輸入客戶來源",
    msgInputClientType: "請輸入客戶類型",
    msgInputUsage: "請輸入樓盤用途",
    msgInputDistrict: "請輸入地區",
    msgInputNet: "請輸入實用面積",
    msgInputPrice: "請輸入售價",
    msgInputRent: "請輸入租金",
    msgInputValidPhoneNumber: "請輸入有效的電話號碼",
    msgInputValidEmail: "請輸入有效的電郵",
    msgConfirmRemoveAgent: "確認移除代理委任?",
    msgConfirmClaimClient: "確認認領此客戶?",
    msgConfirmDeleteSelected: "確認刪除所選？",
    msgNoOtherContacts: "沒有其他聯繫",
    msgConfirmRemoveClient: "確認移除客戶？",
    msgWarningCreateDormantClient:
      "此客戶並沒有被委任代理, 儲存後, 此客戶將開放予相同地區代理認領。",
    msgSelectDistrictForDormantClient:
      "此客戶並沒有被委任代理, 請選擇所開放予代理認領的地區, 否則此客戶將開放予所有代理認領: ",
    msgConfirmDistrictForDormantClient:
      "此客戶並沒有被委任代理, 請確認所開放予代理認領的地區: ",
    msgPleaseSelect: "請選擇",
    msgConfirmRemoveContact: "確定刪除這聯繫方式?",
    msgPleaseInputContact: "請輸入聯繋",
    msgForm4ForSalesPropertyStockOnly: "表格4只適用於放售的樓盤",
    msgForm6ForRentPropertyStockOnly: "表格6只適用於放租的樓盤",
    msgPleaseSaveBeforeMatching: "確定要先儲存客戶詳情再進行對盤 ?",
    msgNotGrantedWishlistItem:
      "您尚未獲得此樓盤的睇樓授權，請聯絡經手代理人取得睇樓授權。",
    msgDuplicatedContact: "經手客戶或公司客戶中已存在相同的客戶聯繫。",
    msgDuplicatedContactOnSave:
      "經手客戶或公司客戶中已存在相同的客戶聯繫,確定儲存?",
    msgMergeClientHavingContact: "你剛才認領的客戶 %s 有以下的聯絡方式:",
    msgMergeClientChoosingToMerge:
      "以下現有客戶與上述客戶有至少一個相同聯絡方式，你可以選擇將上述客戶的資料合入其中一個客戶:",
    actionCancelRetainClaimedClient: "取消(保留認領的客戶)",
    msgConfirmToMerge: "確認合入客戶檔案？",
    msgConfirmNotToMerge:
      "確認取消合入客戶檔案？將保留認領的客戶為獨立的客戶檔案",
    msgSaveBeoreUploading:
      "確認保存客戶檔案？請在上傳新表格4 / 表格6前先保存客戶檔案。",
    //basic info
    clientStatus: "狀態",
    companyClient: "公司客戶",
    clientNo: "客戶編號",
    clientName: "客戶名稱",
    chineseName: "中文姓名",
    englishName: "英文姓名",
    contactNoOrEmail: "聯繫",
    sourceOfClient: "客戶來源",
    agent: "代理人",
    remarks: "備註",

    //contact info
    clientType: "客戶類型",
    contactType: "聯繫方式",
    contactName: "姓名",
    contactRemarks: "備註",
    agentsContactRemarks1: "(代理",
    agentsContactRemarks2: ")",
    otherContacts: "其他聯繫",

    //address
    adderss: "地址",
    address1: "座/樓/室 ",
    address2: "大廈  ",
    address3: "街道/屋苑",
    address4: "地區",

    //client preference
    captionOthers: "其他",
    reason: "原因",
    attitude: "態度",
    propertyStatus: "樓盤狀態",

    //client search/client list
    propertyUsage: "樓盤用途",
    clientSearch: "客戶搜尋",
    canClaimBy: "開放認領予",
    dormantClients: "閒置客戶",
    nonDormantClients: "非閒置客戶",
    ownClients: "經手客戶",
    phoneOrEmail: "電話/電郵",

    //property summary report
    selectLanguageTitle: "請選擇概要語言",
    traditionalChinese: "英文 + 繁體中文",
    simplifiedChinese: "英文 + 簡體中文",

    //filter stock cart
    actionFilter: "篩選",
    captionBuyPropertyStock: "買盤",
    captionRentPropertyStock: "租盤",

    captionInterestedProperties: "心水樓盤: ",
  },
  langMemoEnquiry: {
    titleMemoEnquiry: "備忘查閱",
    captionBasicInfo: "基本資料",
    type: "備忘類型",
    username: "用戶名",
    dateCreated: "新增時間",
    user: "用戶",
    content: "內容",
    id: "備忘編號",
    clientName: "客戶名稱",
    property: "物業",
  },
  langNotification: {
    titleNotificationsCentre: "通知中心",
    captionFilter: "篩選",
    captionModule: "訊息類別",
    captionType: "訊息類別",
    captionAction: "操作",
    actionMarkAsRead: "標示為已讀",
    actionMarkAsUnread: "標示為未讀",
    captionAllRead: "全部狀態",
    captionAllModule: "全部類別",
    captionUnread: "未讀",
    captionNotYetProcessed: "未處理",
    navPropertyStock: "前往樓盤...",
    navSalesCommissionReport: "檢視報告...",
    viewPropertyStock: "檢視樓盤...",
    navClient: "前往客戶...",
    viewClient: "檢視客戶...",
    captionClaimed: "(已認領)",
    captionProcessed: "(已處理)",
    captionImpersonationEnded: "(已完結)",
    actionDone: "處理",
    actionRead: "標示為已讀/未讀",
    actionDelete: "刪除",
    ALL: "全部",
    actionLandSearchDone: "完成土地查冊",
    actionLandSearchCancel: "取消土地查冊",
  },
  notificationTypeOptions: {
    BUILDING_MASTER_CREATION_REQUEST: "大廈檔案",
    PROPERTY_STOCK: "樓盤",
    FORM_SIGNING: "表格簽署",
    PROPERTY_STOCK_DORMANCY: "更新樓盤",
    CLIENT_DORMANCY: "更新客戶",
    CLAIM_PROPERTY_STOCK: "認領樓盤",
    CLAIM_CLIENT: "認領客戶",
    IMPERSONATION: "扮演",
    TRNX_DATAFEED: "後台系統工作", //成交紀錄 -> 後台系統工作
  },
  sourceOfClientOptions: {
    A: "中介",
    C: "冷叫",
    D: "派卡/傳單",
    E: "公海客",
    F: "傳真",
    H: "擺街檔",
    I: "網上/電郵",
  },

  langUser: {
    actionNewUser: "新增帳戶",
    actionSetAsDefault: "設為預置",
    actionDefault: "預置",
    actionChangePassword: "修改密碼",
    actionGotoDetail: "前往詳情",
    actionSearch: "搜尋",
    actionUploadPhoto: "上載相片",

    titleUserDetail: "用戶設定",
    titleBasicInfo: "基本資料",
    titleContacts: "聯繋",
    titleChangePassword: "修改密碼",
    titlePageHeading: "帳戶列表",
    titleUserPreference: "用戶偏好",

    msgUsernameRequired: "請輸入帳戶名",
    msgPasswordRequired: "請輸入密碼",
    msgSelectAtLeastOneRole: "請指定至少一種帳戶角色",
    msgAtLeastOneContact: "請輸入至少一個聯繋",
    msgEmailRequired: "請輸入電郵",
    msgInputValidEmail: "請輸入有效的電郵",
    msgPasswordMismatched: "舊密碼不正確",
    msgDuplicatedUsername: "帳戶號碼已被使用",
    msgPreviousPasswordUsed: "新密碼不可與舊密碼相同",
    msgPasswordFailComplexityRequirement: "新密碼必須符合設定要求",
    msgNoEmailForResetPw: "請至少設置一個電郵聯繫以重設密碼",
    msgConfirmToResetPw: "確認重設密碼？",
    msgConfirmToUnlock: "確認解鎖帳戶？",
    msgNoNeedToUnlock: "毋須解鎖",
    msgPleaseEnterChinOrEngName: "請至少輸入中文姓名或英文姓名",
    msgPleaseInput: "請輸入",
    msgNewPasswordMismatch: "輸入的新密碼不一致",
    msgSelectStatus: "請選擇狀態",
    msgInputLicenseNumber: "請輸入牌照號碼",
    msgUserNoFound: "找不到對應用戶",
    msgInputTeamName: "請輸入團隊名稱",

    //forgot password
    msgPleaseWait: "請等候...",
    msgChangePwSuccess: "成功修改密碼",
    msgProcessing: "正在修改密碼, 請勿退出當前頁面",
    msgLinkExpired: "連結已失效",

    captionOperations: "操作",
    captionResetPassword: "重設密碼",
    captionUnlock: "解鎖",

    newPassword: "新密碼",
    oldPassword: "舊密碼",
    confirmNewPassword: "確認新密碼",
    name: "姓名",
    username: "帳戶名",
    password: "密碼",
    chineseName: "姓名(中文)",
    englishName: "姓名(英文)",
    salutation: "稱呼",
    licenseNumber: "牌照號碼",
    email: "電郵",
    teamName: "團隊名稱",
    title: "職位",
    teamLead: "所屬團隊",
    district: "地區",
    actAsTeamHead: "代理團隊",
    actAsTeamHeadYes: "團隊領袖",
    actAsTeamHeadNo: "團隊成員",
    roles: "角色",
    status: "狀態",
    teamMemberCount: "團隊人數",
    cannotSwitchTeamHead: "團隊有成員，不能更改領袖",
    otherContacts: "其他聯繫",
    contact: "聯繫",
    displayLanguage: "顯示語言",
    photo: "相片",
    landSearchCredit: "土地查册次数",

    //password complexity
    pwMinLength: "密碼長度最少為",
    pwAtLeast: "包含最少",
    pwUnit: "個",
    pwLowercaseChar: "小寫字母",
    pwUppercaseChar: "大寫字母",
    pwSpecialChar: "特殊符號",
    pwNumber: "數字",

    //user preference
    propertyStockColumnPreference: "樓盤欄偏好",
    clientColumnPreference: "客戶欄偏好",
    transactionColumnPreference: "成交欄偏好",
    columnPreferenceHelperText: "請拖動你要排列的項目",
    rowsCountPreference: "每頁列數偏好",
    rowsCount: "每頁列數",
    client: "客戶",
    propertyStock: "樓盤",
    transaction: "成交",
    building: "大廈檔案",
    memoEnquiry: "備忘查閱",
    salesCommissionReport: "銷售及分佣報告",
    notificationCenter: "通知中心",
    globalPreference: "全部列表",
    captionShowIndividualSetting: "調整個別列表偏好",

    //impersonation
    actionImpersonationRequest: "請求扮演另一用家",
    actionAuthorize: "授權",
    impersonating: "%s正在扮演%s",
    titleAuthorizeImpersonation: "授權扮演",
    actionStartImpersonation: "開始扮演",
    actionStopImpersonation: "終止授權",
    captionYourPassword: "你的密碼",
    captionAlreadyAuthorized: "已授權",
    msgInputPasswordToConfirmIdentity: "請輸入你的登入密碼以確認身份",
    msgImpersonationSessionExpired: "扮演時段已失效",
    msgIncorrectPassword: "密碼不正確",
    msgAuthorizeImpersonation:
      "在你授權扮演後, %s將會以你的帳戶登入, 以及看到你的客戶或(和)樓盤資料。\n\n確認核准此扮演的請求?",
    msgActiveImpersonationSessionExists:
      "此用家已授權你扮演並且此授權仍然有效，因此不能提交新的授權請求",
    msgSearchUserToImpersonate: "輸入用戶名稱關鍵字以尋找用戶",
    msgRequestSentSuccessfully: "已成功提交扮演請求",
    msgConfirmStopImpersonation: "確認終止扮演授權?",
    msgThirdPartyKeyboard:
      "使用第三方鍵盤可能有潛在安全風險,強烈建議使用設備預設的鍵盤.",
  },
  langRolePrivilege: {
    titlePageHeading: "角色列表",
    titleRoleBasicInfo: "基本資料",
    titlePrivilegeSetting: "權限設定",

    actionNewRole: "新增角色",

    captionRoleName: "角色代號",
    captionDescriptionTC: "簡介(繁)",
    captionDescriptionSC: "簡介(簡)",
    captionDescriptionEN: "簡介(英)",
    captionDataStatus: "狀態",

    msgPleaseInput: "請輸入",
    msgPleaseSelect: "請選擇",
    msgDuplicatedRoleName: "角色代號不能重複",
    msgInactivateRoleForbidden: '已有用戶屬於此角色, 因此不能將狀態設為"停用"',
  },
  langSystemSetting: {
    titlePageHeading: "系統設定列表",
    titleSystemSettingDetailDialog: "系統設定",

    actionNewSystemSetting: "新增系統設定",

    id: "編號",
    module: "組件",
    type: "類型",
    key: "鍵",
    value: "值",
    dataStatus: "狀態",
    description: "簡介",
    seq: "排序",

    msgPleaseInput: "請輸入",
    msgPleaseSelect: "請選擇",
    msgDuplicatedSystemSetting: "已有一個相同組件、類型、鍵和值的紀錄存在",
    msgVersionUpdate: "版本更新",
    msgUpdateVersion: "我們已釋出新的版本，「請按下 OK 重新加載所有頁面」。",
    msgUpdateAppVersion: "我們已釋出新的版本，「請按下 OK 前往更新」。",
  },
  langActivityAuditing: {
    titleActivityAuditingList: "使用紀錄",

    dateCreated: "新增時間",
    username: "帳戶",
    module: "組件",
    subModule: "功能",
    action: "操作",
    enterAt: "進入時間",
    exitAt: "退出時間",
    ipAddress: "IP",
    objectId: "檔案編號",
    propertyAddress: "樓盤地址",
    clientName: "客戶名稱",
    captionCheckIpLocation: "檢視IP地址",
    impersonatedBy: "(由%s扮演)",
  },
  langFeatureTagMgt: {
    titlePageHeading: "主頁精選標籤列表",

    captionTagNameEN: "標籤名稱(英)",
    captionTagNameTC: "標籤名稱(繁)",
    captionTagNameSC: "標籤名稱(簡)",
    captionCoverPhoto: "封面照片",

    msgConfirmRemoveTag: "確認刪除?",
    msgWarnExceedVisibleTagsLimit: "設定為顯示的標籤不能多於4個",
    msgInputAtLeaseOneTagName: "請輸入至少一個標籤名稱",
    msgInputAtLeaseOnePropertyFeature: "請選擇至少一個樓盤特色",
  },
  langAgentRatingCommentMgt: {
    titlePageHeading: "物業代理評分管理",

    actionApprove: "通過審批",
    actionReject: "未能通過審批",
    captionAction: "操作",

    captionAgentName: "代理姓名",
    captionCustomerName: "客戶姓名",
    captionReviewStatus: "審批狀態",
    captionSubmitDate: "提交時間",
    captionNoComment: "沒有評語",

    msgConfirmApprove: "確認通過此評分及評語之審核?",
    msgConfirmReject: "確認未能通過此評分及評語之審核?",
    msgCanOnlyHandlePendingAgentRatingComment: "只能處理未審批的評分及評語",
    msgApprovedBy: "由%s審批通過",
    msgRejectedBy: "由%s拒絕審批通過",
  },
  reviewStatusOptions: {
    PENDING: "未審批",
    APPROVED: "已通過審批",
    REJECTED: "未能通過審批",
  },
  langDashboard: {
    welcomeTitle: "儀表板",
    titleConsole: "版面控制台",
    periodFrom: "從...期間",
    periodTo: "到...期間",
    region: "地區",
    all: "所有",
    hki: "香港島",
    kln: "九龍",
    nt: "新界",
    district: "地區",
    districtTotal: "地區總數",
    districtTotalHki: "香港島總數",
    districtTotalKln: "九龍總數",
    districtTotalNt: "新界總數",
    ownTotal: "自己總計",
    amount: "金額 ($)",
    numberOfVisit: "睇樓次數",
    numberOfNewClients: "新客戶數量",
    numberOfClientUpdates: "更新客戶次數",
    numberOfNewProperties: "新樓盤數量",
    numberOfpropertyUpdates: "更新樓盤次數",
    titleTopNAndOwnDistrict: "(前 %d 個及自己所屬地區)",
    titleTotalCommission: "總銷售佣金",
    titleClientPropertyVisits: "總睇樓次數",
    titleNewClients: "新客戶總數",
    titleNewClientUpdates: "更新客戶總數",
    titleNewProperties: "新樓盤總數",
    titleNewPropertyUpdates: "更新樓盤總數",
    optionNewClients: "新客戶總數",
    optionClientUpdates: "更新客戶總數",
    optionNewProperties: "新樓盤總數",
    optionPropertyUpdates: "更新樓盤總數",
    optionTotalCommission: "總銷售佣金",
    optionClientPropertyVisits: "總睇樓次數",
    dialogTitleHidden: "隱藏",
    dialogTitleShown: "顯示",

    captionLastModified: "最後更新",
  },
  langJobMonitor: {
    titleJobMonitor: "系統排程工作",
    captionLastStartTime: "最後開始於",
    msgRunningLongerThanExpected: "(運行用時超過預期)",
    captionLastEndTime: "最後結束於",
    msgStartedExpectedly: "(如期開始)",
    msgJobMissed: "(上一次工作沒有如期開始)",

    actionFailed: "失敗",
    actionDone: "完成",
    actionRunning: "進行中",
  },

  rowsCountPrefOptions: {
    "10": "10",
    "25": "25",
    "50": "50",
    "100": "100",
  },

  activityAuditingModuleOptions: {
    PROPERTY_STOCK: "樓盤檔案",
    CLIENT: "客戶檔案",
    BUILDING: "大廈檔案",
    TRANSACTION: "成交",
    ACCOUNT: "帳戶",
  },
  statusOptions: {
    A: "啟用",
    I: "停用",
  },
  facingOptions: {
    E: "東",
    S: "南",
    W: "西",
    N: "北",
    SE: "東南",
    NE: "東北",
    SW: "西南",
    NW: "西北",
  },
  viewOptions: {
    GARDEN: "園景",
    MOUNTAIN: "山景",
    POLL: "泳池景",
    FULL_SEA: "全海景",
    LESS_SEA: "少海景",
    SEA: "海景",
    RACECOURSE: "馬場景",
  },
  decoOptions: {
    OLD: "舊裝修",
    INCOMPLETE: "殘裝修",
    GENERAL: "一般裝修",
    LUXURY: "豪華裝修",
    PRETTY: "靚裝修",
    TASTE: "品味設計裝修",
  },
  otherFeatureOptions: {
    PLATFORM: "平台",
    PRIVATE_GARDEN: "私人花園",
    PRIVATE_POOL: "私人泳池",
    CLUB_HOUSE: "住客會所",
    GARAGE: "車房",
    DOUBLE_UNIT: "雙單位",
    COMPANY_TRANSFER: "公司名義轉讓",
  },
  districtOptions: {
    HQB: "鰂魚涌",
    HNP: "北角",
  },
  propertyStockStatusOptions: {
    RENT: "租",
    SALES: "售",
    LEASED: "已租",
    SOLD: "已售",
    BOTH: "租售",
    PENDING: "待確認",
    UNKNOWN: "未知",
  },
  transactionStatusOptions: {
    LEASED: "已租",
    SOLD: "已售",
    PENDING: "封盤",
  },
  salesCommissionTypeOptions: {
    SALE: "售",
    RENTAL: "租",
  },
  targetOptions: {
    V: "買",
    L: "租",
  },
  usageOptions: {
    R: "住宅",
    S: "店舖",
    O: "寫字樓",
    I: "工商廈",
    M: "雜地",
    P: "樓花",
    V: "村屋",
    G: "政府大樓",
  },
  levelOptions: {
    HIGH: "高層",
    MIDDLE: "中層",
    LOW: "低層",
  },
  vacantOptions: {
    VACANT: "交吉",
    IN_USE: "用梗(租客)",
    AVAILABLE: "隨吉(自用)",
    BLANK: "已吉",
    WITH_CONTRACT: "連租約",
  },
  symbolOptions: {
    CONFIRMOR: "確認人",
    TEST: "試盤",
    SOLE_AGENT: "獨家",
    SHELL_COMPANY: "賣公司",
  },
  mgtFeeInclOptions: {
    A: "冷氣費",
    M: "管理費",
    AM: "冷氣連管理費",
    AMC: "冷氣，管理包室內清潔",
  },
  clientTypeOptions: {
    B: "買家",
    L: "業主",
    S: "賣家",
    T: "租客",
  },

  formLocaleOptions: {
    zh_HK: "繁體中文",
    en: "英文",
  },
  clientFormSelections: {
    select: "選取",
    selectBuyerAndForm4: "請選擇本次成交的買家客戶檔案和表格4",
    selectTenantAndForm6: "請選擇本次成交的租方客戶檔案和表格6",
    linkBuyerInfo: "關聯買家客戶檔案",
    linkTenantInfo: "關聯租方客戶檔案",
    buyerInfoLinked: "買家客戶檔案已關聯",
    tenantInfoLinked: "租方客戶檔案已關聯",
  },
};

export default zhHK;