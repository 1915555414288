import {
    Divider, Link, Theme,
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clientApi from 'api/clientApi';
import propertyApi from 'api/propertyApi';
import { hasPermission } from 'common/access-control';
import { useConfirmDialog } from 'common/ConfirmDialog';
import { NotificationDTO } from 'common/dto';
import { genAddress, handlePriceDisplay, handlePriceDisplayUnit, multiLang } from 'common/utils';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IRootState } from 'reducers';

const PUBLIC_URL = process.env['PUBLIC_URL'];
const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];


const useStyles = makeStyles((theme: Theme) => ({
    root: {
      // boxShadow: 'none',
      background: '#fff',
      color: '#000',
    },
    flexGrow: {
      flexGrow: 1
    },
	claimDialogTitle: {
		paddingBottom: '5px',
		textTransform: 'uppercase',
		fontWeight: 'bold'
	},
	claimDialogTitleDivider: {
		marginBottom: '20px'
	},
	claimDialogItemHeading: {
		fontSize: 'small'
	},
	claimDialogItemContent: {
		fontSize: 'medium',
		fontWeight: 'bold',
		paddingTop: '5px',
		marginBottom: '10px',
	},
	claimDialogSizeSuffix: {
		fontSize: 'x-small'
	},
	messageIconButton:{
		padding: '3px',
		margin: '0px 6px'
	}
}));

interface NotificationLinkProps {
	notification: NotificationDTO;
	confirmDialog: ReturnType<typeof useConfirmDialog>;
}

const NotificationLink = (props: NotificationLinkProps) => {
	const { notification, confirmDialog } = props;
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const { langUser, locale, lang, langClientDetail, langNotification, langPropertyStock, sourceOfClientOptions,
    districtNtOptions, districtHkiOptions, districtKltOptions, propertyStockStatusOptions  } = useSelector((state: IRootState) => state.locale);
	const token = useSelector((state: IRootState) => state.login.token ?? '');
	const { uid, privileges } = useSelector((state: IRootState) => state.login);
	
	const canReadUnownedProperty = hasPermission(privileges, 'READ', 'UNOWNED_PROPERTY_STOCK');

	const districtOptions = {
    ...districtNtOptions,
    ...districtHkiOptions,
    ...districtKltOptions,
	};

	const navToSalesCommissionReport = notification.titleEn === "Property Stock (Close Deal Commission Share)" || notification.titleEn === "Property Stock (Close Deal Approval Success)";
	const notificationFurtherActionType = navToSalesCommissionReport ? "SALES_COMMISSION_REPORT" : notification.module;


	const getModuleNavMap = () => ({
		TRNX_DATAFEED: {
			name: 'Transactions'
		},
		BUILDING_MASTER_CREATION_REQUEST: {
			name: 'Building'
		}, 
		PROPERTY_STOCK: {
			name: 'Property Stock',
			link: langNotification.navPropertyStock,
			url: (propertyNo: string) => canReadUnownedProperty ? `/properties-edit/${propertyNo}` : `/properties/${propertyNo}`,
			action: (propertyNo: string) => {
				if (canReadUnownedProperty) {
					history.push({ pathname: `/properties-edit/${propertyNo}`});
				} else {
					history.push({ pathname: `/properties/${propertyNo}`, state: { from: `/notifications-centre`}});
				}
			}
		},
		SALES_COMMISSION_REPORT: {
			name: 'Sales Commission Report',
			link: langNotification.navSalesCommissionReport,
			url: (pid: string) => `${BASE_URL}/commissionReport/generate/${locale}/${pid}/pdf?t=${token}`,
			action: (pid: string) => {
				window.open(`${BASE_URL}/commissionReport/generate/${locale}/${pid}/pdf?t=${token}`);
			}
		},
		CLIENT_DORMANCY: {
			name: 'Client',
			link: langNotification.navClient,
			url: (cid: string) => `/client-detail/${cid}`,
			action: (cid: string) => {
				history.push(`/client-detail/${cid}`);
			}
		},
		PROPERTY_STOCK_DORMANCY: {
			name: 'Property Stock',
			link: langNotification.navPropertyStock,
			url: (propertyNo: string) => `/properties/${propertyNo}`,
			action: (propertyNo: string) => {
				history.push(`/properties/${propertyNo}`);
			}
		},
		FORM135_SIGNING: {
			name: 'Form Signing',
			link: langNotification.navPropertyStock,
			url: (propertyNo: string) =>  canReadUnownedProperty ? `/properties-edit/${propertyNo}` : `/properties/${propertyNo}`,
			action: (propertyNo: string) => {
				if (canReadUnownedProperty) {
					history.push({ pathname: `/properties-edit/${propertyNo}`});
				} else {
					history.push({ pathname: `/properties/${propertyNo}`, state: { from: `/notifications-centre`}});
				}
			}
		},
		FORM46_SIGNING: {
			name: 'Form Signing',
			link: langNotification.navClient,
			url: (cid: string) => `/client-detail/${cid}`,
			action: (cid: string) => {
				history.push(`/client-detail/${cid}`);
			}
		},
		CLAIM_CLIENT: {
			name: 'Client',
			link: langNotification.navClient,
			url: (cid: string) => `/client-detail/${cid}`,
			action: (cid: string) => {
				history.push(`/client-detail/${cid}`); 
			}
		},
		CLAIM_PROPERTY_STOCK: {
			name: 'Property Stock',
			link: langNotification.navPropertyStock,
			url: (propertyNo: string) => `/properties/${propertyNo}`,
			action: (propertyNo: string) => { 
				history.push(`/properties/${propertyNo}`);
			}
		}
	});

	const getUrl = () =>{
		const navConfig = moduleNavMap[notificationFurtherActionType];
		let url = '/notifications-centre';
		if (navConfig['url']) {
			if (notificationFurtherActionType === 'FORM135_SIGNING' || notificationFurtherActionType === 'PROPERTY_STOCK' || notificationFurtherActionType === 'PROPERTY_STOCK_DORMANCY') {
				url = navConfig['url'](notification.propertyNo);
			}else{
				url = navConfig['url'](notification.moduleItemId);
			}
		}
		return url; 
	}

	const _originalModuleMap = getModuleNavMap();

	const moduleNavMap = { 
		..._originalModuleMap,
		CLAIM_CLIENT: notification.isExpired ? _originalModuleMap.CLAIM_CLIENT : {
			name: 'Client',
			link: langNotification.viewClient,
			action: (cid: string) => {
				
				clientApi.getSummary(cid, token).then(({ error, data }) => {
					if (error) {
						dispatch({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgAlreadyAssignedClient, severity: 'error' } })
						return;
					}
					confirmDialog.confirm(
						<ClientClaimView
							chineseName={data?.chineseName ?? ''}
							englishName={data?.englishName ?? ''}
							source={data?.source ?? ''}
							district={data?.district ?? []}
						/>, lang.actionYes, lang.actionNo
					).then(confirmed => {
						if (confirmed) {
							dispatch({ type: 'Client.ClaimRequested', payload: { cid } });
						}
					});
				});        
			},
		},
		CLAIM_PROPERTY_STOCK: {
			name: 'Property Stock',
			link: langNotification.viewPropertyStock,
			action: (pid: string) => {
				propertyApi.getSummary(pid, token).then(({ error, data }) => {
					if (error) {
						dispatch({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgAlreadyAssignedPropertyStock, severity: 'error' } })
						return;
					}
					confirmDialog.confirm(
						<div>
							<Typography variant="h3" className={classes.claimDialogTitle}>{langPropertyStock.titleClaimPropertySummary}</Typography>
							<Divider className={classes.claimDialogTitleDivider}/>

							<div className={classes.claimDialogItemHeading}>{langPropertyStock.stockNo}:</div>
							<div className={classes.claimDialogItemContent}>{data?.propertyNo}</div>

							<div className={classes.claimDialogItemHeading}>{langPropertyStock.status}:</div>
							<div className={classes.claimDialogItemContent}>{propertyStockStatusOptions[data?.status ?? '']}</div>

							<div className={classes.claimDialogItemHeading}>{langPropertyStock.address}:</div>
							<div className={classes.claimDialogItemContent}>{genAddress(locale, data?.unit ?? '', data?.floor ?? '', data?.blockZh ??'', data?.blockEn ??'', data?.buildingZh ??'',  data?.buildingEn ??'', data?.streetZh ?? '', data?.streetEn?? '',  districtOptions[data?.district ?? ''])}</div>
							
							<div className={classes.claimDialogItemHeading}>{langPropertyStock.lot}:</div>
							<div className={classes.claimDialogItemContent}>{data?.lot || 'N/A'}</div>

							{data?.status !== 'RENT' ? <>
								<div className={classes.claimDialogItemHeading}>{langPropertyStock.captionPrice}:</div>
								<div className={classes.claimDialogItemContent}>{`$${handlePriceDisplay(data?.price ?? 0, locale)}${handlePriceDisplayUnit(data?.price ?? 0, locale, lang)}`}</div>
								 </>: null 
							 } 

							{data?.status !== 'SALES' ? <>
								<div className={classes.claimDialogItemHeading}>{langPropertyStock.captionRent}:</div>
								<div className={classes.claimDialogItemContent}>{`$${data?.rent}`}</div>
								</>: null
							}

							<div className={classes.claimDialogItemHeading}>{langPropertyStock.size}:</div>
							<div className={classes.claimDialogItemContent}>
								{data?.gross ? <>
									{data?.gross} <span className={classes.claimDialogSizeSuffix}>({langPropertyStock.captionGross})</span> &nbsp;
								</> : ''
								}
								{data?.net ? <>
									{data?.net} <span className={classes.claimDialogSizeSuffix}>({langPropertyStock.captionNet})</span>
								</> : ''
								}
							
							</div>

							<div className={classes.claimDialogItemHeading}>{langPropertyStock.msgStockCountPerDistrict}:</div>
							<div className={classes.claimDialogItemContent}>
								{Object.keys(data?.propertyStockCountPerDistrict ?? {}).map(district => `${districtOptions[district]} (${data?.propertyStockCountPerDistrict![district]})`).join(', ')}
							</div>
							<div>{langPropertyStock.msgClaimAllUnderTheClient}</div>

							{/* <div><span><b>{langPropertyStock.stockNo}: </b>{data?.propertyStockId}</span></div>
              				<div><span><b>{langPropertyStock.status}: </b>{propertyStockStatusOptions[data?.status ?? '']}</span></div>
							<div><span><b>{langPropertyStock.usage}: </b>{usageOptions[data?.usage ?? '']}</span></div>
							<div><span><b>{langPropertyStock.address}: </b>{genAddress(locale, data?.unit ?? '', data?.floor ?? '', data?.blockZh ??'', data?.blockEn ??'', data?.buildingZh ??'',  data?.buildingEn ??'', data?.streetZh ?? '', data?.streetEn?? '',  districtOptions[data?.district ?? ''])}</span></div>
              				<div><span><b>{langPropertyStock.lot}: </b>{data?.lot ?? 'N/A'}</span></div>
							<div><span><b>{langPropertyStock.captionPrice}: </b>{`$${priceToView(data?.price ?? 0, locale)}${multiLang(locale, lang.u10k, lang.u1M)}`}</span></div>
							<div><span><b>{langPropertyStock.captionRent}: </b>{data?.rent}</span></div>
							<div><span><b>{langPropertyStock.gross}: </b>{data?.gross ?? 'N/A'}</span></div>
							<div><span><b>{langPropertyStock.net}: </b>{data?.net ?? 'N/A'}</span></div>
							<div><span><b>{langPropertyStock.occupancyPermitDate}: </b>{moment(data?.occupancyPermitDate).format(DISPLAY_DATE_FORMAT)}</span></div>
							<div><span><b>{langPropertyStock.expiryYear}: </b>{data?.expiryYear}</span></div>
							<div><span><b>{langPropertyStock.captionKeyNo}: </b>{data?.keyNo ?? 'N/A'}</span></div>
							<div><span><b>{langPropertyStock.symbol}: </b>{data?.symbol!.length > 0 ? data?.symbol?.map(s => symbolOptions[s ?? '']).join(',') : 'N/A'}</span></div>
							<div><span><b>{langPropertyStock.captionClientName}: </b>{multiLang(locale, data?.sellerZh, data?.sellerEn)}</span></div> */}
							
							<Divider className={classes.claimDialogTitleDivider}/>
							<div style={{ marginTop: 8 }}>
								<span style={{ textAlign: 'right' }}>{langPropertyStock.msgConfirmClaimProperty}</span>
							</div>
						</div>, lang.actionConfirm, lang.actionCancel
					).then(confirmed => {
						if (confirmed) {
							dispatch({ type: 'Property.ClaimRequested', payload: { id: pid }});
						}
					})
				})
			}
		}
	} as any;


	const onReadAction = () => {
		dispatch({ type: 'Notifications.ReadActionRequested', payload: { ids: [ notification.id ], isRead: !notification.isRead }});
	};

	const isClaim = notification.module === 'CLAIM_CLIENT' || notification.module === 'CLAIM_PROPERTY_STOCK';
	
	const getLinkProps = () =>{
		if ( isClaim && !notification.isExpired ){
			return { component: 'button' };
		}else{
			return { component: 'a', href: PUBLIC_URL + getUrl() };
		}
	}
	
	return (<div>
		<Link
			// {...{ component: 'a', href: PUBLIC_URL + getUrl() }}
			{...getLinkProps()}
			variant="body2"
			onClick={(ev: any) => {
				ev.preventDefault();
				if (!notification.isRead){
					onReadAction();
				}
				const navConfig = moduleNavMap[notificationFurtherActionType];
				if (navConfig['action']) {
					if (notificationFurtherActionType === 'FORM135_SIGNING' || notificationFurtherActionType === 'PROPERTY_STOCK' || notificationFurtherActionType === 'PROPERTY_STOCK_DORMANCY') {
						navConfig['action'](notification.propertyNo);
					} else {
						navConfig['action'](notification.moduleItemId);
					}
				}
			}}
		>
			{`${moduleNavMap[notificationFurtherActionType]['link']}`}
		</Link>
	</div>
  )
}

export interface ClientClaimViewProps {
	district: string[];
	source: string;
	chineseName: string;
	englishName: string;
}

export function ClientClaimView(props: ClientClaimViewProps) {
	const classes = useStyles();
	const { langUser, locale, lang, langClientDetail, langNotification, langPropertyStock, sourceOfClientOptions,
		districtNtOptions, districtHkiOptions, districtKltOptions, propertyStockStatusOptions  } = useSelector((state: IRootState) => state.locale);
	const districtOptions = {
		...districtNtOptions,
		...districtHkiOptions,
		...districtKltOptions,
	};

	return <div style={{ width: '100%'}}>
		<Typography variant="h3" className={classes.claimDialogTitle}>{langClientDetail.titleSummary}</Typography>
		<Divider className={classes.claimDialogTitleDivider}/>
		{/* <span><b>Client Name: </b>{multiLang(locale, data.chineseName, data.englishName) ?? ''}</span> */}
		
		{/* <div><span><b>{langClientDetail.chineseName}: </b>{data?.chineseName}</span></div>
		<div><span><b>{langClientDetail.englishName}: </b>{data?.englishName}</span></div>
		
		<div><span><b>{langClientDetail.sourceOfClient}: </b>{sourceOfClientOptions[data?.source ?? '']}</span></div>
		<div>
			<span>
			<b>{langPropertyStock.district}: </b>
			{data?.district.map(d => districtOptions[d]).join(', ')}
			</span>
		</div> */}
		<div className={classes.claimDialogItemHeading}>{langClientDetail.clientName}:</div>
		<div className={classes.claimDialogItemContent}>{ multiLang(locale,props.chineseName, props.englishName) ?? 'N/A'}</div>
		
		{/* 
		<div className={classes.claimDialogItemHeading}>{langClientDetail.englishName}:</div>
		<div className={classes.claimDialogItemContent}>{data?.englishName ?? 'N/A'}</div> */}

		<div className={classes.claimDialogItemHeading}>{langClientDetail.sourceOfClient}:</div>
		<div className={classes.claimDialogItemContent}>{sourceOfClientOptions[props.source ?? ''] || 'N/A'}</div>

		<div className={classes.claimDialogItemHeading}>{langPropertyStock.district}:</div>
		<div className={classes.claimDialogItemContent}>{props.district.map(d => districtOptions[d]).join(', ') || 'N/A'}</div>

		<Divider className={classes.claimDialogTitleDivider}/>
		<div style={{ marginTop: 8 }}>
			<span style={{ textAlign: 'right' }}>{langClientDetail.msgConfirmClaimClient}</span>
		</div>
	</div>;
}

export default NotificationLink;