export interface ContentOptionItem{
    pattern: string;
    displayName: string;
}

export const contentAutocompleteOptions: ContentOptionItem[] = [
    ///////////////////////////////////////////////////////
    /////////////////// client master /////////////////////
    ///////////////////////////////////////////////////////
    // {
    //     pattern: '% created the Client Master record.',
    //     displayName: '<User Full Name> created the Client Master record.'
    // },
    // {
    //     pattern: 'The Client Status has been changed from %',
    //     displayName: 'The Client Status has been changed from <Old Status> to <New Status> by <User Full Name>'
    // },
    // {
    //     pattern: 'Form % has been % for the following property visits:%',
    //     displayName: 'Form <4/6> has been <signed/uploaded> for the following property visits:<Property Visit List in Schedule 1>'
    // },
    // {
    //     pattern: 'Agent of the client has been assigned to %.',
    //     displayName: 'Agent of the client has been assigned to <User Full Name>.'
    // },
    {
        // pattern: 'Client - Agent Change: Agent of the client has been % to %',
        pattern: 'Client - Agent Change',
        displayName: 'Client - Agent Change'
    },
    // {
    //     pattern: '% Client has been claimed by %',
    //     displayName: '<Dormant/New> Client has been claimed by <User Full Name>.'
    // },
    // {
    //     pattern: 'The client has been set as company client by %',
    //     displayName: 'The client has been set as company client by <User Full Name>.'
    // },
    // {
    //     pattern: 'The company client has been changed to non-company client by %',
    //     displayName: 'The company client has been changed to non-company client by <User Full Name>.'
    // },

    ///////////////////////////////////////////////////////
    ////////////////// property stock /////////////////////
    ///////////////////////////////////////////////////////
    {
        // pattern: '% created the Property Stock record',
        pattern: 'Property - New entry',
        displayName: 'Property - New entry'
    },
    {
        // pattern: 'Property - Status Change (%): The Property Stock Status has been changed from % to % by %',
        pattern: 'Property - Status Change',
        displayName: 'Property - Status Change'
    },
    { 
        // pattern: 'Property - Price increases: The Property Stock Price has been changed from % to % by %',
        pattern: 'Property - Price increases',
        displayName: 'Property - Price increases'
    },
    { 
        // pattern: 'Property - Price drops: The Property Stock Price has been changed from % to % by %',
        pattern: 'Property - Price drops',
        displayName: 'Property - Price drops'
    },
    { 
        // pattern: 'Property - Rent increases: The Property Stock Rent has been changed from % to % by %',
        pattern: 'Property - Rent increases',
        displayName: 'Property - Rent increases'
    },
    { 
        // pattern: 'Property - Rent drops: The Property Stock Price has been changed from % to % by %',
        pattern: 'Property - Rent drops',
        displayName: 'Property - Rent drops'
    },
    // {
    //     pattern: 'The Property Stock % has been changed from % to % by %',
    //     displayName: 'Property - <Price/Rent> <increases/drops>: The Property Stock <Price/Rent> has been changed from <Old Amount> to <New Amount> by <User Full Name>.'
    // },
    // {
    //     pattern: 'Lead Agent of the property stock has been assigned to %',
    //     displayName: 'Lead Agent of the property stock has been assigned to <User Full Name>.'
    // },
    // {
    //     pattern: '% assigned % to this property stock',
    //     displayName: '<Assigner User Full Name> assigned <Assignee Full Name> to this property stock.'
    // },
    // {
    //     pattern: '% removed % from this property stock.',
    //     displayName: '<Remover User Full Name> removed <Full Name of Agent User being removed> from this property stock.'
    // },
    // {
    //     pattern: 'Dormant property stock has been claimed by %',
    //     displayName: 'Dormant property stock has been claimed by <User Full Name>.'
    // },
    // {
    //     pattern: '% was signed on %',
    //     displayName: '<Form/Provisional Agreement> was signed on <Client Sign Date>.'
    // },
    // {
    //     pattern: 'A signed % was uploaded on %',
    //     displayName: 'A signed <Form/Agreement> was uploaded on <File Upload Date>.'
    // },
    // {
    //     pattern: '% uploaded a % file%(Description:%, Date:%) on %',
    //     displayName: '<File Uploader Full Name> uploaded a <Document Type> file (Description: <Value of "Name" field entered by User>, Date: <Value of "Date" field entered by User>) on <File Upload Date>.'
    // },
    // {
    //     pattern: '% uploaded a Supplementary Document for Provisional Agreement on %',
    //     displayName: '<File Uploader Full Name> uploaded a Supplementary Document for Provisional Agreement on <File Upload Date>.'
    // },

]