import {
  Card,
  CardActions,
  CardContent, colors, Divider,
  Table,
  TableBody,
  TableCell,
  TableHead, TableRow, TableSortLabel,
  // TablePagination,
  Theme
} from '@material-ui/core';
import { CardProps } from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/styles';
import clientApi from 'api/clientApi';
import clsx from 'clsx';
import { hasPermission } from 'common/access-control';
import { useConfirmDialog } from 'common/ConfirmDialog';
import { ClientListItemDTO, PropertyListItemDTO } from 'common/dto';
import DataRow from 'common/elements/DataRow';
import TablePagination from 'common/elements/TablePagination';
import { handlePriceDisplay, handlePriceDisplayUnit, isNonEmpty, multiLang } from 'common/utils';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { IRootState, PASDispatch } from 'reducers';
import { isNullOrUndefined } from 'util';
import { ClientClaimView } from 'views/Notification/NotificationLink';
import { SimplePopover } from './Popover';

const PUBLIC_URL = process.env['PUBLIC_URL'];

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-start',
    background: colors.grey[50],
    flexWrap: 'wrap',
    '& > *': {
      wordBreak: 'keep-all',
      // marginBottom: '8px',
    },
    padding: '0px',
  },
  tableRow: {
    cursor: 'pointer',
  },
  unownedClientRow: {
    background: '#eeeeee',
  },
  tableCell: {
    wordWrap: 'normal',
    wordBreak: 'keep-all',
    lineBreak: 'strict',
    whiteSpace: 'nowrap',
    // minWidth: 128,
  },
  tablePaginationIconButton: {
    '& .MuiIconButton-root': {
      padding: '6px',
    }
  },
  tablePaginationToolbar: {
    paddingRight: '12px',
  },
  clearSortingContainer: {
    padding: '3px',
  }
}));

type Header = {
  title: string | JSX.Element,
  overrideStyle?: any,
};

interface ClientsTableProps extends CardProps, PAS.WishlistVariant {
  className?: string;
  properties: PropertyListItemDTO[];
  clients: ClientListItemDTO[];
  matchingClient?: any;
  onCancelPropertyMatching?: () => void;

  formSigned?: boolean;
  setFormSigned?: (v: boolean) => void;

  page: number;
  totalPages: number;
  totalElements: number;
  limit: number;
  rowsCountOptions: number[];
  handleRowsCount: (count: number) => void;
  setPage: (page: number) => void;
  sortOrders?: { [k: string]: 'desc' | 'asc' };
  handleSort?: (key: string | null) => void;
  canReadUnownedClient: boolean;
}

type ColumnName = string; type ColumnPreferenceKey = string;
const columnNameToColumnPreferenceMapping: Record<ColumnName, ColumnPreferenceKey> = {
  clientType: 'clientType',
  clientStatus: 'clientStatus',
  clientName: 'clientName',
  contactType: 'contactType',
  mainContact: 'mainContact',
  source: 'source',
  usage: 'usage',
  gross: 'gross',
  net: 'net',
  price: 'price',
  remarks: 'remarks',
  dateModified: 'dateModified',
};

const ClientsTable = (props: ClientsTableProps) => {
  const { className, properties, clients, matchingClient, onCancelPropertyMatching,
    wishlistVariant, formSigned, setFormSigned, sortOrders, handleSort, canReadUnownedClient,
    page, totalPages, totalElements, limit, setPage, rowsCountOptions, handleRowsCount, ...rest } = props;

  const { langPropertyStock, lang: langCommon, locale, langClientDetail, langUser, clientTypeOptions, sourceOfClientOptions, usageOptions, dormancyOptions, clientStatusOptions, contactOptions } = useSelector((state: IRootState) => state.locale);

  const isRent = useSelector((state: IRootState) => state.clients.lastSearchCriteria?.isRent ?? false);
  const isDormant = useSelector((state: IRootState) => state.clients.lastSearchCriteria?.isDormant ?? false);
  const unit = locale === 'en' ? langCommon.u1M : langCommon.u10k;
  const { token } = useSelector((state: IRootState) => state.login);

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch() as PASDispatch;
  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);
  const privileges = useSelector((state: IRootState) => state.login.privileges);

  const confirmDialog = useConfirmDialog();

  const [] = useState<string[]>([]);

  /*------column sorting ---------*/
  const { columnsOrder, disabledColumns } = useSelector((state: IRootState) => state.clients);
  const [colOrders, setColOrders] = useState<string[]>([]);

  const [tableHeadersSorted, setTableHeadersSorted] = useState<Record<any, Header | null>>({});
  const selectColumnOrder = (columnName: string) => colOrders.indexOf(columnNameToColumnPreferenceMapping[columnName]);

  useEffect(() => {
    dispatch({ type: 'Client.ColumnPreference.FetchRequested' });
  }, []);

  useEffect(() => {
    const sortedHeaders: Record<any, Header | null> = {};
    if (colOrders?.length > 0) {
      Object.keys(tableHeaders).sort((colA, colB) =>
        selectColumnOrder(colA) > selectColumnOrder(colB) ? 1 : -1
      ).forEach((colName) => sortedHeaders[colName] = tableHeaders[colName]);
    }

    setTableHeadersSorted(sortedHeaders);
  }, [colOrders ?? [], locale]);

  useEffect(() => {
    setColOrders(columnsOrder ?? []);
  }, [columnsOrder]);



  const navigationLink = (id: string) => `/client-detail/${id}`;
  const canCreateOwnClient = hasPermission(privileges, 'CREATE', 'OWN_CLIENT');

  const handleNavigateToItem = (id: string, client: ClientListItemDTO) => () => {
    const canActuallyReadDetail = client.isLeadAgent || client.isTeamHead || client.isCompanyClient || canReadUnownedClient;
    sessionStorage.setItem('lastClickedClient', id);
    if (canActuallyReadDetail) {
      history.push(navigationLink(id));
    } else if (canCreateOwnClient && !canReadUnownedClient) {
      clientApi.getSummary(id, token ?? '').then(({ error, data }) => {
        if (error) {
          dispatch({ type: 'Layout.AlertMessageAdded', payload: { message: langCommon.msgAlreadyAssignedClient, severity: 'error' } })
          return;
        }
        confirmDialog.confirm(
          <ClientClaimView
            chineseName={data?.chineseName ?? ''}
            englishName={data?.englishName ?? ''}
            source={data?.source ?? ''}
            district={data?.district ?? []}
          />, langCommon.actionYes, langCommon.actionNo
        ).then(confirmed => {
          if (confirmed) {
            dispatch({ type: 'Client.ClaimRequested', payload: { cid: id } });
          }
        });
      });
    } else {
      // Just nothing
    }
    // if(!isDormant) {
    // }
  }

  const handlePageChange = (event: React.MouseEvent | null, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleRowsCount(+event.target.value);
  };


  let tableHeaders: Record<any, Header | null> = { // keyof PropertyListItemDTO
    clientType: { title: langClientDetail.clientType },
    clientStatus: { title: langClientDetail.clientStatus },
    clientName: { title: langClientDetail.clientName },
    contactType: { title: langClientDetail.contactType },
    mainContact: { title: langClientDetail.contactNoOrEmail, overrideStyle: { minWidth: 128 } }, //contact No/email
    source: { title: langClientDetail.sourceOfClient },
    usage: { title: langClientDetail.propertyUsage },
    net: { title: langPropertyStock.net, overrideStyle: { minWidth: 135 } },
    gross: { title: langPropertyStock.gross, overrideStyle: { minWidth: 135 } },
    price: { title: (langPropertyStock.price + ' / ' + langPropertyStock.rent), overrideStyle: { minWidth: 128 } },
    // //TODO agent
    remarks: { title: langClientDetail.remarks },
    dateModified: { title: langCommon.dateModified },
  };

  const keyMap: { [key: string]: string } = {
    //change table header to corrsponding property
    usage: 'clientPreferences.usage', //TODO
    net: 'clientPreferences.netAreaFrom',
    gross: 'clientPreferences.grossAreaFrom',
    price: isRent ? 'clientPreferences.rentBudgetFrom' : 'clientPreferences.buyBudgetFrom',
    clientStatus: 'clientStatus',
    clientName: 'chineseName',
    clientType: 'clientPreferences.clientType', //TODO
    contactType: 'contacts.type',
    mainContact: 'contacts.value',
    source: 'source',
    remarks: 'remarks',
    dateModified: 'dateModified',
  };

  const dislayRangeValue = (lowerLimit: number | undefined, upperLimit: number | undefined, unit?: string, handlingPrice = false) => {
    if (isNullOrUndefined(lowerLimit) && isNullOrUndefined(upperLimit)) {
      return '';
    }
    const lowerLimitDisplay = isNullOrUndefined(lowerLimit) ? 0 : (handlingPrice ? handlePriceDisplay(lowerLimit ?? 0, locale) : lowerLimit) + (handlingPrice ? handlePriceDisplayUnit(lowerLimit ?? 0, locale, langCommon) ?? '' : '');
    const upperLimitDisplay = isNullOrUndefined(upperLimit) ? langPropertyStock.captionUnlimited : (handlingPrice ? handlePriceDisplay(upperLimit ?? 0, locale) : upperLimit) + (handlingPrice ? handlePriceDisplayUnit(upperLimit ?? 0, locale, langCommon) ?? '' : '');
    return lowerLimitDisplay + '-' + upperLimitDisplay;
  };

  const displaySlash = (client: ClientListItemDTO) => {
    if (isNullOrUndefined(client.clientPreferences.buyBudgetFrom) && isNullOrUndefined(client.clientPreferences.buyBudgetTo)) {
      return false;
    } else {
      if (isNullOrUndefined(client.clientPreferences.rentBudgetFrom) && isNullOrUndefined(client.clientPreferences.rentBudgetTo)) {
        return false;
      } else {
        return true;
      }
    }
  }

  const overrideCols: { [col: string]: (client: ClientListItemDTO) => any } = {
    usage: (client) => client.clientFeatures?.filter((features) => features.type == 'usage')
      .map((features) => usageOptions[features.value]).join(', '),
    clientStatus: (client) => clientStatusOptions[client.clientStatus ?? ''],
    net: (client) => dislayRangeValue(client.clientPreferences.netAreaFrom, client.clientPreferences.netAreaTo),
    gross: (client) => dislayRangeValue(client.clientPreferences.grossAreaFrom, client.clientPreferences.grossAreaTo),
    price: (client) => dislayRangeValue(client.clientPreferences.buyBudgetFrom, client.clientPreferences.buyBudgetTo, unit, true)
      + (displaySlash(client) ? ' / ' : '') + dislayRangeValue(client.clientPreferences.rentBudgetFrom, client.clientPreferences.rentBudgetTo),
    clientName: (client) => multiLang(locale, client.chineseName, client.englishName),
    clientType: (client) => client.clientFeatures?.filter((features) => features.type == 'clientType')
      .map((features) => clientTypeOptions[features.value]).join(', '),
    contactType: (client) => client.showClientContact ?
      (client.otherContacts[0] ? contactOptions[client.otherContacts[0].type] : '')
      : (contactOptions[client.agentContact?.type ?? ''] ?? ''),
    mainContact: (client) => client.showClientContact ?
      (client.otherContacts[0] ? client.otherContacts[0].value : '')
      : isNullOrUndefined(client.agentContact?.value) ? '' : ((client.agentContact?.value ?? '') + ' ' + langClientDetail.agentsContactRemarks1 + multiLang(locale, client.agentNameZh ?? '', client.agentNameEn ?? '') + langClientDetail.agentsContactRemarks2),
    source: (client) => client.source ? sourceOfClientOptions[client.source] : '',
    dateModified: (client) => moment(client.dateModified).format(DISPLAY_DATE_FORMAT),
  }

  //handle popover
  // const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLTableHeaderCellElement | null>(null);

  const handleClientNameClick = (event: React.MouseEvent<HTMLTableHeaderCellElement>, selectedClient: ClientListItemDTO) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setSelectedClient(selectedClient);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };


  const [selectedClient, setSelectedClient] = useState<ClientListItemDTO | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      {confirmDialog.render()}
      {/* {matchingClient ? <CardHeader
        title={<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', }}>
          <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
            <Typography>正在為</Typography>
            <Typography color="secondary">{matchingClient.name}</Typography>
            <Typography>對盤</Typography>
          </div>
          <Button style={{ marginLeft: 10, marginBottom: 10 }} variant="outlined" onClick={onCancelPropertyMatching} color="secondary" startIcon={<AddIcon />}>將所選樓盤加入睇樓清單 (0)</Button>
          <Button style={{ marginLeft: 10, marginBottom: 10 }} variant="outlined" onClick={onCancelPropertyMatching}>取消對盤</Button>
        </div>}
      />: null} */}

      <CardActions className={classes.actions}>
        {/* {wishlistVariant && setFormSigned && !formSigned ? <Button variant="outlined" color="secondary" onClick={() => setFormSigned(true)} style={{ marginRight: 10 }}>簽署睇樓紙 (表格4)</Button> : null}
        {wishlistVariant && !formSigned ? <Button variant="outlined">{langCommon.actionRemoveSelected}</Button> : null} */}

        <div style={{ flexGrow: 1 }} />

        {/* {Object.keys(sortOrders ?? {}).length ?
          <div className={classes.clearSortingContainer}>
            <Button style={{ marginRight: 0 }} variant="outlined" size="small" onClick={() => handleSort?.(null)}>{langPropertyStock.actionClearSorting}</Button>
          </div>
        : null} */}

        <TablePagination
          classes={{
            actions: classes.tablePaginationIconButton,
            toolbar: classes.tablePaginationToolbar,
          }}
          // component="div"
          count={totalElements}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          totalPages={totalPages}
          rowsPerPage={limit}
          labelRowsPerPage={langUser.rowsCount}
          rowsPerPageOptions={rowsCountOptions}
        // rowsPerPageOptions={[20]}
        />
      </CardActions>

      <Divider />

      <SimplePopover
        open={open}
        id={id}
        handleClose={handlePopoverClose}
        handleClick={handleClientNameClick}
        anchorEl={anchorEl}
        selectedClient={selectedClient} />

      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {/* {matchingClient || (wishlistVariant && !formSigned) ? <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedProperties.length === properties.length}
                      color="primary"
                      indeterminate={
                        selectedProperties.length > 0 &&
                        selectedProperties.length < properties.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell> : null} */}
                  {/* { 
                    isDormant ? 
                      <TableCell className={classes.tableCell}>{langClientDetail.actionClaimClient}</TableCell> : null
                  } */}
                  {
                    Object.keys(tableHeadersSorted)
                      .filter((key) => !!tableHeadersSorted[key as keyof PAS.ClientListItem])
                      .filter((key) => !disabledColumns.includes(columnNameToColumnPreferenceMapping[key]))
                      .map((key, idx) =>
                        <TableCell key={idx} className={classes.tableCell} style={tableHeadersSorted[key as keyof PAS.ClientListItem]?.overrideStyle}>
                          <TableSortLabel active={(key === 'dateModified' && sortOrders !== undefined && sortOrders?.[keyMap[key]] !== undefined && Object.keys(sortOrders)[0] === 'dateModified') || (key !== 'dateModified' && sortOrders?.[keyMap[key]] !== undefined)} direction={sortOrders?.[keyMap[key]]} onClick={() => handleSort?.(keyMap[key])}>
                            {/* {tableHeadersSorted[key as keyof PAS.ClientListItem]?.title} */}
                            {(sortOrders?.['clientPreferences.buyBudgetFrom'] || sortOrders?.['clientPreferences.rentBudgetFrom']) && key === 'price' ? (isRent ? '' : '$') + langPropertyStock.price + ' / ' + (isRent ? '$' : '') + langPropertyStock.rent : tableHeadersSorted[key as keyof PAS.ClientListItem]?.title}
                          </TableSortLabel>
                        </TableCell>

                      )
                  }
                  {
                    isDormant ?
                      <TableCell className={classes.tableCell}>{langClientDetail.canClaimBy}</TableCell> : null
                  }

                </TableRow>

              </TableHead>
              <TableBody>
                {clients.map(client => (
                  <DataRow
                    className={[classes.tableRow, !client.agentId ? classes.unownedClientRow : ''].join(' ')}

                    hover
                    key={client.cid}
                    // selected={selectedProperties.indexOf(property.id!) !== -1}
                    onClick={handleNavigateToItem(client.cid!, client)}
                    href={client.isLeadAgent || client.isTeamHead || client.isCompanyClient || canReadUnownedClient ? PUBLIC_URL + navigationLink(client.cid!) : undefined}
                  >
                    {/* {
                    client.dormancyStatus != null ? 
                      <TableCell className={classes.tableCell}><Button variant="outlined" onClick={(ev) => {
                        ev.stopPropagation();
                        confirmDialog.confirm('確認認領客戶？', '確認', '取消').then((confirmed) => {
                          if (confirmed) {
                            handleClaimClient(client.cid!);
                          }
                        })
                      }         
                      }>{langClientDetail.actionClaimClient}</Button>
                      </TableCell> : null
                    } */}
                    {
                      Object.keys(tableHeadersSorted)
                        .filter((key) => !!tableHeadersSorted[key as keyof PAS.ClientListItem])
                        .filter((key) => !disabledColumns.includes(columnNameToColumnPreferenceMapping[key]))
                        .map((key) => {
                          if (key != 'clientName') {
                            return <TableCell style={client.cid?.toString() === sessionStorage.getItem('lastClickedClient') ? { fontWeight: 'bold', color: '#551A8B ' } : {}} className={classes.tableCell} key={`${client.cid}-${key}`}>{overrideCols[key] ? overrideCols[key](client) : client[key as keyof ClientListItemDTO]}</TableCell>
                          }
                          else {
                            return <TableCell style={client.cid?.toString() === sessionStorage.getItem('lastClickedClient') ? { fontWeight: 'bold', color: '#551A8B ' } : {}} onClick={(e) => handleClientNameClick(e, client)} className={classes.tableCell} key={`${client.cid}-${key}`}>{overrideCols[key] ? overrideCols[key](client) : client[key as keyof ClientListItemDTO]}</TableCell>
                          }
                        })
                    }
                    {
                      isDormant ?
                        <TableCell className={classes.tableCell}>
                          {/* 1. If client.dormancy_status = 'D' OR (client.claim_bucket IS NOT NULL && client.claim_bucket != 'A')
                              => Display "同地區代理"
                          2. If client.dormancy_status = 'A' OR client.claim_bucket = 'A'
                              => Display "所有代理" */}
                          {dormancyOptions[
                            (client.dormancyStatus === 'D' || isNonEmpty(client.claimBucket) && client.claimBucket !== 'A') ? 'D' :
                              ((client.dormancyStatus === 'A' || client.claimBucket === 'A') ? 'A' : 'D')
                          ]}
                        </TableCell> : null
                    }

                  </DataRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <div style={{ flexGrow: 1 }} />
        <TablePagination
          classes={{
            actions: classes.tablePaginationIconButton,
            toolbar: classes.tablePaginationToolbar,
          }}
          count={totalElements}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          totalPages={totalPages}
          rowsPerPage={limit}
          labelRowsPerPage={langUser.rowsCount}
          rowsPerPageOptions={rowsCountOptions}
        />
      </CardActions>
    </Card>
  );
};

// PropertiesTable.propTypes = {
//   className: PropTypes.string,
//   properties: PropTypes.array.isRequired
// };

export default ClientsTable;
