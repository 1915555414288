import { DialogActions, IconButton, TextField } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MuiSaveIcon from '@material-ui/icons/Save';
import { CloseIcon, MapIcon, MapMarker, SearchIcon } from 'common/ui';
import GoogleMapReact from 'google-map-react';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';
import moreClasses from './MapDialog.module.scss';


const useStyles = makeStyles((theme: Theme) => ({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
    buildingPrimaryText: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    dialogRoot: {
        minWidth: 300,
    },
    closeButton: {
        position: 'relative',
        right: theme.spacing(1),
        top: theme.spacing(1)
    },
    saveButton: {
        position: 'absolute',
        right: theme.spacing(3),
        top: theme.spacing(3)
    },
    addBorderIfLocationNotDefined: {
        border: 'lightsalmon',
        borderStyle: 'solid',
        borderRadius: '5px',
        borderWidth: 'medium'
    },
    noBorder: {

    },
}));

interface MapDialogProps {
    // {
    //     onChange: () => void;
    //     value: any;
    // };
    onClose: () => void;
    onSave: (lat: number, lng: number) => void;
    open: boolean;
    allowSave: boolean;
    allowClick: boolean;
    selectedValue: number[];
    // value: number;

    useDefaultCenter?: boolean;
}


interface MapMarkerProps {
    lat: number;
    lng: number;
}

function Marker(props: MapMarkerProps) {
    if (props.lat && props.lng) {
        return (<MapMarker style={{ position: 'relative', top: '-1.5rem', left: '-0.5rem' }} />);
    } else {
        return (null);
    }
}

function SaveButton(props: any) {
    if (props.allowSave) {
        return (<IconButton aria-label="save" onClick={props.onClick}><MuiSaveIcon /></IconButton>);
    } else {
        return (null);
    }
}

export function MapDialog(props: MapDialogProps) {
    const defaultCenter = {
        // Hong Kong's lat & lng
        lat: 22.302711,
        lng: 114.177216
    }
    const { onClose, onSave, open, selectedValue } = props;
    const [marker_coord, setMarkerCoord] = React.useState(props.selectedValue);
    const [flag_changeCoord, setflagChangeCoord] = React.useState(false);
    const [center, setCenter] = React.useState(defaultCenter);
    const [zoom, setZoom] = React.useState(10);
    const { langBuilding } = useSelector((state: IRootState) => state.locale);

    const mapRef = useRef<google.maps.Map | null>(null);
    const centerRef = useRef<{ lat: number, lng: number }>(defaultCenter);

    useEffect(() => {
        if (!flag_changeCoord) {
            setMarkerCoord(selectedValue);
        }
    });

    useLayoutEffect(() => {
        if (marker_coord[0] && marker_coord[1]) {
            setCenter({ lat: marker_coord[0], lng: marker_coord[1] });
            centerRef.current = { lat: marker_coord[0], lng: marker_coord[1] };
        } else {
            setCenter(defaultCenter);
        }
    }, [marker_coord]);

    const handleMapClick = (obj: { lat: number, lng: number }) => {
        if (props.allowClick) {
            if (!flag_changeCoord) {
                setflagChangeCoord(true);
            }
            setMarkerCoord([obj.lat, obj.lng]);
        }
    }

    const handleClose = () => {
        onClose();
    };

    const handleSave = () => {
        onSave(marker_coord[0], marker_coord[1]);
        onClose();

    };

    const handleApiLoaded = () => {
        if (marker_coord[0] && marker_coord[1]) {
            // map?.setCenter({lat: marker_coord[0], lng: marker_coord[1]});
            setZoom(17);
        }
        if (!(selectedValue[0] && selectedValue[1]) || props.useDefaultCenter) {
            // map?.setCenter(defaultCenter);
            setZoom(10);
        }
    };

    const [__forceReRender, __setForceReRender] = useState(Math.random());
    const [fullscreen, setFullscreen] = useState(false);
    const fullscreenControlIOSDivRef = useRef<HTMLDivElement | null>(null);
    useLayoutEffect(() => {
        fullscreenControlIOSDivRef.current && ReactDOM.render(<button data-fullscreen={fullscreen ? 'yes' : 'no'} className={moreClasses['right-btn']} onClick={() => setFullscreen(!fullscreen)}>
            <i className={(['fas', !fullscreen ? 'fa-expand' : 'fa-compress']).join(' ')}></i>
        </button>, fullscreenControlIOSDivRef.current);
    }, [fullscreenControlIOSDivRef.current, fullscreen]);

    const mapSearchRef = useRef<HTMLInputElement | null>(null);

    return (
        <Dialog onClose={handleClose} aria-labelledby="map-dialog-title" open={open} fullWidth fullScreen={fullscreen}>
            <DialogActions>
                {/* {(props.allowSave) ? <IconButton aria-label="save" onClick={handleSave}><MuiSaveIcon/></IconButton> : ""} */}
                {/* // <IconButton aria-label="save" onClick={handleSave}>
                //     <MuiSaveIcon/>
                // </IconButton> */}
                {props.allowSave ? <TextField
                    InputProps={{
                        startAdornment: <div style={{ paddingRight: 4 }}><SearchIcon /></div>
                    }}
                    variant="outlined"
                    style={{ width: '100%' }}
                    inputRef={mapSearchRef}
                /> : null}
                <SaveButton allowSave={props.allowSave} onClick={handleSave} />
                <IconButton aria-label="close" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogActions>

            <div style={{ height: fullscreen ? '100%' : '60vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: process.env['REACT_APP_GOOGLE_MAP_API_KEY'] ?? '',
                        language: 'en',
                        libraries: ['places'],
                    }}
                    center={center}
                    zoom={zoom}
                    onClick={handleMapClick}
                    yesIWantToUseGoogleMapApiInternals={true}
                    onGoogleApiLoaded={({ map }) => {
                        mapRef.current = map;
                        handleApiLoaded();
                        const centerControlDiv = document.createElement("div");
                        ReactDOM.render(<button className={moreClasses['center-btn']} onClick={() => mapRef.current?.setCenter(centerRef.current)}>
                            {/* {langBuilding.actionCenterMap} */}
                            <i className={(['fas', 'fa-street-view']).join(' ')}></i>
                        </button>, centerControlDiv);
                        mapRef.current?.controls[google.maps.ControlPosition.TOP_LEFT].push(centerControlDiv);

                        if (!window.document.body.requestFullscreen) {
                            const fullscreenControlIOSDiv = document.createElement("div");
                            fullscreenControlIOSDivRef.current = fullscreenControlIOSDiv;
                            mapRef.current?.controls[google.maps.ControlPosition.TOP_RIGHT].push(fullscreenControlIOSDiv);
                            __setForceReRender(Math.random());
                        }

                        const autocomplete = new google.maps.places.Autocomplete(mapSearchRef.current!);
                        // Bind the map's bounds (viewport) property to the autocomplete object,
                        // so that the autocomplete requests use the current map bounds for the
                        // bounds option in the request.
                        autocomplete.bindTo("bounds", map);

                        // Set the data fields to return when the user selects a place.
                        autocomplete.setFields(["address_components", "geometry", "icon", "name"]);

                        autocomplete.addListener('place_changed', () => {
                            const place = autocomplete.getPlace();
                            if (place.geometry) {
                                if (place.geometry.viewport) {
                                    map.fitBounds(place.geometry.viewport);
                                } else if (place.geometry.location) {
                                    map.setCenter(place.geometry.location);
                                    setMarkerCoord([
                                        place.geometry.location.lat(),
                                        place.geometry.location.lng(),
                                    ]);
                                }
                            }
                        });
                    }}
                >
                    <Marker lat={marker_coord[0]} lng={marker_coord[1]} />
                </GoogleMapReact>
            </div>
        </Dialog>
    );
}

// export default function MapDialogContainer(props:MapDialogContainerProps) {
export default function MapDialogContainer(props: any) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);

    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = (lat: number, lng: number) => {
        props.onMapSave(lat, lng);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <IconButton
                size="small"
                onClick={handleClickOpen}
                className={(props.selectedValue[0] && props.selectedValue[1]) ? classes.noBorder : classes.addBorderIfLocationNotDefined}
            >
                <MapIcon />
            </IconButton>
            <MapDialog allowClick={props.allowClick} allowSave={props.allowSave} onSave={handleSave} open={open} onClose={handleClose} selectedValue={[props.selectedValue[0], props.selectedValue[1]]} />
        </div>
    );
}
