import { hasPermission } from 'common/access-control';
import { UserDTO } from "common/dto";
import NavigationBlockerDialog from "common/elements/NavigationBlockerDialog";
import { useForm } from "common/hooks";
import deepEqual from 'deep-equal';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IRootState, PASDispatch } from "reducers";
import userFormValidations from './user-validations';
import UserDetail from './UserDetail';


const EMPTY = {};

export default function UserUpdatePage() {
  const dispatch = useDispatch() as PASDispatch;
  const { langUser } = useSelector((state: IRootState) => state.locale);
  const { id }: any = useParams();
  const userDto = useSelector((state: IRootState) => state.user.fetchedDetails[id!]) ?? EMPTY as Partial<UserDTO>;
  const currentUid = useSelector((state: IRootState) => state.login.uid);

  const ownedUser = String(userDto.id) === currentUid;
  const privileges = useSelector((state: IRootState) => state.login.privileges) ?? [];
  const canUpdateUserProfile = ownedUser && hasPermission(privileges, 'UPDATE', 'USER_PROFILE');
  const canUpdateUserInfo = hasPermission(privileges, 'UPDATE', 'USER');

  // User Form
  const userForm = useForm<UserDTO>({
    roles: [],
  }, {
    validations: userFormValidations(langUser, false),
  });

  useEffect(() => {
    dispatch({ type: 'User.FetchRequested', payload: { id: id ?? '' } });
  }, [id]);

  useEffect(() => {

    const userFormValues = { ...userForm.values, ...userDto };
    userForm.setValues(userFormValues);

    setInitialFormValues([userFormValues]);
  }, [userDto]);

  const [initialFormValues, setInitialFormValues] = useState<any>({});
  const unsaved = !deepEqual([userForm.values], initialFormValues, { strict: true });

  return <div>
    <NavigationBlockerDialog matchesUrl={`/users/${id}`} unsaved={unsaved} goBack />
    <UserDetail profileDisabled={!canUpdateUserProfile} infoDisabled={!canUpdateUserInfo} creating={false} onSave={() => {
      if (!userForm.validate()) {
        return;
      }

      if (ownedUser) {
        dispatch({ type: 'Locale.SwitchLocale', locale: userForm.values.language ?? 'zh_HK' });
      }

      dispatch({
        type: 'User.UpdateRequested', payload: {
          ...userForm.values,
          rowsCountPreference: JSON.stringify(userForm.values.rowsCountPreference),
          propertyStockColumnPreference: JSON.stringify(userForm.values.propertyStockColumnPreference),
          clientColumnPreference: JSON.stringify(userForm.values.clientColumnPreference),
          transactionColumnPreference: JSON.stringify(userForm.values.transactionColumnPreference)
        } as UserDTO
      });
    }} form={userForm} unsaved={unsaved} />
  </div>;
}

