import { Button, Card, CardActions, CardContent, Checkbox as MuiCheckbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { CardProps } from '@material-ui/core/Card';
import { TextFieldProps } from '@material-ui/core/TextField';
import { useForm } from 'common/hooks';
import { HandleFormHelperText, tooltipHoc } from 'common/ui';
import { initFormKeysAndBooleanMap, limitTextFieldLength } from 'common/utils';
import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';

//#region Common UI
const Checkbox = tooltipHoc(MuiCheckbox);

const UITextField = (props: TextFieldProps & { fixedLabel?: boolean }) => {
  let { label, fixedLabel, ...rest } = props;
  fixedLabel = false;
  return <Fragment>
    {fixedLabel ? <Typography>{label}</Typography> : null}
    <TextField {...rest} label={!fixedLabel ? label : null} />
  </Fragment>
};

//#endregion Common UI

export interface SystemSettingSearchForm {
  module: string;
  type: string;
  key: string;
  dataStatus: string[];
}

interface SystemSettingSearchProps extends CardProps {
  onSearch?(values: Partial<SystemSettingSearchForm>): any;
}


const SystemSettingSearch = (props: SystemSettingSearchProps) => {
  const searchForm = useForm<SystemSettingSearchForm>({
    dataStatus: [],
  });

  const { lang, statusOptions, langSystemSetting } = useSelector((state: IRootState) => state.locale);

  const bind = searchForm.bind;

  const [keyBooleanMap, setKeyBooleanMap] = useState(initFormKeysAndBooleanMap(searchForm));

  // UI Fields
  const field = (name: keyof SystemSettingSearchForm) => <UITextField
    label={langSystemSetting[name as string]}
    {...bind(name)}
    fullWidth
    margin="dense"
    variant="outlined"
    onChange={(e) => {
      limitTextFieldLength(e, 255, name, searchForm, keyBooleanMap, setKeyBooleanMap);
    }}
  ></UITextField>;

  const dataStatusSelect = () => <FormControl variant="outlined" margin="dense" fullWidth>
    <InputLabel variant="outlined" style={{ background: '#fff' }}>{langSystemSetting.dataStatus}</InputLabel>
    <Select
      multiple
      {...bind('dataStatus')}
      renderValue={(selected) => (selected as string[]).map(key => statusOptions[key]).join(', ')}
    >
      {Object.keys(statusOptions).filter(key => key === 'A' || key === 'I').map(key => (
        <MenuItem key={key} value={key}>
          <Checkbox checked={searchForm.values.dataStatus!.indexOf(key) > -1} />
          <ListItemText primary={statusOptions[key]} />
        </MenuItem>
      ))}
    </Select>
  </FormControl>

  const filteredSearchForm = () => {
    searchForm.values = {
      ...searchForm.values,
      module: searchForm.values.module?.trim(),
      type: searchForm.values.type?.trim(),
      key: searchForm.values.key?.trim(),
    }
    const newForm: Partial<SystemSettingSearchForm> = {};
    Object
      .keys(searchForm.values)
      // .filter((key) => !disabledCriterias[key])
      .forEach((key) => (newForm as any)[key] = searchForm.values[key as keyof SystemSettingSearchForm]);
    return newForm;
  };

  return (
    <Card
      {...props}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardContent>
          <Grid
            container
            spacing={1}
          >
            <Grid item container md={12} xs={12} spacing={1}>

              <Grid
                item
                md={3}
                sm={3}
                xs={12}
              >
                {field('module')}
                <HandleFormHelperText
                  isError={keyBooleanMap.get('module')}
                  errorMessage={lang.textOverLimit}
                />
              </Grid>

              <Grid
                item
                md={3}
                sm={3}
                xs={12}
              >
                {field('type')}
                <HandleFormHelperText
                  isError={keyBooleanMap.get('type')}
                  errorMessage={lang.textOverLimit}
                />
              </Grid>

              <Grid
                item
                md={3}
                sm={3}
                xs={12}
              >
                {field('key')}
                <HandleFormHelperText
                  isError={keyBooleanMap.get('key')}
                  errorMessage={lang.textOverLimit}
                />
              </Grid>

              <Grid
                item
                md={3}
                sm={3}
                xs={12}
              >
                {dataStatusSelect()}
              </Grid>

            </Grid>

          </Grid>
        </CardContent>
      </form>

      <CardActions style={{ flexWrap: 'wrap' }}>
        <div style={{ flexGrow: 1 }} />
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.onSearch?.(filteredSearchForm())}>
          {lang.actionSearch}
        </Button>
      </CardActions>
    </Card>
  );
}

export default SystemSettingSearch;
