import { TableRow, TableRowProps } from '@material-ui/core';
import React from 'react';

export interface DataRowProps extends TableRowProps {
  href?: string | undefined;
}

// const useStyles = makeStyles({
//   dataRowAnchor: {
//     display: 'block',
//     height: '100%',
//     width: '100%',
//   },
// });


export default function DataRow(props: DataRowProps) {
  const { href, children, style, onClick, ...tableRowProps } = props;
  // const classes = useStyles();
  return <TableRow {...tableRowProps}
    style={{ ...style ?? {}, display: 'table-row' }}
    onClick={(ev) => {
      ev.preventDefault();
      onClick?.(ev);
    }}
    component={'a' as any} {...{ href } as any}//to confirm about the error
  >
    {children}
  </TableRow>
}