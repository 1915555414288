export const fetchMasterData = async(): Promise<Response> => {
    const res = fetch('http://localhost:8080/standardDemo/rest/masterData/masterDataList', {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    })
        // .then( res => res.json())
        // .catch( err => {throw err});
    return (await res).json();
}

