import { hasPermission } from "common/access-control";
import { useConfirmDialog } from 'common/ConfirmDialog';
import { ClientDetailDTO, ClientFileMap, ClientMultiValueMap, ClientPreferencesDTO, MemoDTO, PropertySearchDTO } from "common/dto";
import { DEFAULT_FROM, DEFAULT_TO } from 'common/elements/MinMaxField';
import NavigationBlockerDialog from 'common/elements/NavigationBlockerDialog';
import { useForm } from "common/hooks";
import { useInfoDialog } from "common/InfoDialog";
import { isNonEmpty } from "common/utils";
import deepEqual from 'deep-equal';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router";
import { ClientActions, IRootState, PASDispatch } from "reducers";
import ClientDetail from './ClientDetail';
import { ClientDetailFormValidations, PreferenceFormValidations } from "./components/client-validations";


const BLANK = {};

export default function ClientUpdatePage() {
  const dispatch = useDispatch() as PASDispatch;
  // const [ isNew, setIsNew ] = useState(true);
  // const pid = 'PS10000';
  const { cid }: any = useParams();

  const history = useHistory();
  const clientDetailDto = useSelector((state: IRootState) => state.clients.fetchedDetails[cid!]) ?? BLANK as Partial<ClientDetailDTO>;
  const memos = useSelector((state: IRootState) => state.clients.memos ?? [] as MemoDTO[]);
  // const { loading, loadSuccess } = useSelector((state: IRootState) => state.clients);
  const [pendingForMatching, setPendingForMatching] = useState(false);

  const confirmCreateDialog = useConfirmDialog();
  const alertDialog = useInfoDialog();
  const onMatchDialog = useConfirmDialog();

  const { privileges } = useSelector((state: IRootState) => state.login);
  const canUpdateOwnClient = clientDetailDto.isLeadAgent && hasPermission(privileges, 'UPDATE', 'OWN_CLIENT');
  const canUpdateUnownedClient = hasPermission(privileges, 'UPDATE', 'UNOWNED_CLIENT');
  const canUpdateCompanyClient = hasPermission(privileges, 'UPDATE', 'COMPANY_CLIENT');
  const canUpdate = clientDetailDto.isCompanyClient ? canUpdateCompanyClient : (canUpdateOwnClient || canUpdateUnownedClient);

  const canDeleteClient = hasPermission(privileges, 'DELETE', 'CLIENT');
  const canDeleteOwnClient = clientDetailDto.isLeadAgent && hasPermission(privileges, 'DELETE', 'OWN_CLIENT');
  const canDeleteUnownedClient = !clientDetailDto.isLeadAgent && hasPermission(privileges, 'DELETE', 'UNOWNED_CLIENT');
  const canDeleteCompanyClient = hasPermission(privileges, 'DELETE', 'COMPANY_CLIENT');
  const hasSellerStockOrForm4Form6 = clientDetailDto.hasSellerStockOrForm4Form6;
  const canDelete = !hasSellerStockOrForm4Form6 && (clientDetailDto.isCompanyClient ? canDeleteCompanyClient : canDeleteClient && (canDeleteOwnClient || canDeleteUnownedClient));

  const { langClientDetail, lang } = useSelector((state: IRootState) => state.locale);
  const [duplicatedContact, setDuplicatedContact] = useState(false);
  const clientDetailForm = useForm<ClientDetailDTO>({
    ...clientDetailDto,
  }, {
    label: 'clientDetailForm',
    validations: ClientDetailFormValidations(langClientDetail), scrollToErrorOnValidate: true, dataKeyPrefix: 'clientdetails'
  });

  useEffect(() => {
    dispatch({ type: 'Client.Memo.FetchRequested', payload: { cid: cid ?? '' } } as ClientActions);
  }, []);

  const agentContactsForm = useForm<any>({
    agentId: '',
    agentNameEn: '',
    agentNameZh: '',
    agentContact: {}
  }, { label: 'agentContactsForm' });

  const otherContactsForm = useForm<any>({
    otherContacts: []
  }, { label: 'otherContactsForm' });

  const tagForm = useForm<{ [type: string]: string[] }>({
    view: [],
    otherFeatures: [],
    symbol: [],
    clientType: [],
    usage: [],
    facing: [],
    deco: [],
    livingRoom: [],

    propertyStatus: [],
    clubHouseFacilities: [],
    others: [],
    secondarySchoolNet: [],
    primarySchoolNet: [],
  }, { label: 'tagForm', validations: [] });

  const multiSelectForm = useForm<{ [type: string]: string[] }>({
    room: [],
    suite: [],
    livingRoom: [],
    helperRoom: [],
    balcony: [],
    bathroom: [],
    // propertyStatus: [],
    district: [],
    level: [],
  }, { label: 'multiSelectForm', validations: [], });

  const preferenceForm = useForm<ClientPreferencesDTO>({
    // const preferenceForm = useForm<Partial<ClientPreferencesDTO>>({
    //remove later
    // gross: [ 0, 3000 ],
    // net: [ 0, 3000 ],
    // price: [ 3000000, 200000000 ],
    // rent: [ 3000, 15000 ],

    // facingArray: [],
  }, { label: 'preferenceForm', validations: PreferenceFormValidations(langClientDetail), });

  const fileForm = useForm<ClientFileMap>({
    FORM_4: [],
    FORM_6: [],
  } as ClientFileMap);

  // const dummyForm = useForm<any>({
  //   usage: [],
  //   status: [],
  //   district: [],
  //   level: [],

  //   // Free-text Searching
  //   street: '',
  //   building: '',

  //   // Range values, can be Infinity on each side, for whcich case it shoud be filled with -1
  //   // Query string example: ...&gross=-1,300&...
  //   gross: [ 0, 3000 ],
  //   net: [ 0, 3000 ],
  //   price: [ 3000000, 200000000 ],
  //   rent: [ 3000, 15000 ],
  // }, { label: 'dummyForm' });

  // Request API again when the id param changed
  useEffect(() => {
    dispatch({ type: 'ClientDetail.FetchRequested', payload: { cid: cid ?? '' } });
  }, []);

  // Refresh from object when clientDetail changed
  useEffect(() => {

    // ---- client detail 
    const clientFormValues = {
      ...clientDetailDto,
      clientPreferences: {
        ...clientDetailDto.clientPreferences,
        clientType: clientDetailDto.clientPreferences?.clientType?.split(',').sort().join(",")
      }
    };

    clientDetailForm.setValues(clientFormValues);

    // ---- peference form
    const preferenceFormValues: Partial<ClientPreferencesDTO> = {
      ...clientDetailDto.clientPreferences,
      clientType: clientDetailDto.clientPreferences?.clientType?.split(',').sort().join(",")
      // gross: [ 0, 3000 ],
      // net: [ 0, 3000 ],
      // price: [ 3000000, 200000000 ],
      // rent: [ 3000, 15000 ],
    };

    preferenceForm.setValues({
      ...preferenceFormValues,
    });

    // ---- agent contacts
    const agentContactsFormValues = {
      agentId: clientDetailDto.agentId,
      agentNameEn: clientDetailDto.agentNameEn,
      agentNameZh: clientDetailDto.agentNameZh,
      agentContact: clientDetailDto.agentContact,
    };

    agentContactsForm.setValues(agentContactsFormValues);

    // ---- client contacts
    const otherContactsFormValues = {
      otherContacts: clientDetailDto.otherContacts
    };

    otherContactsForm.setValues(otherContactsFormValues);

    // ---- handle tag from
    const featureMap: { [type: string]: string[] } = {};
    clientDetailDto.clientFeatures?.filter(f => isNonEmpty(f.value))?.forEach(f => {
      if (!featureMap[f.type]) {
        featureMap[f.type] = [];
      }
      featureMap[f.type].push(f.value);
    });

    const tagFormValues = {
      ...tagForm.values,
      ...featureMap,
    }

    tagForm.setValues(tagFormValues);

    // ---- handle multiSelectForm
    const multiSelectFormValues = {
      ...multiSelectForm.values,
      room: clientDetailDto.clientPreferences?.room?.trim().split(',') ?? [],
      suite: clientDetailDto.clientPreferences?.suite?.trim().split(',') ?? [],
      livingRoom: clientDetailDto.clientPreferences?.livingRoom?.trim().split(',') ?? [],
      helperRoom: clientDetailDto.clientPreferences?.helperRoom?.trim().split(',') ?? [],
      balcony: clientDetailDto.clientPreferences?.balcony?.trim().split(',') ?? [],
      bathroom: clientDetailDto.clientPreferences?.bathroom?.trim().split(',') ?? [],
      propertyStatus: clientDetailDto.clientPreferences?.propertyStatus?.trim().split(',') ?? [],
      // district: clientDetailDto.clientPreferences?.district?.trim().split(',') ?? [],
      level: clientDetailDto.clientPreferences?.level?.trim().split(',') ?? [],
    };

    multiSelectForm.setValues(multiSelectFormValues);

    // ---- handle files
    const fileMap: ClientFileMap = {
      FORM_4: [],
      FORM_6: [],
    };

    clientDetailDto.form4Attachments?.forEach(attachment => {
      const type = 'FORM_4';
      if (fileMap[type]) {
        fileMap[type].push(attachment);
      }
    });

    clientDetailDto.form6Attachments?.forEach(attachment => {
      const type = 'FORM_6';
      if (fileMap[type]) {
        fileMap[type].push(attachment);
      }
    });
    fileForm.setValues(fileMap);

    setInitialFormValues([clientFormValues, preferenceFormValues, agentContactsFormValues,
      otherContactsFormValues, tagFormValues, multiSelectFormValues, fileMap]);

  }, [clientDetailDto]);

  //update ClientDetailForm.clientPreference 
  useEffect(() => {
    clientDetailForm.setValues({
      ...clientDetailForm.values,
      clientPreferences: {
        ...preferenceForm.values,
        clientType: preferenceForm.values?.clientType?.split(',').sort().join(",")
      }
    });
  }, [preferenceForm.values]);


  useEffect(() => {
    clientDetailForm.setValues({
      ...clientDetailForm.values,
      agentId: agentContactsForm.values.agentId,
      agentNameEn: agentContactsForm.values.agentNameEn,
      agentNameZh: agentContactsForm.values.agentNameZh,
      agentContact: agentContactsForm.values.agentContact
    })
  }, [agentContactsForm.values])

  //update clientDetailForm.otherContacts 
  useEffect(() => {
    clientDetailForm.setValues({
      ...clientDetailForm.values,
      otherContacts: otherContactsForm.values.otherContacts,

    });
  }, [otherContactsForm.values]);

  //update client type and usage (for sorting)
  useEffect(() => {
    clientDetailForm.setValues({
      ...clientDetailForm.values,
      clientPreferences: {
        ...clientDetailForm.values.clientPreferences,
        usage: tagForm.values.usage?.join(','),
        clientType: tagForm.values.clientType?.sort()?.join(','),
      },
    });
  }, [tagForm.values.usage, tagForm.values.clientType]);

  const trimSpace = (array: string[] | undefined) => {
    const joinStr = array?.filter(v => v != '').join(',');
    if (joinStr == '') {
      return null;
    }
    return joinStr;
  }

  //handle multiSelectForm
  useEffect(() => {
    clientDetailForm.setValues({
      ...clientDetailForm.values,
      clientPreferences: {
        ...clientDetailForm.values.clientPreferences,
        room: trimSpace(multiSelectForm.values.room),
        suite: trimSpace(multiSelectForm.values.suite),
        livingRoom: trimSpace(multiSelectForm.values.livingRoom),
        helperRoom: trimSpace(multiSelectForm.values.helperRoom),
        balcony: trimSpace(multiSelectForm.values.balcony),
        bathroom: trimSpace(multiSelectForm.values.bathroom),
        propertyStatus: trimSpace(multiSelectForm.values.propertyStatus),
        // district: trimSpace(multiSelectForm.values.district),
        level: trimSpace(multiSelectForm.values.level),
      },
    });
  }, [multiSelectForm.values]);

  useEffect(() => {
    preferenceForm.updateValues('level',
      // ...preferenceForm.values,
      // district: trimSpace(multiSelectForm.values.district),
      trimSpace(multiSelectForm.values.level)
    );
  }, [multiSelectForm.values.level])

  const onRemove = () => {
    dispatch({
      type: 'Client.RemoveRequested',
      payload: { cid: cid ?? '' }
    });
  }
  // useEffect(() => {
  //   
  //   
  //   
  //   
  //   
  //   
  // }, [tagForm.values, preferenceForm.values, multiSelectForm.values]);
  // const copy = {} as any;

  const [initialFormValues, setInitialFormValues] = useState<any>({});
  const unsaved = !deepEqual([clientDetailForm.values, preferenceForm.values, agentContactsForm.values, otherContactsForm.values, tagForm.values, multiSelectForm.values, fileForm.values], initialFormValues, { strict: true });

  // 
  // 
  // 
  // 
  // 
  // 
  // 

  // 
  // 
  const onSave = () => {
    if (!clientDetailForm.validate()) {
      return;

    } else if (duplicatedContact) {
      confirmCreateDialog.confirm(langClientDetail.msgDuplicatedContactOnSave, lang.actionConfirm, lang.actionCancel).then((confirm) => {
        if (confirm) {
          const assembleFormToDto = () => {
            const updatedResult = {
              ...clientDetailForm.values,
              //TODO: unit transformation for budget??
              clientFeatures: Object.keys(tagForm.values)
                .map(type => tagForm.values[type as keyof ClientMultiValueMap]
                  ?.map(value => ({ type, value, id: clientDetailDto.clientFeatures?.filter(f0 => f0.type === type && f0.value === value)[0]?.id }))
                )
                .reduce((a, b) => (a ?? []).concat(b ?? []), []),

              form4Attachments: (fileForm.values["FORM_4"] ?? [])
                .reduce((a: any, b: any) => (a ?? []).concat(b ?? []), []),
              //isManualUpload, purchaserName will be handled in backend

              form6Attachments: (fileForm.values["FORM_6"] ?? [])
                .reduce((a: any, b: any) => (a ?? []).concat(b ?? []), []),
              //isManualUpload, tenantName will be handled in backend
            } as Record<string, any>;
            Object.keys(updatedResult).forEach(key => {
              if (typeof updatedResult[key as keyof ClientDetailDTO] === "string" && updatedResult[key as keyof ClientDetailDTO].trim() === "") {
                updatedResult[key as keyof ClientDetailDTO] = null;
              }
              if (key === 'otherContacts') {
                updatedResult["otherContacts"].forEach((contact: any) => {
                  Object.keys(contact).forEach((key2: any) => {
                    if (typeof contact[key2] === "string" && contact[key2].trim() === "") {
                      contact[key2] = null;
                    }
                  })
                })
              } else if (key === 'clientPreferences') {
                Object.keys(updatedResult["clientPreferences"]).forEach(key2 => {
                  if (typeof updatedResult["clientPreferences"][key2 as keyof ClientPreferencesDTO] === "string" && updatedResult["clientPreferences"][key2 as keyof ClientPreferencesDTO].trim() === "") {
                    updatedResult["clientPreferences"][key2 as keyof ClientPreferencesDTO] = null;
                  }
                })
              }

            });
            return (updatedResult as ClientDetailDTO);

          }
          dispatch({
            type: 'ClientDetail.UpdateRequested', payload: assembleFormToDto()
          });
        }
      })
    } else {
      const assembleFormToDto = () => {
        const updatedResult = {
          ...clientDetailForm.values,
          //TODO: unit transformation for budget??
          clientFeatures: Object.keys(tagForm.values)
            .map(type => tagForm.values[type as keyof ClientMultiValueMap]
              ?.map(value => ({ type, value, id: clientDetailDto.clientFeatures?.filter(f0 => f0.type === type && f0.value === value)[0]?.id }))
            )
            .reduce((a, b) => (a ?? []).concat(b ?? []), []),

          form4Attachments: (fileForm.values["FORM_4"] ?? [])
            .reduce((a: any, b: any) => (a ?? []).concat(b ?? []), []),
          //isManualUpload, purchaserName will be handled in backend

          form6Attachments: (fileForm.values["FORM_6"] ?? [])
            .reduce((a: any, b: any) => (a ?? []).concat(b ?? []), []),
          //isManualUpload, tenantName will be handled in backend
        } as Record<string, any>;
        Object.keys(updatedResult).forEach(key => {
          if (typeof updatedResult[key as keyof ClientDetailDTO] === "string" && updatedResult[key as keyof ClientDetailDTO].trim() === "") {
            updatedResult[key as keyof ClientDetailDTO] = null;
          }
          if (key === 'otherContacts') {
            updatedResult["otherContacts"].forEach((contact: any) => {
              Object.keys(contact).forEach((key2: any) => {
                if (typeof contact[key2] === "string" && contact[key2].trim() === "") {
                  contact[key2] = null;
                }
              })
            })
          } else if (key === 'clientPreferences') {
            Object.keys(updatedResult["clientPreferences"]).forEach(key2 => {
              if (typeof updatedResult["clientPreferences"][key2 as keyof ClientPreferencesDTO] === "string" && updatedResult["clientPreferences"][key2 as keyof ClientPreferencesDTO].trim() === "") {
                updatedResult["clientPreferences"][key2 as keyof ClientPreferencesDTO] = null;
              }
            })
          }
        });
        return (updatedResult as ClientDetailDTO);

      }
      dispatch({
        type: 'ClientDetail.UpdateRequested', payload: assembleFormToDto()
      });
    }
  }

  const onMatch = () => {

    dispatch({ type: 'PropertyMatchingList.ResetPropertyList' });

    dispatch({
      type: 'PropertyMatching.StartMatching', payload: {
        cid: clientDetailForm.values.cid ?? '',
        name: clientDetailForm.values.chineseName ?? '',
        nameEn: clientDetailForm.values.englishName ?? '',
        clientPreference: {
          usage: tagForm.values.usage,
          // TODO: status
          isGross: preferenceForm.values.isGross,
          isRent: preferenceForm.values.isRent,
          price: [preferenceForm.values.buyBudgetFrom ?? DEFAULT_FROM, preferenceForm.values.buyBudgetTo ?? DEFAULT_TO] as [number, number],
          rent: [preferenceForm.values.rentBudgetFrom ?? DEFAULT_FROM, preferenceForm.values.rentBudgetTo ?? DEFAULT_TO] as [number, number],
          net: [preferenceForm.values.netAreaFrom ?? DEFAULT_FROM, preferenceForm.values.netAreaTo ?? DEFAULT_TO] as [number, number],
          gross: [preferenceForm.values.grossAreaFrom ?? DEFAULT_FROM, preferenceForm.values.grossAreaTo ?? DEFAULT_TO] as [number, number],
          district: preferenceForm.values.district?.trim()?.split(',')?.filter(v => isNonEmpty(v)) ?? [],
          level: multiSelectForm.values.level,
          facing: tagForm.values.facing,
          deco: tagForm.values.deco,
          view: tagForm.values.view,
          otherFeatures: tagForm.values.otherFeatures,
          livingRooms: tagForm.values.livingRoom,
          status: tagForm.values.propertyStatus,
          clubHouseFacilities: tagForm.values.clubHouseFacilities,
          others: tagForm.values.others,
          secondarySchoolNet: tagForm.values.secondarySchoolNet,
          primarySchoolNet: tagForm.values.primarySchoolNet,
          room: multiSelectForm.values.room ?? [],
          suite: multiSelectForm.values.suite ?? [],
          helperRoom: multiSelectForm.values.helperRoom ?? [],
          balcony: multiSelectForm.values.balcony ?? [],
          bathroom: multiSelectForm.values.bathroom ?? [],
          vacant: preferenceForm.values.vacant ? [preferenceForm.values.vacant] : [],
          building: preferenceForm.values.building,
          street: preferenceForm.values.street,
        } as Partial<PropertySearchDTO>,

      }
    })
    // setPendingForMatching(false);
    history.push('/matching-properties');
  }

  // 
  // 
  // 

  // const matching = pendingForMatching && !loading && loadSuccess;

  return (
    <div>
      {/* <Prompt 
            when={unsaved}
            message={"Unsaved"}
          /> */}
      {/* {confirmDialog.render()} */}
      <NavigationBlockerDialog matchesUrl={`/client-detail/${cid}`} unsaved={unsaved && !pendingForMatching} goBack />
      {alertDialog.render()}
      {onMatchDialog.render()}
      <ClientDetail unsaved={unsaved} confirmCreateDialog={confirmCreateDialog} onSave={() => {
        onSave();
      }}
        onRemove={onRemove}
        onMatch={() => {
          if (unsaved) {
            onMatchDialog.confirm(langClientDetail.msgPleaseSaveBeforeMatching, lang.actionConfirm, lang.actionClose).then(confirmed => {
              if (confirmed) {
                if (!pendingForMatching) setPendingForMatching(true);
                onSave();
                onMatch();
              }
            });
            // return;
          } else {
            onMatch();
          }


        }}
        form={clientDetailForm}
        creating={false}
        tagForm={tagForm}
        preferenceForm={preferenceForm}
        agentContactsForm={agentContactsForm}
        otherContactsForm={otherContactsForm}
        multiSelectForm={multiSelectForm}
        memos={memos}
        fileForm={fileForm}
        canEdit={canUpdate}
        canDelete={canDelete}
        setDuplicatedContact={setDuplicatedContact}
      />
    </div>
  );
}
