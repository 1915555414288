import { DialogTitle, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import { useForm, FormPresenter, useMenu } from 'common/hooks';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { isNonEmpty } from './utils';

interface DialogProps {
  open: boolean;
  onClose: (confirmed: boolean) => void;

  title?: React.ReactNode;
  message: React.ReactNode;
  actionConfirm: string;
  actionCancel: string;
  style?: React.CSSProperties;
}

const useStyles = makeStyles(() => ({
  dialogContent: {
    minWidth: 300,
    display: 'flex',
    alignItems: 'center',
  },
}));

export const useInfoDialog = () => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState<React.ReactNode>('');
  const [message, setMessage] = useState<React.ReactNode>('');
  const [actionCancel, setActionCancel] = useState('');
  const [actionConfirm, setActionConfirm] = useState('');
  // const onClose = React.useRef((_: boolean) => {});
  const [onClose, setOnClose] = useState({
    current: (confirmed: boolean) => { },
  });

  return {
    render: (customStyle?: React.CSSProperties) => <InfoDialog
      open={open} onClose={onClose.current ?? (() => { })}
      title={title}
      message={message}
      actionCancel={actionCancel} actionConfirm={actionConfirm}
      style={customStyle} />,
    confirm: (message: React.ReactNode, actionConfirm: string, actionCancel: string, title = ''): Promise<boolean> => {
      setOpen(true);
      setTitle(title);
      setMessage(message);
      setActionCancel(actionCancel);
      setActionConfirm(actionConfirm);
      return new Promise<boolean>((resolve) => {
        setOnClose({
          current: (confirmed) => {
            resolve(confirmed);
            setOpen(false);
          },
        });
      });
    },
  }
}

export const InfoDialog = ({ open, onClose, message, actionCancel, actionConfirm, title, style }: DialogProps) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => onClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={style}
      >
        {isNonEmpty(title) ?
          <DialogTitle><Typography style={{ fontWeight: 'bold' }}>{title}</Typography></DialogTitle>
          : null}
        <DialogContent className={classes.dialogContent}>
          <DialogContentText id="alert-dialog-description" style={{ width: '100%' }}>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false)} color="primary">
            {actionCancel}
          </Button>
          {/* <Button onClick={() => onClose(true)} color="primary" variant="contained"  autoFocus>
            {actionConfirm}
          </Button> */}
        </DialogActions>
      </Dialog>
    </div >
  );
}

export default InfoDialog;