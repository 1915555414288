import notificationApi from 'api/notificationApi';
import systemSettingApi from 'api/systemSettingApi';
import { LoginStateDTO } from "common/dto";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { PASSagaContext } from 'sagas';
import { store } from "store";
import { loginSuccess } from '../actions';
import { ApiResult, fetchLogin, loadOptions, logout, renew } from '../api';
import {
    BuildingActions, GlobalActions, IRootState, LocaleActions, LoginActions, TransactionActions, UserActions
} from "../reducers";


export function* watchLoginRequestStart(context: PASSagaContext) {
    yield takeLatest(
        'Login.Start',
        requestLogin,
        context
    );
}

export function* watchLoginLogoutRequested(context: PASSagaContext) {
    yield takeLatest(
        'Login.LogoutRequested',
        requestLogout,
        context
    );
}

const envUrl = process.env['PUBLIC_URL'].replace("/", "");
function* requestLogin(context: PASSagaContext, action: Extract<LoginActions, { type: 'Login.Start' }>) {
    const payload = action.payload;
    const env = process.env['PUBLIC_URL'];
    const { lang } = yield select((state: IRootState) => state.locale);
    const { deviceId } = yield select((state: IRootState) => state.login);

    try {
        const response: ApiResult<LoginStateDTO> = yield call(fetchLogin, { ...payload, deviceId });
        if (response.data?.token) {
            sessionStorage.setItem('newLogin', 'true');
            window.localStorage.setItem('logoutEnv', env);
            localStorage.removeItem('impersonateUserToken');
            yield put(loginSuccess(response.data));
            yield put<UserActions>({ type: 'User.FetchRequested', payload: { id: response.data?.uid } });
            // Update user preference related module
            const rowsCountPreference = JSON.parse(response.data?.rowsCountPreference);
            yield put<BuildingActions>({ type: 'BuildingList.CriteriaUpdated', payload: { size: rowsCountPreference?.BUILDING_MASTER ?? 10 } });
            yield put<TransactionActions>({ type: 'TransactionList.CriteriaUpdated', payload: { size: rowsCountPreference?.TRANSACTION ?? 10 } });

            // Load notifications
            const { data: notifications, error: notificationLoadError } = yield call(notificationApi.getList, response.data?.token);
            if (!notificationLoadError) {
                yield put({ type: 'Notifications.Loaded', payload: notifications });
            }

            // Reload system settings
            const { data: systemSettingData, error: systemSettingError } = yield call(systemSettingApi.getListForFrontend, response.data?.token);
            if (systemSettingData) {
                yield put({ type: 'SystemSetting.Loaded', payload: { data: systemSettingData } });
            }

            // Reload locale bundles
            const { data: optionData, error: optionLoadError } = yield call(loadOptions, response.data?.token);
            if (optionData) {
                yield put({ type: 'Locale.RemoteBundleLoaded', payload: optionData });
            }

            const PROFILE: string = yield select((state: IRootState) => state.systemSettings?.BuildInfo?.ENV?.PROFILE) ?? null;
            window.SCBeacon?.trackEvent("Agent contact event", {
                xagentId: `pas-app-${PROFILE.toLowerCase()}-` + response.data?.uid,
                xfirstName: response.data?.englishName ?? response.data?.chineseName,
                xlastName: '',
                xemail: response.data?.email,
                xlanguage: response.data?.language
            });


            // Switch Locale
            const { DEFAULT_DISPLAY_LANGUAGE } = yield select((state: IRootState) => state.systemSettings?.System?.SYSTEM_PARAM);
            yield put<LocaleActions>({ type: 'Locale.SwitchLocale', locale: response.data?.language ?? DEFAULT_DISPLAY_LANGUAGE ?? 'zh_HK' });

            // Force change password
            if (response.data?.passwordExpired) {
                yield call(context.browserHistory.replace as any, `/force-change-password`);
            } else {
                // Navigate
                yield call(context.browserHistory.replace as any, `/`);
            }
        } else {
            switch (response.error) {

                case 'ERR_MAX_PW_FAIL_ATTEMPT':
                    yield put({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgPwMaxFailAttempt } });
                    break;

                default:
                    yield put({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgUserPwIncorrect } });
            }
            yield put<LoginActions>({ type: 'Login.Failed' });

        }
        window.localStorage.setItem('timestamp', Date.now().toString());
        let loginTimeout = setTimeout(() => { sessionStorage.setItem('newLogin', 'false'); localStorage.removeItem('logoutEnv'); clearTimeout(loginTimeout) }, 1000);

    }
    catch (error) {

    }
}

function* requestLogout(context: PASSagaContext, action: Extract<LoginActions, { type: 'Login.LogoutRequested' }>) {
    const deviceId: string | undefined = yield select((root: IRootState) => root.login.deviceId);
    const token: string = yield select((root: IRootState) => root.login.token);
    yield call(logout, token, deviceId);
    deleteAllCookies();
    yield put<LoginActions>({ type: 'Login.LoggedOut' });
    yield put<GlobalActions>({ type: 'Global.ClearCacheRequested', payload: null });
    context.browserHistory.length = 0;
    context.browserHistory.replace('/sign-in');
    const ITEM_NAME = process.env['PUBLIC_URL'] + '/state';
    localStorage.removeItem(ITEM_NAME);
    localStorage.removeItem('impersonateUserToken');
    sessionStorage.clear();
}

function deleteAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}

export const renewAndReloadToken = (token: string) => {
    if (token) {
        try {
            renew(token!).then(res => (store.dispatch({ type: 'Login.Success', payload: { ...res.data! } })))
            store.dispatch({ type: 'Layout.DataReloadRequested' });

        }
        catch (e) {
            localStorage.removeItem('impersonateUserToken');
            store.dispatch({ type: 'Login.LogoutRequested' });
            return { error: String(e) };

        }
    }
}
