import { PropertyDraftUpdateDTO } from 'common/dto';
import { FormValidations } from 'common/hooks';
import { isNonEmpty } from 'common/utils';
import { format } from 'util';

type Options = { [key: string]: string };
const VALID_FLOOR_UNIT = /^[\d\w\,\-]*[\d\w\-]$/;

export default function propertyDraftEditValidations(lang: Options, usageOptions: Options, districtOptions: Options): FormValidations<PropertyDraftUpdateDTO> {
  const validBuildingNames = (values: Partial<PropertyDraftUpdateDTO>) =>
    !!(values.buildingNameEn?.trim()) || !!(values.buildingNameZh?.trim());

  return [
    (values) => validBuildingNames(values) ? null : [['buildingNameEn', lang.msgPleaseInputValidBuildingName]],
    (values) => validBuildingNames(values) ? null : [['buildingNameZh', lang.msgPleaseInputValidBuildingName]],

    (values) => !!(values.usage?.trim()) ? null : [['usage', lang.msgPleaseInputValidUsage]],
    (values) => validFloorUnits(values.floor) ? null : [['floor', lang.msgPleaseInputValidFloor]],
    (values) => validFloorUnits(values.unit) ? null : [['unit', lang.msgPleaseInputValidUnit]],

    (values) => usageOptions[values.usage ?? ''] ? null : [['usage', format(lang.msgInvalidUsage, values.usage ?? '-')]],
    (values) => districtOptions[values.district ?? ''] ? null : [['district', format(lang.msgInvalidDistrict, values.district ?? '-')]],
  ];
}

export function validFloorUnits(literal: string | undefined) {
  const validLiteral = !isNonEmpty(literal) || VALID_FLOOR_UNIT.test(literal ?? '');
  if (validLiteral) {
    const list = literal?.split(',') ?? [];
    return list.length === ([...new Set(list)]).length;
  } else {
    return false;
  }
}