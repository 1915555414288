import { makeStyles, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { userApi } from 'api';
import { UserListItemDTO } from 'common/dto';
import { useForm } from 'common/hooks';
import { isNonEmpty, multiLang } from 'common/utils';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';



const useStyles = makeStyles(() => ({
	root: {

	},
	patchOutlineAutocomplete: {
		'& .MuiAutocomplete-inputRoot': {
			padding: 0,
			paddingLeft: 8,
		}
	},
	dialogContent: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		width: 350,
	},
	textArea: {
		width: '90%'
	}
}));

export interface UserSelectionDialogProps {
	open: boolean;
	onClose?: (selectedUid?: number | undefined) => any;
	actionName?: string;
	description?: string;
	// agentOnly?: boolean;
}

export default function UserSelectionDialog(props: UserSelectionDialogProps) {
	const [newAgentNameEn, setNewAgentNameEn] = useState<string | undefined>(undefined);
	const [newAgentNameZh, setNewAgentNameZh] = useState<string | undefined>(undefined);
	const [newAgentId, setNewAgentId] = useState<string | undefined>(undefined);

	const classes = useStyles();
	const { langClientDetail, lang, locale } = useSelector((state: IRootState) => state.locale);

	const handleNoSelection = () => {
		props.onClose?.(undefined);
		form.updateValues('newAgentId', undefined);
		form.stopValidation();
	};

	const handleSelectedAndClose = () => {
		if (form.validate()) {
			props.onClose?.(form.values.newAgentId);
			form.updateValues('newAgentId', undefined);
			form.stopValidation();
		}
	};

	// Validation
	const form = useForm<any>({}, {
		validations: [
			(values) => !values.newAgentId ? [['newAgentId', '']] : null,
		],
	});

	// Autcomplete
	const [agents, setAgents] = useState<UserListItemDTO[]>([]);
	const [agentListLoading, setAgentListLoading] = useState(false);

	const token: string = useSelector((state: IRootState) => state.login.token ?? '');

	const fetchAgents = (keyword: string) => {
		if (keyword.length < 2) { return; }
		setAgentListLoading(true);
		userApi.getListForAutocomplete({ name: keyword, onlyHasCreateOwnClientPrivilege: true }, token).then(result => {
			if (result.data?.content) {
				setAgents(result.data!.content);
				setAgentListLoading(false);
				setAgentUsername(result.data!.content.filter(agent => agent.username != 'companyStockAgent').map((agent) => agent.username));

			}
		});
	}

	const [agentUsername, setAgentUsername] = useState(agents.filter(agent => agent.username != 'companyStockAgent').map((agent) => agent.username));
	const agentMap: { [name: string]: UserListItemDTO } = {};
	agents.forEach((agent) => {
		agentMap[agent.username] = agent;
	})

	const [keywordOfAgent, setKeywordOfAgent] = useState('')

	useEffect(() => {
		const timer = setTimeout(() => {
			fetchAgents(keywordOfAgent);
		}, 1000);
		return () => clearTimeout(timer);
	}, [keywordOfAgent]);

	useEffect(() => {
		setNewAgentId(undefined);
		setNewAgentNameEn(undefined);
		setNewAgentNameZh(undefined);
	}, [props.open]);

	useEffect(() => {
		form.setValues({ newAgentId: newAgentId, newAgentNameEn: newAgentNameEn, newAgentNameZh: newAgentNameZh });
	}, [newAgentId]);


	return (
		<div>
			<Dialog open={props.open} onClose={handleNoSelection} aria-labelledby="assign-agent-dialog">
				<DialogTitle id="assign-agent-dialog-title">{props.actionName}</DialogTitle>
				<DialogContent className={classes.dialogContent}>
					{props.description ? <Typography variant="body2">{props.description}</Typography> : null}
					<Autocomplete
						style={{ width: '100%' }}
						options={agentUsername}
						noOptionsText={''}
						loading={agentListLoading}
						popupIcon={""}
						loadingText={lang.msgLoading}
						filterOptions={(option) => option}
						renderOption={(option) => <div>
							<div><Typography >{`${agents.filter(agent => agent.username === option)?.[0]?.englishName ?? ''}${agents.filter(agent => agent.username === option)?.[0]?.chineseName ? '/' : ''}${agents.filter(agent => agent.username === option)?.[0]?.chineseName ?? ''}`}</Typography></div>
							<div><Typography variant="caption">{agents.filter(agent => agent.username === option)?.[0]?.mainContact}</Typography></div>
						</div>}
						renderInput={(params: any) => (
							<TextField
								{...params}
								className={classes.patchOutlineAutocomplete}
								error={!!form.hasError('newAgentId')}
								fullWidth
								label={langClientDetail.contactName}
								margin="dense"
								//required
								variant="outlined"
								onFocus={(ev) => {
									setAgentUsername([]);
									setKeywordOfAgent(ev.target.value);
								}}
								onChange={(ev) => {
									// fetchAgents(ev.target.value)
									setAgents([]);
									setAgentUsername([]);
									setKeywordOfAgent(ev.target.value);
									// setAgentListLoading(true);
									if (ev.target.value.length < 2 || !isNonEmpty(ev.target.value)) {
										setAgentListLoading(false);
									}
								}}
								onBlur={() => form.validate()}
							/>
						)}
						value={multiLang(locale, form.values.newAgentNameZh, form.values.newAgentNameEn) ?? ''}
						onChange={(_: any, val: any) => {
							if (agentMap[val]) {
								const { id, chineseName, englishName } = agentMap[val];
								// form.updateValues('newAgentId', id);
								setNewAgentId(id)
								setNewAgentNameEn(englishName);
								setNewAgentNameZh(chineseName);
							} else {
								// form.updateValues('newAgentId', undefined);
								setNewAgentId(undefined)
								setNewAgentNameEn(undefined);
								setNewAgentNameZh(undefined);
							}
						}}
						getOptionLabel={() => multiLang(locale, form.values.newAgentNameZh, form.values.newAgentNameEn) ?? ''}
					/>

				</DialogContent>
				<DialogActions>
					<Button onClick={handleNoSelection} color="primary">
						{lang.actionCancel}
					</Button>
					<Button onClick={handleSelectedAndClose} color="primary" variant="contained" autoFocus>
						{lang.actionConfirm}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}