import { ApiError, ApiPageResult } from 'api';
import commissionReportApi from 'api/commissionReportApi';
import { CommissionReportListItemDTO } from 'common/dto';
import { CommissionReportActions, IRootState } from 'reducers';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PASSagaContext } from 'sagas';
import { apiTaskWrapper } from './saga-commons';


export function* watchCommissionReportListFetchRequested(context: PASSagaContext) {
  yield takeLatest(
    'CommissionReportList.FetchRequested',
    apiTaskWrapper(requestCommissionReportList),
    context,
  );
}

export function* requestCommissionReportList(context: PASSagaContext, action: Extract<CommissionReportActions, { type: 'CommissionReportList.FetchRequested' }>) {
  if (action.type !== 'CommissionReportList.FetchRequested') {
    return;
  }

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  // yield delay(1000);

  const result: ApiPageResult<CommissionReportListItemDTO> = yield call(commissionReportApi.getList, action.payload, token);
  

  if (!result.error) {
    yield put<CommissionReportActions>({ type: 'CommissionReportList.Loaded', payload: result.data! });
  } else {
    throw ApiError.of(result.error!);
  }

}
