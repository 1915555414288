import {
  Card,
  CardContent,
  Divider,
  Grid, MenuItem, TextField, FormHelperText,
  withStyles
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useConfirmDialog } from 'common/ConfirmDialog';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { initFormKeysAndBooleanMap, isNonEmpty, limitNumberFieldLength, limitTextFieldLength } from 'common/utils';
import { HandleFormHelperText } from 'common/ui';

const useStyles = makeStyles(() => ({
  root: {
  },
  patchOutlineAutocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
      paddingLeft: 8,
    }
  },
  label: {
    fontSize: '14px',
    paddingTop: '8px'
  },
  filecontainer: {
    paddingLeft: '16px'
  },
  filename: {
    fontSize: '14px',
    paddingTop: '16px',
    paddingLeft: '16px'
  }
}));

// const NumberField = withStyles({
//   root: {
//     '& input': {
//       textAlign: 'right',
//     },
//   },
// })(TextField);
const regex = new RegExp(/(^\d*\.?\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/);
const NumberField = withStyles({
  root: {
    '& input': {
      textAlign: 'right',
      '-moz-appearance': 'textfield',
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  },
},
)((props) => {
  return <TextField type={"text"}{...props} inputProps={{
    ...props.inputProps,
    min: 0,
    max: Math.max(),
    inputMode: 'decimal',
    pattern: "([^0-9]*)",
    step: 0.01
  }}

    onKeyDown={e => {
      // If the key pressed is not a number or a period, nothing is printed
      if (!e.key.match(regex)) {

        e.preventDefault();

      }
      // if (!e.key.match(regex) || (e.key === '.' && String(props.value).indexOf('.') > 0) || (e.key === '.' && String(props.value).length === 0) || e.keyCode === 229 || e.key === 'Unidentified') {

      //   e.preventDefault();

      // }

    }}

    onKeyPress={e => {
      // If the key pressed is not a number or a period, nothing is printed
      if (!e.key.match(regex)) {

        e.preventDefault();

      }
    }}

  />;
});

const PropertyManagementInfo = props => {
  const { className, tagForm, fileForm, clientFileForm, creating, canDownloadOrDeleteFiles, ...rest } = props;
  const { langPropertyStock: lang, mgtFeeInclOptions, profileOptions, commissionOptions } = useSelector((state) => state.locale);
  /** @type {import('common/hooks').FormPresenter<import('common/dto').PropertyStockDTO>} */
  const form = rest.form;
  const bind = form.bind;
  const [keyBooleanMap, setKeyBooleanMap] = useState(initFormKeysAndBooleanMap(form));
  const classes = useStyles(0);

  useEffect(() => {
    setKeyBooleanMap(initFormKeysAndBooleanMap(form));
  }, [Object.keys(form.values).length])

  const confirmDialog = useConfirmDialog();

  const [filteredOptions, setFilteredOptions] = useState([]);

  useEffect(() => {
    setFilteredOptions(Object.values(commissionOptions));
  }, [commissionOptions]);

  const filterOptions = (keyword) => {
    if (keyword.length < 1) { return; }
    setFilteredOptions(filteredOptions.filter(option => option.includes(keyword)));
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
      >
        {confirmDialog.render()}
        {/* <CardHeader
          subheader="The information can be edited"
          title="Profile"
        />
        <Divider /> */}
        <CardContent>
          {/* <Grid
              item
              md={12} sm={12}
              xs={12}
            >
              <Typography h2>物業資料</Typography>
          </Grid> */}

          <Grid
            container
            spacing={1}
          >

            <Grid
              item
              md={6} sm={8}
              xs={12}
            >
              <TextField
                fullWidth
                label={lang.developer}
                margin="dense"
                variant="outlined"
                {...bind("developer")}
                onChange={(e) => {
                  limitTextFieldLength(e, 255, 'developer', form, keyBooleanMap, setKeyBooleanMap);
                }}
              />
              <HandleFormHelperText
                isError={keyBooleanMap.get('developer')}
                errorMessage={lang.textOverLimit}
              />
            </Grid>


            <Grid
              item
              md={3} sm={4}
              xs={6}
            >
              <TextField
                fullWidth
                label={lang.assessmentNum}
                margin="dense"
                variant="outlined"
                {...bind("assessmentNum")}
                onChange={(e) => {
                  limitTextFieldLength(e, 255, 'assessmentNum', form, keyBooleanMap, setKeyBooleanMap);
                }}
              />
              <HandleFormHelperText
                isError={keyBooleanMap.get('assessmentNum')}
                errorMessage={lang.textOverLimit}
              />
            </Grid>

            <Grid
              item
              md={3} sm={4}
              xs={6}
            >
              <TextField
                fullWidth
                label={lang.prn}
                margin="dense"
                variant="outlined"
                {...bind("prn")}
                onChange={(e) => {
                  limitTextFieldLength(e, 255, 'prn', form, keyBooleanMap, setKeyBooleanMap);
                }}
              />
              <HandleFormHelperText
                isError={keyBooleanMap.get('prn')}
                errorMessage={lang.textOverLimit}
              />

            </Grid>

            <Grid
              item
              md={12} sm={8}
              xs={12}
            >
              <TextField
                fullWidth
                label={lang.ratesPropertyAddress}
                margin="dense"
                variant="outlined"
                {...bind("ratesPropertyAddress")}
                onChange={(e) => {
                  limitTextFieldLength(e, 255, 'ratesPropertyAddress', form, keyBooleanMap, setKeyBooleanMap);
                }}
              />
              <HandleFormHelperText
                isError={keyBooleanMap.get('ratesPropertyAddress')}
                errorMessage={lang.textOverLimit}
              />
            </Grid>
            <Grid
              item
              md={4} sm={4}
              xs={6}
            >
              <NumberField
                fullWidth
                label={lang.quarterlyRates}
                margin="dense"
                name="quarterlyRates"
                variant="outlined"
                {...bind("quarterlyRates")}
                onChange={(e) => {
                  limitNumberFieldLength(e, 'quarterlyRates', form);
                }}
              />
            </Grid>

            {/* <Grid
              item
              md={4} sm={4}
              xs={12}
            >
              <NumberField
                fullWidth
                label="佣金"
                margin="dense"
                variant="outlined"
                {...bind('mgtCommission')}
              />
            </Grid> */}


            <Grid
              item
              md={4} sm={4}
              xs={6}
            >
              <NumberField
                fullWidth
                label={lang.annualGovRent}
                margin="dense"
                variant="outlined"
                {...bind("annualGovRent")}
                onChange={(e) => {
                  limitNumberFieldLength(e, 'annualGovRent', form);
                }}

              />
            </Grid>

            <Grid
              item
              md={4} sm={4}
              xs={6}
            >
              <TextField
                fullWidth
                label={lang.mgtCompanyRegNum}
                margin="dense"
                variant="outlined"
                {...bind('mgtCompanyRegNum')}
                onChange={(e) => {
                  limitTextFieldLength(e, 255, 'mgtCompanyRegNum', form, keyBooleanMap, setKeyBooleanMap);
                  //console.log("lang.textOverLimit", lang);
                }}
              />
              <HandleFormHelperText
                isError={keyBooleanMap.get('mgtCompanyRegNum')}
                errorMessage={lang.textOverLimit}
              />
            </Grid>


            <Grid
              item
              md={3} sm={3}
              xs={6}
            >
              <NumberField
                fullWidth
                label={lang.monthlyMgtFee}
                margin="dense"
                variant="outlined"
                {...bind("monthlyMgtFee")}
                onChange={(e) => {
                  limitNumberFieldLength(e, 'monthlyMgtFee', form);
                }}
              />
            </Grid>

            <Grid
              item
              md={3} sm={3}
              xs={12}
            >
              <TextField
                fullWidth
                select
                label={lang.mgtFeeIncl}
                margin="dense"
                variant="outlined"
                {...bind('mgtFeeIncl')}
              >
                <MenuItem key={-1} value={null}><option aria-label="None" value="" /></MenuItem>
                {Object.keys(mgtFeeInclOptions).map((v, idx) => <MenuItem key={idx} value={v}>{mgtFeeInclOptions[v]}</MenuItem>)}
              </TextField>
            </Grid>

            <Grid
              item
              md={3} sm={3}
              xs={6}
            >
              <NumberField
                fullWidth
                label={lang.mgtFeeGross}
                disabled
                margin="dense"
                variant="outlined"
                value={(form.values.monthlyMgtFee && form.values.gross) ? (form.values.monthlyMgtFee / form.values.gross).toFixed(2) : 0}
              // onChange={(ev) => {
              //   form.setValues({
              //     ...form.values,
              //     mgtFeePerFeetGross: ev.target.value
              //   })
              // }}
              // {...bind("mgtFeePerFeetGross")}
              />
            </Grid>

            <Grid
              item
              md={3} sm={3}
              xs={6}
            >
              <NumberField
                fullWidth
                label={lang.mgtFeeNet}
                disabled
                margin="dense"
                variant="outlined"
                value={(form.values.monthlyMgtFee && form.values.net) ? (form.values.monthlyMgtFee / form.values.net).toFixed(2) : 0}
              // {...bind("mgtFeePerFeetNet")}
              />
            </Grid>

            {/* <Grid
              item
              md={3} sm={3}
              xs={6}
            >
              <TextField
                fullWidth
                select
                label={lang.mgtProfile}
                margin="dense"
                variant="outlined"
                {...bind('mgtProfile')}
              > 
                <MenuItem key={-1} value={null}><option aria-label="None" value="" /></MenuItem>
                {Object.keys(profileOptions ?? {}).map((v,idx) => <MenuItem key={idx} value={v}>{profileOptions[v]}</MenuItem>)}
              </TextField>
            </Grid>

            <Grid
              item
              md={3} sm={3}
              xs={6}
            >
              <Autocomplete
                options={filteredOptions}
                noOptionsText={''}
                // loading={buildingListLoading}
                // loadingText={lang.msgLoading}
                freeSolo={true}
                renderInput={params => (
                  <TextField
                    {...params}
                    className={classes.patchOutlineAutocomplete}
                    // error={!!form.hasError('building')}
                    // helperText={form.hasError('building')}
                    fullWidth
                    label={lang.mgtCommission}
                    margin="dense"
                    variant="outlined"
                    disabled={form.isDisabled('mgtCommission')}
                    onChange={(ev) => {
                      form.setValues({
                        ...form.values,
                        mgtCommission: ev.target.value
                      })
                      filterOptions(ev.target.value);
                    }}
                    // value={form.values.building ?? ''}
                  />
                )} 
                value={form.values.mgtCommission ?? ''}
                onChange={(_, val) => {
                  if(val) {
                    form.setValues({
                      ...form.values,
                      mgtCommission: val
                    })
                  }
                }}
              />
            </Grid> */}

            {/* <Grid
              item
              md={1} sm={1}
              xs={1}
            >
              <Typography className={classes.label}>土地查册</Typography>
            </Grid>
            <Grid
              item
              md={1} sm={1}
              xs={1}
            >
              <Button 
                disabled tooltip="File uploaded will be ready in next iteration"
                variant="contained" 
                color="primary" 
                component="span"
                onClick={ () => handleDialogOpen('isLandSearchSection')}
              >
                上載
              </Button>
            </Grid>
            <Grid
              container
              md={10} sm={10}
              xs={10}
              className={classes.filecontainer}
            >
              <Test files={values.landSearchFiles}/>
            </Grid> */}
            {/* 
            <UploadPane
              id="file-land-search" type="LAND_SEARCH" title={lang.landSearch}
              fileForm={fileForm} idxName="idx-land-search"
              uploadPolicy={propertyStockSettings[`LAND_SEARCH_UPLOAD_POLICY`]}
              confirmDialog={confirmDialog}
            />

            <UploadPane
              id="file-sa-search" type="SA_SEARCH" title={lang.saSearch}
              fileForm={fileForm} idxName="idx-sa-search"
              uploadPolicy={propertyStockSettings[`SA_SEARCH_UPLOAD_POLICY`]}
              confirmDialog={confirmDialog}
            />

            <UploadPane
              id="file-op" type="OP" title={lang.occPermit}
              fileForm={fileForm} idxName="idx-op"
              uploadPolicy={propertyStockSettings[`OP_UPLOAD_POLICY`]}
              confirmDialog={confirmDialog}
            />

            <UploadPane
              id="file-company-search" type="COMPANY_SEARCH" title={lang.businessReg}
              fileForm={fileForm} idxName="company-search"
              uploadPolicy={propertyStockSettings[`BR_UPLOAD_POLICY`]}
              confirmDialog={confirmDialog}
            />

            <UploadPane
              id="file-others" type="OTHERS" title={lang.otherDoc}
              fileForm={fileForm} idxName="others"
              uploadPolicy={propertyStockSettings[`OTHERS_UPLOAD_POLICY`]}
              confirmDialog={confirmDialog}
            />

            {
              form.values.status !== 'RENT' &&
              <FormUploadPane
                id="file-form-1" type="FORM_1" title={lang.form1}
                fileForm={fileForm} idxName="form-1"
                uploadPolicy={propertyStockSettings[`FORM_1_UPLOAD_POLICY`]}
                // confirmDialog={confirmDialog}
                canDownloadOrDeleteFiles={canDownloadOrDeleteFiles}
              />
            }

            {
              form.values.status !== 'SALES' &&
              <FormUploadPane
                id="file-form-2" type="FORM_2" title={lang.form2}
                fileForm={fileForm} idxName="form-2"
                uploadPolicy={propertyStockSettings[`FORM_2_UPLOAD_POLICY`]}
                // confirmDialog={confirmDialog}
                canDownloadOrDeleteFiles={canDownloadOrDeleteFiles}
              />
            }

            {
              form.values.status !== 'RENT' &&
              <FormUploadPane
                id="file-form-3" type="FORM_3" title={lang.form3}
                fileForm={fileForm} idxName="form-3"
                uploadPolicy={propertyStockSettings[`FORM_3_UPLOAD_POLICY`]}
                hasAgreementInfo
                // confirmDialog={confirmDialog}
                canDownloadOrDeleteFiles={canDownloadOrDeleteFiles}
              />
            }
            
            {
              !creating && form.values.status !== 'RENT' &&
              <FormUploadPane
                id="file-form-4" type="FORM_4" title={lang.form4} 
                fileForm={clientFileForm} idxName="form-4"
              
                uploadPolicy={clientSettings[`FORM_4_UPLOAD_POLICY`]}
                // confirmDialog={confirmDialog}
                hasAgreementInfo
                isPropertyStockPage={true}
              />
            }

            {
              form.values.status !== 'SALES' &&
              <FormUploadPane
                id="file-form-5" type="FORM_5" title={lang.form5}
                fileForm={fileForm} idxName="form-5"
                uploadPolicy={propertyStockSettings[`FORM_5_UPLOAD_POLICY`]}
                hasAgreementInfo
                // confirmDialog={confirmDialog}
                canDownloadOrDeleteFiles={canDownloadOrDeleteFiles}
              />
            }
            
            {
              !creating && form.values.status !== 'SALES' &&
              <FormUploadPane
                id="file-form-6" type="FORM_6" title={lang.form6}
                fileForm={clientFileForm} idxName="form-6"
              
                uploadPolicy={clientSettings[`FORM_6_UPLOAD_POLICY`]}
                // confirmDialog={confirmDialog}
                hasAgreementInfo
                isPropertyStockPage={true}
              />
            }

            <FormUploadPane
              id="file-provisional-agreement" type="PROVISIONAL_AGREEMENT" title={lang.provisionalAgreement}
              fileForm={fileForm} idxName="provisional-agreement"
              uploadPolicy={propertyStockSettings[`PROVISIONAL_AGREEMENT_UPLOAD_POLICY`]}
              hasAgreementInfo
              // confirmDialog={confirmDialog}
            />
             <UploadPane
              id="file-supplemental-document" type="SUPPLEMENTAL_DOCUMENT" title={lang.provisionalAgreementSuppDoc}
              fileForm={fileForm} idxName="supplemental-document"
              confirmDialog={confirmDialog}
            /> */}
          </Grid>
        </CardContent>
        <Divider />
        {/* <CardActions>
          <Button
            color="primary"
            variant="contained"
          >
            Save details
          </Button>
        </CardActions> */}
      </form>
    </Card>
  );
};

// PropertyManagementInfo.propTypes = {
//   className: PropTypes.string
// };

export default PropertyManagementInfo;
