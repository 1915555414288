import {
  Button, Card, CardActions, CardContent, Checkbox as MuiCheckbox, FormControl, Grid, InputLabel, ListItemText, makeStyles, MenuItem, Select, TextField, TextFieldProps, Typography
} from '@material-ui/core';
import { CardProps } from '@material-ui/core/Card';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker } from '@material-ui/pickers';
import buildingApi from 'api/buildingApi';
import userApi from 'api/userApi';
import { hasPermission } from "common/access-control";
import { BuildingListItemDTO, UserListItemDTO } from 'common/dto';
import { useForm } from 'common/hooks';
import { HandleFormHelperText, tooltipHoc } from 'common/ui';
import { getBuildingSortForAutoComplete, getBuilingListWithoutDuplicates, initFormKeysAndBooleanMap, limitTextFieldLength, multiLang } from 'common/utils';
import moment from 'moment';
import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';
import { contentAutocompleteOptions, ContentOptionItem } from './ContentAutocompleteOptions';



const Checkbox = tooltipHoc(MuiCheckbox);

const UITextField = (props: TextFieldProps & { fixedLabel?: boolean }) => {
  let { label, fixedLabel, ...rest } = props;
  fixedLabel = false;
  return <Fragment>
    {fixedLabel ? <Typography>{label}</Typography> : null}
    <TextField {...rest} label={!fixedLabel ? label : null} />
  </Fragment>
};

export interface MemoEnquirySearchDTO {
  type: string[];
  userId: string;
  dateCreated: string;
  building: string;
  block: string;
  level: string[];
  unit: string;
  clientName: string;
  content: string;
}

interface MemoEnquirySearchProps extends CardProps {
  onSearch?(values: Partial<MemoEnquirySearchDTO>): any;
  initialCriteria?: Partial<MemoEnquirySearchDTO>;
}


const InputGroup = (props: { children: React.ReactNode[], label?: string }) => {
  const { children } = props;
  const { langMemoEnquiry } = useSelector((state: IRootState) => state.locale);
  return (
    <Grid container xs={12} style={{ display: 'flex' }}>
      <Grid
        item
        style={{ flex: 1 }}
      >
        {children[0]}
      </Grid>

      <Grid item style={{ width: 40, flexShrink: 1 }}>
        <TextField
          fullWidth
          InputProps={{
            style: { borderRadius: 0, borderLeft: 0, borderRight: 0 },
          }}
          inputProps={{
            style: { paddingLeft: 0, paddingRight: 0, textAlign: 'center', borderLeft: 0, borderRight: 0 },
          }}
          disabled value={langMemoEnquiry.to}
          variant="outlined"
          margin="dense" />
      </Grid>

      <Grid
        item
        style={{ flex: 1 }}
      // md={5}
      // xs={5}
      >
        {children[1]}
      </Grid>
    </Grid>
  );
}

InputGroup.leftItem = () => ({ style: { borderTopRightRadius: 0, borderBottomRightRadius: 0 } });
InputGroup.rightItem = () => ({ style: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } });

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  patchOutlineAutocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
      paddingLeft: 8,
    }
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

const MemoEnquirySearch = (props: MemoEnquirySearchProps) => {
  const classes = useStyles();
  const localeBundle = useSelector((state: IRootState) => state.locale);
  const { locale, lang, langMemoEnquiry, memoTypeOptions, langPropertyStock, levelOptions } = localeBundle;
  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);
  const { privileges } = useSelector((state: IRootState) => state.login);

  const agentFieldVisible = hasPermission(privileges, 'READ', 'UNOWNED_PROPERTY_STOCK_MEMO')
    || hasPermission(privileges, 'READ', 'UNOWNED_CLIENT_MEMO')
    || hasPermission(privileges, 'READ', 'TEAM_PROPERTY_STOCK_MEMO')
    || hasPermission(privileges, 'READ', 'TEAM_CLIENT_MEMO');

  const searchForm = useForm<MemoEnquirySearchDTO>({
    ...props.initialCriteria
  });

  const bind = searchForm.bind;

  const token: string = useSelector((state: IRootState) => state.login.token ?? '');

  const [disabledCriterias] = useState<{ [k: string]: boolean }>({

  });

  const filteredSearchForm = () => {
    searchForm.values = {
      ...searchForm.values,
      userId: searchForm.values.userId?.toString()?.trim(),
      content: searchForm.values.content?.trim(),
      clientName: searchForm.values.clientName?.trim(),
      building: searchForm.values.building?.trim(),
      block: searchForm.values.block?.trim(),
      unit: searchForm.values.unit?.trim(),
    }

    const newForm: Partial<MemoEnquirySearchDTO> = {};
    Object
      .keys(searchForm.values)
      .filter((key) => !disabledCriterias[key])
      .forEach((key) => (newForm as any)[key] = searchForm.values[key as keyof MemoEnquirySearchDTO]);
    return newForm;
  };

  const [users, setUsers] = useState<UserListItemDTO[]>([]);
  const [agentListLoading, setAgentListLoading] = useState(false);

  const fetchAgents = (keyword: string) => {
    if (keyword.length < 2) { return; }
    setAgentListLoading(true);
    userApi.getList({ username: keyword, onlyHasCreateOwnClientPrivilege: false }, token).then(result => {
      if (result.data?.content) {
        setUsers(result.data!.content);
        setAgentListLoading(false);
      }
    });
  };

  const userMap: { [id: string]: UserListItemDTO } = {};
  users.forEach(user => {
    userMap[user?.id] = user;
  });

  const userAutocomplete = () => <Autocomplete
    popupIcon={''}
    options={users}
    noOptionsText={''}
    loading={agentListLoading}
    getOptionLabel={(userId) => {
      const user = userMap[userId];
      return (locale === 'en' ? user?.englishName : user?.chineseName) ?? user?.username ?? '';
    }}
    loadingText={lang.msgLoading}
    renderOption={(user: UserListItemDTO) => <div>
      <div><Typography>{(locale === 'en' ? user?.englishName : user?.chineseName) ?? user?.username ?? ''}</Typography></div>
      {/* <div><Typography variant="caption">{agents.filter(agent => agent.username === option)?.[0]?.mainContact}</Typography></div> */}
    </div>}
    renderInput={(params: any) => (
      <TextField
        {...params}
        inputProps={{ ...params.inputProps, maxLength: 255 }}
        className={classes.patchOutlineAutocomplete}
        fullWidth
        label={langMemoEnquiry.username}
        margin="dense"
        variant="outlined"
        onChange={(ev) => {
          setKeyBooleanMap(keyBooleanMap.set("username", ev.target.value.length === 255))
          fetchAgents(ev.target.value)

        }}
      />
    )}
    value={searchForm.values.userId ?? ''}
    onChange={(_: any, user: UserListItemDTO | undefined) => {
      searchForm.updateValues('userId', user?.id ?? '');
    }}
  />

  //content autocomplete
  const [contentOptions, setContentOptions] = useState<ContentOptionItem[]>(contentAutocompleteOptions);

  const fetchContentOptions = (keyword: string) => {
    if (keyword.length < 1) {
      setContentOptions(contentAutocompleteOptions);
    }
    if (keyword.length < 2) {
      return;
    }
    setContentOptions(contentAutocompleteOptions.filter(item => item.displayName.toLowerCase().includes(keyword.toLowerCase())));
  };

  const contentAutocomplete = () => (
    <Autocomplete
      options={contentOptions}
      noOptionsText={''}
      loadingText={lang.msgLoading}
      freeSolo={true}
      disableClearable
      getOptionLabel={option => typeof option === 'string' ? option : option.displayName}
      renderOption={(item: ContentOptionItem) => { return item.displayName ?? ''; }}
      renderInput={params => (
        <TextField
          {...params}
          className={classes.patchOutlineAutocomplete}
          inputProps={{ ...params.inputProps, maxLength: 255 }}
          fullWidth
          label={langMemoEnquiry.content}
          margin="dense"
          variant="outlined"
          onChange={(ev) => {
            setKeyBooleanMap(keyBooleanMap.set("content", ev.target.value.length === 255));
            searchForm.modify({
              content: ev.target.value
            })
            fetchContentOptions(ev.target.value)
          }}
          value={searchForm.values.content ?? ''}
        />
      )}
      value={searchForm.values.content ?? ''}
      onChange={(_, optionItem: ContentOptionItem) => {
        searchForm.modify({
          // content: optionItem.displayName
          content: optionItem.pattern
        })
      }}
    />
  )

  //-- building autocomplete
  const [buildings, setBuildings] = useState<BuildingListItemDTO[]>([]);
  const [buildingListLoading, setBuildingListLoading] = useState(false);

  const fetchBuilding = (keyword: string) => {
    if (keyword.length < 2) { return; }
    setBuildingListLoading(true);
    buildingApi.getList({ building: keyword, sort: getBuildingSortForAutoComplete(locale) }, token).then(result => {
      if (result.data?.content) {
        setBuildings(getBuilingListWithoutDuplicates(result.data!.content));
        setBuildingListLoading(false);
      }
    });
  }
  const buildingNames = buildings.map((building) => multiLang(locale, building.buildingNameZh, building.buildingNameEn));

  const buildingAutocomplete = () => (
    <Autocomplete
      options={buildingNames}
      noOptionsText={''}
      loading={buildingListLoading}
      loadingText={lang.msgLoading}
      freeSolo={true}
      disableClearable
      renderInput={params => (
        <TextField
          {...params}
          className={classes.patchOutlineAutocomplete}
          inputProps={{ ...params.inputProps, maxLength: 255 }}
          // error={!!form.hasError('building')}
          // helperText={form.hasError('building')}
          fullWidth
          label={langPropertyStock.buildingOrVillageName}
          margin="dense"
          variant="outlined"
          onChange={(ev) => {
            setKeyBooleanMap(keyBooleanMap.set("building", ev.target.value.length === 255))
            searchForm.modify({
              building: ev.target.value
            })
            fetchBuilding(ev.target.value)
          }}
          // value={form.values.building ?? ''}
          value={searchForm.values.building ?? ''}
        />
      )}
      value={searchForm.values.building ?? ''}
      onChange={(_, val) => {
        searchForm.modify({
          building: val
        })
        // if(buildingMap[val]) {
        //   const { building} = buildingMap[val];
        //   searchForm.modify({
        //     building: building
        //   })
        // }
      }}
    />
  )
  const [keyBooleanMap, setKeyBooleanMap] = useState(initFormKeysAndBooleanMap(searchForm));

  const blockField = () => (
    <UITextField
      label={langPropertyStock.block}
      {...bind('block')}
      fullWidth
      margin="dense"
      variant="outlined"
      onChange={(e) => {
        limitTextFieldLength(e, 255, 'block', searchForm, keyBooleanMap, setKeyBooleanMap);
      }}
    ></UITextField>
  )

  const unitField = () => (
    <UITextField
      label={langPropertyStock.unit}
      {...bind('unit')}
      fullWidth
      margin="dense"
      variant="outlined"
      onChange={(e) => {
        limitTextFieldLength(e, 255, 'unit', searchForm, keyBooleanMap, setKeyBooleanMap);
      }}
    ></UITextField>
  )

  const levelField = () => (
    <FormControl variant="outlined" margin="dense" fullWidth>
      <InputLabel variant="outlined" style={{ background: '#fff' }}>{langPropertyStock.level}</InputLabel>
      <Select
        multiple
        {...bind('level')}
        renderValue={(selected) => (selected as string[]).map(key => levelOptions[key]).join(', ')}
      >
        {Object.keys(levelOptions).map(key => (
          <MenuItem key={key} value={key}>
            <Checkbox checked={searchForm.values.level!.indexOf(key) > -1} />
            <ListItemText primary={levelOptions[key]} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )

  const clientNameField = () => (
    <UITextField
      label={langMemoEnquiry.clientName}
      {...bind('clientName')}
      fullWidth
      margin="dense"
      variant="outlined"
      onChange={(e) => {
        limitTextFieldLength(e, 255, 'clientName', searchForm, keyBooleanMap, setKeyBooleanMap);
      }}
    ></UITextField>
  )

  const contentField = () => (
    <UITextField
      label={langMemoEnquiry.content}
      {...bind('content')}
      fullWidth
      margin="dense"
      variant="outlined"
    ></UITextField>
  )

  return (
    <Card
      {...props}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardContent>
          <Grid
            container
            spacing={1}
          >
            <Grid item container md={12} xs={12} spacing={1}>
              <Grid
                item
                md={12}
                xs={12}
              >
                <Typography style={{ fontWeight: 'bold' }}>{langMemoEnquiry.captionBasicInfo}</Typography>
              </Grid>

              <Grid
                item
                md={4}
                sm={4}
                xs={12}
              >
                <FormControl variant="outlined" margin="dense" fullWidth>
                  <InputLabel variant="outlined" style={{ background: '#fff' }}>{langMemoEnquiry.type}</InputLabel>
                  <Select
                    multiple
                    {...bind('type')}
                    renderValue={(selected) => (selected as string[]).map(val => memoTypeOptions[val]).join(', ')}
                  >
                    {Object.keys(memoTypeOptions).map(key => (
                      <MenuItem key={key} value={key}>
                        <Checkbox checked={(searchForm.values.type ?? []).indexOf(key) > -1} />
                        <ListItemText primary={memoTypeOptions[key]} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>



              {
                agentFieldVisible ?
                  <Grid
                    item
                    md={4}
                    sm={4}
                    xs={12}
                  >
                    {userAutocomplete()}
                    <HandleFormHelperText
                      isError={keyBooleanMap.get('username')}
                      errorMessage={lang.textOverLimit}
                    />
                  </Grid> : null
              }

              <Grid
                item
                md={4}
                sm={4}
                xs={12}
              >
                <KeyboardDatePicker autoOk={true}
                  //disableToolbar
                  fullWidth
                  variant="inline"
                  inputVariant="outlined"
                  label={langMemoEnquiry.dateCreated}
                  format={DISPLAY_DATE_FORMAT}
                  margin="dense"
                  maxDate={new Date()}
                  value={searchForm.values.dateCreated ? moment(searchForm.values.dateCreated) : null}
                  onChange={(value) => {
                    searchForm.setValues({
                      ...searchForm.values,
                      dateCreated: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
                    });
                  }}
                  // onChange={(value) => {
                  //   searchForm.updateValues('dateCreated', value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? null);
                  // }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>

              <Grid
                item
                md={12}
                sm={12}
                xs={12}
              >
                {contentAutocomplete()}
                <HandleFormHelperText
                  isError={keyBooleanMap.get('content')}
                  errorMessage={lang.textOverLimit}
                />
              </Grid>

              <Grid
                item
                md={3}
                sm={3}
                xs={12}
              >
                {clientNameField()}
                <HandleFormHelperText
                  isError={keyBooleanMap.get('clientName')}
                  errorMessage={lang.textOverLimit}
                />
              </Grid>

              <Grid
                item
                md={3}
                sm={3}
                xs={12}
              >
                {buildingAutocomplete()}
                <HandleFormHelperText
                  isError={keyBooleanMap.get('building')}
                  errorMessage={lang.textOverLimit}
                />
              </Grid>

              <Grid
                item
                md={3}
                sm={3}
                xs={12}
              >
                {blockField()}
                <HandleFormHelperText
                  isError={keyBooleanMap.get('block')}
                  errorMessage={lang.textOverLimit}
                />
              </Grid>

              <Grid
                item
                md={2}
                sm={2}
                xs={12}
              >
                {levelField()}
              </Grid>

              <Grid
                item
                md={1}
                sm={1}
                xs={12}
              >
                {unitField()}
                <HandleFormHelperText
                  isError={keyBooleanMap.get('unit')}
                  errorMessage={lang.textOverLimit}
                />
              </Grid>




            </Grid>

            {/* <Grid item xs={12}>
            <Divider />
          </Grid> */}
          </Grid>
        </CardContent>
      </form>
      <CardActions style={{ flexWrap: 'wrap' }}>
        <div style={{ flexGrow: 1 }} />
        <Button variant="contained" color="primary" onClick={() => props.onSearch?.(filteredSearchForm())}>{lang.actionSearch}</Button>
      </CardActions>
    </Card>
  );
};

export default MemoEnquirySearch;