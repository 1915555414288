import { ApiError, ApiPageResult } from 'api';
import memoEnquiryApi from 'api/memoEnquiryApi';
import { MemoEnquiryDTO } from 'common/dto';
import { IRootState, MemoEnquiryActions } from 'reducers';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { apiTaskWrapper } from './saga-commons';


export function* watchMemoEnquiryListFetchRequested() {
  yield takeLatest(
    'MemoEnquiryList.FetchRequested',
    apiTaskWrapper(requestMemoEnquiryList),
  );
}

export function* requestMemoEnquiryList(action: Extract<MemoEnquiryActions, { type: 'MemoEnquiryList.FetchRequested' }>) {
  if (action.type !== 'MemoEnquiryList.FetchRequested') {
    return;
  }

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiPageResult<MemoEnquiryDTO> = yield call(memoEnquiryApi.getList, action.payload, token);
  

  if (!result.error) {
    yield put<MemoEnquiryActions>({ type: 'MemoEnquiryList.Loaded', payload: result.data! });
  } else {
    throw ApiError.of(result.error);
  }
}