import {
    Button as MuiButton, Grid, IconButton,
    ListItemIcon, makeStyles, Menu,
    MenuItem
} from '@material-ui/core';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SaveIcon from '@material-ui/icons/Save';
import clsx from 'clsx';
import { BuildingDetailDTO } from 'common/dto';
import { FormPresenter, useMenu } from 'common/hooks';
import { AliasIcon, tooltipHoc } from 'common/ui';
import NavigationBar from 'layouts/Main/components/Topbar/NavigationBar';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducers';
import { BuildingBasicInfo, BuildingFeatures, Info } from './components';
import { BuildingAliasListDialog } from './components/BuildingAliasListDialog';
import BuildingMergeDialogContainer from './components/BuildingMergeDialog';



const Button = tooltipHoc(MuiButton);

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  unsavedBtn: {
    color: '#FFBF00',
    fontWeight: 'bold'
  }
}));


function TabPanel(props: { children: JSX.Element, value: number, index: number }): JSX.Element {
  const { children, value, index } = props;

  return value !== index ? <div/> : children;
}

const When = ({ test, children }: { test?: boolean, children: JSX.Element }): JSX.Element => test ? children : <span />;

export interface BuildingDetailProps {
  onSave: () => void;
  form: FormPresenter<Partial<BuildingDetailDTO>>;
  tagForm: FormPresenter<any>;
  floorForm: FormPresenter<any>;
//   fileForm: FormPresenter<PropertyFileMap>;
  creating?: boolean;
  canEdit?: boolean;
  canCreate?: boolean;
  unsaved?: boolean;
}

const BuildingDetail = ({ onSave, form, tagForm, floorForm, creating, canEdit, canCreate, unsaved }: BuildingDetailProps) => {
  const classes = useStyles();
  const { langBuilding: lang, lang: langCommon} = useSelector((state: IRootState) => state.locale);
  const [tabIndex] = useState(0);

  const dispatch = useDispatch();
  
  const collapseMenu = useMenu();
  const [ buildingMergeDialogOpen, setBuildingMergeDialogOpen ] = useState(false); // `/buildings/${form.values.id}/merge`
  const [ buildingAliasListDialogOpen, setBuildingAliasListDialogOpen ] = useState(false);
  
  return (
    <div className={classes.root}>
      {/* Dialogs */}
      <BuildingMergeDialogContainer open={buildingMergeDialogOpen} onClose={() => setBuildingMergeDialogOpen(false)} />
      <BuildingAliasListDialog
        currentBuilding={form.values}
        aliases={[]}
        open={buildingAliasListDialogOpen} onClose={() => setBuildingAliasListDialogOpen(false)}
      />

      <NavigationBar title={creating ? lang.titleBuildingNew : (lang.titleBuildingDetail + (unsaved ? '*' : ''))}>
        <Button color="primary" className={clsx(unsaved && classes['unsavedBtn'])} disabled={!canEdit} variant="text" startIcon={<SaveIcon />} onClick={onSave}>{langCommon.actionSave + (unsaved && canEdit ? '*' : '')}</Button>

        {(creating || (!canEdit && !canCreate)) ? null :
          <IconButton {...collapseMenu.buttonProps()}>
            <MoreVertIcon />
          </IconButton>
        }

        <Menu {...collapseMenu.menuProps()}>
          <MenuItem onClick={() => {
            setBuildingMergeDialogOpen(true);
            dispatch({ type: 'BuildingMerge.Reset' });
            dispatch({ type: 'BuildingMerge.SelectA', payload: form.values });
            collapseMenu.onClose();
          }} disabled={!canEdit}>
            <ListItemIcon style={{ minWidth: 30 }}><MergeTypeIcon /></ListItemIcon>
            {lang.actionMergeBuilding}
          </MenuItem>

          <MenuItem onClick={() => {
            dispatch({ type: 'BuildingAliasList.Reset' });
            dispatch({ type: 'BuildingAliasList.FetchRequested', payload: { buildingId: form.values.id } });
            setBuildingAliasListDialogOpen(true);
            collapseMenu.onClose();
          }} disabled={!canEdit}>
            <ListItemIcon style={{ minWidth: 30 }}><AliasIcon /></ListItemIcon>
            {lang.titleBuildingAlias}
          </MenuItem>
        </Menu>
      </NavigationBar>
      
      {/* <Grid container md={12} xs={12} spacing={1}>
        <Grid item md={12} xs={12} >
          <Card>
            <StyledTabs value={tabIndex} onChange={(_, val) => setTabIndex(val)}>
              <StyledTab label="基本資料"/>
            </StyledTabs>
          </Card>
        </Grid>
      </Grid> */}
      

      <TabPanel value={tabIndex} index={0}>
        <Grid
          container
          // md={12}
          // sm={12}
          // xs={12}
          spacing={1}
        >
					<When test={!creating}>
						<Grid
							item
              md={12}
              sm={12}
							xs={12}
						>
							<Info form={form} isNew={ creating ?? false }/>
						</Grid>
					</When>
          {/* <Grid item md={12} xs={12}>
            <ContactInfo form={form} />
          </Grid> */}
          {/* <Grid item>
            <PropertyBasicInfo />
          </Grid> */}

          <Grid
            item
            md={6}
            // sm={6}
            xs={12}
          >
            <BuildingBasicInfo isNew={creating ?? false} form={form} canCreate={canCreate ?? false} canEdit={canEdit ?? false}/>
          </Grid>

          <Grid
            item
            lg={6}
            md={6}
            xl={6}
            xs={12}
          >
            <BuildingFeatures form={form} tagForm={tagForm} floorForm={floorForm} isNew={creating ?? false} canCreate={canCreate ?? false} canEdit={canEdit ?? false} />
          </Grid>
        </Grid>
      </TabPanel>

      {/* <TabPanel value={tabIndex} index={1}>
        <Grid
          container
          md={12}
          spacing={1}
        >
          <Grid item md={12} xs={12} spacing={1}>
            <PropertyFeatures form={form} tagForm={tagForm} fileForm={fileForm} /> 
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={tabIndex} index={2}>
        <Grid
          container
          md={12}
          spacing={1}
        >
          <Grid item md={12} spacing={1}>
            <PropertyManagementInfo form={form} tagForm={tagForm} />
          </Grid>
        </Grid>
      </TabPanel> */}
    </div>
  );
};

export default BuildingDetail;
