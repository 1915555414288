import { Button, Card, CardActions, CardContent, Checkbox as MuiCheckbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { CardProps } from '@material-ui/core/Card';
import { TextFieldProps } from '@material-ui/core/TextField';
import DistrictMultiSelect from 'common/elements/DistrictMultiSelect';
import { useForm } from 'common/hooks';
import { HandleFormHelperText, tooltipHoc } from 'common/ui';
import { initFormKeysAndBooleanMap, limitTextFieldLength } from 'common/utils';
import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';


//#region Common UI

const Checkbox = tooltipHoc(MuiCheckbox);

const UITextField = (props: TextFieldProps & { fixedLabel?: boolean }) => {
  let { label, fixedLabel, ...rest } = props;
  fixedLabel = false;
  return <Fragment>
    {fixedLabel ? <Typography>{label}</Typography> : null}
    <TextField {...rest} label={!fixedLabel ? label : null} />
  </Fragment>
};

//#endregion Common UI

export interface UsersSearchForm {
  username: string;
  roles: string[];
  district: [];
}

interface UsersSearchProps extends CardProps {
  onSearch?(values: Partial<UsersSearchForm>): any;
}


const UsersSearch = (props: UsersSearchProps) => {
  const searchForm = useForm<UsersSearchForm>({
    roles: [],
    district: [],
  });

  const { langUser, lang, roleOptions } = useSelector((state: IRootState) => state.locale);

  const bind = searchForm.bind;

  const [keyBooleanMap, setKeyBooleanMap] = useState(initFormKeysAndBooleanMap(searchForm));
  // UI Fields
  const field = (name: keyof UsersSearchForm) => <UITextField
    label={langUser[name as string]}
    {...bind(name)}
    fullWidth
    margin="dense"
    variant="outlined"
    onChange={(e) => {
      limitTextFieldLength(e, 255, name, searchForm, keyBooleanMap, setKeyBooleanMap);
    }}
  ></UITextField>;

  const roleSelect = () => <FormControl variant="outlined" margin="dense" fullWidth>
    <InputLabel variant="outlined" style={{ background: '#fff' }}>{langUser.roles}</InputLabel>
    <Select
      multiple
      {...bind('roles')}
      renderValue={(selected) => (selected as string[]).map(key => roleOptions[key]).join(', ')}
    >
      {Object.keys(roleOptions).map(key => (
        <MenuItem key={key} value={key}>
          <Checkbox checked={searchForm.values.roles!.indexOf(key) > -1} />
          <ListItemText primary={roleOptions[key]} />
        </MenuItem>
      ))}
    </Select>
  </FormControl>

  const districtSelect = () => (
    <DistrictMultiSelect
      value={searchForm.values.district ?? []}
      onChange={(value) => searchForm.updateValues('district', value)}
    />
  )

  const filteredSearchForm = () => {
    searchForm.values = {
      ...searchForm.values,
      username: searchForm.values.username?.trim(),
    }
    const newForm: Partial<UsersSearchForm> = {};
    Object
      .keys(searchForm.values)
      // .filter((key) => !disabledCriterias[key])
      .forEach((key) => (newForm as any)[key] = searchForm.values[key as keyof UsersSearchForm]);
    return newForm;
  };
  return (
    <Card
      {...props}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardContent>
          <Grid
            container
            spacing={1}
          >
            <Grid item container md={12} xs={12} spacing={1}>
              <Grid
                item
                md={12}
                xs={12}
              >
                <Typography style={{ fontWeight: 'bold' }}>{langUser.titleBasicInfo}</Typography>
              </Grid>

              <Grid
                item
                md={4}
                sm={4}
                xs={12}
              >
                {field('username')}
                <HandleFormHelperText
                  isError={keyBooleanMap.get('username')}
                  errorMessage={lang.textOverLimit}
                />
              </Grid>

              <Grid
                item
                md={4}
                sm={4}
                xs={12}
              >
                {roleSelect()}
              </Grid>

              <Grid
                item
                md={4}
                sm={4}
                xs={12}
              >
                {districtSelect()}
              </Grid>

            </Grid>

          </Grid>
        </CardContent>
      </form>

      <CardActions style={{ flexWrap: 'wrap' }}>
        <div style={{ flexGrow: 1 }} />
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.onSearch?.(filteredSearchForm())}>
          {langUser.actionSearch}
        </Button>
      </CardActions>
    </Card>
  );
}

export default UsersSearch;
