import { ApiPageResult, ApiResult } from 'api';
import { NotificationDTO, TeamDTO, UserDTO } from 'common/dto';
import { objectToQuery } from 'common/utils';

const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];

// TODO: auth
export const userApi = {
  getTeamList: async (criteria: any = {}, token: string): Promise<ApiResult<TeamDTO[]>> => {
    try {
      const response = await fetch(`${BASE_URL}/teams?${objectToQuery(criteria)}`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  getList: async (criteria: any = {}, token: string): Promise<ApiPageResult<any>> => {
    try {
      const response = await fetch(`${BASE_URL}/users?${objectToQuery(criteria)}`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  getListForAutocomplete: async (criteria: any = {}, token: string): Promise<ApiPageResult<any>> => {
    try {
      const response = await fetch(`${BASE_URL}/users/autocomplete?${objectToQuery(criteria)}`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  getDetail: async (id: string, token: string): Promise<ApiResult<UserDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/users/${id}`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  add: async (building: UserDTO, token: string): Promise<ApiResult<UserDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/users`, {
        method: 'POST', body: JSON.stringify(building),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  update: async (user: UserDTO, token: string): Promise<ApiResult<any>> => {
    try {
      const response = await fetch(`${BASE_URL}/users/${user.id}`, {
        method: 'POST', body: JSON.stringify(user),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  changePassword: async (oldPassword: string, newPassword: string, token: string): Promise<ApiResult<boolean>> => {
    const dto = { oldPassword, newPassword };
    try {
      const response = await fetch(`${BASE_URL}/users/current/change-password`, {
        method: 'POST', body: JSON.stringify(dto),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  resetPassword: async (id: string | number, token: string): Promise<ApiResult<boolean>> => {
    try {
      const response = await fetch(`${BASE_URL}/users/${id}/reset-password`, {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  unlock: async (id: string | number, token: string): Promise<ApiResult<boolean>> => {
    try {
      const response = await fetch(`${BASE_URL}/users/${id}/unlock`, {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  searchUserForImpersonation: async (kw: string, token: string): Promise<ApiResult<UserDTO[]>> => {
    try {
      const response = await fetch(`${BASE_URL}/users/searchUserForImpersonation?kw=${kw}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  requestImpersonation: async (beingImpersonatedUserId: string | number, token: string): Promise<ApiResult<boolean>> => {
    try {
      const response = await fetch(`${BASE_URL}/users/request-impersonation/${beingImpersonatedUserId}`, {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  startImpersonation: async (password: string, notificationId: string, token: string): Promise<ApiResult<any>> => {
    const payload = new FormData();

    payload.append('password', password);
    payload.append('notificationId', notificationId);
    const res = fetch(`${BASE_URL}/users/start-impersonation`, {
      method: 'POST',
      body: payload,
      headers: { 'Authorization': `Bearer ${token}` },
    });

    return (await res).json();
  },

  approverStopImpersonation: async (impersonatedUserId: string | number, beingImpersonatedUserId: string | number, token: string): Promise<ApiResult<boolean>> => {
    try {
      const response = await fetch(`${BASE_URL}/users/stop-impersonation/${impersonatedUserId}/${beingImpersonatedUserId}`, {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  logoutImpersonation: async (impersonationId: string | number, token: string): Promise<ApiResult<boolean>> => {
    try {
      const response = await fetch(`${BASE_URL}/users/logout-impersonation/${impersonationId}`, {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  approveImpersonation: async (notification: NotificationDTO, deviceId: string, token: string): Promise<ApiResult<any>> => {
    try {
      const response = await fetch(`${BASE_URL}/users/approve-impersonation`, {
        method: 'PUT', body: JSON.stringify(notification),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`, 'DeviceId': deviceId },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  switchUserLocale: async (uid: string, locale: string, token: string): Promise<ApiResult<any>> => {
    try {
      const response = await fetch(`${BASE_URL}/users/${uid}/switch-locale?locale=${locale}`, {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  getCurrentUserLandSearchCredit: async (token: string): Promise<ApiResult<number>> => {
    try {
      const response = await fetch(`${BASE_URL}/users/current/land-search-credit`, {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  }
};

export default userApi;