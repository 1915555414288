import {
  Button, Card, CardContent, CardHeader, Checkbox, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Divider, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, ListItemText, Menu, MenuItem, Select, TextField, Tooltip, Typography, useMediaQuery, useTheme
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import WarningIcon from '@material-ui/icons/Warning';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/styles';
import clientApi from 'api/clientApi';
import clsx from 'clsx';
import { hasPermission } from 'common/access-control';
import { useConfirmDialog } from 'common/ConfirmDialog';
import { ClientDetailDTO, ContactsDTO, PropertyStockDTO } from 'common/dto';
import { FormPresenter, useForm, useMenu } from 'common/hooks';
import { useInfoDialog } from 'common/InfoDialog';
import { AddIcon, ExternalLinkIcon, HandleFormHelperText, PSAddClientInfoIcon, PSClientInfoIcon, PSEditClientInfoIcon } from 'common/ui';
import { getInvalidContactErrMsg, initFormKeysAndBooleanMap, isNonEmpty, limitTextFieldLength, multiLang } from 'common/utils';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IRootState } from 'reducers';
import theme from 'theme';
import { format } from 'util';
import { CompactClientCreationValidations } from 'views/ClientDetail/components/client-validations';
import ContactCreationDialog from './ContactCreationDialog';
const PUBLIC_URL = process.env['REACT_APP_PAS_APP_PUBLIC_URL'] ?? '/';

const BLANK = {};

const useStyles = makeStyles(() => ({
  root: {},
  patchOutlineAutocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
      paddingLeft: 8,
    }
  },
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  clientInfoIconButtonLabel: {
    paddingTop: '3px',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12px',
    color: '#4285F4',
  },
  highlightedOwnerTextField: {
    '& .MuiInputBase-input': {
      backgroundColor: '#ffff80',
    }
  }
}));

interface PropertyContactInfoProps {
  className?: string;
  form: FormPresenter<PropertyStockDTO>;
  creating?: boolean;
  isSameOwner?: boolean;
}

const ContactInfo = (props: PropertyContactInfoProps) => {
  const { className, form, creating, isSameOwner, ...rest } = props;

  const { isLeadAgent, canReadTeamProperty, canReadUnowned, canReadCompanyStock } = form.values;

  const classes = useStyles();

  const dispatch = useDispatch();

  let [creationDialogOpened, isCreationDialogOpened] = useState(false);
  const openCreationDialog = () => isCreationDialogOpened(true);
  const closeCreationDialog = () => isCreationDialogOpened(false);
  const [shouldDisplayClientAutoComplete, setShouldDisplayClientAutoComplete] = useState(false);

  const [clientInfoDialogOpen, setClientInfoDialogOpen] = useState(false);
  const closeClientInfoDialog = () => {
    setClientInfoDialogOpen(false);
    dispatch({ type: 'ClientDetail.setHasAlreadyBeenModifiedApiError', payload: false });
  };

  const { locale, lang, langPropertyStock, contactOptions } = useSelector((state: IRootState) => state.locale);
  const { token, privileges } = useSelector((state: IRootState) => state.login);
  const clientDetailDto = useSelector((state: IRootState) => state.clients.fetchedDetails[form.values.sellerClientId ?? '']) ?? BLANK;

  const canCreatePropertyStock = hasPermission(privileges, 'CREATE', 'PROPERTY_STOCK');
  const canCreateUnownPropertyStock = hasPermission(privileges, 'CREATE', 'UNOWNED_PROPERTY_STOCK');
  const canCreateOwnPropertyStock = hasPermission(privileges, 'CREATE', 'OWN_PROPERTY_STOCK');
  const canCreateUnownedClient = hasPermission(privileges, 'CREATE', 'UNOWNED_CLIENT');
  const canCreateOwnedClient = hasPermission(privileges, 'CREATE', 'OWN_CLIENT');
  const canCreateCompanyClient = hasPermission(privileges, 'CREATE', 'COMPANY_CLIENT');
  const canAssignUnownedAgentClient = hasPermission(privileges, 'UPDATE', 'ASSIGN_UNOWNED_AGENT_CLIENT');
  const canAssignTeamAgentClient = hasPermission(privileges, 'UPDATE', 'ASSIGN_TEAM_AGENT_CLIENT');
  const canAssignOwnAgentClient = hasPermission(privileges, 'UPDATE', 'ASSIGN_OWN_AGENT_CLIENT');
  const canAssignClientAgent = canAssignUnownedAgentClient || canAssignTeamAgentClient || canAssignOwnAgentClient;

  // When the user has the privilege to create property stock + create unowned property stock but without privilege to create own property stock, some special handling needed
  const hasSpecialHandling = canCreatePropertyStock && canCreateUnownPropertyStock && !canCreateOwnPropertyStock;
  // If the user does NOT have the privilege to create unowned client & create company client -> hide/disable the button
  const hideButton = hasSpecialHandling ? !(canCreateUnownedClient || canCreateCompanyClient)
    : !(canCreateOwnedClient || (canCreateUnownedClient && canAssignClientAgent) || canCreateCompanyClient);

  // handling header in mobile view
  const shouldCollapse = useMediaQuery('(max-width: 599px)');

  const [contacts, setContacts] = useState<ContactsDTO[]>([]);

  const searchClients = async (kw: string) => {
    try {
      const response = await clientApi.searchContactsOfLandlord(kw, token ?? '');
      // 
      setContacts(response.data ?? []);
    } catch (e) {
      // { error: String(e) };
    }
  };

  const [, setReadOnlyContactInfo] = useState<Partial<ContactsDTO>>({});

  useEffect(() => {
    setReadOnlyContactInfo({ ...clientDetailDto?.otherContacts?.[0] ?? {} })
  }, [clientDetailDto]);

  const showSellerInfo = isLeadAgent || (form.values.companyStock === true && canReadCompanyStock) || creating;

  const handleViewClientInfo = () => {
    dispatch({ type: 'ClientDetail.FetchRequested', payload: { cid: form.values.sellerClientId?.toString() ?? '' } })
    setClientInfoDialogOpen(true);
  }

  const confirmDialog = useConfirmDialog();

  //-------- edit seller client
  const newSellerClientForm = useForm<PropertyStockDTO>({}, {
    validations: [
      (values) => values.sellerClientId ? null : [['mainContact', langPropertyStock.msgInputClient]],
    ],
  });
  const sellerClientFormToUse = creating ? form : newSellerClientForm;
  const [openEditSellerClientDialog, setOpenEditSellerClientDialog] = useState(false);

  const [keyBooleanMap, setKeyBooleanMap] = useState(initFormKeysAndBooleanMap(form));

  const shouldUpdateApprovalStage = (form.values.approvalStage ?? 0) > 0;
  const editSellerClientDialog = () => {
    return (
      <Dialog fullWidth={true} maxWidth={'sm'} style={{ zIndex: 1000 }} open={openEditSellerClientDialog} onClose={() => { setOpenEditSellerClientDialog(false) }} aria-labelledby="edit-seller-client-dialog">
        <DialogTitle id="edit-seller-client-dialog-title">{format(langPropertyStock.titleSellerClientDialog, isNonEmpty(form.values.sellerClientId) ? lang.actionEdit : lang.actionAdd)}</DialogTitle>
        <DialogContent>
          <Grid item container spacing={1}
            md={12}
            sm={12}
            xs={12}
          >
            {showSellerInfo &&
              <Grid item
                md={6}
                sm={6}
                xs={6}
              >
                <Autocomplete
                  freeSolo={false}
                  popupIcon={""}
                  options={contacts}
                  getOptionLabel={(_contact: ContactsDTO) => sellerClientFormToUse.values.mainContact ?? ''}
                  noOptionsText={shouldDisplayClientAutoComplete ? langPropertyStock.msgContactNotFound : ''}
                  // getOptionLabel={(option) => option.phone}
                  // getOptionSelected={(option, value) => option.phone === value}
                  // onChange={handleChange}
                  // value={values.phone}
                  // {...sellerClientFormToUse.bind('mainContact')}
                  // inputValue={sellerClientFormToUse.values.mainContact ?? ''}
                  value={sellerClientFormToUse.values.mainContact || ''}
                  onChange={(_, contact: ContactsDTO | undefined) => {
                    sellerClientFormToUse.setValues({
                      ...sellerClientFormToUse.values,
                      sellerClientId: +(contact?.cid ?? '0'),
                      mainContactId: +(contact?.id ?? '0'),
                      'mainContact': contact?.value,
                      'sellerZh': contact?.chineseName,
                      'sellerEn': contact?.englishName,
                      'mainContactRemarks': contact?.remarks,
                      //auto-check the checkbox when company client is picked in the client autocomplete field
                      companyStock: contact?.isCompanyClient ? true : false,
                    });
                  }}
                  renderOption={(contact: ContactsDTO) => <div>
                    <div><Typography>{multiLang(locale, contact.chineseName, contact.englishName)}</Typography></div>
                    <div><Typography variant="caption" style={{ display: isNonEmpty(contact.value) ? 'block' : 'none' }}>{contact.value} ({contactOptions[contact.type]})</Typography></div>
                  </div>}
                  renderInput={params => (
                    <TextField
                      {...params}
                      className={classes.patchOutlineAutocomplete}
                      fullWidth
                      error={!!sellerClientFormToUse.hasError('mainContact')}
                      helperText={sellerClientFormToUse.hasError('mainContact') ?? langPropertyStock.msgInputClient}
                      label={langPropertyStock.captionClientContact}
                      margin="dense"
                      required
                      variant="outlined"
                      onChange={(ev) => {
                        setShouldDisplayClientAutoComplete(ev.target.value.length >= 1);
                        if (ev.target.value.length >= 1) {
                          searchClients(ev.target.value);
                        }
                      }}
                    />
                  )}
                />
              </Grid>
            }
            {showSellerInfo &&
              <Grid item
                md={6}
                sm={6}
                xs={6}
              >
                <TextField
                  fullWidth
                  disabled
                  label={lang.captionName}
                  margin="dense"
                  variant="outlined"
                  value={`${isNonEmpty(sellerClientFormToUse.values.sellerZh) ? sellerClientFormToUse.values.sellerZh : ''} ${isNonEmpty(sellerClientFormToUse.values.sellerEn) ? sellerClientFormToUse.values.sellerEn : ''}`}
                />
              </Grid>
            }

            {showSellerInfo ?
              <Grid item
                md={6}
                sm={6}
                xs={6}
              >
                <Button variant="text" color="primary" onClick={openCreationDialog}>{langPropertyStock.msgNewClient}</Button>
              </Grid> : null
            }

          </Grid>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setOpenEditSellerClientDialog(false) }} color="primary">
            {creating ? lang.actionConfirm : lang.actionCancel}
          </Button>
          {!creating && showSellerInfo &&
            <Button onClick={() => {
              if (!newSellerClientForm.validate()) {
                return;
              }
              confirmDialog.confirm(<div>
                <Typography>{'- '}{langPropertyStock.msgWarningReplaceExistingClient}</Typography>
                {
                  shouldUpdateApprovalStage ?
                    <Typography style={{ whiteSpace: 'pre-line' }}>{'- '}{langPropertyStock.msgWarningRequireApproveAgain}</Typography>
                    : null
                }
                <br />
                <Typography>{lang.actionConfirm + '?'}</Typography>
              </div>, lang.actionConfirm, lang.actionCancel, langPropertyStock.waringDialogTitle)
                .then(confirmed => {
                  if (confirmed) {
                    setOpenEditSellerClientDialog(false);
                    newSellerClientForm.setValues({});
                    newSellerClientForm.stopValidation();
                    dispatch({
                      type: 'Property.UpdateSellerClientRequested',
                      payload: {
                        id: form.values.id,
                        sellerClientId: sellerClientFormToUse.values.sellerClientId,
                        mainContactId: sellerClientFormToUse.values.mainContactId,
                        shouldResetApprovalStage: shouldUpdateApprovalStage,
                      } as PropertyStockDTO
                    });
                  }
                });
            }} color="primary" variant="contained" autoFocus>
              {lang.actionConfirm}
            </Button>
          }
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <div style={{ display: (shouldCollapse ? '' : 'flex'), flexDirection: 'row' }}>
          <CardHeader
            title={<Typography style={{ fontSize: '16px' }} color={form.hasError('mainContact') ? 'error' : 'inherit'}>{langPropertyStock.titleContactInfo}</Typography>}
          />
          {/* {
            (isLeadAgent || canReadTeamProperty || canReadUnowned || (form.values.companyStock === true && canReadCompanyStock) || creating) ?
            <TextField
              style={{ width: (shouldCollapse ? '90%' : '400px'), marginLeft: 8 , 
                      // background: (creating && isSameOwner || !creating && form.values.ownerFromLatestTransaction) ? 'inherit' : '#ffff80'
                    }}
              label={langPropertyStock.owner}
              margin="dense"
              variant="outlined"
              classes={{root:(creating && isSameOwner || !creating && form.values.ownerFromLatestTransaction) ? undefined : classes.highlightedOwnerTextField }}
                  {...form.bind('owner')}
            /> : null
          } */}

          {showSellerInfo &&
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ display: isNonEmpty(form.values.sellerClientId) ? 'flex' : 'none' }}>
                <Tooltip title={lang.actionEdit}>
                  <IconButton onClick={() => {
                    setOpenEditSellerClientDialog(true);
                  }}>
                    <PSEditClientInfoIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <div style={{ display: isNonEmpty(form.values.sellerClientId) ? 'none' : 'flex' }}>
                <Tooltip title={lang.actionAdd}>
                  <IconButton onClick={() => {
                    setOpenEditSellerClientDialog(true);
                  }}>
                    <PSAddClientInfoIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          }

          <div style={{ flexGrow: 1 }}></div>
          {/* {
            (!hideButton) ?
            <Button variant="text" color="primary" onClick={openCreationDialog}>{langPropertyStock.msgNewClient}</Button>
            : null
          } */}
        </div>
        {editSellerClientDialog()}
        {confirmDialog.render()}
        <ContactCreationDialog close={closeCreationDialog} onNewClient={(newClient) => {
          if (!newClient.cid) return;

          sellerClientFormToUse.setValues({
            ...form.values,
            sellerClientId: +(newClient.cid!),
            'mainContact': newClient.otherContacts[0]?.value,
            'sellerZh': newClient.chineseName,
            'sellerEn': newClient.englishName,
            'mainContactRemarks': newClient.otherContacts[0]?.remarks,
            companyStock: newClient.isCompanyClient ? true : undefined,
          })
        }} keepMounted open={creationDialogOpened && !hideButton} onClose={closeCreationDialog} />
        <ClientInfoDialog
          cid={form.values.sellerClientId?.toString() ?? ''}
          showSellerInfo={!!showSellerInfo}
          close={closeClientInfoDialog}
          open={clientInfoDialogOpen}
          onClose={closeClientInfoDialog} />
        <Divider />
        <CardContent>

          <Grid
            container
            spacing={1}
            style={{ alignItems: 'center' }}
          >
            {/* ----------- owner -------------*/}
            {
              (isLeadAgent || canReadTeamProperty || canReadUnowned || (form.values.companyStock === true && canReadCompanyStock) || creating) ?
                <Grid
                  item
                  md={6}
                  sm={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    style={{
                      // width: (shouldCollapse ? '90%' : '400px'), marginLeft: 8,
                      // backgroundColor: (creating && isSameOwner || !creating && form.values.ownerFromLatestTransaction) ? 'inherit' : '#ffff80'
                    }}
                    label={langPropertyStock.owner}
                    margin="dense"
                    variant="outlined"
                    classes={{ root: isSameOwner ? undefined : classes.highlightedOwnerTextField }}
                    {...form.bind('owner')}
                    onChange={(e) => {
                      limitTextFieldLength(e, 255, 'owner', form, keyBooleanMap, setKeyBooleanMap);
                    }}
                    error={!!form.hasError('owner')}
                    required
                  />
                  {form.hasError('owner') ? <FormHelperText error={!!form.hasError('owner')}>{langPropertyStock.msgInputOwner}</FormHelperText> : null}
                  <HandleFormHelperText
                    isError={keyBooleanMap.get('owner')}
                    errorMessage={lang.textOverLimit}
                  />
                </Grid> : null
            }
            {/* ----------- view client info  -------------*/}
            {(!creating && showSellerInfo || creating && form.values.sellerClientId) ?
              <div onClick={handleViewClientInfo}
              // style={{  cursor: 'pointer', display: 'flex', alignItems: 'center', flexDirection: 'column', padding: '0px 5px' }}
              >
                <IconButton classes={{ label: classes.clientInfoIconButtonLabel }}>
                  <PSClientInfoIcon style={{ fontSize: '45px', padding: '5px' }} />
                  <div>{multiLang(locale, form.values.sellerZh, form.values.sellerEn)}</div>
                </IconButton>
                <Typography></Typography>
              </div>
              : null
            }


            {/* {
              showSellerInfo?
              <Grid
                item
                md={3}
                sm={3}
                xs={12}
              >
                <Autocomplete
                  options={contacts}
                  getOptionLabel={(_contact: ContactsDTO) => form.values.mainContact ?? ''}
                  noOptionsText={shouldDisplayClientAutoComplete ? langPropertyStock.msgContactNotFound : ''}
                  // getOptionLabel={(option) => option.phone}
                  // getOptionSelected={(option, value) => option.phone === value}
                  // onChange={handleChange}
                  // value={values.phone}
                  // {...form.bind('mainContact')}
                  // inputValue={form.values.mainContact ?? ''}
                  value={form.values.mainContact || ''}
                  onChange={(_, contact: ContactsDTO | undefined) => {
                    form.setValues({
                      ...form.values,
                      sellerClientId: +(contact?.cid ?? '0'),
                      mainContactId: +(contact?.id ?? '0'),
                      'mainContact': contact?.value,
                      'sellerZh': contact?.chineseName,
                      'sellerEn': contact?.englishName,
                      'mainContactRemarks': contact?.remarks,
                      //auto-check the checkbox when company client is picked in the client autocomplete field
                      companyStock: contact?.isCompanyClient ? true : false,
                    });
                  }}
                  renderOption={(contact: ContactsDTO) => <div>
                    <div><Typography>{multiLang(locale, contact.chineseName, contact.englishName)}</Typography></div>
                    <div><Typography variant="caption" style={{display: isNonEmpty(contact.value) ? 'block' : 'none'}}>{contact.value} ({contactOptions[contact.type]})</Typography></div>
                  </div>}
                  renderInput={params => (
                    <TextField
                      {...params}
                      className={classes.patchOutlineAutocomplete}
                      fullWidth
                      error={!!form.hasError('mainContact')}
                      helperText={form.hasError('mainContact') ?? langPropertyStock.msgInputClient}
                      label={langPropertyStock.captionClientContact}
                      margin="dense"
                      required
                      variant="outlined"
                      onChange={(ev) => {
                        setShouldDisplayClientAutoComplete(ev.target.value.length >= 1);
                        if (ev.target.value.length >= 1) {
                          searchClients(ev.target.value);
                        }
                      }}
                    />
                  )}
                />
              </Grid> : null
            } */}

            {/* <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                label="電郵地址"
                margin="dense"
                // name="email"
                // onChange={handleChange}
                required
                value=""
                variant="outlined"
              />
            </Grid> */}
            {/* {
              showSellerInfo?
                <Grid
                  item
                  md={3}
                  sm={3}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    disabled
                    label={lang.captionName}
                    margin="dense"
                    variant="outlined"
                    value={`${isNonEmpty(form.values.sellerZh) ? form.values.sellerZh : ''} ${isNonEmpty(form.values.sellerEn) ? form.values.sellerEn : ''}`}
                  />
                </Grid> : null
            } */}

            {/* {
              showSellerInfo ?
                <Grid
                  item
                  md={3}
                  sm={3}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    disabled
                    label={langPropertyStock.captionRemarks}
                    margin="dense"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={form.values.mainContactRemarks}
                  />
                </Grid> : null
            } */}

            {/* {
              (!creating && showSellerInfo || creating && form.values.sellerClientId) ?
                <Grid
                  item
                  md={3}
                  sm={3}
                  xs={12}
                >
                  <Button 
                    startIcon={<ContactsIcon />} 
                    style={{ marginRight: 5, marginBottom:2, paddingTop: '15px'}} 
                    color="default" 
                    variant="text" 
                    onClick={handleViewClientInfo}
                  >{langPropertyStock.actionViewClientMaster}</Button> 
                  
                </Grid> : null
            } */}
          </Grid>
        </CardContent>
        <Divider />
        {/* <CardActions>
          <Button
            color="primary"
            variant="contained"
          >
            Save details
          </Button>
        </CardActions> */}
      </form>
    </Card>
  );
};

// ContactInfo.propTypes = {
//   className: PropTypes.string
// };

export default ContactInfo;

interface ClientInfoDialogProps extends DialogProps {
  cid: string;
  showSellerInfo: boolean;
  close: () => any;
}

const ClientInfoDialog = (props: ClientInfoDialogProps) => {

  const { cid, showSellerInfo, close, ...dialogProps } = props;
  const { locale, langClientDetail, clientTypeOptions, sourceOfClientOptions, contactOptions, lang } = useSelector((state: IRootState) => state.locale);
  const clientDetailDto = useSelector((state: IRootState) => state.clients.fetchedDetails[cid!]) ?? BLANK;
  const { token } = useSelector((state: IRootState) => state.login);
  const classes = useStyles();
  const theme = useTheme();
  const confirmCreateDialog = useConfirmDialog();
  const dispatch = useDispatch();
  const alertDialog = useInfoDialog();
  // const [duplicatedContactValues, setDuplicatedContactValues] = useState<{ type: string, value: string }[]>([]);
  const hasAlreadyBeenModifiedApiError = useSelector((state: IRootState) => state.clients.hasAlreadyBeenModifiedApiError)
  // const [contactDetail, setContactDetail] = useState<{ type: string, value: string }>({ type: '', value: '' });
  const [duplicatedContactValues, setDuplicatedContactValues] = useState<{ type: string, value: string }[]>([]);
  const [contactDetail, setContactDetail] = useState<{ type: string, value: string }>({ type: '', value: '' });
  useEffect(() => {
    if (!isNonEmpty(contactDetail) || duplicatedContactValues.some(d => d.type === contactDetail.type && d.value === contactDetail.value)) return;

    const timeout = setTimeout(() => {
      clientApi.checkDuplicatedContact(contactDetail, token ?? '').then(result => {
        if (!result.error && result.data) {
          setDuplicatedContactValues((prev) => [...new Set([...prev, contactDetail])]);
        }
      }); clearTimeout(timeout);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [contactDetail])

  // form init
  const form = useForm<ClientDetailDTO>({
    ...clientDetailDto
  }, {
    validations: CompactClientCreationValidations(langClientDetail),
  });

  useEffect(() => {
    if (cid !== '' && showSellerInfo) {
      dispatch({ type: 'ClientDetail.FetchRequested', payload: { cid: cid ?? '' } });
    }
  }, []);

  useEffect(() => {
    form.setValues({
      ...form.values,
      ...clientDetailDto
    })
  }, [clientDetailDto]);

  useEffect(() => {
    if (hasAlreadyBeenModifiedApiError === false) {
      close();
    }
  }, [hasAlreadyBeenModifiedApiError])

  const bindFeature = (featureKey: keyof ClientDetailDTO, type: string) => {
    const featureValues = (form.values[featureKey] as ClientDetailDTO['clientFeatures'])?.filter(cf => cf.type === type).map(cf => cf.value) ?? [];

    const setFeatureValues = (newList: string[]) => form.updateValues(featureKey, [
      ...((form.values[featureKey] as ClientDetailDTO['clientFeatures']) ?? [])?.filter(cf => cf.type !== type),
      ...newList.map(value => ({ type, value })),
    ]);

    return {
      value: featureValues,
      onChange: (ev: React.ChangeEvent<any>) => setFeatureValues(ev.target.value as string[])
    }
  }

  //add or remove contact
  const addContact = (key: string) => () => {
    form.updateValues('otherContacts',
      [...form.values.otherContacts ?? [], { type: key, value: undefined, remarks: undefined, }]
    );
  };
  const removeContact = (i: number) => () => {
    form.updateValues('otherContacts', form.values.otherContacts?.filter((_: any, _i: number) => i !== _i));
  }

  const contactMenu = useMenu();
  const history = useHistory();
  const confirmDialog = useConfirmDialog();

  //validation of contact values
  const invalidContactsPhoneNum = form.hasError('invalidPhoneNumber');//list of index concat with '_'
  const invalidContactsEmail = form.hasError('invalidEmail');//list of index concat with '_'
  const emptyContactValue = form.hasError('emptyContactValue');//list of index concat with '_' 

  const isInvalidContact = (idx: number, type: string) => {
    return getInvalidContactErrMsg(invalidContactsPhoneNum, invalidContactsEmail, emptyContactValue, idx, type, langClientDetail);
  };

  return (

    <Dialog maxWidth={'sm'} {...dialogProps}>
      {alertDialog.render({ zIndex: 6000 })}
      {confirmDialog.render()}
      {confirmCreateDialog.render()}
      <form
        autoComplete="off"
        noValidate
      >
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <Typography variant="h6">{langClientDetail.titleClientInfo}</Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={close}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        {/* <DialogTitle>{langClientDetail.titleClientInfo}</DialogTitle> */}
        <Divider />
        <DialogContent>
          {/* {true ? 
            <Grid item md={12} xs={12}>
              <FormControl component="fieldset" >
                <FormGroup aria-label="is-company-client-checkbox" row>
                  <FormControlLabel
                    control={<Checkbox color="primary" readOnly={true}/>}
                    label={langClientDetail.companyClient}
                    labelPlacement="end"
                    checked={form.values.isCompanyClient ?? false}
                  /> 
                </FormGroup>
              </FormControl>
            </Grid>
          : null } */}
          <Grid container spacing={1} style={{ padding: '5px 0px' }}>

            {/* ------------ client name -----------*/}
            <Grid item md={5} sm={5} xs={12}>
              <TextField
                fullWidth
                label={langClientDetail.clientName}
                margin="dense"
                required
                variant="outlined"
                InputProps={{ disabled: true }}
                value={multiLang(locale, form.values.chineseName, form.values.englishName) ?? ''}
              />
            </Grid>

            {/* ------------ client type -----------*/}
            <Grid item md={5} sm={5} xs={8}>
              <FormControl variant="outlined" margin="dense" fullWidth>
                <InputLabel required variant="outlined" style={{ background: '#fff' }}>{langClientDetail.clientType}</InputLabel>
                <Select
                  multiple
                  {...bindFeature('clientFeatures', 'clientType')}
                  renderValue={(selected) => (selected as string[]).map(key => clientTypeOptions[key]).join(', ')}
                  disabled={true}
                >
                  {Object.keys(clientTypeOptions).filter(key => key === 'S' || key === 'L').map(key => (
                    <MenuItem key={key} value={key}>
                      <Checkbox checked={bindFeature('clientFeatures', 'clientType').value.indexOf(key) > -1} />
                      <ListItemText primary={clientTypeOptions[key]} />
                    </MenuItem>
                  ))}
                </Select>
                {/* {tagForm.hasError('clientType') ?  <FormHelperText error>{tagForm.hasError('clientType')}</FormHelperText> : null} */}
              </FormControl>
            </Grid>

            {/* ------------ go to client detail page -----------*/}
            <Grid item md={2} sm={2} xs={4}>
              <Tooltip title={lang.captionMore}>
                <IconButton onClick={() => {
                  // history.push(`/client-detail/${cid}`);
                  const newWindow = window.open(`${PUBLIC_URL}/client-detail/${cid}`, '_blank', 'noopener,noreferrer')
                  if (newWindow) newWindow.opener = null
                }}>
                  <ExternalLinkIcon />
                </IconButton>
              </Tooltip>
            </Grid>

            { /* ----------- otherContacts caption ------------- */}
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
            // style={{ display: contactDisplayStyle(false) }}
            >
              <Typography color={!!form.hasError('otherContacts') ? 'error' : 'inherit'}>
                {langClientDetail.contactType + '*'}
                {/* <Button color="primary" variant="outlined" startIcon={<AddIcon />}>新增</Button> */}
                <Tooltip title={lang.actionAdd}>
                  <IconButton {...contactMenu.buttonProps()} color="primary">
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Typography>

              {!!form.hasError('otherContacts') ?
                <FormHelperText error={!!form.hasError('otherContacts')}>{langClientDetail.msgAtLeastOneContact}</FormHelperText>
                : null}

              <Menu {...contactMenu.menuProps()}>
                {Object.keys(contactOptions).map((key, idx) => {
                  return <MenuItem key={idx} onClick={addContact(key)}>{contactOptions[key]}</MenuItem>
                })}
              </Menu>

            </Grid>

            {/* ------------ client contacts -----------*/}

            {(form.values.otherContacts ?? []).map((contact: { type: string, value: string, name?: string, remarks?: string }, i: number) => {
              return <Grid item container md={12} spacing={1} key={i}>
                {/* ------- contact.type --------- */}
                <Grid
                  item
                  md={3}
                  sm={3}
                  xs={12}
                >
                  <Typography style={{ display: 'flex', alignItems: 'center', flexGrow: 2 }}>
                    <IconButton key={i} onClick={() => {
                      confirmDialog.confirm(langClientDetail.msgConfirmRemoveContact, lang.actionContinue, lang.actionNo).then(confirmed => {
                        if (confirmed) {
                          removeContact(i)();
                        }
                      });
                    }}>
                      <DeleteIcon />
                    </IconButton>
                    {contactOptions[contact.type]}{' : '}
                  </Typography>

                </Grid>

                {/* ------- contact.value --------- */}
                <Grid
                  item
                  md={4}
                  sm={4}
                  xs={12}
                >
                  <TextField
                    style={{}}
                    value={contact.value}
                    label={langClientDetail.contactNoOrEmail}
                    error={!!isInvalidContact(i, contact.type)}
                    helperText={isInvalidContact(i, contact.type)}
                    onChange={(e) => {
                      const updatedContact = { ...contact, value: e.target.value };
                      setContactDetail({ type: contact.type, value: e.target.value });
                      //update form
                      form.updateValues('otherContacts',
                        form.values.otherContacts?.map((c: Object, idx: number) => idx === i ? updatedContact : c)
                      );
                    }}
                    InputProps={{
                      endAdornment: duplicatedContactValues.some(d => d.type === contact.type && d.value === contact.value) ? <InputAdornment position="end">
                        <IconButton onClick={() => {

                          alertDialog.confirm(langClientDetail.msgDuplicatedContact, lang.actionConfirm, lang.actionConfirm);
                        }}>
                          <WarningIcon color='error' />
                        </IconButton>
                      </InputAdornment> : null,
                    }}
                    fullWidth
                    margin="dense"
                    required
                    variant="outlined"
                  />
                </Grid>


                {/* ------- contact.remarks --------- */}
                <Grid
                  item
                  md={4}
                  sm={4}
                  xs={12}
                >
                  <TextField
                    style={{}}
                    value={contact.remarks}
                    label={langClientDetail.contactRemarks}
                    onChange={(e) => {
                      const updatedContact = { ...contact, remarks: e.target.value };
                      //update form
                      form.updateValues('otherContacts',
                        form.values.otherContacts?.map((c: Object, idx: number) => idx === i ? updatedContact : c)
                      );
                    }}
                    fullWidth
                    margin="dense"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            })}

            {/* <Grid item md={3} sm={6}  xs={12}>
              <TextField
                {...form.bind('chineseName')}
                fullWidth
                label={langClientDetail.chineseName}
                margin="dense"
                required
                variant="outlined"
                InputProps={{ disabled: true }}
              />
            </Grid>

            <Grid item md={3} sm={6}  xs={12}>
              <TextField
                fullWidth
                {...form.bind('englishName')}
                label={langClientDetail.englishName}
                margin="dense"
                name="email"
                required
                variant="outlined"
                InputProps={{ disabled: true }}
              />
            </Grid>

            <Grid item md={3} sm={6} xs={12}>
              <FormControl variant="outlined" margin="dense" fullWidth>
                <InputLabel required variant="outlined" style={{ background: '#fff' }}>{langClientDetail.clientType}</InputLabel>
                <Select
                  multiple
                  {...bindFeature('clientFeatures', 'clientType')}
                  renderValue={(selected) => (selected as string[]).map(key => clientTypeOptions[key]).join(', ')}
                  disabled={true}
                >
                  {Object.keys(clientTypeOptions).filter(key => key === 'S' || key === 'L').map(key => (
                    <MenuItem key={key} value={key}>
                      <Checkbox checked={bindFeature('clientFeatures', 'clientType').value.indexOf(key) > -1} />
                      <ListItemText primary={clientTypeOptions[key]} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              md={3}
              sm={6}
              xs={12}
            >
              <TextField
                fullWidth
                label={langClientDetail.sourceOfClient}
                margin="dense"
                select
                required
                variant="outlined"
                {...form.bind('source')}
                InputProps={{ disabled: true }}
              >
                {
                  Object.keys(sourceOfClientOptions).map((key) =>
                    <MenuItem value={key}>{sourceOfClientOptions[key]}</MenuItem>)
                }

              </TextField>
            </Grid> */}

            {/* {(form.values.otherContacts ?? []).map((contact: { type: string, value: string, name?: string, remarks?: string }, i: number) => {
              return <Grid item container md={12} spacing={1}>
                  <Grid
                    item
                    md={2}
                    sm={2}
                    xs={12}
                  >
                    <Typography style={{ marginTop: 13, display: 'flex', alignItems: 'center', flexGrow: 2, width: 200 }}>
                      
                      {contactOptions[contact.type]}{' : '}
                    </Typography>

                  </Grid>
                
                  <Grid
                    item
                    md={4}
                    sm={4}
                    xs={12}
                  >
                    <TextField
                      style={{}}
                      value={contact.value}
                      label={langClientDetail.contactNoOrEmail}
                      // onChange={(e) => {
                      //   const updatedContact = { ...contact, value: e.target.value };
                      //   //update form
                      //   form.updateValues('otherContacts',
                      //     form.values.otherContacts?.map((c: Object, idx: number) => idx === i ? updatedContact : c)
                      //   );
                      // }}
                      fullWidth
                      margin="dense"
                      required
                      variant="outlined"
                      InputProps={{ disabled: true }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    sm={4}
                    xs={12}
                    style={{display: 'none'}}
                  >
                    <TextField
                      style={{}}
                      value={contact.name}
                      label={langClientDetail.contactName}
                      // onChange={(e) => {
                      //   const updatedContact = { ...contact, name: e.target.value };
                      //   //update form
                      //   form.updateValues('otherContacts',
                      //     form.values.otherContacts?.map((c: Object, idx: number) => idx === i ? updatedContact : c)
                      //   );
                      // }}
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      InputProps={{ disabled: true }}
                    />
                  </Grid>

                  <Grid
                    item
                    md={6}
                    sm={6}
                    xs={12}
                  >
                    <TextField
                      style={{}}
                      value={contact.remarks}
                      label={langClientDetail.contactRemarks}
                      onChange={(e) => {
                        const updatedContact = { ...contact, remarks: e.target.value };
                        //update form
                        form.updateValues('otherContacts',
                          form.values.otherContacts?.map((c: Object, idx: number) => idx === i ? updatedContact : c)
                        );
                      }}
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      InputProps={{ disabled: true }}
                    />
                  </Grid>
                </Grid>
            })} */}

            {/* --------------  agent section ---------------- */}

            {/* <Grid
              item
              md={12}
              xs={12}
              style={{display: !form.values.isCompanyClient ? 'block' : 'none'}}
            >
              <Typography style={{paddingTop: theme.spacing(1)}}>
                {langClientDetail.agent}
              </Typography>
            </Grid>
            <Grid
              item
              md={4}
              sm={4}
              xs={12}
              style={{display: !form.values.isCompanyClient ? 'block' : 'none'}}
            >
              <TextField
                value={form.values.isCompanyClient === true ? '' : multiLang(locale, form.values.agentNameZh, form.values.agentNameEn)}
                label={langClientDetail.contactName}
                InputProps={{ disabled: true }}
                InputLabelProps={{ shrink: true }}
                fullWidth
                margin="dense"
                required
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={4}
              sm={4}
              xs={12}
              style={{display: !form.values.isCompanyClient ? 'block' : 'none'}}
            >
              <TextField
                style={{}}
                value={form.values.isCompanyClient === true ? '' : form.values.agentContact?.type}
                label={langClientDetail.contactType}
                InputProps={{ disabled: true }}
                InputLabelProps={{ shrink: true }}
                fullWidth
                margin="dense"
                required
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={4}
              sm={4}
              xs={12}
              style={{display: !form.values.isCompanyClient ? 'block' : 'none'}}
            >
              <TextField
                style={{}}
                value={form.values.isCompanyClient === true ? '' : form.values.agentContact?.value}
                label={langClientDetail.contactNoOrEmail}
                InputProps={{ disabled: true }}
                InputLabelProps={{ shrink: true }}
                fullWidth
                margin="dense"
                required
                variant="outlined"
              />
            </Grid> */}
          </Grid>
        </DialogContent>
        {/* <Divider />*/}
        <DialogActions>
          <Button onClick={close} color="primary">
            {lang.actionCancel}
          </Button>
          <Button
            onClick={() => {
              const hasDupContact = isNonEmpty(form.values.otherContacts) ? form.values.otherContacts!.filter(contact => duplicatedContactValues.some(d => d.type === contact.type && d.value === contact.value)).length > 0 : false;
              if (!form.validate()) {
                return;
              } else if (hasDupContact) {
                confirmCreateDialog.confirm(langClientDetail.msgDuplicatedContactOnSave, lang.actionConfirm, lang.actionCancel).then(confirm => {

                  if (confirm) {

                    dispatch({ type: 'ClientDetail.UpdateRequested', payload: form.values });
                    form.stopValidation();
                    dispatch({ type: 'ClientDetail.setHasAlreadyBeenModifiedApiError', payload: undefined });
                  }
                })
              } else {
                dispatch({ type: 'ClientDetail.UpdateRequested', payload: form.values });
                form.stopValidation();
                dispatch({ type: 'ClientDetail.setHasAlreadyBeenModifiedApiError', payload: undefined });
              }
            }}
            color="primary"
            variant="contained"
          >
            {lang.actionSave}
          </Button>
        </DialogActions>
      </form>
    </Dialog >
  );
}