import { UserDTO } from "common/dto";
import NavigationBlockerDialog from "common/elements/NavigationBlockerDialog";
import { useForm } from "common/hooks";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { IRootState, PASDispatch } from "reducers";
import userFormValidations from './user-validations';
import UserDetail from './UserDetail';

const EMPTY = {};

export default function UserCreatePage() {
  const dispatch = useDispatch() as PASDispatch;
  const { langUser } = useSelector((state: IRootState) => state.locale);
  const { DEFAULT_COLUMN_PREFERENCE, DEFAULT_NUM_RECORD_PER_PAGE } = useSelector((state: IRootState) => state.systemSettings.System) ?? EMPTY;
  
  const userForm = useForm<UserDTO>({
    roles: [],
    status: 'A',
  }, {
    validations: userFormValidations(langUser),
  });

  useEffect(() => {
    // dispatch({ type: 'UserList.FetchRequested', payload: { } as any});
  }, [ 1 ]);

  const [ shoudBlockNaigation, setShoudBlockNaigation ] = useState(true);

  return <>
  <NavigationBlockerDialog matchesUrl={`/users/new`} unsaved={shoudBlockNaigation} goBack/>
  <UserDetail creating onSave={() => {
    if (!userForm.validate()) {
      return;
    }

    const initialRowsCountPreference = {
      CLIENT: DEFAULT_NUM_RECORD_PER_PAGE.CLIENT,
      PROPERTY_STOCK: DEFAULT_NUM_RECORD_PER_PAGE.PROPERTY_STOCK,
      TRANSACTION: DEFAULT_NUM_RECORD_PER_PAGE.TRANSACTION,
      BUILDING_MASTER: DEFAULT_NUM_RECORD_PER_PAGE.BUILDING_MASTER,
      MEMO_ENQUIRY: DEFAULT_NUM_RECORD_PER_PAGE.MEMO_ENQUIRY,
      SALE_COMMISSION_REPORT: DEFAULT_NUM_RECORD_PER_PAGE.SALE_COMMISSION_REPORT,
      NOTIFICATION_CENTER: DEFAULT_NUM_RECORD_PER_PAGE.NOTIFICATION_CENTER
    };

    setShoudBlockNaigation(false);

    dispatch({ type: 'User.CreationRequested', payload: {
      ...userForm.values,
      rowsCountPreference: JSON.stringify(initialRowsCountPreference),
      propertyStockColumnPreference: DEFAULT_COLUMN_PREFERENCE.PROPERTY_STOCK,
      clientColumnPreference: DEFAULT_COLUMN_PREFERENCE.CLIENT,
      transactionColumnPreference: DEFAULT_COLUMN_PREFERENCE.TRANSACTION,
    } as UserDTO });
  }} form={userForm} unsaved={true} /></>;
}