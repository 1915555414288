import { IRootState } from 'reducers';

export interface SalePaAccessControls {
	isBuyerAgent: boolean;
	isCompanyStock: boolean;
	isSellerAgent: boolean;
	isSalePaSigner: boolean;
	isSellerAgentExternal: boolean;
	isBuyerAgentExternal: boolean;

	canEditSellerFields: boolean;
	canEditBuyerFields: boolean;
	canEditSalePaAssignees: boolean;
	canEditSalePaData: boolean;
	canDeleteSalePa: boolean;
}

export interface TenancyPaAccessControls {
	isTenantAgent: boolean;
	isCompanyStock: boolean;
	isLandlordAgent: boolean;
	isTenancyPaSigner: boolean;
	isLandlordAgentExternal: boolean;
	isTenantAgentExternal: boolean;

	canEditLandlordFields: boolean;
	canEditTenantFields: boolean;
	canEditTenancyPaAssignees: boolean;
	canEditTenancyPaData: boolean;
	canDeleteTenancyPa: boolean;
}

export function selectSalePaAccessControls(isLeadAgent: boolean, isCompanyStock: boolean, currentUid: string, canDeleteUnownedProvisionalAgreement: boolean) {
	return (state: IRootState): SalePaAccessControls => {
		const currentSalePurchaseAgreement = state.property.salePurchaseAgreement;

		const isSellerAgentExternal = currentSalePurchaseAgreement?.sellerAgentType === 'E';
		const isBuyerAgentExternal = currentSalePurchaseAgreement?.buyerAgentType === 'E';
		const isBuyerAgent = !isBuyerAgentExternal && currentSalePurchaseAgreement?.buyerAgentUserId?.toString() === currentUid;
		const isSellerAgent = !isSellerAgentExternal && currentSalePurchaseAgreement?.sellerAgentUserId?.toString() === currentUid;
		const isSalePaSigner = currentSalePurchaseAgreement?.signerUserId?.toString() === currentUid;
		const leadAgentCreatingSalePa = isLeadAgent && !currentSalePurchaseAgreement?.id;

		const canEditSellerFields = isSellerAgent || isCompanyStock || (isSalePaSigner && isSellerAgentExternal) || leadAgentCreatingSalePa;
		const canEditBuyerFields = isBuyerAgent || isCompanyStock || (isSalePaSigner && isBuyerAgentExternal) || leadAgentCreatingSalePa;
		const canEditSalePaAssignees = isCompanyStock || leadAgentCreatingSalePa || isSalePaSigner;
		const canEditSalePaData = isCompanyStock || leadAgentCreatingSalePa || isSalePaSigner || isSellerAgent || isBuyerAgent;
		const canDeleteSalePa = !!(currentSalePurchaseAgreement?.id) && (isSalePaSigner || canDeleteUnownedProvisionalAgreement);

		return {
			isCompanyStock,
			isBuyerAgent,
			isSellerAgent,
			isSalePaSigner,
			isSellerAgentExternal,
			isBuyerAgentExternal,

			canEditSellerFields,
			canEditBuyerFields,
			canEditSalePaAssignees,
			canEditSalePaData,
			canDeleteSalePa,
		};
	};
}

export function selectTenancyPaAccessControls(isLeadAgent: boolean, isCompanyStock: boolean, currentUid: string, canDeleteUnownedProvisionalAgreement: boolean) {
	return (state: IRootState): TenancyPaAccessControls => {
		const currentTenancyAgreement = state.property.tenancyAgreement;

		const isLandlordAgentExternal = currentTenancyAgreement?.landlordAgentType === 'E';
		const isTenantAgentExternal = currentTenancyAgreement?.tenantAgentType === 'E';
		const isTenantAgent = !isTenantAgentExternal && currentTenancyAgreement?.tenantAgentUserId?.toString() === currentUid;
		const isLandlordAgent = !isLandlordAgentExternal && currentTenancyAgreement?.landlordAgentUserId?.toString() === currentUid;
		const isTenancyPaSigner = currentTenancyAgreement?.signerUserId?.toString() === currentUid;
		const leadAgentCreatingTenancyPa = isLeadAgent && !currentTenancyAgreement?.id;

		const canEditLandlordFields = isLandlordAgent || isCompanyStock || (isTenancyPaSigner && isLandlordAgentExternal) || leadAgentCreatingTenancyPa;
		const canEditTenantFields = isTenantAgent || isCompanyStock || (isTenancyPaSigner && isTenantAgentExternal) || leadAgentCreatingTenancyPa;
		const canEditTenancyPaAssignees = isCompanyStock || leadAgentCreatingTenancyPa || isTenancyPaSigner;
		const canEditTenancyPaData = isCompanyStock || leadAgentCreatingTenancyPa || isTenancyPaSigner || isLandlordAgent || isTenantAgent;
		const canDeleteTenancyPa = !!(currentTenancyAgreement?.id) && (isTenancyPaSigner || canDeleteUnownedProvisionalAgreement);

		return {
			isCompanyStock,
			isTenantAgent,
			isLandlordAgent,
			isTenancyPaSigner,
			isLandlordAgentExternal,
			isTenantAgentExternal,

			canEditLandlordFields,
			canEditTenantFields,
			canEditTenancyPaAssignees,
			canEditTenancyPaData,
			canDeleteTenancyPa,
		};
	}
}