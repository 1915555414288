import { call, put, takeLatest } from "redux-saga/effects";
import { requestMasterDataFailed, requestMasterDataSuccess } from '../actions';
import { fetchMasterData } from '../api';

export function* watchMasterDataRequestStart() {
    yield takeLatest(
        'MasterDataRequest.Start',   // reducer type: 'MasterDataRequest.Start' is being dispatched
        requestMasterData         // then call the function below
    );
}

function* requestMasterData() {
    // const response = yield call(fetchMasterData);
    // yield put({ type: 'MasterDataRequest.Complete', payload: response});
    try {
        const response = yield call(fetchMasterData);
        yield put(requestMasterDataSuccess(response));
    }
    catch(error) {
        yield put(requestMasterDataFailed(error));
        
    }
}