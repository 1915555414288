import { ApiError, ApiPageResult, ApiResult } from 'api';
import propertyApi from 'api/propertyApi';
import { MemoDTO, PropertyListItemDTO, PropertyStockDTO } from 'common/dto';
import { DEFAULT_FROM, DEFAULT_TO } from 'common/elements/MinMaxField';
import { isNonEmpty, priceFromView } from 'common/utils';
import { IRootState, LayoutActions, PropertyActions, PropertyMatchingActions, PropertyMatchingState } from 'reducers';
import { call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { PASSagaContext } from 'sagas';
import { PropertyStockSearchFormValues } from 'views/PropertyList/PropertyList';
import { apiTaskWrapper, itemActionMessage } from './saga-commons';


export function* watchPropertyListFetchRequested(context: PASSagaContext) {
  yield takeLatest(
    'PropertyList.FetchRequested',
    apiTaskWrapper(requestPropertyList),
    context,
  );
}

const toCriteria = (values: Partial<PropertyStockSearchFormValues>, locale: string, showApprovedOnly: boolean) => {
  if (Array.isArray(values.price)) {
    values = {
      ...values,
      price: [
        values.price[0] === DEFAULT_FROM ? DEFAULT_FROM : priceFromView(values.price[0], locale),
        values.price[1] === DEFAULT_TO ? DEFAULT_TO : priceFromView(values.price[1], locale),
      ],
    };
  }

  values = {
    ...values,
    buildingList: values.building?.split(/[,，;]+/)?.map(v => v.trim()).filter(v => isNonEmpty(v)),
    streetList: values.street?.split(/[,，;]+/)?.map(v => v.trim()).filter(v => isNonEmpty(v)),
    owner: values.owner?.trim(),
    mainContact: values.mainContact?.trim(),
    block: values.block?.trim(),
    propertyNo: values.propertyNo?.trim(),
    lot: values.lot?.trim(),
  }

  const newForm: Partial<PropertyStockSearchFormValues> = {};
  const { isGross, isRent, disabledCriteriaList, ...formValues } = values;

  let newDisabledCriteriaList = disabledCriteriaList ?? {};
  newDisabledCriteriaList['buildingList'] = disabledCriteriaList?.['building'] ?? false;
  newDisabledCriteriaList['streetList'] = disabledCriteriaList?.['street'] ?? false;

  Object
    .keys(formValues)
    .filter((key) => !(newDisabledCriteriaList ?? {})[key] &&
      (isGross ? (key !== 'net') : (key !== 'gross')) &&
      (isRent ? (key !== 'price') : (key !== 'rent'))
    )
    .forEach((key) => (newForm as any)[key] = formValues[key as keyof typeof formValues]);

  newForm.showApprovedOnly = showApprovedOnly;

  return newForm;
};

export function* requestPropertyList(context: PASSagaContext, action: Extract<PropertyActions, { type: 'PropertyList.FetchRequested' }>) {
  if (action.type !== 'PropertyList.FetchRequested') {
    return;
  }
  yield put({ type: 'Layout.MaskPresentRequested' });
  const token: string = yield select((state: IRootState) => state.login.token ?? '');
  // const propertyMatching: PropertyMatchingState = yield select((state: IRootState) => state.propertyMatching);
  // const showApprovedOnly = !!propertyMatching;
  const locale: string = yield select((state: IRootState) => state.locale.locale);

  const result: ApiPageResult<PropertyListItemDTO> = yield call(propertyApi.getList, toCriteria(action.payload, locale, action.payload.showApprovedOnly ?? false), token);


  if (!result.error) {
    if (action.payload.showApprovedOnly) {
      yield put<PropertyActions>({ type: 'PropertyMatchingList.Loaded', payload: result.data! });
      yield put({ type: 'Layout.MaskDismissRequested' });
    } else {
      yield put<PropertyActions>({ type: 'PropertyList.Loaded', payload: result.data! });
      yield put({ type: 'Layout.MaskDismissRequested' });
    }
  } else {
    yield put({ type: 'Layout.MaskDismissRequested' });
    throw ApiError.of(result.error);
  }

}

///////////////////////////////////////////////////////////////////
///////////// Property Detail Fetched (by id) /////////////////////
///////////////////////////////////////////////////////////////////

export function* watchPropertyDetailFetchRequested(context: PASSagaContext) {
  yield takeEvery(
    'Property.FetchRequested',
    apiTaskWrapper(requestPropertyDetail),
    context,
  )
}

export function* requestPropertyDetail(context: PASSagaContext, action: PropertyActions) {
  if (action.type != 'Property.FetchRequested') {
    return;
  }

  const { id } = action.payload;

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  // yield delay(1000);

  const result: ApiResult<PropertyStockDTO> = yield call(propertyApi.getDetail, id, token);

  if (!result.error) {
    yield put<PropertyActions>({ type: 'Property.Loaded', payload: result.data! });
    yield put<PropertyActions>({ type: 'Memo.FetchRequested', payload: { id: id ?? '' } });
  } else {
    yield put<PropertyActions>({ type: 'Property.Failed', payload: result.error });
    yield call(context.browserHistory.push as any, `/properties`);
    throw ApiError.of(result.error);
  }

}


///////////////////////////////////////////////////////////////////
//////////// Property Detail (by property no) /////////////////////
///////////////////////////////////////////////////////////////////

export function* watchPropertyDetailFetchByPropertyNoRequested(context: PASSagaContext) {
  yield takeEvery(
    'Property.FetchByPropertyNoRequested',
    apiTaskWrapper(requestPropertyDetailByPropertyNo),
    context,
  )
}

export function* requestPropertyDetailByPropertyNo(context: PASSagaContext, action: PropertyActions) {
  if (action.type != 'Property.FetchByPropertyNoRequested') {
    return;
  }

  const { propertyNo } = action.payload;

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<PropertyStockDTO> = yield call(propertyApi.getDetailByPropertyNo, propertyNo, token);

  if (!result.error) {
    yield put<PropertyActions>({ type: 'Property.Loaded', payload: result.data! });
  } else {
    yield put<PropertyActions>({ type: 'Property.Failed', payload: result.error });
    yield call(context.browserHistory.push as any, `/properties`);
    throw ApiError.of(result.error);
  }

}

/////////////////////////////////////////////////////////////////////
///////////////////////// Property Creation /////////////////////////
/////////////////////////////////////////////////////////////////////

export function* watchPropertyDetailCreationRequested(context: PASSagaContext) {
  yield takeEvery(
    'Property.CreationRequested',
    apiTaskWrapper(createPropertyDetail),
    context,
  );
}

export function* createPropertyDetail(context: PASSagaContext, action: Extract<PropertyActions, { type: 'Property.CreationRequested' }>) {


  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<PropertyStockDTO> = yield call(propertyApi.add, action.payload, token);


  if (!result.error) {
    yield* itemActionMessage('create', 'PropertyStock');
    const propertyNo = result.data?.propertyNo;
    if (action.payload.addLeadAgentInPreviewPage) {
      yield call(context.browserHistory.replace as any, `/properties-edit/${propertyNo}`);
    } else {
      yield call(context.browserHistory.replace as any, `/properties/${propertyNo}`);
    }
  } else {
    throw ApiError.of(result.error);
  }
}

/////////////////////////////////////////////////////////////////////
////////////////////////// Property Update //////////////////////////
/////////////////////////////////////////////////////////////////////

export function* watchPropertyDetailUpdateRequested(context: PASSagaContext) {
  yield takeEvery(
    'Property.UpdateRequested',
    apiTaskWrapper(updatePropertyDetail),
    context,
  );
}

export function* updatePropertyDetail(context: PASSagaContext, action: Extract<PropertyActions, { type: 'Property.UpdateRequested' }>) {


  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<PropertyStockDTO> = yield call(propertyApi.update, action.payload, token);

  if (!result.error) {
    yield* itemActionMessage('update', 'PropertyStock');
    const readyToStage2Approval = result.data!.longitude && result.data!.latitude && result.data!.status == 'SALES' ? result.data!.form3Available : result.data!.status == 'RENT' ? result.data!.form5Available : (result.data!.form3Available && result.data!.form5Available);
    const approvalSuggestions: Set<number> = new Set();
    if (!result.data!.pendingApprovalStage) {
      if (result.data!.published) {
        if (readyToStage2Approval && result.data!.approvalStage <= 1) {
          approvalSuggestions.add(2);
        }
        if (result.data!.approvalStage === 0) {
          approvalSuggestions.add(1);
        }
      } else {
        if (readyToStage2Approval && result.data!.approvalStage <= 1) {
          approvalSuggestions.add(2);
        }
      }
    }
    if (approvalSuggestions.size > 0) {
      yield put<PropertyActions>({ type: 'Property.Loaded', payload: { ...result.data!, approvalSuggestions: [...approvalSuggestions], noNavigateAfterApprovalSuggestions: action.meta?.noNavigate } });
    } else {
      yield put<PropertyActions>({ type: 'Property.Loaded', payload: { ...result.data! } });
      if (!action.meta?.noNavigate) {
        yield delay(1000); //FIXME: ugly workaround, so that the local state `unsaved` can catch up with the update
        yield call(context.browserHistory.replace as any, `/properties/${result.data?.propertyNo}`);
      }
    }

    // yield put<PropertyActions>({ type: 'Memo.FetchRequested',  payload: { id: result.data?.id ?? '' }});
    // yield put<CloseTransactionActions>({ type: 'CloseTransaction.FetchRequested', payload: { pid: result.data?.id ?? ''} });
  } else {
    throw ApiError.of(result.error);
  }

}

///////////////////////////////////////////////////////////////////////
////////////////////////// Property Approval //////////////////////////
///////////////////////////////////////////////////////////////////////

export function* watchPropertyApprovalRequested(context: PASSagaContext) {
  yield takeEvery(
    'Property.ApprovalRequested',
    apiTaskWrapper(approvePropertyStock),
    context,
  );
}

export function* approvePropertyStock(context: PASSagaContext, action: Extract<PropertyActions, { type: 'Property.ApprovalRequested' }>) {


  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<PropertyStockDTO> = yield call(propertyApi.approve, action.payload.id, action.payload.action, action.payload.stage, action.payload.rejectionReason, token);

  if (!result.error) {
    yield put<PropertyActions>({ type: 'Property.Loaded', payload: result.data! });
  } else {
    throw ApiError.of(result.error);
  }

}

export function* watchStatusUpdateRequested() {
  yield takeLatest(
    'Property.UpdateStatusRequested',
    apiTaskWrapper(requestStatusRequested)
  );
}

export function* requestStatusRequested(action: PropertyActions) {
  if (action.type !== 'Property.UpdateStatusRequested') {
    return;
  }

  const { id, status, dateModified } = action.payload;

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<PropertyStockDTO> = yield call(propertyApi.updateStatus, id, status, dateModified, token);

  if (!result.error) {
    yield put<PropertyActions>({ type: 'Property.Loaded', payload: result.data! });
    yield put<PropertyActions>({ type: 'Memo.FetchRequested', payload: { id: id ?? '' } });
  } else {
    throw ApiError.of(result.error);
  }

}
/////////////////////////////////////////////////////////////////////
///////////////////////// Memo List Fetched /////////////////////////
/////////////////////////////////////////////////////////////////////
export function* watchPropertyMemoFetchRequested() {
  yield takeLatest(
    'Memo.FetchRequested',
    apiTaskWrapper(requestMemoList),
  );
}

export function* requestMemoList(action: PropertyActions) {
  if (action.type !== 'Memo.FetchRequested') {
    return;
  }

  const { id } = action.payload;

  if (!isNonEmpty(id)) {
    return;
  }

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<MemoDTO[]> = yield call(propertyApi.getMemoList, id, token);

  if (!result.error) {
    yield put<PropertyActions>({ type: 'Memo.Loaded', payload: result.data! });
  } else {
    yield put<PropertyActions>({ type: 'Memo.Failed', payload: result.error });
  }

}

export function* watchPropertyMemoCreateRequested(context: PASSagaContext) {
  yield takeLatest(
    'Memo.CreateRequested',
    apiTaskWrapper(createMemo),
    context
  );
}

export function* createMemo(context: PASSagaContext, action: PropertyActions) {
  if (action.type !== 'Memo.CreateRequested') {
    return;
  }

  const { id, memo } = action.payload;

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<MemoDTO> = yield call(propertyApi.addMemo, id, memo, token);

  if (!result.error) {
    // yield call(context.browserHistory.push as any, `/properties/${id}`);
    yield put<PropertyActions>({ type: 'Memo.FetchRequested', payload: { id: id ?? '' } });
  } else {
    throw ApiError.of(result.error);
  }
}

export function* watchClaimPropertyRequested(context: PASSagaContext) {
  yield takeEvery(
    'Property.ClaimRequested',
    apiTaskWrapper(claimProperty),
    context,
  );
}

export function* claimProperty(context: PASSagaContext, action: Extract<PropertyActions, { type: 'Property.ClaimRequested' }>) {
  // 

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  // const lastSearchCriteria  = yield select((state: IRootState) => state.clients.lastSearchCriteria as ClientSearchCritiera);
  const result: ApiResult<any> = yield call(propertyApi.agentClaim, action.payload.id ?? '', token);

  if (!result.error) {
    // yield put<ClientActions>({ type: 'ClientList.FetchRequested', payload: { isCurrentUser: true }});
    // yield put<ClientActions>({ type: 'ClientDetail.TabIndexChanged', payload: { cid: action.payload.cid, tabIndex: 2}});
    // yield put<PropertyMatchingActions>({ type: 'PropertyMatching.StopMatching' });
    yield call(context.browserHistory.push as any, `/properties/${result.data}`);
    // yield put<PropertyActions>({ type: 'Property.FetchRequested', payload: { id: result.data ?? '' } });
  } else {
    throw ApiError.of(result.error!);
  }
}

export function* watchRemovePropertyRequested(context: PASSagaContext) {
  yield takeEvery(
    'Property.RemoveRequested',
    apiTaskWrapper(removeProperty),
    context,
  );
}

export function* removeProperty(context: PASSagaContext, action: Extract<PropertyActions, { type: 'Property.RemoveRequested' }>) {
  // 

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  // const lastSearchCriteria  = yield select((state: IRootState) => state.clients.lastSearchCriteria as ClientSearchCritiera);
  const result: ApiResult<any> = yield call(propertyApi.remove, action.payload.id ?? '', token);

  if (!result.error) {
    yield call(context.browserHistory.push as any, `/properties`);
  } else {
    throw ApiError.of(result.error!);
  }
}

///////////////////////////////////////////////////////////////////
///////////////////// Remove Lead agent requested /////////////////
///////////////////////////////////////////////////////////////////

export function* watchRemoveLeadAgentRequested(context: PASSagaContext) {
  yield takeEvery(
    'Property.RemoveLeadAgentRequested',
    apiTaskWrapper(requestRemoveLeadAgent),
    context,
  );
}

export function* requestRemoveLeadAgent(context: PASSagaContext, action: Extract<PropertyActions, { type: 'Property.RemoveLeadAgentRequested' }>) {
  if (action.type != 'Property.RemoveLeadAgentRequested') {
    return;
  }

  const { pid, agentId } = action.payload;

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<PropertyStockDTO> = yield call(propertyApi.removeLeadAgent, pid, agentId, token);

  if (!result.error) {
    yield call(context.browserHistory.push as any, `/properties`);
  } else {
    yield put<PropertyActions>({ type: 'Property.Failed', payload: result.error });
    throw ApiError.of(result.error);
  }

}

///////////////////////////////////////////////////////////////////
///////////////////// Add Lead agent requested ////////////////////
///////////////////////////////////////////////////////////////////

export function* watchAddLeadAgentRequested(context: PASSagaContext) {
  yield takeEvery(
    'Property.AddLeadAgentRequested',
    apiTaskWrapper(requestAddLeadAgent),
    context,
  );
}

export function* requestAddLeadAgent(context: PASSagaContext, action: Extract<PropertyActions, { type: 'Property.AddLeadAgentRequested' }>) {
  if (action.type != 'Property.AddLeadAgentRequested') {
    return;
  }
  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<PropertyStockDTO> = yield call(propertyApi.addLeadAgent, action.payload, token, action.payload.addAgentsToNewPropertyStockByAdmin ?? 'false', action.payload.addedFromPropertyStockNum ?? '');
  if (!result.error) {
    yield put<PropertyActions>({ type: 'Property.FetchRequested', payload: { id: action.payload.id! } });
  } else {
    yield put<PropertyActions>({ type: 'Property.Failed', payload: result.error });
    throw ApiError.of(result.error);
  }

}


export function* watchPropertyColumnPreferenceRequested() {
  yield takeEvery(
    'Property.ColumnPreference.FetchRequested',
    apiTaskWrapper(requestPropertyColumnPreference),
  );
}

export function* requestPropertyColumnPreference(action: PropertyActions) {
  if (action.type != 'Property.ColumnPreference.FetchRequested') {
    return;
  }
  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<any> = yield call(propertyApi.getColumnPreference, token);

  if (!result.error) {
    yield put<PropertyActions>({ type: 'Property.ColumnPreference.Loaded', payload: JSON.parse(result.data) ?? {} })
  } else {
    throw ApiError.of(result.error);
  }

}


/////////////////////////////////////////////////////////////////////
///////////// Property Update Seller Client /////////////////////////
/////////////////////////////////////////////////////////////////////

export function* watchUpdateSellerClientRequested(context: PASSagaContext) {
  yield takeEvery(
    'Property.UpdateSellerClientRequested',
    apiTaskWrapper(UpdateSellerClient),
    context,
  );
}

export function* UpdateSellerClient(context: PASSagaContext, action: Extract<PropertyActions, { type: 'Property.UpdateSellerClientRequested' }>) {


  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<any> = yield call(propertyApi.updateSellerClient, action.payload, token);

  if (!result.error) {
    yield put<PropertyActions>({ type: 'Property.FetchRequested', payload: { id: action.payload.id! } });
  } else {
    throw ApiError.of(result.error);
  }

}

export function* watchAddStockCartMultiClientRequested(context: PASSagaContext) {
  yield takeEvery(
    'PropertyMatching.MultiClient.AddStockCartRequested',
    apiTaskWrapper(addStockCartMultiClient),
    context,
  );
}

export function* addStockCartMultiClient(context: PASSagaContext, action: Extract<PropertyMatchingActions, { type: 'PropertyMatching.MultiClient.AddStockCartRequested' }>) {
  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const { langPropertyStock } = yield select((state: IRootState) => state.locale);

  const result: ApiResult<any> = yield call(propertyApi.addStockCartMultiClient, action.payload, token);

  if (!result.error) {
    yield put<LayoutActions>({ type: 'Layout.AlertMessageAdded', payload: { message: langPropertyStock.msgAddToMultiClientWishlistsSuccess, severity: 'error' } })
  } else {
    throw ApiError.of(result.error!);

  }

}


export function* watchProvisionalAgreementDeleteRequested(context: PASSagaContext) {
  yield takeEvery(
    'ProvisionalAgreement.DeleteRequested',
    apiTaskWrapper(deleteProvisionalAgreement),
    context,
  );
}

export function* deleteProvisionalAgreement(_context: PASSagaContext, action: Extract<PropertyActions, { type: 'ProvisionalAgreement.DeleteRequested' }>) {
  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<any> = yield call(propertyApi.deleteProvisionalAgreement, action.payload.propertyStockId, action.payload.type, token);

  if (!result.error) {
    yield put<PropertyActions>({
      type: action.payload.type === 'SALE' ? 'SalePurchaseAgreement.FetchRequested' : 'TenancyAgreement.FetchRequested',
      payload: { propertyStockId: action.payload.propertyStockId },
    });
  } else {
    throw ApiError.of(result.error);
  }
}

export function* watchWishlistGrantedAgentsFetchRequested(context: PASSagaContext) {
  yield takeEvery(
    'Property.WishlistGrantedAgents.FetchRequested',
    apiTaskWrapper(fetchWishlistGrantedAgents),
    context,
  );
}

export function* fetchWishlistGrantedAgents(_context: PASSagaContext, action: Extract<PropertyActions, { type: 'Property.WishlistGrantedAgents.FetchRequested' }>) {
  const { token, uid } = yield select((state: IRootState) => state.login ?? {});

  const result: ApiResult<any> = yield call(propertyApi.fetchWishlistGrantedAgents, action.payload.propertyStockId, uid, token);

  if (!result.error) {
    yield put<PropertyActions>({
      type: 'Property.WishlistGrantedAgents.Loaded',
      payload: result.data!
    });
  } else {
    throw ApiError.of(result.error);
  }
}

export function* watchWishlistGrantedAgentsUpdateRequested(context: PASSagaContext) {
  yield takeEvery(
    'Property.WishlistGrantedAgents.UpdateRequested',
    apiTaskWrapper(updateWishlistGrantedAgents),
    context,
  );
}

export function* updateWishlistGrantedAgents(_context: PASSagaContext, action: Extract<PropertyActions, { type: 'Property.WishlistGrantedAgents.UpdateRequested' }>) {
  const { token, uid } = yield select((state: IRootState) => state.login ?? {});

  const result: ApiResult<any> = yield call(propertyApi.updateWishlistGrantedAgents,
    action.payload.propertyStockId, uid,
    action.payload.updates, token,
  );

  if (!result.error) {
    yield put<PropertyActions>({
      type: 'Property.WishlistGrantedAgents.FetchRequested',
      payload: { propertyStockId: action.payload.propertyStockId },
    });
  } else {
    throw ApiError.of(result.error);
  }
}

function dispatch(arg0: { type: string; }) {
  throw new Error('Function not implemented.');
}
