import {
  Card, CardContent, CardHeader, Divider,
  Grid,
  TextField
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { BuildingDetailDTO } from 'common/dto';
import DistrictSingleSelect from 'common/elements/DistrictSingleSelect';
import { FormPresenter } from 'common/hooks';
import { HandleFormHelperText } from 'common/ui';
import { initFormKeysAndBooleanMap, isNonEmpty, limitTextFieldLength } from 'common/utils';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';
import MapDialogContainer from './MapDialog';



const useStyles = makeStyles(() => ({
  root: {},
  patchOutlineAutocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
      paddingLeft: 8,
    }
  }
}));

interface BuildingBasicInfoProps {
  className?: string;
  form: FormPresenter<Partial<BuildingDetailDTO>>;
  isNew: boolean;
  canCreate: boolean;
  canEdit: boolean;
}

const BuildingBasicInfo = (props: BuildingBasicInfoProps) => {
  const { className, form, isNew, canCreate, canEdit, ...rest } = props;
  const { langBuilding: lang, usageOptions } = useSelector((state: IRootState) => state.locale);
  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);
  const classes = useStyles();

  const bind = form.bind;

  const [keyBooleanMap, setKeyBooleanMap] = useState(initFormKeysAndBooleanMap(form));

  useEffect(() => {
    setKeyBooleanMap(initFormKeysAndBooleanMap(form));
  }, [Object.keys(form.values).length])

  // useEffect(() => {
  //   dispatch({ type: 'Locale.SwitchLocale', locale: 'en' });
  // }, []);
  var startDate = moment(new Date()); // today

  const handleMapDialogSave = (lat: number, lng: number) => {
    form.updateValues('latitude', lat);
    form.updateValues('longitude', lng);
  }

  const canSave = isNew || canCreate || canEdit;

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardHeader
          title={lang.titleBasicInfo}
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={1}
          >
            {/* <Grid
              item
              md={4}
              sm={4}
              xs={12}
            >
              <TextField
                fullWidth
                label={lang.usage}
                margin="dense"
                required
                select
                variant="outlined"
                {...bind('usage')}
              >
								{Object.keys(usageOptions).map(key => (
									<MenuItem key={key} value={key}>{usageOptions[key]}</MenuItem>
								))}
              </TextField>
            </Grid> */}
            <Grid
              item
              md={8}
              sm={8}
              xs={12}
            >
              <DistrictSingleSelect
                {...bind('district')}
                fullWidth
                label={lang.district}
                margin="dense"
                required
                select
                variant="outlined"
                error={!!form.hasError('district')}
                helperText={form.hasError('district')}
                districtValue={form.values.district ?? ''}
                onToggleChip={(value) => form.updateValues('district', value)}
                disableSelection={!canSave}
              />
            </Grid>

            {/* <Grid
              item
              md={5}
              sm={5}
              xs={12}
            >
              <TextField
                {...bind('latitude')}
                label={lang.latitude}
                fullWidth
                margin="dense"
                variant="outlined"
              ></TextField>
            </Grid>

            <Grid
              item
              md={5}
              sm={5}
              xs={12}
            >
              <TextField
                {...bind('longitude')}
                label={lang.longitude}
                fullWidth
                margin="dense"
                variant="outlined"
              ></TextField>
            </Grid> */}

            <Grid
              item
              md={1}
              sm={1}
              xs={2}
            //alignItems="center"
            >
              <MapDialogContainer
                allowSave={canSave}
                allowClick={canSave}
                onMapSave={handleMapDialogSave}
                selectedValue={[form.values.latitude, form.values.longitude]}
              />
            </Grid>

            {isNonEmpty(form.values.locationScore) &&
              <Grid
                item
                md={3}
                sm={3}
                xs={12}
              >
                <TextField
                  fullWidth
                  label={lang.locationScore}
                  margin="dense"
                  variant="outlined"
                  {...bind('locationScore')}
                  InputProps={{ disabled: true }}
                />
              </Grid>
            }

            <Grid
              item
              md={12}
              sm={12}
              xs={12}
            >
              <TextField
                {...bind('buildingNameZh')}
                fullWidth
                label={form.values.usage !== 'V' ? lang.buildingNameZh : lang.villageNameZh}
                margin="dense"
                name="lastName"
                variant="outlined"
                required
                InputProps={{ readOnly: !canSave }}
                onChange={(e) => {
                  limitTextFieldLength(e, 255, 'buildingNameZh', form, keyBooleanMap, setKeyBooleanMap);
                }}
              />
              <HandleFormHelperText
                isError={keyBooleanMap.get('buildingNameZh')}
                errorMessage={lang.textOverLimit}
              />
            </Grid>

            <Grid
              item
              md={12}
              sm={12}
              xs={12}
            >
              <TextField
                {...bind('buildingNameEn')}
                fullWidth
                label={form.values.usage !== 'V' ? lang.buildingNameEn : lang.villageNameEn}
                margin="dense"
                name="lastName"
                variant="outlined"
                required
                InputProps={{ readOnly: !canSave }}
                onChange={(e) => {
                  limitTextFieldLength(e, 255, 'buildingNameEn', form, keyBooleanMap, setKeyBooleanMap);
                }}
              />
              <HandleFormHelperText
                isError={keyBooleanMap.get('buildingNameEn')}
                errorMessage={lang.textOverLimit}
              />
            </Grid>

            <Grid
              item
              md={6}
              sm={6}
              xs={12}
            >
              <TextField
                {...bind('streetZh')}
                fullWidth
                label={lang.streetZh}
                margin="dense"
                variant="outlined"
                // {...bind('street')}
                InputProps={{ readOnly: !canSave }}
                onChange={(e) => {
                  limitTextFieldLength(e, 255, 'streetZh', form, keyBooleanMap, setKeyBooleanMap);
                }}
              />
              <HandleFormHelperText
                isError={keyBooleanMap.get('streetZh')}
                errorMessage={lang.textOverLimit}
              />
            </Grid>

            <Grid
              item
              md={6}
              sm={6}
              xs={12}
            >
              <TextField
                {...bind('streetEn')}
                fullWidth
                label={lang.streetEn}
                margin="dense"
                variant="outlined"
                // {...bind('street')}
                InputProps={{ readOnly: !canSave }}
                onChange={(e) => {
                  limitTextFieldLength(e, 255, 'streetEn', form, keyBooleanMap, setKeyBooleanMap);
                }}
              />
              <HandleFormHelperText
                isError={keyBooleanMap.get('streetEn')}
                errorMessage={lang.textOverLimit}
              />
            </Grid>

            <Grid
              item
              md={12}
              sm={12}
              xs={12}
            >
              <TextField
                fullWidth
                label={lang.lot}
                margin="dense"
                variant="outlined"
                {...bind('lot')}
                InputProps={{ readOnly: !canSave }}
                onChange={(e) => {
                  limitTextFieldLength(e, 255, 'lot', form, keyBooleanMap, setKeyBooleanMap);
                }}
              />
              <HandleFormHelperText
                isError={keyBooleanMap.get('lot')}
                errorMessage={lang.textOverLimit}
              />
            </Grid>

            <Grid
              item
              md={4}
              sm={4}
              xs={12}
            >
              <TextField
                fullWidth
                label={lang.blockZh}
                margin="dense"
                variant="outlined"
                {...bind('blockZh')}
                InputProps={{ readOnly: !canSave }}
                onChange={(e) => {
                  limitTextFieldLength(e, 255, 'blockZh', form, keyBooleanMap, setKeyBooleanMap);
                }}
              />
              <HandleFormHelperText
                isError={keyBooleanMap.get('blockZh')}
                errorMessage={lang.textOverLimit}
              />
            </Grid>

            <Grid
              item
              md={4}
              sm={4}
              xs={12}
            >
              <TextField
                fullWidth
                label={lang.blockEn}
                margin="dense"
                variant="outlined"
                {...bind('blockEn')}
                InputProps={{ readOnly: !canSave }}
                onChange={(e) => {
                  limitTextFieldLength(e, 255, 'blockEn', form, keyBooleanMap, setKeyBooleanMap);
                }}
              />
              <HandleFormHelperText
                isError={keyBooleanMap.get('blockEn')}
                errorMessage={lang.textOverLimit}
              />
            </Grid>

            <Grid
              item
              md={4}
              sm={4}
              xs={'auto'}
            >

            </Grid>


            <Grid
              item
              md={4}
              sm={4}
              xs={12}
            >
              <KeyboardDatePicker autoOk={true}
                variant="inline"
                inputVariant="outlined"
                fullWidth
                format={DISPLAY_DATE_FORMAT}
                label={lang.occupancyPermitDate}
                margin="dense"
                // inputValue={occupancyPermitDateInput}
                value={form.values.occupancyPermitDate ? moment(form.values.occupancyPermitDate) : null}
                onChange={(value) => {
                  // setOccupancyPermitDateInput(value?.format(DISPLAY_DATE_FORMAT) ?? '');
                  form.setValues({
                    ...form.values,
                    occupancyPermitDate: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString(),
                  });
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                disabled={!canSave}
                InputProps={{ readOnly: !canSave }}
              />
              {/* <TextField
                fullWidth
                label="入伙日期"
                margin="dense"
                type="text"
                variant="outlined"
                {...bind('occupancyPermitDate')}
              /> */}
            </Grid>

            <Grid
              item
              md={2}
              sm={2}
              xs={12}
            >
              <TextField
                fullWidth
                label={lang.occupancyPermitAge}
                margin="dense"
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
                // {...bind('occupancyPermitAge')}
                value={-moment(form.values.occupancyPermitDate ?? startDate.toISOString()).diff(moment(), 'years') ?? 0}
              />
            </Grid>

            <Grid
              item
              md={2}
              sm={2}
              xs={12}
            >
              <TextField
                fullWidth
                label={lang.expiryYear}
                margin="dense"
                variant="outlined"
                placeholder="YYYY"
                // inputProps={inputProps}
                {...bind('expiryYear')}
                onChange={(e) => {
                  limitTextFieldLength(e, 255, 'expiryYear', form, keyBooleanMap, setKeyBooleanMap);
                }}
                InputProps={{ readOnly: !canSave }}
              >
              </TextField>
              <HandleFormHelperText
                isError={keyBooleanMap.get('expiryYear')}
                errorMessage={lang.textOverLimit}
              />
            </Grid>

            <Grid
              item
              md={4}
              sm={4}
              xs={12}
            >
              <TextField
                fullWidth
                label={lang.developer}
                margin="dense"
                // required
                variant="outlined"
                {...bind('developer')}
                InputProps={{ readOnly: !canSave }}
                onChange={(e) => {
                  limitTextFieldLength(e, 255, 'developer', form, keyBooleanMap, setKeyBooleanMap);
                }}
              />
              <HandleFormHelperText
                isError={keyBooleanMap.get('developer')}
                errorMessage={lang.textOverLimit}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        {/* <CardActions>
          <Button
            color="primary"
            variant="contained"
          >
            Save details
          </Button>
        </CardActions> */}
      </form>
    </Card>
  );
};

export default BuildingBasicInfo;