import { makeStyles } from '@material-ui/core';
import { hasPermission } from 'common/access-control';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState, PASDispatch } from 'reducers';
import SystemSettingSearch, { SystemSettingSearchForm } from './components/SystemSettingSearch';
import SystemSettingTable from './components/SystemSettingTable';
import SystemSettingToolbar from './components/SystemSettingToolbar';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  search: {
    marginBottom: theme.spacing(2)
  }
}));

const SystemSettingList = () => {
	const classes = useStyles(0);

	const dispatch = useDispatch() as PASDispatch;
	const systemSettings = useSelector((state: IRootState) => state.systemSettingsList.currentList);
  const { totalPages, totalElements } = useSelector((state: IRootState) => state.systemSettingsList);
	
	const [ page, setPage ] = useState(0);
  const [ limit ] = useState(10);
	const [ sortOrders, setSortOrders ] = useState<{ [k: string]: 'desc' | 'asc' }>({
    dateModified: 'desc',
  });
  
  const [ values, setValues ] = useState<Partial<SystemSettingSearchForm>>({});

  const handleSearch = (values: Partial<SystemSettingSearchForm>) => {
		setValues(values);
		setPage(0);
		
	};
	
	
	const handleSort = (key: string | null) => {
    if (key === null) {
      setSortOrders({});
     
      return;
    }
    const newSortOrders = { [key]: sortOrders[key] === 'asc' ? 'desc' : 'asc' };
    setSortOrders(newSortOrders as any);
    
	}
	
	useEffect(() => {
    dispatch({ type: 'SystemSettingList.FetchRequested', payload: {
			page, size: limit, sort: sortOrders, ...values
    } });
    
	}, [ page, limit, sortOrders, values ]);

	const { privileges } = useSelector((state: IRootState) => state.login);
	const canCreate= hasPermission(privileges, 'CREATE', 'SYSTEM_SETTING');
	
	return (
		<div className={classes.root}>
			<SystemSettingToolbar canCreate={canCreate ? "true" : "false"}/>
			<div className={classes.content}>				
      <SystemSettingSearch onSearch={handleSearch} className={classes.search}/>
				<SystemSettingTable
					page={page}
					limit={limit}
					totalPages={totalPages}
					totalElements={totalElements}
					setPage={(page) => {
						setPage(page);
						// dispatch({ 
						// 	type: 'UserList.FetchRequested', 
						// 	payload: { page, size: limit }
            // });
					}}
					systemSettings={systemSettings}
					sortOrders={sortOrders}
					handleSort={handleSort}
				/>
			</div>
		</div>
	);
};

export default SystemSettingList;