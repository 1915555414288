import {
    Card,
    CardContent,
    Grid,
    Table, TableBody, TableCell, TableHead, TableRow
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MemoDTO } from 'common/dto';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from "reducers";

const useStyles = makeStyles(() => ({
  root: {

  },
  card: {
    overflowX: 'hidden',
    overflowY: 'scroll',
    height: 400,
    padding: 0,
  },
  memoCard: {
    overflowX: 'hidden',
    overflowY: 'scroll',
    height: 300,
  },
  highlight: {
    fontWeight: 'bold'
  },
  textArea:{
    width: 300,
    fontSize: '14px'
  }
}));

interface MemoProps {
	className?: string,
	memos?: MemoDTO[]
}

export const Memo = (props: MemoProps) => {
  const { memos } = props;

  const { lang, locale } = useSelector((state: IRootState) => state.locale);
  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);

  const classes = useStyles();

  return (
    // <Grid container md={12} xs={12}>
    <Grid container >
      <Grid item md={12} xs={12}>
        <Card>
          <CardContent 
            className={classes.card}
          >
            <Table stickyHeader>
								<TableHead>
									<TableRow>
                    <TableCell>{lang.dateCreated}</TableCell>
                    <TableCell>{lang.content}</TableCell>
                    <TableCell>{lang.author}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {memos?.map(memo => 
                    <TableRow key={memo.id}>
                      <TableCell>{memo.dateCreated ? moment(memo.dateCreated).format(DISPLAY_DATE_FORMAT) : ''}</TableCell>
                      <TableCell style={{whiteSpace: 'pre-line'}}>{memo.content}</TableCell>
                      <TableCell>{locale === 'en' ? memo.authorNameEn : memo.authorNameZh}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
            </Table>
          </CardContent>
        </Card>        
      </Grid>
    </Grid>    
  )  
}

export default Memo;