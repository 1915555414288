import {
  Button, Card, CardActions, CardContent, Checkbox, Divider, FormControl, FormControlLabel, FormHelperText, Grid, InputAdornment, makeStyles, MenuItem, Radio, RadioGroup, TextareaAutosize, TextField, TextFieldProps, Typography, withStyles
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { KeyboardDatePicker } from '@material-ui/pickers';
import clsx from 'clsx';
import { Form2DTO, PropertyStockDTO } from 'common/dto';
import { FormPresenter } from "common/hooks";
import { HandleFormHelperText } from 'common/ui';
import { genAddress, initFormKeysAndBooleanMap, isNonEmpty, limitNumberFieldLength, limitTextFieldLength, multiLang } from 'common/utils';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducers';
const regex = new RegExp(/(^\d*\.?\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/);
const NumberField = withStyles({
  root: {
    '& input': {
      textAlign: 'right',
      '-moz-appearance': 'textfield',
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  },
},
)((props: TextFieldProps) => {
  return <TextField type={"text"}{...props} inputProps={{
    ...props.inputProps,
    min: 0,
    max: Math.max(),
    inputMode: 'decimal',
    pattern: "([^0-9]*)",
    step: 0.01
  }}

    onKeyDown={e => {
      // If the key pressed is not a number or a period, nothing is printed
      if (!e.key.match(regex)) {

        e.preventDefault();

      }
      // if (!e.key.match(regex) || (e.key === '.' && String(props.value).indexOf('.') > 0) || (e.key === '.' && String(props.value).length === 0) || e.keyCode === 229 || e.key === 'Unidentified') {

      //   e.preventDefault();

      // }

    }}

    onKeyPress={e => {
      // If the key pressed is not a number or a period, nothing is printed
      if (!e.key.match(regex)) {

        e.preventDefault();

      }
    }}


  />;
});

const useStyles = makeStyles(() => ({
  root: {},
  partTitle: {
    fontWeight: 'bold',
  },
  textArea: {
    width: '100%',
    margin: 'dense',
    padding: '8px',
    fontSize: '14px'
  },
  table: {
    minWidth: 650,
  },
  datePicker: {
    width: '30%',
  },
  column0: {
    width: '5%',
  },
  partB_column1: {
    width: '20%',
  },
  partC_column1: {
    width: '20%',
  },
  numberFields: {
    width: '30%',
  },
  part2_column1: {
    width: '7%',
  },
  part2_description: {
    width: '100%',
  },
  part2_textAreaDiv: {
    paddingTop: '4px'
  },
  subTableCell: {
    borderBottomColor: '#FFFFFF',
  },
  divider: {
    backgroundColor: '#1565c0',
  },
  longTableContainer: {
    overflowY: 'auto',
  },
}));

const errorColor = '#e53935';

export const form2Initial: Partial<Form2DTO> = {
  hasPossessionOrders: false,
  hasMortgageOrCharge: false,
  hasRestrictionOnLeasing: false,
  hasYearOfCompletion: false,
  hasUserRestrictions: false,
  toBeSublet: false,
};

interface Form2Props {
  className?: string;
  propertyStockId: string;
  form2: FormPresenter<Form2DTO>;
}

const Form2Dialog = (props: Form2Props) => {
  const { className, form2, propertyStockId, ...rest } = props;
  const classes = useStyles();
  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);
  const agencyCompanyInfo = useSelector((state: IRootState) => state.systemSettings.Company.AGENCY_COMPANY_INFO);
  const { lang, langForm2, form2SourceOptions, langPropertyStock, formLocaleOptions, locale } = useSelector((state: IRootState) => state.locale);
  const dispatch = useDispatch();

  const bind = form2.bind;

  const [keyBooleanMap, setKeyBooleanMap] = useState(initFormKeysAndBooleanMap(form2));

  useEffect(() => {
    setKeyBooleanMap(initFormKeysAndBooleanMap(form2));
  }, [Object.keys(form2.values).length])

  const requiredFields: { [key: string]: string[] } = {
    form: ['formLocale',],
    partA: ['propertyAddress'],
    partB1: ['ownerName', 'dateOfObtainingB1'],
    partB2: ['partB2_option', 'saleableAreaByDepartment', 'saleableAreaFirstAssignment', 'dateOfObtainingB2'],
    partB3: [/*'yearOfCompletion', 'sourceOfB3', */'dateOfObtainingB3'],
    partB4: [/*'userRestrictions', 'sourceOfB4',*/ 'dateOfObtainingB4'],
    partB5: [/*'sourceOfB5',*/ 'dateOfObtainingB5'],
    partC: ['annualGovRent', 'quarterlyRates', 'monthlyManagementFee', 'subletParticulars'],
    agentInfo: ['agentName', 'nameAndLicenceNumberOfSignatory', 'numberOfAgentStatement', 'address', 'telephoneNumber'],
  };

  const getPartName = (part: string) => {
    return multiLang(locale, part + langForm2.part, langForm2.part + part);
  }

  const partNameMapping = (partName: string) => {
    switch (partName) {
      case 'form': return langForm2.form;
      case 'partA': return getPartName('A');
      case 'partB1': return getPartName('B1');
      case 'partB2': return getPartName('B2');
      case 'partB3': return getPartName('B3');
      case 'partB4': return getPartName('B4');
      case 'partB5': return getPartName('B5');
      case 'partC': return getPartName('C');
      case 'agentInfo': return langForm2.agentInfo;
    }
  }

  const missingFieldMapping: { [key: string]: string } = {
    //general
    formLocale: langForm2.captionFormLocale,
    //partA
    propertyAddress: langForm2.propertyAddress,
    //B1
    ownerName: langForm2.ownerName,
    dateOfObtainingB1: langForm2.dateOfObtainingInfo,
    //B2
    partB2_option: langForm2.titlePartB2,
    saleableAreaByDepartment: langForm2.B2_optionA,
    saleableAreaFirstAssignment: langForm2.B2_optionB,
    dateOfObtainingB2: langForm2.dateOfObtainingInfo,
    //B3
    yearOfCompletion: langForm2.titlePartB3,
    sourceOfB3: langForm2.source,
    dateOfObtainingB3: langForm2.dateOfObtainingInfo,
    //B4
    userRestrictions: langForm2.titlePartB4,
    sourceOfB4: langForm2.source,
    dateOfObtainingB4: langForm2.dateOfObtainingInfo,
    //B5
    sourceOfB5: langForm2.source,
    dateOfObtainingB5: langForm2.dateOfObtainingInfo,
    //Part C
    annualGovRent: langForm2.annualGovRent,
    quarterlyRates: langForm2.quarterlyRates,
    monthlyManagementFee: langForm2.monthlyManagementFee,
    subletParticulars: langForm2.subletParticulars,
    //agent info
    agentName: langForm2.agentName,
    nameAndLicenceNumberOfSignatory: langForm2.nameAndLicenceNumberOfSignatory,
    numberOfAgentStatement: langForm2.numberOfAgentStatement,
    address: langForm2.address,
    telephoneNumber: langForm2.telephoneNumber,
  };

  const missingFieldList = () => {
    let resultStr: string[] = [];
    Object.keys(requiredFields).forEach(part => {
      let partMissingFields: string[] = [];
      requiredFields[part].forEach(key => {
        if (form2.hasError(key as keyof Form2DTO)) {
          partMissingFields.push(missingFieldMapping[key]);
        }
      });
      if (partMissingFields.length > 0) {
        resultStr.push(partNameMapping(part.toString()) + ": " + partMissingFields.join(', '));
      }

    });
    return resultStr.join('\n');
  }
  const propertyStockDto = useSelector((state: IRootState) => state.property.fetchedDetailsByPropertyStockId[props.propertyStockId]) ?? {} as Partial<PropertyStockDTO>;
  const { districtHkiOptions: districtHkiOptionsEn, districtNtOptions: districtNtOptionsEn, districtKltOptions: districtKltOptionsEn } = useSelector((state: IRootState) => state.locale._bundle['en']);
  const { districtHkiOptions: districtHkiOptionsZhHK, districtNtOptions: districtNtOptionsZhHK, districtKltOptions: districtKltOptionsZhHK } = useSelector((state: IRootState) => state.locale._bundle['zh_HK']);

  const districtEnOptions = {
    ...districtHkiOptionsEn,
    ...districtNtOptionsEn,
    ...districtKltOptionsEn,
  };
  const districtZhHkOptions = {
    ...districtHkiOptionsZhHK,
    ...districtNtOptionsZhHK,
    ...districtKltOptionsZhHK,
  };

  const companyDetailsLocale = (locale: string) => {
    return (locale === 'en') ? { name: agencyCompanyInfo['COMPANY_NAME_EN'], address: agencyCompanyInfo['ADDRESS_EN'] } : { name: agencyCompanyInfo['COMPANY_NAME_CN'], address: agencyCompanyInfo['ADDRESS_CN'] };
  };
  const propertyAddress = (locale: string) => {
    const address = genAddress(locale,
      propertyStockDto.unit?.join(',') ?? '',
      propertyStockDto.floor?.join(',') ?? '',
      propertyStockDto.block ?? '', propertyStockDto.blockEn ?? '',
      propertyStockDto.building ?? '', propertyStockDto.buildingEn ?? '',
      propertyStockDto.street ?? '', propertyStockDto.streetEn ?? '',
      (locale === 'en') ? districtEnOptions[propertyStockDto.district ?? ''] : districtZhHkOptions[propertyStockDto.district ?? '']);
    return address;
  };

  const updateCompanyCurrentLocaleDetails = (locale: string) => {
    let details = companyDetailsLocale(locale);
    form2.updateValues('agentName', details.name);
    form2.updateValues('address', details.address);
    form2.updateValues('propertyAddress', propertyAddress(locale));
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container xs={12} spacing={1}>
          {isNonEmpty(missingFieldList()) ?
            <Grid
              item
              md={12}
              xs={12}
            >

              <div style={{ marginBottom: '5px', whiteSpace: "pre-wrap" }}>
                <Typography style={{ color: errorColor, fontSize: '13px', fontWeight: 'bold', fontStyle: 'italic' }}>{langForm2.msgInputMissingFields}</Typography>
                <Typography style={{ color: errorColor, fontSize: '13px', fontStyle: 'italic' }}>{missingFieldList()}</Typography>
              </div>
              <Divider />
            </Grid>
            : null
          }
          <Grid
            item
            sm={2}
            md={2}
            xs={12}
          >
            <Typography>{langPropertyStock.captionFormLocale}</Typography>
            <TextField select
              fullWidth
              margin="dense"
              {...bind('formLocale')}
              required
              variant="outlined"
              onChange={(event) => {
                bind('formLocale').onChange(event);
                updateCompanyCurrentLocaleDetails(event.target.value);
              }}>
              {
                Object.keys(formLocaleOptions).map((opt) => <MenuItem value={opt}>{formLocaleOptions[opt]}</MenuItem>)
              }
            </TextField>
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >
            <Typography style={{ fontSize: '16px' }} className={classes.partTitle} >{langForm2.formName}</Typography>
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >
            <Typography className={classes.partTitle}>{langForm2.titlePartA}</Typography>

          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >
            <TextareaAutosize
              className={classes.textArea}
              rowsMin={2}
              rowsMax={2}
              placeholder=''
              {...bind('propertyAddress')}
              onChange={(e) => {
                limitTextFieldLength(e, 255, 'propertyAddress', form2, keyBooleanMap, setKeyBooleanMap);
              }}
            />
            {form2.hasError('propertyAddress') ? <FormHelperText style={{ color: form2.hasError('propertyAddress') ? errorColor : 'inherit' }}>{langForm2.pleaseInput + langForm2.propertyAddress}</FormHelperText> : null}
            <HandleFormHelperText
              isError={keyBooleanMap.get('propertyAddress')}
              errorMessage={lang.textOverLimit}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

        </Grid>

        {/* -------------------------- part B ----------------------*/}
        <Grid container xs={12} spacing={1}>

          <Grid
            item
            md={12}
            xs={12}
          >
            <Typography className={classes.partTitle}>{langForm2.titlePartB}</Typography>

          </Grid>

          {/* ------------------------- B1 ----------------------------- */}
          <Grid
            item
            md={12}
            xs={12}
          >
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="B1 table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.column0}>1.</TableCell>
                    <TableCell colSpan={2} align="left">{langForm2.titlePartB1}</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {/*----------- ownerName ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell className={classes.partB_column1} >{langForm2.ownerName}</TableCell>
                    <TableCell>
                      <TextField
                        className={classes.datePicker}
                        margin="dense"
                        variant="outlined"
                        {...bind('ownerName')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'ownerName', form2, keyBooleanMap, setKeyBooleanMap);
                        }}
                        helperText={form2.hasError('ownerName')}
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('ownerName')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>

                  {/*----------- possession orders ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell colSpan={2}>
                      <Checkbox
                        checked={form2.values.hasPossessionOrders}
                        onChange={() => {
                          form2.updateValues('hasPossessionOrders', !form2.values.hasPossessionOrders);
                        }}
                      />
                      {langForm2.possessionOrders}

                    </TableCell>
                  </TableRow>

                  {/*----------- mortgage or charge---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell colSpan={2}>
                      <Checkbox
                        checked={form2.values.hasMortgageOrCharge}
                        onChange={() => {
                          form2.updateValues('hasMortgageOrCharge', !form2.values.hasMortgageOrCharge);
                        }}
                      />
                      {langForm2.mortgageOrCharge}

                    </TableCell>

                  </TableRow>

                  {/*----------- Date of obtaining the information of section B1 ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell >
                      {langForm2.dateOfObtainingInfo}

                    </TableCell>
                    <TableCell data-key={'field-form2-dateOfObtainingB1'}>
                      <KeyboardDatePicker autoOk={true}
                        className={classes.datePicker}
                        //disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        format={DISPLAY_DATE_FORMAT}
                        margin="dense"
                        maxDate={new Date()}
                        value={form2.values.dateOfObtainingB1 ? moment(form2.values.dateOfObtainingB1) : null}
                        onChange={(value) => {
                          form2.setValues({
                            ...form2.values,
                            dateOfObtainingB1: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
                          });
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        maxDateMessage={langForm2.msgSelectionValidDate}
                      />
                      {form2.hasError('dateOfObtainingB1') ? <FormHelperText style={{ color: form2.hasError('dateOfObtainingB1') ? errorColor : 'inherit' }}>{form2.hasError('dateOfObtainingB1')}</FormHelperText> : null}

                    </TableCell>
                  </TableRow>


                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          {/* ------------------------- B2 ----------------------------- */}
          <Grid
            item
            md={12}
            xs={12}
          >
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="B2 table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.column0}>2.</TableCell>
                    <TableCell colSpan={2} align="left">{langForm2.titlePartB2}</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {/*----------- Source of the Floor area of the Property  ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell colSpan={2} data-key={'field-form2-partB2_option'}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          value={form2.values.partB2_option ?? ''}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const val = (event.target as HTMLInputElement).value;
                            form2.updateValues('saleableAreaByDepartment', '');
                            form2.updateValues('saleableAreaFirstAssignment', '');
                            form2.updateValues('partB2_option', val);
                          }}
                        >
                          <div>
                            <FormControlLabel
                              value="optionA"
                              control={<Radio />}
                              label={'(a) ' + langForm2.B2_optionA + ':'}
                            />
                            <TextField
                              style={{}}
                              className={classes.datePicker}
                              margin="dense"
                              variant="outlined"
                              disabled={form2.values.partB2_option !== 'optionA'}
                              {...bind('saleableAreaByDepartment')}
                              onChange={(e) => {
                                limitTextFieldLength(e, 255, 'saleableAreaByDepartment', form2, keyBooleanMap, setKeyBooleanMap);
                              }}
                            />
                            <HandleFormHelperText
                              isError={keyBooleanMap.get('saleableAreaByDepartment')}
                              errorMessage={lang.textOverLimit}
                            />
                            <Typography></Typography>
                          </div>
                          <div>
                            <FormControlLabel
                              value="optionB"
                              control={<Radio />}
                              label={'(b) ' + langForm2.B2_optionB + ':'}
                            />
                            <TextField
                              style={{}}
                              className={classes.datePicker}
                              margin="dense"
                              variant="outlined"
                              disabled={form2.values.partB2_option !== 'optionB'}
                              {...bind('saleableAreaFirstAssignment')}
                              onChange={(e) => {
                                limitTextFieldLength(e, 255, 'saleableAreaFirstAssignment', form2, keyBooleanMap, setKeyBooleanMap);
                              }}
                            />
                            <HandleFormHelperText
                              isError={keyBooleanMap.get('saleableAreaFirstAssignment')}
                              errorMessage={lang.textOverLimit}
                            />
                          </div>
                          <FormControlLabel
                            value="optionC"
                            control={<Radio />}
                            label={langForm2.B2_optionC}
                          />
                          <FormControlLabel value="" control={<Radio size="small" />} label='' style={{ display: 'none' }} />
                        </RadioGroup>
                      </FormControl>
                      {form2.hasError('partB2_option') ? <FormHelperText style={{ color: form2.hasError('partB2_option') ? errorColor : 'inherit' }}>{langForm2.pleaseSelect}</FormHelperText> : null}

                    </TableCell>
                  </TableRow>


                  {/*----------- Date of obtaining the information of section B2 ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell className={classes.partB_column1}>
                      {langForm2.dateOfObtainingInfo}

                    </TableCell>
                    <TableCell data-key={'field-form2-dateOfObtainingB2'}>
                      <KeyboardDatePicker autoOk={true}
                        className={classes.datePicker}
                        //disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        format={DISPLAY_DATE_FORMAT}
                        margin="dense"
                        maxDate={new Date()}
                        value={form2.values.dateOfObtainingB2 ? moment(form2.values.dateOfObtainingB2) : null}
                        onChange={(value) => {
                          form2.setValues({
                            ...form2.values,
                            dateOfObtainingB2: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
                          });
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        maxDateMessage={langForm2.msgSelectionValidDate}
                      />
                      {form2.hasError('dateOfObtainingB2') ? <FormHelperText style={{ color: form2.hasError('dateOfObtainingB2') ? errorColor : 'inherit' }}>{form2.hasError('dateOfObtainingB2')}</FormHelperText> : null}


                    </TableCell>
                  </TableRow>

                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          {/* ------------------------- B3 ----------------------------- */}
          <Grid
            item
            md={12}
            xs={12}
          >
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="B3 table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.column0}>3.</TableCell>
                    <TableCell colSpan={8} align="left">{langForm2.titlePartB3}</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {/*----------- year of completion ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell className={classes.partB_column1}>
                      {langForm2.yearOfCompletion}

                    </TableCell>
                    <TableCell colSpan={2} data-key={'field-form2-yearOfCompletion'}>
                      <Checkbox
                        checked={form2.values.hasYearOfCompletion}
                        onChange={() => {
                          form2.updateValues('hasYearOfCompletion', !form2.values.hasYearOfCompletion);
                          if (form2.values.hasYearOfCompletion) {
                            form2.setValues({
                              ...form2.values,
                              hasYearOfCompletion: false,
                              yearOfCompletion: "",
                              sourceOfB3: undefined
                            });
                          }
                        }}
                      />
                      <KeyboardDatePicker autoOk={true}
                        className={classes.datePicker}
                        //disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        format="YYYY"
                        margin="dense"
                        openTo={"year"}
                        views={['year']}
                        disabled={form2.values.hasYearOfCompletion ? false : true}
                        value={form2.values.yearOfCompletion && form2.values.hasYearOfCompletion ? moment(form2.values.yearOfCompletion) : null}
                        onChange={(_, value) => {
                          form2.setValues({
                            ...form2.values,
                            yearOfCompletion: value ?? undefined
                          });
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        maxDateMessage={langForm2.msgSelectionValidDate}
                      />
                      {form2.hasError('yearOfCompletion') ? <FormHelperText style={{ color: form2.hasError('yearOfCompletion') ? errorColor : 'inherit' }}>{langForm2.pleaseInput + langForm2.titlePartB3}</FormHelperText> : null}
                    </TableCell>
                  </TableRow>

                  {/*----------- source of year of completion ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell>
                      {langForm2.source}

                    </TableCell>
                    <TableCell colSpan={4}>
                      <TextField
                        className={classes.datePicker}
                        margin="dense"
                        select
                        disabled={form2.values.hasYearOfCompletion ? false : true}
                        variant="outlined"
                        {...bind('sourceOfB3')}
                      >
                        {
                          Object.keys(form2SourceOptions).filter((key) => key == 'A' || key == 'B' || key == 'E').map((key) =>
                            <MenuItem key={key} value={key}>{form2SourceOptions[key]}</MenuItem>)
                        }

                      </TextField>
                    </TableCell>
                  </TableRow>

                  {/*----------- Date of obtaining the information of section B3 ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell className={classes.partB_column1}>
                      {langForm2.dateOfObtainingInfo}

                    </TableCell>
                    <TableCell colSpan={2} data-key={'field-form2-dateOfObtainingB3'}>
                      <KeyboardDatePicker autoOk={true}
                        className={classes.datePicker}
                        //disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        format={DISPLAY_DATE_FORMAT}
                        margin="dense"
                        maxDate={new Date()}
                        value={form2.values.dateOfObtainingB3 ? moment(form2.values.dateOfObtainingB3) : null}
                        onChange={(value) => {
                          form2.setValues({
                            ...form2.values,
                            dateOfObtainingB3: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
                          });
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        maxDateMessage={langForm2.msgSelectionValidDate}
                      />
                      {form2.hasError('dateOfObtainingB3') ? <FormHelperText style={{ color: form2.hasError('dateOfObtainingB3') ? errorColor : 'inherit' }}>{form2.hasError('dateOfObtainingB3')}</FormHelperText> : null}

                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

          </Grid>

          {/* ------------------------- B4 ----------------------------- */}
          <Grid
            item
            md={12}
            xs={12}
          >
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="B4 table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.column0}>4.</TableCell>
                    <TableCell colSpan={2} align="left">{langForm2.titlePartB4}</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {/*----------- user restrictions ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell className={classes.partB_column1}>
                      {langForm2.B4_description}

                    </TableCell>
                    <TableCell>
                      <Checkbox
                        style={{ marginBottom: "23px" }}
                        checked={form2.values.hasUserRestrictions}
                        onChange={() => {
                          form2.updateValues('hasUserRestrictions', !form2.values.hasUserRestrictions);
                          if (form2.values.hasUserRestrictions) {
                            form2.setValues({
                              ...form2.values,
                              hasUserRestrictions: false,
                              userRestrictions: undefined,
                              sourceOfB4: undefined
                            });
                          }
                        }}
                      />
                      <TextareaAutosize
                        className={classes.textArea}
                        style={{ width: '60%', marginTop: "20px" }}
                        rowsMin={1}
                        rowsMax={2}
                        disabled={form2.values.hasUserRestrictions ? false : true}
                        placeholder=''
                        {...bind('userRestrictions')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'userRestrictions', form2, keyBooleanMap, setKeyBooleanMap);
                        }}
                      />
                      {form2.hasError('userRestrictions') ? <FormHelperText style={{ color: form2.hasError('userRestrictions') ? errorColor : 'inherit' }}>{langForm2.pleaseInput + langForm2.titlePartB4}</FormHelperText> : null}
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('userRestrictions')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>

                  {/*----------- source of user restriction info ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell>
                      {langForm2.source}

                    </TableCell>
                    <TableCell>
                      <TextField
                        className={classes.datePicker}
                        margin="dense"
                        select
                        disabled={form2.values.hasUserRestrictions ? false : true}
                        variant="outlined"
                        {...bind('sourceOfB4')}
                      >
                        {
                          Object.keys(form2SourceOptions).filter((key) => key == 'A' || key == 'E').map((key) =>
                            <MenuItem key={key} value={key}>{form2SourceOptions[key]}</MenuItem>)
                        }

                      </TextField>
                    </TableCell>
                  </TableRow>

                  {/*----------- Date of obtaining the information of section B4 ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell >
                      {langForm2.dateOfObtainingInfo}

                    </TableCell>
                    <TableCell data-key={'field-form2-dateOfObtainingB4'}>
                      <KeyboardDatePicker autoOk={true}
                        className={classes.datePicker}
                        //disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        format={DISPLAY_DATE_FORMAT}
                        margin="dense"
                        maxDate={new Date()}
                        value={form2.values.dateOfObtainingB4 ? moment(form2.values.dateOfObtainingB4) : null}
                        onChange={(value) => {
                          form2.setValues({
                            ...form2.values,
                            dateOfObtainingB4: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
                          });
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        maxDateMessage={langForm2.msgSelectionValidDate}
                      />
                      {form2.hasError('dateOfObtainingB4') ? <FormHelperText style={{ color: form2.hasError('dateOfObtainingB4') ? errorColor : 'inherit' }}>{form2.hasError('dateOfObtainingB4')}</FormHelperText> : null}

                    </TableCell>
                  </TableRow>

                </TableBody>
              </Table>
            </TableContainer>

          </Grid>

          {/* ------------------------- B5 ----------------------------- */}
          <Grid
            item
            md={12}
            xs={12}
          >
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="B5 table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.column0}>5.</TableCell>
                    <TableCell colSpan={2} align="left">{langForm2.titlePartB5}</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>

                  {/*-----------Restrictions on leasing ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell className={classes.partB_column1}>
                      {langForm2.B5_descripton}

                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={form2.values.hasRestrictionOnLeasing}
                        onChange={() => {
                          form2.updateValues('hasRestrictionOnLeasing', !form2.values.hasRestrictionOnLeasing);
                          if (form2.values.hasRestrictionOnLeasing) {
                            form2.setValues({
                              ...form2.values,
                              hasRestrictionOnLeasing: false,
                              sourceOfB5: undefined
                            });
                          }
                        }}
                      />
                    </TableCell>
                  </TableRow>

                  {/*----------- source of user restriction info ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell>
                      {langForm2.source}

                    </TableCell>
                    <TableCell>
                      <TextField
                        className={classes.datePicker}
                        margin="dense"
                        select
                        variant="outlined"
                        disabled={form2.values.hasRestrictionOnLeasing ? false : true}
                        {...bind('sourceOfB5')}
                      >
                        {
                          Object.keys(form2SourceOptions).filter((key) => key == 'C' || key == 'D').map((key) =>
                            <MenuItem key={key} value={key}>{form2SourceOptions[key]}</MenuItem>)
                        }

                      </TextField>
                    </TableCell>
                  </TableRow>

                  {/*----------- Date of obtaining the information of section B5 ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell >
                      {langForm2.dateOfObtainingInfo}

                    </TableCell>
                    <TableCell data-key={'field-form2-dateOfObtainingB5'}>
                      <KeyboardDatePicker autoOk={true}
                        className={classes.datePicker}
                        //disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        format={DISPLAY_DATE_FORMAT}
                        margin="dense"
                        maxDate={new Date()}
                        value={form2.values.dateOfObtainingB5 ? moment(form2.values.dateOfObtainingB5) : null}
                        onChange={(value) => {
                          form2.setValues({
                            ...form2.values,
                            dateOfObtainingB5: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
                          });
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        maxDateMessage={langForm2.msgSelectionValidDate}
                      />
                      {form2.hasError('dateOfObtainingB5') ? <FormHelperText style={{ color: form2.hasError('dateOfObtainingB5') ? errorColor : 'inherit' }}>{form2.hasError('dateOfObtainingB5')}</FormHelperText> : null}

                    </TableCell>
                  </TableRow>


                </TableBody>
              </Table>
            </TableContainer>

          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

        </Grid>

        {/* -------------------------------- Part C -----------------------------*/}

        <Grid container xs={12} spacing={1} className={classes.longTableContainer}>
          <Grid
            item
            md={12}
            xs={12}
          >
            <Typography className={classes.partTitle}>{langForm2.titlePartC}</Typography>

          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >

            <Table className={classes.table} size="small" aria-label="Part C table">
              <TableHead>
                <TableRow style={{ display: 'none' }}>
                  <TableCell className={classes.column0}></TableCell>
                  <TableCell align="left" className={classes.partC_column1}></TableCell>
                  <TableCell align="left" ></TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {/*----------- 1. The annual Government rent ---------- */}
                <TableRow >
                  <TableCell className={classes.column0} component="th" scope="row">1</TableCell>
                  <TableCell align="left" className={classes.partC_column1} data-key={'field-form2-annualGovRent'}>
                    {langForm2.annualGovRent}
                  </TableCell>
                  <TableCell align="left">
                    <NumberField
                      className={classes.numberFields}
                      margin="dense"
                      variant="outlined"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{langForm2.HKDollar}</InputAdornment>
                      }}
                      {...bind('annualGovRent')}
                      onChange={(e) => {
                        limitNumberFieldLength(e, 'annualGovRent', form2);
                      }}
                    />
                  </TableCell>
                </TableRow>

                {/*----------- 2. The quarterly rates ---------- */}
                <TableRow >
                  <TableCell className={classes.column0} component="th" scope="row">2</TableCell>
                  <TableCell align="left" className={classes.partC_column1} data-key={'field-form2-quarterlyRates'}>
                    {langForm2.quarterlyRates}
                  </TableCell>
                  <TableCell align="left" >
                    <NumberField
                      className={classes.numberFields}
                      margin="dense"
                      variant="outlined"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{langForm2.HKDollar}</InputAdornment>
                      }}
                      {...bind('quarterlyRates')}
                      onChange={(e) => {
                        limitNumberFieldLength(e, 'quarterlyRates', form2);
                      }}
                    />
                  </TableCell>
                </TableRow>

                {/*----------- 3. The monthly management fee ---------- */}
                <TableRow >
                  <TableCell className={classes.column0} component="th" scope="row">3</TableCell>
                  <TableCell align="left" className={classes.partC_column1} data-key={'field-form2-monthlyManagementFee'}>
                    {langForm2.monthlyManagementFee}
                  </TableCell>
                  <TableCell align="left">
                    <NumberField
                      className={classes.numberFields}
                      margin="dense"
                      variant="outlined"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{langForm2.HKDollar}</InputAdornment>
                      }}
                      {...bind('monthlyManagementFee')}
                      onChange={(e) => {
                        limitNumberFieldLength(e, 'monthlyManagementFee', form2);
                      }}
                    />
                  </TableCell>
                </TableRow>

                {/*----------- 4. The Property is to be sublet and is presently subject to a lease ---------- */}
                <TableRow >
                  <TableCell className={classes.column0} component="th" scope="row">4</TableCell>
                  <TableCell align="left" className={classes.partC_column1}>
                    {/* {langForm2.toBeSublet} */}
                    <div>{langForm2.toBeSubletSentence1}</div>
                    <div>{langForm2.toBeSubletSentence2}
                      <Checkbox
                        checked={form2.values.toBeSublet}
                        onChange={() => {
                          if (form2.values.toBeSublet) {
                            form2.updateValues('subletParticulars', '');
                          }
                          form2.updateValues('toBeSublet', !form2.values.toBeSublet);
                        }}
                      />
                    </div>

                  </TableCell>
                  <TableCell align="left">

                    <TextareaAutosize
                      className={classes.textArea}
                      rowsMin={2}
                      rowsMax={2}
                      disabled={form2.values.toBeSublet == false}
                      placeholder={langForm2.helperTextParticulars}
                      {...bind('subletParticulars')}
                      onChange={(e) => {
                        limitTextFieldLength(e, 255, 'subletParticulars', form2, keyBooleanMap, setKeyBooleanMap);
                      }}
                    />
                    {form2.hasError('subletParticulars') ? <FormHelperText style={{ color: form2.hasError('subletParticulars') ? errorColor : 'inherit' }}>{langForm2.pleaseInput + langForm2.subletParticulars}</FormHelperText> : null}
                    <HandleFormHelperText
                      isError={keyBooleanMap.get('subletParticulars')}
                      errorMessage={lang.textOverLimit}
                    />
                  </TableCell>
                </TableRow>

              </TableBody>
            </Table>

          </Grid>

          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>


        </Grid>
        {/*-------------------------------- agent details -------------------------------- */}

        <Grid container xs={12} spacing={1}>
          <Grid
            item
            md={12}
            xs={12}
          >
            <Table className={classes.table} size="small" aria-label="agent details table">
              <TableBody>
                {/* ------------------------ agent name ------------------------- */}
                <TableRow>
                  <TableCell component="th" style={{ width: '15%' }} scope="row" className={classes.subTableCell}>
                    {langForm2.agentName}
                  </TableCell>

                  <TableCell colSpan={2} align="left" className={classes.subTableCell}>
                    <TextField
                      className={classes.datePicker}
                      margin="dense"
                      disabled={true}
                      variant="outlined"
                      {...bind('agentName')}
                      onChange={(e) => {
                        limitTextFieldLength(e, 255, 'agentName', form2, keyBooleanMap, setKeyBooleanMap);
                      }}
                    />
                    <HandleFormHelperText
                      isError={keyBooleanMap.get('agentName')}
                      errorMessage={lang.textOverLimit}
                    />
                  </TableCell>
                </TableRow>

                {/* ------------------------ Name and licence number of the signatory ------------------------- */}
                <TableRow>
                  <TableCell component="th" style={{ width: '15%' }} scope="row" className={classes.subTableCell}>
                    {langForm2.nameAndLicenceNumberOfSignatory}
                  </TableCell>

                  <TableCell colSpan={2} align="left" className={classes.subTableCell}>
                    <TextField
                      className={classes.datePicker}
                      margin="dense"
                      variant="outlined"
                      {...bind('nameAndLicenceNumberOfSignatory')}
                      onChange={(e) => {
                        limitTextFieldLength(e, 255, 'nameAndLicenceNumberOfSignatory', form2, keyBooleanMap, setKeyBooleanMap);
                      }}
                    />
                    <HandleFormHelperText
                      isError={keyBooleanMap.get('nameAndLicenceNumberOfSignatory')}
                      errorMessage={lang.textOverLimit}
                    />
                  </TableCell>
                </TableRow>

                {/* ------------------------ Number of Agent’s statement of particulars of business: ------------------------- */}
                <TableRow>

                  <TableCell component="th" style={{ width: '15%' }} scope="row" className={classes.subTableCell}>
                    <div>
                      {langForm2.numberOfAgentStatement}
                    </div>
                  </TableCell>

                  <TableCell colSpan={2} align="left" className={classes.subTableCell}>
                    <TextField
                      className={classes.datePicker}
                      margin="dense"
                      disabled={true}
                      variant="outlined"
                      {...bind('numberOfAgentStatement')}
                      onChange={(e) => {
                        limitTextFieldLength(e, 255, 'numberOfAgentStatement', form2, keyBooleanMap, setKeyBooleanMap);
                      }}
                    />
                    <HandleFormHelperText
                      isError={keyBooleanMap.get('numberOfAgentStatement')}
                      errorMessage={lang.textOverLimit}
                    />
                  </TableCell>
                </TableRow>

                {/* ------------------------ Address ------------------------- */}
                <TableRow>
                  <TableCell component="th" style={{ width: '15%' }} scope="row" className={classes.subTableCell}>
                    {langForm2.address}
                  </TableCell>

                  <TableCell colSpan={2} align="left" className={classes.subTableCell}>
                    <TextareaAutosize
                      className={classes.textArea}
                      style={{ width: '30%', }}
                      disabled={true}
                      rowsMin={4}
                      rowsMax={4}
                      placeholder=''
                      {...bind('address')}
                      onChange={(e) => {
                        limitTextFieldLength(e, 255, 'address', form2, keyBooleanMap, setKeyBooleanMap);
                      }}
                    />
                    {form2.hasError('address') ? <FormHelperText style={{ color: form2.hasError('address') ? errorColor : 'inherit' }}>{langForm2.pleaseInput + langForm2.address}</FormHelperText> : null}
                    <HandleFormHelperText
                      isError={keyBooleanMap.get('address')}
                      errorMessage={lang.textOverLimit}
                    />
                  </TableCell>
                </TableRow>

                {/* ------------------------ Telephone number ------------------------- */}
                <TableRow>
                  <TableCell component="th" style={{ width: '15%' }} scope="row" className={classes.subTableCell}>
                    {langForm2.telephoneNumber}
                  </TableCell>

                  <TableCell colSpan={2} align="left" className={classes.subTableCell} data-key={'field-form2-telephoneNumber'} >
                    <TextField
                      className={classes.datePicker}
                      disabled={true}
                      margin="dense"
                      variant="outlined"
                      {...bind('telephoneNumber')}
                      onChange={(e) => {
                        limitTextFieldLength(e, 255, 'telephoneNumber', form2, keyBooleanMap, setKeyBooleanMap);
                      }}
                    />
                    <HandleFormHelperText
                      isError={keyBooleanMap.get('telephoneNumber')}
                      errorMessage={lang.textOverLimit}
                    />
                  </TableCell>
                </TableRow>

                {/* ------------------------ fax number ------------------------- */}
                <TableRow>
                  <TableCell component="th" style={{ width: '15%' }} scope="row" className={classes.subTableCell}>
                    {langForm2.faxNumber}
                  </TableCell>

                  <TableCell colSpan={2} align="left" className={classes.subTableCell}>
                    <TextField
                      className={classes.datePicker}
                      disabled={true}
                      margin="dense"
                      variant="outlined"
                      {...bind('faxNumber')}
                      onChange={(e) => {
                        limitTextFieldLength(e, 255, 'faxNumber', form2, keyBooleanMap, setKeyBooleanMap);
                      }}
                    />
                    <HandleFormHelperText
                      isError={keyBooleanMap.get('faxNumber')}
                      errorMessage={lang.textOverLimit}
                    />
                  </TableCell>
                </TableRow>

                {/* ------------------------ date ------------------------- */}
                {/* <TableRow>
                    <TableCell component="th"  style={{width: '15%'}} scope="row" className={classes.subTableCell}>
                        {langForm2.date}
                    </TableCell>

                    <TableCell colSpan={2} align="left" className={classes.subTableCell}>
                      <KeyboardDatePicker autoOk={true}
                          className={classes.datePicker}
                          //disableToolbar
                          variant="inline"
                          inputVariant="outlined"
                          format={DISPLAY_DATE_FORMAT}
                          margin="dense"
                          value={form2.values.date ?  moment(form2.values.date ) : null}
                          onChange={(value) => {
                            form2.setValues({
                              ...form2.values,
                              date: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
                            });
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        /> 
                       {form2.hasError('date') ? <FormHelperText style={{color: form2.hasError('date') ? errorColor : 'inherit'}}>{langForm2.pleaseInput + langForm2.date}</FormHelperText> : null}  

                    </TableCell>
                  </TableRow> */}

              </TableBody>
            </Table>


          </Grid>

        </Grid>

      </CardContent>

      <CardActions style={{ justifyContent: 'flex-end', display: 'none' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (form2.values.id != undefined && form2.values.id != null) {
              dispatch({
                type: 'Form2.UpdateRequested', payload: {
                  ...form2.values,
                }
              });
            } else {
              dispatch({
                type: 'Form2.CreationRequested', payload: {
                  ...form2.values,
                  propertyStockId: propertyStockId,
                }
              });
            }
          }}

        >{lang.actionSaveForm}</Button>
        <Button variant="contained" color="primary">生成PDF檔案</Button>
      </CardActions>
    </Card>
  )
};

export default Form2Dialog;
