import { ApiError, ApiResult } from 'api';
import clientSignApi from 'api/clientSignApi';
import { FormSigningInfoProps } from 'common/elements/FormSigningInfo';
import en from 'common/languages/en';
import zhHK from 'common/languages/zh_HK';
import { ClientSignActions, LayoutActions } from 'reducers';
import { call, put, takeEvery } from 'redux-saga/effects';
import { PASSagaContext } from 'sagas';
import { apiTaskWrapper } from './saga-commons';

const PUBLIC_URL = process.env['REACT_APP_PAS_APP_PUBLIC_URL'] ?? '/';

///////////////////////////////////////////////////////////////////
///////////////////// Verify token and status  ////////////////////////
///////////////////////////////////////////////////////////////////

export function* watchVerifyTokenAndStatusRequested(context: PASSagaContext) {
  yield takeEvery(
    'ClientSign.VerifyTokenAndStatusRequested',
    apiTaskWrapper(verifyTokenAndStatus),
    context,
  )
}

export function* verifyTokenAndStatus(context: PASSagaContext, action: ClientSignActions) {
  if (action.type != 'ClientSign.VerifyTokenAndStatusRequested') {
    return;
  }
  const result: ApiResult<any> = yield call(clientSignApi.verifyToken, action.payload.token);

  if (!result.error) {
    // if(!result.data.isValidToken){
    //     window.location.href = PUBLIC_URL + "/not-found";
    //   }else{
    if (result.data.formStatus === 'S') {
      yield put<ClientSignActions>({ type: 'ClientSign.UpdateStage', payload: 'signed' });
    }
    else if (result.data.formStatus === 'P') {
      yield put<ClientSignActions>({ type: 'ClientSign.UpdateStage', payload: 'pendingContactVerification' });
    }
    else if (result.data.formStatus === 'D') {
      yield put<ClientSignActions>({ type: 'ClientSign.UpdateStage', payload: 'linkExpired' });
    }
    else {
      window.location.href = PUBLIC_URL + "/not-found";
    }
    // }
  } else {
    if (result.error === 'ERR_INVALID_TOKEN') {
      window.location.href = PUBLIC_URL + "/not-found";
    }
    else if (result.error === 'ERR_TOKEN_EXPIRED') {
      yield put<ClientSignActions>({ type: 'ClientSign.UpdateStage', payload: 'linkExpired' });
    } else {
      throw ApiError.of(result.error);
    }
  }

}

///////////////////////////////////////////////////////////////////
///////////////////// confirm sign ////////////////////////
///////////////////////////////////////////////////////////////////

export function* watchHandleClientSignRequested(context: PASSagaContext) {
  yield takeEvery(
    'ClientSign.confirmSignRequested',
    apiTaskWrapper(confirmSign),
    context,
  )
}

export function* confirmSign(context: PASSagaContext, action: ClientSignActions) {
  if (action.type != 'ClientSign.confirmSignRequested') {
    return;
  }
  const { langSignDialog, lang } = zhHK;
  const { langSignDialog: langSignDialogEn, lang: langEn } = en;

  yield put<ClientSignActions>({ type: 'ClientSign.UpdateStage', payload: 'generating' });
  const { token, signature, hasHKID, clientIdentificationDocNo, isHKID } = action.payload;
  const result: ApiResult<any> = yield call(clientSignApi.confirmSign, token, signature, hasHKID, clientIdentificationDocNo, isHKID);

  if (!result.error) {
    yield put<ClientSignActions>({ type: 'ClientSign.Success', payload: { filename: result.data.filename } });
    yield put<ClientSignActions>({ type: 'ClientSign.NextActiveStep' });
    if (result.data.sentEmailResult?.toString() === '1') {
      yield put<ClientSignActions>({ type: 'ClientSign.emailSentAlready' });
      yield put<ClientSignActions>({ type: 'ClientSign.UpdateMessage', payload: `${langSignDialog.msgSuccessClientSignByEmail}\n${langSignDialogEn.msgSuccessClientSignByEmail}` });
    } else {
      yield put<ClientSignActions>({ type: 'ClientSign.UpdateMessage', payload: `${langSignDialog.msgSuccessClientSignByLink}\n${langSignDialogEn.msgSuccessClientSignByLink}\n\n${langSignDialog.msgInputEmailToReceiveCopy}\n${langSignDialogEn.msgInputEmailToReceiveCopy}` });
      yield put<ClientSignActions>({ type: 'ClientSign.ShowEmailInputBox' });
    }
  } else {
    yield put<ClientSignActions>({ type: 'ClientSign.UpdateStage', payload: 'signing' });
    if (result.error === 'ERR_FAILED_CLIENT_SIGN') {
      yield put<LayoutActions>({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgFailedClientSignError + ' ' + langEn.msgFailedClientSignError, severity: 'error' } })
    }
    else if (result.error === 'ERR_TOKEN_EXPIRED') {
      yield put<ClientSignActions>({ type: 'ClientSign.UpdateStage', payload: 'linkExpired' });
    }
    else {
      throw ApiError.of(result.error);
    }

  }
}


///////////////////////////////////////////////////////////////////
///////////////////// handleVerifyContact ////////////////////////
///////////////////////////////////////////////////////////////////

export function* watchCheckVerificationContactRequestedRequested(context: PASSagaContext) {
  yield takeEvery(
    'ClientSign.checkVerificationContactRequested',
    apiTaskWrapper(handleVerifyContact),
    context,
  )
}

export function* handleVerifyContact(context: PASSagaContext, action: ClientSignActions) {
  if (action.type != 'ClientSign.checkVerificationContactRequested') {
    return;
  }
  const { langSignDialog } = zhHK
  const { langSignDialog: langSignDialogEn } = en

  const result: ApiResult<any> = yield call(clientSignApi.checkVerificationContact, action.payload.token, action.payload.clientVerificationContact);

  if (!result.error) {
    const { match, formName, id, formDetail } = result.data;
    if (match) {
      yield put<ClientSignActions>({ type: 'ClientSign.UpdateStage', payload: formName === 'form1' ? 'signing' : 'inputIdentityDocNo' });
      yield put<ClientSignActions>({ type: 'ClientSign.UpdateMessage', payload: formName === 'form1' ? '' : `${langSignDialog.msgSuccessClientContactVerfification}\n${langSignDialogEn.msgSuccessClientContactVerfification}` });
      yield put<ClientSignActions>({ type: 'ClientSign.NextActiveStep' });
      yield put<ClientSignActions>({ type: 'ClientSign.FormInfo.Loaded', payload: { formDetail: { ...formDetail } as Partial<FormSigningInfoProps>, id: id, formName: formName } });
    } else {
      yield put<LayoutActions>({ type: 'Layout.AlertMessageAdded', payload: { message: langSignDialog.msgInvalidVerificationContact + ' ' + langSignDialogEn.msgInvalidVerificationContact, severity: 'error' } });
      yield put<ClientSignActions>({ type: 'ClientSign.UpdateStage', payload: 'pendingContactVerification' });
    }
  } else {
    if (result.error === 'ERR_INVALID_TOKEN') {
      window.location.href = PUBLIC_URL + "/not-found";
    }
    else if (result.error === 'ERR_TOKEN_EXPIRED') {
      yield put<ClientSignActions>({ type: 'ClientSign.UpdateStage', payload: 'linkExpired' });
    }
    else {
      yield put<ClientSignActions>({ type: 'ClientSign.UpdateStage', payload: 'pendingContactVerification' });
      throw ApiError.of(result.error);
    }
  }
}