import {
  Button, Card, CardContent, CardHeader, Checkbox, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider, FormControl, FormHelperText, Grid as MuiGrid, InputAdornment, InputLabel, ListItemText, MenuItem,
  Select, Snackbar, TextField as MuiTextField, TextFieldProps, Typography, useTheme, withStyles
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/styles';
import buildingApi from 'api/buildingApi';
import clsx from 'clsx';
import { hasPermission } from 'common/access-control';
import { BuildingListItemDTO, PropertyMultiValueMap, PropertyStockDTO } from 'common/dto';
import DistrictSingleSelect from 'common/elements/DistrictSingleSelect';
import MultilineTextField from 'common/elements/MultilineTextField';
import { FormPresenter, useForm } from 'common/hooks';
import { hideWhenHoc, tooltipHoc } from 'common/ui';
import { getBuildingSortForAutoComplete, handleBlockDisplay, initFormKeysAndBooleanMap, isNonEmpty, limitNumberFieldLength, limitTextFieldLength, multiLang } from 'common/utils';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducers';
import { format } from 'util';
import MapDialogContainer from 'views/BuildingDetail/components/MapDialog';
import CommaTextField from './CommaTextField';


const errorColor = '#e53935';
const When = ({ test, children }: { test?: boolean, children: JSX.Element }): JSX.Element => test ? children : <span />;
const Grid = hideWhenHoc(MuiGrid);
const TextField = tooltipHoc(MuiTextField);
const regex = new RegExp(/(^\d*\.?\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/);
const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
  patchOutlineAutocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
      paddingLeft: 8,
    },
    '& .MuiAutocomplete-popupIndicator': {
      display: 'none'
    }
  },
  dialogContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogTextArea: {
    width: '90%',
    margin: 'dense',
    padding: '8px',
    fontSize: '14px'
  },
  highlightedAreaField: {
    '& .MuiInputBase-root': {
      background: '#ffff80',
    }
  }
}));

const NumberField = withStyles({
  root: {
    '& input': {
      textAlign: 'right',
      '-moz-appearance': 'textfield',
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  },
},
)((props: TextFieldProps) => {
  return <TextField type='text' inputMode='decimal' {...props} inputProps={{
    ...props.inputProps,
    min: 0,
    max: Math.max(),
    inputMode: 'decimal',
    pattern: "([^0-9]*)",
    step: 0.01
  }}
    onKeyDown={e => {
      // If the key pressed is not a number or a period, nothing is printed
      if (!e.key.match(regex)) {

        e.preventDefault();

      }
      // if (!e.key.match(regex) || (e.key === '.' && String(props.value).indexOf('.') > 0) || (e.key === '.' && String(props.value).length === 0) || e.keyCode === 229 || e.key === 'Unidentified') {

      //   e.preventDefault();

      // }

    }}

    onKeyPress={e => {
      // If the key pressed is not a number or a period, nothing is printed
      if (!e.key.match(regex)) {

        e.preventDefault();

      }
    }} />;
});

interface BuildingDescription {
  district: string;
  streetName: string;
  buildingName: string;
}

interface DialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSend: () => void;
  description: FormPresenter<BuildingDescription>;
}

const NewBuildingRequestDialog = (props: DialogProps) => {
  const { isOpen, onClose, onSend, description } = props;
  const { langPropertyStock } = useSelector((state: IRootState) => state.locale);
  const classes = useStyles();

  return (
    <Dialog fullWidth={true} onClose={onClose} open={isOpen}>
      <DialogTitle >
        <Typography variant="h3">{langPropertyStock.titleNewBuildingRequest}</Typography>
      </DialogTitle>
      <DialogContent
        dividers
        className={classes.dialogContent}
      >
        <Grid container spacing={1}>
          <Grid item md={4} sm={4} xs={12}>
            <DistrictSingleSelect
              fullWidth
              label={langPropertyStock.district}
              margin="dense"
              required
              select
              variant="outlined"
              {...description.bind('district')}
              // value={description.district}
              // onChange={(e) => setDescription({ ...description, district: e.target.value })}
              districtValue={description.values.district ?? ''}
              onToggleChip={(value) => description.updateValues('district', value)}
            />
          </Grid>
          <Grid item md={8} sm={8} xs={12}>
            <TextField
              fullWidth margin="dense" variant="outlined" required
              label={langPropertyStock.street}
              {...description.bind('streetName')}
            // value={description.streetName}
            // onChange={(e) => setDescription({ ...description, streetName: e.target.value })}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <TextField
              fullWidth margin="dense" variant="outlined" required
              label={langPropertyStock.building}
              {...description.bind('buildingName')}
            // value={description.buildingName}
            // onChange={(e) => setDescription({ ...description, buildingName: e.target.value })}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>{langPropertyStock.actionCancel}</Button>
        <Button color="primary" variant="contained" onClick={onSend}>{langPropertyStock.actionSendRequest}</Button>
      </DialogActions>
    </Dialog>
  )
};

interface BuildingUpdateRequestForm {
  message: string;
}

interface BuildingUpdateDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSend: () => void;
  description: FormPresenter<BuildingUpdateRequestForm>;
  buildingName: string;
  buildingId: string;
  onNewBuildingRequest?: () => void;
}

const _defaultBuildingUpdateReqBody =
  `Dear Office Admins:
 
Mavin reported a update request for building record %s (%s).
 
Here is the update suggestion:
***
%s
***`
  ;

const BuildingUpdateRequestDialog = (props: BuildingUpdateDialogProps) => {
  const { isOpen, onClose, onSend, description, buildingName, buildingId, onNewBuildingRequest } = props;
  const { langPropertyStock } = useSelector((state: IRootState) => state.locale);
  const classes = useStyles();

  const subjectTemplate = useSelector((state: IRootState) => state.systemSettings.PropertyStock?.BUILDING_UPDATE_REQUEST_EMAIL?.SUBJECT) ?? '[PMS] Building Update Request for %s (ID: %s)';
  const bodyTemplate = useSelector((state: IRootState) => state.systemSettings.PropertyStock?.BUILDING_UPDATE_REQUEST_EMAIL?.BODY) ?? _defaultBuildingUpdateReqBody;
  const receiver = useSelector((state: IRootState) => state.systemSettings.PropertyStock?.BUILDING_UPDATE_REQUEST_EMAIL?.RECEIVER) ?? 'info@propertymavin.com';

  const subject = format(subjectTemplate, buildingName, buildingId);
  const buildingUrl = process.env['REACT_APP_PAS_APP_PUBLIC_URL'] + '/buildings/' + buildingId;
  const body = format(bodyTemplate, buildingName, buildingUrl, description.values.message ?? '(No message)');

  return (
    <Dialog fullWidth={true} onClose={onClose} open={isOpen}>
      <DialogTitle >
        <Typography variant="h3">{langPropertyStock.titleBuildingUpdateReq}</Typography>
      </DialogTitle>
      <DialogContent
        dividers
        className={classes.dialogContent}
      >
        <Grid container spacing={1}>
          <Grid item md={12} sm={12} xs={12}>
            <Typography>{langPropertyStock.megRequestBuildingUpdate_1} <a href={buildingUrl} target="_blank">{buildingName}</a> {langPropertyStock.megRequestBuildingUpdate_2}</Typography>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <MultilineTextField
              fullWidth margin="dense" variant="outlined" required
              rows={3}
              label={langPropertyStock.msgInputBuildingUpdateSuggestion}
              {...description.bind('message')}
            />
          </Grid>
          {onNewBuildingRequest ? <Grid item container md={12} sm={12} xs={12} alignItems="center">
            <Typography>{langPropertyStock.msgIfBuildingNotFound}</Typography>
            <Button variant="text" color="primary" onClick={onNewBuildingRequest}>{langPropertyStock.actionNewBuildingRequest}</Button>
          </Grid> : null}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>{langPropertyStock.actionCancel}</Button>
        <Button color="primary" variant="contained" onClick={onSend} component={'a'} href={
          `mailto:${receiver}?subject=${subject}&body=${encodeURIComponent(body)}`
        }>
          {langPropertyStock.actionSendRequest}
        </Button>
      </DialogActions>
    </Dialog>
  )
};

interface PropertyBasicInfoProps {
  className?: string;
  form: FormPresenter<PropertyStockDTO>;
  tagForm: FormPresenter<PropertyMultiValueMap>;
  floorUnitOptions?: BuildingListItemDTO['floorUnits']; // transaction copying: floor unit dropdown autofill
  isNew: boolean;
  editDisabled?: boolean;
  hideFloor?: boolean;
  hideStreet?: boolean;
  hideLot?: boolean;
  getCheckDuplicateResult: () => void;
  getPropertyPreviousTransactionData: () => void;
  isSameNet?: boolean;
  isSameGross?: boolean;
}

const PropertyBasicInfo = (props: PropertyBasicInfoProps) => {
  const { className, form, tagForm, floorUnitOptions, isNew, editDisabled, hideFloor, getCheckDuplicateResult, getPropertyPreviousTransactionData, isSameNet, isSameGross, ...rest } = props;
  const { lang, langBuilding, langPropertyStock, propertyStockStatusOptions, levelOptions
  } = useSelector((state: IRootState) => state.locale);

  const dispatch = useDispatch();

  const { isLeadAgent, canReadTeamProperty, canReadUnowned, canReadCompanyStock } = form.values;
  const showFloor = isLeadAgent || canReadTeamProperty || canReadUnowned || (form.values.companyStock === true && canReadCompanyStock) || isNew;
  const showUnit = isLeadAgent || canReadTeamProperty || canReadUnowned || (form.values.companyStock === true && canReadCompanyStock) || isNew || isNonEmpty(form.values.floor);
  // const showStreet = isLeadAgent || canReadTeamProperty || (canReadUnowned && !(form.values.usage === 'V')) || (form.values.companyStock === true && canReadCompanyStock) || isNew || isNonEmpty(form.values.floor) || isNonEmpty(form.values.unit);
  // const showStreetLot = isNew || !(form.values.usage === 'V' && !form.values.companyStock && !isLeadAgent && !canReadTeamProperty && !canReadUnowned);
  // const showStreetLot = true;
  const showLot = isLeadAgent || canReadTeamProperty || canReadUnowned || (form.values.companyStock === true && canReadCompanyStock) || isNew;
  const classes = useStyles();
  const theme = useTheme();

  const { locale, usageOptions } = useSelector((state: IRootState) => state.locale);
  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);
  const { privileges } = useSelector((state: IRootState) => state.login);
  const showCompanyStockBox = !isNew || hasPermission(privileges, 'CREATE', 'COMPANY_STOCK');
  const isVillageHouse = form.values.usage === 'V';

  const bind = form.bind;

  const [buildings, setBuildings] = useState<BuildingListItemDTO[]>([]);
  const [selectedBuilding, setSelectedBuilding] = useState<any>();
  const [buildingListLoading, setBuildingListLoading] = useState(false);
  const token: string = useSelector((state: IRootState) => state.login.token ?? '');
  // Building Search For Autofill
  const fetchBuilding = (keyword: string, byId?: number) => {
    if (keyword.length < 2 && !byId) { return; }
    setBuildingListLoading(true);

    buildingApi.getList({ keyword: keyword, idList: byId ? [byId] : undefined, district: form.values.district, fetchFloorUnits: true, sort: getBuildingSortForAutoComplete(locale) }, token).then(result => {
      if (result.data?.content) {
        setBuildings(result.data!.content);
        setBuildingListLoading(false);
        if (result.data!.content.length === 1) {
          setSelectedBuilding(result.data!.content[0]);
          setFloorUnits(result.data!.content[0].floorUnits);
        }
      }
    });
  }

  const buildingMap: { [id: string]: BuildingListItemDTO } = {};
  buildings.forEach((building) => { buildingMap[building.id] = building });
  const [floorUnits, setFloorUnits] = useState<BuildingListItemDTO['floorUnits']>([]);

  useEffect(() => {
    // if (form.values.building || form.values.buildingEn) {
    //   fetchBuilding(form.values.building ?? form.values.buildingEn ?? '');
    // }
    if (form.values.buildingId && !isNew) {
      fetchBuilding('', Number(form.values.buildingId ?? 0));
    }

  }, [form.values.id ?? '0', isNew]);

  useEffect(() => {
    setFloorUnits(floorUnitOptions);
  }, [floorUnitOptions]);

  const previousUsage = useRef(form.values.usage);
  useEffect(() => {
    if (previousUsage.current === 'V' && form.values.usage !== 'V' && selectedBuilding) {
      form.updateValues('floor', undefined);
      form.updateValues('unit', undefined);
      form.updateValues('street', selectedBuilding.streetZh);
      form.updateValues('streetEn', selectedBuilding.streetEn);
      form.updateValues('lot', selectedBuilding.lot);
      setFloorUnits(selectedBuilding.floorUnits);
    }
    previousUsage.current = form.values.usage;
  }, [form.values.usage, previousUsage, selectedBuilding, setFloorUnits]);

  const buildingDescriptionForm = useForm<BuildingDescription>({}, {
    validations: [
      (values) => isNonEmpty(values.district) ? null : [['district', langBuilding.msgInputDistrict]],
      (values) => isNonEmpty(values.streetName) ? null : [['streetName', langBuilding.msgInputStreetName]],
      (values) => isNonEmpty(values.buildingName) ? null : [['buildingName', langBuilding.msgInputBuildingName]],
    ],
  });

  const buildingUpdateRequestForm = useForm<BuildingUpdateRequestForm>({}, {
    validations: [
      (values) => isNonEmpty(values.message) ? null : [['message', '     ']],
    ],
  });

  // const [ buildingDescription, setBuildingDescription ] = useState<{[key: string] : string}>({
  //   district: '',
  //   streetName: '',
  //   buildingName: ''
  // });

  // New Building Request
  const [dialogOpen, setDialogOpen] = useState(false);
  const [messageBarOpen, setMessageBarOpen] = useState(false);
  // Building Update Request
  const [buildingUpdateDialogOpen, setBuildingUpdateDialogOopen] = useState(false);

  const requestBuildingCreation = () => {
    if (buildingDescriptionForm.validate()) {
      dispatch({ type: 'Layout.MaskPresentRequested' });
      buildingApi.createBuildingRequest(buildingDescriptionForm.values, token).then(result => {
        if (result.data.success) {
          setMessageBarOpen(true);
          setDialogOpen(false);
          buildingDescriptionForm.stopValidation();
          buildingDescriptionForm.setValues({});
          dispatch({ type: 'Layout.MaskDismissRequested' });
        } else {
          if (result.error) {
            throw new Error(result.error);
          }
          setDialogOpen(false);
          buildingDescriptionForm.stopValidation();
          buildingDescriptionForm.setValues({});
          dispatch({ type: 'Layout.MaskDismissRequested' });
        }
      }).catch(err => {
        console.error(err);
        dispatch({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgNetworkError } });
        dispatch({ type: 'Layout.MaskDismissRequested' });
      });
    }
  }

  const [keyBooleanMap, setKeyBooleanMap] = useState(initFormKeysAndBooleanMap(form));
  useEffect(() => {
    setKeyBooleanMap(initFormKeysAndBooleanMap(form));
  }, [Object.keys(form.values).length])


  const floorUnitSelect = (label: string, options: string[], selected: string[], onChange: (newVal: string[]) => any, required: boolean, errorText?: string) => {
    return <FormControl variant="outlined" margin="dense" fullWidth>

      <InputLabel required={required} variant="outlined" style={{ background: '#fff' }}>{label}</InputLabel>
      <Select
        multiple
        value={selected}
        onChange={(ev) => {
          onChange(ev.target.value as string[]);
          form.updateValues('gross', undefined);
          form.updateValues('owner', undefined);
          form.updateValues('net', undefined);
        }}
        renderValue={(selected) => (selected as string[]).join(',')}
        onClose={() => {
          getCheckDuplicateResult();
          getPropertyPreviousTransactionData();
        }}
        error={!!errorText}
      >
        {(options).filter(o => isNonEmpty(o)).map(val => (
          <MenuItem key={val} value={val}>
            <Checkbox checked={selected.indexOf(val) > -1} />
            <ListItemText primary={val} />
          </MenuItem>
        ))}
      </Select>
      {errorText ? <FormHelperText error>{errorText}</FormHelperText> : null}
    </FormControl>
  }

  const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });

  // useEffect(() => {
  //   if (!form.values.hasKey) {
  //     form.updateValues('keyNo', undefined);
  //     // form.stopValidation();
  //   }
  // }, [ form.values.hasKey ]);

  useEffect(() => {
    getCheckDuplicateResult();
    getPropertyPreviousTransactionData();
  }, [form.values.buildingId, form.values.usage, form.values.floor, form.values.unit]);

  const addressEditable = isNew || (form.values.leadAgents?.length ?? 0) === 1 || hasPermission(privileges, 'UPDATE', 'UNOWNED_PROPERTY_STOCK');

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
      >
        {isNew ? <NewBuildingRequestDialog
          isOpen={dialogOpen}
          onClose={() => setDialogOpen(false)}
          onSend={() => requestBuildingCreation()}
          description={buildingDescriptionForm}
        /> : null}
        <BuildingUpdateRequestDialog
          isOpen={buildingUpdateDialogOpen}
          onClose={() => {
            setBuildingUpdateDialogOopen(false);
            buildingUpdateRequestForm.stopValidation();
          }}
          onSend={() => {
            setTimeout(() => buildingUpdateRequestForm.updateValues('message', ''), 1000);
            setBuildingUpdateDialogOopen(false);
            buildingUpdateRequestForm.stopValidation();
          }}
          description={buildingUpdateRequestForm}
          buildingName={multiLang(locale, form.values.building, form.values.buildingEn) ?? ''}
          buildingId={form.values.buildingId ?? ''}
          onNewBuildingRequest={isNew ? () => {
            setTimeout(() => buildingUpdateRequestForm.updateValues('message', ''), 1000);
            setBuildingUpdateDialogOopen(false);
            buildingUpdateRequestForm.stopValidation();
            setDialogOpen(true);
          } : undefined}
        />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={messageBarOpen}
          autoHideDuration={6000}
          onClose={() => setMessageBarOpen(false)}
          message={langPropertyStock.msgBuildingRequestSuccess}
        />
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
          <CardHeader
            title={langPropertyStock.titleBasicInfo}
          />

          <div style={{ flexGrow: 1 }}></div>

          <When test={isNew && !form.values.buildingId}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: theme.spacing(1) }}>
              <Button variant="text" color="primary" onClick={() => setDialogOpen(true)}>
                {langPropertyStock.actionNewBuildingRequest}
              </Button>
            </div>
          </When>
          <When test={!!form.values.buildingId}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: theme.spacing(1) }}>
              <Button variant="text" color="primary" onClick={() => setBuildingUpdateDialogOopen(true)}>
                {langPropertyStock.actionBuildingUpdateReq}
              </Button>
            </div>
          </When>
        </div>
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={1}
          >
            <When test={!isNew}>
              <React.Fragment>
                <Grid item md={3} sm={3} xs={6}>
                  <TextField
                    fullWidth
                    InputProps={{ disabled: true }}
                    margin="dense"
                    variant="outlined"
                    label={langPropertyStock.stockNo}
                    value={form.values.propertyNo ?? ''}
                  />
                </Grid>
                <Grid item md={3} sm={3} xs={6}>
                  <TextField
                    fullWidth
                    InputProps={{ disabled: true }}
                    margin="dense"
                    variant="outlined"
                    label={langPropertyStock.status}
                    value={propertyStockStatusOptions[form.values.status ?? 'PENDING']}
                  />
                </Grid>
                <Grid item md={3} sm={3} xs={6}>
                  <TextField
                    fullWidth
                    InputProps={{ disabled: true }}
                    margin="dense"
                    variant="outlined"
                    label={langPropertyStock.dateModified}
                    value={form.values.dateModified ? moment(form.values.dateModified).format(DISPLAY_DATE_FORMAT) : ''}
                  />
                </Grid>
                <Grid item md={3} sm={3} xs={6}>
                  <TextField
                    fullWidth
                    InputProps={{ disabled: true }}
                    margin="dense"
                    variant="outlined"
                    label={langPropertyStock.modifiedBy}
                    value={form.values.modifiedBy ?? ''}
                  />
                </Grid>
              </React.Fragment>
            </When>


            <Grid item md={4} sm={4} xs={12}>
              <TextField
                fullWidth
                InputProps={{ disabled: !addressEditable || editDisabled }}
                label={langPropertyStock.usage}
                margin="dense"
                tooltipFontSize="1rem"
                tooltip={addressEditable ? undefined : langPropertyStock.msgAddressNonEditable}
                select
                required
                variant="outlined"
                {...bind('usage')}
                data-key={'field-propertybasicinfo-usage'}
              >
                {Object.keys(usageOptions).map(key => (
                  <MenuItem key={key} value={key}>{usageOptions[key]}</MenuItem>
                ))}
              </TextField>
              {form.hasError('usage') ? <FormHelperText style={{ color: form.hasError('usage') ? errorColor : 'inherit' }}>{langPropertyStock.msgInputUsage}</FormHelperText> : null}

            </Grid>

            <Grid
              item
              md={8} sm={8}
              xs={12}
              hideWhen={isNew}
            >
              <TextField
                fullWidth
                InputProps={{ disabled: true }}
                label={langPropertyStock.buildingOrVillageName}
                margin="dense"
                variant="outlined"
                value={multiLang(locale, form.values.building, form.values.buildingEn) ?? ''}
              />
            </Grid>

            <Grid
              item
              md={8} sm={8}
              xs={12}
              hideWhen={!isNew}
            >
              <Autocomplete
                disabled={editDisabled || !isNew}
                options={buildings}
                getOptionLabel={(id) => {
                  if (!id) {
                    return '';
                  }
                  return (multiLang(locale, form.values.building, form.values.buildingEn) ?? '');
                  // ` (${(multiLang(locale, form.values.block, form.values.blockEn) ?? '')})`;
                }}
                renderOption={(building) => {
                  const streetDisplay = multiLang(locale, building?.streetZh, building?.streetEn) ?? '';
                  return (multiLang(locale, building?.buildingNameZh, building?.buildingNameEn) ?? '') +
                    ((building?.blockZh || building?.blockEn) ? ` (${handleBlockDisplay(locale, multiLang(locale, building?.blockZh, building?.blockEn) ?? '')})` : '') +
                    // (building?.usage === 'V' ? ` ${building?.lot ?? ''}` : '');
                    (building?.lot ? ` ${building?.lot}` : '') +
                    (streetDisplay ? ' / ' + streetDisplay : '')
                }}
                noOptionsText={''}
                loading={buildingListLoading}
                loadingText={lang.msgLoading}
                renderInput={params => (
                  <TextField
                    {...params}
                    inputProps={{ ...params.inputProps, readOnly: editDisabled || !isNew }}
                    className={classes.patchOutlineAutocomplete}
                    error={!!form.hasError('building')}
                    helperText={form.hasError('building')}
                    fullWidth
                    label={langPropertyStock.buildingOrVillageName}
                    margin="dense"
                    required
                    variant="outlined"
                    onChange={(ev) => fetchBuilding(ev.target.value)}
                    // value={form.values.building ?? ''}
                    // {...bind('building')}
                    data-key={'field-propertybasicinfo-building'}
                  />
                )}
                // {...bind('building')}
                value={multiLang(locale, form.values.building, form.values.buildingEn) ?? ''}
                // inputValue={form.values.building ?? ''}

                onChange={(_, val) => {
                  if (val) {
                    setSelectedBuilding(val);
                    const { id: buildingId, floorUnits, usage: _, ...b } = val;
                    if (buildingId !== form.values.buildingId) {
                      form.setValues({
                        ...form.values,
                        buildingId,
                        ...b,
                        street: b.streetZh,
                        streetEn: b.streetEn,
                        building: b.buildingNameZh,
                        buildingEn: b.buildingNameEn,
                        primarySchoolNet: b.primarySchoolNet,
                        secondarySchoolNet: b.secondarySchoolNet,
                        owner: undefined,
                        floor: undefined,
                        unit: undefined,
                        gross: undefined,
                        net: undefined,
                        hasFloorDefinitions: floorUnits?.filter((fu: any) => isNonEmpty(fu.floor))?.length > 0,
                        hasUnitDefinitions: floorUnits?.filter((fu: any) => isNonEmpty(fu.unit))?.length > 0,
                        latitude: b.latitude,
                        longitude: b.longitude,
                        developer: b.developer,
                      });
                      tagForm.setValues({
                        ...tagForm.values,
                        CLUB_HOUSE_FACILITIES: b.clubHouseFacilities,
                        OTHERS: b.others
                      })
                      setFloorUnits(floorUnits);
                    }
                  } else {
                    form.setValues({
                      ...form.values,
                      buildingId: undefined,
                      district: undefined,
                      building: undefined,
                      buildingEn: undefined,
                      street: undefined,
                      streetEn: undefined,
                      block: undefined,
                      blockEn: undefined,
                      occupancyPermitDate: undefined,
                      expiryYear: undefined,
                      // usage: undefined,
                      floor: undefined,
                      unit: undefined,
                      gross: undefined,
                      net: undefined,
                      hasFloorDefinitions: undefined,
                      hasUnitDefinitions: undefined,
                      owner: undefined,
                      latitude: undefined,
                      longitude: undefined,
                      lot: undefined,

                      primarySchoolNet: undefined,
                      secondarySchoolNet: undefined,
                    });
                  }
                }}
              />
            </Grid>


            {/* {
              showStreetLot && showStreet && */}
            <Grid
              item
              md={12} sm={12}
              xs={12}
              hideWhen={isVillageHouse}
            >
              <TextField
                fullWidth
                InputProps={{ disabled: true }}
                label={langPropertyStock.street}
                margin="dense"
                variant="outlined"
                value={multiLang(locale, form.values.street, form.values.streetEn) ?? ''}
              />
            </Grid>
            {/* } */}



            <Grid
              item
              md={6} sm={6}
              xs={12}
              hideWhen={!(isVillageHouse)} // isNew && 
            >
              <TextField
                fullWidth
                InputProps={{ disabled: editDisabled || !addressEditable }}
                label={locale === 'en' ? langPropertyStock.streetEn : langPropertyStock.streetZh}
                margin="dense"
                variant="outlined"
                {...bind(locale === 'en' ? 'streetEn' : 'street')}
                onBlur={() => {
                  getCheckDuplicateResult();
                  getPropertyPreviousTransactionData();
                }}
                data-key={'field-propertybasicinfo-street'}
              />
            </Grid>
            <Grid
              item
              md={6} sm={6}
              xs={12}
              hideWhen={!(isVillageHouse)} // isNew && 
            >
              <TextField
                fullWidth
                InputProps={{ disabled: editDisabled || !addressEditable }}
                label={locale === 'en' ? langPropertyStock.streetZh : langPropertyStock.streetEn}
                margin="dense"
                variant="outlined"
                {...bind(locale === 'en' ? 'street' : 'streetEn')}
                onBlur={() => {
                  getCheckDuplicateResult();
                  getPropertyPreviousTransactionData();
                }}
              />
            </Grid>

            {/* <Grid
              item
              md={7} sm={7}
              xs={12}
              hideWhen={!isNew}
            >
              <Autocomplete
                disabled={editDisabled || !isNew}
                options={buildings}
                getOptionLabel={(id) => {
                  if (!id) {
                    return '';
                  }
                  return (multiLang(locale, form.values.street, form.values.streetEn) ?? '');
                }}
                renderOption={(building) => <div>
                  <div><Typography>{(multiLang(locale, building?.buildingNameZh, building?.buildingNameEn) ?? '') +
                    ((building?.blockZh || building?.blockEn) ? ` (${handleBlockDisplay(locale, multiLang(locale, building?.blockZh, building?.blockEn) ?? '')})` : '') +
                    (building?.lot ? ` ${building?.lot}` : '')}
                  </Typography></div>
                  <div><Typography variant="caption" style={{ display: isNonEmpty(multiLang(locale, building?.streetZh, building?.streetEn) ?? '') ? 'block' : 'none' }}>
                    {(multiLang(locale, building?.streetZh, building?.streetEn) ?? '')}
                  </Typography></div>
                </div>
                }
                noOptionsText={''}
                loading={buildingListLoading}
                loadingText={lang.msgLoading}
                renderInput={params => (
                  <TextField
                    {...params}
                    inputProps={{ ...params.inputProps, readOnly: editDisabled || !isNew }}
                    className={classes.patchOutlineAutocomplete}
                    error={!!form.hasError('street')}
                    helperText={form.hasError('street')}
                    fullWidth
                    label={langPropertyStock.street}
                    margin="dense"
                    variant="outlined"
                    onChange={(ev) => fetchBuilding(ev.target.value, 'street')}
                    // value={form.values.building ?? ''}
                    // {...bind('building')}
                  />
                )} 
                // {...bind('building')}
                value={form.values.street ?? ''}
                // inputValue={form.values.building ?? ''}
                
                onChange={(_, val) => {
                  if (val) {
                    const { id: buildingId, floorUnits, usage: _, ...b } = val;
                    form.setValues({
                      ...form.values,
                      buildingId,
                      ...b,
                      street: b.streetZh,
                      streetEn: b.streetEn,
                      building: b.buildingNameZh,
                      buildingEn: b.buildingNameEn,
                      primarySchoolNet: b.primarySchoolNet,
                      secondarySchoolNet: b.secondarySchoolNet,
                      floor: undefined,
                      unit: undefined,
                      hasFloorDefinitions: floorUnits?.filter((fu: any) => isNonEmpty(fu.floor))?.length > 0,
                      hasUnitDefinitions: floorUnits?.filter((fu: any) => isNonEmpty(fu.unit))?.length > 0,
                      latitude: b.latitude,
                      longitude: b.longitude,
                    });
                    tagForm.setValues({
                      ...tagForm.values,
                      CLUB_HOUSE_FACILITIES: b.clubHouseFacilities,
                      OTHERS: b.others
                    })
                    setFloorUnits(floorUnits);
                  } else {
                    form.setValues({
                      ...form.values,
                      buildingId: undefined,
                      district: undefined,
                      building: undefined,
                      buildingEn: undefined,
                      street: undefined,
                      streetEn: undefined,
                      block: undefined,
                      blockEn: undefined,
                      occupancyPermitDate: undefined,
                      expiryYear: undefined,
                      // usage: undefined,
                      floor: undefined,
                      unit: undefined,
                      hasFloorDefinitions: undefined,
                      hasUnitDefinitions: undefined,

                      latitude: undefined,
                      longitude: undefined,

                      primarySchoolNet: undefined,
                      secondarySchoolNet: undefined,
                    });
                  }
                }}
              />
            </Grid> */}

            <Grid
              item
              md={4} sm={4}
              xs={4}
            >
              <DistrictSingleSelect
                {...bind('district')}
                onChange={(ev) => {
                  setBuildings([]);
                  bind('district').onChange(ev);
                }}
                InputProps={{ disabled: true }}
                // InputProps={{ disabled: editDisabled || !isNew }}
                fullWidth
                label={langPropertyStock.district}
                margin="dense"
                select
                variant="outlined"
                districtValue={form.values.district ?? ''}
                onToggleChip={(value) => {
                  form.updateValues('district', value);
                }}
                disableSelection={true}
                data-key={'field-propertybasicinfo-district'}
              />
            </Grid>

            <Grid
              item
              md={7} sm={7}
              xs={7}
            >
              <TextField
                fullWidth
                InputProps={{ disabled: !isVillageHouse || !addressEditable || editDisabled }}
                label={langPropertyStock.lot}
                margin="dense"
                variant="outlined"
                {...bind('lot')}
                data-key={'field-propertybasicinfo-lot'}
              />
            </Grid>


            <Grid
              item
              md={1}
              sm={1}
              xs={1}
            // alignItems="center"
            >
              <MapDialogContainer
                // onMapSave={handleMapDialogSave}
                allowSave={false}
                allowClick={false}
                selectedValue={[form.values.latitude, form.values.longitude]}
              />
            </Grid>

            <Grid
              item
              md={4} sm={4}
              xs={4}
            >
              <TextField
                fullWidth
                InputProps={{ disabled: true }}
                label={langPropertyStock.block}
                margin="dense"
                variant="outlined"
                value={multiLang(locale, form.values.block, form.values.blockEn) ?? ''}
              />
            </Grid>


            <Grid
              item
              md={4} sm={4}
              xs={4}
            >
              {/* <TextField
                  fullWidth
                  select
                  label={langPropertyStock.floor}
                  margin="dense"
                  required
                  variant="outlined"
                  {...bind('floor')}
                  inputProps={{ readOnly: editDisabled || !isNew }}
                >
                  {[...new Set(
                    floorUnits?.map(fu => fu.floor).sort((a, b) => collator.compare(a, b)) ?? []
                  )].map((v) => <MenuItem key={v} value={v}>{v}</MenuItem>)}
                </TextField> */}
              <When test={!editDisabled} >
                <>
                  <When test={!isVillageHouse}>
                    {floorUnitSelect(langPropertyStock.floor,
                      [...new Set(
                        floorUnits?.map(fu => fu.floor) ?? []
                      )].sort(collator.compare), form.values.floor ?? [],
                      (val) => {


                        form.setValues({
                          ...form.values,
                          floor: val,
                          //floor: val?.sort(collator.compare),
                          // filter out the units that are not in the set of associated units of the selected floors
                          unit: form.values.unit?.filter(u => floorUnits?.filter(o => val?.includes(o.floor)).map(o => o.unit).includes(u)),
                          // the selected floors have their associated units
                          hasUnitDefinitions: (floorUnits?.filter(o => val?.includes(o.floor) && isNonEmpty(o.unit))?.length ?? 0) > 0
                        })

                        // form.updateValues('floor', val?.sort(collator.compare))
                      },
                      form.values.hasFloorDefinitions ?? false,
                      form.hasError('floor'),

                    )}
                  </When>
                  <When test={isVillageHouse}>
                    <CommaTextField
                      fullWidth
                      label={langPropertyStock.floor}
                      InputProps={{ disabled: !addressEditable }}
                      margin="dense"
                      variant="outlined"
                      {...bind('floor')}
                      val={form.values.floor}
                      //val={form.values.floor?.sort(collator.compare)}
                      onValChange={(updated) => {
                        form.updateValues('floor', updated)
                      }
                      }



                    />
                  </When>
                </>
              </When>
              <When test={editDisabled} >
                <TextField
                  fullWidth
                  label={langPropertyStock.floor}
                  margin="dense"
                  variant="outlined"
                  {...bind('floor')}
                  value={form.values.level?.map(l => levelOptions[l.trim()]) ?? ''}
                  InputProps={{ disabled: true }}
                  error={!!form.hasError('floor')}
                  helperText={form.hasError('floor')}

                />
              </When>

              {/* <When test={!isNew}>
                {showFloor ? 
                  <TextField
                    fullWidth
                    label={langPropertyStock.floor}
                    margin="dense"
                    variant="outlined"
                    {...bind('floor')}
                    InputProps={{ disabled: true }}
                  /> :
                  <TextField
                    fullWidth
                    label={langPropertyStock.floor}
                    margin="dense"
                    variant="outlined"
                    value={form.values.level?.map(l => levelOptions[l]).join(',') ?? ''}
                    InputProps={{ disabled: true }}
                  />
                }
              </When> */}
            </Grid>

            <Grid
              item
              md={4} sm={4}
              xs={4}
            >
              {/* <TextField
                  fullWidth
                  select
                  label={langPropertyStock.unit}
                  margin="dense"
                  required
                  variant="outlined"
                  {...bind('unit')}
                  inputProps={{ readOnly: editDisabled || !isNew }}
                >
                  {[...new Set(
                    floorUnits?.filter(fu => !form.values.floor ? true : fu.floor === form.values.floor)
                    .map(fu => fu.unit).sort((a, b) => collator.compare(a, b)) ?? []
                  )].map((v) => <MenuItem key={v} value={v}>{v}</MenuItem>)}
                </TextField> */}
              <When test={!editDisabled}>
                <>
                  <When test={!isVillageHouse}>
                    {floorUnitSelect(langPropertyStock.unit,
                      [...new Set(
                        floorUnits?.filter(fu => (isNonEmpty(form.values.floor)) ? form.values.floor?.includes(fu.floor) : fu.floor === "").map(fu => fu.unit) ?? []
                        // floorUnits?.filter(fu => !form.values.floor ? true : (form.values.floor.indexOf(fu.floor) > -1))
                        //   .map(fu => fu.unit) ?? []
                      )].sort(collator.compare),
                      form.values.unit ?? [],
                      (val) => {
                        form.setValues({
                          ...form.values,
                          unit: val?.sort(collator.compare),
                          // filter out the floors that are not in the set of associated floors of the selected units
                          floor: form.values.floor?.filter(f => floorUnits?.filter(o => val?.includes(o.unit)).map(o => o.floor).includes(f)),
                          //floor: isNonEmpty(form.values.floor)? form.values.floor?.filter(f => floorUnits?.filter(o => val?.includes(o.unit)).map(o => o.floor).includes(f)): undefined,
                          //the selected units have their associated floor
                          // unit is compulsory, so no matter what UnitDefinitions should be true
                          hasFloorDefinitions: (floorUnits?.filter(o => val?.includes(o.unit) && isNonEmpty(o.floor))?.length ?? 0) > 0
                        })
                        // form.updateValues('unit', val?.sort(collator.compare))
                      },
                      form.values.hasUnitDefinitions ?? false,
                      form.hasError('unit'),
                    )}
                  </When>
                  <When test={isVillageHouse}>
                    <CommaTextField
                      fullWidth
                      label={langPropertyStock.unit}
                      margin="dense"
                      variant="outlined"
                      InputProps={{ disabled: !addressEditable }}
                      {...bind('unit')}
                      val={form.values.unit?.sort(collator.compare)}
                      onValChange={(updated) => form.updateValues('unit', updated)}

                    />
                  </When>
                </>
              </When>
              <When test={editDisabled}>
                <TextField
                  fullWidth
                  label={langPropertyStock.unit}
                  margin="dense"
                  variant="outlined"
                  {...bind('unit')}
                  InputProps={{ disabled: true }}
                  error={!!form.hasError('unit')}
                  helperText={form.hasError('unit')}
                />
              </When>

              {/* <When test={!isNew && showUnit}>
                <TextField
                  fullWidth
                  label={langPropertyStock.unit}
                  margin="dense"
                  variant="outlined"
                  {...bind('unit')}
                  InputProps={{ disabled: true }}
                />
              </When> */}
            </Grid>


            <Grid
              item
              md={3} sm={3}
              xs={6}
            >
              <NumberField
                fullWidth
                required
                label={langPropertyStock.gross}
                margin="dense"
                variant="outlined"
                InputProps={{
                  endAdornment: <InputAdornment position="start">{lang.uFt}</InputAdornment>
                }}
                inputProps={{ readOnly: editDisabled }}
                classes={{ root: isSameGross ? undefined : classes.highlightedAreaField }}
                {...bind('gross')}
                data-key={'field-propertybasicinfo-gross'}
                onChange={(e) => {
                  limitNumberFieldLength(e, 'gross', form);
                }}
              />
              {form.hasError('gross') ? <FormHelperText style={{ color: form.hasError('gross') ? errorColor : 'inherit' }}>{langPropertyStock.msgInputGross}</FormHelperText> : null}

            </Grid>
            <Grid
              item
              md={3} sm={3}
              xs={6}
            >
              <NumberField
                fullWidth
                required
                label={langPropertyStock.net}
                margin="dense"
                variant="outlined"
                InputProps={{
                  endAdornment: <InputAdornment position="start">{lang.uFt}</InputAdornment>
                }}
                inputProps={{ readOnly: editDisabled }}
                classes={{ root: isSameNet ? undefined : classes.highlightedAreaField }}
                {...bind('net')}
                data-key={'field-propertybasicinfo-net'}
                onChange={(e) => {
                  limitNumberFieldLength(e, 'net', form);
                }}
              />
              {form.hasError('net') ? <FormHelperText style={{ color: form.hasError('net') ? errorColor : 'inherit' }}>{langPropertyStock.msgInputNet}</FormHelperText> : null}
            </Grid>

            <Grid
              item
              md={3} sm={3}
              xs={6}
            >
              <TextField
                fullWidth
                InputProps={{ disabled: true }}
                label={langPropertyStock.expiryYear}
                margin="dense"
                // required
                variant="outlined"
                {...bind('expiryYear')}
                data-key={'field-propertybasicinfo-expiryYear'}
              />
            </Grid>

            <Grid
              item
              md={3} sm={3}
              xs={6}
            >
              <TextField
                fullWidth
                InputProps={{ disabled: true }}
                label={langPropertyStock.occupancyPermitDate}
                margin="dense"
                type="text"
                variant="outlined"
                // {...bind('occupancyPermitDate')}
                value={form.values.occupancyPermitDate ? moment(form.values.occupancyPermitDate).format(DISPLAY_DATE_FORMAT) : ''}
              />
            </Grid>

            {
              form.values.usage == 'S' ?
                <Grid item container spacing={1}>
                  <Grid
                    item
                    md={3} sm={3}
                    xs={6}
                  >
                    <NumberField
                      fullWidth
                      label={langPropertyStock.baseFloorGross}
                      margin="dense"
                      variant="outlined"
                      InputProps={{
                        endAdornment: <InputAdornment position="start">{lang.uFt}</InputAdornment>
                      }}
                      {...bind('baseFloorGross')}
                      data-key={'field-propertybasicinfo-baseFloorGross'}
                    />
                  </Grid>
                  <Grid
                    item
                    md={3} sm={3}
                    xs={6}
                  >
                    <NumberField
                      fullWidth
                      label={langPropertyStock.baseFloorNet}
                      margin="dense"
                      variant="outlined"
                      InputProps={{
                        endAdornment: <InputAdornment position="start">{lang.uFt}</InputAdornment>
                      }}
                      {...bind('baseFloorNet')}
                    />
                  </Grid>
                  <Grid
                    item
                    md={3} sm={3}
                    xs={6}
                  >
                    <NumberField
                      fullWidth
                      label={langPropertyStock.groundFloorGross}
                      margin="dense"
                      variant="outlined"
                      InputProps={{
                        endAdornment: <InputAdornment position="start">{lang.uFt}</InputAdornment>
                      }}
                      {...bind('groundFloorGross')}
                      data-key={'field-propertybasicinfo-groundFloorGross'}
                    />
                  </Grid>
                  <Grid
                    item
                    md={3} sm={3}
                    xs={6}
                  >
                    <NumberField
                      fullWidth
                      label={langPropertyStock.groundFloorNet}
                      margin="dense"
                      variant="outlined"
                      InputProps={{
                        endAdornment: <InputAdornment position="start">{lang.uFt}</InputAdornment>
                      }}
                      {...bind('groundFloorNet')}
                      data-key={'field-propertybasicinfo-groundFloorNet'}
                    />
                  </Grid>
                  <Grid
                    item
                    md={3} sm={3}
                    xs={6}
                  >
                    <NumberField
                      fullWidth
                      label={langPropertyStock.mezzanineFloorGross}
                      margin="dense"
                      variant="outlined"
                      InputProps={{
                        endAdornment: <InputAdornment position="start">{lang.uFt}</InputAdornment>
                      }}
                      {...bind('mezzanineFloorGross')}
                      data-key={'field-propertybasicinfo-mezzanineFloorGross'}
                    />
                  </Grid>
                  <Grid
                    item
                    md={3} sm={3}
                    xs={6}
                  >
                    <NumberField
                      fullWidth
                      label={langPropertyStock.mezzanineFloorNet}
                      margin="dense"
                      variant="outlined"
                      InputProps={{
                        endAdornment: <InputAdornment position="start">{lang.uFt}</InputAdornment>
                      }}
                      {...bind('mezzanineFloorNet')}
                      data-key={'field-propertybasicinfo-mezzanineFLoorNet'}
                    />
                  </Grid>
                  <Grid
                    item
                    md={3} sm={3}
                    xs={6}
                  >
                    <NumberField
                      fullWidth
                      label={langPropertyStock.otherAreaGross}
                      margin="dense"
                      variant="outlined"
                      InputProps={{
                        endAdornment: <InputAdornment position="start">{lang.uFt}</InputAdornment>
                      }}
                      {...bind('otherAreaGross')}
                      data-key={'field-propertybasicinfo-otherAreaGross'}
                    />
                  </Grid>
                  <Grid
                    item
                    md={3} sm={3}
                    xs={6}
                  >
                    <NumberField
                      fullWidth
                      label={langPropertyStock.otherAreaNet}
                      margin="dense"
                      variant="outlined"
                      InputProps={{
                        endAdornment: <InputAdornment position="start">{lang.uFt}</InputAdornment>
                      }}
                      classes={{ root: isSameGross ? undefined : classes.highlightedAreaField }}
                      {...bind('otherAreaNet')}
                      data-key={'field-propertybasicinfo-otherAreaNet'}
                    />
                  </Grid>
                </Grid>
                : null
            }
          </Grid>
        </CardContent>
      </form>
    </Card>
  );
};

export default PropertyBasicInfo;
