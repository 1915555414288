import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState, PASDispatch } from 'reducers';
import { BuildingsSearch, BuildingsTable, BuildingsToolbar } from './components';

const DEFAULT_SIZE = 10;

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(2)
	},
	content: {
		marginTop: theme.spacing(2)
	},
	search: {
		marginBottom: theme.spacing(2)
	}
}));

interface SearchFormValues {
	usage: string[];
	district: string[];
	street: string;
	building: string;
	block: string;
}

let initialPage = 0 as number | undefined;
let currentBuildingRow = 0;
const BuildingList = () => {
	const classes = useStyles(0);

	const dispatch = useDispatch() as PASDispatch;
	const buildings = useSelector((state: IRootState) => state.building.currentList);
	const {
		totalPages, totalElements,
		criteria: { page, size, sort: sortOrders, ...values },
	} = useSelector((state: IRootState) => state.building);
	initialPage = page;
	const limitPref = useSelector((state: IRootState) => state.login.rowsCountPreference?.BUILDING_MASTER) ?? DEFAULT_SIZE;

	const [currentRowsPerPage, setCurrentRowsPerPage] = useState(currentBuildingRow === 0 ? limitPref : currentBuildingRow);
	currentBuildingRow = currentRowsPerPage;
	// const [ limitPref, setLimitPref ] = useState(limit);
	// const [ limit, setLimit ] = useState(10);

	const handleRowsCount = (count: number) => {
		dispatch({ type: 'Login.RowCountPrefUpdate', payload: { BUILDING_MASTER: count } });
	}

	const handleSearch = (values: Partial<SearchFormValues>) => {
		dispatch({
			type: 'BuildingList.CriteriaUpdated', payload: {
				page: 0,
				...values,
			}
		});
		dispatch({ type: 'BuildingList.FetchRequested' });
	};

	const handleSort = (key: string | null) => {
		if (key === null) {
			dispatch({
				type: 'BuildingList.CriteriaUpdated',
				payload: { sort: {}, },
			});
		} else {
			let newSortOrders;
			if (key != 'dateModified') {
				newSortOrders = {
					[key]: sortOrders?.[key] === 'asc' ? 'desc' : 'asc',
					['dateModified']: 'desc'
				};
			} else {
				newSortOrders = { [key]: sortOrders?.[key] === 'asc' ? 'desc' : 'asc' };
			}
			dispatch({
				type: 'BuildingList.CriteriaUpdated', payload: {
					sort: newSortOrders,
				}
			});
		}
		dispatch({ type: 'BuildingList.FetchRequested' });
	}

	useEffect(() => {
		dispatch({
			type: 'BuildingList.CriteriaUpdated', payload: {
				page: initialPage,
				size: currentRowsPerPage,
				...values,
			}
		});
		dispatch({ type: 'BuildingList.FetchRequested' });
		// setLimit(rowsCountPreference?.BUILDING_MASTER);
	}, [currentRowsPerPage]);

	return (
		<div className={classes.root}>
			<BuildingsToolbar />
			<div className={classes.content}>
				<BuildingsSearch form={values} onSearch={handleSearch} className={classes.search} />
				<BuildingsTable
					page={page ?? 0}
					limit={currentRowsPerPage}
					handleRowsCount={(ev) => { setCurrentRowsPerPage(ev); handleRowsCount(ev); }}
					totalPages={totalPages}
					totalElements={totalElements}
					setPage={(page) => {
						dispatch({
							type: 'BuildingList.CriteriaUpdated',
							payload: { page },
						});
						dispatch({ type: 'BuildingList.FetchRequested' });
					}}
					buildings={buildings}
					sortOrders={sortOrders as any}
					handleSort={handleSort}
				/>
			</div>
		</div>
	);
};

export default BuildingList;