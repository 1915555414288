import { ApiResult } from 'api';
import { CAFeatureTagDTO } from 'common/dto';
import { DiffUpdateSpec } from 'common/utils';

const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];

// TODO: auth
export const featureTagApi = {

  getList: async (token: string): Promise<ApiResult<CAFeatureTagDTO[]>> => {
    try {
      const response = await fetch (`${BASE_URL}/featureTagMgt/list`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  editList: async (listUpdate: DiffUpdateSpec<CAFeatureTagDTO>, token: string): Promise<ApiResult<boolean>> => {
    try {
      const response = await fetch (`${BASE_URL}/featureTagMgt/edit`, {
        method: 'PATCH', 
        body: JSON.stringify(listUpdate),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

};

export default featureTagApi;