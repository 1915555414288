import palette from '../../../../theme/palette';

export const options = (langDashboard) => ({

  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  legend: { 
    display: true,
    position: 'top',
    align: 'end',
    labels:{fontSize: 10} 
  },
  cornerRadius: 20,
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: palette.divider,
    backgroundColor: palette.white,
    titleFontColor: palette.text.primary,
    bodyFontColor: palette.text.secondary,
    footerFontColor: palette.text.secondary
  },
  layout: { padding: 0 },
  scales: {
    xAxes: [
      {
        // barThickness: 12,
        // maxBarThickness: 10,
        // barPercentage: 0.5,
        // categoryPercentage: 0.5,
        // stacked: true,
        ticks: {
          fontColor: palette.text.secondary,
          beginAtZero: true,
          precision:0,
          min: 0
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: palette.divider
        },
        scaleLabel: {
          display: true,
          labelString: langDashboard.amount,
        }
      }
    ],
    yAxes: [
      {
        stacked: true,
        ticks: {
          fontColor: palette.text.secondary,
          // precision:0
          // beginAtZero: true,
          // min: 0
        },
        gridLines: {
          display: false,
          drawBorder: false
        },
        scaleLabel: {
          display: true,
          labelString: langDashboard.district,
        }
      }
    ]
  }
}
);
