import { ApiPageResult, ApiResult } from 'api';
import { ActivityAuditingDTO } from 'common/dto';
import { objectToQuery } from 'common/utils';

const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];

export const activityAuditingApi = {
  getList: async (criteria: any = {}, token: string): Promise<ApiPageResult<ActivityAuditingDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/audit-records?${objectToQuery(criteria)}`, {
        headers: { 'Authorization': `Bearer ${token}`}
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  reportReadActivity: async (module: string, id: string, enterAt: string, exitAt: string, token: string): Promise<ApiResult<any>> => {
    try {
      const response = await fetch(`${BASE_URL}/audit-records/r?m=${module}&n=${id}&s=${enterAt}&e=${exitAt}`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  reportReadActivityBeacon: (module: string, id: string, enterAt: string, exitAt: string, token: string): boolean => {
    try {
      if ((navigator as any).sendBeacon) {
        
        return navigator.sendBeacon(
          `${BASE_URL}/audit-records/r?m=${module}&n=${id}&s=${enterAt}&e=${exitAt}&t=${token}`
        );
      }
      
      return false;
    } catch (e) {
      console.error(e);
      return false;
    }
  },

};

export default activityAuditingApi;