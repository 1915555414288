import { Form1DTO, Form2DTO, Form3DTO, Form4DTO, Form5DTO, Form6DTO } from "common/dto";
import { FormValidations } from "common/hooks";
import { checkValidPhoneNumber, isNonEmpty } from 'common/utils';
import moment from "moment";
import { isNullOrUndefined } from "util";

type Options = { [key: string]: string };

const formLocaleValidations = (lang: Options): FormValidations<{ formLocale?: string | undefined }> => [
  (values: { formLocale?: string | undefined }) => !isNullOrUndefined(values.formLocale) ? null : [
    ['formLocale', lang.pleaseInput + lang.captionFormLocale],
  ],
];

export const Form1Validations = (langForm1: Options): FormValidations<Form1DTO> => [
  ...formLocaleValidations(langForm1),
  (values) => values.propertyAddress ? null : [
    ['propertyAddress', langForm1.pleaseInput + langForm1.propertyAddress],
  ],
  (values) => values.ownerName ? null : [
    ['ownerName', langForm1.pleaseInput + langForm1.ownerName],
  ],
  (values) => !(values.hasCourtOrders === true && (isNullOrUndefined(values.courtOrdersParticulars) || values.courtOrdersParticulars === '')) ? null : [
    ['courtOrdersParticulars', langForm1.pleaseInput + langForm1.particulars],
  ],
  (values) => !(values.hasBuildingOrders === true && (isNullOrUndefined(values.buildingOrdersParticulars) || values.buildingOrdersParticulars === '')) ? null : [
    ['buildingOrdersParticulars', langForm1.pleaseInput + langForm1.particulars],
  ],
  (values) => !(values.hasSlopeMaintenanceOrders === true && (isNullOrUndefined(values.slopeMaintenanceOrdersParticulars) || values.slopeMaintenanceOrdersParticulars === '')) ? null : [
    ['slopeMaintenanceOrdersParticulars', langForm1.pleaseInput + langForm1.particulars],
  ],
  (values) => !(values.hasLease === true && (isNullOrUndefined(values.leaseParticulars) || values.leaseParticulars === '')) ? null : [
    ['leaseParticulars', langForm1.pleaseInput + langForm1.particulars],
  ],
  (values) => !(values.hasMortgageOrCharge === true && (isNullOrUndefined(values.mortgageOrChargeParticulars) || values.mortgageOrChargeParticulars === '')) ? null : [
    ['mortgageOrChargeParticulars', langForm1.pleaseInput + langForm1.particulars],
  ],
  (values) => values.dateOfObtainingB1 ? !moment(new Date).isSameOrAfter(values.dateOfObtainingB1, 'day') ? [['dateOfObtainingB1', '']] : null : [
    ['dateOfObtainingB1', langForm1.pleaseSelect + langForm1.dateOfObtainingInfo]
  ],
  //part B2
  (values) => values.partB2_option ? null : [
    ['partB2_option', langForm1.pleaseSelect],
  ],
  (values) => values.partB2_option == 'optionA' ?
    (values.saleableAreaByDepartment ?
      null : [
        ['saleableAreaByDepartment', langForm1.pleaseInput],
      ]) : null,
  (values) => values.partB2_option == 'optionB' ?
    (values.saleableAreaFirstAssignment ?
      null : [
        ['saleableAreaFirstAssignment', langForm1.pleaseInput],
      ]) : null,
  (values) => values.dateOfObtainingB2 ? !moment(new Date).isSameOrAfter(values.dateOfObtainingB2, 'day') ? [['dateOfObtainingB2', '']] : null : [
    ['dateOfObtainingB2', langForm1.pleaseSelect + langForm1.dateOfObtainingInfo]
  ],
  //part B3
  (values) => values.yearOfCompletion || !values.hasYearOfCompletion ? null : [
    ['yearOfCompletion', langForm1.pleaseSelect + langForm1.titlePartB3],
  ],
  (values) => values.sourceOfB3 || !values.hasYearOfCompletion ? null : [
    ['sourceOfB3', langForm1.pleaseSelect + langForm1.source],
  ],
  (values) => values.dateOfObtainingB3 ? !moment(new Date).isSameOrAfter(values.dateOfObtainingB3, 'day') ? [['dateOfObtainingB3', '']] : null : [
    ['dateOfObtainingB3', langForm1.pleaseSelect + langForm1.dateOfObtainingInfo]
  ],
  //part B4
  (values) => values.userRestrictions || !values.hasUserRestrictions ? null : [
    ['userRestrictions', langForm1.pleaseInput + langForm1.titlePartB4],
  ],
  (values) => values.sourceOfB4 || !values.hasUserRestrictions ? null : [
    ['sourceOfB4', langForm1.pleaseSelect + langForm1.source],
  ],
  (values) => values.dateOfObtainingB4 ? !moment(new Date).isSameOrAfter(values.dateOfObtainingB4, 'day') ? [['dateOfObtainingB4', '']] : null : [
    ['dateOfObtainingB4', langForm1.pleaseSelect + langForm1.dateOfObtainingInfo]
  ],
  //part B5
  (values) => values.unexpiredTermOfLease ? null : [
    ['unexpiredTermOfLease', langForm1.pleaseSelect + langForm1.unexpiredTermOfGovLease],
  ],
  (values) => values.dateOfObtainingB5 ? !moment(new Date).isSameOrAfter(values.dateOfObtainingB5, 'day') ? [['dateOfObtainingB5', '']] : null : [
    ['dateOfObtainingB5', langForm1.pleaseSelect + langForm1.dateOfObtainingInfo]
  ],
  //part B6
  (values) => values.termOfProposedLease ? null : [
    ['termOfProposedLease', langForm1.pleaseSelect + langForm1.proposedLease],
  ],
  (values) => values.dateOfObtainingB6 ? !moment(new Date).isSameOrAfter(values.dateOfObtainingB6, 'day') ? [['dateOfObtainingB6', '']] : null : [
    ['dateOfObtainingB6', langForm1.pleaseSelect + langForm1.dateOfObtainingInfo]
  ],
  (values) => !isNonEmpty(values.annualGovRent) || values.annualGovRent!! >= 0 ? null : [
    ['annualGovRent', langForm1.pleaseInput + langForm1.annualGovRent],
  ],
  (values) => !isNonEmpty(values.quarterlyRates) || values.quarterlyRates!! >= 0 ? null : [
    ['quarterlyRates', langForm1.pleaseInput + langForm1.quarterlyRates],
  ],
  (values) => !isNonEmpty(values.monthlyManagementFee) || values.monthlyManagementFee!! >= 0 ? null : [
    ['monthlyManagementFee', langForm1.pleaseInput + langForm1.monthlyManagementFee],
  ],

  (values) => values.propertyCategories ? null : [
    ['propertyCategories', langForm1.msgSelectAtLeastOneForPart6],
  ],

  (values) => values.propertyCategories == "others" && !isNonEmpty(values.descriptionForOthers) ? [
    ['descriptionForOthers', langForm1.msgInputSelectedFields],
  ] : null,


  //part 2
  (values) => values.part2_statement1 != '' ? null : [
    ['part2_statement1', langForm1.pleaseSelect],
  ],
  (values) => values.part2_statement1 == 'Y' ?
    (values.part2_statement1_particular ?
      null : [
        ['part2_statement1_particular', langForm1.pleaseInput],
      ]) : null,
  (values) => values.part2_statement2 != '' ? null : [
    ['part2_statement2', langForm1.pleaseSelect],
  ],
  (values) => values.part2_statement2 == 'Y' ?
    (values.part2_statement2_particular ?
      null : [
        ['part2_statement2_particular', langForm1.pleaseInput],
      ]) : null,
  (values) => values.part2_statement3 != '' ? null : [
    ['part2_statement3', langForm1.pleaseSelect],
  ],
  (values) => values.part2_statement3 == 'Y' ?
    (values.part2_statement3_particular ?
      null : [
        ['part2_statement3_particular', langForm1.pleaseInput],
      ]) : null,
  (values) => values.part2_statement4 != '' ? null : [
    ['part2_statement4', langForm1.pleaseSelect],
  ],
  (values) => values.part2_statement4 == 'Y' ?
    (values.part2_statement4_particular ?
      null : [
        ['part2_statement4_particular', langForm1.pleaseInput],
      ]) : null,
  (values) => !!values.costOfPurchaserOptions ? null : [
    ['costOfPurchaserOptions', langForm1.pleaseSelect],
  ],
  (values) => values.costOfPurchaserOptions?.includes('a') && (isNullOrUndefined(values.natureAndCostOfPurchaserDetail) || values.natureAndCostOfPurchaserDetail === '') ? [
    ['natureAndCostOfPurchaserDetail', langForm1.pleaseSelect],
  ] : null,
  (values) => values.costOfPurchaserOptions?.includes('b') && (isNullOrUndefined(values.natureAndCostOfPurchaserDetail) || values.natureAndCostOfPurchaserDetail === '') ? [
    ['natureAndCostOfPurchaserDetail', langForm1.pleaseSelect],
  ] : null,
  (values) => values.costOfPurchaserOptions?.includes('c') && (isNullOrUndefined(values.natureAndCostOfPurchaserDetail) || values.natureAndCostOfPurchaserDetail === '') ? [
    ['natureAndCostOfPurchaserDetail', langForm1.pleaseSelect],
  ] : null,
  (values) => values.costOfPurchaserOptions?.includes('d') && (isNullOrUndefined(values.natureAndCostOfPurchaserDetail) || values.natureAndCostOfPurchaserDetail === '') ? [
    ['natureAndCostOfPurchaserDetail', langForm1.pleaseSelect],
  ] : null,
  (values) => values.part2_statement6_option ? null : [
    ['part2_statement6_option', langForm1.pleaseSelect],
  ],
  //for part2_statement6_option == optionB
  (values) => values.part2_statement6_option == 'optionB' ?
    (values.leaseOption !== '' && values.monthlyRental && (values.tenancyTermYear !== null || values.tenancyTermYear !== undefined) && (values.tenancyTermMonth !== null || values.tenancyTermMonth !== undefined) && values.tenancyTermStart && values.tenancyTermEnd ?
      null : [
        ['leaseOption', langForm1.pleaseSelect],
        ['monthlyRental', langForm1.pleaseInput],
        ['tenancyTermStart', langForm1.pleaseInput],
        ['tenancyTermEnd', langForm1.pleaseInput],
      ]) : null,
  (values) => values.part2_statement6_option == 'optionB' ?
    (!(values.hasOtherSpecialTerms && (isNullOrUndefined(values.specialTermsDescription) || values.specialTermsDescription === '')) ?
      null : [
        ['specialTermsDescription', langForm1.pleaseInput],
      ]) : null,
  (values) => !(values.informationProvidedByVendor && (isNullOrUndefined(values.vendorName) || values.vendorName === '')) ? null : [
    ['vendorName', langForm1.pleaseInput + langForm1.vendorName],
  ],
  //agent info
  (values) => values.agentName ? null : [
    ['agentName', langForm1.pleaseInput + langForm1.agentName],
  ],
  (values) => values.nameAndLicenceNumberOfSignatory ? null : [
    ['nameAndLicenceNumberOfSignatory', langForm1.pleaseInput + langForm1.nameAndLicenceNumberOfSignatory],
  ],
  (values) => values.numberOfAgentStatement ? null : [
    ['numberOfAgentStatement', langForm1.pleaseInput + langForm1.numberOfAgentStatement],
  ],
  (values) => values.address ? null : [
    ['address', langForm1.pleaseInput + langForm1.address],
  ],
  (values) => values.telephoneNumber ?
    (checkValidPhoneNumber(values.telephoneNumber) ? null
      : [['telephoneNumber', langForm1.msgInputValidPhoneNumber]])
    : [
      ['telephoneNumber', langForm1.pleaseInput + langForm1.telephoneNumber],
    ],
  // (values) => values.faxNumber ? null: [
  //   [ 'faxNumber', langForm1.pleaseInput + langForm1.faxNumber ],
  // ],
  // (values) => values.date ? null: [
  //   [ 'date', langForm1.pleaseSelect + langForm1.date ],
  // ],

];

export const Form2Validations = (langForm2: Options): FormValidations<Form2DTO> => [
  ...formLocaleValidations(langForm2),
  (values) => values.propertyAddress ? null : [
    ['propertyAddress', langForm2.pleaseInput + langForm2.propertyAddress],
  ],
  (values) => values.ownerName ? null : [
    ['ownerName', langForm2.pleaseInput + langForm2.ownerName],
  ],
  (values) => values.dateOfObtainingB1 ? !moment(new Date).isSameOrAfter(values.dateOfObtainingB1, 'day') ? [['dateOfObtainingB1', '']] : null : [
    ['dateOfObtainingB1', langForm2.pleaseSelect + langForm2.dateOfObtainingInfo]
  ],
  //part B2
  (values) => values.partB2_option ? null : [
    ['partB2_option', langForm2.pleaseSelect],
  ],
  (values) => values.partB2_option == 'optionA' ?
    (values.saleableAreaByDepartment ?
      null : [
        ['saleableAreaByDepartment', langForm2.pleaseInput],
      ]) : null,
  (values) => values.partB2_option == 'optionB' ?
    (values.saleableAreaFirstAssignment ?
      null : [
        ['saleableAreaFirstAssignment', langForm2.pleaseInput],
      ]) : null,
  (values) => values.dateOfObtainingB2 ? !moment(new Date).isSameOrAfter(values.dateOfObtainingB2, 'day') ? [['dateOfObtainingB2', '']] : null : [
    ['dateOfObtainingB2', langForm2.pleaseSelect + langForm2.dateOfObtainingInfo]
  ],
  //part B3
  (values) => values.yearOfCompletion || !values.hasYearOfCompletion ? null : [
    ['yearOfCompletion', langForm2.pleaseSelect + langForm2.titlePartB3],
  ],
  (values) => values.sourceOfB3 || !values.hasYearOfCompletion ? null : [
    ['sourceOfB3', langForm2.pleaseSelect + langForm2.source],
  ],
  (values) => values.dateOfObtainingB3 ? !moment(new Date).isSameOrAfter(values.dateOfObtainingB3, 'day') ? [['dateOfObtainingB3', '']] : null : [
    ['dateOfObtainingB3', langForm2.pleaseSelect + langForm2.dateOfObtainingInfo]
  ],
  //part B4
  (values) => values.userRestrictions || !values.hasUserRestrictions ? null : [
    ['userRestrictions', langForm2.pleaseInput + langForm2.titlePartB4],
  ],
  (values) => values.sourceOfB4 || !values.hasUserRestrictions ? null : [
    ['sourceOfB4', langForm2.pleaseSelect + langForm2.source],
  ],
  (values) => values.dateOfObtainingB4 ? !moment(new Date).isSameOrAfter(values.dateOfObtainingB4, 'day') ? [['dateOfObtainingB4', '']] : null : [
    ['dateOfObtainingB4', langForm2.pleaseSelect + langForm2.dateOfObtainingInfo]
  ],
  //part B5
  (values) => values.sourceOfB5 || !values.hasRestrictionOnLeasing ? null : [
    ['sourceOfB5', langForm2.pleaseSelect + langForm2.source],
  ],
  (values) => values.dateOfObtainingB5 ? !moment(new Date).isSameOrAfter(values.dateOfObtainingB5, 'day') ? [['dateOfObtainingB5', '']] : null : [
    ['dateOfObtainingB5', langForm2.pleaseSelect + langForm2.dateOfObtainingInfo]
  ],
  (values) => !isNonEmpty(values.annualGovRent) || values.annualGovRent!! >= 0 ? null : [
    ['annualGovRent', langForm2.pleaseInput + langForm2.annualGovRent],
  ],
  (values) => !isNonEmpty(values.quarterlyRates) || values.quarterlyRates!! >= 0 ? null : [
    ['quarterlyRates', langForm2.pleaseInput + langForm2.quarterlyRates],
  ],
  (values) => !isNonEmpty(values.monthlyManagementFee) || values.monthlyManagementFee!! >= 0 ? null : [
    ['monthlyManagementFee', langForm2.pleaseInput + langForm2.monthlyManagementFee],
  ],
  (values) => !(values.toBeSublet === true && (values.subletParticulars == null || values.subletParticulars == undefined || values.subletParticulars === "")) ? null : [
    ['subletParticulars', langForm2.pleaseInput + langForm2.particulars],
  ],
  //agent info
  (values) => values.agentName ? null : [
    ['agentName', langForm2.pleaseInput + langForm2.agentName],
  ],
  (values) => values.nameAndLicenceNumberOfSignatory ? null : [
    ['nameAndLicenceNumberOfSignatory', langForm2.pleaseInput + langForm2.nameAndLicenceNumberOfSignatory],
  ],
  (values) => values.numberOfAgentStatement ? null : [
    ['numberOfAgentStatement', langForm2.pleaseInput + langForm2.numberOfAgentStatement],
  ],
  (values) => values.address ? null : [
    ['address', langForm2.pleaseInput + langForm2.address],
  ],
  (values) => values.telephoneNumber ?
    (checkValidPhoneNumber(values.telephoneNumber) ? null
      : [['telephoneNumber', langForm2.msgInputValidPhoneNumber]])
    : [
      ['telephoneNumber', langForm2.pleaseInput + langForm2.telephoneNumber],
    ],
  // (values) => values.faxNumber ? null: [
  //   [ 'faxNumber', langForm2.pleaseInput + langForm2.faxNumber ],
  // ],
  // (values) => values.date ? null: [
  //   [ 'date', langForm2.pleaseSelect + langForm2.date ],
  // ],

];

const determineVendorIsCompany = (values: Partial<Form3DTO>) => {
  if (!isNonEmpty(values.vendorHKID) &&
    !isNonEmpty(values.nameOfSignatoryOfVendor) &&
    !isNonEmpty(values.positionOfSignatoryOfVendor) &&
    !isNonEmpty(values.businessRegistrationCertificateNumberOfVendor)
  ) {
    return undefined;
  } else if (isNonEmpty(values.vendorHKID)) {
    return false;
  } else if (isNonEmpty(values.nameOfSignatoryOfVendor) || isNonEmpty(values.positionOfSignatoryOfVendor) || isNonEmpty(values.businessRegistrationCertificateNumberOfVendor)) {
    return true;
  }
}

const isValidityPeriodValid = (values: Partial<Form3DTO>) => {
  if (isNonEmpty(values.validityPeriodStart) && isNonEmpty(values.validityPeriodEnd)) {
    if (new Date(values.validityPeriodStart!) > new Date(values.validityPeriodEnd!)) {
      return false;
    } else {
      return true;
    }
  }
  return true;
}

export const Form3Validations = (langForm3: Options): FormValidations<Form3DTO> => [
  ...formLocaleValidations(langForm3),
  //Part 1
  (values) => values.vendorName ? null : [
    ['vendorName', langForm3.msgInputVendorName],
  ],
  (values) => values.agentName ? null : [
    ['agentName', langForm3.msgInputAgentName],
  ],
  (values) => values.propertyAddress ? null : [
    ['propertyAddress', langForm3.msgInputPropertyAddress],
  ],
  (values) => isValidityPeriodValid(values) ? null : [
    ['isValidityPeriodValid', langForm3.msgInputValidPeriodStartAndEnd],
  ],
  (values) => values.validityPeriodStart ? null : [
    ['validityPeriodStart', langForm3.msgInputValidityPeriodStart]
  ],
  (values) => values.validityPeriodEnd ? null : [
    ['validityPeriodEnd', langForm3.msgInputvalidityPeriodEnd],
  ],

  //part 2
  (values) => values.relationship ? null : [
    ['relationship', langForm3.msgSelectRelationship],
  ],

  //part 3
  (values) => values.listPrice && values.listPrice >= 0 ? null : [
    ['listPrice', langForm3.msgInputListPrice],
  ],

  //part 6
  (values) => values.allowViewing || values.passKeyForSafeCustody || values.passKeyToThirdParty ? null : [
    ['isMissingPart6', langForm3.msgSelectAtLeastOneForPart6],
  ],

  //part 9
  (values) => !(values.needDisclosure && (values.interestsInProperty == null || values.interestsInProperty == undefined || values.interestsInProperty === "")) ? null : [
    ['interestsInProperty', langForm3.msgInputInterestsInProperty],
  ],

  //part 10
  (values) => values.vendorAcknowledgementOption ? null : [
    ['vendorAcknowledgementOption', langForm3.msgSelectVendorAcknowledgementOption],
  ],

  //schedule 2
  // (values) => values.commissionIn ==='amount' || values.commissionIn ==='percentage'? null: [
  //   [ 'commissionIn', langForm3.msgSelectCommissionIn ],
  // ],
  // (values) => !(values.commissionIn =='amount' && !!values.commissionAmount == false)? null: [
  //   [ 'commissionAmount', langForm3.msgInputCommissionAmount ],
  // ],
  // (values) => !(values.commissionIn =='percentage' && !!values.commissionRate == false)? null: [
  //   [ 'commissionRate', langForm3.msgInputCommissionRate ],
  // ],
  // (values) => values.paymentTimeOption ? null: [
  //   [ 'paymentTimeOption', langForm3.msgSelectPaymentTime ],
  // ],
  // (values) => values.withInterest ? null: [
  //   [ 'withInterest', langForm3.msgSelectWithInterest ],
  // ],

  //vendor info
  // (values) => values.vendorHKID ? null: [
  //   [ 'vendorHKID', langForm3.msgInputHKID ],
  // ],
  // (values) => !isNullOrUndefined(determineVendorIsCompany(values)) ? null: [
  //   [ 'eitherSignatoryInfoOrHKID', langForm3.msgInputSignatoryInfoOrHKID ],
  // ],
  // (values) => values.vendorAddress ? null: [
  //   [ 'vendorAddress', langForm3.msgInputAddress ],
  // ],
  (values) => values.vendorPhoneNumber ?
    (checkValidPhoneNumber(values.vendorPhoneNumber) ? null
      : [['vendorPhoneNumber', langForm3.msgInputValidPhoneNumber]])
    : [
      ['vendorPhoneNumber', langForm3.msgInputPhoneNumber],
    ],
  // (values) => values.vendorFaxNumber ? null: [
  //   [ 'vendorFaxNumber', langForm3.msgInputFaxNumber ],
  // ],
  // (values) => values.vendorDate ? null: [
  //   [ 'vendorDate', langForm3.msgInputDate ],
  // ],
  (values) => determineVendorIsCompany(values) === true ?
    (values.nameOfSignatoryOfVendor && values.positionOfSignatoryOfVendor && values.businessRegistrationCertificateNumberOfVendor ?
      null : [
        ['nameOfSignatoryOfVendor', langForm3.msgIputNameOfSignatoryOfVendor],
        ['positionOfSignatoryOfVendor', langForm3.msgPositionOfSignatoryOfVendor],
        ['businessRegistrationCertificateNumberOfVendor', langForm3.msgIputBusinessRegistrationCertificateNumberOfVendor],
        ['eitherSignatoryInfoOrHKID', ''],
      ]) : null,

  //agnet info
  (values) => values.nameAndLicenceNumberOfAgent ? null : [
    ['nameAndLicenceNumberOfAgent', langForm3.msgIputNameAndLicenceNumberOfAgent],
  ],
  (values) => values.numberOfStatementOfParticularsOfBusiness ? null : [
    ['numberOfStatementOfParticularsOfBusiness', langForm3.msgIputNumberOfStatementOfParticularsOfBusiness],
  ],
  (values) => values.agentAddress ? null : [
    ['agentAddress', langForm3.msgInputAddress],
  ],
  (values) => values.agentPhoneNumber ?
    (checkValidPhoneNumber(values.agentPhoneNumber) ? null
      : [['agentPhoneNumber', langForm3.msgInputValidPhoneNumber]])
    : [
      ['agentPhoneNumber', langForm3.msgInputPhoneNumber],
    ],
  // (values) => values.agentFaxNumber ? null: [
  //   [ 'agentFaxNumber', langForm3.msgInputFaxNumber ],
  // ],
  // (values) => values.agentDate ? null: [
  //   [ 'agentDate', langForm3.msgInputDate ],
  // ],
];

const determineLandlordIsCompany = (values: Partial<Form5DTO>) => {
  if (!isNonEmpty(values.landlordHKID) &&
    !isNonEmpty(values.nameOfSignatoryOfLandlord) &&
    !isNonEmpty(values.positionOfSignatoryOfLandlord) &&
    !isNonEmpty(values.businessRegistrationCertificateNumberOfLandlord)
  ) {
    return undefined;
  } else if (isNonEmpty(values.landlordHKID)) {
    return false;
  } else if (isNonEmpty(values.nameOfSignatoryOfLandlord) || isNonEmpty(values.positionOfSignatoryOfLandlord) || isNonEmpty(values.businessRegistrationCertificateNumberOfLandlord)) {
    return true;
  }
}

export const Form5Validations = (langForm5: Options): FormValidations<Form5DTO> => [
  ...formLocaleValidations(langForm5),
  //Part 1
  (values) => values.landlordName ? null : [
    ['landlordName', langForm5.msgInputLandlordName],
  ],
  (values) => values.agentName ? null : [
    ['agentName', langForm5.msgInputAgentName],
  ],
  (values) => values.propertyAddress ? null : [
    ['propertyAddress', langForm5.msgInputPropertyAddress],
  ],
  (values) => isValidityPeriodValid(values) ? null : [
    ['isValidityPeriodValid', langForm5.msgInputValidPeriodStartAndEnd],
  ],
  (values) => values.validityPeriodStart ? null : [
    ['validityPeriodStart', langForm5.msgInputValidityPeriodStart],
  ],
  (values) => values.validityPeriodEnd ? null : [
    ['validityPeriodEnd', langForm5.msgInputvalidityPeriodEnd],
  ],

  // part 2
  (values) => values.relationship ? null : [
    ['relationship', langForm5.msgSelectRelationship],
  ],

  //part 3
  (values) => values.listRental && values.listRental >= 0 ? null : [
    ['listRental', langForm5.msgInputListRental],
  ],

  //part6
  (values) => values.allowViewing || values.passKeyForSafeCustody || values.passKeyToThirdParty ? null : [
    ['isMissingPart6', langForm5.msgSelectAtLeastOneForPart6],
  ],

  //part 9
  (values) => !(values.needDisclosure && (values.interestsInProperty == null || values.interestsInProperty == undefined || values.interestsInProperty === "")) ? null : [
    ['interestsInProperty', langForm5.msgInputInterestsInProperty],
  ],

  //part 10
  (values) => values.landlordAcknowledgementOption ? null : [
    ['landlordAcknowledgementOption', langForm5.msgSelectLandlordAcknowledgementOption],
  ],

  //schedule 2
  // (values) => values.commissionIn ==='amount' || values.commissionIn ==='percentage'? null: [
  //   [ 'commissionIn', langForm5.msgSelectCommissionIn ],
  // ],
  // (values) => !(values.commissionIn =='amount' && !!values.commissionAmount == false)? null: [
  //   [ 'commissionAmount', langForm5.msgInputCommissionAmount ],
  // ],
  // (values) => !(values.commissionIn =='percentage' && !!values.commissionRate == false)? null: [
  //   [ 'commissionRate', langForm5.msgInputCommissionRate ],
  // ],
  // (values) => values.paymentTimeOption ? null: [
  //   [ 'paymentTimeOption', langForm5.msgSelectPaymentTime ],
  // ],
  // (values) => values.withInterest ? null: [
  //   [ 'withInterest', langForm5.msgSelectWithInterest ],
  // ],

  //landlord info
  // (values) => values.landlordHKID ? null: [
  //   [ 'landlordHKID', langForm5.msgInputHKID ],
  // ],
  // (values) => !isNullOrUndefined(determineLandlordIsCompany(values)) ? null: [
  //   [ 'eitherSignatoryInfoOrHKID', langForm5.msgInputSignatoryInfoOrHKID ],
  // ],
  // (values) => values.landlordAddress ? null: [
  //   [ 'landlordAddress', langForm5.msgInputAddress ],
  // ],
  (values) => values.landlordPhoneNumber ?
    (checkValidPhoneNumber(values.landlordPhoneNumber) ? null
      : [['landlordPhoneNumber', langForm5.msgInputValidPhoneNumber]])
    : [
      ['landlordPhoneNumber', langForm5.msgInputPhoneNumber],
    ],
  // (values) => values.landlordFaxNumber ? null: [
  //   [ 'landlordFaxNumber', langForm5.msgInputFaxNumber ],
  // ],
  // (values) => values.landlordDate ? null: [
  //   [ 'landlordDate', langForm5.msgInputDate ],
  // ],
  (values) => determineLandlordIsCompany(values) === true ?
    (values.nameOfSignatoryOfLandlord && values.positionOfSignatoryOfLandlord && values.businessRegistrationCertificateNumberOfLandlord ?
      null : [
        ['nameOfSignatoryOfLandlord', langForm5.msgIputNameOfSignatoryOfLandlord],
        ['positionOfSignatoryOfLandlord', langForm5.msgPositionOfSignatoryOfLandlord],
        ['businessRegistrationCertificateNumberOfLandlord', langForm5.msgIputBusinessRegistrationCertificateNumberOfLandlord],
        ['eitherSignatoryInfoOrHKID', ''],
      ]) : null,

  //agnet info
  (values) => values.nameAndLicenceNumberOfAgent ? null : [
    ['nameAndLicenceNumberOfAgent', langForm5.msgIputNameAndLicenceNumberOfAgent],
  ],
  (values) => values.numberOfStatementOfParticularsOfBusiness ? null : [
    ['numberOfStatementOfParticularsOfBusiness', langForm5.msgIputNumberOfStatementOfParticularsOfBusiness],
  ],
  (values) => values.agentAddress ? null : [
    ['agentAddress', langForm5.msgInputAddress],
  ],
  (values) => values.agentPhoneNumber ?
    (checkValidPhoneNumber(values.agentPhoneNumber) ? null
      : [['agentPhoneNumber', langForm5.msgInputValidPhoneNumber]])
    : [
      ['agentPhoneNumber', langForm5.msgInputPhoneNumber],
    ],
  // (values) => values.agentFaxNumber ? null: [
  //   [ 'agentFaxNumber', langForm5.msgInputFaxNumber ],
  // ],
  // (values) => values.agentDate ? null: [
  //   [ 'agentDate', langForm5.msgInputDate ],
  // ],
];

const determinePurchaserIsCompany = (values: Partial<Form4DTO>) => {
  if (!isNonEmpty(values.purchaserHKID) &&
    !isNonEmpty(values.nameOfSignatoryOfPurchaser) &&
    !isNonEmpty(values.positionOfSignatoryOfPurchaser) &&
    !isNonEmpty(values.businessRegistrationCertificateNumberOfPurchaser)
  ) {
    return undefined;
  } else if (isNonEmpty(values.purchaserHKID)) {
    return false;
  } else if (isNonEmpty(values.nameOfSignatoryOfPurchaser) || isNonEmpty(values.positionOfSignatoryOfPurchaser) || isNonEmpty(values.businessRegistrationCertificateNumberOfPurchaser)) {
    return true;
  }
}

export const Form4Validations = (langForm4: Options): FormValidations<Form4DTO> => [
  ...formLocaleValidations(langForm4),
  //Part 1
  (values) => values.purchaserName ? null : [
    ['purchaserName', langForm4.msgInputPurchaserName],
  ],
  (values) => values.agentName ? null : [
    ['agentName', langForm4.msgInputAgentName],
  ],
  (values) => isValidityPeriodValid(values) ? null : [
    ['isValidityPeriodValid', langForm4.msgInputValidPeriodStartAndEnd],
  ],
  (values) => values.validityPeriodStart ? null : [
    ['validityPeriodStart', langForm4.msgInputValidityPeriodStart],
  ],
  (values) => values.validityPeriodEnd ? null : [
    ['validityPeriodEnd', langForm4.msgInputvalidityPeriodEnd],
  ],

  //part 5
  (values) => !(values.needDisclosure === true && (values.interestsInProperty == null || values.interestsInProperty == undefined || values.interestsInProperty === "")) ? null : [
    ['interestsInProperty', langForm4.msgInputInterestsInProperty],
  ],

  //purchaser info
  // (values) => !isNullOrUndefined(determinePurchaserIsCompany(values)) ? null: [
  //   [ 'eitherSignatoryInfoOrHKID', langForm4.msgInputSignatoryInfoOrHKID ],
  // ],
  // (values) =>  !(values.isCompany === false && isNullOrUndefined(values.purchaserHKID))? null: [
  //   [ 'purchaserHKID', langForm4.msgInputHKID ],
  // ],
  // (values) => values.purchaserAddress ? null: [
  //   [ 'purchaserAddress', langForm4.msgInputAddress ],
  // ],
  (values) => values.purchaserPhoneNumber ?
    (checkValidPhoneNumber(values.purchaserPhoneNumber) ? null
      : [['purchaserPhoneNumber', langForm4.msgInputValidPhoneNumber]])
    : [
      ['purchaserPhoneNumber', langForm4.msgInputPhoneNumber],
    ],
  // (values) => values.purchaserFaxNumber ? null: [
  //   [ 'purchaserFaxNumber', langForm4.msgInputFaxNumber ],
  // ],
  // (values) => values.purchaserDate ? null: [
  //   [ 'purchaserDate', langForm4.msgInputDate ],
  // ],
  (values) => determinePurchaserIsCompany(values) === true ?
    (values.nameOfSignatoryOfPurchaser && values.positionOfSignatoryOfPurchaser && values.businessRegistrationCertificateNumberOfPurchaser ?
      null : [
        ['nameOfSignatoryOfPurchaser', langForm4.msgIputNameOfSignatoryOfPurchaser],
        ['positionOfSignatoryOfPurchaser', langForm4.msgPositionOfSignatoryOfPurchaser],
        ['businessRegistrationCertificateNumberOfPurchaser', langForm4.msgIputBusinessRegistrationCertificateNumberOfPurchaser],
        ['eitherSignatoryInfoOrHKID', ''],
      ]) : null,

  //agnet info
  (values) => values.nameAndLicenceNumberOfAgent ? null : [
    ['nameAndLicenceNumberOfAgent', langForm4.msgIputNameAndLicenceNumberOfAgent],
  ],
  (values) => values.numberOfStatementOfParticularsOfBusiness ? null : [
    ['numberOfStatementOfParticularsOfBusiness', langForm4.msgIputNumberOfStatementOfParticularsOfBusiness],
  ],
  (values) => values.agentAddress ? null : [
    ['agentAddress', langForm4.msgInputAddress],
  ],
  (values) => values.agentPhoneNumber ?
    (checkValidPhoneNumber(values.agentPhoneNumber) ? null
      : [['agentPhoneNumber', langForm4.msgInputValidPhoneNumber]])
    : [
      ['agentPhoneNumber', langForm4.msgInputPhoneNumber],
    ],
  // (values) => values.agentFaxNumber ? null: [
  //   [ 'agentFaxNumber', langForm4.msgInputFaxNumber ],
  // ],
  // (values) => values.agentDate ? null: [
  //   [ 'agentDate', langForm4.msgInputDate ],
  // ],

  // //schedule 2
  // (values) => values.paymentTimeOption ? null: [
  //   [ 'paymentTimeOption', langForm4.msgSelectPaymentTime ],
  // ],

  //schedule 1
  (values) => getInvalidForm4Schedule1Record(values.formProperty).length == 0 ? null : [
    ['invalidFormProperty', getInvalidForm4Schedule1Record(values.formProperty).join('_')],
  ],
  (values) => isNonEmpty(values.formProperty) ? null : [
    ['invalidFormProperty', langForm4.msgInputSchedul1Info],
  ],
];

const getInvalidForm4Schedule1Record = (formProperty: any) => {
  const invalidProperty = formProperty?.map((c: any, idx: number) =>
    isNonEmpty(c.property) && isNonEmpty(c.agencyRelationship) && isNonEmpty(String(c.purchaserCommission).replaceAll('%', '').replaceAll('$', '')) && !isNaN(Number(String(c.purchaserCommission).replaceAll('$', '').replaceAll('%', '')))
      && (c.notToInspect === true || (c.notToInspect === false && isNonEmpty(c.dateOfInspection)))
      ? -1 : idx).filter((idx: number) => idx > -1);
  return invalidProperty ?? [];
}

export const Form4Scheduel1Validations = (): FormValidations<any> => [
  //schedule 1
  //  (values) => (values.formProperty?.filter((c:any) => 
  //     c.property?.length && c.agencyRelationship !== 'pleaseSelect' && c.purchaserCommission?.length
  //     && (c.notToInspect === true || ( c.notToInspect === false && !isNullOrUndefined(c.dateOfInspection)) )
  //   ).length ?? 0) === values.formProperty?.length ? null : [
  //   [ 'formProperty', '(INVALID_RECORD)' ],
  // ],
];

const determineTenantIsCompany = (values: Partial<Form6DTO>) => {
  if (!isNonEmpty(values.tenantHKID) &&
    !isNonEmpty(values.nameOfSignatoryOfTenant) &&
    !isNonEmpty(values.positionOfSignatoryOfTenant) &&
    !isNonEmpty(values.businessRegistrationCertificateNumberOfTenant)
  ) {
    return undefined;
  } else if (isNonEmpty(values.tenantHKID)) {
    return false;
  } else if (isNonEmpty(values.nameOfSignatoryOfTenant) || isNonEmpty(values.positionOfSignatoryOfTenant) || isNonEmpty(values.businessRegistrationCertificateNumberOfTenant)) {
    return true;
  }
}

export const Form6Validations = (langForm6: Options): FormValidations<Form6DTO> => [
  ...formLocaleValidations(langForm6),
  //Part 1
  (values) => values.tenantName ? null : [
    ['tenantName', langForm6.msgInputTenantName],
  ],
  (values) => values.agentName ? null : [
    ['agentName', langForm6.msgInputAgentName],
  ],
  (values) => isValidityPeriodValid(values) ? null : [
    ['isValidityPeriodValid', langForm6.msgInputValidPeriodStartAndEnd],
  ],
  (values) => values.validityPeriodStart ? null : [
    ['validityPeriodStart', langForm6.msgInputValidityPeriodStart],
  ],
  (values) => values.validityPeriodEnd ? null : [
    ['validityPeriodEnd', langForm6.msgInputvalidityPeriodEnd],
  ],

  //part 5
  (values) => !(values.needDisclosure === true && (values.interestsInProperty == null || values.interestsInProperty == undefined || values.interestsInProperty === "")) ? null : [
    ['interestsInProperty', langForm6.msgInputInterestsInProperty],
  ],

  //tenant info
  //purchaser info
  // (values) => !isNullOrUndefined(determineTenantIsCompany(values)) ? null: [
  //   [ 'eitherSignatoryInfoOrHKID', langForm6.msgInputSignatoryInfoOrHKID ],
  // ],
  // (values) => values.tenantHKID ? null: [
  //   [ 'tenantHKID', langForm6.msgInputHKID ],
  // ],
  // (values) => values.tenantAddress ? null: [
  //   [ 'tenantAddress', langForm6.msgInputAddress ],
  // ],
  (values) => values.tenantPhoneNumber ?
    (checkValidPhoneNumber(values.tenantPhoneNumber) ? null
      : [['tenantPhoneNumber', langForm6.msgInputValidPhoneNumber]])
    : [
      ['tenantPhoneNumber', langForm6.msgInputPhoneNumber],
    ],
  // (values) => values.tenantFaxNumber ? null: [
  //   [ 'tenantFaxNumber', langForm6.msgInputFaxNumber ],
  // ],
  // (values) => values.tenantDate ? null: [
  //   [ 'tenantDate', langForm6.msgInputDate ],
  // ],
  (values) => determineTenantIsCompany(values) === true ?
    (values.nameOfSignatoryOfTenant && values.positionOfSignatoryOfTenant && values.businessRegistrationCertificateNumberOfTenant ?
      null : [
        ['nameOfSignatoryOfTenant', langForm6.msgIputNameOfSignatoryOfTenant],
        ['positionOfSignatoryOfTenant', langForm6.msgPositionOfSignatoryOfTenant],
        ['businessRegistrationCertificateNumberOfTenant', langForm6.msgIputBusinessRegistrationCertificateNumberOfTenant],
        ['eitherSignatoryInfoOrHKID', ''],
      ]) : null,

  //agnet info
  (values) => values.nameAndLicenceNumberOfAgent ? null : [
    ['nameAndLicenceNumberOfAgent', langForm6.msgIputNameAndLicenceNumberOfAgent],
  ],
  (values) => values.numberOfStatementOfParticularsOfBusiness ? null : [
    ['numberOfStatementOfParticularsOfBusiness', langForm6.msgIputNumberOfStatementOfParticularsOfBusiness],
  ],
  (values) => values.agentAddress ? null : [
    ['agentAddress', langForm6.msgInputAddress],
  ],
  (values) => values.agentPhoneNumber ?
    (checkValidPhoneNumber(values.agentPhoneNumber) ? null
      : [['agentPhoneNumber', langForm6.msgInputValidPhoneNumber]])
    : [
      ['agentPhoneNumber', langForm6.msgInputPhoneNumber],
    ],
  // (values) => values.agentFaxNumber ? null: [
  //   [ 'agentFaxNumber', langForm6.msgInputFaxNumber ],
  // ],
  // (values) => values.agentDate ? null: [
  //   [ 'agentDate', langForm6.msgInputDate ],
  // ],

  // //schedule 2
  // (values) => values.paymentTimeOption ? null: [
  //   [ 'paymentTimeOption', langForm6.msgSelectPaymentTime ],
  // ],

  //schedule 1
  (values) => getInvalidForm6Schedule1Record(values.formProperty).length == 0 ? null : [
    ['invalidFormProperty', getInvalidForm6Schedule1Record(values.formProperty).join('_')],
  ],
  (values) => isNonEmpty(values.formProperty) ? null : [
    ['invalidFormProperty', langForm6.msgInputSchedul1Info],
  ],
];

const getInvalidForm6Schedule1Record = (formProperty: any) => {
  const invalidProperty = formProperty?.map((c: any, idx: number) =>
    isNonEmpty(c.property) && isNonEmpty(c.agencyRelationship) && isNonEmpty(String(c.tenantCommission).replaceAll('$', '').replaceAll('%', '')) && !isNaN(Number(String(c.tenantCommission).replaceAll('$', '').replaceAll('%', '')))
      && (c.notToInspect === true || (c.notToInspect === false && isNonEmpty(c.dateOfInspection)))
      ? -1 : idx).filter((idx: number) => idx > -1);
  return invalidProperty ?? [];
}


export const Form6Schedule1Validations = (): FormValidations<any> => [
  //schedule 1
  // (values) => (values.formProperty?.filter((c:any) => 
  //     c.property?.length && c.agencyRelationship !== 'pleaseSelect' && c.tenantCommission?.length
  //     && (c.notToInspect === true || ( c.notToInspect === false && !isNullOrUndefined(c.dateOfInspection)) )
  //   ).length ?? 0) === values.formProperty?.length ? null : [
  //   [ 'formProperty', '(INVALID_RECORD)' ],
  // ],
];
