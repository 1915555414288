import { Button, ButtonGroup, Card, CardContent, Checkbox, FormControl, FormHelperText, Grid, ListItemText, makeStyles, MenuItem, Popover, Select, TextField, Tooltip, Typography, useMediaQuery } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import fileApi from 'api/fileApi';
import { hasPermission } from 'common/access-control';
import { useConfirmDialog } from 'common/ConfirmDialog';
import { CAFeatureTagDTO, CAFeatureTagPropertyFeatureValueMap } from 'common/dto';
import { useForm } from 'common/hooks';
import { useInfoDialog } from 'common/InfoDialog';
import { HandleFormHelperText } from 'common/ui';
import { diffUpdateSpec, initFormKeysAndBooleanMap, isNonEmpty, resetFileInput } from 'common/utils';
import deepEqual from 'deep-equal';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Lightbox from 'react-image-lightbox';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState, PASDispatch } from 'reducers';
import FeatureTagMgtToolbar from './components/FeatureTagMgtToolbar';

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(2)
	},
	content: {
		marginTop: theme.spacing(2)
	},
	search: {
		marginBottom: theme.spacing(2)
	},
	dragListStyle: {
		background: '#F0F0F0',
		padding: 8,
	},
	dragItemStyle: {
		paddingLeft: 8,
		margin: `0 0 8px 0`,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		minHeight: '38px',
		// change background colour if dragging
		background: '#FFFFFF'
	},
	featureTagContainer: {
		padding: '10px'
	},
	tagNamesContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	buttonsContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	coverPhotoContainer: {
		cursor: 'pointer',
		border: `1px solid ${grayColor}`,
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		maxHeight: '160px',
		minHeight: '80px',

	},
	photoPopover: {
		padding: theme.spacing(1),
	},
}));

const grayColor = '#a8acb3';
const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];

const FeatureTagList = () => {
	const classes = useStyles(0);
	const { lang, langUser, langFeatureTagMgt, langPropertyStock, decoOptions, otherFeatureOptions, viewOptions, otherOptions } = useSelector((state: IRootState) => state.locale);
	const dispatch = useDispatch() as PASDispatch;
	const featureTagList = useSelector((state: IRootState) => state.featureTagList.currentList) ?? [];

	const { privileges } = useSelector((state: IRootState) => state.login);
	const canSave = hasPermission(privileges, 'UPDATE', 'CLIENT_APP_FEATURED_TAGS');

	const shouldCollapse = useMediaQuery('(max-width: 600px)');

	const [initialFormValues, setInitialFormValues] = useState<any>({});

	// const form = useForm<any>({
	// 	propertyStockColumnPreference: {
	// 		columnsOrder: ["id","dateCreated" ,"dateModified" ,"status"],
	// 		disabledColumns: []
	// 	}
	// });

	const checkHasTagName = (tag: CAFeatureTagDTO) => {
		return (isNonEmpty(tag.tagNameEN) || isNonEmpty(tag.tagNameTC) || isNonEmpty(tag.tagNameSC));
	}

	const checkHasPropertyFeature = (tag: CAFeatureTagDTO) => {
		return (tag.tagForm['VIEW']?.length ?? 0) > 0 || (tag.tagForm['DECO']?.length ?? 0) > 0 || (tag.tagForm['OTHER_FEATURES']?.length ?? 0) > 0 || (tag.tagForm['OTHERS']?.length ?? 0) > 0;
	}

	const form = useForm<any>({
		currentList: [],
	}, {
		validations: [
			(values) => (values.currentList?.filter((tag: CAFeatureTagDTO) => checkHasTagName(tag)
			)?.length ?? 0) === (values.currentList?.length ?? 0) ? null : [['missingTagName', langFeatureTagMgt.msgInputAtLeaseOneTagName]],
			(values) => (values.currentList?.filter((tag: CAFeatureTagDTO) => checkHasPropertyFeature(tag)
			)?.length ?? 0) === (values.currentList?.length ?? 0) ? null : [['missingPropertyFeature', langFeatureTagMgt.msgInputAtLeaseOnePropertyFeature]],
		],
	});


	const tagForm = useForm<CAFeatureTagPropertyFeatureValueMap>({
		VIEW: [],
		DECO: [],
		OTHER_FEATURES: [],
		OTHERS: [],
	});

	//---- handle add, save, delete
	//add 
	const [addCount, setAddCount] = useState<number>(0);
	const handleAdd = () => {
		setAddCount(addCount + 1);
		const newFeatureTag = {
			draggableId: `new-tag-${addCount}`,
			tagForm: {
				VIEW: [],
				DECO: [],
				OTHER_FEATURES: [],
				OTHERS: [],
			}
		} as Partial<CAFeatureTagDTO>;
		form.updateValues('currentList', [newFeatureTag, ...form.values.currentList]);
	}

	//delete
	const confirmRemoveDialog = useConfirmDialog();
	const infoDialog = useInfoDialog();

	const handleRemoveFeatureTag = (index: number) => () => {
		form.updateValues('currentList', form.values.currentList?.filter((_: any, _i: number) => index !== _i));
	}

	//save 
	const handleSave = () => {
		if (!form.validate()) {
			return;
		}
		//check visible tag count
		if (visibleTagCount > 4) {
			infoDialog.confirm(langFeatureTagMgt.msgWarnExceedVisibleTagsLimit, lang.actionCancel, lang.actionCancel).then((confirmed) => {
				if (confirmed) {
					return;
				}
			})
		} else {

			// do save
			const currentList = featureTagList as CAFeatureTagDTO[];
			const updatedList = form.values?.currentList?.map((tag: CAFeatureTagDTO, idx: number) => {
				return {
					...tag,
					order: idx + 1,
					features: Object.keys(tag.tagForm)
						.map(type => tag.tagForm[type]?.map(value => ({ type, value })))
						.reduce((a, b) => (a ?? []).concat(b ?? []), []),
				}
			}) ?? [];

			const result = diffUpdateSpec(currentList, updatedList, (a, b) => { return a.id === b.id });

			dispatch({ type: 'FeatureTagList.UpdateRequested', payload: result })
		}
	}


	//---- preprocess multiSelect value when initial load

	useEffect(() => {
		dispatch({ type: 'FeatureTagList.FetchRequested' });
	}, [])

	useEffect(() => {
		const formValues = {
			currentList: featureTagList.map((item: CAFeatureTagDTO) => {
				const featureMap: { [type: string]: string[] } = {};
				item.features?.forEach((f: any) => {
					if (!featureMap[f.type]) {
						featureMap[f.type] = [];
					}
					featureMap[f.type].push(f.value);
				});

				const initialValues = {
					VIEW: [],
					DECO: [],
					OTHER_FEATURES: [],
					OTHERS: [],
				}

				const tagFormValues = {
					...initialValues,
					...featureMap,
				}

				return { ...item, tagForm: tagFormValues };
			})
		};
		form.setValues(formValues);
		setInitialFormValues([formValues]);
	}, [featureTagList])

	const unsaved = !deepEqual([form.values], initialFormValues, { strict: true });

	const [keyBooleanMap, setKeyBooleanMap] = useState(initFormKeysAndBooleanMap(form));

	useEffect(() => {
		setKeyBooleanMap(initFormKeysAndBooleanMap(form));
	}, [Object.keys(form.values).length])

	//------ multiSelectField
	type Options = { [key: string]: string };
	const multiSelectField = (key: 'VIEW' | 'DECO' | 'OTHER_FEATURES' | 'OTHERS', label: string, options: Options, index: number) => {

		return <React.Fragment>
			<Typography>{label}</Typography>
			<FormControl variant="outlined" margin="dense" fullWidth>
				<Select
					multiple
					// {...tagForm.bind(key)}
					value={form.values?.currentList?.[index]?.tagForm[key] ?? []}
					onChange={(e) => {
						if (!canSave) {
							return;
						}
						tagForm.updateValues(key, e.target.value as string[]);
						const item = form.values?.currentList?.[index];
						const updatedItem = {
							...item,
							tagForm: { ...item.tagForm, [key]: e.target.value as string[] },
						};
						form.setValues({
							...form.values,
							currentList: form.values.currentList?.map((c: CAFeatureTagDTO, idx: number) => idx === index ? updatedItem : c),
						})
					}}
					renderValue={(selected) => (selected as string[]).map(key => options?.[key] ?? key).join(', ')}
				>
					{Object.keys(options ?? {}).map(val => (
						<MenuItem key={val} value={val}>
							<Checkbox checked={form.values?.currentList?.[index]?.tagForm[key]!.indexOf(val) > -1} />
							<ListItemText primary={options[val]} />
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</React.Fragment>
	};

	//---- upload photo
	const handleUploadPhoto = (ev: React.ChangeEvent<HTMLInputElement>) => {
		let files = Array.from(ev.target.files ?? []);
		const currentLength = isNonEmpty(form.values?.currentList?.[indexOfPhoto]?.coverPhotoFilename) ? 1 : 0;
		const limit = 1;
		let remainLength = limit ?? 0 - currentLength > 0 ? limit ?? 0 - currentLength : 0;
		if (remainLength === 0) return;

		Promise.all(files.slice(0, remainLength).map(file => {
			return fileApi.addFile(file).then(result => result.data as string)
		})).then(paths => {
			const updatedItem = { ...form.values?.currentList?.[indexOfPhoto], coverPhotoFilename: paths![0] };
			form.setValues({
				...form.values,
				currentList: form.values.currentList?.map((c: CAFeatureTagDTO, idx: number) => idx === indexOfPhoto ? updatedItem : c),
			})
		});
	}

	//---- photo popover
	const [photoLightBoxOpened, setPhotoLightBoxOpened] = useState(false);
	const [indexOfPhoto, setIndexOfPhoto] = useState<number>(0);
	const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

	const handleClickPhoto = (event: React.MouseEvent<HTMLDivElement>, index: number) => {
		setAnchorEl(event.currentTarget);
		setIndexOfPhoto(index);
	};

	const handleClosePhotoPopover = () => {
		setAnchorEl(null);
	};

	const openPhotoPopover = Boolean(anchorEl);
	const photoPopoverId = openPhotoPopover ? 'simple-popover' : undefined;


	const photoPopover = () => (
		<Popover
			id={photoPopoverId}
			open={openPhotoPopover}
			anchorEl={anchorEl}
			onClose={handleClosePhotoPopover}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
		>
			<div className={classes.photoPopover}>
				<ButtonGroup size="small" color="primary" aria-label="photo button group">
					{isNonEmpty(form.values?.currentList?.[indexOfPhoto]?.coverPhotoFilename) &&
						<Button onClick={() => {
							setPhotoLightBoxOpened(true)
						}}>{lang.actionView}</Button>}
					{canSave &&
						<Button onClick={() => {
							document.getElementById("UPLOAD_COVER_PHOTO")?.click();
						}}>{lang.actionUpload}</Button>}
					{canSave && isNonEmpty(form.values?.currentList?.[indexOfPhoto]?.coverPhotoFilename) &&
						<Button onClick={() => {
							const updatedItem = { ...form.values?.currentList?.[indexOfPhoto], coverPhotoFilename: undefined };
							form.setValues({
								...form.values,
								currentList: form.values.currentList?.map((c: CAFeatureTagDTO, idx: number) => idx === indexOfPhoto ? updatedItem : c),
							})
						}}>{lang.actionDelete}</Button>}
				</ButtonGroup>

			</div>
		</Popover>
	)

	const visibleTagCount = form.values.currentList?.filter((c: CAFeatureTagDTO) => c.isVisible === true)?.length ?? 0;

	return (<div className={classes.root}>
		<FeatureTagMgtToolbar canSave={canSave} onSave={handleSave} unsaved={unsaved} />
		<div className={classes.content}>
			<Card>
				{confirmRemoveDialog.render()}
				{infoDialog.render()}
				{photoPopover()}
				<input
					style={{ display: 'none' }}
					id={'UPLOAD_COVER_PHOTO'}
					type="file"
					onChange={(ev) => {
						handleUploadPhoto(ev);
						resetFileInput(ev);
					}}
				/>
				{photoLightBoxOpened &&
					<Lightbox
						reactModalStyle={{ overlay: { zIndex: 1500 } }}
						mainSrc={`${BASE_URL}/files/${form.values?.currentList?.[indexOfPhoto]?.coverPhotoFilename}`}
						onCloseRequest={() => setPhotoLightBoxOpened(false)}

					/>
				}
				<CardContent>
					<Grid container spacing={1}>
						<Grid item md={12} xs={12} style={{ display: canSave ? 'block' : 'none' }}>
							<Tooltip title={lang.actionAdd}>
								<IconButton onClick={() => {
									if (!canSave) {
										return;
									}
									handleAdd();
								}} color="primary">
									<AddIcon />
								</IconButton>
							</Tooltip>
						</Grid>
						<Grid item md={12} xs={12}>
							<Typography variant="caption" style={{ fontStyle: 'italic', display: canSave ? 'block' : 'none' }}>
								{langUser.columnPreferenceHelperText}
							</Typography>
						</Grid>
						<Grid item md={8} xs={12}>
							<DragDropContext onDragEnd={(result) => {
								if (!result.destination) {
									return;
								}

								const i1 = result.source.index, i = result.destination?.index ?? 0;
								const oldList = [...form.values.currentList];
								let newList = [];
								if (i > i1) { //move downward
									newList.push(...oldList.slice(0, i1)); // items above the selected item's original position
									newList.push(...oldList.slice(i1 + 1, i + 1)); //items between the original position and the destination
									newList.push(oldList[i1]); // selected item
									newList.push(...oldList.slice(i + 1, oldList.length)); //items below the destination
								} else if (i < i1) { //move upward
									newList.push(...oldList.slice(0, i)); // items above the selected item's destination
									newList.push(oldList[i1]); //selected item
									newList.push(...oldList.slice(i, i1)); //items between the destination and the original position
									newList.push(...oldList.slice(i1 + 1, oldList.length)); // items below the original position
								} else {
									newList = oldList;
								}
								// form.updateValues('propertyStockColumnPreference', newList);
								form.setValues({
									currentList: newList
								});
							}}>
								<Droppable droppableId="container" direction="vertical">
									{(provided) => (
										<div
											{...provided.droppableProps}
											ref={provided.innerRef}
											className={classes.dragListStyle}
										>
											{form?.values?.currentList?.map((item: CAFeatureTagDTO, index: number) => (
												<Draggable key={isNonEmpty(item.id) ? item.id?.toString() : item.draggableId}
													draggableId={isNonEmpty(item.id) ? item.id?.toString() : item.draggableId}
													index={index}
													isDragDisabled={!canSave}
												>
													{(provided) => (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															className={classes.dragItemStyle}
														>
															<Grid item container md={12} sm={12} xs={12} spacing={1}
																className={classes.featureTagContainer}
															>
																<Grid item container md={1} sm={1} xs={12}>
																	{shouldCollapse ?
																		<Grid item container xs={12}>
																			<Grid item container xs={6}>
																				<IconButton style={{ cursor: 'move' }}>
																					<OpenWithIcon />
																				</IconButton>
																			</Grid>
																			<Grid item container xs={6} style={{ justifyContent: 'flex-end' }}>
																				<Switch
																					checked={item.isVisible ?? false}
																					onChange={() => {
																						if (!canSave) {
																							return;
																						}
																						const updatedItem = { ...item, isVisible: !item.isVisible };

																						if (updatedItem.isVisible && visibleTagCount >= 4) {
																							infoDialog.confirm(langFeatureTagMgt.msgWarnExceedVisibleTagsLimit, lang.actionCancel, lang.actionCancel).then((confirmed) => {
																								if (confirmed) {
																									return;
																								}
																							})
																						} else {

																							form.setValues({
																								...form.values,
																								currentList: form.values.currentList?.map((c: CAFeatureTagDTO, idx: number) => idx === index ? updatedItem : c),
																							})
																						}

																					}}
																					color="primary"
																					name="tag-isVisible"
																					inputProps={{ 'aria-label': 'set visible switch' }}
																				/>
																				<Tooltip title={lang.actionDelete}>
																					<IconButton onClick={() => {
																						if (!canSave) {
																							return;
																						}
																						confirmRemoveDialog.confirm(langFeatureTagMgt.msgConfirmRemoveTag, lang.actionConfirm, lang.actionCancel).then((confirmed) => {
																							if (confirmed) {
																								handleRemoveFeatureTag(index)();
																							}
																						})
																					}}>
																						<DeleteIcon />
																					</IconButton>
																				</Tooltip>
																			</Grid>
																		</Grid>
																		:
																		<IconButton style={{ cursor: 'move' }}>
																			<OpenWithIcon />
																		</IconButton>
																	}
																</Grid>

																<Grid item container md={11} sm={11} xs={12} spacing={1}>

																	<Grid item container md={12} sm={12} xs={12} spacing={1}>

																		{/* --------- cover photo --------*/}
																		<Grid item md={6} sm={6} xs={12}
																		// style={{width: '50%'}}
																		>
																			<div className={classes.coverPhotoContainer}
																				style={{
																					backgroundColor: grayColor,
																					textAlign: isNonEmpty(item.coverPhotoFilename) ? 'inherit' : 'center',
																				}}
																				onClick={(e) => {
																					if (!canSave && !isNonEmpty(item.coverPhotoFilename)) {
																						return;
																					}
																					handleClickPhoto(e, index);
																				}}
																			>
																				{isNonEmpty(item.coverPhotoFilename) ?
																					<img
																						width={'100%'}
																						height={'auto'}
																						style={{ maxHeight: '100%', objectFit: 'cover', zIndex: 100 }}
																						// src={`https://i.ytimg.com/vi/yaqe1qesQ8c/maxresdefault.jpg?_=${0}`}
																						src={isNonEmpty(item.coverPhotoFilename) ? `${BASE_URL}/files/${item.coverPhotoFilename}` : ''}
																						onClick={() => { return; }}
																					/>
																					:
																					<Typography>{langFeatureTagMgt.captionCoverPhoto}</Typography>
																				}
																			</div>
																		</Grid>
																		{/* --------- tagName container --------*/}
																		<Grid item md={5} sm={5} xs={12}
																			className={classes.tagNamesContainer}
																		>
																			{!!form.hasError('missingTagName') && !checkHasTagName(item) &&
																				<Grid item md={12} sm={12} xs={12} style={{ paddingBottom: '5px' }}>
																					<FormHelperText error>{form.hasError('missingTagName')}</FormHelperText>
																				</Grid>
																			}
																			<Grid item container spacing={2}>
																				{/* --------- tagNameEN --------*/}
																				<Grid item md={12} sm={12} xs={12}>
																					<TextField
																						fullWidth
																						margin="dense"
																						variant="outlined"
																						label={langFeatureTagMgt.captionTagNameEN}
																						inputProps={{ readOnly: !canSave }}
																						value={item.tagNameEN}
																						onChange={(e) => {
																							//console.log("changed", keyBooleanMap);
																							if (e.target.value.length > 255) {
																								e.preventDefault();
																								setKeyBooleanMap(keyBooleanMap.set('tagNameEN' + index, true));
																								const updatedItem = { ...item, tagNameEN: e.target.value.substring(0, 255) };
																								form.updateValues('currentList',
																									form.values.currentList?.map((a: any, i: number) => index === i ? updatedItem : a)
																								);
																							}
																							else {
																								setKeyBooleanMap(keyBooleanMap.set('tagNameEN' + index, false))
																								const updatedItem = { ...item, tagNameEN: e.target.value };
																								form.updateValues('currentList',
																									form.values.currentList?.map((c: any, i: number) => index === i ? updatedItem : c)
																								);
																							}

																							// const updatedItem = { ...item, tagNameEN: e.target.value };
																							// form.setValues({
																							// 	...form.values,
																							// 	currentList: form.values.currentList?.map((c: CAFeatureTagDTO, idx: number) => idx === index ? updatedItem : c),
																							// })
																						}}
																					/>
																					<HandleFormHelperText
																						isError={keyBooleanMap.get('tagNameEN' + index)}
																						errorMessage={lang.textOverLimit}
																					/>
																				</Grid>
																				{/* --------- tagNameTC --------*/}
																				<Grid item md={12} sm={12} xs={12}>
																					<TextField
																						fullWidth
																						margin="dense"
																						variant="outlined"
																						label={langFeatureTagMgt.captionTagNameTC}
																						inputProps={{ readOnly: !canSave }}
																						value={item.tagNameTC}
																						onChange={(e) => {

																							if (e.target.value.length > 255) {
																								e.preventDefault();
																								setKeyBooleanMap(keyBooleanMap.set('tagNameTC' + index, true));
																								const updatedItem = { ...item, tagNameTC: e.target.value.substring(0, 255) };
																								form.updateValues('currentList',
																									form.values.currentList?.map((a: CAFeatureTagDTO, i: number) => index === i ? updatedItem : a)
																								);
																							}
																							else {
																								setKeyBooleanMap(keyBooleanMap.set('tagNameTC' + index, false))
																								const updatedItem = { ...item, tagNameTC: e.target.value };
																								form.updateValues('currentList',
																									form.values.currentList?.map((c: CAFeatureTagDTO, i: number) => index === i ? updatedItem : c)
																								);
																							}
																							// const updatedItem = { ...item, tagNameTC: e.target.value };
																							// form.setValues({
																							// 	...form.values,
																							// 	currentList: form.values.currentList?.map((c: CAFeatureTagDTO, idx: number) => idx === index ? updatedItem : c),
																							// })
																						}}
																					/>
																					<HandleFormHelperText
																						isError={keyBooleanMap.get('tagNameTC' + index)}
																						errorMessage={lang.textOverLimit}
																					/>
																				</Grid>
																				{/* --------- tagNameSC --------*/}
																				<Grid item md={12} sm={12} xs={12}>
																					<TextField
																						fullWidth
																						margin="dense"
																						variant="outlined"
																						label={langFeatureTagMgt.captionTagNameSC}
																						inputProps={{ readOnly: !canSave }}
																						value={item.tagNameSC}
																						onChange={(e) => {
																							if (e.target.value.length > 255) {
																								e.preventDefault();
																								setKeyBooleanMap(keyBooleanMap.set('tagNameSC' + index, true));
																								const updatedItem = { ...item, tagNameSC: e.target.value.substring(0, 255) };
																								form.updateValues('currentList',
																									form.values.currentList?.map((a: CAFeatureTagDTO, i: number) => index === i ? updatedItem : a)
																								);
																							}
																							else {
																								setKeyBooleanMap(keyBooleanMap.set('tagNameSC' + index, false))
																								const updatedItem = { ...item, tagNameSC: e.target.value };
																								form.updateValues('currentList',
																									form.values.currentList?.map((c: CAFeatureTagDTO, i: number) => index === i ? updatedItem : c)
																								);
																							}
																							// const updatedItem = { ...item, tagNameSC: e.target.value };
																							// form.setValues({
																							// 	...form.values,
																							// 	currentList: form.values.currentList?.map((c: CAFeatureTagDTO, idx: number) => idx === index ? updatedItem : c),
																							// })
																						}}
																					/>
																					<HandleFormHelperText
																						isError={keyBooleanMap.get('tagNameSC' + index)}
																						errorMessage={lang.textOverLimit}
																					/>
																				</Grid>
																			</Grid>
																		</Grid>
																		{/* --------- end: tagName container --------*/}

																		{/* --------- set visible and delete --------*/}
																		{!shouldCollapse &&
																			<Grid item md={1} sm={1} xs={12} container
																				className={classes.buttonsContainer}
																			>
																				<Grid item container spacing={2}>
																					<Grid item md={12} sm={12} xs={12} >
																						<Switch
																							checked={item.isVisible ?? false}
																							onChange={() => {
																								if (!canSave) {
																									return;
																								}
																								const updatedItem = { ...item, isVisible: !item.isVisible };

																								if (updatedItem.isVisible && visibleTagCount >= 4) {
																									infoDialog.confirm(langFeatureTagMgt.msgWarnExceedVisibleTagsLimit, lang.actionCancel, lang.actionCancel).then((confirmed) => {
																										if (confirmed) {
																											return;
																										}
																									})
																								} else {
																									form.setValues({
																										...form.values,
																										currentList: form.values.currentList?.map((c: CAFeatureTagDTO, idx: number) => idx === index ? updatedItem : c),
																									})
																								}
																							}}
																							color="primary"
																							name="tag-isVisible"
																							inputProps={{ 'aria-label': 'set visible switch' }}
																						/>
																					</Grid>
																					<Grid item md={12} sm={12} xs={12}
																						style={{ justifyContent: 'center', display: 'flex' }} >
																						<Tooltip title={lang.actionDelete}>
																							<IconButton onClick={() => {
																								if (!canSave) {
																									return;
																								}
																								confirmRemoveDialog.confirm(langFeatureTagMgt.msgConfirmRemoveTag, lang.actionConfirm, lang.actionCancel).then((confirmed) => {
																									if (confirmed) {
																										handleRemoveFeatureTag(index)();
																									}
																								})
																							}}>
																								<DeleteIcon />
																							</IconButton>
																						</Tooltip>
																					</Grid>
																				</Grid>
																			</Grid>
																		}
																		{/* --------- end: set visible and delete --------*/}

																	</Grid>


																	<Grid item container md={12} sm={12} xs={12} spacing={1}>
																		{!!form.hasError('missingPropertyFeature') && !checkHasPropertyFeature(item) &&
																			<Grid item md={12} sm={12} xs={12}>
																				<FormHelperText error>{form.hasError('missingPropertyFeature')}</FormHelperText>
																			</Grid>
																		}
																		<Grid item container md={3} sm={3} xs={6}>
																			{multiSelectField('VIEW', langPropertyStock.view, viewOptions, index)}
																		</Grid>
																		<Grid item container md={3} sm={3} xs={6}>
																			{multiSelectField('OTHER_FEATURES', langPropertyStock.otherFeature, otherFeatureOptions, index)}
																		</Grid>
																		<Grid item container md={3} sm={3} xs={6}>
																			{multiSelectField('DECO', langPropertyStock.deco, decoOptions, index)}
																		</Grid>
																		<Grid item container md={3} sm={3} xs={6}>
																			{multiSelectField('OTHERS', langPropertyStock.others, otherOptions, index)}
																		</Grid>

																	</Grid>


																</Grid>

															</Grid>

														</div>
													)}
												</Draggable>
											))}
											{provided.placeholder}
										</div>
									)}
								</Droppable>
							</DragDropContext>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</div>
	</div>
	);
};

export default FeatureTagList;