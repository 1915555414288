import { makeStyles, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { userApi } from 'api';
import { hasPermission } from 'common/access-control';
import { UserListItemDTO } from 'common/dto';
import { useForm } from 'common/hooks';
import { isNonEmpty, multiLang } from 'common/utils';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState, PASDispatch } from 'reducers';
import { isNullOrUndefined } from 'util';

const useStyles = makeStyles(() => ({
  root: {

  },
  patchOutlineAutocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
      paddingLeft: 8,
    }
  },
  dialogContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 350,
  },
  textArea: {
    width: '90%'
  }
}));

export interface AssignAgentDialogProps {
  cid?: string;
  originalAgentId?: string;
  open: boolean;
  handleClose: () => any;
}

const AssignAgentDialog = ({ cid, originalAgentId, open, handleClose }: AssignAgentDialogProps) => {
  const dispatch = useDispatch() as PASDispatch;
  const classes = useStyles();
  const { langClientDetail, lang, locale } = useSelector((state: IRootState) => state.locale);
  const { privileges } = useSelector((state: IRootState) => state.login);
  const [newAgentId, setNewAgentId] = useState<string | undefined>(undefined);
  const [newAgentNameEn, setNewAgentNameEn] = useState<string | undefined>(undefined);
  const [newAgentNameZh, setNewAgentNameZh] = useState<string | undefined>(undefined);

  //validation
  const form = useForm<any>({}, {
    validations: [
      (values) => !values.newAgentId ? [['newAgentId', ' ']] : null,
    ],
  });

  useEffect(() => {
    form.setValues({ newAgentId: newAgentId });
  }, [newAgentId]);

  //dialog event
  const actionName = isNullOrUndefined(originalAgentId) ? langClientDetail.actionAssignAgent : langClientDetail.actionReassignAgent;

  // const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    // setOpen(true);
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const shouldNotRedirect = hasPermission(privileges, 'READ', 'UNOWNED_CLIENT');
  const handleConfirm = () => {
    if (form.validate()) {
      dispatch({ type: 'Client.AssignAgentRequested', payload: { cid: cid ?? '', agentId: newAgentId ?? '', shouldNotRedirect: shouldNotRedirect } });
      handleClose();
    }

  }

  //autcomplete
  const [agents, setAgents] = useState<UserListItemDTO[]>([]);
  const [agentListLoading, setAgentListLoading] = useState(false);

  const token: string = useSelector((state: IRootState) => state.login.token ?? '');

  const fetchAgents = (keyword: string) => {
    if (keyword.length < 2) { return; }
    setAgentListLoading(true);
    userApi.getListForAutocomplete({ name: keyword, onlyHasCreateOwnClientPrivilege: true }, token).then(result => {
      if (result.data?.content) {
        setAgents(result.data!.content);
        setAgentListLoading(false);
        setAgentUsername(result.data!.content.filter(agent => agent.username != 'companyStockAgent').map((agent) => agent.username));
      }
    });
  }

  const [agentUsername, setAgentUsername] = useState(agents.filter(agent => agent.username != 'companyStockAgent').map((agent) => agent.username));
  const agentMap: { [name: string]: UserListItemDTO } = {};
  agents.forEach((agent) => {
    agentMap[agent.username] = agent;
  })

  const [keywordOfAgent, setKeywordOfAgent] = useState('')

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchAgents(keywordOfAgent);
    }, 1000);
    return () => clearTimeout(timer);
  }, [keywordOfAgent]);

  return (
    <div>
      {/* <Button size="small" variant="outlined" color="primary" onClick={handleClickOpen}>
        {actionName}
      </Button> */}
      <Dialog open={open} onClose={handleClose} aria-labelledby="assign-agent-dialog">
        <DialogTitle id="assign-agent-dialog-title">{actionName}</DialogTitle>
        <DialogContent className={classes.dialogContent}>

          <Autocomplete
            style={{ width: '100%' }}
            options={agentUsername}
            noOptionsText={''}
            loading={agentListLoading}
            popupIcon={''}
            loadingText={lang.msgLoading}
            filterOptions={(option) => option}
            renderOption={(option) => <div>
              <div><Typography >{`${agents.filter(agent => agent.username === option)?.[0]?.englishName ?? ''}${agents.filter(agent => agent.username === option)?.[0]?.chineseName ? '/' : ''}${agents.filter(agent => agent.username === option)?.[0]?.chineseName ?? ''}`}</Typography></div>
              <div><Typography variant="caption">{agents.filter(agent => agent.username === option)?.[0]?.mainContact}</Typography></div>
            </div>}
            renderInput={(params: any) => (
              <TextField
                {...params}
                className={classes.patchOutlineAutocomplete}
                error={!!form.hasError('newAgentId')}
                // helperText={form.hasError('building')}
                fullWidth
                label={langClientDetail.contactName}
                margin="dense"
                required
                variant="outlined"
                onFocus={(ev) => {
                  setAgentUsername([]);
                  setKeywordOfAgent(ev.target.value);
                }}
                onChange={(ev) => {
                  // fetchAgents(ev.target.value)
                  setAgents([]);
                  setAgentUsername([]);
                  setKeywordOfAgent(ev.target.value);
                  setAgentListLoading(true);
                  if (ev.target.value.length < 2 || !isNonEmpty(ev.target.value)) {
                    setAgentListLoading(false);
                  }
                }}
              // value={form.values.building ?? ''}
              // {...bind('building')}
              />
            )}
            // {...bind('building')}
            value={multiLang(locale, newAgentNameZh, newAgentNameEn)}
            // inputValue={form.values.building ?? ''}
            onChange={(_: any, val: any) => {
              if (agentMap[val]) {
                const { id, chineseName, englishName } = agentMap[val];

                setNewAgentId(id);
                setNewAgentNameEn(englishName);
                setNewAgentNameZh(chineseName);

              } else {
                setNewAgentId(undefined);
                setNewAgentNameEn(undefined);
                setNewAgentNameZh(undefined);
              }
            }}
            getOptionLabel={() => multiLang(locale, newAgentNameZh, newAgentNameEn) ?? ''}
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {lang.actionCancel}
          </Button>
          <Button onClick={handleConfirm} color="primary" variant="contained" autoFocus>
            {lang.actionConfirm}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AssignAgentDialog;
