import { Button as _Button, Grid, IconButton, ListItemIcon, makeStyles, Menu, MenuItem, Tab as _Tab, Tabs } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import SaveIcon from '@material-ui/icons/Save';
import clsx from 'clsx';
import { hasPermission } from 'common/access-control';
import { useConfirmDialog } from 'common/ConfirmDialog';
import { ClientDetailDTO, ClientFileMap, ClientPreferencesDTO, MemoDTO } from 'common/dto';
import FixedTabPanel from 'common/elements/FixedTabPanel';
import FixedTabsContainer from 'common/elements/FixedTabsContainer';
import { FormPresenter, useForm, useMenu } from 'common/hooks';
import { Memo } from 'common/Memo';
import { MemoNew } from 'common/MemoNew';
import { AssignAgentIcon, PropertyMatchingIcon, RemoveAgentIcon, tooltipHoc } from 'common/ui';
import { isNonEmpty } from 'common/utils';
import NavigationBar from 'layouts/Main/components/Topbar/NavigationBar';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { AllActions, ClientActions, IRootState } from 'reducers';
import { Dispatch } from 'redux';
import Documents from 'views/PropertyDetail/components/Documents';
import AssignAgentDialog from './components/AssignAgentDialog';
import ClientBasic from './components/ClientBasic';
import Preferences from './components/Preferences';
import SellerStockList from './components/SellerStockList';
import Wishlist from './components/Wishlist';
const Tab = tooltipHoc(_Tab);
const Button = tooltipHoc(_Button)
const BLANK = {};

// console.log(
//   Object.keys(constants).map((k) => `${k}:\n  ${((constants as any)[k] as any).join && ((constants as any)[k] as any).join('\n  ')}\n\n`).reduce((a, b) => a + b, '')
// );
// const tabMap = {
//   1: "basicInfo",
//   2: "preferences",
//   3: "wishlist"
// }

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  button: {
    marginRight: theme.spacing(1),
  },
  unsavedBtn: {
    color: '#FFBF00',
    fontWeight: 'bold'
  }
}));

const When = ({ test, children }: { test?: boolean, children: JSX.Element }): JSX.Element => test ? children : <span />;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const TabPanel = FixedTabPanel;

export interface ClientDetailProps {
  onSave: () => void;
  onRemove?: () => void;
  onMatch?: () => void;
  form: FormPresenter<ClientDetailDTO>;
  tagForm: FormPresenter<{ [type: string]: string[] }>;
  agentContactsForm?: FormPresenter<any>;
  otherContactsForm: FormPresenter<any>;
  preferenceForm: FormPresenter<ClientPreferencesDTO>;
  creating?: boolean;
  multiSelectForm: FormPresenter<{ [type: string]: string[] }>;
  memos?: MemoDTO[];
  fileForm: FormPresenter<ClientFileMap>;
  canEdit?: boolean;
  canDelete?: boolean;
  unsaved?: boolean;
  confirmCreateDialog?: any;
  setDuplicatedContact?: React.Dispatch<React.SetStateAction<boolean>>;
}


const ClientDetail = ({ unsaved, canEdit, canDelete, onSave, onRemove, onMatch, form, tagForm, creating, preferenceForm, agentContactsForm, otherContactsForm, multiSelectForm, memos, fileForm, confirmCreateDialog, setDuplicatedContact }: ClientDetailProps) => {
  // const [isNew, setIsNew] = useState(true);
  const classes = useStyles();
  const query = useQuery();
  const { langClientDetail, lang, langPropertyStock } = useSelector((state: IRootState) => state.locale);
  // const requestedTabIndex = useSelector((state: IRootState) => state.clients.tabIndex ?? 0);
  const [tabIndex, setTabIndex] = useState(0);
  const onScrollIn = (idx: number) => setTabIndex(idx);
  const scrollRef0 = React.useRef<() => void>();
  const scrollRef1 = React.useRef<() => void>();
  const scrollRef2 = React.useRef<() => void>();
  const scrollRef3 = React.useRef<() => void>();
  const scrollRef4 = React.useRef<() => void>();
  const scrollRef5 = React.useRef<() => void>();

  const tabScrolls = [
    scrollRef0,
    scrollRef1,
    scrollRef2,
    scrollRef3,
    scrollRef4,
    scrollRef5,
  ];

  const [disablePrefTab, setDisablePrefTab] = useState(false);
  const [disableSellerStockTab, setDisableSellerStockTab] = useState(false);

  const [memoDialogOpen, setMemoDialogOpen] = useState(false);
  const dispatch = useDispatch<Dispatch<AllActions>>();

  const memoForm = useForm<MemoDTO>({});
  const confirmDialog = useConfirmDialog();

  const clientDetail = form.values;

  const formSigned = clientDetail.formSigned ?? false;

  const cid = form.values.cid ?? (creating == true ? '0' : '');

  const setFormSigned = () =>
    console.log("setFormSigned");
  // dispatch({
  //   type: 'ClientDetail.BasicUpdated',
  //   payload: { formSigned: v },
  //   cid,
  // })
  ;

  // useEffect(() => {
  //   setTabIndex(requestedTabIndex);
  // }, [requestedTabIndex]);

  // caveat: mask will block scroll, thus we scroll after mask disappeared.
  const { maskRefCount } = useSelector((state: IRootState) => state.layout);
  const [handledWishListJump, setHandledWishlistJump] = useState(false);

  useEffect(() => {
    if (query.get("tab") == "wishlist" && !handledWishListJump && maskRefCount === 0) {
      handleTabChange(2);
      setHandledWishlistJump(true);
    }
    // console.log("query index:");
    // console.log(query.get("tabIndex"));
    // setTabIndex(query.get("tabIndex") ?? 0);
  }, [query.get("tab") ?? '', maskRefCount, cid]);

  useEffect(() => {
    //show preferences tab for buyer and tenant only
    if (tagForm.values.clientType?.includes('B') || tagForm.values.clientType?.includes('T')) {
      setDisablePrefTab(false);
    } else {
      setDisablePrefTab(true);
    }
    //show seller stock for seller and landlord only
    if (tagForm.values.clientType?.includes('S') || tagForm.values.clientType?.includes('L')) {
      setDisableSellerStockTab(false);
    } else {
      setDisableSellerStockTab(true);
    }
  }, [tagForm.values.clientType]);

  const handleTabChange = (tab: number) => {
    setTabIndex(tab);
    tabScrolls[tab]?.current?.();
    // if(creating) {
    //   history.push( `/client-detail/new?tab=${encodeURIComponent(clientTabMap[tab])}`);
    // }else {
    //   history.push( `/client-detail/${cid}?tab=${encodeURIComponent(clientTabMap[tab])}`);
    // }    
  }

  const handleDeleteStockCart = (id: string[], formtype: string) => {
    var status: string;
    if (formtype === "FORM_4") {
      status = "SALES"
    } else {
      status = "RENT"
    }
    if (id !== null && id.length > 0) {
      dispatch({
        type: 'PropertyMatching.RemoveStockCartRequested', payload: {
          cid: cid ?? '', pid: id ?? [], propertyStatus: status,
        }
      })
    }
  }

  const basicInfoTabHasError = form.hasError('chineseName') ||
    form.hasError('englishName') || form.hasError('otherContacts') ||
    form.hasError('source') || form.hasError('missingClientType')
    ;

  const preferenceTabHasError = form.hasError('missingUsage') || form.hasError('missingDistrict')
    // preferenceForm.hasError('netAreaFrom') || preferenceForm.hasError('netAreaTo') ||
    // preferenceForm.hasError('buyBudgetFrom') || preferenceForm.hasError('buyBudgetTo') ||
    // preferenceForm.hasError('rentBudgetFrom') || preferenceForm.hasError('rentBudgetTo')
    // || preferenceForm.hasError('district')
    ;


  // collapse button/menu
  const collapseMenu = useMenu();

  //privilege for handling the agent section
  const { privileges } = useSelector((state: IRootState) => state.login);
  const clientDetailDto = useSelector((state: IRootState) => state.clients.fetchedDetails[cid!]) ?? BLANK as Partial<ClientDetailDTO>;

  const canCreateUnownedClient = hasPermission(privileges, 'CREATE', 'UNOWNED_CLIENT');
  const canCreateOwnedClient = hasPermission(privileges, 'CREATE', 'OWN_CLIENT');
  const canReadOwnedClient = hasPermission(privileges, 'READ', 'OWN_CLIENT');
  const canAssignUnownedAgentClient = hasPermission(privileges, 'UPDATE', 'ASSIGN_UNOWNED_AGENT_CLIENT');
  const canAssignTeamAgentClient = clientDetailDto.isTeamHead && hasPermission(privileges, 'UPDATE', 'ASSIGN_TEAM_AGENT_CLIENT');
  const canAssignOwnAgentClient = hasPermission(privileges, 'UPDATE', 'ASSIGN_OWN_AGENT_CLIENT');

  //privilege for company client
  const canCreateCompanyClient = hasPermission(privileges, 'CREATE', 'COMPANY_CLIENT');
  const canReadCompanyClient = hasPermission(privileges, 'READ', 'COMPANY_CLIENT');
  const canUpdateCompanyClient = hasPermission(privileges, 'UPDATE', 'COMPANY_CLIENT');

  const isOwnedClient = clientDetailDto.isLeadAgent;
  const isUnownedClient = !clientDetailDto.isLeadAgent && !clientDetailDto.isTeamHead;

  //show or hide the assign/reassign agent butotn
  const showAssignAgentButton = () => {
    if (creating) {
      return false;
    } else {
      const canAssign = (isOwnedClient && canAssignOwnAgentClient) ||
        (isUnownedClient && canAssignUnownedAgentClient) ||
        canAssignTeamAgentClient;
      return canAssign;
    }
  }

  const [openAssignAgentDialog, setOpenAssignAgentDialog] = React.useState(false);

  const buttonBar = () => (
    <Fragment>
      {confirmDialog.render()}
      {/* <When test={!creating}>
        <Button disabled={!canEdit} startIcon={<NoteAddIcon />} style={{marginRight: 5, marginBottom:2 }} color="default" variant="text" onClick={() => setMemoDialogOpen(true)}>{lang.actionAddMemo}</Button>  
      </When> */}
      {/* <When test={!creating}>  
        {
          <Button disabled={disablePrefTab || !canEdit} tooltip={disablePrefTab ? langClientDetail.msgOnlyForBuyerOrTenant : undefined} variant="text" style={{ marginRight: 10 }} color="primary" onClick={onMatch
          // dispatch({ type: 'PropertyMatching.StartMatching', payload: { cid: cid, name: form.values.englishName ?? '', clientPreference: {} } });
          // history.push('/properties');
        }>{langClientDetail.actionStartMatching}</Button>}
      </When> */}

      {/* <When test={!creating}>        
        <Button disabled={!canDelete} variant="text" style={{ marginRight: 10 }} color="primary" 
          onClick={() => {
            confirmDialog.confirm(langClientDetail.msgConfirmRemoveClient, lang.actionConfirm, lang.actionCancel).then((confirmed) => {
              if (confirmed) {
                onRemove?.();
              }
            })
          }}>
          {langClientDetail.actionRemoveClient}
        </Button>
      </When> */}

      {/* {!isNew ? <Button variant="contained" style={{ marginRight: 10 }} color="primary" onClick={() => {
        dispatch({ type: 'PropertyMatching.StartMatching', payload: { name: '陳小文' } });
        history.push('/properties');
      }}>開始對盤 (睇租)</Button> : null} */}
      <Button disabled={!canEdit} color="primary" variant="text"
        className={clsx(unsaved && classes['unsavedBtn'])}
        style={{ fontWeight: unsaved ? 'bold' : 'normal' }}
        startIcon={<SaveIcon />} onClick={onSave
        /*
        () => {
        //setIsNew(false);
        basicForm.setValues({
          tel: '54489991',
          email: 'chansiuman@gmail.com',
          chineseName: '陳小文',
          englishName: 'Chan Siu Man',
          company: '',
          comOrdNo: '',
          bnRegNo: '',
          source: '',
          remarks: '',
          // otherContacts: [
          //   { key: 'WeChat', value: 'chansiuman1992' },
          //   { key: '工作電話', value: '88888888' },
          // ]
        });
        
        dispatch({ type: 'ClientDetail.ContactAdded', cid, payload: { type: 'WeChat', value: 'chansiuman1992' } });
        dispatch({ type: 'ClientDetail.ContactAdded', cid, payload: { type: '工作電話', value: '88888888' } });
        
      }*/}>{lang.actionSave}{unsaved ? '*' : ''}</Button>

      <When test={!creating}>
        <IconButton {...collapseMenu.buttonProps()}>
          <MoreVertIcon />
        </IconButton>
      </When>

      {/* Collapse Menu */}
      <Menu {...collapseMenu.menuProps()}>
        {/* Remove Button */}
        <MenuItem disabled={!canDelete} onClick={() => {
          confirmDialog.confirm(langClientDetail.msgConfirmRemoveClient, lang.actionConfirm, lang.actionCancel).then((confirmed) => {
            if (confirmed) {
              onRemove?.();
            }
          })
        }}>
          <ListItemIcon style={{ minWidth: 30 }}><DeleteIcon /></ListItemIcon>
          {langClientDetail.actionRemoveClient}
        </MenuItem>

        {/* Memo Button */}
        <MenuItem onClick={collapseMenu.withClosing(() => setMemoDialogOpen(true))}>
          <ListItemIcon style={{ minWidth: 30 }}><NoteAddIcon /></ListItemIcon>
          {lang.actionAddMemo}
        </MenuItem>

        {/* Matching Button */}
        {disablePrefTab ? null : <MenuItem onClick={collapseMenu.withClosing(() => onMatch?.())}>
          <ListItemIcon style={{ minWidth: 30 }}><PropertyMatchingIcon /></ListItemIcon>
          {langClientDetail.actionStartMatching}
        </MenuItem>}

        {/* Assign agent Button */}
        {showAssignAgentButton() ?
          <MenuItem onClick={collapseMenu.withClosing(() => setOpenAssignAgentDialog(true))}>
            <ListItemIcon style={{ minWidth: 30 }}><AssignAgentIcon /></ListItemIcon>
            {!isNonEmpty(form.values.agentId) ? langClientDetail.actionAssignAgent : langClientDetail.actionReassignAgent}
          </MenuItem>
          : null
        }

        {/* remove lead agent */}
        {!creating && isOwnedClient ?
          <MenuItem onClick={collapseMenu.withClosing(() => {
            confirmDialog.confirm(langClientDetail.msgConfirmRemoveAgent, lang.actionYes, lang.actionNo).then((confirmed: boolean) => {
              if (confirmed) {
                dispatch({ type: 'Client.RemoveAgentRequested', payload: { cid: cid ?? '' } });
              }
            });
          })}>
            <ListItemIcon style={{ minWidth: 30 }}><RemoveAgentIcon /></ListItemIcon>
            {langClientDetail.actionRemoveAgent}
          </MenuItem>
          : null
        }
      </Menu>
    </Fragment>
  );

  const formsOptions: { [key: string]: string } = {
    DUMMY: lang.actionUpload,
    FORM_4: langPropertyStock.form4,
    FORM_6: langPropertyStock.form6,
  };

  const getInitialStockCartFilterOption = () => {
    // return tagForm.values.clientType?.filter(type => type === 'T' || type ==='B')?.map(type => type === 'T' ? 'RENT' : 'SALES') ?? []
    return tagForm.values.clientType?.includes('B') ? 'SALES' : tagForm.values.clientType?.includes('T') ? 'RENT' : 'SALES';
  }

  return <div className={classes.root}>
    <NavigationBar title={creating ? langClientDetail.titleNewClientDetail : (langClientDetail.titleViewClientDetail + (unsaved ? '*' : ''))}>
      <Grid item container
        lg={6}
        md={6}
        xl={6}
        xs={6}
        justify="flex-end">
        <Grid item >
          {buttonBar()}
        </Grid>
      </Grid>
    </NavigationBar>
    {confirmCreateDialog.render()}
    <AssignAgentDialog cid={cid} originalAgentId={form.values.agentId} open={openAssignAgentDialog} handleClose={() => { setOpenAssignAgentDialog(false) }} />
    <MemoNew onClose={() => setMemoDialogOpen(false)} isOpen={memoDialogOpen} form={memoForm} onSave={() => {
      if (memoForm.values?.content) {
        dispatch({
          type: 'Client.Memo.CreateRequested', payload: {
            cid: cid ?? '',
            memo: memoForm.values as MemoDTO,
          }
        } as ClientActions);
        setMemoDialogOpen(false);
        memoForm.setValues({
          ...memoForm.values,
          content: ''
        });
      }
    }}
    />
    {/* <Grid container md={12} xs={12} spacing={1}>
      <Grid item
        lg={6}
        md={6}
        xl={6}
        xs={6}>
        <Typography
          variant="h2"
        >
          {creating ? langClientDetail.titleNewClientDetail : langClientDetail.titleViewClientDetail}
        </Typography>
        <Typography
          color="textSecondary"
          gutterBottom
        ></Typography>
      </Grid>
    </Grid> */}

    <FixedTabsContainer>
      <Tabs TabIndicatorProps={{ children: <div /> }} value={tabIndex} onChange={(_, val) => { handleTabChange(val)/*(_, val) => setTabIndex(val)*/ }} variant="scrollable">
        <Tab label={langClientDetail.titleBasicInfo} style={{ color: basicInfoTabHasError ? 'red' : 'inherit' }} />
        {!creating ? <Tab style={{ pointerEvents: "auto" }}
          disabled={disableSellerStockTab}
          label={
            <span>{langClientDetail.titleSellerStock}</span>
          } /> : null}
        <Tab label={langClientDetail.titlePreference} tooltip={disablePrefTab ? langClientDetail.msgOnlyForBuyerOrTenant : undefined} disabled={disablePrefTab}
          style={{ color: preferenceTabHasError ? 'red' : 'inherit' }} />
        {!creating ? <Tab style={{ pointerEvents: "auto" }}
          disabled={disablePrefTab}
          label={
            <span>{langClientDetail.titleWishlist}</span>
          } /> : null}
        {!creating ? <Tab style={{ pointerEvents: "auto" }}
          disabled={disablePrefTab}
          label={langClientDetail.titleUploadForms} /> : null}
        {!creating ? <Tab style={{ pointerEvents: "auto" }}
          label={
            <span>{lang.memos}</span>
          } /> : null}
      </Tabs>
    </FixedTabsContainer>

    <TabPanel scrollRef={tabScrolls[0]} index={0} onScrollIn={onScrollIn}>
      <Grid
        container
        // md={12}
        // sm={12}
        // xs={12}
        spacing={1}
      >
        <Grid data-key={"field-clientdetails-missingClientType"} item md={12} sm={12} xs={12}>
          <ClientBasic
            cid={cid}
            clientDetailForm={form}
            creating={creating}
            tagForm={tagForm}
            agentContactsForm={agentContactsForm}
            otherContactsForm={otherContactsForm}
            multiSelectForm={multiSelectForm}
            setDuplicatedContact={setDuplicatedContact}
          />
        </Grid>
      </Grid>
    </TabPanel>

    {
      creating || disableSellerStockTab ? null : <TabPanel scrollRef={tabScrolls[1]} index={1} onScrollIn={onScrollIn} title={langClientDetail.titleSellerStock} >
        <Grid
          container
          // md={12}
          // sm={12}
          // xs={12}
          spacing={1}
        >
          <Grid item md={12} xs={12}>
            <SellerStockList />
          </Grid>
        </Grid>
      </TabPanel>
    }

    {
      disablePrefTab ? null : <TabPanel scrollRef={tabScrolls[2]} index={2} onScrollIn={onScrollIn} title={langClientDetail.titlePreference}>
        <Grid
          data-key={"field-clientdetails-missingUsage"}
          container
          // md={12}
          // sm={12}
          // xs={12}
          spacing={1}
        >
          <Grid item md={12} xs={12}>
            <Preferences tagForm={tagForm} form={form} preferenceForm={preferenceForm} multiSelectForm={multiSelectForm} />
          </Grid>
        </Grid>
      </TabPanel>
    }


    {
      creating || disablePrefTab ? null : <TabPanel scrollRef={tabScrolls[3]} index={3} onScrollIn={onScrollIn} title={langClientDetail.titleWishlist} >
        <Grid
          container
          // md={12}
          // sm={12}
          // xs={12}
          spacing={1}
        >
          <Grid item md={12} sm={12} xs={12}>
            <Wishlist formSigned={formSigned} setFormSigned={setFormSigned} fileForm={fileForm} unsaved={(unsaved ? true : false)} onSave={onSave}
              initialStockCartFilterOption={getInitialStockCartFilterOption()}
            />
          </Grid>
        </Grid>
      </TabPanel>
    }

    {
      creating || disablePrefTab ? null : <TabPanel scrollRef={tabScrolls[4]} index={4} onScrollIn={onScrollIn} title={langClientDetail.titleUploadForms} >
        <Grid
          container
          // md={12}
          // sm={12}
          // xs={12}
          spacing={1}
        >
          <Grid item md={12} sm={12} xs={12}>
            {/* <UploadForms fileForm={fileForm}/> */}
            <Documents
              fileForm={fileForm}
              isClientPage={true}
              //handleDeleteStockCart={handleDeleteStockCart}
              isClientAgent={clientDetail.isLeadAgent}
              docTypeOptions={formsOptions}
              sectionTitle={langClientDetail.titleForm}
              elementIdPrefix={'client-page'}
            />
          </Grid>
        </Grid>
      </TabPanel>
    }

    <When test={!creating}>
      <TabPanel title={lang.memos} scrollRef={tabScrolls[5]} index={5} onScrollIn={onScrollIn}>
        <Grid
          container
          // md={12}
          // sm={12}
          // xs={12}
          spacing={1}
        >
          <Memo memos={memos} />
        </Grid>
      </TabPanel>
    </When>
  </div>
};


export default ClientDetail;