import { ApiResult } from "api";
import { store } from "store";

const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];

export const fileApi = {
  async addFile(file: File, addWatermark = false, watermarkType = 'LOGO'): Promise<ApiResult<string>> {
    store.dispatch({ type: 'Layout.MaskPresentRequested' });
    const token = store.getState().login.token ?? '';
    const result: Promise<ApiResult<string>> = fetch(`${BASE_URL}/files/${file.name}?addWatermark=${addWatermark}&watermarkType=${watermarkType}`, {
      method: 'POST',
      body: file,
      headers: {
        'Content-Type': 'application/octet-stream', 'Authorization': `${token}`
      },
    })
      .then(res => res.json())
      .catch(err => ({ error: String(err) }));
    // let msg = '';
    // result.then(res => {
    //   if (res.error === 'ERR_MALICIOUS_FAILED') {
    //     alert(res.data);
    //     console.log('metadate', res.metadata);
    //     store.dispatch({ type: 'Layout.AlertMessageAdded', payload: { message: '此檔案可能含有惡意內容, 請上傳另一份檔案. This file may have malicious contents, please upload another file.', severity: 'error' } });
    //     store.dispatch({ type: 'Layout.MaskDismissRequested' });
    //     throw Error;
    //   }
    // });
    // store.dispatch({ type: 'Layout.MaskDismissRequested' });

    // return result;
    if ((await result).error) {
      store.dispatch({ type: 'Layout.AlertMessageAdded', payload: { message: '此檔案可能含有惡意內容, 請上傳另一份檔案. This file may have malicious contents, please upload another file.', severity: 'error' } });
      store.dispatch({ type: 'Layout.MaskDismissRequested' });
      throw Error;

    } else {
      store.dispatch({ type: 'Layout.MaskDismissRequested' });
      return result;
    }
  },


};

export default fileApi;