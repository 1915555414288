import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const FeatureTagMgtToolbar = props => {
  const { className, canSave, onSave, unsaved,...rest } = props;
  const { lang, langFeatureTagMgt} = useSelector((state) => state.locale);
  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <Typography
          className={classes.title}
          variant="h2"
        >
          {langFeatureTagMgt.titlePageHeading}
        </Typography>
        <span className={classes.spacer} />
        {/* <Button className={classes.importButton}>Import</Button>
        <Button className={classes.exportButton}>Export</Button> */}
        {canSave ? 
          <Button
            color="primary"
            variant="contained"
            onClick={onSave}
            style={{ fontWeight: unsaved ? 'bold' : 'normal' }} 
          >
            {lang.actionSave}{unsaved ? '*' : ''}
          </Button>
        : null}
      </div>
      {/* <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search user"
        />
      </div> */}
    </div>
  );
};

FeatureTagMgtToolbar.propTypes = {
  className: PropTypes.string,
  canSave: PropTypes.bool,
  onSave: PropTypes.func,
  unsaved: PropTypes.bool
};

export default FeatureTagMgtToolbar;
