import {
  Card, CardContent, Checkbox, Divider, FormControl, FormHelperText, Grid, InputLabel, ListItemText, MenuItem, Select, Switch, TextField, Typography, useMediaQuery, useTheme
} from '@material-ui/core';
import { CardProps } from '@material-ui/core/Card';
import { ClientDetailDTO } from 'common/dto';
import DistrictMultiSelect from 'common/elements/DistrictMultiSelect';
import MinMaxField, { BLANK_RANGE, DEFAULT_FROM, DEFAULT_TO } from 'common/elements/MinMaxField';
import { FormPresenter, useForm } from 'common/hooks';
import { HandleFormHelperText } from 'common/ui';
import { initFormKeysAndBooleanMap, limitTextFieldLength, priceFromView, priceToView } from 'common/utils';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';
import { SearchFormValues } from '../../ClientList/components/ClientsSearch';


const numberOptions = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

interface PreferenceProps extends CardProps {
  form: FormPresenter<ClientDetailDTO>;
  tagForm: FormPresenter<{ [type: string]: string[] }>;
  preferenceForm: FormPresenter<any>;
  multiSelectForm: FormPresenter<{ [type: string]: string[] }>;
}

const Preferences = (props: PreferenceProps) => {
  const { form, tagForm, preferenceForm, multiSelectForm } = props;
  const { langClientDetail, lang: langCommon, langPropertyStock, lang,
    decoOptions, otherFeatureOptions, viewOptions, facingOptions, levelOptions, usageOptions, locale,
    vacantOptions, propertyStockStatusOptions, clubHouseFacilitiesOptions,
    otherOptions, secondarySchoolNetOptions, primarySchoolNetOptions
  } = useSelector((state: IRootState) => state.locale);

  const rangeValueForm = useForm<SearchFormValues>({
    isGross: preferenceForm.values.isGross ?? false,
    isRent: preferenceForm.values.isRent ?? false,
    gross: [preferenceForm.values.grossAreaFrom ?? DEFAULT_FROM, preferenceForm.values.grossAreaTo ?? DEFAULT_TO],
    net: [preferenceForm.values.netAreaFrom ?? DEFAULT_FROM, preferenceForm.values.netAreaTo ?? DEFAULT_TO],
    // netTo: 500,
    price: [preferenceForm.values.buyBudgetFrom ? priceToView(preferenceForm.values.buyBudgetFrom, locale) : DEFAULT_FROM,
    preferenceForm.values.buyBudgetTo ? priceToView(preferenceForm.values.buyBudgetTo, locale) : DEFAULT_TO],
    rent: [preferenceForm.values.rentBudgetFrom ?? DEFAULT_FROM, preferenceForm.values.rentBudgetTo ?? DEFAULT_TO],
  });
  const theme = useTheme();
  const shouldCollapse = useMediaQuery('(max-width: 599px)');
  useEffect(() => {

  }, [rangeValueForm.values.isGross])

  const isGross = rangeValueForm.values.isGross ?? false;
  const setIsGross = (newVal: boolean) => {
    rangeValueForm.setValues({
      ...rangeValueForm.values,
      isGross: newVal,
    })
    // swap
    if (newVal) {
      rangeValueForm.modify({
        gross: rangeValueForm.values.net,
        net: BLANK_RANGE,
      });
    } else {
      rangeValueForm.modify({
        net: rangeValueForm.values.gross,
        gross: BLANK_RANGE,
      });
    }
  };


  const isRent = rangeValueForm.values.isRent ?? false;
  const setIsRent = (newVal: boolean) => {
    rangeValueForm.updateValues('isRent', newVal);
    // swap
    if (newVal) {
      rangeValueForm.modify({
        price: BLANK_RANGE,
        rent: BLANK_RANGE,
      });
    } else {
      rangeValueForm.modify({
        rent: BLANK_RANGE,
        price: BLANK_RANGE,
      });
    }
  }

  useEffect(() => {
    preferenceForm.modify({
      isGross: rangeValueForm.values.isGross ?? false,
      grossAreaFrom: rangeValueForm.values.gross![0] === DEFAULT_FROM ? null : rangeValueForm.values.gross![0],
      grossAreaTo: rangeValueForm.values.gross![1] === DEFAULT_TO ? null : rangeValueForm.values.gross![1],
      netAreaFrom: rangeValueForm.values.net![0] === DEFAULT_FROM ? null : rangeValueForm.values.net![0],
      netAreaTo: rangeValueForm.values.net![1] === DEFAULT_TO ? null : rangeValueForm.values.net![1],

      isRent: rangeValueForm.values.isRent ?? false,
      buyBudgetFrom: rangeValueForm.values.price![0] === DEFAULT_FROM ? null : priceFromView(rangeValueForm.values.price![0], locale),
      buyBudgetTo: rangeValueForm.values.price![1] === DEFAULT_TO ? null : priceFromView(rangeValueForm.values.price![1], locale),
      rentBudgetFrom: rangeValueForm.values.rent![0] === DEFAULT_FROM ? null : rangeValueForm.values.rent![0],
      rentBudgetTo: rangeValueForm.values.rent![1] === DEFAULT_TO ? null : rangeValueForm.values.rent![1],
    });

  }, [rangeValueForm.values]);

  //handling clientType validation
  useEffect(() => {
    preferenceForm.modify({
      clientType: tagForm.values.clientType?.sort().join(','),
      usage: tagForm.values.usage?.join(','),
    });

    //if the client is tenant only, set isRent = true
    if (tagForm.values.clientType?.includes('T') && !tagForm.values.clientType?.includes('B')) {
      if (!isRent) {
        setIsRent(true);
      }
    }

    //if the client is buyer only, set isRent = false
    if (tagForm.values.clientType?.includes('B') && !tagForm.values.clientType?.includes('T')) {
      if (isRent) {
        setIsRent(false);
      }
    }

  }, [tagForm.values.clientType, tagForm.values.usage]);

  const isRentFixed = tagForm.values.clientType?.includes('T') && !tagForm.values.clientType?.includes('B');
  const isPriceFixed = tagForm.values.clientType?.includes('B') && !tagForm.values.clientType?.includes('T');
  // const handleIsRent = (val: boolean) => {
  //   
  //   if (tagForm.values.clientType?.includes('B') && tagForm.values.clientType?.includes('T')) {
  //     setIsRent(val);
  //   }
  // }

  const [keyBooleanMap, setKeyBooleanMap] = useState(initFormKeysAndBooleanMap(preferenceForm));

  // 

  return <Card>
    <CardContent>
      <Grid
        container
        spacing={1}
      >

        <Grid
          container
          item
          md={6}
          sm={12}
          xs={12}
          spacing={1}
        >

          { /* ----------- usage ------------- */}
          <Grid
            item
            md={3}
            sm={3}
            xs={12}
          >
            <FormControl error={!!form.hasError('missingUsage')} variant="outlined" margin="dense" fullWidth>
              <InputLabel required variant="outlined" style={{ background: '#fff' }}>{langPropertyStock.usage}</InputLabel>
              <Select
                multiple
                {...tagForm.bind('usage')}
                renderValue={(selected) => (selected as string[]).map(key => usageOptions[key]).join(', ')}
              >
                {Object.keys(usageOptions).map(key => (
                  <MenuItem key={key} value={key}>
                    <Checkbox checked={(tagForm.values.usage ?? []).indexOf(key) > -1} />
                    <ListItemText primary={usageOptions[key]} />
                  </MenuItem>
                ))}
              </Select>
              {form.hasError('missingUsage') ? <FormHelperText error>{form.hasError('missingUsage')}</FormHelperText> : null}
            </FormControl>

          </Grid>



          { /* ----------- property status------------- */}
          <Grid
            item
            md={3}
            sm={3}
            xs={12}
          >
            <FormControl variant="outlined" margin="dense" fullWidth>
              <InputLabel variant="outlined" style={{ background: '#fff' }}>{langClientDetail.propertyStatus}</InputLabel>
              <Select
                multiple
                {...tagForm.bind('propertyStatus')}
                renderValue={(selected) => (selected as string[]).map(key => propertyStockStatusOptions[key]).join(', ')}
              >
                {Object.keys(propertyStockStatusOptions).filter(status => status !== 'UNKNOWN' && status !== 'BOTH').map(key => (
                  <MenuItem key={key} value={key}>
                    <Checkbox checked={tagForm.values.propertyStatus!.indexOf(key) > -1} />
                    <ListItemText primary={propertyStockStatusOptions[key]} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

          </Grid>
          { /* ----------- district ------------- */}
          <Grid
            item
            md={6}
            sm={6}
            xs={12}
          >

            <DistrictMultiSelect
              // required
              value={preferenceForm.values.district?.split(',').map((n: string) => n.trim()) ?? []}
              onChange={(values) => preferenceForm.updateValues('district', values.join(','))}
              error={!!form.hasError('missingDistrict')}
            />
            {form.hasError('missingDistrict') ? <FormHelperText error>{form.hasError('missingDistrict')}</FormHelperText> : null}

          </Grid>

          { /* ----------- street ------------- */}
          <Grid
            item
            md={6}
            sm={6}
            xs={12}
          >
            <TextField
              fullWidth
              label={langPropertyStock.street}
              margin="dense"
              variant="outlined"
              {...preferenceForm.bind('street')}
              onChange={(e) => {
                limitTextFieldLength(e, 255, 'street', preferenceForm, keyBooleanMap, setKeyBooleanMap);
              }}
            />
            <HandleFormHelperText
              isError={keyBooleanMap.get('street')}
              errorMessage={lang.textOverLimit}
            />
            <FormHelperText>{langPropertyStock.msgStreetHelperText}</FormHelperText>
          </Grid>

          { /* ----------- building ------------- */}
          <Grid
            item
            md={6}
            sm={6}
            xs={12}
          >
            <TextField
              fullWidth
              label={langPropertyStock.buildingOrVillageName}
              margin="dense"
              variant="outlined"
              {...preferenceForm.bind('building')}
              onChange={(e) => {
                limitTextFieldLength(e, 255, 'building', preferenceForm, keyBooleanMap, setKeyBooleanMap);
              }}
            />
            <HandleFormHelperText
              isError={keyBooleanMap.get('building')}
              errorMessage={lang.textOverLimit}
            />
            <FormHelperText>{langPropertyStock.msgBuildingHelperText}</FormHelperText>

          </Grid>

          { /* ----------- level ------------- */}
          <Grid
            item
            md={3}
            sm={3}
            xs={12}
          >

            <FormControl variant="outlined" margin="dense" fullWidth>
              <InputLabel variant="outlined" style={{ background: '#fff' }}>{langPropertyStock.level}</InputLabel>
              <Select
                multiple
                {...multiSelectForm.bind('level')}
                renderValue={(selected) => (selected as string[]).map(key => levelOptions[key]).join(', ')}
              >
                {Object.keys(levelOptions).map(key => (
                  <MenuItem key={key} value={key}>
                    <Checkbox checked={multiSelectForm.values.level!.indexOf(key) > -1} />
                    <ListItemText primary={levelOptions[key]} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

          </Grid>

          { /* ----------- vacant ------------- */}
          <Grid
            item
            md={3}
            sm={3}
            xs={12}
          >
            <TextField
              fullWidth
              label={langPropertyStock.vacant}
              margin="dense"
              // required
              select
              variant="outlined"
              {...preferenceForm.bind('vacant')}
            >
              {Object.keys(vacantOptions).map((key) => (
                <MenuItem key={key} value={key}>
                  {vacantOptions[key]}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

        </Grid>

        <Grid
          container
          item
          md={6}
          sm={12}
          xs={12}
          spacing={1}
          style={{ alignContent: 'flex-start' }}
        >
          { /* ----------- net/gross block ------------- */}
          {/* <Grid container md={12}> */}
          <Grid container >

            { /* ----------- shouldCollapse caption area + toggle ------------- */}


            {
              (shouldCollapse) ?
                <Grid container item md={12} xs={12}>
                  <Grid
                    item
                    md={3}
                    sm={3}
                    xs={6}
                  >
                    <div style={{ marginRight: 10, marginTop: 13, display: 'flex-end', flexDirection: 'row', textAlign: 'left' }}>
                      <Typography style={{ fontWeight: 'bold' }}>{langPropertyStock.captionArea}</Typography>
                    </div>
                  </Grid>
                  < Grid
                    container
                    item
                    md={3}
                    sm={3}
                    xs={6}
                  >
                    <div style={{ marginLeft: theme.spacing(1), display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <Typography>{langPropertyStock.captionNet}</Typography>
                      <Switch
                        checked={isGross}
                        onChange={(_, val) => setIsGross(val)}
                      />
                      <Typography>{langPropertyStock.captionGross}</Typography>
                    </div>
                  </Grid>
                </Grid>
                :

                <Grid
                  item
                  md={3}
                  sm={3}
                  xs={12}
                >
                  <div style={{ marginRight: 10, marginTop: 13, display: 'flex-end', flexDirection: 'row', textAlign: 'left' }}>
                    <Typography style={{ fontWeight: 'bold' }}>{langPropertyStock.captionArea}</Typography>
                  </div>
                </Grid>


            }

            { /* ----------- net/gross range input ------------- */}
            <Grid item md={6} sm={6} xs={12}>
              <MinMaxField
                range={isGross ? rangeValueForm.values.gross! : rangeValueForm.values.net!}
                unit={langCommon.uFt}
                onChange={(newRange) => {
                  //console.log("change");
                  const key = isGross ? 'gross' : 'net';
                  rangeValueForm.setValues({
                    ...rangeValueForm.values,
                    [key]: newRange,
                  });
                }}


              />



              {preferenceForm.hasError('netAreaFrom') || preferenceForm.hasError('netAreaTo') ? <FormHelperText error>{preferenceForm.hasError('netAreaFrom')}</FormHelperText> : null}


            </Grid>

            { /* ----------- !shouldCollapse net/gross toggle ------------- */}
            {!(shouldCollapse) ?
              <Grid
                item
                md={3}
                sm={3}
                xs={3}
              >
                <div style={{ marginLeft: theme.spacing(1), display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Typography>{langPropertyStock.captionNet}</Typography>
                  <Switch
                    checked={isGross}
                    onChange={(_, val) => setIsGross(val)}
                  />
                  <Typography>{langPropertyStock.captionGross}</Typography>
                </div>
              </Grid>
              : null}

          </Grid>

          {/* ----------- price/rent block --------- */}
          {/* <Grid container md={12}> */}
          <Grid container >


            { /* ----------- shouldCollapse caption price/rent + toggle ------------- */}
            {
              (shouldCollapse) ?
                <Grid container item md={12} xs={12}>
                  <Grid
                    item
                    md={3}
                    sm={3}
                    xs={6}
                  >
                    <div style={{ marginRight: 10, marginTop: 13, display: 'flex-end', flexDirection: 'row', textAlign: 'left' }}>
                      <Typography style={{ fontWeight: 'bold' }}>{langPropertyStock.captionPriceOrRent}</Typography>
                    </div>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    sm={3}
                    xs={6}
                  >
                    <div style={{ marginLeft: theme.spacing(1), display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <Typography>{langPropertyStock.price}</Typography>
                      <Switch
                        //disabled={isRentFixed || isPriceFixed}
                        //checked={isRentFixed ? true : isPriceFixed ? false : isRent}
                        onChange={(_, val) => setIsRent(val)}
                      />
                      <Typography>{langPropertyStock.rent}</Typography>
                    </div>
                  </Grid>

                </Grid>
                :
                <Grid
                  item
                  md={3}
                  sm={3}
                  xs={12}
                >
                  <div style={{ marginRight: 10, marginTop: 13, display: 'flex-end', flexDirection: 'row', textAlign: 'left' }}>
                    <Typography style={{ fontWeight: 'bold' }}>{langPropertyStock.captionPriceOrRent}</Typography>
                  </div>
                </Grid>
            }


            { /* ----------- price/rent range input ------------- */}
            <Grid item md={6} sm={6} xs={12}>
              <MinMaxField
                range={isRent ? rangeValueForm.values.rent! : rangeValueForm.values.price!}
                unit={!isRent ? (locale === 'en' ? langCommon.u1M : langCommon.u10k) : undefined}
                onChange={(newRange) => {
                  //console.log("newRange", newRange);
                  const key = isRent ? 'rent' : 'price';
                  rangeValueForm.modify({
                    [key]: newRange,
                  });
                }}
                digitLimit={isRent ? 12 : locale == 'en' ? 6 : 8}
                isRent={isRent}
              />

            </Grid>

            { /* ----------- !shouldCollapse price/rent toggle ------------- */}
            {!(shouldCollapse) ?
              <Grid
                item
                md={3}
                sm={3}
                xs={3}
              >
                <div style={{ marginLeft: theme.spacing(1), display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Typography>{langPropertyStock.price}</Typography>
                  <Switch
                    //disabled={isRentFixed || isPriceFixed}
                    //checked={isRentFixed ? true : isPriceFixed ? false : isRent}
                    onChange={(_, val) => setIsRent(val)}
                  />
                  <Typography>{langPropertyStock.rent}</Typography>
                </div>
              </Grid>
              : null}


          </Grid>

        </Grid>

        <Grid item container md={3} xs={12} spacing={1}>
          {/* <Grid
              item
              md={12}
              xs={12}
            >
              <Typography style={{ fontWeight: 'bold' }}>{langPropertyStock.captionBasicInfo}</Typography>
            </Grid> */}
          { /* ----------- usage ------------- */}
          {/* <Grid
              item
              md={12}
              xs={12}
            >
              <FormControl error={!!form.hasError('missingUsage')} variant="outlined" margin="dense" fullWidth>
                <InputLabel required variant="outlined" style={{ background: '#fff' }}>{langPropertyStock.usage}</InputLabel>
                <Select
                  multiple
                  {...tagForm.bind('usage')}
                  renderValue={(selected) => (selected as string[]).map(key => usageOptions[key]).join(', ')}
                >
                  {Object.keys(usageOptions).map(key => (
                    <MenuItem key={key} value={key}>
                      <Checkbox checked={(tagForm.values.usage ?? []).indexOf(key) > -1} />
                      <ListItemText primary={usageOptions[key]} />
                    </MenuItem>
                  ))}
                </Select>
                {form.hasError('missingUsage') ?  <FormHelperText error>{form.hasError('missingUsage')}</FormHelperText> : null}
              </FormControl>

            </Grid> */}

        </Grid>




        <Grid item container md={8} xs={12} spacing={1} style={{ alignContent: 'start' }}>
          {/* <Grid
              item
              md={12}
              xs={12}
            >
              <Typography style={{ fontWeight: 'bold' }}>{langPropertyStock.captionLocation}</Typography>
            </Grid> */}
          { /* ----------- district ------------- */}
          {/* <Grid
              item
              md={4}
              xs={12}
            >
              
              <DistrictMultiSelect
                required
                value={preferenceForm.values.district?.split(',').map((n: string) => n.trim()) ?? []}
                onChange={(values) => preferenceForm.updateValues('district', values.join(','))}
                error={!!form.hasError('missingDistrict') }
              />
              { form.hasError('missingDistrict') ?  <FormHelperText error>{form.hasError('missingDistrict')}</FormHelperText> : null}
              
            </Grid> */}

          { /* ----------- level ------------- */}
          {/* <Grid
              item
              md={2}
              xs={12}
            >
             
              <FormControl variant="outlined" margin="dense" fullWidth>
                <InputLabel variant="outlined" style={{ background: '#fff' }}>{langPropertyStock.level}</InputLabel>
                <Select
                  multiple
                  {...multiSelectForm.bind('level')}
                  renderValue={(selected) => (selected as string[]).map(key => levelOptions[key]).join(', ')}
                >
                  {Object.keys(levelOptions).map(key => (
                    <MenuItem key={key} value={key}>
                      <Checkbox checked={multiSelectForm.values.level!.indexOf(key) > -1} />
                      <ListItemText primary={levelOptions[key]} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              
            </Grid> */}

        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        {/* =============================== Area/Price =============================== */}

        <Grid item container xs={12} spacing={1}>
          { /* ----------- captionArea ------------- */}
          {/* <Grid
              item
              md={2}
              xs={6}
            >
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography  style={{ marginRight: theme.spacing(3), fontWeight: 'bold' }}>{langPropertyStock.captionArea + '/' + langPropertyStock.captionPriceOrRent}</Typography>
              </div>

            </Grid> */}

          { /* ----------- net/gross ------------- */}
          <Grid item md={4} xs={12} style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }}>
            {/* <Grid
                item
                xs={6}
              >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Typography>{langPropertyStock.captionArea}</Typography>
                  
                </div>
              </Grid> */}

            {/* <Grid
                container
                item
                md={6}
                xs={6}
                justify={'flex-end'}
              >
                <div style={{ marginLeft: theme.spacing(1), display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Typography>{langPropertyStock.captionNet}</Typography>
                  <Switch
                    checked={isGross}
                    onChange={(_, val) => setIsGross(val)}
                  />
                  <Typography>{langPropertyStock.captionGross}</Typography>
                </div>
              </Grid> */}

            {/* <Grid item md={12} xs={12}>
                <MinMaxField
                  range={isGross ? rangeValueForm.values.gross! : rangeValueForm.values.net!}
                  unit={langCommon.uFt}
                  onChange={(newRange) => {
                    const key = isGross ? 'gross' : 'net';
                    // 
                    rangeValueForm.setValues({
                      ...rangeValueForm.values,
                      [key]: newRange,
                    });
                  }}
                />
              </Grid>
              {preferenceForm.hasError('netAreaFrom') || preferenceForm.hasError('netAreaTo') ?  <FormHelperText error>{preferenceForm.hasError('netAreaFrom')}</FormHelperText> : null}
          */}

          </Grid>

          <Grid item md={4} xs={12} style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }}>

            { /* ----------- price/rent ------------- */}
            {/* <Grid
                item
                md={12}
                xs={12}
              >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Typography>{langPropertyStock.price}</Typography>
                  
                </div>
              </Grid> */}

            {/* <Grid item md={12} xs={12}>
                <MinMaxField
                  range={rangeValueForm.values.price!}
                  unit={locale === 'en' ? langCommon.u1M : langCommon.u10k}
                  onChange={(newRange) => {
                    const key = isRent ? 'rent' : 'price';
                    rangeValueForm.setValues({
                      ...rangeValueForm.values,
                      price: newRange,
                    });
                  }}
                />
              </Grid> */}
            {/* {preferenceForm.hasError('buyBudgetFrom') || preferenceForm.hasError('buyBudgetTo')  ?  <FormHelperText error>{preferenceForm.hasError('buyBudgetFrom')}</FormHelperText> : null} */}

          </Grid>


          <Grid item md={4} xs={12} style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }}>


            {/* <Grid
                item
                md={12}
                xs={12}
              >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  
                  <Typography>{langPropertyStock.rent}</Typography>
                </div>
              </Grid> */}

            {/* <Grid item md={12} xs={12}>
                <MinMaxField
                  range={ rangeValueForm.values.rent! }
                  unit={undefined}
                  onChange={(newRange) => {
                    const key = isRent ? 'rent' : 'price';
                    rangeValueForm.setValues({
                      ...rangeValueForm.values,
                      rent: newRange,
                    });
                  }}
                />
              </Grid> */}
            {preferenceForm.hasError('rentBudgetFrom') || preferenceForm.hasError('rentBudgetTo') ? <FormHelperText error>{preferenceForm.hasError('rentBudgetFrom')}</FormHelperText> : null}

          </Grid>


        </Grid>

        {/* <Grid item xs={12}>
          <Divider />
        </Grid> */}

        {/* =============================== Features =============================== */}

        <Grid
          item
          md={12}
          xs={12}
        >
          <Typography style={{ fontWeight: 'bold' }}>{langPropertyStock.captionFeature}</Typography>
        </Grid>

        { /* ----------- facing------------- */}
        <Grid
          item
          md={2}
          sm={4}
          xs={12}
        >
          {/* <Typography>{langPropertyStock.facing}</Typography> */}

          <FormControl variant="outlined" margin="dense" fullWidth>
            <InputLabel id="facing-label" style={{ background: '#fff' }}>{langPropertyStock.facing}</InputLabel>
            <Select
              multiple
              {...tagForm.bind('facing')}
              renderValue={(selected) => (selected as string[]).map(key => facingOptions[key]).join(', ')}
            >
              {Object.keys(facingOptions).map(view => (
                <MenuItem key={view} value={view}>
                  <Checkbox checked={tagForm.values.facing!.indexOf(view) > -1} />
                  <ListItemText primary={facingOptions[view]} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

        </Grid>

        { /* ----------- deco------------- */}
        <Grid
          item
          md={2}
          sm={4}
          xs={12}
        >
          {/* <Typography>{langPropertyStock.deco}</Typography> */}

          <FormControl variant="outlined" margin="dense" fullWidth>
            <InputLabel id="deco-label" style={{ background: '#fff' }}>{langPropertyStock.deco}</InputLabel>
            <Select
              multiple
              {...tagForm.bind('deco')}
              renderValue={(selected) => (selected as string[]).map(key => decoOptions[key]).join(', ')}
            >
              {Object.keys(decoOptions).map(key => (
                <MenuItem key={key} value={key}>
                  <Checkbox checked={tagForm.values.deco!.indexOf(key) > -1} />
                  <ListItemText primary={decoOptions[key]} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        { /* ----------- view------------- */}
        <Grid
          item
          md={2}
          sm={4}
          xs={12}
        >
          {/* <Typography>{langPropertyStock.view}</Typography> */}
          <FormControl variant="outlined" margin="dense" fullWidth>
            <InputLabel id="view-label" style={{ background: '#fff' }}>{langPropertyStock.view}</InputLabel>
            <Select
              multiple
              {...tagForm.bind('view')}
              renderValue={(selected) => (selected as string[]).map(key => viewOptions[key]).join(', ')}
            >
              {Object.keys(viewOptions).map(view => (
                <MenuItem key={view} value={view}>
                  <Checkbox checked={tagForm.values.view!.indexOf(view) > -1} />
                  <ListItemText primary={viewOptions[view]} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        { /* ----------- otherFeature ------------- */}
        <Grid
          item
          md={2}
          sm={4}
          xs={12}
        >

          {/* <Typography>{langPropertyStock.otherFeature}</Typography> */}
          <FormControl variant="outlined" margin="dense" fullWidth>
            <InputLabel id="otherFeature-label" style={{ background: '#fff' }}>{langPropertyStock.otherFeature}</InputLabel>
            <Select
              multiple
              {...tagForm.bind('otherFeatures')}
              renderValue={(selected) => (selected as string[]).map(key => otherFeatureOptions[key]).join(', ')}
            >
              {Object.keys(otherFeatureOptions).map(feature => (
                <MenuItem key={feature} value={feature}>
                  <Checkbox checked={tagForm.values.otherFeatures!.indexOf(feature) > -1} />
                  <ListItemText primary={otherFeatureOptions[feature]} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        { /* ----------- clubHouseFacilities ------------- */}
        <Grid
          item
          md={2}
          sm={4}
          xs={12}
        >

          {/* <Typography>{langPropertyStock.clubHouseFacilities}</Typography> */}
          <FormControl variant="outlined" margin="dense" fullWidth>
            <InputLabel id="clubHouseFacilities-label" style={{ background: '#fff' }}>{langPropertyStock.clubHouseFacilities}</InputLabel>
            <Select
              multiple
              {...tagForm.bind('clubHouseFacilities')}
              renderValue={(selected) => (selected as string[]).map(key => clubHouseFacilitiesOptions[key]).join(', ')}
            >
              {Object.keys(clubHouseFacilitiesOptions).map(key => (
                <MenuItem key={key} value={key}>
                  <Checkbox checked={tagForm.values.clubHouseFacilities!.indexOf(key) > -1} />
                  <ListItemText primary={clubHouseFacilitiesOptions[key]} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        { /* ----------- others ------------- */}
        <Grid
          item
          md={2}
          sm={4}
          xs={12}
        >

          {/* <Typography>{langPropertyStock.others}</Typography> */}
          <FormControl variant="outlined" margin="dense" fullWidth>
            <InputLabel id="others-label" style={{ background: '#fff' }}>{langPropertyStock.others}</InputLabel>
            <Select
              multiple
              {...tagForm.bind('others')}
              renderValue={(selected) => (selected as string[]).map(key => otherOptions[key]).join(', ')}
            >
              {Object.keys(otherOptions).map(key => (
                <MenuItem key={key} value={key}>
                  <Checkbox checked={tagForm.values.others!.indexOf(key) > -1} />
                  <ListItemText primary={otherOptions[key]} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>


        { /* ----------- room ------------- */}
        <Grid
          item
          md={1}
          sm={2}
          xs={6}
        >

          {/* <Typography>{langPropertyStock.room}</Typography> */}

          <FormControl variant="outlined" margin="dense" fullWidth>
            <InputLabel id="room-label" style={{ background: '#fff' }}>{langPropertyStock.room}</InputLabel>
            <Select
              multiple
              {...multiSelectForm.bind('room')}
              renderValue={(selected) => (selected as string[]).sort().join(', ')}
            >

              {Object.keys(numberOptions).map(v => (
                <MenuItem key={v} value={v}>
                  <Checkbox checked={multiSelectForm.values.room!.indexOf(v) > -1} />
                  <ListItemText primary={v} />
                </MenuItem>
              ))}

            </Select>
          </FormControl>
        </Grid>

        { /* ----------- suite ------------- */}
        <Grid
          item
          md={1}
          sm={2}
          xs={6}
        >
          {/* <Typography>{langPropertyStock.suite}</Typography> */}


          <FormControl variant="outlined" margin="dense" fullWidth>
            <InputLabel id="suite-label" style={{ background: '#fff' }}>{langPropertyStock.suite}</InputLabel>
            <Select
              multiple
              {...multiSelectForm.bind('suite')}
              renderValue={(selected) => (selected as string[]).sort().join(', ')}
            >

              {Object.keys(numberOptions).map(v => (
                <MenuItem key={v} value={v}>
                  <Checkbox checked={multiSelectForm.values.suite!.indexOf(v) > -1} />
                  <ListItemText primary={v} />
                </MenuItem>
              ))}

            </Select>
          </FormControl>
        </Grid>

        { /* ----------- helperRoom ------------- */}
        <Grid
          item
          md={1}
          sm={2}
          xs={6}
        >
          {/* <Typography>{langPropertyStock.helperRoom}</Typography> */}

          <FormControl variant="outlined" margin="dense" fullWidth>
            <InputLabel id="helperRoom-label" style={{ background: '#fff' }}>{langPropertyStock.helperRoom}</InputLabel>
            <Select
              multiple
              {...multiSelectForm.bind('helperRoom')}
              renderValue={(selected) => (selected as string[]).sort().join(', ')}
            >

              {Object.keys(numberOptions).map(v => (
                <MenuItem key={v} value={v}>
                  <Checkbox checked={multiSelectForm.values.helperRoom!.indexOf(v) > -1} />
                  <ListItemText primary={v} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>



        { /* ----------- bathroom ------------- */}
        <Grid
          item
          md={1}
          sm={2}
          xs={6}
        >
          {/* <Typography>{langPropertyStock.bathroom}</Typography> */}

          <FormControl variant="outlined" margin="dense" fullWidth>
            <InputLabel id="bathroom-label" style={{ background: '#fff' }}>{langPropertyStock.bathroom}</InputLabel>
            <Select
              multiple
              {...multiSelectForm.bind('bathroom')}
              renderValue={(selected) => (selected as string[]).sort().join(', ')}
            >

              {Object.keys(numberOptions).map(v => (
                <MenuItem key={v} value={v}>
                  <Checkbox checked={multiSelectForm.values.bathroom!.indexOf(v) > -1} />
                  <ListItemText primary={v} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        { /* ----------- balcony ------------- */}
        <Grid
          item
          md={1}
          sm={2}
          xs={6}
        >
          {/* <Typography>{langPropertyStock.balcony}</Typography> */}

          <FormControl variant="outlined" margin="dense" fullWidth>
            <InputLabel id="balcony-label" style={{ background: '#fff' }}>{langPropertyStock.balcony}</InputLabel>
            <Select
              multiple
              {...multiSelectForm.bind('balcony')}
              renderValue={(selected) => (selected as string[]).sort().join(', ')}
            >

              {Object.keys(numberOptions).map(v => (
                <MenuItem key={v} value={v}>
                  <Checkbox checked={multiSelectForm.values.balcony!.indexOf(v) > -1} />
                  <ListItemText primary={v} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        { /* ----------- primarySchoolNet ------------- */}
        <Grid
          item
          md={3}
          sm={6}
          xs={12}
        >

          {/* <Typography>{langPropertyStock.primarySchoolNet}</Typography> */}
          <FormControl variant="outlined" margin="dense" fullWidth>
            <InputLabel id="primarySchoolNet-label" style={{ background: '#fff' }}>{langPropertyStock.primarySchoolNet}</InputLabel>
            <Select
              multiple
              {...tagForm.bind('primarySchoolNet')}
              renderValue={(selected) => (selected as string[]).map(key => primarySchoolNetOptions[key]).join(', ')}
            >
              {Object.keys(primarySchoolNetOptions).map(key => (
                <MenuItem key={key} value={key}>
                  <Checkbox checked={tagForm.values.primarySchoolNet!.indexOf(key) > -1} />
                  <ListItemText primary={primarySchoolNetOptions[key]} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>


        { /* ----------- secondarySchoolNet ------------- */}
        <Grid
          item
          md={4}
          sm={6}
          xs={12}
        >

          {/* <Typography>{langPropertyStock.secondarySchoolNet}</Typography> */}
          <FormControl variant="outlined" margin="dense" fullWidth>
            <InputLabel id="secondarySchoolNet-label" style={{ background: '#fff' }}>{langPropertyStock.secondarySchoolNet}</InputLabel>
            <Select
              multiple
              {...tagForm.bind('secondarySchoolNet')}
              renderValue={(selected) => (selected as string[]).map(key => secondarySchoolNetOptions[key]).join(', ')}
            >
              {Object.keys(secondarySchoolNetOptions).map(key => (
                <MenuItem key={key} value={key}>
                  <Checkbox checked={tagForm.values.secondarySchoolNet!.indexOf(key) > -1} />
                  <ListItemText primary={secondarySchoolNetOptions[key]} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>



        {/* <Grid item xs={12}>
          <Divider />
        </Grid> */}

        {/* =============================== Reason, attitude HIDDEN =============================== */}

        {/* <Grid
          item
          md={12}
          xs={12}
        >
          <Typography style={{ fontWeight: 'bold' }}>{langClientDetail.captionOthers}</Typography>
        </Grid> */}


        { /* ----------- reason ------------- */}
        {/* <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label={langClientDetail.reason}
                margin="dense"
                // required
                select
                variant="outlined"
                {...preferenceForm.bind('reason')}
              >
                  {Object.keys(reasonOptions).map(v => <MenuItem value={v}>{reasonOptions[v]}</MenuItem>)}

              </TextField>
            </Grid> */}

        { /* ----------- attitude ------------- */}
        {/* <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label={langClientDetail.attitude}
                margin="dense"
                // required
                select
                variant="outlined"
                {...preferenceForm.bind('attitude')}
              >
                  {Object.keys(attitudeOptions).map(v => <MenuItem value={v}>{attitudeOptions[v]}</MenuItem>)}

              </TextField>
            </Grid> */}



      </Grid>
    </CardContent>
  </Card>;
};

export default Preferences;