import palette from '../palette';
import typography from '../typography';

export default {
  root: {
    ...typography.body1,
    borderBottom: `1px solid ${palette.divider}`,
    paddingLeft: '8px',
    paddingRight: '3px',
  },
  head: {
    fontWeight: 'bold',
  },
  body: {
    paddingLeft: '8px',
    paddingRight: '3px',
  },
  sizeSmall: {
    paddingLeft: '8px',
    paddingRight: '3px',
  }
};
