import { colors } from '@material-ui/core';
import MuiButton from './MuiButton';
import MuiIconButton from './MuiIconButton';
import MuiPaper from './MuiPaper';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTableRow from './MuiTableRow';
import MuiTypography from './MuiTypography';

export default {
  MuiButton,
  MuiIconButton,
  MuiPaper,
  MuiTableCell,
  MuiTableHead,
  MuiTableRow,
  MuiTypography,
  MuiInputLabel: {
    root: {
      color: colors.grey[600],
    },
  },
  MuiFormControl: {
    'marginDense': {
      marginTop: 4,
      marginBottom: 0,
    },
  },
  MuiOutlinedInput: {
    root: {
      '&$disabled $notchedOutline, &[readonly] $notchedOutline': {
        borderColor: colors.grey[400],
      },
      '&$disabled, &[readonly]': {
        color: colors.common.black,
        background: 'rgb(250, 250, 250)',
      }
    },
  },
  MuiListSubheader: {
    root: {
      background: colors.common.white,
    }
  },
};
