import { UserDTO } from "common/dto";
import { FormValidations } from "common/hooks";
import { checkValidEmail, isNonEmpty } from "common/utils";
import { isNullOrUndefined } from 'util';
import { getEmptyContactValueIndexArray, getInvalidContactIndexArrayByEmail, getInvalidContactIndexArrayByPhoneNum } from 'views/ClientDetail/components/client-validations';

type Options = { [key: string]: string }

const userFormValidations = (langUser: Options, creating = true): FormValidations<UserDTO> => [
  (values) => values.username ? null : [
    ['username', langUser.msgUsernameRequired],
  ],
  (values) => (values.chineseName && isNonEmpty(values.chineseName.trim())) || (values.englishName && isNonEmpty(values.englishName.trim())) ? null : [
    ['chineseName', langUser.msgPleaseEnterChinOrEngName],
    ['englishName', langUser.msgPleaseEnterChinOrEngName],
  ],
  // (values) => values.password || !creating ? null : [
  //   [ 'password', langUser.msgPasswordRequired ],
  // ],
  (values) => (values.roles?.length ?? 0) > 0 ? null : [
    ['roles', langUser.msgSelectAtLeastOneRole],
  ],
  // (values) => (values.contacts?.filter(c => c.value?.length).length ?? 0) > 0 ? null : [
  //   [ 'contacts', '(AT_LEAST_ONE_CONTACT)' ],
  // ],
  (values) => checkValidEmail(values.email ?? '') ? null : [
    ['email', isNullOrUndefined(values.email) ? langUser.msgEmailRequired : langUser.msgInputValidEmail],
  ],
  //other contacts
  (values) => getInvalidContactIndexArrayByPhoneNum(values, 'contacts').length == 0 ? null : [
    ['invalidPhoneNumber', getInvalidContactIndexArrayByPhoneNum(values, 'contacts').join('_')],
  ],
  (values) => getInvalidContactIndexArrayByEmail(values, 'contacts').length == 0 ? null : [
    ['invalidEmail', getInvalidContactIndexArrayByEmail(values, 'contacts').join('_')],
  ],
  (values) => getEmptyContactValueIndexArray(values, 'contacts').length == 0 ? null : [
    ['emptyContactValue', getEmptyContactValueIndexArray(values, 'contacts').join('_')],
  ],
  (values) => values.status ? null : [
    ['status', langUser.msgSelectStatus],
  ],
  (values) => values.roles?.includes('AGENT') && !isNonEmpty(values.licenseNumber) ? [
    ['licenseNumber', langUser.msgInputLicenseNumber],
  ] : null,
  (values) => values.actAsTeamHead && !isNonEmpty(values.teamName) ? [
    ['teamName', langUser.msgInputTeamName],
  ] : null,
];

export default userFormValidations;