import Step from '@material-ui/core/Step';
import { StepIconProps } from '@material-ui/core/StepIcon';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import SettingsIcon from '@material-ui/icons/Settings';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import clsx from 'clsx';
import React from 'react';

const completedColor = '#33A64C';
const currentStepColor = '#4285F4';

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 30,
    height: 30,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: currentStepColor,
    width: 45,
    height: 45,
  },
  completed: {
    backgroundColor: completedColor,
  },
});

function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {String(props.icon)}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    labelContainer: {
      "& $alternativeLabel": {
        marginTop: 5,
        whiteSpace:'pre-line',
        // color:'red',
      }
    },
    alternativeLabel: {
    },
    currentStep:{
      "& $alternativeLabel": {
        color: currentStepColor,
        fontSize: '15px',
      }
    },
    completedStep:{
      "& $alternativeLabel": {
        color: completedColor,
      }
    }
  }),
);

interface FormSigningStepperProps{
	steps: string[];
	activeStep: number;
}

const FormSigningStepper = (props: FormSigningStepperProps) => {
	const { steps, activeStep } = props;
  const classes = useStyles();
 
  return (
    <div className={classes.root}>
      
			<Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel classes={{
            alternativeLabel: activeStep === index ? clsx(classes.alternativeLabel, classes.currentStep)  :
                              index < activeStep ? clsx(classes.alternativeLabel, classes.completedStep) : classes.alternativeLabel,
            labelContainer: classes.labelContainer
          }} 
          StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

export default FormSigningStepper;