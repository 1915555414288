import { Divider, Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
// import ImageIcon from '@material-ui/icons/Image';
import HouseIcon from '@material-ui/icons/House';
import PaymentIcon from '@material-ui/icons/Payment';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Profile, SidebarNav } from './components';


const useStyles = makeStyles(theme => ({
  drawer: {
    width: 220,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

export const getPages = () => [
  {
    title: '儀表板',
    href: '/dashboard',
    icon: <DashboardIcon />
  },
  {
    title: '樓盤存貨',
    // href: '/account',
    icon: <HouseIcon />,
    subMenu: [
      {
        title: '新增樓盤',
        href: '/account',
      },
      // {
      //   title: '新增租盤',
      // },
      {
        title: '樓盤搜尋',
        href: '/properties',
      }
    ]
  },
  {
    title: '成交記錄',
    // href: '/products',
    icon: <PaymentIcon />
  },
  {
    title: '客戶檔案',
    // href: '/sign-in',
    icon: <PeopleIcon />,
    subMenu: [
      {
        title: '新增客戶',
        href: '/client-detail',
      },
      // {
      //   title: '買家檔案',
      // }
    ]
  },
  {
    title: '大廈檔案',
    // href: '/typography',
    icon: <TextFieldsIcon />
  },
  // {
  //   title: 'Icons',
  //   href: '/icons',
  //   icon: <ImageIcon />
  // },
  // {
  //   title: 'Account',
  //   href: '/account',
  //   icon: <AccountBoxIcon />
  // },
  {
    title: '系統設定',
    // href: '/settings',
    icon: <SettingsIcon />
  }
];

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const pages = getPages();

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />
        {/* <UpgradePlan /> */}
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
