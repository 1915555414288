import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { blue } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useConfirmDialog } from 'common/ConfirmDialog';
import { ClientListItemDTO } from 'common/dto';
import { ClientDetailIcon, SearchIcon } from 'common/ui';
import { multiLang } from 'common/utils';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { IRootState, PASDispatch } from 'reducers';
import { format } from 'util';



type ClientType = ClientListItemDTO;

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  searchField: {
    paddingLeft: 8,
    paddingRight: 8,
  },
  usageTag: {
    textAlign: 'right',
    borderRadius: 3,
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    borderStyle: 'solid',
    padding: 2,
    color: theme.palette.primary.main,
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap',
  },
  primaryText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dialogRoot: {
    minWidth: 300,
  },
}));

interface ClientMergeDialogProps {
  onClose: () => void;
  open: boolean;
  onSearch: (kw?: string[]) => void;
  clients: ClientType[];

  onSelectClient: (client: ClientType) => void;
  onSwitchMergeDirection: () => void;
  onCancel: () => void;
  onMerge: () => void;
  stage: 'select' | 'merge';

  mergeClientA: ClientType | undefined;
  mergeClientB: ClientType | undefined;
  addToAlias: boolean;
  onAddToAliasChange: (val: boolean) => void;
}

const ClientMergeDialogContainer = () => {

  const dispatch = useDispatch() as PASDispatch;
  const history = useHistory();

  const {
    mergeClientA, mergeClientB, stage, open, mergeCandidates,
  } = useSelector((state: IRootState) => state.clientMerge);

  const handleSelectClient = (value: ClientType) => {
    dispatch({ type: 'ClientMerge.SelectB', payload: value });
    dispatch({ type: 'ClientMerge.MergeRequested' });
  };

  const onClose = () => {
    dispatch({ type: 'ClientMerge.DialogOpen', payload: false });
  };

  const swapBuilding = () => {
    dispatch({ type: 'ClientMerge.Swap' });
  };


  const handleSearch = async (kw: string[] = []) => {
    if (kw.length == 0) {
      return;
    }
    // const { data } = await clientApi.getList({
    //   matchAnyContact: kw,
    //   includeOwnedOnly: true,
    // }, token ?? '');
    // setClients(data?.content ?? []);
  };

  const handleMerge = () => {
    dispatch({ type: 'ClientMerge.MergeRequested' });
    onClose();
  };

  return <ClientMergeDialog
    mergeClientA={mergeClientA}
    mergeClientB={mergeClientB}
    stage={stage}
    open={open}
    onClose={onClose}
    onSelectClient={handleSelectClient}
    onSwitchMergeDirection={swapBuilding}
    onCancel={() => {
      onClose();
      history.push(`/client-detail/${mergeClientA?.cid}`);
    }}
    onMerge={handleMerge}
    onSearch={handleSearch}
    addToAlias={false}
    onAddToAliasChange={(val) => dispatch({ type: 'ClientMerge.UpdateAddToAlias', payload: val })}
    clients={mergeCandidates}
  />
};

export default ClientMergeDialogContainer;

function ClientMergeDialog(props: ClientMergeDialogProps) {
  const classes = useStyles();
  const confirmDialog = useConfirmDialog();
  const { stage,
    onClose, open,
    onSearch, clients,
    onSelectClient, onSwitchMergeDirection, onCancel, onMerge,
    mergeClientA, mergeClientB,
    addToAlias, onAddToAliasChange,
  } = props;
  const theme = useTheme();

  const { locale, langClientDetail, lang, clientTypeOptions, contactOptions } = useSelector((state: IRootState) => state.locale);

  const handleListItemClick = (value: ClientType) => {
    confirmDialog.confirm(langClientDetail.msgConfirmToMerge, lang.actionYes, lang.actionNo).then(confirmed => {
      if (confirmed) {
        onSelectClient(value);
      }
    });
  };

  const handleCancel = () => {
    confirmDialog.confirm(langClientDetail.msgConfirmNotToMerge, lang.actionYes, lang.actionNo).then(confirmed => {
      if (confirmed) {
        onCancel?.();
      }
    });
  };

  const clientPrimaryText = (client: ClientType | undefined, i: number) => {
    const name = multiLang(locale, client?.chineseName, client?.englishName) ?? '';
  
    return <div className={classes.primaryText}>
      <div>{name}</div>
      <div>
        <div className={classes.usageTag}>{clientTypeOptions[client?.clientPreferences.clientType?.split(',')[0] ?? '']}</div>
      </div>
    </div>;
  }

  const clientSecondaryText = (client: ClientType | undefined, i: number) => {
    return <div>
      {client?.otherContacts.map(contact => <div>
        <b>{contactOptions[contact.type]}:</b>&nbsp;
        {contact.value}
      </div>)}
    </div>;
  }

  const selectionView = () => <React.Fragment>
    <DialogTitle>
      <div>{format(langClientDetail.msgMergeClientHavingContact, mergeClientA?.chineseName)}</div>

      <ListItem>
        <ListItemAvatar>
          <Avatar className={classes.avatar}>
            <ClientDetailIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={clientPrimaryText(mergeClientA, 0)}
          secondary={clientSecondaryText(mergeClientA, 0)}
        />
      </ListItem>
      <div style={{ paddingTop: '1rem' }}>
        {langClientDetail.msgMergeClientChoosingToMerge}
      </div>
    </DialogTitle>

    {
      clients.length > 0 ? null : <Typography style={{  padding: 16, width: '100%', textAlign: 'center' }}>
        <SearchIcon style={{ fontSize: 35, color: '#f0f0f0' }} />
      </Typography>
    }

    <List style={{ overflowY: 'auto' }}>
      {clients.map((client, i) => (
        <ListItem button onClick={() => handleListItemClick(client)} key={client.cid ?? `new-client-${i}`}>
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              <ClientDetailIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={clientPrimaryText(client, i)}
            secondary={clientSecondaryText(client, i)}
          />
        </ListItem>
      ))}
    </List>

    <DialogActions>
      <Button color="primary" onClick={handleCancel}>{langClientDetail.actionCancelRetainClaimedClient}</Button>
    </DialogActions>
  </React.Fragment>

  return (
    <Dialog onClose={handleCancel}
      open={open}
      PaperProps={{ className: classes.dialogRoot }}
    >
      {confirmDialog.render()}
      {selectionView()}
    </Dialog>
  );
}