import { Button, Divider, Grid, IconButton, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import FacebookIcon from '@material-ui/icons/Facebook';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { propertyApi } from 'api';
import { hasPermission } from 'common/access-control';
import { PropertyStockDTO } from 'common/dto';
import { ContactRemarksIcon, WeChatIcon } from 'common/ui';
import { isNonEmpty, multiLang } from 'common/utils';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducers';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			'& > *': {
				margin: theme.spacing(1),
			},
		},
		nameCard:{
			position: 'relative',
			display: 'flex',
			flexDirection: 'column',
			'& > *': {
				// margin: theme.spacing(1),
			},
			// border: '1px solid rgb(0,0,0, .3)',
			// borderRadius: '5px',
			// minWidth: '150px',
			cursor: 'pointer',
			justifyContent: 'center',
			alignItems: 'center',
		},
		nameCardAvatar: {
      width: theme.spacing(6),
      height: theme.spacing(6),
			border: '1px solid #a8acb3',
		},
		dialogAvatar: {
      width: theme.spacing(8),
      height: theme.spacing(8),
    	border: '1px solid #a8acb3',
		},
		agentName: {
			paddingTop: '3px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		dialogSectionLabel:{
			color: 'rgb(0,0,0, .4)'
		},
		clientDetailRow:{
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
		},
		clientDetailTypography:{
			paddingLeft: '5px',
		},
		dialogTitle:{
			display:'flex', 
			justifyContent:'space-between',
			padding: '3px 12px 3px 24px', 
			alignItems:'center'
		}

	}),
);

const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];

interface LeadAgentNameCardProps{
	agentPhotoFilename: string;
	agentNameEn: string;
	agentNameZh: string;
	agentContactType: string
	agentContactValue: string;

	showClientContact?:boolean;
	clientNameEn?: string; //propertyStockDTO.sellerEn
	clientNameZh?: string; //propertyStockDTO.sellerZh
	clientContactType?: string;
	clientContactValue?: string; //propertyStockDTO.mainContact
	clientContactRemarks?: string; //propertyStockDTO.mainContactRemarks

	showDeleteButton?: boolean;
	handleRemoveLeadAgent?: () => any;
	altBorderColor?: string;
	propertyStockId: number;
	clientId: number;
}

export default function LeadAgentNameCard(props: LeadAgentNameCardProps) {
	const classes = useStyles();
  const { locale, langPropertyStock } = useSelector((state: IRootState) => state.locale);
  const token = useSelector((state: IRootState) => state.login.token) ?? '';
	const dispatch = useDispatch();
	
	const [ openDetailDialog, setOpenDetailDialog ] = React.useState(false);
	const [ revealedSellerInfo, setRevealedSellerInfo ] = React.useState<PropertyStockDTO['leadAgents'][0] | null>(null);
	const privileges = useSelector((state: IRootState) => state.login.privileges);

	const mergedProps: LeadAgentNameCardProps = {
		...props,
		...(revealedSellerInfo ? {
			clientNameEn: revealedSellerInfo.clientEnglishName,
			clientNameZh: revealedSellerInfo.clientChineseName,
			clientContactType: revealedSellerInfo.mainContactType,
			clientContactValue: revealedSellerInfo.mainContact,
			clientContactRemarks: revealedSellerInfo.mainContactRemarks,
			showClientContact: true,
		} : {}),
	}

	const { agentNameEn, agentNameZh, agentPhotoFilename } = mergedProps;

  const handleCloseDetailDialog = () => {
    setOpenDetailDialog(false);
  };

	return (<div>
		<div className={classes.nameCard} onClick={()=>{setOpenDetailDialog(true)}}>
			<Avatar  className={classes.nameCardAvatar} style={{ borderColor: props.altBorderColor }}
				src={isNonEmpty(agentPhotoFilename) ? `${BASE_URL}/files/${agentPhotoFilename}` :''}
			/>
			<Typography className={classes.agentName}>{multiLang(locale,agentNameZh,agentNameEn)}</Typography>
			
		</div>
		<AgentDetailDialog {...mergedProps}	
			open={openDetailDialog}
			handleClose={handleCloseDetailDialog}
			onRevealSellerInfo={hasPermission(privileges, 'READ', 'REVEALED_SELLER_INFO') && mergedProps.propertyStockId && mergedProps.clientId && !mergedProps.showClientContact ? () => {
				dispatch({ type: 'Layout.MaskPresentRequested' });
				propertyApi.requestSellerInfoReveal(mergedProps.propertyStockId, mergedProps.clientId, token).then(result => {
					dispatch({ type: 'Layout.MaskDismissRequested' });
					dispatch({ type: 'Layout.AlertMessageAdded', payload: { message: langPropertyStock.msgSellerInfoRevealed , severity: 'error' } })
					if (result.data) {
						setRevealedSellerInfo(result.data);
					}
				});
			} : undefined}
		/>
		</div>
	);
}

interface AgentDetailDialogProps extends LeadAgentNameCardProps{
	open: boolean;
  handleClose: () => void;
	onRevealSellerInfo?: () => void;
}

const AgentDetailDialog = (props: AgentDetailDialogProps) =>{
	const classes = useStyles();
	const { open, handleClose, agentNameEn, agentNameZh, agentPhotoFilename, agentContactType, 
		agentContactValue, clientNameEn, clientNameZh, clientContactValue , clientContactType,
		clientContactRemarks, showClientContact, showDeleteButton, handleRemoveLeadAgent,
	} = props;
  const { langPropertyStock, locale, contactOptions} = useSelector((state: IRootState) => state.locale);
	
	const printContactTypeIcon = () =>{
		return (
			clientContactType === 'TEL' ? <PhoneIcon/> :
			clientContactType === 'FACEBOOK' ? <FacebookIcon/> :
			clientContactType === 'WHATSAPP' ? <WhatsAppIcon/> :
			clientContactType === 'EMAIL' ? <MailOutlineIcon/> : 
			clientContactType === 'WECHAT' ? <WeChatIcon/> : 
			<Typography>{`${clientContactType}: `}</Typography>
		)
	}

  return (
    <div>
      <Dialog fullWidth={true} maxWidth="xs" open={open} onClose={handleClose} aria-labelledby="agent-detail-dialog-title">
        <div  className={classes.dialogTitle}  >
					{langPropertyStock.titleAgentAndClientDetail}
					<IconButton onClick={handleClose} >
						<CloseIcon/>
					</IconButton>
				</div>
        <Divider/>
				<DialogContent>
					<Grid
						item
						container
						md={12}
						sm={12}
						xs={12}
						spacing={1}
					>

						{/* ------ agent photo and name ------  */}
						<Grid
							item container
							md={12}
							sm={12}
							xs={12}
							style={{ display: 'flex', justifyContent: 'center' }}
						>
							<div style={{ display: 'flex', flexDirection: 'row' }}>
								<Avatar className={classes.dialogAvatar}
									src={isNonEmpty(agentPhotoFilename) ? `${BASE_URL}/files/${agentPhotoFilename}` :''}
								/>
								<Typography style={{ paddingLeft: '10px' }} className={classes.agentName}>{multiLang(locale, agentNameZh, agentNameEn)}</Typography>
							</div>
						</Grid>

						{/* ------ agent contact ------  */}
						<Grid
							item
							md={12}
							sm={12}
							xs={12}
						>
							<Typography className={classes.dialogSectionLabel}>{contactOptions[agentContactType]}</Typography>
							
						</Grid>

						<Grid
							item
							md={12}
							sm={12}
							xs={12}
						>
							{agentContactValue}
						</Grid>

						{/* ------ client detail ------  */}
						{showClientContact ? <div>
						<Grid
							item
							md={12}
							sm={12}
							xs={12}
							style={{paddingTop: '15px'}}
						>
							<Typography className={classes.dialogSectionLabel}>{langPropertyStock.captionAssociatedClient}</Typography>
						</Grid>

						{/* ------ client name ------  */}
						<Grid
							item
							md={12}
							sm={12}
							xs={12}
							className={classes.clientDetailRow}
						>
							<PersonIcon/>
							<Typography className={classes.clientDetailTypography}>{isNonEmpty(clientNameZh) && isNonEmpty(clientNameEn)? `${clientNameZh} ${clientNameEn}` : isNonEmpty(clientNameZh) ? clientNameZh : clientNameEn }</Typography>
						</Grid>

						{/* ------ client contact value ------  */}
						{clientContactValue ? <Grid
							item
							md={12}
							sm={12}
							xs={12}
							className={classes.clientDetailRow}
						>
							{printContactTypeIcon()}
							<Typography className={classes.clientDetailTypography}>{clientContactValue}</Typography>
						</Grid> : null}

						{/* ------ client contact remarks ------  */}
						{isNonEmpty(clientContactRemarks) ?
						<Grid
							item
							md={12}
							sm={12}
							xs={12}
							className={classes.clientDetailRow}
						>
							<ContactRemarksIcon/>
							<Typography style={{paddingLeft: '8px'}} className={classes.clientDetailTypography}>{clientContactRemarks}</Typography>
						</Grid>
						: null}

					</div>: null}


					</Grid>

				</DialogContent>
				<DialogActions style={{justifyContent: 'flex-start', paddingLeft: '15px'}}>
					{props.onRevealSellerInfo && 
						<Button  // startIcon={<DeleteIcon/>}
							style={{ marginTop: 7 }}
							color="default" variant="text"
							onClick={props.onRevealSellerInfo}
						>
							{langPropertyStock.actionRevealSellerInfo}
						</Button>
					}
					{showDeleteButton && 
						<Button  startIcon={<DeleteIcon/>}
							style={{ marginTop: 7, color: '#ff3224' }}
							color="default" variant="text"
							onClick={handleRemoveLeadAgent}
						>
							{langPropertyStock.actionRemoveLeadAgent}
						</Button>
					}
				</DialogActions>
			</Dialog>
    </div>
  );
} 


