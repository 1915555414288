import {
  Card,
  CardActions,
  CardContent, colors, Divider,
  Table,
  TableBody,
  TableCell,
  TableHead, TableRow, TableSortLabel,
  // TablePagination,
  Theme
} from '@material-ui/core';
import { CardProps } from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { CommissionReportListItemDTO } from 'common/dto';
import TablePagination from 'common/elements/TablePagination';
import { handlePriceDisplay, handlePriceDisplayUnit, multiLang } from 'common/utils';
import moment from 'moment';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';

const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-start',
    background: colors.grey[50],
    flexWrap: 'wrap',
    '& > *': {
      wordBreak: 'keep-all',
      // marginBottom: '8px',
    },
    padding: '0px',
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableCell: {
    wordWrap: 'normal',
    wordBreak: 'keep-all',
    lineBreak: 'strict',
    whiteSpace: 'nowrap',
    // minWidth: 128,
  },
  tablePaginationIconButton: {
    '& .MuiIconButton-root': {
      padding: '6px',
    }
  },
  tablePaginationToolbar: {
    paddingRight: '12px',
  },
  clearSortingContainer: {
    padding: '3px',
  }
}));

type Header = {
  title: string | JSX.Element,
  overrideStyle?: any,
};

interface CommissionReportTableProps extends CardProps {
  className?: string;
  reportList: CommissionReportListItemDTO[];

  page: number;
  totalPages: number;
  totalElements: number;
  limit: number;
  rowsCountOptions: number[];
  handleRowsCount: (count: number) => void;
  setPage: (page: number) => void;
  sortOrders?: { [k: string]: 'desc' | 'asc' };
  handleSort?: (key: string | null) => void;
}

const CommissionReportTable = (props: CommissionReportTableProps) => {
  const { className, reportList, sortOrders, handleSort, page, totalPages, totalElements, limit, setPage, rowsCountOptions, handleRowsCount, ...rest } = props;

  const { locale, usageOptions, salesCommissionTypeOptions, districtHkiOptions, districtKltOptions, districtNtOptions, langTransaction: lang, lang: langCommon, langUser } = useSelector((state: IRootState) => state.locale);
  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);
  const classes = useStyles();

  const districtOptions = {
    ...districtHkiOptions,
    ...districtKltOptions,
    ...districtNtOptions,
  };

  const token: string = useSelector((state: IRootState) => state.login.token ?? '');
  const handleNaviageToItem = (pid: string) => () => {
    sessionStorage.setItem('lastClickedCommissionReport', pid);
    // history.push(`/transactions/${id}`);
    window.open(`${BASE_URL}/commissionReport/generate/${locale}/${pid}/pdf?t=${token}`);
    setPage(page);
  }

  const handlePageChange = (event: React.MouseEvent | null, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleRowsCount(+event.target.value);
  };


  let tableHeaders: Record<any, Header | null> = { // keyof PropertyListItemDTO
    // checkboxes: <Checkbox
    //   checked={selectedProperties.length === properties.length}
    //   color="primary"
    //   indeterminate={
    //     selectedProperties.length > 0 &&
    //     selectedProperties.length < properties.length
    //   }
    //   onChange={handleSelectAll}
    // />,
    // id: null,
    transactionDate: { title: lang.transactionDate },
    usage: { title: lang.usage },
    district: { title: lang.district },
    [locale === 'en' ? 'streetEn' : 'streetZh']: { title: lang.street },
    [locale === 'en' ? 'buildingNameEn' : 'buildingNameZh']: { title: lang.building },
    [locale === 'en' ? 'blockEn' : 'blockZh']: { title: lang.block },
    floor: { title: lang.floor },
    unit: { title: lang.unit },

    price: { title: lang.priceOrRent, overrideStyle: { minWidth: 128 } },
    'tranType': { title: lang.type },
    'buyerNamesAll': { title: lang.buyer },
    'sellerNamesAll': { title: lang.seller },
  };

  const overrideCols: { [col: string]: (transaction: CommissionReportListItemDTO) => any } = {
    // status: (transaction) => propertyStockStatusOptions[transaction.status ?? ''],

    [locale === 'en' ? 'streetEn' : 'streetZh']:
      (transaction) => multiLang(locale, transaction.streetZh, transaction.streetEn),
    [locale === 'en' ? 'buildingNameEn' : 'buildingNameZh']:
      (transaction) => multiLang(locale, transaction.buildingNameZh, transaction.buildingNameEn),
    [locale === 'en' ? 'blockEn' : 'blockZh']:
      (transaction) => multiLang(locale, transaction.blockZh, transaction.blockEn),

    usage: (transaction) => usageOptions[transaction.usage],
    district: (transaction) => districtOptions[transaction.district],
    price: (transaction) => String(transaction.type == "SALE" ? (handlePriceDisplay(transaction.price ?? 0, locale) + handlePriceDisplayUnit(transaction.price ?? 0, locale, langCommon)) : transaction.price),
    'tranType': (transaction) => salesCommissionTypeOptions[transaction.type],
    'buyerNamesAll': (transaction) => transaction.buyerName ?? '',
    'sellerNamesAll': (transaction) => transaction.sellerName ?? '',
    transactionDate: (transaction) => transaction.transactionDate ? moment(transaction.transactionDate).format(DISPLAY_DATE_FORMAT) : '',
    floor: (transaction) => transaction.floor ?? '',
    unit: (transaction) => transaction.unit ?? '',

  }

  const tableBodyStyle: { [key: string]: React.CSSProperties } = {
    price: { textAlign: 'right' },
    transactionDate: { textAlign: 'left' },
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardActions className={classes.actions}>
        <div style={{ flexGrow: 1 }} />

        {/* {Object.keys(sortOrders ?? {}).length ?
          <div className={classes.clearSortingContainer}>
            <Button style={{ marginRight: 0 }} variant="outlined" size="small" onClick={() => handleSort?.(null)}>{lang.actionClearSorting}</Button>
          </div>
        : null} */}

        <TablePagination
          classes={{
            actions: classes.tablePaginationIconButton,
            toolbar: classes.tablePaginationToolbar,
          }}
          // component="div"
          count={totalElements}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          totalPages={totalPages}
          rowsPerPage={limit}
          labelRowsPerPage={langUser.rowsCount}
          rowsPerPageOptions={rowsCountOptions}
        // rowsPerPageOptions={[20]}
        />
      </CardActions>

      <Divider />

      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {
                    Object.keys(tableHeaders).filter((key) => !!tableHeaders[key as keyof CommissionReportListItemDTO]).map((key, idx) =>
                      <TableCell key={idx} className={classes.tableCell} style={tableHeaders[key as keyof CommissionReportListItemDTO]?.overrideStyle}>
                        <TableSortLabel active={(key === 'transactionDate' && sortOrders !== undefined && sortOrders?.[key] !== undefined && Object.keys(sortOrders)[0] === 'transactionDate') || (key !== 'transactionDate' && sortOrders?.[key] !== undefined)} direction={sortOrders?.[key]} onClick={() => handleSort?.(key)}>
                          {tableHeaders[key as keyof CommissionReportListItemDTO]?.title}
                        </TableSortLabel>
                      </TableCell>
                    )
                  }
                </TableRow>

              </TableHead>
              <TableBody>
                {reportList.map(reportRec => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={reportRec.id}
                    onClick={handleNaviageToItem(reportRec.propertyStockId!)}
                  >
                    {
                      Object.keys(tableHeaders).filter((key) => !!tableHeaders[key as keyof CommissionReportListItemDTO]).map((key) => {
                        // 
                        return <TableCell id={sessionStorage.getItem('lastClickedCommissionReport') ?? 'undefined'} style={reportRec.propertyStockId?.toString() === sessionStorage.getItem('lastClickedCommissionReport') ? { ...tableBodyStyle[key], fontWeight: 'bold', color: '#551A8B ' } : { ...tableBodyStyle[key] }} size="small" className={classes.tableCell} key={`${reportRec.id}-${key}`}>{overrideCols[key] ? overrideCols[key](reportRec) : reportRec[key as keyof CommissionReportListItemDTO]}</TableCell>
                      })
                    }
                    {/* <TableCell style={{ cursor: 'default' }} onClick={(ev) => ev.stopPropagation()} size="small" className={classes.tableCell} key={`${transaction.id}-viewIcon`}>
                      <IconButton disabled tooltip="Will be ready with Transaction module">
                        <ViewIcon />
                      </IconButton>
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <div style={{ flexGrow: 1 }} />
        <TablePagination
          classes={{
            actions: classes.tablePaginationIconButton,
            toolbar: classes.tablePaginationToolbar,
          }}
          count={totalElements}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          totalPages={totalPages}
          rowsPerPage={limit}
          labelRowsPerPage={langUser.rowsCount}
          rowsPerPageOptions={rowsCountOptions}
        />
      </CardActions>
    </Card>
  );
};

// PropertiesTable.propTypes = {
//   className: PropTypes.string,
//   properties: PropTypes.array.isRequired
// };

export default CommissionReportTable;
