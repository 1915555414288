import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { MasterDataDTO } from 'common/dto';
import { useForm } from 'common/hooks';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState, PASDispatch } from 'reducers';

const useStyles = makeStyles(theme => ({
  root: {},
  title: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const AgentRatingCommentToolbar = (props: any) => {
  const { className,  ...rest } = props;
  const { langSystemSetting, langAgentRatingCommentMgt} = useSelector((state: IRootState) => state.locale);
  
  const classes = useStyles();
  const systemSettingList = useSelector((state: IRootState) => state.systemSettingsList.currentList);
  
	const dispatch = useDispatch() as PASDispatch;
  
  const [openCreateDialog, setOpenCreateDialog] = React.useState(false);

  const handleClickOpenCreateDialog = () => {
    setOpenCreateDialog(true);
  };

  const handleCloseCreateDialog = () => {
    setOpenCreateDialog(false);
  };

  const systemSettingForm = useForm<MasterDataDTO>({dataStatus: 'A'});
  const reset = () =>{
    systemSettingForm.stopValidation();
    systemSettingForm.setValues({dataStatus: 'A'});
  }
  const handleCreateSystemSetting = () =>{
    if (!systemSettingForm.validate()){
      return;
    }
    dispatch({type: 'SystemSettingDetail.CreationRequested', payload: systemSettingForm.values ?? {}});
    handleCloseCreateDialog();
  }

  useEffect(()=>{
    //reset form values after create
    reset();
  }, [systemSettingList])
 
  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <Typography
          className={classes.title}
          variant="h2"
        >
           {langAgentRatingCommentMgt.titlePageHeading}
        </Typography>
        <span className={classes.spacer} />
        {/* <Button className={classes.importButton}>Import</Button>
        <Button className={classes.exportButton}>Export</Button> */}
        
      </div>
      {/* <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search user"
        />
      </div> */}
    </div>
  );
};

AgentRatingCommentToolbar.propTypes = {
  className: PropTypes.string,
};

export default AgentRatingCommentToolbar;
