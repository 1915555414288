import { ApiPageResult } from 'api';
import { MemoEnquiryDTO } from 'common/dto';
import { objectToQuery } from 'common/utils';

const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];

export const memoEnquiryApi = {
	getList: async (criteria: any = {}, token: string): Promise<ApiPageResult<MemoEnquiryDTO>> => {
		try {
			const response = await fetch(`${BASE_URL}/memo-enquiry?${objectToQuery(criteria)}`, {
				headers: { 'Authorization': `Bearer ${token}` },
			});
			return await response.json();
		} catch (e) {
			return { error: String(e) };
		}  
	},
}

export default memoEnquiryApi;