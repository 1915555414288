import { Button, Card, CardContent, FormHelperText, Grid, IconButton, Menu, MenuItem, TextField, Tooltip, Typography, useTheme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useConfirmDialog } from 'common/ConfirmDialog';
import { UserDTO } from 'common/dto';
import { FormPresenter, useMenu } from 'common/hooks';
import { AddIcon, HandleFormHelperText } from 'common/ui';
import { getInvalidContactErrMsg, initFormKeysAndBooleanMap, limitTextFieldLength } from 'common/utils';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';


export interface UserContactsProps {
  form: FormPresenter<UserDTO>;
  canEdit: boolean;
}

const UserContacts = ({ form, canEdit }: UserContactsProps) => {
  const contactMenu = useMenu();
  const contacts = form.values.contacts ?? [];
  const theme = useTheme();
  const { lang, langUser, contactOptions, langClientDetail } = useSelector((state: IRootState) => state.locale);

  const confirmDialog = useConfirmDialog();

  const addContact = (type: string) => () => {
    form.updateValues('contacts', [...contacts, { type, value: '' }]);
  };
  const updateContact = (i: number) => (updatedContact: any) => {
    form.updateValues('contacts',
      form.values.contacts?.map((c, idx) => idx === i ? updatedContact : c)
    );
  };
  const removeContact = (i: number) => () => {
    form.updateValues('contacts',
      form.values.contacts?.filter((c, idx) => idx !== i)
    );
  };
  const setAsTopContact = (i: number) => () => {
    const newContactList = [...(form.values.contacts ?? [])];
    const [c] = newContactList.splice(i, 1);
    newContactList.unshift(c);
    form.updateValues('contacts', newContactList);
  };

  //validation of contact values
  const invalidContactsPhoneNum = form.hasError('invalidPhoneNumber');//list of index concat with '_'
  const invalidContactsEmail = form.hasError('invalidEmail');//list of index concat with '_'
  const emptyContactValue = form.hasError('emptyContactValue');//list of index concat with '_' 

  const [keyBooleanMap, setKeyBooleanMap] = useState(initFormKeysAndBooleanMap(form));

  const isInvalidContact = (idx: number, type: string) => {
    return getInvalidContactErrMsg(invalidContactsPhoneNum, invalidContactsEmail, emptyContactValue, idx, type, langClientDetail);
  };

  return (
    <Card>
      {confirmDialog.render()}
      <CardContent style={{ minHeight: 400 }}>
        <Grid container spacing={1}>
          <Grid
            item
            md={12}
            xs={12}
          >
            <Typography style={{ fontWeight: 'bold' }}>{langUser.titleContacts}</Typography>
          </Grid>
          <Grid item md={7} xs={12}>
            <TextField
              {...form.bind("email")}
              onChange={(e) => {
                limitTextFieldLength(e, 255, 'email', form, keyBooleanMap, setKeyBooleanMap);
              }}
              label={langUser.email}
              fullWidth
              margin="dense"
              variant="outlined"
              required
              disabled={!canEdit}
            />
            <HandleFormHelperText
              isError={keyBooleanMap.get('email')}
              errorMessage={lang.textOverLimit}
            />
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
            style={{ paddingTop: '2rem' }}
          >
            <Typography color={!!form.hasError('contacts') ? 'error' : 'inherit'} style={{ marginRight: theme.spacing(3) }}>
              {langUser.otherContacts}
              <Tooltip title={lang.actionAdd}>
                <IconButton disabled={!canEdit} {...contactMenu.buttonProps()} color="primary">
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Typography>

            <FormHelperText error={!!form.hasError('contacts')}>{langUser.msgContactRequired}</FormHelperText>

            <Menu {...contactMenu.menuProps()}>
              {
                Object.keys(contactOptions).map((key) => {
                  return <MenuItem key={key} onClick={addContact(key)}>{contactOptions[key]}</MenuItem>
                })
              }
            </Menu>

          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >
            {/* <Grid
              item
              md={12}
              xs={12}
            ></Grid> */}
          </Grid>
        </Grid>

        {contacts.map((contact, i) => {
          return (

            <Grid
              key={i}
              item container
              xs={12}
              md={12}
              alignItems="center"
            // style={{ display: 'flex' }}
            >

              <Grid
                item
                md={4}
                xs={12}
              >
                <Typography style={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton disabled={!canEdit} key={i} onClick={() => {
                    confirmDialog.confirm(lang.msgConfirmDelete, lang.actionYes, lang.actionNo).then(confirmed => {
                      if (confirmed) {
                        removeContact(i)();
                      }
                    });
                  }}>
                    <DeleteIcon />
                  </IconButton>
                  <div style={{ textAlign: 'right' }}>{contactOptions[contact.type] ?? contact.type}{' : '}</div>
                </Typography>

              </Grid>

              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  style={{}}
                  value={contact.value}
                  disabled={!canEdit}
                  error={!!isInvalidContact(i, contact.type)}
                  helperText={isInvalidContact(i, contact.type)}
                  label={langUser.contact}
                  onChange={(e) => {

                    if (e.target.value.length > 255) {
                      e.preventDefault();
                      setKeyBooleanMap(keyBooleanMap.set('contact' + i, true));
                      const updatedContact = { ...contact, value: e.target.value.substring(0, 255) };
                      updateContact(i)(updatedContact);

                    } else {
                      setKeyBooleanMap(keyBooleanMap.set('contact' + i, false))
                      const updatedContact = { ...contact, value: e.target.value.substring(0, 255) };
                      updateContact(i)(updatedContact);
                    }
                    // const updatedContact = { ...contact, value: e.target.value };
                    // //update form
                    // updateContact(i)(updatedContact);
                  }}
                  fullWidth
                  margin="dense"
                  required
                  variant="outlined"
                />
                <HandleFormHelperText
                  isError={keyBooleanMap.get('contact' + i)}
                  errorMessage={lang.textOverLimit}
                />
              </Grid>

              <Grid
                item
                md={2}
                xs={12}
              >
                <Button size="small" disabled={i === 0 || !canEdit} variant="text" onClick={setAsTopContact(i)}>
                  {i === 0 ? langUser.actionDefault : langUser.actionSetAsDefault}
                </Button>
              </Grid>
            </Grid>
          );
        })}
      </CardContent>
    </Card>
  )
};

export default UserContacts;