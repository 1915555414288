import {
    Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid, IconButton, TextField,
    Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/styles';
import clientApi from 'api/clientApi';
import { ContactsDTO } from 'common/dto';
import { CheckUserIcon } from 'common/ui';
import { isNonEmpty, multiLang } from 'common/utils';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducers';
import theme from 'theme';


const useStyles = makeStyles(() => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    color: theme.palette.grey[500],
	},
	clientItem: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center', 
		width: '100%'
	},
	clientList: {
		maxHeight: '150px',
		overflowY: 'scroll',
		overflowX: 'hidden'
	},
	dialogRoot: {
		width: '400px'
	},
	dialogTitle: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	dialogActions: {
		display: 'flex',
		flexDirection: 'row', 
		justifyContent: 'center'
	},
	patchOutlineAutocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
      paddingLeft: 8,
    }
  },
}));

interface MultiClientWishlistDialogProps extends DialogProps {
	pid: string;
	open: boolean;
	close: () => any;
}

const MultiClientWishlistDialog = (props: MultiClientWishlistDialogProps) => {
	const { pid, open, close } = props;
	const { locale, langPropertyStock, lang, contactOptions } = useSelector((state: IRootState) => state.locale);
	const { token } = useSelector((state: IRootState) => state.login);

	const classes = useStyles();

	const dispatch = useDispatch();

	const [ contacts, setContacts ] = useState<ContactsDTO[]>([]);

	const [ shouldDisplayClientAutoComplete, setShouldDisplayClientAutoComplete ] = useState(false);

	const [ chosenClients, setChosenClients ] = useState<ContactsDTO[]>([]);

  const searchClients = async (kw: string) => {
    try {
      const response = await clientApi.searchContactsForStockCart(kw, pid, token ?? '');
      // 
			setContacts(response.data?.filter(contact => chosenClients.length === 0 
				|| chosenClients.length > 0 && chosenClients.filter(c => c.cid === contact.cid).length === 0) 
				?? []
			);
    } catch (e) {
      // { error: String(e) };
    }
	};

	return <Dialog PaperProps={{ className: classes.dialogRoot }} open={open} onClose={close} aria-labelledby="multi-client-wishlist-dialog">
		<DialogTitle id="multi-client-wishlist-dialog-title" disableTypography className={classes.dialogTitle}>
			<Typography variant="h6" style={{ fontWeight: 'bold' }}>{langPropertyStock.actionAddToMultiClientWishlists}</Typography>
        <IconButton aria-label="close" size='small' onClick={close} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
		</DialogTitle>
		<DialogContent>
			{/* <Grid container spacing={1}
				md={12}
				sm={12}
				xs={12}
			> */}
			<Grid container spacing={1}>
				<Grid item
					md={12}
					sm={12}
					xs={12}
				>
					<Autocomplete
						options={contacts}
						popupIcon={''}
						// getOptionLabel={(_contact: ContactsDTO) => sellerClientFormToUse.values.mainContact ?? ''}
						noOptionsText={shouldDisplayClientAutoComplete ? langPropertyStock.msgContactNotFound : ''}
						value={''}
						// value={multiLang(locale, chosenClients[chosenClients.length - 1]?.chineseName, chosenClients[chosenClients.length - 1]?.englishName) ?? ''}
						onChange={(_, contact: ContactsDTO) => {
							if (contact !== null && contact !== undefined && chosenClients.filter(c => c.cid === contact.cid).length === 0) {
								setChosenClients([...chosenClients, contact]);
							}
						}}
						renderOption={(contact: ContactsDTO) => <div>
							<div><Typography>{multiLang(locale, contact.chineseName, contact.englishName)}</Typography></div>
							<div><Typography variant="caption" style={{ display: isNonEmpty(contact.value) ? 'block' : 'none' }}>{contact.value} ({contactOptions[contact.type]})</Typography></div>
						</div>}
						renderInput={params => (
							<TextField
								{...params}
								className={classes.patchOutlineAutocomplete}
								fullWidth
								// error={!!sellerClientFormToUse.hasError('mainContact')}
								helperText={langPropertyStock.msgInputClient}
								label={langPropertyStock.captionClientContact}
								margin="dense"
								required
								variant="outlined"
								onChange={(ev) => {
									setShouldDisplayClientAutoComplete(ev.target.value.length >= 1);
									if (ev.target.value.length >= 1) {
										searchClients(ev.target.value);
									}
								}}
							/>
						)}
					/>
				</Grid>
				
				<Grid item md={12} sm={12} xs={12}>
					<Typography variant="caption">{langPropertyStock.captionChosenClientsForMatching}</Typography>
				</Grid>
				{chosenClients.length === 0 && <Grid item md={12} sm={12} xs={12}>
					<Typography variant="caption">{`(${lang.captionNone})`}</Typography>
				</Grid>}
				{
					chosenClients.map((client: ContactsDTO, index: number) => 
						<div className={classes.clientItem}>
							<div style={{ paddingRight: '10px' }}><CheckUserIcon /></div>
							<div>{`${client.chineseName ?? ''} ${client.englishName ?? ''}`}</div>
							<IconButton onClick={() => 
								setChosenClients(chosenClients.filter((_: any, i: number) => i !== index))
							}>
								<DeleteIcon />
							</IconButton>
						</div>
					)
				}
			</Grid>
		</DialogContent>
		<DialogActions className={classes.dialogActions}>
			<Button color="primary" variant="contained" disabled={chosenClients.length === 0} 
				onClick={() => {
					dispatch({ 
						type: 'PropertyMatching.MultiClient.AddStockCartRequested', 
						payload: { pid: pid, cids: chosenClients.map(c => c.cid ) }
					});
					close();
					setChosenClients([]);
				}
			}>
				{lang.actionConfirm}
			</Button>
		</DialogActions>
	</Dialog>
}

export default MultiClientWishlistDialog;