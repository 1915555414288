import { ApiError, ApiResult } from 'api';
import formApi from 'api/formApi';
import { CheckExistSignedFormDTO, Form1DTO, Form2DTO, Form3DTO, Form4DTO, Form5DTO, Form6DTO } from 'common/dto';
import { openNewWindow } from 'common/utils';
import { ClientActions, IRootState, PropertyActions } from 'reducers';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { PASSagaContext } from 'sagas';
import { isNullOrUndefined } from 'util';
import { apiTaskWrapper, itemActionMessage } from './saga-commons';

const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];

/////////////////////////////////////////////////////////////////////
///////////////////////// All Forms Fetched /////////////////////////
/////////////////////////////////////////////////////////////////////

export function* watchAllFormsFetchRequested() {
    yield takeEvery<PropertyActions['type']>(
      'AllForm.FetchRequested',
      apiTaskWrapper(requestAllForms)
    )
  }
  
export function* requestAllForms(action: PropertyActions) {
    if (action.type != 'AllForm.FetchRequested') {
      return;
    }
    const { propertyStockId} = action.payload;
    
    if(propertyStockId == ''){
      return;
    }
  
    const token: string = yield select((state: IRootState) => state.login.token ?? '');
    //get form 1
    const form1Result: ApiResult<Form1DTO> = yield call(formApi.getForm1, propertyStockId, token);
    if (!form1Result.error) {
        yield put<PropertyActions>({ type: 'Form1.Loaded', payload: form1Result.data! });
    } else {
        yield put<PropertyActions>({ type: 'Form1.Failed', payload: form1Result.error });
    }
    //get form 2
    const form2Result: ApiResult<Form2DTO> = yield call(formApi.getForm2, propertyStockId, token);
    if (!form2Result.error) {
        yield put<PropertyActions>({ type: 'Form2.Loaded', payload: form2Result.data! });
    } else {
        yield put<PropertyActions>({ type: 'Form2.Failed', payload: form2Result.error });
    }
    //get form 3
    const form3Result: ApiResult<Form3DTO> = yield call(formApi.getForm3, propertyStockId, token);
    if (!form3Result.error) {
        yield put<PropertyActions>({ type: 'Form3.Loaded', payload: form3Result.data! });
    } else {
        yield put<PropertyActions>({ type: 'Form3.Failed', payload: form3Result.error });
    }
    //get form 5
    const form5Result: ApiResult<Form5DTO> = yield call(formApi.getForm5, propertyStockId, token);
    if (!form5Result.error) {
        yield put<PropertyActions>({ type: 'Form5.Loaded', payload: form5Result.data! });
    } else {
        yield put<PropertyActions>({ type: 'Form5.Failed', payload: form5Result.error });
    }
  }
  

/////////////////////////////////////////////////////////////////////
///////////////////////// Form 1 Creation ///////////////////////////
/////////////////////////////////////////////////////////////////////

export function* watchForm1CreationRequested(context: PASSagaContext) {
    yield takeEvery(
      'Form1.CreationRequested',
      apiTaskWrapper(createForm1),
      context,
    );
  }
  
  export function* createForm1(context: PASSagaContext, action: Extract<PropertyActions, { type: 'Form1.CreationRequested' }>) {
    
    
    const token: string = yield select((state: IRootState) => state.login.token ?? '');
    
    const { propertyStockId, id } = action.payload;
    const result: ApiResult<Form1DTO> = yield call(formApi.addForm1, propertyStockId,id,action.payload, token);
    
    if (!result.error) {
      const propertyStockId = result.data?.propertyStockId;
      yield put<PropertyActions>({ type: 'Form1.FetchRequested', payload: { propertyStockId: propertyStockId! } });

      if (action.payload.extraAction === 'GENERATE_PDF') {
        const formLocale = action.payload.formLocale;
        openNewWindow(`${BASE_URL}/form/generate/${result.data!.propertyStockId}/form1/${formLocale}/pdf?t=${token}`);
      }
      else if (action.payload.extraAction === 'SIGN_FORM') {
        const checkForm1Dto = {
          id: result.data?.propertyStockId,
          formName: 'form1',
          validityPeriodStart: '',
          validityPeriodEnd: ''
        } as CheckExistSignedFormDTO;
        const checkResult: ApiResult<any> = yield call(formApi.checkExistSignedForm, checkForm1Dto, token);
        
        yield put<PropertyActions>({ type: 'Form1.CheckExistSignedFormResultLoaded', payload: { isExist: checkResult.data.isExist, invitationSent: checkResult.data.invitationSent } });
      } else {
        yield* itemActionMessage('create', 'Form1');
      }
    } else {
      throw ApiError.of(result.error!);
    }
    
  }
  
  
/////////////////////////////////////////////////////////////////////
///////////////////////// Form 1 Fetched ////////////////////////////
/////////////////////////////////////////////////////////////////////
export function* watchForm1FetchRequested() {
    yield takeEvery<PropertyActions['type']>(
      'Form1.FetchRequested',
      apiTaskWrapper(requestForm1),
    )
  }
  
export function* requestForm1(action: PropertyActions) {
    if (action.type != 'Form1.FetchRequested') {
      return;
    }
    const { propertyStockId} = action.payload;
    
    if(propertyStockId == ''){
      return;
    }
  
  
    const token: string = yield select((state: IRootState) => state.login.token ?? '');
    const result: ApiResult<Form1DTO> = yield call(formApi.getForm1, propertyStockId, token);
  
    if (!result.error) {
        
        yield put<PropertyActions>({ type: 'Form1.Loaded', payload: result.data! });
    } else {
        yield put<PropertyActions>({ type: 'Form1.Failed', payload: result.error });
    }
  
  }
  
/////////////////////////////////////////////////////////////////////
////////////////////////// Form1 Update /////////////////////////////
/////////////////////////////////////////////////////////////////////

export function* watchForm1UpdateRequested(context: PASSagaContext) {
    yield takeEvery(
      'Form1.UpdateRequested',
      apiTaskWrapper(updateForm1),
      context,
    );
  }
  
export function* updateForm1(context: PASSagaContext, action: Extract<PropertyActions, { type: 'Form1.UpdateRequested' }>) {
    
  
  
    const token: string = yield select((state: IRootState) => state.login.token ?? '');
  
    const result: ApiResult<Form1DTO> = yield call(formApi.updateForm1, action.payload, token);
    
    if (!result.error) {
      yield put<PropertyActions>({ type: 'Form1.Loaded', payload: result.data! });
      if (action.payload.extraAction === 'GENERATE_PDF') {
        const formLocale = action.payload.formLocale;
        openNewWindow(`${BASE_URL}/form/generate/${result.data!.propertyStockId}/form1/${formLocale}/pdf?t=${token}`);
      }
      else if (action.payload.extraAction === 'SIGN_FORM') {
        const checkForm1Dto = {
          id: result.data?.propertyStockId,
          formName: 'form1',
          validityPeriodStart: '',
          validityPeriodEnd: ''
        } as CheckExistSignedFormDTO;
        const checkResult: ApiResult<any> = yield call(formApi.checkExistSignedForm, checkForm1Dto, token);
        
        yield put<PropertyActions>({ type: 'Form1.CheckExistSignedFormResultLoaded', payload: { isExist: checkResult.data.isExist, invitationSent: checkResult.data.invitationSent } });
      } else {
        yield* itemActionMessage('update', 'Form1');
      }
    } else {
      throw ApiError.of(result.error!);
  
    }
  
}

/////////////////////////////////////////////////////////////////////
///////////////////////// Form 2 Creation ///////////////////////////
/////////////////////////////////////////////////////////////////////

export function* watchForm2CreationRequested(context: PASSagaContext) {
  yield takeEvery(
      'Form2.CreationRequested',
      apiTaskWrapper(createForm2),
      context,
  );
}

export function* createForm2(context: PASSagaContext, action: Extract<PropertyActions, { type: 'Form2.CreationRequested' }>) {
  

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const { propertyStockId, id } = action.payload;
  const result: ApiResult<Form2DTO> = yield call(formApi.addForm2, propertyStockId, id, action.payload, token);

  if (!result.error) {
      const propertyStockId = result.data?.propertyStockId;
      yield put<PropertyActions>({ type: 'Form2.FetchRequested', payload: { propertyStockId: propertyStockId! } });
      if (action.payload.extraAction === 'GENERATE_PDF') {
        const formLocale = action.payload.formLocale;
        openNewWindow(`${BASE_URL}/form/generate/${result.data!.propertyStockId}/form2/${formLocale}/pdf?t=${token}`);
      } else {
        yield* itemActionMessage('create', 'Form2');
      }
  } else {
      throw ApiError.of(result.error!);
  }

}



/////////////////////////////////////////////////////////////////////
///////////////////////// Form 2 Fetched ////////////////////////////
/////////////////////////////////////////////////////////////////////
export function* watchForm2FetchRequested() {
  yield takeEvery<PropertyActions['type']>(
      'Form2.FetchRequested',
      apiTaskWrapper(requestForm2),
  )
}

export function* requestForm2(action: PropertyActions) {
  if (action.type != 'Form2.FetchRequested') {
      return;
  }
  const { propertyStockId } = action.payload;
  if (propertyStockId == '') {
      return;
  }

  const token: string = yield select((state: IRootState) => state.login.token ?? '');
  const result: ApiResult<Form2DTO> = yield call(formApi.getForm2, propertyStockId, token);
  if (!result.error) {
      
      yield put<PropertyActions>({ type: 'Form2.Loaded', payload: result.data! });
  } else {
      yield put<PropertyActions>({ type: 'Form2.Failed', payload: result.error });
  }
}


/////////////////////////////////////////////////////////////////////
////////////////////////// Form2 Update /////////////////////////////
/////////////////////////////////////////////////////////////////////

export function* watchForm2UpdateRequested(context: PASSagaContext) {
  yield takeEvery(
    'Form2.UpdateRequested',
    apiTaskWrapper(updateForm2),
    context,
  );
}

export function* updateForm2(context: PASSagaContext, action: Extract<PropertyActions, { type: 'Form2.UpdateRequested' }>) {
  

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<Form2DTO> = yield call(formApi.updateForm2, action.payload, token);

  if (!result.error) {
    yield put<PropertyActions>({ type: 'Form2.Loaded', payload: result.data! });
    if (action.payload.extraAction === 'GENERATE_PDF') {
      const formLocale = action.payload.formLocale;
      openNewWindow(`${BASE_URL}/form/generate/${result.data!.propertyStockId}/form2/${formLocale}/pdf?t=${token}`);
    } else {
      yield* itemActionMessage('update', 'Form2');
    }
  } else {
    throw ApiError.of(result.error!);

  }
}

/////////////////////////////////////////////////////////////////////
///////////////////////// Form 3 Creation ///////////////////////////
/////////////////////////////////////////////////////////////////////

export function* watchForm3CreationRequested(context: PASSagaContext) {
    yield takeEvery(
      'Form3.CreationRequested',
      apiTaskWrapper(createForm3),
      context,
    );
  }
  
  export function* createForm3(context: PASSagaContext, action: Extract<PropertyActions, { type: 'Form3.CreationRequested' }>) {
    
    
    const token: string = yield select((state: IRootState) => state.login.token ?? '');
    
    const { propertyStockId, id } = action.payload;
    const result: ApiResult<Form3DTO> = yield call(formApi.addForm3, propertyStockId,id,action.payload, token);
    
    if (!result.error) {
      const propertyStockId = result.data?.propertyStockId;
      yield put<PropertyActions>({ type: 'Form3.FetchRequested', payload: { propertyStockId: propertyStockId! } });
      if (action.payload.extraAction === 'GENERATE_PDF') {
        const formLocale = action.payload.formLocale;
        openNewWindow(`${BASE_URL}/form/generate/${result.data!.propertyStockId}/form3/${formLocale}/pdf?t=${token}`);
      }
      else if (action.payload.extraAction === 'SIGN_FORM') {
        const checkForm3Dto = {
          id: result.data?.propertyStockId,
          formName: 'form3',
          validityPeriodStart: result.data?.validityPeriodStart,
          validityPeriodEnd: result.data?.validityPeriodEnd
        } as CheckExistSignedFormDTO;
        const checkResult: ApiResult<any> = yield call(formApi.checkExistSignedForm, checkForm3Dto, token);
        
        yield put<PropertyActions>({ type: 'Form3.CheckExistSignedFormResultLoaded', payload: { isExist: checkResult.data.isExist, invitationSent: checkResult.data.invitationSent } });
      } else {
        yield* itemActionMessage('create', 'Form3');
      }
    } else {
      throw ApiError.of(result.error!);
    }
    
  }

  
/////////////////////////////////////////////////////////////////////
///////////////////////// Form 3 Fetched ////////////////////////////
/////////////////////////////////////////////////////////////////////
export function* watchForm3FetchRequested() {
    yield takeEvery<PropertyActions['type']>(
      'Form3.FetchRequested',
      apiTaskWrapper(requestForm3),
    )
  }
  
export function* requestForm3(action: PropertyActions) {
    if (action.type != 'Form3.FetchRequested') {
      return;
    }
    const { propertyStockId} = action.payload;
    
    if(propertyStockId == ''){
      return;
    }
  
  
    const token: string = yield select((state: IRootState) => state.login.token ?? '');
  
    const result: ApiResult<Form3DTO> = yield call(formApi.getForm3, propertyStockId, token);
  
    if (!result.error) {
        yield put<PropertyActions>({ type: 'Form3.Loaded', payload: result.data! });
    } else {
        yield put<PropertyActions>({ type: 'Form3.Failed', payload: result.error });
    }
  
  }
  
/////////////////////////////////////////////////////////////////////
////////////////////////// Form3 Update /////////////////////////////
/////////////////////////////////////////////////////////////////////

export function* watchForm3UpdateRequested(context: PASSagaContext) {
    yield takeEvery(
      'Form3.UpdateRequested',
      apiTaskWrapper(updateForm3),
      context,
    );
  }
  
export function* updateForm3(context: PASSagaContext, action: Extract<PropertyActions, { type: 'Form3.UpdateRequested' }>) {
    
  
  
    const token: string = yield select((state: IRootState) => state.login.token ?? '');
    const result: ApiResult<Form3DTO> = yield call(formApi.updateForm3, action.payload, token);
    if (!result.error) {
      yield put<PropertyActions>({ type: 'Form3.Loaded', payload: result.data! });
      if (action.payload.extraAction === 'GENERATE_PDF') {
        const formLocale = action.payload.formLocale;
        openNewWindow(`${BASE_URL}/form/generate/${result.data!.propertyStockId}/form3/${formLocale}/pdf?t=${token}`);
      }
      else if (action.payload.extraAction === 'SIGN_FORM') {
        const checkForm3Dto = {
          id: result.data?.propertyStockId,
          formName: 'form3',
          validityPeriodStart: result.data?.validityPeriodStart,
          validityPeriodEnd: result.data?.validityPeriodEnd
        } as CheckExistSignedFormDTO;
        const checkResult: ApiResult<any> = yield call(formApi.checkExistSignedForm, checkForm3Dto, token);
        
        yield put<PropertyActions>({ type: 'Form3.CheckExistSignedFormResultLoaded', payload: { isExist: checkResult.data.isExist, invitationSent: checkResult.data.invitationSent } });
      } else {
        yield* itemActionMessage('update', 'Form3');
      }
    } else {
      throw ApiError.of(result.error!);
    }
  
}


/////////////////////////////////////////////////////////////////////
///////////////////////// Form 5 Creation ///////////////////////////
/////////////////////////////////////////////////////////////////////

export function* watchForm5CreationRequested(context: PASSagaContext) {
  yield takeEvery(
    'Form5.CreationRequested',
    apiTaskWrapper(createForm5),
    context,
  );
}

export function* createForm5(context: PASSagaContext, action: Extract<PropertyActions, { type: 'Form5.CreationRequested' }>) {
  
  
  const token: string = yield select((state: IRootState) => state.login.token ?? '');
  
  const { propertyStockId, id } = action.payload;
  const result: ApiResult<Form5DTO> = yield call(formApi.addForm5, propertyStockId,id,action.payload, token);
  
  if (!result.error) {
    const propertyStockId = result.data?.propertyStockId;
    yield put<PropertyActions>({ type: 'Form5.FetchRequested', payload: { propertyStockId: propertyStockId! } });
    if (action.payload.extraAction === 'GENERATE_PDF') {
      const formLocale = action.payload.formLocale;
      openNewWindow(`${BASE_URL}/form/generate/${result.data!.propertyStockId}/form5/${formLocale}/pdf?t=${token}`);
    }
    else if (action.payload.extraAction === 'SIGN_FORM') {
      const checkForm5Dto = {
        id: result.data?.propertyStockId,
        formName: 'form5',
        validityPeriodStart: result.data?.validityPeriodStart,
        validityPeriodEnd: result.data?.validityPeriodEnd
      } as CheckExistSignedFormDTO;
      const checkResult: ApiResult<any> = yield call(formApi.checkExistSignedForm, checkForm5Dto, token);
      
      yield put<PropertyActions>({ type: 'Form5.CheckExistSignedFormResultLoaded', payload: { isExist: checkResult.data.isExist, invitationSent: checkResult.data.invitationSent } });
    } else {
      yield* itemActionMessage('create', 'Form5');
    }
  } else {
    throw ApiError.of(result.error!);
  }
  
}


/////////////////////////////////////////////////////////////////////
///////////////////////// Form 5 Fetched ////////////////////////////
/////////////////////////////////////////////////////////////////////
export function* watchForm5FetchRequested() {
  yield takeEvery<PropertyActions['type']>(
    'Form5.FetchRequested',
    apiTaskWrapper(requestForm5),
  )
}

export function* requestForm5(action: PropertyActions) {
  if (action.type != 'Form5.FetchRequested') {
    return;
  }
  const { propertyStockId} = action.payload;
  
  if(propertyStockId == ''){
    return;
  }

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<Form5DTO> = yield call(formApi.getForm5, propertyStockId, token);

  if (!result.error) {
      yield put<PropertyActions>({ type: 'Form5.Loaded', payload: result.data! });
  } else {
      yield put<PropertyActions>({ type: 'Form5.Failed', payload: result.error });
  }
}

/////////////////////////////////////////////////////////////////////
////////////////////////// Form5 Update /////////////////////////////
/////////////////////////////////////////////////////////////////////

export function* watchForm5UpdateRequested(context: PASSagaContext) {
  yield takeEvery(
    'Form5.UpdateRequested',
    apiTaskWrapper(updateForm5),
    context,
  );
}

export function* updateForm5(context: PASSagaContext, action: Extract<PropertyActions, { type: 'Form5.UpdateRequested' }>) {
  

  const token: string = yield select((state: IRootState) => state.login.token ?? '');
  const result: ApiResult<Form5DTO> = yield call(formApi.updateForm5, action.payload, token);
  if (!result.error) {
    yield put<PropertyActions>({ type: 'Form5.Loaded', payload: result.data! });
    if (action.payload.extraAction === 'GENERATE_PDF') {
      const formLocale = action.payload.formLocale;
      openNewWindow(`${BASE_URL}/form/generate/${result.data!.propertyStockId}/form5/${formLocale}/pdf?t=${token}`);
    }
    else if (action.payload.extraAction === 'SIGN_FORM') {
      const checkForm5Dto = {
        id: result.data?.propertyStockId,
        formName: 'form5',
        validityPeriodStart: result.data?.validityPeriodStart,
        validityPeriodEnd: result.data?.validityPeriodEnd
      } as CheckExistSignedFormDTO;
      const checkResult: ApiResult<any> = yield call(formApi.checkExistSignedForm, checkForm5Dto, token);
      
      yield put<PropertyActions>({ type: 'Form5.CheckExistSignedFormResultLoaded', payload: { isExist: checkResult.data.isExist, invitationSent: checkResult.data.invitationSent } });
    } else {
      yield* itemActionMessage('update', 'Form5');
    }
  } else {
    throw ApiError.of(result.error!);
  }
}

/////////////////////////////////////////////////////////////////////
///////////////////////// Form 4 Creation ///////////////////////////
/////////////////////////////////////////////////////////////////////

export function* watchForm4CreationRequested(context: PASSagaContext) {
  yield takeEvery(
    'Form4.CreationRequested',
    apiTaskWrapper(createForm4),
    context,
  );
}

export function* createForm4(context: PASSagaContext, action: Extract<ClientActions, { type: 'Form4.CreationRequested' }>) {
  
  
  const token: string = yield select((state: IRootState) => state.login.token ?? '');
  
  const { buyerClientId, id } = action.payload;
  const result: ApiResult<Form4DTO> = yield call(formApi.addForm4, buyerClientId,id,action.payload, token);
  
  if (!result.error) {
    const buyerClientId = result.data?.buyerClientId;
    yield put<ClientActions>({ type: 'Form4.FetchRequested', payload: { buyerClientId: buyerClientId! } });
    if (action.payload.extraAction === 'GENERATE_PDF') {
      const formLocale = action.payload.formLocale;
      openNewWindow(`${BASE_URL}/form/generate-client-form/${result.data!.buyerClientId}/form4/${formLocale}/pdf?t=${token}`);
    }
    else if (action.payload.extraAction === 'SIGN_FORM') {
      const checkForm4Dto = {
        id: result.data?.buyerClientId,
        formName: 'form4',
        validityPeriodStart: result.data?.validityPeriodStart,
        validityPeriodEnd:result.data?.validityPeriodEnd
      } as CheckExistSignedFormDTO;
      const checkResult: ApiResult<any> = yield call(formApi.checkExistSignedForm, checkForm4Dto, token);
      
      yield put<ClientActions>({ type: 'Form4.CheckExistSignedFormResultLoaded', payload: { isExist: checkResult.data.isExist, invitationSent: checkResult.data.invitationSent } });
    } else {
      yield* itemActionMessage('create', 'Form4');
    }
  } else {
    throw ApiError.of(result.error!);
  }
  
}



/////////////////////////////////////////////////////////////////////
///////////////////////// Form 4 Fetched ////////////////////////////
/////////////////////////////////////////////////////////////////////
export function* watchForm4FetchRequested() {
  yield takeEvery<ClientActions['type']>(
    'Form4.FetchRequested',
    apiTaskWrapper(requestForm4),
  )
}

export function* requestForm4(action: ClientActions) {
  if (action.type != 'Form4.FetchRequested') {
    return;
  }
  const { buyerClientId} = action.payload;
  
  if(isNullOrUndefined(buyerClientId) || buyerClientId == '' || buyerClientId == '0'){
    return;
  }

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<Form4DTO> = yield call(formApi.getForm4, buyerClientId, token);

  if (!result.error) {
      yield put<ClientActions>({ type: 'Form4.Loaded', payload: result.data! });
  } else {
      yield put<ClientActions>({ type: 'Form4.Failed', payload: result.error });
  }
}

/////////////////////////////////////////////////////////////////////
////////////////////////// Form4 Update /////////////////////////////
/////////////////////////////////////////////////////////////////////

export function* watchForm4UpdateRequested(context: PASSagaContext) {
  yield takeEvery(
    'Form4.UpdateRequested',
    apiTaskWrapper(updateForm4),
    context,
  );
}

export function* updateForm4(context: PASSagaContext, action: Extract<ClientActions, { type: 'Form4.UpdateRequested' }>) {
  

  const token: string = yield select((state: IRootState) => state.login.token ?? '');
  const result: ApiResult<Form4DTO> = yield call(formApi.updateForm4, action.payload, token);
  if (!result.error) {
    yield put<ClientActions>({ type: 'Form4.Loaded', payload: result.data! });
    if (action.payload.extraAction === 'GENERATE_PDF') {
      const formLocale = action.payload.formLocale;
      openNewWindow(`${BASE_URL}/form/generate-client-form/${result.data!.buyerClientId}/form4/${formLocale}/pdf?t=${token}`);
    }
    else if (action.payload.extraAction === 'SIGN_FORM') {
      const checkForm4Dto = {
        id: result.data?.buyerClientId,
        formName: 'form4',
        validityPeriodStart: result.data?.validityPeriodStart,
        validityPeriodEnd:result.data?.validityPeriodEnd
      } as CheckExistSignedFormDTO;
      const checkResult: ApiResult<any> = yield call(formApi.checkExistSignedForm, checkForm4Dto, token);
      
      yield put<ClientActions>({ type: 'Form4.CheckExistSignedFormResultLoaded', payload: { isExist: checkResult.data.isExist, invitationSent: checkResult.data.invitationSent } });
    } else {
      yield* itemActionMessage('update', 'Form4');
    }
  } else {
    throw ApiError.of(result.error!);
  }
}

	
/////////////////////////////////////////////////////////////////////
///////////////////////// Form 6 Creation ///////////////////////////
/////////////////////////////////////////////////////////////////////

export function* watchForm6CreationRequested(context: PASSagaContext) {
  yield takeEvery(
    'Form6.CreationRequested',
    apiTaskWrapper(createForm6),
    context,
  );
}

export function* createForm6(context: PASSagaContext, action: Extract<ClientActions, { type: 'Form6.CreationRequested' }>) {
  
  
  const token: string = yield select((state: IRootState) => state.login.token ?? '');
  
  const { tenantClientId, id } = action.payload;
  const result: ApiResult<Form6DTO> = yield call(formApi.addForm6, tenantClientId,id,action.payload, token);
  
  if (!result.error) {
    const tenantClientId = result.data?.tenantClientId;
    yield put<ClientActions>({ type: 'Form6.FetchRequested', payload: { tenantClientId: tenantClientId! } });
    if (action.payload.extraAction === 'GENERATE_PDF') {
      const formLocale = action.payload.formLocale;
      openNewWindow(`${BASE_URL}/form/generate-client-form/${result.data!.tenantClientId}/form6/${formLocale}/pdf?t=${token}`);
    }
    else if (action.payload.extraAction === 'SIGN_FORM') {
      const checkForm6Dto = {
        id: result.data?.tenantClientId,
        formName: 'form6',
        validityPeriodStart: result.data?.validityPeriodStart,
        validityPeriodEnd:result.data?.validityPeriodEnd
      } as CheckExistSignedFormDTO;
      const checkResult: ApiResult<any> = yield call(formApi.checkExistSignedForm, checkForm6Dto, token);
      
      yield put<ClientActions>({ type: 'Form6.CheckExistSignedFormResultLoaded', payload: { isExist: checkResult.data.isExist, invitationSent: checkResult.data.invitationSent } });
    } else {
      yield* itemActionMessage('create', 'Form6');
    }
  } else {
    throw ApiError.of(result.error!);
  }
  
}

/////////////////////////////////////////////////////////////////////
///////////////////////// Form 6 Fetched ////////////////////////////
/////////////////////////////////////////////////////////////////////
export function* watchForm6FetchRequested() {
  yield takeEvery<ClientActions['type']>(
    'Form6.FetchRequested',
    apiTaskWrapper(requestForm6),
  )
}

export function* requestForm6(action: ClientActions) {
  if (action.type != 'Form6.FetchRequested') {
    return;
  }
  const { tenantClientId} = action.payload;
  
  if(isNullOrUndefined(tenantClientId) || tenantClientId == '' || tenantClientId == '0'){
    return;
  }

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<Form6DTO> = yield call(formApi.getForm6, tenantClientId, token);

  if (!result.error) {
      yield put<ClientActions>({ type: 'Form6.Loaded', payload: result.data! });
  } else {
      yield put<ClientActions>({ type: 'Form6.Failed', payload: result.error });
  }
}

/////////////////////////////////////////////////////////////////////
////////////////////////// Form6 Update /////////////////////////////
/////////////////////////////////////////////////////////////////////

export function* watchForm6UpdateRequested(context: PASSagaContext) {
  yield takeEvery(
    'Form6.UpdateRequested',
    apiTaskWrapper(updateForm6),
    context,
  );
}

export function* updateForm6(context: PASSagaContext, action: Extract<ClientActions, { type: 'Form6.UpdateRequested' }>) {
  

  const token: string = yield select((state: IRootState) => state.login.token ?? '');
  const result: ApiResult<Form6DTO> = yield call(formApi.updateForm6, action.payload, token);
  if (!result.error) {
    yield put<ClientActions>({ type: 'Form6.Loaded', payload: result.data! });
    if (action.payload.extraAction === 'GENERATE_PDF') {
      const formLocale = action.payload.formLocale;
      openNewWindow(`${BASE_URL}/form/generate-client-form/${result.data!.tenantClientId}/form6/${formLocale}/pdf?t=${token}`);
    }
    else if (action.payload.extraAction === 'SIGN_FORM') {
      const checkForm6Dto = {
        id: result.data?.tenantClientId,
        formName: 'form6',
        validityPeriodStart: result.data?.validityPeriodStart,
        validityPeriodEnd:result.data?.validityPeriodEnd
      } as CheckExistSignedFormDTO;
      const checkResult: ApiResult<any> = yield call(formApi.checkExistSignedForm, checkForm6Dto, token);
      
      yield put<ClientActions>({ type: 'Form6.CheckExistSignedFormResultLoaded', payload: { isExist: checkResult.data.isExist, invitationSent: checkResult.data.invitationSent } });
    } else {
      yield* itemActionMessage('update', 'Form6');
    }
  } else {
    throw ApiError.of(result.error!);
  }
}