import { makeStyles, MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { useForm } from 'common/hooks';
import { openNewWindow } from 'common/utils';
import React from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';

const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];

const useStyles = makeStyles(() => ({
  root: {

  },
  dialogContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minWidth: 250,
  },

}));

interface PropertySummaryReportDialogProps {
  open: boolean;
  handleClose: () => void;
  propertyNoList: string;
}

const PropertySummaryReportDialog = ({ open, handleClose, propertyNoList }: PropertySummaryReportDialogProps) => {
  const { langClientDetail, lang } = useSelector((state: IRootState) => state.locale);
  const classes = useStyles();
  const form = useForm<any>({ locale: 'zh_HK'}, {
    validations: [
      (values) => !values.locale ? [[ 'locale',  langClientDetail.msgPleaseSelect]] : null,
    ],
  });
  
  const token: string = useSelector((state: IRootState) => state.login.token ?? '');
  const genPropertySummaryReport = () => {
    if (!form.validate()){
      return;
    }
    openNewWindow(`${BASE_URL}/form/generate-client/PropertySummaryReport/${form.values.locale}/${propertyNoList}?t=${token}`);
    
    handleClose();
  }
  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="propertySummaryReportForm-dialog-title">
        <DialogTitle id="propertySummaryReportForm-dialog-title">{langClientDetail.titleGeneratePropertySummaryReport}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          
          
          <TextField
              margin="dense"
              required
              select
              fullWidth
              variant="outlined"
              label={langClientDetail.selectLanguageTitle}
              {...form.bind('locale')}
              error={!!form.hasError('locale')}
              helperText={form.hasError('locale')}
            >
              <MenuItem key={'zh_HK'} value={'zh_HK'}>{langClientDetail.traditionalChinese}</MenuItem>
              <MenuItem key={'zh_CN'} value={'zh_CN'}>{langClientDetail.simplifiedChinese}</MenuItem>
            </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {lang.actionCancel}
          </Button>
          <Button onClick={genPropertySummaryReport} color="primary" variant="contained" autoFocus>
            {langClientDetail.actionGenerate}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PropertySummaryReportDialog;