import { ApiResult } from 'api';
import { EmailFormDTO } from 'common/dto';
import { trackGoals } from 'common/utils';

const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];

// TODO: auth
export const clientSignApi = {

    verifyToken: async (token: string): Promise<ApiResult<any>> => {
        const payload = new FormData();

        payload.append('token', token);

        const res = fetch(`${BASE_URL}/clientSign/verifyToken`, {
            method: 'POST',
            body: payload,
        });

        return (await res).json();
    },

    checkVerificationContact: async (token: string, clientVerificationContact: string): Promise<ApiResult<any>> => {
        const payload = new FormData();

        payload.append('token', token);
        payload.append('clientVerificationContact', clientVerificationContact);
        const res = fetch(`${BASE_URL}/clientSign/checkVerificationContact`, {
            method: 'POST',
            body: payload,
        });

        return (await res).json();
    },

    confirmSign: async(token: string, signatureBlobUrl: string, hasHKID: boolean, clientIdentificationDocNo : string , isHKID: string): Promise<ApiResult<any>> => {
		try {
            const formData = new FormData();
            formData.append('token', token);
			formData.append(
				'clientSign',
				await fetch(signatureBlobUrl).then(r => r.blob()),
            );
            if (hasHKID){
                formData.append('clientIdentificationDocNo', clientIdentificationDocNo!);
                formData.append('isHKID', isHKID!);
            }

			const response = await fetch(`${BASE_URL}/clientSign/confirmSign`, {
                method: 'POST', 
                body: formData,
			});
			const apiResult = await response.json();
            const goalsListToTrack = apiResult.metadata?.goalsListToTrack ?? [];
            trackGoals(goalsListToTrack);

            return apiResult;
		} catch (e) {
			return { error: String(e) };
		}
    },
    
    sendCopyToClient: async (dto: EmailFormDTO, token: string): Promise<ApiResult<any>> => {
        try {
          const response = await fetch(`${BASE_URL}/clientSign/sendCopyToClient/${dto.formName}`, {
            method: 'POST', body: JSON.stringify(dto),
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
          });
          return await response.json();
        } catch (e) {
          return { error: String(e) };
        }
      },

};

export default clientSignApi;