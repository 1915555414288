import { RoleDTO } from "common/dto";
import { FormValidations } from "common/hooks";

type Options = { [key: string]: string };

const roleFormValidations = (langRolePrivilege: Options): FormValidations<RoleDTO> => [
 
  (values) => values.roleName? null : [
    [ 'roleName', langRolePrivilege.msgPleaseInput + langRolePrivilege.captionRoleName],
  ],
  (values) => values.descriptionTC? null : [
    [ 'descriptionTC', langRolePrivilege.msgPleaseInput + langRolePrivilege.captionDescriptionTC],
  ],
  (values) => values.descriptionSC? null : [
    [ 'descriptionSC', langRolePrivilege.msgPleaseInput + langRolePrivilege.captionDescriptionSC],
  ],
  (values) => values.descriptionEN? null : [
    [ 'descriptionEN', langRolePrivilege.msgPleaseInput + langRolePrivilege.captionDescriptionEN],
  ],
  (values) => values.dataStatus? null : [
    [ 'dataStatus', langRolePrivilege.msgPleaseSelect + langRolePrivilege.captionDataStatus],
  ],
  
];

export default roleFormValidations;