import { makeStyles } from '@material-ui/core';
import { hasPermission } from "common/access-control";
import { PropertySearchDTO } from 'common/dto';
import { useForm } from 'common/hooks';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
// import { Dispatch } from 'redux';
import { getInitialPropertySearchFormValues, IRootState, PASDispatch } from 'reducers';
import { PropertiesTable, PropertiesToolbar } from './components';
import PropertiesSearch from './components/PropertiesSearch';




export const PS_DEFAULT_SIZE = 10;

export interface PropertyStockSearchFormValues extends PropertySearchDTO {
  // Toggling
  isRent: boolean;
  isGross: boolean;
  disabledCriteriaList: { [k: string]: boolean };
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  search: {
    marginBottom: theme.spacing(2)
  }
}));

export let initialSortOrder: { [k: string]: 'desc' | 'asc' } = {
  // price: 'desc',
  dateModified: 'desc',
};

export let initialPage = 0;
let currentPropertyRow = 0;
const PropertyList = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch() as PASDispatch;
  const propertyMatching = useSelector((state: IRootState) => state.propertyMatching);
  let lastSearchForm = useSelector((state: IRootState) => state.property.lastSearchFormValues ?? null);
  const isRent = useSelector((state: IRootState) => state.property.lastSearchFormValues?.isRent ?? false);
  const { currentList: properties, totalPages, totalElements, lastSearchFormValues } = useSelector((state: IRootState) => state.property);
  const { districts } = useSelector((state: IRootState) => state.login);
  const { locale, rowsCountPrefOptions } = useSelector((state: IRootState) => state.locale);

  const { privileges } = useSelector((state: IRootState) => state.login);
  const canReadUnownedProperty = hasPermission(privileges, 'READ', 'UNOWNED_PROPERTY_STOCK');
  const canReadTeamProperty = hasPermission(privileges, 'READ', 'TEAM_PROPERTY_STOCK');
  // const rowsCountPreference = useSelector((state: IRootState) => state.login.rowsCountPreference) ?? EMPTY_STRING;

  // When matching, show ONLY those passing approval stage 1
  // const showApprovedOnly = !!propertyMatching;


  const [page, setPage] = useState(initialPage);
  initialPage = page;

  const limitPref = useSelector((state: IRootState) => state.login.rowsCountPreference?.PROPERTY_STOCK) ?? PS_DEFAULT_SIZE;
  // const [ limitPref, setLimitPref ] = useState(limit);

  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(currentPropertyRow === 0 ? limitPref : currentPropertyRow);
  const [sortOrders, setSortOrders] = useState<{ [k: string]: 'desc' | 'asc' }>(initialSortOrder);
  // Work-around solution (same as page)
  initialSortOrder = sortOrders;

  currentPropertyRow = currentRowsPerPage;
  // 

  const searchForm = useForm<PropertyStockSearchFormValues>(
    getInitialPropertySearchFormValues(districts, lastSearchFormValues, locale)
  );


  // Search Request Handling

  const handleSearch = () => {
    setPage(0);
    dispatch({
      type: 'PropertyList.FetchRequested', payload: {
        ...searchForm.values as any,
        page: 0,
        size: currentRowsPerPage,
        sort:
          Object.keys(sortOrders)[0] === 'price' && searchForm.values.isRent ? { ['rent']: sortOrders[Object.keys(sortOrders)[0]] } :
            Object.keys(sortOrders)[0] === 'price' && !searchForm.values.isRent ? { ['price']: sortOrders[Object.keys(sortOrders)[0]] } :
              Object.keys(sortOrders)[0] === 'pricePerNet' && searchForm.values.isRent ? { ['rentPerNet']: sortOrders[Object.keys(sortOrders)[0]] } :
                Object.keys(sortOrders)[0] === 'pricePerNet' && !searchForm.values.isRent ? { ['pricePerNet']: sortOrders[Object.keys(sortOrders)[0]] } :
                  Object.keys(sortOrders)[0] === 'pricePerGross' && searchForm.values.isRent ? { ['rentPerGross']: sortOrders[Object.keys(sortOrders)[0]] } :
                    Object.keys(sortOrders)[0] === 'pricePerGross' && !searchForm.values.isRent ? { ['pricePerGross']: sortOrders[Object.keys(sortOrders)[0]] } :
                      sortOrders,
      },
    });


  };

  const handleRowsCount = (count: number) => {
    dispatch({ type: 'Login.RowCountPrefUpdate', payload: { PROPERTY_STOCK: count } });
  }

  const handleSort = (key: string | null) => {
    if (key === null) {
      setSortOrders({});
      dispatch({
        type: 'PropertyList.FetchRequested', payload: {
          // lastSearchFormValues: searchForm.values,
          ...searchForm.values as any,
          page, size: currentRowsPerPage, sort: {},
        }
      });
      return;
    }
    const newSortOrders = { [key]: sortOrders[key] === 'asc' ? 'desc' : 'asc' };
    setSortOrders(newSortOrders as any);
    const searchFormWithSort = lastSearchForm == null ? searchForm?.values : lastSearchForm
    dispatch({
      type: 'PropertyList.FetchRequested', payload: {
        // lastSearchFormValues: searchForm.values,
        ...searchFormWithSort as any,
        //...searchForm.values as any,
        page, size: currentRowsPerPage,
        sort:
          key === 'price' && isRent ? { ['rent']: sortOrders[key] === 'asc' ? 'desc' : 'asc' } :
            key === 'pricePerNet' && isRent ? { ['rentPerNet']: sortOrders[key] === 'asc' ? 'desc' : 'asc' } :
              key === 'pricePerGross' && isRent ? { ['rentPerGross']: sortOrders[key] === 'asc' ? 'desc' : 'asc' } : newSortOrders,
      }
    });
  }

  // Initial Fetch
  useEffect(() => {
    dispatch({
      type: 'PropertyList.FetchRequested', payload: {
        // lastSearchFormValues: searchForm.values,
        ...searchForm.values as any,
        page, size: currentRowsPerPage, sort: sortOrders,
        // isCurrentUser: true,
      }
    });
  }, [currentRowsPerPage]);

  useEffect(() => {
    dispatch({ type: 'Layout.MaskDismissRequested' });
  }, [properties]);

  return (
    <div className={classes.root}>
      <PropertiesToolbar />
      <div className={classes.content}>
        <PropertiesSearch form={searchForm} onSearch={handleSearch} canReadUnownedProperty={canReadUnownedProperty} canReadTeamProperty={canReadTeamProperty} className={classes.search}></PropertiesSearch>
        <PropertiesTable
          page={page}
          totalPages={totalPages}
          totalElements={totalElements}
          limit={currentRowsPerPage}
          setPage={(page) => {
            setPage(page);
            dispatch({
              type: 'PropertyList.FetchRequested', payload: {
                ...searchForm.values as any,
                page, size: currentRowsPerPage, sort: sortOrders,
              }
            });
          }}
          rowsCountOptions={Object.keys(rowsCountPrefOptions).map(Number)}
          handleRowsCount={(ev) => { setCurrentRowsPerPage(ev); handleRowsCount(ev) }}
          properties={properties}
          sortOrders={sortOrders}
          handleSort={handleSort}
          fileForm={null}
        />
      </div>
    </div>
  );
};

export default PropertyList;