import { ApiError, ApiResult } from "api";
import featureTagApi from "api/featureTagApi";
import { FeatureTagListActions, IRootState } from "reducers";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { PASSagaContext } from 'sagas';
import { apiTaskWrapper } from "./saga-commons";


/////////////////////////////////////////////////////////////////////
///////////////////////// Fetch List ////////////////////////////////
/////////////////////////////////////////////////////////////////////


export function* watchFeatureTagListFetchRequested(context: PASSagaContext) {
  yield takeEvery( 
    'FeatureTagList.FetchRequested',
    apiTaskWrapper(requestFeatureTagList),
    context
  )
}

export function* requestFeatureTagList(context: PASSagaContext, action: Extract<FeatureTagListActions, { type: 'FeatureTagList.FetchRequested' }>) {
  if(action.type != 'FeatureTagList.FetchRequested') {
    return;
  }
  
  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<any> = yield call(featureTagApi.getList, token);
  
  if(!result.error) {
    yield put<FeatureTagListActions>({ type: 'FeatureTagList.Loaded', payload: result.data! });
  } else {
    throw ApiError.of(result.error);
  }

}


/////////////////////////////////////////////////////////////////////
////////////////////// update feature tag list //////////////////////
/////////////////////////////////////////////////////////////////////


export function* watchFeatureTagListUpdateRequested(context: PASSagaContext) {
  yield takeEvery( 
    'FeatureTagList.UpdateRequested',
    apiTaskWrapper(requestUpdateFeatureTagList),
    context
  )
}

export function* requestUpdateFeatureTagList(context: PASSagaContext, action: Extract<FeatureTagListActions, { type: 'FeatureTagList.UpdateRequested' }>) {
  if(action.type != 'FeatureTagList.UpdateRequested') {
    return;
  }

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<boolean> = yield call(featureTagApi.editList, action.payload, token);

  if(!result.error) {
    yield put<FeatureTagListActions>({ type: 'FeatureTagList.FetchRequested'});
  } else throw ApiError.of(result.error);

}
