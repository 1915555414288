import {
    Card,
    CardActions,
    CardContent, colors, Divider,
    Link,
    Table,
    TableBody,
    TableCell,
    TableHead, TableRow, TableSortLabel,
    // TablePagination,
    Theme
} from '@material-ui/core';
import { CardProps } from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { ActivityAuditingDTO } from 'common/dto';
import TablePagination from 'common/elements/TablePagination';
import { isNonEmpty } from 'common/utils';
import moment from 'moment';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { IRootState } from 'reducers';
import { format } from 'util';

const PUBLIC_URL = process.env['PUBLIC_URL'];
const IPLOCATION = 'https://iplocation.com/?ip=';

const useStyles = makeStyles((theme: Theme) => ({
	root: {},
	content: {
		padding: 0
	},
	inner: {
		minWidth: 1050
	},
	nameContainer: {
		display: 'flex',
		alignItems: 'center'
	},
	avatar: {
		marginRight: theme.spacing(2)
	},
	actions: {
		justifyContent: 'flex-start',
		background: colors.grey[50],
		flexWrap: 'wrap',
    '& > *': {
      wordBreak: 'keep-all',
      // marginBottom: '8px',
    },
    padding: '0px',
	},
	navLink: {
		color: '#1976d2', 
		cursor: 'pointer' 
	},
	tableRow: {
		// cursor: 'pointer',
	},
	tableCell: {
		wordWrap: 'normal',
		wordBreak: 'keep-all',
		lineBreak: 'strict',
		whiteSpace: 'nowrap',
		// minWidth: 128,
	},
	tablePaginationIconButton:{
    '& .MuiIconButton-root':{
      padding: '6px',
    }
	},
	tablePaginationToolbar:{
    paddingRight: '12px',
  },
  clearSortingContainer: {
    padding: '3px',
  }
}));

const navModuleMap: { [key: string]: any} = {
	BUILDING: {
		url: 'buildings'
	},
	CLIENT: {
		url: 'client-detail'
	},
	PROPERTY_STOCK: {
		url: 'properties'
	},
	TRANSACTION: {
		url: 'transactions'
	},
	ACCOUNT: {
		url: 'users'
	}
};

type Header = {
	title: string | JSX.Element,
	overrideStyle?: any,
};

interface ActivityAuditingTableProps extends CardProps {
  className?: string;
	activityAuditingList: ActivityAuditingDTO[];
	
  page: number;
  totalPages: number;
  totalElements: number;
  limit: number;
  setPage: (page: number) => void;
  sortOrders?: { [k: string]: 'desc' | 'asc' };
  handleSort?: (key: string | null) => void;
}

const ActivityAuditingTable = (props: ActivityAuditingTableProps) => {
	const { className, activityAuditingList, sortOrders, handleSort, page, totalPages, totalElements, limit, setPage, ...rest } = props;
	const { locale, langActivityAuditing, activityAuditingModuleOptions, lang } = useSelector((state: IRootState) => state.locale);
	const { DISPLAY_DATETIME_FORMAT } = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM ?? {});

	const classes = useStyles(0);
	const history = useHistory();

	let tableHeaders: Record<any, Header | null> = { // keyof ActivityAuditingDTO
		username: { title: langActivityAuditing.username },
		dateCreated: { title: langActivityAuditing.dateCreated },
		module: { title: langActivityAuditing.module },
		objectId: { title: langActivityAuditing.objectId },
		subModule: { title: langActivityAuditing.subModule },
		action: { title: langActivityAuditing.action },
		ipAddress: { title: langActivityAuditing.ipAddress },
		enterAt: { title: langActivityAuditing.enterAt },
		exitAt: { title: langActivityAuditing.exitAt },
		[locale === 'en' ? 'propertyAddressEn' : 'propertyAddressZh']: { title: langActivityAuditing.propertyAddress },
		[locale === 'en' ? 'clientNameEn' : 'clientNameZh']: { title: langActivityAuditing.clientName },
	};

	const navigationLink = (module: string, id: any) => PUBLIC_URL + `/${navModuleMap[module]?.['url']}/${id}`;
	const externalLink = (ipAddress: string) => IPLOCATION + ipAddress;

	const handlePageChange = (_: React.MouseEvent | null, page: number) => {
		setPage(page);
	};
	
	const overrideCols: { [col: string]: (activityAuditing: ActivityAuditingDTO) => any } = {
		username: (n) => n.username + (isNonEmpty(n.impersonateUsername) ? ' ' +  format(langActivityAuditing.impersonatedBy, n.impersonateUsername) : '') ,
		module: (n) => activityAuditingModuleOptions[n.module] ?? n.module,
		dateCreated: (n) => n.dateCreated ? moment(n.dateCreated).format(DISPLAY_DATETIME_FORMAT) : '',
		enterAt: (n) => n.enterAt ? moment(n.enterAt).format(DISPLAY_DATETIME_FORMAT) : '',
		exitAt: (n) => n.exitAt ? moment(n.exitAt).format(DISPLAY_DATETIME_FORMAT) : '',
		// objectId: (n) => n.objectId ? <a href={navigationLink(n.module, n.objectId)} style={{ color: '#1976d2' }}>{`#${n.objectId}`}</a> : '',
		objectId: (n) => ( n.propertyNumber!= null && n.module == 'PROPERTY_STOCK') ? <Link href={navigationLink(n.module, n.propertyNumber)}>{`${n.propertyNumber}`}</Link> 
		: (n.objectId != null) ? <Link href={navigationLink(n.module, n.objectId)}>{`${n.objectId}`}</Link> : '',
		ipAddress: (n) => n.ipAddress ? <Link href={externalLink(n.ipAddress)} target="_blank">{`${n.ipAddress}`}</Link> : '',
		propertyAddressEn: (n) => n.propertyAddressEn ?? n.propertyAddressZh,
		propertyAddressZh: (n) => n.propertyAddressZh ?? n.propertyAddressEn,
		clientNameEn: (n) => n.clientNameEn ?? n.clientNameZh,
		clientNameZh: (n) => n.clientNameZh ?? n.clientNameEn,
		
	};

	// const [selectedActivityAuditing, setSelectedActivityAuditing] = useState<ActivityAuditingDTO | null>(null);

	const [anchorEl, setAnchorEl] = React.useState<HTMLTableHeaderCellElement | null>(null);
	
	const open = Boolean(anchorEl);

	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>	
			<CardActions className={classes.actions}>
				<div style={{ flexGrow: 1 }} /> 
				{/* {Object.keys(sortOrders ?? {}).length ?
          <div className={classes.clearSortingContainer}>
						<Button style={{ marginRight: 0 }} variant="outlined" size="small" onClick={() => handleSort?.(null)}>{lang.actionClearSorting}</Button>
          </div>
				: null} */}

				<TablePagination
					classes={{
						actions: classes.tablePaginationIconButton,
            toolbar: classes.tablePaginationToolbar,
					}}
					// component="div"
					count={totalElements}
					onChangePage={handlePageChange}
					// onChangeRowsPerPage={handleRowsPerPageChange}
					page={page}
					totalPages={totalPages}
					rowsPerPage={limit}
					labelRowsPerPage={lang.rowsCount}
					rowsPerPageOptions={[20]}
				/>
			</CardActions>

			<Divider />
{/* 
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handlePopoverClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
			>
				<Typography style={{ padding: theme.spacing(2)}}>
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<div>{langActivityAuditing.captionCheckIpLocation}</div>
						<a href={externalLink(selectedActivityAuditing?.ipAddress ?? '')} target="_blank" style={{ color: '#1976d2', paddingLeft: '2px' }}>{selectedActivityAuditing?.ipAddress}</a>
					</div>
				</Typography>
			</Popover> */}

			<CardContent className={classes.content}>
				<PerfectScrollbar>
					<div className={classes.inner}>
						<Table size="small">
							<TableHead>
								<TableRow>
									{
										Object.keys(tableHeaders).filter((key) => !!tableHeaders[key as keyof ActivityAuditingDTO]).map((key,idx) =>
											<TableCell key={idx} className={classes.tableCell} style={tableHeaders[key as keyof ActivityAuditingDTO]?.overrideStyle}>
												<TableSortLabel active={sortOrders?.[key] !== undefined} 
												hideSortIcon={key =='username'|| key =='objectId'} 
												direction={sortOrders?.[key]} onClick={(key =='username'|| key =='objectId')? undefined : () => handleSort?.(key)}>
													{tableHeaders[key as keyof ActivityAuditingDTO]?.title}
												</TableSortLabel>
											</TableCell>
										)
									}
								</TableRow>
								{Object.keys(sortOrders ?? {}).length ? <TableRow>
								</TableRow> : null}
							</TableHead>
							<TableBody>
								{activityAuditingList.map(activityAuditing => (
									// <DataRow
									// 	className={classes.tableRow}
									// 	hover
									// 	key={activityAuditing.id}
									// 	onClick={handleNavigateToItem(activityAuditing.module, activityAuditing.objectId!)}
									// 	href={PUBLIC_URL + navigationLink(activityAuditing.module, String(activityAuditing.objectId!))}
									// >
									<TableRow
										className={classes.tableRow}
										// hover
										key={activityAuditing.id}
										// selected={selectedProperties.indexOf(property.id!) !== -1}
										// onClick={handleNavigateToItem(activityAuditing.module, activityAuditing.objectId!)}
									>
										{
											Object.keys(tableHeaders).filter((key) => !!tableHeaders[key as keyof ActivityAuditingDTO]).map((key) => {
												return <TableCell className={classes.tableCell} key={`${activityAuditing.id}-${key}`}>{overrideCols[key] ? overrideCols[key](activityAuditing) : activityAuditing[key as keyof ActivityAuditingDTO]}</TableCell>                        
											})
										}
									</TableRow>
									// </DataRow>
								))}
							</TableBody>
						</Table>
					</div>
				</PerfectScrollbar>
			</CardContent>
		</Card>
	);
}

export default ActivityAuditingTable;