import { CloseTransactionAgentDTO, CloseTransactionDTO } from "common/dto";
import { FormValidations } from "common/hooks";
import { isNonEmpty, numericValue } from "common/utils";

type Options = { [key: string]: string }

const isValidAgent = (agent: CloseTransactionAgentDTO) => {
  return isNonEmpty(agent.type) && isNonEmpty(agent.name) && isNonEmpty(agent.contact) && ((agent.type === 'I' && numericValue(agent.commission) !== undefined) || agent.type === 'E');
}

const sumAgentCommission = (agents?: CloseTransactionAgentDTO[]) => {
  return agents?.map(ba => ba?.commission ?? 0).reduce((a, b) => a + b, 0) ?? 0;
}

const closeTransactionFormValidations = (lang: Options): FormValidations<CloseTransactionDTO> => [
  (values) => (values.price || !values.canEditSellerFields) ? null : [
    ['price', lang.pleaseInput + lang.captionPrice],
  ],
  (values) => (values.transactionDate || !values.canEditSellerFields) ? null : [
    ['transactionDate', lang.pleaseSelect + lang.captionTransactionDate],
  ],
  (values) => (values.premises || !values.canEditSellerFields) ? null : [
    ['premises', lang.pleaseInput + lang.titlePremises],
  ],
  (values) => values.tranType !== 'SALE' ? null :
  (!values.canEditSellerFields || (isNonEmpty(values.initialDeposit?.toString()))) ? null : [
    ['initialDeposit', lang.pleaseInput + lang.captionInitialDeposit],
  ],
  (values) => values.tranType !== 'SALE' ? null :
    ((Number(values.initialDeposit) ?? 0) <= (values.price ?? 0) && (Number(values.bigDeposit) ?? 0) + (Number(values.initialDeposit) ?? 0) <= (values.price ?? 0)) ? null : [
      ['initialDeposit', lang.msgInvalidInitialDepositAndBigDepositSum],
  ],
  (values) => values.tranType !== 'SALE' ? null :
  (values.initialDepositDueDate || !values.canEditSellerFields) ? null : [
    ['initialDepositDueDate', lang.pleaseInput + lang.captionInitialDeposit],
  ],
  (values) => values.tranType !== 'SALE' ? null :
  (!values.canEditSellerFields || (isNonEmpty(values.bigDeposit?.toString()))) ? null : [
    ['bigDeposit', lang.pleaseInput + lang.captionBigDeposit],
  ],
  (values) => values.tranType !== 'SALE' ? null :
    ((Number(values.bigDeposit) ?? 0) <= (values.price ?? 0) && (Number(values.bigDeposit) ?? 0) + (Number(values.initialDeposit) ?? 0) <= (values.price ?? 0)) ? null : [
      ['bigDeposit', lang.msgInvalidInitialDepositAndBigDepositSum],
  ],
  (values) => values.tranType !== 'SALE' ? null :
  (values.bigDepositDueDate || !values.canEditSellerFields) ? null : [
    ['bigDepositDueDate', lang.pleaseInput + lang.captionBigDeposit],
  ],
  (values) => (isNonEmpty(values.completion?.toString()) || !(values.tranType === 'SALE') || !values.canEditSellerFields) ? null : [
    ['completion', lang.pleaseInput + lang.captionCompletion],
  ],
  (values) => (values.completionDueDate || !(values.tranType === 'SALE') || !values.canEditSellerFields) ? null : [
    ['completionDueDate', lang.pleaseSelect + lang.date],
  ],
  (values) => ((values.sellerComm !== undefined && values.sellerComm !== null) || !values.canEditSellerFields) ? null : [
    ['sellerComm', lang.pleaseInput + lang.captionCommission],
  ],
  (values) => (!values.sellerComm || !values.canEditSellerFields) || (values.sellerComm ?? 0) >= sumAgentCommission(values.sellerAgents) ? null : [
    ['sellerComm', values.tranType === 'SALE' ? lang.msgSellerAgentCommExceed : lang.msgLandlordAgentCommExceed],
  ],
  //If all agents are internal, commision must be equal to total commission
  (values) => ((values.sellerAgents?.filter(agent => agent.type === 'I').length === values.sellerAgents?.length) && (values.sellerComm ?? 0) === sumAgentCommission(values.sellerAgents)) || values.sellerAgents?.filter(agent => agent.type === 'I').length !== values.sellerAgents?.length || !values.canEditSellerFields ? null : [
    ['sellerComm', values.tranType === 'SALE' ? lang.msgSellerAgentCommNotEqual : lang.msgLandlordAgentCommNotEqual],
  ],
  (values) => (values.sellerCommReceivedDate || !values.canEditSellerFields) ? null : [
    ['sellerCommReceivedDate', lang.pleaseSelect + (values.tranType === 'SALE' ? lang.captionSellerCommReceivedDate : lang.captionLandlordCommReceivedDate)],
  ],
  // (values) => (values.sellerCommAgentShare || !values.canEditSellerFields) ? null : [
  //   [ 'sellerCommAgentShare', lang.pleaseInput + lang.captionCommissionShareForAgents ],
  // ],
  // (values) => (values.sellerCommAgentShare || !values.canEditSellerFields) ? null : [
  //   [ 'sellerCommAgentShare', lang.pleaseInput + lang.captionCommissionShareForAgents ],
  // ],
  (values) => ((values.buyerComm !== undefined && values.buyerComm !== null) || !values.canEditBuyerFields) ? null : [
    ['buyerComm', lang.pleaseInput + lang.captionCommission],
  ],
  (values) => (!values.buyerComm || !values.canEditBuyerFields) || (values.buyerComm ?? 0) >= sumAgentCommission(values.buyerAgents) ? null : [
    ['buyerComm', values.tranType === 'SALE' ? lang.msgBuyerAgentCommExceed : lang.msgTenantAgentCommExceed],
  ],
  //If all agents are internal, commision must be equal to total commission
  (values) => ((values.buyerAgents?.filter(agent => agent.type === 'I').length === values.buyerAgents?.length) && (values.buyerComm ?? 0) === sumAgentCommission(values.buyerAgents)) || (values.buyerAgents?.filter(agent => agent.type === 'I').length !== values.buyerAgents?.length) || !values.canEditBuyerFields ? null : [
    ['buyerComm', values.tranType === 'SALE' ? lang.msgBuyerAgentCommNotEqual : lang.msgTenantAgentCommNotEqual],
  ],
  (values) => (values.buyerCommReceivedDate || !values.canEditBuyerFields) ? null : [
    ['buyerCommReceivedDate', lang.pleaseSelect + (values.tranType === 'SALE' ? lang.captionBuyerCommReceivedDate : lang.captionTenantCommReceivedDate)],
  ],
  // (values) => (values.buyerCommAgentShare || !values.canEditBuyerFields) ? null : [
  //   [ 'buyerCommAgentShare', lang.pleaseInput + lang.captionCommissionShareForAgents ],
  // ],
  (values) => ((values.buyerAgents?.filter(a => !isValidAgent(a)).length ?? 0) === 0 || !values.canEditBuyerFields) ? null : [
    ['hasInvalidBuyerAgent', lang.pleaseInput],
  ],
  (values) => ((values.sellerAgents?.filter(a => !isValidAgent(a)).length ?? 0) === 0 || !values.canEditSellerFields) ? null : [
    ['hasInvalidSellerAgent', lang.pleaseInput],
  ],

  // Check clientId for internal signing buyer side agent
  (values) => values.buyerAgents?.filter(a => !a.linkedBuyerClientId && a.shouldSign && a.type === 'I')
    .filter(() => values.canEditBuyerFields)
    .map(a => [`invalidLinkedClientId-${a.agentId}` as any, values.tranType === 'SALE' ? lang.msgSpecifyBuyerClient : lang.msgSpecifyTenantClient]) ?? [],
];

export default closeTransactionFormValidations;