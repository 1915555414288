import { makeStyles, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { propertyApi } from 'api';
import clientApi from 'api/clientApi';
import { hasPermission } from 'common/access-control';
import { useConfirmDialog } from 'common/ConfirmDialog';
import { PropertyStockDTO } from 'common/dto';
import { FormPresenter, useForm } from 'common/hooks';
import { isNonEmpty } from 'common/utils';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState, PASDispatch } from 'reducers';
import { browserHistory } from 'store';
const useStyles = makeStyles(() => ({
  root: {

  },
  patchOutlineAutocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
      paddingLeft: 8,
    }
  },
  dialogContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60%',
  },
  textArea: {
    width: '90%'
  }
}));

interface AddLeadAgentFromApprovalDialogProps {
  stockForm: FormPresenter<PropertyStockDTO>;

  open: boolean;
  handleClickOpen: () => void;
  handleClose: () => void;

  //for warning dialog in create page OR add as lead agent in the preview page
  isCreatePage?: boolean; //set true for preview page
  createPropertyStock?: () => void; //NOT used 
  hasAssociatedClients?: boolean;
  hideOpenButton?: boolean;
  propertyStockId?: string;
  isPreviewPage?: boolean;
  propertyStockNum?: string;
}

const errorColor = '#e53935';

const AddLeadAgentFromApprovalDialog = ({ stockForm, open, handleClickOpen, handleClose, isCreatePage, hasAssociatedClients, hideOpenButton, propertyStockId, isPreviewPage, propertyStockNum }: AddLeadAgentFromApprovalDialogProps) => {
  const dispatch = useDispatch() as PASDispatch;
  const classes = useStyles();
  const { langPropertyStock, lang, locale, contactOptions } = useSelector((state: IRootState) => state.locale);

  const { token, privileges } = useSelector((state: IRootState) => state.login);

  const addLeadAgentForm = useForm<PropertyStockDTO>({}, {
    validations: [
      (values) => values.sellerClientId ? null : [['mainContact', langPropertyStock.msgInputClient]],
    ],
  });
  const confirmDialog = useConfirmDialog();

  // ---- privilege
  const canCreateUnownClient = hasPermission(privileges, 'CREATE', 'UNOWNED_CLIENT');
  const canCreateOwnedClient = hasPermission(privileges, 'CREATE', 'OWN_CLIENT');
  const canAssignUnownedAgentClient = hasPermission(privileges, 'UPDATE', 'ASSIGN_UNOWNED_AGENT_CLIENT');
  const canAssignTeamAgentClient = hasPermission(privileges, 'UPDATE', 'ASSIGN_TEAM_AGENT_CLIENT');
  const canAssignOwnAgentClient = hasPermission(privileges, 'UPDATE', 'ASSIGN_OWN_AGENT_CLIENT');
  const canAssignClientAgent = canAssignUnownedAgentClient || canAssignTeamAgentClient || canAssignOwnAgentClient;
  const canCreatePropertyStock = hasPermission(privileges, 'CREATE', 'PROPERTY_STOCK');
  const canCreateUnownPropertyStock = hasPermission(privileges, 'CREATE', 'UNOWNED_PROPERTY_STOCK');
  const canCreateOwnPropertyStock = hasPermission(privileges, 'CREATE', 'OWN_PROPERTY_STOCK');
  const canCreateCompanyClient = hasPermission(privileges, 'CREATE', 'COMPANY_CLIENT');

  // When the user has the privilege to create property stock + create unowned property stock but without privilege to create own property stock, some special handling needed
  const hasSpecialHandling = canCreatePropertyStock && canCreateUnownPropertyStock && !canCreateOwnPropertyStock;
  // If the user does NOT have the privilege to create unowned client & create company client -> hide/disable the button
  const hideButton = hasSpecialHandling ? !(canCreateUnownClient || canCreateCompanyClient)
    : !(canCreateOwnedClient || (canCreateUnownClient && canAssignClientAgent) || canCreateCompanyClient);

  // ---- dialog event
  // const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };



  const clickConfirmButton = async () => {

    // for adding lead agent to the open property stock
    const resultList = stockForm.values.leadAgents?.map(async (agent) => {

      return await propertyApi.checkExistSellerWithSameAgent(propertyStockId ?? '', agent.clientId, token ?? '');

    });

    const getResult = async () => {

      return await Promise.all(resultList ?? []);

    }
    if (!cannotAddCompanyClient) {

      if (isCreatePage) {
        doConfirmAction();
      } else {

        handleClose();

        dispatch({ type: 'Layout.MaskPresentRequested' });

        if ((await getResult()).filter(r => isNonEmpty(r.error)).length === 0) {
          if ((await getResult()).filter(r => r.data === true).length > 0) {
            const confirmMsg = langPropertyStock.msgWarningTheAgentIsAlreadyLeadAgent;
            confirmDialog.confirm(confirmMsg, lang.actionConfirm, lang.actionNo).then(confirmed => {

              if (confirmed) {
                doConfirmAction();
                propertyApi.resetPendingApprovalStage(stockForm.values.id ?? '', token ?? '');
                browserHistory.replace(`/properties/${stockForm.values.propertyNo!}`);
              } else {
                handleClose();
              }
            });
          } else {
            //no existing seller with same agent
            doConfirmAction();

            handleClose();
            propertyApi.resetPendingApprovalStage(stockForm.values.id ?? '', token ?? '');
            browserHistory.replace(`/properties/${stockForm.values.propertyNo!}`);
          }

        }
        dispatch({ type: 'Layout.MaskDismissRequested' });
      }
    }
  }

  const doConfirmAction = () => {
    dispatch({ type: 'Layout.MaskPresentRequested' });
    if (!cannotAddCompanyClient) {
      stockForm.values.leadAgents?.forEach((agent) => {
        clientApi.getDetail(agent.clientId.toString(), token ?? '', 'true').then((client) => {
          dispatch({
            type: 'Property.AddLeadAgentRequested', payload: {
              sellerClientId: +(client.data?.cid ?? '0'),
              mainContactId: +(client.data?.otherContacts?.[0].id ?? '0'),
              'mainContact': client.data?.otherContacts?.[0].value,
              'sellerZh': client.data?.chineseName,
              'sellerEn': client.data?.englishName,
              id: propertyStockId,
              addAgentsToNewPropertyStockByAdmin: 'true',
              addedFromPropertyStockNum: stockForm.values.propertyNo,
            } as PropertyStockDTO
          });
        });
      });
    }
    handleClose();
    dispatch({ type: 'Layout.MaskDismissRequested' });
  }

  //----- check whether the selected client is addable
  //If an existing property stock WITH associated client is detected, the user cannot input a company client as the new associated client.
  let [companyClientSelected, setCompanyClientSelected] = useState(false);
  const numOfLeadAgent = isCreatePage ? (hasAssociatedClients ? 1 : 0)
    : stockForm.values.leadAgents?.length ?? 0;
  const cannotAddCompanyClient = numOfLeadAgent > 0 ? companyClientSelected : false;
  const showUpdateAsCompanyStockMsg = numOfLeadAgent === 0 && !cannotAddCompanyClient && companyClientSelected;

  return (
    <div>
      {confirmDialog.render()}
      {!hideOpenButton ? <Button variant="text" color="primary" style={{ marginTop: '7px' }} onClick={handleClickOpen}>
        {langPropertyStock.actionAddLeadAgent}
      </Button> : null}
      <Dialog open={open} onClose={handleClose} aria-labelledby="add-agent-dialog">
        <DialogTitle id="add-agent-dialog-title">
          <Typography style={{ fontWeight: 'bold' }}>{langPropertyStock.actionAddLeadAgent}</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography >{`${langPropertyStock.msgConfirmAddAllAgentsAndClients}: ${propertyStockNum} ?`}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {lang.actionCancel}
          </Button>
          <Button disabled={cannotAddCompanyClient} onClick={clickConfirmButton} color="primary" variant="contained" autoFocus>
            {lang.actionConfirm}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddLeadAgentFromApprovalDialog;
