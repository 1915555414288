import { ApiResult } from "api";
import { JobDTO } from "common/dto";

const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];

const jobMonitorApi = {
  getJobs: async (token: string): Promise<ApiResult<JobDTO[]>> => {
    try {
      const response = await fetch(`${BASE_URL}/jobs`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },
};

export default jobMonitorApi;