import {
  Card,
  CardActions,
  CardContent, colors, Divider,
  Table,
  TableBody,
  TableCell,
  TableHead, TableRow, TableSortLabel,
  // TablePagination,
  Theme, useTheme
} from '@material-ui/core';
import { CardProps } from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { TransactionListItemDTO } from 'common/dto';
import DataRow from 'common/elements/DataRow';
import TablePagination from 'common/elements/TablePagination';
import { handlePriceDisplay, handlePriceDisplayUnit, isNonEmpty, multiLang } from 'common/utils';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { IRootState, PASDispatch } from 'reducers';




const PUBLIC_URL = process.env['PUBLIC_URL'];

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-start',
    background: colors.grey[50],
    flexWrap: 'wrap',
    '& > *': {
      wordBreak: 'keep-all',
      // marginBottom: '8px',
    },
    padding: '0px',
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableCell: {
    wordWrap: 'normal',
    wordBreak: 'keep-all',
    lineBreak: 'strict',
    whiteSpace: 'nowrap',
    // minWidth: 128,
  },
  tablePaginationIconButton: {
    '& .MuiIconButton-root': {
      padding: '6px',
    },
  },
  tablePaginationToolbar: {
    paddingRight: '12px',
  },
  clearSortingContainer: {
    padding: '3px',
  }
}));

type Header = {
  title: string | JSX.Element,
  overrideStyle?: any,
};

interface TransactionsTableProps extends CardProps {
  className?: string;
  transactions: TransactionListItemDTO[];

  page: number;
  totalPages: number;
  totalElements: number;
  limit: number;
  rowsCountOptions: number[];
  handleRowsCount: (count: number) => void;
  setPage: (page: number) => void;
  sortOrders?: { [k: string]: 'desc' | 'asc' };
  handleSort?: (key: string | null) => void;
}

type ColumnName = string; type ColumnPreferenceKey = string;
const columnNameToColumnPreferenceMapping: Record<ColumnName, ColumnPreferenceKey> = {
  dateCreated: 'dateCreated',
  district: 'district',
  streetEn: 'street',
  streetZh: 'street',
  buildingNameEn: 'building',
  buildingNameZh: 'building',
  lot: 'lot',
  blockEn: 'block',
  blockZh: 'block',
  floor: 'floor',
  unit: 'unit',
  gross: 'gross',
  net: 'net',
  price: 'price',
  pricePerNet: 'pricePerNet',
  buildingAge: 'buildingAge',
  transactionDate: 'transactionDate',
  usage: 'usage',
  buyerNameEn: 'buyerName',
  buyerNameZh: 'buyerName',
  sellerNameEn: 'sellerName',
  sellerNameZh: 'sellerName'
};

const TransactionsTable = (props: TransactionsTableProps) => {
  const { className, transactions, sortOrders, handleSort, page, totalPages, totalElements, limit, setPage, rowsCountOptions, handleRowsCount, ...rest } = props;
  const dispatch = useDispatch() as PASDispatch;

  const { locale, langTransaction, langUser, levelOptions, usageOptions, districtHkiOptions, districtKltOptions, districtNtOptions, transactionStatusOptions, langTransaction: lang, lang: langCommon } = useSelector((state: IRootState) => state.locale);
  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const districtOptions = {
    ...districtHkiOptions,
    ...districtKltOptions,
    ...districtNtOptions,
  };


  /*------column sorting ---------*/
  const { columnsOrder, disabledColumns } = useSelector((state: IRootState) => state.transaction);
  const [colOrders, setColOrders] = useState<string[]>([]);

  const [tableHeadersSorted, setTableHeadersSorted] = useState<Record<any, Header | null>>({});
  const selectColumnOrder = (columnName: string) => colOrders.indexOf(columnNameToColumnPreferenceMapping[columnName]);

  useEffect(() => {
    dispatch({ type: 'Transaction.ColumnPreference.FetchRequested' });
  }, []);

  useEffect(() => {
    const sortedHeaders: Record<any, Header | null> = {};
    if (colOrders?.length > 0) {
      Object.keys(tableHeaders).sort((colA, colB) =>
        selectColumnOrder(colA) > selectColumnOrder(colB) ? 1 : -1
      ).forEach((colName) => sortedHeaders[colName] = tableHeaders[colName]);
    }

    setTableHeadersSorted(sortedHeaders);
  }, [colOrders ?? [], locale]);

  useEffect(() => {
    setColOrders(columnsOrder ?? []);
  }, [columnsOrder]);

  /*------end: column sorting ---------*/

  const navigationLink = (id: string) => `/transactions/${id}`;

  const handleNavigateToItem = (id: string) => () => {
    sessionStorage.setItem('lastClickedTransaction', id);
    history.push(navigationLink(id));
  }

  const handlePageChange = (event: React.MouseEvent | null, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleRowsCount(+event.target.value);
  };


  let tableHeaders: Record<any, Header | null> = { // keyof PropertyListItemDTO
    // checkboxes: <Checkbox
    //   checked={selectedProperties.length === properties.length}
    //   color="primary"
    //   indeterminate={
    //     selectedProperties.length > 0 &&
    //     selectedProperties.length < properties.length
    //   }
    //   onChange={handleSelectAll}
    // />,
    // id: null,
    // status: { title: lang.status },
    dateCreated: { title: langCommon.dateCreated },
    district: { title: lang.district },
    [locale === 'en' ? 'streetEn' : 'streetZh']: { title: lang.street },
    [locale === 'en' ? 'buildingNameEn' : 'buildingNameZh']: { title: lang.buildingOrVillage },
    lot: { title: lang.lot },
    [locale === 'en' ? 'blockEn' : 'blockZh']: { title: lang.block },
    floor: { title: lang.floor },
    // level: { title: lang.level },
    unit: { title: lang.unit },
    gross: { title: lang.gross, overrideStyle: { width: 80, textAlign: 'right' } },
    net: { title: lang.net, overrideStyle: { width: 80, textAlign: 'right' } },
    price: { title: langTransaction.price, overrideStyle: { width: 100, textAlign: 'right' } },
    pricePerNet: { title: langTransaction.pricePerNetAbbr, overrideStyle: { width: 100, textAlign: 'right' } },
    buildingAge: { title: lang.buildingAgeAbbr, overrideStyle: { width: 70, textAlign: 'right' } },
    transactionDate: { title: lang.documentDate, overrideStyle: { width: 100, textAlign: 'right' } },
    usage: { title: lang.usage },
    [locale === 'en' ? 'buyerNameEn' : 'buyerNameZh']: { title: lang.buyer },
    [locale === 'en' ? 'sellerNameEn' : 'sellerNameZh']: { title: lang.seller },
  };

  const overrideCols: { [col: string]: (transaction: TransactionListItemDTO) => any } = {
    dateCreated: (transaction) => transaction.dateCreated ? moment(transaction.dateCreated).format(DISPLAY_DATE_FORMAT) : '',
    // status: (transaction) => propertyStockStatusOptions[transaction.status ?? ''],
    [locale === 'en' ? 'streetEn' : 'streetZh']:
      (transaction) => multiLang(locale, transaction.streetZh, transaction.streetEn),
    [locale === 'en' ? 'buildingNameEn' : 'buildingNameZh']:
      (transaction) => multiLang(locale, transaction.buildingNameZh, transaction.buildingNameEn),
    [locale === 'en' ? 'blockEn' : 'blockZh']:
      (transaction) => multiLang(locale, transaction.blockZh, transaction.blockEn),
    lot: (transaction) => transaction.lot ?? '',
    status: (transaction) => transactionStatusOptions[transaction.status],
    usage: (transaction) => usageOptions[transaction.usage],
    district: (transaction) => districtOptions[transaction.district],
    level: (transaction) => transaction.level != null ? transaction.level?.split(',').map(l => levelOptions[l.trim()]).join(',') : 'N/A',
    // // gross: (property) => property.grossArea,
    price: (transaction) => transaction.status === 'SOLD' ?
      (String(handlePriceDisplay(transaction.price ?? 0, locale)) + handlePriceDisplayUnit(transaction.price ?? 0, locale, langCommon))
      : String(transaction.rent),
    // price: (transaction) => locale !== 'en' ? chineseNumberM(transaction.price) : transaction.price,
    pricePerNet: (transaction) => transaction.status === 'SOLD' ?
      (isNonEmpty(transaction.net) ? (transaction.pricePerNet?.toFixed(0) ?? 0) : '')
      : transaction.rentPerNet?.toFixed(0) ?? 0,
    buildingAge: (transaction) => String(transaction.buildingAge ?? '') ?? '',
    transactionDate: (transaction) => transaction.transactionDate ? moment(transaction.transactionDate).format(DISPLAY_DATE_FORMAT) : '',
    [locale === 'en' ? 'buyerNameEn' : 'buyerNameZh']:
      (transaction) => multiLang(locale, transaction.buyerNameZh, transaction.buyerNameEn),
    [locale === 'en' ? 'sellerNameEn' : 'sellerNameZh']:
      (transaction) => multiLang(locale, transaction.sellerNameZh, transaction.sellerNameEn),
    // // net: (property) => property.netArea,
  }

  const tableBodyStyle: { [key: string]: React.CSSProperties } = {
    gross: { textAlign: 'right' },
    net: { textAlign: 'right' },
    price: { textAlign: 'right' },
    pricePerNet: { textAlign: 'right' },
    buildingAge: { textAlign: 'right' },
    transactionDate: { textAlign: 'right' },
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardActions className={classes.actions}>
        <div style={{ flexGrow: 1 }} />

        {/* {Object.keys(sortOrders ?? {}).length ?
          <div className={classes.clearSortingContainer}>
            <Button style={{ marginRight: 0 }} variant="outlined" size="small" onClick={() => handleSort?.(null)}>{lang.actionClearSorting}</Button>
          </div>
        : null} */}

        <TablePagination
          classes={{
            actions: classes.tablePaginationIconButton,
            toolbar: classes.tablePaginationToolbar,
          }}
          // component="div"
          count={totalElements}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          scrollocate={400}
          totalPages={totalPages}
          rowsPerPage={limit}
          labelRowsPerPage={langUser.rowsCount}
          rowsPerPageOptions={rowsCountOptions}
        // rowsPerPageOptions={[20]}
        />
      </CardActions>

      <Divider />

      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {
                    Object.keys(tableHeadersSorted)
                      .filter((key) => !!tableHeadersSorted[key as keyof TransactionListItemDTO])
                      .filter((key) => !disabledColumns.includes(columnNameToColumnPreferenceMapping[key]))
                      .map((key, idx) =>
                        <TableCell key={idx} className={classes.tableCell} style={tableHeadersSorted[key as keyof TransactionListItemDTO]?.overrideStyle}>
                          <TableSortLabel active={(key === 'transactionDate' && sortOrders !== undefined && sortOrders?.[key] !== undefined && Object.keys(sortOrders)[0] === 'transactionDate') || (key !== 'transactionDate' && sortOrders?.[key] !== undefined)} direction={sortOrders?.[key]} onClick={() => handleSort?.(key)}>
                            {tableHeadersSorted[key as keyof TransactionListItemDTO]?.title}
                          </TableSortLabel>
                        </TableCell>
                      )
                  }
                </TableRow>

              </TableHead>
              <TableBody>
                {transactions.map(transaction => (
                  <DataRow
                    className={classes.tableRow}
                    hover
                    // style={{ background: transaction.propertyIsValid ? 'inherit' : theme.palette.error.light }}
                    key={transaction.id}
                    onClick={handleNavigateToItem(transaction.id!)}
                    href={PUBLIC_URL + navigationLink(transaction.id!)}
                  >
                    {
                      Object.keys(tableHeadersSorted)
                        .filter((key) => !!tableHeadersSorted[key as keyof TransactionListItemDTO])
                        .filter((key) => !disabledColumns.includes(columnNameToColumnPreferenceMapping[key]))
                        .map((key) => {
                          return <TableCell
                            size="small"
                            style={transaction.id?.toString() === sessionStorage.getItem('lastClickedTransaction') ? { ...tableBodyStyle[key], fontWeight: 'bold', color: '#551A8B ' } : { color: transaction.propertyIsValid ? 'inherit' : theme.palette.error.dark, ...tableBodyStyle[key] }}
                            className={classes.tableCell}
                            key={`${transaction.id}-${key}`}>
                            {overrideCols[key] ? overrideCols[key](transaction) : transaction[key as keyof TransactionListItemDTO]}
                          </TableCell>

                        })
                    }
                    {/* <TableCell style={{ cursor: 'default' }} onClick={(ev) => ev.stopPropagation()} size="small" className={classes.tableCell} key={`${transaction.id}-viewIcon`}>
                      <IconButton disabled tooltip="Will be ready with Transaction module">
                        <ViewIcon />
                      </IconButton>
                    </TableCell> */}
                  </DataRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <div style={{ flexGrow: 1 }} />

        {/* {Object.keys(sortOrders ?? {}).length ?
          <div className={classes.clearSortingContainer}>
            <Button style={{ marginRight: 0 }} variant="outlined" size="small" onClick={() => handleSort?.(null)}>{lang.actionClearSorting}</Button>
          </div>
        : null} */}

        <TablePagination
          classes={{
            actions: classes.tablePaginationIconButton,
            toolbar: classes.tablePaginationToolbar,
          }}
          // component="div"
          count={totalElements}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          scrollocate={400}
          totalPages={totalPages}
          rowsPerPage={limit}
          labelRowsPerPage={langUser.rowsCount}
          rowsPerPageOptions={rowsCountOptions}
        // rowsPerPageOptions={[20]}
        />
      </CardActions>
    </Card>
  );
};

// PropertiesTable.propTypes = {
//   className: PropTypes.string,
//   properties: PropTypes.array.isRequired
// };

export default TransactionsTable;
