import { ApiError, ApiResult } from 'api';
import forgotPasswordApi from 'api/forgotPasswordApi';
import { ResetPasswordActions } from 'reducers';
import { call, put, takeEvery } from 'redux-saga/effects';
import { PASSagaContext } from 'sagas';
import { apiTaskWrapper } from './saga-commons';

const PUBLIC_URL = process.env['REACT_APP_PAS_APP_PUBLIC_URL'] ?? '/';


///////////////////////////////////////////////////////////////////
///////////////////// Verify token and status  ////////////////////////
///////////////////////////////////////////////////////////////////

export function* watchVerifyTokenRequested(context: PASSagaContext) {
    yield takeEvery(
      'ResetPassword.VerifyTokenRequested',
      apiTaskWrapper(verifyToken),
      context,
    )
  }

export function* verifyToken(context: PASSagaContext, action: ResetPasswordActions) {
  if (action.type != 'ResetPassword.VerifyTokenRequested') {
    return;
  }
   const result: ApiResult<any> = yield call(forgotPasswordApi.verifyToken, action.payload.token);

  if (!result.error) {
    yield put<ResetPasswordActions>({ type: 'ResetPassword.PendingForUserInput' });
  } else {

    switch (result.error) {
      case 'ERR_INVALID_TOKEN': window.location.href = PUBLIC_URL + "/not-found"; break;
      case 'ERR_TOKEN_EXPIRED': yield put<ResetPasswordActions>({ type: 'ResetPassword.LinkExpired' });; break;
      default: throw ApiError.of(result.error);
    }
  }

}
 