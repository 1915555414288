import {
  Button, Card, CardActions, CardContent, Checkbox, Divider, FormControl, FormControlLabel,
  FormGroup, FormHelperText, Grid, InputAdornment, makeStyles, MenuItem, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, TextareaAutosize, TextField, TextFieldProps, Typography, withStyles
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import clsx from 'clsx';
import { Form5DTO, PropertyStockDTO } from 'common/dto';
import { FormPresenter } from "common/hooks";
import { HandleFormHelperText } from 'common/ui';
import { genAddress, initFormKeysAndBooleanMap, isNonEmpty, limitNumberFieldLength, limitTextFieldLength } from 'common/utils';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducers';
const regex = new RegExp(/(^\d*\.?\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/);
const NumberField = withStyles({
  root: {
    '& input': {
      textAlign: 'right',
      '-moz-appearance': 'textfield',
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  },
},
)((props: TextFieldProps) => {
  return <TextField type={"text"}{...props}

    inputProps={{
      ...props.inputProps,
      min: 0,
      max: Math.max(),
      inputMode: 'decimal',
      pattern: "([^0-9]*)",
      step: 0.01
    }}

    onKeyDown={e => {
      // If the key pressed is not a number or a period, nothing is printed
      if (!e.key.match(regex)) {

        e.preventDefault();

      }
      // if (!e.key.match(regex) || (e.key === '.' && String(props.value).indexOf('.') > 0) || (e.key === '.' && String(props.value).length === 0) || e.keyCode === 229 || e.key === 'Unidentified') {

      //   e.preventDefault();

      // }

    }}

    onKeyPress={e => {
      // If the key pressed is not a number or a period, nothing is printed
      if (!e.key.match(regex)) {

        e.preventDefault();

      }
    }}



  />;
});

const useStyles = makeStyles(() => ({
  root: {},
  partTitle: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  textArea: {
    width: '100%',
    margin: 'dense',
    padding: '8px',
    fontSize: '14px'
  },
  table: {
    minWidth: 650,
  },
  textField: {
    width: '30%',
  },
  datePicker: {
    width: '30%',
  },
  column0: {
    width: '3%',
  },
  column1: {
    width: '13%',
  },
  numberFields: {
    width: '30%',
  },
  checkboxColumn: {
    width: '5%',
  },
  subTableCell: {
    borderBottomColor: '#FFFFFF',
  },
  divider: {
    backgroundColor: '#1565c0',
  },
  infoPartTextField: {
    width: '100%',
  },
  companyInfo: {
    paddingLeft: '20px',
  },
  part6DisabledText: {
    "& .MuiFormControlLabel-label": {
      color: 'black',
    }
  }
}));

const errorColor = '#e53935';

interface Form5Props {
  className?: string;
  propertyStockId: string;
  form5: FormPresenter<Form5DTO>;
  landsearchdate: string;
}

export const form5Initial: Partial<Form5DTO> = {
  relationship: 'potentiallyDualAgency',
  landlordAcknowledgementOption: 'option2',
  allowViewingPerson: 'both',
  passKeyToPerson: 'both',
  commissionIn: 'percentage',
  commissionRate: 50,
  exclusiveAgent: false,
  includeRatesAndMgtFee: false,
  allowViewing: true,
  passKeyForSafeCustody: false,
  passKeyToThirdParty: false,
  allowSubListing: true,
  allowAdvertisement: true,
  needDisclosure: false,
  withInterest: false,
  paymentTimeOption: 'option1'
};

const disablePrevDates = (startDates: any) => {
  const startSeconds = Date.parse(startDates);
  return (date: any) => {
    return Date.parse(date) < startSeconds;
  }
}

const Form5Dialog = (props: Form5Props) => {
  const { className, propertyStockId, form5, ...rest } = props;

  const classes = useStyles();
  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);
  const agencyCompanyInfo = useSelector((state: IRootState) => state.systemSettings.Company.AGENCY_COMPANY_INFO);
  const { lang, langForm5, langPropertyStock, formLocaleOptions } = useSelector((state: IRootState) => state.locale);
  const dispatch = useDispatch();
  const bind = form5.bind;

  const [keyBooleanMap, setKeyBooleanMap] = useState(initFormKeysAndBooleanMap(form5));

  useEffect(() => {
    setKeyBooleanMap(initFormKeysAndBooleanMap(form5));
  }, [Object.keys(form5.values).length])

  const requiredFields: { [key: string]: string[] } = {
    form: ['formLocale', 'landlordName', 'agentName', 'propertyAddress', 'validityPeriodStart', 'validityPeriodEnd', 'listRental', 'interestsInProperty', 'landlordAcknowledgementOption'],
    landlordInfo: ['eitherSignatoryInfoOrHKID', 'nameOfSignatoryOfLandlord', 'positionOfSignatoryOfLandlord', 'businessRegistrationCertificateNumberOfLandlord', 'landlordAddress', 'landlordPhoneNumber'],
    agentInfo: ['nameAndLicenceNumberOfAgent', 'numberOfStatementOfParticularsOfBusiness', 'agentAddress', 'agentPhoneNumber'],
  };

  const partNameMapping = (partName: string) => {
    switch (partName) {
      case 'form': return langForm5.form;
      case 'landlordInfo': return langForm5.landlordInfo;
      case 'agentInfo': return langForm5.agentInfo;
    }
  }

  const missingFieldList = () => {
    let resultStr: string[] = [];
    Object.keys(requiredFields).forEach(part => {
      let partMissingFields: string[] = [];
      requiredFields[part].forEach(key => {
        let validationMsg = form5.hasError(key as keyof Form5DTO);
        if (validationMsg) {
          partMissingFields.push(validationMsg?.replace(langForm5.pleaseInput, '')?.replace(langForm5.pleaseSelect, ''));
        }
      });
      if (partMissingFields.length > 0) {
        resultStr.push(partNameMapping(part.toString()) + ": " + partMissingFields.join(', '));
      }

    });
    return resultStr.join('\n');
  }

  const propertyStockDto = useSelector((state: IRootState) => state.property.fetchedDetailsByPropertyStockId[props.propertyStockId]) ?? {} as Partial<PropertyStockDTO>;
  const { districtHkiOptions: districtHkiOptionsEn, districtNtOptions: districtNtOptionsEn, districtKltOptions: districtKltOptionsEn } = useSelector((state: IRootState) => state.locale._bundle['en']);
  const { districtHkiOptions: districtHkiOptionsZhHK, districtNtOptions: districtNtOptionsZhHK, districtKltOptions: districtKltOptionsZhHK } = useSelector((state: IRootState) => state.locale._bundle['zh_HK']);

  const districtEnOptions = {
    ...districtHkiOptionsEn,
    ...districtNtOptionsEn,
    ...districtKltOptionsEn,
  };
  const districtZhHkOptions = {
    ...districtHkiOptionsZhHK,
    ...districtNtOptionsZhHK,
    ...districtKltOptionsZhHK,
  };

  const companyDetailsLocale = (locale: string) => {
    return (locale === 'en') ? { name: agencyCompanyInfo['COMPANY_NAME_EN'], address: agencyCompanyInfo['ADDRESS_EN'] } : { name: agencyCompanyInfo['COMPANY_NAME_CN'], address: agencyCompanyInfo['ADDRESS_CN'] };
  };
  const propertyAddress = (locale: string) => {

    const address = genAddress(locale,
      propertyStockDto.unit?.join(',') ?? '',
      propertyStockDto.floor?.join(',') ?? '',
      propertyStockDto.block ?? '', propertyStockDto.blockEn ?? '',
      propertyStockDto.building ?? '', propertyStockDto.buildingEn ?? '',
      propertyStockDto.street ?? '', propertyStockDto.streetEn ?? '',
      (locale === 'en') ? districtEnOptions[propertyStockDto.district ?? ''] : districtZhHkOptions[propertyStockDto.district ?? '']);
    return address;
  };

  const updateCompanyCurrentLocaleDetails = (locale: string) => {
    let details = companyDetailsLocale(locale);
    form5.updateValues('agentName', details.name);
    form5.updateValues('agentAddress', details.address);
    form5.updateValues('propertyAddress', propertyAddress(locale));
  };

  return (
    <Card {...rest}
      className={clsx(classes.root, className)}>
      {/* 生成出售物業代理協議 (表格3) */}
      {/* <CardHeader></CardHeader> */}
      {/* <Divider /> */}
      <CardContent>
        <Grid item container xs={12} spacing={2}>
          {isNonEmpty(missingFieldList()) ?
            <Grid
              item
              md={12}
              xs={12}
            >

              <div style={{ marginBottom: '5px', whiteSpace: "pre-wrap" }}>
                <Typography style={{ color: errorColor, fontSize: '13px', fontWeight: 'bold', fontStyle: 'italic' }}>{langForm5.msgInputMissingFields}</Typography>
                <Typography style={{ color: errorColor, fontSize: '13px', fontStyle: 'italic' }}>{missingFieldList()}</Typography>
              </div>
              <Divider />
            </Grid>
            : null
          }
          <Grid
            item
            sm={2}
            md={2}
            xs={12}
          >
            <Typography>{langPropertyStock.captionFormLocale}</Typography>
            <TextField select
              fullWidth
              margin="dense"
              {...bind('formLocale')}
              required
              variant="outlined"
              onChange={(event) => {
                bind('formLocale').onChange(event);
                updateCompanyCurrentLocaleDetails(event.target.value);
              }}>
              {
                Object.keys(formLocaleOptions).map((opt) => <MenuItem value={opt}>{formLocaleOptions[opt]}</MenuItem>)
              }
            </TextField>
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >
            <Typography className={classes.partTitle} >{langForm5.formName}</Typography>
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >
            {/*------------- 1. Appointment of Agent and Validity Period of this Agreement------------ */}
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="part 1 table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.column0}>1.</TableCell>
                    <TableCell colSpan={2} align="left" className={classes.column1} >{langForm5.titlePart1}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/*----------- landlord name ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell className={classes.column1} >{langForm5.landlordName}</TableCell>
                    <TableCell>
                      <TextField
                        className={classes.textField}
                        margin="dense"
                        variant="outlined"
                        {...bind('landlordName')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'landlordName', form5, keyBooleanMap, setKeyBooleanMap);
                        }}
                        helperText={form5.hasError('landlordName')}
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('landlordName')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>

                  {/*----------- agent name ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell className={classes.column1} >{langForm5.agentName}</TableCell>
                    <TableCell>
                      <TextField
                        className={classes.textField}
                        margin="dense"
                        variant="outlined"
                        disabled={true}
                        {...bind('agentName')}
                      />
                      <FormControl component="fieldset" style={{ paddingLeft: '10px', paddingTop: '7px' }}>
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            control={<Checkbox color="primary" />}
                            label={langForm5.exclusiveAgent}
                            labelPlacement="end"
                            checked={form5.values.exclusiveAgent}
                            onChange={() => {
                              form5.updateValues('exclusiveAgent', !form5.values.exclusiveAgent);
                            }}
                          />
                        </FormGroup>
                      </FormControl>
                    </TableCell>

                  </TableRow>

                  {/*----------- property address ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell className={classes.column1} >{langForm5.propertyAddress}</TableCell>
                    <TableCell>
                      <TextareaAutosize
                        className={classes.textArea}
                        style={{ width: '30%' }}
                        rowsMin={4}
                        rowsMax={5}
                        placeholder=''
                        {...bind('propertyAddress')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'propertyAddress', form5, keyBooleanMap, setKeyBooleanMap);
                        }}
                      />
                      {form5.hasError('propertyAddress') ? <FormHelperText style={{ color: form5.hasError('propertyAddress') ? errorColor : 'inherit' }}>{langForm5.pleaseInput + langForm5.propertyAddress}</FormHelperText> : null}
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('propertyAddress')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>

                  {/*----------- validity period ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row" data-key={'field-form5-isValidityPeriodValid'} ></TableCell>
                    <TableCell className={classes.column1} data-key={'field-form5-validityPeriodStart'}>
                      {langForm5.validityPeriod}
                      <div style={{ display: 'inline-block', width: '80%' }}>
                        {form5.hasError('isValidityPeriodValid') ? <FormHelperText style={{ color: form5.hasError('isValidityPeriodValid') ? errorColor : 'inherit' }}>{form5.validationErrors?.isValidityPeriodValid}</FormHelperText> : null}
                      </div>
                    </TableCell>
                    <TableCell data-key={'field-form5-validityPeriodEnd'} >
                      <div>
                        <KeyboardDatePicker autoOk={true}
                          className={classes.datePicker}
                          //disableToolbar
                          variant="inline"
                          inputVariant="outlined"
                          format={DISPLAY_DATE_FORMAT}
                          label={langForm5.validityPeriodStart}
                          margin="dense"
                          shouldDisableDate={disablePrevDates(props.landsearchdate)}
                          value={form5.values.validityPeriodStart ? moment(form5.values.validityPeriodStart) : null}
                          onChange={(value) => {
                            form5.setValues({
                              ...form5.values,
                              validityPeriodStart: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
                            });
                            // if (value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString()?.localeCompare(form5.values.validityPeriodEnd ?? "") === 1) {
                            //   form5.updateValues('validityPeriodEnd', "");
                            // }
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                        {' '}
                        {form5.values.validityPeriodStart?.length !== 20 && form5.values.validityPeriodStart?.localeCompare(form5.values.validityPeriodEnd ?? "") === 1 ? form5.values.validityPeriodEnd = "" : ""}
                        <KeyboardDatePicker autoOk={true}
                          className={classes.datePicker}
                          // InputProps={InputGroup.leftItem()}
                          //disableToolbar
                          variant="inline"
                          inputVariant="outlined"
                          format={DISPLAY_DATE_FORMAT}
                          label={langForm5.validityPeriodEnd}
                          margin="dense"
                          value={form5.values.validityPeriodEnd ? moment(form5.values.validityPeriodEnd) : null}
                          shouldDisableDate={disablePrevDates(form5.values.validityPeriodStart)}
                          onChange={(value) => {
                            form5.setValues({
                              ...form5.values,
                              validityPeriodEnd: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
                            });
                            // if (value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString()?.localeCompare(form5.values.validityPeriodStart ?? "") === -1) {
                            //   form5.updateValues('validityPeriodStart', "");
                            // }
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                        {langForm5.validityPeriodRemarks}
                      </div>
                      <div>
                        <div style={{ display: 'inline-block', width: '30%' }}>
                          {form5.hasError('validityPeriodStart') ? <FormHelperText style={{ color: form5.hasError('validityPeriodStart') ? errorColor : 'inherit' }}>{form5.validationErrors?.validityPeriodStart}</FormHelperText> : null}
                        </div>
                        <div style={{ display: 'inline-block', width: '30%' }}>
                          {form5.hasError('validityPeriodEnd') ? <FormHelperText style={{ color: form5.hasError('validityPeriodEnd') ? errorColor : 'inherit' }}>{form5.validationErrors?.validityPeriodEnd}</FormHelperText> : null}
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            {/* ------------- 2. Agency Relationship and Duties of Agent ------------- */}
            <Grid
              item
              md={12}
              xs={12}
            >
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="part 2 table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.column0}>2.</TableCell>
                      <TableCell colSpan={2} align="left" data-key={'field-form5-relationship'} >{langForm5.titlePart2}</TableCell>
                      {/* <TableCell></TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell className={classes.column1}>{langForm5.relationship}
                      </TableCell>
                      <TableCell>
                        <div >
                          <FormControl component="fieldset" style={{}}>
                            <RadioGroup aria-label="Relationships" name="customized-radios" row
                              value={form5.values.relationship ?? ''}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const val = (event.target as HTMLInputElement).value;
                                form5.updateValues('relationship', val);
                              }}
                            >
                              <FormControlLabel
                                value="singleAgency"
                                control={<Radio size="small" />}
                                label={langForm5.singleAgency}
                              />
                              <FormControlLabel
                                value="dualAgency"
                                control={<Radio size="small" />}
                                label={langForm5.dualAgency}
                              />
                              <FormControlLabel
                                value="potentiallyDualAgency"
                                control={<Radio size="small" />}
                                label={langForm5.potentiallyDualAgency}
                              />
                              <FormControlLabel value="" control={<Radio size="small" />} label='' style={{ display: 'none' }} />
                            </RadioGroup>
                          </FormControl>
                        </div>
                        {form5.hasError('relationship') ? <FormHelperText style={{ color: form5.hasError('relationship') ? errorColor : 'inherit' }}>{langForm5.msgSelectRelationship}</FormHelperText> : null}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/*------------- 3. List Rental ------------ */}
            <Grid
              item
              md={12}
              xs={12}
            >
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="part 3 table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.column0}>3.</TableCell>
                      <TableCell align="left" className={classes.column1} >{langForm5.titlePart3}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell>{langForm5.titlePart3}
                      </TableCell>
                      <TableCell>
                        <NumberField
                          className={classes.numberFields}
                          margin="dense"
                          variant="outlined"
                          InputProps={{
                            startAdornment: <InputAdornment position="start">{langForm5.HKDollar}</InputAdornment>
                          }}
                          {...bind('listRental')}
                          onChange={(e) => {
                            limitNumberFieldLength(e, 'listRental', form5);
                          }}
                          /*
                          onKeyPress={(e) => {
                            if (e.key == ".") {
                              e.preventDefault();
                            }
                          }}
                          */
                          helperText={form5.hasError('listRental')}
                        />
                        <FormControl component="fieldset" style={{ paddingLeft: '10px', paddingTop: '7px' }}>
                          <FormGroup aria-label="position" row>
                            <FormControlLabel
                              control={<Checkbox color="primary" />}
                              label={langForm5.includeRatesAndMgtFee}
                              labelPlacement="end"
                              checked={form5.values.includeRatesAndMgtFee}
                              onChange={() => {
                                form5.updateValues('includeRatesAndMgtFee', !form5.values.includeRatesAndMgtFee);
                              }}
                            />
                          </FormGroup>
                        </FormControl>
                      </TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>



            {/*------------- 6. Property Inspection ------------ */}
            <Grid
              item
              md={12}
              xs={12}
            >
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="part 6 table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.column0}>6.</TableCell>
                      <TableCell align="left" colSpan={2} data-key={'field-form5-isMissingPart6'} >
                        {langForm5.titlePart6}
                        {form5.hasError('isMissingPart6') &&
                          <FormHelperText error>{form5.hasError('isMissingPart6')}</FormHelperText>
                        }
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {/* -------------------- (a) allow viewing ------------------- */}
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      {/* <TableCell className={classes.checkboxColumn}>
                        <Checkbox/>
                      </TableCell>
                      <TableCell>
                        <Typography>{langForm3.allowViewing}</Typography>
                      </TableCell> */}
                      <TableCell colSpan={2}>
                        <div >
                          <FormControl component="fieldset" style={{ display: 'inline-block', paddingTop: '8px' }}>
                            <FormGroup aria-label="position" row>
                              <FormControlLabel
                                control={<Checkbox color="primary" />}
                                label={langForm5.allowViewingSentence1}
                                labelPlacement="end"
                                checked={form5.values.allowViewing}
                                onChange={() => {
                                  //reset inline dropdown selection
                                  if (form5.values.allowViewing) {
                                    form5.updateValues('allowViewingPerson', 'both');
                                  }
                                  //update checkbox value
                                  form5.updateValues('allowViewing', !form5.values.allowViewing);
                                }}
                              />

                            </FormGroup>
                          </FormControl>
                          <TextField
                            style={{ width: '15%' }}
                            margin="dense"
                            select
                            variant="outlined"
                            disabled={form5.values.allowViewing === false}
                            {...bind('allowViewingPerson')}
                          >
                            <MenuItem value='both'>{langForm5.bothAgentAndPurchaser}</MenuItem>
                            <MenuItem value='agent'>{langForm5.agent}</MenuItem>
                            <MenuItem value='tenants'>{langForm5.tenants}</MenuItem>

                          </TextField>
                          <div style={{ display: 'inline-block', paddingTop: '20px', paddingLeft: '6px' }}>
                            {langForm5.allowViewingSentence2}
                          </div>
                        </div>
                        {/* show radio button */}
                        {/* <div style={{paddingLeft: '30px'}}>
                          <FormControl component="fieldset" style={{}}>
                           
                            <RadioGroup defaultValue="both" aria-label="Property Categories" name="customized-radios" row>
                              <FormControlLabel value="agent" control={<Radio size="small"/>} label={langForm3.agent} />
                              <FormControlLabel value="purchaser" control={<Radio size="small"/>} label={langForm3.purchaser} />
                              <FormControlLabel value="both" control={<Radio size="small"/>} label={langForm3.bothAgentAndPurchaser} />
                              
                            </RadioGroup>
                          </FormControl>
                        </div> */}
                      </TableCell>
                    </TableRow>

                    {/* -------------------- (b) Pass key for safe custody  ------------------- */}
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      {/* <TableCell className={classes.checkboxColumn}>
                        <Checkbox/>
                      </TableCell>
                      <TableCell>
                        <Typography>{langForm3.passKeyForSafeCustody}</Typography>
                      </TableCell> */}
                      <TableCell colSpan={2}>
                        <FormControl component="fieldset">
                          <FormGroup aria-label="position" row>
                            <FormControlLabel
                              control={<Checkbox color="primary" />}
                              label={langForm5.passKeyForSafeCustody}
                              labelPlacement="end"
                              checked={form5.values.passKeyForSafeCustody}
                              onChange={() => {
                                form5.updateValues('passKeyForSafeCustody', !form5.values.passKeyForSafeCustody);
                              }}
                            />
                          </FormGroup>
                        </FormControl>
                      </TableCell>
                    </TableRow>

                    {/* -------------------- (c) Pass key to third party  ------------------- */}
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      {/* <TableCell className={classes.checkboxColumn}>
                        <Checkbox/>
                      </TableCell>
                      <TableCell>
                        <Typography>{langForm3.passKeyToThirdParty}</Typography>
                      </TableCell> */}
                      <TableCell colSpan={2}>
                        <div >
                          <FormControl component="fieldset" style={{ display: 'inline-block', paddingTop: '8px' }}>
                            <FormGroup aria-label="position" row>
                              <FormControlLabel
                                control={<Checkbox color="primary" />}
                                label={langForm5.passKeyToThirdPartySentence1}
                                labelPlacement="end"
                                checked={form5.values.passKeyToThirdParty}
                                onChange={() => {
                                  //reset inline dropdown selection 
                                  if (form5.values.passKeyToThirdParty) {
                                    form5.updateValues('passKeyToPerson', 'both');
                                  }
                                  //update checkbox value
                                  form5.updateValues('passKeyToThirdParty', !form5.values.passKeyToThirdParty);
                                }}
                              />

                            </FormGroup>
                          </FormControl>
                          <TextField
                            style={{ width: '15%' }}
                            margin="dense"
                            // required
                            select
                            variant="outlined"
                            disabled={form5.values.passKeyToThirdParty === false}
                            {...bind('passKeyToPerson')}
                          >
                            <MenuItem value='both'>{langForm5.bothEstateAgentOrPerson}</MenuItem>
                            <MenuItem value='estateAgent'>{langForm5.estateAgent}</MenuItem>
                            <MenuItem value='otherPerson'>{langForm5.otherPerson}</MenuItem>

                          </TextField>
                          <div style={{ display: 'inline-block', paddingTop: '20px', paddingLeft: '6px' }}>
                            {langForm5.passKeyToThirdPartySentence2}
                          </div>
                        </div>
                        {/* approach 1: radio button */}
                        {/* <div>
                          <FormControl component="fieldset">
                            <FormGroup aria-label="position" row>
                              <FormControlLabel
                                value="end"
                                control={<Checkbox color="primary" />}
                                label={langForm3.passKeyToThirdParty}
                                labelPlacement="end"
                              />
                            </FormGroup>
                          </FormControl>
                          
                        </div>
                        <div style={{paddingLeft: '30px'}}>
                          <FormControl component="fieldset" style={{}}>
                           
                            <RadioGroup defaultValue="both" aria-label="Property Categories" name="customized-radios" row>
                              <FormControlLabel value="estateAgentOnly" control={<Radio size="small"/>} label={langForm3.estateAgent} />
                              <FormControlLabel value="personOnly" control={<Radio size="small"/>} label={langForm3.otherPerson} />
                              <FormControlLabel value="both" control={<Radio size="small"/>} label={langForm3.bothEstateAgentOrPerson} />
                              
                            </RadioGroup>
                          </FormControl>
                        </div> */}
                        {/* end approach 1: radio button */}
                      </TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/*------------- 7. Sub-listing ------------ */}
            <Grid
              item
              md={12}
              xs={12}
            >
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="part 7 table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.column0}>7.</TableCell>
                      <TableCell align="left" colSpan={2} >{langForm5.titlePart7}</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>

                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell colSpan={2}>
                        <FormControl component="fieldset">
                          <FormGroup aria-label="position" row>
                            <FormControlLabel
                              classes={{ disabled: classes.part6DisabledText }}
                              control={<Checkbox color="primary" />}
                              label={langForm5.allowSubListing}
                              labelPlacement="end"
                              checked={form5.values.allowSubListing}
                              // disabled={true} 
                              onChange={() => {
                                form5.updateValues('allowSubListing', !form5.values.allowSubListing);
                              }}
                            />
                          </FormGroup>
                        </FormControl>
                      </TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/*------------- 8. Advertising------------ */}
            <Grid
              item
              md={12}
              xs={12}
            >
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="part 8 table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.column0}>8.</TableCell>
                      <TableCell align="left" colSpan={2} >{langForm5.titlePart8}</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>

                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell colSpan={2}>
                        <FormControl component="fieldset">
                          <FormGroup aria-label="position" row>
                            <FormControlLabel
                              classes={{ disabled: classes.part6DisabledText }}
                              control={<Checkbox color="primary" />}
                              label={langForm5.allowAdvertisement}
                              labelPlacement="end"
                              // disabled={true} 
                              checked={form5.values.allowAdvertisement}
                              onChange={() => {
                                form5.updateValues('allowAdvertisement', !form5.values.allowAdvertisement);
                              }}
                            />
                          </FormGroup>
                        </FormControl>

                      </TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/*------------- 9. Disclosure of Interest by Agent------------ */}
            <Grid
              item
              md={12}
              xs={12}
            >
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="part 8 table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.column0}>9.</TableCell>
                      <TableCell align="left" colSpan={2} >{langForm5.titlePart9}</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell colSpan={2}>
                        <FormControl component="fieldset" style={{ paddingBottom: '4px' }}>
                          <FormGroup aria-label="position" row>
                            <FormControlLabel
                              control={<Checkbox color="primary" />}
                              label={langForm5.disclosureOfInterestByAgent}
                              labelPlacement="end"
                              checked={form5.values.needDisclosure}
                              onChange={() => {
                                if (form5.values.needDisclosure) {
                                  form5.updateValues('interestsInProperty', undefined);
                                }
                                form5.updateValues('needDisclosure', !form5.values.needDisclosure);
                              }}
                            />
                          </FormGroup>
                        </FormControl>

                        <TextareaAutosize
                          className={classes.textArea}
                          rowsMin={2}
                          rowsMax={2}
                          placeholder={langForm5.helperTextParticulars}
                          {...bind('interestsInProperty')}
                          disabled={form5.values.needDisclosure == false}
                          onChange={(e) => {
                            limitTextFieldLength(e, 255, 'interestsInProperty', form5, keyBooleanMap, setKeyBooleanMap);
                          }}
                        />
                        {form5.hasError('interestsInProperty') ? <FormHelperText style={{ color: form5.hasError('interestsInProperty') ? errorColor : 'inherit' }}>{langForm5.msgInputInterestsInProperty}</FormHelperText> : null}
                        <HandleFormHelperText
                          isError={keyBooleanMap.get('interestsInProperty')}
                          errorMessage={lang.textOverLimit}
                        />
                      </TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/*------------- 10. Acknowledgement by landlord ------------ */}
            <Grid
              item
              md={12}
              xs={12}
            >

              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="part 10 table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.column0}>10.</TableCell>
                      <TableCell align="left" colSpan={2} >{langForm5.titlePart10}</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell align="left" colSpan={2} data-key={'field-form5-landlordAcknowledgementOption'}>
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="landlord acknowledgement"
                            name="customized-radios"
                            value={form5.values.landlordAcknowledgementOption ?? ''}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              const val = (event.target as HTMLInputElement).value;
                              form5.updateValues('landlordAcknowledgementOption', val);
                            }}
                          >
                            <FormControlLabel
                              value="option1"
                              control={<Radio size="small" />}
                              label={langForm5.landlordAcknowledgement1}
                            />
                            <FormControlLabel
                              value="option2"
                              control={<Radio size="small" />}
                              label={langForm5.landlordAcknowledgement2}
                            />
                            <FormControlLabel value="" control={<Radio size="small" />} label='' style={{ display: 'none' }} />
                          </RadioGroup>
                        </FormControl>

                        {form5.hasError('landlordAcknowledgementOption') ? <FormHelperText style={{ color: form5.hasError('landlordAcknowledgementOption') ? errorColor : 'inherit' }}>{langForm5.msgSelectLandlordAcknowledgementOption}</FormHelperText> : null}

                      </TableCell>
                    </TableRow>

                    {/*------------- (a) Has received Property Information Form ------------ */}
                    {/* <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell colSpan={2}>
                        <FormControl component="fieldset">
                          <FormGroup aria-label="position" row>
                            <FormControlLabel
                              value="end"
                              control={<Checkbox color="primary" />}
                              label={langForm5.landlordAcknowledgement1}
                              labelPlacement="end"
                            />
                          </FormGroup>
                        </FormControl>

                      </TableCell>
                    </TableRow> */}

                    {/*------------- (b) Has agreed to receive Property Information Form before entering into a binding agreement ------------ */}
                    {/* <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell colSpan={2}>
                        <FormControl component="fieldset">
                          <FormGroup aria-label="position" row>
                            <FormControlLabel
                              value="end"
                              control={<Checkbox color="primary" />}
                              label={langForm5.landlordAcknowledgement2}
                              labelPlacement="end"
                            />
                          </FormGroup>
                        </FormControl>

                      </TableCell>
                    </TableRow> */}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>


            {/*----------------------- 13. Extra Terms ------------------------ */}
            <Grid
              item
              md={12}
              xs={12}
            >
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="part 13 table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.column0}>12.</TableCell>
                      <TableCell align="left" colSpan={2} >{langForm5.titlePart12}</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>

                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell colSpan={2}>
                        <Typography style={{ paddingBottom: '4px' }}> {langForm5.extraTermsCautions}</Typography>
                        <TextareaAutosize
                          className={classes.textArea}
                          rowsMin={2}
                          rowsMax={2}
                          placeholder={langForm5.helperTextParticulars}
                          {...bind('extraTerms')}
                          onChange={(e) => {
                            limitTextFieldLength(e, 255, 'extraTerms', form5, keyBooleanMap, setKeyBooleanMap);
                          }}
                        />
                        {form5.hasError('extraTerms') ? <FormHelperText style={{ color: form5.hasError('extraTerms') ? errorColor : 'inherit' }}>{langForm5.pleaseInput + langForm5.extraTerms}</FormHelperText> : null}
                        <HandleFormHelperText
                          isError={keyBooleanMap.get('extraTerms')}
                          errorMessage={lang.textOverLimit}
                        />
                      </TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
              </TableContainer>

            </Grid>

          </Grid>

          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>

        </Grid>

        {/* ---------------- Schedule 2 ------------------ */}
        <Grid item container xs={12} spacing={2}>

          <Grid
            item
            md={12}
            xs={12}
          >
            <Typography style={{ fontWeight: 'bold' }} >{langForm5.titleSchedule2}</Typography>
          </Grid>

          {/*------------- Commission ------------ */}
          <Grid
            item
            md={12}
            xs={12}
          >
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="schedule 2 table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.column0}>1.</TableCell>
                    <TableCell align="left" colSpan={2} >{langForm5.titleCommission}</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {/*----------- commission in ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell className={classes.column1} >{langForm5.commission}</TableCell>
                    <TableCell>
                      <div style={{ width: '20%', display: 'inline-block' }}>
                        <TextField
                          style={{ width: '100%' }}
                          margin="dense"
                          // required
                          label={langForm5.commissionIn}
                          select
                          // value={form5.values.commissionIn ?? ''}
                          {...bind('commissionIn')}
                          variant="outlined"
                        // onChange={(e)=> {
                        //   var commissionAmountInput = document.getElementById("commissionAmountInput");
                        //   var commissionPercentageInput = document.getElementById("commissionPercentageInput");
                        //   var fakeInputBox = document.getElementById("fakeInputBox");
                        //   if (commissionAmountInput ==  null || commissionPercentageInput == null || fakeInputBox == null){
                        //     return;
                        //   }
                        //   form5.updateValues('commissionIn', e.target.value);
                        //   if (e.target.value == 'amount') {
                        //     commissionAmountInput.style.display = "inline-block";
                        //     commissionPercentageInput.style.display = "none";
                        //     fakeInputBox.style.display = "none";
                        //    } else if (e.target.value == 'percentage') {
                        //     commissionPercentageInput.style.display = "inline-block";
                        //     commissionAmountInput.style.display = "none";
                        //     fakeInputBox.style.display = "none";
                        //   } else{
                        //     return;
                        //   }
                        // }}
                        >
                          <MenuItem key='amount' value='amount'>{langForm5.amount}</MenuItem>
                          <MenuItem key='percentage' value='percentage'>{langForm5.percentage}</MenuItem>
                          <MenuItem style={{ display: 'none' }} key='' value=''></MenuItem>
                        </TextField>
                      </div>
                      {' '}
                      {/* ------- fake text field -------- */}
                      <div id='fakeInputBox' style={{ display: form5.values.commissionIn == 'amount' || form5.values.commissionIn == 'percentage' ? 'none' : 'inline-block', width: '20%' }}>
                        <TextField
                          margin="dense"
                          variant="outlined" />
                      </div>
                      {/* ------- amount --------- */}
                      <div id='commissionAmountInput' style={{ display: form5.values.commissionIn == 'amount' ? 'inline-block' : 'none', width: '35%' }}>
                        <NumberField
                          margin="dense"
                          variant="outlined"
                          InputProps={{
                            startAdornment: <InputAdornment position="start">{langForm5.HKDollar}</InputAdornment>
                          }}
                          {...bind('commissionAmount')}
                          onChange={(e) => {
                            limitNumberFieldLength(e, 'commissionAmount', form5);
                          }}

                        />
                      </div>
                      {/* ------- percentage --------- */}
                      <div id='commissionPercentageInput' style={{ display: form5.values.commissionIn == 'percentage' ? 'inline-block' : 'none', width: '35%' }}>
                        <NumberField
                          margin="dense"
                          variant="outlined"
                          InputProps={{
                            endAdornment: <InputAdornment position="start">{'%'}</InputAdornment>,
                          }}
                          {...bind('commissionRate')}
                          onChange={(e) => {
                            limitNumberFieldLength(e, 'commissionRate', form5);
                          }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>

                  {/*----------- payment time ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell className={classes.column1} >{langForm5.paymentTime}</TableCell>
                    <TableCell>
                      <RadioGroup defaultValue="" aria-label="Property Categories" name="customized-radios" row
                        value={form5.values.paymentTimeOption ?? ''}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          const val = (event.target as HTMLInputElement).value;
                          form5.updateValues('paymentTimeOption', val);
                        }}>
                        <FormControlLabel
                          value="option1"
                          control={<Radio />}
                          label={langForm5.paymentTimeOption1}
                        />
                        <FormControlLabel
                          value="option2"
                          control={<Radio />}
                          label={langForm5.paymentTimeOption2}
                        />
                        <FormControlLabel value="" control={<Radio size="small" />} label='' style={{ display: 'none' }} />
                      </RadioGroup>
                      {form5.hasError('paymentTimeOption') ? <FormHelperText style={{ color: form5.hasError('paymentTimeOption') ? errorColor : 'inherit' }}>{langForm5.msgSelectPaymentTime}</FormHelperText> : null}
                    </TableCell>
                  </TableRow>


                </TableBody>
              </Table>
            </TableContainer>


            {/* ------------------------ Schedule 2 point 2 ---------------- */}
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="schedule 2 point 2 table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.column0}>2.</TableCell>
                    <TableCell align="left" >{langForm5.titleSchedulePoint2}</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell>
                      <FormControl component="fieldset" style={{}}>
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            control={<Checkbox color="primary" />}
                            label={langForm5.withInterest}
                            labelPlacement="end"
                            checked={form5.values.withInterest}
                            onChange={() => {
                              form5.updateValues('withInterest', !form5.values.withInterest);
                            }}
                          />
                        </FormGroup>
                      </FormControl>
                      {form5.hasError('withInterest') ? <FormHelperText style={{ color: form5.hasError('withInterest') ? errorColor : 'inherit' }}>{langForm5.msgSelectWithInterest}</FormHelperText> : null}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

          </Grid>

          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>

        </Grid>

        {/* ---------------- info of signatory ------------------ */}
        <Grid item container xs={12} spacing={2}>

          {/* ---------landlord's info ----------- */}
          <Grid
            item
            md={6}
            xs={12}
          >
            <TableContainer component={Paper}>
              <Table size="small" aria-label="vendor info table">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2} align="left" >{langForm5.landlordInfo}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* ------------------------ ID no------------------------- */}
                  <TableRow style={{ display: 'none' }}>
                    <TableCell component="th" style={{ width: '35%' }} scope="row">
                      <Typography>{langForm5.HKID}</Typography>
                      <Typography>{langForm5.ifApplicable}</Typography>
                      {form5.hasError('eitherSignatoryInfoOrHKID') ? <FormHelperText style={{ color: form5.hasError('eitherSignatoryInfoOrHKID') ? errorColor : 'inherit' }}>{langForm5.msgInputSignatoryInfoOrHKID}</FormHelperText> : null}
                    </TableCell>

                    <TableCell align="left">
                      <TextField
                        className={classes.infoPartTextField}
                        margin="dense"
                        variant="outlined"
                        // {...bind('landlordHKID')}
                        value={form5.values.landlordHKID ?? ''}
                        onChange={(e) => {
                          if (e.target.value.length > 255) {
                            e.preventDefault();
                          } else {
                            form5.updateValues('landlordHKID', e.target.value);
                            form5.updateValues('nameOfSignatoryOfLandlord', undefined);
                            form5.updateValues('positionOfSignatoryOfLandlord', undefined);
                            form5.updateValues('businessRegistrationCertificateNumberOfLandlord', undefined);
                          }
                        }}
                      />
                      <FormHelperText>{keyBooleanMap.get('landlordHKID') ? lang.textOverLimit : ''}</FormHelperText>
                    </TableCell>
                  </TableRow>

                  {/* -------------- if landlord is a company------------------------- */}
                  <TableRow>
                    <TableCell colSpan={2} className={classes.subTableCell}>
                      <Typography>{langForm5.whereLandlordIsCompany}</Typography>
                    </TableCell>

                  </TableRow>
                  {/* ----------- name ---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row" className={classes.subTableCell}>
                      <Typography className={classes.companyInfo}>{langForm5.nameOfSignatoryOfLandlord}</Typography>
                    </TableCell>

                    <TableCell align="left" className={classes.subTableCell}>
                      <TextField
                        className={classes.infoPartTextField}
                        margin="dense"
                        variant="outlined"
                        // {...bind('nameOfSignatoryOfLandlord')}
                        error={!!form5.hasError('nameOfSignatoryOfLandlord')}
                        helperText={form5.hasError('nameOfSignatoryOfLandlord')}
                        value={form5.values.nameOfSignatoryOfLandlord ?? ''}
                        onChange={(e) => {
                          if (keyBooleanMap && setKeyBooleanMap) {
                            setKeyBooleanMap(keyBooleanMap.set('nameOfSignatoryOfLandlord', e.target.value.length > 255))
                          }
                          if (e.target.value.length > 255) {
                            e.preventDefault();
                            form5.bind('nameOfSignatoryOfLandlord').onChange({ target: { value: e.target.value.substring(0, 255) } });
                          } else {
                            form5.bind('nameOfSignatoryOfLandlord').onChange(e);
                            form5.updateValues('landlordHKID', undefined);
                          }
                        }}
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('nameOfSignatoryOfLandlord')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>
                  {/* ----------- position ---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row" className={classes.subTableCell}>
                      <Typography className={classes.companyInfo}>{langForm5.positionOfSignatoryOfLandlord}</Typography>
                    </TableCell>

                    <TableCell align="left" className={classes.subTableCell}>
                      <TextField
                        className={classes.infoPartTextField}
                        margin="dense"
                        variant="outlined"
                        // {...bind('positionOfSignatoryOfLandlord')}
                        error={!!form5.hasError('positionOfSignatoryOfLandlord')}
                        helperText={form5.hasError('positionOfSignatoryOfLandlord')}
                        value={form5.values.positionOfSignatoryOfLandlord ?? ''}
                        onChange={(e) => {
                          if (keyBooleanMap && setKeyBooleanMap) {
                            setKeyBooleanMap(keyBooleanMap.set('positionOfSignatoryOfLandlord', e.target.value.length > 255))
                          }
                          if (e.target.value.length > 255) {
                            e.preventDefault();
                            form5.bind('positionOfSignatoryOfLandlord').onChange({ target: { value: e.target.value.substring(0, 255) } });
                          } else {
                            form5.bind('positionOfSignatoryOfLandlord').onChange(e);
                            form5.updateValues('landlordHKID', undefined);
                          }
                        }}
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('positionOfSignatoryOfLandlord')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>
                  {/* ----------- business registration certificate number ---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography className={classes.companyInfo}>{langForm5.businessRegistrationCertificateNumberOfLandlord}</Typography>
                    </TableCell>

                    <TableCell align="left">
                      <TextField
                        className={classes.infoPartTextField}
                        margin="dense"
                        variant="outlined"
                        // {...bind('businessRegistrationCertificateNumberOfLandlord')}
                        error={!!form5.hasError('businessRegistrationCertificateNumberOfLandlord')}
                        helperText={form5.hasError('businessRegistrationCertificateNumberOfLandlord')}
                        value={form5.values.businessRegistrationCertificateNumberOfLandlord ?? ''}
                        onChange={(e) => {
                          if (keyBooleanMap && setKeyBooleanMap) {
                            setKeyBooleanMap(keyBooleanMap.set('businessRegistrationCertificateNumberOfLandlord', e.target.value.length > 255))
                          }
                          if (e.target.value.length > 255) {
                            e.preventDefault();
                            form5.bind('businessRegistrationCertificateNumberOfLandlord').onChange({ target: { value: e.target.value.substring(0, 255) } });
                          } else {
                            form5.bind('businessRegistrationCertificateNumberOfLandlord').onChange(e);
                            form5.updateValues('landlordHKID', undefined);
                          }
                        }}
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('businessRegistrationCertificateNumberOfLandlord')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>

                  {/* ----------- address ---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography>{langForm5.address}</Typography>
                    </TableCell>

                    <TableCell align="left">
                      <TextareaAutosize
                        className={classes.textArea}
                        style={{ width: '100%', }}
                        rowsMin={4}
                        rowsMax={4}
                        placeholder=''
                        {...bind('landlordAddress')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'landlordAddress', form5, keyBooleanMap, setKeyBooleanMap);
                        }}
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('landlordAddress')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>

                  {/* ----------- phone number---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography >{langForm5.phoneNumber}</Typography>
                    </TableCell>

                    <TableCell align="left" data-key={'field-form5-landlordPhoneNumber'} >
                      <TextField
                        className={classes.infoPartTextField}
                        margin="dense"
                        variant="outlined"
                        {...bind('landlordPhoneNumber')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'landlordPhoneNumber', form5, keyBooleanMap, setKeyBooleanMap);
                        }}
                        helperText={form5.hasError('landlordPhoneNumber')}
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('landlordPhoneNumber')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>

                  {/* ----------- fax number---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography>{langForm5.faxNumber}</Typography>
                    </TableCell>

                    <TableCell align="left">
                      <TextField
                        className={classes.infoPartTextField}
                        margin="dense"
                        variant="outlined"
                        {...bind('landlordFaxNumber')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'landlordFaxNumber', form5, keyBooleanMap, setKeyBooleanMap);
                        }}
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('landlordFaxNumber')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>

                  {/* ----------- date --------- */}
                  {/* <TableRow>
                      <TableCell component="th"  scope="row">
                          <Typography>{langForm5.date}</Typography>
                      </TableCell>

                      <TableCell  align="left">
                        <KeyboardDatePicker autoOk={true}
                           className={classes.infoPartTextField}
                          // InputProps={InputGroup.leftItem()}
                          //disableToolbar
                          variant="inline"
                          inputVariant="outlined"
                          format={DISPLAY_DATE_FORMAT} 
                          margin="dense"
                          value={form5.values.landlordDate ?  moment(form5.values.landlordDate) : null}
                          onChange={(value) => {
                            form5.setValues({
                              ...form5.values,
                              landlordDate: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
                            });
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          /> 
                          {form5.hasError('landlordDate') ? <FormHelperText style={{color: form5.hasError('landlordDate') ? errorColor : 'inherit'}}>{langForm5.msgInputDate}</FormHelperText> : null}
                      </TableCell>
                    </TableRow> */}

                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          {/* ---------agents's info ----------- */}
          <Grid
            item
            md={6}
            xs={12}
          >

            <TableContainer component={Paper}>
              <Table size="small" aria-label="agent info table">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2} align="left" >{langForm5.agentInfo}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  {/* ----------- Name and licence number of the Agent---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography >{langForm5.nameAndLicenceNumberOfAgent}</Typography>
                    </TableCell>

                    <TableCell align="left">
                      <TextField
                        className={classes.infoPartTextField}
                        margin="dense"
                        variant="outlined"
                        {...bind('nameAndLicenceNumberOfAgent')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'nameAndLicenceNumberOfAgent', form5, keyBooleanMap, setKeyBooleanMap);
                        }}
                        helperText={form5.hasError('nameAndLicenceNumberOfAgent')}
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('nameAndLicenceNumberOfAgent')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>

                  {/* ----------- Number of Agent’s statement of particulars of business---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography >{langForm5.numberOfStatementOfParticularsOfBusiness}</Typography>
                    </TableCell>

                    <TableCell align="left">
                      <TextField
                        className={classes.infoPartTextField}
                        disabled={true}
                        margin="dense"
                        variant="outlined"
                        {...bind('numberOfStatementOfParticularsOfBusiness')}
                      />
                    </TableCell>
                  </TableRow>

                  {/* ----------- address ---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography>{langForm5.address}</Typography>
                    </TableCell>

                    <TableCell align="left">
                      <TextareaAutosize
                        className={classes.textArea}
                        disabled={true}
                        style={{ width: '100%', }}
                        rowsMin={4}
                        rowsMax={4}
                        placeholder=''
                        {...bind('agentAddress')}
                      />
                      {form5.hasError('agentAddress') ? <FormHelperText style={{ color: form5.hasError('agentAddress') ? errorColor : 'inherit' }}>{langForm5.msgInputAddress}</FormHelperText> : null}
                    </TableCell>
                  </TableRow>

                  {/* ----------- phone number---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography >{langForm5.phoneNumber}</Typography>
                    </TableCell>

                    <TableCell align="left">
                      <TextField
                        className={classes.infoPartTextField}
                        disabled={true}
                        margin="dense"
                        variant="outlined"
                        {...bind('agentPhoneNumber')}
                      />
                    </TableCell>
                  </TableRow>

                  {/* ----------- fax number---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography>{langForm5.faxNumber}</Typography>
                    </TableCell>

                    <TableCell align="left">
                      <TextField
                        className={classes.infoPartTextField}
                        disabled={true}
                        margin="dense"
                        variant="outlined"
                        {...bind('agentFaxNumber')}
                      />
                    </TableCell>
                  </TableRow>

                  {/* ----------- date --------- */}
                  {/* <TableRow>
                      <TableCell component="th"  scope="row">
                          <Typography>{langForm5.date}</Typography>
                      </TableCell>

                      <TableCell  align="left">
                        <KeyboardDatePicker autoOk={true}
                           className={classes.infoPartTextField}
                          // InputProps={InputGroup.leftItem()}
                          //disableToolbar
                          variant="inline"
                          inputVariant="outlined"
                          format={DISPLAY_DATE_FORMAT} 
                          margin="dense"
                          value={form5.values.agentDate ?  moment(form5.values.agentDate) : null}
                          onChange={(value) => {
                            form5.setValues({
                              ...form5.values,
                              agentDate: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
                            });
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          /> 
                          {form5.hasError('agentDate') ? <FormHelperText style={{color: form5.hasError('agentDate') ? errorColor : 'inherit'}}>{langForm5.msgInputDate}</FormHelperText> : null}

                      </TableCell>
                    </TableRow> */}




                </TableBody>
              </Table>
            </TableContainer>

          </Grid>
        </Grid>

      </CardContent>

      <CardActions style={{ justifyContent: 'flex-end', display: 'none' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (form5.values.id != undefined && form5.values.id != null) {
              dispatch({
                type: 'Form5.UpdateRequested', payload: {
                  ...form5.values,
                }
              });
            } else {

              dispatch({
                type: 'Form5.CreationRequested', payload: {
                  ...form5.values,
                  propertyStockId: propertyStockId,
                }
              });
            }
          }}

        >{lang.actionSaveForm}</Button>
        <Button variant="contained" color="primary">生成PDF檔案</Button>
      </CardActions>
    </Card>
  )
};

export default Form5Dialog;
