import { Grid, makeStyles, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useConfirmDialog } from 'common/ConfirmDialog';
import { CheckDuplicatedPropertyStockResultDTO, PropertyStockDTO } from 'common/dto';
import { FormPresenter } from 'common/hooks';
import { isNonEmpty, multiLang } from 'common/utils';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IRootState, PASDispatch } from 'reducers';
import { format } from 'util';
import AddLeadAgentFromApprovalDialog from './AddLeadAgentFromApprovalDialog';

const useStyles = makeStyles(() => ({
  root: {

  },
  patchOutlineAutocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
      paddingLeft: 8,
    }
  },
  dialogContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 350,
  },
  textArea: {
    width: '90%'
  }
}));

interface WarningDialogProps {
  form: FormPresenter<PropertyStockDTO>;
  openDialog: boolean;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
  handleConfirm: () => void;
  checkResult: Partial<CheckDuplicatedPropertyStockResultDTO>;
  forEditing?: boolean;
  fromWarningDialog?: boolean
}


const WarningDialogForCreatingExisingPropertyStock = ({ form, openDialog, setOpenDialog, handleConfirm, checkResult, forEditing, fromWarningDialog }: WarningDialogProps) => {
  const dispatch = useDispatch() as PASDispatch;
  const classes = useStyles();
  const { langPropertyStock, lang, locale } = useSelector((state: IRootState) => state.locale);
  const history = useHistory();

  const handleClose = () => {
    setOpenDialog(false);
  };
  const role = useSelector((state: IRootState) => state.user.fetchedDetails);
  //const isAgent = Object.values(role).filter(r => r.roles.filter(r => r === "AGENT").length > 0).length > 0;
  const isAdminOrOfficeAdmin = Object.values(role).filter(r => r.roles.filter(r => r === "ADMIN" || r === "OFFICE_ADMIN").length > 0).length > 0;
  // const getCheckDuplicateResult =() =>{
  //   if (isNonEmpty(form.values.buildingId) && isNonEmpty(form.values.floor) && isNonEmpty(form.values.unit)){
  //     form.updateValues('isCreatingDuplicatedPropertyStock', undefined);
  //     form.updateValues('isCreatingDuplicatedCompanyStock', undefined);
  //     //call api
  //     propertyApi.checkDuplicatedPropertyStock(form.values.buildingId, form.values.floor ?? [], form.values.unit ?? [], token?? '').then(result => {
  //       if (result.data) { 
  //         setOpenDialog(result.data.duplicated ?? false);
  //         setCheckResult(result.data);
  //         form.updateValues('isCreatingDuplicatedPropertyStock', result.data.duplicated);
  //         form.updateValues('isCreatingDuplicatedCompanyStock', result.data.duplicated && result.data.companyStock);
  //       }else{
  //         setCheckResult(EMPTY);
  //       }
  //     });
  //   }
  // }
  // //check if there is existing property stock after full property address (building + floor + unit) is inputted
  // useEffect(()=>{
  //   if (isNonEmpty(form.values.buildingId) && isNonEmpty(form.values.floor) && isNonEmpty(form.values.unit)){
  //     form.updateValues('isCreatingDuplicatedPropertyStock', undefined);
  //     form.updateValues('isCreatingDuplicatedCompanyStock', undefined);
  //     getCheckDuplicateResult();
  //   }
  // },[form.values.buildingId, form.values.floor, form.values.unit])

  const confirmDialog = useConfirmDialog();
  const alreadyHaveSameAddress = isNonEmpty(checkResult.duplicatedActiveStock) && Object.values(checkResult.duplicatedActiveStock!).filter(p => p.id?.toString() !== form.values.id?.toString()).length > 0;
  const sameAdddressPropertyNo = isNonEmpty(checkResult.duplicatedActiveStock) ? Object.values(checkResult.duplicatedActiveStock!).filter(p => p.id?.toString() !== form.values.id?.toString()).map(p => p.propertyNo).join(",") : "";
  const messageTemplate = forEditing && isAdminOrOfficeAdmin ? langPropertyStock.msgCannotEditToExistingAddress : langPropertyStock.msgDuplicatedPropertyStockAddress;

  const msgExistingPropertyStockAddress = format(messageTemplate,
    checkResult.companyStock ? langPropertyStock.companyStock : langPropertyStock.propertyStock, (checkResult.propertyNo) ? checkResult.propertyNo : sameAdddressPropertyNo,
    (checkResult.propertyNo) ? multiLang(locale, checkResult.propertyAddressZh, checkResult.propertyAddressEn) : "");

  const cannotAddCompanyClient = checkResult.companyStock ? false : (checkResult.hasAssociatedClients ? form.values.companyStock : false);

  const showUpdateAsCompanyStockMsg = checkResult.companyStock ? false : (checkResult.hasAssociatedClients ? false : form.values.companyStock);


  const confirmMsg = checkResult.companyStock ? '' :
    ((form.values.sellerClientId === null || form.values.sellerClientId?.toString() === "") ?
      (cannotAddCompanyClient ? langPropertyStock.msgCannotAddCompanyClient
        : (showUpdateAsCompanyStockMsg ? format(langPropertyStock.msgWarningUpdateAsCompanyStock, lang.actionContinue) : langPropertyStock.msgAddClientByAdmin))
      : (checkResult.isAlreadyLeadAgent ? langPropertyStock.msgWarningCurrentUserIsAlreadyLeadAgent : langPropertyStock.msgPleaseAddClient));

  const confirmButtonName = checkResult.companyStock ? langPropertyStock.actionRedirectToExistingProperty : (!isNonEmpty(checkResult.id) && alreadyHaveSameAddress || !fromWarningDialog) ? lang.actionConfirm : lang.actionContinue;

  const missingClientDetail = checkResult.duplicated && !checkResult.companyStock && !isNonEmpty(form.values.sellerClientId);

  //for lead agent dialog
  const [openAddLeadAgentDialog, setOpenAddLeadAgentDialog] = React.useState(false);

  const handleClickOpenAddLeadAgentDialog = () => {
    setOpenAddLeadAgentDialog(true);
  };

  const handleCloseAddLeadAgentDialog = () => {
    setOpenAddLeadAgentDialog(false);
  };

  useEffect(() => {
    if (openDialog) {
      dispatch({ type: 'Layout.MaskDismissRequested' });
    }
  }, [openDialog])

  return (
    <div>
      {confirmDialog.render()}
      <AddLeadAgentFromApprovalDialog
        isCreatePage={false}
        createPropertyStock={handleConfirm}
        hasAssociatedClients={checkResult.hasAssociatedClients}
        hideOpenButton
        propertyStockId={checkResult.id}
        open={openAddLeadAgentDialog} handleClickOpen={handleClickOpenAddLeadAgentDialog} handleClose={handleCloseAddLeadAgentDialog} stockForm={form} propertyStockNum={checkResult.propertyNo} />

      <Dialog open={openDialog} onClose={handleClose} aria-labelledby="warning-dialog">
        <DialogTitle id="warning-dialog-title">
          <Typography style={{ fontWeight: 'bold' }}>{langPropertyStock.waringDialogTitle}</Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid item container md={12} xs={12} spacing={1} >
            {cannotAddCompanyClient ? null :
              <Grid
                item
                md={12}
                xs={12}
              >
                <Typography>{msgExistingPropertyStockAddress}</Typography>
              </Grid>
            }
            {isNonEmpty(confirmMsg) ?
              <Grid
                item
                md={12}
                xs={12}
              >
                <Typography>{(alreadyHaveSameAddress && !isNonEmpty(checkResult.id)) || !fromWarningDialog ? "" : confirmMsg}</Typography>
              </Grid>
              : null}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button disabled={(!isNonEmpty(checkResult.id) && alreadyHaveSameAddress) || !fromWarningDialog} onClick={() => {
            // if (missingClientDetail){
            //   form.validate();
            // }
            handleClose();
          }} color="primary">
            {(!isNonEmpty(checkResult.id) && alreadyHaveSameAddress) || !fromWarningDialog ? "" : checkResult.companyStock ? lang.actionCancel : (missingClientDetail || cannotAddCompanyClient ? lang.actionGoBack : lang.actionCancel)}
          </Button>
          {checkResult.companyStock || (!cannotAddCompanyClient) ?
            <Button onClick={() => {
              if (!isNonEmpty(checkResult.id) && alreadyHaveSameAddress || !fromWarningDialog) {
                handleClose()
              } else if (!isNonEmpty(form.values.leadAgents)) {
                confirmDialog.confirm(langPropertyStock.msgNoLeadAgent, lang.actionConfirm, lang.actionNo)
                handleClose();
              }
              // else if (checkResult.isAlreadyLeadAgent) {
              //   history.replace(`/properties/${checkResult.propertyNo}`, {
              //     bypassUnsavedCheck: true,
              //   });
              // }
              else if (checkResult.duplicated && !checkResult.companyStock) {
                setOpenAddLeadAgentDialog(true);
                handleClose();
              } else {
                handleConfirm();
              }
            }} color="primary" variant="contained" autoFocus>
              {confirmButtonName}
            </Button>
            : null}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default WarningDialogForCreatingExisingPropertyStock;
