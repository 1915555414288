import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { isNonEmpty } from 'common/utils';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Route, useHistory } from "react-router-dom";
import { renewAndReloadToken } from "sagas/loginSaga";
import { Device } from "@capacitor/device";
import { App } from "@capacitor/app";

const RouteWithLayout = (props) => {
  const {
    layout: Layout,
    component: Component,
    disableDefaultTopBar,
    ...rest
  } = props;

  const version =
    useSelector((state) => state.systemSettings?.System.FRONTEND.VERSION) ?? "";
  const apkLink = useSelector(
    (state) => state.systemSettings.System?.APK_LOCATION.AGENT
  );
  const appStoreLink = useSelector(
    (state) => state.systemSettings.System?.APP_STORE_LOCATION.AGENT
  );
  const token = useSelector((state) => state.login.token);
  const loggedIn = useSelector((state) => state.login.loggedIn);
  const [openRefreshPopUp, setOpenRefreshPopUp] = useState(false);
  const [openAppUpdatePopUp, setOpenAppUpdatePopUp] = useState(false);
  const { langSystemSetting, lang } = useSelector((state) => state.locale);
  const [linkToUpdate, setLinkToUpdate] = useState(null);

  const env = process.env["PUBLIC_URL"];

  const [deviceInfo, setDeviceInfo] = useState(null);
  const [buildVersion, setBuildVersion] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    //renew token if and only if loggedIn
    if (props.path !== "/sign-in" && loggedIn) {
      Device.getInfo().then((info) => setDeviceInfo(info.platform));
      App.getInfo().then((info) => setBuildVersion(info.version));
      if (!isNonEmpty(localStorage.getItem("impersonateUserToken"))) {
        renewAndReloadToken(token);
      }
      if(!isNonEmpty(localStorage.getItem(env + "/" + "frontendVersion"))){
        localStorage.setItem(env + "/" + "frontendVersion", version);
      } else if (version !== localStorage.getItem(env + "/" + "frontendVersion")) {
        setOpenRefreshPopUp(true);
      }
    }
  }, [props.location, version, deviceInfo, loggedIn]);

  useEffect(() => {
    if (deviceInfo === "android") setLinkToUpdate(apkLink);
    if (deviceInfo === "ios") setLinkToUpdate(appStoreLink);
    if (props.path === "/sign-in" || !loggedIn) return;
    if (deviceInfo === "android" || deviceInfo === "ios")
      if (
        buildVersion !== undefined &&
        buildVersion !== "" &&
        buildVersion !== version
      ) {
        setOpenAppUpdatePopUp(true);
        history.replace("/sign-in");
        dispatch({ type: "Login.LogoutRequested" });
        window.localStorage.setItem("logoutEnv", env);
        window.localStorage.setItem("timestamp", Date.now().toString());
      }
  }, [deviceInfo, buildVersion, version]);

  const versionUpdate = () => (
    <Dialog
      open={openRefreshPopUp}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle color="black" id="alert-dialog-title">
        {langSystemSetting.msgVersionUpdate}
      </DialogTitle>
      <DialogContent>
        <DialogContentText color="initial" id="alert-dialog-description">
          {langSystemSetting.msgUpdateVersion}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            setOpenRefreshPopUp(false);
            window.location.reload();
            localStorage.setItem(env + "/" + "frontendVersion", version);
          }}
          autoFocus
        >
          {"OK"}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const appVersionUpdate = () => (
    <Dialog
      open={openAppUpdatePopUp}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle color="black" id="alert-dialog-title">
        {langSystemSetting.msgVersionUpdate}
      </DialogTitle>
      <DialogContent>
        <DialogContentText color="initial" id="alert-dialog-description">
          {langSystemSetting.msgUpdateAppVersion}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            setOpenAppUpdatePopUp(false);
            window.open(linkToUpdate);
          }}
          autoFocus
        >
          {"OK"}
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <div id="myInvisibleDiv" style={{ display: "contents" }}>
      <Route
        {...rest}
        render={(matchProps) => (
          <Layout disableDefaultTopBar={disableDefaultTopBar}>
            <Component {...matchProps} />
          </Layout>
        )}
      />
      {deviceInfo === "web" ? versionUpdate() : appVersionUpdate()}
    </div>
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default RouteWithLayout;
