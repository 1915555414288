import { InputAdornment, TextField, TextFieldProps, withStyles } from '@material-ui/core';
import { isNonEmpty } from 'common/utils';
import React, { useEffect, useState } from 'react';

// Range Util
export const DEFAULT_FROM = -Number.MAX_VALUE, DEFAULT_TO = Number.MAX_VALUE;
export const displayFromVal = ([from, to]: [number, number]) => {
  return from == DEFAULT_FROM ? '' : String(from);
};

export const isEmptyRange = (o: any): o is [number, number] => Array.isArray(o) &&
  o.length === 2 && o[0] === DEFAULT_FROM && o[1] == DEFAULT_TO;

export const displayToVal = ([from, to]: [number, number]) => {
  return to == DEFAULT_TO ? '' : String(to);
};

export const updateFromVal = ([from, to]: [number, number], newFrom: string): [number, number] => {
  return [newFrom.trim() === '' ? DEFAULT_FROM : parseFloat(newFrom), to];
};

export const updateToVal = ([from, to]: [number, number], newTo: string): [number, number] => {
  return [from, newTo.trim() === '' ? DEFAULT_TO : parseFloat(newTo)];
};

export const BLANK_RANGE: [number, number] = [DEFAULT_FROM, DEFAULT_TO];

export interface MinMaxFieldProps {
  // label: string;
  range: [number, number];
  unit?: string;
  onChange: (newRange: [number, number]) => any;
  digitLimit?: number;
  isRent?: boolean;
}
const regex = new RegExp(/(^\d*\.?\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/);
const NumberField = withStyles({
  root: {
    '& input': {
      textAlign: 'right',
      '-moz-appearance': 'textfield',
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  },
},
)((props: TextFieldProps) => {
  return <TextField type={"text"}{...props} inputProps={{
    ...props.inputProps,
    min: 0,
    max: Math.max(),
    inputMode: 'decimal',
    pattern: "([^0-9]*)",
    step: 0.01
  }}
    onKeyDown={e => {
      // If the key pressed is not a number or a period, nothing is printed
      if (!e.key.match(regex)) {

        e.preventDefault();

      }
      // if (!e.key.match(regex) || (e.key === '.' && String(props.value).indexOf('.') > 0) || (e.key === '.' && String(props.value).length === 0) || e.keyCode === 229 || e.key === 'Unidentified') {

      //   e.preventDefault();

      // }

    }}

    onKeyPress={e => {
      // If the key pressed is not a number or a period, nothing is printed
      if (!e.key.match(regex)) {

        e.preventDefault();

      }
    }}


  />;
});

export default function MinMaxField({ range, onChange, unit, digitLimit, isRent }: MinMaxFieldProps) {
  const [fromLiteral, setFromLiteral] = useState(displayFromVal(range));
  const [toLiteral, setToLiteral] = useState(displayToVal(range));
  useEffect(() => {
    setFromLiteral(displayFromVal(range));
    setToLiteral(displayToVal(range));
  }, [range]);

  const fromField = <NumberField
    fullWidth
    margin="dense"
    variant="outlined"
    value={fromLiteral}
    onBlur={(e) => {
      onChange(updateFromVal(range, e.target.value));
    }}
    onChange={(e) => {
      if (!isNonEmpty(e.target.value)) {
        setFromLiteral(e.target.value);
      }
      else if (isNaN(parseFloat(e.target.value))) {
        e.preventDefault();
      } else if (e.target.value.match(/^\d*\.?\d*$/) == null) {
        // Restrict input special character after number
        e.preventDefault();
      } else if (e.target.value.indexOf('.') != -1 && e.target.value.match(/\./g)!.length >= 2) {
        e.preventDefault();
      }
      else if (isRent && e.target.value.indexOf('.') != -1) {
        e.preventDefault();
      }
      else {
        //if (e.target.value.length - 1 > (e.target.value.indexOf(".") == -1 ? 13 : 14)) {
        if ((e.target.value.indexOf(".") == -1 && e.target.value.length > (digitLimit ?? 12)) || (e.target.value.indexOf(".") != -1 && e.target.value.indexOf(".") > (digitLimit ?? 12))) {
          e.preventDefault();
        } else {
          setFromLiteral(e.target.value.indexOf(".") == -1 ? e.target.value : (e.target.value.length - (e.target.value.indexOf(".") + 1)) > 2 ? (Math.floor(parseFloat(e.target.value) * 100) / 100).toFixed(2) : e.target.value);
          //form.updateValues(value, e.target.value.indexOf(".") == -1 ? e.target.value : (e.target.value.length - (e.target.value.indexOf(".") + 1)) > 2 ? (Math.floor(parseFloat(e.target.value) * 100) / 100).toFixed(2) : e.target.value);
          //e.target.value.length - (e.target.value.indexOf(".") + 1) > 2 ? parseFloat(e.target.value).toFixed(2) : e.target.value);
        }
      }

    }}
    InputProps={{
      endAdornment: unit ? <InputAdornment position="end">{unit}</InputAdornment> : undefined,
    }}
    style={{ padding: 4 }}
  />;

  const toField = <NumberField
    fullWidth
    margin="dense"
    variant="outlined"
    value={toLiteral}
    onBlur={(e) => {
      onChange(updateToVal(range, e.target.value));
    }}
    onChange={(e) => {
      if (!isNonEmpty(e.target.value)) {
        setToLiteral(e.target.value);
      } else if (isNaN(parseFloat(e.target.value))) {
        e.preventDefault();
      } else if (e.target.value.match(/^\d*\.?\d*$/) == null) {
        // Restrict input special character after number
        e.preventDefault();
      } else if (e.target.value.indexOf('.') != -1 && e.target.value.match(/\./g)!.length >= 2) {
        e.preventDefault();
      } else if (isRent && e.target.value.indexOf('.') != -1) {
        e.preventDefault();
      } else {
        //if (e.target.value.length - 1 > (e.target.value.indexOf(".") == -1 ? 13 : 14)) {
        if ((e.target.value.indexOf(".") == -1 && e.target.value.length > (digitLimit ?? 12)) || (e.target.value.indexOf(".") != -1 && e.target.value.indexOf(".") > (digitLimit ?? 12))) {
          e.preventDefault();
        } else {
          setToLiteral(e.target.value.indexOf(".") == -1 ? e.target.value : (e.target.value.length - (e.target.value.indexOf(".") + 1)) > 2 ? (Math.floor(parseFloat(e.target.value) * 100) / 100).toFixed(2) : e.target.value);
          //form.updateValues(value, e.target.value.indexOf(".") == -1 ? e.target.value : (e.target.value.length - (e.target.value.indexOf(".") + 1)) > 2 ? (Math.floor(parseFloat(e.target.value) * 100) / 100).toFixed(2) : e.target.value);
          //e.target.value.length - (e.target.value.indexOf(".") + 1) > 2 ? parseFloat(e.target.value).toFixed(2) : e.target.value);
        }
      }
      //setToLiteral(e.target.value);
    }}
    InputProps={{
      endAdornment: unit ? <InputAdornment position="end">{unit}</InputAdornment> : undefined,
    }}
    style={{ padding: 4 }}
  />;


  // Grid Based
  // return <Grid item container md={12} xs={12}>
  //   <Grid item md={5} xs={5}>
  //     {fromField}
  //   </Grid>

  //   <Grid item md={2} xs={2}>
  //     <Typography style={{
  //       marginLeft: theme.spacing(3),
  //       marginTop: theme.spacing(2)
  //     }}>-</Typography>
  //   </Grid>

  //   <Grid item md={5} xs={5}>
  //     {toField}
  //   </Grid>
  // </Grid>

  // Flex Based
  return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', width: '100%', flex: 1 }}>
    <div style={{ flexGrow: 2 }}>
      {fromField}
    </div>
    <div style={{ flexShrink: 2, width: 15, textAlign: 'center', height: '1.1875em', background: '#fdfdfd' }}>
      -
    </div>
    <div style={{ flexGrow: 2 }}>
      {toField}
    </div>
  </div>
}
