import { ApiError, ApiPageResult, ApiResult } from 'api';
import systemSettingApi from 'api/systemSettingApi';
import { MasterDataDTO } from 'common/dto';
import { IRootState, LayoutActions, SystemSettingListActions } from 'reducers';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { PASSagaContext } from 'sagas';
import { apiTaskWrapper } from './saga-commons';


///////////////////////////////////////////////////////////////////
/////////////////////////// Fetch List ////////////////////////////
///////////////////////////////////////////////////////////////////

export function* watchSystemSettingListFetchRequested(context: PASSagaContext) {
  yield takeLatest(
    'SystemSettingList.FetchRequested',
    apiTaskWrapper(requestSystemSettingList),
    context,
  );
}

export function* requestSystemSettingList(context: PASSagaContext, action: Extract<SystemSettingListActions, { type: 'SystemSettingList.FetchRequested' }>) {
  if (action.type !== 'SystemSettingList.FetchRequested') {
    return;
  }

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiPageResult<MasterDataDTO> = yield call(systemSettingApi.getList, action.payload, token);
  

  if (!result.error) {
    yield put<SystemSettingListActions>({ type: 'SystemSettingList.Loaded', payload: result.data! });
  } else {
    throw ApiError.of(result.error);
  }
}

///////////////////////////////////////////////////////////////////
/////////////////////////// update ///////////////////////////////
///////////////////////////////////////////////////////////////////

export function* watchSystemSettingUpdateRequested(context: PASSagaContext) {
  yield takeLatest(
    'SystemSettingDetail.UpdateRequested',
    apiTaskWrapper(requestUpdateSystemSetting),
    context,
  );
}

export function* requestUpdateSystemSetting(context: PASSagaContext, action: Extract<SystemSettingListActions, { type: 'SystemSettingDetail.UpdateRequested' }>) {
  if (action.type !== 'SystemSettingDetail.UpdateRequested') {
    return;
  }
  const {langSystemSetting } = yield select((state: IRootState) => state.locale);
  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<MasterDataDTO> = yield call(systemSettingApi.update, action.payload, token);
  const lastSearchCriteria = yield select((state: IRootState) => state.systemSettingsList.lastSearchCriteria);
  
  if (!result.error) {
    // yield put<SystemSettingListActions>({ type: 'SystemSettingDetail.Loaded', payload: result.data! });
    yield put<SystemSettingListActions>({ type: 'SystemSettingList.FetchRequested', payload: lastSearchCriteria });
  } else {
    if (result.error === "ERR_DUPLICATED_SYSTEM_SETTING"){
      yield put<LayoutActions>({ type: 'Layout.AlertMessageAdded', payload: { message: langSystemSetting.msgDuplicatedSystemSetting , severity: 'error' } })
    }else throw ApiError.of(result.error);
  }
}


///////////////////////////////////////////////////////////////////
/////////////////////////// Add //////////////////////////////////
///////////////////////////////////////////////////////////////////

export function* watchSystemSettingCreationRequested(context: PASSagaContext) {
  yield takeLatest(
    'SystemSettingDetail.CreationRequested',
    apiTaskWrapper(requestCreateSystemSetting),
    context,
  );
}

export function* requestCreateSystemSetting(context: PASSagaContext, action: Extract<SystemSettingListActions, { type: 'SystemSettingDetail.CreationRequested' }>) {
  if (action.type !== 'SystemSettingDetail.CreationRequested') {
    return;
  }
  const {langSystemSetting } = yield select((state: IRootState) => state.locale);
  const token: string = yield select((state: IRootState) => state.login.token ?? '');
  const lastSearchCriteria = yield select((state: IRootState) => state.systemSettingsList.lastSearchCriteria);
  
  const result: ApiResult<string> = yield call(systemSettingApi.add, action.payload, token);

  if (!result.error) {
    yield put<SystemSettingListActions>({ type: 'SystemSettingList.FetchRequested', payload: lastSearchCriteria });
  } else {
    if (result.error === "ERR_DUPLICATED_SYSTEM_SETTING"){
      yield put<LayoutActions>({ type: 'Layout.AlertMessageAdded', payload: { message: langSystemSetting.msgDuplicatedSystemSetting , severity: 'error' } })
    }else throw ApiError.of(result.error);
  }
}

export function* fetchFrontendVersion(){
  while(true){
    yield delay(1000*1800);
    const { token }: IRootState['login'] = yield select((state: IRootState) => state.login);
		if (!token) {
			
			continue;
		}
    put({ type: 'SystemSetting.FetchRequested' });
  }
}