import {
    Button as MuiButton, Card,
    CardActions,
    CardContent, colors, Divider,
    Table,
    TableBody,
    TableCell,
    TableHead, TableRow, TableSortLabel,
    // TablePagination,
    Theme
} from '@material-ui/core';
import { CardProps } from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useConfirmDialog } from 'common/ConfirmDialog';
import { RoleDTO } from 'common/dto';
import DataRow from 'common/elements/DataRow';
import TablePagination from 'common/elements/TablePagination';
import { tooltipHoc } from 'common/ui';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { IRootState } from 'reducers';




const Button = tooltipHoc(MuiButton);

const PUBLIC_URL = process.env['PUBLIC_URL'];

const useStyles = makeStyles((theme: Theme) => ({
	root: {},
	content: {
		padding: 0
	},
	inner: {
		minWidth: 650
	},
	nameContainer: {
		display: 'flex',
		alignItems: 'center'
	},
	avatar: {
		marginRight: theme.spacing(2)
	},
	actions: {
		justifyContent: 'flex-start',
		background: colors.grey[50],
		flexWrap: 'wrap',
    '& > *': {
      wordBreak: 'keep-all',
      // marginBottom: '8px',
    },
    padding: '0px',
	},
	tableRow: {
		cursor: 'pointer',
	},
	tableCell: {
		wordWrap: 'normal',
		wordBreak: 'keep-all',
		lineBreak: 'strict',
		whiteSpace: 'nowrap',
		// minWidth: 128,
	},
	tablePaginationIconButton:{
    '& .MuiIconButton-root':{
      padding: '6px',
    }
	},
	tablePaginationToolbar:{
    paddingRight: '12px',
  },
  clearSortingContainer: {
    padding: '3px',
  }
}));
  
type Header = {
	title: string | JSX.Element,
	overrideStyle?: any,
	sortDisabled?: boolean;
};

interface RoleTableProps extends CardProps {
  className?: string;
  roles: RoleDTO[];
  // matchingClient?: {
  //   name: string;
  // } | null;
  // onCancelPropertyMatching?: () => void;

  // formSigned?: boolean;
  // setFormSigned?: (v: boolean) => void;

  page: number;
	totalPages: number;
	totalElements: number;
  limit: number;
  setPage: (page: number) => void;
  sortOrders?: { [k: string]: 'desc' | 'asc' };
  handleSort?: (key: string | null) => void;
}

const RoleTable = (props: RoleTableProps) => {
	const { className, roles, sortOrders, handleSort, page, totalPages, totalElements, limit, setPage, ...rest } = props;
	const { langRolePrivilege, lang, statusOptions, } = useSelector((state: IRootState) => state.locale);
	const confirmDialog = useConfirmDialog();


	const classes = useStyles(0);
	const history = useHistory();


	let tableHeaders: Record<any, Header | null> = { // keyof RoleDTO
    roleName: { title: langRolePrivilege.captionRoleName },
    descriptionEN: { title: langRolePrivilege.captionDescriptionEN },
		descriptionTC: { title: langRolePrivilege.captionDescriptionTC },
		descriptionSC: { title: langRolePrivilege.captionDescriptionSC },
		dataStatus: { title: langRolePrivilege.captionDataStatus },
	};

	const navigationLink = (roleId: string) => `/roles/${roleId}`;

	const handleNavigateToItem = (roleId: string) => () => {
		history.push(navigationLink(roleId));
	}

	const handlePageChange = (event: React.MouseEvent | null, page: number) => {
		setPage(page);
	};
	
	const overrideCols: { [col: string]: (roles: RoleDTO) => any } = {
		dataStatus: role => role.dataStatus ? statusOptions[role.dataStatus] : '',
	};

	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>	
			{confirmDialog.render()}
			<CardActions className={classes.actions}>
				<div style={{ flexGrow: 1 }} /> 
				{/* {Object.keys(sortOrders ?? {}).length ?
          <div className={classes.clearSortingContainer}>
						<Button style={{ marginRight: 0 }} variant="outlined" size="small" onClick={() => handleSort?.(null)}>{lang.actionClearSorting}</Button>
          </div>
				: null} */}

				<TablePagination
					classes={{
						actions: classes.tablePaginationIconButton,
            toolbar: classes.tablePaginationToolbar,
					}}
					// component="div"
					count={totalElements}
					onChangePage={handlePageChange}
					// onChangeRowsPerPage={handleRowsPerPageChange}
					page={page}
					totalPages={totalPages}
					rowsPerPage={limit}
					labelRowsPerPage={lang.rowsCount}
					rowsPerPageOptions={[20]}
				/>
			</CardActions>

			<Divider />

			<CardContent className={classes.content}>
				<PerfectScrollbar>
					<div className={classes.inner}>
						<Table size="small">
							<TableHead>
								<TableRow>
									{
										Object.keys(tableHeaders).filter((key) => !!tableHeaders[key as keyof RoleDTO]).map((key,idx) =>
											<TableCell key={idx} className={classes.tableCell} style={tableHeaders[key as keyof RoleDTO]?.overrideStyle}>
												{tableHeaders[key as keyof RoleDTO]?.sortDisabled ? tableHeaders[key as keyof RoleDTO]?.title : <TableSortLabel
													active={sortOrders?.[key] !== undefined}
													direction={sortOrders?.[key]}
													onClick={() => handleSort?.(key)}
												>
													{tableHeaders[key as keyof RoleDTO]?.title}
												</TableSortLabel>}
											</TableCell>
										)
									}
								</TableRow>
								{Object.keys(sortOrders ?? {}).length ? <TableRow>
								</TableRow> : null}
							</TableHead>
							<TableBody>
								{roles.map(role => (
									<DataRow
										className={classes.tableRow}
										hover
										key={'row-' + role.id }
										// selected={selectedProperties.indexOf(property.id!) !== -1}
										onClick={handleNavigateToItem(role.id!)}
										href={PUBLIC_URL + navigationLink(role.id!)}
									>
										{
											Object.keys(tableHeaders).filter((key) => !!tableHeaders[key as keyof RoleDTO]).map((key) => {
												// 
												return <TableCell className={classes.tableCell}  key={`${role.id}-${key}`}>{overrideCols[key] ? overrideCols[key](role) : role[key as keyof RoleDTO]}</TableCell>
											})
										}
									</DataRow>
								))}
							</TableBody>
						</Table>
					</div>
				</PerfectScrollbar>
			</CardContent>
		</Card>
	);
}

export default RoleTable;