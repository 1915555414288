import {
  Card, CardContent, CardHeader, Checkbox, Divider, FormControl, FormHelperText, Grid,
  IconButton,
  ListItemText, MenuItem, Select,
  TextField, Theme, Tooltip, Typography
} from '@material-ui/core';
import { CardProps } from '@material-ui/core/Card';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { BuildingDetailDTO } from 'common/dto';
import { FormPresenter } from 'common/hooks';
import { HandleFormHelperText, } from 'common/ui';
import { initFormKeysAndBooleanMap, isNonEmpty, limitTextFieldLength } from 'common/utils';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';
import { ConfirmDeleteDialog } from "./ConfirmDeleteDialog";


const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  multiLangInputRoot: {
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.grey[400],
    borderWidth: 1,
    borderStyle: 'solid',
    // paddingLeft: theme.spacing(1)
  },
  langAdornment: {
    paddingLeft: theme.spacing(1),
  },
  fileItem: {
    '& .fileControl': {
      display: 'none',
    },
    '&:hover .fileControl': {
      display: 'inherit',
    },
  }
}));

const errorColor = '#e53935';

interface BuildingFeaturesProps extends CardProps {
  form: FormPresenter<BuildingDetailDTO>;
  tagForm: FormPresenter<any>;
  floorForm: FormPresenter<any>;
  isNew: boolean;
  canCreate: boolean;
  canEdit: boolean;
}

const BuildingFeatures = (props: BuildingFeaturesProps) => {
  const { className, form, tagForm, floorForm, isNew, canCreate, canEdit, ...rest } = props;
  const { langBuilding: lang, secondarySchoolNetOptions, primarySchoolNetOptions, clubHouseFacilitiesOptions, otherOptions, levelOptions } = useSelector((state: IRootState) => state.locale);

  const classes = useStyles();

  // setLinksArray(linksArray => [...linksArray, formattedArray[i]])
  const addFloorDefinition = () => {
    floorForm.updateValues('floorDefinition', [...floorForm.values.floorDefinition, { floor: '', unit: '' }]);
  };

  const removeFloorDefinition = (i: number) => () => {
    floorForm.updateValues('floorDefinition', floorForm.values.floorDefinition.filter((_: any, _i: number) => i !== _i));
  }

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedFloorDefinition, setSelectedFloorDefinition] = useState(-1);

  const clickDeleteButton = (i: number) => () => {
    setSelectedFloorDefinition(i);
    showConfirmDialog();
  }

  const showConfirmDialog = () => {
    setConfirmDialogOpen(true);
  };

  const closeConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };
  // floorForm.values[floorForm.values.length + 1]
  // const editFloor = (event: React.ChangeEvent<HTMLInputElement>, idx: number) => {
  //   floorForm.setValues([...floorForm.values, { floor: event.target.value, unit: floorForm!.values[idx]!.unit ?? ''}]);
  // }
  const bind = form.bind;

  const [keyBooleanMap, setKeyBooleanMap] = useState(initFormKeysAndBooleanMap(form));

  useEffect(() => {
    setKeyBooleanMap(initFormKeysAndBooleanMap(form));
  }, [Object.keys(form.values).length])


  const hasErrorForFloorOrUnit = (idx: number, value: string) => {
    const indexArray = form.hasError('floorDefinition')?.split("_") ?? [];
    if (indexArray.includes(idx.toString()) && (!isNonEmpty(value) || value?.split(',').some(i => !i.trim()))) return true;
    return false;
  }

  const canSave = isNew || canCreate || canEdit;

  return (
    <Grid container /*xs={12}*/ spacing={1}>
      <ConfirmDeleteDialog
        open={confirmDialogOpen}
        handleClose={closeConfirmDialog}
        handleClickOpen={showConfirmDialog}
        selectedFloorDefinition={selectedFloorDefinition}
        removeFloorDefinition={removeFloorDefinition}
        floorForm={floorForm}
      />
      <Grid item xs={12}>
        <Card
          {...rest}
          className={clsx(classes.root, className)}
        >
          <CardHeader title={lang.titleBuildingFeature} subheader="" />
          <Divider />
          <form
            autoComplete="off"
            noValidate
          >
            <CardContent>
              <Grid
                container
                spacing={1}
              >
                {/* <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
              fullWidth
              label="會所設施"
              select
              margin="dense"
              name="clubHouseFacilities"
              onChange={handleChange}
              required
              value={values.clubHouseFacilities}
              variant="outlined"
              >
              <MenuItem value={0}>無</MenuItem>
              <MenuItem value={1}>有</MenuItem>
              </TextField>
            </Grid> */}
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <Typography>
                    {lang.captionFloorUnit}
                    <Tooltip title={lang.actionAdd}>
                      <IconButton color="primary" onClick={addFloorDefinition} disabled={!canEdit && !isNew}>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                  {form.hasError('floorDefinition') ? <FormHelperText style={{ color: form.hasError('floorDefinition') ? errorColor : 'inherit' }}>{lang.msgPleaseEnterFloorDefinition}</FormHelperText> : null}
                  {form.hasError('invalidFloorDefinition') ? <FormHelperText style={{ color: form.hasError('invalidFloorDefinition') ? errorColor : 'inherit' }}>{lang.msgPleaseEnterFloorDefinition}</FormHelperText> : null}
                </Grid>

                {
                  floorForm!.values!.floorDefinition!.map((value: any, idx: number) => {
                    return (
                      <Grid item container md={12} sm={12} xs={12} spacing={2}>
                        <Grid
                          item
                          md={1}
                          sm={1}
                          xs={12}
                        >
                          <Typography style={{ display: 'flex', alignItems: 'center', flexGrow: 2, width: 200 }}>
                            <IconButton key={idx} onClick={clickDeleteButton(idx)} disabled={!canEdit && !isNew}>
                              <DeleteIcon />
                            </IconButton>
                          </Typography>

                        </Grid>
                        <Grid
                          item
                          md={5}
                          sm={5}
                          xs={12}
                        >
                          {/* <Typography>樓層</Typography> */}
                          <TextField
                            fullWidth
                            label={lang.floor}
                            margin="dense"
                            onChange={(e) => {
                              // console.log("form", form);
                              // console.log("formfloor", floorForm);
                              if (e.target.value.length > 255) {
                                e.preventDefault();
                                setKeyBooleanMap(keyBooleanMap.set('floorDefinitionFloor' + idx, true));
                                const updatedValue = { ...value, floor: e.target.value.substring(0, 255) };
                                floorForm.updateValues('floorDefinition',
                                  floorForm.values.floorDefinition?.map((c: any, i: number) => idx === i ? updatedValue : c)
                                );

                              } else {
                                setKeyBooleanMap(keyBooleanMap.set('floorDefinitionFloor' + idx, false))
                                const updatedValue = { ...value, floor: e.target.value };
                                floorForm.updateValues('floorDefinition',
                                  floorForm.values.floorDefinition?.map((c: any, i: number) => idx === i ? updatedValue : c)
                                );
                              }



                              // const updatedValue = { ...value, floor: e.target.value };
                              // floorForm.updateValues('floorDefinition',
                              //   floorForm.values?.floorDefinition?.map((v: any, i: number) => i === idx ? updatedValue : v)
                              // )
                            }}
                            value={value.floor}
                            error={hasErrorForFloorOrUnit(idx, value.floor)}
                            variant="outlined"
                            required
                            InputProps={{ readOnly: !canEdit && !isNew }}
                            disabled={!canEdit && !isNew}
                          ></TextField>
                          <HandleFormHelperText
                            isError={keyBooleanMap.get('floorDefinitionFloor' + idx)}
                            errorMessage={lang.textOverLimit}
                          />
                        </Grid>

                        <Grid
                          item
                          md={5}
                          sm={5}
                          xs={12}
                        >
                          {/* <Typography>單位</Typography> */}
                          <TextField
                            fullWidth
                            label={lang.unit}
                            margin="dense"
                            onChange={(e) => {

                              if (e.target.value.length > 255) {
                                e.preventDefault();
                                setKeyBooleanMap(keyBooleanMap.set('floorDefinitionUnit' + idx, true));
                                const updatedValue = { ...value, unit: e.target.value.substring(0, 255) };
                                floorForm.updateValues('floorDefinition',
                                  floorForm.values.floorDefinition?.map((c: any, i: number) => idx === i ? updatedValue : c)
                                );

                              } else {
                                setKeyBooleanMap(keyBooleanMap.set('floorDefinitionUnit' + idx, false))
                                const updatedValue = { ...value, unit: e.target.value };
                                floorForm.updateValues('floorDefinition',
                                  floorForm.values.floorDefinition?.map((c: any, i: number) => idx === i ? updatedValue : c)
                                );
                              }

                              // const updatedValue = { ...value, unit: e.target.value };
                              // floorForm.updateValues('floorDefinition',
                              //   floorForm.values?.floorDefinition?.map((v: any, i: number) => i === idx ? updatedValue : v)
                              // )
                            }}
                            value={value.unit}
                            variant="outlined"
                            error={hasErrorForFloorOrUnit(idx, value.unit)}
                            InputProps={{ readOnly: !canEdit && !isNew }}
                            disabled={!canEdit && !isNew}
                            required


                          ></TextField>
                          <HandleFormHelperText
                            isError={keyBooleanMap.get('floorDefinitionUnit' + idx)}
                            errorMessage={lang.textOverLimit}
                          />
                        </Grid>
                      </Grid>
                    )
                  })
                }
                <Grid
                  item
                  md={4}
                  sm={4}
                  xs={12}
                >
                  <Typography>{levelOptions.LOW}</Typography>
                  <TextField
                    fullWidth
                    // label="睡房"
                    margin="dense"
                    name="suite"
                    required
                    variant="outlined"
                    {...bind('lowLevel')}
                    InputProps={{ readOnly: !canEdit && !isNew }}
                    disabled={!canEdit && !isNew}

                    onChange={(e) => {
                      limitTextFieldLength(e, 255, 'lowLevel', form, keyBooleanMap, setKeyBooleanMap);
                    }}
                  ></TextField>
                </Grid>

                <Grid
                  item
                  md={4}
                  sm={4}
                  xs={12}
                >
                  <Typography>{levelOptions.MIDDLE}</Typography>
                  <TextField
                    fullWidth
                    // label="睡房"
                    margin="dense"
                    required
                    variant="outlined"
                    {...bind('middleLevel')}
                    InputProps={{ readOnly: !canEdit && !isNew }}
                    disabled={!canEdit && !isNew}
                    onChange={(e) => {
                      limitTextFieldLength(e, 255, 'middleLevel', form, keyBooleanMap, setKeyBooleanMap);
                    }}
                  ></TextField>
                </Grid>

                <Grid
                  item
                  md={4}
                  sm={4}
                  xs={12}
                >
                  <Typography>{levelOptions.HIGH}</Typography>
                  <TextField
                    fullWidth
                    // label="工人房"
                    margin="dense"
                    required
                    variant="outlined"
                    {...bind('highLevel')}
                    InputProps={{ readOnly: !canEdit && !isNew }}
                    disabled={!canEdit && !isNew}
                    onChange={(e) => {
                      limitTextFieldLength(e, 255, 'highLevel', form, keyBooleanMap, setKeyBooleanMap);
                    }}
                  ></TextField>
                </Grid>

                <Grid
                  item
                  md={6}
                  sm={6}
                  xs={12}
                >
                  <Typography>{lang.clubHouseFacilities}</Typography>
                  <FormControl variant="outlined" margin="dense" fullWidth>
                    <Select disabled={!canEdit && !isNew}
                      multiple
                      {...tagForm.bind('CLUB_FACILITIES')}
                      renderValue={(selected) => (selected as string[]).map(key => clubHouseFacilitiesOptions[key]).join(', ')}
                    >
                      {Object.keys(clubHouseFacilitiesOptions).map(feature => (
                        <MenuItem key={feature} value={feature}>
                          <Checkbox checked={tagForm.values.CLUB_FACILITIES!.indexOf(feature) > -1} />
                          <ListItemText primary={clubHouseFacilitiesOptions[feature]} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {/* <TextField select
                fullWidth
                margin="dense"
                name="features"
                onChange={handleChange}
                required
                value={values.features}
                variant="outlined">
                {
                  otherFeatureOptions.map((opt) => <MenuItem value={opt}>{opt}</MenuItem>)
                }
              </TextField> */}
                </Grid>
                <Grid
                  item
                  md={6}
                  sm={6}
                  xs={12}
                >
                  <Typography>{lang.others}</Typography>
                  <FormControl variant="outlined" margin="dense" fullWidth>
                    <Select disabled={!canEdit && !isNew}
                      multiple
                      {...tagForm.bind('OTHERS')}
                      renderValue={(selected) => (selected as string[]).map(key => otherOptions[key]).join(', ')}
                    >
                      {Object.keys(otherOptions).map(feature => (
                        <MenuItem key={feature} value={feature}>
                          <Checkbox checked={tagForm.values.OTHERS!.indexOf(feature) > -1} />
                          <ListItemText primary={otherOptions[feature]} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  md={6}
                  sm={6}
                  xs={12}

                >
                  <Typography>{lang.primarySchoolNet}</Typography>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    select
                    {...form.bind('primarySchoolNet')}
                    InputProps={{ readOnly: !canEdit && !isNew }}
                    disabled={!canEdit && !isNew}
                  >
                    {Object.keys(primarySchoolNetOptions).map(val => (
                      <MenuItem key={val} value={val}>
                        {primarySchoolNetOptions[val]}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid
                  item
                  md={6}
                  sm={6}
                  xs={12}
                >
                  <Typography>{lang.secondarySchoolNet}</Typography>
                  <TextField
                    select
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    {...form.bind('secondarySchoolNet')}
                    InputProps={{ readOnly: !canEdit && !isNew }}
                    disabled={!canEdit && !isNew}
                  >
                    {Object.keys(secondarySchoolNetOptions).map(val => (
                      <MenuItem key={val} value={val}>
                        {secondarySchoolNetOptions[val]}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <Typography>{lang.remarks}</Typography>
                  <TextField
                    fullWidth
                    multiline rows="2"
                    // label="備註"
                    margin="dense"
                    required
                    {...bind('remarks')}
                    variant="outlined"
                    InputProps={{ readOnly: !canEdit && !isNew }}
                    onChange={(e) => {
                      limitTextFieldLength(e, 255, 'remarks', form, keyBooleanMap, setKeyBooleanMap);
                    }}
                  />
                  <HandleFormHelperText
                    isError={keyBooleanMap.get('remarks')}
                    errorMessage={lang.textOverLimit}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </form>
        </Card>
      </Grid>
    </Grid >
  );
};

BuildingFeatures.propTypes = {
  className: PropTypes.string
};

export default BuildingFeatures;
