import { makeStyles, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { userApi } from 'api';
import { UserDTO } from 'common/dto';
import { useForm } from 'common/hooks';
import { multiLang } from 'common/utils';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState, PASDispatch } from 'reducers';

const useStyles = makeStyles(() => ({ 
  root: {

  },
  patchOutlineAutocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
      paddingLeft: 8,
    }
  },
  dialogContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60%',
  },
  textArea: {
    width: '90%'
  }
}));

interface ImpersonationRequestDialogProps {
  open: boolean;
  handleClickOpen: () => void;
  handleClose: () => void;
}


const ImpersonationRequestDialog = ({open, handleClose} : ImpersonationRequestDialogProps) => {
  
  const { lang, locale, langUser } = useSelector((state: IRootState) => state.locale);
  const { token } = useSelector((state: IRootState) => state.login);
  const dispatch = useDispatch() as PASDispatch;
  const classes = useStyles();

  const form = useForm<UserDTO>({}, {
    validations: [
      (values) => values.id ? null : [[ 'id', langUser.msgSearchUserToImpersonate]]  ,
    ],
  });

  const [users, setUsers] = useState<UserDTO[]>([]);
  const [shouldDisplayUserAutoComplete, setShouldDisplayUserAutoComplete] = useState(false);

  const searchUsers = async (kw: string) => {
    try {
      const response = await userApi.searchUserForImpersonation(kw, token ?? '');
      // 
      setUsers(response.data ?? []);
    } catch (e) {
      // { error: String(e) };
    }
  };

  const handleConfirm = async() =>{
    if (!form.validate()){
      return;
    }
    dispatch({ type: 'User.ImpersonationRequested' , payload:{beingImpersonatedUserId: form.values.id ?? ''}});
    handleClose();
    form.setValues({});
    form.stopValidation();
  }

  return (
    <div> 
      <Dialog maxWidth="xs" fullWidth open={open} onClose={handleClose} aria-labelledby="impersonation-request-dialog-title">
        <DialogTitle id="impersonation-request-dialog-title">{langUser.actionImpersonationRequest}</DialogTitle>
        <DialogContent >
          
            <Autocomplete
                  options={users}
                  popupIcon={''}
                  getOptionLabel={(_user: UserDTO) => form.values.username ?? ''}
                  noOptionsText={shouldDisplayUserAutoComplete ? langUser.msgUserNoFound : ''}
                  value={form.values.username || ''}
                  onChange={(_, user: UserDTO | undefined) => {
                    form.setValues({
                      ...form.values,
                      id: user?.id ?? '0',
                      chineseName: user?.chineseName,
                      englishName: user?.englishName,
                      username: user?.username,
                    });
                  }}
                  renderOption={(user: UserDTO) => <div>
                    <div><Typography>{user?.username} ({multiLang(locale, user.chineseName, user.englishName) ?? '-'})</Typography></div>
                  </div>}
                  renderInput={params => (
                    <TextField
                      {...params}
                      className={classes.patchOutlineAutocomplete}
                      fullWidth
                      error={!!form.hasError('id')}
                      helperText={langUser.msgSearchUserToImpersonate}
                      label={langUser.username}
                      margin="dense"
                      required
                      variant="outlined"
                      onChange={(ev) => {
                        setShouldDisplayUserAutoComplete(ev.target.value.length >= 1);
                        if (ev.target.value.length >= 1) {
                          searchUsers(ev.target.value);
                        }
                      }}
                    />
                  )}
                />  
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {lang.actionCancel}
          </Button>
          <Button onClick={handleConfirm} color="primary" variant="contained" autoFocus>
            {lang.actionConfirm}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default ImpersonationRequestDialog;
