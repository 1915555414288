import {
  Button, Card, CardActions, CardContent, Checkbox, Divider, FormControl, FormControlLabel,
  FormGroup, FormHelperText, Grid, InputAdornment, makeStyles, MenuItem, Paper, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, TextareaAutosize, TextField, TextFieldProps, Typography, withStyles
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import clsx from 'clsx';
import { Form4DTO, Form4PropertyDTO, PropertyListItemDTO } from 'common/dto';
import { FormPresenter } from "common/hooks";
import { HandleFormHelperText } from 'common/ui';
import { genAddress, initFormKeysAndBooleanMap, isNonEmpty, limitTextFieldLength } from 'common/utils';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';


const useStyles = makeStyles(() => ({
  root: {},
  partTitle: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  textArea: {
    width: '100%',
    margin: 'dense',
    padding: '8px',
    fontSize: '14px'
  },
  table: {
    minWidth: 650,
    '& .MuiTableHead-root': {
      // wordBreak: 'keep-all',
    },
  },
  textField: {
    width: '30%',
  },
  datePicker: {
    width: '30%',
  },
  column0: {
    width: '3%',
  },
  column1: {
    width: '13%',
  },
  numberFields: {
    width: '30%',
  },
  checkboxColumn: {
    width: '5%',
  },
  subTableCell: {
    borderBottomColor: '#FFFFFF',
  },
  divider: {
    backgroundColor: '#1565c0',
  },
  infoPartTextField: {
    width: '100%',
  },
  companyInfo: {
    paddingLeft: '20px',
  },
  //for schedule 1 table
  s1Column1: {
    width: '20%',
  },
  s1Column2: {
    width: '15%',
  },
  s1Column3: {
    width: '10%',
  },
  s1Column4: {
    width: '10%',
  },
  s1Column5: {
    width: '15%',
  },
  s1Column6: {
    width: '15%',
  },
  s1Column7: {
    width: '15%',
  },
  s1Cell: {
    margin: '2px',
  }
}));

const regex = new RegExp(/(^\d*\.?\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/);
const NumberField = withStyles({
  root: {
    '& input': {
      textAlign: 'right',
      '-moz-appearance': 'textfield',
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  },
},
)((props: TextFieldProps) => {
  return <TextField type={"text"}{...props} inputProps={{
    ...props.inputProps,
    min: 0,
    max: Math.max(),
    inputMode: 'decimal',
    pattern: "([^0-9]*)",
    step: 0.01
  }}

    onKeyDown={e => {
      // If the key pressed is not a number or a period, nothing is printed
      if (!e.key.match(regex)) {

        e.preventDefault();

      }
      // if (!e.key.match(regex) || (e.key === '.' && String(props.value).indexOf('.') > 0) || (e.key === '.' && String(props.value).length === 0) || e.keyCode === 229 || e.key === 'Unidentified') {

      //   e.preventDefault();

      // }

    }}

    onKeyPress={e => {
      // If the key pressed is not a number or a period, nothing is printed
      if (!e.key.match(regex)) {

        e.preventDefault();

      }
    }}

  />;
});

const errorColor = '#e53935';

const disablePrevDates = (startDates: any) => {
  const startSeconds = Date.parse(startDates);
  return (date: any) => {
    return Date.parse(date) < startSeconds;
  }
}

export const form4Initial: Partial<Form4DTO> = {
  needDisclosure: false,
  withInterest: false,
  paymentTimeOption: 'option1',
};

export const form4Schedule1RecordInit = (selectedProperties: PropertyListItemDTO[], locale: string, districtEnOptions: { [key: string]: string }, districtZhHkOptions: { [key: string]: string }) => {
  let resultList: Form4PropertyDTO[] = [];
  selectedProperties.map((p: PropertyListItemDTO) => {
    let record = {} as Form4PropertyDTO;
    const propertyAddress = genAddress(locale, p.unit ?? '', p.floor ?? '', p.blockZh ?? '', p.blockEn ?? '', p.buildingNameZh ?? '', p.buildingNameEn ?? '', p.streetZh ?? '', p.streetEn ?? '', (locale === 'en') ? districtEnOptions[p.district ?? ''] : districtZhHkOptions[p.district ?? '']);
    // record.formId = formId;
    record.propertyStockId = String(p.id!);
    record.property = propertyAddress;
    record.dateOfInspection = undefined;
    record.notToInspect = false;
    record.waiveRight = false;
    record.agencyRelationship = 'potentiallyDualAgency';
    record.vendorCommission = '';
    record.purchaserCommission = '';
    resultList.push(record);
  });
  return resultList;
  // let record = {} as Form4Schedule1RecordInterface;
  // record.formId = formId;
  // record.propertyStockId
  // record.property = property;
  // record.dateOfInspection = undefined;
  // record.notToInspect = false;
  // record.waiveRight = false;
  // record.agencyRelationship = '';
  // record.vendorCommission = undefined;
  // record.purchaserCommission = undefined;
  // return record;
};
interface CommissionUnitProps {
  detail: Form4PropertyDTO, classes: any, form4Schedule1: FormPresenter<any>, i: number, type: string, schedule1HasError: boolean, langForm4: any
}

const CommissionUnit = (props: CommissionUnitProps) => {
  const [commissionUnit, setCommissionUnit] = useState(props.type === 'vendor' ? props.detail.vendorCommission?.includes('%') ? '%' : '$' : props.detail.purchaserCommission?.includes('%') ? '%' : '$');
  // let commissionUnit = type === 'vendor' ? detail.vendorCommission?.includes('%') ? '%' : '$' : detail.purchaserCommission?.includes('%') ? '%' : '$'
  const handleCommissionUnitChange = (event: any) => {
    // console.log('handleCommissionUnit', event.target.value);
    setCommissionUnit(event.target.value);
  };
  // console.log('commission', detail.vendorCommission, detail.purchaserCommission)
  // const value = () => { return commissionUnit }
  // e.target.value.indexOf(".") == -1 ? e.target.value.replaceAll('%', '').replaceAll('$', '')  : (e.target.value.replaceAll('%', '').replaceAll('$', '').length - (e.target.value.replaceAll('%', '').replaceAll('$', '') .indexOf(".") + 1)) > 2 ? (Math.floor(parseFloat(e.target.value.replaceAll('%', '').replaceAll('$', '') ) * 100) / 100).toFixed(2) : e.target.value.replaceAll('%', '').replaceAll('$', '') 
  useEffect(() => {
    const updatedDetail = props.type === 'vendor' ? { ...props.detail, vendorCommission: props.detail.vendorCommission?.replaceAll('%', '').replaceAll('$', '') + (commissionUnit === '%' ? '%' : '') } : { ...props.detail, purchaserCommission: props.detail.purchaserCommission?.replaceAll('%', '').replaceAll('$', '') + (commissionUnit === '%' ? '%' : '') }
    props.form4Schedule1.updateValues('formProperty',
      props.form4Schedule1.values.formProperty?.map((c: Object, idx: number) => idx === props.i ? updatedDetail : c)
    );
  }, [commissionUnit])

  return <TableCell padding='none'>
    <div className={props.classes.s1Cell}>
      <NumberField
        className={props.classes.textField}
        style={{ width: '100%', textAlignLast: 'end' }}
        margin="dense"
        variant="outlined"
        value={(props.type === 'vendor' ? props.detail.vendorCommission?.replaceAll('%', '').replaceAll('$', '') ?? '' : props.detail.purchaserCommission?.replaceAll('%', '').replaceAll('$', '') ?? '')}
        error={props.schedule1HasError && props.type === 'purchaser' && !isNonEmpty(props.detail.purchaserCommission?.replaceAll('%', '').replaceAll('$', ''))}
        helperText={props.schedule1HasError && props.type === 'purchaser' && !isNonEmpty(props.detail.purchaserCommission?.replaceAll('%', '').replaceAll('$', '')) ? props.langForm4.pleaseInput : ''}
        onChange={(e) => {
          //console.log("e.target.value", e.target.value, props.detail.purchaserCommission);
          if (!isNonEmpty(e.target.value)) {
            e.preventDefault();
          } else if (isNaN(parseFloat(e.target.value))) {
            e.preventDefault();
          } else if (e.target.value.match(/^\d*\.?\d*$/) == null) {
            e.preventDefault();
          } else if ((e.target.value.indexOf(".") == -1 && e.target.value.length > 12) || (e.target.value.indexOf(".") != -1 && e.target.value.indexOf(".") > 12 || (e.target.value.indexOf('.') != -1 && e.target.value.match(/\./g)!.length >= 2))) {
            e.preventDefault();
          } else {
            const updatedDetail = props.type === 'vendor' ? {
              ...props.detail, vendorCommission:
                e.target.value.indexOf(".") == -1 ?
                  e.target.value.replaceAll('%', '').replaceAll('$', '') + (commissionUnit === '%' ? '%' : '')
                  : (e.target.value.replaceAll('%', '').replaceAll('$', '').length - (e.target.value.replaceAll('%', '').replaceAll('$', '').indexOf(".") + 1)) > 2 ?
                    (Math.floor(parseFloat(e.target.value.replaceAll('%', '').replaceAll('$', '')) * 100) / 100).toFixed(2) + (commissionUnit === '%' ? '%' : '')
                    : e.target.value.replaceAll('%', '').replaceAll('$', '') + (commissionUnit === '%' ? '%' : '')
            }
              : {
                ...props.detail, purchaserCommission: e.target.value.indexOf(".") == -1 ?
                  e.target.value.replaceAll('%', '').replaceAll('$', '') + (commissionUnit === '%' ? '%' : '')
                  : (e.target.value.replaceAll('%', '').replaceAll('$', '').length - (e.target.value.replaceAll('%', '').replaceAll('$', '').indexOf(".") + 1)) > 2 ?
                    (Math.floor(parseFloat(e.target.value.replaceAll('%', '').replaceAll('$', '')) * 100) / 100).toFixed(2) + (commissionUnit === '%' ? '%' : '')
                    : e.target.value.replaceAll('%', '').replaceAll('$', '') + (commissionUnit === '%' ? '%' : '')
              }

            props.form4Schedule1.updateValues('formProperty',
              props.form4Schedule1.values.formProperty?.map((c: Object, idx: number) => idx === props.i ? updatedDetail : c)
            );
          }
        }
        }
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <Select
                value={commissionUnit}
                onChange={handleCommissionUnitChange}
              >
                <MenuItem key='commision$' value='$'>$</MenuItem>
                <MenuItem key='commision%' value='%'>%</MenuItem>
              </Select>
            </InputAdornment>
          ),
        }}
      />
    </div>
  </TableCell>


}

interface Form4Props {
  className?: string;
  form4: FormPresenter<Form4DTO>;
  form4Schedule1: FormPresenter<any>;
}



const Form4Dialog = (props: Form4Props) => {
  const { className, form4, form4Schedule1 } = props;

  const classes = useStyles();
  const agencyCompanyInfo = useSelector((state: IRootState) => state.systemSettings.Company.AGENCY_COMPANY_INFO);
  const { lang, langForm4, langPropertyStock, formLocaleOptions } = useSelector((state: IRootState) => state.locale);
  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);
  //commission unit

  const schedule1Table = form4Schedule1.values.formProperty || [];

  const bind = form4.bind;

  const [keyBooleanMap, setKeyBooleanMap] = useState(initFormKeysAndBooleanMap(form4));

  useEffect(() => {
    setKeyBooleanMap(initFormKeysAndBooleanMap(form4));
  }, [Object.keys(form4.values).length])

  const requiredFields: { [key: string]: string[] } = {
    form: ['formLocale', 'purchaserName', 'agentName', 'validityPeriodStart', 'validityPeriodEnd', 'interestsInProperty',],
    purchaserInfo: ['eitherSignatoryInfoOrHKID', 'nameOfSignatoryOfPurchaser', 'positionOfSignatoryOfPurchaser', 'businessRegistrationCertificateNumberOfPurchaser', 'purchaserAddress', 'purchaserPhoneNumber'],
    agentInfo: ['nameAndLicenceNumberOfAgent', 'numberOfStatementOfParticularsOfBusiness', 'agentAddress', 'agentPhoneNumber'],
  };

  const partNameMapping = (partName: string) => {
    switch (partName) {
      case 'form': return langForm4.form;
      case 'purchaserInfo': return langForm4.purchaserInfo;
      case 'agentInfo': return langForm4.agentInfo;
    }
  }
  //TODO

  const missingFieldList = () => {
    let resultStr: string[] = [];
    Object.keys(requiredFields).forEach(part => {
      let partMissingFields: string[] = [];
      requiredFields[part].forEach(key => {
        let validationMsg = form4.hasError(key as keyof Form4DTO);
        if (validationMsg) {
          partMissingFields.push(validationMsg?.replace(langForm4.pleaseInput, '')?.replace(langForm4.pleaseSelect, ''));
        }
      });
      if (partMissingFields.length > 0) {
        resultStr.push(partNameMapping(part.toString()) + ": " + partMissingFields.join(', '));
      }
    });

    if (form4.hasError('invalidFormProperty')) {
      resultStr.push(langForm4.titleSchedule1);
    }
    return resultStr.join('\n');
  }

  const schedule1HasError = !!form4.hasError('invalidFormProperty');

  const companyDetailsLocale = (locale: string) => {
    return (locale === 'en') ? { name: agencyCompanyInfo['COMPANY_NAME_EN'], address: agencyCompanyInfo['ADDRESS_EN'] } : { name: agencyCompanyInfo['COMPANY_NAME_CN'], address: agencyCompanyInfo['ADDRESS_CN'] };
  }

  const { districtHkiOptions: districtHkiOptionsEn, districtNtOptions: districtNtOptionsEn, districtKltOptions: districtKltOptionsEn } = useSelector((state: IRootState) => state.locale._bundle['en']);
  const { districtHkiOptions: districtHkiOptionsZhHK, districtNtOptions: districtNtOptionsZhHK, districtKltOptions: districtKltOptionsZhHK } = useSelector((state: IRootState) => state.locale._bundle['zh_HK']);

  const districtEnOptions = {
    ...districtHkiOptionsEn,
    ...districtNtOptionsEn,
    ...districtKltOptionsEn,
  };
  const districtZhHkOptions = {
    ...districtHkiOptionsZhHK,
    ...districtNtOptionsZhHK,
    ...districtKltOptionsZhHK,
  };

  const stockCartList = useSelector((state: IRootState) => state.clients.stockCartList) ?? [];
  const form4Schedule1Id = props.form4Schedule1.values.formProperty.map((p: { propertyStockId: any; }) => p.propertyStockId.toString());
  const form4SelectedProperties = stockCartList.filter((p) => form4Schedule1Id.includes(p.id.toString()));


  const genForm4Schedule1AddressByLocale = (form4SelectedProperties: PropertyListItemDTO[], locale: string) => {
    let newFormProperties: any[] = []
    form4SelectedProperties.forEach((p: PropertyListItemDTO) => {
      let propertyAddress = genAddress(locale, p.unit ?? '', p.floor ?? '', p.blockZh ?? '', p.blockEn ?? '', p.buildingNameZh ?? '', p.buildingNameEn ?? '', p.streetZh ?? '', p.streetEn ?? '', (locale === 'en') ? districtEnOptions[p.district ?? ''] : districtZhHkOptions[p.district ?? '']);
      let temp = { ...form4Schedule1.values.formProperty.filter((f: { propertyStockId: any; }) => f.propertyStockId.toString() === p.id.toString())[0], property: propertyAddress }
      newFormProperties.push(temp);
    });
    form4Schedule1.updateValues('formProperty', newFormProperties);
  };

  const updateCompanyCurrentLocaleDetails = (locale: string) => {
    let details = companyDetailsLocale(locale);
    form4.updateValues('agentName', details.name);
    form4.updateValues('agentAddress', details.address);
    genForm4Schedule1AddressByLocale(form4SelectedProperties, locale);
  }


  return (
    <Card
      // {...rest}
      className={clsx(classes.root, className)}>
      {/* 生成出售物業代理協議 (表格3) */}
      {/* <CardHeader></CardHeader> */}
      {/* <Divider /> */}
      <CardContent>
        <Grid item container xs={12} spacing={2}>
          {isNonEmpty(missingFieldList()) ?
            <Grid
              item
              md={12}
              xs={12}
            >

              <div style={{ marginBottom: '5px', whiteSpace: "pre-wrap" }}>
                <Typography style={{ color: errorColor, fontSize: '13px', fontWeight: 'bold', fontStyle: 'italic' }}>{langForm4.msgInputMissingFields}</Typography>
                <Typography style={{ color: errorColor, fontSize: '13px', fontStyle: 'italic' }}>{missingFieldList()}</Typography>
              </div>
              <Divider />
            </Grid>
            : null
          }
          <Grid
            item
            sm={2}
            md={2}
            xs={12}
          >
            <Typography>{langPropertyStock.captionFormLocale}</Typography>
            <TextField select
              fullWidth
              margin="dense"
              {...bind('formLocale')}
              required
              variant="outlined"
              onChange={(event) => {
                bind('formLocale').onChange(event);
                updateCompanyCurrentLocaleDetails(event.target.value);
              }}>
              {
                Object.keys(formLocaleOptions).map((opt) => <MenuItem value={opt}>{formLocaleOptions[opt]}</MenuItem>)
              }
            </TextField>
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >
            <Typography className={classes.partTitle} >{langForm4.formName}</Typography>
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >
            {/*------------- 1. Appointment of Agent and Validity Period of this Agreement------------ */}
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="part 1 table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.column0} data-key={'field-form4-isValidityPeriodValid'}>1.</TableCell>
                    <TableCell colSpan={2} align="left" className={classes.column1} >{langForm4.titlePart1}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/*----------- purchaser name ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell className={classes.column1} >{langForm4.purchaserName}</TableCell>
                    <TableCell>
                      <TextField
                        className={classes.textField}
                        margin="dense"
                        variant="outlined"
                        {...bind('purchaserName')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'purchaserName', form4, keyBooleanMap, setKeyBooleanMap);
                        }}
                        helperText={form4.hasError('purchaserName')}
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('purchaserName')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>

                  {/*----------- agent name ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell className={classes.column1} >{langForm4.agentName}</TableCell>
                    <TableCell>
                      <TextField
                        className={classes.textField}
                        margin="dense"
                        variant="outlined"
                        {...bind('agentName')}
                        disabled={true}
                      />
                    </TableCell>

                  </TableRow>

                  {/*----------- validity period ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell className={classes.column1} data-key={'field-form4-validityPeriodStart'}>
                      {langForm4.validityPeriod}
                      <div style={{ display: 'inline-block', width: '80%' }}>
                        {form4.hasError('isValidityPeriodValid') ? <FormHelperText style={{ color: form4.hasError('isValidityPeriodValid') ? errorColor : 'inherit' }}>{form4.validationErrors?.isValidityPeriodValid}</FormHelperText> : null}
                      </div>
                    </TableCell>
                    <TableCell data-key={'field-form4-validityPeriodEnd'}>
                      <div>
                        <KeyboardDatePicker autoOk={true}
                          className={classes.datePicker}
                          // InputProps={InputGroup.leftItem()}
                          //disableToolbar
                          variant="inline"
                          inputVariant="outlined"
                          format={DISPLAY_DATE_FORMAT}
                          label={langForm4.validityPeriodStart}
                          margin="dense"
                          value={form4.values.validityPeriodStart ? moment(form4.values.validityPeriodStart) : null}
                          onChange={(value) => {
                            form4.setValues({
                              ...form4.values,
                              validityPeriodStart: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
                            });
                            // if (value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString()?.localeCompare(form4.values.validityPeriodEnd ?? "") === 1) {
                            //   form4.updateValues('validityPeriodEnd', "");
                            // }
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                        {' '}
                        {form4.values.validityPeriodStart?.length !== 20 && form4.values.validityPeriodStart?.localeCompare(form4.values.validityPeriodEnd ?? "") === 1 ? form4.values.validityPeriodEnd = "" : ""}
                        <KeyboardDatePicker autoOk={true}
                          className={classes.datePicker}
                          // InputProps={InputGroup.leftItem()}
                          //disableToolbar
                          variant="inline"
                          inputVariant="outlined"
                          format={DISPLAY_DATE_FORMAT}
                          label={langForm4.validityPeriodEnd}
                          margin="dense"
                          shouldDisableDate={disablePrevDates(form4.values.validityPeriodStart)}
                          value={form4.values.validityPeriodEnd ? moment(form4.values.validityPeriodEnd) : null}
                          onChange={(value) => {
                            form4.setValues({
                              ...form4.values,
                              validityPeriodEnd: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
                            });
                            // if (value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString()?.localeCompare(form4.values.validityPeriodStart ?? "") === -1) {
                            //   form4.updateValues('validityPeriodStart', "");
                            // }
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                        {langForm4.validityPeriodRemarks}
                      </div>
                      <div>
                        <div style={{ display: 'inline-block', width: '30%' }}>
                          {form4.hasError('validityPeriodStart') ? <FormHelperText style={{ color: form4.hasError('validityPeriodStart') ? errorColor : 'inherit' }}>{form4.validationErrors?.validityPeriodStart}</FormHelperText> : null}
                        </div>
                        <div style={{ display: 'inline-block', width: '30%' }}>
                          {form4.hasError('validityPeriodEnd') ? <FormHelperText style={{ color: form4.hasError('validityPeriodEnd') ? errorColor : 'inherit' }}>{form4.validationErrors?.validityPeriodEnd}</FormHelperText> : null}
                        </div>

                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>


            {/*------------- 5. Disclosure of Interest by Agent------------ */}
            <Grid
              item
              md={12}
              xs={12}
            >
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="part 5 table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.column0} data-key={'field-form4-interestsInProperty'}>5.</TableCell>
                      <TableCell align="left" colSpan={2} >{langForm4.titlePart5}</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell colSpan={2}>
                        <FormControl component="fieldset" style={{ paddingBottom: '4px' }}>
                          <FormGroup aria-label="position" row>
                            <FormControlLabel
                              value="end"
                              control={<Checkbox color="primary" />}
                              label={langForm4.disclosureOfInterestByAgent}
                              labelPlacement="end"
                              checked={form4.values.needDisclosure}
                              onChange={() => {
                                if (form4.values.needDisclosure) {
                                  form4.updateValues('interestsInProperty', undefined);
                                }
                                form4.updateValues('needDisclosure', !form4.values.needDisclosure);
                              }}
                            />
                          </FormGroup>
                        </FormControl>

                        <TextareaAutosize
                          className={classes.textArea}
                          rowsMin={2}
                          rowsMax={2}
                          placeholder={langForm4.helperTextParticulars}
                          disabled={form4.values.needDisclosure === false}
                          {...bind('interestsInProperty')}
                          onChange={(e) => {
                            limitTextFieldLength(e, 255, 'interestsInProperty', form4, keyBooleanMap, setKeyBooleanMap);
                          }}
                        />
                        {form4.hasError('interestsInProperty') ? <FormHelperText style={{ color: form4.hasError('interestsInProperty') ? errorColor : 'inherit' }}>{langForm4.msgInputInterestsInProperty}</FormHelperText> : null}
                        <HandleFormHelperText
                          isError={keyBooleanMap.get('interestsInProperty')}
                          errorMessage={lang.textOverLimit}
                        />
                      </TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>


            {/*----------------------- 9. Extra Terms ------------------------ */}
            <Grid
              item
              md={12}
              xs={12}
            >
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="part 13 table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.column0}>9.</TableCell>
                      <TableCell align="left" colSpan={2} >{langForm4.titlePart9}</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>

                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell colSpan={2}>
                        <Typography style={{ paddingBottom: '4px' }}> {langForm4.extraTermsCautions}</Typography>
                        <TextareaAutosize
                          className={classes.textArea}
                          rowsMin={2}
                          rowsMax={2}
                          placeholder={langForm4.helperTextParticulars}
                          {...bind('extraTerms')}
                          onChange={(e) => {
                            limitTextFieldLength(e, 255, 'extraTerms', form4, keyBooleanMap, setKeyBooleanMap);
                          }}
                        />
                        <HandleFormHelperText
                          isError={keyBooleanMap.get('extraTerms')}
                          errorMessage={lang.textOverLimit}
                        />
                      </TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
              </TableContainer>

            </Grid>

          </Grid>



        </Grid>



        {/* ---------------- info of signatory ------------------ */}
        <Grid item container xs={12} spacing={2}>

          {/* ---------purchaser's info ----------- */}
          <Grid
            item
            md={6}
            xs={12}
          >
            <TableContainer component={Paper}>
              <Table size="small" aria-label="vendor info table">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2} align="left" >{langForm4.purchaserInfo}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* ------------------------ ID no------------------------- */}
                  <TableRow style={{ display: 'none' }}>
                    <TableCell component="th" style={{ width: '35%' }} scope="row">
                      <Typography>{langForm4.HKID}</Typography>
                      <Typography>{langForm4.ifApplicable}</Typography>
                      {form4.hasError('eitherSignatoryInfoOrHKID') ? <FormHelperText style={{ color: form4.hasError('eitherSignatoryInfoOrHKID') ? errorColor : 'inherit' }}>{langForm4.msgInputSignatoryInfoOrHKID}</FormHelperText> : null}
                    </TableCell>

                    <TableCell align="left">
                      <TextField
                        className={classes.infoPartTextField}
                        margin="dense"
                        variant="outlined"
                        // {...bind('purchaserHKID')}
                        value={form4.values.purchaserHKID ?? ''}
                        onChange={(e) => {
                          form4.updateValues('purchaserHKID', e.target.value);
                          form4.updateValues('nameOfSignatoryOfPurchaser', undefined);
                          form4.updateValues('positionOfSignatoryOfPurchaser', undefined);
                          form4.updateValues('businessRegistrationCertificateNumberOfPurchaser', undefined);
                        }}
                      />
                    </TableCell>
                  </TableRow>

                  {/* -------------- if purchaser is a company------------------------- */}
                  <TableRow>
                    <TableCell colSpan={2} className={classes.subTableCell}>
                      <Typography>{langForm4.wherePurchaserIsCompany}</Typography>
                    </TableCell>

                  </TableRow>
                  {/* ----------- name ---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row" className={classes.subTableCell}>
                      <Typography className={classes.companyInfo}>{langForm4.nameOfSignatoryOfPurchaser}</Typography>
                    </TableCell>

                    <TableCell align="left" className={classes.subTableCell}>
                      <TextField
                        className={classes.infoPartTextField}
                        margin="dense"
                        variant="outlined"
                        // {...bind('nameOfSignatoryOfPurchaser')}
                        error={!!form4.hasError('nameOfSignatoryOfPurchaser')}
                        helperText={form4.hasError('nameOfSignatoryOfPurchaser')}
                        value={form4.values.nameOfSignatoryOfPurchaser ?? ''}
                        onChange={(e) => {
                          if (keyBooleanMap && setKeyBooleanMap) {
                            setKeyBooleanMap(keyBooleanMap.set('nameOfSignatoryOfPurchaser', e.target.value.length > 255))
                          }
                          if (e.target.value.length > 255) {
                            e.preventDefault();
                            form4.bind('nameOfSignatoryOfPurchaser').onChange({ target: { value: e.target.value.substring(0, 255) } });
                          } else {
                            form4.bind('nameOfSignatoryOfPurchaser').onChange(e);
                            form4.updateValues('purchaserHKID', undefined);
                          }
                        }}
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('nameOfSignatoryOfPurchaser')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>
                  {/* ----------- position ---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row" className={classes.subTableCell}>
                      <Typography className={classes.companyInfo}>{langForm4.positionOfSignatoryOfPurchaser}</Typography>
                    </TableCell>

                    <TableCell align="left" className={classes.subTableCell}>
                      <TextField
                        className={classes.infoPartTextField}
                        margin="dense"
                        variant="outlined"
                        // {...bind('positionOfSignatoryOfPurchaser')}
                        error={!!form4.hasError('positionOfSignatoryOfPurchaser')}
                        helperText={form4.hasError('positionOfSignatoryOfPurchaser')}
                        value={form4.values.positionOfSignatoryOfPurchaser ?? ''}
                        onChange={(e) => {
                          if (keyBooleanMap && setKeyBooleanMap) {
                            setKeyBooleanMap(keyBooleanMap.set('positionOfSignatoryOfPurchaser', e.target.value.length > 255))
                          }
                          if (e.target.value.length > 255) {
                            e.preventDefault();
                            form4.bind('positionOfSignatoryOfPurchaser').onChange({ target: { value: e.target.value.substring(0, 255) } });
                          } else {
                            form4.bind('positionOfSignatoryOfPurchaser').onChange(e);
                            form4.updateValues('purchaserHKID', undefined);
                          }
                        }}
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('positionOfSignatoryOfPurchaser')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>
                  {/* ----------- business registration certificate number ---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography className={classes.companyInfo}>{langForm4.businessRegistrationCertificateNumberOfPurchaser}</Typography>
                    </TableCell>

                    <TableCell align="left">
                      <TextField
                        className={classes.infoPartTextField}
                        margin="dense"
                        variant="outlined"
                        error={!!form4.hasError('businessRegistrationCertificateNumberOfPurchaser')}
                        helperText={form4.hasError('businessRegistrationCertificateNumberOfPurchaser')}
                        // {...bind('businessRegistrationCertificateNumberOfPurchaser')}
                        value={form4.values.businessRegistrationCertificateNumberOfPurchaser ?? ''}
                        onChange={(e) => {
                          if (keyBooleanMap && setKeyBooleanMap) {
                            setKeyBooleanMap(keyBooleanMap.set('businessRegistrationCertificateNumberOfPurchaser', e.target.value.length > 255))
                          }
                          if (e.target.value.length > 255) {
                            e.preventDefault();
                            form4.bind('businessRegistrationCertificateNumberOfPurchaser').onChange({ target: { value: e.target.value.substring(0, 255) } });
                          } else {
                            form4.bind('businessRegistrationCertificateNumberOfPurchaser').onChange(e);
                            form4.updateValues('purchaserHKID', undefined);
                          }
                        }}
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('businessRegistrationCertificateNumberOfPurchaser')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>

                  {/* ----------- address ---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography>{langForm4.address}</Typography>
                    </TableCell>

                    <TableCell align="left">
                      <TextareaAutosize
                        className={classes.textArea}
                        style={{ width: '100%', }}
                        rowsMin={4}
                        rowsMax={4}
                        placeholder=''
                        {...bind('purchaserAddress')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'purchaserAddress', form4, keyBooleanMap, setKeyBooleanMap);
                        }}
                      />
                      {form4.hasError('purchaserAddress') ? <FormHelperText style={{ color: form4.hasError('purchaserAddress') ? errorColor : 'inherit' }}>{langForm4.msgInputAddress}</FormHelperText> : null}
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('purchaserAddress')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>

                  {/* ----------- phone number---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography >{langForm4.phoneNumber}</Typography>
                    </TableCell>

                    <TableCell align="left" data-key={'field-form4-purchaserPhoneNumber'} >
                      <TextField
                        className={classes.infoPartTextField}
                        margin="dense"
                        variant="outlined"
                        {...bind('purchaserPhoneNumber')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'purchaserPhoneNumber', form4, keyBooleanMap, setKeyBooleanMap);
                        }}
                        helperText={form4.hasError('purchaserPhoneNumber')}
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('purchaserPhoneNumber')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>

                  {/* ----------- fax number---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography>{langForm4.faxNumber}</Typography>
                    </TableCell>

                    <TableCell align="left">
                      <TextField
                        className={classes.infoPartTextField}
                        margin="dense"
                        variant="outlined"
                        {...bind('purchaserFaxNumber')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'purchaserFaxNumber', form4, keyBooleanMap, setKeyBooleanMap);
                        }}
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('purchaserFaxNumber')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>

                  {/* ----------- date --------- */}
                  {/* <TableRow>
                      <TableCell component="th"  scope="row">
                          <Typography>{langForm4.date}</Typography>
                      </TableCell>

                      <TableCell  align="left">
                        <KeyboardDatePicker autoOk={true}
                           className={classes.infoPartTextField}
                          // InputProps={InputGroup.leftItem()}
                          //disableToolbar
                          variant="inline"
                          inputVariant="outlined"
                          format={DISPLAY_DATE_FORMAT}
                          margin="dense"
                          value={form4.values.purchaserDate ?  moment(form4.values.purchaserDate) : null}
                          onChange={(value) => {
                              form4.setValues({
                              ...form4.values,
                              purchaserDate: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
                              });
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          /> 
                          {form4.hasError('purchaserDate') ? <FormHelperText style={{color: form4.hasError('purchaserDate') ?errorColor : 'inherit'}}>{langForm4.msgInputDate}</FormHelperText> : null}
                      </TableCell>
                    </TableRow> */}

                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          {/* ---------agents's info ----------- */}
          <Grid
            item
            md={6}
            xs={12}
          >

            <TableContainer component={Paper}>
              <Table size="small" aria-label="agent info table">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2} align="left" >{langForm4.agentInfo}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  {/* ----------- Name and licence number of the Agent---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography >{langForm4.nameAndLicenceNumberOfAgent}</Typography>
                    </TableCell>

                    <TableCell align="left">
                      <TextField
                        className={classes.infoPartTextField}
                        margin="dense"
                        variant="outlined"
                        {...bind('nameAndLicenceNumberOfAgent')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'nameAndLicenceNumberOfAgent', form4, keyBooleanMap, setKeyBooleanMap);
                        }}
                        helperText={form4.hasError('nameAndLicenceNumberOfAgent')}
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('nameAndLicenceNumberOfAgent')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>

                  {/* ----------- Number of Agent’s statement of particulars of business---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography >{langForm4.numberOfStatementOfParticularsOfBusiness}</Typography>
                    </TableCell>

                    <TableCell align="left">
                      <TextField
                        className={classes.infoPartTextField}
                        disabled={true}
                        margin="dense"
                        variant="outlined"
                        {...bind('numberOfStatementOfParticularsOfBusiness')}

                      />
                    </TableCell>
                  </TableRow>

                  {/* ----------- address ---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography>{langForm4.address}</Typography>
                    </TableCell>

                    <TableCell align="left">
                      <TextareaAutosize
                        className={classes.textArea}
                        disabled={true}
                        style={{ width: '100%', }}
                        rowsMin={4}
                        rowsMax={4}
                        placeholder=''
                        {...bind('agentAddress')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'agentAddress', form4, keyBooleanMap, setKeyBooleanMap);
                        }}
                      />
                      {form4.hasError('agentAddress') ? <FormHelperText style={{ color: form4.hasError('agentAddress') ? errorColor : 'inherit' }}>{langForm4.msgInputAddress}</FormHelperText> : null}
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('agentAddress')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>

                  {/* ----------- phone number---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography >{langForm4.phoneNumber}</Typography>
                    </TableCell>

                    <TableCell align="left">
                      <TextField
                        className={classes.infoPartTextField}
                        disabled={true}
                        margin="dense"
                        variant="outlined"
                        {...bind('agentPhoneNumber')}
                      />
                    </TableCell>
                  </TableRow>

                  {/* ----------- fax number---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography>{langForm4.faxNumber}</Typography>
                    </TableCell>

                    <TableCell align="left">
                      <TextField
                        className={classes.infoPartTextField}
                        disabled={true}
                        margin="dense"
                        variant="outlined"
                        {...bind('agentFaxNumber')}
                      />
                    </TableCell>
                  </TableRow>

                  {/* ----------- date --------- */}
                  {/* <TableRow>
                      <TableCell component="th"  scope="row">
                          <Typography>{langForm4.date}</Typography>
                      </TableCell>

                      <TableCell  align="left">
                        <KeyboardDatePicker autoOk={true}
                           className={classes.infoPartTextField}
                          // InputProps={InputGroup.leftItem()}
                          //disableToolbar
                          variant="inline"
                          inputVariant="outlined"
                          format={DISPLAY_DATE_FORMAT} 
                          margin="dense"
                          value={form4.values.agentDate ?  moment(form4.values.agentDate) : null}
                          onChange={(value) => {
                              form4.setValues({
                              ...form4.values,
                              agentDate: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
                              });
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          /> 
                          {form4.hasError('agentDate') ? <FormHelperText style={{color: form4.hasError('agentDate') ?errorColor : 'inherit'}}>{langForm4.msgInputDate}</FormHelperText> : null}

                      </TableCell>
                    </TableRow> */}



                </TableBody>
              </Table>
            </TableContainer>

          </Grid>

          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>

        </Grid>

        {/* ---------------- Schedule 1 ------------------ */}
        <Grid item container xs={12} spacing={2}>
          <Grid
            item
            md={12}
            xs={12}
          >
            <Typography style={{ fontWeight: 'bold' }} data-key={'field-form4-invalidFormProperty'}>{langForm4.titleSchedule1}</Typography>
            {schedule1HasError ? <FormHelperText style={{ color: schedule1HasError ? errorColor : 'inherit' }}>{langForm4.msgInputSchedul1Info}</FormHelperText> : null}
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="schedule 1 table">
                <TableHead>
                  <TableRow>
                    {/* <TableCell className={classes.column0}></TableCell> */}
                    <TableCell className={classes.s1Column1} align="center" style={{ color: schedule1HasError ? errorColor : 'inherit' }}>{langForm4.column1}</TableCell>
                    <TableCell className={classes.s1Column2} align="center" style={{ color: schedule1HasError ? errorColor : 'inherit' }}>{langForm4.column2}
                      {schedule1HasError ? <div><FormHelperText style={{ color: schedule1HasError ? errorColor : 'inherit' }}>{langForm4.msgInputDateToInspect}</FormHelperText></div> : null}
                    </TableCell>
                    <TableCell className={classes.s1Column3} align="center">{langForm4.column3}</TableCell>
                    <TableCell className={classes.s1Column4} align="center">{langForm4.column4}</TableCell>
                    <TableCell className={classes.s1Column5} align="center" style={{ color: schedule1HasError ? errorColor : 'inherit' }}>{langForm4.column5}</TableCell>
                    <TableCell className={classes.s1Column6} align="center">{langForm4.column6}</TableCell>
                    <TableCell className={classes.s1Column7} align="center" style={{ color: schedule1HasError ? errorColor : 'inherit' }}>{langForm4.column7}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {schedule1Table.map((detail: Form4PropertyDTO, i: number) => (
                    <TableRow key={i}>
                      {/* <TableCell></TableCell> */}
                      {/* -------- property --------- */}
                      <TableCell padding='none'>
                        <div className={classes.s1Cell}>
                          <TextareaAutosize
                            className={classes.textArea}
                            style={{ width: '100%' }}
                            rowsMin={2}
                            rowsMax={2}
                            value={detail.property}
                            onChange={(e) => {
                              if (keyBooleanMap && setKeyBooleanMap) {
                                setKeyBooleanMap(keyBooleanMap.set('formProperty', e.target.value.length > 255))
                              }
                              if (e.target.value.length > 255) {
                                e.preventDefault();
                                const updatedDetail = { ...detail, property: e.target.value.substring(0, 255) }
                                form4Schedule1.updateValues('formProperty',
                                  form4Schedule1.values.formProperty?.map((c: Object, idx: number) => idx === i ? updatedDetail : c)
                                );
                              } else {
                                const updatedDetail = { ...detail, property: e.target.value }
                                form4Schedule1.updateValues('formProperty',
                                  form4Schedule1.values.formProperty?.map((c: Object, idx: number) => idx === i ? updatedDetail : c)
                                );
                              }
                            }}
                          />
                          {schedule1HasError && !isNonEmpty(detail.property) ? <FormHelperText style={{ color: schedule1HasError && !isNonEmpty(detail.property) ? errorColor : 'inherit' }}>{langForm4.pleaseInput}</FormHelperText> : null}
                          <HandleFormHelperText
                            isError={keyBooleanMap.get('formProperty')}
                            errorMessage={lang.textOverLimit}
                          />
                        </div>
                      </TableCell>

                      {/* -------- dateOfInspection --------- */}
                      <TableCell padding='none'>
                        <div className={classes.s1Cell}>
                          <KeyboardDatePicker autoOk={true}
                            className={classes.datePicker}
                            style={{ width: '100%' }}
                            //disableToolbar
                            variant="inline"
                            inputVariant="outlined"
                            format={DISPLAY_DATE_FORMAT}
                            error={schedule1HasError && detail.notToInspect !== true && !isNonEmpty(detail.dateOfInspection)}
                            helperText={schedule1HasError && detail.notToInspect !== true && !isNonEmpty(detail.dateOfInspection) ? langForm4.pleaseSelect : ''}
                            margin="dense"
                            value={detail.dateOfInspection ? moment(detail.dateOfInspection) : null}
                            onChange={(value) => {
                              const updatedDetail = { ...detail, dateOfInspection: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined, notToInspect: isNonEmpty(value) ? false : detail.notToInspect }
                              form4Schedule1.updateValues('formProperty',
                                form4Schedule1.values.formProperty?.map((c: Object, idx: number) => idx === i ? updatedDetail : c)
                              );
                            }}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </div>
                      </TableCell>

                      {/* -------- not to inspect --------- */}
                      <TableCell align="center">
                        <Checkbox
                          checked={detail.notToInspect === true}
                          onChange={() => {
                            //update checkbox
                            const updatedDetail = { ...detail, notToInspect: !detail.notToInspect, dateOfInspection: detail.notToInspect === false ? undefined : detail.dateOfInspection }
                            form4Schedule1.updateValues('formProperty',
                              form4Schedule1.values.formProperty?.map((c: Object, idx: number) => idx === i ? updatedDetail : c)
                            );
                          }}
                        />
                      </TableCell>

                      {/* -------- waiveRight --------- */}
                      <TableCell align="center">
                        <Checkbox
                          checked={detail.waiveRight === true}
                          onChange={() => {
                            const updatedDetail = { ...detail, waiveRight: !detail.waiveRight }
                            form4Schedule1.updateValues('formProperty',
                              form4Schedule1.values.formProperty?.map((c: Object, idx: number) => idx === i ? updatedDetail : c)
                            );
                          }}
                        />
                      </TableCell>

                      {/* -------- agencyRelationShip --------- */}
                      <TableCell padding='none'>
                        <div className={classes.s1Cell}>
                          <TextField
                            className={classes.textField}
                            style={{ width: '100%' }}
                            margin="dense"
                            variant="outlined"
                            select
                            value={detail.agencyRelationship}
                            error={schedule1HasError && !isNonEmpty(detail.agencyRelationship)}
                            helperText={schedule1HasError && !isNonEmpty(detail.agencyRelationship) ? langForm4.pleaseSelect : ''}
                            onChange={(e) => {
                              const updatedDetail = { ...detail, agencyRelationship: e.target.value }
                              form4Schedule1.updateValues('formProperty',
                                form4Schedule1.values.formProperty?.map((c: Object, idx: number) => idx === i ? updatedDetail : c)
                              );
                            }}
                          >
                            <MenuItem key='singleAgency' value='singleAgency'>{langForm4.singleAgency}</MenuItem>
                            <MenuItem key='dualAgency' value='dualAgency'>{langForm4.dualAgency}</MenuItem>
                            <MenuItem key='potentiallyDualAgency' value='potentiallyDualAgency'>{langForm4.potentiallyDualAgency}</MenuItem>
                            <MenuItem key='' value='' style={{ display: 'none' }}>{''}</MenuItem>

                          </TextField>
                        </div>
                      </TableCell>

                      {/* -------- vendorCommission--------- */}
                      {/* {CommissionUnit(detail, classes, form4Schedule1, i, 'vendor')} */}
                      <CommissionUnit detail={detail} classes={classes} form4Schedule1={form4Schedule1} i={i} type={'vendor'} schedule1HasError={schedule1HasError} langForm4={langForm4} />
                      {/* <TableCell padding='none'>
                        <div className={classes.s1Cell}>
                          <NumberField
                            className={classes.textField}
                            style={{ width: '100%', textAlignLast: 'end' }}
                            margin="dense"
                            variant="outlined"
                            value={parseFloat(detail.vendorCommission?.replace('%', '') ?? '')}
                            onChange={(e) => {
                              const updatedDetail = { ...detail, vendorCommission: e.target.value.replace('%', '') + vendorCommissionUnit }
                              form4Schedule1.updateValues('formProperty',
                                form4Schedule1.values.formProperty?.map((c: Object, idx: number) => idx === i ? updatedDetail : c)
                              );
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <Select
                                    value={vendorCommissionUnit}
                                    onChange={handleVendorCommissionUnitChange}
                                  >
                                    <MenuItem key='vendorCommision$' value='$'>$</MenuItem>
                                    <MenuItem key='vendorCommision%' value='%'>%</MenuItem>
                                  </Select>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      </TableCell> */}

                      {/* -------- purchaserCommission --------- */}
                      <CommissionUnit detail={detail} classes={classes} form4Schedule1={form4Schedule1} i={i} type={'purchaser'} schedule1HasError={schedule1HasError} langForm4={langForm4} />
                      {/* <TableCell padding='none'>
                        <div className={classes.s1Cell}>

                          <NumberField
                            className={classes.textField}
                            style={{ width: '100%', textAlignLast: 'end' }}
                            margin="dense"
                            variant="outlined"
                            value={parseFloat(detail.purchaserCommission?.replace('%', '') ?? '')}
                            error={schedule1HasError && !isNonEmpty(detail.purchaserCommission)}
                            helperText={schedule1HasError && !isNonEmpty(detail.purchaserCommission) ? langForm4.pleaseInput : ''}
                            onChange={(e) => {
                              const updatedDetail = { ...detail, purchaserCommission: e.target.value.replace('%', '') + purchaserCommissionUnit }
                              form4Schedule1.updateValues('formProperty',
                                form4Schedule1.values.formProperty?.map((c: Object, idx: number) => idx === i ? updatedDetail : c)
                              );
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <Select
                                    value={'$'}
                                  >
                                    <MenuItem key='purchaserdorCommision$' value='$'>$</MenuItem>
                                    <MenuItem key='purchaserCommision%' value='%'>%</MenuItem>
                                  </Select>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

          </Grid>


          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>
        </Grid>

        {/* ---------------- Schedule 2 ------------------ */}
        <Grid item container xs={12} spacing={2}>

          <Grid
            item
            md={12}
            xs={12}
          >
            <Typography style={{ fontWeight: 'bold' }} >{langForm4.titleSchedule2}</Typography>
          </Grid>

          {/*------------- Commission ------------ */}
          <Grid
            item
            md={12}
            xs={12}
          >
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="schedule 2 table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.column0}>1.</TableCell>
                    <TableCell align="left" colSpan={2} >{langForm4.titleCommission}</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {/*----------- payment time ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell className={classes.column1} >{langForm4.paymentTime}</TableCell>
                    <TableCell>
                      <RadioGroup defaultValue="" aria-label="payment time" name="customized-radios" row
                        value={form4.values.paymentTimeOption ?? ''}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          const val = (event.target as HTMLInputElement).value;
                          form4.updateValues('paymentTimeOption', val);
                        }}
                      >
                        <FormControlLabel value="option1" control={<Radio />} label={langForm4.paymentTimeOption1} />
                        <FormControlLabel value="option2" control={<Radio />} label={langForm4.paymentTimeOption2} />
                        <FormControlLabel value="" control={<Radio size="small" />} label='' style={{ display: 'none' }} />
                      </RadioGroup>
                      {/* {form4.hasError('paymentTimeOption') ? <FormHelperText style={{color: form4.hasError('paymentTimeOption') ?errorColor : 'inherit'}}>{langForm4.msgSelectPaymentTime}</FormHelperText> : null} */}
                    </TableCell>
                  </TableRow>


                </TableBody>
              </Table>
            </TableContainer>

            {/* ------------------------ Schedule 2 point 2 ---------------- */}
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="schedule 2 point 2 table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.column0}>2.</TableCell>
                    <TableCell align="left" >{langForm4.titleSchedulePoint2}</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell>
                      <FormControl component="fieldset" style={{ paddingLeft: '10px', paddingTop: '7px' }}>
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            control={<Checkbox color="primary" />}
                            label={langForm4.withInterest}
                            labelPlacement="end"
                            checked={form4.values.withInterest}
                            onChange={() => {
                              form4.updateValues('withInterest', !form4.values.withInterest);
                            }}
                          />
                        </FormGroup>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

          </Grid>

          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>

        </Grid>

      </CardContent>

      <CardActions style={{ justifyContent: 'flex-end', display: 'none' }}>
        <Button variant="contained" color="primary">生成PDF檔案</Button>
      </CardActions>
    </Card>
  )
};

export default Form4Dialog;
