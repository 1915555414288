import { isNonEmpty } from "common/utils";

const ITEM_NAME = process.env['PUBLIC_URL'] + '/state';

export const loadState = () => {

        if(isNonEmpty(localStorage.getItem(ITEM_NAME))){
        sessionStorage.setItem(ITEM_NAME,localStorage.getItem(ITEM_NAME)!);
        };

        const serializedState = sessionStorage.getItem(ITEM_NAME); 
    try {
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
  };

export const saveState = (state: any) => {
        
        
    try {
        const serializedState = JSON.stringify(state);
        sessionStorage.setItem(ITEM_NAME, serializedState);
        localStorage.setItem(ITEM_NAME,sessionStorage.getItem(ITEM_NAME)!);
    } catch {
        // ignore write errors
    }
};

