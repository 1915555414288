import { Button, Card, CardContent, CardHeader, ClickAwayListener, colors, Divider, makeStyles, Typography, useTheme, Zoom } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DehazeIcon from '@material-ui/icons/Dehaze';
import SettingsIcon from '@material-ui/icons/Settings';
import { hasPermission } from 'common/access-control';
import { ActivityAuditingIcon, AgentCommentIcon, BuildingIcon, ClientAppSettingIcon, ClientDetailIcon, CornJobIcon, HomePageFeaturedTagsIcon, MemoIcon, PropertyDetailIcon, PropertyHistoryIcon, ReportIcon, RoleMgtIcon, SystemSettingIcon, tooltipHoc } from 'common/ui';
import { isNonEmpty } from 'common/utils';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router';
import { IRootState, PASDispatch } from 'reducers';


const PUBLIC_URL = process.env['PUBLIC_URL'];

const useStyles = makeStyles(theme => ({
  iconBarItem: {
    textTransform: 'none',

    margin: theme.spacing(1),
    // width: theme.spacing(12),
    // height: theme.spacing(12),
    // width: theme.spacing(20),
    // height: theme.spacing(20),
    // background: theme.palette.grey[100],
    background: theme.palette.common.white,
    '& .icon': {
      fontSize: '2.5rem',
      color: theme.palette.text.secondary,
    },
    '& .label': {
      color: theme.palette.text.secondary
    }
  },
  icon: {
    color: theme.palette.primary.main,
  },
  fabMenu: {
    display: 'flex', flexDirection: 'row',
    flexWrap: 'wrap',
    flexShrink: 1,
    justifyContent: 'center',
  },
  menuCard: ({ qPosition }: any) => ({
    position: 'fixed',
    maxWidth: theme.spacing(3 * 12 + 3 * 2 + 4),
    height: 'auto',
    zIndex: theme.zIndex.modal,

    right: (qPosition === 'bottom-right' ? theme.spacing(3) : undefined),
    bottom: (qPosition === 'bottom-right' ? theme.spacing(11) : undefined),
    top: (qPosition === 'top-left' ? theme.spacing(11) : undefined),
    left: (qPosition === 'top-left' ? theme.spacing(3) : undefined),

    boxShadow: theme.shadows[15],
  } as const),

  
}));

interface QuickAccessButtonProps {
  icon: React.ComponentType<any>;
  label: string;
  to?: string;
  onClick?: () => void;
  dashboardVariant?: boolean;
  disabled?: boolean;
}

const QuickAccessButton = tooltipHoc((props: QuickAccessButtonProps) => {
  const theme = useTheme();
  const history = useHistory();
  
  const classes = useStyles({ });
  const { icon: Icon, label } = props;
  const onClick = (ev: React.MouseEvent) => {
    ev.preventDefault();
    if (props.to) {
      history.push(props.to);
    }
    props.onClick && props.onClick();
  };

  return <Button disabled={props.disabled} className={classes.iconBarItem} 
    variant={props.dashboardVariant ? 'contained' : undefined}
    color={'primary'} onClick={onClick}
    {...{ component: 'a', href: props.to ? `${PUBLIC_URL}${props.to}` : undefined, target: '_blank' } as any}
    style={{
      width: props.dashboardVariant ? theme.spacing(20) : theme.spacing(12),
      height: props.dashboardVariant ? theme.spacing(20) : theme.spacing(12),
    }}>
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Icon
        style={{ color: props.disabled ? colors.grey[500] : 'default' }}
        className="icon"
        fontSize={props.dashboardVariant ? '2.5rem' as any : 'large'} /> 
      {/* fontSize="large" */}
      <Typography style={{ color: props.disabled ? colors.grey[500] : 'default', textAlign: 'center' }} className='label'>{label}</Typography>
    </div>
    {/* {label} */}
  </Button>
});


export const RootQuickAccessBar = ({ onDismissParent, navigate }: any) => {
  const dispatch = useDispatch() as PASDispatch;
  const classes = useStyles();
  const { lang } = useSelector((state: IRootState) => state.locale);

  const { privileges } = useSelector((state: IRootState) => state.login);
  const canReadBuilding = hasPermission(privileges, 'READ', 'BUILDING');
  const canReadClient = hasPermission(privileges, 'READ', 'CLIENT');
  const canReadPropertyStock = hasPermission(privileges, 'READ', 'PROPERTY_STOCK');
  const canReadTransaction = hasPermission(privileges, 'READ', 'TRANSACTION') || hasPermission(privileges, 'READ', 'TRANSACTION_RESTRICTED');
  const canReadCommissionReport = hasPermission(privileges, 'READ', 'SALES_COMMISSION_REPORT');

  const canAccessOptionEditor = hasPermission(privileges, 'READ', 'OPTION');
  const canAccessUserMgt = hasPermission(privileges, 'READ', 'USER');
  const canAccessAuditing = hasPermission(privileges, 'READ', 'AGENT_ACTIVITY_ENQUIRY');
  const canAccessRoleMgt= hasPermission(privileges, 'READ', 'ROLE');
  const canAccessSystemSetting= hasPermission(privileges, 'READ', 'SYSTEM_SETTING');
  const canAccessSetting = canAccessOptionEditor || canAccessUserMgt || canAccessAuditing || canAccessRoleMgt || canAccessSystemSetting;

  const canAccessFeaturedTags = hasPermission(privileges, 'READ', 'CLIENT_APP_FEATURED_TAGS');
  const canAccessAgentRatingComment = hasPermission(privileges, 'READ', 'CLIENT_APP_AGENT_RATING_COMMENT');
  const canAccessClientAppSetting = canAccessFeaturedTags || canAccessAgentRatingComment;
  const canAccessMemoEnquiry = hasPermission(privileges, 'READ', 'MEMO_ENQUIRY');

  const propertyMatching = useSelector((state: IRootState) => state.propertyMatching);

  return <div className={classes.fabMenu}>
    <div>
      <QuickAccessButton onClick={onDismissParent} to="/dashboard" icon={DashboardIcon} label={lang.navDashboard} />
    </div>
    <div style={{ display: canReadPropertyStock ? 'block' : 'none' }}>
      <QuickAccessButton disabled={!canReadPropertyStock} 
        onClick={() => {
          if (isNonEmpty(propertyMatching?.cid)){
            dispatch({ type: 'PropertyMatching.StopMatching' });
            dispatch({ type: 'PropertyList.ForceCancelPropertyMatching', payload: true });
          }
          onDismissParent();
        }} 
        to="/properties" icon={PropertyDetailIcon} label={lang.navPropertyStockSearch} />
    </div>
    <div style={{ display: canReadTransaction ? 'block' : 'none' }}>
      <QuickAccessButton disabled={!canReadTransaction} onClick={onDismissParent} to="/transactions" icon={PropertyHistoryIcon} label={lang.navTransaction} />
    </div>
    <div style={{ display: canReadClient ? 'block' : 'none' }}>
      <QuickAccessButton disabled={!canReadClient} onClick={onDismissParent} to="/client-detail" icon={ClientDetailIcon} label={lang.navClient} />
    </div>
    {/* <div>
      <QuickAccessButton onClick={onDismissParent} to="/client-detail/new" icon={ClientAddIcon} label="新增客戶" />
    </div> */}
    <div style={{ display: canReadBuilding ? 'block' : 'none' }}>
      <QuickAccessButton disabled={!canReadBuilding} onClick={onDismissParent} to="/buildings" icon={BuildingIcon} label={lang.navBuilding} />
    </div>
    <div style={{ display: canAccessMemoEnquiry ? 'block' : 'none' }}>
      <QuickAccessButton disabled={!canAccessMemoEnquiry} onClick={onDismissParent} to="/memo-enquiry" icon={MemoIcon} label={lang.navMemoEnquiry} />
    </div>
    <div style={{ display: canReadCommissionReport ? 'block' : 'none' }}>
      <QuickAccessButton disabled={!canReadCommissionReport} onClick={onDismissParent} to="/commission-report" icon={ReportIcon} label={lang.navCommissionReport} />
    </div>
    <div style={{ display: canAccessSetting ? 'block' : 'none' }}>
      <QuickAccessButton disabled={!canAccessSetting} onClick={() => navigate('setting')} icon={SettingsIcon} label={lang.navSystemSetting} />
    </div>
    <div style={{ display: canAccessClientAppSetting ? 'block' : 'none' }}>
      <QuickAccessButton disabled={!canAccessClientAppSetting} onClick={() => navigate('clientAppSetting')} icon={ClientAppSettingIcon} label={lang.navClientAppSetting} />
    </div>
  </div>
};

export const SettingQuickAccessBar = ({ onDismissParent }: any) => {
  const classes = useStyles();
  const { lang } = useSelector((state: IRootState) => state.locale);
  const { privileges } = useSelector((state: IRootState) => state.login);

  const canAccessOptionEditor = hasPermission(privileges, 'READ', 'OPTION');
  const canAccessJobMonitor = hasPermission(privileges, 'READ', 'JOB_MONITOR');
  const canAccessUserMgt = hasPermission(privileges, 'READ', 'USER');
  const canAccessAuditing = hasPermission(privileges, 'READ', 'AGENT_ACTIVITY_ENQUIRY');
  const canAccessRoleMgt = hasPermission(privileges, 'READ', 'ROLE');
  const canAccessSystemSetting = hasPermission(privileges, 'READ', 'SYSTEM_SETTING');

  return <div className={classes.fabMenu}>
    <div style={{ display: canAccessUserMgt ? 'block' : 'none' }}>
      <QuickAccessButton disabled={!canAccessUserMgt} onClick={onDismissParent} to="/users" icon={ClientDetailIcon} label={lang.navUserMgt} />
    </div>

    <div style={{ display: canAccessRoleMgt ? 'block' : 'none' }}>
      <QuickAccessButton disabled={!canAccessRoleMgt} onClick={onDismissParent} to="/roles" icon={RoleMgtIcon} label={lang.navRoleMgt} />
    </div>

    <div style={{ display: canAccessAuditing ? 'block' : 'none' }}>
      <QuickAccessButton disabled={!canAccessAuditing} onClick={onDismissParent} to="/activity-auditing" icon={ActivityAuditingIcon} label={lang.navActivityAuditing} />
    </div>

    <div style={{ display: canAccessSystemSetting ? 'block' : 'none' }}>
      <QuickAccessButton disabled={!canAccessSystemSetting} onClick={onDismissParent} to="/system-setting" icon={SystemSettingIcon} label={lang.navSystemSettingListPage} />
    </div>

    <div style={{ display: canAccessOptionEditor ? 'block' : 'none' }}>
      <QuickAccessButton disabled={!canAccessOptionEditor} onClick={onDismissParent} to="/option-editor" icon={DehazeIcon} label={lang.navOptionEditor} />
    </div>

    <div style={{ display: canAccessJobMonitor ? 'block' : 'none' }}>
      <QuickAccessButton disabled={!canAccessJobMonitor} onClick={onDismissParent} to="/jobs" icon={CornJobIcon} label={lang.navJobMonitor} />
    </div>
  </div>
};


export const ClientAppSettingQuickAccessBar = ({ onDismissParent }: any) => {
  const classes = useStyles();
  const { lang } = useSelector((state: IRootState) => state.locale);
  const { privileges } = useSelector((state: IRootState) => state.login);

  const canAccessFeaturedTags = hasPermission(privileges, 'READ', 'CLIENT_APP_FEATURED_TAGS');
  const canAccessAgentRatingComment = hasPermission(privileges, 'READ', 'CLIENT_APP_AGENT_RATING_COMMENT');

  return <div className={classes.fabMenu}>
    <div style={{ display: canAccessFeaturedTags ? 'block' : 'none' }}>
      <QuickAccessButton disabled={!canAccessFeaturedTags} onClick={onDismissParent} to="/CAFeatureTagMgt" icon={HomePageFeaturedTagsIcon} label={lang.navHomePageFeaturedTags} />
    </div>
    <div style={{ display: canAccessAgentRatingComment ? 'block' : 'none' }}>
      <QuickAccessButton disabled={!canAccessAgentRatingComment} onClick={onDismissParent} to="/CAAgentRatingCommentMgt" icon={AgentCommentIcon} label={lang.navAgentRatingComment} />
    </div>
  </div>
};


export interface QuickAccessDrawerProps {
  open: boolean;
  position?: 'top-left' | 'bottom-right' | undefined;
  clickAwayAvoidanceRef?: React.RefObject<HTMLElement>;
  setOpen?: (open: boolean) => void;
}

const QuickAccessDrawer = (props: QuickAccessDrawerProps) => {
  const classes = useStyles({ qPosition: props.position || 'top-left' });
  const [ view, setView ] = useState('root');
  const { lang } = useSelector((state: IRootState) => state.locale);

  const navigate = (v: string) => setView(v);
  const onDismissParent = () => {
    props.setOpen?.(false);
    navigate('root');
  };

  const views: { [v: string]: JSX.Element } = {
    'root': <RootQuickAccessBar navigate={navigate} onDismissParent={onDismissParent} />,
    'setting': <SettingQuickAccessBar navigate={navigate} onDismissParent={onDismissParent} />,
    'clientAppSetting': <ClientAppSettingQuickAccessBar navigate={navigate} onDismissParent={onDismissParent} />,
  };

  return (
    <ClickAwayListener onClickAway={(event) => {
      const clickAwayAvoidanceNode = props.clickAwayAvoidanceRef?.current;
      if (clickAwayAvoidanceNode && !clickAwayAvoidanceNode.contains(event.target as Node)) {
        props.setOpen?.(false);
        navigate('root');
      }
    }}>
      <Zoom in={props.open} unmountOnExit>
        <Card className={classes.menuCard}>
          {/* <CardHeader title="Mavin" action={<Button variant="outlined" startIcon={<SettingsIcon />}>系統設定</Button>} />
          <Divider /> */}
          { view !== 'root' ?
            <CardHeader title={<Button onClick={() => navigate('root')} variant="outlined" startIcon={<ArrowBackIcon />}>{lang.actionGoBack}</Button>} />
            : null
          }
          {/* <Divider /> */}
          <CardContent style={{ display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center', alignItems: 'center' }} >
            {views[view]}
          </CardContent>
          <Divider />
        </Card>
      </Zoom>
    </ClickAwayListener>
  );
}

export default QuickAccessDrawer;