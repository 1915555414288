import { Page } from 'api';
import {
  ActivityAuditingDTO, BuildingAliasDTO, BuildingDetailDTO, BuildingListItemDTO, CAAgentRatingCommentDTO, CAFeatureTagDTO, ClientDetailDTO,
  ClientListItemDTO,
  ClientSearchDTO, CloseTransactionDTO, CommissionReportListItemDTO, Form1DTO, Form2DTO, Form3DTO, Form4DTO, Form5DTO, Form6DTO, JobDTO, LoginStateDTO, MasterDataDTO,
  MasterDataMap, MemoDTO, MemoEnquiryDTO,
  NotificationDTO,
  NotificationSearchDTO, PropertyDraftUpdateDTO, PropertyListItemDTO,
  PropertySearchDTO, PropertyStockDTO, ResourceDTO,
  RoleDTO, SalePurchaseAgreementDTO,
  TenancyAgreementDTO, TransactionDetailDTO, TransactionListItemDTO, TransactionSearchDTO, UserDTO, WishlistGrantedAgentDTO
} from 'common/dto';
import { FormSigningInfoProps } from 'common/elements/FormSigningInfo';
import { BLANK_RANGE, DEFAULT_FROM, DEFAULT_TO } from 'common/elements/MinMaxField';
import languages from 'common/languages';
import { DiffUpdateSpec, priceToView } from 'common/utils';
import { Dispatch } from 'redux';
import { PropertyStockSearchFormValues } from 'views/PropertyList/PropertyList';
import { MasterData } from './MasterData';

// Composed Interfaces
export const reducerMap = {
  layout: layoutReducer,
  locale: localeReducer,
  login: loginReducer,
  property: propertyReducer,
  transaction: transactionReducer,
  commissionReport: commissionReportReducer,
  closeTransaction: closeTransactionReducer,
  propertyMatching: propertyMatchingReducer,
  // clientDetail: clientDetailReducer,
  masterData: masterDataReducer,
  systemSettings: systemSettingReducer,
  clients: clientMasterReducer,
  building: buildingReducer,
  user: userReducer,
  activityAuditing: activityAuditingReducer,
  memoEnquiry: memoEnquiryReducer,
  notification: notificationReducer,
  clientSign: clientSignReducer,
  resetPassword: ResetPasswordReducer,
  buildingMerge: buildingMergeReducer,
  clientMerge: clientMergeReducer,
  buildingAliasList: buildingAliasListReducer,
  rolePrivilege: rolePrivilegeReducer,
  systemSettingsList: SystemSettingListReducer,
  featureTagList: FeatureTagListReducer,
  dashboard: dashboardReducer,
  agentRatingCommentList: agentRatingCommentMgtReducer,
  jobMonitor: jobMonitorReducer,
};

export type IRootState = StateOfReducer<typeof reducerMap>;


export type AllActions = LocaleActions | LoginActions | LayoutActions |
  PropertyMatchingActions | PropertyActions | TransactionActions | CommissionReportActions | CloseTransactionActions | BuildingActions |
  ClientActions | MasterDataActions | SystemSettingActions | UserActions | ActivityAuditingActions |
  MemoEnquiryActions | NotificationActions | ClientSignActions | ResetPasswordActions | BuildingMergeActions | ClientMergeActions | BuildingAliasListActions |
  RolePrivilegeActions | SystemSettingListActions | DashboardActions | FeatureTagListActions | AgentRatingCommentActions | JobMonitorActions
  ;
export type PASDispatch = Dispatch<AllActions>;
export type GlobalActions = { type: 'Global.ClearCacheRequested', payload: any };

export interface ListAndDetailState<ListItem, Detail> {
  currentList: ListItem[];
  currentPage: number;
  totalPages: number;
  totalElements: number;
  currentMatchingList: ListItem[];
  currentMatchingPage: number;
  totalMatchingPages: number;
  totalMatchingElements: number;
  fetchedDetails: { [id: string]: Detail };
  fetchedDetailsByPropertyStockId: { [id: string]: Detail };
}

export function emptyListAndDetailState<ListItem, Detail>(): ListAndDetailState<ListItem, Detail> {
  return {
    currentList: [],
    currentPage: 0,
    totalPages: 0,
    totalElements: 0,
    currentMatchingList: [],
    currentMatchingPage: 0,
    totalMatchingPages: 0,
    totalMatchingElements: 0,
    fetchedDetails: {},
    fetchedDetailsByPropertyStockId: {},
  }
}

export type LayoutActions = { type: 'Layout.MaskPresentRequested' } |
{ type: 'Layout.MaskDismissRequested' } |
{ type: 'Layout.AlertMessagePresented', payload: { message: string, severity?: string } } |
{ type: 'Layout.AlertMessageAdded', payload: { message: string, severity?: string } } |
{ type: 'Layout.AlertMessageRemoved' } |
{ type: 'Layout.Initialize' } |
{ type: 'Layout.DataReloadRequested' } |
{ type: 'Layout.InitialStatusChanged', payload: { initialized: boolean, initializationFailed?: boolean } } |
{ type: 'Layout.IOSStatusBarUpdated', payload: { height: number } }
  ;

const layoutInitials = {
  maskRefCount: 0,
  initialized: false,
  initializationFailed: false as boolean | undefined,
  // alerts: [] as Array<{ message: string, severity?: string }>,
  alert: null as { message: string, severity?: string } | null,
  iosStatusBarHeight: 0,
};

export function layoutReducer(state = layoutInitials, action: LayoutActions): typeof layoutInitials {
  switch (action.type) {
    case 'Layout.MaskPresentRequested':
      return { ...state, maskRefCount: state.maskRefCount + 1 };
    case 'Layout.MaskDismissRequested':
      return { ...state, maskRefCount: state.maskRefCount - 1 < 0 ? 0 : state.maskRefCount - 1 };
    case 'Layout.AlertMessagePresented':
      return { ...state, alert: action.payload };
    case 'Layout.AlertMessageRemoved':
      return { ...state, alert: null };
    case 'Layout.InitialStatusChanged':
      return {
        ...state,
        initialized: action.payload.initialized,
        initializationFailed: action.payload.initializationFailed,
      };
    case 'Layout.IOSStatusBarUpdated':
      return { ...state, iosStatusBarHeight: action.payload.height };
    default:
      return state;
  }
}


////////////////////////////////////////////////////////////////////
////////////////////////////// Locale //////////////////////////////
////////////////////////////////////////////////////////////////////

export type LocaleActions = { type: 'Locale.SwitchLocale', locale: string } |
{ type: 'Locale.RemoteBundleLoaded', payload: { [locale: string]: LocaleStateRemote } };

type Options = { [key: string]: string }
export interface LocaleState {
  locale: string;

  lang: Options;
  langBuilding: Options;
  langPropertyStock: Options;
  langCloseTransaction: Options;
  langTransaction: Options;
  langSettings: Options;
  langForm1: Options;
  form1SourceOptions: Options;
  form1CostOfPurchaserOptions: Options;
  langForm2: Options;
  form2SourceOptions: Options;
  langForm3: Options;
  langForm4: Options;
  langForm5: Options;
  langForm6: Options;
  langSalePurchaseAgreement: Options;
  langTenancyAgreement: Options;
  langActivityAuditing: Options;
  langClientDetail: Options;
  langMemoEnquiry: Options;
  langNotification: Options;
  langSignDialog: Options;
  langRolePrivilege: Options;
  langSystemSetting: Options;
  langFeatureTagMgt: Options;
  langAgentRatingCommentMgt: Options;
  langCreationUpdateMessages: Options;
  reviewStatusOptions: Options;
  langDashboard: Options;
  langJobMonitor: Options;
  langCompany: Options;

  rowsCountPrefOptions: Options;
  activityAuditingModuleOptions: Options;
  identityTypeOptions: Options;
  agentAppointmentOptionsSale: Options;
  agentAppointmentOptionsTenancy: Options;
  prevailingVersionOptions: Options;
  yesNoOptions: Options;
  dormancyOptions: Options;
  notificationTypeOptions: Options;
  sourceOfClientOptions: Options;
  langUser: Options;
  statusOptions: Options;
  facingOptions: Options;
  viewOptions: Options;
  decoOptions: Options;
  otherFeatureOptions: Options;
  // deprecated, use region specific district options
  districtOptions: Options;
  // clientStatusOptions:Options;
  propertyStockStatusOptions: Options;
  transactionStatusOptions: Options;
  salesCommissionTypeOptions: Options;
  targetOptions: Options;
  usageOptions: Options;
  levelOptions: Options;
  vacantOptions: Options;
  symbolOptions: Options;
  clientTypeOptions: Options;
  mgtFeeInclOptions: Options;
  formLocaleOptions: Options;
  clientFormSelections: Options;
}

export interface LocaleStateRemote extends LocaleState {
  roleOptions: Options;
  contactOptions: Options;
  livingRoomOptions: Options;
  clubHouseFacilitiesOptions: Options;
  facingOptions: Options;
  viewOptions: Options;
  decoOptions: Options;
  otherFeatureOptions: Options;
  otherOptions: Options;
  districtHkiOptions: Options;
  districtKltOptions: Options;
  districtNtOptions: Options;
  primarySchoolNetOptions: Options;
  secondarySchoolNetOptions: Options;
  clientStatusOptions: Options;
  propertyStockStatusOptions: Options;
  transactionStatusOptions: Options;
  targetOptions: Options;
  usageOptions: Options;
  levelOptions: Options;
  propertyListLevelOptions: Options;
  vacantOptions: Options;
  symbolOptions: Options;
  clientTypeOptions: Options;
  mgtFeeInclOptions: Options;
  sourceOfClientOptions: Options;
  statusOptions: Options;
  yesNoOptions: Options;
  reasonOptions: Options;
  attitudeOptions: Options;
  profileOptions: Options;
  commissionOptions: Options;
  agentTypeOptions: Options;
  memoTypeOptions: Options;
  userSalutationOptions: Options;
}

interface LocaleStateBundle extends LocaleStateRemote {
  _bundle: { [key: string]: LocaleStateRemote };
}

// const localeInitState = {
//   lang: 'zh_HK',
//   facingOptions: {},
//   viewOptions: {},
//   decoOptions: {},
//   otherFeatureOptions: {},
//   districtOptions: {},
//   propertyStockStatusOptions: {},
//   targetOptions: {},
//   usageOptions: {},
//   levelOptions: {},
//   vacantOptions: {},
//   symbolOptions: {},
//   clientTypeOptions : {},
// };

const localeInitials: LocaleStateBundle = {
  _bundle: {
    'zh_HK': languages.zh_HK as any,
    'zh_CN': (languages as any).zh_CN ?? {},
    'en': languages.en as any
  },
  ...(languages.zh_HK as any),
};

export function localeReducer(state: LocaleStateBundle = localeInitials, action: LocaleActions): LocaleStateBundle {
  switch (action.type) {
    case 'Locale.SwitchLocale':
      return { _bundle: state._bundle, ...state._bundle[action.locale] };
    case 'Locale.RemoteBundleLoaded':
      const newBundle: { [locale: string]: LocaleStateRemote } = {
        'zh_HK': { ...state._bundle['zh_HK'], ...action.payload['zh_HK'] },
        'zh_CN': { ...state._bundle['zh_CN'], ...action.payload['zh_CN'] },
        'en': { ...state._bundle['en'], ...action.payload['en'] },
      };
      return {
        _bundle: newBundle,
        ...newBundle[state.locale],
      };
    default:
      return state;
  }
}

interface LoginState extends Partial<LoginStateDTO> {
  loggedIn: boolean;
  isLoading?: boolean;
  loginFailed?: boolean;
  deviceReady: boolean;
  deviceId?: string | null;
}

export type LoginActions =
  { type: 'Login.Start', payload: { username: string, password: string } } |
  { type: 'Login.Success', payload: { token: string, username: string, uid: string, rowsCountPreference: any } } |
  { type: 'Login.RowCountPrefUpdate', payload: any } |
  { type: 'Login.Failed' } |
  { type: 'Login.LogoutRequested' } |
  { type: 'Login.LoggedOut' } |
  { type: 'Login.DeviceReady', payload: { deviceId: string | null } }
  ;

export function loginReducer(state: LoginState = { loggedIn: false, deviceReady: false }, action: LoginActions): LoginState {
  switch (action.type) {
    case 'Login.DeviceReady':
      return { ...state, deviceReady: true, deviceId: action.payload.deviceId };
    case 'Login.Start':
      return { ...state, loggedIn: state.loggedIn, isLoading: true, loginFailed: false };
    case 'Login.Failed':
      return { ...state, isLoading: false, loggedIn: false, loginFailed: true };
    case 'Login.LoggedOut':
      return { ...state, isLoading: false, loggedIn: false, loginFailed: false, token: undefined, impersonationId: undefined, impersonateUsername: undefined };
    case 'Login.Success':
      return { ...state, isLoading: false, loggedIn: true, loginFailed: false, ...action.payload, rowsCountPreference: typeof (action.payload.rowsCountPreference) === 'object' ? action.payload.rowsCountPreference : JSON.parse(action.payload.rowsCountPreference ?? '{}') };
    case 'Login.RowCountPrefUpdate':
      return { ...state, rowsCountPreference: { ...state.rowsCountPreference, ...action.payload } };
    default:
      return state;
  }
}
//////////////////////////////////////////////////////////////////////
////////////////////////////// Property //////////////////////////////
//////////////////////////////////////////////////////////////////////




export interface PaginationCriteria {
  page: number;
  size?: number;
  // Query string example: ...&sort=price:asc;gross:desc&...
  sort: { [field: string]: string };
}

export interface PropertyListFetchRequest extends PaginationCriteria, Partial<PropertySearchDTO> {
  // lastSearchFormValues?: Partial<PropertyStockSearchFormValues>;
  forceCancelPropertyMatching?: boolean;
  lastSearchFormValuesForPropertiesMatching?: Partial<PropertyStockSearchFormValues>;
}

export type PropertyActions = GlobalActions |
{ type: 'PropertyList.FetchRequested', payload: PropertyListFetchRequest } |
{ type: 'PropertyList.Loading' } |
{ type: 'PropertyList.Loaded', payload: Page<PropertyListItemDTO> } |
{ type: 'PropertyList.Failed', payload: string } |
{ type: 'PropertyList.ForceCancelPropertyMatching', payload: boolean } |
{ type: 'PropertyList.ResetPropertyList' } |
{ type: 'PropertyMatchingList.Loaded', payload: Page<PropertyListItemDTO> } |
{ type: 'PropertyMatchingList.ResetPropertyList' } |
{ type: 'Property.FetchRequested', payload: { id: string } } |
{ type: 'Property.FetchByPropertyNoRequested', payload: { propertyNo: string } } |
{ type: 'Property.UpdateRequested', payload: PropertyStockDTO, meta?: { noNavigate?: boolean } } |
{ type: 'Property.ApprovalRequested', payload: { stage: number, id: string, action: 'RESOLVE' | 'PENDING' | 'REJECT' | 'REVERT', rejectionReason?: string } } |
{ type: 'Property.CreationRequested', payload: PropertyStockDTO } |
{ type: 'Property.Loading' } |
{ type: 'Property.Loaded', payload: PropertyStockDTO } |
{ type: 'Property.Failed', payload: string } |

{ type: 'Property.UpdateStatusRequested', payload: { id: string, status: string, dateModified: string } } |
{ type: 'Property.ClaimRequested', payload: { id: string } } |
{ type: 'Property.RemoveRequested', payload: { id: string } } |
{ type: 'Property.RemoveLeadAgentRequested', payload: { pid: string, agentId: string } } |
{ type: 'Property.AddLeadAgentRequested', payload: PropertyStockDTO } |
{ type: 'Property.UpdateSellerClientRequested', payload: PropertyStockDTO } |

{ type: 'Memo.FetchRequested', payload: { id: string } } |
{ type: 'Memo.UpdateRequested', payload: MemoDTO } |
{ type: 'Memo.CreateRequested', payload: { id: string, memo: MemoDTO } } | //id = propertyStock id
{ type: 'Memo.Loading' } |
{ type: 'Memo.Loaded', payload: MemoDTO[] } | //action payload should match with reducer type
{ type: 'Memo.Failed', payload: string } |

{ type: 'AllForm.FetchRequested', payload: { propertyStockId: string } } |

{ type: 'Form1.FetchRequested', payload: { propertyStockId: string } } |
{ type: 'Form1.CreationRequested', payload: Form1DTO & PdfGenerateAction } |
{ type: 'Form1.UpdateRequested', payload: Form1DTO & PdfGenerateAction } |
{ type: 'Form1.Loaded', payload: Form1DTO } |
{ type: 'Form1.Failed', payload: string } |
{ type: 'Form1.CheckExistSignedFormResultLoaded', payload: { isExist?: boolean, invitationSent?: boolean } } |

{ type: 'Form2.FetchRequested', payload: { propertyStockId: string } } |
{ type: 'Form2.CreationRequested', payload: Form2DTO & PdfGenerateAction } |
{ type: 'Form2.UpdateRequested', payload: Form2DTO & PdfGenerateAction } |
{ type: 'Form2.Loaded', payload: Form2DTO } |
{ type: 'Form2.Failed', payload: string } |

{ type: 'Form3.FetchRequested', payload: { propertyStockId: string } } |
{ type: 'Form3.CreationRequested', payload: Form3DTO & PdfGenerateAction } |
{ type: 'Form3.UpdateRequested', payload: Form3DTO & PdfGenerateAction } |
{ type: 'Form3.Loaded', payload: Form3DTO } |
{ type: 'Form3.Failed', payload: string } |
{ type: 'Form3.CheckExistSignedFormResultLoaded', payload: { isExist?: boolean, invitationSent?: boolean } } |

{ type: 'Form5.FetchRequested', payload: { propertyStockId: string } } |
{ type: 'Form5.CreationRequested', payload: Form5DTO & PdfGenerateAction } |
{ type: 'Form5.UpdateRequested', payload: Form5DTO & PdfGenerateAction } |
{ type: 'Form5.Loaded', payload: Form5DTO } |
{ type: 'Form5.Failed', payload: string } |
{ type: 'Form5.CheckExistSignedFormResultLoaded', payload: { isExist?: boolean, invitationSent?: boolean } } |

{ type: 'ProvisionalAgreement.DeleteRequested', payload: { type: 'SALE' | 'RENTAL', propertyStockId: string } } |
{ type: 'SalePurchaseAgreement.FetchRequested', payload: { propertyStockId: string } } |
{ type: 'SalePurchaseAgreement.CreationRequested', payload: SalePurchaseAgreementDTO & PdfGenerateAction } |
{ type: 'SalePurchaseAgreement.UpdateRequested', payload: SalePurchaseAgreementDTO & PdfGenerateAction } |
{ type: 'SalePurchaseAgreement.Loaded', payload: SalePurchaseAgreementDTO } |
{ type: 'SalePurchaseAgreement.Failed', payload: string } |

{ type: 'TenancyAgreement.FetchRequested', payload: { propertyStockId: string } } |
{ type: 'TenancyAgreement.CreationRequested', payload: TenancyAgreementDTO & PdfGenerateAction } |
{ type: 'TenancyAgreement.UpdateRequested', payload: TenancyAgreementDTO & PdfGenerateAction } |
{ type: 'TenancyAgreement.Loaded', payload: TenancyAgreementDTO } |
{ type: 'TenancyAgreement.Failed', payload: string } |

{ type: 'Property.ColumnPreference.FetchRequested' } |
{ type: 'Property.ColumnPreference.Loaded', payload: { columnsOrder: string[], disabledColumns: string[] } } |

{ type: 'Property.WishlistGrantedAgents.FetchRequested', payload: { propertyStockId: number } } |
{ type: 'Property.WishlistGrantedAgents.Loaded', payload: WishlistGrantedAgentDTO[] } |
{ type: 'Property.WishlistGrantedAgents.UpdateRequested', payload: { propertyStockId: number, updates: DiffUpdateSpec<WishlistGrantedAgentDTO> } }
  ;

export interface PropertyMasterState extends ListAndDetailState<PropertyListItemDTO, PropertyStockDTO> {
  // lastSearchCriteria?: PropertyListFetchRequest;
  lastSearchFormValues?: Partial<PropertyStockSearchFormValues>;
  lastSearchFormValuesForPropertiesMatching?: Partial<PropertyStockSearchFormValues>;
  memos?: MemoDTO[];
  form1?: Partial<Form1DTO>;
  form2?: Partial<Form2DTO>;
  form3?: Partial<Form3DTO>;
  form5?: Partial<Form5DTO>;
  salePurchaseAgreement?: Partial<SalePurchaseAgreementDTO>;
  tenancyAgreement?: Partial<TenancyAgreementDTO>;
  formCallbackAction?: string[]; // TODO: Maybe anti-pattern
  isCurrentUserList?: boolean;
  columnsOrder: string[];
  disabledColumns: string[];
  forceCancelPropertyMatching?: boolean;
  wishlistGrantedAgents: WishlistGrantedAgentDTO[];
}

const propertyInitialState = () => ({
  currentList: [],
  currentPage: 0,
  totalPages: 0,
  totalElements: 0,
  currentMatchingList: [],
  currentMatchingPage: 0,
  totalMatchingPages: 0,
  totalMatchingElements: 0,
  fetchedDetails: {},
  fetchedDetailsByPropertyStockId: {},
  memos: [],
  form1: {},
  form2: {},
  form3: {},
  form5: {},
  salePurchaseAgreement: {},
  tenancyAgreement: {},
  wishlistGrantedAgents: [],
  isCurrentUserList: true,
  columnsOrder: [],
  disabledColumns: [],
  forceCancelPropertyMatching: false,
});

export const getInitialPropertySearchFormValues = (districts: string[] | undefined, lastSearchFormValues: Partial<PropertyStockSearchFormValues> | undefined, locale: string) => {
  return {
    district: [...(districts ?? [])],
    status: ['SALES'],
    usage: [],
    level: [],
    streetList: [],
    buildingList: [],
    gross: BLANK_RANGE,

    rent: BLANK_RANGE,
    vacant: [],

    price: BLANK_RANGE,
    net: BLANK_RANGE,

    facing: [],
    deco: [],
    view: [],
    otherFeatures: [],
    clubHouseFacilities: [],
    others: [],
    symbol: [],
    livingRooms: [],
    occupancyPermitDate: [null, null],
    dateCreated: [null, null],
    dateModified: [null, null],
    primarySchoolNet: [],
    secondarySchoolNet: [],

    room: [],
    suite: [],
    helperRoom: [],
    balcony: [],
    bathroom: [],

    isDormant: false,
    isCurrentUser: false,
    canClaimBy: 'D',

    // Toggling
    disabledCriteriaList: {},

    ...lastSearchFormValues ?? {},
  }
}

export const getInitialPropertyMatchingSearchFormValues = (clientPreference: Partial<PropertySearchDTO> | undefined, locale: string) => {
  return {
    district: [],
    status: [],
    usage: [],
    level: [],
    streetList: [],
    buildingList: [],
    gross: BLANK_RANGE,

    rent: BLANK_RANGE,
    vacant: [],

    price: BLANK_RANGE,
    net: BLANK_RANGE,

    facing: [],
    deco: [],
    view: [],
    otherFeatures: [],
    clubHouseFacilities: [],
    others: [],
    symbol: [],
    livingRooms: [],
    occupancyPermitDate: [null, null],
    dateCreated: [null, null],
    dateModified: [null, null],
    primarySchoolNet: [],
    secondarySchoolNet: [],

    room: [],
    suite: [],
    helperRoom: [],
    balcony: [],
    bathroom: [],

    isDormant: false,
    isCurrentUser: false,
    canClaimBy: 'D',

    showApprovedOnly: true,

    // Toggling
    disabledCriteriaList: {},

    // Client Preference Assigning
    ...clientPreference ?? {},
    ...(clientPreference ? {
      net: [
        clientPreference?.net?.[0] ?? DEFAULT_FROM,
        clientPreference?.net?.[1] ?? DEFAULT_TO,
      ] as [number, number],
      price: [
        // clientPreference?.price?.[0] ? priceToView(clientPreference.price[0], locale) : DEFAULT_FROM,
        // clientPreference?.price?.[1] ? priceToView(clientPreference.price[1], locale) : DEFAULT_TO,
        (clientPreference?.price?.[0] ?? DEFAULT_FROM) === DEFAULT_FROM ? DEFAULT_FROM : priceToView(clientPreference?.price?.[0] ?? 0, locale),
        (clientPreference?.price?.[1] ?? DEFAULT_TO) === DEFAULT_TO ? DEFAULT_TO : priceToView(clientPreference?.price?.[1] ?? 0, locale),
      ] as [number, number],
    } : {}),
  }
}

export function propertyReducer(state: PropertyMasterState = propertyInitialState(), action: PropertyActions): PropertyMasterState {
  switch (action.type) {
    case 'Global.ClearCacheRequested':
      return propertyInitialState();
    case 'PropertyList.ForceCancelPropertyMatching':
      return {
        ...state,
        forceCancelPropertyMatching: action.payload,
      };
    case 'PropertyList.FetchRequested':
      return {
        ...state, lastSearchFormValues: action.payload.showApprovedOnly ? state.lastSearchFormValues : action.payload,
        lastSearchFormValuesForPropertiesMatching: action.payload.lastSearchFormValuesForPropertiesMatching,
        isCurrentUserList: action.payload.isCurrentUser ?? true
      };
    case 'PropertyList.Loaded':
      return {
        ...state,
        currentList: action.payload.content,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
      };
    case 'Property.Loaded':
      return {
        ...state,
        fetchedDetails: { ...state.fetchedDetails, [action.payload.propertyNo!]: action.payload },
        fetchedDetailsByPropertyStockId: { ...state.fetchedDetails, [action.payload.id!]: action.payload },
        form1: action.payload.form1 ?? {},
        form2: action.payload.form2 ?? {},
        form3: action.payload.form3 ?? {},
        form5: action.payload.form5 ?? {},
      };
    case 'Memo.Loaded':
      return {
        ...state,
        memos: action.payload
      };
    case 'Form1.Loaded':
      return {
        ...state,
        form1: action.payload
      };
    case 'Form1.CheckExistSignedFormResultLoaded':
      return {
        ...state,
        form1: {
          ...state.form1,
          isExist: action.payload.isExist,
          invitationSent: action.payload.invitationSent
        }
      };
    case 'Form2.Loaded':
      return {
        ...state,
        form2: action.payload
      };
    case 'Form3.Loaded':
      return {
        ...state,
        form3: action.payload
      };
    case 'Form3.CheckExistSignedFormResultLoaded':
      return {
        ...state,
        form3: {
          ...state.form3,
          isExist: action.payload.isExist,
          invitationSent: action.payload.invitationSent
        }
      };
    case 'Form5.Loaded':
      return {
        ...state,
        form5: action.payload
      };
    case 'Form5.CheckExistSignedFormResultLoaded':
      return {
        ...state,
        form5: {
          ...state.form5,
          isExist: action.payload.isExist,
          invitationSent: action.payload.invitationSent
        }
      };
    case 'SalePurchaseAgreement.Loaded':
      return {
        ...state,
        salePurchaseAgreement: action.payload
      };
    case 'TenancyAgreement.Loaded':
      return {
        ...state,
        tenancyAgreement: action.payload
      };
    case 'Property.ColumnPreference.Loaded':
      return {
        ...state,
        columnsOrder: action.payload.columnsOrder,
        disabledColumns: action.payload.disabledColumns,
      };
    case 'Property.WishlistGrantedAgents.Loaded':
      return {
        ...state,
        wishlistGrantedAgents: action.payload,
      };

    case 'PropertyList.ResetPropertyList':
      return {
        ...state,
        currentList: [],
        currentPage: 0,
        totalPages: 0,
        totalElements: 0,
      };
    case 'PropertyMatchingList.Loaded':
      return {
        ...state,
        currentMatchingList: action.payload.content,
        currentMatchingPage: action.payload.currentPage,
        totalMatchingPages: action.payload.totalPages,
        totalMatchingElements: action.payload.totalElements,
      };
    case 'PropertyMatchingList.ResetPropertyList':
      return {
        ...state,
        currentMatchingList: [],
        currentMatchingPage: 0,
        totalMatchingPages: 0,
        totalMatchingElements: 0,
      }
    default:
      // 
      return state;
  }
}
///////////////////////////////////////////////////////////////////////////////
////////////////////////////// Property Matching //////////////////////////////
///////////////////////////////////////////////////////////////////////////////

export type PropertyMatchingState = null | {
  cid: string,
  name: string,
  nameEn: string,
  clientPreference: Partial<PropertySearchDTO>,
};

export type PropertyMatchingActions = GlobalActions |
{ type: 'PropertyMatching.StartMatching', payload: { cid: string, name: string, nameEn: string, clientPreference: Partial<PropertySearchDTO> } } |
{ type: 'PropertyMatching.StopMatching' } |
{ type: 'PropertyMatching.AddStockCartRequested', payload: { cid: string, pid: string[] } } |
{ type: 'PropertyMatching.RemoveStockCartRequested', payload: { cid: string, pid: string[], propertyStatus: string | undefined } } |
{ type: 'PropertyMatching.MultiClient.AddStockCartRequested', payload: { pid: string, cids: string[] } }
  // { type: 'PropertyMatching.SetTabPanelRequested', payload: { tabIndex: number, callStopMathcing: boolean } }
  ;

export function propertyMatchingReducer(state: PropertyMatchingState = null, action: PropertyMatchingActions): PropertyMatchingState {
  switch (action.type) {
    case 'Global.ClearCacheRequested':
      return null;
    case 'PropertyMatching.StartMatching':
      return action.payload;
    case 'PropertyMatching.StopMatching':
      return null;
    // case 'PropertyMatching.SetTabPanelRequested':
    //   return {
    //     ...state,
    //     tabIndex: action.payload.tabIndex,
    //     callStopMatching: action.payload.callStopMathcing
    //   };
    default:
      return state;
  }
}


//////////////////////////////////////////////////////////////////////////////
/////////////////////////////// Transaction /////////////////////////////
//////////////////////////////////////////////////////////////////////////////
export interface TransactionSearchCriteria extends PaginationCriteria, TransactionSearchDTO {
  $replaced?: boolean;
}

export type TransactionActions = GlobalActions |
{ type: 'TransactionList.FetchRequested' } |
{ type: 'TransactionList.Loading' } |
{ type: 'TransactionList.Loaded', payload: Page<TransactionListItemDTO> } |
{ type: 'TransactionList.Failed', payload: string } |
{ type: 'TransactionList.CriteriaUpdated', payload: Partial<TransactionSearchCriteria> } |

{ type: 'Transaction.FetchRequested', payload: { id: string } } |
{ type: 'Transaction.Loaded', payload: TransactionDetailDTO } |
{ type: 'Transaction.Failed', payload: string } |

{ type: 'Transaction.CreatePropertyStockRequested', payload: { id: string } } |
{ type: 'Transaction.UpdatePropertyDraftRequested', payload: { update: PropertyDraftUpdateDTO } } |

{ type: 'Transaction.ColumnPreference.FetchRequested' } |
{ type: 'Transaction.ColumnPreference.Loaded', payload: { columnsOrder: string[], disabledColumns: string[] } }

  // { type: 'Property.FetchRequested', payload: { id: string } } |
  // { type: 'Property.UpdateRequested', payload: PropertyStockDTO } |
  // { type: 'Property.CreationRequested', payload: PropertyStockDTO } |
  // { type: 'Property.Loading' } |
  // { type: 'Property.Loaded', payload: PropertyStockDTO } |
  // { type: 'Property.Failed', payload: string } 
  ;

export interface TransactionMasterState extends ListAndDetailState<TransactionListItemDTO, any> {
  // lastSearchCriteria?: TransactionSearchCriteria;
  criteria: Partial<TransactionSearchCriteria>;
  transactionForCreatingNewPropertyStock?: string;
  columnsOrder: string[];
  disabledColumns: string[];
}

const transactionInitState = (): TransactionMasterState => ({
  criteria: {
    page: 0, size: 10, sort: {
      transactionDate: 'desc',
    },

    district: [],
    status: ['SOLD'],
    usage: [],
    level: [],

    gross: BLANK_RANGE,
    net: BLANK_RANGE,
    price: BLANK_RANGE,
    rent: BLANK_RANGE,

    buyer: '',
    seller: '',
    transactionDate: [null, null],
    internal: false,

    isRent: false,
    isGross: false,
    propertyIsValid: 'true',
  },
  currentList: [],
  currentPage: 0,
  totalPages: 0,
  totalElements: 0,
  currentMatchingList: [],
  currentMatchingPage: 0,
  totalMatchingPages: 0,
  totalMatchingElements: 0,
  fetchedDetails: {},
  fetchedDetailsByPropertyStockId: {},
  columnsOrder: [],
  disabledColumns: [],
});

export function transactionReducer(state: TransactionMasterState = transactionInitState(), action: TransactionActions): TransactionMasterState {
  switch (action.type) {
    case 'Global.ClearCacheRequested':
      return transactionInitState();
    case 'TransactionList.CriteriaUpdated':
      const { $replaced, ...updatedCriteria } = action.payload;
      return {
        ...state,
        criteria: $replaced ?
          { ...transactionInitState().criteria, ...updatedCriteria } :
          { ...state.criteria, ...updatedCriteria },
      };
    case 'TransactionList.FetchRequested':
      return { ...state };
    case 'TransactionList.Loaded':
      return {
        ...state,
        currentList: action.payload.content,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
      };
    case 'Transaction.Loaded':
      return {
        ...state,
        fetchedDetails: { ...state.fetchedDetails, [action.payload.id!]: action.payload }
      };
    case 'Transaction.CreatePropertyStockRequested':
      return {
        ...state,
        transactionForCreatingNewPropertyStock: action.payload.id!
      };
    case 'Transaction.ColumnPreference.Loaded':
      return {
        ...state,
        columnsOrder: action.payload.columnsOrder,
        disabledColumns: action.payload.disabledColumns,
      };
    default:
      // 
      return state;
  }
}


//////////////////////////////////////////////////////////////////////////////
/////////////////////////////// CommissionReport /////////////////////////////
//////////////////////////////////////////////////////////////////////////////
export interface CommissionReportSearchCritiera extends PaginationCriteria {
  // Multi Selection Criteria
  // Query string example: ...&usage=V,R&district=NTP,NSG&...
  usage?: string[];
  status?: string[];
  district?: string[];
  level?: string[];

  // Free-text Searching
  // streetOrBuilding?: string;
  street?: string;
  building?: string;
  block?: string;
  buyer?: string;
  seller?: string;

  // Range values, can be Infinity on each side, for whcich case it shoud be filled with -1
  // Query string example: ...&gross=-1,300&...
  gross?: [number, number];
  net?: [number, number];
  price?: [number, number];
  rent?: [number, number];
  buildingAge?: number;
  transactionDate?: (string | null)[];
  username?: string;
  // internal?: boolean;
  // showOnlyCurrentUser?: boolean;
}

export type CommissionReportActions = GlobalActions |
{ type: 'CommissionReportList.FetchRequested', payload: CommissionReportSearchCritiera } |
{ type: 'CommissionReportList.Loading' } |
{ type: 'CommissionReportList.Loaded', payload: Page<CommissionReportListItemDTO> } |
{ type: 'CommissionReportList.Failed', payload: string }
  ;

export interface CommissionReportMasterState extends ListAndDetailState<CommissionReportListItemDTO, any> {
  lastSearchCriteria?: CommissionReportSearchCritiera;
}

export function commissionReportReducer(state: CommissionReportMasterState = {
  currentList: [],
  currentPage: 0,
  totalPages: 0,
  totalElements: 0,
  currentMatchingList: [],
  currentMatchingPage: 0,
  totalMatchingPages: 0,
  totalMatchingElements: 0,
  fetchedDetails: {},
  fetchedDetailsByPropertyStockId: {},
}, action: CommissionReportActions): CommissionReportMasterState {
  switch (action.type) {
    case 'Global.ClearCacheRequested':
      return emptyListAndDetailState();
    case 'CommissionReportList.FetchRequested':
      return { ...state, lastSearchCriteria: action.payload };
    case 'CommissionReportList.Loaded':
      return {
        ...state,
        currentList: action.payload.content,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
      };
    default:
      // 
      return state;
  }
}


//////////////////////////////////////////////////////////////////////////////
/////////////////////////////// Close Transaction ////////////////////////////
//////////////////////////////////////////////////////////////////////////////

export type RequestForConfirmationAction = {
  extraAction?: 'REQUEST_FOR_CONFIRMATION',
}

export type CloseTransactionActions = GlobalActions |
{ type: 'CloseTransaction.FetchRequested', payload: { pid: string } } |
{ type: 'CloseTransaction.Loaded', payload: CloseTransactionDTO | null } |
{ type: 'CloseTransaction.Failed', payload: string } |
{ type: 'CloseTransaction.AddRequested', payload: CloseTransactionDTO & RequestForConfirmationAction } |
{ type: 'CloseTransaction.UpdateRequested', payload: CloseTransactionDTO & RequestForConfirmationAction } |
{ type: 'CloseTransaction.RequestConfirmationRequested', payload: { id: string } } |
{ type: 'CloseTransaction.ConfirmationRequested', payload: { id: string, signatureBlobUrl: string } } |
{ type: 'CloseTransaction.ApproveRequested', payload: { id: string, approverSignBlobUrl: string } } |
{ type: 'CloseTransaction.RejectRequested', payload: { id: string, remarks: CloseTransactionDTO } } |
{ type: 'CloseTransaction.RequestApprovalRequested', payload: { id: string } }
  ;

export interface CloseTransactionState extends ListAndDetailState<CloseTransactionDTO, CloseTransactionDTO> {
}

export function closeTransactionReducer(state: CloseTransactionState = {
  currentList: [],
  currentPage: 0,
  totalPages: 0,
  totalElements: 0,
  currentMatchingList: [],
  currentMatchingPage: 0,
  totalMatchingPages: 0,
  totalMatchingElements: 0,
  fetchedDetails: {},
  fetchedDetailsByPropertyStockId: {},
}, action: CloseTransactionActions): CloseTransactionState {
  if (!action.payload) {
    return state;
  }
  switch (action.type) {
    case 'Global.ClearCacheRequested':
      return emptyListAndDetailState();
    case 'CloseTransaction.Loaded':
      return {
        ...state,
        fetchedDetails: { ...state.fetchedDetails, [action.payload!.propertyStockId]: action.payload! }
      }
    default:
      // 
      return state;
  }
}
///////////////////////////////////////////////////////////////////////////////////
/////////////////////////////// System Settings ///////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////

export type SystemSettingActions = GlobalActions |
{ type: 'SystemSetting.FetchRequested' } |
{ type: 'SystemSetting.Loaded', payload: { data: MasterDataDTO[] } };

export function systemSettingReducer(state: MasterDataMap = {}, action: SystemSettingActions): MasterDataMap {
  switch (action.type) {
    // case 'Global.ClearCacheRequested':
    //   return {};
    case 'SystemSetting.Loaded':
      const newMap: MasterDataMap = {};
      action.payload.data.forEach(masterData => {
        const moduleName = masterData.module ?? '';
        const typeName = masterData.type ?? '';
        const key = masterData.key ?? '';
        const value = masterData.value ?? '';
        if (!newMap[moduleName]) {
          newMap[moduleName] = {};
        }
        if (!newMap[moduleName][typeName]) {
          newMap[moduleName][typeName] = {};
        }
        newMap[moduleName][typeName][key] = value;
      });
      return newMap;
    default:
      return state;
  }
}

//////////////////////////////////////////////////////////////////////////////
/////////////////////////////// Mater Data ///////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

export type MasterDataActions = GlobalActions |
{ type: 'MasterDataRequest.Start' } |
{ type: 'MasterDataRequest.Success', payload: { masterData: MasterData[] } } |
{ type: 'MasterDataRequest.Failed', payload: { error: String } };

export function masterDataReducer(state: any = null, action: MasterDataActions): any {
  switch (action.type) {
    case 'Global.ClearCacheRequested':
      return null;
    case 'MasterDataRequest.Start':
      return null;
    case 'MasterDataRequest.Success':
      return action.payload;
    case 'MasterDataRequest.Failed':
      return action.payload;
    default:
      return state;
  }
}

///////////////////////////////////////////////////////////////////////////////
////////////////////////////// Client Master //////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

export interface ClientSearchCritiera extends PaginationCriteria {
  email?: string;

  // Multi Selection Criteria
  // Query string example: ...&usage=V,R&district=NTP,NSG&...
  usage?: string[];
  status?: string[];
  district?: string[];
  level?: string[];

  // Free-text Searching
  street?: string;
  building?: string;

  // Range values, can be Infinity on each side, for whcich case it shoud be filled with -1
  // Query string example: ...&gross=-1,300&...
  gross?: [number, number];
  net?: [number, number];
  price?: [number, number];
  rent?: [number, number];

  isCurrentUser?: boolean;
  isDormant?: boolean;
  canClaimBy?: string;
}

export type PdfGenerateAction = {
  extraAction?: 'GENERATE_PDF' | 'SIGN_PDF' | 'SIGN_FORM',
  regenerate?: boolean,
  formLocale?: string,
}

export type ClientActions = GlobalActions |
{ type: 'ClientList.FetchRequested', payload: ClientSearchCritiera } |
{ type: 'ClientList.Loading' } |
{ type: 'ClientList.Loaded', payload: Page<ClientListItemDTO> } |
{ type: 'ClientList.Failed', payload: string } |

{ type: 'ClientDetail.FetchRequested', payload: { cid: string } } |
{ type: 'ClientDetail.UpdateRequested', payload: ClientDetailDTO } |
{ type: 'ClientDetail.setHasAlreadyBeenModifiedApiError', payload: boolean } |
// { type: 'ClientDetail.UpdateRequested', payload: ClientDetailDTO } |

{ type: 'ClientDetail.CreationRequested', payload: ClientDetailDTO } |
{ type: 'ClientDetail.Loading', cid: string, payload: boolean } |
{ type: 'ClientDetail.Loaded', payload: ClientDetailDTO } |
{ type: 'ClientDetail.TabIndexChanged', payload: { cid: string, tabIndex: number } } |
{ type: 'ClientDetail.Reseted', cid: string } |

// { type: 'ClientDetail.BasicUpdated', cid: string, payload: Partial<ClientDetailDTO['basic']> } |

{ type: 'ClientDetail.ContactAdded', cid: string, payload: { type: string, value: string, } } |
{ type: 'ClientDetail.ContactRemoved', cid: string, payload: { id: number } } |
{ type: 'ClientDetail.OtherContactUpdated', cid: string, payload: { id: number, value: string, name: string, remarks: string } } |

{ type: 'ClientDetail.PropertyMatchingStarted', cid: string, } |
{ type: 'ClientDetail.PropertyMatchingEnded', cid: string, } |
// { type: 'ClientDetail.WishlistUpdated', cid: string, payload: WishlistItemDTO[] } |

//FIXME remove later
{ type: 'ClientDetail.UpdatedOrCreated', payload: ClientDetailDTO } |
////////////////////////////// Stock Cart /////////////////////////////
{ type: 'StockCart.FetchRequested', cid: string, payload: PropertyListFetchRequest, propertyStatus?: string } |
{ type: 'StockCart.Loaded', payload: Page<PropertyListItemDTO> } |

{ type: 'Client.ClaimRequested', payload: { cid: string } } |

//filter stockCartList when clicking form 4/6 button
{ type: 'StockCart.RefreshForForm4And6', payload: { propertyList: PropertyListItemDTO[] } } |

//////////////////////////////Memos//////////////////////////////
{ type: 'Client.Memo.FetchRequested', payload: { cid: string } } |
{ type: 'Client.Memo.UpdateRequested', payload: MemoDTO } |
{ type: 'Client.Memo.CreateRequested', payload: { cid: string, memo: MemoDTO } } |
{ type: 'Client.Memo.Loading' } |
{ type: 'Client.Memo.Loaded', payload: MemoDTO[] } |
{ type: 'Client.Memo.Failed', payload: string } |

////////////////////////////// Forms/////////////////////////////
{ type: 'Form4.FetchRequested', payload: { buyerClientId: string } } |
{ type: 'Form4.CreationRequested', payload: Form4DTO & PdfGenerateAction } |
{ type: 'Form4.UpdateRequested', payload: Form4DTO & PdfGenerateAction } |
{ type: 'Form4.Loaded', payload: Form4DTO } |
{ type: 'Form4.Failed', payload: string } |
{ type: 'Form4.CheckExistSignedFormResultLoaded', payload: { isExist?: boolean, invitationSent?: boolean } } |

{ type: 'Form6.FetchRequested', payload: { tenantClientId: string } } |
{ type: 'Form6.CreationRequested', payload: Form6DTO & PdfGenerateAction } |
{ type: 'Form6.UpdateRequested', payload: Form6DTO & PdfGenerateAction } |
{ type: 'Form6.Loaded', payload: Form6DTO } |
{ type: 'Form6.Failed', payload: string } |
{ type: 'Form6.CheckExistSignedFormResultLoaded', payload: { isExist?: boolean, invitationSent?: boolean } } |

{ type: 'Client.RemoveRequested', payload: { cid: string } } |

//assign agent/remove agent
{ type: 'Client.AssignAgentRequested', payload: { cid: string, agentId: string, shouldNotRedirect: boolean } } |
{ type: 'Client.RemoveAgentRequested', payload: { cid: string } } |

//seller stock
{ type: 'SellerStock.FetchRequested', cid: string, payload: PropertyListFetchRequest } |
{ type: 'SellerStock.Loaded', payload: Page<PropertyListItemDTO> } |

{ type: 'Client.ColumnPreference.FetchRequested' } |
{ type: 'Client.ColumnPreference.Loaded', payload: { columnsOrder: string[], disabledColumns: string[] } }

  ;

interface ClientMasterState extends ListAndDetailState<ClientListItemDTO, ClientDetailDTO> {
  lastSearchCriteria?: Partial<ClientSearchDTO>;
  isCurrentUserList?: boolean,
  stockCartList?: PropertyListItemDTO[],
  currentCartPage?: number,
  totalCartPages?: number,
  totalCartElements?: number;
  tabIndex?: number,
  form4?: Partial<Form4DTO>;
  form6?: Partial<Form6DTO>;
  memos?: MemoDTO[];
  //seller stock
  sellerStockList?: PropertyListItemDTO[],
  currentSellerStockPage?: number,
  totalSellerStockPages?: number,
  totalSellerStockElements?: number;
  columnsOrder?: string[];
  disabledColumns: string[];
  hasAlreadyBeenModifiedApiError?: boolean
}


const clientInitialState = () => ({
  hasAlreadyBeenModifiedApiError: false,
  currentList: [],
  currentPage: 0,
  totalPages: 0,
  totalElements: 0,
  currentMatchingList: [],
  currentMatchingPage: 0,
  totalMatchingPages: 0,
  totalMatchingElements: 0,
  fetchedDetails: {},
  fetchedDetailsByPropertyStockId: {},
  stockCartList: [],
  memos: [],
  form4: {},
  form6: {},
  currentCartPage: 0,
  totalCartPages: 0,
  totalCartElements: 0,
  tabIndex: 0,
  isCurrentUserList: true,
  //seller stock
  sellerStockList: [],
  currentSellerStockPage: 0,
  totalSellerStockPages: 0,
  totalSellerStockElements: 0,
  columnsOrder: [],
  disabledColumns: [],
});

function clientMasterReducer(state: ClientMasterState = clientInitialState(), action: ClientActions): ClientMasterState {
  switch (action.type) {
    case 'Global.ClearCacheRequested':
      return clientInitialState();
    case 'ClientList.FetchRequested':
      return { ...state, lastSearchCriteria: action.payload, isCurrentUserList: action.payload.isCurrentUser ?? true };
    case 'ClientList.Loaded':
      return {
        ...state,
        currentList: action.payload.content,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
      };
    //TODO fix later
    /*
    case 'ClientDetail.Reseted': 
      return clientDetailInitials();
    // case 'Global.ClearCacheRequested':
    //   return state.visible ? state : clientDetailInitials();
    */
    case 'ClientDetail.setHasAlreadyBeenModifiedApiError':
      return { ...state, hasAlreadyBeenModifiedApiError: action.payload }
    case 'ClientDetail.Loading':
      return { ...state };

    case 'ClientDetail.Loaded':
      // GET /agents/{aid}/clients/{cid}
      // GET /agents/{aid}/clients/{cid}/wishlist
      // GET /agents/{aid}/clients/{cid}/other-contacts
      return {
        ...state,
        hasAlreadyBeenModifiedApiError: false,
        fetchedDetails: { ...state.fetchedDetails, [action.payload.cid!]: action.payload },
      };

    case 'ClientDetail.TabIndexChanged':
      return { ...state, tabIndex: action.payload.tabIndex };

    /*
    case 'ClientDetail.BasicUpdated':
      // PATCH /agents/{aid}/clients/{cid}
      //return { ...state, basic: { ...state.basic, ...action.payload }};
      
      //return state;
      return {
        ...state,
        fetchedDetails: {
          ...state.fetchedDetails,
          [action.cid!]: {
            ...state.fetchedDetails[action.cid!],
            basic: { ...state.fetchedDetails[action.cid!].basic , ...action.payload }
          }
        }
      };
    */
    // case 'ClientDetail.PropertyMatchingStarted':
    //   return { ...state, propertyMatchingMode: true }; 
    // case 'ClientDetail.PropertyMatchingEnded':
    //   return { ...state, propertyMatchingMode: false };

    // case 'ClientDetail.WishlistUpdated': // wishlist?: (state => state.clientDetail.current?.wishlist ?? [])
    //   // POST /agents/{aid}/clients/{cid}/wishlist
    //   return { ...state, wishlist: action.payload };

    case 'ClientDetail.ContactAdded':
      // POST /agents/{aid}/clients/{cid}/other-contacts
      // return { ...state, otherContacts: [ ...(state.otherContacts || []), action.payload ] };
      return {
        ...state,
        fetchedDetails: {
          ...state.fetchedDetails,
          [action.cid!]: {
            ...state.fetchedDetails[action.cid!],
            otherContacts: [...(state.fetchedDetails[action.cid!].otherContacts || []), action.payload]
          }
        }
      };

    case 'ClientDetail.ContactRemoved':
      // DELETE /agents/{aid}/clients/{cid}/other-contacts/{id}
      // return { ...state, otherContacts: state.otherContacts ?
      //   state.otherContacts.filter((_, idx) => idx != action.payload.id) : []
      // };
      return {
        ...state,
        fetchedDetails: {
          ...state.fetchedDetails,
          [action.cid!]: {
            ...state.fetchedDetails[action.cid!],
            otherContacts: state.fetchedDetails[action.cid!].otherContacts ?
              state.fetchedDetails[action.cid!].otherContacts?.filter((_, idx) => idx != action.payload.id) : []
          }
        }
      };

    case 'ClientDetail.OtherContactUpdated':
      // PATCH /agents/{aid}/clients/{cid}/other-contacts/{id}
      // return { ...state, otherContacts: state.otherContacts ?
      //   state.otherContacts.map((c, idx) => idx === action.payload.id ? { ...c, value: action.payload.value } : c) : []
      // };
      return {
        ...state,
        fetchedDetails: {
          ...state.fetchedDetails,
          [action.cid!]: {
            ...state.fetchedDetails[action.cid!],
            otherContacts: state.fetchedDetails[action.cid!].otherContacts ?
              state.fetchedDetails[action.cid!].otherContacts?.map((c, idx) => idx === action.payload.id ? { ...c, value: action.payload.value, name: action.payload.name, remarks: action.payload.remarks } : c) : []
          }
        }
      };
    //FIXME temp use only, remove later
    case 'ClientDetail.UpdatedOrCreated':
      return {
        ...state,
        fetchedDetails: {
          ...state.fetchedDetails,
          [action.payload.cid!]: action.payload
        }
      };
    case 'StockCart.Loaded':
      return {
        ...state,
        stockCartList: action.payload.content,
        currentCartPage: action.payload.currentPage,
        totalCartPages: action.payload.totalPages,
        totalCartElements: action.payload.totalElements,
      };
    case 'StockCart.RefreshForForm4And6':
      return {
        ...state,
        stockCartList: action.payload.propertyList,
      };
    case 'Client.Memo.Loaded':
      return {
        ...state,
        memos: action.payload
      };
    case 'Form4.Loaded':
      return {
        ...state,
        form4: action.payload,
      };
    case 'Form4.CheckExistSignedFormResultLoaded':
      return {
        ...state,
        form4: {
          ...state.form4,
          isExist: action.payload.isExist,
          invitationSent: action.payload.invitationSent
        }
      };
    case 'Form6.Loaded':
      return {
        ...state,
        form6: action.payload,
      };
    case 'Form6.CheckExistSignedFormResultLoaded':
      return {
        ...state,
        form6: {
          ...state.form6,
          isExist: action.payload.isExist,
          invitationSent: action.payload.invitationSent
        }
      };
    case 'SellerStock.Loaded':
      return {
        ...state,
        sellerStockList: action.payload.content,
        currentSellerStockPage: action.payload.currentPage,
        totalSellerStockPages: action.payload.totalPages,
        totalSellerStockElements: action.payload.totalElements,
      };
    case 'Client.ColumnPreference.Loaded':
      return {
        ...state,
        columnsOrder: action.payload.columnsOrder,
        disabledColumns: action.payload.disabledColumns,
      };
    default:
      return state;
  }
}



/*
function clientMasterReducer(state = clientMasterInitials(), action: ClientDetailActions): ClientMasterState {
  const [ mainType ] = action.type.split('.');
  
  if (mainType === 'ClientDetail') {
    const cid = (action as ClientDetailActions).cid;
    const updatedClientDetail = clientDetailReducer(
      state.fetchedDetails[cid],
      action as ClientDetailActions
    );
    return {
      ...state,
      fetchedDetails: {
        ...state.fetchedDetails,
        [cid]: updatedClientDetail,
      },
    };
  } else {
    return state; // TODO:
  }
}
  

interface ClientDetailState extends ClientDetailDTO {
  tabIndex: number;
  propertyMatchingMode: boolean;
  errors: string[];
  loading: boolean;
  visible: boolean;
}

const clientDetailInitials = (): ClientDetailState => ({
  basic: {
    isNew: true,
  },
  tabIndex: 0,
  propertyMatchingMode: false,
  errors: [],
  loading: false,
  visible: false,
  // isNew: true,
  // formSigned: false,
});

export function clientDetailReducer(state = clientDetailInitials(), action: ClientDetailActions): ClientDetailState {
  switch (action.type) {
    case 'ClientDetail.Reseted': 
      return clientDetailInitials();
    // case 'Global.ClearCacheRequested':
    //   return state.visible ? state : clientDetailInitials();
    case 'ClientDetail.Loading':
        return { ...state, loading: action.payload };
    case 'ClientDetail.Loaded':
      // GET /agents/{aid}/clients/{cid}
      // GET /agents/{aid}/clients/{cid}/wishlist
      // GET /agents/{aid}/clients/{cid}/other-contacts
      return { ...state, ...action.payload };
    case 'ClientDetail.TabIndexChanged':
      return { ...state, tabIndex: action.payload };
    
    case 'ClientDetail.BasicUpdated':
      // PATCH /agents/{aid}/clients/{cid}
      return { ...state, basic: { ...state.basic, ...action.payload }};

    // case 'ClientDetail.PropertyMatchingStarted':
    //   return { ...state, propertyMatchingMode: true }; 
    // case 'ClientDetail.PropertyMatchingEnded':
    //   return { ...state, propertyMatchingMode: false };

    case 'ClientDetail.WishlistUpdated': // wishlist?: (state => state.clientDetail.current?.wishlist ?? [])
      // POST /agents/{aid}/clients/{cid}/wishlist
      return { ...state, wishlist: action.payload };
    
    case 'ClientDetail.ContactAdded':
      // POST /agents/{aid}/clients/{cid}/other-contacts
      return { ...state, otherContacts: [ ...(state.otherContacts || []), action.payload ] };
    case 'ClientDetail.ContactRemoved':
      // DELETE /agents/{aid}/clients/{cid}/other-contacts/{id}
      return { ...state, otherContacts: state.otherContacts ?
        state.otherContacts.filter((_, idx) => idx != action.payload.id) : []
      };
    case 'ClientDetail.OtherContactUpdated':
      // PATCH /agents/{aid}/clients/{cid}/other-contacts/{id}
      return { ...state, otherContacts: state.otherContacts ?
        state.otherContacts.map((c, idx) => idx === action.payload.id ? { ...c, value: action.payload.value } : c) : []
      };

    default:
      return state;
  }
}

*/
/////////////////////////////////////////////////////////////////////////////////
////////////////////////////// Building Master //////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////

export interface BuildingState extends ListAndDetailState<BuildingListItemDTO, any> {
  criteria: Partial<BuildingSearchCriteria>;
}

export interface BuildingSearchCriteria extends PaginationCriteria {
  // Multi Selection Criteria
  // Query string example: ...&usage=V,R&district=NTP,NSG&...
  usage?: string[];
  district?: string[];

  // Free-text Searching
  street?: string;
  building?: string;
  block?: string;

  // replace directive
  $replaced?: boolean;
}

export type BuildingActions = GlobalActions |
{ type: 'BuildingList.FetchRequested' } |
{ type: 'BuildingList.Loaded', payload: Page<BuildingListItemDTO> } |
{ type: 'BuildingList.Failed', payload: string } |
{ type: 'Building.FetchRequested', payload: { id: string } } |
{ type: 'Building.UpdateRequested', payload: BuildingDetailDTO } |
{ type: 'Building.CreationRequested', payload: BuildingDetailDTO } |
{ type: 'Building.Loading' } |
{ type: 'Building.Loaded', payload: BuildingDetailDTO } |
{ type: 'Building.Failed', payload: string } |
{ type: 'BuildingList.CriteriaUpdated', payload: Partial<BuildingSearchCriteria> }
  ;

const buildingInitialState = (): BuildingState => ({
  ...emptyListAndDetailState(),
  criteria: {
    page: 0, size: 10, sort: {
      dateModified: 'desc',
    },
    usage: [],
    district: [],
    street: '',
    building: '',
    block: '',
  },
});

export function buildingReducer(state: BuildingState = buildingInitialState(), action: BuildingActions): BuildingState {
  switch (action.type) {
    case 'Global.ClearCacheRequested':
      return buildingInitialState();
    case 'BuildingList.FetchRequested':
      return {
        ...state
      };
    case 'BuildingList.Loaded':
      return {
        ...state,
        currentList: action.payload.content,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
      };
    case 'Building.Loaded':
      return {
        ...state,
        fetchedDetails: { ...state.fetchedDetails, [action.payload.id!]: action.payload },
      };
    case 'BuildingList.CriteriaUpdated':
      const { $replaced: $replace, ...updatedCriteria } = action.payload;
      return {
        ...state,
        criteria: $replace ?
          { ...buildingInitialState().criteria, ...updatedCriteria } :
          { ...state.criteria, ...updatedCriteria }
        ,
      };
    default:
      return state;
  }
}

/////////////////////////////////////////////////////////////////////////////////
////////////////////////////// User Management //////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////

export interface UserState extends ListAndDetailState<any, UserDTO> {
  lastSearchCriteria?: any;
}

export interface UserSearchCriteria extends PaginationCriteria {

}

export type UserActions = GlobalActions |
{ type: 'UserList.FetchRequested', payload: UserSearchCriteria | null } |
{ type: 'UserList.Loaded', payload: Page<any> } |
{ type: 'UserList.Failed', payload: string } |
{ type: 'User.FetchRequested', payload: { id: string } } |
{ type: 'User.UpdateRequested', payload: UserDTO } |
{ type: 'User.CreationRequested', payload: UserDTO } |
{ type: 'User.ResetPasswordRequested', payload: { id: string | number } } |
{ type: 'User.UnlockRequested', payload: { id: string | number } } |
{ type: 'User.Loading' } |
{ type: 'User.Loaded', payload: UserDTO } |
{ type: 'User.Failed', payload: string } |

//impersonation
{ type: 'User.ImpersonationRequested', payload: { beingImpersonatedUserId: string | number } } |
{ type: 'User.ApproveImpersonationRequested', payload: NotificationDTO } |
{ type: 'User.StartImpersonationRequested', payload: { notificationId: string, password: string } } |
{ type: 'User.ApproverStopImpersonationRequested', payload: { impersonatedUserId: string | number, beingImpersonatedUserId: string | number } } |
{ type: 'User.ImpersonateUserStopImpersonationRequested', payload: { impersonationId: string | number, impersonateUsername: string, } }
  ;

export function userReducer(state: UserState = emptyListAndDetailState(), action: UserActions): UserState {
  switch (action.type) {
    case 'Global.ClearCacheRequested':
      return emptyListAndDetailState();
    case 'UserList.FetchRequested':
      return {
        ...state,
        lastSearchCriteria: action.payload,
      };
    case 'UserList.Loaded':
      return {
        ...state,
        currentList: action.payload.content,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
      };
    case 'User.Loaded':
      return {
        ...state,
        fetchedDetails: { ...state.fetchedDetails, [action.payload.id!]: action.payload },
      };
    default:
      return state;
  }
}


///////////////////////////////////////////////////////////////////////////////////
////////////////////////////// Activity Auditing //////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////

export interface ActivityAuditingState extends ListAndDetailState<ActivityAuditingDTO, ActivityAuditingDTO> {

}

export interface ActivityAuditingSearchCriteria extends PaginationCriteria {
  userId?: string;
}

export type ActivityAuditingActions = GlobalActions |
{ type: 'ActivityAuditingList.FetchRequested', payload: ActivityAuditingSearchCriteria | null } |
{ type: 'ActivityAuditingList.Loaded', payload: Page<any> } |
{ type: 'ActivityAuditingList.Failed', payload: string };
// { type: 'ActivityAuditing.FetchRequested', payload: { id: string } } |
// { type: 'ActivityAuditing.UpdateRequested', payload: ActivityAuditingDTO } |
// { type: 'ActivityAuditing.CreationRequested', payload: ActivityAuditingDTO } |
// { type: 'ActivityAuditing.Loading' } |
// { type: 'ActivityAuditing.Loaded', payload: ActivityAuditingDTO } |
// { type: 'ActivityAuditing.Failed', payload: string } ;

export function activityAuditingReducer(state: ActivityAuditingState = emptyListAndDetailState(), action: ActivityAuditingActions): ActivityAuditingState {
  switch (action.type) {
    case 'Global.ClearCacheRequested':
      return emptyListAndDetailState();
    case 'ActivityAuditingList.Loaded':
      return {
        ...state,
        currentList: action.payload.content,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
      };
    // case 'ActivityAuditing.Loaded':
    //   return {...state,
    //     fetchedDetails: { ...state.fetchedDetails, [action.payload.id!]: action.payload },
    //   };
    default:
      return state;
  }
}

////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////// Memo Enquiry /////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////

export interface MemoEnquiryState extends ListAndDetailState<MemoEnquiryDTO, MemoEnquiryDTO> {

}

export interface MemoEnquirySearchCriteria extends PaginationCriteria {
  userId?: string;
  dateCreated?: string;
}

export type MemoEnquiryActions = GlobalActions |
{ type: 'MemoEnquiryList.FetchRequested', payload: MemoEnquirySearchCriteria | null } |
{ type: 'MemoEnquiryList.Loaded', payload: Page<any> } |
{ type: 'MemoEnquiryList.Failed', payload: string };

export function memoEnquiryReducer(state: MemoEnquiryState = emptyListAndDetailState(), action: MemoEnquiryActions): MemoEnquiryState {
  switch (action.type) {
    case 'Global.ClearCacheRequested':
      return emptyListAndDetailState();
    case 'MemoEnquiryList.Loaded':
      return {
        ...state,
        currentList: action.payload.content,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
      };
    default:
      return state;
  }
}

export interface NotificationListFetchRequest extends PaginationCriteria, Partial<NotificationSearchDTO> {
}

export interface NotificationState extends ListAndDetailState<NotificationDTO, NotificationDTO> {
  lastSearchCriteria?: NotificationListFetchRequest;
  allList?: NotificationDTO[];
}

export type NotificationActions = GlobalActions |
{ type: 'Notifications.FetchRequested' } |  //Notification Hub
{ type: 'Notifications.ReadActionRequested', payload: { ids: string[], isRead: boolean } } |
{ type: 'Notifications.DoneActionRequested', payload: NotificationDTO } |
{ type: 'Notifications.ExtraActionRequested', payload: { id: number, action: string } } |
{ type: 'Notifications.DeleteActionRequested', payload: { ids: string[] } } |
{ type: 'Notifications.Loaded', payload: NotificationDTO[] } |
{ type: 'Notifications.Failed', payload: string } |
{ type: 'NotificationPage.FetchRequested', payload: NotificationListFetchRequest } |   //Notification Center
{ type: 'NotificationPage.Loaded', payload: Page<NotificationDTO> } |
{ type: 'NotificationPage.Failed', payload: string }
  ;

export function notificationReducer(state: NotificationState = emptyListAndDetailState(), action: NotificationActions): NotificationState {
  switch (action.type) {
    case 'Global.ClearCacheRequested':
      return emptyListAndDetailState();
    case 'Notifications.Loaded':
      return {
        ...state,
        allList: action.payload
      };
    case 'NotificationPage.FetchRequested':
      return {
        ...state,
        lastSearchCriteria: action.payload
      };
    case 'NotificationPage.Loaded':
      return {
        ...state,
        currentList: action.payload.content,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
      };
    default:
      return state;
  }
}

////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////// client sign /////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////

export type ClientSignActions = GlobalActions |
{ type: 'ClientSign.VerifyTokenAndStatusRequested', payload: { token: string } } |
{ type: 'ClientSign.Success', payload: { filename: string } } |
{ type: 'ClientSign.confirmSignRequested', payload: { token: string, signature: string, hasHKID: boolean, clientIdentificationDocNo: string, isHKID: string } } |
{ type: 'ClientSign.checkVerificationContactRequested', payload: { token: string, clientVerificationContact: string } } |
{ type: 'ClientSign.UpdateStage', payload: ClientSignStage } |
{ type: 'ClientSign.UpdateMessage', payload: string } |
{ type: 'ClientSign.NextActiveStep' } |
{ type: 'ClientSign.FormInfo.Loaded', payload: { formDetail: Partial<FormSigningInfoProps>, id: string, formName: string } } |
{ type: 'ClientSign.emailSentAlready' } |
{ type: 'ClientSign.ShowEmailInputBox' }
  ;

type ClientSignStage = 'pendingContactVerification' | 'verifyingContact' | 'inputIdentityDocNo' | 'signing' | 'generating' | 'linkExpired' | 'success' | 'index' | 'signed';

interface ClientSignMasterState extends FormSigningInfoProps {
  stage: ClientSignStage;
  formStatus: string;
  filename?: string;
  emailSent: boolean;
  activeStep: number;
  message?: string;
  showEmailInputBox?: boolean;

  formName?: string;
  id?: string; //pid or cid
}

const clientSignInitialState = () => ({
  stage: 'index' as ClientSignStage,
  formStatus: '',
  emailSent: false,
  activeStep: 0,
});

function clientSignReducer(state: ClientSignMasterState = clientSignInitialState(), action: ClientSignActions): ClientSignMasterState {
  switch (action.type) {
    case 'Global.ClearCacheRequested':
      return clientSignInitialState();
    case 'ClientSign.Success':
      return {
        ...state,
        stage: 'success',
        filename: action.payload.filename ?? '',
      }
    case 'ClientSign.UpdateStage':
      return {
        ...state,
        stage: action.payload,
      }
    case 'ClientSign.UpdateMessage':
      return {
        ...state,
        message: action.payload,
      }
    case 'ClientSign.NextActiveStep':
      return {
        ...state,
        activeStep: state.activeStep + 1,
      }
    case 'ClientSign.FormInfo.Loaded':
      return {
        ...state,
        ...action.payload.formDetail,
        id: action.payload.id,
        formName: action.payload.formName,
      }
    case 'ClientSign.emailSentAlready':
      return {
        ...state,
        emailSent: true,
      }
    case 'ClientSign.ShowEmailInputBox':
      return {
        ...state,
        showEmailInputBox: true,
      }
    default:
      return state;
  }
}

////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////// Reset Password ///////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////

export type ResetPasswordActions = GlobalActions |
{ type: 'ResetPassword.VerifyTokenRequested', payload: { token: string } } |
{ type: 'ResetPassword.PendingForUserInput' } |
{ type: 'ResetPassword.Success' } |
{ type: 'ResetPassword.Processing' } |
{ type: 'ResetPassword.LinkExpired' }
  ;

interface ResetPasswordMasterState {
  stage: string;
}

const ResetPasswordInitialState = () => ({
  stage: 'index',
});

function ResetPasswordReducer(state: ResetPasswordMasterState = ResetPasswordInitialState(), action: ResetPasswordActions): ResetPasswordMasterState {
  switch (action.type) {
    case 'Global.ClearCacheRequested':
      return ResetPasswordInitialState();
    case 'ResetPassword.Success':
      return {
        ...state,
        stage: 'success',
      }
    case 'ResetPassword.PendingForUserInput':
      return {
        ...state,
        stage: 'pendingForUserInput',
      }
    case 'ResetPassword.Processing':
      return {
        ...state,
        stage: 'processing',
      }
    case 'ResetPassword.LinkExpired':
      return {
        ...state,
        stage: 'linkExpired',
      }
    default:
      return state;
  }
}

////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////// Building Merge ///////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////


interface BuildingMergeDialogState {
  stage: 'select' | 'merge';
  mergeBuildingA?: BuildingListItemDTO;
  mergeBuildingB?: BuildingListItemDTO;
  addToAlias: boolean;
}

export type BuildingMergeActions = { type: 'BuildingMerge.SelectA', payload: BuildingListItemDTO } |
{ type: 'BuildingMerge.SelectB', payload: BuildingListItemDTO } |
{ type: 'BuildingMerge.Swap' } |
{ type: 'BuildingMerge.UpdateStage', payload: 'merge' | 'select' } |
{ type: 'BuildingMerge.UpdateAddToAlias', payload: boolean } |
{ type: 'BuildingMerge.MergeRequested' } |
{ type: 'BuildingMerge.Reset' };

const buildingMergeInitState = (): BuildingMergeDialogState => ({
  stage: 'select',
  addToAlias: true,
});

function buildingMergeReducer(state = buildingMergeInitState(), action: BuildingMergeActions): BuildingMergeDialogState {
  switch (action.type) {
    case 'BuildingMerge.Reset':
      return buildingMergeInitState();
    case 'BuildingMerge.Swap':
      return {
        ...state,
        mergeBuildingA: state.mergeBuildingB,
        mergeBuildingB: state.mergeBuildingA
      };
    case 'BuildingMerge.SelectA':
      return {
        ...state,
        mergeBuildingA: action.payload,
      };
    case 'BuildingMerge.SelectB':
      return {
        ...state,
        mergeBuildingB: action.payload,
      };
    case 'BuildingMerge.UpdateAddToAlias':
      return {
        ...state,
        addToAlias: action.payload,
      };
    case 'BuildingMerge.UpdateStage':
      return {
        ...state,
        stage: action.payload,
      }
    default:
      return state;
  }
}

////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////// Client Merge ////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////


interface ClientMergeDialogState {
  stage: 'select'; // no 'merge' stage for now
  mergeClientA?: ClientListItemDTO;
  mergeClientB?: ClientListItemDTO;
  mergeCandidates: ClientListItemDTO[];
  open: boolean;
  // addToAlias: boolean; // no need 'Alias'
}

export type ClientMergeActions = { type: 'ClientMerge.SelectA', payload: ClientListItemDTO } |
{ type: 'ClientMerge.SelectB', payload: ClientListItemDTO } |
{ type: 'ClientMerge.Swap' } |
{ type: 'ClientMerge.UpdateStage', payload: 'select' } |
{ type: 'ClientMerge.UpdateAddToAlias', payload: boolean } |
{ type: 'ClientMerge.MergeRequested' } |
{ type: 'ClientMerge.DialogOpen', payload: boolean } |
{ type: 'ClientMerge.Reset', payload: { mergeClientA?: ClientListItemDTO, mergeCandidates?: ClientListItemDTO[] } };

const clientMergeInitState = (mergeClientA?: ClientListItemDTO, mergeCandidates: ClientListItemDTO[] = []): ClientMergeDialogState => ({
  stage: 'select',
  mergeCandidates,
  mergeClientA,
  open: false,
});

function clientMergeReducer(state = clientMergeInitState(), action: ClientMergeActions): ClientMergeDialogState {
  switch (action.type) {
    case 'ClientMerge.DialogOpen':
      return { ...state, open: action.payload };
    case 'ClientMerge.Reset':
      return clientMergeInitState(action.payload.mergeClientA, action.payload.mergeCandidates ?? []);
    case 'ClientMerge.Swap':
      return {
        ...state,
        mergeClientA: state.mergeClientB,
        mergeClientB: state.mergeClientA
      };
    case 'ClientMerge.SelectA':
      return {
        ...state,
        mergeClientA: action.payload,
      };
    case 'ClientMerge.SelectB':
      return {
        ...state,
        mergeClientB: action.payload,
      };
    case 'ClientMerge.UpdateStage':
      return {
        ...state,
        stage: action.payload,
      }
    default:
      return state;
  }
}

////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////// Building Alias ///////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////

export interface BuildingAliasListState {
  aliases: BuildingAliasDTO[];
  aliasToAdd: Partial<BuildingAliasDTO>; // unused for now
  adding: boolean;
}

export type BuildingAliasListActions = { type: 'BuildingAliasList.Loaded', payload: { list: BuildingAliasDTO[] } } |
{ type: 'BuildingAliasList.DraftEdited', payload: Partial<BuildingAliasDTO> } |
{ type: 'BuildingAliasList.AddRequested', payload: BuildingAliasDTO } |
{ type: 'BuildingAliasList.RemoveRequested', payload: { aliasId: string, buildingId: string } } |
{ type: 'BuildingAliasList.FetchRequested', payload: { buildingId: string } } |
{ type: 'BuildingAliasList.Reset' } |
{ type: 'BuildingAliasList.ToggleAdding', payload: boolean }
  ;

const buildingAliasListInitState = (): BuildingAliasListState => ({
  aliases: [],
  aliasToAdd: {},
  adding: false,
});

function buildingAliasListReducer(state = buildingAliasListInitState(), action: BuildingAliasListActions): BuildingAliasListState {
  switch (action.type) {
    case 'BuildingAliasList.Loaded':
      return { ...state, aliases: action.payload.list };
    case 'BuildingAliasList.DraftEdited':
      return { ...state, aliasToAdd: action.payload };
    case 'BuildingAliasList.Reset':
      return buildingAliasListInitState();
    case 'BuildingAliasList.ToggleAdding':
      return { ...state, adding: action.payload };
    default:
      return state;
  }
}


////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////// Role Privilege /////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
export interface RolePrivilegeState extends ListAndDetailState<any, RoleDTO> {
  lastSearchCriteria?: any;
  resource?: ResourceDTO[];
}

export interface RoleSearchCriteria extends PaginationCriteria {

}

export type RolePrivilegeActions = GlobalActions |
//for role and its privilege
{ type: 'RolePrivilege.FetchRequested', payload: { roleId: string } } |
{ type: 'RolePrivilege.CreationRequested', payload: RoleDTO } |
{ type: 'RolePrivilege.UpdateRequested', payload: RoleDTO } |
{ type: 'RolePrivilege.Loaded', payload: { roleId: string, detail: RoleDTO } } |
{ type: 'RolePrivilege.Failed', payload: string } |

//role list
{ type: 'RoleList.FetchRequested', payload: RoleSearchCriteria | null } |
{ type: 'RoleList.Loaded', payload: Page<any> } |
{ type: 'RoleList.Failed', payload: string } |

{ type: 'Resource.FetchRequested' } |
{ type: 'Resource.Loaded', payload: ResourceDTO[] } |
{ type: 'Resource.Failed', payload: string }
  ;


// interface RolePrivilegeMasterState{
//   rolePrivilege: RolePrivilegeMap;
// }

export function rolePrivilegeReducer(state: RolePrivilegeState = emptyListAndDetailState(), action: RolePrivilegeActions): RolePrivilegeState {
  switch (action.type) {
    case 'Global.ClearCacheRequested':
      return emptyListAndDetailState();

    case 'RolePrivilege.Loaded':
      return {
        ...state,
        fetchedDetails: { ...state.fetchedDetails, [action.payload.roleId!]: action.payload.detail },
      };
    case 'RoleList.Loaded':
      return {
        ...state,
        currentList: action.payload.content,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
      };
    case 'Resource.Loaded':
      return {
        ...state,
        resource: action.payload,
      };
    default:
      return state;
  }
}


///////////////////////////////////////////////////////////////////////////////////
//////////////////////////// System Setting List Page /////////////////////////////
///////////////////////////////////////////////////////////////////////////////////

export interface SystemSettingListState extends ListAndDetailState<MasterDataDTO, MasterDataDTO> {
  lastSearchCriteria?: any;
}

export interface SystemSettingListSearchCriteria extends PaginationCriteria {

}

export type SystemSettingListActions = GlobalActions |
{ type: 'SystemSettingList.FetchRequested', payload: SystemSettingListSearchCriteria | null } |
{ type: 'SystemSettingList.Loaded', payload: Page<any> } |
{ type: 'SystemSettingList.Failed', payload: string } |

{ type: 'SystemSettingDetail.UpdateRequested', payload: MasterDataDTO } |
{ type: 'SystemSettingDetail.Loaded', payload: MasterDataDTO } |
{ type: 'SystemSettingDetail.CreationRequested', payload: MasterDataDTO }
  ;
// { type: 'SystemSettingList.FetchRequested', payload: { id: string } } |
// { type: 'SystemSettingList.UpdateRequested', payload: SystemSettingListItemDTO } |
// { type: 'SystemSettingList.CreationRequested', payload: SystemSettingListItemDTO } |
// { type: 'SystemSettingList.Loading' } |
// { type: 'SystemSettingList.Loaded', payload: SystemSettingListItemDTO } |
// { type: 'SystemSettingList.Failed', payload: string } ;

export function SystemSettingListReducer(state: SystemSettingListState = emptyListAndDetailState(), action: SystemSettingListActions): SystemSettingListState {
  switch (action.type) {
    case 'Global.ClearCacheRequested':
      return emptyListAndDetailState();
    case 'SystemSettingList.FetchRequested':
      return {
        ...state,
        lastSearchCriteria: action.payload,
      };
    case 'SystemSettingList.Loaded':
      return {
        ...state,
        currentList: action.payload.content,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
      };
    case 'SystemSettingDetail.Loaded':
      return {
        ...state,
        currentList: state.currentList.map((ss: MasterDataDTO) => ss.id === action.payload.id ? action.payload : ss),

      };
    default:
      return state;
  }
}

////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////// Dashboard /////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////

export enum DashboardKpiTypes {
  TOTAL_COMMISSION = 5,
  CLIENT_PROPERTY_VISIT = 6,
  NEW_CLIENTS = 1,
  NEW_CLIENT_UPDATES = 2,
  NEW_PROPERTIES = 3,
  NEW_PROPERTY_UPDATES = 4
}

export interface DashboardGraphViewModel {
  /** District labels. Transformed from DashboardAggregate.district via districtOptions[district] */
  labels: string[];
  datasets: {
    backgroundColor: string | string[];
    /** KPI Dataset. From DashboardAggregate.kpiValue */
    data: number[];
    fill: string;
    label?: string | string[];
    maxBarThickness?: number;
    // stack?: number;
    // labels: string[];
  }[];
}

export interface DashboardState {
  region: string;
  periodStart: string;
  periodEnd: string;

  /** Map<Integer, DashboardGraphViewModel> */
  graphData: Partial<Record<DashboardKpiTypes, DashboardGraphViewModel>>;
  allowedKpiTypeList: number[];


  dashboardPref: Array<DashboardPrefState>;

  lastModified?: string | undefined;
}

const dashboardInitialState = (): DashboardState => ({
  region: '',
  periodStart: '',
  periodEnd: '',
  graphData: {},
  allowedKpiTypeList: [],
  dashboardPref: [],
  // { kpiType: DashboardKpiTypes.NEW_CLIENTS, isVisible: true, sortOrder: 1 },
  //                { kpiType: DashboardKpiTypes.NEW_CLIENT_UPDATES, isVisible: true, sortOrder: 2 },
  //                { kpiType: DashboardKpiTypes.NEW_PROPERTIES, isVisible: true, sortOrder: 3 },
  //                { kpiType: DashboardKpiTypes.NEW_PROPERTY_UPDATES, isVisible: true, sortOrder: 4 },
  //                { kpiType: DashboardKpiTypes.TOTAL_COMMISSION, isVisible: true, sortOrder: 5 },
  //                { kpiType: DashboardKpiTypes.CLIENT_PROPERTY_VISIT, isVisible: true, sortOrder: 6 }],
  // category: 'NT',
  // periodStart: '202007',
  // periodEnd: '202007',
  // graphData: {},
});

export interface DashboardPrefState {
  kpiType: number;
  isVisible: boolean;
  sortOrder: number;
}


export type DashboardActions = { type: 'Dashboard.FetchRequested' } | // Saga Action
{ type: 'Dashboard.CriteriaUpdated', payload: { region?: string, periodStart?: string, periodEnd?: string } } |
{ type: 'Dashboard.DataUpdated', payload: { graphData: DashboardState['graphData'], allowedKpiTypeList: DashboardState['allowedKpiTypeList'], lastModified: string | undefined } } |
{ type: 'Dashboard.PrefUpdated', payload: { dashboardPref: DashboardState['dashboardPref'] } } |
{ type: 'Dashboard.PrefUpdatedAndSaved', payload: { dashboardPref: DashboardState['dashboardPref'] } };

export function dashboardReducer(state = dashboardInitialState(), action: DashboardActions): DashboardState {
  switch (action.type) {
    case 'Dashboard.CriteriaUpdated':
      return { ...state, ...action.payload };
    case 'Dashboard.DataUpdated':
      return { ...state, graphData: action.payload.graphData, allowedKpiTypeList: action.payload.allowedKpiTypeList, lastModified: action.payload.lastModified };
    case 'Dashboard.PrefUpdated':
      return { ...state, dashboardPref: action.payload.dashboardPref };
    case 'Dashboard.PrefUpdatedAndSaved':
      return { ...state, dashboardPref: action.payload.dashboardPref };
    default:
      return state;
  }
}


///////////////////////////////////////////////////////////////////////////////////
///////////////////// CA Feature Tag Management page /////////////////////////////
///////////////////////////////////////////////////////////////////////////////////

export interface FeatureTagListState {
  currentList?: any;
}


export type FeatureTagListActions = GlobalActions |
{ type: 'FeatureTagList.FetchRequested' } |
{ type: 'FeatureTagList.UpdateRequested', payload: DiffUpdateSpec<CAFeatureTagDTO> } |
{ type: 'FeatureTagList.Loaded', payload: CAFeatureTagDTO[] } |
{ type: 'FeatureTagList.Failed', payload: string }
  ;

export function FeatureTagListReducer(state: FeatureTagListState = { currentList: [] }, action: FeatureTagListActions): FeatureTagListState {
  switch (action.type) {
    case 'Global.ClearCacheRequested':
      return emptyListAndDetailState();

    case 'FeatureTagList.Loaded':
      return {
        ...state,
        currentList: action.payload,
      };
    default:
      return state;
  }
}


//////////////////////////////////////////////////////////////////////////////
/////////////////////////////// AgentRatingCommentMgt /////////////////////////////
//////////////////////////////////////////////////////////////////////////////
export interface AgentRatingCommentSearchCritiera extends PaginationCriteria {
  agentId?: string;
  agentNameEn?: string,
  agentNameZh?: string,
  customerName?: string;
  reviewStatus?: string[];
  submitDate?: (string | null)[];
}

export type AgentRatingCommentActions = GlobalActions |
{ type: 'AgentRatingCommentList.FetchRequested', payload: AgentRatingCommentSearchCritiera } |
{ type: 'AgentRatingCommentList.Loading' } |
{ type: 'AgentRatingCommentList.Loaded', payload: Page<CAAgentRatingCommentDTO> } |
{ type: 'AgentRatingCommentList.Failed', payload: string } |

{ type: 'AgentRatingComment.ApproveActionRequested', payload: { ids: string[] } } |
{ type: 'AgentRatingComment.RejectActionRequested', payload: { ids: string[] } }
  ;

export interface AgentRatingCommentMgtMasterState extends ListAndDetailState<CAAgentRatingCommentDTO, any> {
  lastSearchCriteria?: AgentRatingCommentSearchCritiera;
}

export function agentRatingCommentMgtReducer(state: AgentRatingCommentMgtMasterState = {
  currentList: [],
  currentPage: 0,
  totalPages: 0,
  totalElements: 0,
  currentMatchingList: [],
  currentMatchingPage: 0,
  totalMatchingPages: 0,
  totalMatchingElements: 0,
  fetchedDetails: {},
  fetchedDetailsByPropertyStockId: {},
}, action: AgentRatingCommentActions): AgentRatingCommentMgtMasterState {
  switch (action.type) {
    case 'Global.ClearCacheRequested':
      return emptyListAndDetailState();
    case 'AgentRatingCommentList.FetchRequested':
      return { ...state, lastSearchCriteria: action.payload };
    case 'AgentRatingCommentList.Loaded':
      return {
        ...state,
        currentList: action.payload.content,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
      };
    default:
      return state;
  }
}

export type JobMonitorActions = { type: 'JobMonitor.DataUpdated', payload: JobDTO[] } |
{ type: 'JobMonitor.FetchRequested' }
  ;

export interface JobMonitorState {
  jobs: JobDTO[];
}

export const jobMonitorInitials = () => ({
  jobs: [],
});

export function jobMonitorReducer(state: JobMonitorState = jobMonitorInitials(), action: JobMonitorActions) {
  switch (action.type) {
    case 'JobMonitor.DataUpdated':
      return { jobs: action.payload };
    default:
      return state;
  }
}