import { hasPermission } from "common/access-control";
import { BuildingDetailDTO } from "common/dto";
import NavigationBlockerDialog from 'common/elements/NavigationBlockerDialog';
import { useForm } from "common/hooks";
import deepEqual from "deep-equal";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router";
import { IRootState, PASDispatch } from "reducers";
import buildingFormValidations from "./building-validations";
import BuildingDetail from "./BuildingDetail";

const EMPTY = {};

export default function BuildingUpdatePage() {
  const dispatch = useDispatch() as PASDispatch;

  const { id }: any = useParams();
  const buildingDetailDto = useSelector((state: IRootState) => state.building.fetchedDetails[id!]) ?? EMPTY as Partial<BuildingDetailDTO>;
  const { locale, langBuilding } = useSelector((state: IRootState) => state.locale);

  const { privileges } = useSelector((state: IRootState) => state.login);
  // const canRead = hasPermission(privileges, 'READ', 'BUILDING');
  const canUpdate = hasPermission(privileges, 'UPDATE', 'BUILDING');
  const canCreate = hasPermission(privileges, 'CREATE', 'BUILDING');
  
  const form = useForm<Partial<BuildingDetailDTO>>({
    ...buildingDetailDto
  }, {
    validations: buildingFormValidations(langBuilding)
  })
  
  const tagForm = useForm<any>({
    CLUB_FACILITIES: [],
    OTHERS: []
  });

  const floorForm = useForm<any>({
    floorDefinition: []
  })

  useEffect(() => {
    dispatch({ type: 'Building.FetchRequested', payload: { id: id ?? ''}});
  }, [ id, locale ]);
  
  useEffect(() => {
    const initialFormValues = {
      ...buildingDetailDto
    };

    const initialTagFormValues = {
      ...tagForm.values,
      CLUB_FACILITIES: buildingDetailDto.clubHouseFacilities ?? [],
      OTHERS: buildingDetailDto.others ?? []
    };

    const initialFloorFormValues = {
      ...floorForm.values,
      floorDefinition: buildingDetailDto.floorDefinition ?? []
    }

    form.setValues(initialFormValues);

    tagForm.setValues(initialTagFormValues);

    floorForm.setValues(initialFloorFormValues);

    setInitialFormValues([ initialFormValues, initialTagFormValues, initialFloorFormValues ]);

  }, [ buildingDetailDto]);

  useEffect(() => {
    form.setValues({
      ...form.values,
      floorDefinition : floorForm.values.floorDefinition
    })
  }, [floorForm.values]);
  // useEffect(() => {
  //   tagForm.setValues({
  //     ...tagForm.values,
  //     CLUB_FACILITIES: form.values.clubHouseFacilities ?? [],
  //     OTHERS: form.values.others ?? []
  //   });
  // }, [buildingDetailDto.clubHouseFacilities, buildingDetailDto.others]);

  // useEffect(() => {
  //   floorForm.setValues({
  //     ...floorForm.values,
  //     floorDefinition: form.values.floorDefinition ?? []
  //   });
  // }, [buildingDetailDto.floorDefinition]);

  const [ initialFormValues, setInitialFormValues ] = useState<any>({});
  const unsaved = !deepEqual([ form.values, tagForm.values, floorForm.values ], initialFormValues, { strict: true });
  
  const assembleFormToDto = () => {
    const updatedResult = {
      ...form.values,
      lowLevel: form.values.lowLevel?.replace(/\s/g, ""),
      middleLevel: form.values.middleLevel?.replace(/\s/g, ""),
      highLevel: form.values.highLevel?.replace(/\s/g, ""),
      floorDefinition: floorForm.values.floorDefinition.map((f: any, _: any) => true ? { ...f, floor: f.floor.replace(/\s/g, ""), unit: f.unit.replace(/\s/g, "") } : f),
      clubHouseFacilities: tagForm.values.CLUB_FACILITIES,
      others: tagForm.values.OTHERS
    } as Record<string, any>;
    Object.keys(updatedResult).forEach(key => {
      if (typeof updatedResult[key as keyof BuildingDetailDTO] === "string" && updatedResult[key as keyof BuildingDetailDTO].trim() === "") {
        updatedResult[key as keyof BuildingDetailDTO] = null;
      }
    });
    return (updatedResult as BuildingDetailDTO);
  }

  return <div>
    <NavigationBlockerDialog matchesUrl={`/buildings/${id}`} unsaved={unsaved && canUpdate} goBack />
    <BuildingDetail unsaved={unsaved} onSave={() => {
      if (!form.validate()) {
        return;
      }
      dispatch({ type: 'Building.UpdateRequested', payload: assembleFormToDto() });
    }} form={form} tagForm={tagForm} floorForm={floorForm} canEdit={canUpdate} canCreate={canCreate} />
  </div>
}