import { useAppBarHeight } from 'common/hooks';
import React from 'react';

export default function FixedTabsContainer(props: React.PropsWithChildren<{}>) {
  const tabBarTop = useAppBarHeight();

  return <div>
    <div style={{ position: 'fixed', top: tabBarTop, left: 0, paddingLeft: 32, width: '100%', background: '#fff', zIndex: 1000 }}>
      {props.children}
    </div>
    <div style={{ height: 48 }}></div>
  </div>
}