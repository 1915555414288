import { AgreementClientDTO, SalePurchaseAgreementDTO, TenancyAgreementDTO } from "common/dto";
import { FormValidations } from "common/hooks";
import { isNonEmpty } from 'common/utils';
import { isEmpty } from "lodash";
import { isNullOrUndefined } from "util";

type Options = { [key: string]: string }

const AgreementLocaleValidations = (lang: Options): FormValidations<{ agreementLocale?: string | undefined }> => [
    (values: { agreementLocale?: string | undefined }) => !isNullOrUndefined(values.agreementLocale) ? null : [
        ['agreementLocale', lang.pleaseInput + lang.captionAgreementLocale],
    ],
];

const getInvalidAgreementClientIndexArray = (formValues: any, key: string) => {
    const invalidClient = formValues[key]?.map((c: AgreementClientDTO, idx: string) => (isNonEmpty(c.name) && isNonEmpty(c.identityType) && isNonEmpty(c.identityNumber)) ? -1 : idx).filter((idx: number) => idx > -1);
    return invalidClient ?? [];
}

export const TenacnyAgreementValidations = (langTenancyAgreement: Options): FormValidations<TenancyAgreementDTO> => [
    ...AgreementLocaleValidations(langTenancyAgreement),
    (values) => values.landlordAgentType === 'E' && values.tenantAgentType === 'E' ? [
        ['invalidTenantOrLandlordAgentType', langTenancyAgreement.msgInvalidTenantOrLandlordAgentType],
    ] : null,
    (values) => values.landlordAgentType ? null : [
        ['landlordAgentType', langTenancyAgreement.pleaseSelect + langTenancyAgreement.agentType],
    ],
    (values) => values.landlordAgentName ? null : [
        ['landlordAgentName', langTenancyAgreement.pleaseInput + langTenancyAgreement.landlordAgentName],
    ],
    (values) => values.landlordAgentLicenseNumber ? null : [
        ['landlordAgentLicenseNumber', langTenancyAgreement.pleaseInput + langTenancyAgreement.agentLicenseNumber],
    ],
    (values) => values.tenantAgentType ? null : [
        ['tenantAgentType', langTenancyAgreement.pleaseSelect + langTenancyAgreement.agentType],
    ],
    (values) => values.tenantAgentName ? null : [
        ['tenantAgentName', langTenancyAgreement.pleaseInput + langTenancyAgreement.tenantAgentName],
    ],
    (values) => values.tenantAgentLicenseNumber ? null : [
        ['tenantAgentLicenseNumber', langTenancyAgreement.pleaseInput + langTenancyAgreement.agentLicenseNumber],
    ],
    (values) => values.agreementDate ? null : [
        ['agreementDate', langTenancyAgreement.pleaseSelect + langTenancyAgreement.titleAgreementDate],
    ],
    (values) => values.agentFor ? null : [
        ['agentFor', langTenancyAgreement.pleaseSelect],
    ],
    //Allow empty Landlord when Agent is external
    (values) => (((values.landlords ?? []).length ?? 0) > 0 || values.landlordAgentType === 'E') ? null : [
        ['landlords', langTenancyAgreement.msgAtLeastOneLandlord],
    ],
    // (values) => getInvalidAgreementClientIndexArray(values, 'landlords').length == 0 ? null : [
    //     ['invalidLandlords', getInvalidAgreementClientIndexArray(values, 'landlords').join('_')],
    // ],
    (values) => ((values.tenants ?? []).length ?? 0) > 0 ? null : [
        ['tenants', langTenancyAgreement.msgAtLeastOneTenant],
    ],
    (values) => getInvalidAgreementClientIndexArray(values, 'tenants').length == 0 ? null : [
        ['invalidTenants', getInvalidAgreementClientIndexArray(values, 'tenants').join('_')],
    ],
    (values) => values.agentName ? null : [
        ['agentName', langTenancyAgreement.pleaseInput + langTenancyAgreement.agentName],
    ],
    (values) => values.agentBusinessRegNo ? null : [
        ['agentBusinessRegNo', langTenancyAgreement.pleaseInput + langTenancyAgreement.agentBusinessRegNo],
    ],
    (values) => values.agentLicenceNo ? null : [
        ['agentLicenceNo', langTenancyAgreement.pleaseInput + langTenancyAgreement.agentLicenceNo],
    ],
    (values) => isNonEmpty(values.agentAddress) ? null : [
        ['agentAddress', langTenancyAgreement.pleaseInput + langTenancyAgreement.agentAddress],
    ],
    // (values) => values.tenantAgentName ? null : [
    //     ['tenantAgentName', langTenancyAgreement.pleaseInput + langTenancyAgreement.tenantAgentName],
    // ],
    // (values) => values.tenantAgentContact ? null : [
    //     ['tenantAgentContact', langTenancyAgreement.pleaseInput + langTenancyAgreement.tenantAgentContact],
    // ],
    (values) => isNonEmpty(values.premises) ? null : [
        ['premises', langTenancyAgreement.pleaseInput + langTenancyAgreement.titlePremises],
    ],
    (values) => isNonEmpty(values.monthlyRental) && !isNaN(values.monthlyRental ?? NaN) ? null : [
        ['monthlyRental', langTenancyAgreement.pleaseInput + langTenancyAgreement.titleMonthlyRental],
    ],
    (values) => ((isNonEmpty(values.deposit) && !isNaN(values.deposit ?? NaN)) || values.deposit === 0) ? null : [
        ['deposit', langTenancyAgreement.pleaseInput + langTenancyAgreement.titleDeposit],
    ],
    (values) => ((isNonEmpty(values.initialDeposit) && !isNaN(values.initialDeposit ?? NaN)) || values.initialDeposit === 0) ? null : [
        ['initialDeposit', langTenancyAgreement.pleaseInput + langTenancyAgreement.titleInitialDeposit],
    ],
    (values) => values.tenancyFrom ? null : [
        ['tenancyFrom', langTenancyAgreement.pleaseSelect],
    ],
    (values) => values.tenancyTo ? null : [
        ['tenancyTo', langTenancyAgreement.pleaseSelect],
    ],
    // (values) => values.breakClauseMonthInAdvance ? null : [
    //     ['breakClauseMonthInAdvance', langTenancyAgreement.pleaseInput ],
    // ],
    // (values) => values.breakClauseRentInLieu ? null : [
    //     ['breakClauseRentInLieu', langTenancyAgreement.pleaseInput ],
    // ],
    // (values) => values.breakClauseRentForAtLeast ? null : [
    //     ['breakClauseRentForAtLeast', langTenancyAgreement.pleaseInput ],
    // ],
    (values) => values.vacantPossessionDeliveryDate ? null : [
        ['vacantPossessionDeliveryDate', langTenancyAgreement.pleaseSelect],
    ],
    (values) => values.premisesUsage ? null : [
        ['premisesUsage', langTenancyAgreement.pleaseInput + langTenancyAgreement.titlePremisesUsage],
    ],
    (values) => values.formalAgreementSignDeadline ? null : [
        ['formalAgreementSignDeadline', langTenancyAgreement.pleaseSelect],
    ],
    (values) => values.signAt ? null : [
        ['signAt', langTenancyAgreement.pleaseInput],
    ],
    // (values) => values.landlordCommission ? null : [
    //     ['landlordCommission', langTenancyAgreement.pleaseInput + langTenancyAgreement.landlordCommission],
    // ],
    (values) => ((isNonEmpty(values.tenantCommission) && !isNaN(values.tenantCommission ?? NaN)) || values.tenantCommission === 0) ? null : [
        ['tenantCommission', langTenancyAgreement.pleaseInput + langTenancyAgreement.tenantCommission],
    ],
    (values) => values.commissionPaymentDeadline ? null : [
        ['commissionPaymentDeadline', langTenancyAgreement.pleaseSelect + langTenancyAgreement.commissionPaymentDeadline],
    ],
    (values) => ((isNonEmpty(values.compensationToAgent) && !isNaN(values.compensationToAgent ?? NaN)) || values.compensationToAgent === 0) ? null : [
        ['compensationToAgent', langTenancyAgreement.pleaseInput + langTenancyAgreement.compensation],
    ],
    // (values) => (!isNonEmpty(values.compensationToAgent) || isEmpty(values.compensationToAgent)) ? [
    //     ['compensationToAgent', langTenancyAgreement.pleaseInput + langTenancyAgreement.compensation],
    // ] : null,
    // (values) => values.prevailingVersion ? null : [
    //     ['prevailingVersion', langTenancyAgreement.pleaseSelect + langTenancyAgreement.titlePrevailingVersion],
    // ],
    // (values) => values.remarks ? null : [
    //     ['remarks', langTenancyAgreement.pleaseInput + langTenancyAgreement.remarks],
    // ],
    (values) => (!values.isCheque || values.isCheque && isNonEmpty(values.chequeNo)) ? null : [
        ['chequeNo', langTenancyAgreement.pleaseInput + langTenancyAgreement.chequeNo],
    ],
    (values) => (!values.isCheque || values.isCheque && isNonEmpty(values.chequeBank)) ? null : [
        ['chequeBank', langTenancyAgreement.pleaseInput + langTenancyAgreement.chequeBank],
    ],
];

export const SalePurchaseAgreementValidations = (langSalePurchaseAgreement: Options): FormValidations<SalePurchaseAgreementDTO> => [
    ...AgreementLocaleValidations(langSalePurchaseAgreement),
    (values) => values.sellerAgentType === 'E' && values.buyerAgentType === 'E' ? [
        ['invalidBuyerOrSellerAgentType', langSalePurchaseAgreement.msgInvalidBuyerOrSellerAgentType],
    ] : null,
    (values) => values.sellerAgentType ? null : [
        ['sellerAgentType', langSalePurchaseAgreement.pleaseSelect + langSalePurchaseAgreement.agentType],
    ],
    (values) => values.sellerAgentName ? null : [
        ['sellerAgentName', langSalePurchaseAgreement.pleaseInput + langSalePurchaseAgreement.sellerAgentName],
    ],
    (values) => values.sellerAgentLicenseNumber ? null : [
        ['sellerAgentLicenseNumber', langSalePurchaseAgreement.pleaseInput + langSalePurchaseAgreement.agentLicenseNumber],
    ],
    (values) => values.buyerAgentType ? null : [
        ['buyerAgentType', langSalePurchaseAgreement.pleaseSelect + langSalePurchaseAgreement.agentType],
    ],
    (values) => values.buyerAgentName ? null : [
        ['buyerAgentName', langSalePurchaseAgreement.pleaseInput + langSalePurchaseAgreement.buyerAgentName],
    ],
    (values) => values.buyerAgentLicenseNumber ? null : [
        ['buyerAgentLicenseNumber', langSalePurchaseAgreement.pleaseInput + langSalePurchaseAgreement.agentLicenseNumber],
    ],
    (values) => values.agreementDate ? null : [
        ['agreementDate', langSalePurchaseAgreement.pleaseSelect + langSalePurchaseAgreement.titleAgreementDate],
    ],
    //allow empty vendor when Agent is external
    (values) => (((values.vendors ?? []).length ?? 0) > 0 || values.sellerAgentType === 'E') ? null : [
        ['vendors', langSalePurchaseAgreement.msgAtLeastOneVendor],
    ],
    // (values) => getInvalidAgreementClientIndexArray(values, 'vendors').length == 0 ? null : [
    //     ['invalidVendor', getInvalidAgreementClientIndexArray(values, 'vendors').join('_')],
    // ],
    (values) => ((values.purchasers ?? []).length ?? 0) > 0 ? null : [
        ['purchasers', langSalePurchaseAgreement.msgAtLeastOnePurchaser],
    ],
    (values) => getInvalidAgreementClientIndexArray(values, 'purchasers').length == 0 ? null : [
        ['invalidPurchasers', getInvalidAgreementClientIndexArray(values, 'purchasers').join('_')],
    ],
    (values) => values.agentFor ? null : [
        ['agentFor', langSalePurchaseAgreement.pleaseSelect],
    ],
    (values) => values.agentName ? null : [
        ['agentName', langSalePurchaseAgreement.pleaseInput + langSalePurchaseAgreement.agentName],
    ],
    (values) => values.agentBusinessRegNo ? null : [
        ['agentBusinessRegNo', langSalePurchaseAgreement.pleaseInput + langSalePurchaseAgreement.agentBusinessRegNo],
    ],
    (values) => values.agentLicenceNo ? null : [
        ['agentLicenceNo', langSalePurchaseAgreement.pleaseInput + langSalePurchaseAgreement.agentLicenceNo],
    ],
    (values) => isNonEmpty(values.agentAddress) ? null : [
        ['agentAddress', langSalePurchaseAgreement.pleaseInput + langSalePurchaseAgreement.agentAddress],
    ],
    // (values) => values.buyerAgentName ? null : [
    //     ['buyerAgentName', langSalePurchaseAgreement.pleaseInput + langSalePurchaseAgreement.buyerAgentName],
    // ],
    // (values) => values.buyerAgentContact ? null : [
    //     ['buyerAgentContact', langSalePurchaseAgreement.pleaseInput + langSalePurchaseAgreement.buyerAgentContact],
    // ],
    (values) => isNonEmpty(values.premises) ? null : [
        ['premises', langSalePurchaseAgreement.pleaseInput + langSalePurchaseAgreement.titlePremises],
    ],
    (values) => isNonEmpty(values.purchasePrice) ? null : [
        ['purchasePrice', langSalePurchaseAgreement.pleaseInput + langSalePurchaseAgreement.purchasePrice],
    ],
    (values) => (isNonEmpty(values.initialDeposit) || values.initialDeposit === 0) ? null : [
        ['initialDeposit', langSalePurchaseAgreement.pleaseInput + langSalePurchaseAgreement.initialDeposit],
    ],
    (values) => values.formalAgreementSignDeadline ? null : [
        ['formalAgreementSignDeadline', langSalePurchaseAgreement.pleaseSelect + langSalePurchaseAgreement.formalAgreementSignDeadline],
    ],
    (values) => (isNonEmpty(values.fullDeposit) || values.fullDeposit === 0) ? null : [
        ['fullDeposit', langSalePurchaseAgreement.pleaseInput + langSalePurchaseAgreement.fullDeposit],
    ],
    (values) => values.fullDepositDate ? null : [
        ['fullDepositDate', langSalePurchaseAgreement.pleaseSelect + langSalePurchaseAgreement.fullDepositDate],
    ],
    (values) => values.paymentCompletionDate ? null : [
        ['paymentCompletionDate', langSalePurchaseAgreement.pleaseSelect + langSalePurchaseAgreement.paymentCompletionDate],
    ],
    (values) => isNullOrUndefined(values.remainingSum) ? ([
        ['remainingSum', langSalePurchaseAgreement.pleaseInput + langSalePurchaseAgreement.remainingSum],
    ]) : ((values.purchasePrice ?? 0) - (values.initialDeposit ?? 0) - (values.fullDeposit ?? 0) >= 0 ? null : [
        ['purchasePrice', langSalePurchaseAgreement.msgInvalidRemainingSum],
        ['initialDeposit', langSalePurchaseAgreement.msgInvalidRemainingSum],
        ['fullDeposit', langSalePurchaseAgreement.msgInvalidRemainingSum],
    ]),
    (values) => values.vacantPossessionTerm ? null : [
        ['vacantPossessionTerm', langSalePurchaseAgreement.pleaseInput + langSalePurchaseAgreement.titleVacantPossessionTerm],
    ],
    // (values) => values.vendorSolicitor ? null : [
    //     ['vendorSolicitor', langSalePurchaseAgreement.pleaseInput + langSalePurchaseAgreement.vendorSolicitor],
    // ],
    (values) => values.purchaserSolicitor ? null : [
        ['purchaserSolicitor', langSalePurchaseAgreement.pleaseInput + langSalePurchaseAgreement.purchaserSolicitor],
    ],
    // (values) => values.vendorCommission ? null : [
    //     ['vendorCommission', langSalePurchaseAgreement.pleaseInput + langSalePurchaseAgreement.vendorCommission],
    // ],
    (values) => (isNonEmpty(values.purchaserCommission) || values.purchaserCommission === 0) ? null : [
        ['purchaserCommission', langSalePurchaseAgreement.pleaseInput + langSalePurchaseAgreement.purchaserCommission],
    ],
    (values) => values.commissionPaymentDeadline ? null : [
        ['commissionPaymentDeadline', langSalePurchaseAgreement.pleaseSelect + langSalePurchaseAgreement.commissionPaymentDeadline],
    ],
    // (values) => (isNonEmpty(values.compensationToAgent) || values.compensationToAgent === 0) ? null : [
    //     ['compensationToAgent', langSalePurchaseAgreement.pleaseInput + langSalePurchaseAgreement.compensation],
    // ],
    (values) => (isNonEmpty(values.compensationToAgent) || !isNaN(values.compensationToAgent ?? NaN) || values.compensationToAgent == 0) ? null : [
        ['compensationToAgent', langSalePurchaseAgreement.pleaseInput + langSalePurchaseAgreement.compensation],
    ],
    (values) => !isNullOrUndefined(values.isResidential) ? null : [
        ['isResidential', langSalePurchaseAgreement.pleaseSelect + langSalePurchaseAgreement.isResidential],
    ],
    // (values) => values.prevailingVersion ? null : [
    //     ['prevailingVersion', langSalePurchaseAgreement.pleaseSelect + langSalePurchaseAgreement.titlePrevailingVersion],
    // ],
    (values) => (isNonEmpty(values.certificateOfValue) || values.certificateOfValue === 0) ? null : [
        ['certificateOfValue', langSalePurchaseAgreement.pleaseInput + langSalePurchaseAgreement.certificateOfValue],
    ],
    // (values) => values.remarks ? null : [
    //     ['remarks', langSalePurchaseAgreement.pleaseInput + langSalePurchaseAgreement.remarks],
    // ],
    (values) => (!values.isCheque || values.isCheque && isNonEmpty(values.chequeNo)) ? null : [
        ['chequeNo', langSalePurchaseAgreement.pleaseInput + langSalePurchaseAgreement.chequeNo],
    ],
    (values) => (!values.isCheque || values.isCheque && isNonEmpty(values.chequeBank)) ? null : [
        ['chequeBank', langSalePurchaseAgreement.pleaseInput + langSalePurchaseAgreement.chequeBank],
    ],
];
