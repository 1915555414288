import {
    Button as MuiButton, Card,
    CardActions,
    CardContent, colors, Divider,
    Table,
    TableBody,
    TableCell,
    TableHead, TableRow, TableSortLabel,
    // TablePagination,
    Theme
} from '@material-ui/core';
import { CardProps } from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useConfirmDialog } from 'common/ConfirmDialog';
import { MasterDataDTO, RoleDTO } from 'common/dto';
import DataRow from 'common/elements/DataRow';
import TablePagination from 'common/elements/TablePagination';
import { useForm } from 'common/hooks';
import { tooltipHoc } from 'common/ui';
import moment from 'moment';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState, PASDispatch } from 'reducers';
import SystemSettingFormValidations from '../system-setting-validation';
import SystemSettingDetailDialog from './SystemSettingDetailDialog';

const Button = tooltipHoc(MuiButton);

const useStyles = makeStyles((theme: Theme) => ({
	root: {},
	content: {
		padding: 0
	},
	inner: {
		minWidth: 1050
	},
	nameContainer: {
		display: 'flex',
		alignItems: 'center'
	},
	avatar: {
		marginRight: theme.spacing(2)
	},
	actions: {
		justifyContent: 'flex-start',
		background: colors.grey[50],
		flexWrap: 'wrap',
    '& > *': {
      wordBreak: 'keep-all',
      // marginBottom: '8px',
    },
    padding: '0px',
	},
	tableRow: {
		cursor: 'pointer',
	},
	tableCell: {
		wordWrap: 'normal',
		wordBreak: 'keep-all',
		lineBreak: 'strict',
		whiteSpace: 'nowrap',
		// minWidth: 128,
	},
	tablePaginationIconButton:{
    '& .MuiIconButton-root':{
      padding: '6px',
    }
	},
	tablePaginationToolbar:{
    paddingRight: '12px',
  },
  clearSortingContainer: {
    padding: '3px',
  }
}));
  
type Header = {
	title: string | JSX.Element,
	overrideStyle?: any,
	sortDisabled?: boolean;
};

interface RoleTableProps extends CardProps {
  className?: string;
  systemSettings: MasterDataDTO[];

  page: number;
	totalPages: number;
	totalElements: number;
  limit: number;
  setPage: (page: number) => void;
  sortOrders?: { [k: string]: 'desc' | 'asc' };
  handleSort?: (key: string | null) => void;
}

const SystemSettingTable = (props: RoleTableProps) => {
	const { className, systemSettings, sortOrders, handleSort, page, totalPages, totalElements, limit, setPage, ...rest } = props;
	const { langSystemSetting, lang, statusOptions, } = useSelector((state: IRootState) => state.locale);
  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);
  const dispatch = useDispatch() as PASDispatch;
	const confirmDialog = useConfirmDialog();


	const classes = useStyles(0);
	
	let tableHeaders: Record<any, Header | null> = { // keyof RoleDTO
    id: { title: '#'},
    module: { title: langSystemSetting.module },
    type: { title: langSystemSetting.type },
		key: { title: langSystemSetting.key },
		value: { title: langSystemSetting.value },
    dataStatus: { title: langSystemSetting.dataStatus },
    dateModified: {title: lang.dateModified},
    modifiedBy: {title: lang.modifiedBy},
	};


	const handleNavigateToItem = (systemSetting: MasterDataDTO) => () => {
    
    systemSettingForm.setValues(systemSetting);
    handleClickOpenDetailDialog();
	}

	const handlePageChange = (event: React.MouseEvent | null, page: number) => {
		setPage(page);
	};
	
	const overrideCols: { [col: string]: (systemSettings: MasterDataDTO) => any } = {
    value: systemSetting => (systemSetting.value?.length ?? 0) > 40 ? systemSetting.value?.substring(0,39) + '...' :  systemSetting.value,
    dataStatus: systemSetting => systemSetting.dataStatus ? statusOptions[systemSetting.dataStatus] : '',
    dateModified: (systemSetting) => systemSetting.dateModified ? moment(systemSetting.dateModified).format(DISPLAY_DATE_FORMAT) : '',
  };

  const [] = React.useState<MasterDataDTO>({});

  const [openDetailDialog, setOpenDetailDialog] = React.useState(false);

  const handleClickOpenDetailDialog = () => {
    setOpenDetailDialog(true);
  };

  const handleCloseDetailDialog = () => {
    setOpenDetailDialog(false);
  };

  const systemSettingForm = useForm<MasterDataDTO>({},{validations: SystemSettingFormValidations(langSystemSetting)});

  const handleSaveSystemSetting = () =>{
		if (!systemSettingForm.validate()){
      return;
    }
    dispatch({type: 'SystemSettingDetail.UpdateRequested', payload: systemSettingForm.values ?? {}});
    handleCloseDetailDialog();
  }

	return ( 
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>	
			{confirmDialog.render()}
      <SystemSettingDetailDialog onSave={handleSaveSystemSetting} systemSettingForm={systemSettingForm} open={openDetailDialog} handleClickOpen={handleClickOpenDetailDialog} handleClose={handleCloseDetailDialog}/>
			<CardActions className={classes.actions}>
				<div style={{ flexGrow: 1 }} /> 
				{/* {Object.keys(sortOrders ?? {}).length ?
          <div className={classes.clearSortingContainer}>
						<Button style={{ marginRight: 0 }} variant="outlined" size="small" onClick={() => handleSort?.(null)}>{lang.actionClearSorting}</Button>
          </div>
				: null} */}

				<TablePagination
					classes={{
						actions: classes.tablePaginationIconButton,
            toolbar: classes.tablePaginationToolbar,
					}}
					// component="div"
					count={totalElements}
					onChangePage={handlePageChange}
					// onChangeRowsPerPage={handleRowsPerPageChange}
					page={page}
					totalPages={totalPages}
					rowsPerPage={limit}
					labelRowsPerPage={lang.rowsCount}
					rowsPerPageOptions={[20]}
				/>
			</CardActions>

			<Divider />

			<CardContent className={classes.content}>
				<PerfectScrollbar>
					<div className={classes.inner}>
						<Table size="small">
							<TableHead>
								<TableRow>
									{
										Object.keys(tableHeaders).filter((key) => !!tableHeaders[key as keyof RoleDTO]).map((key,idx) =>
											<TableCell key={idx} className={classes.tableCell} style={tableHeaders[key as keyof RoleDTO]?.overrideStyle}>
												{tableHeaders[key as keyof RoleDTO]?.sortDisabled ? tableHeaders[key as keyof RoleDTO]?.title : <TableSortLabel
													active={sortOrders?.[key] !== undefined}
													direction={sortOrders?.[key]}
													onClick={() => handleSort?.(key)}
												>
													{tableHeaders[key as keyof RoleDTO]?.title}
												</TableSortLabel>}
											</TableCell>
										)
									}
								</TableRow>
								{Object.keys(sortOrders ?? {}).length ? <TableRow>
								</TableRow> : null}
							</TableHead>
							<TableBody>
								{systemSettings.map(systemSetting => (
									<DataRow
										className={classes.tableRow}
										hover
										key={'row-' + systemSetting.id }
										// selected={selectedProperties.indexOf(property.id!) !== -1}
										onClick={handleNavigateToItem(systemSetting)}
									>
										{
											Object.keys(tableHeaders).filter((key) => !!tableHeaders[key as keyof MasterDataDTO]).map((key) => {
												// 
												return <TableCell className={classes.tableCell}  key={`${systemSetting.id}-${key}`}>{overrideCols[key] ? overrideCols[key](systemSetting) : systemSetting[key as keyof MasterDataDTO]}</TableCell>
											})
										}
									</DataRow>
								))}
							</TableBody>
						</Table>
					</div>
				</PerfectScrollbar>
			</CardContent>
		</Card>
	);
}

export default SystemSettingTable;