import { isNonEmpty } from "common/utils";
import { createBrowserHistory } from 'history';
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";
import { AllActions, IRootState, reducerMap } from './reducers';
import { rootSaga } from './sagas';
import { loadState, saveState } from './utils/localStorage';

const middlewares = [];

const sagaMiddleware = createSagaMiddleware();

const ITEM_NAME = process.env['PUBLIC_URL'] + '/state';
const env = process.env['PUBLIC_URL'];

middlewares.push(sagaMiddleware);

export const browserHistory = createBrowserHistory({ basename: process.env.PUBLIC_URL });

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

window.addEventListener('storage', (ev) => {
  if (ev.key === 'timestamp') {
    if (isNonEmpty(sessionStorage.getItem(ITEM_NAME)) && sessionStorage.getItem('newLogin') !== 'true' && env === localStorage.getItem('logoutEnv')) {
      store.dispatch({ type: 'Login.LogoutRequested' });
      let logoutTimeout = setTimeout(() => { localStorage.removeItem('logoutEnv'); clearTimeout(logoutTimeout) }, 1000);
      sessionStorage.clear();
    };

  };
}
);

window.addEventListener("mousemove", (e) => {
  if (!isNonEmpty(localStorage.getItem(ITEM_NAME)) && isNonEmpty(sessionStorage.getItem(ITEM_NAME))) {
    localStorage.setItem(ITEM_NAME, sessionStorage.getItem(ITEM_NAME)!);
  };
});


window.addEventListener("unload", () => {

  localStorage.removeItem(ITEM_NAME);
});

window.addEventListener("storage", (ev) => {
  if (ev.key === env + '/' + 'frontendVersion') {
    window.location.reload();

  }
});


// window.addEventListener('focus', () => {

//   if (!isNonEmpty(localStorage.getItem(ITEM_NAME))) {
//     localStorage.setItem(ITEM_NAME, sessionStorage.getItem(ITEM_NAME)!);
//   }
// });


const composeEnhancer =
  (process.env.NODE_ENV !== 'production' &&
    window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const persistedLoginState = loadState();

export const store = createStore<IRootState, AllActions, unknown, unknown>(
  combineReducers(reducerMap),
  // persistedState,
  composeEnhancer(applyMiddleware(...middlewares))
);


if (persistedLoginState?.loggedIn) {
  store.dispatch({ type: 'Login.Success', payload: persistedLoginState });

}

// Save Login
store.subscribe(() => {
  if (store.getState().login.loggedIn) {
    saveState(store.getState().login);
  }
});

sagaMiddleware.run(rootSaga, { browserHistory });
// sagaMiddleware.run(rootSaga, store.dispatch).toPromise().catch(e => {
//   logger.error({ message: e.message, source: 'sagaError', stacktrace: e.sagaStack });
// });


