import { ApiResult } from 'api';

const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];
const PUBLIC_URL = process.env['REACT_APP_PAS_APP_PUBLIC_URL'] ?? '/';

export const forgotPasswordApi = {

  request: async (username: string, contactEmail: string): Promise<ApiResult<any>> => { 
    const payload = new FormData();

    payload.append('username', username);
    payload.append('contactEmail', contactEmail);
    payload.append('publicUrl', PUBLIC_URL);

    const res = fetch(`${BASE_URL}/forgotPassword/request`, {
      method: 'POST',
      body: payload,
    });

    return (await res).json();
  },

  verifyToken: async (token: string): Promise<ApiResult<any>> => {
    const payload = new FormData();

    payload.append('token', token);

    const res = fetch(`${BASE_URL}/forgotPassword/verifyToken`, {
      method: 'POST',
      body: payload,
    });

    return (await res).json();
  },

  changePassword: async (token: string, newPassword: string): Promise<ApiResult<any>> => {
    const payload = new FormData();

    payload.append('token', token);
    payload.append('newPassword', newPassword);
    const res = fetch(`${BASE_URL}/forgotPassword/changePassword`, {
        method: 'POST',
        body: payload,
    });

    return (await res).json();
},



};

export default forgotPasswordApi;