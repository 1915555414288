import { Capacitor } from '@capacitor/core';
import {
  Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { isNonEmpty } from 'common/utils';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import validate from 'validate.js';
import { ForgotPasswordDialog } from './components';
import { Device } from "@capacitor/device";


// import { Facebook as FacebookIcon, Google as GoogleIcon } from '../../icons';

const schema = {
  username: {
    presence: { allowEmpty: false, message: () => '請輸入用戶名 Please Input Username' },
    // email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: () => '請輸入密碼 Please Input Password' },
    length: {
      maximum: 128
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(images/logo-max.png)',
    backgroundSize: '60%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));

const SignIn = props => {
  const { history } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  const [open,setOpen] = useState(false);
  const { loginFailed, isLoading, loggedIn } = useSelector(state => state.login);
 
  useEffect(() => {
    // fetch('http://localhost:8080/public/login', {
    //     method: 'POST',
    //     body: data
    //   })
    //     .then(res => res.json())
    //     .catch(error => console.error('Error:', error))
    //     .then(response => 
      // dispatch({type: 'Login.Start', payload: formData});

      if(loginFailed===true){
        setFormState(formState => ({
          ...formState,
          values: {}
          }
        ));
       }
    }, [loginFailed]
  );
  
  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]
  );




  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const env = process.env["PUBLIC_URL"];
  const version =
    useSelector((state) => state.systemSettings?.System?.FRONTEND?.VERSION) ??
    "";
  const [deviceInfo, setDeviceInfo] = useState(null);

  useEffect(() => {
    dispatch({ type: "SystemSetting.FetchRequested" });
    if (!isNonEmpty(localStorage.getItem(env + "/" + "frontendVersion"))) {
      localStorage.setItem(env + "/" + "frontendVersion", version);
    }
  }, [dispatch]);

  useEffect(() => {
    Device.getInfo().then((info) => setDeviceInfo(info.platform));
    if (deviceInfo === "web" && !loggedIn) {
      if (
        isNonEmpty(localStorage.getItem(env + "/" + "frontendVersion")) &&
        version !== localStorage.getItem(env + "/" + "frontendVersion")
      ) {
        setOpen(true);
      }
    }
  }, [deviceInfo, version, env, loggedIn]);

  const handleSignIn = (event) => {
    event.preventDefault();
    dispatch({
      type: "Login.Start",
      payload: {
        username: formState.values.username,
        password: formState.values.password,
      },
    });
  };

  const versionUpdate = () =>
  <Dialog 
    open={open} 
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description">
  <DialogTitle color="black" id="alert-dialog-title">
    {'版本更新 Version Update'}
    </DialogTitle>
    <DialogContent>
      <DialogContentText color="initial" id="alert-dialog-description">
      {'我們已釋出新的版本，「請按下 OK 重新加載所有頁面」。'}
      </DialogContentText>
      <DialogContentText color="initial" id="alert-dialog-description">
      {'We have released a new version, "please click OK to reload all pages"'}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button color="primary" onClick={()=>{
        setOpen(false);
        window.location.reload();
        localStorage.setItem(env+'/'+'frontendVersion',version);}
        } autoFocus>
        {'OK'}
      </Button>
    </DialogActions>
</Dialog>;
 
  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.quoteContainer}
          item
          lg={5}
        >
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography
                className={classes.quoteText}
                variant="h1"
              >
                {/* Property Mavin Limited */}
              </Typography>
              {/* <div className={classes.person}>
                <Typography
                  className={classes.name}
                  variant="h2"
                >
                  物業代理系統
                </Typography>
              </div> */}
            </div>
          </div>
        </Grid>
        <Grid
          className={classes.content}
          item
          lg={7}
          xs={12}
        >
          <div className={classes.content}>
            {/* <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div> */}
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                onSubmit={handleSignIn}
              >
                <Typography
                  className={classes.title}
                  variant="h2"
                >
                  登入 Sign In
                </Typography>
                {/* <Typography
                  color="textSecondary"
                  gutterBottom
                >
                  Sign in with social media
                </Typography> */}
                
                {Capacitor.getPlatform()==="android"?<Typography
                  color={"secondary"}
                  variant="subtitle1"
                >
                  Using third party keyboard may have potential security risks, using the default keyboard of your device is strongly recommended.
                </Typography>: null}
                {Capacitor.getPlatform()==="android"?<Typography
                  color={"secondary"}
                  variant="subtitle1"
                >
                  使用第三方鍵盤可能有潛在安全風險,強烈建議使用設備預設的鍵盤.
                </Typography>: null}
                <TextField
                  className={classes.textField}
                  error={hasError('username')}
                  fullWidth
                  helperText={
                    hasError('username') ? formState.errors.username[0] : null
                  }
                  label="用戶名 Username"
                  name="username"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.username || ''}
                  variant="outlined"
                  autoComplete="new-password"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('password')}
                  fullWidth
                  helperText={
                    hasError('password') ? formState.errors.password[0] : null
                  }
                  label="密碼 Password"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.password || ''}
                  variant="outlined"
                  autoComplete="new-password"
                />
                <Button
                  className={classes.signInButton}
                  color="primary"
                  disabled={!formState.isValid || isLoading}
                  fullWidth
                  size="large"
                  type="submit"
                  variant={loggedIn ? "outlined" : "contained"}
                >
                  {isLoading ? <CircularProgress size={15} /> : null}
                  {isLoading ? '請稍候 Please Wait' : loggedIn ? '登入中 Logging In' : '登入 Sign In'}
                </Button>
                <ForgotPasswordDialog/>
                {/* <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Don't have an account?{' '}
                  <Link
                    component={RouterLink}
                    to="/sign-up"
                    variant="h6"
                  >
                    Sign up
                  </Link>
                </Typography> */}
              </form>
              {versionUpdate()}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignIn);
