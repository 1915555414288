import {
    Button,
    Card,
    CardContent,
    CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemText, Paper, Typography
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CloseIcon from '@material-ui/icons/Close';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { DashboardKpiTypes, DashboardPrefState, IRootState } from 'reducers';



// import { DashboardPrefDTO } from 'common/dto';

const useStyles = makeStyles(theme => ({
    root: {
    },
    head: {
        flex: '0 0 auto',
        margin: '0',
        padding: '16px 24px',
    },
    cardcontent: {
        padding: 0,
        "&:last-child": {
          paddingBottom: 0,
        }
    },
    dialogcontent: {
        flex: '1 1 auto',
        padding: '8px 24px',
        //overflowY: 'scroll',
        display: 'block',
        alignItems: 'center',
        justifyContent: 'center',
    },
    dialogTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paper: {
        width: 200,
        height: 255,
        //overflow: 'auto',
    },
    paper_layoutController: {
        [theme.breakpoints.down(400)]:{
            width: 210,
        },
        "@media (min-width:400px) and (max-width:600px)":{
            width: 270,
        },
        [theme.breakpoints.up('sm')]:{
            width: 450,
        },
        height:180,
        overflowY: 'auto',
    },
    button: {
        display: "flex",
        margin: theme.spacing(0, 0),
    },
    listTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    listTitle_card: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '8px 16px 4px 16px',
    },
    card_control: {
        margin: '4px',
    },
    title: {},
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: '8px',
        color: theme.palette.grey[500],
    },
    dragListStyle: {
        //background: '#804646',
        //padding: 8,
        [theme.breakpoints.down(400)]:{
            width: 210,
        },
        "@media (min-width:400px) and (max-width:600px)":{
            width: 270,
        },
        [theme.breakpoints.up('sm')]:{
            width: 450,
        },
        paddingTop: 0,
        paddingBottom: 0,
        
        //height: 260,
        overflow: 'auto',
    },
    dragItemStyle: {
        //paddingRight: 40,
        //overflow: 'auto',
        // change background colour if dragging
        //background: '#26e071'
    },
    listItem_control: {
        [theme.breakpoints.down(400)]:{
            width: 210,
        },
        "@media (min-width:400px) and (max-width:600px)":{
            width: 270,
        },
        [theme.breakpoints.up('sm')]:{
            width: 450,
        },
    }
}));



function not(a: DashboardPrefState[], b: DashboardPrefState[]) {
    return a.filter((value) => b.indexOf(value) === -1);
}



// const [left, setLeft] = React.useState<number[]>([0, 1, 2, 3]);
// const [right, setRight] = React.useState<number[]>([4, 5, 6, 7]);


// const handleToggle = (value: number) => () => {
//     const currentIndex = checked.indexOf(value);
//     const newChecked = [...checked];

//     if (currentIndex === -1) {
//         newChecked.push(value);
//     } else {
//         newChecked.splice(currentIndex, 1);
//     }

//     setChecked(newChecked);
// };


interface LayoutControlProps {
    open: boolean;
    onApply: (key: Array<DashboardPrefState>) => any;
    onApplySave: (key: Array<DashboardPrefState>) => any;
    onClose: () => void;
}

const LayoutControlDialog = (props: LayoutControlProps) => {
    const { open, onApply, onApplySave, onClose } = props;
    const { lang, langDashboard } = useSelector((state: IRootState) => state.locale);
    const classes = useStyles();
    const {allowedKpiTypeList} = useSelector((state: IRootState) => state.dashboard);
    const dashboardPref = useSelector((state: IRootState) => state.dashboard.dashboardPref);


    function labeling(kpiType: number) {
        let label = '';
        switch (kpiType) {
            case DashboardKpiTypes.NEW_CLIENTS:
                label = langDashboard.optionNewClients;
                break;
            case DashboardKpiTypes.NEW_CLIENT_UPDATES:
                label = langDashboard.optionClientUpdates;
                break;
            case DashboardKpiTypes.NEW_PROPERTIES:
                label = langDashboard.optionNewProperties;
                break;
            case DashboardKpiTypes.NEW_PROPERTY_UPDATES:
                label = langDashboard.optionPropertyUpdates;
                break;
            case DashboardKpiTypes.TOTAL_COMMISSION:
                label = langDashboard.optionTotalCommission;
                break;
            case DashboardKpiTypes.CLIENT_PROPERTY_VISIT:
                label = langDashboard.optionClientPropertyVisits;
                break;

        };
        return label;
    }

    const [prefLocalHidden, setPrefLocalHidden]
        = useState(dashboardPref
            .filter(d =>  allowedKpiTypeList.includes(d.kpiType))
            .filter(dp => !dp.isVisible));

    const [prefLocalShown, setPrefLocalShown]
        = useState(dashboardPref
            .filter(d => allowedKpiTypeList.includes(d.kpiType))
            .filter(dp => dp.isVisible));
    
        const [prefLocalNoPrivi, setPrefLocalNoPrivi ]
        = useState(dashboardPref.filter(d => !allowedKpiTypeList.includes(d.kpiType)));

    useEffect(() => { setPrefLocalHidden(dashboardPref.filter(d => allowedKpiTypeList.includes(d.kpiType))
        .filter(dp => !dp.isVisible).map((dp, i) => ({ ...dp, sortOrder: ( dashboardPref.length-  (i+1 +prefLocalNoPrivi.length))} ) ));
        
        setPrefLocalShown(dashboardPref.filter(d => allowedKpiTypeList.includes(d.kpiType)).filter(dp => dp.isVisible).map((dp, i) => ({ ...dp, sortOrder: i })));
        
        setPrefLocalNoPrivi(dashboardPref.filter(d => !allowedKpiTypeList.includes(d.kpiType)));
    }, [ open ]);



    const [selectedIndex, setSelectedIndex] = React.useState(0);

    // Here, you used = instead of ===, which assign 0 to kpiType instead of comparing with it

    //why this dialog affect the Pref when it is not toggle?
    // dashboardPref <- this is selected from reducer, so if you call dashboardPref[0].kpiType = 0,
    // it will update the pref.
    // ok I will try to import thee pref in the list item here and avoid this, thank you.
    const leftSelected =
    prefLocalHidden.filter(dp => dp.kpiType === selectedIndex);
    // intersection(dashboardPref.filter(dp => dp.kpiType === selectedIndex), prefLocalHidden);
    const rightSelected =
    prefLocalShown.filter(dp => dp.kpiType === selectedIndex);
    //  intersection(dashboardPref.filter(dp => dp.kpiType === selectedIndex), prefLocalShown);


    const handleSelectedShown = () => {
        setPrefLocalShown(prefLocalShown.concat(leftSelected.map(dpSelect => ({ ...dpSelect, isVisible: true, sortOrder: prefLocalShown.length }))));
        
        setPrefLocalHidden(not(prefLocalHidden, leftSelected).map((dp, i) => ({ ...dp, sortOrder: ( dashboardPref.length-  (i+1 +prefLocalNoPrivi.length))} ) ));
        // not(prefLocalHidden, leftSelected).map(dp => console.log( "filtering "+dp.sortOrder));
        // prefLocalShown.map((dp, i) => console.log("filtered S "+dp.sortOrder ));
        // prefLocalHidden.map((dp, i) => console.log("filtered H "+dp.sortOrder ));
        // not(dashboardPref[selectedIndex], leftSelected).map( dp => setSelectedIndex( dp.kpiType));
    };

    const handleSelectedHidden = () => {
        setPrefLocalHidden(prefLocalHidden.concat(rightSelected.map(dpSelect => ({ ...dpSelect, isVisible: false }))).map((dp, i) => ({ ...dp, sortOrder: ( dashboardPref.length-  (i+1 +prefLocalNoPrivi.length))  }) ));
        
        
        setPrefLocalShown(not(prefLocalShown, rightSelected).map((dp, i) => ({ ...dp, sortOrder: i })));
        // prefLocalShown = [...prefLocalShown];
        // not(prefLocalShown, rightSelected).map(dp => console.log( "filtering "+dp.sortOrder));
        // prefLocalShown.map((dp, i) => console.log("filtered S "+dp.sortOrder ));
        // prefLocalHidden.map((dp, i) => console.log("filtered H "+dp.sortOrder ));
        // not(dashboardPref[selectedIndex], leftSelected).map( dp => setSelectedIndex(dp.kpiType));
    };

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedIndex(index);
    };


    const HiddenList = (items: DashboardPrefState[]) => (
        <Paper className={classes.paper_layoutController}>
            <List dense component="div" role="list" style={{overflowY:'auto'}}>
                {items.map((value: DashboardPrefState) => {
                    const labelId = `transfer-list-item-${value.kpiType}-label`;

                    return (
                        <ListItem key={value.kpiType} role="listitem" button
                            selected={selectedIndex === value.kpiType}
                            onClick={(event) => handleListItemClick(event, value.kpiType)} 
                            className={classes.listItem_control} >

                            <ListItemText id={labelId} primary={labeling(value.kpiType)} className={classes.dragItemStyle}/>
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );
    const ShownList = (items: DashboardPrefState[]) => (
        <Paper className={classes.paper_layoutController}>
            {/* <List dense component="div" role="list"> */}
            {/* {items.map((value: DashboardPrefState) => { */}
            {/* const labelId = `transfer-list-item-${value.kpiType}-label`; */}

            {/* return ( */}
            {/* <ListItem key={value.kpiType} role="listitem" button
                            selected={selectedIndex === value.kpiType}
                            onClick={(event) => handleListItemClick(event, value.kpiType)} >

                            <ListItemText id={labelId} primary={labeling(value.kpiType)} />
                            {/* <IconButton aria-label="Up"  >
                                <ArrowUpwardIcon />
                            </IconButton> 
                        </ListItem> */}


            <DragDropContext onDragEnd={(result) => {
                if (!result.destination) {
                    return;
                }

                const i1 = result.source.index  , i = result.destination?.index   ?? 0;

                console.log('sourceT'+i1);
                console.log('destT'+ i);

                const oldList
                //  = [...prefLocalShown.map(dp => dp.sortOrder)];
                = [...prefLocalShown];
               
                // let newList : number[] = [];
                let newList : DashboardPrefState[] = [];
                if (i > i1) { //move downward
                    newList.push(...oldList.slice(0, i1)); // items above the selected item's original position
                    newList.push(...oldList.slice(i1 + 1, i + 1)); //items between the original position and the destination
                    newList.push(oldList[i1]); // selected item
                    newList.push(...oldList.slice(i + 1, oldList.length)); //items below the destination
                    console.log('forward list '+i1 );
                    console.log('forward'+i1 );
                    
                } else if (i < i1) { //move upward
                    newList.push(...oldList.slice(0, i)); // items above the selected item's destination
                    newList.push(oldList[i1]); //selected item
                    newList.push(...oldList.slice(i, i1)); //items between the destination and the original position
                    newList.push(...oldList.slice(i1 + 1, oldList.length)); // items below the original position
                    console.log('back list '+i1 );
                    console.log('back'+i1 );
                } else {
                    console.log('non'+i1+ " "+i );
                    newList = oldList;
                }
                
                // setPrefLocalShown( newList.map((dp, index ) =>({ ...dp, sortOrder: newList.indexOf(dp) }) ));
                setPrefLocalShown(newList.map((dp, index) => ({ ...dp, sortOrder: index })) );
                // .sort((a, b) => a.sortOrder > b.sortOrder  ? 1 : -1));
                newList =[];



            }}>
                <Droppable droppableId="container" direction="vertical">
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                         className={classes.dragListStyle}
                         
                        >
                            <List dense component="div" role="list" style={{overflowY:'auto'}}>
                                {items.map((value: DashboardPrefState, index: number) => {
                                    const labelId = `transfer-list-item-${value.kpiType}-label`;
                                    console.log(value);
                                    
                                    return ( 
                                                
                                        <Draggable key={labelId} draggableId={labelId} index={index}>
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                 //className={classes.dragItemStyle}
                                                >
                                                   
                                                    <ListItem key={value.kpiType} role="listitem" button
                                                        className={classes.listItem_control}
                                                        selected={selectedIndex === value.kpiType}
                                                        //className={classes.dragItemStyle}
                                                        onClick={(event) => handleListItemClick(event, value.kpiType)} >
                                                             <OpenWithIcon />
                                                             <span> &nbsp;  &nbsp; </span> 
                                                        <ListItemText id={labelId} primary={labeling(value.kpiType)} className={classes.dragItemStyle}/>
                                                        {/* <IconButton aria-label="Up"  >
                                                            <ArrowUpwardIcon />
                                                        </IconButton> */}
                                                    </ListItem>
                                                   {console.log("item order: "+ value.sortOrder) } 
                                                </div>
                                               
                                            )}
                                            
                                        </Draggable>
                                    )
                                })}
                            </List>
                             {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>


            {/* // );
                // })}
                // <ListItem /> */}
            {/* </List> */}
        </Paper>

    );

    return (
        <Dialog onClose={onClose} open={open} scroll={'paper'}>
            <DialogTitle className={classes.head}>
                <Typography variant="h3" className={classes.listTitle} >{langDashboard.titleConsole}
                </Typography>
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>

            </DialogTitle>
            <DialogContent
                dividers
                className={classes.dialogcontent}
            >
                <Grid container direction="column" alignItems="center" style={{width:'auto'}}>
                    <Grid item>
                        <Card>
                            <CardHeader className={classes.listTitle_card} title={langDashboard.dialogTitleHidden}>  </CardHeader>
                            <CardContent className={classes.cardcontent}>
                
                                {HiddenList(prefLocalHidden )}

                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item>
                        <Card className={classes.card_control}>
                            <CardContent className={classes.cardcontent}>
                                <Grid container direction='row'>
                                    <Grid item>
                                        <IconButton
                                            // variant="outlined"
                                            // size="small"
                                            className={classes.button}
                                            onClick={handleSelectedShown}
                                            disabled={leftSelected.length === 0}
                                            aria-label="move selected down"
                                        >
                                            <ArrowDownwardIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <IconButton
                                            // variant="outlined"
                                            // size="small"
                                            className={classes.button}
                                            onClick={handleSelectedHidden}
                                            disabled={rightSelected.length === 0}
                                            aria-label="move selected up"
                                        >
                                            <ArrowUpwardIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                
                               
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item>
                        <Card>
                            <CardHeader className={classes.listTitle_card} title={langDashboard.dialogTitleShown}>  </CardHeader>
                            <CardContent className={classes.cardcontent}>

                                {ShownList(prefLocalShown )}

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                

                

            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={() => {
                setPrefLocalHidden(prefLocalShown);
                setPrefLocalHidden (prefLocalHidden );
                setPrefLocalNoPrivi(prefLocalNoPrivi );
                onApply([...prefLocalShown, ...prefLocalHidden, ...prefLocalNoPrivi ]);}
                }>{lang.actionApply}</Button>

                <Button color="primary" variant="contained" onClick={() => {
                setPrefLocalHidden(prefLocalShown);
                setPrefLocalHidden (prefLocalHidden );
                setPrefLocalNoPrivi(prefLocalNoPrivi );
                onApplySave([...prefLocalShown, ...prefLocalHidden, ...prefLocalNoPrivi]);
                 } }>{lang.actionApplySave}</Button>
            </DialogActions >
        </Dialog >
    )
}

export default LayoutControlDialog;

