import { TextField, TextFieldProps, Typography } from "@material-ui/core";
import { isNonEmpty } from "common/utils";
import React, { Fragment } from "react";
import { useSelector } from 'react-redux';
import { IRootState } from "reducers";
import { SelectDistrictDialog } from "./SelectDistrictDialog";


interface DistrictMultiSelectProps {
  // form: FormPresenter<T>;
  value: string[];
  onChange: (value: string[]) => any;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
}

const UITextField = (props: TextFieldProps & { fixedLabel?: boolean }) => {
  let { label, fixedLabel, ...rest } = props;
  fixedLabel = false;
  return <Fragment>
    {fixedLabel ? <Typography>{label}</Typography> : null}
    <TextField {...rest} label={!fixedLabel ? label : null} InputProps={{readOnly: true}} inputProps={{style: {cursor: 'pointer'}}}/>
  </Fragment>
};

export default function DistrictMultiSelect({ value, onChange, required, error, disabled }: DistrictMultiSelectProps) {
  const localeBundle = useSelector((state: IRootState) => state.locale);
  const { langPropertyStock, districtHkiOptions, districtNtOptions, districtKltOptions,
  } = localeBundle;


  const regionMap = {
    HKI: districtHkiOptions,
    KLT: districtKltOptions,
    NT: districtNtOptions,
  };

  const allDistrictOptions = {
    ...districtHkiOptions,
    ...districtKltOptions,
    ...districtNtOptions,
  };

  const regionAllSelected = (region: keyof typeof regionMap) => {
    if (disabled){
      return;
    }
    
    const all = Object.keys(regionMap[region]);
    const current = Array.from(value ?? []);
    const selected = current.filter(n => all.indexOf(n) > -1);
    return selected.length === all.length;
  };

  const toggleDistrictByRegion = (region: keyof typeof regionMap) => {
    if (disabled){
      return;
    }

    const all = Object.keys(regionMap[region]);
    const current = Array.from(value ?? []);
    const selected = current.filter(n => all.indexOf(n) > -1);
    if (selected.length === all.length) {
      // all selected, clear all
      onChange(current.filter(n => selected.indexOf(n) < 0).filter(v => isNonEmpty(v)));
    } else if (selected.length < all.length) {
      current.push(...all.filter(n => selected.indexOf(n) < 0));
      onChange(current.filter(v => isNonEmpty(v)));
    }
  };
  
  const toggleChip = (key: string) =>{
    if (disabled){
      return;
    }

    if (value.includes(key)){
      onChange(value.filter(v => v !== key).filter(v => isNonEmpty(v)));
    }else{
      onChange([...value, key].filter(v => isNonEmpty(v)));
    }
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    if (disabled) return;
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return ( <div>
  
  {/* ----------- select dialog --------------- */}
    <UITextField
      label={langPropertyStock.district}
      value={value.map(val => allDistrictOptions[val]).join(', ')}
      onClick={handleClickOpen}
      fullWidth
      margin="dense"
      variant="outlined"
      required={required ?? false}
      error={error ?? false}
      disabled={disabled ?? false}
    />
    <SelectDistrictDialog
      open={open}
      handleClose={handleClose}
      handleClickOpen={handleClickOpen}
      value={value}
      regionAllSelected={regionAllSelected}
      toggleDistrictByRegion={toggleDistrictByRegion}
      toggleChip={toggleChip}
    />
  </div>);
}