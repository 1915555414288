import { AppBar, CardHeader, CircularProgress, DialogContent, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputAdornment, Radio, RadioGroup, TextField, Toolbar, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ErrorIcon from '@material-ui/icons/Error';
import { formApi } from 'api/formApi';
import { useConfirmDialog } from 'common/ConfirmDialog';
import { EmailFormDTO } from 'common/dto';
import { useAppBarHeight, useForm } from 'common/hooks';
import { checkIsHKID, isNonEmpty } from 'common/utils';
import React, { MutableRefObject, RefObject, useEffect, useLayoutEffect, useRef, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState, PASDispatch } from 'reducers';
import { delay } from 'redux-saga/effects';
import SignaturePad from 'signature_pad';
import theme from 'theme';
import { format } from 'util';
import FormSigningInfo, { FormSigningInfoProps } from './FormSigningInfo';
import FormSigningStepper from './FormSigningStepper';

const successWord = '#33A64C';
const successBackground = '#E0F0D6';
const warningWord = '#D17212';
const warningBackground = '#FFCC99';

const useStyles = makeStyles(() => ({
  root: {},
  partTitle: {
    fontWeight: 'bold',
  },
  textArea: {
    width: '100%',
    margin: 'dense',
    padding: '8px',
    fontSize: '14px'
  },
  appBar: {
    position: 'relative',
  },
  dialogRoot: {
    '& .MuiDialog-paper': {
      overflowX: 'hidden',
    }
  },
  clientActionInputFields: {
    marginLeft: '25px',
  },
  signAreaActionButton: {
    marginLeft: '10px',
  },
  message: {
    borderRadius: '5px',
    padding: '10px',
    whiteSpace: 'pre-line',
    width: '100%',
  },
  dialogTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: 'white',
    fontWeight: 'bold',
  },
}));

const When = ({ test, children }: { test?: boolean, children: JSX.Element }): JSX.Element => test ? children : <span />;

const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];
const PUBLIC_URL = process.env['REACT_APP_PAS_APP_PUBLIC_URL'] ?? '/';


interface NewFormSigningDialogProps extends FormSigningInfoProps {
  open: boolean;
  onClose: () => any; //for signing completed
  id: string; //propertyStockId (form 1,2,3,5) or clientId(form 4,6)
  formIndex: string; // 'form1', 'form2', ...
  requireAgentSign?: boolean;
  requireSellerSign?: boolean;
  locale?: string; //formLocale
  autoFillClientVerificationContact?: string;
  autoFillClientEmail?: string;
  onCloseSigningDialog: () => any;

  form4or6option?: string;
  selectedProperties?: string[];
  clientuploadform4or6?: boolean;
  displayFormName?: string;
  setSelectedProperties?: any;
}

const NewFormSigningDialog = (props: NewFormSigningDialogProps) => {
  const { open, onClose, formIndex, id, locale, autoFillClientVerificationContact, onCloseSigningDialog,
    agentName, clientName, requireAgentSign, requireSellerSign, displayFormName, autoFillClientEmail,
    form4or6option, selectedProperties, clientuploadform4or6, setSelectedProperties
  } = props;
  const dispatch = useDispatch() as PASDispatch;
  const appBarHeight = useAppBarHeight();
  const { iosStatusBarHeight } = useSelector((state: IRootState) => state.layout);
  const { langSignDialog, lang, locale: currentLocale } = useSelector((state: IRootState) => state.locale);
  const [signSpecs, setSignSpecs] = useState<{ title: React.ReactNode, name: string }[]>([
    {
      // title: <span><b>{langSignDialog.captionAgent}</b> {langSignDialog.actionSign}</span>,
      title: <span>{format(langSignDialog.msgSignAreaTitle, agentName)}</span>,
      name: 'agentSign'
    }
  ]);
  const classes = useStyles();
  const confirmDialog = useConfirmDialog();
  const { token } = useSelector((state: IRootState) => state.login);

  const [clientSignAction, setClientSignAction] = useState('');
  const [currentSignUser, setCurrentSignUser] = useState<'AGENT' | 'CLIENT'>('AGENT');

  const [messageNode, setMessageNode] = useState<React.ReactNode>();
  const [noteForDocument, setNoteForDocument] = useState('');

  const [isClientSignNow, setIsClientSignNow] = useState(false);
  const [signedFormUrl, setSignedFormUrl] = useState('');
  const [signs, setSigns] = useState<{ [key: string]: string }>({}); // data urls
  const [signSpecIdx, setSignSpecIdx] = useState(0);
  const [status, setStatus] = useState<'AGENT_SIGNING' | 'SELECT_ACTION' | 'CLIENT_SIGNING' | 'GENERATING' | 'SUCCESS'>('AGENT_SIGNING');

  const [formStatus, setFormStatus] = useState('');
  const [formId, setFormId] = useState('');
  const [clientSignUrl, setClientSignUrl] = useState('');
  const [timeToExpiryInHour, setTimeToExpiryInHour] = useState('');
  const reset = () => {
    setSigns({});
    setSignSpecIdx(0);
    setStatus('AGENT_SIGNING');
    setCurrentSignUser('AGENT');
    setSignSpecs([
      {
        // title: <span><b>{langSignDialog.captionAgent}</b> {langSignDialog.actionSign}</span>,
        title: <span>{format(langSignDialog.msgSignAreaTitle, agentName)}</span>,
        name: 'agentSign'
      }
    ]);

    setFormId('');
    setFormStatus('');
    setIsClientSignNow(false);
    setEmailSent(false);
    setShowSigningLink(false);
    setClientSignUrl('');
    setTimeToExpiryInHour('');
    setMessageNode(null);
    setNoteForDocument('');
    setActiveStep(0);
    setClientSignAction('');

    sendCopyToClientform.setValues({});
    requiredInfoForm.setValues({
      clientVerificationContact: autoFillClientVerificationContact,
      clientEmail: autoFillClientEmail,
    });
    sendCopyToClientform.stopValidation();
    requiredInfoForm.stopValidation();
  }

  // #endregion Form Signing Related

  //------- send email to client
  const [emailSent, setEmailSent] = useState(false);
  const sendCopyToClientform = useForm<EmailFormDTO>({},
    {
      validations: [
        (values) => values.clientEmail ? null : [['clientEmail', langSignDialog.msgPleaseInput]],
      ]
    })

  // 

  const requiredInfoForm = useForm<EmailFormDTO>({
    clientVerificationContact: autoFillClientVerificationContact,
    clientEmail: autoFillClientEmail,
  },
    {
      validations: [
        (values) => formIndex !== 'form1' && clientSignAction === 'SIGN_NOW' && !isNonEmpty(values.clientIdentificationDocNo) ? [['clientIdentificationDocNo', langSignDialog.msgPleaseInput]] : null,
        (values) => clientSignAction === 'SIGN_NOW' || clientSignAction === 'LINK' || values.clientEmail ? null : [['clientEmail', langSignDialog.msgPleaseInput]],
        (values) => clientSignAction === 'SIGN_NOW' || clientSignAction === 'EMAIL' || values.clientVerificationContact ? null : [['clientVerificationContact', langSignDialog.msgPleaseInput]],

      ]
    })

  const inviteClientToSign = () => {
    dispatch({ type: 'Layout.MaskPresentRequested' });
    formApi.inviteClientToSign({
      id: id,
      locale: locale,
      formId: formId,
      clientEmail: requiredInfoForm.values.clientEmail,
      formName: formIndex,
      clientVerificationContact: requiredInfoForm.values.clientVerificationContact,
      publicUrl: PUBLIC_URL,
    } as EmailFormDTO, token ?? '').then(async result => {
      if (result.data) {
        dispatch({ type: 'Layout.MaskDismissRequested' });
        dispatch({ type: 'Layout.AlertMessageAdded', payload: { message: langSignDialog.msgSentSuccess } });
        setStatus('SUCCESS');
        setActiveStep(activeStep + 1);
        setMessageNode(getMessageNode(langSignDialog.msgSuccessInvitationSent));
        setNoteForDocument(langSignDialog.captionWaitForClientSign);
      } else {
        if (result.error === 'ERR_FAILED_TO_SEND_EMAIL') {
          // dispatch ({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgFailedToSendEmailError, severity: 'error' } });
          setMessageNode(getMessageNode(lang.msgFailedToSendEmailError, true));
        } else {
          setMessageNode(getMessageNode(lang.msgPleaseTryAgain, true));
        }
      }
      dispatch({ type: 'Layout.MaskDismissRequested' });
    });
  }

  const generateClientSigningLink = () => {
    dispatch({ type: 'Layout.MaskPresentRequested' });
    formApi.generateClientSigningLink({
      id: id,
      formId: formId,
      formName: formIndex,
      clientVerificationContact: requiredInfoForm.values.clientVerificationContact,
      publicUrl: PUBLIC_URL,
    } as EmailFormDTO, token ?? '').then(async result => {
      if (result.data) {
        dispatch({ type: 'Layout.MaskDismissRequested' });
        setClientSignUrl(result.data.clientSignUrl);
        setTimeToExpiryInHour(result.data.timeToExpiryInHour);
        setShowSigningLink(true);
        setStatus('SUCCESS');
        setActiveStep(activeStep + 1);
        setMessageNode(getMessageNode(langSignDialog.msgSuccessLinkGenerated));
        setNoteForDocument(langSignDialog.captionWaitForClientSign);
      } else {
        dispatch({ type: 'Layout.MaskDismissRequested' });
        dispatch({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgNetworkError, severity: 'error' } });
        setMessageNode(getMessageNode(lang.msgPleaseTryAgain, true));
      }
      dispatch({ type: 'Layout.MaskDismissRequested' });
    });
  }

  const sendCopyToClient = () => {
    dispatch({ type: 'Layout.MaskPresentRequested' });
    formApi.sendCopyToClient({ id: id, filename: signedFormUrl, clientEmail: sendCopyToClientform.values.clientEmail, formName: formIndex } as EmailFormDTO, token ?? '').then(async result => {
      if (result.data) {
        dispatch({ type: 'Layout.MaskDismissRequested' });
        dispatch({ type: 'Layout.AlertMessageAdded', payload: { message: langSignDialog.msgSentSuccess } });
        delay(2000);
        reset();
        onClose();

      } else {
        if (result.error === 'ERR_FAILED_TO_SEND_EMAIL') {
          dispatch({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgFailedToSendEmailError, severity: 'error' } });
        }
      }
      dispatch({ type: 'Layout.MaskDismissRequested' });
    });
  }

  useEffect(() => {

  }, [formId])



  // const canvasRef = useRef<HTMLCanvasElement>(null);
  // const signaturePadRef = useRef<SignaturePad | null>(null);
  const signaturePadBindingRef = useRef<ReturnType<typeof useSignaturePad> | null>(null);
  const [currentSignBlob, setCurrentSignBlob] = useState<string | null>(null);
  const onSignature = async () => {

    if (!currentSignBlob) {
      return;
    }

    setActiveStep(activeStep + 1);

    const updatedSigns = { ...signs, [signSpecs[signSpecIdx]?.name]: currentSignBlob };
    setSigns(updatedSigns);

    const formData = new FormData();
    for (let key in updatedSigns) {
      formData.append(
        key,
        await fetch(updatedSigns[key]).then(r => r.blob()),
      );
    }
    formData.append('publicUrl', PUBLIC_URL);

    if (currentSignUser === 'AGENT' && props.requireSellerSign) {
      // const formData = new FormData();
      // for (let key in updatedSigns) {
      //   formData.append(
      //     key,
      //     await fetch(updatedSigns[key]).then(r => r.blob()),
      //   );
      // }
      // formData.append('publicUrl', PUBLIC_URL);
      //handle agent sign, call api to save signature
      setStatus('GENERATING');
      let url = `${BASE_URL}/form/agentSignForm/${id}/${formIndex}/${locale}/pdf?t=${token}`;
      if (formIndex === 'form4' || formIndex === 'form6') {
        url = `${BASE_URL}/form/agentSignClientForm/${id}/${formIndex}/${locale}/pdf?t=${token}`;
      }

      formApi.sign(url, formData).then(async result => {

        if (result.error) {
          setStatus('AGENT_SIGNING');
          setMessageNode(getMessageNode(lang.msgPleaseTryAgain, true));
        } else {
          setFormId(result.data.formId);
          setStatus('SELECT_ACTION');
          setCurrentSignUser('CLIENT');
          setNoteForDocument(langSignDialog.captionAgentSigned);
          setMessageNode(getMessageNode(langSignDialog.msgSuccessAgentSignAndSelect));
        }
      });
      return;
    }

    if (signSpecIdx < signSpecs.length - 1) {
      setSignSpecIdx(signSpecIdx + 1);
    } else {
      setStatus('GENERATING');

      // const formData = new FormData();
      // for (let key in updatedSigns) {
      //   formData.append(
      //     key,
      //     await fetch(updatedSigns[key]).then(r => r.blob()),
      //   );
      // }
      // formData.append('publicUrl',PUBLIC_URL);

      if (formIndex === 'form3' || formIndex === 'form4' || formIndex === 'form5' || formIndex === 'form6') {
        formData.append('clientIdentificationDocNo', requiredInfoForm.values.clientIdentificationDocNo ?? '');
        formData.append('isHKID', checkIsHKID(requiredInfoForm.values.clientIdentificationDocNo ?? '') ? 'true' : 'false')
      }

      let url = isClientSignNow ? `${BASE_URL}/form/generate/${id}/${formIndex}/${locale}/pdf?t=${token}`
        : `${BASE_URL}/form/agentSignForm/${id}/${formIndex}/${locale}/pdf?t=${token}`;
      if (formIndex === 'form4' || formIndex === 'form6') {
        url = isClientSignNow ? `${BASE_URL}/form/generate-client-form/${id}/${formIndex}/${locale}/pdf?t=${token}`
          : `${BASE_URL}/form/agentSignClientForm/${id}/${formIndex}/${locale}/pdf?t=${token}`;
      }


      formApi.sign(url, formData).then(async result => {

        if (result.error) {
          setStatus('AGENT_SIGNING');
          setMessageNode(getMessageNode(lang.msgPleaseTryAgain, true));
        } else {
          setSignedFormUrl(result.data.filename);
          setStatus('SUCCESS');
          setFormStatus(result.data.formStatus);
          setFormId(result.data.formId);
          setMessageNode(getMessageNode(requireSellerSign ? langSignDialog.msgSuccessClientSign : langSignDialog.titleGenerated));
          if (requireSellerSign) {
            setNoteForDocument(langSignDialog.captionClientSigned);
          }
          setActiveStep(activeStep + 1);
        }

      });

    }
  };

  const signaturePad = signaturePadBindingRef.current?._signaturePad();
  useLayoutEffect(() => {
    signaturePad?.clear();
    setCurrentSignBlob(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signSpecIdx]);

  const [currentWindowWidth, setCurrentWindowWidth] = React.useState(window.innerWidth);
  const updateWidthAndHeight = () => {
    setCurrentWindowWidth(window.innerWidth);
  };
  React.useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });

  const signaturePanel = () => <Grid
    item container spacing={1}
    md={12}
    sm={12}
    xs={12}
    style={{ display: 'flex', justifyContent: 'center' }}
  >
    <div>
      <div style={{ display: 'flex' }}>
        <CardHeader
          subheader={langSignDialog.msgSigningTipsShort}
          title={signSpecs[signSpecIdx]?.title}
        />
        <div style={{ flexGrow: 2 }} />
      </div>
      <div style={{
        position: 'relative',
        width: currentWindowWidth * 0.9,
        height: currentWindowWidth * 0.9 * 9 / 16,
      }}>
        <div style={{ display: 'flex', position: 'absolute', right: '1%', top: '1%' }}>
          {/* ------ confirm button   ------*/}
          <Button
            color="primary"
            variant="contained"
            // {...signaturePadBindingRef.current?.saveButton()}
            onClick={() => {
              onSignature();
            }}
          >
            {lang.actionConfirm}
          </Button>
          {/* ------ clear button  ------*/}
          <Button
            className={classes.signAreaActionButton}
            color="primary"
            variant="outlined"
            onClick={() => {
              signaturePadBindingRef.current?.clear();
              setCurrentSignBlob(null);
            }}
          >
            {langSignDialog.actionClear}
          </Button>
        </div>
        <SignaturePadCanvas
          currentWindowWidth={currentWindowWidth}
          signaturePadBindingRef={signaturePadBindingRef}
          onChange={(blobUrl) => setCurrentSignBlob(blobUrl)}
        // onSignature={onSignature}
        />
        {/* <canvas
        style={{ background: '#ffffbb' }}
        ref={signaturePadBindingRef.current?.canvas()}
        // key={`sig-canvas-${signSpecIdx}`}
        // {...signaturePadBinding.canvas()}
        width={currentWindowWidth * 0.9}
        height={currentWindowWidth * 0.9 * 9/16}
      /> */}
      </div>
    </div></Grid>;

  const loadingPanel = () => <Grid
    item
    md={12}
    sm={12}
    xs={12}
    style={{ display: 'flex', justifyContent: 'center' }}
  ><CardHeader
      avatar={<CircularProgress />}
      title={langSignDialog.msgGenerating}
    />
  </Grid>;

  const viewSignedFormUrl = `${BASE_URL}/form/viewSignedForm/${id}/${formIndex}/${locale}/pdf?t=${token}`;

  const successMessage = formStatus === 'S' ? langSignDialog.titleGenerated : langSignDialog.msgSuccessAgentSign;

  const [showSigningLink, setShowSigningLink] = useState(false);

  const getSteps = () => {
    if (!requireSellerSign) {
      return [langSignDialog.captionStepSignByAgent, langSignDialog.captionStepDone]
    } else {
      return [
        langSignDialog.captionStepSignByAgent,
        status === 'CLIENT_SIGNING' ? langSignDialog.captionStepSignByClient : langSignDialog.captionStepInviteClientToSign,
        langSignDialog.captionStepDone
      ]
    }
  }

  const handledelete = (cid: any, selectedProperties: string[], stockCartFilterOption: string) => {

    for (var i = 0; i < selectedProperties.length; i++) {
      dispatch({
        type: 'PropertyMatching.RemoveStockCartRequested', payload: {
          cid: cid ?? '', pid: [selectedProperties[i]], propertyStatus: stockCartFilterOption,
        }
      })
    }
  }
  const [activeStep, setActiveStep] = useState(0);

  const clientSignUrlRef: any = useRef(null);

  const successPanel = () => <Grid
    item
    md={12}
    sm={12}
    xs={12}
    style={{ display: 'flex', justifyContent: 'center' }}
  >
    {showSigningLink ?
      printClientLinkInfo() :
      null
    }
  </Grid>;

  const printClientLinkInfo = () => <Grid
    item container spacing={0}
    // md={12}
    // sm={12}
    // xs={12}
    style={{ display: 'flex', justifyContent: 'center' }}
  >

    <Grid
      item
      md={12}
      sm={12}
      xs={12}
      container spacing={1}
      style={{ display: 'flex', justifyContent: 'center' }}
    // style={{ display: 'flex', flexDirection: 'column' }}
    >
      <Grid
        item
        md={7}
        sm={7}
        xs={10}
      >
        <TextField
          fullWidth
          // multiline rows="2"
          ref={clientSignUrlRef}
          margin="dense"
          // id={'TEST'}
          disabled={true}
          variant="outlined"
          value={clientSignUrl}
        />
        <FormHelperText style={{ fontSize: '13px', fontWeight: 'bold' }} error>{format(langSignDialog.msgWarnLinkExpiry, timeToExpiryInHour)}</FormHelperText>
      </Grid>

      <Grid
        item
        // md={2}
        // sm={2}
        // xs={2}
        style={{ paddingTop: '13px' }}
      >
        <CopyToClipboard text={clientSignUrl}>
          <Button variant="contained" color="primary" size="small"
            onClick={() => {
              dispatch({ type: 'Layout.AlertMessageAdded', payload: { message: langSignDialog.msgSuccessLinkCopied } });
            }}
          >
            {langSignDialog.actionCopyLink}
          </Button>
        </CopyToClipboard>
      </Grid>

    </Grid>
    {/* <Grid
        item
        md={12}
        sm={12}
        xs={12}
        // style={{display:'flex', justifyContent: 'flex-start'}}
      >
        <Typography style={{fontWeight: 'bold'}} color="error">{format(langSignDialog.msgWarnLinkExpiry, timeToExpiryInHour)}</Typography>
      </Grid> */}
  </Grid>;

  const getActionButtonName = () => {
    switch (status) {
      case 'AGENT_SIGNING':
      case 'CLIENT_SIGNING':
        return '';

      case 'SELECT_ACTION':
        return clientSignAction === 'EMAIL' ? langSignDialog.actionSendEmail : lang.actionContinue;
      case 'SUCCESS':
        return lang.actionEnd;
      default:
        return '';
    }
  }

  const getMessageNode = (content: string, isWarning = false) => {
    return (<Typography className={classes.message}
      style={{ color: isWarning ? warningWord : successWord, backgroundColor: isWarning ? warningBackground : successBackground }}>
      {content}
    </Typography>);
  }

  const selectActionPanel = () => (
    <Grid
      item
      md={12}
      sm={12}
      xs={12}
      style={{ justifyContent: 'center', display: 'flex' }}
    >
      <FormControl component="fieldset">
        <RadioGroup aria-label="client-sign-action" value={clientSignAction} onChange={(event) => {
          setClientSignAction((event.target as HTMLInputElement).value);
        }}>
          <FormControlLabel value="SIGN_NOW" control={<Radio color="primary" />} label={langSignDialog.actionClientSignNow} />
          <When test={formIndex !== 'form1' && clientSignAction === 'SIGN_NOW'}>
            <Grid
              item
              className={classes.clientActionInputFields}
            >
              <TextField
                fullWidth
                margin="dense"
                name="headingChi"
                variant="outlined"
                label={langSignDialog.captionIdentificationDocNo}
                {...requiredInfoForm.bind('clientIdentificationDocNo')}
                InputProps={{
                  endAdornment: requiredInfoForm.values.clientIdentificationDocNo && !checkIsHKID(requiredInfoForm.values.clientIdentificationDocNo ?? '') ?
                    <InputAdornment position="end">
                      <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{`${langSignDialog.msgNonHKID}`}</span>} aria-label='hot-pick-expiry'>
                        <IconButton
                          style={{ color: '#FFBF00' }}
                          aria-label="hkid-indicator"
                          edge="end"
                        >
                          <ErrorIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment> : null
                }}
              />
              <FormHelperText>{`${langSignDialog.msgFormatForHKID}`}</FormHelperText>
            </Grid>
          </When>
          <FormControlLabel value="EMAIL" control={<Radio color="primary" />} label={langSignDialog.actionInviteClientToSign} />
          <When test={clientSignAction === 'EMAIL'}>
            <Grid
              item
              className={classes.clientActionInputFields}
            >
              <TextField
                fullWidth
                margin="dense"
                name="headingChi"
                // onChange={handleChange}
                variant="outlined"
                label={langSignDialog.captionClientEmail}
                {...requiredInfoForm.bind('clientEmail')}
              />
            </Grid>
          </When>
          <When test={clientSignAction === 'EMAIL'}>
            <Grid
              item
              style={{ marginTop: '10px' }}
              className={classes.clientActionInputFields}
            >
              <TextField
                fullWidth
                margin="dense"
                name="headingChi"
                required
                variant="outlined"
                label={langSignDialog.captionClientVerificationContact}
                {...requiredInfoForm.bind('clientVerificationContact')}
              />
            </Grid>
          </When>
          <FormControlLabel value="LINK" control={<Radio color="primary" />} label={langSignDialog.actionGenerateClientSigningLink} />
          <When test={clientSignAction === 'LINK'}>
            <Grid
              item
              className={classes.clientActionInputFields}
            >
              <TextField
                fullWidth
                margin="dense"
                name="headingChi"
                required
                variant="outlined"
                label={langSignDialog.captionClientVerificationContact}
                {...requiredInfoForm.bind('clientVerificationContact')}
              />
            </Grid>
          </When>
        </RadioGroup>
      </FormControl>
    </Grid>
  )

  return <Dialog
    style={{ paddingTop: appBarHeight }} className={classes.dialogRoot}
    keepMounted
    open={open}
    fullScreen
    // onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <AppBar style={{ position: 'fixed', top: 0, paddingTop: iosStatusBarHeight, transform: 'translate3d(0,0,0)', }} className={classes.appBar}>
      <Toolbar >
        <IconButton style={{ display: status === 'SUCCESS' || status === 'GENERATING' ? 'none' : 'flex' }} edge="start" color="inherit" onClick={() => { reset(); onCloseSigningDialog(); }} aria-label="close">
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" className={classes.dialogTitle}>
          {langSignDialog.actionSign + (currentLocale === 'en' ? ' ' : '') + displayFormName}
        </Typography>
      </Toolbar>
    </AppBar>
    <DialogContent>
      {confirmDialog.render()}
      <Grid item container spacing={1}
        md={12}
        sm={12}
        xs={12}
      // style={{ display: 'flex',  justifyContent: 'center'}}
      >
        {/* --------------- stepper ------------- */}
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
        >
          <FormSigningStepper steps={getSteps()} activeStep={activeStep} />

        </Grid>

        {/* --------------- form info ------------- */}
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <FormSigningInfo {...props}
            noteForDocument={noteForDocument}
            noteForDocumentIsWarning={status === 'SUCCESS' && (clientSignAction === 'EMAIL' || clientSignAction === 'LINK')}
            documentUrl={!isNonEmpty(noteForDocument) ?
              (formIndex === 'form4' || formIndex === 'form6' ? `${BASE_URL}/form/generate-client-form/${id}/${formIndex}/${locale}/pdf?t=${token}`
                : (isNonEmpty(signedFormUrl) ? `${BASE_URL}/files/${signedFormUrl}` : `${BASE_URL}/form/generate/${id}/${formIndex}/${locale}/pdf?t=${token}`))
              : formStatus === 'S' ? `${BASE_URL}/files/${signedFormUrl}` : viewSignedFormUrl}
            completed={formStatus === 'S'}
          />

        </Grid>

        {/* --------------- message ------------- */}
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Grid container spacing={1}
            item
            md={8}
            sm={8}
            xs={12}
          >
            {messageNode}
          </Grid>
          {/* <Typography className={classes.message} style={{...getMessageColorStyle()}}>{getMessageContent()}</Typography> */}
        </Grid>

        {/* --------------- main panel ------------- */}
        {/* {{
          AGENT_SIGNING: signaturePanel,
          SELECT_ACTION: selectActionPanel,
          CLIENT_SIGNING: signaturePanel,
          GENERATING: loadingPanel,
          SUCCESS: successPanel,
        }[status]()} */}
        {status === 'AGENT_SIGNING' || status === 'CLIENT_SIGNING' ? signaturePanel() : null}
        {status === 'SELECT_ACTION' && selectActionPanel()}
        {status === 'GENERATING' && loadingPanel()}
        {status === 'SUCCESS' && successPanel()}
        {/* {successPanel()} */}

        {/* --------------- action button ------------- */}
        <When test={isNonEmpty(getActionButtonName())}>
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            style={{ justifyContent: 'center', display: 'flex' }}
          >
            <Button variant="contained" color="primary"
              onClick={() => {
                setMessageNode(null);
                if (status === 'SELECT_ACTION') {

                  switch (clientSignAction) {
                    case 'SIGN_NOW':
                      if (!requiredInfoForm.validate()) {
                        return;
                      }
                      setIsClientSignNow(true);
                      setStatus('CLIENT_SIGNING');
                      setSignSpecs([
                        ...signSpecs,
                        {
                          // title: <span><b>{langSignDialog.captionVendor}</b> {langSignDialog.actionSign}</span>,
                          title: <span>{format(langSignDialog.msgSignAreaTitle, clientName)}</span>,
                          name: 'sellerSign'
                        }
                      ]);
                      setSignSpecIdx(signSpecIdx + 1);
                      break;
                    case 'EMAIL':
                      if (!requiredInfoForm.validate()) {
                        return;
                      }
                      inviteClientToSign();
                      break;
                    case 'LINK':
                      if (!requiredInfoForm.validate()) {
                        return;
                      }
                      generateClientSigningLink();
                      break;
                  }
                } else if (status === 'SUCCESS') {
                  if (clientSignAction === 'LINK') {
                    confirmDialog.confirm(langSignDialog.msgWarningSignByUrl, lang.actionConfirm, lang.actionCancel).then((confirmed) => {
                      if (confirmed) {
                        if (clientuploadform4or6) {
                          handledelete(id ?? '', selectedProperties ?? [], form4or6option ?? '')
                          setSelectedProperties([]);
                        }
                        reset(); onClose();
                      }
                    })
                  } else {
                    if (clientuploadform4or6) {
                      handledelete(id ?? '', selectedProperties ?? [], form4or6option ?? '')
                      setSelectedProperties([]);
                    }
                    reset(); onClose();
                  }
                }
              }}
            >
              {getActionButtonName()}
            </Button>
          </Grid>
        </When>



      </Grid>



    </DialogContent>
  </Dialog>;
};

export default NewFormSigningDialog;


export interface SignaturePadCanvasProps {
  // onSignature: (blobUrl: string) => any;
  signaturePadBindingRef: MutableRefObject<ReturnType<typeof useSignaturePad> | null>;
  currentWindowWidth: number;
  onChange: (blobUrl: string | null) => any;
}

export const SignaturePadCanvas = (props: SignaturePadCanvasProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const signaturePadRef = useRef<SignaturePad | null>(null);
  const signaturePadBinding = useSignaturePad(canvasRef, signaturePadRef);
  props.signaturePadBindingRef.current = signaturePadBinding;

  useLayoutEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    signaturePadRef.current = new SignaturePad(canvasRef.current!, { minWidth: 3.5, maxWidth: 6.5 });
    (canvasRef.current as any).signaturePad = signaturePadRef.current;
  }, [canvasRef, signaturePadRef]);

  const handleChange = () => {
    if (signaturePadRef.current?.isEmpty()) {
      props.onChange(null);
    } else {
      const dataURL = signaturePadRef.current?.toDataURL();
      props.onChange(window.URL.createObjectURL(dataURLToBlob(dataURL)));
    }
  }

  return <canvas
    style={{ background: '#ffffbb' }}
    ref={canvasRef}
    // key={`sig-canvas-${signSpecIdx}`}
    // {...signaturePadBinding.canvas()}
    onMouseUp={handleChange}
    onTouchEnd={() => {
      // For chrome & safari mobile
      handleChange();
    }}
    onPointerUp={() => {
      // For firefox mobile
      if (!document.hasOwnProperty('ontouchend')) {
        handleChange();
      }
    }}
    width={props.currentWindowWidth * 0.9}
    height={props.currentWindowWidth * 0.9 * 9 / 16}
  />
};


const dataURLToBlob = (dataURL: any) => {
  const parts = dataURL.split(';base64,');
  const contentType = parts[0].split(":")[1];
  const raw = window.atob(parts[1]);
  const rawLength = raw.length;
  const uInt8Array = new Uint8Array(rawLength);

  for (var i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  return new Blob([uInt8Array], { type: contentType });
};

export const useSignaturePad = (canvasRef: RefObject<HTMLCanvasElement>, signaturePadRef: MutableRefObject<SignaturePad | null>) => {
  // useLayoutEffect(() => {
  //   if (!canvasRef.current) {
  //     return;
  //   }
  //   signaturePadRef.current = new SignaturePad(canvasRef.current!, { minWidth: 3.5, maxWidth: 6.5 });
  // }, [ canvasRef, signaturePadRef ]);

  return {
    // saveButton: () => ({
    //   onClick: () => {
    //     const dataURL = signaturePadRef.current?.toDataURL();
    //     if (!signaturePadRef.current?.isEmpty() && dataURL) {
    //       onSignature(window.URL.createObjectURL(dataURLToBlob(dataURL)));
    //     }
    //   }
    // }),
    canvas: () => ({
      ref: canvasRef,
    }),
    clear: () => {
      signaturePadRef.current?.clear();
    },
    currentData: () => {
      return signaturePadRef.current?.isEmpty() ? undefined : signaturePadRef.current?.toDataURL();
    },
    _signaturePad: () => {
      return signaturePadRef.current;
    },
  }
};