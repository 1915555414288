import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';

const useStyles = makeStyles(theme => ({
	root: {},
	title: {

	},
	row: {
		height: '42px',
		display: 'flex',
		alignItems: 'center',
		marginTop: theme.spacing(1)
	},
	spacer: {
		flexGrow: 1
	},
	importButton: {
		marginRight: theme.spacing(1)
	},
	exportButton: {
		marginRight: theme.spacing(1)
	},
	searchInput: {
		marginRight: theme.spacing(1)
	}
}));

const MemoEnquiryToolbar = (props: any) => {
	const { className, ...rest } = props;
	const { langMemoEnquiry } = useSelector((state: IRootState) => state.locale);

	const classes = useStyles();

	return (
		<div
			{...rest}
			className={clsx(classes.root, className)}
		>
			<div className={classes.row}>
				<Typography
					className={classes.title}
					variant="h2"
				>
					{langMemoEnquiry.titleMemoEnquiry}
				</Typography>
			</div>
		</div>
	)
}

MemoEnquiryToolbar.propTypes = {
    className: PropTypes.string
};

export default MemoEnquiryToolbar;