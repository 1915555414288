import { IconButton, InputBase, makeStyles, MenuItem, Select, SelectProps, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducers';
import { format } from 'util';


const useStyles = makeStyles((theme) => ({
  /* Styles applied to the root element. */
  root: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    // Increase the specificity to override TableCell.
    '&:last-child': {
      padding: 0,
    },
  },
  /* Styles applied to the Toolbar component. */
  toolbar: {
    minHeight: 52,
    paddingRight: 2,
  },
  /* Styles applied to the spacer element. */
  spacer: {
    flex: '1 1 100%',
  },
  /* Styles applied to the caption Typography components if `variant="caption"`. */
  caption: {
    flexShrink: 0,
  },
  /* Styles applied to the Select component root element. */
  selectRoot: {
    // `.selectRoot` should be merged with `.input` in v5.
    marginRight: 32,
    marginLeft: 8,
  },
  /* Styles applied to the Select component `select` class. */
  select: {
    paddingLeft: 2,
    paddingRight: 8,
    textAlign: 'right',
    textAlignLast: 'right', // Align <select> on Chrome.
  },
  /* Styles applied to the Select component `icon` class. */
  selectIcon: {
    top: 1,
  },
  /* Styles applied to the `InputBase` component. */
  input: {
    color: 'inherit',
    fontSize: 'inherit',
    flexShrink: 0,
  },
  pageInput: {
    fontSize: '12px',
    width: '3rem',
    borderColor: '#546e7a',
    borderRadius: '4px',
    borderWidth: '1px',
    padding: '3px',
    textAlign: 'center',
    '&.hover': {
      borderColor: '#1565c0'
    },
    '&:focus': {
      borderColor: '#1565c0'
    }
  },
  /* Styles applied to the MenuItem component. */
  menuItem: {},
  /* Styles applied to the internal `TablePaginationActions` component. */
  actions: {
    flexShrink: 0,
    marginLeft: 20,
  },
}));

const defaultRowsPerPageOptions = [10, 25, 50, 100];

export interface TablePaginationProps {
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes: any;
  /**
   * @ignore
   */
  className?: string;
  /**
   * @ignore
   */
  colSpan?: number;
  /**
   * The component used for the root node.
   * Either a string to use a DOM element or a component.
   */
  // component: React.ReactNode;
  /**
   * The total number of rows.
   */
  count: number;
  /**
   * Customize the rows per page label. Invoked with a `{ from, to, count, page }`
   * object.
   */
  labelRowsPerPage: React.ReactNode;
  /**
   * Callback fired when the page is changed.
   *
   * @param {object} event The event source of the callback.
   * @param {number} page The page selected.
   */
  onChangePage: (event: React.MouseEvent | null, page: number) => void;
  /**
   * Callback fired when the number of rows per page is changed.
   *
   * @param {object} event The event source of the callback.
   */
  onChangeRowsPerPage?: (event: React.ChangeEvent<any>) => void,
  /**
   * The zero-based index of the current page.
   */
  page: number;
  /**
   * The total number of pages.
  */
  totalPages: number;
  /**
   * The number of rows per page.
   */
  rowsPerPage: number;
  /**
   * Customizes the options of the rows per page select field. If less than two options are
   * available, no select field will be displayed.
   */
  rowsPerPageOptions: number[];
  /**
   * Props applied to the rows per page [`Select`](/api/select/) element.
   */
  SelectProps?: SelectProps;
  /**
   * To locate the position to scroll to
   */
  scrollocate?: number;
}
/**
 * A `TableCell` based component for placing inside `TableFooter` for pagination.
 */
export default function TablePagination(props: TablePaginationProps) {
  const {
    // classes,
    className,
    colSpan: colSpanProp,
    // component: Component = TableCell,
    count,
    labelRowsPerPage = 'Rows per page:',
    onChangePage,
    onChangeRowsPerPage,
    page,
    totalPages,
    rowsPerPage,
    rowsPerPageOptions = defaultRowsPerPageOptions,
    SelectProps = {},
    scrollocate,
    ...other
  } = props;

  const { lang } = useSelector((state: IRootState) => state.locale);
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles();

  const isSmallScreen = useMediaQuery('(max-width: 400px)');

  const [pageInput, setPageInput] = useState((page + 1).toString());

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (isNaN(parseInt(pageInput))) {
      setPageInput((page + 1).toString());
    }
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter' && parseInt(pageInput) > 0 && parseInt(pageInput) <= totalPages && parseInt(pageInput) - 1 !== page) {
      event.preventDefault();
      event.stopPropagation();
      onChangePage(null, parseInt(pageInput) - 1);
    }

  }
  const onChangePageBlur = (event: React.ChangeEvent<HTMLDivElement>): void => {

    if (isNaN(parseInt(pageInput))) {
      setPageInput((page + 1).toString());
    }
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (parseInt(pageInput) > 0 && parseInt(pageInput) <= totalPages && parseInt(pageInput) - 1 !== page) {
      onChangePage(null, parseInt(pageInput) - 1);

    }
  }

  useEffect(() => {
    if (parseInt(pageInput) != page + 1) {
      window.scrollTo(0, scrollocate ?? 500);
    }
    setPageInput((page + 1).toString());

  }, [page]);

  useEffect(() => {
    if (page >= totalPages && totalPages != 0) {
      setPageInput((totalPages - 1).toString());
      onChangePage(null, totalPages - 1);
    } else {
      setPageInput((page + 1).toString());
    }
  }, [totalPages, rowsPerPage]);



  return (
    <div className={clsx(classes.root, className)} {...other} style={{ overflowX: isSmallScreen ? 'scroll' : 'hidden' }}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.spacer} />
        <Typography color="inherit" variant="body2" className={classes.caption}>
          {`${format(lang.captionTotalPages, totalPages)},`}&nbsp;
        </Typography>
        {
          rowsPerPageOptions.length > 1 &&
          <Typography color="inherit" variant="body2" className={classes.caption}>
            {labelRowsPerPage}:
          </Typography>
        }
        {rowsPerPageOptions.length > 1 && (
          <Select
            classes={{
              select: classes.select,
              icon: classes.selectIcon,
            }}
            input={<InputBase className={clsx(classes.input, classes.selectRoot)} />}
            value={rowsPerPage}
            onChange={onChangeRowsPerPage}
          // {...SelectProps}
          >
            {rowsPerPageOptions.map((rowsPerPageOption: number) => (
              <MenuItem
                className={classes.menuItem}
                key={rowsPerPageOption}
                value={rowsPerPageOption}
              >
                {rowsPerPageOption}
              </MenuItem>
            ))}
          </Select>
        )}

        <IconButton
          onClick={(ev) => onChangePage(ev, page - 1)}
          disabled={page === 0}
          color="inherit"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <input className={clsx(classes.input, classes.pageInput)} onBlur={onChangePageBlur}

          onChange={(ev) => {
            if (isNaN(Number(ev.target.value))) {
              return;
            } else {
              setPageInput((+Number(ev.target.value)).toString())
            }
          }
          }

          onKeyDown={onKeyDown} value={pageInput}></input>
        <IconButton
          onClick={(ev) => onChangePage(ev, page + 1)}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          color="inherit"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
      </Toolbar>
    </div >
  );
};

