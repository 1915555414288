import { Grid, makeStyles, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { UserDTO } from 'common/dto';
import { useForm } from 'common/hooks';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState, PASDispatch } from 'reducers';

const useStyles = makeStyles(() => ({
  root: {

  },
  patchOutlineAutocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
      paddingLeft: 8,
    }
  },
  dialogContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60%',
  },
  textArea: {
    width: '90%'
  }
}));

interface StartImpersonationDialogProps {
  notificationId: string;
  open: boolean;
  handleClose:  () => void;
}

const StartImpersonationDialog = ({notificationId, open, handleClose}: StartImpersonationDialogProps) => {
  const dispatch = useDispatch() as PASDispatch;
  const { langUser, lang } = useSelector((state: IRootState) => state.locale);

  const passwordForm = useForm<UserDTO>({}, {
    validations: [
      (values) => values.password ? null : [[ 'password', ' ' ]]  ,
    ],
  });
	
	const handleConfirm =() =>{
		if (!passwordForm.validate()){
			return;
		}
		dispatch({type: 'User.StartImpersonationRequested', payload:{password: passwordForm.values.password ??'', notificationId: notificationId}});
		handleClose();
		passwordForm.setValues({});
		passwordForm.stopValidation();
	}
  
  return (
    <div>
      
      <Dialog open={open} onClose={handleClose} aria-labelledby="start-impersonation-dialog">
        <DialogTitle id="start-impersonation-dialog-title">
          <Typography style={{fontWeight: 'bold'}}>
						{langUser.actionStartImpersonation }
					</Typography>
        </DialogTitle>
        <DialogContent>
      
          <Grid item container md={12} xs={12} spacing={1} >
						<TextField
							fullWidth
							type="password"
							label={langUser.captionYourPassword}
							margin="dense"
							{...passwordForm.bind('password')}
							helperText={langUser.msgInputPasswordToConfirmIdentity}
							variant="outlined"
						/>
           
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {lang.actionCancel}
          </Button>
          <Button onClick={handleConfirm} color="primary" variant="contained" autoFocus>
            {lang.actionConfirm}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default StartImpersonationDialog;
