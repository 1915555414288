import { Avatar, Grid, IconButton, Theme, Tooltip, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useConfirmDialog } from 'common/ConfirmDialog';
import { CAAgentRatingCommentDTO } from 'common/dto';
import { AgentCommentIcon, AgentRatingCommentApproveIcon, AgentRatingCommentRejectIcon, ClockIcon, PSClientInfoIcon, StarSolidIcon, StarWhiteIcon, TagIcon } from 'common/ui';
import { isNonEmpty, multiLang } from 'common/utils';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IRootState } from 'reducers';
import theme from 'theme';
import { format } from 'util';
const PUBLIC_URL = process.env['PUBLIC_URL'];
const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		// boxShadow: 'none',
		background: '#fff',
		color: '#000',
	},
	flexGrow: {
		flexGrow: 1
	},
	signOutButton: {
		marginLeft: theme.spacing(1)
	},
	navbar: {
		marginLeft: theme.spacing(3),
	},
	activeNavBtn: {
		backgroundColor: theme.palette.primary.dark,
	},
	brand: {
		color: '#183B68',
		display: 'flex', flexDirection: 'row', alignItems: 'center',
		lineHeight: '40px',
		fontWeight: 'bold',
	},
	list: {
		maxHeight: 400,
		minWidth: 300,
		maxWidth: 300,
		overflowY: 'auto'
	},
	notificationUnread: {
		fontWeight: 800
	},
	agentInfo: {
		display: 'flex',
	},
	nameCardAvatar: {
		display: 'flex',
		width: theme.spacing(4),
		height: theme.spacing(4),
		border: '1px solid #a8acb3',
	},
	agentName: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		paddingLeft: '5px',
		wordBreak: 'break-all',
	},
	customerInfoColor: {
		color: '#4285F4',
	},
	customerName: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	iconCommonStyle: {
		fontSize: '30px',
		padding: '5px',
	}
}));

interface AgentRatingCommentItemProps {
	CAAgentRatingComment: CAAgentRatingCommentDTO;
	canApprove: boolean;
}

const AgentRatingCommentItem = (props: AgentRatingCommentItemProps) => {
	const { CAAgentRatingComment: item, canApprove } = props;
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const { langAgentRatingCommentMgt, locale, lang, reviewStatusOptions} = useSelector((state: IRootState) => state.locale);
	const { DISPLAY_DATETIME_FORMAT } = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM);
	const token = useSelector((state: IRootState) => state.login.token ?? '');
	const { uid, privileges } = useSelector((state: IRootState) => state.login);

	const shouldCollapse = useMediaQuery(theme.breakpoints.down('sm'));

	const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);
	const sumitTimeFormat = DISPLAY_DATE_FORMAT + ' HH:mm';

	const confirmDialog = useConfirmDialog();

	const printAgentInfo = () => (
		<Grid item className={classes.agentInfo} style={{ display: 'flex', alignItems: 'center' }} 
			md={2}
			sm={2}
			// xs={12}
		>
			<Avatar className={classes.nameCardAvatar}
				src={isNonEmpty(item.agentPhotoFilename) ? `${BASE_URL}/files/${item.agentPhotoFilename}` : ''}
			/>
			<Typography className={classes.agentName}>{multiLang(locale, item.agentNameZh, item.agentNameEn)}</Typography>

		</Grid>
	)

	const maxRatingArray: number[] = [];
	var i;
	for (i = 1; i <= (item.maxRating ?? 1) + 1; i++) {
		maxRatingArray.push(i);
	}

	const printRatingStars = () => {
		const rating = item.rating ?? 0;
		const numFullStar = Math.floor(rating);
		const numHalfStar = numFullStar < rating ? 1 : 0;

		return (
			<Grid item style={{ display: 'flex', alignItems: 'center' }}
				md={2}
				sm={3}
				// xs={12}
			>
				{/* <RatingStars maxRating={item.maxRating ?? 0} rating={item.rating} color={'#F96401'} /> */}
				{maxRatingArray.map((_, i) => {
					return i > 0 ? (<StarSolidIcon key={`${item.id}-rating-star-${i}`} style={{ color: '#F96401', display: numFullStar >= i ? 'flex' : ' none' }} />) : null;
				}
				)}
				{maxRatingArray.map((_, i) => {
					return i > 0 ? (<StarWhiteIcon key={`${item.id}-white-rating-star-${i}`} style={{ color: '#F96401', display: (item.maxRating ?? 1) >= numFullStar && i > numFullStar  ? 'flex' : ' none' }} />) : null;
				}
				)}
			</Grid>
		)
	}

	const printCustomerInfo = () => (
		<Grid item style={{ display: 'flex', alignItems: 'center', }}
			md={3}
			sm={3}
			// xs={12}
		>
			<PSClientInfoIcon className={clsx(classes.customerInfoColor, classes.iconCommonStyle)} />
			<div className={clsx(classes.customerInfoColor, classes.customerName)} title={item.customerName ?? item.customerEmail}>{item.customerName ?? item.customerEmail}</div>
		</Grid>
	)

	const printSubmitTime = () => (
		<Grid item style={{ display: 'flex', alignItems: 'center', }}
			md={3}
			sm={3}
			// xs={12}
		>
			<ClockIcon className={classes.iconCommonStyle} />
			<div>{moment(item.submitDate).format(sumitTimeFormat) ?? ''}</div>
		</Grid>
	)

	const printReviewStatus = () =>(
		<Grid item style={{ display: 'flex', alignItems: 'center', }}
			md={2}
			sm={2}
			// xs={12}
		>
			<TagIcon className={classes.iconCommonStyle} />
			<div>{reviewStatusOptions[item.reviewStatus ?? '']}</div>
		</Grid>
	)

	const printActions = () => (
		<Grid item style={{ display: 'flex', alignItems: 'center', }}>
			<Tooltip title={langAgentRatingCommentMgt.actionApprove}>
				<IconButton style={{ padding: '3px' }} onClick={onApproveAction}>
					<AgentRatingCommentApproveIcon className={classes.iconCommonStyle} />
				</IconButton>
			</Tooltip>
			<Tooltip title={langAgentRatingCommentMgt.actionReject}>
				<IconButton style={{ padding: '3px' }} onClick={onRejectAction}>
					<AgentRatingCommentRejectIcon className={classes.iconCommonStyle} />
				</IconButton>
			</Tooltip>
		</Grid>
	)

	const printComment = () => (
		<Grid item container spacing={1}>
			<Grid item>
				<AgentCommentIcon className={classes.iconCommonStyle} />
			</Grid>
			<Grid item>
				<Typography style={{ whiteSpace: 'pre-line', paddingTop: '5px' }}>{item.comment ?? langAgentRatingCommentMgt.captionNoComment}</Typography>
			</Grid>
		</Grid>
	)

	const onApproveAction = () => {
    confirmDialog.confirm(langAgentRatingCommentMgt.msgConfirmApprove, lang.actionConfirm, lang.actionCancel).then(confirmed => {
			if (confirmed) {
        dispatch({ type: 'AgentRatingComment.ApproveActionRequested', payload: { ids: [ item.id ] } });
			}
		});
	};

	const onRejectAction = () => {
    confirmDialog.confirm(langAgentRatingCommentMgt.msgConfirmReject, lang.actionConfirm, lang.actionCancel).then(confirmed => {
			if (confirmed) {
        dispatch({ type: 'AgentRatingComment.RejectActionRequested', payload: { ids: [ item.id ] } });
			}
		});
	};


	return (
		<div style={{ padding: '5px 0px' }}>
			{confirmDialog.render()}
			<Grid
				item container spacing={1}
				md={12}
				sm={12}
				xs={12}
			>
			<Grid
				item container spacing={1}
				md={10}
				sm={10}
				xs={12}
			>
				<Grid
					item container spacing={1} style={{ width: 'fit-content' }}
					md={12}
					sm={12}
					xs={12}
					>
					{printAgentInfo()}
					{printRatingStars()}
					{printCustomerInfo()}
					{printSubmitTime()}
					{printReviewStatus()}
				</Grid>

				<Grid
					item
					md={12}
					sm={12}
					xs={12}
				>
					{printComment()}
				</Grid>
				
			</Grid>
			
				{item.reviewStatus === 'PENDING' && canApprove &&
					<Grid
						item 
						md={2}
						sm={2}
						xs={12}
					>
						{printActions()}
					</Grid>
				}
				{item.reviewStatus !== 'PENDING' &&
					<Grid
						item 
						md={2}
						sm={2}
						xs={12}
					>
						<div className={'common-row'}>

							{item.reviewStatus === 'APPROVED' ? <AgentRatingCommentApproveIcon className={classes.iconCommonStyle} />	:
							item.reviewStatus === 'REJECTED' ?	<AgentRatingCommentRejectIcon className={classes.iconCommonStyle} />
							: null}

							<div>{format(item.reviewStatus === 'APPROVED' ? langAgentRatingCommentMgt.msgApprovedBy	:
								item.reviewStatus === 'REJECTED' ?	langAgentRatingCommentMgt.msgRejectedBy
								: '', item.modifiedBy ?? '?'
							)}</div>
						</div>
					</Grid>
				}
				</Grid>
		</div>
	)
}

export default AgentRatingCommentItem;