import { ButtonGroup, ClickAwayListener, Grid, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { TransitionProps } from '@material-ui/core/transitions';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { hasPermission } from 'common/access-control';
import { useConfirmDialog } from 'common/ConfirmDialog';
import { ClientDetailDTO, Form4DTO, Form4PropertyDTO, Form6DTO, Form6PropertyDTO, UserDTO } from 'common/dto';
import NavigationBlockerDialog from 'common/elements/NavigationBlockerDialog';
import NewFormSigningDialog from 'common/elements/NewFormSigningDialog';
import { useForm, useRouteBasedState } from 'common/hooks';
import { useInfoDialog } from 'common/InfoDialog';
import { autoFillCompanyInfo, autoFillNameAndLicenseNumberOfAgent, filterUndefinedFields, multiLang } from 'common/utils';
import deepEqual from 'deep-equal';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClientActions, IRootState, PASDispatch } from 'reducers';
import { isNullOrUndefined } from 'util';
import Documents from 'views/PropertyDetail/components/Documents';
import { Form4Validations, Form6Validations } from 'views/PropertyDetail/components/form-validations';
import Form4Dialog, { form4Initial, form4Schedule1RecordInit } from './Form4Dialog';
import Form6Dialog, { form6Initial, form6Schedule1RecordInit } from './Form6Dialog';
import { useHistory } from 'react-router';
//const AButton = tooltipHoc(MuiButton);

const EMPTY = {};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    dialogTitle: {
      marginLeft: theme.spacing(2),
      flex: 1,
      color: 'white',
      fontWeight: 'bold',
    },
    dialogRoot: {
      '& .MuiDialog-paper': {
        overflowX: 'hidden',
      },
    },
  }),
);

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



interface DialogProps {
  cid?: string;
  selectedProperties: string[]; //list of propertyStockId
  validSelectedProperties: boolean; // status of selected properties matches the form type
  selectedFormIndex: number;
  setSelectedFormIndex: (index: number) => void;
  fileForm: any;
  clientuploadform4or6?: boolean;
  unsaved?: boolean;
  onSave?: () => void;
  setSelectedProperties?: any;
}

const FullScreenDialogForm4And6 = ({ cid, selectedProperties, validSelectedProperties, selectedFormIndex, setSelectedFormIndex, fileForm, clientuploadform4or6, unsaved, onSave, setSelectedProperties }: DialogProps) => {
  const classes = useStyles();
  // const [openFormDialog, setOpenFormDialog] = React.useState(false);
  const [openFormDialog, setOpenFormDialog] = useRouteBasedState(`/client-detail/${cid}/form-view`, selectedFormIndex !== 0);
  // useRouteBasedState(`/client-detail/${cid}/form-view`, selectedFormIndex !== 0);
  const dispatch = useDispatch() as PASDispatch;
  const { iosStatusBarHeight } = useSelector((state: IRootState) => state.layout);
  const history = useHistory();

  //get cid
  // const fetchedDetails = useSelector((state: IRootState) => state.clients.fetchedDetails) ?? {} ;
  // const cid =  Object.keys(fetchedDetails)[0];
  const currentClient = useSelector((state: IRootState) => state.clients.fetchedDetails[cid!]) ?? {};

  const defaultClientPhoneNumber = currentClient.otherContacts?.filter(c => c.type === 'TEL')?.[0]?.value ?? undefined;

  //get selected property details
  const stockCartList = useSelector((state: IRootState) => state.clients.stockCartList) ?? [];
  const form4SelectedProperties = stockCartList.filter((p) => selectedProperties.indexOf(String(p.id) ?? '') > -1 && (p.status === 'SALES' || p.status === 'BOTH'));
  const form6SelectedProperties = stockCartList.filter((p) => selectedProperties.indexOf(String(p.id) ?? '') > -1 && (p.status === 'RENT' || p.status === 'BOTH'));

  const { lang, langClientDetail, langForm4, langForm6, locale, districtOptions, langSignDialog, langPropertyStock } = useSelector((state: IRootState) => state.locale);


  const handleCloseDialog = () => {
    setOpenFormDialog(false);
  };

  //for split button
  const selectFormBtnMenuList = [langClientDetail.actionForm, langClientDetail.actionFillForm4, langClientDetail.actionFillForm6,
  langClientDetail.actionUploadForms, langClientDetail.actionForm4, langClientDetail.actionForm6];

  const [openFormButtonMenu, setOpenFormButtonMenu] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  // const [selectedFormIndex, setSelectedFormIndex] = React.useState(0);

  const [form4UploadDialogOpen, setForm4UploadDialogOpen] = useState(false);
  const [form6UploadDialogOpen, setForm6UploadDialogOpen] = useState(false);

  const handleClick = (index: number) => {

    if (selectedProperties.length === 0) {
      setOpenFormDialog(false);
    }
    switch (index) {
      case 0:
        setForm4UploadDialogOpen(false);
        setForm6UploadDialogOpen(false);
        setOpenFormDialog(false);
        break;
      case 1:
        if (stockCartList?.filter(p => selectedProperties.indexOf(String(p.id) ?? '') > -1 && p.status !== 'SALES' && p.status !== 'BOTH')?.length ?? 0 > 0) {
          warningDialogForForms.confirm(langClientDetail.msgForm4ForSalesPropertyStockOnly, lang.actionYes, lang.actionClose).then((confirmed: boolean) => {
            if (confirmed) {
            }
          });
        } else {
          setOpenFormDialog(selectedProperties.length === 0 ? false : true);
          setForm4UploadDialogOpen(false);
          setForm6UploadDialogOpen(false);
        }
        break;
      case 2:
        if (stockCartList?.filter(p => selectedProperties.indexOf(String(p.id) ?? '') > -1 && p.status !== 'RENT' && p.status !== 'BOTH')?.length ?? 0 > 0) {
          warningDialogForForms.confirm(langClientDetail.msgForm6ForRentPropertyStockOnly, lang.actionYes, lang.actionClose).then((confirmed: boolean) => {
            if (confirmed) {
            }
          });
        } else {
          setOpenFormDialog(selectedProperties.length === 0 ? false : true);
          setForm4UploadDialogOpen(false);
          setForm6UploadDialogOpen(false);
        }
        break;
      case 3:
      case 4:
        setOpenFormDialog(false);
        setForm4UploadDialogOpen(selectedProperties.length === 0 ? false : true);
        setForm6UploadDialogOpen(false);
        break;
      case 5:
        setOpenFormDialog(false);
        setForm4UploadDialogOpen(false);
        setForm6UploadDialogOpen(selectedProperties.length === 0 ? false : true);
        break;
    }
  };

  // useEffect(() => {
  //   
  // }, fileForm.values['FORM_4'])


  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedFormIndex(index);
    setOpenFormButtonMenu(false);
    handleClick(index);
  };

  const handleToggle = () => {
    setOpenFormButtonMenu(prevOpen => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpenFormButtonMenu(false);
  };


  //forms
  const currentForm4 = useSelector((state: IRootState) => state.clients.form4 ?? form4Initial);
  const form4 = useForm<Form4DTO>({}, { validations: Form4Validations(langForm4), scrollToErrorOnValidate: true, dataKeyPrefix: 'form4' });
  const form4Schedule1 = useForm<any>({ formProperty: [] }, { validations: [] });
  const currentForm4Schedule1 = currentForm4.formProperty; //those saved in DB
  const form4Scheduel1ToBeKept = currentForm4Schedule1?.filter(p => selectedProperties.includes(String(p.propertyStockId)));
  const form4SelectedPropertiesToBeAdded = form4SelectedProperties.filter(selectedProperty => !currentForm4Schedule1?.map(cp => String(cp.propertyStockId)).includes(String(selectedProperty.id!)));

  const currentForm6 = useSelector((state: IRootState) => state.clients.form6 ?? form6Initial);
  const form6 = useForm<Form6DTO>({}, { validations: Form6Validations(langForm6), scrollToErrorOnValidate: true, dataKeyPrefix: 'form6' });
  const form6Schedule1 = useForm<any>({ formProperty: [] }, { validations: [] });
  const currentForm6Schedule1 = currentForm6.formProperty; //those saved in DB
  const form6Scheduel1ToBeKept = currentForm6Schedule1?.filter(p => selectedProperties.includes(String(p.propertyStockId))).map(cf => { return { ...cf, landlordCommission: isNaN(Number(cf.landlordCommission?.replaceAll('%', '').replaceAll('$', ''))) ? '' : cf.landlordCommission } });
  const form6SelectedPropertiesToBeAdded = form6SelectedProperties.filter(selectedProperty => !currentForm6Schedule1?.map(cp => String(cp.propertyStockId)).includes(String(selectedProperty.id!)));

  //fetch form 4 and 6
  useEffect(() => {
    dispatch({ type: 'Form4.FetchRequested', payload: { buyerClientId: cid ?? '' } });
    dispatch({ type: 'Form6.FetchRequested', payload: { tenantClientId: cid ?? '' } });
  }, [cid]);

  //company setting
  const companyInfoSystemSetting = useSelector((state: IRootState) =>
    state.systemSettings?.Company?.AGENCY_COMPANY_INFO) ?? {};

  //agent profile
  const currentUid = useSelector((state: IRootState) => state.login.uid);
  const userDto = useSelector((state: IRootState) => state.user.fetchedDetails[currentUid!]) ?? EMPTY as Partial<UserDTO>;

  const { districtHkiOptions: districtHkiOptionsEn, districtNtOptions: districtNtOptionsEn, districtKltOptions: districtKltOptionsEn } = useSelector((state: IRootState) => state.locale._bundle['en']);
  const { districtHkiOptions: districtHkiOptionsZhHK, districtNtOptions: districtNtOptionsZhHK, districtKltOptions: districtKltOptionsZhHK } = useSelector((state: IRootState) => state.locale._bundle['zh_HK']);

  const districtEnOptions = {
    ...districtHkiOptionsEn,
    ...districtNtOptionsEn,
    ...districtKltOptionsEn,
  };
  const districtZhHkOptions = {
    ...districtHkiOptionsZhHK,
    ...districtNtOptionsZhHK,
    ...districtKltOptionsZhHK,
  };

  // Unsaved Check
  const [initialForm4Values, setInitialForm4Values] = useState<any>({});
  const [initialForm6Values, setInitialForm6Values] = useState<any>({});



  useEffect(() => {
    // Monitor on page reload (22/5/2023)
    if ((performance.getEntriesByType("navigation")[0] as PerformanceNavigationTiming).type == "reload") {
      openFormDialog ? history.goBack() : history.replace(`/client-detail/${cid}`, { bypassUnsavedCheck: true });
    }
  }, [])


  //update form values
  useEffect(() => {
    if (!openFormDialog) {
      return;
    }
    let initialForm4: Partial<Form4DTO> = { ...currentForm4 };
    let initialForm4Schedule = {
      formProperty: currentForm4.formProperty?.map(fp => {
        return { ...fp, vendorCommission: isNaN(Number(fp.vendorCommission?.replaceAll('%', '').replaceAll('$', ''))) ? '' : fp.vendorCommission } as Form4PropertyDTO
      }),
    };

    form4.setValues({ ...currentForm4, ...autoFillCompanyInfo('form4', currentForm4.formLocale ?? locale, companyInfoSystemSetting) });
    form4Schedule1.setValues({ formProperty: currentForm4.formProperty });
    if (isNullOrUndefined(currentForm4.id)) {
      initialForm4 = {
        ...form4Initial,
        ...autoFillCompanyInfo('form4', locale, companyInfoSystemSetting),
        ...autoFillNameAndLicenseNumberOfAgent('form4', locale, userDto),
        formLocale: locale,
        validityPeriodStart: moment().hours(0).minutes(0).seconds(0).milliseconds(0).toISOString(),
        validityPeriodEnd: moment().add(6, 'M').hours(0).minutes(0).seconds(0).milliseconds(0).toISOString(),
        purchaserName: multiLang(locale, currentClient.chineseName, currentClient.englishName),
        purchaserPhoneNumber: defaultClientPhoneNumber,
        formProperty: form4Schedule1RecordInit(form4SelectedProperties, form4.values.formLocale ?? locale, districtEnOptions, districtZhHkOptions)
      }
      initialForm4Schedule = {
        ...initialForm4Schedule,
        formProperty: form4Schedule1RecordInit(form4SelectedProperties, form4.values.formLocale ?? locale, districtEnOptions, districtZhHkOptions)
      }
      form4.setValues({ ...initialForm4 });
      form4Schedule1.setValues({ ...initialForm4Schedule });
      setInitialForm4Values([initialForm4, initialForm4Schedule]);
    } else { // with id
      form4Schedule1.updateValues('formProperty', [...form4Scheduel1ToBeKept ?? [], ...form4Schedule1RecordInit(form4SelectedPropertiesToBeAdded, currentForm4.formLocale ?? locale, districtEnOptions, districtZhHkOptions)]);
      setInitialForm4Values([initialForm4, {
        ...initialForm4Schedule,
        formProperty: [...form4Scheduel1ToBeKept ?? [], ...form4Schedule1RecordInit(form4SelectedPropertiesToBeAdded, currentForm4.formLocale ?? locale, districtEnOptions, districtZhHkOptions)]
      }]);
    }
  }, [openFormDialog, currentForm4])

  // // 
  // // 
  // // 
  // // 
  // // 

  useEffect(() => {
    form4.updateValues('formProperty', form4Schedule1.values.formProperty);
    // setInitialForm4Values([{
    //   ...initialForm4Values[0], formProperty: form4Schedule1.values.formProperty
    // }, initialForm4Values[1]
    // ]);
  }, [form4Schedule1.values])

  useEffect(() => {
    if (!openFormDialog) {
      return;
    }
    let initialForm6: Partial<Form6DTO> = { ...currentForm6 };
    let initialForm6Schedule = {
      formProperty: currentForm6.formProperty?.map(fp => {
        return { ...fp, landlordCommission: isNaN(Number(fp.landlordCommission?.replaceAll('%', '').replaceAll('$', ''))) ? '' : fp.landlordCommission } as Form6PropertyDTO
      }),
    };

    form6.setValues({ ...currentForm6, ...autoFillCompanyInfo('form6', currentForm6.formLocale ?? locale, companyInfoSystemSetting) });
    form6Schedule1.setValues({ formProperty: currentForm6.formProperty });
    if (isNullOrUndefined(currentForm6.id)) {
      initialForm6 = {
        ...form6Initial,
        ...autoFillCompanyInfo('form6', locale, companyInfoSystemSetting),
        ...autoFillNameAndLicenseNumberOfAgent('form6', locale, userDto),
        formLocale: locale,
        validityPeriodStart: moment().hours(0).minutes(0).seconds(0).milliseconds(0).toISOString(),
        validityPeriodEnd: moment().add(6, 'M').hours(0).minutes(0).seconds(0).milliseconds(0).toISOString(),
        tenantName: multiLang(locale, currentClient.chineseName, currentClient.englishName),
        tenantPhoneNumber: defaultClientPhoneNumber,
        formProperty: form6Schedule1RecordInit(form6SelectedProperties, form6.values.formLocale ?? locale, districtEnOptions, districtZhHkOptions)
      }
      initialForm6Schedule = {
        ...initialForm6Schedule,
        formProperty: form6Schedule1RecordInit(form6SelectedProperties, form6.values.formLocale ?? locale, districtEnOptions, districtZhHkOptions)
      }

      form6.setValues({ ...initialForm6 });
      form6Schedule1.setValues({ ...initialForm6Schedule });
      setInitialForm6Values([initialForm6, initialForm6Schedule]);
    } else {
      form6Schedule1.updateValues('formProperty', [...form6Scheduel1ToBeKept ?? [], ...form6Schedule1RecordInit(form6SelectedPropertiesToBeAdded, currentForm6.formLocale ?? locale, districtEnOptions, districtZhHkOptions)]);
      setInitialForm6Values([initialForm6, {
        ...initialForm6Schedule,
        formProperty: [...form6Scheduel1ToBeKept ?? [], ...form6Schedule1RecordInit(form6SelectedPropertiesToBeAdded, currentForm6.formLocale ?? locale, districtEnOptions, districtZhHkOptions)]
      }]);
    }
  }, [openFormDialog, currentForm6])

  useEffect(() => {
    form6.updateValues('formProperty', form6Schedule1.values.formProperty);
    // setInitialForm6Values([{
    //   ...initialForm6Values[0], formProperty: form6Schedule1.values.formProperty
    // }, initialForm6Values[1]
    // ]);
  }, [form6Schedule1.values])

  const unsavedStates: { [index: number]: boolean } = {
    1: selectedFormIndex !== 1 ? false : !deepEqual([filterUndefinedFields(form4.values), form4Schedule1.values], [filterUndefinedFields(initialForm4Values?.[0] ?? {}), initialForm4Values[1]], { strict: true }),
    2: selectedFormIndex !== 2 ? false : !deepEqual([filterUndefinedFields(form6.values), form6Schedule1.values], [filterUndefinedFields(initialForm6Values?.[0] ?? {}), initialForm6Values[1]], { strict: true }),
  }

  // 
  const warnOverwriteDialog = useConfirmDialog();
  const warningDialogForForms = useInfoDialog();

  const getFormStatus = () => {
    switch (selectedFormIndex) {
      case 1: return currentForm4.status;
      case 2: return currentForm6.status;
    }
  }

  //save actions
  const handleSave = (extraAction?: 'GENERATE_PDF' | 'SIGN_FORM', doOverwrite = false) => {
    switch (selectedFormIndex) {
      case 1: saveForm4(extraAction, doOverwrite); break;
      case 2: saveForm6(extraAction, doOverwrite); break;
      default:
        return;
    }
  };

  const saveForm4 = (extraAction?: 'GENERATE_PDF' | 'SIGN_FORM', doOverwrite = false) => {
    // if (!form4.validate() && !!form4.validationErrors.isValidityPeriodValid) return;
    if (isNullOrUndefined(form4.values.id)) {
      dispatch({
        type: 'Form4.CreationRequested', payload: {
          ...form4.values,
          buyerClientId: cid,
          formProperty: form4Schedule1.values.formProperty,
          status: doOverwrite ? 'D' : currentForm4.status,
          extraAction,
          date: (extraAction === 'GENERATE_PDF' || extraAction === undefined) ? null : moment().toISOString(),
          purchaserDate: (extraAction === 'GENERATE_PDF' || extraAction === undefined) ? null : moment().toISOString(),
          agentDate: (extraAction === 'GENERATE_PDF' || extraAction === undefined) ? null : moment().toISOString(),
        }
      } as ClientActions);
    } else {
      dispatch({
        type: 'Form4.UpdateRequested', payload: {
          ...form4.values,
          formProperty: form4Schedule1.values.formProperty,
          status: doOverwrite ? 'D' : currentForm4.status,
          extraAction,
          date: (extraAction === 'GENERATE_PDF' || extraAction === undefined) ? null : moment().toISOString(),
          purchaserDate: (extraAction === 'GENERATE_PDF' || extraAction === undefined) ? null : moment().toISOString(),
          agentDate: (extraAction === 'GENERATE_PDF' || extraAction === undefined) ? null : moment().toISOString(),
        }
      } as ClientActions);
    }

  };

  const saveForm6 = (extraAction?: 'GENERATE_PDF' | 'SIGN_FORM', doOverwrite = false) => {
    // if (!form6.validate() && !!form6.validationErrors.isValidityPeriodValid) return;
    if (isNullOrUndefined(form6.values.id)) {
      dispatch({
        type: 'Form6.CreationRequested', payload: {
          ...form6.values,
          tenantClientId: cid,
          formProperty: form6Schedule1.values.formProperty,
          status: doOverwrite ? 'D' : currentForm6.status,
          extraAction,
          tenantDate: (extraAction === 'GENERATE_PDF' || extraAction === undefined) ? null : moment().toISOString(),
          agentDate: (extraAction === 'GENERATE_PDF' || extraAction === undefined) ? null : moment().toISOString(),
        }
      } as ClientActions);
    } else {
      dispatch({
        type: 'Form6.UpdateRequested', payload: {
          ...form6.values,
          formProperty: form6Schedule1.values.formProperty,
          status: doOverwrite ? 'D' : currentForm6.status,
          extraAction,
          tenantDate: (extraAction === 'GENERATE_PDF' || extraAction === undefined) ? null : moment().toISOString(),
          agentDate: (extraAction === 'GENERATE_PDF' || extraAction === undefined) ? null : moment().toISOString(),
        }
      } as ClientActions);
    }
  };

  const isValidForm = () => {
    switch (selectedFormIndex) {
      case 1: return (form4.validate() && form4Schedule1.validate());
      case 2: return (form6.validate() && form6Schedule1.validate());
      default:
        return false;
    }
  }


  //for form signing
  const [singingDialogOpen, setSigningDialogOpen] = useState(false);

  const signIndex: { [no: number]: string } = {
    1: 'form4',
    2: 'form6',
  };


  const getUploadFormType = () => {
    switch (selectedFormIndex) {
      case 4: return 'FORM_4';
      case 5: return 'FORM_6';
      default: return 'DUMMY';
    }
  }

  const { privileges } = useSelector((state: IRootState) => state.login);

  const clientDetailDto = useSelector((state: IRootState) => state.clients.fetchedDetails[cid!]) ?? EMPTY as Partial<ClientDetailDTO>;
  const canSignForm = clientDetailDto.isLeadAgent && hasPermission(privileges, 'UPDATE', 'CLIENT');

  //--------------- check exist signed form with overlapping validity period

  const confirmSignAgainDialog = useConfirmDialog();

  const confirmCloseDialog = useConfirmDialog();

  useEffect(() => {
    if (selectedFormIndex === 1) {
      if (currentForm4.isExist === true) {
        const msg = currentForm4.invitationSent ? langSignDialog.msgWarnInvitationSentAlready : langSignDialog.msgWarnSignedFormExistForForm46;
        confirmSignAgainDialog.confirm(msg, lang.actionYes, lang.actionNo).then((confirmed: boolean) => {
          if (confirmed) {
            setSigningDialogOpen(true);
          }
        });
      } else if (currentForm4.isExist === false) {
        setSigningDialogOpen(true);
      }
    }
  }, [currentForm4.isExist, currentForm4.invitationSent])

  useEffect(() => {
    if (selectedFormIndex === 2) {
      if (currentForm6.isExist === true) {
        const msg = currentForm6.invitationSent ? langSignDialog.msgWarnInvitationSentAlready : langSignDialog.msgWarnSignedFormExistForForm46;
        confirmSignAgainDialog.confirm(msg, lang.actionYes, lang.actionNo).then((confirmed: boolean) => {
          if (confirmed) {
            setSigningDialogOpen(true);
          }
        });
      } else if (currentForm6.isExist === false) {
        setSigningDialogOpen(true);
      }
    }
  }, [currentForm6.isExist, currentForm6.invitationSent])


  const formsOptions: { [key: string]: string } = {
    DUMMY: lang.actionUpload,
    FORM_4: langPropertyStock.form4,
    FORM_6: langPropertyStock.form6,
  };

  // ------------- for form signing dialog
  const agentName = multiLang(locale, userDto.chineseName, userDto.englishName);

  const getClientName = () => {
    switch (selectedFormIndex) {
      case 1: return currentForm4.purchaserName;
      case 2: return currentForm6.tenantName;
    }
  }

  return (
    <div>
      {/* {confirmCloseDialog.render()} */}
      {confirmSignAgainDialog.render()}
      {warnOverwriteDialog.render()}
      {warningDialogForForms.render()}
      <NavigationBlockerDialog matchesUrl={`/client-detail/${cid}/form-view`} unsaved={openFormDialog ? unsavedStates[selectedFormIndex] : false} goBack />
      {/* --------------------- split button -----------------------*/}
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12}
          style={{ display: currentClient.isLeadAgent ? 'block' : 'none' }}
        >
          <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
            <Button disabled={(selectedProperties?.length ?? 0) < 1 || !validSelectedProperties} onClick={() => {
              unsaved ?
                warnOverwriteDialog.confirm(langClientDetail.msgSaveBeoreUploading, lang.actionYes, lang.actionNo).then((confirmed: boolean) => {
                  if (confirmed) {
                    if (typeof (onSave) !== undefined) {
                      onSave!()
                      handleClick(selectedFormIndex)
                    } else {
                      handleClick(selectedFormIndex)
                    }
                  }
                })
                :
                handleClick(selectedFormIndex)
            }
            }>{selectedFormIndex === 2 ? langClientDetail.actionFillForm6 : langClientDetail.actionFillForm4}</Button>
            {/* <Button
              color="primary"
              size="small"
              aria-controls={openFormButtonMenu ? 'split-button-menu' : undefined}
              aria-expanded={openFormButtonMenu ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <ArrowDropDownIcon />
            </Button> */}
          </ButtonGroup>
          <Popper style={{ zIndex: 1 }} open={openFormButtonMenu} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {selectFormBtnMenuList.map((option, index) => (
                        <MenuItem
                          style={{ display: index < 3 ? 'block' : 'none' }}
                          key={option}
                          disabled={index === 0 || index === 3}
                          // selected={index === selectedFormIndex}
                          onClick={event => handleMenuItemClick(event, index)}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>

        {/* ------------------- form4 upload ---------------- */}
        <Grid item xs={12} style={{ display: 'none' }}>
          {/* <FormUploadPane
            id="file-form-4" type="FORM_4" title={langClientDetail.actionForm4}
            fileForm={fileForm} idxName="form-4"
            // handleUpload={handleUpload}
            uploadPolicy={clientSettings[`FORM_4_UPLOAD_POLICY`]}
            // confirmDialog={confirmDialog}
            hasAgreementInfo
            openDialog={form4UploadDialogOpen}
            setOpenDialog={setForm4UploadDialogOpen}
            selectedProperties={form4SelectedProperties}
          /> */}
          <Documents
            fileForm={fileForm}
            isClientPage={true}
            isClientAgent={currentClient.isLeadAgent}
            uploadFormType={getUploadFormType()}
            openDialog={getUploadFormType() === 'FORM_4' ? form4UploadDialogOpen : form6UploadDialogOpen}
            setOpenDialog={getUploadFormType() === 'FORM_4' ? setForm4UploadDialogOpen : setForm6UploadDialogOpen}
            selectedProperties={getUploadFormType() === 'FORM_4' ? form4SelectedProperties : form6SelectedProperties}
            docTypeOptions={formsOptions}
            sectionTitle={langClientDetail.titleForm}
            elementIdPrefix={'form46-section'}
          />
        </Grid>

        {/* ------------------- form6 upload ---------------- */}
        <Grid item xs={12} style={{ display: 'none' }}>
          {/* <FormUploadPane
            id="file-form-6" type="FORM_6" title={langClientDetail.actionForm6}
            fileForm={fileForm} idxName="form-6"
            // handleUpload={handleUpload}
            uploadPolicy={clientSettings[`FORM_6_UPLOAD_POLICY`]}
            // confirmDialog={confirmDialog}
            hasAgreementInfo
            openDialog={form6UploadDialogOpen}
            setOpenDialog={setForm6UploadDialogOpen}
            selectedProperties={form6SelectedProperties}
          /> */}

        </Grid>
      </Grid>



      {/* --------------------- full screen dialog -----------------------*/}
      <Dialog className={classes.dialogRoot} fullScreen open={openFormDialog} onClose={handleCloseDialog} TransitionComponent={Transition}>
        <AppBar style={{ position: 'sticky', top: 0, paddingTop: iosStatusBarHeight }} className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.dialogTitle}>
              {selectedFormIndex === 1 ? langClientDetail.actionForm4 : selectedFormIndex === 2 ? langClientDetail.actionForm6 : undefined}
            </Typography>
            <Button autoFocus color="inherit" style={{ color: unsavedStates[selectedFormIndex] ? '#FFBF00' : 'inherit', fontWeight: unsavedStates[selectedFormIndex] ? 'bold' : 'inherit' }}
              onClick={() => {
                if ("P" === getFormStatus()) {
                  warnOverwriteDialog.confirm(langSignDialog.msgWarnOverwrite, lang.actionYes, lang.actionNo).then((confirmed: boolean) => {
                    if (confirmed) {
                      handleSave(undefined, true);
                    }
                  });
                } else {
                  handleSave(undefined);
                }
              }}>
              {`${lang.actionSaveForm} ${unsavedStates[selectedFormIndex] ? '*' : ''}`}
            </Button>
            <Button autoFocus color="inherit" onClick={() => {
              if (isValidForm()) {
                if ("P" === getFormStatus()) {
                  warnOverwriteDialog.confirm(langSignDialog.msgWarnOverwrite, lang.actionYes, lang.actionNo).then((confirmed: boolean) => {
                    if (confirmed) {
                      handleSave('GENERATE_PDF', true);
                    }
                  });
                } else {
                  handleSave('GENERATE_PDF');
                }
              }
            }}>
              {lang.actionGenerate}
            </Button>
            <Button disabled={!canSignForm} autoFocus color="inherit" onClick={() => {
              if (isValidForm()) {
                // handleSave('SIGN_FORM');
                // setSingingDialogOpen(true);
                // dispatch({ type: 'Form4.CheckExistSignedFormResultLoaded', payload: { isExist: undefined, invitationSent: undefined } });
                // dispatch({ type: 'Form6.CheckExistSignedFormResultLoaded', payload: { isExist: undefined, invitationSent: undefined } });
                // checkExistSignedForm();

                if ("P" === getFormStatus()) {
                  warnOverwriteDialog.confirm(langSignDialog.msgWarnOverwrite, lang.actionYes, lang.actionNo).then((confirmed: boolean) => {
                    if (confirmed) {
                      handleSave('SIGN_FORM', true);
                      dispatch({ type: 'Form4.CheckExistSignedFormResultLoaded', payload: { isExist: undefined, invitationSent: undefined } });
                      dispatch({ type: 'Form6.CheckExistSignedFormResultLoaded', payload: { isExist: undefined, invitationSent: undefined } });
                    }
                  });
                } else {
                  handleSave('SIGN_FORM');
                  dispatch({ type: 'Form4.CheckExistSignedFormResultLoaded', payload: { isExist: undefined, invitationSent: undefined } });
                  dispatch({ type: 'Form6.CheckExistSignedFormResultLoaded', payload: { isExist: undefined, invitationSent: undefined } });
                }
              }
            }}>
              {lang.actionGenerateAndSign}
            </Button>
          </Toolbar>
        </AppBar>

        <NewFormSigningDialog
          open={singingDialogOpen}
          onClose={() => {
            setSigningDialogOpen(false);
            dispatch({ type: 'ClientDetail.FetchRequested', payload: { cid: cid ?? '' } });
            if (selectedFormIndex === 1) {
              dispatch({ type: 'Form4.FetchRequested', payload: { buyerClientId: cid ?? '' } });
            } else {
              dispatch({ type: 'Form6.FetchRequested', payload: { tenantClientId: cid ?? '' } });
            }
            dispatch({ type: 'Client.Memo.FetchRequested', payload: { cid: cid ?? '' } })
            handleCloseDialog();
          }}
          id={cid ?? ''}
          formIndex={signIndex[selectedFormIndex]}
          requireAgentSign={true}
          requireSellerSign={true}
          locale={selectedFormIndex === 1 ? form4.values.formLocale ?? locale : form6.values.formLocale ?? locale}
          autoFillClientVerificationContact={currentClient.otherContacts?.[0]?.value}
          autoFillClientEmail={currentClient.otherContacts?.filter(contact => contact.type === 'EMAIL')[0]?.value}
          onCloseSigningDialog={() => { setSigningDialogOpen(false); }}
          form4or6option={selectedFormIndex === 1 ? "SALES" : "RENT"}
          selectedProperties={selectedProperties}
          clientuploadform4or6={clientuploadform4or6}
          agentName={agentName}
          clientName={getClientName()}
          displayFormName={selectedFormIndex === 1 ? langClientDetail.actionForm4 : selectedFormIndex === 2 ? langClientDetail.actionForm6 : undefined}
          setSelectedProperties={setSelectedProperties}
        />

        <div>
          {selectedFormIndex === 1 ? <Form4Dialog form4={form4} form4Schedule1={form4Schedule1} /> : null}
          {selectedFormIndex === 2 ? <Form6Dialog form6={form6} form6Schedule1={form6Schedule1} /> : null}
        </div>

      </Dialog>
    </div>
  );
}

export default FullScreenDialogForm4And6;