import { Button, Card, CardActions, CardContent, Checkbox as MuiCheckbox, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { CardProps } from '@material-ui/core/Card';
import { TextFieldProps } from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { buildingApi } from 'api/buildingApi';
import { BuildingListItemDTO } from 'common/dto';
import DistrictMultiSelect from 'common/elements/DistrictMultiSelect';
import { useForm } from 'common/hooks';
import { HandleFormHelperText, tooltipHoc } from 'common/ui';
import { getBuildingSortForAutoComplete, getBuilingListWithoutDuplicates, getStreetListWithoutDuplicates, getStreetSortForAutoComplete, initFormKeysAndBooleanMap, limitTextFieldLength, multiLang } from 'common/utils';
import { values } from 'lodash';
import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';


const Checkbox = tooltipHoc(MuiCheckbox);

export interface SearchFormValues {
  usage: string[];
  district: string[];
  street: string;
  building: string;
  block: string;
}

interface BuildingsSearchProps extends CardProps {
  form: Partial<SearchFormValues>;
  onSearch?(values: Partial<SearchFormValues>): any;
}

const useStyles = makeStyles((theme) => ({
  patchOutlineAutocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
      paddingLeft: 8,
    }
  },
}));

const UITextField = (props: TextFieldProps & { fixedLabel?: boolean }) => {
  let { label, fixedLabel, ...rest } = props;
  fixedLabel = false;
  return <Fragment>
    {fixedLabel ? <Typography>{label}</Typography> : null}
    <TextField {...rest} label={!fixedLabel ? label : null} />
  </Fragment>
};

const BuildingsSearch = (props: BuildingsSearchProps) => {
  const classes = useStyles();
  const { lang: langCommon, locale, langBuilding: lang, usageOptions, langPropertyStock } = useSelector((state: IRootState) => state.locale);
  const searchForm = useForm<SearchFormValues>({
    ...props.form,
  });

  const bind = searchForm.bind;

  const filteredSearchForm = () => {
    searchForm.values = {
      ...searchForm.values,
      street: searchForm.values.street?.trim(),
      building: searchForm.values.building?.trim(),
      block: searchForm.values.block?.trim(),
    }

    const newForm: Partial<SearchFormValues> = {};
    Object
      .keys(searchForm.values)
      // .filter((key) => !disabledCriterias[key])
      .forEach((key) => (newForm as any)[key] = searchForm.values[key as keyof SearchFormValues]);
    return newForm;
  };


  const [buildings, setBuildings] = useState<BuildingListItemDTO[]>([]);
  const [buildingListLoading, setBuildingListLoading] = useState(false);
  const [streets, setStreets] = useState<BuildingListItemDTO[]>([]);
  const [streetListLoading, setStreetListLoading] = useState(false);

  const token: string = useSelector((state: IRootState) => state.login.token ?? '');
  const fetchBuilding = (keyword: string) => {
    if (keyword.length < 2) { return; }
    setBuildingListLoading(true);
    buildingApi.getList({ building: keyword, sort: getBuildingSortForAutoComplete(locale) }, token).then(result => {
      if (result.data?.content) {
        setBuildings(getBuilingListWithoutDuplicates(result.data!.content));
        setBuildingListLoading(false);
      }
    });
  }

  const fetchStreet = (keyword: string) => {
    if (keyword.length < 2) { return; }
    setStreetListLoading(true);
    buildingApi.getList({ street: keyword, sort: getStreetSortForAutoComplete(locale) }, token).then(result => {
      if (result.data?.content) {
        setStreets(getStreetListWithoutDuplicates(result.data!.content));
        setStreetListLoading(false);
      }
    });
  }

  // const buildingNamesWithDuplicate = buildings.map((building) => building.building);
  // const buildingNames = [...new Set(buildingNamesWithDuplicate)];
  // const buildingMap: { [name: string]: BuildingListItemDTO } = {};
  // buildings.forEach((building) => { buildingMap[building.building] = building });
  const buildingNames = buildings.map((building) => multiLang(locale, building.buildingNameZh, building.buildingNameEn));

  // const streetNamesWithDuplicate = streets.map((street) => street.street);
  // const streetNames = [...new Set(streetNamesWithDuplicate)];
  // const streetMap: { [name: string]: BuildingListItemDTO } = {};
  // streets.forEach((street) => { streetMap[street.street] = street });
  const streetNames = streets.map((street) => multiLang(locale, street.streetZh, street.streetEn));

  const buildingField = () => (
    <Autocomplete
      options={buildingNames}
      noOptionsText={''}
      loading={buildingListLoading}
      loadingText={langCommon.msgLoading}
      freeSolo={true}
      disableClearable
      renderInput={params => (
        <TextField
          {...params}
          inputProps={{ ...params.inputProps, maxLength: 255 }}
          className={classes.patchOutlineAutocomplete}
          // error={!!form.hasError('building')}
          // helperText={form.hasError('building')}
          fullWidth
          label={langPropertyStock.buildingOrVillageName}
          margin="dense"
          variant="outlined"
          onChange={(ev) => {
            setKeyBooleanMap(keyBooleanMap.set("building", ev.target.value.length === 255))
            searchForm.modify({
              building: ev.target.value
            })
            fetchBuilding(ev.target.value)
          }}
          value={searchForm.values.building ?? ''}
        />
      )}
      value={searchForm.values.building ?? ''}
      onChange={(_, val) => {
        searchForm.modify({
          building: val
        })
        // if (buildingMap[val]) {
        //   const { building } = buildingMap[val];
        //   searchForm.modify({
        //     building: building
        //   })
        // }
      }}
    />
  );

  const [keyBooleanMap, setKeyBooleanMap] = useState(initFormKeysAndBooleanMap(searchForm));
  const streetField = () => (
    <Autocomplete
      options={streetNames}
      noOptionsText={''}
      loading={streetListLoading}
      loadingText={langCommon.msgLoading}
      freeSolo={true}
      disableClearable
      renderInput={params => (
        <TextField
          {...params}
          inputProps={{ ...params.inputProps, maxLength: 255 }}
          className={classes.patchOutlineAutocomplete}
          // error={!!form.hasError('building')}
          // helperText={form.hasError('building')}
          fullWidth
          label={langPropertyStock.street}
          margin="dense"
          variant="outlined"
          onChange={(ev) => {
            setKeyBooleanMap(keyBooleanMap.set("street", ev.target.value.length === 255))
            searchForm.modify({
              street: ev.target.value
            });
            fetchStreet(ev.target.value)
          }}
          // value={form.values.building ?? ''}
          value={searchForm.values.street ?? ''}
        />
      )}
      value={searchForm.values.street ?? ''}
      onChange={(_, val) => {
        searchForm.modify({
          street: val
        });
        // if(streetMap[val]) {
        //   const { street} = streetMap[val];
        //   searchForm.modify({
        //     street: street
        //   })
        // }
      }}
    />
  );

  return (
    <Card
      {...props}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardContent>
          <Grid
            container
            spacing={1}
          >
            {/* <Grid item container md={2} sm={2} xs={12} spacing={1}>
            <Grid
              item
              md={12}
              sm={12} 
              xs={12}
            >
              <Typography style={{ fontWeight: 'bold' }}>{lang.captionBasicInfo}</Typography>
            </Grid>            
            <Grid
              item
              md={12}
              sm={12} 
              xs={12}
            >
              <FormControl variant="outlined" margin="dense" fullWidth>
                <InputLabel variant="outlined" style={{ background: '#fff' }}>{lang.usage}</InputLabel>
                <Select
                  multiple
                  {...bind('usage')}
                  renderValue={(selected) => (selected as string[]).map(val => usageOptions[val]).join(', ')}
                >
                  {Object.keys(usageOptions).map(key => (
                    <MenuItem key={key} value={key}>
                      <Checkbox checked={(searchForm.values.usage ?? []).indexOf(key) > -1} />
                      <ListItemText primary={usageOptions[key]} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid> */}

            <Grid item container md={12} sm={12} xs={12} spacing={1} style={{ alignContent: 'start' }}>
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
              >
                <Typography style={{ fontWeight: 'bold' }}>{lang.captionLocation}</Typography>
              </Grid>

              <Grid
                item
                md={3}
                sm={3}
                xs={12}
              >
                {/* {!!(() => (window as any).districtOptions = districtOptions)()} */}
                <DistrictMultiSelect
                  value={searchForm.values.district ?? []}
                  onChange={(values) => searchForm.updateValues('district', values.filter(v => !!v))}
                />
              </Grid>

              <Grid
                item
                md={3}
                sm={3}
                xs={12}
              >
                {/* <UITextField
                label={lang.street}
                {...bind('street')}
                fullWidth
                margin="dense"
                variant="outlined"
              ></UITextField> */}
                {streetField()}
                <HandleFormHelperText
                  isError={keyBooleanMap.get('street')}
                  errorMessage={lang.textOverLimit}
                />
              </Grid>

              <Grid
                item
                md={4}
                sm={4}
                xs={12}
              >
                {/* <UITextField
                label={lang.buildingOrVillage}
                {...bind('building')}
                fullWidth
                margin="dense"
                variant="outlined"
              ></UITextField> */}
                {buildingField()}
                <HandleFormHelperText
                  isError={keyBooleanMap.get('building')}
                  errorMessage={lang.textOverLimit}
                />
              </Grid>

              <Grid
                item
                md={2}
                sm={2}
                xs={12}
              >
                <UITextField
                  label={lang.block}
                  {...bind('block')}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  onChange={(e) => {
                    limitTextFieldLength(e, 255, 'block', searchForm, keyBooleanMap, setKeyBooleanMap);
                  }}
                ></UITextField>
                <HandleFormHelperText
                  isError={keyBooleanMap.get('block')}
                  errorMessage={lang.textOverLimit}
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </form>

      <CardActions style={{ flexWrap: 'wrap' }}>
        {/* <IconButton 
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={() => setExpanded(!expanded)}
          aria-expanded={expanded}
          aria-label="show more">
          <ExpandMore />
        </IconButton>
        其它 */}
        {
          // <Typography>{'搜尋條件'}:{' '}</Typography>

          // Object.keys(searchForm.values).filter((key) => {
          //   return isNonEmpty(searchForm.values[key as keyof SearchFormValues]) && !!lang[key as keyof typeof lang];
          // }).map((key) => {
          //   return <Chip style={{ marginBottom: theme.spacing(.5), background: disabledCriterias[key] ? 'inherit' : '#444' }} variant={disabledCriterias[key] ? 'outlined' : 'default'} color={disabledCriterias[key] ? 'default' : 'primary'} label={lang[key as keyof typeof lang]} onClick={() => setDisabledCriterias(val => ({ ...val, [key]: !val[key] }))} />
          // })
        }
        <div style={{ flexGrow: 1 }} />
        {/* {!props.matchingClient ? <FormControlLabel control={<Checkbox disabled tooltip="Will be ready in coming iteration" value="checkedC" />} label="經手樓盤" /> : null}
        {props.matchingClient ? <Button variant="contained" color="primary">對盤</Button> */}
        <Button variant="contained" color="primary" onClick={() => props.onSearch?.(filteredSearchForm())}>{lang.actionSearch}</Button>
      </CardActions>
    </Card>
  );
}

export default BuildingsSearch;
