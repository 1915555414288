import { IconButton, Theme, Tooltip, Typography } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ClearButton from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import DraftsIcon from '@material-ui/icons/Drafts';
import MarkunreadIcon from '@material-ui/icons/Markunread';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import StopIcon from '@material-ui/icons/Stop';
import { makeStyles } from '@material-ui/styles';
import { hasPermission } from 'common/access-control';
import { useConfirmDialog } from 'common/ConfirmDialog';
import { NotificationDTO } from 'common/dto';
import { isNonEmpty, multiLang } from 'common/utils';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IRootState } from 'reducers';
import { format } from 'util';
import NotificationLink from './NotificationLink';
import StartImpersonationDialog from './StartImpersonationDialog';

const PUBLIC_URL = process.env['PUBLIC_URL'];

const useStyles = makeStyles((theme: Theme) => ({
    root: {
      // boxShadow: 'none',
      background: '#fff',
      color: '#000',
    },
    flexGrow: {
      flexGrow: 1
    },
    signOutButton: {
      marginLeft: theme.spacing(1)
    },
    navbar: {
      marginLeft: theme.spacing(3),
    },
    activeNavBtn: {
      backgroundColor: theme.palette.primary.dark,
    },
    brand: {
      color: '#183B68',
      display: 'flex', flexDirection: 'row', alignItems: 'center',
      lineHeight: '40px',
      fontWeight: 'bold',
    },
    list: {
      maxHeight: 400,
      minWidth: 300,
      maxWidth: 300,
      overflowY: 'auto'
		},
    notificationUnread: {
      fontWeight: 800
	},
	notificationExpired: {
		opacity: '50%'
	},
	titleCommonStyle:{
		display:'flex',
		alignItems: 'center',
		padding: '0px 4px',
		borderRadius: '5px',
	},
	TRNX_DATAFEED_TITLE: {
		backgroundColor: '#98FB98', //pale green
		// padding: '10px',
		// border: '1px solid green',
	},
	BUILDING_MASTER_CREATION_REQUEST_TITLE: {
		backgroundColor: '#AFEEEE', //pale turtoise
		// padding: '10px',
		// border: '1px solid green',
	}, 
	PROPERTY_STOCK_TITLE: {
		backgroundColor: '#FFC0CB', //pink
		// padding: '10px',
		// border: '1px solid green',
	},
	CLIENT_DORMANCY_TITLE: {
		backgroundColor: '#FFFFE0', //yellow
		// padding: '10px',
		// border: '1px solid green',
	},
	PROPERTY_STOCK_DORMANCY_TITLE: {
		backgroundColor: '#BA55D3', //orchid
		// padding: '10px',
		// border: '1px solid green',
	},
	CLAIM_CLIENT_TITLE: {
		backgroundColor: '#FAF0E6', //linen
		// padding: '10px',
		// border: '1px solid green',
	},
	CLAIM_PROPERTY_STOCK_TITLE: {
		backgroundColor: '#F5FFFA', //minty cream
		// padding: '10px',
		// border: '1px solid green',
	},
	IMPERSONATION_REQUEST_TITLE:{
		backgroundColor: '#b3b3ff', //Indigo
		// padding: '10px',
	},
	FORM_SIGNING_TITLE:{
		backgroundColor: '#ffc37a',
	},
	claimDialogTitle: {
		paddingBottom: '5px',
		textTransform: 'uppercase',
		fontWeight: 'bold'
	},
	claimDialogTitleDivider: {
		marginBottom: '20px'
	},
	claimDialogItemHeading: {
		fontSize: 'small'
	},
	claimDialogItemContent: {
		fontSize: 'medium',
		fontWeight: 'bold',
		paddingTop: '5px',
		marginBottom: '10px',
	},
	claimDialogSizeSuffix: {
		fontSize: 'x-small'
	},
	messageIconButton:{
		padding: '3px',
		margin: '0px 6px'
	}
}));

interface NotificationMessageProps {
	notification: NotificationDTO;
}

const NotificationMessage = (props: NotificationMessageProps) => {
	const { notification } = props;
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const { langUser, locale, lang, langClientDetail, langNotification, langPropertyStock, sourceOfClientOptions,
    districtNtOptions, districtHkiOptions, districtKltOptions, propertyStockStatusOptions  } = useSelector((state: IRootState) => state.locale);
	const { DISPLAY_DATETIME_FORMAT } = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM);
	const token = useSelector((state: IRootState) => state.login.token ?? '');
	const { uid, privileges } = useSelector((state: IRootState) => state.login);
	const districtOptions = {
    ...districtNtOptions,
    ...districtHkiOptions,
    ...districtKltOptions,
	};

	const EXTRA_ACTION_DEFS: Record<string, { title: string, icon: JSX.Element }> = {
		LAND_SEARCH_DONE: {
			title: langNotification.actionLandSearchDone,
			icon: <DoneIcon />,
		},
		LAND_SEARCH_CANCEL: {
			title: langNotification.actionLandSearchCancel,
			icon: <ClearButton />,
		},
	};
	
	const titleStyle = {
		TRNX_DATAFEED: classes.TRNX_DATAFEED_TITLE,
		BUILDING_MASTER_CREATION_REQUEST: classes.BUILDING_MASTER_CREATION_REQUEST_TITLE, 
		PROPERTY_STOCK: classes.PROPERTY_STOCK_TITLE,
		CLIENT_DORMANCY: classes.CLIENT_DORMANCY_TITLE,
		PROPERTY_STOCK_DORMANCY: classes.PROPERTY_STOCK_DORMANCY_TITLE,
		CLAIM_CLIENT: classes.CLAIM_CLIENT_TITLE,
		CLAIM_PROPERTY_STOCK: classes.CLAIM_PROPERTY_STOCK_TITLE, 
		IMPERSONATION: classes.IMPERSONATION_REQUEST_TITLE, 
		FORM135_SIGNING: classes.FORM_SIGNING_TITLE,
		FORM46_SIGNING: classes.FORM_SIGNING_TITLE,
	} as any;

	const confirmDialog = useConfirmDialog();
	
  const onDoneAction = () => {
    confirmDialog.confirm(lang.msgConfirmDone, lang.actionConfirm, lang.actionCancel).then(confirmed => {
			if (confirmed) {
				dispatch({ type: 'Notifications.DoneActionRequested', payload: notification });
			}
		});
	};

	const onReadAction = () => {
		dispatch({ type: 'Notifications.ReadActionRequested', payload: { ids: [ notification.id ], isRead: !notification.isRead }});
	};

	const onDeleteAction = () => {
    confirmDialog.confirm(lang.msgConfirmDelete, lang.actionConfirm, lang.actionCancel).then(confirmed => {
      if (confirmed) {
        dispatch({ type: 'Notifications.DeleteActionRequested', payload: { ids: [ notification.id ] } });
      }
    });
  }


	//--------  for impersonation
	const canImpersonate = hasPermission(privileges, 'UPDATE', 'IMPERSONATION');
	const canBeImpersonated = hasPermission(privileges, 'READ', 'BEING_IMPERSONATED');
  const [impersonationNotiId, setImpersonationNotiId] = useState<string| undefined>(undefined);
  const [openStartImpersonationDialog, setOpenStartImpersonationDialog] = React.useState(false);
	const impersonationId = useSelector((state: IRootState) => state.login.impersonationId ?? '');
	const impersonating = isNonEmpty(impersonationId);


  const handleClickOpenStartImpersonationDialog = (notificationId: string) => {
    setImpersonationNotiId(notificationId)
    setOpenStartImpersonationDialog(true);
  };

  const handleCloseStartImpersonationDialog = () => {
    setOpenStartImpersonationDialog(false);
	};
	
	const handleStopImpersonation = (impersonatedUserId: string) =>{
		confirmDialog.confirm(langUser.msgConfirmStopImpersonation, lang.actionConfirm, lang.actionCancel).then(confirmed => {
			if (confirmed) {
				dispatch({type: 'User.ApproverStopImpersonationRequested', payload: {impersonatedUserId: impersonatedUserId, beingImpersonatedUserId: uid }});
			}
		});
	}

	const handleApproveImpersonation = (notification: NotificationDTO) => {
    switch (notification.impersonationState) {
      case 'P': //pending for impersonation approval
      default: 
				confirmDialog.confirm(<Typography style={{whiteSpace:'pre-line'}}>{format(langUser.msgAuthorizeImpersonation, multiLang(locale, notification.impersonateUsernameCh, notification.impersonateUsernameEn))}</Typography>, lang.actionConfirm, lang.actionCancel, langUser.titleAuthorizeImpersonation).then(confirmed => {
          if (confirmed) {
            dispatch({ type: 'User.ApproveImpersonationRequested', payload: notification });
          }
        });
    }
	};

  const printActionsForImpersonation = (notification: NotificationDTO) => {
    return (
      <span>
        {/* approve button */}
				{!notification.isExpired && canBeImpersonated && notification.impersonationState === 'P' && !impersonating ?
          <Tooltip title={langUser.actionAuthorize} placement="top-start"><IconButton className={classes.messageIconButton} onClick={() => handleApproveImpersonation(notification)}><DoneIcon /></IconButton></Tooltip>
          : null}
        {/* start impersonation button (for impersonteUser*/}
        { !notification.isExpired && canImpersonate && notification.impersonationState === 'A' && uid === notification.requesterUserId?.toString()?
          <Tooltip title={langUser.actionStartImpersonation} placement="top-start">
            <IconButton
							className={classes.messageIconButton}
              onClick={() => {
                handleClickOpenStartImpersonationDialog(notification.id)
              }}><PlayCircleOutlineIcon />
            </IconButton></Tooltip>
          : null}
        {/* stop button (for beingImpersonteUser*/}
        {!notification.isExpired && canBeImpersonated && notification.impersonationState === 'A' && uid !== notification.requesterUserId?.toString() ?
        <Tooltip title={langUser.actionStopImpersonation} placement="top-start"><IconButton className={classes.messageIconButton} onClick={() => handleStopImpersonation(notification.requesterUserId ?? '')}><StopIcon /></IconButton></Tooltip>
        : null}
        </span>
    )
  }
    
	
	return (
		<div>
			{confirmDialog.render()}
      <StartImpersonationDialog open={openStartImpersonationDialog} handleClose={handleCloseStartImpersonationDialog} notificationId={impersonationNotiId ?? ''}/>
			<ListItem alignItems="flex-start" style={{paddingBottom: '0px', paddingTop: '0px'}}>
				<ListItemText
					classes={{ 
						primary: notification.isExpired ? classes.notificationExpired : (notification.isRead ? 'inherit' : classes.notificationUnread), 
						secondary: notification.isExpired ? classes.notificationExpired : (notification.isRead ? 'inherit' : classes.notificationUnread)
					}}
					primary={
						<React.Fragment>
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
								<div style={{ display: 'flex', flexDirection: 'row' }}>
									{/* <div style={{ display: 'flex', flexDirection: 'column' }}> */}
										{/* <div style={{ display: (notification.module === 'CLAIM_CLIENT' || notification.module === 'CLAIM_PROPERTY_STOCK') && notification.isExpired ? 'block' : 'none' }}>
											{langNotification.captionClaimed}
										</div> */}
									<div className={classes.titleCommonStyle + ' ' + titleStyle[notification.module] + (notification.isExpired ? ' ' + classes.notificationExpired : '')}>
										{multiLang(locale, notification.titleTc, notification.titleEn)}
									</div>
									{/* </div> */}
									{
										notification.isExpired ?
											<div style={{ paddingTop: '10px' }}>
												{ 
													notification.module==='IMPERSONATION' ? 
													langNotification.captionImpersonationEnded : 
													(notification.module === 'CLAIM_CLIENT' || notification.module === 'CLAIM_PROPERTY_STOCK') && notification.isExpired ?
													langNotification.captionClaimed : langNotification.captionProcessed
												}
											</div> : null
									}
									{ //impersonation: show for approver only
										notification.module ==='IMPERSONATION' && !notification.isExpired && notification.impersonationState === 'A' && uid !== notification.requesterUserId?.toString() ?
											<div style={{ paddingTop: '10px' , fontWeight: 'bold'}}>
												{'(' + langUser.captionAlreadyAuthorized + ')'}
											</div> : null
									}
								</div>
								<div style={{ display: 'flex', flexDirection: 'row' }}>
                {/* ---- start actions for impersonation */}
                  { notification.module === 'IMPERSONATION' ? 
                    printActionsForImpersonation(notification) : null}
                {/* ---- end for impersonation */}
								{notification.extraActions.map(action => <Tooltip
									key={action}
									title={EXTRA_ACTION_DEFS[action].title}
									placement="top-start"
								>
									<IconButton className={classes.messageIconButton} disabled={!!notification.isExpired} onClick={() => {
										dispatch({ type: 'Notifications.ExtraActionRequested', payload: { id: +notification.id, action } });
									}}>
										{EXTRA_ACTION_DEFS[action].icon}
									</IconButton>
								</Tooltip>)}
								{ notification.module !== 'IMPERSONATION' && notification.hasDoneAction && !notification.isExpired && <Tooltip title={langNotification.actionDone} placement="top-start"><IconButton className={classes.messageIconButton} onClick={() => onDoneAction()}><DoneIcon/></IconButton></Tooltip> }
									<Tooltip title={notification.isRead ? langNotification.actionMarkAsUnread : langNotification.actionMarkAsRead} placement="top-start"><IconButton className={classes.messageIconButton} onClick={() => onReadAction()}>{notification.isRead ? <DraftsIcon/> : <MarkunreadIcon/>}</IconButton></Tooltip>
									<Tooltip title={langNotification.actionDelete} placement="top-start"><IconButton className={classes.messageIconButton} onClick={() => onDeleteAction()}><DeleteIcon/></IconButton></Tooltip>
								</div>
							</div>
							{/* {notification.isRead ? lang.captionIsRead : null} */}
						</React.Fragment>
					}
					secondary={
						<React.Fragment>
							{
								// notification.content?.split('\n').map((line, _) => 
								multiLang(locale, notification.contentTc, notification.contentEn)?.split('\n').map((line, _) =>
									<React.Fragment>
										{line}
										<br />
									</React.Fragment>
								)
							}
							{
								(notification.noFurtherAction && !notification.isExpired) ? 
								<NotificationLink notification={notification} confirmDialog={confirmDialog} /> : null
							}
							{/* Exception: claimed client can still see the link */}
							{
								(notification.module === 'CLAIM_CLIENT' && notification.isExpired) ? 
								<NotificationLink notification={notification} confirmDialog={confirmDialog} /> : null
							}
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
								{moment(notification.dateCreated).format(DISPLAY_DATETIME_FORMAT)}
							</div>
						</React.Fragment>
					}
				/>
			</ListItem>
		</div>
  )
}

export default NotificationMessage;