import { ApiError, ApiResult } from 'api';
import dashboardApi from 'api/dashboardApi';
import { DashboardResponseDTO } from 'common/dto';
import { selectAllDistrictOptions } from 'common/selectors';
import { DashboardActions, DashboardGraphViewModel, DashboardKpiTypes, IRootState } from 'reducers';
import { call, put, select, takeLeading } from 'redux-saga/effects';
import { PASSagaContext } from 'sagas';
import { apiTaskWrapper } from './saga-commons';
// import { useSelector } from 'react-redux';

export function* watchDashboardPrefUpdatedAndSaved(context: PASSagaContext){
  yield takeLeading(
    'Dashboard.PrefUpdatedAndSaved',
    apiTaskWrapper(DashboardPrefUpdatedAndSaved),
    context,
  );
}

export function* DashboardPrefUpdatedAndSaved(context: PASSagaContext, action: Extract<DashboardActions, { type: 'Dashboard.PrefUpdatedAndSaved' }>){
const {dashboardPref }: IRootState['dashboard'] = yield select((state: IRootState) => state.dashboard);
const { token } = yield select((state: IRootState) => state.login);
  
  yield call(dashboardApi.updateDasboardPref,  dashboardPref , token);

}

export function* watchDashboardFetchRequested(context: PASSagaContext) {
  yield takeLeading(
    'Dashboard.FetchRequested',
    apiTaskWrapper(fetchDashboard),
    context,
  );
}

export function* fetchDashboard(context: PASSagaContext, action: Extract<DashboardActions, { type: 'Dashboard.FetchRequested' }>) {
  const { region, periodStart, periodEnd }: IRootState['dashboard'] = yield select((state: IRootState) => state.dashboard);
  
  const token: string = yield select((state: IRootState) => state.login.token ?? '');
  const TOP_N_DISTRICT = +(yield select((state: IRootState) => state.systemSettings.Dashboard?.GLOBAL_SETTING.TOP_N_DISTRICT));

  const { langDashboard, districtHkiOptions, districtNtOptions, districtKltOptions, } = yield select((state: IRootState) => state.locale);

  const districtOptions: LocaleOptions = yield select(selectAllDistrictOptions);

  const ownDistrictColor = 'rgba(255, 205, 86, 1)';
  const hkIslandColor = 'rgba(255, 99, 132, 1)';
  const kowloonColor = 'rgba(153, 102, 255, 1)';
  const ntColor = 'rgba(75, 192, 192, 1)';
  const barMaxHeight = 50;
  // const { locale, lang,
  //   districtHkiOptions, districtNtOptions, districtKltOptions,
  // } = localeBundle;

  // TODO: Call dashboard api with region, periodStart, periodEnd.
  const { data, error }: ApiResult<DashboardResponseDTO> = yield call(dashboardApi.getDashboard, { region, periodStart, periodEnd }, token);
  if (error) { throw ApiError.of(error!); }
  
  if (data?.global.length == 0) {
    yield put<DashboardActions>({ type: 'Dashboard.DataUpdated', payload: { graphData: {}, allowedKpiTypeList: [], lastModified: undefined } });
    yield put<DashboardActions>({ type: 'Dashboard.PrefUpdated', payload:{dashboardPref : [] } });
  }
  // TODO: Transform data (with type DashboardAggregateDTO[]) to graphData (with type Record<DashboardKpiTypes, DashboardGraphViewModel>)
  const graphData: Partial<Record<DashboardKpiTypes, DashboardGraphViewModel>> = {};
  const dataKpiTypeList = data!.kpiTypeList;
  // const dataKpiTypeArray = [DashboardKpiTypes.NEW_CLIENTS, DashboardKpiTypes.NEW_CLIENT_UPDATES,
  // DashboardKpiTypes.NEW_PROPERTIES, DashboardKpiTypes.NEW_PROPERTY_UPDATES,
  // DashboardKpiTypes.TOTAL_COMMISSION, DashboardKpiTypes.CLIENT_PROPERTY_VISIT];

  for (const dataKpiType of dataKpiTypeList) {
    const additionalBar = data!.own.filter(ownbar => ownbar.kpiType == Number(dataKpiType)
      && data!.global.filter(bar => bar.kpiType == Number(dataKpiType)
      ).sort((a, b) => a.kpiValue < b.kpiValue ? 1 : -1
      ).slice(0, TOP_N_DISTRICT).filter(bar => bar.district == ownbar.district).length == 0
    ).map(ownbar => Number(ownbar.kpiValue));//additional for own data that no exist in top N bar

    // const globalData = data!.global.filter(bar => bar.kpiType == Number(dataKpiType)
    // );

    // if (
    //   globalData.length == 0
    // ) {
    //   
    //   continue;
    // } else {
      if (region === 'ALL') {
         graphData[dataKpiType as unknown as DashboardKpiTypes]  = {
          // fill in districts
          labels: data!.global.filter(bar => bar.kpiType == Number(dataKpiType))
            .sort((a, b) => a.kpiValue < b.kpiValue ? 1 : -1)
            .slice(0, TOP_N_DISTRICT)
            .map(bar => districtOptions[String(bar.district)])
            .concat(data!.own.filter(ownbar => ownbar.kpiType == Number(dataKpiType)
              && data!.global.filter(bar => bar.kpiType == Number(dataKpiType))
                .sort((a, b) => a.kpiValue < b.kpiValue ? 1 : -1)
                .slice(0, TOP_N_DISTRICT).filter(bar => bar.district == ownbar.district).length == 0)
              .map(ownbar => districtOptions[String(ownbar.district)])), // fill in districts of own data for non Top N global data
          // fill in data & colors, etc.
          datasets: [
            {    //own data
              backgroundColor: ownDistrictColor,
              data: data!.global.filter(bar => bar.kpiType == Number(dataKpiType)
              ).sort((a, b) => a.kpiValue < b.kpiValue ? 1 : -1
              ).slice(0, TOP_N_DISTRICT).map(bar =>
                data!.own.filter(ownbar => ownbar.kpiType == bar.kpiType && ownbar.district == bar.district).map(ownbar =>
                  Number(ownbar.kpiValue)
                )[0] ?? 0     //N own data for top N global data
              ).concat(additionalBar), //other own data for non-top N global data
              fill: 'false',
              label: langDashboard.ownTotal,
              // stack:1
              maxBarThickness: barMaxHeight,
            },
            {
              //global data - HKI
              backgroundColor: hkIslandColor,
              data: data!.global.filter(bar => bar.kpiType == Number(dataKpiType)
              ).sort((a, b) => a.kpiValue < b.kpiValue ? 1 : -1)
                .slice(0, TOP_N_DISTRICT)
                // ).slice(0, TOP_N_DISTRICT + additionalBar.length)
                // .filter(bar => Object.keys(districtHkiOptions).indexOf(bar.district) > -1)
                .map(bar =>
                  Object.keys(districtHkiOptions).indexOf(bar.district) > -1 ? Number(bar.kpiValue) : 0)
                .concat(data!.global.filter(bar => bar.kpiType == Number(dataKpiType)
                  && data!.own.filter(ownbar => ownbar.kpiType == Number(dataKpiType))
                    .sort((a, b) => a.kpiValue < b.kpiValue ? 1 : -1)
                    // .slice(0, TOP_N_DISTRICT)
                    .filter(ownbar => ownbar.district == bar.district).length != 0
                  && data!.global.filter(topbar => topbar.kpiType == Number(dataKpiType))
                    .sort((a, b) => a.kpiValue < b.kpiValue ? 1 : -1)
                    .slice(0, TOP_N_DISTRICT).filter(
                      topbar => topbar.district == bar.district).length == 0)
                  .slice(0, additionalBar.length)
                  // .filter(bar => Object.keys(districtNtOptions).indexOf(bar.district) > -1)
                  .map(bar => Object.keys(districtHkiOptions).indexOf(bar.district) > -1 ? Number(bar.kpiValue) : 0)),
              // .slice(0, additionalBar.length),
              fill: 'false',
              label: langDashboard.districtTotalHki,
              // stack:2
              // label:  region!=="ALL"? langDashboard.districtTotal : [langDashboard.districtTotalHki, langDashboard.districtTotalKln, langDashboard.districtTotalNt ]
              maxBarThickness: barMaxHeight,
            },

            {
              //global data - KLN
              backgroundColor: kowloonColor,
              data: data!.global.filter(bar => bar.kpiType == Number(dataKpiType)
              ).sort((a, b) => a.kpiValue < b.kpiValue ? 1 : -1
              ).slice(0, TOP_N_DISTRICT)
                .map(bar =>
                  Object.keys(districtKltOptions).indexOf(bar.district) > -1 ? Number(bar.kpiValue) : 0)
                .concat(data!.global.filter(bar => bar.kpiType == Number(dataKpiType)
                  && data!.own.filter(ownbar => ownbar.kpiType == Number(dataKpiType))
                    .sort((a, b) => a.kpiValue < b.kpiValue ? 1 : -1)
                    // .slice(0, TOP_N_DISTRICT)
                    .filter(ownbar => ownbar.district == bar.district).length != 0
                  && data!.global.filter(topbar => topbar.kpiType == Number(dataKpiType))
                    .sort((a, b) => a.kpiValue < b.kpiValue ? 1 : -1)
                    .slice(0, TOP_N_DISTRICT).filter(
                      topbar => topbar.district == bar.district).length == 0).slice(0, additionalBar.length)
                  // .slice(0,  additionalBar.length)
                  // .filter(bar => Object.keys(districtNtOptions).indexOf(bar.district) > -1)
                  .map(bar => Object.keys(districtKltOptions).indexOf(bar.district) > -1 ? Number(bar.kpiValue) : 0)),

              fill: 'false',
              label: langDashboard.districtTotalKln,
              // label:  region!=="ALL"? langDashboard.districtTotal : [langDashboard.districtTotalHki, langDashboard.districtTotalKln, langDashboard.districtTotalNt ]
              // stack:2
              maxBarThickness: barMaxHeight,
            },
            {
              //global data - NT
              backgroundColor: ntColor,
              data: data!.global.filter(bar => bar.kpiType == Number(dataKpiType)
              ).sort((a, b) => a.kpiValue < b.kpiValue ? 1 : -1
              ).slice(0, TOP_N_DISTRICT)
                .map(bar =>
                  Object.keys(districtNtOptions).indexOf(bar.district) > -1 ? Number(bar.kpiValue) : 0)
                .concat(data!.global.filter(bar => bar.kpiType == Number(dataKpiType)
                  && data!.own.filter(ownbar => ownbar.kpiType == Number(dataKpiType))
                    .sort((a, b) => a.kpiValue < b.kpiValue ? 1 : -1)
                    // .slice(0, TOP_N_DISTRICT)
                    .filter(ownbar => ownbar.district == bar.district).length != 0
                  && data!.global.filter(topbar => topbar.kpiType == Number(dataKpiType))
                    .sort((a, b) => a.kpiValue < b.kpiValue ? 1 : -1)
                    .slice(0, TOP_N_DISTRICT).filter(
                      topbar => topbar.district == bar.district).length == 0)
                  .slice(0, additionalBar.length)
                  // .slice(0,  additionalBar.length)
                  // .filter(bar => Object.keys(districtNtOptions).indexOf(bar.district) > -1)
                  .map(bar => Object.keys(districtNtOptions).indexOf(bar.district) > -1 ? Number(bar.kpiValue) : 0)),

              fill: 'false',
              label: langDashboard.districtTotalNt,
              // label:  region!=="ALL"? langDashboard.districtTotal : [langDashboard.districtTotalHki, langDashboard.districtTotalKln, langDashboard.districtTotalNt ]
              // stack:2
              maxBarThickness: barMaxHeight,
            },
          ],
        };

      } else {
        //region is hki, klt or nt
        graphData[dataKpiType as unknown as DashboardKpiTypes ] = {
          // fill in districts
          labels: data!.global.filter(bar => bar.kpiType == Number(dataKpiType))
            .sort((a, b) => a.kpiValue < b.kpiValue ? 1 : -1)
            .slice(0, TOP_N_DISTRICT)
            .map(bar => districtOptions[String(bar.district)])  //top N districts
            .concat(data!.own.filter(ownbar => ownbar.kpiType == Number(dataKpiType)
              && data!.global.filter(bar => bar.kpiType == Number(dataKpiType))
                .sort((a, b) => a.kpiValue < b.kpiValue ? 1 : -1)
                .slice(0, TOP_N_DISTRICT).filter(bar => bar.district == ownbar.district).length == 0)
              .map(ownbar => districtOptions[String(ownbar.district)])), // fill in districts of own data for non Top N global data
          // fill in data & colors, etc.
          datasets: [
            {    //own 
              backgroundColor: ownDistrictColor,
              data: data!.global.filter(bar => bar.kpiType == Number(dataKpiType)
              ).sort((a, b) => a.kpiValue < b.kpiValue ? 1 : -1
              ).slice(0, TOP_N_DISTRICT).map(bar =>
                data!.own.filter(ownbar => ownbar.kpiType == bar.kpiType && ownbar.district == bar.district).map(ownbar =>
                  Number(ownbar.kpiValue)
                )[0] ?? 0     //N own data for top N global data, if null return 0
              ).concat(additionalBar), //other own data for non-top N own data
              fill: 'false',
              label: langDashboard.ownTotal,
              // stack:1
              maxBarThickness: barMaxHeight,
            },
            {
              //global 
              backgroundColor: data!.global.filter(bar => bar.kpiType == Number(dataKpiType))
                .sort((a, b) => a.kpiValue < b.kpiValue ? 1 : -1)
                .slice(0, TOP_N_DISTRICT)
                .map(bar =>
                  String(bar.district)).map(
                    // fill color bassed on region of district
                    district => {
                      if (Object.keys(districtHkiOptions).indexOf(district) > -1
                      ) {
                        //hki region
                        return hkIslandColor;
                      } else if (Object.keys(districtKltOptions).indexOf(district) > -1
                      ) {
                        //kln region
                        return kowloonColor;
                      } else {
                        //nt region
                        return ntColor;
                      }
                    }
                  ).concat(data!.own.filter(ownbar => ownbar.kpiType == Number(dataKpiType)
                    && data!.global.filter(bar => bar.kpiType == Number(dataKpiType))
                      .sort((a, b) => a.kpiValue < b.kpiValue ? 1 : -1)
                      .slice(0, TOP_N_DISTRICT).filter(bar => bar.district == ownbar.district).length == 0)//non top N global data
                    .map(ownbar => String(ownbar.district)).map(
                      // fill color bassed on region of district
                      district => {
                        if (Object.keys(districtHkiOptions).indexOf(district) > -1
                        ) {
                          //hki region
                          return hkIslandColor;
                        } else if (Object.keys(districtKltOptions).indexOf(district) > -1
                        ) {
                          //kln region
                          return kowloonColor;
                        } else {
                          //nt region
                          return ntColor;
                        }
                      }
                    )),
              //global data
              data: data!.global.filter(bar => bar.kpiType == Number(dataKpiType)
              ).sort((a, b) => a.kpiValue < b.kpiValue ? 1 : -1
              ).slice(0, TOP_N_DISTRICT + additionalBar.length) //additional for own data that no exist in top N bar
                .map(bar => Number(bar.kpiValue)),
              // .slice(0, TOP_N_DISTRICT),
              fill: 'false',
              label: langDashboard.districtTotal,
              // region!=="ALL"? langDashboard.districtTotal : [langDashboard.districtTotalHki,langDashboard.districtTotalKln, langDashboard.districtTotalNt ]
              // stack:2
              maxBarThickness: barMaxHeight,

            },


          ],
        };

      }
    }
    yield put<DashboardActions>({ type: 'Dashboard.DataUpdated', payload: { graphData, allowedKpiTypeList: dataKpiTypeList, lastModified: data?.lastModified } });
    yield put<DashboardActions>({ type: 'Dashboard.PrefUpdated', payload:{dashboardPref : data!.pref } })

}