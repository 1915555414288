import { ApiPageResult, ApiResult } from 'api';
import { PropertyDraftUpdateDTO, PropertyStockDTO, TransactionDetailDTO, TransactionListItemDTO } from 'common/dto';
import { objectToQuery } from 'common/utils';

const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];

export const transactionApi = {
	getList: async (criteria: any = {}, token: string): Promise<ApiPageResult<TransactionListItemDTO>> => {
		try {
			const response = await fetch(`${BASE_URL}/transactions?${objectToQuery(criteria)}`, {
				headers: { 'Authorization': `Bearer ${token}` }
			});
			return await response.json();
		} catch (e) {
			return { error: String(e) };
		}  
	},

	getDetail: async(id: string, token: string): Promise<ApiResult<TransactionDetailDTO>> => {
		try {
			const response = await fetch(`${BASE_URL}/transactions/${id}`, {
				headers: { 'Authorization': `Bearer ${token}` }
			});
			return await response.json();
		} catch (e) {
			return { error: String(e) };
		}
	},

	getPropertyPreviousTransactionData: async (propertyStock: PropertyStockDTO, token: string): Promise<ApiResult<TransactionDetailDTO>> => {
	  try {
		const response = await fetch(`${BASE_URL}/properties/getPropertyPreviousTransactionData`, {
			method: 'POST', body: JSON.stringify(propertyStock),
			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
		});
		return await response.json();
	  } catch (e) {
		return { error: String(e) };
	  }
	},
	// getPropertyPreviousTransactionData: async (criteria: any = {}, token: string): Promise<ApiResult<TransactionDetailDTO>> => {
	// 	try {
	// 	  const response = await fetch(`${BASE_URL}/properties/getPropertyPreviousTransactionData?${objectToQuery(criteria)}`, {
	// 			headers: { 'Authorization': `Bearer ${token}` }
	// 	  });
	// 	  return await response.json();
	// 	} catch (e) {
	// 	  return { error: String(e) };
	// 	}
	// },

	updatePropertyDraft: async (propertyDraftUpdate: PropertyDraftUpdateDTO, token: string): Promise<ApiResult<TransactionDetailDTO>> => {
		try {
			const response = await fetch(`${BASE_URL}/transactions/updatePropertyDraft`, {
				method: 'POST', body: JSON.stringify(propertyDraftUpdate),
				headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
			});
			return await response.json();
		} catch (e) {
			return { error: String(e) };
		}
	},

	getColumnPreference: async (token: string): Promise<ApiResult<any>> => {
		try {
			const response = await fetch(`${BASE_URL}/transactions/column-preference`, {
				headers: { 'Authorization': `Bearer ${token}` }
			});
			return await response.json();
		} catch (e) {
			return { error: String(e) };
		}
	}, 
}

export default transactionApi;