import { delay, put, take } from "redux-saga/effects";

export function *watchLayoutMessageAdded() {
    while (true) {
        const action = yield take('Layout.AlertMessageAdded');
        if (action.type === 'Layout.AlertMessageAdded') {
            yield put({ type: 'Layout.AlertMessagePresented', payload: action.payload });
            yield delay(6000);
            yield put({ type: 'Layout.AlertMessageRemoved' });
        }
    }
}