import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, TextField } from '@material-ui/core';
import { BuildingAliasDTO } from 'common/dto';
import { FormPresenter } from 'common/hooks';
import { selectAllDistrictOptions } from 'common/selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';

const useStyles = makeStyles(() => ({
  dialogRoot: {
    minWidth: 300,
  },
  buildingPrimaryText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

export interface BuildingAliasListProps {
  onClose: () => void;
  open: boolean;
  onAdd: () => void;
  form: FormPresenter<BuildingAliasDTO>;
}

export const BuildingAliasEditDialog: React.FC<BuildingAliasListProps> = (props) => {
  const classes = useStyles();
  const { onClose, open, onAdd, form } = props;
  const { langBuilding, lang, usageOptions } = useSelector((state: IRootState) => state.locale);
  const allDistrictOptions = useSelector(selectAllDistrictOptions);
  
  const bind = form.bind;

  return (
    <Dialog onClose={onClose}
      open={open}
      PaperProps={{ className: classes.dialogRoot }}
      keepMounted={false}
    >
      <DialogTitle>
        {langBuilding.titleAddAlias}
      </DialogTitle>

      <DialogContent>

        <Grid
          container
          spacing={1}
        >
          {/* <Grid
            item
            md={4}
            xs={12}
          >
            <TextField
              fullWidth
              label={langBuilding.usage}
              margin="dense"
              required
              select
              variant="outlined"
              {...bind('usage')}
            >
              {Object.keys(usageOptions).map(key => (
                <MenuItem key={key} value={key}>{usageOptions[key]}</MenuItem>
              ))}
            </TextField>
          </Grid> */}
          <Grid
            item
            md={4}
            xs={12}
          >
            {/* <DistrictSingleSelect
              {...bind('district')}
              fullWidth
              label={langBuilding.district}
              margin="dense"
              required
              select
              variant="outlined"
            /> */}
            <TextField
              value={allDistrictOptions[form.values.district ?? ''] ?? ''}
              disabled
              fullWidth
              label={langBuilding.district}
              margin="dense"
              required
              variant="outlined"
            />
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >
            <TextField
              {...bind('buildingNameZh')}
              fullWidth
              label={form.values.usage !== 'V' ? langBuilding.buildingNameZh : langBuilding.villageNameZh}
              margin="dense"
              name="lastName"
              variant="outlined"
              required
            />
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >
            <TextField
              {...bind('buildingNameEn')}
              fullWidth
              label={form.values.usage !== 'V' ? langBuilding.buildingNameEn : langBuilding.villageNameEn}
              margin="dense"
              name="lastName"
              variant="outlined"
              required
            />
          </Grid>

          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              {...bind('streetZh')}
              fullWidth
              label={langBuilding.streetZh}
              margin="dense"
              variant="outlined"
              // {...bind('street')}
            />
          </Grid>

          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              {...bind('streetEn')}
              fullWidth
              label={langBuilding.streetEn}
              margin="dense"
              variant="outlined"
              // {...bind('street')}
            />
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >
            <TextField
              fullWidth
              label={langBuilding.lot}
              margin="dense"
              variant="outlined"
              {...bind('lot')}
            />
          </Grid>

          <Grid
            item
            md={4}
            xs={12}
          >
            <TextField
              fullWidth
              label={langBuilding.blockZh}
              margin="dense"
              variant="outlined"
              {...bind('blockZh')}
            />
          </Grid>

          <Grid
            item
            md={4}
            xs={12}
          >
            <TextField
              fullWidth
              label={langBuilding.blockEn}
              margin="dense"
              variant="outlined"
              {...bind('blockEn')}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{lang.actionCancel}</Button>
        <Button onClick={() => {
          if (form.validate()) {
            onAdd();
          }
        }} color="primary">{lang.actionSave}</Button>
      </DialogActions>
    </Dialog>
  );
}