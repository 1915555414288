import { BuildingDetailDTO } from "common/dto";
import NavigationBlockerDialog from "common/elements/NavigationBlockerDialog";
import { useForm } from "common/hooks";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { IRootState, PASDispatch } from "reducers";
import buildingFormValidations from "./building-validations";
import BuildingDetail from "./BuildingDetail";

export default function BuildingCreatePage() {
  const dispatch = useDispatch() as PASDispatch;
  const { langBuilding } = useSelector((state: IRootState) => state.locale);

  const form = useForm<BuildingDetailDTO>({}, {
    validations: buildingFormValidations(langBuilding),
  });
  const tagForm = useForm<any>({
    CLUB_FACILITIES: [],
    OTHERS: []
  });
  const floorForm = useForm<any>({
    floorDefinition: []
  });

  useEffect(() => {
    form.modify({
      floorDefinition : floorForm.values.floorDefinition
    })
  }, [ floorForm.values ]);

  const [ shoudBlockNaigation, setShoudBlockNaigation ] = useState(true);

  const assembleFormToDto = () => {
    const updatedResult = {
      ...form.values,
      lowLevel: form.values.lowLevel?.replace(/\s/g, ""),
      middleLevel: form.values.middleLevel?.replace(/\s/g, ""),
      highLevel: form.values.highLevel?.replace(/\s/g, ""),
      floorDefinition: floorForm.values.floorDefinition.map((f: any, _: any) => true ? { ...f, floor: f.floor.replace(/\s/g, ""), unit: f.unit.replace(/\s/g, "") } : f),
      clubHouseFacilities: tagForm.values.CLUB_FACILITIES,
      others: tagForm.values.OTHERS
    } as Record<string, any>;
    Object.keys(updatedResult).forEach(key => {
      if (typeof updatedResult[key as keyof BuildingDetailDTO] === "string" && updatedResult[key as keyof BuildingDetailDTO].trim() === "") {
        updatedResult[key as keyof BuildingDetailDTO] = null;
      }
    });
    return (updatedResult as BuildingDetailDTO);

  }

  return <>
    <NavigationBlockerDialog matchesUrl={`/buildings/new`} unsaved={shoudBlockNaigation} goBack />
    <BuildingDetail creating onSave={() => {
      if (!form.validate()) {
        return;
      }
      dispatch({ type: 'Building.CreationRequested', payload: assembleFormToDto() });
    setShoudBlockNaigation(false);
  }} form={form} tagForm={tagForm} floorForm={floorForm} canEdit={true} unsaved={true} /></>;
}