import { ApiResult } from 'api';
import { SalePurchaseAgreementDTO, TenancyAgreementDTO } from 'common/dto';

const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];

// TODO: auth
export const agreementApi = {

  addSalePurchaseAgreement: async (propertyStockId: string, agreement: SalePurchaseAgreementDTO, token: string): Promise<ApiResult<SalePurchaseAgreementDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/properties/${propertyStockId}/sale-purchase-agreement`, {
        method: 'POST', body: JSON.stringify(agreement),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  getSalePurchaseAgreement: async (propertyStockId: string, token: string): Promise<ApiResult<SalePurchaseAgreementDTO>> => {
    try {
      const response = await fetch (`${BASE_URL}/properties/${propertyStockId}/sale-purchase-agreement`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  updateSalePurchaseAgreement: async (propertyStockId: string, agreement: SalePurchaseAgreementDTO, token: string): Promise<ApiResult<SalePurchaseAgreementDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/properties/${propertyStockId}/sale-purchase-agreement/${agreement.id}`, {
        method: 'PUT', body: JSON.stringify(agreement),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  addTenancyAgreement: async (propertyStockId: string, agreement: TenancyAgreementDTO, token: string): Promise<ApiResult<TenancyAgreementDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/properties/${propertyStockId}/tenancy-agreement`, {
        method: 'POST', body: JSON.stringify(agreement),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  getTenancyAgreement: async (propertyStockId: string, token: string): Promise<ApiResult<TenancyAgreementDTO>> => {
    try {
      const response = await fetch (`${BASE_URL}/properties/${propertyStockId}/tenancy-agreement`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  updateTenancyAgreement: async (propertyStockId: string, agreement: TenancyAgreementDTO, token: string): Promise<ApiResult<TenancyAgreementDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/properties/${propertyStockId}/tenancy-agreement/${agreement.id}`, {
        method: 'PUT', body: JSON.stringify(agreement),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  }
};

export default agreementApi;