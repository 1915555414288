import { ApiPageResult, ApiResult } from 'api';
import { CheckDuplicatedPropertyStockResultDTO, MemoDTO, PropertyListItemDTO, PropertyStockDTO, PropertyStockSummaryDTO, QueryDuplicatedPropertyStockRequestDTO, WishlistGrantedAgentDTO } from 'common/dto';
import { DiffUpdateSpec, objectToQuery, trackGoals } from 'common/utils';

const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];

// TODO: auth
export const propertyApi = {
  getList: async (criteria: any = {}, token: string): Promise<ApiPageResult<PropertyListItemDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/properties?${objectToQuery(criteria)}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  getDetail: async (id: string, token: string): Promise<ApiResult<PropertyStockDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/properties/${id}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  getDetailByPropertyNo: async (propertyNo: string, token: string): Promise<ApiResult<PropertyStockDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/properties/getByProeprtyNo/${propertyNo}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  getSummary: async (id: string, token: string): Promise<ApiResult<PropertyStockSummaryDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/properties/${id}/summary`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  // Unused for now
  queryDuplicated: async (query: QueryDuplicatedPropertyStockRequestDTO, token: string): Promise<ApiResult<{ id: number | null }>> => {
    try {
      const response = await fetch(`${BASE_URL}/properties/query-duplicated`, {
        method: 'POST', body: JSON.stringify(query),
        headers: { 'Authorization': `Bearer ${token}` }
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  add: async (propertyStock: PropertyStockDTO, token: string): Promise<ApiResult<PropertyStockDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/properties`, {
        method: 'POST', body: JSON.stringify(propertyStock),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });

      const apiResult = await response.json();
      // const goalsToTrack: string[] = apiResult.metadata?.goalsToTrack ?? [];
      const goalsListToTrack = apiResult.metadata?.goalsListToTrack ?? [];
      trackGoals(goalsListToTrack);

      return apiResult;
    } catch (e) {
      return { error: String(e) };
    }
  },

  update: async (propertyStock: PropertyStockDTO, token: string): Promise<ApiResult<PropertyStockDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/properties/${propertyStock.id}`, {
        method: 'POST', body: JSON.stringify(propertyStock),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });

      const apiResult = await response.json();
      // const goalsToTrack: string[] = apiResult.metadata?.goalsToTrack ?? [];
      const goalsListToTrack = apiResult.metadata?.goalsListToTrack ?? [];
      trackGoals(goalsListToTrack);

      return apiResult;
    } catch (e) {
      return { error: String(e) };
    }
  },

  approve: async (id: string, action: string, stage: number, reason: string | undefined, token: string): Promise<ApiResult<PropertyStockDTO>> => {
    const params = new URLSearchParams({
      action, stage: String(stage),
    });
    if (reason) {
      params.append('reason', reason);
    }

    try {
      const response = await fetch(`${BASE_URL}/properties/${id}/approve?${params.toString()}`, {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${token}` },
      });
      const apiResult = await response.json();
      // const goalsToTrack: string[] = apiResult.metadata?.goalsToTrack ?? [];
      const goalsListToTrack = apiResult.metadata?.goalsListToTrack ?? [];
      trackGoals(goalsListToTrack);

      return apiResult;
    } catch (e) {
      return { error: String(e) };
    }
  },

  getMemoList: async (id: string, token: string): Promise<ApiResult<MemoDTO[]>> => {
    try {
      const response = await fetch(`${BASE_URL}/properties/${id}/memo`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  addMemo: async (id: string, memo: MemoDTO, token: string): Promise<ApiResult<MemoDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/properties/${id}/memo`, {
        method: 'POST', body: JSON.stringify(memo),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      const apiResult = await response.json();
      const goalsListToTrack = apiResult.metadata?.goalsListToTrack ?? [];
      trackGoals(goalsListToTrack);
      return apiResult;
    } catch (e) {
      return { error: String(e) };
    }
  },

  updateStatus: async (id: string, status: string, dateModified: string, token: string,): Promise<ApiResult<any>> => {
    const payload = new FormData();
    payload.append('status', status);
    payload.append('dateModified', dateModified)

    try {
      const response = await fetch(`${BASE_URL}/properties/${id}`, {
        method: 'PATCH', body: payload,
        headers: { 'Authorization': `Bearer ${token}` }
      });
      const apiResult = await response.json();
      // const goalsToTrack: string[] = apiResult.metadata?.goalsToTrack ?? [];
      const goalsListToTrack = apiResult.metadata?.goalsListToTrack ?? [];
      trackGoals(goalsListToTrack);

      return apiResult;
    } catch (e) {
      return { error: String(e) };
    }
  },

  agentClaim: async (id: string, token: string): Promise<ApiResult<any>> => {
    try {
      const response = await fetch(`${BASE_URL}/properties/${id}/agent-claim`, {
        method: 'PATCH', body: JSON.stringify(id),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  requestLandSearch: async (id: string, token: string): Promise<ApiResult<boolean>> => {
    try {
      const response = await fetch(`${BASE_URL}/properties/${id}/request-land-search`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  remove: async (id: string, token: string): Promise<ApiResult<any>> => {
    try {
      const response = await fetch(`${BASE_URL}/properties/${id}/remove`, {
        method: 'PATCH', body: JSON.stringify(id),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  getPropertyOwner: async (buildingId: string | undefined, floor: string[] | undefined, unit: string[] | undefined, token: string): Promise<ApiResult<PropertyStockDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/properties/getPropertyOwner/${buildingId}/${floor}/${unit}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  removeLeadAgent: async (pid: string, agentId: string, token: string): Promise<ApiResult<any>> => {
    try {
      const response = await fetch(`${BASE_URL}/properties/remove-lead-agent/${pid}/${agentId}`, {
        method: 'PATCH', body: JSON.stringify(pid),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  // checkDuplicatedPropertyStock: async (buildingId: string|undefined, floor: string[]|undefined, unit: string[]|undefined, token: string): Promise<ApiResult<CheckDuplicatedPropertyStockResultDTO>> => {
  //   try {
  // 	const response = await fetch(`${BASE_URL}/properties/checkDuplicatedPropertyStock/${buildingId}/${floor}/${unit}`, {
  // 	  headers: { 'Authorization': `Bearer ${token}` }
  // 	});
  // 	return await response.json();
  //   } catch (e) {
  // 	return { error: String(e) };
  //   }
  // },

  checkDuplicatedPropertyStock: async (propertyStock: PropertyStockDTO, token: string): Promise<ApiResult<CheckDuplicatedPropertyStockResultDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/properties/checkDuplicatedPropertyStock`, {
        method: 'POST', body: JSON.stringify(propertyStock),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  addLeadAgent: async (propertyStock: PropertyStockDTO, token: string, addAgentsToNewPropertyStockByAdmin?: string, addedFromPropertyStockNum?: string): Promise<ApiResult<any>> => {
    try {
      const response = await fetch(`${BASE_URL}/properties/add-lead-agent/${propertyStock.id}`, {
        method: 'POST', body: JSON.stringify(propertyStock),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`, 'AddAgentsToNewPropertyStockByAdmin': addAgentsToNewPropertyStockByAdmin ?? 'false', 'AddedFromPropertyStockNum': addedFromPropertyStockNum ?? '' },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  checkExistSellerWithSameAgent: async (propertyStockId: string, newSellerId: number, token: string): Promise<ApiResult<boolean>> => {
    try {
      const response = await fetch(`${BASE_URL}/properties/checkExistSellerWithSameAgent/${propertyStockId}/${newSellerId}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  getColumnPreference: async (token: string): Promise<ApiResult<any>> => {
    try {
      const response = await fetch(`${BASE_URL}/properties/column-preference`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  updateSellerClient: async (propertyStock: PropertyStockDTO, token: string): Promise<ApiResult<any>> => {
    try {
      const response = await fetch(`${BASE_URL}/properties/updateSellerClient/${propertyStock.id}`, {
        method: 'POST', body: JSON.stringify(propertyStock),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  addStockCartMultiClient: async (ids: any, token: string): Promise<ApiResult<any>> => {
    const { cids, pid } = ids;
    try {
      const response = await fetch(`${BASE_URL}/properties/${pid}/stock-cart`, {
        method: 'POST', body: JSON.stringify(cids),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  deleteProvisionalAgreement: async (pid: string, type: string, token: string): Promise<ApiResult<any>> => {
    try {
      const response = await fetch(`${BASE_URL}/properties/${pid}/pa-draft?type=${type}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  fetchWishlistGrantedAgents: async (pid: number | string, uid: number | string, token: string): Promise<ApiResult<WishlistGrantedAgentDTO[]>> => {
    try {
      const response = await fetch(`${BASE_URL}/properties/${pid}/wishlist-granted-lists/${uid}`, {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  updateWishlistGrantedAgents: async (pid: number, uid: number | string, updates: DiffUpdateSpec<WishlistGrantedAgentDTO>, token: string): Promise<ApiResult<boolean>> => {
    try {
      const response = await fetch(`${BASE_URL}/properties/${pid}/wishlist-granted-lists/${uid}`, {
        method: 'POST', body: JSON.stringify(updates),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  requestSellerInfoReveal: async (pid: number, cid: number, token: string): Promise<ApiResult<PropertyStockDTO['leadAgents'][0]>> => {
    try {
      const response = await fetch(`${BASE_URL}/properties/${pid}/request-seller-info-reveal?clientId=${cid}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  resetPendingApprovalStage: async (pid: string, token: string): Promise<ApiResult<any>> => {
    try {
      const response = await fetch(`${BASE_URL}/properties/reset/pending_approval_stage`, {
        method: 'PATCH', body: JSON.stringify(pid),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

};

export default propertyApi;