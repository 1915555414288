import { useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';
import { isNonEmpty } from 'common/utils';
import React, { useLayoutEffect, useRef, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { IRootState } from 'reducers';
import classes from './PhotoGallery.module.scss';

const buttonBlue = '#1976d2';
const buttonGray = '#999898';

export interface PhotoGalleryProps {
  /** List of photo url */
  photos: string[];
  video?: string;
  floorPlan: string[];
}

export default function PhotoGallery({ photos: photoList, video, floorPlan }: PhotoGalleryProps) {
  const { langPropertyStock: lang, } = useSelector((state: IRootState) => state.locale);

  const isDesktop = true;

  const [swipeIndex, setSwipeIndex] = useState(0);
  const handleSwipeIndex = (i: number) => {
    setSwipeIndex(i);
    setPhotoLightBoxIndex(i);
  }

  const hasPhotos = isNonEmpty(photoList);
  const hasVideo = isNonEmpty(video);
  const hasFloorPlan = isNonEmpty(floorPlan);

  const [photoType, setPhotoType] = useState<'PHOTO' | 'VIDEO' | 'FLOOR_PLAN'>(hasPhotos ? 'PHOTO' : hasVideo ? 'VIDEO' : 'FLOOR_PLAN');

  const photos = photoType === 'PHOTO' ? photoList : floorPlan;
  const [photoLightBoxOpened, setPhotoLightBoxOpened] = useState(false);
  const [photoLightBoxIndex, setPhotoLightBoxIndex] = useState(0);

  const galleryRef = useRef<HTMLDivElement>(null);
  const [photoHeight, setPhotoHeight] = useState(0);

  const isPhoto = photoType === 'PHOTO';
  const isVideo = photoType === 'VIDEO';
  const isFloorPlan = photoType === 'FLOOR_PLAN';
  const hasPaging = (isPhoto && photoList.length > 1) || (isFloorPlan && floorPlan.length > 1);

  const videoIdBefore = video?.includes('https://www.youtube.com/watch?v=') ? video?.replace('https://www.youtube.com/watch?v=', '') : video?.replace('https://youtu.be/', '');
  const videoId = videoIdBefore?.includes('&ab_channel=') ? videoIdBefore.substring(0, videoIdBefore.indexOf('&ab_channel=')) : videoIdBefore;
  const videoPath = "https://www.youtube.com/embed/" + videoId;

  const handleChangePhotoType = (type: 'PHOTO' | 'VIDEO' | 'FLOOR_PLAN') => {
    setPhotoType(type);
    handleSwipeIndex(0);
  }

  const shouldHideButtonName = useMediaQuery('(max-width: 700px)');

  useLayoutEffect(() => {
    setPhotoHeight((galleryRef.current?.offsetWidth ?? 0) * 0.65); // 4:3
    setPhotoType(hasPhotos ? 'PHOTO' : hasVideo ? 'VIDEO' : 'FLOOR_PLAN');
  }, [galleryRef.current?.offsetWidth]);

  return <div>
    {photoLightBoxOpened &&
      <Lightbox
        reactModalStyle={{ overlay: { zIndex: 1500 } }}
        mainSrc={photos[photoLightBoxIndex]}
        nextSrc={photos[(photoLightBoxIndex + 1) % photos.length]}
        prevSrc={photos[(photoLightBoxIndex + photos.length - 1) % photos.length]}
        onCloseRequest={() => setPhotoLightBoxOpened(false)}
        onMovePrevRequest={() => setPhotoLightBoxIndex((photoLightBoxIndex + photos.length - 1) % photos.length)}
        onMoveNextRequest={() => setPhotoLightBoxIndex((photoLightBoxIndex + 1) % photos.length)}
      />
    }
    <div ref={galleryRef} className={classes['photo-gallery']}>
      <SwipeableViews index={swipeIndex} disabled={false} onChangeIndex={handleSwipeIndex}>
        {
          isVideo ? <iframe
            src={videoPath}
            title="Video player"
            width="100%"
            height={`${photoHeight}px`}
            frameBorder="0"
            allow="accelerometer; 
                      autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen></iframe>
            : photos.map((photo: string, i: number) => <div key={photo} className={classes['photo-container']}
              style={{ backgroundImage: `url("${photo}")`, height: `${photoHeight}px` }}
              onClick={() => setPhotoLightBoxOpened(true)}
            >
              <div className={classes['photo-count']}>
                {`${i + 1} / ${photos.length}`}
              </div>
            </div>)
        }
      </SwipeableViews>
      <div className={clsx(classes['action-container'])}>
        {
          (isDesktop && hasPaging) ? <div className={(swipeIndex != 0) ? clsx(classes['btn'], classes['page-turn']) : clsx(classes['rbtn'], classes['page-turn'])}
            onClick={() => {
              if (swipeIndex != 0) {
                handleSwipeIndex((swipeIndex + photos.length - 1) % photos.length)
              }
            }}
            style={{ color: swipeIndex != 0 ? buttonBlue : buttonGray }}
          >
            <i className="fas fa-angle-left" />
            <div>{shouldHideButtonName ? '' : lang.actionPrevPhoto}</div>
          </div> : null
        }

        <div className={classes['action-group']}>
          {hasPhotos &&
            <div className={hasFloorPlan || hasVideo ? clsx(classes['btn'], classes['left']) : clsx(classes['btn'], classes['single'])}
              style={{ color: photoType === 'PHOTO' ? buttonBlue : buttonGray }}
              onClick={() => {
                if (hasFloorPlan || hasVideo) {
                  handleChangePhotoType('PHOTO');
                }
              }}
            >
              <i className="fas fa-camera" />
              <div>{lang.photo}</div>
            </div>
          }
          {hasVideo &&
            <div className={hasPhotos ? hasFloorPlan ? classes['btn'] : clsx(classes['btn'], classes['right']) : hasFloorPlan ? clsx(classes['btn'], classes['left']) : clsx(classes['btn'], classes['single'])}
              style={{ color: photoType === 'VIDEO' ? buttonBlue : buttonGray }}
              onClick={() => {
                if (hasFloorPlan || hasPhotos) {
                  handleChangePhotoType('VIDEO');
                }
              }}
            >
              <i className="fas fa-video" />
              <div>{lang.video}</div>
            </div>
          }
          {hasFloorPlan &&
            <div className={hasPhotos || hasVideo ? clsx(classes['btn'], classes['right']) : clsx(classes['btn'], classes['single'])}
              style={{ color: photoType === 'FLOOR_PLAN' ? buttonBlue : buttonGray }}
              onClick={() => {
                if (hasPhotos || hasVideo) {
                  handleChangePhotoType('FLOOR_PLAN');
                }
              }}
            >
              <i className="fas fa-puzzle-piece" />
              <div>{lang.floorPlan}</div>
            </div>
          }
        </div>

        {
          isDesktop && !isVideo && hasPaging ? <div className={(swipeIndex != photos.length - 1) ? clsx(classes['btn'], classes['page-turn']) : clsx(classes['rbtn'], classes['page-turn'])}
            onClick={() => {
              if (swipeIndex != photos.length - 1) {
                handleSwipeIndex((swipeIndex + 1) % photos.length)
              }
            }}
            style={{ color: swipeIndex != photos.length - 1 ? buttonBlue : buttonGray }}

          >
            <div>{shouldHideButtonName ? '' : lang.actionNextPhoto}</div>
            <i className="fas fa-angle-right" />
          </div> : null
        }

      </div>
    </div>
  </div>
}