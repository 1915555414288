import { PropertyAttachmentDTO } from "common/dto";
import moment from "moment";

export const isValidForm35Attachment = (type: 'FORM_3' | 'FORM_5') => (a: PropertyAttachmentDTO) =>
  a.id && a.dataStatus === 'A' &&
  a.type === (type as string) &&
  moment(new Date).isSameOrAfter(a.validityPeriodStart, 'day') &&
  moment(new Date).isSameOrBefore(a.validityPeriodEnd, 'day')
  ;

export const form35ValidityChecking = (attachments: PropertyAttachmentDTO[] | undefined, status: string | undefined) => {
  const hasForm3 = attachments?.filter(isValidForm35Attachment('FORM_3')).length;
  const hasForm5 = attachments?.filter(isValidForm35Attachment('FORM_5')).length;

  const missingForm35 = ({
    'SALES': hasForm3 ? 'OK' : '3',
    'RENT': hasForm5 ? 'OK' : '5',
    'BOTH': hasForm3 && hasForm5 ? 'OK' : '3 & 5',
  } as Record<string, string | undefined>)[status ?? ''];
  const form35Valid = missingForm35 === 'OK';

  return {
    missingForm35, form35Valid,
  };
}