import { ApiResult } from 'api';
import { DashboardAggregateDTO } from 'common/dto';
import { objectToQuery } from 'common/utils';


const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];

export const dashboardApi = {
	getDashboard: async (criteria: any = {}, token: string): Promise<ApiResult<DashboardAggregateDTO>> => {
		try {
			const response = await fetch(`${BASE_URL}/dashboard?${objectToQuery(criteria)}`, {
				headers: { 'Authorization': `Bearer ${token}` }
			});
			return await response.json();
		} catch (e) {
			return { error: String(e) };
		}  
	},

	updateDasboardPref: async ( pref: any ={},token: string) :Promise<ApiResult<any>> =>{
		try {	
			const response = await fetch(`${BASE_URL}/dashboard/updateDPref`, {
				method: 'POST', body: JSON.stringify(pref),
				headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
			});
			return await response.json();
		} catch (e) {
			return { error: String(e) };
		}  
	}

}

export default dashboardApi;