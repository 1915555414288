import { Backdrop, CircularProgress, Fab, makeStyles, Snackbar, useMediaQuery } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useTheme } from '@material-ui/styles';
import activityAuditingApi from 'api/activityAuditingApi';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Footer, Sidebar, Topbar } from './components';
import QuickAccessDrawer from './components/QuickAccessBar';




const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    height: '100vh',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  },
  shiftContent: {
    // paddingLeft: 240
    paddingLeft: 20
  },
  content: {
    height: '100%',
    width: '100%',
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
    '& > .content': {
      maxWidth: 1366,
      marginLeft: 'auto',
      marginRight: 'auto',
      height: '100%',
    }
  },
  fab: {
    position: 'fixed',
    right: theme.spacing(3),
    bottom: theme.spacing(3),
    zIndex: theme.zIndex.drawer + 1,
  }
}));

const READ_AUDITING_REQUIRED = /\/(properties|client-detail|transactions|buildings)\/(\d+)/;

const Main = props => {
  const { children, disableDefaultTopBar } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const maskRefCount = useSelector((state) => state.layout.maskRefCount);
  const alert = useSelector((state) => state.layout.alert);
  const { token } = useSelector((state) => state.login);
  const { iosStatusBarHeight } = useSelector((state) => state.layout);

  const [openSidebar, setOpenSidebar] = useState(false);

  // const handleSidebarOpen = () => {
  //   setOpenSidebar(true);
  // };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  // const shouldOpenSidebar = isDesktop ? true : openSidebar;
  const shouldOpenSidebar = openSidebar;

  const [quickOpen, setQuickOpen] = useState(false);
  const [ quickOpenPos, setQuickOpenPos ] = useState(undefined);
  const toggleDrawer = (position) => (open) => {
    if (!quickOpen) {
      setQuickOpenPos(position);
    }
    setQuickOpen(!quickOpen);
  };
  const fabRef = useRef();

  // Path Tracking
  const [ lastRoute, setLastRoute ] = useState('');
  const [ enterAt, setEnterAt ] = useState(new Date());
  const history = useHistory();

  const sendTracking = (beacon) => {
    const reportReadActivity = beacon ? activityAuditingApi.reportReadActivityBeacon : activityAuditingApi.reportReadActivity;
  
    
  
    if (lastRoute) {
      console.timeEnd(lastRoute);
      const [ _, resourcePath, idPath ] = READ_AUDITING_REQUIRED.exec(lastRoute) ?? [];
      
      switch (resourcePath) {
        case 'properties':
          reportReadActivity('PROPERTY_STOCK', idPath, enterAt.toISOString(), new Date().toISOString(), token);
          break;
        case 'client-detail':
          reportReadActivity('CLIENT', idPath, enterAt.toISOString(), new Date().toISOString(), token);
          break;
        case 'transactions':
          reportReadActivity('TRANSACTION', idPath, enterAt.toISOString(), new Date().toISOString(), token);
          break;
        case 'buildings':
          reportReadActivity('BUILDING', idPath, enterAt.toISOString(), new Date().toISOString(), token);
          break;
      }
    }
  };

  // Unload Handling
  const onUnloadCallback = () => {
    sendTracking(true);
  };

  useEffect(() => {
    window.addEventListener('beforeunload', onUnloadCallback);
    return () => window.removeEventListener('beforeunload', onUnloadCallback);
  });

  useEffect(() => {
    
    sendTracking(lastRoute);
    
    
    const newRoute = history.location.pathname;
    console.time(newRoute);
    setLastRoute(newRoute);
    setEnterAt(new Date());
    
    
  }, [ history.location.pathname ]);

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop
      })}
    >
      {disableDefaultTopBar ? null : <Topbar onSidebarOpen={toggleDrawer('top-left')} />}
      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={'temporary'} // isDesktop ? 'persistent' : 'temporary'
      />
      <main className={classes.content} style={{ marginTop: iosStatusBarHeight }}>
        <div className={'content'}>
          {children}
          <Footer />
        </div>
      </main>
      <Fab color="primary" ref={fabRef} onClick={toggleDrawer('bottom-right')} className={classes.fab}>
        <MenuIcon />
      </Fab>
      <QuickAccessDrawer clickAwayAvoidanceRef={fabRef} open={quickOpen} position={quickOpenPos} setOpen={setQuickOpen} />

      <Backdrop
        className={classes.backdrop}
        open={maskRefCount !== 0}
        style={{ zIndex: theme.zIndex.tooltip, color: '#fff' }}
        // onClick={() => {
        //   setOpen(false);
        // }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar open={!!alert}
        // autoHideDuration={6000}
        message={alert?.message}>
      </Snackbar>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  disableDefaultTopBarLayout: PropTypes.bool,
};

export default Main;
