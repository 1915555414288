import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { ClientListItemDTO } from 'common/dto';
import React from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      padding: theme.spacing(2),
    },
    popover:{
      width: '100%',
      
    },
    table: {
      minWidth: 350,
    },
  }),
);

interface PopoverProps {
    open: boolean;
    handleClose: () => void;
    handleClick: (event: React.MouseEvent<HTMLTableHeaderCellElement>, selectedClient:ClientListItemDTO) => void;
    id :string | undefined;
    anchorEl :HTMLTableHeaderCellElement | null;
    selectedClient : ClientListItemDTO | null;

  }
  export const SimplePopover = ({open ,handleClose, handleClick, id, anchorEl ,selectedClient }: PopoverProps) => {
    // const otherContact = selectedClient?.otherContacts.filter((contact, idx)=>contact.name != '' && idx > 0) ?? [];
    const otherContact = selectedClient?.otherContacts.filter((contact, idx) => idx > 0) ?? [];
    const { langClientDetail } = useSelector((state: IRootState) => state.locale);
    const classes = useStyles();
    return (
        <div>
          
          <Popover
            className={classes.popover}
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            
          <Typography className={classes.typography}> {otherContact.length == 0 ? langClientDetail.msgNoOtherContacts : langClientDetail.otherContacts} </Typography>

            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead style={{display: 'none'}}>
                  <TableRow>
                    <TableCell>{langClientDetail.contactType}</TableCell>
                    {/* <TableCell>{langClientDetail.contactNoOrEmail}</TableCell> */}
                    <TableCell>{langClientDetail.contactName}</TableCell>
                    <TableCell>{langClientDetail.remarks}</TableCell>
                
                  </TableRow>
                </TableHead>
                <TableBody>
                  {otherContact.map((contact, idx: number) => (
                      <TableRow key={idx}>
                        <TableCell>{contact.type + ' : ' + contact.value}</TableCell>
                        {/* <TableCell align="left">{contact.value}</TableCell> */}
                        <TableCell>{contact.name}</TableCell>
                        <TableCell>{ (contact.remarks == '' || contact.remarks == null) ? '' : '(' + contact.remarks + ')'}</TableCell>
                      </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>


            
          
          </Popover>
        </div>
      );
  }
  