import { OutlinedTextFieldProps, TextField, TextFieldProps, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import { useSelector } from 'react-redux';
import { IRootState } from "reducers";
import { SelectDistrictDialog } from "./SelectDistrictDialog";


interface DistrictSingleSelectProps extends OutlinedTextFieldProps{
  onToggleChip : (key: string) => any;
  districtValue: string;
  disableSelection?: boolean;
}

const UITextField = (props: TextFieldProps & { fixedLabel?: boolean }) => {
  let { label, fixedLabel, ...rest } = props;
  fixedLabel = false;
  return <Fragment>
    {fixedLabel ? <Typography>{label}</Typography> : null}
    <TextField {...rest} label={!fixedLabel ? label : null} InputProps={{readOnly: true}} inputProps={{style: {cursor: 'pointer'}}}/>
  </Fragment>
};

export default function DistrictSingleSelect({ value, onChange, disabled, onToggleChip, districtValue, disableSelection, required, error, helperText }: DistrictSingleSelectProps) {
  const localeBundle = useSelector((state: IRootState) => state.locale);
  const { langPropertyStock, districtHkiOptions, districtNtOptions, districtKltOptions,
  } = localeBundle;

  const valueArray = [districtValue];

  const allDistrictOptions = {
    ...districtHkiOptions,
    ...districtKltOptions,
    ...districtNtOptions,
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return <div>
    
    <UITextField
      label={langPropertyStock.district}
      value={valueArray.map(val => allDistrictOptions[val]).join(', ')}
      onClick={() => {
        if (disableSelection) {
          return;
        } else {
          handleClickOpen();
        }
      }}
      fullWidth
      margin="dense"
      variant="outlined"
      error={error ?? false}
      helperText={helperText ?? ''}
      required={required ?? false}
      // onChange={onChange} 
      // inputProps={{ readOnly: disableSelection }}
      disabled={disableSelection ?? false}
    />
    <SelectDistrictDialog
      open={open}
      handleClose={handleClose}
      handleClickOpen={handleClickOpen}
      value={valueArray}
      regionAllSelected={() => { return; }}
      toggleDistrictByRegion={() => { return; }}
      toggleChip={onToggleChip}

    />

    </div>;
}