import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, TextareaAutosize, Typography
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { MemoDTO } from 'common/dto';
import { FormPresenter } from 'common/hooks';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';


const useStyles = makeStyles((theme) => ({
	root: {

	},
	dialogContent: {
		display: 'flex',
		alignItems: 'center',
    justifyContent: 'center',
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
	textArea: {
		width: '90%',
		margin: 'dense', 
		padding: '8px',  
		fontSize: '14px'
	}
}));

interface MemoProps {
	isOpen: boolean;
	onClose: () => void;
	form: FormPresenter<MemoDTO>;
	onSave: () => void;
}

export const MemoNew = (props: MemoProps) => {
	const { isOpen, onClose, form, onSave } = props;
	const { lang } = useSelector((state: IRootState) => state.locale);
	const classes = useStyles();

	const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
	const bind = form.bind;
	const bindContent = bind('content');
	const changeTimeout = useRef<any>(0);
	useEffect(() => {
		if (textAreaRef.current) {
			textAreaRef.current!.value = bindContent.value;
		}
	}, [ bindContent.value, textAreaRef ]);

	return (
		<Dialog fullWidth={true} onClose={onClose} open={isOpen}>
			<DialogTitle >
				<Typography variant="h3">{lang.actionAddMemo}</Typography>			
			</DialogTitle>
			<IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
			<DialogContent 
				dividers 
				className={classes.dialogContent}
			>
				<TextareaAutosize
					ref={textAreaRef}
					className={classes.textArea}
					rowsMin={6}
					rowsMax={8}
					placeholder={lang.msgPleaseInputMemo}
					// {...bind('content')}
					// value={val}
					onChange={(ev) => {
						const valuedEv = { target: { value: ev.target.value } };
						clearTimeout(changeTimeout.current);
						changeTimeout.current = setTimeout(() => {
							bindContent.onChange(valuedEv);
						}, 0);
					}}
				/>
			</DialogContent>
			<DialogActions>
				<Button color="primary" variant="contained" onClick={onSave}>{lang.actionSave}</Button>
			</DialogActions>			
		</Dialog>
	)
}

export default MemoNew;