export * from './loginApi';
export * from './masterDataApi';
export { propertyApi } from './propertyApi';
export * from './userApi';

export type ApiResult<T = any> = {
  error?: null | undefined;
  data: T;
  metadata?: any;
} | {
  error: string;
  data?: null | undefined;
  metadata?: any;
}

export interface Page<T> {
  content: T[];
  currentPage: number;
  totalPages: number;
  totalCount: number;
  totalElements: number;
}

export type ApiErrorCode = 'ERR_NOT_FOUND' | 'ERR_PERMISSION_DENIED' |
  'ERR_SERVER_ERROR' | 'ERR_INVALID_REQUEST' | 'ERR_VERSION_MISMATCHED' | 'ERR_MALICIOUS_FAILED' | 'ERR_CLIENT_VERSION_MISMATCHED';

export class ApiError extends Error {
  get code(): string { return this.message as string };
  public metadata?: any;
  public redirect?: string;

  static of(code: string, metadata?: any, redirect?: string) {
    const err = new ApiError(code);
    err.metadata = metadata;
    err.redirect = redirect;
    return err;
  }

  static isApiError(o: any): o is ApiError {
    return o instanceof ApiError;
  }
}

export type ApiPageResult<T> = ApiResult<Page<T>>;
