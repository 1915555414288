import { ApiError } from 'api';
import { IRootState, LayoutActions, LocaleState, LoginActions } from 'reducers';
import { cancelled, put, select } from 'redux-saga/effects';
import { PASSagaContext } from 'sagas';
import { browserHistory } from 'store';
import { format } from 'util';


/**
 * Wrap API call related tasks and provide common functionalities, like loading mask and error handling.
 * 
 * @param func Saga task function which calls API
 */

let lastArg = '';
export function apiTaskWrapper<T extends (...args: any[]) => Generator>(func: T, options?: any, context?: PASSagaContext) {
  return function* (...args: Parameters<T>) {
    yield put<LayoutActions>({ type: 'Layout.MaskPresentRequested' });
    if (lastArg === '') {
      lastArg = args[0].type
    }
    else if (lastArg !== args[0].type) {
      lastArg = args[0].type
    }

    const { lang }: LocaleState = yield select((state: IRootState) => state.locale);
    const token: string = yield select((state: IRootState) => state.login.token);

    try {
      yield* func(...args);

    } catch (e) {

      if (ApiError.isApiError(e)) {
        switch (e.code) {
          case 'ERR_MOVED':
            if (context?.browserHistory.replace) {
              //console.info(`redirect: /${e.metadata?.modulePath}/${e.metadata?.objectId}`);
              context!.browserHistory.replace(`/${e.metadata?.modulePath}/${e.metadata?.objectId}`, { bypassUnsavedCheck: true });
            }
            break;
          case 'ERR_SYSTEM_BUSY':
            yield put<LayoutActions>({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgSystemBusy, severity: 'error' } });
            break;
          case 'ERR_NOT_FOUND':
            if (e.redirect) {
              browserHistory.replace(e.redirect, { bypassUnsavedCheck: true });
            }

            // Some cases you should handle not found error your self.
            yield put<LayoutActions>({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgDataNotFound, severity: 'error' } });
            break;
          case 'ERR_NOT_AUTHORIZED':
            yield put<LayoutActions>({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgSessionExpired, severity: 'error' } });
            yield put<LoginActions>({ type: 'Login.LogoutRequested' });
            break;
          case 'ERR_PERMISSION_DENIED':
            yield put<LayoutActions>({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgPermissionDenied, severity: 'error' } });
            // yield put<LayoutActions>({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgLoggedOut, severity: 'error' } });
            // yield put<LoginActions>({ type: 'Login.LogoutRequested' });
            break;
          case 'ERR_VERSION_MISMATCHED':
            yield put<LayoutActions>({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgVersionMismatched, severity: 'error' } });
            break;
          case 'ERR_CLIENT_VERSION_MISMATCHED':
            yield put<LayoutActions>({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgClientVersionMismatched, severity: 'error' } });
            yield put({ type: 'ClientDetail.setHasAlreadyBeenModifiedApiError', payload: true });
            break;
          case 'ERR_BUILDING_ALIAS_ALREADY_EXISTS':
            yield put<LayoutActions>({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgBuildingAliasAlreadyExists } });
            break;
          case 'TypeError: Failed to fetch':
            yield put<LayoutActions>({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgFailedToConnect, severity: 'error' } });
            break;
          case 'ERR_SERVER_ERROR':
          case 'ERR_MALICIOUS_FAILED':
          default:
            // Client related error should not be handled here (e.g. ERR_INVALID_REQUEST),
            // should be handled in saga functions instead.
            if (e.redirect) {
              browserHistory.replace(e.redirect);
            }
            yield put<LayoutActions>({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgNetworkError, severity: 'error' } });
            break;
        }
      } else {
        console.error('apiTaskWrapper caught unknown error:', e);
        yield put<LayoutActions>({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgNetworkError, severity: 'error' } });
      }
    } finally {
      if (yield cancelled()) {
        console.info('apiTaskWrapper detected cancellation');
      }

      if (lastArg === args[0].type) {
        for (let i = 0; i < 2; i++) {
          yield put<LayoutActions>({ type: 'Layout.MaskDismissRequested' });
        }
      } else {
        yield put<LayoutActions>({ type: 'Layout.MaskDismissRequested' });
      }
    }
  }
}

export function* alertMessage(message: string) {
  yield put<LayoutActions>({ type: 'Layout.AlertMessageAdded', payload: { message, severity: 'error' } });
}

export function* itemActionMessage(action: 'create' | 'update' | 'confirmRequest' | 'confirm' | 'approvalSubmit' | 'approve' | 'reject', item: string) {
  const langCreationUpdateMessages = yield select((state: IRootState) => state.locale.langCreationUpdateMessages)
  const messageKey = {
    'create': 'msgCreation',
    'update': 'msgUpdate',
    'confirmRequest': 'msgConfirmRequest',
    'confirm': 'msgConfirm',
    'approvalSubmit': 'msgApprovalSubmit',
    'approve': 'msgApprove',
    'reject': 'msgReject'
  }[action];

  yield* alertMessage(format(
    langCreationUpdateMessages[messageKey],
    langCreationUpdateMessages['item' + item],
  ));
}