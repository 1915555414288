import { makeStyles } from '@material-ui/core';
import { CommissionReportSearchDTO } from 'common/dto';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { CommissionReportActions, IRootState, PASDispatch } from 'reducers';
import { CommissionReportSearch, CommissionReportTable, CommissionReportToolbar } from './components';


const DEFAULT_SIZE = 10;

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  search: {
    marginBottom: theme.spacing(2)
  }
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function toCriteria(values: any) {
  return values;
}

let currentCommissionReportRow = 0;

const CommissionReportList = () => {
  const classes = useStyles();

  const dispatch = useDispatch() as PASDispatch;
  const { rowsCountPrefOptions } = useSelector((state: IRootState) => state.locale);
  const reportList = useSelector((state: IRootState) => state.commissionReport.currentList);
  const { totalPages, totalElements } = useSelector((state: IRootState) => state.commissionReport);
  // const rowsCountPreference = useSelector((state: IRootState) => state.login.rowsCountPreference) ?? EMPTY_STRING;

  const [page, setPage] = useState(0);
  // const [ limit, setLimit ] = useState(10);
  const limit = useSelector((state: IRootState) => state.login.rowsCountPreference?.SALE_COMMISSION_REPORT) ?? DEFAULT_SIZE;
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(currentCommissionReportRow === 0 ? limit : currentCommissionReportRow);
  currentCommissionReportRow = currentRowsPerPage;
  //   useEffect(() => {
  //     setLimit(JSON.parse(rowsCountPreference)?.SALE_COMMISSION_REPORT);
  // }, [ rowsCountPreference]);
  const handleRowsCount = (count: number) => {
    dispatch({ type: 'Login.RowCountPrefUpdate', payload: { SALE_COMMISSION_REPORT: count } });
  }

  const [sortOrders, setSortOrders] = useState<{ [k: string]: 'desc' | 'asc' }>({
    transactionDate: 'desc'
    // price: 'desc',
    // dateModified: 'desc',
  });

  const query = useQuery();


  const [values, setValues] = useState<Partial<CommissionReportSearchDTO>>({
    street: query.get('street') ?? undefined,
    building: query.get('building') ?? undefined,
    block: query.get('block') ?? undefined,
    level: query.get('level') ? [query.get('level')!] : [],
    status: [],
  });

  const handleSearch = (values: Partial<CommissionReportSearchDTO>) => {
    setValues(values);
    setPage(0);
    dispatch({
      type: 'CommissionReportList.FetchRequested', payload: {
        page: 0, size: currentRowsPerPage, sort: sortOrders, ...toCriteria(values as any)
      }
    });
  };

  const handleSort = (key: string | null) => {
    if (key === null) {
      setSortOrders({});
      dispatch({
        type: 'CommissionReportList.FetchRequested', payload: {
          page, size: currentRowsPerPage, sort: {}, ...toCriteria(values as any)
        }
      });
      return;
    }
    let newSortOrders;
    if (key != 'transactionDate') {
      newSortOrders = {
        [key]: sortOrders?.[key] === 'asc' ? 'desc' : 'asc',
        ['transactionDate']: 'desc'
      };
    } else {
      newSortOrders = { [key]: sortOrders?.[key] === 'asc' ? 'desc' : 'asc' };
    }
    setSortOrders(newSortOrders as any);
    dispatch({
      type: 'CommissionReportList.FetchRequested', payload: {
        page, size: currentRowsPerPage, sort: newSortOrders, ...toCriteria(values as any)
      }
    });
  }

  useEffect(() => {
    dispatch({
      type: 'CommissionReportList.FetchRequested', payload: {
        page, size: currentRowsPerPage, sort: sortOrders, ...toCriteria(values as any)
      }
    } as CommissionReportActions);
  }, [currentRowsPerPage]);

  return (
    <div className={classes.root}>
      <CommissionReportToolbar />
      <div className={classes.content}>
        <CommissionReportSearch onsearch={handleSearch} initialcriteria={values} className={classes.search} iscommissionreportpage={true}></CommissionReportSearch>
        <CommissionReportTable
          page={page}
          limit={currentRowsPerPage}
          rowsCountOptions={Object.keys(rowsCountPrefOptions).map(Number)}
          handleRowsCount={(ev) => { setCurrentRowsPerPage(ev); handleRowsCount(ev); }}
          totalPages={totalPages}
          totalElements={totalElements}
          setPage={(page: any) => {
            setPage(page);
            dispatch({
              type: 'CommissionReportList.FetchRequested', payload: {
                page, size: currentRowsPerPage, sort: sortOrders
              }
            });
          }}
          reportList={reportList}
          sortOrders={sortOrders}
          handleSort={handleSort}
        />
      </div>
    </div>
  );
};

export default CommissionReportList;