import { ApiResult } from 'api';
import { PrivilegeDTO, ResourceDTO, RoleDTO } from 'common/dto';
import { DiffUpdateSpec, objectToQuery } from 'common/utils';

const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];

// TODO: auth
export const rolePrivilegeApi = {

  getRoleAndPrivilege: async (roleId: string, token: string): Promise<ApiResult<RoleDTO>> => {
    try {
      const response = await fetch (`${BASE_URL}/roles/${roleId}`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  addRoleAndPrivilege: async (roleDto: RoleDTO, token: string): Promise<ApiResult<string>> => {
    try {
      const response = await fetch(`${BASE_URL}/roles`, {
        method: 'POST', body: JSON.stringify(roleDto),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  editRoleAndPrivilege: async (roleDto: RoleDTO, token: string): Promise<ApiResult<RoleDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/roles/${roleDto.id}/edit`, {
        method: 'POST', body: JSON.stringify(roleDto),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  //edit privilege ONLY
  editPrivilege: async (roleName: string, listUpdate: DiffUpdateSpec<PrivilegeDTO>,token: string): Promise<ApiResult<boolean>> => {
    try {
      const response = await fetch (`${BASE_URL}/roles/${roleName}/privileges/edit`, {
        method: 'PATCH', 
        body: JSON.stringify(listUpdate),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  getResource: async (token: string): Promise<ApiResult<ResourceDTO[]>> => {
    try {
      const response = await fetch (`${BASE_URL}/resource/get`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  getRoleList: async (criteria: any = {}, token: string): Promise<ApiResult<RoleDTO[]>> => {
    try {
      const response = await fetch (`${BASE_URL}/roles?${objectToQuery(criteria)}`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

};

export default rolePrivilegeApi;