import { Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { Notifications, Password } from './components';



const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: 250,
      flexGrow: 1,
    }
  })
);

const Settings = () => {
  const classes = useStyles();
  let data = new FormData();
  data.append('username', 'admin');
  data.append('password', 'P@ssw0rd');
  // const masterData = useSelector(state => state.masterData);
  useEffect(() => {
    // const fetchBook = () =>{}
      // dispatch({type: 'MasterDataRequest.Start'});
      // fetch('http://localhost:8080/public/getLogin');

      fetch('http://localhost:8080/public/login', {
        method: 'POST',
        body: data
      })
        .then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(response => console.log('Success:', response));
        
      // fetch('http://localhost:8080/customer/list?size=2&page=1', {
      //   method: 'GET',
      //   headers: { 'Authorization': 'Bearer '+ token },
      // })
      //   .then(res => res.json())
      //   .catch(error => console.error('Error:', error))
      //   .then(response => console.log('Success:', response));
      // fetch("http://localhost:8080/standardDemo/rest/masterData/masterDataList")
      // fetch('http://localhost:8080/standardDemo/rest/masterData/masterDataList', {
      // method: 'GET',
      // headers: { 'Content-Type': 'application/json'}
      // })
      //   .then(res => res.json())
      //   .catch(error => console.error('Error:', error))
      //   .then(response => console.log('Success:', response));
    // fetchBook()
  }, [])

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          md={7}
          xs={12}
        >
          <Notifications />
        </Grid>
        <Grid
          item
          md={5}
          xs={12}
        >
          <Password />
        </Grid>
        <Grid
          item
          md={7}
          xs={12}
        >
          {/* <Signature /> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default Settings;
