import {
    Card, CardContent, FormControl, Grid as MuiGrid, IconButton, InputLabel, makeStyles, MenuItem, Select, Switch, Typography
} from '@material-ui/core';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import { hasPermission } from 'common/access-control';
import { UserDTO } from 'common/dto';
import { FormPresenter } from 'common/hooks';
import { hideWhenHoc } from 'common/ui';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';



// #region Base UI

const Grid = hideWhenHoc(MuiGrid);

const EMPTY = {};

const useStyles = makeStyles(() => ({
  root: {},
  patchOutlineAutocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
      paddingLeft: 8,
    }
	},
	dragListStyle: {
		background: '#F0F0F0',
  	padding: 8,
	},
	dragItemStyle: {
		paddingLeft: 8,
		margin: `0 0 8px 0`,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		minHeight: '38px',
		// change background colour if dragging
		background: '#FFFFFF'
	}
}));

// #endregion Base UI


export interface UserPreferenceProps {
  form: FormPresenter<UserDTO>;
  creating?: boolean;
  profileDisabled?: boolean;
  infoDisabled?: boolean;
}

const UserPreference = ({ form, profileDisabled }: UserPreferenceProps) => {
  	const { langUser, langClientDetail, langPropertyStock, lang, rowsCountPrefOptions, langTransaction } = useSelector((state: IRootState) => state.locale);
  	const { privileges } = useSelector((state: IRootState) => state.login);
	// const languageOptions = useSelector((state: IRootState) => state.systemSettings?.Language?.LANGUAGE_OPTION ?? {});

	const { COLUMN_CANNOT_BE_DISABLED } = useSelector((state: IRootState) => state.systemSettings.System) ?? EMPTY;
  	// const dispatch = useDispatch() as PASDispatch;

	// const bind = form.bind;
	const classes = useStyles();
	// const theme = useTheme();
	// const uid = String(form.values.id);

	const canReadClient = hasPermission(privileges, 'READ', 'CLIENT');
	const canReadPropertyStock = hasPermission(privileges, 'READ', 'PROPERTY_STOCK');
	const canReadTransaction = hasPermission(privileges, 'READ', 'TRANSACTION') || hasPermission(privileges, 'READ', 'TRANSACTION_RESTRICTED');
	const canReadBuilding = hasPermission(privileges, 'READ', 'BUILDING');
	const canReadCommissionReport = hasPermission(privileges, 'READ', 'SALES_COMMISSION_REPORT');

	const moduleMap: { [key: string]: { captionName: string, permission?: boolean } } = {
		CLIENT: { captionName: langUser.client, permission: canReadClient },
		PROPERTY_STOCK: { captionName: langUser.propertyStock, permission: canReadPropertyStock },
		TRANSACTION: { captionName: langUser.transaction, permission: canReadTransaction },
		BUILDING_MASTER: { captionName: langUser.building, permission: canReadBuilding },
		MEMO_ENQUIRY: { captionName: langUser.memoEnquiry, permission: true },
		SALE_COMMISSION_REPORT: { captionName: langUser.salesCommissionReport, permission: canReadCommissionReport },
		NOTIFICATION_CENTER: { captionName: langUser.notificationCenter, permission: true }
	};

	const psColNameMap : { [key: string]: string } = {
		id: langPropertyStock.stockNo,
    dateCreated: langPropertyStock.dateCreated ,
    dateModified: langPropertyStock.dateModified ,
    status: langPropertyStock.status ,
    usage: langPropertyStock.usage ,
    street: langPropertyStock.street ,
    building: langPropertyStock.buildingOrVillage ,
    price: langPropertyStock.captionPriceOrRent,
    block: langPropertyStock.block ,
    floor: langPropertyStock.floor ,
    unit: langPropertyStock.unit ,
    gross: langPropertyStock.gross ,
    net: langPropertyStock.net ,
    pricePerGross: langPropertyStock.pricePerGross ,
    pricePerNet: langPropertyStock.pricePerNet ,
    owner: langPropertyStock.sellerOrOwner ,
    mainContact: langPropertyStock.mainContact ,
    lot: langPropertyStock.lot ,
    symbol: langPropertyStock.symbol ,
    keyNo: langPropertyStock.key ,
    vacant: langPropertyStock.vacant ,
    occupancyPermitAge: langPropertyStock.occupancyPermitAge  
	};
	
	const clientColNameMap : { [key: string]: string } = {
    clientType: langClientDetail.clientType,
    clientStatus: langClientDetail.clientStatus,
    clientName: langClientDetail.clientName,
    contactType: langClientDetail.contactType,
    mainContact: langClientDetail.contactNoOrEmail,
    source: langClientDetail.sourceOfClient,
		usage: langClientDetail.propertyUsage ,
		net: langPropertyStock.net,
		gross: langPropertyStock.gross ,
    price: langPropertyStock.captionPriceOrRent,
    remarks: langClientDetail.remarks,
    dateModified: lang.dateModified,
	};

	const transactionColNameMap:  { [key: string]: string } = {
		dateCreated: lang.dateCreated,
		buyerName: langTransaction.buyer,
		sellerName: langTransaction.seller,
		district: langTransaction.district ,
		street: langTransaction.street ,
		building: langTransaction.buildingOrVillage ,
		lot: langTransaction.lot ,
		block: langTransaction.block ,
		floor: langTransaction.floor ,
		unit: langTransaction.unit ,
		gross: langTransaction.gross ,
		net: langTransaction.net ,
		price: langTransaction.price ,
		pricePerNet: langTransaction.pricePerNet ,
		buildingAge: langTransaction.buildingAge ,
		transactionDate: langTransaction.transactionDate ,
		usage: langTransaction.usage ,
	}

	const togglePsCol = (colName: string) => {
		const currDisabledColumns: string[] = form.values.propertyStockColumnPreference?.disabledColumns;
		const newDisabledColumns: string[] = currDisabledColumns.includes(colName) ? 
			currDisabledColumns.filter(c => c !== colName) : [ ...currDisabledColumns, colName ];

		form.setValues({
			...form.values,
			propertyStockColumnPreference: {
				...form.values.propertyStockColumnPreference, 
				disabledColumns: newDisabledColumns
			}
		});
	};

	const toggleClientCol = (colName: string) => {
		const currDisabledColumns: string[] = form.values.clientColumnPreference?.disabledColumns;
		const newDisabledColumns: string[] = currDisabledColumns.includes(colName) ? 
			currDisabledColumns.filter(c => c !== colName) : [ ...currDisabledColumns, colName ];

		form.setValues({
			...form.values,
			clientColumnPreference: {
				...form.values.clientColumnPreference, 
				disabledColumns: newDisabledColumns
			}
		});
	};

	
	const toggleTransactionCol = (colName: string) => {
		const currDisabledColumns: string[] = form.values.transactionColumnPreference?.disabledColumns;
		const newDisabledColumns: string[] = currDisabledColumns.includes(colName) ? 
			currDisabledColumns.filter(c => c !== colName) : [ ...currDisabledColumns, colName ];

		form.setValues({
			...form.values,
			transactionColumnPreference: {
				...form.values.transactionColumnPreference, 
				disabledColumns: newDisabledColumns
			}
		});
	};
	
	
	const [ globalRowCountPreference, setGlobalRowCountPreference ] = useState<number | undefined>();

	const [ showIndividualSetting, setShowIndividualSetting ] = useState(false);

	useEffect(()=>{
		let firstPreference = form.values.rowsCountPreference?.['CLIENT'] ?? 0;
		let isSame = true;
		Object.keys(form.values.rowsCountPreference ?? {}).map(key => {
			isSame = isSame && (form.values.rowsCountPreference?.[key] ?? 0) === firstPreference;
		});
		if (isSame){
			setGlobalRowCountPreference(firstPreference as number);
			setShowIndividualSetting(false);
		}else{
			setGlobalRowCountPreference(undefined);
			setShowIndividualSetting(true);
		}
	},[form.values.rowsCountPreference])

  return (
		<div>
			<Grid container md={12} xs={12} spacing={1}>
				<Grid item md={12} xs={12} spacing={1}>
					<Card>
						<CardContent>
							<Grid container spacing={1}>
								<Grid item md={12} xs={12}>
									<Typography style={{ fontWeight: 'bold' }}>{langUser.rowsCountPreference}</Typography>
								</Grid>
								<Grid item container md={12} xs={12}>
									<Grid item md={2} xs={6}>
										<FormControl variant="outlined" margin="dense" fullWidth>
											<InputLabel variant="outlined" style={{ background: '#fff' }}>{langUser.globalPreference}</InputLabel>
											<Select
												disabled={profileDisabled}
												value={globalRowCountPreference ?? ''}
												onChange={(e) => {
													setGlobalRowCountPreference(e.target.value as number);
													form.setValues({
														...form.values,
														rowsCountPreference: {
															...form.values.rowsCountPreference, 
															CLIENT: e.target.value,
															PROPERTY_STOCK: e.target.value,
															TRANSACTION: e.target.value,
															BUILDING_MASTER: e.target.value,
															MEMO_ENQUIRY: e.target.value,
															SALE_COMMISSION_REPORT: e.target.value,
															NOTIFICATION_CENTER: e.target.value
														}
													});
												}}
											>
												{
													Object.keys(rowsCountPrefOptions).map( countPref => (
														<MenuItem key={countPref} value={countPref}>{countPref}</MenuItem>
													))
												}
												{/* <MenuItem key={10} value={10}>{10}</MenuItem>
												<MenuItem key={25} value={25}>{25}</MenuItem>
												<MenuItem key={50} value={50}>{50}</MenuItem>
												<MenuItem key={100} value={100}>{100}</MenuItem> */}
											</Select>
										</FormControl>
									</Grid>
									<Grid item md={3} xs={6}>
										<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'flex-end' }}>
											<Switch
												checked={showIndividualSetting}
												onChange={() => setShowIndividualSetting(!showIndividualSetting)}
											/>
											<Typography>{langUser.captionShowIndividualSetting}</Typography>
										</div>
									</Grid>
								</Grid>
								{ showIndividualSetting ? 
									Object.keys(form.values.rowsCountPreference?? {})?.
										filter((key) => !!(moduleMap[key]?.permission))
										.map((key) => (
										<Grid item md={2} xs={6}>
											<FormControl variant="outlined" margin="dense" fullWidth>
												<InputLabel variant="outlined" style={{ background: '#fff' }}>{moduleMap[key]?.captionName}</InputLabel>
												<Select
													disabled={profileDisabled}
													value={form.values.rowsCountPreference?.[key] ?? ''}
													onChange={(e) => form.setValues({
														...form.values,
														rowsCountPreference: {
															...form.values.rowsCountPreference, 
															[key]: e.target.value
														}
													})}
												>
													{
														Object.keys(rowsCountPrefOptions).map( countPref => (
															<MenuItem key={countPref} value={countPref}>{countPref}</MenuItem>
														))
													}
													{/* <MenuItem key={10} value={10}>{10}</MenuItem>
													<MenuItem key={25} value={25}>{25}</MenuItem>
													<MenuItem key={50} value={50}>{50}</MenuItem>
													<MenuItem key={100} value={100}>{100}</MenuItem> */}
												</Select>
											</FormControl>
										</Grid>
									)) : null
								}
							</Grid>
						</CardContent>
					</Card>
				</Grid>
				{
					canReadPropertyStock &&
						<Grid item md={4} xs={12}>
							<Card>
								<CardContent>
									<Grid container spacing={1}>
										<Grid item md={12} xs={12}>
											<Typography style={{ fontWeight: 'bold' }}>{langUser.propertyStockColumnPreference}</Typography>
										</Grid>
										<Grid item md={12} xs={12}>
											<Typography variant="caption" style={{ fontStyle: 'italic' }}>
												{langUser.columnPreferenceHelperText}
											</Typography>
										</Grid>
										<Grid item md={12} xs={12}>
											<DragDropContext onDragEnd={(result) => {
												if (!result.destination) {
													return;
												}

												const i1 = result.source.index, i = result.destination?.index ?? 0;
												const oldList = [ ...form.values.propertyStockColumnPreference?.columnsOrder ];
												let newList = [];
												if (i > i1) { //move downward
													newList.push(...oldList.slice(0, i1)); // items above the selected item's original position
													newList.push(...oldList.slice(i1 + 1, i + 1)); //items between the original position and the destination
													newList.push(oldList[i1]); // selected item
													newList.push(...oldList.slice(i + 1, oldList.length)); //items below the destination
												} else if (i < i1) { //move upward
													newList.push(...oldList.slice(0, i)); // items above the selected item's destination
													newList.push(oldList[i1]); //selected item
													newList.push(...oldList.slice(i, i1)); //items between the destination and the original position
													newList.push(...oldList.slice(i1 + 1, oldList.length)); // items below the original position
												} else {
													newList = oldList;
												}
												// form.updateValues('propertyStockColumnPreference', newList);
												form.setValues({
													...form.values,
													propertyStockColumnPreference: {
														...form.values.propertyStockColumnPreference,
														columnsOrder: newList
													}
												});
											}}>
												<Droppable droppableId="container" direction="vertical">
													{(provided, snapshot) => (
														<div
															{...provided.droppableProps}
															ref={provided.innerRef}
															className={classes.dragListStyle}
														>
															{form?.values?.propertyStockColumnPreference?.columnsOrder?.map((col: string, index: number) => (
																<Draggable key={col} draggableId={col} index={index}>
																	{(provided, snapshot) => (
																		<div
																			ref={provided.innerRef}
																			{...provided.draggableProps}
																			{...provided.dragHandleProps}
																			className={classes.dragItemStyle}
																		>
																			<div style={{ 
																				opacity: form.values.propertyStockColumnPreference?.disabledColumns?.includes(col) ? 0.5 : 'inherit' 
																			}}>
																				<IconButton style={{ cursor: 'move' }}>
																					<OpenWithIcon />
																				</IconButton>
																				{psColNameMap[col]}
																			</div>
																			<div style={{paddingTop: '5px',  display: COLUMN_CANNOT_BE_DISABLED.PROPERTY_STOCK?.includes(col) ? 'none' : 'block'}}>
																				<Switch
																					checked={!form.values.propertyStockColumnPreference?.disabledColumns?.includes(col)}
																					onChange={() => togglePsCol(col)}
																				/>
																			</div>
																		</div>
																	)}
																</Draggable>
															))}
															{provided.placeholder}
														</div>
													)}
												</Droppable>
											</DragDropContext>
										</Grid>
									</Grid>
								</CardContent>
							</Card>
						</Grid>
				}
		  	
				{
					canReadClient && 
						<Grid item md={4} xs={12}>
							<Card>
								<CardContent>
									<Grid container spacing={1}>
										<Grid item md={12} xs={12}>
											<Typography style={{ fontWeight: 'bold' }}>
												{langUser.clientColumnPreference}
											</Typography >
										</Grid>
										<Grid item md={12} xs={12}>
											<Typography variant="caption" style={{ fontStyle: 'italic' }}>
												{langUser.columnPreferenceHelperText}
											</Typography>
										</Grid>
										<Grid item md={12} xs={12}>
											<DragDropContext onDragEnd={(result) => {
												if (!result.destination) {
													return;
												}

												const i1 = result.source.index, i = result.destination?.index ?? 0;
												const oldList = [ ...form.values.clientColumnPreference?.columnsOrder ];
												let newList = [];
												if (i > i1) { //move downward
													newList.push(...oldList.slice(0, i1)); // items above the selected item's original position
													newList.push(...oldList.slice(i1 + 1, i + 1)); //items between the original position and the destination
													newList.push(oldList[i1]); // selected item
													newList.push(...oldList.slice(i + 1, oldList.length)); //items below the destination
												} else if (i < i1) { //move upward
													newList.push(...oldList.slice(0, i)); // items above the selected item's destination
													newList.push(oldList[i1]); //selected item
													newList.push(...oldList.slice(i, i1)); //items between the destination and the original position
													newList.push(...oldList.slice(i1 + 1, oldList.length)); // items below the original position
												} else {
													newList = oldList;
												}
												// form.updateValues('clientColumnPreference', newList);
												form.setValues({
													...form.values,
													clientColumnPreference: {
														...form.values.clientColumnPreference,
														columnsOrder: newList
													}
												});
											}}>
												<Droppable droppableId="container" direction="vertical">
													{(provided, snapshot) => (
														<div
															{...provided.droppableProps}
															ref={provided.innerRef}
															className={classes.dragListStyle}
														>
															{form?.values?.clientColumnPreference?.columnsOrder?.map((col: string, index: number) => (
																<Draggable key={col} draggableId={col} index={index}>
																	{(provided, snapshot) => (
																		<div
																			ref={provided.innerRef}
																			{...provided.draggableProps}
																			{...provided.dragHandleProps}
																			className={classes.dragItemStyle}
																		>
																			<div style={{ 
																				opacity: form.values.clientColumnPreference?.disabledColumns?.includes(col) ? 0.5 : 'inherit' 
																			}}>
																				<IconButton style={{ cursor: 'move' }}>
																					<OpenWithIcon />
																				</IconButton>
																				{clientColNameMap[col]}
																			</div>
																			<div style={{ paddingTop: '5px', display: COLUMN_CANNOT_BE_DISABLED.CLIENT?.includes(col) ? 'none' : 'block'}}>
																				<Switch
																					checked={!form.values.clientColumnPreference?.disabledColumns?.includes(col)}
																					onChange={() => toggleClientCol(col)}														
																				/>
																			</div>
																		</div>
																	)}
																</Draggable>
															))}
															{provided.placeholder}
														</div>
													)}
												</Droppable>
											</DragDropContext>
										</Grid>
									</Grid>
								</CardContent>
							</Card>
						</Grid>
				}

				{
					canReadTransaction &&
					<Grid item md={4} xs={12}>
						<Card>
							<CardContent>
								<Grid container spacing={1}>
									<Grid item md={12} xs={12}>
										<Typography style={{ fontWeight: 'bold' }}>
											{langUser.transactionColumnPreference}
										</Typography >
									</Grid>
									<Grid item md={12} xs={12}>
										<Typography variant="caption" style={{ fontStyle: 'italic' }}>
											{langUser.columnPreferenceHelperText}
										</Typography>
									</Grid>
									<Grid item md={12} xs={12}>
										<DragDropContext onDragEnd={(result) => {
											if (!result.destination) {
												return;
											}

											const i1 = result.source.index, i = result.destination?.index ?? 0;
											const oldList = [...form.values.transactionColumnPreference?.columnsOrder];
											let newList = [];
											if (i > i1) { //move downward
												newList.push(...oldList.slice(0, i1)); // items above the selected item's original position
												newList.push(...oldList.slice(i1 + 1, i + 1)); //items between the original position and the destination
												newList.push(oldList[i1]); // selected item
												newList.push(...oldList.slice(i + 1, oldList.length)); //items below the destination
											} else if (i < i1) { //move upward
												newList.push(...oldList.slice(0, i)); // items above the selected item's destination
												newList.push(oldList[i1]); //selected item
												newList.push(...oldList.slice(i, i1)); //items between the destination and the original position
												newList.push(...oldList.slice(i1 + 1, oldList.length)); // items below the original position
											} else {
												newList = oldList;
											}
											// form.updateValues('transactionColumnPreference', newList);
											form.setValues({
												...form.values,
												transactionColumnPreference: {
													...form.values.transactionColumnPreference,
													columnsOrder: newList
												}
											});
										}}>
											<Droppable droppableId="container" direction="vertical">
												{(provided, snapshot) => (
													<div
														{...provided.droppableProps}
														ref={provided.innerRef}
														className={classes.dragListStyle}
													>
														{form?.values?.transactionColumnPreference?.columnsOrder?.map((col: string, index: number) => (
															<Draggable key={col} draggableId={col} index={index}>
																{(provided, snapshot) => (
																	<div
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}
																		className={classes.dragItemStyle}
																	>
																		<div style={{
																			opacity: form.values.transactionColumnPreference?.disabledColumns?.includes(col) ? 0.5 : 'inherit'
																		}}>
																			<IconButton style={{ cursor: 'move' }}>
																				<OpenWithIcon />
																			</IconButton>
																			{transactionColNameMap[col]}
																		</div>
																		<div style={{ paddingTop: '5px', display: COLUMN_CANNOT_BE_DISABLED.TRANSACTION?.includes(col) ? 'none' : 'block' }}>
																			<Switch
																				checked={!form.values.transactionColumnPreference?.disabledColumns?.includes(col)}
																				onChange={() => toggleTransactionCol(col)}
																			/>
																		</div>
																	</div>
																)}
															</Draggable>
														))}
														{provided.placeholder}
													</div>
												)}
											</Droppable>
										</DragDropContext>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				}
			</Grid>
		</div>
  );
}

export default UserPreference;
