import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import ActivityAuditingList from 'views/ActivityAuditing/AcivityAuditingList';
import JobMonitor from 'views/JobMonitor/JobMonitor';
import MemoEnquiryList from 'views/MemoEnquiry/MemoEnquiryList';
import NotificationsCentre from 'views/Notification/NotificationsCentre';
import OptionSetting from 'views/OptionSettings/OptionSettingList';
import PropertyPreviewPage from 'views/PreviewPropertStock/PropertyPreviewPage';
import PropertyCreatePage from 'views/PropertyDetail/PropertyCreatePage';
import PropertyUpdatePage from 'views/PropertyDetail/PropertyUpdatePage';
import UserCreatePage from 'views/UserDetail/UserCreatePage';
import UserUpdatePage from 'views/UserDetail/UserUpdatePage';
import UserList from 'views/UserList/UserList';
import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import {
  AgentRatingCommentMgt as AgentRatingCommentMgtView, BuildingList as BuildingListView, CAFeatureTagMgt as CAFeatureTagMgtView, ClientList as ClientListView, ClientSignPage as ClientSignPageView, CommissionReportList as CommissionReportListView, Dashboard as DashboardView, ForceChangePasswordPage as ForceChangePasswordPageView, ForgotPasswordResetPage as ForgotPasswordResetPageView, NotFound as NotFoundView, ProductList as ProductListView,
  PropertyList as PropertyListView, PropertiesMatchingList as PropertiesMatchingListView, RoleList as RoleListView,
  // Icons as IconsView,
  Settings as SettingsView, SignIn as SignInView, SignUp as SignUpView, SystemSettingList as SystemSettingListView, TransactionDetail, TransactionList as TransactionListView, Typography as TypographyView
} from './views';
import BuildingCreatePage from './views/BuildingDetail/BuildingCreatePage';
import BuildingUpdatePage from './views/BuildingDetail/BuildingUpdatePage';
import ClientCreatePage from './views/ClientDetail/ClientCreatePage';
import ClientUpdatePage from './views/ClientDetail/ClientUpdatePage';
import RolePrivilegeCreatePage from './views/RolePrivilege/RolePrivilegeCreatePage';
import RolePrivilegeUpdatePage from './views/RolePrivilege/RolePrivilegeUpdatePage';



const Routes = () => {
  return (
    <Switch>
      {/* <BrowserRouter basename={process.env.PUBLIC_URL} /> */}
      <Redirect
        exact
        from="/"
        to="/dashboard"
      />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={BuildingListView}
        exact
        layout={MainLayout}
        path="/buildings"
      />
      <RouteWithLayout
        component={BuildingCreatePage}
        exact
        disableDefaultTopBar
        layout={MainLayout}
        path="/buildings/new"
      />
      <RouteWithLayout
        component={BuildingUpdatePage}
        exact
        disableDefaultTopBar
        layout={MainLayout}
        path="/buildings/:id"
      />
      <RouteWithLayout
        component={MemoEnquiryList}
        exact
        layout={MainLayout}
        path="/memo-enquiry"
      />
      <RouteWithLayout
        component={NotificationsCentre}
        exact
        layout={MainLayout}
        path="/notifications-centre"
      />
      <RouteWithLayout
        component={PropertyListView}
        exact
        layout={MainLayout}
        path="/properties"
      />
      <RouteWithLayout
        component={PropertiesMatchingListView}
        exact
        layout={MainLayout}
        path="/matching-properties"
      />
      <RouteWithLayout
        component={PropertyCreatePage}
        disableDefaultTopBar
        exact
        layout={MainLayout}
        path="/properties/new"
      />
      <RouteWithLayout
        component={PropertyPreviewPage}
        disableDefaultTopBar
        exact
        layout={MainLayout}
        path="/properties-preview/:propertyNo"
      />
      <RouteWithLayout
        component={PropertyUpdatePage}
        disableDefaultTopBar
        // exact
        layout={MainLayout}
        path="/properties-edit/:propertyNo"
      />
      <RouteWithLayout
        component={PropertyPreviewPage}
        disableDefaultTopBar
        exact
        layout={MainLayout}
        path="/properties/:propertyNo"
      />
      <RouteWithLayout
        component={TransactionListView}
        exact
        layout={MainLayout}
        path="/transactions"
      />
      <RouteWithLayout
        component={CommissionReportListView}
        exact
        layout={MainLayout}
        path="/commission-report"
      />
      <RouteWithLayout
        component={TransactionDetail}
        disableDefaultTopBar
        exact
        layout={MainLayout}
        path="/transactions/:id"
      />
      <RouteWithLayout
        component={ProductListView}
        exact
        layout={MainLayout}
        path="/products"
      />
      <RouteWithLayout
        component={TypographyView}
        exact
        layout={MainLayout}
        path="/typography"
      />
      <RouteWithLayout
        component={ClientListView}
        exact
        layout={MainLayout}
        path="/client-detail"
      />
      <RouteWithLayout
        component={ClientCreatePage}
        exact
        layout={MainLayout}
        disableDefaultTopBar
        path="/client-detail/new"
      />
      <RouteWithLayout
        component={ClientUpdatePage}
        // exact
        layout={MainLayout}
        disableDefaultTopBar
        path="/client-detail/:cid"
      />

      {/* <RouteWithLayout
        component={IconsView}
        exact
        layout={MainLayout}
        path="/icons"
      /> */}
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />

      <RouteWithLayout
        component={UserCreatePage}
        exact
        disableDefaultTopBar
        layout={MainLayout}
        path="/users/new"
      />

      <RouteWithLayout
        component={UserUpdatePage}
        exact
        disableDefaultTopBar
        layout={MainLayout}
        path="/users/:id"
      />

      <RouteWithLayout
        component={UserList}
        exact
        layout={MainLayout}
        path="/users"
      />
      <RouteWithLayout
        component={ForceChangePasswordPageView}
        exact
        layout={MinimalLayout}
        path="/force-change-password"
      />
      <RouteWithLayout
        component={ActivityAuditingList}
        exact
        layout={MainLayout}
        path="/activity-auditing"
      />
      <RouteWithLayout
        component={RoleListView}
        exact
        layout={MainLayout}
        path="/roles"
      />
      <RouteWithLayout
        component={RolePrivilegeCreatePage}
        exact
        layout={MainLayout}
        path="/roles/new"
      />
      <RouteWithLayout
        component={RolePrivilegeUpdatePage}
        exact
        layout={MainLayout}
        path="/roles/:roleId"
      />
      <RouteWithLayout
        component={SystemSettingListView}
        exact
        layout={MainLayout}
        path="/system-setting"
      />
      <RouteWithLayout
        component={OptionSetting}
        exact
        layout={MainLayout}
        path="/option-editor"
      />
      <RouteWithLayout
        component={ClientSignPageView}
        exact
        layout={MinimalLayout}
        path="/form-client-sign"
      />
      <RouteWithLayout
        component={ForgotPasswordResetPageView}
        exact
        layout={MinimalLayout}
        path="/reset-password"
      />
      <RouteWithLayout
        component={CAFeatureTagMgtView}
        exact
        layout={MainLayout}
        path="/CAFeatureTagMgt"
      />
      <RouteWithLayout
        component={AgentRatingCommentMgtView}
        exact
        layout={MainLayout}
        path="/CAAgentRatingCommentMgt"
      />
      <RouteWithLayout
        component={JobMonitor}
        exact
        layout={MainLayout}
        path="/jobs"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
