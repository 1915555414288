import {
	Button as MuiButton, Card,
	CardActions,
	CardContent, colors, Divider, IconButton as MuiIconButton, Table,
	TableBody,
	TableCell,
	TableHead, TableRow, TableSortLabel,
	// TablePagination,
	Theme
} from '@material-ui/core';
import { CardProps } from '@material-ui/core/Card';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useConfirmDialog } from 'common/ConfirmDialog';
import { UserListItemDTO } from 'common/dto';
import DataRow from 'common/elements/DataRow';
import TablePagination from 'common/elements/TablePagination';
import { tooltipHoc } from 'common/ui';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { IRootState, PASDispatch } from 'reducers';

const IconButton = tooltipHoc(MuiIconButton);
const Button = tooltipHoc(MuiButton);

const PUBLIC_URL = process.env['PUBLIC_URL'];

const useStyles = makeStyles((theme: Theme) => ({
	root: {},
	content: {
		padding: 0
	},
	inner: {
		minWidth: 1050
	},
	nameContainer: {
		display: 'flex',
		alignItems: 'center'
	},
	avatar: {
		marginRight: theme.spacing(2)
	},
	actions: {
		justifyContent: 'flex-start',
		background: colors.grey[50],
		flexWrap: 'wrap',
		'& > *': {
			wordBreak: 'keep-all',
			// marginBottom: '8px',
		},
		padding: '0px',
	},
	tableRow: {
		cursor: 'pointer',
	},
	tableCell: {
		wordWrap: 'normal',
		wordBreak: 'keep-all',
		lineBreak: 'strict',
		whiteSpace: 'nowrap',
		// minWidth: 128,
	},
	iconButton: {
		height: '24px',
		width: '24px',
	},
	tablePaginationIconButton: {
		'& .MuiIconButton-root': {
			padding: '6px',
		}
	},
	tablePaginationToolbar: {
		paddingRight: '12px',
	},
	clearSortingContainer: {
		padding: '3px',
	}
}));

type Header = {
	title: string | JSX.Element,
	overrideStyle?: any,
	sortDisabled?: boolean;
};

interface UsersTableProps extends CardProps {
	className?: string;
	users: UserListItemDTO[];
	// matchingClient?: {
	//   name: string;
	// } | null;
	// onCancelPropertyMatching?: () => void;

	// formSigned?: boolean;
	// setFormSigned?: (v: boolean) => void;

	page: number;
	totalPages: number;
	totalElements: number;
	limit: number;
	setPage: (page: number) => void;
	sortOrders?: { [k: string]: 'desc' | 'asc' };
	handleSort?: (key: string | null) => void;
}

const UsersTable = (props: UsersTableProps) => {
	const { className, users, sortOrders, handleSort, page, totalPages, totalElements, limit, setPage, ...rest } = props;
	const { locale, langUser, roleOptions, lang, statusOptions, districtHkiOptions, districtNtOptions, districtKltOptions, } = useSelector((state: IRootState) => state.locale);
	// const { privileges } = useSelector((state: IRootState) => state.login);
	const FAIL_ATTEMPT = +(useSelector((state: IRootState) => state.systemSettings?.Security?.LOGIN?.FAIL_ATTEMPT)) || 5;
	const dispatch = useDispatch() as PASDispatch;
	const confirmDialog = useConfirmDialog();

	const allDistrictOptions = {
		...districtHkiOptions,
		...districtKltOptions,
		...districtNtOptions,
	};

	const classes = useStyles(0);
	const history = useHistory();


	let tableHeaders: Record<any, Header | null> = { // keyof UserListItemDTO
		username: { title: langUser.username },
		[locale === 'en' ? 'englishName' : 'chineseName']: { title: langUser.name },
		'userRoles.roleName': { title: langUser.roles },
		'features.value': { title: langUser.district },
		'contacts.value': { title: langUser.contact },
		'dataStatus': { title: langUser.status },
	};

	// if (canResetPassword) {
	// 	tableHeaders = {
	// 		resetPassword: { title: langUser.captionResetPassword },
	// 		...tableHeaders,
	// 	};
	// }

	tableHeaders = {
		operations: { title: langUser.captionOperations, sortDisabled: true },
		...tableHeaders,
	};

	const navigationLink = (id: string) => `/users/${id}`;

	const handleNavigateToItem = (id: string) => () => {
		sessionStorage.setItem('lastClickedUser', id);
		history.push(navigationLink(id));
	}

	const handlePageChange = (event: React.MouseEvent | null, page: number) => {
		setPage(page);
	};

	const overrideCols: { [col: string]: (users: UserListItemDTO) => any } = {
		operations: user => <div onClick={(ev) => ev.stopPropagation()} style={{ display: 'flex', flexDirection: 'row' }}>
			<IconButton
				className={classes.iconButton}
				disabled={user.emailCount === 0}
				tooltip={user.emailCount === 0 ? langUser.msgNoEmailForResetPw : langUser.captionResetPassword}
				onClick={(ev) => {
					ev.preventDefault();
					ev.stopPropagation();
					if (user.emailCount === 0) {
						confirmDialog.confirm(langUser.msgNoEmailForResetPw, langUser.actionGotoDetail, lang.actionCancel).then(() => {
							handleNavigateToItem(user.id!)();
						});
					} else {
						confirmDialog.confirm(langUser.msgConfirmToResetPw, lang.actionConfirm, lang.actionCancel).then((confirmed) => {
							if (confirmed) {
								dispatch({ type: 'User.ResetPasswordRequested', payload: { id: user.id } });
							}
						});
					}
				}}
			>
				<RotateLeftIcon />
			</IconButton>

			<IconButton
				className={classes.iconButton}
				disabled={user.failAttempt < FAIL_ATTEMPT}
				tooltip={user.failAttempt < FAIL_ATTEMPT ? langUser.msgNoNeedToUnlock : langUser.captionUnlock}
				onClick={(ev) => {
					ev.preventDefault();
					ev.stopPropagation();
					confirmDialog.confirm(langUser.msgConfirmToUnlock, lang.actionConfirm, lang.actionCancel).then((confirmed) => {
						if (confirmed) {
							dispatch({ type: 'User.UnlockRequested', payload: { id: user.id } });
						}
					});
				}}
			>
				<LockOpenIcon />
			</IconButton>
		</div>,
		chineseName: user => user.chineseName || user.englishName,
		englishName: user => user.englishName || user.chineseName,
		'contacts.value': user => user.mainContact,
		'userRoles.roleName': user => user.roles.map(roleName => roleOptions[roleName]).join(', '),
		'features.value': user => user.district.map(district => allDistrictOptions[district]).join(', '),
		'dataStatus': user => (user.failAttempt < FAIL_ATTEMPT) ? (user.status ? statusOptions[user.status] : '') : statusOptions['L'],
	};

	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>
			{confirmDialog.render()}
			<CardActions className={classes.actions}>
				<div style={{ flexGrow: 1 }} />
				{/* {Object.keys(sortOrders ?? {}).length ?
          <div className={classes.clearSortingContainer}>
						<Button style={{ marginRight: 0 }} variant="outlined" size="small" onClick={() => handleSort?.(null)}>{lang.actionClearSorting}</Button>
          </div>
				: null} */}

				<TablePagination
					classes={{
						actions: classes.tablePaginationIconButton,
						toolbar: classes.tablePaginationToolbar,
					}}
					// component="div"
					count={totalElements}
					onChangePage={handlePageChange}
					// onChangeRowsPerPage={handleRowsPerPageChange}
					page={page}
					totalPages={totalPages}
					rowsPerPage={limit}
					labelRowsPerPage={lang.rowsCount}
					rowsPerPageOptions={[20]}
				/>
			</CardActions>

			<Divider />

			<CardContent className={classes.content}>
				<PerfectScrollbar>
					<div className={classes.inner}>
						<Table size="small">
							<TableHead>
								<TableRow>
									{
										Object.keys(tableHeaders).filter((key) => !!tableHeaders[key as keyof UserListItemDTO]).map((key, idx) =>
											<TableCell key={idx} className={classes.tableCell} style={tableHeaders[key as keyof UserListItemDTO]?.overrideStyle}>
												{tableHeaders[key as keyof UserListItemDTO]?.sortDisabled ? tableHeaders[key as keyof UserListItemDTO]?.title : <TableSortLabel
													active={sortOrders?.[key] !== undefined}
													direction={sortOrders?.[key]}
													onClick={() => handleSort?.(key)}
												>
													{tableHeaders[key as keyof UserListItemDTO]?.title}
												</TableSortLabel>}
											</TableCell>
										)
									}
								</TableRow>
								{Object.keys(sortOrders ?? {}).length ? <TableRow>
								</TableRow> : null}
							</TableHead>
							<TableBody>
								{users.map(user => (
									<DataRow
										className={classes.tableRow}

										hover
										key={user.id}
										// selected={selectedProperties.indexOf(property.id!) !== -1}
										onClick={handleNavigateToItem(user.id!)}
										href={PUBLIC_URL + navigationLink(user.id!)}
									>
										{
											Object.keys(tableHeaders).filter((key) => !!tableHeaders[key as keyof UserListItemDTO]).map((key) => {
												// 
												return <TableCell className={classes.tableCell} style={user.id?.toString() === sessionStorage.getItem('lastClickedUser') ? { fontWeight: 'bold', color: '#551A8B ' } : { color: user.failAttempt < FAIL_ATTEMPT ? 'inherit' : colors.red[500] }} key={`${user.id}-${key}`}>{overrideCols[key] ? overrideCols[key](user) : user[key as keyof UserListItemDTO]}</TableCell>
											})
										}
									</DataRow>
								))}
							</TableBody>
						</Table>
					</div>
				</PerfectScrollbar>
			</CardContent>
		</Card>
	);
}

export default UsersTable;