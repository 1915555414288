import palette from '../../../../theme/palette';

export const options = (langDashboard) => ({

  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  legend: { 
    display: true,
    position: 'top',
    align: 'end' ,
    labels:{fontSize: 10} 
    // labels:{
    //   generateLabels: function(chart){
    //     // 
    //     // 
    //     var data = chart.data;
    //     if (data.labels.length && data.datasets.length ) {
    //       return data.labels.map(function(label, i) {
    //         var meta = chart.getDatasetMeta(0);
    //         var ds = data.datasets[0];
    //         var arc = meta.data[i];
    //         var custom = arc && arc.custom || {};
    //         var getValueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault;
    //         var arcOpts = chart.options.elements.arc;
            
    //         var fill = custom.backgroundColor ? custom.backgroundColor : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);
            
    //         var stroke = custom.borderColor ? custom.borderColor : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
    //         var bw = custom.borderWidth ? custom.borderWidth : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);

    //         // We get the value of the current label
    //         var value = chart.config.data.datasets[arc._datasetIndex].data[arc._index];
          
    //           return {
    //             // Instead of `text: label,`
    //             // We add the value to the string
    //             text: label ,
    //             fillStyle: fill,
    //             strokeStyle: stroke,
    //             lineWidth: bw,
    //             hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
    //             index: i
    //           };
    //         });
    //         }
    //       else {
    //       return [];
    //     }
    //   }
    // }
  },
  cornerRadius: 20,
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: palette.divider,
    backgroundColor: palette.white,
    titleFontColor: palette.text.primary,
    bodyFontColor: palette.text.secondary,
    footerFontColor: palette.text.secondary,    
    // callbacks: {
    //     label: function(tooltipItem, data) {
    //       
    //       
    //       var dataset = data.datasets[tooltipItem.datasetIndex];
    //       var index = tooltipItem.index;
    //       // // var backgroundColor = dataset.
    //       return dataset.label[index] + ": " + dataset.data[index];
    //       // return null;
    //     }
    //   }
  },
  layout: { padding: 0 },
  scales: {
    xAxes: [
      {
        // barThickness: 12,
        // maxBarThickness: 10,
        // barPercentage: 0.5,
        // categoryPercentage: 0.5,
        // stacked: true,
        ticks: {
          fontColor: palette.text.secondary,
          beginAtZero: true,
          precision:0,
          min: 0
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: palette.divider
        },
        scaleLabel: {
          display: true,
          labelString: langDashboard.numberOfpropertyUpdates,
        }
      }
    ],
    yAxes: [
      {
        stacked: true,
        ticks: {
          fontColor: palette.text.secondary,
          // beginAtZero: true,
          // min: 0
        },
        gridLines: {
          display: false,
          drawBorder: false
        },
        scaleLabel: {
          display: true,
          labelString: langDashboard.district,
        }
      }
    ]
  }
}
);
