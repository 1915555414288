import {
  Button, Card, CardActions, CardContent, CardHeader, Checkbox as MuiCheckbox, Divider, FormControl, Grid, InputLabel, ListItemText, makeStyles, MenuItem, Select, TextField, Typography
} from '@material-ui/core';
import { CardProps } from '@material-ui/core/Card';
import { TextFieldProps } from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker } from '@material-ui/pickers';
import buildingApi from 'api/buildingApi';
import { BuildingListItemDTO, TransactionSearchDTO } from 'common/dto';
import DistrictMultiSelect from 'common/elements/DistrictMultiSelect';
import { BLANK_RANGE } from 'common/elements/MinMaxField';
import { useForm } from 'common/hooks';
import { HandleFormHelperText, tooltipHoc } from 'common/ui';
import { getBuildingSortForAutoComplete, getBuilingListWithoutDuplicates, getStreetListWithoutDuplicates, getStreetSortForAutoComplete, initFormKeysAndBooleanMap, limitTextFieldLength, multiLang } from 'common/utils';
import moment from 'moment';
import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';

const Checkbox = tooltipHoc(MuiCheckbox);

export interface SearchFormValues {
  // Multi Selection Criteria. 
  // Query string example: ...&usage=V,R&district=NTP,NSG&...
  usage: string[];
  status: string[];
  district: string[];
  // streetOrBuilding: string;
  street: string;
  building: string;
  level: string[];
  block: string;

  // Area & Price
  // Range values can be Infinity on each side, for whcich case it shoud be filled with -1
  // Query string example: ...&gross=-1,300&...
  gross: [number, number];
  net: [number, number];
  price: [number, number];
  rent: [number, number];

  //Transaction Related
  buyer: string;
  seller: string;
  transactionDate: (string | null)[];
  internal: boolean;
  // showOnlyCurrentUser: boolean;

  username: string;
}

interface CommissionReportSearchProps extends CardProps {
  onsearch?(values: Partial<TransactionSearchDTO>): any;
  initialcriteria?: Partial<TransactionSearchDTO>;
  iscommissionreportpage?: boolean; //if true, then it is the transaction page
}

const UITextField = (props: TextFieldProps & { fixedLabel?: boolean }) => {
  let { label, fixedLabel, ...rest } = props;
  fixedLabel = false;
  return <Fragment>
    {fixedLabel ? <Typography>{label}</Typography> : null}
    <TextField {...rest} label={!fixedLabel ? label : null} />
  </Fragment>
};

const InputGroup = (props: { children: React.ReactNode[], label?: string }) => {
  const { children } = props;
  const { langPropertyStock: lang } = useSelector((state: IRootState) => state.locale);
  return (
    // <Grid container xs={12} style={{ display: 'flex' }}>
    <Grid container style={{ display: 'flex' }}>
      <Grid
        item
        style={{ flex: 1 }}
      >
        {children[0]}
      </Grid>

      <Grid item style={{ width: 40, flexShrink: 1 }}>
        <TextField
          fullWidth
          InputProps={{
            style: { borderRadius: 0, borderLeft: 0, borderRight: 0 },
          }}
          inputProps={{
            style: { paddingLeft: 0, paddingRight: 0, textAlign: 'center', borderLeft: 0, borderRight: 0 },
          }}
          disabled value={lang.to}
          variant="outlined"
          margin="dense" />
      </Grid>

      <Grid
        item
        style={{ flex: 1 }}
      // md={5}
      // xs={5}
      >
        {children[1]}
      </Grid>
    </Grid>
  );
}

InputGroup.leftItem = () => ({ style: { borderTopRightRadius: 0, borderBottomRightRadius: 0 } });
InputGroup.rightItem = () => ({ style: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } });

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  patchOutlineAutocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
      paddingLeft: 8,
    }
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

const CommissionReportSearch = (props: CommissionReportSearchProps) => {
  const classes = useStyles();
  const localeBundle = useSelector((state: IRootState) => state.locale);
  const { lang: langCommon, locale, langPropertyStock: lang, transactionStatusOptions, usageOptions } = localeBundle;
  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);



  const searchForm = useForm<TransactionSearchDTO>({
    district: [],
    status: [],
    usage: [],
    level: [],
    block: '',

    gross: BLANK_RANGE,
    net: BLANK_RANGE,
    price: BLANK_RANGE,
    rent: BLANK_RANGE,

    buyer: '',
    seller: '',
    transactionDate: [null, null],
    internal: true,

    ...props.initialcriteria ?? {},
    // username: ''
  });

  const bind = searchForm.bind;

  const [buildings, setBuildings] = useState<BuildingListItemDTO[]>([]);
  const [buildingListLoading, setBuildingListLoading] = useState(false);
  const [streets, setStreets] = useState<BuildingListItemDTO[]>([]);
  const [streetListLoading, setStreetListLoading] = useState(false);

  const token: string = useSelector((state: IRootState) => state.login.token ?? '');
  const fetchBuilding = (keyword: string) => {
    if (keyword.length < 2) { return; }
    setBuildingListLoading(true);
    buildingApi.getList({ building: keyword, sort: getBuildingSortForAutoComplete(locale) }, token).then(result => {
      if (result.data?.content) {
        setBuildings(getBuilingListWithoutDuplicates(result.data!.content));
        setBuildingListLoading(false);
      }
    });
  }

  const fetchStreet = (keyword: string) => {
    if (keyword.length < 2) { return; }
    setStreetListLoading(true);
    buildingApi.getList({ street: keyword, sort: getStreetSortForAutoComplete(locale) }, token).then(result => {
      if (result.data?.content) {
        setStreets(getStreetListWithoutDuplicates(result.data!.content));
        setStreetListLoading(false);
      }
    });
  }

  // const buildingNamesWithDuplicate = buildings.map((building) => building.building);
  // const buildingNames = [...new Set(buildingNamesWithDuplicate)];
  // const buildingMap: { [name: string]: BuildingListItemDTO } = {};
  // buildings.forEach((building) => { buildingMap[building.building] = building });
  const buildingNames = buildings.map((building) => multiLang(locale, building.buildingNameZh, building.buildingNameEn));

  // const streetNamesWithDuplicate = streets.map((street) => street.street);
  // const streetNames = [...new Set(streetNamesWithDuplicate)];
  // const streetMap: { [name: string]: BuildingListItemDTO } = {};
  // streets.forEach((street) => { streetMap[street.street] = street });
  const streetNames = streets.map((street) => multiLang(locale, street.streetZh, street.streetEn));

  const [disabledCriterias] = useState<{ [k: string]: boolean }>({
    rent: true,
  });

  const filteredSearchForm = () => {
    searchForm.values = {
      ...searchForm.values,
      street: searchForm.values.street?.trim(),
      building: searchForm.values.building?.trim(),
      buyer: searchForm.values.buyer?.trim(),
      seller: searchForm.values.seller?.trim(),
    }

    const newForm: Partial<TransactionSearchDTO> = {};

    Object
      .keys(searchForm.values)
      .filter((key) => !disabledCriterias[key])
      .forEach((key) => (newForm as any)[key] = searchForm.values[key as keyof TransactionSearchDTO]);
    return newForm;
  };

  // const [ isRent, _setIsRent ] = useState(false); //false => isPrice
  // const setIsRent = (newVal: boolean) => {
  //   _setIsRent(newVal);
  //   // swap
  //   if (newVal) {
  //     searchForm.setValues({
  //       ...searchForm.values,
  //       price: BLANK_RANGE,
  //       rent: searchForm.values.price,
  //     });
  //   } else {
  //     searchForm.setValues({
  //       ...searchForm.values,
  //       rent: BLANK_RANGE,
  //       price: searchForm.values.rent,
  //     });
  //   }
  // }

  const usageSelection = () => (
    <FormControl variant="outlined" margin="dense" fullWidth>
      <InputLabel variant="outlined" style={{ background: '#fff' }}>{lang.usage}</InputLabel>
      <Select
        multiple
        {...bind('usage')}
        renderValue={(selected) => (selected as string[]).map(val => usageOptions[val]).join(', ')}
      >
        {Object.keys(usageOptions).map(key => (
          <MenuItem key={key} value={key}>
            <Checkbox checked={(searchForm.values.usage ?? []).indexOf(key) > -1} />
            <ListItemText primary={usageOptions[key]} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const statusSelection = () => (
    <FormControl variant="outlined" margin="dense" fullWidth>
      <InputLabel variant="outlined" style={{ background: '#fff' }}>{lang.status}</InputLabel>
      <Select
        multiple
        {...bind('status')}
        renderValue={(selected) => (selected as string[]).map(key => transactionStatusOptions[key]).join(', ')}
      >
        {Object.keys(transactionStatusOptions).map(status => (
          <MenuItem key={status} value={status}>
            <Checkbox checked={searchForm.values.status!.indexOf(status) > -1} />
            <ListItemText primary={transactionStatusOptions[status]} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const districtSelection = () => (
    <DistrictMultiSelect
      value={searchForm.values.district ?? []}
      onChange={(values) => searchForm.updateValues('district', values.filter(v => !!v))}
    />

    // <FormControl variant="outlined" margin="dense" fullWidth>
    //   <InputLabel variant="outlined" style={{ background: '#fff' }}>{lang.district}</InputLabel>
    //   <Select
    //     multiple
    //     // {...bind('district')}
    //     value={searchForm.values.district}
    //     onChange={(e) => searchForm.updateValues('district', (e.target.value as string[]).filter(v => !!v))}
    //     renderValue={(selected) => (selected as string[]).map(val => allDistrictOptions[val]).join(', ')}
    //   >
    //     <ListSubheader style={{ background: '#fff' }}>
    //       <div style={{ cursor: 'pointer', userSelect: 'none', fontWeight: regionAllSelected('HKI') ? 'bold' : 'normal' }} onClick={(e) => {
    //         setTimeout(() => toggleDistrictByRegion('HKI'), 0);
    //       }}>???</div>
    //     </ListSubheader>
    //     {Object.keys(districtHkiOptions).map((key) => (
    //       <MenuItem key={key} value={key}>
    //         <Checkbox checked={(searchForm.values.district ?? []).indexOf(key) > -1} />
    //         <ListItemText primary={districtHkiOptions[key]} />
    //       </MenuItem>
    //     ))}

    //     <ListSubheader style={{ background: '#fff' }}>
    //       <div style={{ cursor: 'pointer', userSelect: 'none', fontWeight: regionAllSelected('HKI') ? 'bold' : 'normal' }} onClick={(e) => {
    //         setTimeout(() => toggleDistrictByRegion('KLT'), 0);
    //       }}>??</div>
    //     </ListSubheader>
    //     {Object.keys(districtKltOptions).map((key) => (
    //       <MenuItem key={key} value={key}>
    //         <Checkbox checked={(searchForm.values.district ?? []).indexOf(key) > -1} />
    //         <ListItemText primary={districtKltOptions[key]} />
    //       </MenuItem>
    //     ))}

    //     <ListSubheader style={{ background: '#fff' }}>
    //       <div style={{ cursor: 'pointer', userSelect: 'none', fontWeight: regionAllSelected('HKI') ? 'bold' : 'normal' }} onClick={(e) => {
    //         setTimeout(() => toggleDistrictByRegion('NT'), 0);
    //       }}>??</div>
    //     </ListSubheader>
    //     {Object.keys(districtNtOptions).map((key) => (
    //       <MenuItem key={key} value={key}>
    //         <Checkbox checked={(searchForm.values.district ?? []).indexOf(key) > -1} />
    //         <ListItemText primary={districtNtOptions[key]} />
    //       </MenuItem>
    //     ))}
    //   </Select>
    // </FormControl>
  );
  const streetField = () => (
    < Autocomplete
      options={streetNames}
      noOptionsText={''}
      loading={streetListLoading}
      loadingText={langCommon.msgLoading}
      freeSolo={true}
      disableClearable
      renderInput={params => (
        <TextField
          {...params}
          inputProps={{ ...params.inputProps, maxLength: 255 }}
          className={classes.patchOutlineAutocomplete}
          // error={!!form.hasError('building')}
          // helperText={form.hasError('building')}
          fullWidth
          label={lang.street}
          margin="dense"
          variant="outlined"
          onChange={(ev) => {
            setKeyBooleanMap(keyBooleanMap.set("street", ev.target.value.length === 255))
            searchForm.modify({
              street: ev.target.value
            });
            fetchStreet(ev.target.value)
          }}
          // value={form.values.building ?? ''}
          value={searchForm.values.street ?? ''}
        />
      )}
      value={searchForm.values.street ?? ''}
      onChange={(_, val) => {
        searchForm.modify({
          street: val
        });
        // if(streetMap[val]) {
        //   const { street} = streetMap[val];
        //   searchForm.modify({
        //     street: street
        //   })
        // }
      }}
    />
  );
  const buildingField = () => (
    <Autocomplete
      options={buildingNames}
      noOptionsText={''}
      loading={buildingListLoading}
      loadingText={langCommon.msgLoading}
      freeSolo={true}
      disableClearable
      renderInput={params => (
        <TextField
          {...params}
          inputProps={{ ...params.inputProps, maxLength: 255 }}
          className={classes.patchOutlineAutocomplete}
          // error={!!form.hasError('building')}
          // helperText={form.hasError('building')}
          fullWidth
          label={lang.buildingOrVillageName}
          margin="dense"
          variant="outlined"
          onChange={(ev) => {
            setKeyBooleanMap(keyBooleanMap.set("building", ev.target.value.length === 255))
            searchForm.modify({
              building: ev.target.value
            })
            fetchBuilding(ev.target.value)
          }}
          // value={form.values.building ?? ''}
          value={searchForm.values.building ?? ''}
        />
      )}
      value={searchForm.values.building ?? ''}
      onChange={(_, val) => {
        searchForm.modify({
          building: val
        })
        // if(buildingMap[val]) {
        //   const { building} = buildingMap[val];
        //   searchForm.modify({
        //     building: building
        //   })
        // }
      }}

    />
  );
  const [keyBooleanMap, setKeyBooleanMap] = useState(initFormKeysAndBooleanMap(searchForm));

  const buyerField = () => (
    <UITextField
      label={lang.buyer}
      {...bind('buyer')}
      fullWidth
      margin="dense"
      variant="outlined"
      onChange={(e) => {
        limitTextFieldLength(e, 255, 'buyer', searchForm, keyBooleanMap, setKeyBooleanMap);
      }}
    ></UITextField>

  );

  const sellerField = () => (
    <UITextField
      label={lang.seller}
      {...bind('seller')}
      fullWidth
      margin="dense"
      variant="outlined"
      onChange={(e) => {
        limitTextFieldLength(e, 255, 'seller', searchForm, keyBooleanMap, setKeyBooleanMap);
      }}
    ></UITextField>
  );

  const transactionDateRange = () => (
    <div>
      <InputGroup>
        <KeyboardDatePicker autoOk={true}
          InputProps={InputGroup.leftItem()}
          // disableToolbar
          fullWidth
          variant="inline"
          inputVariant="outlined"
          format={DISPLAY_DATE_FORMAT}
          label={lang.transactionDate}
          margin="dense"
          // inputValue={searchForm.values.transactionDate?.[0] ?? ''}
          value={searchForm.values.transactionDate?.[0] ? moment(searchForm.values.transactionDate?.[0]) : null}
          onChange={(value) => {
            searchForm.setValues({
              ...searchForm.values,
              transactionDate: [value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? null, searchForm.values.transactionDate?.[1] ?? null]
            });
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />

        <KeyboardDatePicker autoOk={true}
          InputProps={InputGroup.rightItem()}
          // disableToolbar
          fullWidth
          variant="inline"
          inputVariant="outlined"
          format={DISPLAY_DATE_FORMAT}
          // label={lang.transactionDate}
          margin="dense"
          // inputValue={searchForm.values.transactionDate?.[1] ?? ''}
          value={searchForm.values.transactionDate?.[1] ? moment(searchForm.values.transactionDate?.[1]) : null}
          onChange={(value) => {
            searchForm.setValues({
              ...searchForm.values,
              transactionDate: [searchForm.values.transactionDate?.[0] ?? null, value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? null]
            });
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </InputGroup>
    </div>
  );

  const salesAndCommissionReportPage = () => (
    // <Grid container md={12} sm={12} xs={12} spacing={1}>
    <Grid container spacing={1}>
      <Grid item md={6} sm={12} xs={12}>
        <Card
          {...props}
          style={{ margin: 0 }}
        >
          <CardHeader title={lang.captionBasicInfo} />
          <Divider />

          <CardContent>
            <Grid container spacing={1}>
              <Grid
                item
                md={3} sm={3}
                xs={6}
              >
                {usageSelection()}
              </Grid>
              <Grid
                item
                md={3} sm={3}
                xs={6}
              >
                {statusSelection()}
              </Grid>
              <Grid
                item
                md={6} sm={6}
                xs={12}
              >
                {districtSelection()}
              </Grid>
              <Grid
                item
                md={6} sm={6}
                xs={12}
              >
                {streetField()}
                <HandleFormHelperText
                  isError={keyBooleanMap.get('street')}
                  errorMessage={lang.textOverLimit}
                />
              </Grid>
              <Grid
                item
                md={6} sm={6}
                xs={12}
              >
                {buildingField()}
                <HandleFormHelperText
                  isError={keyBooleanMap.get('building')}
                  errorMessage={lang.textOverLimit}
                />
              </Grid>
            </Grid>

          </CardContent>
        </Card>

      </Grid>
      <Grid item md={6} sm={12} xs={12}>
        <Card
          {...props}
          style={{ margin: 0 }}
        >
          <CardHeader title={lang.captionTransaction} />
          <Divider />
          <CardContent>
            <Grid container spacing={1}>

              <Grid
                item
                md={6} sm={6}
                xs={12}
              >
                {buyerField()}
                <HandleFormHelperText
                  isError={keyBooleanMap.get('buyer')}
                  errorMessage={lang.textOverLimit}
                />
              </Grid>

              <Grid
                item
                md={6} sm={6}
                xs={12}
              >
                {sellerField()}
                <HandleFormHelperText
                  isError={keyBooleanMap.get('seller')}
                  errorMessage={lang.textOverLimit}
                />
              </Grid>

              <Grid
                item
                md={12} sm={12}
                xs={12}
              >
                {transactionDateRange()}
              </Grid>

            </Grid>

          </CardContent>
        </Card>


      </Grid>

      <Grid item md={12} sm={12} xs={12}>
        <Card
          {...props}
        >
          <form
            autoComplete="off"
            noValidate
          >
            <CardContent style={{ display: 'none' }}>
              {/* <Grid
                container
                md={12}
                sm={12}
                xs={12}
                spacing={1}
              >
                <Grid item container md={3} sm={4} xs={12} spacing={1}>
                  <Grid item md={12} sm={12} xs={12}
                  >
                    <Typography style={{ fontWeight: 'bold' }}>{lang.captionBasicInfo}</Typography>
                  </Grid>

                  <Grid item md={8} sm={8} xs={12} >
                    {usageSelection()}
                  </Grid>

                  <Grid item md={4} sm={4} xs={12}
                  >
                    {statusSelection()}
                  </Grid>
                </Grid>

                <Grid item container md={9} sm={8} xs={12} spacing={1} style={{ alignContent: 'start' }}>
                  <Grid item md={12} sm={12} xs={12}
                  >
                    <Typography style={{ fontWeight: 'bold' }}>{lang.captionLocation}</Typography>
                  </Grid>

                  <Grid item md={3} sm={3} xs={12}>
                    {districtSelection()}
                  </Grid>

                  <Grid item md={3} sm={3} xs={12}>
                    {streetField()}
                  </Grid>

                  <Grid item md={3} sm={3} xs={12}>
                    {buildingField()}
                  </Grid>
                </Grid>



                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid container xs={12} spacing={1}>
                  <Grid item md={12} sm={12} xs={12}>
                    <Typography style={{ fontWeight: 'bold' }}>{lang.captionTransaction}</Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    sm={3}
                    xs={12}
                  >
                    {buyerField()}
                  </Grid>
                  <Grid item md={3} sm={3} xs={12}>
                    {sellerField()}
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    {transactionDateRange()}
                  </Grid>

                </Grid>

              </Grid> */}
            </CardContent>
          </form>
          <CardActions style={{ flexWrap: 'wrap' }}>
            {/* <Typography>{lang.captionSearchConditions}</Typography>  */}
            <div style={{ flexGrow: 1 }} />

            <Button variant="contained" color="primary" onClick={() => props.onsearch?.(filteredSearchForm())}>{lang.actionSearch}</Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );

  return salesAndCommissionReportPage();


};

export default CommissionReportSearch;