/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import { Button, colors, List, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { forwardRef, Fragment } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  subMenuItem: {
    color: '#666666',
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const SidebarNav = props => {
  const { pages, className, ...rest } = props;

  const classes = useStyles();

  return (
    <List
      {...rest}
      className={clsx(classes.root, className)}
    >
      {pages.map(page => (
        <Fragment>
          <ListItem
            className={classes.item}
            disableGutters
            key={page.title}
          >
            <Button
              // activeClassName={classes.active}
              className={classes.button}
              // component={CustomRouterLink}
              // to={page.href}
              {...page.href ? {
                activeClassName: classes.active,
                component: CustomRouterLink,
                to: page.href,
              } : {}}
            >
              <div className={classes.icon}>{page.icon}</div>
              {page.title}
            </Button>
          </ListItem>
          {page.subMenu ? page.subMenu.map((item) =>
            <ListItem
              className={classes.item}
              disableGutters
              key={item.title}
            >
              <Button
                className={clsx(classes.button, classes.subMenuItem)}
                {...item.href ? {
                  activeClassName: classes.active,
                  component: CustomRouterLink,
                  to: item.href,
                } : {}}
              >
                <div className={classes.icon} style={{ width: 30 }}>{null}</div>
                {item.title}
              </Button>
            </ListItem>
          ) : null}
        </Fragment>
      ))}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;
