import { AppBar, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IRootState } from 'reducers';

const useStyles = makeStyles(theme => ({
  root: {
    // boxShadow: 'none',
    background: '#fff',
    color: '#000',
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  navbar: {
    marginLeft: theme.spacing(3),
  },
  activeNavBtn: {
    backgroundColor: theme.palette.primary.dark,
  },
  toolbar: {
    paddingLeft: 0,
    '& *': {
      wordBreak: 'keep-all',
    }
  }
}));

interface NavigationBarProps {
  children?: React.ReactNode;
  overrideClassName?: any;
  title?: React.ReactNode;
  noBack?: boolean;
  onBack?: () => void;
}

const NavigationBar = (props: NavigationBarProps) => {
  const { children, overrideClassName, title, onBack, noBack } = props;
  const classes = useStyles({});
  const history = useHistory();
  const { iosStatusBarHeight } = useSelector((state: IRootState) => state.layout);

  return (
    <AppBar
      style={{ paddingTop: iosStatusBarHeight }}
      className={clsx(classes.root, overrideClassName)}
    >
      <Toolbar className={classes.toolbar} style={{ display: 'flex', alignItems: 'center' }}>
        {noBack ? null : <IconButton style={{ paddingRight: 16 }} color="inherit" onClick={onBack ?? (() => history.goBack())}>
          <ArrowBackIcon />
        </IconButton>}
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          {title}
        </Typography>

        <div className={classes.flexGrow} />

        {children}
      </Toolbar>
    </AppBar>
  );
};

export default NavigationBar;
