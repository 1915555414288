import { RoleDTO, RolePrivilegeMap } from "common/dto";
import { useForm } from "common/hooks";
import { convertToPrivilegeDtoList, diffUpdateSpec } from "common/utils";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { IRootState, PASDispatch } from "reducers";
import roleFormValidations from "./role-validation";
import RolePrivilege from './RolePrivilege';


export default function RolePrivilegeCreatePage() {
  const dispatch = useDispatch() as PASDispatch;
  const { langRolePrivilege } = useSelector((state: IRootState) => state.locale);

  const initialRolePrivilege = useForm<RolePrivilegeMap>({});

  //for role detail
  const roleForm = useForm<RoleDTO>({dataStatus: 'A'},{validations: roleFormValidations(langRolePrivilege)});

  //for privilege
  const rolePrivilegeForm = useForm<RolePrivilegeMap>({});

  const roleName = roleForm.values.roleName;

  useEffect(()=>{
    dispatch( { type: 'Resource.FetchRequested'});
  },[1])

  const onSave = ()=>{
    if (!roleForm.validate()){
      return;
    }
    const currentList = convertToPrivilegeDtoList(roleName, initialRolePrivilege);
    const updatedList = convertToPrivilegeDtoList(roleName, rolePrivilegeForm);
    const result = diffUpdateSpec(currentList, updatedList, (a,b) => {return a.id === b.id});
    
    dispatch({ type: 'RolePrivilege.CreationRequested', payload: {...roleForm.values, roleName: roleForm.values.roleName?.trim(), listUpdate:result } as RoleDTO });
  }


  return <RolePrivilege creating={true} roleForm={roleForm} rolePrivilegeForm={rolePrivilegeForm} onSave={onSave}/>;
}