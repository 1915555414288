import { AppBar, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import GitInfo from 'react-git-info/macro';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none'
  }
}));

const gitInfo = GitInfo();
const BLANK = {};

const Topbar = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const { iosStatusBarHeight } = useSelector((state) => state.layout);

  const { GIT, ENV } = useSelector((state) => state.systemSettings?.BuildInfo) ?? BLANK;

  return (
    <AppBar
      {...rest}
      style={{ paddingTop: iosStatusBarHeight }}
      className={clsx(classes.root, className)}
      color="primary"
      position="fixed"
    >
      <Toolbar>
        {/* <RouterLink to="/"> */}
          {/* <img
            alt="Logo"
            src="/images/logos/logo--white.svg"
          /> */}
          <Typography style={{ color: '#ffffff' }}
            data-app-commit-id={gitInfo?.commit?.hash}
            data-api-commit-id={GIT?.COMMIT_ID}
            data-api-version={GIT?.DESCRIPTION}
            data-api-profile={ENV?.PROFILE}
          >
            物業代理系統 Property Management System
          </Typography>
        {/* </RouterLink> */}
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
