import {
  Button as MuiButton, Card, CardContent, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, ListItemText, makeStyles, Menu, MenuItem, Select, Switch, TextareaAutosize, TextField, Tooltip, Typography, useMediaQuery, useTheme
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import WarningIcon from '@material-ui/icons/Warning';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clientApi from 'api/clientApi';
import userApi from 'api/userApi';
import { hasPermission } from "common/access-control";
import { useConfirmDialog } from 'common/ConfirmDialog';
import { ClientDetailDTO, UserListItemDTO } from 'common/dto';
import DistrictSingleSelect from 'common/elements/DistrictSingleSelect';
import { FormPresenter, useMenu } from 'common/hooks';
import { useInfoDialog } from 'common/InfoDialog';
import { HandleFormHelperText, tooltipHoc } from 'common/ui';
import { getInvalidContactErrMsg, initFormKeysAndBooleanMap, isNonEmpty, limitTextFieldLength, multiLang, showClientContact } from 'common/utils';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { IRootState, PASDispatch } from 'reducers';
import { ConfirmDeleteDialog } from "./ConfirmDeleteDialog";





const PUBLIC_URL = process.env['PUBLIC_URL'];

const Button = tooltipHoc(MuiButton);

const When = ({ test, children }: { test?: boolean, children: JSX.Element }): JSX.Element => test ? children : <span />;

interface ClientBasicProps {
  clientDetailForm: FormPresenter<ClientDetailDTO>;
  cid: string;
  tagForm: FormPresenter<{ [type: string]: string[] }>;
  agentContactsForm?: FormPresenter<any>;
  otherContactsForm: FormPresenter<any>;
  creating?: boolean;
  multiSelectForm: FormPresenter<{ [type: string]: string[] }>;
  setDuplicatedContact?: React.Dispatch<React.SetStateAction<boolean>>;
}


const useStyles = makeStyles(() => ({
  root: {

  },
  patchOutlineAutocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
      paddingLeft: 8,
    }
  },
  dialogContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textArea: {
    width: '90%'
  }
}));

const BLANK = {};

const ClientBasic = ({ cid, clientDetailForm, creating, tagForm, agentContactsForm, otherContactsForm, setDuplicatedContact }: ClientBasicProps) => {

  const bind = clientDetailForm.bind;
  const { langClientDetail, lang, sourceOfClientOptions, clientStatusOptions, clientTypeOptions, contactOptions, locale } = useSelector((state: IRootState) => state.locale);

  const contactMenu = useMenu();
  const dispatch = useDispatch() as PASDispatch;
  const classes = useStyles();
  const theme = useTheme();
  const otherContacts = otherContactsForm.values.otherContacts || [];
  const shouldCollapse = useMediaQuery('(max-width: 600px)');
  const shouldShowRemarksOnTheRight = useMediaQuery('(min-width: 960px)');
  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);

  const [keyBooleanMap, setKeyBooleanMap] = useState(initFormKeysAndBooleanMap(clientDetailForm));

  useEffect(() => {
    setKeyBooleanMap(initFormKeysAndBooleanMap(clientDetailForm));
  }, [Object.keys(clientDetailForm.values).length])


  //add or remove contact
  const addContact = (key: string) => () => {
    otherContactsForm.updateValues('otherContacts',
      [...otherContactsForm.values.otherContacts, { type: key, value: '', name: '', remarks: '', }]
    );
  };
  const removeContact = (i: number) => () => {
    otherContactsForm.updateValues('otherContacts', otherContactsForm.values.otherContacts.filter((_: any, _i: number) => i !== _i));
  }

  const [agents, setAgents] = useState<UserListItemDTO[]>([]);
  const [agentListLoading, setAgentListLoading] = useState(false);

  const token: string = useSelector((state: IRootState) => state.login.token ?? '');

  const fetchAgents = (keyword: string) => {
    if (keyword.length < 2) { return; }
    setAgentListLoading(true);
    userApi.getListForAutocomplete({ name: keyword, onlyHasCreateOwnClientPrivilege: true }, token).then(result => {
      if (result.data?.content) {
        setAgents(result.data!.content);
        setAgentListLoading(false);
        setAgentUsername(result.data!.content.filter(agent => agent.username != 'companyStockAgent').map((agent) => agent.username));

      }
    });
  }

  const [agentUsername, setAgentUsername] = useState(agents.filter(agent => agent.username != 'companyStockAgent').map((agent) => agent.username));
  // const agentNames = agents.map((agent) => locale === 'en' ? agent.englishName : agent.englishName);
  const agentMap: { [name: string]: UserListItemDTO } = {};
  agents.forEach((agent) => {
    agentMap[agent.username] = agent;
  })
  // const addAgentContact = (key: string) => () => {
  //   agentContactsForm?.updateValues('agentContacts', 
  //     [ ...agentContactsForm.values.agentContacts , { type: key, value: '', name: '', agentId: ''}]
  //   );
  // }

  const [keywordOfAgent, setKeywordOfAgent] = useState('')

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchAgents(keywordOfAgent);
    }, 1000);
    return () => clearTimeout(timer);
  }, [keywordOfAgent]);

  //handle confirm dialog state
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState(-1);

  const [editAgentEnabled] = useState(true);

  const clickDeleteButton = (i: number) => () => {
    // otherContactsForm.updateValues('otherContacts', otherContactsForm.values.otherContacts.filter((_: any, _i: number) => i !== _i));
    //  setSelectedContact(i);
    setSelectedContact(i);
    showConfirmDialog();

  }


  const showConfirmDialog = () => {
    setConfirmDialogOpen(true);
  };
  const closeConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };

  //validation of contact values
  const invalidContactsPhoneNum = clientDetailForm.hasError('invalidPhoneNumber');//list of index concat with '_'
  const invalidContactsEmail = clientDetailForm.hasError('invalidEmail');//list of index concat with '_'
  const emptyContactValue = clientDetailForm.hasError('emptyContactValue');//list of index concat with '_'

  const isInvalidContact = (idx: number, type: string) => {
    return getInvalidContactErrMsg(invalidContactsPhoneNum, invalidContactsEmail, emptyContactValue, idx, type, langClientDetail);
  };

  //privilege for handling the agent section
  const { privileges, uid } = useSelector((state: IRootState) => state.login);
  const clientDetailDto = useSelector((state: IRootState) => state.clients.fetchedDetails[cid!]) ?? BLANK as Partial<ClientDetailDTO>;

  const canCreateUnownedClient = hasPermission(privileges, 'CREATE', 'UNOWNED_CLIENT');
  const canCreateOwnedClient = hasPermission(privileges, 'CREATE', 'OWN_CLIENT');
  const canReadOwnedClient = hasPermission(privileges, 'READ', 'OWN_CLIENT');
  const canAssignUnownedAgentClient = hasPermission(privileges, 'UPDATE', 'ASSIGN_UNOWNED_AGENT_CLIENT');
  const canAssignTeamAgentClient = clientDetailDto.isTeamHead && hasPermission(privileges, 'UPDATE', 'ASSIGN_TEAM_AGENT_CLIENT');
  const canAssignOwnAgentClient = hasPermission(privileges, 'UPDATE', 'ASSIGN_OWN_AGENT_CLIENT');

  //privilege for company client
  const canCreateCompanyClient = hasPermission(privileges, 'CREATE', 'COMPANY_CLIENT');
  const canReadCompanyClient = hasPermission(privileges, 'READ', 'COMPANY_CLIENT');
  const canUpdateCompanyClient = hasPermission(privileges, 'UPDATE', 'COMPANY_CLIENT');

  const isOwnedClient = clientDetailDto.isLeadAgent;
  const isUnownedClient = !clientDetailDto.isLeadAgent && !clientDetailDto.isTeamHead;

  //for unlink button
  const confirmDialog = useConfirmDialog();

  //show or hide contact section
  const contactDisplayStyle = (needFlex: boolean) => {
    if (showClientContact(clientDetailDto.isCompanyClient, canReadCompanyClient, canReadOwnedClient, isOwnedClient) || creating) {
      return needFlex ? 'flex' : 'block';
    } else {
      return 'none';
    }
  }

  //show or hide the assign/reassign agent butotn
  const showAssignAgentButton = () => {
    if (creating) {
      return false;
    } else {
      const canAssign = (isOwnedClient && canAssignOwnAgentClient) ||
        (isUnownedClient && canAssignUnownedAgentClient) ||
        canAssignTeamAgentClient;
      return canAssign;
    }
  }

  //show or hide agent section
  const showAgentSection = () => {
    //always hide the section when isCompanyClient
    if (clientDetailForm.values.isCompanyClient === true) {
      return 'none';
    }
    if (creating) {
      //If the user has privilege to create unowned client & assign client agent, make the "Agent" section visible for input.
      if (canCreateUnownedClient && canAssignUnownedAgentClient) {
        return 'block';
      }
      //If the user has privilege to create own client, the system will automatically assign the user as the agent for the client
      if (canCreateOwnedClient) {
        return 'none';
      }
    } else {
      //Hide the agent info section if the current user is the agent of the client
      if (clientDetailDto.agentId?.toString() === uid?.toString()) {
        return 'none';
      }
      return 'block';
    }
    return 'none';
  }

  const canEditAgentSection = creating && canCreateUnownedClient && canAssignUnownedAgentClient;

  // Duplicated Contact Warning
  // const [duplicatedContactValues, setDuplicatedContactValues] = useState<string[]>([]);

  // const handleDuplicatedContactWarning = (value: string) => {
  //   if (!value || duplicatedContactValues.includes(value)) return;
  //   clientApi.checkDuplicatedContact(value, token).then(result => {
  //     if (!result.error && result.data) {
  //       setDuplicatedContactValues((prev) => [...new Set([...prev, value])]);
  //     }
  //   });
  // };

  // const [contactDetail, setContactDetail] = useState('');
  const [duplicatedContactValues, setDuplicatedContactValues] = useState<{ type: string, value: string }[]>([]);
  const [contactDetail, setContactDetail] = useState<{ type: string, value: string }>({ type: '', value: '' });
  useEffect(() => {

    if (!isNonEmpty(otherContactsForm) || duplicatedContactValues.some(d => d.type === contactDetail.type && d.value === contactDetail.value)) return;
    const timeout = setTimeout(() => {
      clientApi.checkDuplicatedContact(contactDetail, token ?? '').then(result => {
        if (!result.error && result.data) {
          let temp = [...duplicatedContactValues];
          temp.push(contactDetail);
          setDuplicatedContactValues(temp);
        }
      }); clearTimeout(timeout);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [contactDetail])

  useEffect(() => {
    //check has duplicated contact or not
    let duplicatedContactResult: boolean[] = []
    otherContacts.forEach((contact: { type: string, value: string, name?: string, remarks?: string }) => {
      duplicatedContactResult.push(duplicatedContactValues.some(d => d.type === contact.type && d.value === contact.value));
    });
    if (setDuplicatedContact) {
      if (duplicatedContactResult.includes(true)) {
        setDuplicatedContact(true);
      } else {
        setDuplicatedContact(false);
      }
    }

  }, [duplicatedContactValues, otherContacts])


  const alertDialog = useInfoDialog();

  return <div>
    <Grid
      container
    // md={12}
    // sm={12}
    // xs={12}
    >
      {confirmDialog.render()}
      {alertDialog.render()}

    </Grid>
    {/* <Grid
        item
        md={6}
        xs={12}
      >
        <TextField
          {...bind('tel')}
          fullWidth
          label="電話"
          margin="dense"
          required
          variant="outlined"
        />
      </Grid>

      <Grid
        item
        md={6}
        xs={12}
      >
        <TextField
          {...bind('email')}
          fullWidth
          label="電郵地址"
          margin="dense"
          required
          variant="outlined"
        />
      </Grid> */}
    <Grid
      container
      spacing={1}
    >
      {/* <Grid item md={12} sm={12} xs={12} spacing={1}></Grid> */}
      <Grid item md={12} sm={12} xs={12} ></Grid>
    </Grid>

    <Card><CardContent>
      <Grid
        container
        spacing={1}
      >

        {/* ----------- chineseName ------------- */}
        <Grid
          item
          md={4}
          sm={4}
          xs={12}
        >
          <TextField
            {...bind('chineseName')}
            fullWidth
            label={langClientDetail.clientName}
            margin="dense"
            variant="outlined"
            required
            onChange={(e) => {
              limitTextFieldLength(e, 255, 'chineseName', clientDetailForm, keyBooleanMap, setKeyBooleanMap);
            }}
          />
          <HandleFormHelperText
            isError={keyBooleanMap.get('chineseName')}
            errorMessage={lang.textOverLimit}
          />
          {/* <FormHelperText style={{color: clientDetailForm.hasError('chineseName') || clientDetailForm.hasError('englishName')  ?'red' : 'inherit'}}>{langClientDetail.msgPleaseEnterChinOrEngName}</FormHelperText>  */}
        </Grid>

        {/* ----------- englishName ------------- */}
        {/* <Grid
        item
        md={4}
        sm={6}
        xs={12}
      >
        <TextField
          {...bind('englishName')}
          fullWidth
          label={langClientDetail.englishName}
          margin="dense"
          variant="outlined"
          helperText=''
          required
        />
      </Grid> */}

        {/* ----------- clientStatus ------------- */}
        <Grid item md={2} sm={2} xs={12}>
          <TextField
            select
            fullWidth
            margin="dense"
            variant="outlined"
            label={langClientDetail.clientStatus}
            {...bind('clientStatus')}
          >
            {Object.keys(clientStatusOptions ?? {}).map(key => (
              <MenuItem key={key} value={key}>{clientStatusOptions[key]}</MenuItem>
            ))}
          </TextField>
        </Grid>
        {/* ----------- cid ------------- */}
        <When test={!creating}>
          <Grid
            item
            md={2}
            sm={2}
            xs={12}
          >
            <TextField
              InputProps={{ readOnly: true }}
              fullWidth
              disabled
              label={langClientDetail.clientNo}
              margin="dense"
              variant="outlined"
              value={cid}
            />
          </Grid>
        </When>
        {/* ----------- clientType ------------- */}
        <Grid
          item
          md={2}
          sm={2}
          xs={12}
          data-key={"field-clientdetails-missingClientType"}
        >
          <FormControl error={!!clientDetailForm.hasError('missingClientType')} variant="outlined" margin="dense" fullWidth>
            <InputLabel required variant="outlined" style={{ background: '#fff' }}>{langClientDetail.clientType}</InputLabel>
            <Select
              multiple
              // 
              {...tagForm.bind('clientType')}
              renderValue={(selected) => (selected as string[]).map(key => clientTypeOptions[key]).join(', ')}
            >
              {Object.keys(clientTypeOptions).map(key => (
                <MenuItem key={key} value={key}>
                  <Checkbox checked={tagForm.values.clientType!.indexOf(key) > -1} />
                  <ListItemText primary={clientTypeOptions[key]} />
                </MenuItem>
              ))}
            </Select>
            {clientDetailForm.hasError('missingClientType') ? <FormHelperText error>{clientDetailForm.hasError('missingClientType')}</FormHelperText> : null}
          </FormControl>

        </Grid>
        {/* ----------- sourceOfClient ------------- */}
        <Grid
          item
          md={2}
          sm={2}
          xs={12}
        >
          <TextField
            fullWidth
            label={langClientDetail.sourceOfClient}
            margin="dense"
            // required
            select
            // required
            variant="outlined"
            {...bind('source')}
          >
            {
              Object.keys(sourceOfClientOptions).map((key) =>
                <MenuItem key={key} value={key}>{sourceOfClientOptions[key]}</MenuItem>)
            }

          </TextField>
        </Grid>
        {/* ----------- address toggle !shouldCollapse------------- */}
        {(!shouldCollapse) ?
          <Grid
            item
            md={2}
            sm={2}
            xs={12}
          >
            <FormGroup row>
              <FormControlLabel
                label={langClientDetail.adderss}
                labelPlacement="start"
                control={
                  <Switch
                    checked={clientDetailForm.values.hasAddress ?? false}
                    onChange={() => { clientDetailForm.updateValues('hasAddress', !clientDetailForm.values.hasAddress) }}
                    name="addressToggle"
                    color="primary"

                  />
                }
              />
            </FormGroup>
          </Grid>
          : null}
        {/* ----------- company client ------------- */}
        {(creating && canCreateCompanyClient) || (!creating && canUpdateCompanyClient) || (!creating && canReadCompanyClient) ?
          <Grid
            item
            md={2}
            sm={2}
            xs={12}
          >
            <FormControl component="fieldset" >
              <FormGroup aria-label="is-company-client-checkbox" row>
                <FormControlLabel
                  control={<Checkbox color="primary" />}
                  label={langClientDetail.companyClient}
                  labelPlacement="end"
                  checked={clientDetailForm.values.isCompanyClient ?? false}
                  onChange={() => {
                    if ((creating && canCreateCompanyClient) || (!creating && canUpdateCompanyClient)) {
                      //reset agent section
                      agentContactsForm?.setValues({
                        ...agentContactsForm?.values,
                        agentId: undefined,
                        agentNameEn: undefined,
                        agentNameZh: undefined,
                        agentContact: {
                          ...agentContactsForm?.values.agentContact,
                          agentId: undefined,
                          type: undefined,
                          value: undefined
                        }
                      });
                      clientDetailForm.setValues({
                        ...clientDetailForm.values,
                        agentId: undefined,
                        agentNameEn: undefined,
                        agentNameZh: undefined,
                        agentContact: undefined,
                      });
                      //update checkbox value
                      clientDetailForm.updateValues('isCompanyClient', !clientDetailForm.values.isCompanyClient);
                    }
                  }}
                />
              </FormGroup>
            </FormControl>

          </Grid>
          : null}

        {/* ----------- dateModified ------------- */}
        <When test={!creating}>
          <Grid
            item
            md={2}
            sm={2}
            xs={12}
          >
            <TextField
              InputProps={{ readOnly: true }}
              value={moment(clientDetailForm.values.dateModified).format(DISPLAY_DATE_FORMAT)}
              fullWidth
              disabled
              label={lang.dateModified}
              margin="dense"
              variant="outlined"
            />
          </Grid>
        </When>
        {/* ----------- modifiedBy ------------- */}
        <When test={!creating}>
          <Grid
            item
            md={2}
            sm={2}
            xs={12}
          >
            <TextField
              InputProps={{ readOnly: true }}
              {...bind('modifiedBy')}
              fullWidth
              disabled
              label={lang.modifiedBy}
              margin="dense"
              variant="outlined"
            />
          </Grid>
        </When>
        {/* ----------- dateCreated ------------- */}
        <When test={!creating}>
          <Grid
            item
            md={2}
            sm={2}
            xs={12}
          >
            <TextField
              InputProps={{ readOnly: true }}
              value={moment(clientDetailForm.values.dateCreated).format(DISPLAY_DATE_FORMAT)}
              fullWidth
              disabled
              label={lang.dateCreated}
              margin="dense"
              variant="outlined"
            />
          </Grid>
        </When>
        {/* ----------- createdBy ------------- */}
        <When test={!creating}>
          <Grid
            item
            md={2}
            sm={2}
            xs={12}
          >
            <TextField
              InputProps={{ readOnly: true }}
              {...bind('createdBy')}
              fullWidth
              disabled
              label={lang.createdBy}
              margin="dense"
              variant="outlined"
            />
          </Grid>
        </When>
        {/* ----------- adderss caption ------------- */}
        {/* <Grid
          item
          md={12}
          sm={12}
          xs={12}
        >
          <Typography>{langClientDetail.adderss}</Typography>
        </Grid> */}

        {/* ----------- address toggle shouldCollapse------------- */}
        {(shouldCollapse) ?
          <Grid
            item
            md={2}
            sm={2}
            xs={12}
          >
            <FormGroup row>
              <FormControlLabel
                label={langClientDetail.adderss}
                labelPlacement="start"
                control={
                  <Switch
                    checked={clientDetailForm.values.hasAddress ?? false}
                    onChange={() => { clientDetailForm.updateValues('hasAddress', !clientDetailForm.values.hasAddress) }}
                    name="addressToggle"
                    color="primary"

                  />
                }
              />
            </FormGroup>
          </Grid>
          : null}

        { /* ----------- address block ------------- */}
        <Grid
          item
          container
          spacing={1}
          style={{ display: clientDetailForm.values.hasAddress ? 'flex' : 'none' }}
        >
          { /* ----------- address1 ------------- */}
          <Grid item md={4} sm={4} xs={12}>

            <TextField
              {...bind('address1')}
              fullWidth
              label={langClientDetail.address1}
              margin="dense"
              variant="outlined"
              onChange={(e) => {
                limitTextFieldLength(e, 255, 'address1', clientDetailForm, keyBooleanMap, setKeyBooleanMap);
              }}
            />
            <HandleFormHelperText
              isError={keyBooleanMap.get('address1')}
              errorMessage={lang.textOverLimit}
            />
          </Grid>
          { /* ----------- address2 ------------- */}
          <Grid item md={8} sm={8} xs={12}>

            <TextField
              {...bind('address2')}
              fullWidth
              label={langClientDetail.address2}
              margin="dense"
              variant="outlined"
              onChange={(e) => {
                limitTextFieldLength(e, 255, 'address2', clientDetailForm, keyBooleanMap, setKeyBooleanMap);
              }}
            />
            <HandleFormHelperText
              isError={keyBooleanMap.get('address2')}
              errorMessage={lang.textOverLimit}
            />
          </Grid>
          { /* ----------- address3 ------------- */}
          <Grid item md={8} sm={8} xs={12}>

            <TextField
              {...bind('address3')}
              fullWidth
              label={langClientDetail.address3}
              margin="dense"
              variant="outlined"
              onChange={(e) => {
                limitTextFieldLength(e, 255, 'address3', clientDetailForm, keyBooleanMap, setKeyBooleanMap);
              }}
            />
            <HandleFormHelperText
              isError={keyBooleanMap.get('address3')}
              errorMessage={lang.textOverLimit}
            />
          </Grid>
          { /* ----------- address4 ------------- */}
          <Grid item md={4} sm={4} xs={12} >

            <DistrictSingleSelect
              {...bind('address4')}
              fullWidth
              label={langClientDetail.address4}
              margin="dense"
              select
              variant="outlined"
              districtValue={clientDetailForm.values.address4 ?? ''}
              onToggleChip={(value) => clientDetailForm.updateValues('address4', value)}
            />

          </Grid>
        </Grid>


        <ConfirmDeleteDialog open={confirmDialogOpen}
          handleClose={closeConfirmDialog}
          handleClickOpen={showConfirmDialog}
          selectedContact={selectedContact}
          removeContact={removeContact}
          otherContactsForm={otherContactsForm} />

        <Grid
          container
          // md={12}
          // sm={12}
          // xs={12}
          spacing={1}
        >
          { /* ----------- otherContacts section------------- */}
          <Grid
            item container spacing={0}
            md={shouldShowRemarksOnTheRight ? 6 : 12}
            sm={12}
            xs={12}
            style={{ paddingLeft: '10px', alignContent: 'flex-start' }}
          >

            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              style={{ display: contactDisplayStyle(false) }}
            >
              <Typography color={!!clientDetailForm.hasError('otherContacts') ? 'error' : 'inherit'}>
                {langClientDetail.contactType + '*'}
                {/* <Button color="primary" variant="outlined" startIcon={<AddIcon />}>新增</Button> */}
                <Tooltip title={lang.actionAdd}>
                  <IconButton {...contactMenu.buttonProps()} color="primary">
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Typography>

              {!!clientDetailForm.hasError('otherContacts') ?
                <FormHelperText error={!!clientDetailForm.hasError('otherContacts')}>{langClientDetail.msgAtLeastOneContact}</FormHelperText>
                : null}

              <Menu {...contactMenu.menuProps()}>
                {Object.keys(contactOptions).map((key, idx) => {
                  return <MenuItem key={idx} onClick={addContact(key)}>{contactOptions[key]}</MenuItem>
                })}
              </Menu>

            </Grid>
            { /* ----------- otherContacts detail ------------- */}


            {otherContacts.map((contact: { type: string, value: string, name?: string, remarks?: string }, i: number) => {

              return (

                <Grid
                  key={i}
                  item
                  container
                  md={12}
                  style={{ display: contactDisplayStyle(true) }}
                >

                  <Grid item container md={3} sm={3} xs={12} spacing={1}>
                    { /* ----------- delete button contact.type  ------------- */}
                    <Grid
                      item
                      md={3}
                      sm={3}
                      xs={12}
                    >
                      <Typography style={{ display: 'flex', alignItems: 'center', flexGrow: 2, width: 200 }}>
                        <IconButton key={i} onClick={clickDeleteButton(i)}>
                          <DeleteIcon />
                        </IconButton>
                        {contactOptions[contact.type]}{' : '}
                      </Typography>

                    </Grid>
                  </Grid>

                  <Grid item container md={9} sm={9} xs={12} spacing={1}>
                    { /* ----------- contact.value ------------- */}
                    <Grid
                      item
                      md={6}
                      sm={6}
                      xs={12}
                    >
                      <TextField
                        style={{}}
                        value={contact.value}
                        // error={true}
                        error={!!isInvalidContact(i, contact.type)}
                        helperText={isInvalidContact(i, contact.type)}
                        label={langClientDetail.contactNoOrEmail}
                        onChange={(e) => {
                          if (keyBooleanMap && setKeyBooleanMap) {
                            setKeyBooleanMap(keyBooleanMap.set('otherContacts', e.target.value.length > 255))
                          }
                          if (e.target.value.length > 255) {
                            e.preventDefault();
                            const updatedContact = { ...contact, value: e.target.value.substring(0, 255) };
                            setContactDetail({ type: contact.type, value: e.target.value.substring(0, 255) });
                            //update form
                            otherContactsForm.updateValues('otherContacts',
                              otherContactsForm.values.otherContacts?.map((c: Object, idx: number) => idx === i ? updatedContact : c)
                            );
                          } else {
                            const updatedContact = { ...contact, value: e.target.value };
                            // setContactDetail(e.target.value);
                            setContactDetail({ type: contact.type, value: e.target.value });
                            //update form
                            otherContactsForm.updateValues('otherContacts',
                              otherContactsForm.values.otherContacts?.map((c: Object, idx: number) => idx === i ? updatedContact : c)
                            );
                          }
                        }}
                        // onBlur={(e) => handleDuplicatedContactWarning(e.target.value)}
                        InputProps={{
                          endAdornment: duplicatedContactValues.some(d => d.type === contact.type && d.value === contact.value) ? <InputAdornment position="end">
                            <IconButton onClick={() => {
                              alertDialog.confirm(langClientDetail.msgDuplicatedContact, lang.actionConfirm, lang.actionConfirm);
                            }}>
                              <WarningIcon color='error' />
                            </IconButton>
                          </InputAdornment> : null,
                        }}
                        fullWidth
                        margin="dense"
                        required
                        variant="outlined"
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('otherContacts')}
                        errorMessage={lang.textOverLimit}
                      />
                    </Grid>

                    { /* ----------- contact.remarks ------------- */}
                    <Grid
                      item
                      md={6}
                      sm={6}
                      xs={12}
                    >
                      <TextField
                        style={{}}
                        value={contact.remarks}
                        label={langClientDetail.contactRemarks}
                        onChange={(e) => {
                          if (keyBooleanMap && setKeyBooleanMap) {
                            setKeyBooleanMap(keyBooleanMap.set('contactRemarks', e.target.value.length > 255))
                          }
                          if (e.target.value.length > 255) {
                            e.preventDefault();
                            const updatedContact = { ...contact, remarks: e.target.value.substring(0, 255) };
                            //update form
                            otherContactsForm.updateValues('otherContacts',
                              otherContactsForm.values.otherContacts?.map((c: Object, idx: number) => idx === i ? updatedContact : c)
                            );
                          } else {
                            const updatedContact = { ...contact, remarks: e.target.value };
                            //update form
                            otherContactsForm.updateValues('otherContacts',
                              otherContactsForm.values.otherContacts?.map((c: Object, idx: number) => idx === i ? updatedContact : c)
                            );
                          }
                        }}
                        fullWidth
                        margin="dense"
                        variant="outlined"
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('contactRemarks')}
                        errorMessage={lang.textOverLimit}
                      />
                    </Grid>
                  </Grid>
                </Grid>

              )
            })}
          </Grid>
          { /* ----------- end otherContacts ------------- */}


          { /* ----------- remarks caption ------------- */}
          <Grid
            item container spacing={0}
            md={shouldShowRemarksOnTheRight && (contactDisplayStyle(false) !== 'none') ? 6 : 12}
            sm={12}
            xs={12}
            justify="flex-start"
            style={{ padding: '10px' }}
          >

            { /* ----------- remarks ------------- */}
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
            >
              <Typography style={{ paddingBottom: '15px' }}>{langClientDetail.remarks}</Typography>
              <TextareaAutosize
                style={{ width: '100%', margin: 'dense', padding: '8px', fontSize: '14px' }}
                rowsMin={4}
                rowsMax={8}
                placeholder={langClientDetail.msgPleaseEnterRemarksHere}
                {...bind('remarks')}
                onChange={(e) => {
                  limitTextFieldLength(e, 30000, 'remarks', clientDetailForm, keyBooleanMap, setKeyBooleanMap);
                }}
              />
              <HandleFormHelperText
                isError={keyBooleanMap.get('remarks')}
                errorMessage={lang.textOverLimit}
              />
              {tagForm.values['INTERESTED_PROPERTY_NO']?.length ? <div>
                {langClientDetail.captionInterestedProperties} &nbsp;
                {tagForm.values['INTERESTED_PROPERTY_NO']?.map((pNo, i) => <span key={pNo}>
                  <Link to={`/properties/${pNo}`}>{pNo}</Link>
                  {i !== tagForm.values['INTERESTED_PROPERTY_NO']!.length - 1 ? ', ' : ''}
                </span>)}
              </div> : null}
            </Grid>
          </Grid>
        </Grid>
        { /* ----------- agent section ------------- */}
        <Grid
          container
          // md={12}
          // sm={12}
          // xs={12}
          spacing={1}
          style={{ paddingTop: theme.spacing(2), display: showAgentSection() }}
        >

          <Grid item md={1} sm={1} xs={12} style={{ display: 'inline-block' }}>
            <Typography style={{ paddingTop: theme.spacing(1) }}>
              {langClientDetail.agent}
            </Typography>

          </Grid>

          {/* {showAssignAgentButton() ?
          <Grid item md={3} sm={3} xs={12} style={{ display: 'inline-block' }}>
            <AssignAgentDialog cid={cid} originalAgentId={clientDetailForm.values.agentId} />
          </Grid>
          : null
        } */}

          {/* {!creating && isOwnedClient ?
          <Grid item md={4} sm={4} xs={12} style={{ display: 'inline-block' }}>
            <Button size="small" variant="outlined" color="primary" onClick={()=>{
              confirmDialog.confirm(langClientDetail.msgConfirmRemoveAgent, lang.actionYes, lang.actionNo).then((confirmed: boolean) => {
                if (confirmed) {
                  dispatch({type: 'Client.RemoveAgentRequested', payload: { cid: cid ?? '' }});
                }
              });
            }}>
              {langClientDetail.actionRemoveAgent}
            </Button>
          </Grid>
          : null
        } */}


          {/* <Grid item md={2} xs={6}> */}
          {/* <Button color="primary" variant="contained" style={{ paddingBottom: theme.spacing(1) }} onClick={() => setEditAgentEnable(true)}>編輯</Button> */}
          {/* </Grid> */}

          {/* <FormHelperText error={!!clientDetailForm.hasError('otherContacts')}>{langClientDetail.msgAtLeastOneContact}</FormHelperText> */}

        </Grid>

        {/* <Grid container md={12} sm={12} xs={12} spacing={1} > */}
        <Grid container spacing={1} >
          <Grid item md={4} sm={4} xs={12} style={{ display: showAgentSection() }}>
            {canEditAgentSection ?
              <Autocomplete
                disabled={!editAgentEnabled}
                options={agentUsername}
                noOptionsText={''}
                popupIcon={''}
                loading={agentListLoading}
                filterOptions={(option) => option}
                loadingText={lang.msgLoading}
                renderOption={(option) => <div>
                  <div><Typography >{`${agents.filter(agent => agent.username === option)?.[0]?.englishName ?? ''}${agents.filter(agent => agent.username === option)?.[0]?.chineseName ? '/' : ''}${agents.filter(agent => agent.username === option)?.[0]?.chineseName ?? ''}`}</Typography></div>
                  <div><Typography variant="caption">{agents.filter(agent => agent.username === option)?.[0]?.mainContact}</Typography></div>
                </div>}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    className={classes.patchOutlineAutocomplete}
                    // error={!!form.hasError('building')}
                    // helperText={form.hasError('building')}
                    fullWidth
                    label={langClientDetail.contactName}
                    margin="dense"
                    variant="outlined"
                    onFocus={(ev) => {
                      setAgentUsername([]);
                      setKeywordOfAgent(ev.target.value);
                    }}
                    onChange={(ev) => {
                      // fetchAgents(ev.target.value)
                      setAgents([]);
                      setAgentUsername([]);
                      setKeywordOfAgent(ev.target.value);
                      setAgentListLoading(true);
                      if (ev.target.value.length < 2 || !isNonEmpty(ev.target.value)) {
                        setAgentListLoading(false);
                      }
                    }}
                  // value={form.values.building ?? ''}
                  // {...bind('building')}
                  />
                )}
                // {...bind('building')}
                value={multiLang(locale, agentContactsForm?.values.agentNameZh, agentContactsForm?.values.agentNameEn)}
                // inputValue={form.values.building ?? ''}
                onChange={(_: any, val: any) => {
                  if (agentMap[val]) {
                    const { id, chineseName, englishName, mainContactType, mainContact } = agentMap[val];
                    agentContactsForm?.setValues({
                      ...agentContactsForm?.values,
                      agentId: id,
                      agentNameEn: englishName,
                      agentNameZh: chineseName,
                      agentContact: {
                        ...agentContactsForm?.values.agentContact,
                        type: mainContactType,
                        value: mainContact
                      }
                    })

                  } else {
                    agentContactsForm?.setValues({
                      ...agentContactsForm?.values,
                      agentId: undefined,
                      agentNameEn: undefined,
                      agentNameZh: undefined,
                      agentContact: {
                        ...agentContactsForm?.values.agentContact,
                        agentId: undefined,
                        type: undefined,
                        value: undefined
                      }
                    });
                  }

                }}
                getOptionLabel={() => multiLang(locale, agentContactsForm?.values.agentNameZh, agentContactsForm?.values.agentNameEn) ?? ''}
              /> :
              <TextField
                style={{}}
                disabled={!editAgentEnabled}
                value={clientDetailForm.values.isCompanyClient === true ? '' :
                  multiLang(locale, clientDetailForm.values.agentNameZh, clientDetailForm.values.agentNameEn)}
                label={langClientDetail.contactName}
                InputProps={{ disabled: true }}
                InputLabelProps={{ shrink: true }}
                fullWidth
                margin="dense"
                variant="outlined"
              />
            }
          </Grid>

          <Grid
            item
            md={4}
            sm={4}
            xs={12}
            style={{ display: showAgentSection() }}
          >
            <TextField
              style={{}}
              disabled={!editAgentEnabled}
              value={clientDetailForm.values.isCompanyClient === true ? '' : contactOptions[agentContactsForm?.values.agentContact?.type]}
              label={langClientDetail.contactType}
              InputProps={{ disabled: true }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              margin="dense"
              variant="outlined"
            />
            {/* <Typography style={{ display: 'flex', alignItems: 'center', flexGrow: 2, width: 200 }}>
            {agentContactsForm?.values.agentContact?.type}
          </Typography> */}

          </Grid>

          <Grid item md={4} sm={4} xs={12} style={{ display: showAgentSection() }}>
            <TextField
              style={{}}
              disabled={!editAgentEnabled}
              value={clientDetailForm.values.isCompanyClient === true ? '' : agentContactsForm?.values.agentContact?.value}
              label={langClientDetail.contactNoOrEmail}
              InputProps={{ disabled: true }}
              InputLabelProps={{ shrink: true }}
              // onChange={(e) => {
              //   const updatedContact = {...contact, value: e.target.value};
              //   //update form
              //   agentContactsForm.updateValues('agentContacts',
              //     agentContactsForm.values.agentContacts?.map((c:Object, idx: number) => idx === i? updatedContact : c) 
              //   );
              //}}
              fullWidth
              margin="dense"
              variant="outlined"
            />
          </Grid>
        </Grid>
        {/* <Grid container md={7} xs={12}> */}
        {/* </Grid> */}
        {/* {agentContactsForm?.values.agentContacts?.map((contact: { id?: string, agentId: string, type: string, value: string}, i: number) => {
        return (
        )
      })} */}

        <Grid
          item
          md={3}
          sm={3}
          xs={12}
        >
          {/*         
        <Typography>其它聯繫方式</Typography>
        <FormControl variant="outlined" margin="dense" fullWidth>
          <Select
            multiple
            //{...tagForm.bind('otherContacts')}
            renderValue={(selected) => (selected as string[]).join(', ')}
          >
            {otherFeatureOptions.map(feature => (
              <MenuItem key={feature} value={feature}>
                <Checkbox checked={tagForm?.values.otherContacts!.indexOf(feature) > -1} />
                <ListItemText primary={feature} />
              </MenuItem>
            ))}
          </Select>
          
        </FormControl>
         */}

          {/* <TextField select
          fullWidth
          margin="dense"
          name="features"
          onChange={handleChange}
          required
          value={values.features}
          variant="outlined">
          {
            otherFeatureOptions.map((opt) => <MenuItem value={opt}>{opt}</MenuItem>)
          }
        </TextField> */}
        </Grid>
        {/* <Grid
        item container
        md={2}
        xs={12}
        alignItems="center"
      > */}
        {/* <Button color="default" variant="contained">刪除</Button> */}

        {/* </Grid> */}

        {/* <Grid
        item
        md={12}
        xs={12}
      >
        <Button color="primary" variant="outlined">新增</Button>
      </Grid> */}
      </Grid>
    </CardContent></Card></div>;
};

export default ClientBasic;