import { MasterData } from "./MasterData";
import { LocaleActions, LoginActions, MasterDataActions } from "./reducers";
// Action Creators
// Should not put into takeLatest/takeEvery

export function switchLocale(locale: 'zh_HK' | 'en'): LocaleActions {
    return { type: 'Locale.SwitchLocale', locale };
}

export function loginStart(data: any): LoginActions {
    return { type: 'Login.Start', payload: data };
}

export function loginSuccess(data: any): LoginActions {
    return { type: 'Login.Success', payload: data };
}

export function requestMasterDataStart(): MasterDataActions {
    return { type: 'MasterDataRequest.Start' };
}

export function requestMasterDataSuccess(masterData: MasterData[]): MasterDataActions {
    return { type: 'MasterDataRequest.Success', payload: { masterData }};
}

export function requestMasterDataFailed(error: String): MasterDataActions {
    return { type: 'MasterDataRequest.Failed', payload: { error }};
}
