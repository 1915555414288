import { Avatar, Button, ButtonGroup, Card, CardContent, Checkbox, FormControl, FormHelperText, Grid as MuiGrid, InputLabel, ListItemText, makeStyles, MenuItem, Popover, Select, Switch, TextField, TextFieldProps, Typography, useTheme, Tooltip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { fileApi } from 'api/fileApi';
import userApi from 'api/userApi';
import { TeamDTO, UserDTO } from 'common/dto';
import DistrictMultiSelect from 'common/elements/DistrictMultiSelect';
import { FormPresenter } from 'common/hooks';
import { HandleFormHelperText, hideWhenHoc } from 'common/ui';
import { initFormKeysAndBooleanMap, isNonEmpty, limitTextFieldLength, resetFileInput } from 'common/utils';
import { extname } from 'path';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState, PASDispatch } from 'reducers';
import theme from 'theme';
import { format } from 'util';
import ChangePasswordDialog from './ChangePasswordDialog';
const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];
// #region Base UI

const Grid = hideWhenHoc(MuiGrid);

const useStyles = makeStyles(() => ({
  root: {},
  patchOutlineAutocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
      paddingLeft: 8,
    }
  },
  changePasswordDialogBody: {
    padding: 8,
  },
  photo: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    margin: 'auto',
    cursor: 'pointer',
    border: '1px solid #a8acb3',
    // width: '100%',
    // height: '100%',
  },
  photoPopover: {
    padding: theme.spacing(1),
  },
}));

// #endregion Base UI


export interface UserBasicProps {
  form: FormPresenter<UserDTO>;
  creating: boolean;
  profileDisabled?: boolean;
  infoDisabled?: boolean;
  passwordChangeDialogOpen: boolean;
  setPasswordChangeDialogOpen: Dispatch<SetStateAction<boolean>>;
}

const UserBasic = ({ form, creating, profileDisabled, infoDisabled, passwordChangeDialogOpen, setPasswordChangeDialogOpen }: UserBasicProps) => {
  const { langUser, lang, roleOptions, statusOptions, userSalutationOptions } = useSelector((state: IRootState) => state.locale);
  const { token } = useSelector((state: IRootState) => state.login);
  const languageOptions = useSelector((state: IRootState) => state.systemSettings?.Language?.LANGUAGE_OPTION ?? {});
  const dispatch = useDispatch() as PASDispatch;

  const { PASSWORD_HISTORY } = useSelector((state: IRootState) =>
    state.systemSettings?.System?.PASSWORD) ?? {};

  const bind = form.bind;
  const classes = useStyles();
  const theme = useTheme();
  const uid = String(form.values.id);
  const [keyBooleanMap, setKeyBooleanMap] = useState(initFormKeysAndBooleanMap(form));

  const isNotAgent = (form.values.roles?.filter(role => role === 'AGENT').length ?? 0) === 0;
  const actAsTeamHead = form.values.actAsTeamHead ?? false;
  const teamMembersCount = (form.values.teamMembers ?? []).length > 0; 

  // Auto fill team head
  const [teams, setTeams] = useState<{ [uid: string]: TeamDTO }>({});
  const [teamLoading, setTeamLoading] = useState(false);
  const loadTeams = async (kw: string) => {
    setTeamLoading(true);
    const { data, error } = await userApi.getTeamList({ kw }, '');
    if (!error && data) {
      const teamMap: { [key: string]: TeamDTO } = {};
      data.filter(team => String(team.uid) !== uid).forEach(team => {
        teamMap[String(team.uid)] = team;
      });
      setTeams(teamMap);
    }
    setTeamLoading(false);
  };
  useEffect(() => {
    loadTeams('');
  }, [uid]); // eslint-disable-line react-hooks/exhaustive-deps

  // Info Text Fields
  const infoField = (name: keyof UserDTO, props: TextFieldProps = {}) => <TextField
    {...bind(name)}
    label={langUser[name]}
    fullWidth
    margin="dense"
    variant="outlined"
    required={name === 'chineseName' || name === 'englishName' || (name === 'licenseNumber' && !isNotAgent) || (name === 'teamName' && actAsTeamHead)}
    disabled={infoDisabled}
    {...props as any}
    onChange={(e) => {
      limitTextFieldLength(e, 255, name, form, keyBooleanMap, setKeyBooleanMap);
    }}
  />;

  useEffect(() => {
    setKeyBooleanMap(initFormKeysAndBooleanMap(form));
  }, [Object.keys(form.values).length])
  //console.log("keyBooleanMap", keyBooleanMap);
  // Profile Text Fields
  const profileField = (name: keyof UserDTO, props: TextFieldProps = {}) => <TextField
    {...bind(name)}
    label={langUser[name]}
    fullWidth
    margin="dense"
    variant="outlined"
    disabled={profileDisabled && infoDisabled}
    {...props as any}
  />;

  // Role Settings
  const roleSelection = () => <FormControl variant="outlined" margin="dense" fullWidth>
    <InputLabel required variant="outlined" style={{ background: '#fff' }}>{langUser.roles}</InputLabel>
    <Select
      multiple
      disabled={infoDisabled}
      {...bind('roles')}
      renderValue={(selected) => (selected as string[]).map(key => roleOptions[key]).join(', ')}
    >
      {Object.keys(roleOptions).map(role => (
        <MenuItem key={role} value={role}>
          <Checkbox checked={(form.values.roles?.indexOf(role) ?? -1) > -1} />
          <ListItemText primary={roleOptions[role]} />
        </MenuItem>
      ))}
    </Select>
    {form.hasError('roles') ? <FormHelperText error>{form.hasError('roles')}</FormHelperText> : null}
  </FormControl>;

  // Status
  const statusSelection = () => <FormControl variant="outlined" margin="dense" fullWidth>
    <InputLabel variant="outlined" style={{ background: '#fff' }}>{langUser.status}</InputLabel>
    <Select
      {...bind('status')}
      disabled={infoDisabled}
      required
      error={!!form.hasError('status')}
    // renderValue={(selected) => (selected as string[]).map(val => statusOptions[val])}
    // renderValue={(selected) => (selected as string[]).map(key => roleOptions[key]).join(', ')}
    >
      {Object.keys(statusOptions).map(status => (
        <MenuItem key={status} value={status}>
          {statusOptions[status]}
          {/* <Checkbox checked={(form.values.roles?.indexOf(role) ?? -1) > -1} /> */}
          {/* <ListItemText primary={statusOptions[status]} /> */}
        </MenuItem>
      ))}
    </Select>
    {form.hasError('status') ? <FormHelperText error>{form.hasError('status')}</FormHelperText> : null}
  </FormControl>;

  const districtSelection = () => <DistrictMultiSelect
    disabled={infoDisabled}
    value={form.values.features?.filter(f => f.type === 'DISTRICT').map(f => f.value) ?? []}
    onChange={val => form.updateValues('features', [
      ...form.values.features?.filter(f => f.type !== 'DISTRICT') ?? [],
      ...val.map(v => ({ type: 'DISTRICT', value: v })),
    ])}
  />;

  // #region User Settings

  // Language Settings
  const languageSelection = () => <FormControl variant="outlined" margin="dense" fullWidth>
    <InputLabel variant="outlined" style={{ background: '#fff' }}>{langUser.displayLanguage}</InputLabel>
    <Select
      disabled={profileDisabled}
      {...bind('language')}
      renderValue={(selected) => languageOptions[(selected as string)]}
    >
      {Object.keys(languageOptions).map(language => (
        <MenuItem key={language} value={language}>{languageOptions[language]}</MenuItem>
      ))}
    </Select>
    {form.hasError('language') ? <FormHelperText error>{form.hasError('language')}</FormHelperText> : null}
  </FormControl>;

  // salutation selection 
  const salutationSelection = () => <FormControl variant="outlined" margin="dense" fullWidth>
    <InputLabel variant="outlined" style={{ background: '#fff' }}>{langUser.salutation}</InputLabel>
    <Select
      disabled={infoDisabled}
      {...bind('salutation')}
      renderValue={(selected) => userSalutationOptions[(selected as string)]}
    >
      {Object.keys(userSalutationOptions).map(salutation => (
        <MenuItem key={salutation} value={salutation}>{userSalutationOptions[salutation]}</MenuItem>
      ))}
    </Select>
  </FormControl>;

  // Team Lead Selection
  const teamLeadSelection = () => <Autocomplete
    options={Object.values(teams)}
    popupIcon={''}
    renderOption={(team) => team?.teamName}
    disabled={infoDisabled}
    noOptionsText={''}
    loading={teamLoading}
    loadingText={lang.msgLoading}
    renderInput={params => (
      <TextField
        {...params}
        className={classes.patchOutlineAutocomplete}
        error={!!form.hasError('teamHeadId')}
        helperText={form.hasError('teamHeadId')}
        fullWidth
        label={langUser.teamLead}
        margin="dense"
        variant="outlined"
        onChange={(ev) => { loadTeams(ev.target.value); }}
      />
    )}
    value={teams[String(form.values.teamHeadId)]?.teamName ?? ''}

    onChange={(_, team) => {
      form.updateValues('teamHeadId', team?.uid ?? undefined);
    }}
  />;

  const teamLeadToggle = () => <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    <Typography style={{ marginRight: theme.spacing(3), fontWeight: 'bold' }}>{langUser.actAsTeamHead}</Typography>
    <Typography>{langUser.actAsTeamHeadNo}</Typography>
    {teamMembersCount ?
      <Tooltip title={langUser.cannotSwitchTeamHead}>
        <span>
          <Switch
            disabled={true}
            checked={actAsTeamHead}
            onChange={(_, val) => form.updateValues('actAsTeamHead', val)}
          />
        </span>
      </Tooltip>
      :
      <Switch
      disabled={infoDisabled}
      checked={actAsTeamHead}
      onChange={(_, val) => form.updateValues('actAsTeamHead', val)}
      />
    }
    <Typography>{langUser.actAsTeamHeadYes}</Typography>
  </div>;

  const PREVIEWABLE_EXTENSIONS = ['.jpg', '.jpeg', '.gif', '.png'];
  const [photoLightBoxOpened, setPhotoLightBoxOpened] = useState(false);

  const handleUploadPhoto = (ev: React.ChangeEvent<HTMLInputElement>) => {
    let files = Array.from(ev.target.files ?? []);
    const fileExt = extname(files[0].name || '');
    const SUPPORT_FILE_TYPE = PREVIEWABLE_EXTENSIONS.includes(fileExt.toLowerCase());
    if (!SUPPORT_FILE_TYPE) {
      dispatch({ type: 'Layout.AlertMessageAdded', payload: { message: format(lang.msgUnsupportedFileFormat, fileExt.toString()) } })
      return;
    } else {
      const currentLength = isNonEmpty(form.values.photoFilename) ? 1 : 0;
      const limit = 1;
      let remainLength = limit ?? 0 - currentLength > 0 ? limit ?? 0 - currentLength : 0;
      if (remainLength === 0) return;

      Promise.all(files.slice(0, remainLength).map(file => {
        return fileApi.addFile(file).then(result => result.data as string)
      })).then(paths => {
        form.updateValues('photoFilename', paths![0]);
      });
    }
  }

  const photoDisplay = () => (
    <Avatar
      className={classes.photo}
      alt={form.values.username}
      src={isNonEmpty(form.values.photoFilename) ? `${BASE_URL}/files/${form.values.photoFilename}` : ''}
      // onClick={() => {
      //   if (profileDisabled){
      //     return;
      //   }
      //   document.getElementById("AGENT_UPLOAD_PHOTO")?.click()
      // }}
      onClick={handleClickPhoto}
    >
      {langUser.photo}
    </Avatar>
  )

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleClickPhoto = (event: React.MouseEvent<HTMLDivElement>) => {
    if (profileDisabled && infoDisabled && !isNonEmpty(form.values.photoFilename)) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClosePhotoPopover = () => {
    setAnchorEl(null);
  };

  const openPhotoPopover = Boolean(anchorEl);
  const photoPopoverId = openPhotoPopover ? 'simple-popover' : undefined;


  const photoPopover = () => (
    <Popover
      id={photoPopoverId}
      open={openPhotoPopover}
      anchorEl={anchorEl}
      onClose={handleClosePhotoPopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <div className={classes.photoPopover}>
        <ButtonGroup size="small" color="primary" aria-label="photo button group">
          {isNonEmpty(form.values.photoFilename) &&
            <Button onClick={() => { setPhotoLightBoxOpened(true) }}>{lang.actionView}</Button>}
          {!(profileDisabled && infoDisabled) &&
            <Button onClick={() => { document.getElementById("AGENT_UPLOAD_PHOTO")?.click() }}>{lang.actionUpload}</Button>}
          {!(profileDisabled && infoDisabled) &&
            <Button onClick={() => { form.updateValues('photoFilename', undefined) }}>{lang.actionDelete}</Button>}
        </ButtonGroup>

      </div>
    </Popover>
  )

  const showPhotoSection = !creating && !isNotAgent;

  const langSearchCredit = () => <TextField
    {...form.bind('landSearchCredit')}
    onChange={(ev) => form.updateValues('landSearchCredit', ev.target.value && !isNaN(Number(ev.target.value)) ? Number(ev.target.value) : ev.target.value ? form.values.landSearchCredit : undefined)}
    label={langUser.landSearchCredit}
    disabled={infoDisabled}
    fullWidth
    margin="dense"
    variant="outlined"
  />;

  const [incorrect, setIncorrect] = useState(false);
  return (
    <Card>
      <CardContent style={{ minHeight: 400 }}>
        <Grid container spacing={1}>
          <Grid item container md={12} spacing={1}>
            <Typography style={{ fontWeight: 'bold' }}>{langUser.titleBasicInfo}</Typography>
          </Grid>
          {/* ----- elements for photo ------ */}
          {photoPopover()}
          <input
            style={{ display: 'none' }}
            id={'AGENT_UPLOAD_PHOTO'}
            type="file"
            onChange={(ev) => {
              handleUploadPhoto(ev);
              resetFileInput(ev);
            }}
            accept=".gif,.jpg,.jpeg,.png"
          />
          {photoLightBoxOpened &&
            <Lightbox
              reactModalStyle={{ overlay: { zIndex: 1500 } }}
              mainSrc={`${BASE_URL}/files/${form.values.photoFilename}`}
              onCloseRequest={() => setPhotoLightBoxOpened(false)}

            />
          }
          {/* ----- end elements for photo ------ */}


          <Grid item container spacing={1} md={12}>

            {showPhotoSection ?
              <Grid item container spacing={1} >
                <Grid item md={6} xs={6} style={{ alignContent: 'center' }}>
                  {photoDisplay()}
                </Grid>
                <Grid container item md={6} xs={6}>
                  <Grid item md={12} xs={12} style={{ marginBottom: 5 }}>
                    {infoField('username', { disabled: !creating, required: true })}
                  </Grid>
                  <Grid item md={12} xs={12}>
                    {salutationSelection()}
                  </Grid>
                </Grid>
              </Grid>
              : null
            }

            {showPhotoSection ? null :
              <Grid item container spacing={1}>
                <Grid item md={6} xs={6}>
                  {infoField('username', { disabled: !creating, required: true })}
                </Grid>
                <Grid item md={6} xs={6}>
                  {salutationSelection()}
                </Grid>
              </Grid>
            }

            <ChangePasswordDialog
              open={passwordChangeDialogOpen}
              onClose={() => setPasswordChangeDialogOpen(false)}
              onChangePassword={({ oldPassword, newPassword }) => {
                dispatch({ type: 'Layout.MaskPresentRequested' });
                userApi.changePassword(oldPassword, newPassword, token ?? '').then(({ error }) => {
                  if (error) {
                    setIncorrect(true);
                    let errorMsg = '';
                    switch (error) {
                      case 'ERR_PASSWORD_MISMATCHED': errorMsg = langUser.msgPasswordMismatched; break;
                      case 'ERR_PW_FAIL_COMPLEXITY_REQUIREMENT': errorMsg = langUser.msgPasswordFailComplexityRequirement; break;
                      case 'ERR_PREVIOUS_PW_USED': errorMsg = langUser.msgPreviousPasswordUsed; break;
                      default: errorMsg = lang.msgNetworkError;
                    }
                    dispatch({
                      type: 'Layout.AlertMessageAdded',
                      payload: {
                        message: errorMsg,
                      },
                    });
                    setIncorrect(false);
                  } else {

                    setPasswordChangeDialogOpen(false);
                  }
                  dispatch({ type: 'Layout.MaskDismissRequested' });
                });
              }}
              incorrectPassword={incorrect}
            />
          </Grid>

          <Grid item container spacing={1} md={12}>
            <Grid item md={6} xs={6}>
              {infoField('chineseName', { helperText: langUser.msgPleaseEnterChinOrEngName })}
              <HandleFormHelperText
                isError={keyBooleanMap.get('chineseName')}
                errorMessage={lang.textOverLimit}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              {infoField('englishName', { helperText: '' })}
              <HandleFormHelperText
                isError={keyBooleanMap.get('englishName')}
                errorMessage={lang.textOverLimit}
              />
            </Grid>
            {/* <Grid item md={12} xs={6}>
              <FormHelperText style={{color: form.hasError('chineseName') || form.hasError('englishName')  ?'red' : 'inherit'}}>{langUser.msgPleaseEnterChinOrEngName}</FormHelperText> 
            </Grid> */}
          </Grid>

          <Grid item container spacing={1} md={12}>
            <Grid item md={6} xs={6}>
              {infoField('title')}
              <HandleFormHelperText
                isError={keyBooleanMap.get('title')}
                errorMessage={lang.textOverLimit}
              />
            </Grid>
            {/* <Grid item md={6} xs={6}>
              {infoField('email', { required: true })}
            </Grid> */}
            <Grid item md={6} xs={6}>
              {roleSelection()}
            </Grid>
          </Grid>

          <Grid item container spacing={1} md={12}>
            {/* <Grid item md={6} xs={6}>
              {roleSelection()}
            </Grid> */}
            <Grid item md={6} xs={6}>
              {infoField('licenseNumber')}
              <HandleFormHelperText
                isError={keyBooleanMap.get('licenseNumber')}
                errorMessage={lang.textOverLimit}
              />
            </Grid>

            <Grid item md={6} xs={6}>
              {statusSelection()}
            </Grid>
          </Grid>

          <Grid item container spacing={1} md={12}>
            {/* <Grid item md={6} xs={6}>
              {statusSelection()}
            </Grid> */}

            <Grid item md={6} xs={12}>
              {districtSelection()}
            </Grid>
            {/* <Grid item md={6} xs={6}>
              {languageSelection()}
            </Grid> */}

            <Grid item md={6} xs={12} hideWhen={isNotAgent}>
              {langSearchCredit()}
            </Grid>
          </Grid>

          <Grid item container spacing={1} md={12} hideWhen={isNotAgent}>
            <Grid item md={12} xs={12}>
              {teamLeadToggle()}
            </Grid>
            <Grid item md={6} xs={6} hideWhen={actAsTeamHead}>
              {teamLeadSelection()}
            </Grid>
            <Grid item md={6} xs={6} hideWhen={!actAsTeamHead}>
              {infoField('teamName')}
              <HandleFormHelperText
                isError={keyBooleanMap.get('teamName')}
                errorMessage={lang.textOverLimit}
              />
            </Grid>

            {/* <Grid item md={6} xs={6} hideWhen={!actAsTeamHead}>
              {field('teamMemberCount', { inputProps: { readOnly: !creating } })}
            </Grid> */}
          </Grid>

        </Grid>
      </CardContent>
    </Card>
  );
}

export default UserBasic;
