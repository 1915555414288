import {
    Card,
    CardActions,
    CardContent, colors, Divider,
    Table,
    TableBody,
    TableCell,
    TableHead, TableRow, TableSortLabel,
    // TablePagination,
    Theme
} from '@material-ui/core';
import { CardProps } from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { MemoEnquiryDTO } from 'common/dto';
import TablePagination from 'common/elements/TablePagination';
import { multiLang } from 'common/utils';
import moment from 'moment';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { IRootState } from 'reducers';


const PUBLIC_URL = process.env['PUBLIC_URL'];

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-start',
    background: colors.grey[50],
    flexWrap: 'wrap',
    '& > *': {
      wordBreak: 'keep-all',
      // marginBottom: '8px',
    },
    padding: '0px',
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableCell: {
    wordWrap: 'normal',
    wordBreak: 'keep-all',
    lineBreak: 'strict',
    whiteSpace: 'nowrap',
    // minWidth: 128,
  },
  navLink: {
    color: '#1565c0',
    '&:hover, &:focus': {
      color: '#1565c0',
      textDecoration: 'underline'
    }
  },
  tablePaginationIconButton:{
    '& .MuiIconButton-root':{
      padding: '6px',
    }
  },
  tablePaginationToolbar:{
    paddingRight: '12px',
  },
  clearSortingContainer: {
    padding: '3px',
  },
}));

type Header = {
  title: string | JSX.Element,
  overrideStyle?: any,
  sortingDisabled?: boolean,
};

interface MemoEnquiryTableProps extends CardProps {
  className?: string;
  items: MemoEnquiryDTO[];

  page: number;
  totalPages: number;
  totalElements: number;
  limit: number;
  rowsCountOptions: number[];
  handleRowsCount: (count: number) => void;
  setPage: (page: number) => void;
  sortOrders?: { [k: string]: 'desc' | 'asc' };
  handleSort?: (key: string | null) => void;
}

const MemoEnquiryTable = (props: MemoEnquiryTableProps) => {
  const { className, items, sortOrders, handleSort, page, totalPages, totalElements, limit, setPage, rowsCountOptions, handleRowsCount, ...rest } = props;

  const { locale, lang, langMemoEnquiry, langUser } = useSelector((state: IRootState) => state.locale);
  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);
  const classes = useStyles();

  const history = useHistory();
  // const handleNavigateToItem = () => () => {
    // history.push(`/???/${id}`);
  // };

  const handlePageChange = (event: React.MouseEvent | null, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleRowsCount(+event.target.value);
  };

  let tableHeaders: Record<any, Header | null> = { // keyof PropertyListItemDTO
    'dateCreated': { title: langMemoEnquiry.dateCreated },
    'usernameForSorting': { title: langMemoEnquiry.user },
    'client.chineseName': { title: langMemoEnquiry.clientName},
    [locale === 'en' ? 'propertyAddressEn' : 'propertyAddressZh']: { title: langMemoEnquiry.property, sortingDisabled: true },
    content: { title: langMemoEnquiry.content },
    // type: { title: langMemoEnquiry.type },
    // id: { title: langMemoEnquiry.id },
  };

  const overrideCols: { [col: string]: (memo: MemoEnquiryDTO) => any } = {
    // status: (transaction) => propertyStockStatusOptions[transaction.status ?? ''],
    'dateCreated': (memo) => memo.dateCreated ? moment(memo.dateCreated).format(DISPLAY_DATE_FORMAT) : '',
    'usernameForSorting': (memo) => memo.userName ?? '',
    'client.chineseName': (memo) => multiLang(locale, memo.clientName, memo.clientNameEn),
    [locale === 'en' ? 'propertyAddressEn' : 'propertyAddressZh']: (memo) => memo.propertyNo ? 
      <a href={`${PUBLIC_URL}/properties/${memo?.propertyNo}`} 
        className={classes.navLink}
        onClick={(e) => {
          e.preventDefault(); 
          history.push(`/properties/${memo.propertyNo}`)
        }}
      >
        {multiLang(locale, memo.propertyAddress, memo.propertyAddressEn)}
      </a> :
      multiLang(locale, memo.propertyAddress, memo.propertyAddressEn)
    // type: (memo) => memoTypeOptions[memo.type ?? 'C']
  }

  // const colsClickAction: Record<string, (memo: MemoEnquiryDTO) => any> = {
  //   [locale === 'en' ? 'propertyAddressEn' : 'propertyAddressZh']: (memo) => memo.propertyNo ? history.push(`/properties/${memo.propertyNo}`) : {}
  // }

  // const colsLink: Record<string, (memo: MemoEnquiryDTO) => string> = {
  //   [locale === 'en' ? 'propertyAddressEn' : 'propertyAddressZh']: (memo) => `${PUBLIC_URL}/${memo?.propertyNo}`
  // }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardActions className={classes.actions}>
        <div style={{ flexGrow: 1 }} />
        
        {/* {Object.keys(sortOrders ?? {}).length ?
          <div className={classes.clearSortingContainer}>
            <Button style={{ marginRight: 0 }} variant="outlined" size="small" onClick={() => handleSort?.(null)}>{lang.actionClearSorting}</Button>
          </div>
        : null} */}

        <TablePagination
          classes={{
            actions: classes.tablePaginationIconButton,
            toolbar: classes.tablePaginationToolbar,
          }}
          // component="div"
          count={totalElements}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          totalPages={totalPages}
          rowsPerPage={limit}
          labelRowsPerPage={langUser.rowsCount}
          rowsPerPageOptions={rowsCountOptions}
          // rowsPerPageOptions={[20]}
        />
      </CardActions>

      <Divider />

      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {
                    Object.keys(tableHeaders).filter((key) => !!tableHeaders[key as keyof MemoEnquiryDTO]).map((key,idx) =>
                      tableHeaders[key as keyof MemoEnquiryDTO]?.sortingDisabled ? <TableCell key={idx}>{tableHeaders[key as keyof MemoEnquiryDTO]?.title}</TableCell> 
                      : <TableCell key={idx} className={classes.tableCell} style={tableHeaders[key as keyof MemoEnquiryDTO]?.overrideStyle}>
                          <TableSortLabel active={sortOrders?.[key] !== undefined} direction={sortOrders?.[key]} onClick={() => handleSort?.(key)}>
                            {tableHeaders[key as keyof MemoEnquiryDTO]?.title}
                          </TableSortLabel>
                      </TableCell>
                    )
                  }
                </TableRow>
                
              </TableHead>
              <TableBody>
                {items.map(memo => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={memo.id}
                    // onClick={handleNavigateToItem(transaction.id!)}
                  >
                    {
                      Object.keys(tableHeaders).filter((key) => !!tableHeaders[key as keyof MemoEnquiryDTO]).map((key) => {
                        // 
                        return <TableCell size="small"  style={{whiteSpace: 'pre-line'}} 
                          className={classes.tableCell} key={`${memo.id}-${key}`}
                          // onClick={() => colsClickAction[key]?.(memo)}
                          // component={'a' as any} {...{ href: `${PUBLIC_URL}/${memo?.propertyNo}` } as any}
                        >
                          {overrideCols[key] ? overrideCols[key](memo) : memo[key as keyof MemoEnquiryDTO]}
                        </TableCell>
                      })
                    }
                    {/* <TableCell style={{ cursor: 'default' }} onClick={(ev) => ev.stopPropagation()} size="small" className={classes.tableCell} key={`${transaction.id}-viewIcon`}>
                      <IconButton disabled tooltip="Will be ready with Transaction module">
                        <ViewIcon />
                      </IconButton>
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <div style={{ flexGrow: 1 }} />
        <TablePagination
          classes={{
            actions: classes.tablePaginationIconButton,
            toolbar: classes.tablePaginationToolbar,
          }}
          count={totalElements}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          totalPages={totalPages}
          rowsPerPage={limit}
          labelRowsPerPage={langUser.rowsCount}
          rowsPerPageOptions={rowsCountOptions}
        />
      </CardActions>
    </Card>
  );
};

// PropertiesTable.propTypes = {
//   className: PropTypes.string,
//   properties: PropTypes.array.isRequired
// };

export default MemoEnquiryTable;
