import { FormHelperText, Grid, makeStyles, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { propertyApi } from 'api';
import clientApi from 'api/clientApi';
import { hasPermission } from 'common/access-control';
import { useConfirmDialog } from 'common/ConfirmDialog';
import { ContactsDTO, PropertyStockDTO } from 'common/dto';
import { FormPresenter, useForm } from 'common/hooks';
import { isNonEmpty, multiLang } from 'common/utils';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState, PASDispatch } from 'reducers';
import { format } from 'util';
import ContactCreationDialog from './ContactCreationDialog';

const useStyles = makeStyles(() => ({
  root: {

  },
  patchOutlineAutocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
      paddingLeft: 8,
    }
  },
  dialogContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60%',
  },
  textArea: {
    width: '90%'
  }
}));

interface AddLeadAgentDialogProps {
  stockForm: FormPresenter<PropertyStockDTO>;

  open: boolean;
  handleClickOpen: () => void;
  handleClose: () => void;

  //for warning dialog in create page OR add as lead agent in the preview page
  isCreatePage?: boolean; //set true for preview page
  createPropertyStock?: () => void; //NOT used 
  hasAssociatedClients?: boolean;
  hideOpenButton?: boolean;
  propertyStockId?: string;
  isPreviewPage?: boolean;
}

const errorColor = '#e53935';

const AddLeadAgentDialog = ({ stockForm, open, handleClickOpen, handleClose, isCreatePage, hasAssociatedClients, hideOpenButton, propertyStockId, isPreviewPage }: AddLeadAgentDialogProps) => {
  const dispatch = useDispatch() as PASDispatch;
  const classes = useStyles();
  const { langPropertyStock, lang, locale, contactOptions } = useSelector((state: IRootState) => state.locale);

  const { token, privileges } = useSelector((state: IRootState) => state.login);

  const addLeadAgentForm = useForm<PropertyStockDTO>({}, {
    validations: [
      (values) => values.sellerClientId ? null : [['mainContact', langPropertyStock.msgInputClient]],
    ],
  });
  const confirmDialog = useConfirmDialog();

  // ---- privilege
  const canCreateUnownClient = hasPermission(privileges, 'CREATE', 'UNOWNED_CLIENT');
  const canCreateOwnedClient = hasPermission(privileges, 'CREATE', 'OWN_CLIENT');
  const canAssignUnownedAgentClient = hasPermission(privileges, 'UPDATE', 'ASSIGN_UNOWNED_AGENT_CLIENT');
  const canAssignTeamAgentClient = hasPermission(privileges, 'UPDATE', 'ASSIGN_TEAM_AGENT_CLIENT');
  const canAssignOwnAgentClient = hasPermission(privileges, 'UPDATE', 'ASSIGN_OWN_AGENT_CLIENT');
  const canAssignClientAgent = canAssignUnownedAgentClient || canAssignTeamAgentClient || canAssignOwnAgentClient;
  const canCreatePropertyStock = hasPermission(privileges, 'CREATE', 'PROPERTY_STOCK');
  const canCreateUnownPropertyStock = hasPermission(privileges, 'CREATE', 'UNOWNED_PROPERTY_STOCK');
  const canCreateOwnPropertyStock = hasPermission(privileges, 'CREATE', 'OWN_PROPERTY_STOCK');
  const canCreateCompanyClient = hasPermission(privileges, 'CREATE', 'COMPANY_CLIENT');

  // When the user has the privilege to create property stock + create unowned property stock but without privilege to create own property stock, some special handling needed
  const hasSpecialHandling = canCreatePropertyStock && canCreateUnownPropertyStock && !canCreateOwnPropertyStock;
  // If the user does NOT have the privilege to create unowned client & create company client -> hide/disable the button
  const hideButton = hasSpecialHandling ? !(canCreateUnownClient || canCreateCompanyClient)
    : !(canCreateOwnedClient || (canCreateUnownClient && canAssignClientAgent) || canCreateCompanyClient);

  // ---- dialog event
  // const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const clickConfirmButton = () => {
    if (addLeadAgentForm.validate() && !cannotAddCompanyClient) {

      if (isCreatePage) {
        doConfirmAction();
      } else { //for adding lead agent by clicking the addLeadAgentBtn
        dispatch({ type: 'Layout.MaskPresentRequested' });
        //check whether there exists seller with the same agent
        propertyApi.checkExistSellerWithSameAgent(stockForm.values.id ?? '', addLeadAgentForm.values.sellerClientId!, token ?? '').then(async result => {
          if (!result.error) {
            dispatch({ type: 'Layout.MaskDismissRequested' });
            if (result.data) { //if there exists seller with the same agent
              const confirmMsg = langPropertyStock.msgWarningTheAgentIsAlreadyLeadAgent;
              confirmDialog.confirm(confirmMsg, lang.actionConfirm, lang.actionNo).then(confirmed => {
                if (confirmed) {
                  doConfirmAction();
                } else {
                  handleClose();
                  addLeadAgentForm.setValues({});
                  addLeadAgentForm.stopValidation();
                }
              });
            } else {
              //no existing seller with same agent
              doConfirmAction();
              handleClose();
            }

          } else {
            dispatch({ type: 'Layout.MaskDismissRequested' });
            dispatch({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgNetworkError, severity: 'error' } });
          }
          dispatch({ type: 'Layout.MaskDismissRequested' });
        });
      }
    }

  }

  const doConfirmAction = () => {
    if (addLeadAgentForm.validate() && !cannotAddCompanyClient) {
      if (isCreatePage) {
        dispatch({
          type: 'Property.CreationRequested', payload: {
            ...stockForm.values,
            id: propertyStockId,
            sellerClientId: addLeadAgentForm.values.sellerClientId,
            mainContactId: addLeadAgentForm.values.mainContactId,
            addLeadAgentInPreviewPage: isPreviewPage,
          } as PropertyStockDTO
        });
      } else {
        dispatch({ type: 'Property.AddLeadAgentRequested', payload: { ...addLeadAgentForm.values, id: stockForm.values.id } as PropertyStockDTO });
      }
    }
  }

  //---- creation dialog
  let [creationDialogOpened, isCreationDialogOpened] = useState(false);
  const openCreationDialog = () => isCreationDialogOpened(true);
  const closeCreationDialog = () => isCreationDialogOpened(false);


  //----- client autocomplete
  const [shouldDisplayClientAutoComplete, setShouldDisplayClientAutoComplete] = useState(false);
  const [contacts, setContacts] = useState<ContactsDTO[]>([]);

  const searchClients = async (kw: string) => {
    try {
      const response = await clientApi.searchOwnContacts(kw, token ?? '');
      // 
      setContacts(response.data ?? []);
    } catch (e) {
      // { error: String(e) };
    }
  };

  //----- check whether the selected client is addable
  //If an existing property stock WITH associated client is detected, the user cannot input a company client as the new associated client.
  let [companyClientSelected, setCompanyClientSelected] = useState(false);
  const numOfLeadAgent = isCreatePage ? (hasAssociatedClients ? 1 : 0)
    : stockForm.values.leadAgents?.length ?? 0;
  const cannotAddCompanyClient = numOfLeadAgent > 0 ? companyClientSelected : false;
  const showUpdateAsCompanyStockMsg = numOfLeadAgent === 0 && !cannotAddCompanyClient && companyClientSelected;

  return (
    <div>
      {confirmDialog.render()}
      {!hideOpenButton ? <Button variant="text" color="primary" style={{ marginTop: '7px' }} onClick={handleClickOpen}>
        {langPropertyStock.actionAddLeadAgent}
      </Button> : null}
      <Dialog open={open} onClose={handleClose} aria-labelledby="add-agent-dialog">
        <DialogTitle id="add-agent-dialog-title">
          <Typography style={{ fontWeight: 'bold' }}>{langPropertyStock.actionAddLeadAgent}</Typography>
        </DialogTitle>
        <DialogContent>
          {cannotAddCompanyClient ? <FormHelperText style={{ color: cannotAddCompanyClient ? errorColor : 'inherit' }}>{langPropertyStock.msgCannotAddCompanyClient}</FormHelperText> : null}
          {showUpdateAsCompanyStockMsg ? <FormHelperText style={{ color: showUpdateAsCompanyStockMsg ? 'orange' : 'inherit', fontSize: '12pt' }}>{format(langPropertyStock.msgWarningUpdateAsCompanyStock, lang.actionConfirm)}</FormHelperText> : null}

          <Grid item container md={12} xs={12} spacing={1} >

            {/* ---------------- client autocomplete -------------------- */}
            <Grid
              item
              md={4}
              xs={12}
            >
              <Autocomplete
                options={contacts}
                popupIcon={''}
                getOptionLabel={(_contact: ContactsDTO) => addLeadAgentForm.values.mainContact ?? ''}
                noOptionsText={shouldDisplayClientAutoComplete ? langPropertyStock.msgContactNotFound : ''}
                value={addLeadAgentForm.values.mainContact || ''}
                onChange={(_, contact: ContactsDTO | undefined) => {
                  addLeadAgentForm.setValues({
                    ...addLeadAgentForm.values,
                    sellerClientId: +(contact?.cid ?? '0'),
                    mainContactId: +(contact?.id ?? '0'),
                    'mainContact': contact?.value,
                    'sellerZh': contact?.chineseName,
                    'sellerEn': contact?.englishName,
                  });
                  setCompanyClientSelected(contact?.isCompanyClient ?? false);
                }}
                renderOption={(contact: ContactsDTO) => <div>
                  <div><Typography>{multiLang(locale, contact.chineseName, contact.englishName)}</Typography></div>
                  <div><Typography variant="caption" style={{ display: isNonEmpty(contact.value) ? 'block' : 'none' }}>{contact.value} ({contactOptions[contact.type]})</Typography></div>
                </div>}
                renderInput={params => (
                  <TextField
                    {...params}
                    className={classes.patchOutlineAutocomplete}
                    fullWidth
                    error={!!addLeadAgentForm.hasError('mainContact')}
                    helperText={addLeadAgentForm.hasError('mainContact') ?? langPropertyStock.msgInputClient}
                    label={langPropertyStock.captionClientContact}
                    margin="dense"
                    required
                    variant="outlined"
                    onChange={(ev) => {
                      setShouldDisplayClientAutoComplete(ev.target.value.length >= 1);
                      if (ev.target.value.length >= 1) {
                        searchClients(ev.target.value);
                      }
                    }}
                  />
                )}
              />
            </Grid>
            {/* ------------------- chinese name -------------------- */}
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                disabled
                label={lang.captionChineseName}
                margin="dense"
                {...addLeadAgentForm.bind('sellerZh')}
                variant="outlined"
              />
            </Grid>

            {/* ------------------- english name -------------------- */}
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                disabled
                label={lang.captionEnglishName}
                margin="dense"
                {...addLeadAgentForm.bind('sellerEn')}
                variant="outlined"
              />
            </Grid>
            {!hideButton ?
              <Grid
                item
                md={12}
                xs={12}
              >
                <Button variant="text" color="primary" onClick={openCreationDialog}>{langPropertyStock.msgNewClient}</Button>
                <ContactCreationDialog isAddLeadAgentDialog={isCreatePage ? false : true} close={closeCreationDialog} onNewClient={(newClient) => {
                  if (!newClient.cid) return;
                  addLeadAgentForm.setValues({
                    ...addLeadAgentForm.values,
                    sellerClientId: +(newClient.cid!),
                    'mainContact': newClient.otherContacts[0]?.value,
                    'sellerZh': newClient.chineseName,
                    'sellerEn': newClient.englishName,
                  })
                  setCompanyClientSelected(newClient.isCompanyClient);
                }} keepMounted open={creationDialogOpened} onClose={closeCreationDialog} />
              </Grid>
              : null}

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {lang.actionCancel}
          </Button>
          <Button disabled={cannotAddCompanyClient} onClick={clickConfirmButton} color="primary" variant="contained" autoFocus>
            {lang.actionConfirm}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddLeadAgentDialog;
