import { Typography } from "@material-ui/core";
import React, { useEffect } from "react";

const FixedTabPanel = (props: { children: JSX.Element, scrollRef: React.MutableRefObject<(() => void) | undefined>, value?: number, index: number, onScrollIn?: (idx: number) => void, title?: string }): JSX.Element => {
  const { children, index, scrollRef, onScrollIn, title } = props;
  const panelGroupRef = React.useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!panelGroupRef.current) {
      return;
    }

    scrollRef.current = () => {
      if (!panelGroupRef.current) {
        return;
      }
      
      document.documentElement.scroll({ top: panelGroupRef.current!.offsetTop - 130 });
    };

    const listener = () => {
      // 
      const delta = panelGroupRef.current!.offsetTop - document.documentElement.scrollTop;
      if (delta < 130 && delta > 0) {
        onScrollIn?.(index);
      }
    };

    window.addEventListener('scroll', listener);

    // cleanup
    return () => window.removeEventListener('scroll', listener);
  }, [ panelGroupRef.current ]);

  return <div ref={panelGroupRef}>
    <div style={{ marginTop: 16, marginBottom: 8 }}>
      {title ? <Typography variant="h3">{title}</Typography> : null}
    </div>
    {children}
  </div>;

}

export default FixedTabPanel;