import { Avatar, Button, Dialog, DialogActions, DialogTitle, Grid, IconButton, ListItem, ListItemAvatar, ListItemText, makeStyles, Typography } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
// import { uniqueBuildingValidations } from '../building-validations';
import { useConfirmDialog } from 'common/ConfirmDialog';
import { BuildingAliasDTO, BuildingDetailDTO, IUniqueBuilding } from 'common/dto';
import { useForm } from 'common/hooks';
import { selectAllDistrictOptions } from 'common/selectors';
import { AddIcon, AliasIcon, DeleteIcon } from 'common/ui';
import { multiLang } from 'common/utils';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState, PASDispatch } from 'reducers';
import { BuildingAliasEditDialog } from './BuildingAliasEditDialog';


const useStyles = makeStyles(theme => ({
  dialogRoot: {
    width: 600,
  },
  searchField: {

  },
  buildingPrimaryText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  usageTag: {
    textAlign: 'right',
    borderRadius: 3,
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    borderStyle: 'solid',
    padding: 2,
    color: theme.palette.primary.main,
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap',
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
}));

export interface BuildingAliasListProps {
  onClose: () => void;
  open: boolean;
  aliases: BuildingAliasDTO[];
  currentBuilding: Partial<BuildingDetailDTO>;
}

export const BuildingAliasListDialog: React.FC<BuildingAliasListProps> = (props) => {
  const { onClose, open, currentBuilding } = props;
  const classes = useStyles();

  const { locale, lang, langBuilding, usageOptions } = useSelector((state: IRootState) => state.locale);
  const allDistrictOptions = useSelector(selectAllDistrictOptions);

  // #region Redux Linking
  const dispatch = useDispatch() as PASDispatch;
  const {
    aliases, adding
  } = useSelector((state: IRootState) => state.buildingAliasList);
  const assembleFormToDto = () => {
    const updatedResult = addForm.values as Record<string, any>;
    Object.keys(updatedResult).forEach(key => {
      if (typeof updatedResult[key as keyof BuildingAliasDTO] === "string" && updatedResult[key as keyof BuildingAliasDTO].trim() === "") {
        updatedResult[key as keyof BuildingAliasDTO] = null;
      }
    });
    return (updatedResult as BuildingAliasDTO);
  }
  const onAdd = () => dispatch({ type: 'BuildingAliasList.AddRequested', payload: assembleFormToDto() });
  const setAdding = (val: boolean) => dispatch({ type: 'BuildingAliasList.ToggleAdding', payload: val });
  const onDelete = (aliasId: string) => dispatch({
    type: 'BuildingAliasList.RemoveRequested', payload: {
      aliasId,
      buildingId: currentBuilding.id!
    }
  });
  // #endregion Redux Linking

  // Add Form
  const initialAddForm = {
    buildingId: currentBuilding.id,
    // usage: currentBuilding.usage,
    buildingNameEn: currentBuilding.buildingNameEn,
    buildingNameZh: currentBuilding.buildingNameZh,
    streetEn: currentBuilding.streetEn,
    streetZh: currentBuilding.streetZh,
    blockEn: currentBuilding.blockEn,
    blockZh: currentBuilding.blockZh,
    lot: currentBuilding.lot,
    district: currentBuilding.district
  }
  const addForm = useForm<BuildingAliasDTO>(initialAddForm, {
    // validations: uniqueBuildingValidations(langBuilding),
  });

  // Remove
  // const [ manageMode, setManageMode ] = useState(false);

  const buildingPrimaryText = (building: IUniqueBuilding | undefined) => {
    //const buildingName = multiLang(locale, building?.buildingNameZh, building?.buildingNameEn);
    //const block = multiLang(locale, building?.blockZh, building?.blockEn);

    return <div className={classes.buildingPrimaryText}>
      <div>{multiLang('en', building?.buildingNameZh, building?.buildingNameEn)} {!multiLang('en', building?.blockZh, building?.blockEn) ? '' : `(${multiLang('en', building?.blockZh, building?.blockEn)})`}</div>
      <div>{multiLang('zh_HK', building?.buildingNameZh, building?.buildingNameEn)} {!multiLang('zh_HK', building?.blockZh, building?.blockEn) ? '' : `(${multiLang('zh_HK', building?.blockZh, building?.blockEn)})`}</div>
      {/* <div>
        <div className={classes.usageTag}>{usageOptions[building?.usage ?? '']}</div>
      </div> */}
    </div>;
  }

  const buildingSecondaryText = (building: IUniqueBuilding | undefined) => {
    return <div>
      <div>
        <b>{allDistrictOptions[building?.district ?? ''] ?? ''}</b>
      </div>
      <div>{multiLang('en', building?.streetZh, building?.streetEn) ?? ''}</div>
      <div>{multiLang('zh_HK', building?.streetZh, building?.streetEn) ?? ''}</div>
      <div>
        <i>{building?.lot}&nbsp;</i>
      </div>
    </div>;
  }

  const listView = () => <React.Fragment>
    <Grid container direction="row">
      <DialogTitle>
        <span>{langBuilding.titleBuildingAlias}</span>
        <IconButton onClick={() => {
          addForm.setValues({
            ...initialAddForm,
          });
          addForm.stopValidation();
          setAdding(true);
        }} color="primary">
          <AddIcon />
        </IconButton>
      </DialogTitle>

      {/* <Button onClick={() => setManageMode(!manageMode)}>
        {lang.actionManage}
      </Button> */}
    </Grid>


    {
      aliases.length > 0 ? null : <Typography style={{ padding: 16, width: '100%', textAlign: 'center' }}>
        <AliasIcon style={{ fontSize: 35, color: '#f0f0f0' }} />
      </Typography>
    }

    <Grid container>
      {aliases.map((alias) => (
        <Grid item xs={12} sm={6} container direction="row" wrap="nowrap">
          <Grid item style={{ flexGrow: 1 }}>
            <ListItem key={alias.id}>
              <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  <AliasIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={buildingPrimaryText(alias)}
                secondary={buildingSecondaryText(alias)}
              />
            </ListItem>
          </Grid>
          <Grid item style={{ flexGrow: 0 }}>
            <IconButton onClick={() => {
              confirmDialog.confirm(lang.msgConfirmDelete, lang.actionYes, lang.actionNo).then((confirmed) => {
                if (confirmed) {
                  onDelete(alias.id!);
                }
              });
            }}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
    </Grid>
  </React.Fragment>;

  const confirmDialog = useConfirmDialog();

  return (
    <Dialog onClose={onClose}
      open={open}
      PaperProps={{ className: classes.dialogRoot }}
    >
      {confirmDialog.render()}
      {/* Nested Add Dialog */}
      <BuildingAliasEditDialog
        onAdd={onAdd}
        open={adding}
        onClose={() => setAdding(false)}
        form={addForm}
      />

      {listView()}

      <DialogActions>
        <Button onClick={onClose}>{lang.actionClose}</Button>
      </DialogActions>
    </Dialog>
  );
}