import { ApiPageResult, ApiResult } from 'api';
import { MasterDataDTO, OptionDTO } from 'common/dto';
import { objectToQuery } from 'common/utils';

const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];

type EditableOptionsResponseData = {
	options: OptionDTO[],
	sections: OptionDTO[],
};

export const systemSettingApi = {
	getListForFrontend: async (token: string): Promise<ApiResult<MasterDataDTO>> => {
		try {
			const response = await fetch(`${BASE_URL}/system-settings/list-for-frontend`, {
				headers: { 'Authorization': `Bearer ${token}` },
			});
			return await response.json();
		} catch (e) {
			return { error: String(e) };
		}  
	},

	getEditableOptions: async (token: string): Promise<ApiResult<EditableOptionsResponseData>> => {
		try {
			const response = await fetch(`${BASE_URL}/option-list`, {
				headers: { 'Authorization': `Bearer ${token}` },
			});
			return await response.json();
		} catch (e) {
			return { error: String(e) };
		}
	},

	updateEditableOptions: async (options: OptionDTO[], token: string): Promise<ApiResult<OptionDTO[]>> => {
		try {
			const response = await fetch(`${BASE_URL}/option-list/`, {
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
				method: 'POST',
				body: JSON.stringify(options),
			});
			return await response.json();
		} catch (e) {
			return { error: String(e) };
		}
	},

	///////////////////////////////////////////////////////////////////////////////////
	//////////////////////////// System Setting List Page /////////////////////////////
	///////////////////////////////////////////////////////////////////////////////////

  getList: async (criteria: any = {}, token: string): Promise<ApiPageResult<MasterDataDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/system-settings/list-page?${objectToQuery(criteria)}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  update: async (systemSetting: MasterDataDTO, token: string): Promise<ApiResult<MasterDataDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/system-settings/list-page/${systemSetting.id}`, {
        method: 'POST', body: JSON.stringify(systemSetting),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  add: async (systemSetting: MasterDataDTO, token: string): Promise<ApiResult<string>> => {
    try {
      const response = await fetch(`${BASE_URL}/system-settings/list-page/add`, {
        method: 'POST', body: JSON.stringify(systemSetting),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },
}

export default systemSettingApi;