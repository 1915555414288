import {
  Button, Checkbox, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, ListItemText, Menu, MenuItem, Select, TextField, Tooltip, Typography, useTheme
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import WarningIcon from '@material-ui/icons/Warning';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/styles';
import clientApi from 'api/clientApi';
import userApi from 'api/userApi';
import { hasPermission } from 'common/access-control';
import { useConfirmDialog } from 'common/ConfirmDialog';
import { ClientDetailDTO, UserListItemDTO } from 'common/dto';
import { useForm, useMenu } from 'common/hooks';
import { useInfoDialog } from 'common/InfoDialog';
import { AddIcon, HandleFormHelperText } from 'common/ui';
import { getInvalidContactErrMsg, initFormKeysAndBooleanMap, isNonEmpty, limitTextFieldLength, multiLang } from 'common/utils';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducers';
import theme from 'theme';
import { CompactClientCreationValidations } from 'views/ClientDetail/components/client-validations';
const useStyles = makeStyles(() => ({
  root: {},
  patchOutlineAutocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
      paddingLeft: 8,
    }
  },
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  agentAutoCompletePopper: {
    zIndex: 5500,
  }
}));

interface ContactCreationDialogProps extends DialogProps {
  onNewClient: (client: ClientDetailDTO) => any;
  close: () => any;
  isAddLeadAgentDialog?: boolean;
}


const ContactCreationDialog = (props: ContactCreationDialogProps) => {
  const { locale, lang, langClientDetail, clientTypeOptions, sourceOfClientOptions, contactOptions } = useSelector((state: IRootState) => state.locale);

  const dispatch = useDispatch();

  const agentContactsForm = useForm<any>({
    agentId: '',
    agentNameEn: '',
    agentNameZh: '',
    agentContact: {}
  });

  const classes = useStyles();
  const theme = useTheme();

  const { onNewClient, close, isAddLeadAgentDialog, ...dialogProps } = props;

  //----------- privilege
  const { token, privileges } = useSelector((state: IRootState) => state.login);

  const canCreatePropertyStock = hasPermission(privileges, 'CREATE', 'PROPERTY_STOCK');
  const canCreateUnownPropertyStock = hasPermission(privileges, 'CREATE', 'UNOWNED_PROPERTY_STOCK');
  const canCreateOwnPropertyStock = hasPermission(privileges, 'CREATE', 'OWN_PROPERTY_STOCK');
  const canCreateUnownClient = hasPermission(privileges, 'CREATE', 'UNOWNED_CLIENT');
  const canCreateCompanyClient = hasPermission(privileges, 'CREATE', 'COMPANY_CLIENT');
  const canCreateOwnedClient = hasPermission(privileges, 'CREATE', 'OWN_CLIENT');

  const canAssignUnownedAgentClient = hasPermission(privileges, 'UPDATE', 'ASSIGN_UNOWNED_AGENT_CLIENT');
  const canAssignTeamAgentClient = hasPermission(privileges, 'UPDATE', 'ASSIGN_TEAM_AGENT_CLIENT');
  const canAssignOwnAgentClient = hasPermission(privileges, 'UPDATE', 'ASSIGN_OWN_AGENT_CLIENT');
  const canAssignClientAgent = canAssignUnownedAgentClient || canAssignTeamAgentClient || canAssignOwnAgentClient;

  const hasSpecialHandling = isAddLeadAgentDialog ? false : canCreatePropertyStock && canCreateUnownPropertyStock && !canCreateOwnPropertyStock;

  // If the user HAS the privilege to create unowned client & but NOT privilege to create company client
  // -> Hide the "Company Client" checkbox 
  // === If the user HAS the privilege to create company client
  const displayCompanyClientCheckbox = isAddLeadAgentDialog ? false : canCreateCompanyClient;

  // If the user does NOT have the privilege to create unowned client but HAS the privilege to create company client
  // -> The "Company Client" checkbox must be checked, not allow to uncheck
  const forceCompanyClientChecked = isAddLeadAgentDialog ? false : !canCreateOwnedClient && canCreateCompanyClient;

  // form init
  const form = useForm<ClientDetailDTO>({
    isCompanyClient: forceCompanyClientChecked ? true : undefined,
    clientFeatures: [{ type: 'clientType', value: 'L' }],
  }, {
    validations: CompactClientCreationValidations(langClientDetail),
  });

  const alertDialog = useInfoDialog();

  // const [duplicatedContactValues, setDuplicatedContactValues] = useState<string[]>([]);
  // const [duplicatedContactValues, setDuplicatedContactValues] = useState<Map<string, string>[]>([]);

  // // const [contactDetail, setContactDetail] = useState('');
  // const [contactDetail, setContactDetail] = useState<Map<string, string>>(new Map<string, string>());
  const [duplicatedContactValues, setDuplicatedContactValues] = useState<{ type: string, value: string }[]>([]);
  const [contactDetail, setContactDetail] = useState<{ type: string, value: string }>({ type: '', value: '' });

  useEffect(() => {
    if (!isNonEmpty(contactDetail) || duplicatedContactValues.some(d => d.type === contactDetail.type && d.value === contactDetail.value)) return;

    const timeout = setTimeout(() => {
      clientApi.checkDuplicatedContact(contactDetail, token ?? '').then(result => {
        if (!result.error && result.data) {
          setDuplicatedContactValues((prev) => [...new Set([...prev, contactDetail])]);
        }
      }); clearTimeout(timeout);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [contactDetail])
  // const handleDuplicatedContactWarning = (value: string) => {

  //   if (!value || duplicatedContactValues.includes(value)) return;

  //   const timeout = setTimeout(() => {

  //     clientApi.checkDuplicatedContact(value, token ?? '').then(result => {
  //       if (!result.error && result.data) {
  //         setDuplicatedContactValues((prev) => [...new Set([...prev, value])]);
  //       }
  //     }); clearTimeout(timeout);
  //   }, 3000);
  //   clearTimeout(timeout);
  // };


  // If the user HAS the privilege to create unowned client & create company client & company client is false
  // Or if the user HAS the privilege to create unowned client & but NOT privilege to create company client:
  // display a sub-section for inputting agent (Like the agent section in Client Master creation page, as the attached screen capture). 
  // The agent name will be a mandatory input field.
  const displayAgentSection = isAddLeadAgentDialog ? true : (
    (!canCreateOwnedClient && canCreateUnownClient && canAssignClientAgent && canCreateCompanyClient && form.values.isCompanyClient !== true) ||
    (!canCreateOwnedClient && canCreateUnownClient && canAssignClientAgent && !canCreateCompanyClient))
    && hasSpecialHandling;

  const [] = useState(false);
  const confirmCreateDialog = useConfirmDialog();
  const handleSave = () => {

    form.updateValues('agentFieldRequired', displayAgentSection);
    if (form.validate()) {
      const hasDupContact = isNonEmpty(form.values.otherContacts) ? form.values.otherContacts!.filter(contact => duplicatedContactValues.some(d => d.type === contact.type && d.value === contact.value)).length > 0 : false;

      if (hasDupContact) {
        confirmCreateDialog.confirm(langClientDetail.msgDuplicatedContactOnSave, lang.actionConfirm, lang.actionCancel).then(confirm => {

          if (confirm) {

            clientApi.add(form.values as ClientDetailDTO, token ?? '').then(({ data }) => {
              return clientApi.getNewlyCreatedClientDetail(data?.cid ?? '0', token ?? '');
            }).then(({ data, error }) => {
              if (!error && data) {
                onNewClient(data);
                close();
                form.stopValidation();
                setAgents([]);
                agentContactsForm?.setValues({
                  ...agentContactsForm?.values,
                  agentId: undefined,
                  agentNameEn: undefined,
                  agentNameZh: undefined,
                  agentContact: {
                    ...agentContactsForm?.values.agentContact,
                    agentId: undefined,
                    type: undefined,
                    value: undefined
                  }
                });
                form.setValues({
                  isCompanyClient: forceCompanyClientChecked ? true : undefined,
                  clientFeatures: [{ type: 'clientType', value: 'L' }],
                });
              } else {
                if (error === 'ERR_PERMISSION_DENIED') {
                  dispatch({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgPermissionDenied, severity: 'error' } });
                }
              }
            });
          }
        })
      }
      else {


        clientApi.add(form.values as ClientDetailDTO, token ?? '').then(({ data }) => {
          return clientApi.getNewlyCreatedClientDetail(data?.cid ?? '0', token ?? '');
        }).then(({ data, error }) => {
          if (!error && data) {
            onNewClient(data);
            close();
            form.stopValidation();
            setAgents([]);
            agentContactsForm?.setValues({
              ...agentContactsForm?.values,
              agentId: undefined,
              agentNameEn: undefined,
              agentNameZh: undefined,
              agentContact: {
                ...agentContactsForm?.values.agentContact,
                agentId: undefined,
                type: undefined,
                value: undefined
              }
            });
            form.setValues({
              isCompanyClient: forceCompanyClientChecked ? true : undefined,
              clientFeatures: [{ type: 'clientType', value: 'L' }],
            });
          } else {
            if (error === 'ERR_PERMISSION_DENIED') {
              dispatch({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgPermissionDenied, severity: 'error' } });
            }
          }
        });
      }

    }
  };

  // useEffect(() => {
  //   form.stopValidation();
  //   form.setValues({});
  //   agentContactsForm.setValues({});
  // }, [ saveSuccess ]);

  // useEffect(() => {
  //   if (form.values === BLANK && saveSuccess) {
  //     setSaveSuccess(false);
  //     close();
  //   }
  // }, [ form.values ])
  // useEffect(() => {
  //   if (form.validateClicked) {
  //     form.setValues({});
  //   }
  // }, [ form.validateClicked ]);
  // useEffect(() => {
  //   if (saveSuccess) {
  //     form.skipValidation();
  //     setSaveSuccess(false);
  //   }
  // }, [ saveSuccess ]);

  // useEffect(() => {
  //   if (form.skipValidate) {
  //     form.setValues({});
  //   }
  // }, [ form.skipValidate ]);

  const [keyBooleanMap, setKeyBooleanMap] = useState(initFormKeysAndBooleanMap(form));

  useEffect(() => {
    setKeyBooleanMap(initFormKeysAndBooleanMap(form));
  }, [Object.keys(form.values).length])

  const contactMenu = useMenu();
  const addContact = (key: string) => () => {
    form.updateValues('otherContacts',
      [...(form.values.otherContacts ?? []), { type: key, value: '', name: '', remarks: '', }]
    );
  };
  const removeContact = (i: number) => () => {
    form.updateValues('otherContacts', (form.values.otherContacts ?? []).filter((_: any, _i: number) => i !== _i));
  };

  const bindFeature = (featureKey: keyof ClientDetailDTO, type: string) => {
    const featureValues = (form.values[featureKey] as ClientDetailDTO['clientFeatures'])?.filter(cf => cf.type === type).map(cf => cf.value) ?? [];

    const setFeatureValues = (newList: string[]) => form.updateValues(featureKey, [
      ...((form.values[featureKey] as ClientDetailDTO['clientFeatures']) ?? [])?.filter(cf => cf.type !== type),
      ...newList.map(value => ({ type, value })),
    ]);

    return {
      value: featureValues,
      onChange: (ev: React.ChangeEvent<any>) => setFeatureValues(ev.target.value as string[])
    }
  }

  //validation of contact values
  const invalidContactsPhoneNum = form.hasError('invalidPhoneNumber');//list of index concat with '_'
  const invalidContactsEmail = form.hasError('invalidEmail');//list of index concat with '_'
  const emptyContactValue = form.hasError('emptyContactValue');//list of index concat with '_'

  const isInvalidContact = (idx: number, type: string) => {
    return getInvalidContactErrMsg(invalidContactsPhoneNum, invalidContactsEmail, emptyContactValue, idx, type, langClientDetail);
  };


  //-------------- autocomplete for agent ------------

  const [agents, setAgents] = useState<UserListItemDTO[]>([]);
  const [agentListLoading, setAgentListLoading] = useState(false);

  const fetchAgents = (keyword: string) => {
    if (keyword.length < 2) { return; }
    setAgentListLoading(true);
    userApi.getListForAutocomplete({ name: keyword, onlyHasCreateOwnClientPrivilege: true }, token ?? '').then(result => {
      if (result.data?.content) {
        setAgents(result.data!.content);
        setAgentListLoading(false);
        setAgentUsername(result.data!.content.filter(agent => agent.username != 'companyStockAgent').map((agent) => agent.username));

      }
    });
  }

  const [agentUsername, setAgentUsername] = useState(agents.filter(agent => agent.username != 'companyStockAgent').map((agent) => agent.username));

  const agentMap: { [name: string]: UserListItemDTO } = {};
  agents.forEach((agent) => {
    agentMap[agent.username] = agent;
  })
  const [keywordOfAgent, setKeywordOfAgent] = useState('')

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchAgents(keywordOfAgent);
    }, 1000);
    return () => clearTimeout(timer);
  }, [keywordOfAgent]);



  //update form.agentContacts
  useEffect(() => {
    if (isNonEmpty(agentContactsForm.values.agentId)) {
      form.setValues({
        ...form.values,
        agentId: agentContactsForm.values.agentId,
        agentNameEn: agentContactsForm.values.agentNameEn,
        agentNameZh: agentContactsForm.values.agentNameZh,
        agentContact: agentContactsForm.values.agentContact,
      });
    }
  }, [agentContactsForm.values]);

  //-------------- end autocomplete for agent field
  //update form.values.agentId 
  useEffect(() => {
    form.setValues({
      ...form.values,
      agentFieldRequired: displayAgentSection,
    })
  }, [displayAgentSection]);

  return (
    <div>

      <Grid>{alertDialog.render({ zIndex: 6000 })}</Grid>
      <Grid> {confirmCreateDialog.render({ zIndex: 7000 })}</Grid>
      <Dialog maxWidth={'md'} style={{ zIndex: 5000 }} {...dialogProps}>
        <form
          autoComplete="off"
          noValidate
        >
          <DialogTitle>{langClientDetail.actionNewClient}</DialogTitle>
          <Divider />
          <DialogContent>
            {displayCompanyClientCheckbox ?
              <Grid item md={12} xs={12}>
                <FormControl component="fieldset" >
                  <FormGroup aria-label="is-company-client-checkbox" row>
                    <FormControlLabel
                      control={<Checkbox color="primary" />}
                      label={langClientDetail.companyClient}
                      labelPlacement="end"
                      checked={form.values.isCompanyClient ?? false}
                      onChange={() => {
                        if (!forceCompanyClientChecked) {
                          //update checkbox value
                          form.updateValues('isCompanyClient', !form.values.isCompanyClient);
                        }
                      }}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              : null}
            <Grid container spacing={1}>
              <Grid item md={4} sm={4} xs={12}>
                <TextField
                  {...form.bind('chineseName')}
                  fullWidth
                  label={langClientDetail.clientName}
                  margin="dense"
                  required
                  variant="outlined"
                  onChange={(e) => {
                    limitTextFieldLength(e, 255, 'chineseName', form, keyBooleanMap, setKeyBooleanMap);
                  }}
                />
                <HandleFormHelperText
                  isError={keyBooleanMap.get('chineseName')}
                  errorMessage={lang.textOverLimit}
                />
              </Grid>
              {/*   
              <Grid item md={3} sm={6} xs={12}>
                <TextField
                  fullWidth
                  {...form.bind('englishName')}
                  label={langClientDetail.englishName}
                  margin="dense"
                  name="email"
                  required
                  variant="outlined"
                />
              </Grid> */}

              <Grid item md={4} sm={4} xs={12}>
                <FormControl variant="outlined" margin="dense" fullWidth>
                  <InputLabel required variant="outlined" style={{ background: '#fff' }}>{langClientDetail.clientType}</InputLabel>
                  <Select
                    multiple
                    MenuProps={{
                      style: { zIndex: 5500 }
                    }}
                    {...bindFeature('clientFeatures', 'clientType')}
                    renderValue={(selected) => (selected as string[]).map(key => clientTypeOptions[key]).join(', ')}
                  >
                    {Object.keys(clientTypeOptions).filter(key => key === 'L').map(key => (
                      <MenuItem disabled key={key} value={key}>
                        <Checkbox disabled checked={bindFeature('clientFeatures', 'clientType').value.indexOf(key) > -1} />
                        <ListItemText primary={clientTypeOptions[key]} />
                      </MenuItem>
                    ))}
                  </Select>
                  {/* {tagForm.hasError('clientType') ?  <FormHelperText error>{tagForm.hasError('clientType')}</FormHelperText> : null} */}
                </FormControl>
              </Grid>

              <Grid
                item
                md={4}
                sm={4}
                xs={12}
              >
                {/* <TextField
                  fullWidth
                  label={langClientDetail.sourceOfClient}
                  margin="dense"
                  select
                  required
                  variant="outlined"
                  {...form.bind('source')}
                  style={{zIndex: 5500}} 
                >
                  {
                    Object.keys(sourceOfClientOptions).map((key) =>
                      <MenuItem style={{zIndex: 5500}} value={key}>{sourceOfClientOptions[key]}</MenuItem>)
                  }
  
                </TextField> */}
                <FormControl variant="outlined" margin="dense" fullWidth>
                  <InputLabel style={{ background: '#fff' }}>{langClientDetail.sourceOfClient}</InputLabel>
                  <Select
                    // required
                    MenuProps={{
                      style: { zIndex: 5500 }
                    }}
                    {...form.bind('source')}
                  >
                    {
                      Object.keys(sourceOfClientOptions).map((key, idx) =>
                        <MenuItem key={idx} value={key}>{sourceOfClientOptions[key]}</MenuItem>)
                    }
                  </Select>
                </FormControl>
              </Grid>




              <Grid
                item
                md={12}
                xs={12}
              >
                <Typography color={!!form.hasError('otherContacts') ? 'error' : 'inherit'}>
                  {langClientDetail.contactType + '*'}
                  <Tooltip title={lang.actionAdd}>
                    <IconButton {...contactMenu.buttonProps()} color="primary">
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>

                {!!form.hasError('otherContacts') ?
                  <FormHelperText error={!!form.hasError('otherContacts')}>{langClientDetail.msgAtLeastOneContact}</FormHelperText>
                  : null}

                <Menu style={{ zIndex: 5500 }}  {...contactMenu.menuProps()}>
                  {Object.keys(contactOptions).map((key, idx) => {
                    return <MenuItem key={idx} onClick={addContact(key)}>{contactOptions[key]}</MenuItem>
                  })}
                </Menu>

              </Grid>

              {(form.values.otherContacts ?? []).map((contact: { type: string, value: string, name?: string, remarks?: string }, i: number) => {
                return <Grid item md={12} container spacing={1}>

                  <Grid
                    item
                    md={4}
                    sm={4}
                    xs={12}
                  >
                    <Typography style={{ display: 'flex', alignItems: 'center', flexGrow: 2, width: 200 }}>
                      <IconButton key={i} onClick={() => {
                        removeContact(i)();
                      }}>
                        <DeleteIcon />
                      </IconButton>
                      {contactOptions[contact.type]}{' : '}
                    </Typography>

                  </Grid>


                  {/* --------- contact.value ----------- */}
                  <Grid
                    item
                    md={4}
                    sm={4}
                    xs={12}
                  >

                    <TextField
                      style={{}}
                      value={contact.value}
                      label={langClientDetail.contactNoOrEmail}
                      error={!!isInvalidContact(i, contact.type)}
                      helperText={isInvalidContact(i, contact.type)}
                      // onBlur={(e) => handleDuplicatedContactWarning(e.target.value)}
                      onChange={(e) => {
                        if (keyBooleanMap && setKeyBooleanMap) {
                          setKeyBooleanMap(keyBooleanMap.set('otherContacts', e.target.value.length > 255))
                        }
                        if (e.target.value.length > 255) {
                          e.preventDefault();
                          const updatedContact = { ...contact, value: e.target.value.substring(0, 255) };
                          setContactDetail({ type: contact.type, value: e.target.value.substring(0, 255) });
                          //update form
                          form.updateValues('otherContacts',
                            form.values.otherContacts?.map((c: Object, idx: number) => idx === i ? updatedContact : c)
                          );
                        } else {
                          const updatedContact = { ...contact, value: e.target.value };
                          setContactDetail({ type: contact.type, value: e.target.value });
                          //update form
                          form.updateValues('otherContacts',
                            form.values.otherContacts?.map((c: Object, idx: number) => idx === i ? updatedContact : c)
                          );
                        }
                      }}
                      InputProps={{
                        endAdornment: duplicatedContactValues.some(d => d.type === contact.type && d.value === contact.value) ? <InputAdornment position="end">
                          <IconButton onClick={() => {

                            alertDialog.confirm(langClientDetail.msgDuplicatedContact, lang.actionConfirm, lang.actionConfirm);
                          }}>
                            <WarningIcon color='error' />
                          </IconButton>
                        </InputAdornment> : null,
                      }}
                      fullWidth
                      margin="dense"
                      required
                      variant="outlined"

                    />
                    <HandleFormHelperText
                      isError={keyBooleanMap.get('otherContacts')}
                      errorMessage={lang.textOverLimit}
                    />
                  </Grid>
                  {/* --------- contact.name (HIDDEN) ----------- */}
                  <Grid
                    item
                    md={4}
                    sm={4}
                    xs={12}
                    style={{ display: 'none' }}
                  >
                    <TextField
                      style={{}}
                      value={contact.name}
                      label={langClientDetail.contactName}
                      onChange={(e) => {
                        const updatedContact = { ...contact, name: e.target.value };
                        //update form
                        form.updateValues('otherContacts',
                          form.values.otherContacts?.map((c: Object, idx: number) => idx === i ? updatedContact : c)
                        );
                      }}
                      fullWidth
                      margin="dense"
                      variant="outlined"
                    />
                  </Grid>

                  <Grid
                    item
                    md={4}
                    sm={4}
                    xs={12}
                  >
                    <TextField
                      style={{}}
                      value={contact.remarks}
                      label={langClientDetail.contactRemarks}
                      onChange={(e) => {
                        if (keyBooleanMap && setKeyBooleanMap) {
                          setKeyBooleanMap(keyBooleanMap.set('contactRemarks', e.target.value.length > 255))
                        }
                        if (e.target.value.length > 255) {
                          e.preventDefault();
                          const updatedContact = { ...contact, remarks: e.target.value.substring(0, 255) };
                          //update form
                          form.updateValues('otherContacts',
                            form.values.otherContacts?.map((c: Object, idx: number) => idx === i ? updatedContact : c)
                          );
                        } else {
                          const updatedContact = { ...contact, remarks: e.target.value };
                          //update form
                          form.updateValues('otherContacts',
                            form.values.otherContacts?.map((c: Object, idx: number) => idx === i ? updatedContact : c)
                          );
                        }
                      }}
                      fullWidth
                      margin="dense"
                      variant="outlined"
                    />
                    <HandleFormHelperText
                      isError={keyBooleanMap.get('contactRemarks')}
                      errorMessage={lang.textOverLimit}
                    />
                  </Grid>
                </Grid>
              })}

              {/* --------------  agent section ---------------- */}

              <Grid
                item
                md={12}
                xs={12}
                style={{ display: displayAgentSection ? 'block' : 'none' }}
              >
                <Typography style={{ paddingTop: theme.spacing(1) }}>
                  {langClientDetail.agent}
                </Typography>
              </Grid>
              <Grid
                item
                md={4}
                sm={4}
                xs={12}
                style={{ display: displayAgentSection ? 'block' : 'none' }}
              >

                <Autocomplete
                  classes={{
                    popper: classes.agentAutoCompletePopper
                  }}
                  popupIcon={''}
                  options={agentUsername}
                  noOptionsText={''}
                  loading={agentListLoading}
                  loadingText={lang.msgLoading}
                  filterOptions={(option) => option}
                  renderOption={(option) => <div>
                    <div><Typography >{`${agents.filter(agent => agent.username === option)?.[0]?.englishName ?? ''}${agents.filter(agent => agent.username === option)?.[0]?.chineseName ? '/' : ''}${agents.filter(agent => agent.username === option)?.[0]?.chineseName ?? ''}`}</Typography></div>
                    <div><Typography variant="caption">{agents.filter(agent => agent.username === option)?.[0]?.mainContact}</Typography></div>
                  </div>}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      error={!!form.hasError('missingAgent')}
                      helperText={form.hasError('missingAgent')}
                      className={classes.patchOutlineAutocomplete}
                      fullWidth
                      label={langClientDetail.contactName}
                      margin="dense"
                      required
                      variant="outlined"
                      onFocus={(ev) => {
                        setAgentUsername([]);
                        setKeywordOfAgent(ev.target.value);
                      }}
                      onChange={(ev) => {
                        // fetchAgents(ev.target.value)
                        setAgents([]);
                        setAgentUsername([]);
                        setKeywordOfAgent(ev.target.value);
                        setAgentListLoading(true);
                        if (ev.target.value.length < 2 || !isNonEmpty(ev.target.value)) {
                          setAgentListLoading(false);
                        }
                      }}
                    />
                  )}
                  value={multiLang(locale, agentContactsForm?.values.agentNameZh, agentContactsForm?.values.agentNameEn)}
                  onChange={(_: any, val: any) => {
                    if (agentMap[val]) {
                      const { id, chineseName, englishName, mainContactType, mainContact } = agentMap[val];
                      agentContactsForm?.setValues({
                        ...agentContactsForm?.values,
                        agentId: id,
                        agentNameEn: englishName,
                        agentNameZh: chineseName,
                        agentContact: {
                          ...agentContactsForm?.values.agentContact,
                          type: mainContactType,
                          value: mainContact
                        }
                      })

                    } else {
                      agentContactsForm?.setValues({
                        ...agentContactsForm?.values,
                        agentId: undefined,
                        agentNameEn: undefined,
                        agentNameZh: undefined,
                        agentContact: {
                          ...agentContactsForm?.values.agentContact,
                          agentId: undefined,
                          type: undefined,
                          value: undefined
                        }
                      });
                    }
                  }}
                  getOptionLabel={() => multiLang(locale, agentContactsForm.values.agentNameZh, agentContactsForm.values.agentNameEn) ?? ''}
                />
              </Grid>


              <Grid
                item
                md={4}
                sm={4}
                xs={12}
                style={{ display: displayAgentSection ? 'block' : 'none' }}
              >
                <TextField
                  style={{}}
                  value={(form.values.isCompanyClient === true || !isNonEmpty(form.values.agentId)) ? '' : contactOptions[agentContactsForm?.values.agentContact?.type]}
                  label={langClientDetail.contactType}
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                />
              </Grid>

              <Grid
                item
                md={4}
                sm={4}
                xs={12}
                style={{ display: displayAgentSection ? 'block' : 'none' }}
              >
                <TextField
                  style={{}}
                  value={(form.values.isCompanyClient === true || !isNonEmpty(form.values.agentId)) ? '' : agentContactsForm?.values.agentContact?.value}
                  label={langClientDetail.contactNoOrEmail}
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                />
              </Grid>


            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={close} color="primary">
              {lang.actionCancel}
            </Button>
            <Button
              onClick={() => {
                handleSave();
              }}
              color="primary"
              variant="contained"
            >
              {lang.actionSave}
            </Button>
          </DialogActions>
        </form>

      </Dialog>
    </div>
  );
}

export default ContactCreationDialog;