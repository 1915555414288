import { PrivilegeDTO } from './dto';

export type OperationType = 'CREATE' | 'READ' | 'UPDATE' | 'DELETE' | 'APPROVE';
export type ResourceType = 'USER' |
  'USER_PROFILE' |
  'OPTION' |
  'SYSTEM_SETTING' |
  'AGENT_ACTIVITY_ENQUIRY' |
  'BUILDING' |
  'TRANSACTION' |
  'CLIENT' |
  'OWN_CLIENT' |
  'TEAM_CLIENT' |
  'UNOWNED_CLIENT' |
  'COMPANY_CLIENT' |
  'ASSIGN_OWN_AGENT_CLIENT' |
  'ASSIGN_TEAM_AGENT_CLIENT' |
  'ASSIGN_UNOWNED_AGENT_CLIENT' |
  'PROPERTY_STOCK' |
  'OWN_PROPERTY_STOCK' |
  'TEAM_PROPERTY_STOCK' |
  'UNOWNED_PROPERTY_STOCK' |
  'COMPANY_STOCK' |
  'CLOSE_TRANSACTION' |
  'SALES_COMMISSION_REPORT' |
  'MEMO_ENQUIRY' |
  'OWN_CLIENT_MEMO' |
  'TEAM_CLIENT_MEMO' |
  'DISTRICT_CLIENT_MEMO' |
  'UNOWNED_CLIENT_MEMO' |
  'OWN_PROPERTY_STOCK_MEMO' |
  'TEAM_PROPERTY_STOCK_MEMO' |
  'DISTRICT_PROPERTY_STOCK_MEMO' |
  'UNOWNED_PROPERTY_STOCK_MEMO' |
  'IMPERSONATION' |
  'BEING_IMPERSONATED' |
  'ROLE' |
  'CLIENT_APP_FEATURED_TAGS'|
  'DASHBOARD_COMMISSION'|
  'DASHBOARD_NEWCLIENTS'|
  'DASHBOARD_NEWCLIENTUPDATES'|
  'DASHBOARD_NEWPROPERTIES'|
  'DASHBOARD_NEWPROPERTYUPDATES'|
  'DASHBOARD_CLIENTPROPERTYVISITS' |
  'PREMIUM_PROPERTY' |
  'TRANSACTION_RESTRICTED' | 
  'CLIENT_APP_AGENT_RATING_COMMENT' |
  'UNOWNED_PROVISIONAL_AGREEMENT' |
  'REVEALED_SELLER_INFO' | 
  'JOB_MONITOR'
;

export function hasPermission(privileges: PrivilegeDTO[] | undefined, operation: OperationType, resource: ResourceType, owned: number | null = null, approvalStage: number | null = null) {
  if (!privileges) {
    return false;
  }
  return privileges.filter(privilege => {
    return (operation as string) === privilege.operation && (resource as string) === privilege.resource &&
      (owned === null || privilege.owned === null || owned === privilege.owned) &&
      (approvalStage == null || privilege.minimumApprovalStage === null || privilege.minimumApprovalStage <= approvalStage)
    ;
  }).length > 0;
}