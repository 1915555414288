import { Button, ButtonGroup, Card, CardContent, CardHeader, Checkbox, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormHelperText, Grid, Grow, IconButton, MenuItem, MenuList, Popover, Popper, Switch, TextField, Tooltip, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import HistoryIcon from '@material-ui/icons/History';
import ListIcon from '@material-ui/icons/List';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { propertyApi } from 'api';
import fileApi from 'api/fileApi';
import userApi from 'api/userApi';
import { hasPermission } from 'common/access-control';
import { useConfirmDialog } from 'common/ConfirmDialog';
import { Form4PropertyDTO, PropertyAttachmentDTO, PropertyListItemDTO, PropertyStockDTO, UserDTO, UserListItemDTO } from 'common/dto';
import { FormPresenter, useForm } from 'common/hooks';
import { AddIcon, EditPsStatusIcon, HandleFormHelperText } from 'common/ui';
import { genAddress, initFormKeysAndBooleanMap, isNonEmpty, limitTextFieldLength, multiLang, resetFileInput } from 'common/utils';
import moment from 'moment';
import { basename, extname } from 'path';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState, PASDispatch } from 'reducers';
import theme from 'theme';
import { format, isNullOrUndefined } from 'util';

const DivNode = (props: React.HTMLAttributes<HTMLDivElement>) => <div {...props} />;

const PREVIEWABLE_EXTENSIONS = ['.pdf', '.jpg', '.jpeg', '.gif', '.png'];

const useStyles = makeStyles({
  table: {
    minWidth: 750,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'auto',
  },
  typography: {
    padding: theme.spacing(2),
  },
  patchOutlineAutocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
      paddingLeft: 8,
    }
  },
});

const EMPTY = {};


const disablePrevDates = (startDates: any) => {
  const startSeconds = Date.parse(startDates);
  return (date: any) => {
    return Date.parse(date) < startSeconds;
  }
}

interface DocumentsSectionProps {
  saveDisabled?: boolean;
  sectionTitle: string;
  fileForm: any;
  docTypeOptions: { [key: string]: string };
  elementIdPrefix: string;
  hideRemarksInListTable?: boolean;

  //for property stock page
  stockForm?: FormPresenter<PropertyStockDTO>;
  clientFileForm?: any; //for display only
  canDownloadOrDeleteFormFiles?: boolean; //deprecated
  canDownloadPaFiles?: boolean;//deprecated
  canUploadPropertyDocFiles?: boolean;
  shouldDisableUpload?: boolean
  canEditPa?: boolean;
  canEditForm?: boolean;
  isPaAgent?: boolean; //deprecated

  //for client detail page
  uploadFormType?: string;
  openDialog?: boolean; //open the upload dialog
  setOpenDialog?: Dispatch<SetStateAction<boolean>>;
  isClientPage?: boolean;
  selectedProperties?: PropertyListItemDTO[];
  isClientAgent?: boolean;

  //close transaction 
  isCtDialog?: boolean;
  //*ct-TODO* 
  canUploadCloseTrnxSupp?: boolean;
  canDownloadUnownedCloseTrnxSupp?: boolean;
  canDownloadUnownedPa?: boolean;

  // close transaction form checking table
  isFormPaTableForCloseTrnx?: boolean;

  //for property stock preview
  isPreviewPropertyStockPage?: boolean;

  // appearance
  noCardView?: boolean;

  // Expand all history records to the table
  expandedMode?: boolean;

  // Show all form download anyway
  showAllFormsDownloadAnyway?: boolean;

  //show form4/6
  showForm46?: boolean;
  //show form3/5
  showForm35?: boolean;

  //get closetransaction status
  closeTransactionStatus?: string

  //isCreationePage
  isCreatePage?: boolean;

  teamMemberList?: UserDTO[];
}

const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];
const errorColor = '#e53935';

const Documents = ({
  saveDisabled, sectionTitle, docTypeOptions, fileForm, stockForm, clientFileForm,
  openDialog, setOpenDialog, isClientPage, selectedProperties, uploadFormType,
  canUploadPropertyDocFiles, shouldDisableUpload, canEditPa, canEditForm, isClientAgent, elementIdPrefix, isFormPaTableForCloseTrnx,
  isCtDialog, canUploadCloseTrnxSupp, canDownloadUnownedCloseTrnxSupp,
  isPreviewPropertyStockPage, hideRemarksInListTable, noCardView, canDownloadUnownedPa,
  expandedMode, showAllFormsDownloadAnyway, showForm46, showForm35, closeTransactionStatus, isCreatePage, teamMemberList }: DocumentsSectionProps) => {

  //
  const classes = useStyles();
  const { lang, langPropertyStock, districtHkiOptions, districtKltOptions, districtNtOptions, locale, langTenancyAgreement, langSalePurchaseAgreement } = useSelector((state: IRootState) => state.locale);
  const dispatch = useDispatch() as PASDispatch;
  const currentUid = useSelector((state: IRootState) => state.login.uid);
  const token = useSelector((state: IRootState) => state.login.token) ?? '';
  const privileges = useSelector((state: IRootState) => state.login.privileges);
  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);
  const propertyStockSettings = useSelector((state: IRootState) =>
    state.systemSettings?.PropertyStock) ?? {};
  const confirmDialog = useConfirmDialog();
  const clientSettings = useSelector((state: IRootState) =>
    state.systemSettings?.Client) ?? {};
  const { uid, chineseName, englishName, licenseNumber } = useSelector((state: IRootState) => state.login);

  const currentSalePurchaseAgreement = useSelector((state: IRootState) => state.property.salePurchaseAgreement);
  const currentTenancyAgreement = useSelector((state: IRootState) => state.property.tenancyAgreement);

  // Check if the files belong to own team member
  const isTeamMemberFile = (authorID: string) => {
    if (teamMemberList != undefined) {
      for (let member of teamMemberList) {
        if (authorID === member.id?.toString()) {
          return true;
        }
      }
    }
    return false;
  }

  //------------ dialog event
  const [docUploadDialogOpen, setDocUploadDialogOpen] = useState(false);
  const handleDocUploadDialogClose = () => {
    setDocUploadDialogOpen(false);
    if (!isNullOrUndefined(setOpenDialog)) setOpenDialog(false); //form4,6
  }

  //------------ Upload Requirements
  const [reqPopupOpened, setReqPopupOpened] = useState(false);
  const [anchorEl, setAnchorEl] = useState(undefined);
  const openPopup = (ev: any) => {
    setAnchorEl(ev.currentTarget);
    setReqPopupOpened(true);
  };
  const closePopup = () => {
    setAnchorEl(undefined);
    setReqPopupOpened(false);
  };

  //------------  upload button

  // const docTypeOptions : {[key:string] :string} = {
  //   DUMMY: lang.actionUpload,
  //   LAND_SEARCH: langPropertyStock.landSearch,
  //   SA_SEARCH: langPropertyStock.saSearch,
  //   // OP: langPropertyStock.occPermit,
  //   COMPANY_SEARCH: langPropertyStock.businessReg,
  //   OTHERS: langPropertyStock.otherDoc,
  //   FORM_1: langPropertyStock.form1,
  //   FORM_2: langPropertyStock.form2,
  //   FORM_3: langPropertyStock.form3,
  //   FORM_4: langPropertyStock.form4,
  //   FORM_5: langPropertyStock.form5,
  //   FORM_6: langPropertyStock.form6,
  //   PROVISIONAL_AGREEMENT: langPropertyStock.provisionalAgreement,
  //   SUPPLEMENTAL_DOCUMENT: langPropertyStock.provisionalAgreementSuppDoc,
  // };
  const propertyStatus = stockForm?.values.status ?? '';

  const allowUpload = (type: string) => {
    switch (type) {
      case 'LAND_SEARCH':
      case 'SA_SEARCH':
      // case 'OP': return propertyStockSettings[`OP_UPLOAD_POLICY`];
      case 'COMPANY_SEARCH':
      case 'OTHERS':
        return canUploadPropertyDocFiles;
      case 'FORM_1':
      case 'FORM_3':
        return canEditForm && !(propertyStatus === 'RENT');
      case 'FORM_2':
      case 'FORM_5':
        return canEditForm && !(propertyStatus === 'SALES');
      case 'FORM_4':
      case 'FORM_6':
        return false;
      case 'PROVISIONAL_AGREEMENT':
      case 'SUPPLEMENTAL_DOCUMENT':
        return canEditPa;
      case 'CLOSE_TRNX_SUPP':
        //*ct-TODO* can upload or not
        return canUploadCloseTrnxSupp;
      default: return true;
    }
  }

  const hideUploadButton = isPreviewPropertyStockPage ? true
    : isCtDialog ? !allowUpload('CLOSE_TRNX_SUPP')
      : ((propertyStatus === 'PENDING' || propertyStatus === 'LEASED' || propertyStatus === 'SOLD'));

  const buttonOptions = Object.keys(docTypeOptions)
    .filter(docType => {
      if (isClientPage) {
        return docType === 'FORM_4' || docType === 'FORM_6' || docType === 'DUMMY';
      } else {
        return allowUpload(docType);
      }
    }
    );

  const fileListOptions = Object.keys(docTypeOptions)
    .filter(docType => {
      if (isClientPage) {
        return docType === 'FORM_4' || docType === 'FORM_6';
      } else {
        return !(docType === 'DUMMY');
        // special handling on SUPPLEMENTAL_DOCUMENT and CLOSE_TRNX_SUPP are removed.
        // docType === 'SUPPLEMENTAL_DOCUMENT' || docType === 'CLOSE_TRNX_SUPP'
      }
    });

  const isForm = (fileType: string) => {
    return fileType.includes('FORM_');
  }

  const getStarForNonHKID = (file: any) => {
    const isNonHKID = file?.isHKID === false;
    return isNonHKID ? ' *' : '';
  }

  const needNonHkidRemarks = (fileType: string) => {
    return fileType === 'FORM_3' || fileType === 'FORM_4' || fileType === 'FORM_5' || fileType === 'FORM_6';
  }




  const needNonHkidRemarksInListTable = Object.keys(docTypeOptions).filter(key => needNonHkidRemarks(key) && (isNonEmpty(getStarForNonHKID(fileForm.values[key]?.[0])) || isNonEmpty(getStarForNonHKID(clientFileForm?.values[key]?.[0]))))?.length ?? 0 > 0;

  const printNonHkidRemarks = () => (
    <Typography style={{ paddingTop: '5px' }}>{langPropertyStock.msgRemarksForNonHKID}</Typography>
  );

  const getFileTypeAbbr = (fileType: string) => {
    const formNo = fileType.split('_')[1];
    switch (fileType) {
      case 'FORM_1':
      case 'FORM_3':
      case 'FORM_2':
      case 'FORM_5':
      case 'FORM_4':
      case 'FORM_6':
        return locale === 'en' ? 'Form ' + formNo : '表格' + formNo;
      default:
        return '';
    }
  }



  const [openButtonMenuList, setOpenButtonMenuList] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const defaultUploadDocType = isCtDialog ? 'CLOSE_TRNX_SUPP' : 'DUMMY';

  const [selectedUploadDocType, setSelectedUploadDocType] = React.useState(defaultUploadDocType);

  const handleClick = () => {
    // console.info(`You clicked ${docTypeOptions[selectedUploadDocType]}`);
    // document.getElementById(idMap(selectedDocType))?.click();
    // setDocUploadDialogOpen(selectedUploadDocType === 'DUMMY' ? false : true);
    setOpenButtonMenuList(true);
  };

  const canUpdateUnowned = hasPermission(privileges, 'UPDATE', 'UNOWNED_PROPERTY_STOCK');

  const handleMenuItemClick = async (
    _: React.MouseEvent<HTMLLIElement, MouseEvent>,
    type: string,
  ) => {
    // pre-actions per type
    switch (type) {
      case 'LAND_SEARCH':
        if (!canUpdateUnowned) {
          dispatch({ type: 'Layout.MaskPresentRequested' });
          const confirmedToRequestLandSearch = await userApi.getCurrentUserLandSearchCredit(token).then(result => {
            dispatch({ type: 'Layout.MaskDismissRequested' });
            const credit = result.data ?? 0;
            const noEnoughCredit = credit === 0;
            return confirmDialog.confirm(<div>
              <div><Typography>{langPropertyStock.msgLandSearchRequestRequired}</Typography></div>
              <div><Typography>{noEnoughCredit ? langPropertyStock.msgLandSearchRequestOutOfCredit : format(langPropertyStock.msgConfirmLandSearchRequest, credit)}</Typography></div>
              <div><Typography>&nbsp;</Typography></div>
              <div><Typography>{langPropertyStock.msgManualUploadLandSearch}</Typography></div>
            </div>, noEnoughCredit ? '' : langPropertyStock.actionRequestForLandSearch, langPropertyStock.actionManualUploadLandSearch, langPropertyStock.titleLandSearchRequest, lang.actionCancel).then(async confirmed => {
              if (isCreatePage && confirmed) {
                confirmDialog.confirm(lang.langSearchInCreatePage, lang.actionConfirm, '', langPropertyStock.titleLandSearchRequest);
                return confirmed;
              } else if (confirmed) {
                dispatch({ type: 'Layout.MaskPresentRequested' });
                const landSearchReq = await propertyApi.requestLandSearch(stockForm?.values.id ?? '0', token);
                dispatch({
                  type: 'Layout.AlertMessageAdded', payload: {
                    message: landSearchReq.data ? langPropertyStock.msgLandSearchRequestSuccess : langPropertyStock.msgLandSearchRequestFailed,
                    severity: 'error',
                  }
                });
                dispatch({ type: 'Layout.MaskDismissRequested' });
                return confirmed;
              }

              return confirmed;
            });
          });

          if (confirmedToRequestLandSearch) {
            return;
          }
        } else {
          form.updateValues('confirmed', true);
        }
        break;

      case 'PROVISIONAL_AGREEMENT':
        if (fileForm.values['PROVISIONAL_AGREEMENT']?.length > 0) {
          confirmDialog.confirm(langPropertyStock.msgDeleteExsitingPaBeforeUpload, '', lang.actionConfirm);
          return;
        }
        break;
      case 'FORM_3':
        if (stockForm?.values.hasUnapprovedForm3) {
          confirmDialog.confirm(langPropertyStock.msgDeleteExisingForm3, '', 'OK');
          return;
        }
        // Handle upload more than one file at the same time
        if (fileForm.values['FORM_3']?.filter((attachment: any) => attachment.id == null || attachment.id == undefined)?.length > 0) {
          confirmDialog.confirm(langPropertyStock.msgDeleteExisingForm3, '', 'OK');
          return;
        }
        break;
      case 'FORM_5':
        if (stockForm?.values.hasUnapprovedForm5) {
          confirmDialog.confirm(langPropertyStock.msgDeleteExisingForm5, '', 'OK');
          return;
        }
        // Handle upload more than one file at the same time
        if (fileForm.values['FORM_5']?.filter((attachment: any) => attachment.id == null || attachment.id == undefined)?.length > 0) {
          confirmDialog.confirm(langPropertyStock.msgDeleteExisingForm5, '', 'OK');
          return;
        }
        break;
      default:

    }
    form.stopValidation();
    paForm.stopValidation();
    setSelectedUploadDocType(type);
    setOpenButtonMenuList(false);
    setDocUploadDialogOpen(true)
  };

  const handleToggle = () => {
    setOpenButtonMenuList((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpenButtonMenuList(false);
  };

  //--------- file history 
  const [selectedHistoryDocType, setSelectedHistoryDocType] = React.useState('DUMMY');
  const [openFileHistoryDialog, setOpenFileHistoryDialog] = React.useState(false);
  const needNonHkidRemarksInHistoryDialog = () => {
    if (!needNonHkidRemarks(selectedHistoryDocType)) {
      return;
    }
    let fileFormToUse = fileForm;
    if (!isClientPage && (selectedHistoryDocType === 'FORM_4' || selectedHistoryDocType === 'FORM_6')) {
      fileFormToUse = clientFileForm ?? {};
    }
    return (fileFormToUse.values[selectedHistoryDocType]?.filter((file: any) => isNonEmpty(getStarForNonHKID(file)))?.length ?? 0) > 0;
  };

  const handleCloseFileHistoryDialog = () => {
    setOpenFileHistoryDialog(false);
  };

  // ---------

  const getDialogType = () => {
    switch (selectedUploadDocType) {
      case 'FORM_1':
      case 'FORM_2':
        return 'DIALOG_TYPE_FORM_1_2';
      case 'FORM_3':
      case 'FORM_4':
      case 'FORM_5':
      case 'FORM_6':
        return 'DIALOG_TYPE_FORM_3_4_5_6';
      case 'PROVISIONAL_AGREEMENT':
        return 'DIALOG_TYPE_PROVISIONAL_AGREEMENT';
      case 'SUPPLEMENTAL_DOCUMENT':
      case 'CLOSE_TRNX_SUPP':
        return 'DIALOG_TYPE_SUPPLEMENTAL_DOCUMENT';
      default: return 'DIALOG_TYPE_PROPERTY_DOC';
    }
  }

  const dialogType = getDialogType();

  const isValidityPeriodValid = (values: any) => {
    if (isNonEmpty(values.validityPeriodStart) && isNonEmpty(values.validityPeriodEnd)) {
      if (new Date(values.validityPeriodStart!) > new Date(values.validityPeriodEnd!)) {
        return false;
      } else {
        return true;
      }
    }
    return true;
  }

  const form = useForm<any>({}, {
    validations: [
      (values) => (dialogType === 'DIALOG_TYPE_FORM_1_2' || dialogType === 'DIALOG_TYPE_PROPERTY_DOC') && !values.date ? [['date', ' ']] : null,
      (values) => (dialogType === 'DIALOG_TYPE_SUPPLEMENTAL_DOCUMENT') && !values.name ? [['name', ' ']] : null,
      // (values) => dialogType === 'DIALOG_TYPE_PROPERTY_DOC' && !values.remarks ? [[ 'remarks', ' ' ]] : null,
      (values) => dialogType === 'DIALOG_TYPE_FORM_3_4_5_6' && !isValidityPeriodValid(values) ? [['validityPeriodStart', langPropertyStock.msgInputValidPeriodStartAndEnd]] : null,
      (values) => dialogType === 'DIALOG_TYPE_FORM_3_4_5_6' && !values.validityPeriodStart ? [['validityPeriodStart', ' ']] : null,
      (values) => dialogType === 'DIALOG_TYPE_FORM_3_4_5_6' && !values.validityPeriodEnd ? [['validityPeriodEnd', ' ']] : null,
      (values) => (dialogType === 'DIALOG_TYPE_FORM_1_2' || dialogType === 'DIALOG_TYPE_PROPERTY_DOC') && values.date && !moment(new Date).isSameOrAfter(values.date, 'day') ? [['date', ' ']] : null,
    ],
  });

  useEffect(() => {
    if (dialogType === 'DIALOG_TYPE_FORM_3_4_5_6') {
      if (!isNonEmpty(form.values.validityPeriodStart) && !isNonEmpty(form.values.validityPeriodEnd)) {
        form.setValues({
          ...form.values,
          validityPeriodStart: moment().hours(0).minutes(0).seconds(0).milliseconds(0).toISOString(),
          validityPeriodEnd: moment().add(6, 'M').hours(0).minutes(0).seconds(0).milliseconds(0).subtract(1, 'd').toISOString(),
        })


      }
    } else {
      form.setValues({
        ...form.values,
        validityPeriodStart: undefined,
        validityPeriodEnd: undefined,
      })
    }
  }, [dialogType, docUploadDialogOpen])

  const paForm = useForm<any>({
    provisionalAgreementType: 'SALE',
    buyerAgentType: 'I',
    buyerAgentUserId: uid,
    sellerAgentName: multiLang(locale, chineseName, englishName),
    sellerAgentNameEn: englishName,
    sellerAgentNameZh: chineseName,
    sellerAgentLicenseNumber: licenseNumber,
  }, {
    validations: [
      (values) => !values.signDate ? [['signDate', ' ']] : !moment(new Date).isSameOrAfter(values.signDate, 'day') ? [['signDate', ' ']] : null,
      // (values) => !values.sellerAgentUserId ? [[ 'sellerAgentUserId', ' ']] : null,
      (values) => values.sellerAgentType === 'E' && values.buyerAgentType === 'E' ? [
        ['invalidBuyerOrSellerAgentType', values.provisionalAgreementType === 'SALE' ? langSalePurchaseAgreement.msgInvalidBuyerOrSellerAgentType : langTenancyAgreement.msgInvalidTenantOrLandlordAgentType],
      ] : null,
      (values) => !values.sellerAgentName ? [['sellerAgentName', ' ']] : null,
      (values) => !values.sellerAgentType ? [['sellerAgentType', ' ']] : null,
      (values) => !values.sellerAgentLicenseNumber ? [['sellerAgentLicenseNumber', ' ']] : null,
      (values) => !values.buyerAgentName ? [['buyerAgentName', ' ']] : null,
      (values) => !values.buyerAgentType ? [['buyerAgentType', ' ']] : null,
      (values) => !values.buyerAgentLicenseNumber ? [['buyerAgentLicenseNumber', ' ']] : null,
      (values) => (values.buyerNames?.filter((v: string) => v?.length).length ?? 0) === 0 ? [['buyerNames', ' ']] : null,
      (values) => (values.sellerNames?.filter((v: string) => v?.length).length ?? 0) === 0 ? [['sellerNames', ' ']] : null,
      (values) => values.provisionalAgreementType === 'SALE' ? ((values.sellerAgentType === 'E' ? values.buyerAgentUserId?.toString() !== currentUid?.toString() : false) || (values.buyerAgentType === 'E' ? values.sellerAgentUserId?.toString() !== currentUid?.toString() : false) || values.sellerAgentUserId?.toString() !== currentUid?.toString() && values.buyerAgentUserId?.toString() !== currentUid?.toString()) ? [['sellerAgentName', langSalePurchaseAgreement.msgInvalidBuyerOrSellerAgent], ['buyerAgentName', langSalePurchaseAgreement.msgInvalidBuyerOrSellerAgent]] : null : null,
      (values) => values.provisionalAgreementType === 'RENTAL' ? ((values.sellerAgentType === 'E' ? values.buyerAgentUserId?.toString() !== currentUid?.toString() : false) || (values.buyerAgentType === 'E' ? values.sellerAgentUserId?.toString() !== currentUid?.toString() : false) || values.sellerAgentUserId?.toString() !== currentUid?.toString() && values.buyerAgentUserId?.toString() !== currentUid?.toString()) ? [['sellerAgentName', langSalePurchaseAgreement.msgInvalidBuyerOrSellerAgent], ['buyerAgentName', langSalePurchaseAgreement.msgInvalidBuyerOrSellerAgent]] : null : null,

    ],

  });
  //This useeffect will affect the results of close transaction and upload new PAform.
  //remove this will make sure clear all information when upload new PA form.
  useEffect(() => {
    if (paForm.values.provisionalAgreementType === 'SALE') {
      paForm.setValues((currentSalePurchaseAgreement?.buyerAgentUserId?.toString() === uid?.toString() || currentSalePurchaseAgreement?.sellerAgentUserId?.toString() === uid?.toString()) ? {
        ...paForm.values,
        signDate: currentSalePurchaseAgreement?.agreementDate,
        sellerAgentUserId: currentSalePurchaseAgreement?.sellerAgentUserId,
        sellerAgentNameEn: currentSalePurchaseAgreement?.sellerAgentNameEn,
        sellerAgentNameZh: currentSalePurchaseAgreement?.sellerAgentNameZh,
        sellerAgentName: currentSalePurchaseAgreement?.sellerAgentName,
        sellerAgentType: currentSalePurchaseAgreement?.sellerAgentType,
        sellerAgentLicenseNumber: currentSalePurchaseAgreement?.sellerAgentLicenseNumber,
        sellerNames: currentSalePurchaseAgreement?.vendors?.map(c => c.name) ?? [],
        buyerAgentUserId: currentSalePurchaseAgreement?.buyerAgentUserId,
        buyerAgentNameEn: currentSalePurchaseAgreement?.buyerAgentNameEn,
        buyerAgentNameZh: currentSalePurchaseAgreement?.buyerAgentNameZh,
        buyerAgentName: currentSalePurchaseAgreement?.buyerAgentName,
        buyerAgentType: currentSalePurchaseAgreement?.buyerAgentType,
        buyerAgentLicenseNumber: currentSalePurchaseAgreement?.buyerAgentLicenseNumber,
        buyerNames: currentSalePurchaseAgreement?.purchasers?.map(c => c.name) ?? [],
      } : {
        ...{}, provisionalAgreementType: 'SALE',
        sellerAgentType: 'I',
        sellerAgentUserId: uid,
        sellerAgentName: multiLang(locale, chineseName, englishName),
        sellerAgentNameEn: englishName,
        sellerAgentNameZh: chineseName,
        sellerAgentLicenseNumber: licenseNumber,
      });
    } else if (paForm.values.provisionalAgreementType === 'RENTAL') {
      paForm.setValues((currentTenancyAgreement?.landlordAgentUserId?.toString() === uid?.toString() || currentTenancyAgreement?.tenantAgentUserId?.toString() === uid?.toString()) ? {
        ...paForm.values,
        signDate: currentTenancyAgreement?.agreementDate,
        sellerAgentUserId: currentTenancyAgreement?.landlordAgentUserId,
        sellerAgentNameEn: currentTenancyAgreement?.landlordAgentNameEn,
        sellerAgentNameZh: currentTenancyAgreement?.landlordAgentNameZh,
        sellerAgentName: currentTenancyAgreement?.landlordAgentName,
        sellerAgentType: currentTenancyAgreement?.landlordAgentType,
        sellerAgentLicenseNumber: currentTenancyAgreement?.landlordAgentLicenseNumber,
        sellerNames: currentTenancyAgreement?.landlords?.map(c => c.name) ?? [],
        buyerAgentUserId: currentTenancyAgreement?.tenantAgentUserId,
        buyerAgentNameEn: currentTenancyAgreement?.tenantAgentNameEn,
        buyerAgentNameZh: currentTenancyAgreement?.tenantAgentNameZh,
        buyerAgentName: currentTenancyAgreement?.tenantAgentName,
        buyerAgentType: currentTenancyAgreement?.tenantAgentType,
        buyerAgentLicenseNumber: currentTenancyAgreement?.tenantAgentLicenseNumber,
        buyerNames: currentTenancyAgreement?.tenants?.map(c => c.name) ?? [],
      } : {
        ...{}, provisionalAgreementType: 'RENTAL',
        sellerAgentType: 'I',
        sellerAgentUserId: uid,
        sellerAgentName: multiLang(locale, chineseName, englishName),
        sellerAgentNameEn: englishName,
        sellerAgentNameZh: chineseName,
        sellerAgentLicenseNumber: licenseNumber,
      });
      //
    }
    else if (stockForm?.values?.isLeadAgent) {
      paForm.setValues({
        ...paForm.values,
        sellerAgentUserId: uid,
        sellerAgentNameEn: englishName,
        sellerAgentNameZh: chineseName
      });
    }
  }, [uid ?? EMPTY, stockForm?.values?.isLeadAgent ?? false, currentSalePurchaseAgreement, paForm.values.provisionalAgreementType]);
  // --------- transform upload logic in UploadPane
  const idMap = (type: string | undefined) => {
    switch (type) {
      case 'DUMMY': return 'file-dummy';
      case 'LAND_SEARCH': return 'file-land-search-upload';
      case 'SA_SEARCH': return 'file-sa-search-upload';
      // case 'OP': return 'file-op-upload'';
      case 'COMPANY_SEARCH': return 'file-company-search-upload';
      case 'OTHERS': return 'file-others-upload';
      case 'FORM_1': return 'file-form-1-upload';
      case 'FORM_2': return 'file-form-2-upload';
      case 'FORM_3': return 'file-form-3-upload';
      case 'FORM_4': return 'file-form-4-upload';
      case 'FORM_5': return 'file-form-5-upload';
      case 'FORM_6': return 'file-form-6-upload';
      case 'PROVISIONAL_AGREEMENT': return 'file-provisional-agreement-upload';
      case 'SUPPLEMENTAL_DOCUMENT': return 'file-supplemental-document-upload';
      case 'CLOSE_TRNX_SUPP': return 'file-ct-supplemental-document-upload';
      default: return '';
    }
  }

  const elementId = elementIdPrefix + '-' + idMap(selectedUploadDocType);

  //-------- upload policy
  const getUploadPolicy = (type: string) => {
    switch (type) {
      case 'DUMMY': return {};
      case 'LAND_SEARCH': return propertyStockSettings[`LAND_SEARCH_UPLOAD_POLICY`];
      case 'SA_SEARCH': return propertyStockSettings[`SA_SEARCH_UPLOAD_POLICY`];
      // case 'OP': return propertyStockSettings[`OP_UPLOAD_POLICY`];
      case 'COMPANY_SEARCH': return propertyStockSettings[`BR_UPLOAD_POLICY`];
      case 'OTHERS': return propertyStockSettings[`OTHERS_UPLOAD_POLICY`];
      case 'FORM_1': return propertyStockSettings[`FORM_1_UPLOAD_POLICY`];
      case 'FORM_2': return propertyStockSettings[`FORM_2_UPLOAD_POLICY`];
      case 'FORM_3': return propertyStockSettings[`FORM_3_UPLOAD_POLICY`];
      case 'FORM_4': return clientSettings[`FORM_4_UPLOAD_POLICY`];
      case 'FORM_5': return propertyStockSettings[`FORM_5_UPLOAD_POLICY`];
      case 'FORM_6': return clientSettings[`FORM_6_UPLOAD_POLICY`];
      case 'PROVISIONAL_AGREEMENT': return propertyStockSettings[`PROVISIONAL_AGREEMENT_UPLOAD_POLICY`];
      case 'SUPPLEMENTAL_DOCUMENT':
      case 'CLOSE_TRNX_SUPP':
        return propertyStockSettings[`SUPPLEMENTAL_DOCUMENT_UPLOAD_POLICY`];
      default: return {};
    }
  }

  const uploadPolicy = getUploadPolicy(selectedUploadDocType);

  const fileAccept = uploadPolicy.SUPPORT_FILE_TYPE?.split(',').map((str: string) => `.${str.trim().toUpperCase()}`);
  const uploadLimit = +(uploadPolicy.MAX_NUM_OF_FILE) ?? 0;
  const sizeLimit = (+(uploadPolicy.MAX_FILE_SIZE) * 1024 * 1024) ?? 0;

  // --------- Handle Upload
  const handleUpload = (type: string) => (files: File[], fields?: any) => {
    // 
    // let files = Array.from(ev.target.files ?? []);
    const currentLength = fileForm.values[type]?.length ?? 0;

    let remainLength = Infinity;
    if (uploadLimit) {
      remainLength = uploadLimit - currentLength > 0 ? uploadLimit - currentLength : 0;
      if (remainLength === 0) return;
    }

    // files = files.slice(0, remainLength);

    const hasUnsupportedFileFormats = files.map(f => f.name)
      .map(extname)
      .filter((ext: string) => fileAccept.indexOf(ext.toUpperCase()) < 0)
      ;

    if (hasUnsupportedFileFormats.length > 0) {
      dispatch({ type: 'Layout.AlertMessageAdded', payload: { message: format(lang.msgUnsupportedFileFormat, hasUnsupportedFileFormats.toString()) } })
      return;
    }

    const hasLimitExceededFiles = files.some(f => f.size > sizeLimit);

    if (hasLimitExceededFiles) {
      dispatch({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgFileSizeExceed } });
      return;
    }

    Promise.all(files.slice(0, remainLength).map(file => {
      return fileApi.addFile(file).then(result => result.data);
    })).then(paths => {
      // fileForm.updateValues(type, Array.from(new Set([ ...fileForm.values[type], ...paths ])));
      paths = paths.filter(path => fileForm.values[type].map((f: any) => f.filename).indexOf(path) < 0);
      fileForm.updateValues(type, [
        ...paths.map(path => ({ filename: path, type, manualUpload: true, ...(fields ?? {}), ...addSelectedProperties() })),
        ...fileForm.values[type],
      ]);

    });

    //reset
    // 
    handleDocUploadDialogClose();
    form.setValues({});
    paForm.setValues({
      provisionalAgreementType: 'SALE'
    });
    form.stopValidation();
    paForm.stopValidation();

  };

  //--------- delete action
  const handleDeleteFile = (type: string, i: number) => {
    const newList = [...fileForm.values[type]];
    newList.splice(i, 1);
    confirmDialog
      .confirm(lang.msgConfirmDelete, lang.actionYes, lang.actionNo)
      .then((confirmed: boolean) => {
        if (confirmed) {
          fileForm.updateValues(type, newList);
        }
      });
  };

  //---------- getTableRow()
  const getTableRow = (type: string, index: number, historyDialog: boolean) => {
    let fileFormToUse = fileForm;
    if (!isClientPage && (type === 'FORM_4' || type === 'FORM_6')) {
      fileFormToUse = clientFileForm ?? {};
    }
    if (!historyDialog && !isNonEmpty(fileFormToUse.values[type])) {
      return null;
    }
    let noActiveForm = false;
    if (fileFormToUse.values[type]?.filter((f: any) => f.dataStatus === 'I').length == fileFormToUse.values[type]?.length) {
      noActiveForm = true;
    }
    if (!historyDialog) {
      // if this is not history dialog, override index as the first active item
      const _f = fileFormToUse.values[type]?.filter((f: any) => f.dataStatus !== 'I')?.[0];
      index = fileFormToUse.values[type]?.indexOf(_f);
    }
    let file = fileFormToUse.values[type]?.[index] ?? {};
    if (type === 'FORM_3' && !historyDialog && isFormPaTableForCloseTrnx) {
      file = (fileFormToUse.values['FORM_3'] as PropertyAttachmentDTO[])[0]
    } else if ((type === 'FORM_5') && !historyDialog && isFormPaTableForCloseTrnx) {
      file = (fileFormToUse.values['FORM_5'] as PropertyAttachmentDTO[])[0]
    }

    const latestForm3 = fileFormToUse.values['FORM_3'] ? (fileFormToUse.values['FORM_3'] as PropertyAttachmentDTO[]).filter(f => f.id === stockForm?.values.latestValidForm3Id)[0] : undefined;
    const latestForm5 = fileFormToUse.values['FORM_5'] ? (fileFormToUse.values['FORM_5'] as PropertyAttachmentDTO[]).filter(f => f.id === stockForm?.values.latestValidForm5Id)[0] : undefined;
    /////// date column
    let date = file.date ? moment(file.date).format(DISPLAY_DATE_FORMAT) : '';
    if (type === 'FORM_3' || type === 'FORM_4' || type === 'FORM_5' || type === 'FORM_6') {
      const validityPeriodStart = file.validityPeriodStart ? moment(file.validityPeriodStart).format(DISPLAY_DATE_FORMAT) : '';
      const validityPeriodEnd = file.validityPeriodEnd ? moment(file.validityPeriodEnd).format(DISPLAY_DATE_FORMAT) : '';
      const uploadDate = file.dateCreated ? moment(file.dateCreated).format(DISPLAY_DATE_FORMAT) : '?';
      if (!historyDialog) {
        date = `${uploadDate} (${langPropertyStock.captionValidityPeriod}: ${validityPeriodStart} - ${validityPeriodEnd})`;
        if (type === 'FORM_3' || type === 'FORM_5') {
          const latestValidForm = type === 'FORM_3' ? stockForm?.values.latestValidForm3Id ?? 0 : stockForm?.values.latestValidForm5Id ?? 0;
          const latestForm = type === 'FORM_3' ? latestForm3 ?? 0 : latestForm5 ?? 0;
          if (isFormPaTableForCloseTrnx) {
            const isApproved = file.approvalStage === 2;
            if (!isApproved) {
              date = `(${langPropertyStock.actionUnapproved}) ${uploadDate} (${langPropertyStock.captionValidityPeriod}: ${validityPeriodStart} - ${validityPeriodEnd})`;
            }
            if (!moment(new Date).isSameOrBefore(file.validityPeriodEnd, 'day')) date += ` (${langPropertyStock.captionExpired})`;
          } else if (!(latestValidForm > 0)) {
            if (!file.id) {
              if (noActiveForm) date = langPropertyStock.noValidForm;
            } else {
              date = langPropertyStock.noValidForm;
            }
          } else if (latestForm && latestForm.approvalStage === 0) {
            date = `(${langPropertyStock.actionUnapproved}) ${uploadDate} (${langPropertyStock.captionValidityPeriod}: ${validityPeriodStart} - ${validityPeriodEnd})`;
          }
        } else if (type === 'FORM_4' || type === 'FORM_6') {
          if (noActiveForm) {
            date = langPropertyStock.noValidForm;
          }
        }
      } else {
        date = `${file && file.approvalStage === 0 ? `(${langPropertyStock.actionUnapproved}) ` : ''}${uploadDate} (${langPropertyStock.captionValidityPeriod}: ${validityPeriodStart} - ${validityPeriodEnd})`;
      }
    } else if (type === 'PROVISIONAL_AGREEMENT') {
      date = moment(file.signDate).format(DISPLAY_DATE_FORMAT) ?? '';
      if (!historyDialog && noActiveForm) {
        date = langPropertyStock.noValidForm;
      }
    }
    else if (type === 'SUPPLEMENTAL_DOCUMENT' || type === 'CLOSE_TRNX_SUPP') {
      date = moment(file.dateCreated).format(DISPLAY_DATE_FORMAT) ?? '';
      if (!historyDialog && noActiveForm) {
        date = langPropertyStock.noValidForm;
      }
    }

    /////// access control
    const isAdmin = isNonEmpty(privileges) ? (privileges!.filter(p => (p.role === 'ADMIN')).length > 0) : false;
    const isOfficeAdmin = isNonEmpty(privileges) ? (privileges!.filter(p => (p.role === 'OFFICE_ADMIN')).length > 0) : false;
    const isFileOwner = file.agentUserId?.toString() === currentUid;
    const isClientAgentAndFileOwner = isClientAgent && ((file.agentFullName) === (chineseName ?? englishName) || (file.agentFullNameEn === englishName));

    const showDeleteButton = () => {
      if (!isNonEmpty(file.dateCreated)) { //unsaved files
        return true;
      }
      if (isPreviewPropertyStockPage) {
        return false;
      }
      //delete rights for system admin account
      if (isAdmin) {
        if (isCtDialog && isFormPaTableForCloseTrnx) {
          return false;
        }   //Admin cannot delete files in close transaction page
        if (type === 'FORM_4' || type === 'FORM_6') {
          return isClientPage ? true : false;
        }   //Admin cannot delete form4/6 in property detail page
        return true;
      }
      switch (type) {
        case 'LAND_SEARCH':
          return file.confirmed ? false : isFileOwner && stockForm?.values.isLeadAgent; // Only uploader and lead agent can delete before it is approved
        case 'FORM_4':
        case 'FORM_6':
          return isClientPage ? isClientAgentAndFileOwner : false;
        case 'CLOSE_TRNX_SUPP':
          //*ct-TODO* show delete button or not
          return isFileOwner && closeTransactionStatus === 'C';
        case 'SA_SEARCH':
        case 'COMPANY_SEARCH':
        case 'OTHERS':
        case 'FORM_1':
        case 'FORM_3':
        case 'FORM_2':
        case 'FORM_5':
        case 'PROVISIONAL_AGREEMENT':
        case 'SUPPLEMENTAL_DOCUMENT':
        default:
          return isFileOwner;//Only user who uploaded/signed the form/agreement can delete the document
      }

    }
    const showDownloadButton = () => {
      if (file.dataStatus === 'I') {
        if (isAdmin) {
          return true;
        } else if (isFormPaTableForCloseTrnx && isOfficeAdmin) {
          return true;
        } else {
          return false;
        }
      }
      if (noActiveForm) {
        return false;
      }
      if (!isNonEmpty(file.dateCreated)) { //unsaved files
        return true;
      }
      switch (type) {
        case 'LAND_SEARCH':
        case 'SA_SEARCH':
        case 'COMPANY_SEARCH':
        case 'OTHERS':
          // Lead agents & admins can view search docs 
          return isFileOwner || stockForm?.values.isLeadAgent || (stockForm?.values.canReadTeamProperty && isTeamMemberFile(file.agentUserId?.toString())) || stockForm?.values.canReadCompanyStock || isAdmin || isOfficeAdmin;
        case 'FORM_1':
        case 'FORM_2':
          return isFileOwner || showAllFormsDownloadAnyway || stockForm?.values.canReadCompanyStock; //Only user who uploaded/signed the form/agreement can download the document
        case 'FORM_3':
          const showForm3Download = ((isFileOwner || isAdmin || isOfficeAdmin) && file?.isHKID === null) || showAllFormsDownloadAnyway || stockForm?.values.canReadCompanyStock || isFileOwner;
          const showForm3Button = isFormPaTableForCloseTrnx ? true : (stockForm?.values.latestValidForm3Id ?? 0) > 0;
          //show all form3 in closeTrnx while show valid form3 only in property stock detail page
          if (!historyDialog) {
            return (showForm3Download && showForm3Button);
          }
          return showForm3Download //Only user who uploaded/signed the form/agreement can download the document
        case 'FORM_5':
          const showForm5Download = (((isFileOwner || isAdmin || isOfficeAdmin) && file?.isHKID === null) || showAllFormsDownloadAnyway || stockForm?.values.canReadCompanyStock || isFileOwner);
          const showForm5Button = isFormPaTableForCloseTrnx ? true : (stockForm?.values.latestValidForm5Id ?? 0) > 0;
          //show all form5 in closeTrnx while show valid form5 only in property stock detail page
          if (!historyDialog) {
            return (showForm5Download && showForm5Button);
          }
          return showForm5Download//Only user who uploaded/signed the form/agreement can download the document
        case 'PROVISIONAL_AGREEMENT':
          const isBuyerAgent = file.buyerAgentUserId?.toString() === currentUid
          const isSellerAgent = file.sellerAgentUserId?.toString() === currentUid;
          return isFileOwner || isBuyerAgent || isSellerAgent || canDownloadUnownedPa;
        case 'SUPPLEMENTAL_DOCUMENT':
          return isFileOwner || canDownloadUnownedPa; //buyer agent or tenant agent can also download
        case 'FORM_4':
        case 'FORM_6':
          return isClientPage ? (file.allowDownload || isClientAgentAndFileOwner) : (file.allowDownload || showAllFormsDownloadAnyway);
        case 'CLOSE_TRNX_SUPP':
          //*ct-TODO* show download button or not
          return isFileOwner || canDownloadUnownedCloseTrnxSupp;
        default:
          return isFileOwner;
      }
    }

    const thisFileTypeUploadPolicy = getUploadPolicy(type);
    const thisFileTypeUploadLimit = +(thisFileTypeUploadPolicy.MAX_NUM_OF_FILE) ?? 0;
    const numOfFileUploaded = thisFileTypeUploadLimit > 0 ?
      ' (' + (fileFormToUse.values[type]?.length ?? 0) + '/' + thisFileTypeUploadLimit + ')'
      : '';

    const fileExt = extname(file.filename || '');
    const inlinePreview = PREVIEWABLE_EXTENSIONS.includes(fileExt);
    const src = `${BASE_URL}/files/${file.filename}`;

    const nameColumnValue = (() => {
      switch (type) {
        case 'FORM_4':
        case 'FORM_6': {
          // for property stock page form4 & form6
          const clientNameDisplay = file.clientFullName || file.clientFullNameEn ? multiLang(locale, file.clientFullName, file.clientFullNameEn) : '';
          // Only buyer client agent can see client name in "Upload History".
          return file.allowDownload ? clientNameDisplay : langPropertyStock.captionNameHidden;
        }
        case 'PROVISIONAL_AGREEMENT': {
          if (file.manualUpload) {
            return basename(file.filename) ?? '';
          }
        }
        case 'FORM_1':
        case 'FORM_3':
        case 'FORM_5':
        default: {
          return isForm(type) && historyDialog ?
            <>{getFileTypeAbbr(type)}</> :
            `${file.name ?? ''}`
            ;
        }
      }
    })();

    const authorColumn = (() => {
      switch (type) {
        case 'FORM_4':
        case 'FORM_6': {
          // for property stock page form4 & form6
          const agentNameDisplay = file.agentFullName || file.agentFullNameEn ? langPropertyStock.form46ClientAgentPrefix + multiLang(locale, file.agentFullName, file.agentFullNameEn) : '';
          return agentNameDisplay;
        }
        default: {
          const agentNameDisplay = multiLang(locale, file.agentUserNameChinese, file.agentUserNameEnglish) ?? '';
          return agentNameDisplay;
        }
      }
    })();

    return (

      <TableRow key={(historyDialog ? 'his-' : 'doc-') + type + '-' + file.filename}>
        {isCtDialog ? null : historyDialog ? (!isClientPage && (type === 'FORM_4' || type === 'FORM_6') ? /*<TableCell component="th" scope="row">{getFileTypeAbbr(type) + getStarForNonHKID(file)}</TableCell>*/ null : null) :
          <TableCell component="th" scope="row">
            {docTypeOptions[type] + numOfFileUploaded}
            {needNonHkidRemarks(type) ? getStarForNonHKID(file) : ''}

          </TableCell>
        }
        {/* {historyDialog && !isClientPage && (type ==='FORM_4' || type ==='FORM_6') ?
          <TableCell component="th" scope="row">{getFileTypeAbbr(type) + getStarForNonHKID(file)}</TableCell>
          : null
        } */}
        {/* ----- uploader/author ----- */}
        {historyDialog ? <TableCell align="left">{authorColumn}</TableCell> : null}
        {/* ----- name ----- */}
        {!historyDialog ? null : isClientPage ? null //<TableCell align="left">{getFileTypeAbbr(type) + getStarForNonHKID(file)}</TableCell>
          :
          <TableCell align="left">{file.dataStatus === 'I' ? '(' + lang.captionDeleted + ') ' : ''}
            <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>{(!isNonEmpty(file.dateCreated) ? '(' + lang.captionUnsaved + ') ' : '')}</span>
            {nameColumnValue}{needNonHkidRemarks(type) ? getStarForNonHKID(file) : ''}</TableCell>
        }
        {/* ----- NonHKID ----- */}
        {isCtDialog ? null : historyDialog ? (isClientPage ? (<TableCell align="left">{needNonHkidRemarks(type) ? getStarForNonHKID(file) : ''}</TableCell>) : null) : null}
        {/* ----- date ----- */}
        <TableCell align="left">
          <div className={'common-row'}>
            {(type === "FORM_4" || type === 'FORM_6') && file.dataStatus === 'I' ? '(' + lang.captionDeleted + ') ' : ''}
            <span style={{ display: (type === "FORM_4" || type === 'FORM_6') ? 'block' : 'none', fontWeight: 'bold', fontStyle: 'italic' }}>
              {(file.filename && !isNonEmpty(file.id) ? '(' + lang.captionUnsaved + ') ' : '')}
            </span>
            {date}
            {type === 'LAND_SEARCH' && !isPreviewPropertyStockPage ? (noActiveForm && !historyDialog) ? null : <LandSearchEditDialog disabled={saveDisabled || !canUpdateUnowned} canUpdateUnowned={canUpdateUnowned}
              date={file.date}
              confirmed={file.confirmed}
              onDateChange={(newDate: string) => fileFormToUse.modify({
                [type]: fileFormToUse.values[type]?.map((f: any, i: number) => i === index ? {
                  ...f, date: newDate
                } : f)
              })}
              onConfimedChange={(newConfirmed: boolean) => fileFormToUse.modify({
                [type]: fileFormToUse.values[type]?.map((f: any, i: number) => i === index ? {
                  ...f, confirmed: newConfirmed
                } : f)
              })}
            /> : null}
            {type === 'LAND_SEARCH' && file.confirmed ? <CheckCircleIcon style={{ color: green[500] }} /> : null}
          </div>
        </TableCell>
        {/* ----- remarks ----- */}
        {hideRemarksInListTable ? null :
          <TableCell align="left">
            {isClientPage ?
              <FormPropertyDialog object={file} />
              : <Typography>{(file.remarks ?? '')}</Typography>
            }
          </TableCell>
        }
        {/* ----- download button ----- */}
        <TableCell align="left">
          {showDownloadButton() ?
            <Button startIcon={<GetAppIcon />} color="default" onClick={() => window.open(src, "blank")} />
            : null}
          {showDownloadButton() && inlinePreview ?
            <Button startIcon={<VisibilityIcon />} color="default" onClick={() => window.open(`${src}?inline=1`, "blank")} />
            : null}
        </TableCell>
        {/* ----- history button or delete button ----- */}
        {historyDialog ?
          <TableCell align="left">
            {file.dataStatus !== 'I' && showDeleteButton() ?
              <Button startIcon={<DeleteIcon />} color="default"
                onClick={() => {
                  handleDeleteFile(type, index)
                }}>
              </Button>
              : null}
          </TableCell>
          : <TableCell align="left">
            <Button startIcon={<HistoryIcon />} color="default"
              onClick={() => {
                setSelectedHistoryDocType(type);
                setOpenFileHistoryDialog(true);
              }} />
          </TableCell>
        }
      </TableRow>
    )
  }


  //---------- File history dialog
  const historyTableColumnHeads = () => <TableRow>
    {!isCtDialog && !isClientPage && (selectedHistoryDocType === 'FORM_4' || selectedHistoryDocType === 'FORM_6') ? null : null}
    {<TableCell align="left">{langPropertyStock.captionAuthorCol}</TableCell>}
    {isClientPage ? null : (selectedHistoryDocType === 'FORM_4' || selectedHistoryDocType === 'FORM_6') ? <TableCell align="left">{langPropertyStock.captionClientCol}</TableCell> : <TableCell align="left">{langPropertyStock.captionNameCol}</TableCell>}
    {isCtDialog ? null : (isClientPage ? <TableCell align='left'></TableCell> : null)}
    <TableCell align="left">{langPropertyStock.captionDate}</TableCell>
    {hideRemarksInListTable ? null :
      <TableCell align="left">{isClientPage ? langPropertyStock.captionFormProperty : langPropertyStock.captionNote}</TableCell>
    }
    <TableCell align="left">{lang.actionDownloadOrPreview}</TableCell>
    <TableCell align="left">{lang.actionDelete}</TableCell>
  </TableRow>;

  const fileHistoryListDialog = () => {
    let fileFormToUse = fileForm;
    if (!isClientPage && (selectedHistoryDocType === 'FORM_4' || selectedHistoryDocType === 'FORM_6')) {
      fileFormToUse = clientFileForm ?? {};
    }
    const fileHistoryList = fileFormToUse.values[selectedHistoryDocType] ?? [];
    return (
      <Dialog maxWidth='md' open={openFileHistoryDialog} onClose={handleCloseFileHistoryDialog} aria-labelledby="file-history-dialog">
        <DialogTitle id="file-history-dialog-title">
          <Typography style={{ fontWeight: 'bold' }}>{langPropertyStock.captionHistory + ' - ' + docTypeOptions[selectedHistoryDocType]}</Typography>
        </DialogTitle>
        <DialogContent  >
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="file history list table">
              <TableHead>
                {historyTableColumnHeads()}
              </TableHead>
              <TableBody>
                {fileHistoryList.map((file: any, index: number) => (
                  getTableRow(file.type ?? '', index, true)
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {needNonHkidRemarksInHistoryDialog() ? printNonHkidRemarks() : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFileHistoryDialog} color="primary">
            {lang.actionClose}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }


  // --------- change in fileForm.values
  // useEffect(()=>{
  //   

  // },[fileForm.values])

  //---------- for form4 & 6 only
  useEffect(() => {
    setDocUploadDialogOpen(openDialog ?? false);
    setSelectedUploadDocType(uploadFormType ?? 'DUMMY');
  }, [openDialog, uploadFormType]);

  const districtOptions = {
    ...districtHkiOptions,
    ...districtKltOptions,
    ...districtNtOptions,
  };

  const addSelectedProperties = () => {
    if (selectedUploadDocType === 'FORM_4' || selectedUploadDocType === 'FORM_6') {
      let formPropertyList: Partial<Form4PropertyDTO>[] = [];
      selectedProperties?.forEach(p => {
        formPropertyList.push({
          propertyStockId: String(p.id!),
          propertyAddress: genAddress('zh_HK', p.unit ?? '', p.floor ?? '', p.blockZh ?? '', p.blockEn ?? '', p.buildingNameZh ?? '', p.buildingNameEn ?? '', p.streetZh ?? '', p.streetEn ?? '', districtOptions[p.district ?? '']),
          propertyAddressEn: genAddress('en', p.unit ?? '', p.floor ?? '', p.blockZh ?? '', p.blockEn ?? '', p.buildingNameZh ?? '', p.buildingNameEn ?? '', p.streetZh ?? '', p.streetEn ?? '', districtOptions[p.district ?? '']),
        })
      })
      return { formProperty: formPropertyList };
    }
    return {};
  }

  const OuterNode = noCardView ? DivNode : Card;

  const tableHeads = () => <TableRow>
    {isCtDialog ? null : <TableCell component="th" scope="row">{langPropertyStock.cationType}</TableCell>}
    {/* <TableCell align="left">{langPropertyStock.captionAuthorCol}</TableCell> */}
    {/* {isClientPage?  null :
      <TableCell align="left">{langPropertyStock.captionNameCol}</TableCell>
    } */}
    <TableCell align="left">{langPropertyStock.captionDate}</TableCell>
    {hideRemarksInListTable ? null :
      <TableCell align="left">{isClientPage ? langPropertyStock.captionFormProperty : langPropertyStock.captionNote}</TableCell>
    }
    <TableCell align="left">{lang.actionDownloadOrPreview}</TableCell>
    <TableCell align="left">{langPropertyStock.captionHistory}</TableCell>
  </TableRow>;

  const documentListTable = () =>
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="document list table">
        <TableHead>
          {!expandedMode ? tableHeads() : historyTableColumnHeads()}
        </TableHead>
        <TableBody>
          {!expandedMode ? fileListOptions.map((type) => (

            getTableRow(type, 0, false)
          )) : fileListOptions.map((type) => {
            let fileFormToUse = fileForm;
            if (!isClientPage && (type === 'FORM_4' || type === 'FORM_6')) {
              fileFormToUse = clientFileForm ?? {};
            }
            return fileFormToUse.values[type] ?? [];
          }).flatMap(fileHistoryList => fileHistoryList.map((file: any, index: number) => (
            getTableRow(file.type ?? '', index, true)
          )))}
          {/* pa supp doc */}
          {/* {isNonEmpty(docTypeOptions['SUPPLEMENTAL_DOCUMENT']) ?
                (fileForm.values['SUPPLEMENTAL_DOCUMENT'] ?? []).map((file: any, index: number) => (
                  getTableRow('SUPPLEMENTAL_DOCUMENT', index, false)
                )) : null} */}
          {/* ct supp doc */}
          {/* {isNonEmpty(docTypeOptions['CLOSE_TRNX_SUPP']) ?
                (fileForm.values['CLOSE_TRNX_SUPP'] ?? []).map((file: any, index: number) => (
                  getTableRow('CLOSE_TRNX_SUPP', index, false)
                )) : null} */}
        </TableBody>
      </Table>
    </TableContainer>;


  const documentListTableForCloseTrnx = () => {
    const isSale = fileForm.values['PROVISIONAL_AGREEMENT'][0]?.provisionalAgreementType === 'SALE';
    const fileListOptiosForSale = ['FORM_1', 'FORM_3', 'FORM_4', 'PROVISIONAL_AGREEMENT'];
    const fileListOptiosForRent = ['FORM_2', 'FORM_5', 'FORM_6', 'PROVISIONAL_AGREEMENT'];
    const fileListOptionForCloseTrnx = isSale ? fileListOptiosForSale : fileListOptiosForRent;
    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="document list table">
          <TableHead>
            {tableHeads()}
          </TableHead>
          <TableBody>
            {fileListOptionForCloseTrnx.map((type) => (
              getTableRow(type, 0, false)
            ))}
          </TableBody>
        </Table>
      </TableContainer>)
  }

  return (
    <OuterNode>
      {isPreviewPropertyStockPage ? null :
        <CardHeader
          title={sectionTitle}
        />
      }
      <CardContent>
        <DocUploadDialog
          open={docUploadDialogOpen}
          onClose={handleDocUploadDialogClose}
          handleUpload={handleUpload}
          // fileAccept={fileAccept}
          type={selectedUploadDocType}
          dialogType={dialogType}
          form={form}
          paForm={paForm}
          elementId={elementId}
          dialogTitle={docTypeOptions[selectedUploadDocType]}
          viewUploadRequirement={openPopup}
        />
        {fileHistoryListDialog()}
        {/* || isCtDialog  */}
        {confirmDialog.render()}
        {/* -------------------- related to file upload ------------------ */}


        {Object.keys(docTypeOptions).map((type, idx) => (
          <input
            key={idx}
            accept=".png, .jpg, .jpeg, .gif, .pdf, .doc,.docx, xls, .xlsx"
            style={{ display: 'none' }}
            // accept={fileAccept}
            id={elementIdPrefix + '-' + idMap(type)}
            type="file"
            onChange={(ev) => {
              // 
              handleUpload(type)(
                Array.from(ev.target.files ?? []),
                type !== 'PROVISIONAL_AGREEMENT' ? form.values : paForm.values
              );
              resetFileInput(ev);

            }}
          />
        ))}

        {/* ---------------------- upload button ------------------ */}
        {hideUploadButton ? null :
          <Grid container>
            <Grid item xs={12} style={{ paddingBottom: '5px' }}>
              <div style={{ display: 'inline-block' }}>
                {isCtDialog ? (hideUploadButton ? null :
                  <Button variant="contained" color="primary" onClick={() => { setSelectedUploadDocType(defaultUploadDocType); setDocUploadDialogOpen(true); }}>{lang.actionUpload}</Button>
                )
                  :
                  <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="upload button">
                    <Button disabled={isClientPage || isPreviewPropertyStockPage ? false : !canEditForm || form.values.isOrphan || shouldDisableUpload} onClick={handleClick}>{lang.actionUpload}</Button>
                    {/* <Button
                  color="primary"
                  size="small"
                  aria-controls={openButtonMenuList ? 'split-button-menu' : undefined}
                  aria-expanded={openButtonMenuList ? 'true' : undefined}
                  aria-label="select document type"
                  aria-haspopup="menu"
                  onClick={handleToggle}
                >
                  <ArrowDropDownIcon />
                </Button> */}
                  </ButtonGroup>
                }
              </div>
              <Popper style={{ zIndex: 3 }} open={openButtonMenuList} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      // transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                      transformOrigin: 'bottom-start',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="upload-doc-button-menu">
                          {buttonOptions.map((type, index) => (
                            <MenuItem
                              key={type}
                              disabled={index === 0}
                              selected={type === selectedUploadDocType}
                              onClick={(event) => handleMenuItemClick(event, type)}
                            >
                              {docTypeOptions[type]}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
              <div style={{ display: 'inline-block' }}>
                <Typography style={{ display: 'none' }}>
                  {/* <a onClick={openPopup} href="javascript:">{langPropertyStock.msgUploadRequirement}</a> */}
                  <a onClick={openPopup} href="javascript:">{langPropertyStock.msgUploadRequirement}</a>
                </Typography>
              </div>
              <Popover
                open={reqPopupOpened}
                anchorEl={anchorEl}
                onClose={closePopup}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Typography style={{ margin: 8 }}>
                  {format(langPropertyStock.msgUploadRequirementTemplate,
                    uploadPolicy.MAX_FILE_SIZE,
                    uploadPolicy.SUPPORT_FILE_TYPE,
                    uploadPolicy.RATIO,
                  )}
                </Typography>
              </Popover>

            </Grid>
          </Grid>
        }

        {/* ---------------------- document list ------------------ */}
        {isFormPaTableForCloseTrnx ? documentListTableForCloseTrnx() : documentListTable()}
        {needNonHkidRemarksInListTable ? printNonHkidRemarks() : null}
      </CardContent>
    </OuterNode>
  );
}

///////////////////////////////////////////////////////////////
/////////////////// Doc Upload Dialg //////////////////////////
///////////////////////////////////////////////////////////////

const DocUploadDialog = ({ open, onClose, type, form, paForm, dialogType, elementId, dialogTitle, viewUploadRequirement }: any) => {
  const clientNameFilled = (paForm.values.sellerNames?.filter((name: string) => !isNonEmpty(name)).length === 0)
    && (paForm.values.buyerNames?.filter((name: string) => !isNonEmpty(name)).length === 0);
  // const dialogType = () => {
  //   switch (type) {
  //     case 'FORM_1':
  //     case 'FORM_2':
  //       return 'DIALOG_TYPE_FORM_1_2';
  //     case 'FORM_3':
  //     case 'FORM_5':
  //       return 'DIALOG_TYPE_FORM_3_4_5_6';
  //     case 'PROVISIONAL_AGREEMENT':
  //       return 'DIALOG_TYPE_PROVISIONAL_AGREEMENT';
  //     default: return 'DIALOG_TYPE_PROPERTY_DOC';
  //   }
  // }

  // const form = useForm<any>({}, {
  //   validations: [
  //     (values) => (dialogType === 'DIALOG_TYPE_FORM_1_2' || dialogType === 'DIALOG_TYPE_PROPERTY_DOC') &&  !values.date ? [[ 'date', ' ' ]] : null,
  //     (values) => dialogType === 'DIALOG_TYPE_PROPERTY_DOC' && !values.name ? [[ 'name', ' ' ]] : null,
  //     (values) => dialogType === 'DIALOG_TYPE_FORM_3_4_5_6' && !values.validityPeriodStart ? [[ 'validityPeriodStart', ' ' ]] : null,
  //     (values) => dialogType === 'DIALOG_TYPE_FORM_3_4_5_6' &&  !values.validityPeriodEnd ? [[ 'validityPeriodEnd', ' ' ]] : null,
  //   ],
  // });

  // const paForm = useForm<any>({});
  const { langPropertyStock, lang } = useSelector((state: IRootState) => state.locale);
  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);

  const [keyBooleanMap, setKeyBooleanMap] = useState(initFormKeysAndBooleanMap(form));

  useEffect(() => {
    setKeyBooleanMap(initFormKeysAndBooleanMap(form));
  }, [Object.keys(form.values).length])

  //---------- Dialog Content-------
  const propertyDocDialogContent = (dateDisableFuture: boolean) => (
    <DialogContent>
      <Grid container spacing={1} direction="row">
        <Grid item md={12} xs={12}>
          <TextField
            fullWidth
            margin="dense"
            // required
            variant="outlined"
            {...form.bind('name')}
            onChange={(e) => {
              limitTextFieldLength(e, 255, 'name', form, keyBooleanMap, setKeyBooleanMap);
            }}
            label={langPropertyStock.captionNameCol} />
          <HandleFormHelperText
            isError={keyBooleanMap.get('name')}
            errorMessage={lang.textOverLimit}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            fullWidth
            margin="dense"
            // required
            variant="outlined"
            {...form.bind('remarks')}
            onChange={(e) => {
              limitTextFieldLength(e, 255, 'remarks', form, keyBooleanMap, setKeyBooleanMap);
            }}
            label={langPropertyStock.captionNote} />
          <HandleFormHelperText
            isError={keyBooleanMap.get('remarks')}
            errorMessage={lang.textOverLimit}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <KeyboardDatePicker autoOk={true}
            variant="inline"
            inputVariant="outlined"
            fullWidth
            required
            format={DISPLAY_DATE_FORMAT}
            label={langPropertyStock.captionDate}
            margin="dense"
            disableFuture={dateDisableFuture}
            {...form.bind('date')}
            value={form.values.date ? moment(form.values.date) : null}
            onChange={(value) => {
              form.setValues({
                ...form.values,
                date: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? null,
              });
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            maxDateMessage={langPropertyStock.msgInputValidDate}
          />
        </Grid>
      </Grid>
    </DialogContent>
  )

  const form12DialogContent = () => (
    <DialogContent>
      <KeyboardDatePicker autoOk={true}
        variant="inline"
        inputVariant="outlined"
        fullWidth
        format={DISPLAY_DATE_FORMAT}
        label={langPropertyStock.captionSignDate}
        disableFuture={true}
        margin="dense"
        required
        {...form.bind('date')}
        value={form.values.date ? moment(form.values.date) : null}
        onChange={(value) => {
          form.setValues({
            ...form.values,
            date: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? null,
          });
        }}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        maxDateMessage={langPropertyStock.msgInputValidDate}
      />
    </DialogContent>
  )

  const form3456DialogContent = () => (
    <DialogContent>

      <Grid container spacing={1} direction="row">
        <Grid item xs={12}>
          <Typography>{langPropertyStock.captionValidityPeriod}</Typography>
        </Grid>

        <Grid item xs={5}>
          <KeyboardDatePicker autoOk={true}
            variant="inline"
            inputVariant="outlined"
            fullWidth
            format={DISPLAY_DATE_FORMAT}

            // label={lang.validityPeriodStart}
            margin="dense"
            {...form.bind('validityPeriodStart')}
            value={form.values.validityPeriodStart ? moment(form.values.validityPeriodStart) : null}
            onChange={(value) => {
              form.setValues({
                ...form.values,
                validityPeriodStart: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? null,
              });
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          {form.hasError('validityPeriodStart') ? <FormHelperText style={{ color: form.hasError('validityPeriodStart') ? errorColor : 'inherit' }}>{form.hasError('validityPeriodStart')}</FormHelperText> : null}
        </Grid>
        {form.values.validityPeriodStart?.length !== 20 && form.values.validityPeriodStart?.localeCompare(form.values.validityPeriodEnd ?? "") === 1 ? form.values.validityPeriodEnd = "" : undefined}
        <Grid container item xs={1} justify="center" alignItems="center">
          -
        </Grid>

        <Grid item xs={5}>
          <KeyboardDatePicker autoOk={true}
            variant="inline"
            inputVariant="outlined"
            fullWidth
            format={DISPLAY_DATE_FORMAT}
            // label={lang.validityPeriodEnd}
            margin="dense"
            shouldDisableDate={disablePrevDates(form.values.validityPeriodStart)}
            {...form.bind('validityPeriodEnd')}
            value={form.values.validityPeriodEnd ? moment(form.values.validityPeriodEnd) : null}
            onChange={(value) => {
              form.setValues({
                ...form.values,
                validityPeriodEnd: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? null,
              });
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Grid>

      </Grid>

    </DialogContent>
  );

  const provisionalAgreementDialogContent = () => (
    <ProvisionalAgreementEditCard form={paForm} stockForm={form} />
  );

  //---------- Dialog Content-------
  const supplementaryDialogContent = () => (
    <DialogContent>
      <Grid container spacing={1} direction="row">
        <Grid item md={12} xs={12}>
          <TextField
            fullWidth
            margin="dense"
            required
            variant="outlined"
            {...form.bind('name')}
            onChange={(e) => {
              limitTextFieldLength(e, 255, 'name', form, keyBooleanMap, setKeyBooleanMap);
            }}
            label={langPropertyStock.captionNameCol} />
          <HandleFormHelperText
            isError={keyBooleanMap.get('name')}
            errorMessage={lang.textOverLimit}
          />
        </Grid>
      </Grid>
    </DialogContent>
  )


  return <Dialog keepMounted open={open} onClose={onClose}>
    <DialogTitle id="file-upload-dialog-title">
      <span>
        {dialogTitle}
        <span style={{ paddingLeft: '15px', fontSize: '12px' }}>
          {/* <a onClick={viewUploadRequirement} href="javascript:">{langPropertyStock.msgUploadRequirement}</a> */}
          <a onClick={viewUploadRequirement} href="javascript:">{langPropertyStock.msgUploadRequirement}</a>
        </span>
      </span>
    </DialogTitle>


    {dialogType === 'DIALOG_TYPE_FORM_1_2' ? form12DialogContent() : null}
    {dialogType === 'DIALOG_TYPE_FORM_3_4_5_6' ? form3456DialogContent() : null}
    {dialogType === 'DIALOG_TYPE_PROVISIONAL_AGREEMENT' ? provisionalAgreementDialogContent() : null}
    {dialogType === 'DIALOG_TYPE_PROPERTY_DOC' ? propertyDocDialogContent(type === 'LAND_SEARCH' || type === 'SA_SEARCH' || type === 'COMPANY_SEARCH' || type === 'OTHERS') : null}
    {dialogType === 'DIALOG_TYPE_SUPPLEMENTAL_DOCUMENT' ? supplementaryDialogContent() : null}

    {/* <input
        ref={fileRef}
        style={{ display: 'none' }}
        // accept={fileAccept}
        multiple
        type="file"
        onChange={(ev) => {
         
          handleUpload(type)(
            Array.from(ev.target.files ?? []), 
            type !== 'PROVISIONAL_AGREEMENT' ? form.values : paForm.values
          );
          onClose();
        }}
      /> */}

    <DialogActions
    // style={{ display: 'flex', justifyContent: 'flex-end' }}
    >
      <Button onClick={onClose} color="primary">
        {lang.actionCancel}
      </Button>
      <Button color="primary" variant="contained" autoFocus onClick={() => {
        if (type !== 'PROVISIONAL_AGREEMENT') {
          if (form.validate()) {
            // fileRef.current?.click();
            document.getElementById(elementId)?.click()
          }
        } else {
          if (paForm.validate() && clientNameFilled) {
            // fileRef.current?.click();
            document.getElementById(elementId)?.click()
          }
        }
      }}>
        {lang.actionConfirmAndUpload}
      </Button>
    </DialogActions>
  </Dialog>
}

//////////////// cloned form FormUploadPane ////////////////////

const FormPropertyDialog = ({ object }: any) => {

  const { formProperty } = object;
  const { langPropertyStock, lang, locale } = useSelector((state: IRootState) => state.locale);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {/* <Button size="small" color="primary" variant="outlined" onClick={handleClickOpen}>
      {langPropertyStock.captionFormProperty}
      </Button> */}
      {(formProperty?.length ?? 0) > 0 ?
        <Button startIcon={<ListIcon />} color="default" onClick={handleClickOpen} />
        : null}
      <Dialog open={open} onClose={handleClose} aria-labelledby="FormPropertyDialog-title">
        <DialogTitle style={{ fontWeight: 'bold' }} id="FormPropertyDialog-title">{langPropertyStock.captionFormProperty}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {
              formProperty?.map((property: any, idx: number) => {
                return (
                  <Typography key={idx} className={classes.typography} style={{ margin: '3px' }}>{idx + 1}{'. '}{multiLang(locale, property.propertyAddress, property.propertyAddressEn)}</Typography>
                )
              })
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {lang.actionClose}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

//////////////// cloned form FormUploadPane ////////////////////

const ProvisionalAgreementEditCard = ({ form }: any) => {
  const classes = useStyles();
  const { lang, langPropertyStock, langSalePurchaseAgreement, langTenancyAgreement, locale, agentTypeOptions, langClientDetail } = useSelector((state: IRootState) => state.locale);
  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);
  const [agents, setAgents] = useState<UserListItemDTO[]>([]);
  const [agentListLoading, setAgentListLoading] = useState(false);

  const token: string = useSelector((state: IRootState) => state.login.token ?? '');

  const fetchAgents = (keyword: string) => {
    if (keyword.length < 2) { return; }
    setAgentListLoading(true);
    userApi.getListForAutocomplete({ name: keyword, onlyHasCreateOwnClientPrivilege: true }, token).then(result => {
      if (result.data?.content) {
        setAgents(result.data!.content);
        setAgentListLoading(false);
        setAgentUsername(result.data!.content.filter(agent => agent.username != 'companyStockAgent').map((agent) => agent.username));

      }
    });
  }

  const [agentUsername, setAgentUsername] = useState(agents.filter(agent => agent.username != 'companyStockAgent').map((agent) => agent.username));
  const agentMap: { [name: string]: UserListItemDTO } = {};
  agents.forEach((agent) => {
    agentMap[agent.username] = agent;
  })
  const [keywordOfAgent, setKeywordOfAgent] = useState('')

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchAgents(keywordOfAgent);
    }, 1000);
    return () => clearTimeout(timer);
  }, [keywordOfAgent]);


  useEffect(() => {
    form.updateValues('provisionalAgreementType', 'SALE');
  }, []);

  const addBuyer = () => {
    form.updateValues('buyerNames',
      [...form.values.buyerNames ?? [], '']);
  }

  const addSeller = () => {
    form.updateValues('sellerNames',
      [...form.values.sellerNames ?? [], '']);
  }

  const removeBuyer = (idx: number) => {
    form.updateValues('buyerNames',
      form.values.buyerNames?.filter((_: string, i: number) => idx !== i));
  }

  const removeSeller = (idx: number) => {
    form.updateValues('sellerNames',
      form.values.sellerNames?.filter((_: string, i: number) => idx !== i));
  }

  const confirmDeleteDialog = useConfirmDialog();

  const [keyBooleanMap, setKeyBooleanMap] = useState(initFormKeysAndBooleanMap(form));

  useEffect(() => {
    setKeyBooleanMap(initFormKeysAndBooleanMap(form));
  }, [Object.keys(form.values).length])

  return (
    <CardContent>
      {confirmDeleteDialog.render()}
      <Grid container md={12} xs={12} spacing={1}>
        <Grid item md={12} xs={12}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: theme.spacing(1) }}>
            <Typography>{langPropertyStock.captionSale}</Typography>
            <Switch
              checked={(form.values.provisionalAgreementType ?? 'SALE') === 'RENTAL'}
              onChange={(_, val) => form.updateValues('provisionalAgreementType', val ? 'RENTAL' : 'SALE')}
            />
            <Typography>{langPropertyStock.captionRental}</Typography>
          </div>
        </Grid>
        <Grid item md={12} xs={12}>
          <KeyboardDatePicker autoOk={true}
            variant="inline"
            inputVariant="outlined"
            format={DISPLAY_DATE_FORMAT}
            label={langPropertyStock.captionSignDate}
            disableFuture={true}
            fullWidth
            // label={lang.validityPeriodStart}
            margin="dense"
            {...form.bind('signDate')}
            required
            value={form.values.signDate ? moment(form.values.signDate) : null}
            onChange={(value) => {
              form.setValues({
                ...form.values,
                signDate: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? null,
              });
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            maxDateMessage={langPropertyStock.msgInputValidDate}
          />
        </Grid>
        <Grid item md={12} xs={12} container spacing={1}>
          <Grid item md={4} xs={12}>
            <TextField
              fullWidth
              label={form.values.provisionalAgreementType === 'SALE' ? langSalePurchaseAgreement.agentType : langTenancyAgreement.agentType}
              margin="dense"
              required
              select
              variant="outlined"
              error={!!form.hasError('sellerAgentType') || !!form.hasError('invalidBuyerOrSellerAgentType')}
              helperText={form.hasError('sellerAgentType') || form.hasError('invalidBuyerOrSellerAgentType')}
              // {...bind('sellerAgentType')}
              value={form.values.sellerAgentType || ''}
              onChange={(e) => {
                if (form.values.sellerAgentType !== e.target.value) {
                  form.setValues({
                    ...form.values,
                    sellerAgentType: e.target.value,
                    sellerAgentUserId: undefined,
                    sellerAgentName: undefined,
                    sellerAgentNameEn: undefined,
                    sellerAgentNameZh: undefined,
                    sellerAgentLicenseNumber: undefined,
                  });
                }
              }}

            >
              {
                Object.keys(agentTypeOptions ?? {}).map(key => (
                  <MenuItem key={key} value={key}>{agentTypeOptions[key]}</MenuItem>
                ))
              }
            </TextField>

          </Grid>
          <Grid item md={4} xs={12}>
            {form.values.sellerAgentType === 'I' ?
              <Autocomplete
                popupIcon={''}
                options={form.values.sellerAgentType === 'I' ? agentUsername : []}
                noOptionsText={''}
                loading={agentListLoading}
                filterOptions={(option) => option}
                loadingText={lang.msgLoading}

                renderOption={(option) => <div>
                  <div><Typography >{`${agents.filter(agent => agent.username === option)?.[0]?.englishName ?? ''}${agents.filter(agent => agent.username === option)?.[0]?.chineseName ? '/' : ''}${agents.filter(agent => agent.username === option)?.[0]?.chineseName ?? ''}`}</Typography></div>
                  <div><Typography variant="caption">{agents.filter(agent => agent.username === option)?.[0]?.mainContact}</Typography></div>
                </div>}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    className={classes.patchOutlineAutocomplete}
                    fullWidth
                    error={!!form.hasError('sellerAgentName')}
                    helperText={form.hasError('sellerAgentName')}
                    label={form.values.provisionalAgreementType === 'SALE' ? langSalePurchaseAgreement.titleSellerAgent : langTenancyAgreement.titleLandlordAgent}
                    margin="dense"
                    variant="outlined"
                    onFocus={(ev) => {
                      setAgentUsername([]);
                      setKeywordOfAgent(ev.target.value);
                    }}
                    onChange={(ev) => {
                      // fetchAgents(ev.target.value)
                      setAgents([]);
                      setAgentUsername([]);
                      setKeywordOfAgent(ev.target.value);
                      setAgentListLoading(true);
                      if (ev.target.value.length < 2 || !isNonEmpty(ev.target.value)) {
                        setAgentListLoading(false);
                      }
                    }}
                    required
                  />
                )}
                value={form.values.sellerAgentName ?? ''}

                onChange={(_: any, val: any) => {
                  if (agentMap[val]) {
                    const { id, chineseName, englishName, licenseNumber } = agentMap[val];
                    form.setValues({
                      ...form.values,
                      sellerAgentUserId: id,
                      sellerAgentName: multiLang(locale, chineseName, englishName),
                      sellerAgentNameEn: englishName,
                      sellerAgentNameZh: chineseName,
                      sellerAgentLicenseNumber: licenseNumber,
                    });

                  } else {
                    form.setValues({
                      ...form.values,
                      sellerAgentUserId: undefined,
                      sellerAgentName: undefined,
                      sellerAgentNameEn: undefined,
                      sellerAgentNameZh: undefined,
                      sellerAgentLicenseNumber: undefined,
                    });
                  }
                }}
                getOptionLabel={() => multiLang(locale, form.values.sellerAgentNameZh, form.values.sellerAgentNameEn) ?? ''}
              />
              :
              <TextField
                fullWidth
                label={form.values.provisionalAgreementType === 'SALE' ? langSalePurchaseAgreement.titleSellerAgent : langTenancyAgreement.titleLandlordAgent}
                margin="dense"
                variant="outlined"
                required
                {...form.bind('sellerAgentName')}
                onChange={(e) => {
                  if (keyBooleanMap && setKeyBooleanMap) {
                    setKeyBooleanMap(keyBooleanMap.set('sellerAgentName', e.target.value.length > 255))
                  }
                  if (e.target.value.length > 255) {
                    e.preventDefault();
                    form.setValues({
                      ...form.values,
                      sellerAgentUserId: undefined,
                      sellerAgentName: e.target.value.substring(0, 255),
                    });
                  } else {
                    form.setValues({
                      ...form.values,
                      sellerAgentUserId: undefined,
                      sellerAgentName: e.target.value,
                    });
                  }
                }}
              // value={agent.name}
              // error={!!getAgentValidationMsg('SELLER', 'name', agent.name)}
              // helperText={getAgentValidationMsg('SELLER', 'name', agent.name)}
              // onChange={(e) => {
              //   const updatedAgent = { ...agent, name: e.target.value };
              //   form.updateValues('sellerAgents',
              //     form.values.sellerAgents?.map((a: any, i: number) => idx === i ? updatedAgent : a)
              //   );
              // }}
              />
            }
            <HandleFormHelperText
              isError={keyBooleanMap.get('sellerAgentName')}
              errorMessage={lang.textOverLimit}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              fullWidth
              label={form.values.provisionalAgreementType === 'SALE' ? langSalePurchaseAgreement.agentLicenseNumber : langTenancyAgreement.agentLicenseNumber}
              margin="dense"
              variant="outlined"
              required
              {...form.bind('sellerAgentLicenseNumber')}
              onChange={(e) => {
                if (keyBooleanMap && setKeyBooleanMap) {
                  setKeyBooleanMap(keyBooleanMap.set('sellerAgentLicenseNumber', e.target.value.length > 255))
                }
                if (e.target.value.length > 255) {
                  e.preventDefault();
                  form.setValues({
                    ...form.values,
                    sellerAgentLicenseNumber: e.target.value.substring(0, 255),
                  });
                } else {
                  form.setValues({
                    ...form.values,
                    sellerAgentLicenseNumber: e.target.value,
                  });
                }
              }}

            // value={agent.name}
            // error={!!getAgentValidationMsg('SELLER', 'name', agent.name)}
            // helperText={getAgentValidationMsg('SELLER', 'name', agent.name)}
            // onChange={(e) => {
            //   const updatedAgent = { ...agent, name: e.target.value };
            //   form.updateValues('sellerAgents',
            //     form.values.sellerAgents?.map((a: any, i: number) => idx === i ? updatedAgent : a)
            //   );
            // }}
            />
            <HandleFormHelperText
              isError={keyBooleanMap.get('sellerAgentLicenseNumber')}
              errorMessage={lang.textOverLimit}
            />
          </Grid>

        </Grid>

        <Grid item md={12} xs={12} container spacing={1}>

          <Grid item md={4} xs={12}>
            <TextField
              fullWidth
              label={form.values.provisionalAgreementType === 'SALE' ? langSalePurchaseAgreement.agentType : langTenancyAgreement.agentType}
              margin="dense"
              required
              select
              variant="outlined"
              // {...bind('buyerAgentType')}
              error={!!form.hasError('buyerAgentType') || !!form.hasError('invalidBuyerOrSellerAgentType')}
              helperText={form.hasError('buyerAgentType') || form.hasError('invalidBuyerOrSellerAgentType')}
              value={form.values.buyerAgentType || ''}
              onChange={(e) => {
                if (form.values.buyerAgentType !== e.target.value) {
                  form.setValues({
                    ...form.values,
                    buyerAgentType: e.target.value,
                    buyerAgentUserId: undefined,
                    buyerAgentName: undefined,
                    buyerAgentNameEn: undefined,
                    buyerAgentNameZh: undefined,
                    buyerAgentLicenseNumber: undefined,
                  });
                }
              }}

            >
              {
                Object.keys(agentTypeOptions ?? {}).map(key => (
                  <MenuItem key={key} value={key}>{agentTypeOptions[key]}</MenuItem>
                ))
              }
            </TextField>

          </Grid>

          <Grid item md={4} xs={12}>
            {form.values.buyerAgentType === 'I' ?
              <Autocomplete
                popupIcon={''}
                options={form.values.buyerAgentType === 'I' ? agentUsername : []}
                noOptionsText={''}
                loading={agentListLoading}
                loadingText={lang.msgLoading}
                filterOptions={(option) => option}

                renderOption={(option) => <div>
                  <div><Typography >{`${agents.filter(agent => agent.username === option)?.[0]?.englishName ?? ''}${agents.filter(agent => agent.username === option)?.[0]?.chineseName ? '/' : ''}${agents.filter(agent => agent.username === option)?.[0]?.chineseName ?? ''}`}</Typography></div>
                  <div><Typography variant="caption">{agents.filter(agent => agent.username === option)?.[0]?.mainContact}</Typography></div>
                </div>}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    className={classes.patchOutlineAutocomplete}
                    fullWidth
                    label={form.values.provisionalAgreementType === 'SALE' ? langSalePurchaseAgreement.titleBuyerAgent : langTenancyAgreement.titleTenantAgent}
                    margin="dense"
                    variant="outlined"
                    required
                    error={!!form.hasError('buyerAgentName')}
                    helperText={form.hasError('buyerAgentName')}
                    onFocus={(ev) => {
                      setAgentUsername([]);
                      setKeywordOfAgent(ev.target.value);
                    }}
                    onChange={(ev) => {
                      // fetchAgents(ev.target.value)
                      setAgents([]);
                      setAgentUsername([]);
                      setKeywordOfAgent(ev.target.value);
                      setAgentListLoading(true);
                      if (ev.target.value.length < 2 || !isNonEmpty(ev.target.value)) {
                        setAgentListLoading(false);
                      }
                    }}
                  />
                )}

                value={form.values.buyerAgentName ?? ''}
                // inputValue={multiLang(locale, form.values.buyerAgentNameZh, form.values.buyerAgentNameEn)}
                onChange={(_: any, val: any) => {
                  if (agentMap[val]) {
                    const { id, chineseName, englishName, licenseNumber } = agentMap[val];
                    form.setValues({
                      ...form.values,
                      buyerAgentUserId: id,
                      buyerAgentName: multiLang(locale, chineseName, englishName),
                      buyerAgentNameEn: englishName,
                      buyerAgentNameZh: chineseName,
                      buyerAgentLicenseNumber: licenseNumber,
                    });

                  } else {
                    form.setValues({
                      ...form.values,
                      buyerAgentUserId: undefined,
                      buyerAgentName: undefined,
                      buyerAgentNameEn: undefined,
                      buyerAgentNameZh: undefined,
                      buyerAgentLicenseNumber: undefined,
                    });
                  }
                }}
                getOptionLabel={() => multiLang(locale, form.values.buyerAgentNameZh, form.values.buyerAgentNameEn) ?? ''}
              />
              :
              <TextField
                fullWidth
                label={form.values.provisionalAgreementType === 'SALE' ? langSalePurchaseAgreement.titleBuyerAgent : langTenancyAgreement.titleTenantAgent}
                margin="dense"
                variant="outlined"
                required
                {...form.bind('buyerAgentName')}
                onChange={(e) => {
                  if (keyBooleanMap && setKeyBooleanMap) {
                    setKeyBooleanMap(keyBooleanMap.set('buyerAgentName', e.target.value.length > 255))
                  }
                  if (e.target.value.length > 255) {
                    e.preventDefault();
                    form.setValues({
                      ...form.values,
                      buyerAgentName: e.target.value.substring(0, 255),
                    });
                  } else {
                    form.setValues({
                      ...form.values,
                      buyerAgentName: e.target.value,
                    });
                  }
                }}
              // value={agent.name}
              // error={!!getAgentValidationMsg('SELLER', 'name', agent.name)}
              // helperText={getAgentValidationMsg('SELLER', 'name', agent.name)}
              // onChange={(e) => {
              //   const updatedAgent = { ...agent, name: e.target.value };
              //   form.updateValues('buyerAgents',
              //     form.values.buyerAgents?.map((a: any, i: number) => idx === i ? updatedAgent : a)
              //   );
              // }}
              />
            }
            <HandleFormHelperText
              isError={keyBooleanMap.get('buyerAgentName')}
              errorMessage={lang.textOverLimit}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <TextField
              fullWidth
              label={form.values.provisionalAgreementType === 'SALE' ? langSalePurchaseAgreement.agentLicenseNumber : langTenancyAgreement.agentLicenseNumber}
              margin="dense"
              variant="outlined"
              required
              {...form.bind('buyerAgentLicenseNumber')}
              onChange={(e) => {
                if (keyBooleanMap && setKeyBooleanMap) {
                  setKeyBooleanMap(keyBooleanMap.set('buyerAgentLicenseNumber', e.target.value.length > 255))
                }
                if (e.target.value.length > 255) {
                  e.preventDefault();
                  form.setValues({
                    ...form.values,
                    buyerAgentLicenseNumber: e.target.value.substring(0, 255),
                  });
                } else {
                  form.setValues({
                    ...form.values,
                    buyerAgentLicenseNumber: e.target.value,
                  });
                }
              }}
            // value={agent.name}
            // error={!!getAgentValidationMsg('SELLER', 'name', agent.name)}
            // helperText={getAgentValidationMsg('SELLER', 'name', agent.name)}
            // onChange={(e) => {
            //   const updatedAgent = { ...agent, name: e.target.value };
            //   form.updateValues('buyerAgents',
            //     form.values.buyerAgents?.map((a: any, i: number) => idx === i ? updatedAgent : a)
            //   );
            // }}
            />
            <HandleFormHelperText
              isError={keyBooleanMap.get('buyerAgentLicenseNumber')}
              errorMessage={lang.textOverLimit}
            />
          </Grid>

        </Grid>

        {/* SellerNames */}
        <Grid item md={12} xs={12}>
          <Typography>
            {form.values.provisionalAgreementType === 'SALE' ? langPropertyStock.captionSeller : langPropertyStock.captionLandlord}{'*'}
            {
              <Tooltip title={lang.actionAdd}>
                <IconButton color="primary" onClick={addSeller}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
            }
          </Typography>
          {form.hasError('sellerNames') ? <FormHelperText style={{ color: form.hasError('sellerNames') ? errorColor : 'inherit' }}>{format(langPropertyStock.msgAtLeastOneClient, (form.values.provisionalAgreementType === 'SALE' ? langPropertyStock.captionSeller : langPropertyStock.captionLandlord).toLowerCase())}</FormHelperText> : null}
        </Grid>
        {
          form.values.sellerNames?.map((name: string, idx: number) => {
            return (
              <Grid item container md={12} xs={12}>
                <Grid
                  item
                  md={1}
                  xs={2}
                >
                  <Typography style={{ display: 'flex', alignItems: 'center', flexGrow: 2, width: 200 }}>
                    <IconButton key={idx} onClick={() => {
                      confirmDeleteDialog.confirm(langPropertyStock.actionRemoveBuyer, lang.actionConfirm, lang.actionCancel).then((confirmed) => {
                        if (confirmed) {
                          removeSeller(idx);
                        }
                      })
                    }
                    }>
                      <DeleteIcon />
                    </IconButton>
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={11}
                  xs={10}
                >
                  <TextField
                    fullWidth
                    label={langPropertyStock.captionName}
                    margin="dense"
                    variant="outlined"
                    value={name}
                    required
                    onChange={(e) => {
                      if (keyBooleanMap && setKeyBooleanMap) {
                        setKeyBooleanMap(keyBooleanMap.set('sellerNames', e.target.value.length > 255))
                      }
                      if (e.target.value.length > 255) {
                        e.preventDefault();
                        const name = e.target.value.substring(0, 255);
                        form.updateValues('sellerNames',
                          form.values.sellerNames?.map((n: string, i: number) => idx == i ? name : n)
                        );
                      } else {
                        const name = e.target.value;
                        form.updateValues('sellerNames',
                          form.values.sellerNames?.map((n: string, i: number) => idx == i ? name : n)
                        );
                      }
                    }}
                  />
                  {!isNonEmpty(name) ? <FormHelperText style={{ color: errorColor }}>{langClientDetail.msgPleaseEnterClientName}</FormHelperText> : null}
                  <HandleFormHelperText
                    isError={keyBooleanMap.get('sellerNames')}
                    errorMessage={lang.textOverLimit}
                  />
                </Grid>
              </Grid>
            )
          })
        }
        {/* BuyerNames */}
        <Grid item md={12} xs={12}>
          <Typography>
            {form.values.provisionalAgreementType === 'SALE' ? langPropertyStock.captionBuyer : langPropertyStock.captionTenant}{'*'}
            {
              <Tooltip title={lang.actionAdd}>
                <IconButton color="primary" onClick={addBuyer}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
            }
          </Typography>
          {form.hasError('buyerNames') ? <FormHelperText style={{ color: form.hasError('buyerNames') ? errorColor : 'inherit' }}>{format(langPropertyStock.msgAtLeastOneClient, (form.values.provisionalAgreementType === 'SALE' ? langPropertyStock.captionBuyer : langPropertyStock.captionTenant).toLowerCase())}</FormHelperText> : null}
        </Grid>
        {
          form.values.buyerNames?.map((name: string, idx: number) => {
            return (
              <Grid item container md={12} xs={12}>
                <Grid
                  item
                  md={1}
                  xs={2}
                >
                  <Typography style={{ display: 'flex', alignItems: 'center', flexGrow: 2, width: 200 }}>
                    <IconButton key={idx} onClick={() => {
                      confirmDeleteDialog.confirm(langPropertyStock.actionRemoveBuyer, lang.actionConfirm, lang.actionCancel).then((confirmed) => {
                        if (confirmed) {
                          removeBuyer(idx);
                        }
                      })
                    }
                    }>
                      <DeleteIcon />
                    </IconButton>
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={11}
                  xs={10}
                >
                  <TextField
                    fullWidth
                    label={langPropertyStock.captionName}
                    margin="dense"
                    variant="outlined"
                    value={name}
                    required
                    onChange={(e) => {
                      if (keyBooleanMap && setKeyBooleanMap) {
                        setKeyBooleanMap(keyBooleanMap.set('buyerNames', e.target.value.length > 255))
                      }
                      if (e.target.value.length > 255) {
                        e.preventDefault();
                        const name = e.target.value.substring(0, 255);
                        form.updateValues('buyerNames',
                          form.values.buyerNames?.map((n: string, i: number) => idx == i ? name : n)
                        );
                      } else {
                        const name = e.target.value;
                        form.updateValues('buyerNames',
                          form.values.buyerNames?.map((n: string, i: number) => idx == i ? name : n)
                        );
                      }
                    }
                    }
                  />
                  {!isNonEmpty(name) ? <FormHelperText style={{ color: errorColor }}>{langClientDetail.msgPleaseEnterClientName}</FormHelperText> : null}
                  <HandleFormHelperText
                    isError={keyBooleanMap.get('buyerNames')}
                    errorMessage={lang.textOverLimit}
                  />
                </Grid>
              </Grid>
            )
          })
        }
      </Grid>
    </CardContent>
  )
}

function LandSearchEditDialog({ disabled, date, onDateChange, confirmed, onConfimedChange, canUpdateUnowned }: any) {

  const { lang, langPropertyStock } = useSelector((state: IRootState) => state.locale);
  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);
  const [open, setOpen] = useState(false);

  const dialog = <Dialog open={open} onClose={() => {
    if (moment(new Date).isSameOrAfter(date)) {
      setOpen(false);
    }
  }}>
    <DialogTitle id="file-upload-dialog-title">
      {langPropertyStock.landSearch}
    </DialogTitle>

    <DialogContent>
      <Grid container spacing={1} direction="row">
        <Grid item md={12} xs={12}>
          <KeyboardDatePicker autoOk={true}
            variant="inline"
            inputVariant="outlined"
            fullWidth
            required
            format={DISPLAY_DATE_FORMAT}
            label={langPropertyStock.captionDate}
            margin="dense"
            disableFuture
            value={date ? moment(date) : null}
            onChange={(value) => {
              onDateChange(
                value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? null
              );
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            maxDateMessage={langPropertyStock.msgInputValidDate}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <FormControlLabel
            value="end"
            control={<Checkbox color="primary" />}
            disabled={!canUpdateUnowned}
            label={langPropertyStock.captionConfirmLandSearch}
            checked={confirmed ?? false}
            onChange={() => onConfimedChange(!confirmed)}
          />
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => {
        if (moment(new Date).isSameOrAfter(date)) {
          setOpen(false);
        }
      }} color="primary"
      >
        {lang.actionClose}
      </Button>
    </DialogActions>
  </Dialog>

  return <>
    {dialog}
    <IconButton onClick={() => setOpen(true)} disabled={disabled}>
      <EditPsStatusIcon />
    </IconButton>
  </>
}


export default Documents;
