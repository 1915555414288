import { ApiError, ApiResult } from 'api';
import agreementApi from 'api/agreementApi';
import { SalePurchaseAgreementDTO, TenancyAgreementDTO } from 'common/dto';
import { openNewWindow } from 'common/utils';
import { IRootState, LayoutActions, PropertyActions } from 'reducers';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { apiTaskWrapper, itemActionMessage } from './saga-commons';

const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];

////////////////////////Sale & Purchase Agreement///////////////////////
export function* watchSalePurchaseAgreementCreationRequested() {
  yield takeEvery(
    'SalePurchaseAgreement.CreationRequested',
    apiTaskWrapper(createSalePurchaseAgreement),
  );
}

export function* createSalePurchaseAgreement(action: Extract<PropertyActions, { type: 'SalePurchaseAgreement.CreationRequested' }>) {

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const { propertyStockId } = action.payload;
  const result: ApiResult<SalePurchaseAgreementDTO> = yield call(agreementApi.addSalePurchaseAgreement, propertyStockId, action.payload, token);

  if (!result.error) {
    yield put({ type: 'Layout.MaskPresentRequested' });
    yield put<PropertyActions>({ type: 'SalePurchaseAgreement.Loaded', payload: result.data! });
    const agreementLocale = action.payload.agreementLocale;
    if (action.payload.extraAction === 'GENERATE_PDF') {
      yield put<LayoutActions>({ type: 'Layout.MaskDismissRequested' })
      openNewWindow(`${BASE_URL}/provisionalAgreement/generate-report/${propertyStockId}/sale_and_purchase/${agreementLocale}/pdf?t=${token}`);
    } else if (action.payload.extraAction === 'SIGN_PDF') {
      yield put<LayoutActions>({ type: 'Layout.MaskDismissRequested' })
      openNewWindow(`${BASE_URL}/sign-now/sign/${propertyStockId}/sale_and_purchase/${agreementLocale}?t=${token}&regenerate=1`);
    } else {
      yield put<LayoutActions>({ type: 'Layout.MaskDismissRequested' })
      yield* itemActionMessage('create', 'SalePurchaseAgreement');
    }
  } else {
    throw ApiError.of(result.error!);
  }

}

export function* watchSalePurchaseAgreementFetchRequested() {
  yield takeEvery<PropertyActions['type']>(
    'SalePurchaseAgreement.FetchRequested',
    apiTaskWrapper(requestSalePurchaseAgreement),
  )
}

export function* requestSalePurchaseAgreement(action: PropertyActions) {
  if (action.type != 'SalePurchaseAgreement.FetchRequested') {
    return;
  }

  const { propertyStockId } = action.payload;

  if (propertyStockId == '') {
    return;
  }

  const token: string = yield select((state: IRootState) => state.login.token ?? '');
  const result: ApiResult<SalePurchaseAgreementDTO> = yield call(agreementApi.getSalePurchaseAgreement, propertyStockId, token);

  if (!result.error) {
    yield put<PropertyActions>({ type: 'SalePurchaseAgreement.Loaded', payload: result.data! });
  } else {
    yield put<PropertyActions>({ type: 'SalePurchaseAgreement.Failed', payload: result.error });
  }


}

export function* watchSalePurchaseAgreementUpdateRequested() {
  yield takeEvery(
    'SalePurchaseAgreement.UpdateRequested',
    apiTaskWrapper(updateSalePurchaseAgreement)
  );
}

export function* updateSalePurchaseAgreement(action: Extract<PropertyActions, { type: 'SalePurchaseAgreement.UpdateRequested' }>) {

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const { propertyStockId } = action.payload;
  const result: ApiResult<SalePurchaseAgreementDTO> = yield call(agreementApi.updateSalePurchaseAgreement, propertyStockId, action.payload, token);

  if (!result.error) {
    yield put({ type: 'Layout.MaskPresentRequested' });
    yield put<PropertyActions>({ type: 'SalePurchaseAgreement.Loaded', payload: result.data! });
    const agreementLocale = action.payload.agreementLocale;
    if (action.payload.extraAction === 'GENERATE_PDF') {
      yield put({ type: 'Layout.MaskDismissRequested' });
      openNewWindow(`${BASE_URL}/provisionalAgreement/generate-report/${propertyStockId}/sale_and_purchase/${agreementLocale}/pdf?t=${token}`);
    } else if (action.payload.extraAction === 'SIGN_PDF') {
      yield put({ type: 'Layout.MaskDismissRequested' });
      yield put({ type: 'Layout.MaskDismissRequested' });
      const regenerate = action.payload.regenerate ? 1 : 0;
      openNewWindow(`${BASE_URL}/sign-now/sign/${propertyStockId}/sale_and_purchase/${agreementLocale}?t=${token}&regenerate=${regenerate}`);
    } else {
      yield put({ type: 'Layout.MaskDismissRequested' });
      yield* itemActionMessage('update', 'SalePurchaseAgreement');
    }
  } else {
    throw ApiError.of(result.error!);
  }


}

//////////////////////////Tenancy Agreement//////////////////////
export function* watchTenancyAgreementCreationRequested() {
  yield takeEvery(
    'TenancyAgreement.CreationRequested',
    apiTaskWrapper(createTenancyAgreement),
  );
}

export function* createTenancyAgreement(action: Extract<PropertyActions, { type: 'TenancyAgreement.CreationRequested' }>) {

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const { propertyStockId } = action.payload;
  const result: ApiResult<TenancyAgreementDTO> = yield call(agreementApi.addTenancyAgreement, propertyStockId, action.payload, token);

  if (!result.error) {
    yield put({ type: 'Layout.MaskPresentRequested' });
    yield put<PropertyActions>({ type: 'TenancyAgreement.Loaded', payload: result.data! });
    const agreementLocale = action.payload.agreementLocale;
    if (action.payload.extraAction === 'GENERATE_PDF') {
      yield put({ type: 'Layout.MaskDismissRequested' });
      openNewWindow(`${BASE_URL}/provisionalAgreement/generate-report/${propertyStockId}/tenancy/${agreementLocale}/pdf?t=${token}`);
    } else if (action.payload.extraAction === 'SIGN_PDF') {
      yield put({ type: 'Layout.MaskDismissRequested' });
      const regenerate = action.payload.regenerate ? 1 : 0;
      openNewWindow(`${BASE_URL}/sign-now/sign/${propertyStockId}/tenancy/${agreementLocale}?t=${token}&regenerate=${regenerate}`);
    } else {
      yield put({ type: 'Layout.MaskDismissRequested' });
      yield* itemActionMessage('create', 'TenancyAgreement');
    }
  } else {
    throw ApiError.of(result.error!);
  }

}

export function* watchTenancyAgreementFetchRequested() {
  yield takeEvery<PropertyActions['type']>(
    'TenancyAgreement.FetchRequested',
    apiTaskWrapper(requestTenancyAgreement),
  )
}

export function* requestTenancyAgreement(action: PropertyActions) {
  if (action.type != 'TenancyAgreement.FetchRequested') {
    return;
  }

  const { propertyStockId } = action.payload;

  if (propertyStockId == '') {
    return;
  }


  const token: string = yield select((state: IRootState) => state.login.token ?? '');
  const result: ApiResult<TenancyAgreementDTO> = yield call(agreementApi.getTenancyAgreement, propertyStockId, token);

  if (!result.error) {
    yield put<PropertyActions>({ type: 'TenancyAgreement.Loaded', payload: result.data! });
  } else {
    yield put<PropertyActions>({ type: 'TenancyAgreement.Failed', payload: result.error });
  }

}

export function* watchTenancyAgreementUpdateRequested() {
  yield takeEvery(
    'TenancyAgreement.UpdateRequested',
    apiTaskWrapper(updateTenancyAgreement),
  );
}

export function* updateTenancyAgreement(action: Extract<PropertyActions, { type: 'TenancyAgreement.UpdateRequested' }>) {

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const { propertyStockId } = action.payload;

  const result: ApiResult<TenancyAgreementDTO> = yield call(agreementApi.updateTenancyAgreement, propertyStockId, action.payload, token);

  if (!result.error) {
    yield put({ type: 'Layout.MaskPresentRequested' });
    yield put<PropertyActions>({ type: 'TenancyAgreement.Loaded', payload: result.data! });
    const agreementLocale = action.payload.agreementLocale;
    if (action.payload.extraAction === 'GENERATE_PDF') {
      yield put({ type: 'Layout.MaskDismissRequested' });
      openNewWindow(`${BASE_URL}/provisionalAgreement/generate-report/${propertyStockId}/tenancy/${agreementLocale}/pdf?t=${token}`);
    } else if (action.payload.extraAction === 'SIGN_PDF') {
      yield put({ type: 'Layout.MaskDismissRequested' });
      openNewWindow(`${BASE_URL}/sign-now/sign/${propertyStockId}/tenancy/${agreementLocale}?t=${token}&regenerate=1`);
    } else {
      yield put({ type: 'Layout.MaskDismissRequested' });
      yield* itemActionMessage('update', 'TenancyAgreement');
    }
  } else {
    throw ApiError.of(result.error!);
  }

}