import { Card, CardContent, CardHeader, colors, Grid, makeStyles, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { JobDTO } from 'common/dto';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';


export interface JobCardProps extends JobDTO {
  
}

const icons = {
  'SUCCEED': <DoneIcon />,
  'FAILED': <ClearIcon />,
  'RUNNING': <PlayArrowIcon />,
};

const useStyles = makeStyles(theme => ({
  icon: {
    padding: '4px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
  },
}));

export default function JobCard(props: JobCardProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { langJobMonitor } = useSelector((root: IRootState) => root.locale);
  const DISPLAY_DATETIME_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATETIME_FORMAT);

  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));;

  const statusTexts = {
    'FAILED': langJobMonitor.actionFailed ?? 'Failed',
    'SUCCEED': langJobMonitor.actionDone ?? 'Done',
    'RUNNING': langJobMonitor.actionRunning ?? 'Running',
  };

  const statusColors = {
    'FAILED': colors.red[500],
    'SUCCEED': colors.green[500],
    'RUNNING': colors.orange[500],
  };

  const jobMissed = useMemo(() => 
    !props.intervalMin ? false : moment().isAfter(moment(props.lastStartTime).add(props.intervalMin + 2, 'minutes'))
  , [ props.intervalMin, props.lastStartTime ]);

  return <Card>
    <CardHeader
      title={<div>
        <div>
          <Typography variant="subtitle1">{props.display}</Typography>
        </div>
        <div>
          <Typography variant="body1">{props.schedule}</Typography>
        </div>
      </div>}
      action={
        <div className={classes.icon} style={{ backgroundColor: statusColors[props.status] }}>
          <span style={{ color: '#fff' }}>{icons[props.status]}</span>
          <Typography  style={{ color: '#fff' }} variant="caption">{statusTexts[props.status]}</Typography>
        </div>
      }
    />

    <CardContent>
      <Grid container>
        <Grid item sm={12} xs={12}>
          <Typography variant="body2">{langJobMonitor.captionLastStartTime ?? 'Last Start Time'}:&nbsp;</Typography>
          <Typography variant="subtitle2" style={{ color: jobMissed ? colors.orange[800] : undefined }}>
            {moment(props.lastStartTime).format(DISPLAY_DATETIME_FORMAT)}&nbsp;
            {jobMissed ? <span style={{ color: colors.orange[800], fontStyle: 'italic' }}>
              &nbsp;{langJobMonitor.msgJobMissed ?? '(Last job was missed)'}
            </span> : <span style={{ color: colors.green[800], fontStyle: 'italic' }}>
              &nbsp;{langJobMonitor.msgStartedExpectedly ?? '(Started expectedly)'}
            </span>}
          </Typography>
        </Grid>
        <Grid item sm={12} xs={12} style={{ marginTop: isXsScreen ? '8px' : undefined }}>
          <Typography variant="body2">{langJobMonitor.captionLastEndTime ?? 'Last End Time'}:&nbsp;</Typography>
          <Typography variant="subtitle2">
            {props.lastEndTime ? moment(props.lastEndTime).format(DISPLAY_DATETIME_FORMAT) : '-'}
            {props.status === 'RUNNING' &&
              props.expectedMaxRunningMin &&
              moment().isAfter(moment(props.lastStartTime).add(props.expectedMaxRunningMin, 'minutes')) ?
              <span style={{ color: colors.orange[800] }}>
                &nbsp;{langJobMonitor.msgRunningLongerThanExpected ?? '(Lasts longer than expected)'}
              </span> : null
            }
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
}