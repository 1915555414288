import {
	Card,
	CardActions,
	CardContent, colors, Divider,
	Table,
	TableBody,
	TableCell,
	TableHead, TableRow, TableSortLabel,
	// TablePagination,
	Theme
} from '@material-ui/core';
import { CardProps } from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { BuildingListItemDTO } from 'common/dto';
import DataRow from 'common/elements/DataRow';
import TablePagination from 'common/elements/TablePagination';
import moment from 'moment';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { IRootState } from 'reducers';


const PUBLIC_URL = process.env['PUBLIC_URL'];

const useStyles = makeStyles((theme: Theme) => ({
	root: {},
	content: {
		padding: 0
	},
	inner: {
		minWidth: 1050
	},
	nameContainer: {
		display: 'flex',
		alignItems: 'center'
	},
	avatar: {
		marginRight: theme.spacing(2)
	},
	actions: {
		justifyContent: 'flex-start',
		background: colors.grey[50],
		flexWrap: 'wrap',
		'& > *': {
			wordBreak: 'keep-all',
			// marginBottom: '8px',
		},
		padding: '0px',
	},
	tableRow: {
		cursor: 'pointer',
	},
	tableCell: {
		wordWrap: 'normal',
		wordBreak: 'keep-all',
		lineBreak: 'strict',
		whiteSpace: 'nowrap',
		// minWidth: 128,
	},
	tablePaginationIconButton: {
		'& .MuiIconButton-root': {
			padding: '6px',
		}
	},
	tablePaginationToolbar: {
		paddingRight: '12px',
	},
	clearSortingContainer: {
		padding: '3px',
	}
}));

type Header = {
	title: string | JSX.Element,
	overrideStyle?: any,
};

interface BuildingsTableProps extends CardProps {
	className?: string;
	buildings: BuildingListItemDTO[];
	// matchingClient?: {
	//   name: string;
	// } | null;
	// onCancelPropertyMatching?: () => void;

	// formSigned?: boolean;
	// setFormSigned?: (v: boolean) => void;

	page: number;
	totalPages: number;
	totalElements: number;
	limit: number;
	setPage: (page: number) => void;
	sortOrders?: { [k: string]: 'desc' | 'asc' };
	handleSort?: (key: string | null) => void;
	handleRowsCount: (count: number) => void;
}

const BuildingsTable = (props: BuildingsTableProps) => {
	const { className, buildings, sortOrders, handleSort, page, totalPages, totalElements, limit, setPage, handleRowsCount, ...rest } = props;
	const { langBuilding: lang, lang: langCommon, langUser, locale, usageOptions, districtHkiOptions, districtKltOptions, districtNtOptions, rowsCountPrefOptions } = useSelector((state: IRootState) => state.locale);
	const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);
	const classes = useStyles(0);
	const history = useHistory();

	const allDistrictOptions = {
		...districtHkiOptions,
		...districtKltOptions,
		...districtNtOptions,
	}

	let tableHeaders: Record<any, Header | null> = { // keyof BuildingListItemDTO
		// usage: { title: lang.usage },
		district: { title: lang.district },
		[locale === 'en' ? 'streetEn' : 'streetZh']: { title: lang.street },
		[locale === 'en' ? 'buildingNameEn' : 'buildingNameZh']: { title: lang.buildingOrVillage },
		[locale === 'en' ? 'blockEn' : 'blockZh']: { title: lang.block },
		lot: { title: lang.lot },
		locationScore: { title: lang.locationScore },
		createdBy: { title: langCommon.createdBy },
		dateCreated: { title: langCommon.dateCreated },
		modifiedBy: { title: langCommon.modifiedBy },
		dateModified: { title: langCommon.dateModified }
	};

	const navigationLink = (id: string) => `/buildings/${id}`;

	const handleNavigateToItem = (id: string) => () => {
		sessionStorage.setItem('lastClickedBuilding', id);
		history.push(navigationLink(id));
	}

	const handlePageChange = (event: React.MouseEvent | null, page: number) => {
		setPage(page);
	};

	const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		handleRowsCount(+event.target.value);
	};

	const overrideCols: { [col: string]: (building: BuildingListItemDTO) => any } = {
		// usage: (building) => usageOptions[building.usage],
		district: (building) => allDistrictOptions[building.district],
		dateCreated: (building) => building.dateCreated ? moment(building.dateCreated).format(DISPLAY_DATE_FORMAT) : '',
		dateModified: (building) => building.dateModified ? moment(building.dateModified).format(DISPLAY_DATE_FORMAT) : ''
	}

	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>
			<CardActions className={classes.actions}>
				{/* {wishlistVariant && setFormSigned && !formSigned ? <Button variant="outlined" color="secondary" onClick={() => setFormSigned(true)} style={{ marginRight: 10 }}>簽署睇樓紙 (表格4)</Button> : null}
				{wishlistVariant && !formSigned ? <Button variant="outlined">{langCommon.actionRemoveSelected}</Button> : null}

				}*/}
				<div style={{ flexGrow: 1 }} />
				{/* {Object.keys(sortOrders ?? {}).length ?
          <div className={classes.clearSortingContainer}>
						<Button style={{ marginRight: 0 }} variant="outlined" size="small" onClick={() => handleSort?.(null)}>{langCommon.actionClearSorting}</Button>
          </div>
				: null} */}

				<TablePagination
					classes={{
						actions: classes.tablePaginationIconButton,
						toolbar: classes.tablePaginationToolbar,
					}}
					// component="div"
					count={totalElements}
					onChangePage={handlePageChange}
					onChangeRowsPerPage={handleRowsPerPageChange}
					page={page}
					scrollocate={250}
					totalPages={totalPages}
					rowsPerPage={limit}
					labelRowsPerPage={langUser.rowsCount}
					rowsPerPageOptions={Object.keys(rowsCountPrefOptions).map(Number)}
				/>
			</CardActions>

			<Divider />

			<CardContent className={classes.content}>
				<PerfectScrollbar>
					<div className={classes.inner}>
						<Table size="small">
							<TableHead>
								<TableRow>
									{/* {matchingClient || (wishlistVariant && !formSigned) ? <TableCell padding="checkbox">
										<Checkbox
											checked={selectedProperties.length === properties.length}
											color="primary"
											indeterminate={
												selectedProperties.length > 0 &&
												selectedProperties.length < properties.length
											}
											onChange={handleSelectAll}
										/>
									</TableCell> : null} */}
									{
										Object.keys(tableHeaders).filter((key) => !!tableHeaders[key as keyof PAS.BuildingListItem]).map((key, idx) =>
											<TableCell key={idx} className={classes.tableCell} style={tableHeaders[key as keyof PAS.BuildingListItem]?.overrideStyle}>
												<TableSortLabel active={(key === 'dateModified' && sortOrders !== undefined && sortOrders?.[key] !== undefined && Object.keys(sortOrders)[0] === 'dateModified') || (key !== 'dateModified' && sortOrders?.[key] !== undefined)} direction={sortOrders?.[key]} onClick={() => handleSort?.(key)}>
													{tableHeaders[key as keyof PAS.BuildingListItem]?.title}
												</TableSortLabel>
											</TableCell>
										)
									}
									{/* <TableCell className={classes.tableCell}>
										成交紀錄
									</TableCell> */}
								</TableRow>
								{Object.keys(sortOrders ?? {}).length ? <TableRow>

								</TableRow> : null}
							</TableHead>
							<TableBody>
								{buildings.map(building => (
									<DataRow
										className={classes.tableRow}
										hover
										key={building.id}
										// selected={selectedProperties.indexOf(property.id!) !== -1}
										onClick={handleNavigateToItem(building.id!)}
										href={PUBLIC_URL + navigationLink(building.id!)}
									>
										{/* {matchingClient || (wishlistVariant && !formSigned) ? <TableCell size="small" className={classes.tableCell} key={`${property.id!}-checkbox`} padding="checkbox">
											<Checkbox
												checked={selectedProperties.indexOf(property.id!) !== -1}
												color="primary"
												onChange={event => handleSelectOne(event, property.id!)}
												value="true"
											/>
										</TableCell> : null} */}
										{
											Object.keys(tableHeaders).filter((key) => !!tableHeaders[key as keyof PAS.BuildingListItem]).map((key) => {
												// 
												return <TableCell style={building.id?.toString() === sessionStorage.getItem('lastClickedBuilding') ? { fontWeight: 'bold', color: '#551A8B ' } : {}} className={classes.tableCell} key={`${building.id}-${key}`}>{overrideCols[key] ? overrideCols[key](building) : building[key as keyof PAS.BuildingListItem]}</TableCell>
											})
										}
										{/* <TableCell style={{ cursor: 'default' }} onClick={(ev) => ev.stopPropagation()} size="small" className={classes.tableCell} key={`${building.id}-viewIcon`}>
											<IconButton disabled tooltip="Will be ready with Transaction module">
												<ViewIcon />
											</IconButton>
										</TableCell> */}
									</DataRow>
								))}
							</TableBody>
						</Table>
					</div>
				</PerfectScrollbar>
			</CardContent>
			<CardActions className={classes.actions}>
				<div style={{ flexGrow: 1 }} />
				<TablePagination
					classes={{
						actions: classes.tablePaginationIconButton,
						toolbar: classes.tablePaginationToolbar,
					}}
					count={totalElements}
					onChangePage={handlePageChange}
					onChangeRowsPerPage={handleRowsPerPageChange}
					page={page}
					scrollocate={250}
					totalPages={totalPages}
					rowsPerPage={limit}
					labelRowsPerPage={langUser.rowsCount}
					rowsPerPageOptions={Object.keys(rowsCountPrefOptions).map(Number)}
				/>
			</CardActions>

		</Card>
	);
}

export default BuildingsTable;