import { ApiError, ApiResult } from "api";
import closeTransactionApi from "api/closeTransactionApi";
import { CloseTransactionDTO } from "common/dto";
import { CloseTransactionActions, IRootState, PropertyActions } from "reducers";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { apiTaskWrapper, itemActionMessage } from './saga-commons';


export function* watchCloseTransactionFetchRequested() {
	yield takeEvery( 
		'CloseTransaction.FetchRequested',
		apiTaskWrapper(requestCloseTransaction)
	)
}
	
export function* requestCloseTransaction(action: CloseTransactionActions){
	if (action.type != 'CloseTransaction.FetchRequested') {
		return;
	}

	const { pid } = action.payload;

	const token: string = yield select((state: IRootState) => state.login.token ?? '');
	const locale: string = yield select((state: IRootState) => state.locale.locale ?? '');

	const result: ApiResult<CloseTransactionDTO> = yield call(closeTransactionApi.getDetail, pid, token, locale);
	
	if (!result.error) {
		yield put<CloseTransactionActions>({ type: 'CloseTransaction.Loaded', payload: result.data! });
	} else {
		throw ApiError.of(result.error!);
	}
}

export function* watchAddCloseTransactionRequested() {
	yield takeLatest( 
		'CloseTransaction.AddRequested',
		apiTaskWrapper(requestAddCloseTransaction)
	)
}
	
export function* requestAddCloseTransaction(action: CloseTransactionActions){
	if (action.type != 'CloseTransaction.AddRequested') {
		return;
	}

	const token: string = yield select((state: IRootState) => state.login.token ?? '');
	
	const result: ApiResult<any> = yield call(closeTransactionApi.add, action.payload, token);
	
	if (!result.error) {
		if (action.payload.extraAction === 'REQUEST_FOR_CONFIRMATION') {
			yield put<CloseTransactionActions>({ type: 'CloseTransaction.RequestConfirmationRequested', payload: { id: result.data.id ?? '' }});
		}else{
			yield put<CloseTransactionActions>({ type: 'CloseTransaction.FetchRequested', payload: { pid: result.data.pid ?? '' }});
			yield* itemActionMessage('create', 'CloseTrnx');
		}
	} else {
		throw ApiError.of(result.error!);
	}
}

export function* watchUpdateCloseTransactionRequested() {
	yield takeLatest( 
		'CloseTransaction.UpdateRequested',
		apiTaskWrapper(requestUpdateCloseTransaction)
	)
}
	
export function* requestUpdateCloseTransaction(action: CloseTransactionActions){
	if (action.type != 'CloseTransaction.UpdateRequested') {
		return;
	}

	const token: string = yield select((state: IRootState) => state.login.token ?? '');
	
	const result: ApiResult<any> = yield call(closeTransactionApi.update, action.payload, token);
	
	if (!result.error) {
		if (action.payload.extraAction === 'REQUEST_FOR_CONFIRMATION') {
			yield put<CloseTransactionActions>({ type: 'CloseTransaction.RequestConfirmationRequested', payload: { id: action.payload.id ?? '' }});
		}else{
			yield put<CloseTransactionActions>({ type: 'CloseTransaction.FetchRequested', payload: { pid: result.data ?? ''}});
			yield* itemActionMessage('update', 'CloseTrnx');
		}
	}else{
		throw ApiError.of(result.error!);
	}
}

export function* watchRequestConfirmationRequested() {
	yield takeLatest( 
		'CloseTransaction.RequestConfirmationRequested',
		apiTaskWrapper(requestConfirmationRequest)
	)
}
	
export function* requestConfirmationRequest(action: CloseTransactionActions){
	if (action.type != 'CloseTransaction.RequestConfirmationRequested') {
		return;
	}

	const token: string = yield select((state: IRootState) => state.login.token ?? '');
	
	const result: ApiResult<CloseTransactionDTO> = yield call(closeTransactionApi.confirmRequest, action.payload.id, token);
	
	if (!result.error) {
		yield put<CloseTransactionActions>({ type: 'CloseTransaction.Loaded', payload: result.data! });
		yield* itemActionMessage('confirmRequest', 'CloseTrnx');
	} else {
		throw ApiError.of(result.error!);
	}
}

export function* watchConfirmationRequested() {
	yield takeLatest( 
		'CloseTransaction.ConfirmationRequested',
		apiTaskWrapper(requestConfirmation)
	)
}
	
export function* requestConfirmation(action: CloseTransactionActions){
	if (action.type != 'CloseTransaction.ConfirmationRequested') {
		return;
	}

	const token: string = yield select((state: IRootState) => state.login.token ?? '');
	
	const result: ApiResult<CloseTransactionDTO> = yield call(closeTransactionApi.confirm, action.payload.id, action.payload.signatureBlobUrl, token);
	
	if (!result.error) {
		yield put<CloseTransactionActions>({ type: 'CloseTransaction.Loaded', payload: result.data! });
		yield* itemActionMessage('confirm', 'CloseTrnx');
	} else {
		throw ApiError.of(result.error!);
	}
}

export function* watchApproveRequested() {
	yield takeLatest( 
		'CloseTransaction.ApproveRequested',
		apiTaskWrapper(requestApprove)
	)
}
	
export function* requestApprove(action: CloseTransactionActions){
	if (action.type != 'CloseTransaction.ApproveRequested') {
		return;
	}

	const token: string = yield select((state: IRootState) => state.login.token ?? '');
	
	const result: ApiResult<CloseTransactionDTO> = yield call(closeTransactionApi.approve, action.payload.id, action.payload.approverSignBlobUrl, token);
	
	if (!result.error) {
		yield put<CloseTransactionActions>({ type: 'CloseTransaction.Loaded', payload: result.data! });
		yield* itemActionMessage('approve', 'CloseTrnx');
		yield put<PropertyActions>({ type: 'Property.FetchRequested', payload: { id: result.data!.propertyStockId ?? '' } });
	} else {
		throw ApiError.of(result.error!);
	}
}


export function* watchRejectRequested() {
	yield takeLatest( 
		'CloseTransaction.RejectRequested',
		apiTaskWrapper(requestReject)
	)
}
	
export function* requestReject(action: CloseTransactionActions){
	if (action.type != 'CloseTransaction.RejectRequested') {
		return;
	}

	const token: string = yield select((state: IRootState) => state.login.token ?? '');
	
	const result: ApiResult<CloseTransactionDTO> = yield call(closeTransactionApi.reject, action.payload.id, action.payload.remarks, token);
	
	if (!result.error) {
		yield put<CloseTransactionActions>({ type: 'CloseTransaction.Loaded', payload: result.data! });
		yield* itemActionMessage('reject', 'CloseTrnx');
	} else {
		throw ApiError.of(result.error!);
	}
}


export function* watchRequestApprovalRequested() {
	yield takeLatest( 
		'CloseTransaction.RequestApprovalRequested',
		apiTaskWrapper(requestApprovalRequest)
	)
}
	
export function* requestApprovalRequest(action: CloseTransactionActions){
	if (action.type != 'CloseTransaction.RequestApprovalRequested') {
		return;
	}

	const token: string = yield select((state: IRootState) => state.login.token ?? '');
	
	const result: ApiResult<CloseTransactionDTO> = yield call(closeTransactionApi.approvalRequest, action.payload.id, token);
	
	if (!result.error) {
		yield put<CloseTransactionActions>({ type: 'CloseTransaction.Loaded', payload: result.data! });
		yield* itemActionMessage('approvalSubmit', 'CloseTrnx');
	} else {
		throw ApiError.of(result.error!);
	}

}