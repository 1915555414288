import {
    Card,
    CardContent,
    Divider,
    Grid,
    TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { BuildingDetailDTO } from 'common/dto';
import { FormPresenter } from 'common/hooks';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';

const useStyles = makeStyles(() => ({
	root: {},
	patchOutlineAutocomplete: {
		'& .MuiAutocomplete-inputRoot': {
			padding: 0,
			paddingLeft: 8,
		}
	}
}));

interface InfoProps {
  className?: string;
  form: FormPresenter<Partial<BuildingDetailDTO>>;
  isNew: boolean;
}

const Info = (props: InfoProps) => {
  const { className, form, ...rest } = props;
  const { lang} = useSelector((state: IRootState) => state.locale);
  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);
  const classes = useStyles();

  const bind = form.bind;
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardContent>
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              md={3}
              sm={3}
              xs={12}
            >
              <TextField
                fullWidth
                label={lang.createdBy}
                margin="dense"
								name="lastName"
								disabled
                variant="outlined"
                {...bind('createdBy')}
              />
            </Grid>

						<Grid
              item
              md={3}
              sm={3}
              xs={12}
            >
              <TextField
                fullWidth
                label={lang.dateCreated}
                margin="dense"
                name="lastName"
                disabled
                variant="outlined"
                value={form.values.dateCreated ? moment(form.values.dateCreated).format(DISPLAY_DATE_FORMAT) : ''}
              />
            </Grid>
						<Grid
              item
              md={3}
              sm={3}
              xs={12}
            >
              <TextField
                fullWidth
                label={lang.modifiedBy}
                margin="dense"
								name="lastName"
								disabled
								variant="outlined"
								{...bind('modifiedBy')}
              />
            </Grid>

						<Grid
              item
              md={3}
              sm={3}
              xs={12}
            >
              <TextField
                fullWidth
                label={lang.dateModified}
                margin="dense"
                name="lastName"
                disabled
								variant="outlined"
								value={form.values.dateModified ? moment(form.values.dateModified).format(DISPLAY_DATE_FORMAT) : ''}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        {/* <CardActions>
          <Button
            color="primary"
            variant="contained"
          >
            Save details
          </Button>
        </CardActions> */}
      </form>
    </Card>
  );
};

export default Info;