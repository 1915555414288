import { ConfirmDialog } from 'common/ConfirmDialog';
import React from "react";
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import NavigationPrompt from "react-router-navigation-prompt";
import { IRootState } from "reducers";


interface NavigationBlockerDialogProps {
	unsaved: boolean;
	goBack?: boolean;
	matchesUrl?: string;
}

export default function NavigationBlockerDialog({ unsaved, goBack, matchesUrl }: NavigationBlockerDialogProps) {
	const env = process.env['PUBLIC_URL'];
	const { lang } = useSelector((state: IRootState) => state.locale);
	const match = useRouteMatch(matchesUrl ?? '');
	//bypass NavigationBlocer when logout is triggered
	if ((matchesUrl && (!match || !match.isExact))||(localStorage.getItem('logoutEnv')===env)) {
		return <></>;
	}

	return <NavigationPrompt
		when={(crntLocation, nextLocation) =>
			(!nextLocation || !nextLocation.pathname.startsWith(crntLocation.pathname)) && unsaved && !nextLocation?.state?.bypassUnsavedCheck
		}
		allowGoBack={goBack ?? false}
	>
		{({ onConfirm, onCancel }) => (
			<ConfirmDialog
				open={unsaved}
				onClose={(confirm: boolean) => confirm ? onConfirm() : onCancel()}
				message={lang.msgUnsavedChanges}
				actionCancel={lang.actionCancel}
				actionConfirm={lang.actionConfirm}
				// title={"Navigation Blocker"}
			/>
		)}
	</NavigationPrompt>
}