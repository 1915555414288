import { Button, Card, CardActions, CardContent, Checkbox as MuiCheckbox, FormControl, Grid, InputLabel, ListItemText, makeStyles, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { CardProps } from '@material-ui/core/Card';
import { Autocomplete } from '@material-ui/lab';
import userApi from 'api/userApi';
import { UserListItemDTO } from 'common/dto';
import { useForm } from 'common/hooks';
import { HandleFormHelperText, tooltipHoc } from 'common/ui';
import { initFormKeysAndBooleanMap } from 'common/utils';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';

//#region Common UI

const Checkbox = tooltipHoc(MuiCheckbox);

const useStyles = makeStyles(() => ({
  patchOutlineAutocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
      paddingLeft: 8,
    }
  },
}));

//#endregion Common UI

export interface ActivityAuditingSearchForm {
  userId: string;
  modules: string[];
  actions: string[];
}

type Options = { [key: string]: string };

interface ActivityAuditingSearchProps extends CardProps {
  onSearch?(values: Partial<ActivityAuditingSearchForm>): any;
}


const ActivityAuditingSearch = (props: ActivityAuditingSearchProps) => {
  const classes = useStyles();
  const searchForm = useForm<ActivityAuditingSearchForm>({
    modules: [],
    actions: [],
  });

  const { locale, langActivityAuditing, lang, activityAuditingModuleOptions } = useSelector((state: IRootState) => state.locale);

  const bind = searchForm.bind;


  const multiSelect = (name: keyof ActivityAuditingSearchForm, label: string, options: Options) => <FormControl variant="outlined" margin="dense" fullWidth>
    <InputLabel variant="outlined" style={{ background: '#fff' }}>{label}</InputLabel>
    <Select
      multiple
      {...bind(name)}
      renderValue={(selected) => (selected as string[]).map(key => options[key]).join(', ')}
    >
      {Object.keys(options).map(key => (
        <MenuItem key={key} value={key}>
          <Checkbox checked={(searchForm.values[name]! as string[]).indexOf(key) > -1} />
          <ListItemText primary={options[key]} />
        </MenuItem>
      ))}
    </Select>
  </FormControl>;

  const [users, setUsers] = useState<UserListItemDTO[]>([]);
  const [agentListLoading, setAgentListLoading] = useState(false);

  const token: string = useSelector((state: IRootState) => state.login.token ?? '');

  const fetchAgents = (keyword: string) => {
    if (keyword.length < 2) { return; }
    setAgentListLoading(true);
    userApi.getList({ username: keyword, onlyHasCreateOwnClientPrivilege: false }, token).then(result => {
      if (result.data?.content) {
        setUsers(result.data!.content);
        setAgentListLoading(false);
      }
    });
  };

  const userMap: { [id: string]: UserListItemDTO } = {};
  users.forEach(user => {
    userMap[user?.id] = user;
  });

  const [keyBooleanMap, setKeyBooleanMap] = useState(initFormKeysAndBooleanMap(searchForm));

  useEffect(() => {
    setKeyBooleanMap(initFormKeysAndBooleanMap(searchForm));
  }, [Object.keys(searchForm.values).length])

  const userAutocomplete = () => <Autocomplete
    options={users}
    freeSolo={true}
    popupIcon={''}
    noOptionsText={''}
    loading={agentListLoading}
    getOptionLabel={(userId) => {
      const user = userMap[userId];
      // return (locale === 'en' ? user?.englishName : user?.chineseName) ?? user?.username ?? '';
      return user?.username ?? '';
    }}
    loadingText={lang.msgLoading}
    renderOption={(user: UserListItemDTO) => <div>
      <div><Typography>{user?.username} ({(locale === 'en' ? user?.englishName : user?.chineseName) ?? '-'})</Typography></div>
      {/* <div><Typography variant="caption">{agents.filter(agent => agent.username === option)?.[0]?.mainContact}</Typography></div> */}
    </div>}
    renderInput={(params: any) => (
      <TextField
        {...params}
        inputProps={{ ...params.inputProps, maxLength: 255 }}
        className={classes.patchOutlineAutocomplete}
        fullWidth
        label={langActivityAuditing.username}
        margin="dense"
        // required
        variant="outlined"
        onChange={(ev) => {
          setKeyBooleanMap(keyBooleanMap.set("userId", ev.target.value.length === 255))
          fetchAgents(ev.target.value)
        }}
      />
    )}
    value={searchForm.values.userId ?? ''}
    onChange={(_: any, user: UserListItemDTO | undefined) => {
      searchForm.updateValues('userId', user?.id ?? '');
    }}
  />

  const filteredSearchForm = () => {
    // searchForm.values = {
    //   ...searchForm.values,
    //   userId:searchForm.values.userId?.trim(),
    // }
    const newForm: Partial<ActivityAuditingSearchForm> = {};
    Object
      .keys(searchForm.values)
      // .filter((key) => !disabledCriterias[key])
      .forEach((key) => (newForm as any)[key] = searchForm.values[key as keyof ActivityAuditingSearchForm]);
    return newForm;
  };

  return (
    <Card
      {...props}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardContent>
          <Grid
            container
            spacing={1}
          >
            <Grid item container md={12} xs={12} spacing={1}>
              <Grid
                item
                md={12}
                xs={12}
              >
                <Typography style={{ fontWeight: 'bold' }}>&nbsp;</Typography>
              </Grid>

              <Grid
                item
                md={6}
                sm={6}
                xs={12}
              >
                {userAutocomplete()}
                <HandleFormHelperText
                  isError={keyBooleanMap.get('userId')}
                  errorMessage={lang.textOverLimit}
                />
              </Grid>

              <Grid
                item
                md={6}
                sm={6}
                xs={12}
              >
                {multiSelect('modules', langActivityAuditing.module, activityAuditingModuleOptions)}
              </Grid>

            </Grid>

          </Grid>
        </CardContent>
      </form>

      <CardActions style={{ flexWrap: 'wrap' }}>
        <div style={{ flexGrow: 1 }} />
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.onSearch?.(filteredSearchForm())}>
          {lang.actionSearch}
        </Button>
      </CardActions>
    </Card>
  );
}

export default ActivityAuditingSearch;
