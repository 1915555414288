import { History } from 'history';
import { all, fork } from 'redux-saga/effects';
import { watchActivityAuditingListFetchRequested } from './activityAuditingSaga';
import { watchAgentRatingCommentApproveRequested, watchAgentRatingCommentListFetchRequested, watchAgentRatingCommentRejectRequested } from './agentRatingCommentSaga';
import {
  watchSalePurchaseAgreementCreationRequested,
  watchSalePurchaseAgreementFetchRequested,
  watchSalePurchaseAgreementUpdateRequested,
  watchTenancyAgreementCreationRequested,
  watchTenancyAgreementFetchRequested,
  watchTenancyAgreementUpdateRequested
} from './agreementSaga';
import {
  watchBuildingAliasAddRequested, watchBuildingAliasListFetchRequested, watchBuildingAliasRemoveRequested, watchBuildingCreationRequested, watchBuildingDetailFetchRequested, watchBuildingListFetchRequested, watchBuildingMergeRequested, watchBuildingUpdateRequested
} from './buildingSaga';
import { watchAddStockCartRequested, watchAssignAgentRequested, watchClaimClientRequested, watchClientColumnPreferenceRequested, watchClientDetailCreationRequested, watchClientDetailFetchRequested, watchClientDetailUpdateRequested, watchClientListFetchRequested, watchClientMergeRequested, watchMemoCreateRequested, watchMemoFetchRequested, watchRemoveAgentRequested, watchRemoveClientRequested, watchRemoveStockCartRequested, watchSellerStockFetchRequested, watchStockCartFetchRequested } from './clientSaga';
import { watchCheckVerificationContactRequestedRequested, watchHandleClientSignRequested, watchVerifyTokenAndStatusRequested } from './clientSignSaga';
import {
  watchAddCloseTransactionRequested, watchApproveRequested, watchCloseTransactionFetchRequested, watchConfirmationRequested, watchRejectRequested, watchRequestApprovalRequested, watchRequestConfirmationRequested, watchUpdateCloseTransactionRequested
} from './closeTransactionSaga';
import { watchCommissionReportListFetchRequested } from './commissionRerportSaga';
import {
  watchDashboardFetchRequested, watchDashboardPrefUpdatedAndSaved
} from './dashboardSaga';
import { watchFeatureTagListFetchRequested, watchFeatureTagListUpdateRequested } from './featureTagSaga';
import { watchVerifyTokenRequested } from './ForgotPasswordSaga';
import {
  watchAllFormsFetchRequested, watchForm1CreationRequested, watchForm1FetchRequested, watchForm1UpdateRequested, watchForm2CreationRequested, watchForm2FetchRequested, watchForm2UpdateRequested, watchForm3CreationRequested, watchForm3FetchRequested, watchForm3UpdateRequested, watchForm4CreationRequested, watchForm4FetchRequested, watchForm4UpdateRequested,
  watchForm5CreationRequested, watchForm5FetchRequested, watchForm5UpdateRequested,
  watchForm6CreationRequested, watchForm6FetchRequested, watchForm6UpdateRequested
} from './formSaga';
import { watchDataReloadRequest, watchInitRequest } from './initSaga';
import { watchJobMonitorFetchRequested } from './jobMonitorSaga';
import { watchLayoutMessageAdded } from './layoutSaga';
import { watchLoginLogoutRequested, watchLoginRequestStart } from './loginSaga';
import { watchMasterDataRequestStart } from './masterDataSaga';
import { watchMemoEnquiryListFetchRequested } from './memoEnquirySaga';
import { notificationPolling, watchNotificationDeleteRequested, watchNotificationDoneRequested, watchNotificationExtraActionRequested, watchNotificationFetchRequested, watchNotificationPageFetchRequested, watchNotificationReadRequested } from './notificationSaga';
import {
  watchAddLeadAgentRequested, watchAddStockCartMultiClientRequested, watchClaimPropertyRequested, watchPropertyApprovalRequested, watchPropertyColumnPreferenceRequested, watchPropertyDetailCreationRequested, watchPropertyDetailFetchByPropertyNoRequested, watchPropertyDetailFetchRequested, watchPropertyDetailUpdateRequested, watchPropertyListFetchRequested, watchPropertyMemoCreateRequested, watchPropertyMemoFetchRequested, watchProvisionalAgreementDeleteRequested, watchRemoveLeadAgentRequested,
  watchRemovePropertyRequested, watchStatusUpdateRequested, watchUpdateSellerClientRequested, watchWishlistGrantedAgentsFetchRequested, watchWishlistGrantedAgentsUpdateRequested
} from './propertySaga';
import { watchResourceFetchRequested, watchRoleListFetchRequested, watchRolePrivilegeCreationRequested, watchRolePrivilegeFetchRequested, watchRolePrivilegeUpdateRequested } from './rolePrivilegeSaga';
import { fetchFrontendVersion, watchSystemSettingCreationRequested, watchSystemSettingListFetchRequested, watchSystemSettingUpdateRequested } from './systemSettingSaga';
import { watchTransactionColumnPreferenceRequested, watchTransactionDetailFetchRequested, watchTransactionListFetchRequested, watchTransactionUpdatePropertyDraftRequested } from './transactionSaga';
import { watchApproveImpersonationRequested, watchApproverStopImpersonationRequested, watchImpersonateUserStopImpersonationRequested, watchImpersonationRequested, watchResetPasswordRequested, watchStartImpersonationRequested, watchUnlockRequested, watchUserCreationRequested, watchUserDetailFetchRequested, watchUserListFetchRequested, watchUserUpdateRequested } from './userSaga';
  export const rootSaga = function* root(context: PASSagaContext) {
  yield all([
    fork(watchLoginRequestStart, context),
    fork(watchLoginLogoutRequested, context),
    fork(watchMasterDataRequestStart),
    fork(watchLayoutMessageAdded),
    fork(watchInitRequest, context),
    fork(watchDataReloadRequest, context),
    // PropertyStock
    fork(watchPropertyDetailFetchRequested, context),
    fork(watchPropertyDetailFetchByPropertyNoRequested, context),
    fork(watchPropertyListFetchRequested, context),
    fork(watchPropertyDetailCreationRequested, context),
    fork(watchPropertyDetailUpdateRequested, context),
    fork(watchPropertyApprovalRequested, context),
    fork(watchPropertyMemoFetchRequested),
    fork(watchPropertyMemoCreateRequested, context),
    fork(watchStatusUpdateRequested),
    fork(watchClaimPropertyRequested, context),
    fork(watchRemoveLeadAgentRequested, context),
    fork(watchRemovePropertyRequested, context),
    fork(watchAddLeadAgentRequested, context),
    fork(watchPropertyColumnPreferenceRequested),
    fork(watchUpdateSellerClientRequested, context), 
    fork(watchAddStockCartMultiClientRequested, context),
    fork(watchProvisionalAgreementDeleteRequested, context),
    fork(watchWishlistGrantedAgentsFetchRequested, context),
    fork(watchWishlistGrantedAgentsUpdateRequested, context),
    // Transaction 
    fork(watchTransactionListFetchRequested, context),
    fork(watchTransactionDetailFetchRequested),
    fork(watchTransactionUpdatePropertyDraftRequested),
    fork(watchTransactionColumnPreferenceRequested),
    // Close Transaction
    fork(watchCloseTransactionFetchRequested),
    fork(watchAddCloseTransactionRequested),
    fork(watchUpdateCloseTransactionRequested),
    fork(watchRequestConfirmationRequested),
    fork(watchConfirmationRequested),
    fork(watchApproveRequested),
    fork(watchRejectRequested),
    fork(watchRequestApprovalRequested),
    // Building Master
    fork(watchBuildingDetailFetchRequested),
    fork(watchBuildingListFetchRequested),
    fork(watchBuildingCreationRequested, context),
    fork(watchBuildingUpdateRequested, context),
    fork(watchBuildingMergeRequested, context),
    fork(watchBuildingAliasAddRequested, context),
    fork(watchBuildingAliasListFetchRequested, context),
    fork(watchBuildingAliasRemoveRequested, context),
    // Client master
    fork(watchClientListFetchRequested, context),
    fork(watchClientDetailFetchRequested, context),
    fork(watchClientDetailCreationRequested, context),
    fork(watchClientDetailUpdateRequested, context),
    fork(watchStockCartFetchRequested),
    fork(watchAddStockCartRequested, context),
    fork(watchRemoveStockCartRequested, context),
    fork(watchClaimClientRequested, context),
    fork(watchRemoveClientRequested, context),
    fork(watchClientMergeRequested, context),
    fork(watchMemoFetchRequested),
    fork(watchMemoCreateRequested, context),
    fork(watchAssignAgentRequested, context),
    fork(watchRemoveAgentRequested, context),
    fork(watchSellerStockFetchRequested),
    fork(watchClientColumnPreferenceRequested),
    //client sign
    fork(watchVerifyTokenAndStatusRequested,context),
    fork(watchHandleClientSignRequested,context),
    fork(watchCheckVerificationContactRequestedRequested,context),
    // Form 1
    fork(watchAllFormsFetchRequested),
    fork(watchForm1CreationRequested, context),
    fork(watchForm1FetchRequested),
    fork(watchForm1UpdateRequested,context),
    // Form 2
    fork(watchForm2CreationRequested, context),
    fork(watchForm2FetchRequested),
    fork(watchForm2UpdateRequested,context),
    // Form 3
    fork(watchForm3CreationRequested, context),
    fork(watchForm3FetchRequested),
    fork(watchForm3UpdateRequested,context),
    // Form 4
    fork(watchForm4CreationRequested, context),
    fork(watchForm4FetchRequested),
    fork(watchForm4UpdateRequested,context),
    // Form 5
    fork(watchForm5CreationRequested, context),
    fork(watchForm5FetchRequested),
    fork(watchForm5UpdateRequested,context),
    // Form 6
    fork(watchForm6CreationRequested, context),
    fork(watchForm6FetchRequested),
    fork(watchForm6UpdateRequested,context),
    // Provisional Agreement
    fork(watchSalePurchaseAgreementCreationRequested),
    fork(watchSalePurchaseAgreementFetchRequested),
    fork(watchSalePurchaseAgreementUpdateRequested),
    fork(watchTenancyAgreementCreationRequested),
    fork(watchTenancyAgreementFetchRequested),
    fork(watchTenancyAgreementUpdateRequested),
    // User Management
    fork(watchUserDetailFetchRequested),
    fork(watchUserListFetchRequested),
    fork(watchUserCreationRequested, context),
    fork(watchUserUpdateRequested, context),
    fork(watchResetPasswordRequested, context),
    fork(watchUnlockRequested, context),
    // Impersonation
    fork(watchImpersonationRequested, context),
    fork(watchStartImpersonationRequested, context),
    fork(watchApproverStopImpersonationRequested, context),
    fork(watchImpersonateUserStopImpersonationRequested, context),
    fork(watchApproveImpersonationRequested),
    // Forgot password
    fork(watchVerifyTokenRequested,context),
    // Activity Auditing
    fork(watchActivityAuditingListFetchRequested, context),
    // Memo Enquiry
    fork(watchMemoEnquiryListFetchRequested),
    // Commission report
    fork(watchCommissionReportListFetchRequested, context),
    // notificationSaga
    fork(watchNotificationFetchRequested), 
    fork(watchNotificationPageFetchRequested),
    fork(watchNotificationReadRequested), 
    fork(watchNotificationDoneRequested), 
    fork(watchNotificationExtraActionRequested), 
    fork(watchNotificationDeleteRequested),
    fork(notificationPolling),
    //role privilege
    fork(watchRolePrivilegeFetchRequested, context),
    fork(watchResourceFetchRequested,context), 
    fork(watchRolePrivilegeUpdateRequested, context),
    fork(watchRoleListFetchRequested, context),
    fork(watchRolePrivilegeCreationRequested, context),
    //system setting
    fork(watchSystemSettingListFetchRequested, context),
    fork(watchSystemSettingUpdateRequested, context),
    fork(watchSystemSettingCreationRequested, context),
    //feature tag
    fork(watchFeatureTagListFetchRequested, context),
    fork(watchFeatureTagListUpdateRequested, context),
    // Dashboard
    fork(watchDashboardFetchRequested, context),
    fork(watchDashboardPrefUpdatedAndSaved, context),
    // agent rating comment mgt
    fork(watchAgentRatingCommentListFetchRequested, context),
    fork(watchAgentRatingCommentApproveRequested),
    fork(watchAgentRatingCommentRejectRequested),
    // Job Monitor
    fork(watchJobMonitorFetchRequested, context),

    //fetch Frontend Version
    fork(fetchFrontendVersion),
  ]);
};

export interface PASSagaContext {
  browserHistory: History<{
    bypassUnsavedCheck?: boolean;
    isPref?: boolean; // For user detail page
  }>;
}