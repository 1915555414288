import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState, PASDispatch } from 'reducers';
import MemoEnquirySearch, { MemoEnquirySearchDTO } from './components/MemoEnquirySearch';
import MemoEnquiryTable from './components/MemoEnquiryTable';
import MemoEnquiryToolbar from './components/MemoEnquiryToolbar';


const DEFAULT_SIZE = 10;

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  search: {
    marginBottom: theme.spacing(2)
  }
}));

function toCriteria(values: any) {
  return values;
}

let currentMemoListRow = 0;

const MemoEnquiryList = () => {
  const classes = useStyles();

  // const history = useHistory();

  const dispatch = useDispatch() as PASDispatch;
  const { rowsCountPrefOptions } = useSelector((state: IRootState) => state.locale);
  // // const propertyMatching = useSelector((state: IRootState) => state.propertyMatching);
  const memoEnquiryList = useSelector((state: IRootState) => state.memoEnquiry.currentList);
  const { totalPages, totalElements } = useSelector((state: IRootState) => state.memoEnquiry);

  const [page, setPage] = useState(0);
  // const [ limit, setLimit ] = useState(10);
  const limit = useSelector((state: IRootState) => state.login.rowsCountPreference?.MEMO_ENQUIRY) ?? DEFAULT_SIZE;
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(currentMemoListRow === 0 ? limit : currentMemoListRow);
  currentMemoListRow = currentRowsPerPage;

  const handleRowsCount = (count: number) => {
    dispatch({ type: 'Login.RowCountPrefUpdate', payload: { MEMO_ENQUIRY: count } });
  }

  const [sortOrders, setSortOrders] = useState<{ [k: string]: 'desc' | 'asc' }>({
    dateCreated: 'desc'
  });

  const [values, setValues] = useState<Partial<MemoEnquirySearchDTO>>({
    type: [],
    level: [],
  });
  // const searchForm = useForm<Partial<MemoEnquiryDTO>>({});
  const handleSearch = (values: Partial<MemoEnquirySearchDTO>) => {
    setValues(values);
    setPage(0)
    dispatch({
      type: 'MemoEnquiryList.FetchRequested', payload: {
        page: 0, size: currentRowsPerPage, sort: sortOrders, ...toCriteria(values as any)
      }
    });
  };

  const handleSort = (key: string | null) => {
    if (key === null) {
      setSortOrders({});
      dispatch({
        type: 'MemoEnquiryList.FetchRequested', payload: {
          page, size: currentRowsPerPage, sort: {}, ...toCriteria(values as any)
        }
      });
      return;
    }
    const newSortOrders = { [key]: sortOrders[key] === 'asc' ? 'desc' : 'asc' };
    setSortOrders(newSortOrders as any);
    dispatch({
      type: 'MemoEnquiryList.FetchRequested', payload: {
        page, size: currentRowsPerPage, sort: newSortOrders, ...toCriteria(values as any)
      }
    });
  }

  // // Initial Fetch
  useEffect(() => {
    dispatch({ type: 'MemoEnquiryList.FetchRequested', payload: { page, size: currentRowsPerPage, sort: sortOrders, ...toCriteria(values as any) } });
  }, [currentRowsPerPage]);

  return (
    <div className={classes.root}>
      <MemoEnquiryToolbar />
      <div className={classes.content}>
        <MemoEnquirySearch onSearch={handleSearch} initialCriteria={values} className={classes.search}></MemoEnquirySearch>
        <MemoEnquiryTable
          page={page}
          limit={currentRowsPerPage}
          rowsCountOptions={Object.keys(rowsCountPrefOptions).map(Number)}
          handleRowsCount={(ev) => { setCurrentRowsPerPage(ev); handleRowsCount(ev); }}
          totalPages={totalPages}
          totalElements={totalElements}
          setPage={(page: any) => {
            setPage(page);
            dispatch({
              type: 'MemoEnquiryList.FetchRequested', payload: {
                page, size: currentRowsPerPage, sort: sortOrders, ...toCriteria(values as any)
              }
            });
          }}
          items={memoEnquiryList}
          sortOrders={sortOrders}
          handleSort={handleSort}
        />
      </div>
    </div>
  );
};

export default MemoEnquiryList;