import { Divider, FormControl, FormHelperText, Grid, InputLabel, makeStyles, MenuItem, Select, TextareaAutosize, Typography, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { hasPermission } from 'common/access-control';
import { MasterDataDTO } from 'common/dto';
import { FormPresenter } from 'common/hooks';
import { HandleFormHelperText } from 'common/ui';
import { initFormKeysAndBooleanMap, isNonEmpty, limitTextFieldLength } from 'common/utils';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';


const useStyles = makeStyles(() => ({
  root: {},

  textArea: {
    width: '100%',
    margin: 'dense',
    padding: '8px',
    fontSize: '14px'
  },

}));
const regex = new RegExp(/(^\d*\.?\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/);
const NumberField = withStyles({
  root: {
    '& input': {
      textAlign: 'right',
      '-moz-appearance': 'textfield',
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  },
},
)((props: TextFieldProps) => {
  return <TextField type={"text"}{...props} inputProps={{
    ...props.inputProps,
    min: 0,
    max: Math.max(),
    inputMode: 'decimal',
    pattern: "([^0-9]*)",
    step: 0.01
  }}

    onKeyDown={e => {
      // If the key pressed is not a number or a period, nothing is printed
      if (!e.key.match(regex)) {

        e.preventDefault();

      }
      // if (!e.key.match(regex) || (e.key === '.' && String(props.value).indexOf('.') > 0) || (e.key === '.' && String(props.value).length === 0) || e.keyCode === 229 || e.key === 'Unidentified') {

      //   e.preventDefault();

      // }

    }}

    onKeyPress={e => {
      // If the key pressed is not a number or a period, nothing is printed
      if (!e.key.match(regex)) {

        e.preventDefault();

      }
    }}

  />;
});

interface SystemSettingDetailDialogProps {
  open: boolean;
  handleClickOpen: () => void;
  handleClose: () => void;
  onSave: () => void;

  systemSettingForm: FormPresenter<MasterDataDTO>;
}

const SystemSettingDetailDialog = ({ open, handleClose, systemSettingForm, onSave }: SystemSettingDetailDialogProps) => {
  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);
  const { langSystemSetting, lang, statusOptions, } = useSelector((state: IRootState) => state.locale);
  const classes = useStyles(0);

  const bind = systemSettingForm.bind;

  const [keyBooleanMap, setKeyBooleanMap] = useState(initFormKeysAndBooleanMap(systemSettingForm));

  useEffect(() => {
    setKeyBooleanMap(initFormKeysAndBooleanMap(systemSettingForm));
  }, [Object.keys(systemSettingForm.values).length])

  const { privileges } = useSelector((state: IRootState) => state.login);
  const canUpdate = hasPermission(privileges, 'UPDATE', 'SYSTEM_SETTING');
  const allFieldsReadOnly = !canUpdate;
  const creating = isNonEmpty(systemSettingForm.values.id) ? false : true;
  const dialogName = creating ? langSystemSetting.actionNewSystemSetting
    : (langSystemSetting.titleSystemSettingDetailDialog + (systemSettingForm.values.id ? (' - #' + systemSettingForm.values.id) : ''));
  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="system-setting-detail-title">
        <DialogTitle id="system-setting-detail-title">{dialogName}</DialogTitle>
        <DialogContent>
          <Grid item container md={12} xs={12} spacing={1} >
            <Grid
              item
              md={12}
              xs={12}
              style={{ display: creating ? 'none' : 'flex' }}
              container
              spacing={1}
            >

              <Grid
                item
                md={3}
                sm={3}
                xs={12}
              >
                <TextField
                  InputProps={{ readOnly: true }}
                  value={moment(systemSettingForm.values.dateModified).format(DISPLAY_DATE_FORMAT) ?? ''}
                  fullWidth
                  disabled
                  label={lang.dateModified}
                  margin="dense"
                  variant="outlined"
                />
              </Grid>

              <Grid
                item
                md={3}
                sm={3}
                xs={12}
              >
                <TextField
                  InputProps={{ readOnly: true }}
                  value={systemSettingForm.values.modifiedBy ?? ''}
                  fullWidth
                  disabled
                  label={lang.modifiedBy}
                  margin="dense"
                  variant="outlined"
                />
              </Grid>

              <Grid
                item
                md={3}
                sm={3}
                xs={12}
              >
                <TextField
                  InputProps={{ readOnly: true }}
                  value={moment(systemSettingForm.values.dateCreated).format(DISPLAY_DATE_FORMAT) ?? ''}
                  fullWidth
                  disabled
                  label={lang.dateCreated}
                  margin="dense"
                  variant="outlined"
                />
              </Grid>

              <Grid
                item
                md={3}
                sm={3}
                xs={12}
              >
                <TextField
                  InputProps={{ readOnly: true }}
                  value={systemSettingForm.values.createdBy ?? ''}
                  fullWidth
                  disabled
                  label={lang.createdBy}
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <Grid
              item
              md={12}
              xs={12}
              style={{ display: creating ? 'none' : 'block' }}
            >
              <Divider />
            </Grid>


            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                label={langSystemSetting.module}
                margin="dense"
                variant="outlined"
                inputProps={{ readOnly: allFieldsReadOnly }}
                {...bind('module')}
                onChange={(e) => {
                  limitTextFieldLength(e, 200, 'module', systemSettingForm, keyBooleanMap, setKeyBooleanMap);
                }}
              />
              <HandleFormHelperText
                isError={keyBooleanMap.get('module')}
                errorMessage={lang.textOverLimit}
              />
            </Grid>

            <Grid
              item
              md={6}
              sm={6}
              xs={12}
            >
              <TextField
                fullWidth
                label={langSystemSetting.type}
                margin="dense"
                variant="outlined"
                inputProps={{ readOnly: allFieldsReadOnly }}
                {...bind('type')}
                onChange={(e) => {
                  limitTextFieldLength(e, 200, 'type', systemSettingForm, keyBooleanMap, setKeyBooleanMap);
                }}
              />
              <HandleFormHelperText
                isError={keyBooleanMap.get('type')}
                errorMessage={lang.textOverLimit}
              />
            </Grid>

            <Grid
              item
              md={6}
              sm={6}
              xs={12}
            >
              <TextField
                fullWidth
                label={langSystemSetting.key}
                margin="dense"
                variant="outlined"
                inputProps={{ readOnly: allFieldsReadOnly }}
                {...bind('key')}
                onChange={(e) => {
                  limitTextFieldLength(e, 200, 'key', systemSettingForm, keyBooleanMap, setKeyBooleanMap);
                }}
              />
              <HandleFormHelperText
                isError={keyBooleanMap.get('key')}
                errorMessage={lang.textOverLimit}
              />
            </Grid>



            <Grid
              item
              md={12}
              xs={12}
            >
              <Typography>{langSystemSetting.value}</Typography>
            </Grid>

            <Grid
              item
              md={12}
              xs={12}
            >
              <TextareaAutosize
                className={classes.textArea}
                rowsMin={2}
                rowsMax={2}
                placeholder={''}
                readOnly={allFieldsReadOnly}
                {...bind('value')}
                onChange={(e) => {
                  limitTextFieldLength(e, 4000, 'value', systemSettingForm, keyBooleanMap, setKeyBooleanMap);
                }}
              />
              <HandleFormHelperText
                isError={keyBooleanMap.get('value')}
                errorMessage={lang.textOverLimit}
              />

            </Grid>

            <Grid
              item
              md={12}
              xs={12}
            >
              <Typography>{langSystemSetting.description}</Typography>
            </Grid>

            <Grid
              item
              md={12}
              xs={12}
            >

              <TextareaAutosize
                className={classes.textArea}
                rowsMin={2}
                rowsMax={2}
                placeholder={''}
                readOnly={allFieldsReadOnly}
                {...bind('description')}
                onChange={(e) => {
                  limitTextFieldLength(e, 255, 'description', systemSettingForm, keyBooleanMap, setKeyBooleanMap);
                }}
              />
              <HandleFormHelperText
                isError={keyBooleanMap.get('description')}
                errorMessage={lang.textOverLimit}
              />

            </Grid>

            <Grid
              item
              md={6}
              sm={6}
              xs={12}
            >
              <NumberField
                fullWidth
                label={langSystemSetting.seq}
                margin="dense"
                variant="outlined"
                value={systemSettingForm.values.seq ?? ''}
                onChange={(e) => {
                  if (allFieldsReadOnly) {
                    systemSettingForm.updateValues('seq', systemSettingForm.values.seq);
                  }
                  else {
                    if (!isNonEmpty(e.target.value)) {
                      systemSettingForm.updateValues('seq', '');
                    } else if (isNaN(parseInt(e.target.value))) {
                      e.preventDefault();
                    } else if (e.target.value.indexOf('.') != -1) {
                      e.preventDefault();
                    } else {
                      e.target.value.length > 10 ? e.preventDefault() : systemSettingForm.updateValues('seq', parseInt(e.target.value));
                    }
                  }
                  // systemSettingForm.updateValues('seq', allFieldsReadOnly ? systemSettingForm.values.seq : e.target.value);
                }}
              />
            </Grid>

            <Grid
              item
              md={6}
              sm={6}
              xs={12}
            >
              <FormControl disabled={allFieldsReadOnly} variant="outlined" margin="dense" fullWidth>
                <InputLabel variant="outlined" style={{ background: '#fff' }}>{langSystemSetting.dataStatus}</InputLabel>
                <Select
                  // error={!!roleForm.hasError('dataStatus')}
                  {...systemSettingForm.bind('dataStatus')}
                >
                  {Object.keys(statusOptions).filter(v => v === 'I' || v === 'A').map(status => (
                    <MenuItem key={status} value={status}>
                      {statusOptions[status]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {systemSettingForm.hasError('dataStatus') ? <FormHelperText error>{systemSettingForm.hasError('dataStatus')}</FormHelperText> : null}
            </Grid>


          </Grid>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {lang.actionCancel}
          </Button>
          {creating || canUpdate ?
            <Button onClick={onSave} color="primary" variant="contained" autoFocus>
              {creating ? lang.actionAdd : lang.actionSave}
            </Button>
            : null}
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default SystemSettingDetailDialog;