import {
	Button, Card, Checkbox, Collapse, Divider,
	Grid, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText,
	Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead,
	TableRow, TextField, Theme, Typography, useMediaQuery
} from '@material-ui/core';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MarkunreadIcon from '@material-ui/icons/Markunread';
import { makeStyles } from '@material-ui/styles';
import { useConfirmDialog } from 'common/ConfirmDialog';
import { NotificationSearchDTO } from 'common/dto';
import TablePagination from 'common/elements/TablePagination';
import { useForm, useMenu } from 'common/hooks';
import { FilterIcon, NotYetProcessedIcon } from 'common/ui';
import { isNonEmpty } from 'common/utils';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducers';
import theme from 'theme';
import NotificationMessage from './NotificationMessage';
import NotificationsToolbar from './NotificationsToolbar';




const DEFAULT_SIZE = 10;

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		flexDirection: 'column',
		display: 'flex',
		height: '100%',
		flexBasis: '100%',
	},
	content: {
		padding: theme.spacing(2),
		height: '100%',
		'& > div': {
			height: '100%',
		},
	},
	mainCard: {
		borderRadius: 0,
		overflowX: 'hidden',
		overflowY: 'scroll',
		height: '100%',
		padding: 0,
		flexDirection: 'column',
		display: 'flex',
	},
	sideCard: {
		borderRadius: 0,
		height: '100%',
		padding: 0
	},
	sideButton: {
		textAlign: 'left'
	},
	list: {
		display: 'flex',
	},
	listItemSelected: {
		backgroundColor: '#1976d2',
		'&:hover': { backgroundColor: '#1976d2' },
		'&:focus': { backgroundColor: '#1976d2' },
	},
	listItemTextSelected: {
		color: '#fff'
	},
	listItemDefault: {
		backgroundColor: theme.palette.background.paper,
	},
	listItemTextDefault: {
		color: '#1976d2'
	},
	table: {
		// minWidth: 650,
		position: 'absolute',
	},
	tableContainer: {
		position: 'relative',
		height: '100%',
	},
	tablePaginationIconButton: {
		'& .MuiIconButton-root': {
			padding: '6px',
		}
	},
	tablePaginationToolbar: {
		paddingRight: '12px',
	},
	unreadDot: {
		position: 'absolute',
		top: '12px',
		left: '22px',
		borderRadius: '50%',
		background: '#4285F4',
		width: '10px',
		height: '10px',
	}
}));
let currentNotificationRow = 0;
let initialPage = 0;
const When = ({ test, children }: { test?: boolean, children: JSX.Element }): JSX.Element => test ? children : <span />;

const NotificationsCentre = () => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const userMenuDesktopView = useMenu();
	const userMenuMobileView = useMenu();
	const shouldCollapse = useMediaQuery(theme.breakpoints.down('sm'));

	const { langNotification, lang, langUser, notificationTypeOptions, rowsCountPrefOptions } = useSelector((state: IRootState) => state.locale);
	const { allList, currentList: notifications, totalElements, totalPages } = useSelector((state: IRootState) => state.notification);

	// const rowsCountPreference = useSelector((state: IRootState) => state.login.rowsCountPreference) ?? EMPTY_STRING;

	const confirmDialog = useConfirmDialog();

	const [page, setPage] = useState(initialPage);

	// const [ limit, setLimit ] = useState(10);
	const limit = useSelector((state: IRootState) => state.login.rowsCountPreference?.NOTIFICATION_CENTER) ?? DEFAULT_SIZE;

	const [currentRowsPerPage, setCurrentRowsPerPage] = useState(currentNotificationRow === 0 ? limit as number : currentNotificationRow);
	currentNotificationRow = currentRowsPerPage;



	const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		// handleRowsCount(+event.target.value);
		dispatch({ type: 'Login.RowCountPrefUpdate', payload: { NOTIFICATION_CENTER: +event.target.value } });
	};

	// useEffect(() => {
	//   setLimit(JSON.parse(rowsCountPreference)?.NOTIFICATION_CENTER);
	// }, [ rowsCountPreference]);

	const [sortOrders] = useState<{ [k: string]: 'desc' | 'asc' }>({
		dateCreated: 'desc',
		isRead: 'desc'
	});

	type filterOption = 'ALL' | 'UNREAD' | 'NOT_YET_PROCESSED'; // | 'BY_TYPE' ;

	const [filterOptionBasic, setFilterOptionBasic] = useState<filterOption>('ALL');

	const searchForm = useForm<NotificationSearchDTO>({
		isUnread: undefined,
		module: undefined,
	});

	const bind = searchForm.bind;

	const [selectedNotifications, setSelectedNotifications] = useState<string[]>([]);

	const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			setSelectedNotifications(notifications.map(n => n.id!));
		} else {
			setSelectedNotifications([]);
		}

	};

	const handleSelectOne = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
		const selectedIndex = selectedNotifications.indexOf(id);
		let newSelectedNotifications: string[] = [];

		if (selectedIndex === -1) {
			newSelectedNotifications = newSelectedNotifications.concat(selectedNotifications, id);
		} else if (selectedIndex === 0) {
			newSelectedNotifications = newSelectedNotifications.concat(selectedNotifications.slice(1));
		} else if (selectedIndex === selectedNotifications.length - 1) {
			newSelectedNotifications = newSelectedNotifications.concat(selectedNotifications.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelectedNotifications = newSelectedNotifications.concat(
				selectedNotifications.slice(0, selectedIndex),
				selectedNotifications.slice(selectedIndex + 1)
			);
		}
		setSelectedNotifications(newSelectedNotifications);
	};

	const handlePageChange = (event: React.MouseEvent | null, page: number) => {
		setPage(page);
		initialPage = page;
		dispatch({
			type: 'NotificationPage.FetchRequested', payload: {
				...searchForm.values as any,
				page, size: currentRowsPerPage, sort: sortOrders,
			}
		});
	};

	const handleReadAction = (read: boolean) => {
		dispatch({ type: 'Notifications.ReadActionRequested', payload: { ids: selectedNotifications, isRead: read } })
		setSelectedNotifications([]);
	};

	const handleDeleteAction = () => {
		confirmDialog.confirm(lang.msgConfirmDelete, lang.actionConfirm, lang.actionCancel).then(confirmed => {
			if (confirmed) {
				dispatch({ type: 'Notifications.DeleteActionRequested', payload: { ids: selectedNotifications } });
				setSelectedNotifications([]);
			}
		});
	}

	useEffect(() => {
		dispatch({
			type: 'NotificationPage.FetchRequested', payload: {
				...searchForm.values as any,
				page, size: currentRowsPerPage, sort: sortOrders
			}
		});
	}, [currentRowsPerPage]);

	useEffect(() => {
		dispatch({
			type: 'NotificationPage.FetchRequested', payload: {
				...searchForm.values as any,
				page, size: currentRowsPerPage, sort: sortOrders
			}
		});
		setPage(0);
	}, [searchForm.values]);


	const [openFilterOptionType, setOpenFilterOptionType] = React.useState(true);

	const doFilter = (option: string, module: undefined | string) => {
		if (module != searchForm.values.module) {
			searchForm.modify({
				module
			});
		}

		if (option !== filterOptionBasic) switch (option) {
			case 'ALL':
				setFilterOptionBasic('ALL');
				searchForm.modify({
					isUnread: undefined,
					notYetProcessed: undefined,
				});
				break;
			case 'UNREAD':
				setFilterOptionBasic('UNREAD');
				searchForm.modify({
					isUnread: true,
					notYetProcessed: undefined,
				});
				break;
			case 'NOT_YET_PROCESSED':
				setFilterOptionBasic('NOT_YET_PROCESSED');
				searchForm.modify({
					isUnread: undefined,
					notYetProcessed: true,
				});
				break;
		}
	}

	const filterOptionMenuMap: { [key: string]: string } = {
		ALL: langNotification.captionAllRead,
		UNREAD: langNotification.captionUnread,
		NOT_YET_PROCESSED: langNotification.captionNotYetProcessed,
		BY_TYPE: langNotification.captionType,
		...notificationTypeOptions,
	}

	const printUserMenuItems = (menu: any) => (
		<Menu {...menu.menuProps()}>
			<MenuItem onClick={() => handleReadAction(true)}>{langNotification.actionMarkAsRead}</MenuItem>
			<MenuItem onClick={() => handleReadAction(false)}>{langNotification.actionMarkAsUnread}</MenuItem>
			<MenuItem onClick={() => handleDeleteAction()}>{langNotification.actionDelete}</MenuItem>
		</Menu>
	)

	return (
		<div className={classes.root}>
			{confirmDialog.render()}
			<NotificationsToolbar />
			<div className={classes.content}>
				<Grid container md={12} xs={12}>
					<When test={!shouldCollapse}>
						<Grid item md={3} xs={3}>
							<Card className={classes.sideCard}>
								<div style={{ margin: theme.spacing(2) }}>
									<List>
										{/* -------------------- All ----------------- */}
										<ListItem button
											className={filterOptionBasic === 'ALL' ? classes.listItemSelected : classes.listItemDefault}
											onClick={() => {
												doFilter('ALL', searchForm.values.module);
											}}>
											<ListItemIcon>
												<AllInboxIcon className={filterOptionBasic === 'ALL' ? classes.listItemTextSelected : classes.listItemTextDefault} />
											</ListItemIcon>
											<ListItemText
												classes={{
													primary: filterOptionBasic === 'ALL' ? classes.listItemTextSelected : classes.listItemTextDefault,
													secondary: filterOptionBasic === 'ALL' ? classes.listItemTextSelected : classes.listItemTextDefault
												}}
											>{langNotification.captionAllRead}</ListItemText>
										</ListItem>
										{/* -------------------- UNREAD -------------------- */}
										<ListItem button
											className={filterOptionBasic === 'UNREAD' ? classes.listItemSelected : classes.listItemDefault}
											onClick={() => {
												doFilter('UNREAD', searchForm.values.module);
											}}>
											<ListItemIcon>
												<MarkunreadIcon className={filterOptionBasic === 'UNREAD' ? classes.listItemTextSelected : classes.listItemTextDefault} />
											</ListItemIcon>
											<ListItemText
												classes={{
													primary: filterOptionBasic === 'UNREAD' ? classes.listItemTextSelected : classes.listItemTextDefault,
													secondary: filterOptionBasic === 'UNREAD' ? classes.listItemTextSelected : classes.listItemTextDefault
												}}
											>{langNotification.captionUnread}</ListItemText>
											<ListItemSecondaryAction>
												<Typography className={filterOptionBasic === 'UNREAD' ? classes.listItemTextSelected : classes.listItemTextDefault}>{isNonEmpty(allList) ? allList![0].count : 0}</Typography>
											</ListItemSecondaryAction>
										</ListItem>
										{/* -------------------- NOT_YET_PRPCESSED -------------------- */}
										<ListItem button
											className={filterOptionBasic === 'NOT_YET_PROCESSED' ? classes.listItemSelected : classes.listItemDefault}
											onClick={() => {
												doFilter('NOT_YET_PROCESSED', searchForm.values.module);
											}}>
											<ListItemIcon style={{ display: filterOptionBasic === 'NOT_YET_PROCESSED' ? 'flex' : 'none' }}>
												<NotYetProcessedIcon style={{ marginLeft: '3px' }} className={classes.listItemTextSelected} />
											</ListItemIcon>
											<ListItemIcon style={{ display: filterOptionBasic === 'NOT_YET_PROCESSED' ? 'none' : 'flex' }}>
												<NotYetProcessedIcon style={{ marginLeft: '3px' }} className={classes.listItemTextDefault} />
											</ListItemIcon>

											<ListItemText
												classes={{
													primary: filterOptionBasic === 'NOT_YET_PROCESSED' ? classes.listItemTextSelected : classes.listItemTextDefault,
													secondary: filterOptionBasic === 'NOT_YET_PROCESSED' ? classes.listItemTextSelected : classes.listItemTextDefault
												}}
											>{langNotification.captionNotYetProcessed}</ListItemText>
											{/* <ListItemSecondaryAction>
												<Typography className={filterOptionBasic === 'NOT_YET_PROCESSED' ? classes.listItemTextSelected : classes.listItemTextDefault}>{allList?.filter(n => !n.isExpired &&  (n.hasDoneAction || ['CLAIM_CLIENT', 'CLAIM_CLIENT_CONTACT', 'CLAIM_PROPERTY_STOCK'].includes(n.module))).length}</Typography>
											</ListItemSecondaryAction> */}
										</ListItem>

										<ListItem button onClick={() => setOpenFilterOptionType(!openFilterOptionType)}>
											<ListItemIcon>
												<FilterIcon className={classes.listItemTextDefault} />
											</ListItemIcon>
											<ListItemText
												classes={{
													primary: classes.listItemTextDefault,
													secondary: classes.listItemTextDefault
												}}
											>{langNotification.captionType}</ListItemText>
											{openFilterOptionType ? <ExpandLess /> : <ExpandMore />}
										</ListItem>
										<Collapse in={openFilterOptionType} timeout="auto" unmountOnExit>
											<List component="div" disablePadding>
												<ListItem button key={'ALL'} style={{ paddingLeft: '72px' }}
													className={searchForm.values.module === undefined ? classes.listItemSelected : classes.listItemDefault}
													onClick={() => {
														doFilter(filterOptionBasic, undefined);
													}}
												>
													<ListItemText
														classes={{
															primary: searchForm.values.module === undefined ? classes.listItemTextSelected : classes.listItemTextDefault,
															secondary: searchForm.values.module === undefined ? classes.listItemTextSelected : classes.listItemTextDefault
														}}
													>{langNotification.captionAllModule}</ListItemText>
												</ListItem>
												{Object.keys(notificationTypeOptions).map(key => (
													<ListItem button key={key}
														style={{ paddingLeft: '72px' }}
														className={searchForm.values.module === key ? classes.listItemSelected : classes.listItemDefault}
														onClick={() => {
															doFilter(filterOptionBasic, key);
														}}
													>
														<ListItemText
															classes={{
																primary: searchForm.values.module === key ? classes.listItemTextSelected : classes.listItemTextDefault,
																secondary: searchForm.values.module === key ? classes.listItemTextSelected : classes.listItemTextDefault
															}}
														>{notificationTypeOptions[key]}</ListItemText>
													</ListItem>
												))}

											</List>
										</Collapse>
									</List>
									{/* <Button 
									fullWidth color="primary" variant="contained" 
									onClick={() => searchForm.updateValues('isRead', true)}
								>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
										<AllInboxIcon/>
										<div>{langNotification.captionAllRead}</div>
									</div>
								</Button>
								<Button fullWidth color="primary" endIcon={<div>{allList?.filter(n => !n.isRead).length}</div>}onClick={() => searchForm.updateValues('isRead', false)}>
									<MarkunreadIcon/>
									<div>{langNotification.captionUnread}</div>
								</Button> */}
								</div>
							</Card>
						</Grid>
					</When>
					<Grid item md={9} xs={12}>
						<Card className={classes.mainCard}>
							<When test={shouldCollapse}>
								<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: 10, marginLeft: 14, marginRight: 14 }}>
									<div>
										<TextField
											label={langNotification.captionFilter}
											margin="dense"
											select
											variant="outlined"
											style={{ backgroundColor: '#fff', minWidth: '130px' }}
											value={filterOptionBasic}
											onChange={(e) => {
												const option = e.target.value;
												doFilter(option, searchForm.values.module);
											}}
										>
											<MenuItem key={'ALL'} value={'ALL'} onClick={() => { doFilter('ALL', undefined) }}>{filterOptionMenuMap['ALL']}</MenuItem>
											<MenuItem key={'UNREAD'} value={'UNREAD'} onClick={() => { doFilter('UNREAD', undefined) }}>{filterOptionMenuMap['UNREAD']}</MenuItem>
											<MenuItem key={'NOT_YET_PROCESSED'} value={'NOT_YET_PROCESSED'} onClick={() => { doFilter('NOT_YET_PROCESSED', undefined) }}>{filterOptionMenuMap['NOT_YET_PROCESSED']}</MenuItem>
											{/* <MenuItem key={'BY_TYPE'} value={'BY_TYPE'} disabled >{filterOptionMenuMap['BY_TYPE']}</MenuItem> */}

											{/* {Object.keys(notificationTypeOptions).map(key => (
											<MenuItem key={key} value={key} onClick={()=>{doFilter('BY_TYPE', key)}}>{notificationTypeOptions[key]}</MenuItem>
										))} */}
										</TextField>
									</div>
									<div style={{ marginLeft: 5 }}>
										<TextField
											label={langNotification.captionModule}
											margin="dense"
											select
											variant="outlined"
											style={{ backgroundColor: '#fff', minWidth: '130px' }}
											value={searchForm.values.module ?? 'ALL'}
											onChange={(e) => {
												const module = e.target.value;
												doFilter(filterOptionBasic, module === 'ALL' ? undefined : module);
											}}
										>
											<MenuItem key={'ALL'} value={'ALL'} onClick={() => { doFilter(filterOptionBasic, undefined) }}>{langNotification.captionAllModule}</MenuItem>
											{Object.keys(notificationTypeOptions).map(key => (
												<MenuItem key={key} value={key} onClick={() => { doFilter(filterOptionBasic, key) }}>{notificationTypeOptions[key]}</MenuItem>
											))}
										</TextField>
									</div>
									<Button style={{ height: '37px', marginTop: '4px', marginLeft: '5px' }} variant="outlined" {...userMenuMobileView.buttonProps()} endIcon={<ArrowDropDownIcon />} >{langNotification.captionAction}</Button>
									{printUserMenuItems(userMenuMobileView)}
									<div>
										{/* <When test={shouldCollapse}>
										<div style={{ marginLeft: theme.spacing(1), display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
											<Typography className={searchForm.values.isRead ? classes.listItemTextDefault : 'inherit'}>{langNotification.captionAllRead}</Typography>
											<Switch
												checked={!searchForm.values.isRead}
												onChange={(_) => searchForm.updateValues('isRead', !searchForm.values.isRead)}
											/>
											<Typography className={!searchForm.values.isRead ? classes.listItemTextDefault : 'inherit'}>{langNotification.captionUnread}</Typography>
											<Typography className={!searchForm.values.isRead ? classes.listItemTextDefault : 'inherit'} style={{ paddingLeft: '10px' }}>{allList?.filter(n => !n.isRead).length}</Typography>
										</div>
								</When> */}
									</div>
								</div>
							</When>
							<When test={shouldCollapse}>
								<Divider />
							</When>
							<Table size="small" aria-label="Notifications-Toolbar">
								<TableHead>
									<TableRow>
										<TableCell padding="checkbox">
											<Checkbox
												checked={selectedNotifications.length === notifications.length}
												onChange={handleSelectAll}
											/>
										</TableCell>
										<TableCell style={{ paddingBottom: '0px', paddingTop: '0px' }}>
											<div style={{ display: 'flex', justifyContent: 'space-between' }}>
												<When test={!shouldCollapse}>
													<Button {...userMenuDesktopView.buttonProps()} endIcon={<ArrowDropDownIcon />} color="inherit">{langNotification.captionAction}</Button>
												</When>
												{printUserMenuItems(userMenuDesktopView)}

												<TablePagination
													classes={{
														actions: classes.tablePaginationIconButton,
														toolbar: classes.tablePaginationToolbar,
													}}
													// component="div"
													count={totalElements}
													onChangePage={handlePageChange}
													onChangeRowsPerPage={(ev) => { setCurrentRowsPerPage(Number(ev.target.value)); handleRowsPerPageChange(ev) }}
													page={initialPage}
													totalPages={totalPages}
													rowsPerPage={currentRowsPerPage}
													labelRowsPerPage={langUser.rowsCount}
													rowsPerPageOptions={Object.keys(rowsCountPrefOptions).map(Number)}
												// rowsPerPageOptions={[20]}
												/>
											</div>
										</TableCell>
									</TableRow>
								</TableHead>
							</Table>
							<TableContainer component={Paper} className={classes.tableContainer}>
								<Table className={classes.table} size="small" aria-label="Notifications">
									<TableBody>
										{
											notifications.map(notification => (
												<TableRow >
													<TableCell padding="checkbox" style={{ position: 'relative' }}>
														{!notification.isRead &&
															<div className={classes.unreadDot} />
														}
														<Checkbox
															checked={selectedNotifications.indexOf(notification.id!) !== -1}
															onChange={(ev) => handleSelectOne(ev, notification.id)}></Checkbox>
													</TableCell>
													<TableCell style={{ paddingBottom: '0px', paddingTop: '0px' }}>
														<NotificationMessage notification={notification} />
													</TableCell>
												</TableRow>
											))
										}
									</TableBody>
								</Table>
							</TableContainer>
						</Card>
					</Grid>
				</Grid>
			</div>
		</div>
	);
}

export default NotificationsCentre;