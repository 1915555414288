import {
  Button as MuiButton, CardActions, CardContent, CardHeader, CircularProgress, Dialog,
  DialogContent, Grid, makeStyles, Typography
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import forgotPasswordApi from 'api/forgotPasswordApi';
import { tooltipHoc } from 'common/ui';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { IRootState, PASDispatch } from 'reducers';
import { format } from 'util';
import ChangePasswordDialog from 'views/UserDetail/components/ChangePasswordDialog';


const Button = tooltipHoc(MuiButton);

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    background: '#fff',
    color: '#000',
  },
  dialogContent: {
    width: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    flexGrow: 1,
  },
  brand: {
    color: '#183B68',
    display: 'flex', flexDirection: 'row', alignItems: 'center',
    lineHeight: '40px',
    fontWeight: 'bold',
  },
}));


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const resetPwLang: { [key: string]: string } = {
  // msgPleaseWait: '請等候... \n Please wait...',
  // msgChangePwSuccess: '成功修改密碼 Password changed successfully',
  // msgProcessing: '正在修改密碼, 請勿退出當前頁面 \n Changing password, please do not leave current page...',
  // msgLinkExpired: '連結已失效 This link has expired',
  pas: '物業代理系統 Property Management System',
}

const ForgotPasswordResetPage = () => {
  const { lang, langUser } = useSelector((state: IRootState) => state.locale);

  const { langUser: langUserEn, lang: langEn } = useSelector((state: IRootState) => state.locale._bundle['en']);

  const dispatch = useDispatch() as PASDispatch;
  const query = useQuery();
  const history = useHistory();
  const { stage: status } = useSelector((state: IRootState) => state.resetPassword);
  // const [status, setStatus] = useState<'signing' | 'generating' | 'success' | 'pendingContactVerification' | 'verifyingContact' | 'index'>('index'); 
  // const [formStatus, setFormStatus] = useState('');
  // const [signedFormUrl, setSignedFormUrl] = useState('');

  const token = query.get("key") ?? '';

  const classes = useStyles();

  // const [indexPageMsg , setIndexPageMsg ] = useState(langSignDialog.msgmsgmsgPleaseWait);

  const indexPageMsg = langUser.msgPleaseWait + '\n' + langUserEn.msgPleaseWait;

  const verifyTokenAndStatus = () => {
    dispatch({ type: 'ResetPassword.VerifyTokenRequested', payload: { token: token } });

  }

  useEffect(() => {
    if (status === 'index') {
      verifyTokenAndStatus();
    }
  }, []);

  const indexPanel = () => (
    <CardContent>
      <Typography>{indexPageMsg}</Typography>
    </CardContent>
  )

  const linkExpiredPanel = () => (
    <CardContent>
      <Typography>{langUser.msgLinkExpired + ' ' + langUserEn.msgLinkExpired}</Typography>
    </CardContent>
  )

  const loadingPanel = () => <CardHeader
    avatar={<CircularProgress />}
    style={{ whiteSpace: 'pre-line' }}
    title={resetPwLang.msgChangingPassword}
  />;

  const successMsg = langUser.msgChangePwSuccess + ' ' + langUserEn.msgChangePwSuccess;

  const successPanel = () =>
    <div>
      <CardHeader
        // subheader={langSignDialog.msgGenerated}
        title={successMsg}
      />
      <CardActions>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            history.replace('/sign-in');
            dispatch({ type: 'Login.LogoutRequested' });
          }}
        >
          {lang.msgSignInAgain + ' ' + langEn.msgSignInAgain}
        </Button>
      </CardActions>
    </div>
    ;

  const { PASSWORD_HISTORY } = useSelector((state: IRootState) =>
    state.systemSettings?.System?.PASSWORD) ?? {};
  const [incorrect, setIncorrect] = useState(false);
  const pendingForUserInputPanel = () => (
    <div className={classes.root}>
      <Grid container md={6} xs={12} spacing={1}>
        <ChangePasswordDialog
          open={true}
          onClose={() => { return; }}
          onChangePassword={({ newPassword }) => {
            // dispatch({type: 'ResetPassword.Processing'});
            forgotPasswordApi.changePassword(token ?? '', newPassword).then(({ error }) => {
              if (error) {
                setIncorrect(true);
                let errorMsg = '';
                switch (error) {
                  case 'ERR_INVALID_TOKEN': dispatch({ type: 'ResetPassword.LinkExpired' }); break;
                  case 'ERR_PW_FAIL_COMPLEXITY_REQUIREMENT': errorMsg = langUser.msgPasswordFailComplexityRequirement + ' ' + langUserEn.msgPasswordFailComplexityRequirement; break;
                  case 'ERR_PREVIOUS_PW_USED': errorMsg = langUser.msgPreviousPasswordUsed + ' ' + langUserEn.msgPreviousPasswordUsed; break;
                  default: errorMsg = lang.msgNetworkError + ' ' + langEn.msgNetworkError;
                }
                dispatch({
                  type: 'Layout.AlertMessageAdded',
                  payload: {
                    message: errorMsg,
                  },
                });
                setIncorrect(false);
              } else {
                dispatch({ type: 'ResetPassword.Success' });
              }
            });

          }}
          isForgotPasswordPage={true}
          incorrectPassword={incorrect}
        />
      </Grid>
    </div>
  );

  return (
    <Dialog
      keepMounted
      open={true}
      fullScreen
      // onClose={handleClose}
      aria-labelledby="reset-password-dialog"
      aria-describedby="reset-password-form"
    >
      <AppBar position="static" style={{ background: '#fff', color: '#000' }}>
        <Toolbar>
          <Typography className={classes.brand}>
            <img src="images/logo-max.png" style={{ height: 40, marginRight: 16 }} />

          </Typography>
          <Typography >
            {resetPwLang.pas}
          </Typography>
        </Toolbar>
      </AppBar>

      <DialogContent>
        {/* {{
          signing: signaturePanel,
          generating: loadingPanel,
          success: successPanel,
          pendingContactVerification: pendingVerifiactionPanel,
          verifyingContact: verifyingPanel,
          index: indexPanel
        }[status]()} */}
        {status === 'generating' ? loadingPanel() : null}
        {status === 'success' ? successPanel() : null}
        {status === 'index' ? indexPanel() : null}
        {status === 'pendingForUserInput' ? pendingForUserInputPanel() : null}
        {status === 'linkExpired' ? linkExpiredPanel() : null}
      </DialogContent>
    </Dialog>
  );
};

export default ForgotPasswordResetPage;
