import { TextField, TextFieldProps } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

export type CommaTextFieldProps = TextFieldProps & {
  onValChange?: (arr: string[]) => any;
  val?: string[] | undefined;
}

export default function CommaTextField(props: CommaTextFieldProps) {
  const [ val, setVal ] = useState('');
  useEffect(() => {
    setVal(props.val?.join(',') ?? '');
  }, [ props.val ]);

  return <TextField
    {...props}
    onChange={(ev) => {
      setVal(ev.target.value);
    }}
    onBlur={(ev) => {
      if (ev.target.value.trim()) {
        props.onValChange?.(ev.target.value.split(',').map(n => n.trim()));
      } else {
        props.onValChange?.([]);
      }
      props.onBlur?.(ev);
    }}
    value={val}
  />
}