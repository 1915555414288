import { App as CapacitorApp } from '@capacitor/app';
// Native App Settings
import { Capacitor } from '@capacitor/core';
import { LocalNotifications } from '@capacitor/local-notifications';
import { PushNotifications } from '@capacitor/push-notifications';
import { StatusBar } from '@capacitor/status-bar';
import { ApiResult } from 'api';
import notificationApi from 'api/notificationApi';
import { NotificationDTO } from 'common/dto';
import 'element-scroll-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import StatusBarIOSPlugin from 'StatusBarIOSPlugin';
import theme from 'theme';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { store } from './store';



if (Capacitor.isNativePlatform()) {
    let msgIdSeq = 1;
    if (Capacitor.isPluginAvailable("StatusBarIOSPlugin")) {
        StatusBarIOSPlugin.getStatusBarHeight().then(({ height }: any) => {
            
            store.dispatch({ type: 'Layout.IOSStatusBarUpdated', payload: { height } });
        });
    }
    StatusBar.setBackgroundColor({ color: theme.palette.primary.dark });
    PushNotifications.requestPermissions().then( result => {
    if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
    } else {
        // Show some error
        console.error("Error: cannot register to FCM")
    }
    });
    PushNotifications.addListener('registration', (token) => {
        //console.info('pnToken', token.value);
    });
    PushNotifications.createChannel({
        id: 'default',
        name: 'Default',
        importance: 3,
        vibration: true,
    });

    PushNotifications.addListener('pushNotificationReceived', (notification) => {
            CapacitorApp.getState().then(async (state) => {
            const token = store.getState().login.token ?? '';
            const listResult: ApiResult<NotificationDTO[]> = await notificationApi.getList(token);
            if (!listResult.error) {
                store.dispatch({ type: 'Notifications.Loaded', payload: listResult.data! });
            }

            if (state.isActive) {
                await LocalNotifications.schedule({
                    notifications: [
                        {
                            id: ++msgIdSeq,
                            title: notification.title ?? '',
                            body: notification.body ?? '',
                            schedule: { at: new Date(Date.now()) },
                            channelId: 'default',
                        },
                    ],
                });
            }
        })
    });

    PushNotifications.addListener('pushNotificationActionPerformed', (payload) => {
        //console.info('payload', payload);
        if (payload.actionId === 'tap') {
            // Handle tap with module & link
        }
    });
}

// Scroll handling
window.history.scrollRestoration = 'manual';

// Switch Language
window.addEventListener('keydown', (ev) => {
    if (ev.code === 'F2') {
        store.dispatch({ type: 'Locale.SwitchLocale', locale: store.getState().locale.locale === 'en' ? 'zh_HK' : 'en' })
    }
});

// debug store
(window as any).store = store;
// TODO: Very simple privilege editor. Make this to an ui
const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];
(window as any).privilegeEditor = {
    ls: async (role: string | undefined) => {
        if (!role) {
            //console.info('Usage: ls(<roleName>)');
            return;
        }
        let result = await fetch(`${BASE_URL}/roles/${role.toUpperCase()}/privileges?t=${store.getState().login.token ?? ''}`)
            .then(res => res.json())
        ;

        //console.table(result.data);

        return result;
    },

    add: async (role: string, operation: string, resource: string) => {
        if (!role || !operation || !resource) {
            
            return;
        }
        let result = await fetch(`${BASE_URL}/roles/${role}/privileges/edit?t=${store.getState().login.token ?? ''}`, {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ created: [{ operation, resource }] }),
        }).then(res => res.json());
        
    },

    remove: async (role: string, id: number) => {
        if (!role || !id) {
            
            return;
        }
        let result = await fetch(`${BASE_URL}/roles/${role}/privileges/edit?t=${store.getState().login.token ?? ''}`, {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ deleted: [{ id }] }),
        }).then(res => res.json());
        
    },
}

ReactDOM.render(<Provider store={store}>
    <App />
</Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
