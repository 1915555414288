import { ApiResult } from 'api';
import { CloseTransactionDTO } from 'common/dto';
import { trackGoals } from 'common/utils';

const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];

export const closeTransactionApi = {
	getDetail: async(pid: string, token: string, locale: string): Promise<ApiResult<CloseTransactionDTO>> => {
		try {
			const response = await fetch(`${BASE_URL}/closeTransaction/${pid}/${locale}`, {
				headers: { 'Authorization': `Bearer ${token}` }
			});
			return await response.json();
		} catch (e) {
			return { error: String(e) };
		}
    },
    add: async(ct: CloseTransactionDTO, token: string): Promise<ApiResult<any>> => {
        try {
			const response = await fetch(`${BASE_URL}/closeTransaction`, {
                method: 'POST', body: JSON.stringify(ct),
				headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
			});
			return await response.json();
		} catch (e) {
			return { error: String(e) };
		}
	},
	update: async(ct: CloseTransactionDTO, token: string): Promise<ApiResult<any>> => {
		const { id } = ct;
        try {
			const response = await fetch(`${BASE_URL}/closeTransaction/${id}`, {
                method: 'PUT', body: JSON.stringify(ct),
				headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
			});
			return await response.json();
		} catch (e) {
			return { error: String(e) };
		}
	},
	confirmRequest: async(id: string, token: string): Promise<ApiResult<CloseTransactionDTO>> => {
		try {
			const response = await fetch(`${BASE_URL}/closeTransaction/${id}/confirm-request`, {
                method: 'PATCH', body: JSON.stringify(id),
				headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
			});
			return await response.json();
		} catch (e) {
			return { error: String(e) };
		}
	},
	confirm: async(id: string, signatureBlobUrl: string, token: string): Promise<ApiResult<CloseTransactionDTO>> => {
		try {
			const formData = new FormData();
			formData.append(
				'agentSign',
				await fetch(signatureBlobUrl).then(r => r.blob()),
			);
			const response = await fetch(`${BASE_URL}/closeTransaction/${id}/confirm`, {
                method: 'PATCH', body: formData,
				headers: { 'Authorization': `Bearer ${token}` },
			});
			return await response.json();
		} catch (e) {
			return { error: String(e) };
		}
	},
	approve: async(id: string, approverSignBlobUrl: string, token: string): Promise<ApiResult<CloseTransactionDTO>> => {
		try {
			// const formData = new FormData();
			// formData.append(
			// 	'approverSign',
			// 	await fetch(approverSignBlobUrl).then(r => r.blob()),
			// );

			const response = await fetch(`${BASE_URL}/closeTransaction/approve/${id}`, {
                method: 'PATCH', body: JSON.stringify(id),
				headers: { 'Authorization': `Bearer ${token}` },
			});
			const apiResult = await response.json();
			// const goalsToTrack: string[] = apiResult.metadata?.goalsToTrack ?? [];
			const goalsListToTrack = apiResult.metadata?.goalsListToTrack ?? [];
			trackGoals(goalsListToTrack);

			return apiResult;
		} catch (e) {
			return { error: String(e) };
		}
	},
	reject: async(id: string, ct: CloseTransactionDTO, token: string): Promise<ApiResult<CloseTransactionDTO>> => {
		try {
			const response = await fetch(`${BASE_URL}/closeTransaction/reject/${id}`, {
				method: 'PATCH', body: JSON.stringify(ct),
				headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
			});
			return await response.json();
		} catch (e) {
			return { error: String(e) };
		}
	},
	approvalRequest: async(id: string, token: string): Promise<ApiResult<CloseTransactionDTO>> => {
		try {
			const response = await fetch(`${BASE_URL}/closeTransaction/${id}/approval-request`, {
                method: 'PATCH', body: JSON.stringify(id),
				headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
			});
			return await response.json();
		} catch (e) {
			return { error: String(e) };
		}
	},
}

export default closeTransactionApi;