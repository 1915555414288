import { MasterDataDTO } from "common/dto";
import { FormValidations } from "common/hooks";

type Options = { [key: string]: string };

const SystemSettingFormValidations = (langSystemSetting: Options): FormValidations<MasterDataDTO> => [
 
  (values) => values.module? null : [
    [ 'module', langSystemSetting.msgPleaseInput + langSystemSetting.module],
  ],
  (values) => values.type? null : [
    [ 'type', langSystemSetting.msgPleaseInput + langSystemSetting.type],
  ],
  (values) => values.key? null : [
    [ 'key', langSystemSetting.msgPleaseInput + langSystemSetting.key],
  ],
  (values) => values.value? null : [
    [ 'value', langSystemSetting.msgPleaseInput + langSystemSetting.value],
  ],
  (values) => values.dataStatus? null : [
    [ 'dataStatus', langSystemSetting.msgPleaseSelect + langSystemSetting.dataStatus],
  ],
  
];

export default SystemSettingFormValidations;