import { ApiError, ApiPageResult, ApiResult } from 'api';
import notificationApi from 'api/notificationApi';
import { NotificationDTO } from 'common/dto';
import { IRootState, NotificationActions } from 'reducers';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { store } from 'store';
import { apiTaskWrapper } from './saga-commons';

const env = process.env['PUBLIC_URL'];
export function* watchNotificationFetchRequested() {
	yield takeLatest(
		'Notifications.FetchRequested',
		requestNotificationList,
	);
}

export function* requestNotificationList(action: NotificationActions) {
	if (action.type !== 'Notifications.FetchRequested') {
		return;
	}

	const token: string = yield select((state: IRootState) => state.login.token ?? '');

	const result: ApiResult<NotificationDTO[]> = yield call(notificationApi.getList, token);

	if (!result.error) {
		yield put<NotificationActions>({ type: 'Notifications.Loaded', payload: result.data! });
	} else {
		throw ApiError.of(result.error);
	}
}

export function* watchNotificationPageFetchRequested() {
	yield takeLatest(
		'NotificationPage.FetchRequested',
		requestNotificationPage,
	);
}

export function* requestNotificationPage(action: NotificationActions) {
	if (action.type !== 'NotificationPage.FetchRequested') {
		return;
	}

	const token: string = yield select((state: IRootState) => state.login.token ?? '');

	const result: ApiPageResult<NotificationDTO> = yield call(notificationApi.getPage, action.payload, token);

	if (!result.error) {
		yield put<NotificationActions>({ type: 'NotificationPage.Loaded', payload: result.data! });
	} else {
		throw ApiError.of(result.error);
	}
}

export function* watchNotificationReadRequested() {
	yield takeLatest(
		'Notifications.ReadActionRequested',
		apiTaskWrapper(requestNotificationRead),
	);
}

export function* requestNotificationRead(action: NotificationActions) {
	if (action.type !== 'Notifications.ReadActionRequested') {
		return;
	}

	const token: string = yield select((state: IRootState) => state.login.token ?? '');

	const result: ApiResult<any> = yield call(notificationApi.readAction, action.payload, token);

	const { lastSearchCriteria }: IRootState['notification'] = yield select((state: IRootState) => state.notification);

	if (!result.error) {
		yield put<NotificationActions>({ type: 'NotificationPage.FetchRequested', payload: lastSearchCriteria ?? { page: 0, sort: {} } });
		yield put<NotificationActions>({ type: 'Notifications.FetchRequested' });
		// yield put<NotificationActions>({ type: 'Notifications.Loaded', payload: result.data! });
	} else {
		throw ApiError.of(result.error);
	}
}

export function* watchNotificationDoneRequested() {
	yield takeLatest(
		'Notifications.DoneActionRequested',
		apiTaskWrapper(requestNotificationDone),
	);
}

export function* requestNotificationDone(action: NotificationActions) {
	if (action.type !== 'Notifications.DoneActionRequested') {
		return;
	}

	const token: string = yield select((state: IRootState) => state.login.token ?? '');

	const result: ApiResult<any> = yield call(notificationApi.doneAction, action.payload, token);

	const { lastSearchCriteria }: IRootState['notification'] = yield select((state: IRootState) => state.notification);

	if (!result.error) {
		yield put<NotificationActions>({ type: 'NotificationPage.FetchRequested', payload: lastSearchCriteria ?? { page: 0, sort: {} } });
	} else {
		throw ApiError.of(result.error);
	}
}

export function* watchNotificationExtraActionRequested() {
	yield takeLatest(
		'Notifications.ExtraActionRequested',
		apiTaskWrapper(requestNotificationExtraAction),
	);
}

export function* requestNotificationExtraAction(action: NotificationActions) {
	if (action.type !== 'Notifications.ExtraActionRequested') {
		return;
	}

	const token: string = yield select((state: IRootState) => state.login.token ?? '');

	const result: ApiResult<any> = yield call(notificationApi.handleExtraAction, action.payload.id, action.payload.action, token);

	const { lastSearchCriteria }: IRootState['notification'] = yield select((state: IRootState) => state.notification);

	if (!result.error) {
		yield put<NotificationActions>({ type: 'NotificationPage.FetchRequested', payload: lastSearchCriteria ?? { page: 0, sort: {} } });
	} else {
		throw ApiError.of(result.error);
	}
}

export function* watchNotificationDeleteRequested() {
	yield takeLatest(
		'Notifications.DeleteActionRequested',
		apiTaskWrapper(requestNotificationDelete),
	);
}

export function* requestNotificationDelete(action: NotificationActions) {
	if (action.type !== 'Notifications.DeleteActionRequested') {
		return;
	}

	const token: string = yield select((state: IRootState) => state.login.token ?? '');

	const result: ApiResult<any> = yield call(notificationApi.deleteAction, action.payload, token);

	const { lastSearchCriteria }: IRootState['notification'] = yield select((state: IRootState) => state.notification);

	if (!result.error) {
		yield put<NotificationActions>({ type: 'NotificationPage.FetchRequested', payload: lastSearchCriteria ?? { page: 0, sort: {} } });
	} else {
		throw ApiError.of(result.error);
	}
}

export function* notificationPolling() {
	if (process.env['REACT_APP_PAS_DISABLE_NOTIFICATION_POLLING'] === '1') {
		return;
	}
	let count = 0;
	while (true) {
		yield delay(1000 * 60);
		const { token }: IRootState['login'] = yield select((state: IRootState) => state.login);
		if (!token) {

			continue;
		}
		// Do receive new notification
		const listResult: ApiResult<NotificationDTO[]> = yield call(notificationApi.getList, token);
		if (!listResult.error) {
			yield put<NotificationActions>({ type: 'Notifications.Loaded', payload: listResult.data! });
		} else {
			// throw ApiError.of(listResult.error);
		}
		const { lastSearchCriteria }: IRootState['notification'] = yield select((state: IRootState) => state.notification);
		const pageResult: ApiPageResult<NotificationDTO> = yield call(notificationApi.getPage, lastSearchCriteria, token);

		if (!pageResult.error) {
			yield put<NotificationActions>({ type: 'NotificationPage.Loaded', payload: pageResult.data! });
		} else {
			// throw ApiError.of(pageResult.error);
		}

		if (listResult.error && pageResult.error) {
			count += 1;
			if (count >= 3) {
				count = 0
				localStorage.setItem('logoutEnv', env);
				store.dispatch({ type: 'Login.LogoutRequested' });
			}
		} else if (!listResult.error || !pageResult.error) {
			count = 0
		}
	}
}