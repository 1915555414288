import { TextField, TextFieldProps } from '@material-ui/core';
import { HandleFormHelperText } from 'common/ui';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';

type TextLimit = {
    textLimit?: number
}

export type MultilineTextFieldProps = TextFieldProps & TextLimit;

export default function MultilineTextField(props: MultilineTextFieldProps) {
    const { value, onChange, textLimit, ...rest } = props;
    const textFieldRef = useRef<any>(null);
    const timeoutRef = useRef<any>(0);
    const { lang } = useSelector((state: IRootState) => state.locale);

    useEffect(() => {
        if (textFieldRef.current) {
            textFieldRef.current!.value = value;
        }
    }, [value, textFieldRef]);
    return <><TextField
        {...rest}
        // ref={textFieldRef}
        inputProps={{ ...rest.inputProps, maxLength: textLimit ?? 1024 }}
        inputRef={textFieldRef}
        multiline={true}
        onChange={(ev) => {
            //console.log("textLimit", textLimit);
            clearTimeout(timeoutRef.current);
            const valuedEv = { target: { value: ev.target.value } };
            timeoutRef.current = setTimeout(() => {
                onChange?.(valuedEv as any);
            }, 200);
        }}

    /><HandleFormHelperText
            isError={(value as string).length === textLimit ?? 1024}
            errorMessage={lang.textOverLimit}

        /></>

}