import { ApiPageResult, ApiResult } from 'api';
import { ClientDetailDTO, ClientListItemDTO, ClientSummaryDTO, ContactsDTO, MemoDTO, PropertyListItemDTO } from 'common/dto';
import { objectToQuery, trackGoals } from 'common/utils';

const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];

// TODO: auth
export const clientApi = {

  searchOwnContacts: async (kw: string, token: string, contactId?: number): Promise<ApiResult<ContactsDTO[]>> => {
    try {
      const response = await fetch(`${BASE_URL}/client-contacts?kw=${kw}${contactId ? `&contactId=${contactId}` : ''}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  searchUpdatableContacts: async (kw: string, token: string): Promise<ApiResult<ContactsDTO[]>> => {
    try {
      const response = await fetch(`${BASE_URL}/client-contacts/updatable?kw=${kw}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  searchContactsForStockCart: async (kw: string, pid: string, token: string): Promise<ApiResult<ContactsDTO[]>> => {
    try {
      const response = await fetch(`${BASE_URL}/client-contacts/stock-cart?kw=${kw}&pid=${pid}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  searchContactsOfLandlord: async (kw: string, token: string, contactId?: number): Promise<ApiResult<ContactsDTO[]>> => {
    try {
      const response = await fetch(`${BASE_URL}/client-contacts/landlord?kw=${kw}${contactId ? `&contactId=${contactId}` : ''}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  getList: async (criteria: any = {}, token: string): Promise<ApiPageResult<ClientListItemDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/clients?${objectToQuery(criteria)}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  getDetail: async (cid: string, token: string, assignAgentToNewProperty?: string): Promise<ApiResult<ClientDetailDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/clients/${cid}`, {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`, 'AssignAgentToNewProperty': assignAgentToNewProperty ?? 'false' },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  getNewlyCreatedClientDetail: async (cid: string, token: string): Promise<ApiResult<ClientDetailDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/clients/newlyCreatedClient/${cid}`, {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  getSummary: async (cid: string, token: string): Promise<ApiResult<ClientSummaryDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/clients/${cid}/summary`, {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  add: async (clientDetail: ClientDetailDTO, token: string): Promise<ApiResult<ClientDetailDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/clients`, {
        method: 'POST', body: JSON.stringify(clientDetail),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      const apiResult = await response.json();
      // const goalsToTrack: string[] = apiResult.metadata?.goalsToTrack ?? [];
      // 
      // 
      // trackGoals(goalsToTrack);
      const goalsListToTrack = apiResult.metadata?.goalsListToTrack ?? [];
      trackGoals(goalsListToTrack);
      return apiResult;
    } catch (e) {
      return { error: String(e) };
    }
  },

  update: async (clientDetail: ClientDetailDTO, token: string): Promise<ApiResult<ClientDetailDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/clients/${clientDetail.cid}`, {
        method: 'POST', body: JSON.stringify(clientDetail),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });

      const apiResult = await response.json();
      // const goalsToTrack: string[] = apiResult.metadata?.goalsToTrack ?? [];
      const goalsListToTrack = apiResult.metadata?.goalsListToTrack ?? [];
      trackGoals(goalsListToTrack);

      return apiResult;
    } catch (e) {
      return { error: String(e) };
    }
  },

  getStockCart: async (cid: any, status: string, criteria: any = {}, token: string): Promise<ApiPageResult<PropertyListItemDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/clients/${cid}/${status}/stock-cart?${objectToQuery(criteria)}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  addStockCart: async (ids: any, token: string): Promise<ApiResult<any>> => {
    const { cid, pid } = ids;
    try {
      const response = await fetch(`${BASE_URL}/clients/${cid}/stock-cart`, {
        method: 'POST', body: JSON.stringify(pid),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  removeStockCart: async (ids: any, token: string): Promise<ApiResult<any>> => {
    const { cid, pid } = ids;
    try {
      const response = await fetch(`${BASE_URL}/clients/${cid}/stock-cart`, {
        method: 'PATCH', body: JSON.stringify(pid),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  getMemoList: async (id: string, token: string): Promise<ApiResult<MemoDTO[]>> => {
    try {
      const response = await fetch(`${BASE_URL}/clients/${id}/memo`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  addMemo: async (id: string, memo: MemoDTO, token: string): Promise<ApiResult<MemoDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/clients/${id}/memo`, {
        method: 'POST', body: JSON.stringify(memo),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });

      const apiResult = await response.json();
      const goalsListToTrack = apiResult.metadata?.goalsListToTrack ?? [];
      trackGoals(goalsListToTrack);
      return apiResult;
    } catch (e) {
      return { error: String(e) };
    }
  },

  agentClaim: async (cid: string, token: string): Promise<ApiResult<any>> => {
    try {
      const response = await fetch(`${BASE_URL}/clients/${cid}/agent-claim`, {
        method: 'PATCH', body: JSON.stringify(cid),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      const apiResult = await response.json();
      // const goalsToTrack: string[] = apiResult.metadata?.goalsToTrack ?? [];
      const goalsListToTrack = apiResult.metadata?.goalsListToTrack ?? [];
      trackGoals(goalsListToTrack);

      return apiResult;
    } catch (e) {
      return { error: String(e) };
    }
  },

  agentRemove: async (cid: string, token: string): Promise<ApiResult<any>> => {
    try {
      const response = await fetch(`${BASE_URL}/clients/${cid}/remove`, {
        method: 'PATCH', body: JSON.stringify(cid),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  mergeClient: async (cidA: number, cidB: number, strategy: 'CLIENT_CLAIM', token: string): Promise<ApiResult<any>> => {
    try {
      const response = await fetch(`${BASE_URL}/clients/merge-client?cidA=${cidA}&cidB=${cidB}&strategy=${strategy}`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  assignAgent: async (cid: string, agentId: string, token: string): Promise<ApiResult<any>> => {
    try {
      const response = await fetch(`${BASE_URL}/clients/assign-agent/${cid}/${agentId}`, {
        method: 'PATCH', body: JSON.stringify(cid),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      const apiResult = await response.json();
      // const goalsToTrack: string[] = apiResult.metadata?.goalsToTrack ?? [];
      const goalsListToTrack = apiResult.metadata?.goalsListToTrack ?? [];
      trackGoals(goalsListToTrack);

      return apiResult;
    } catch (e) {
      return { error: String(e) };
    }
  },

  removeAgent: async (cid: string, token: string): Promise<ApiResult<any>> => {
    try {
      const response = await fetch(`${BASE_URL}/clients/${cid}/remove-agent`, {
        method: 'PATCH', body: JSON.stringify(cid),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  getSellerStock: async (cid: any, criteria: any = {}, token: string): Promise<ApiPageResult<PropertyListItemDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/clients/${cid}/seller-stock?${objectToQuery(criteria)}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  getColumnPreference: async (token: string): Promise<ApiResult<any>> => {
    try {
      const response = await fetch(`${BASE_URL}/clients/column-preference`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  checkDuplicatedContact: async (contact: { type: string, value: string }, token: string): Promise<ApiResult<boolean>> => {
    try {
      const response = await fetch(`${BASE_URL}/clients/check-duplicated-contact?type=${contact.type}&value=${contact.value}`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },
};

export default clientApi;