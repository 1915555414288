import { ApiError, ApiPageResult, ApiResult } from "api";
import buildingApi from "api/buildingApi";
import { BuildingDetailDTO, BuildingListItemDTO } from "common/dto";
import { BuildingActions, BuildingAliasListActions, BuildingMergeActions, IRootState, LayoutActions } from "reducers";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { PASSagaContext } from 'sagas';
import { apiTaskWrapper, itemActionMessage } from "./saga-commons";


export function* watchBuildingListFetchRequested() {
  yield takeLatest(
    'BuildingList.FetchRequested',
    apiTaskWrapper(requestBuildingList)
  );
}

export function* requestBuildingList(action: Extract<BuildingActions, { type: 'BuildingList.FetchRequested' }>) {

  const token: string = yield select((state: IRootState) => state.login.token ?? '');
  // const limit = yield select((state: IRootState) => state.login.rowsCountPreference?.)
  const { criteria }: IRootState['building'] = yield select((state: IRootState) => state.building);

  const result: ApiPageResult<BuildingListItemDTO> = yield call(buildingApi.getList, criteria, token);

  if (!result.error) {
    yield put<BuildingActions>({ type: 'BuildingList.Loaded', payload: result.data! });
  } else {
    throw ApiError.of(result.error!);
  }

}

export function* watchBuildingDetailFetchRequested() {
  yield takeEvery<BuildingActions['type']>(
    'Building.FetchRequested',
    apiTaskWrapper(requestBuildingDetail)
  )
}

export function* requestBuildingDetail(action: BuildingActions) {
  if (action.type != 'Building.FetchRequested') {
    return;
  }

  const { id } = action.payload;

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<BuildingDetailDTO> = yield call(buildingApi.getDetail, id, token);

  if (!result.error) {
    yield put<BuildingActions>({ type: 'Building.Loaded', payload: result.data! });
  } else {
    throw ApiError.of(result.error!, null, "/buildings");
  }

}

export function* watchBuildingCreationRequested(context: PASSagaContext) {
  yield takeEvery(
    'Building.CreationRequested',
    apiTaskWrapper(createBuilding),
    context,
  );
}

export function* createBuilding(context: PASSagaContext, action: Extract<BuildingActions, { type: 'Building.CreationRequested' }>) {
  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<BuildingDetailDTO> = yield call(buildingApi.add, action.payload, token);

  const { lang } = yield select((state: IRootState) => state.locale);

  if (!result.error) {
    yield* itemActionMessage('create', 'Building');
    const newBuildingId = result.data?.id;
    yield call(context.browserHistory.replace as any, `/buildings/${newBuildingId}`);
  } else {
    if (result.error === 'ERR_DUPLICATED_BUILDING') {
      yield put<LayoutActions>({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgDuplicatedBuilding, severity: 'error' } })
    }
    // else if (result.error === 'ERR_BUILDING_FLOOR_LEVEL_VALIDATION') {
    //   yield put<LayoutActions>({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgFloorLevelValidationError, severity: 'error' } })
    // } 
    else if (result.error === 'ERR_BULIDING_FLOOR_UNIT_VALIDATION') {
      yield put<LayoutActions>({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgFloorUnitValidationError, severity: 'error' } });
    } else if (result.error === 'ERR_BUILDING_LEVEL_OVERLAP') {
      yield put<LayoutActions>({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgLevelOverlapError, severity: 'error' } })
    } else {
      throw ApiError.of(result.error);
    }
  }
}

export function* watchBuildingUpdateRequested(context: PASSagaContext) {
  yield takeEvery(
    'Building.UpdateRequested',
    apiTaskWrapper(updateBuilding),
    context,
  );
}

export function* updateBuilding(context: PASSagaContext, action: Extract<BuildingActions, { type: 'Building.UpdateRequested' }>) {

  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const result: ApiResult<BuildingDetailDTO> = yield call(buildingApi.update, action.payload, token);

  const { lang } = yield select((state: IRootState) => state.locale);

  if (!result.error) {
    yield* itemActionMessage('update', 'Building');
    // const buildingId = result.data?.id;
    // yield put<BuildingActions>({ type: 'BuildingList.FetchRequested', payload: { page: '0', limit: '10'}}});
    yield put<BuildingActions>({ type: 'Building.Loaded', payload: result.data! });
  } else {
    if (result.error === 'ERR_DUPLICATED_BUILDING') {
      yield put<LayoutActions>({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgDuplicatedBuilding, severity: 'error' } })
    }
    // else if (result.error === 'ERR_BUILDING_FLOOR_LEVEL_VALIDATION') {
    //   yield put<LayoutActions>({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgFloorLevelValidationError, severity: 'error' } });
    // } 
    else if (result.error === 'ERR_BULIDING_FLOOR_UNIT_VALIDATION') {
      yield put<LayoutActions>({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgFloorUnitValidationError, severity: 'error' } });
    } else if (result.error === 'ERR_BUILDING_LEVEL_OVERLAP') {
      yield put<LayoutActions>({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgLevelOverlapError, severity: 'error' } })
    } else if (result.error === 'ERR_FLOOR_UNIT_LEVEL_IN_USE_BY_PROPERTY') {
      yield put<LayoutActions>({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgFloorUnitInUseByProperty, severity: 'error' } })
    } else {
      throw ApiError.of(result.error);
    }
  }
}

export function* watchBuildingMergeRequested(context: PASSagaContext) {
  yield takeEvery(
    'BuildingMerge.MergeRequested',
    apiTaskWrapper(mergeBuilding),
    context,
  );
}

export function* mergeBuilding(context: PASSagaContext, action: Extract<BuildingMergeActions, { type: 'BuildingMerge.MergeRequested' }>) {
  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const {
    mergeBuildingA, mergeBuildingB, addToAlias
  }: IRootState['buildingMerge'] = yield select((state: IRootState) => state.buildingMerge);

  const result: ApiResult<BuildingDetailDTO> = yield call(buildingApi.merge,
    +(mergeBuildingA?.id ?? 0),
    +(mergeBuildingB?.id ?? 0),
    addToAlias,
    token,
  );

  if (result.error) {
    throw ApiError.of(result.error);
  }

  if (context.browserHistory.location.pathname !== `/buildings/${mergeBuildingA?.id}`) {
    yield call(context.browserHistory.replace as any,
      `/buildings/${mergeBuildingA?.id}`, {}
    );
  } else {
    yield put({ type: 'Building.FetchRequested', payload: { id: mergeBuildingA?.id ?? 0 } });
  }
}



export function* watchBuildingAliasListFetchRequested(context: PASSagaContext) {
  yield takeEvery(
    'BuildingAliasList.FetchRequested',
    apiTaskWrapper(fetchBuildingAliasList),
    context,
  );
}

function* fetchBuildingAliasList(context: PASSagaContext, action: Extract<BuildingAliasListActions, { type: 'BuildingAliasList.FetchRequested' }>) {
  const buildingId = action.payload.buildingId;
  const token: string = yield select((state: IRootState) => state.login.token ?? '');

  const { data, error } = yield call(buildingApi.getAliasList, buildingId, token);

  if (error) {
    throw ApiError.of(error);
  }

  yield put<BuildingAliasListActions>({ type: 'BuildingAliasList.Loaded', payload: { list: data } });
}

export function* watchBuildingAliasAddRequested(context: PASSagaContext) {
  yield takeEvery(
    'BuildingAliasList.AddRequested',
    apiTaskWrapper(addAlias),
    context,
  );
}

function* addAlias(context: PASSagaContext, action: Extract<BuildingAliasListActions, { type: 'BuildingAliasList.AddRequested' }>) {
  const token: string = yield select((state: IRootState) => state.login.token ?? '');
  const alias = action.payload;

  const { _data, error } = yield call(buildingApi.addAlias, alias, token);

  if (error) {
    throw ApiError.of(error);
  }

  yield put<BuildingAliasListActions>({ type: 'BuildingAliasList.ToggleAdding', payload: false });
  yield put<BuildingAliasListActions>({ type: 'BuildingAliasList.FetchRequested', payload: { buildingId: alias.buildingId } });
}

export function* watchBuildingAliasRemoveRequested(context: PASSagaContext) {
  yield takeEvery(
    'BuildingAliasList.RemoveRequested',
    apiTaskWrapper(deleteAlias),
    context,
  );
}

function* deleteAlias(context: PASSagaContext, action: Extract<BuildingAliasListActions, { type: 'BuildingAliasList.RemoveRequested' }>) {
  const token: string = yield select((state: IRootState) => state.login.token ?? '');
  const { aliasId, buildingId } = action.payload;

  const { _data, error } = yield call(buildingApi.deleteAlias, buildingId, aliasId, token);

  if (error) {
    throw ApiError.of(error);
  }

  yield put<BuildingAliasListActions>({ type: 'BuildingAliasList.FetchRequested', payload: { buildingId } });
}