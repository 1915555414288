import { ApiResult } from 'api';
import { CheckExistSignedFormDTO, EmailFormDTO, Form1DTO, Form2DTO, Form3DTO, Form4DTO, Form5DTO, Form6DTO } from 'common/dto';
import { trackGoals } from 'common/utils';

const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];

// TODO: auth
export const formApi = {

  /////////////////////// Common /////////////////////////

  sign: async (url: string, formData:any): Promise<ApiResult<any>> => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });
      const apiResult = await response.json();
      // const goalsToTrack: string[] = apiResult.metadata?.goalsToTrack ?? [];
      const goalsListToTrack = apiResult.metadata?.goalsListToTrack ?? [];
      trackGoals(goalsListToTrack);

      return apiResult;
    } catch (e) {
      return { error: String(e) };
    }
  },

  sendCopyToClient: async (dto: EmailFormDTO, token: string): Promise<ApiResult<any>> => {
    try {
      const response = await fetch(`${BASE_URL}/form/sendCopyToClient/${dto.formName}`, {
        method: 'POST', body: JSON.stringify(dto),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  inviteClientToSign: async (dto: EmailFormDTO, token: string): Promise<ApiResult<any>> => {
    try {
      const response = await fetch(`${BASE_URL}/form/inviteClientToSign/${dto.id}/${dto.formName}/${dto.locale}`, {
        method: 'POST', body: JSON.stringify(dto),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  generateClientSigningLink: async (dto: EmailFormDTO, token: string): Promise<ApiResult<any>> => {
    try {
      const response = await fetch(`${BASE_URL}/form/generateClientSigningLink/${dto.id}/${dto.formName}`, {
        method: 'POST', body: JSON.stringify(dto),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  checkExistSignedForm: async (dto: CheckExistSignedFormDTO, token: string): Promise<ApiResult<any>> => {
    try {
      const response = await fetch(`${BASE_URL}/form/checkExistSignedForm`, {
        method: 'POST', body: JSON.stringify(dto),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  /////////////////////// FORM 1//////////////////////////

  addForm1: async (propertyStockId: string, formId: string, form1: Form1DTO, token: string): Promise<ApiResult<Form1DTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/properties/${propertyStockId}/form1`, {
        method: 'POST', body: JSON.stringify(form1),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  getForm1: async (propertyStockId: string, token: string): Promise<ApiResult<Form1DTO>> => {
    try {
      const response = await fetch (`${BASE_URL}/properties/form1/${propertyStockId}`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  updateForm1: async (form1: Form1DTO, token: string): Promise<ApiResult<Form1DTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/properties/form1/${form1.id}`, {
        method: 'POST', body: JSON.stringify(form1),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  /////////////////////// FORM 2//////////////////////////

  addForm2: async (propertyStockId: string, formId: string, form2: Form2DTO, token: string): Promise<ApiResult<Form2DTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/properties/${propertyStockId}/form2`, {
        method: 'POST', body: JSON.stringify(form2),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  getForm2: async (propertyStockId: string, token: string): Promise<ApiResult<Form2DTO>> => {
    try {
      const response = await fetch (`${BASE_URL}/properties/form2/${propertyStockId}`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  updateForm2: async (form2: Form2DTO, token: string): Promise<ApiResult<Form2DTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/properties/form2/${form2.id}`, {
        method: 'POST', body: JSON.stringify(form2),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },
 
  /////////////////////// FORM 3//////////////////////////

  addForm3: async (propertyStockId: string, formId: string, form3: Form3DTO, token: string): Promise<ApiResult<Form3DTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/properties/${propertyStockId}/form3`, {
        method: 'POST', body: JSON.stringify(form3),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  getForm3: async (propertyStockId: string, token: string): Promise<ApiResult<Form3DTO>> => {
    try {
      const response = await fetch (`${BASE_URL}/properties/form3/${propertyStockId}`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  updateForm3: async (form3: Form3DTO, token: string): Promise<ApiResult<Form3DTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/properties/form3/${form3.id}`, {
        method: 'POST', body: JSON.stringify(form3),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  /////////////////////// FORM 5//////////////////////////

  addForm5: async (propertyStockId: string, formId: string, form5: Form5DTO, token: string): Promise<ApiResult<Form5DTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/properties/${propertyStockId}/form5`, {
        method: 'POST', body: JSON.stringify(form5),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  getForm5: async (propertyStockId: string, token: string): Promise<ApiResult<Form5DTO>> => {
    try {
      const response = await fetch (`${BASE_URL}/properties/form5/${propertyStockId}`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  updateForm5: async (form5: Form5DTO, token: string): Promise<ApiResult<Form5DTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/properties/form5/${form5.id}`, {
        method: 'POST', body: JSON.stringify(form5),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  /////////////////////// FORM 4//////////////////////////

  addForm4: async (buyerClientId: string, formId: string, form4: Form4DTO, token: string): Promise<ApiResult<Form4DTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/clients/${buyerClientId}/form4`, {
        method: 'POST', body: JSON.stringify(form4),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  getForm4: async (buyerClientId: string, token: string): Promise<ApiResult<Form4DTO>> => {
    try {
      const response = await fetch (`${BASE_URL}/clients/form4/${buyerClientId}`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  updateForm4: async (form4: Form4DTO, token: string): Promise<ApiResult<Form4DTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/clients/form4/${form4.id}`, {
        method: 'POST', body: JSON.stringify(form4),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  /////////////////////// FORM 6//////////////////////////

  addForm6: async (tenantClientId: string, formId: string, form6: Form6DTO, token: string): Promise<ApiResult<Form6DTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/clients/${tenantClientId}/form6`, {
        method: 'POST', body: JSON.stringify(form6),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  getForm6: async (tenantClientId: string, token: string): Promise<ApiResult<Form6DTO>> => {
    try {
      const response = await fetch (`${BASE_URL}/clients/form6/${tenantClientId}`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  updateForm6: async (form6: Form6DTO, token: string): Promise<ApiResult<Form6DTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/clients/form6/${form6.id}`, {
        method: 'POST', body: JSON.stringify(form6),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },
};

export default formApi;