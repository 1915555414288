import { PropertyStockDTO } from "common/dto";
import { FormValidations } from "common/hooks";
import { isNonEmpty } from "common/utils";
import { numberFields } from 'common/validators';
import { validFloorUnits } from 'views/TransactionDetail/property-draft-edit-validations';

type Options = { [key: string]: string };

const propertyFormValidations = (lang: Options, langCommons: Options, creating: boolean): FormValidations<PropertyStockDTO> => [
  ...numberFields<PropertyStockDTO>([
    // Basic & Sales
    'net', 'gross',
    'baseFloorGross', 'baseFloorNet', 'groundFloorGross', 'groundFloorNet',
    'mezzanineFloorGross', 'mezzanineFloorNet',
    'otherAreaGross', 'otherAreaNet',
    'price', 'rent', 'bottomRent', 'bottomPrice',
    // Features
    'gardenArea', 'rooftopArea', 'currentRent', 'mezzanineFloorArea',
    'storeFrontWidth', 'storeFrontHeight', 'shopDepth',
    // Mgt.
    'rentFreePeriod',
    'quarterlyRates', 'annualGovRent', 'monthlyMgtFee',
  ], langCommons.msgMustBeNumber),

  (values) => values.isCreatingDuplicatedPropertyStock || values.price || values.rent ? null : [
    ['price', lang.msgInputAtLeastPriceOrRent],
    ['rent', lang.msgInputAtLeastPriceOrRent],
  ],
  (values) => (values.price ?? 0) >= (values.bottomPrice ?? 0) ? null : [
    ['bottomPrice', lang.msgBottomPriceExceedsPrice],
  ],
  (values) => (values.rent ?? 0) >= (values.bottomRent ?? 0) ? null : [
    ['bottomRent', lang.msgBottomRentExceedsRent],
  ],
  (values) => values.isCreatingDuplicatedPropertyStock || (values.net || values.gross) ? null : [
    ['net', lang.msgInputNetOrGross],
    ['gross', lang.msgInputNetOrGross],
  ],
  // During edit, lead agents must fill in the seller client.
  (values) => (values.isCreatingDuplicatedCompanyStock || (!values.isLeadAgent && !creating) || values.sellerClientId) ? null : [
    ['mainContact', lang.msgInputClient],
  ],
  (values) => !creating || values.building || values.buildingEn ? null : [
    ['building', lang.msgInputBuilding],
  ],
  (values) => isNonEmpty(values.floor) ? null : [
    ['floor', lang.msgInputFloor],
  ],
  (values) => isNonEmpty(values.unit) ? null : [
    ['unit', lang.msgInputUnit],
  ],
  (values) => values.usage ? null : [
    ['usage', lang.msgInputUsage],
  ],
  (values) => values.usage !== 'V' ? null :
    (validFloorUnits(values.floor?.join(',')) ? null :
      [['floor', lang.msgPleaseInputValidFloor]]),
  (values) => values.usage !== 'V' ? null :
    (validFloorUnits(values.unit?.join(',')) ? null :
      [['unit', lang.msgPleaseInputValidUnit]]),
  (values) => (!values.hasKey || values.hasKey && isNonEmpty(values.keyNo)) ? null : [
    ['keyNo', lang.msgInputKeyNo],
  ],
  (values) => (!values.isHotPick || values.isHotPick && isNonEmpty(values.hotpickExpiry)) ? null : [
    ['hotpickExpiry', lang.msgInputHotPickExpiry],
  ],
  (values) => values.owner && values.owner?.trim() !== ""
    ? null
    : [["owner", lang.msgInputOwner]],

];

export default propertyFormValidations;