import { Card, CardContent, CardHeader, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { format } from 'util';
import { options } from './chart';
// import { KeyboardDatePicker } from '@material-ui/pickers';
// import moment from 'moment';


const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 300,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const NewClientUpdates = props => {
  const { className, graphData, ...rest } = props;
  const { langDashboard } = useSelector((state) => state.locale);

  const classes = useStyles();
  const TOP_N_DISTRICT = useSelector((state) => state.systemSettings.Dashboard?.GLOBAL_SETTING.TOP_N_DISTRICT);
  // const DISPLAY_DATE_FORMAT = useSelector((state) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);

  // const [ categoryState, setCategoryState ] = useState('NT');
  // const [ timePeriodTypeState, setTimePeriodTypeState ] = useState('M');
  // const [ timePeriodState, setTimePeriodState ] = useState(0);
  // const [ graphData, setGraphData] = useState(newTerritoriesData());

  const reference = useRef();
  useEffect(() => {
    
    let chart = reference.current && reference.current.chartInstance;
    chart && chart.update();
  }, [ graphData ]);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        // action={
        //   <div>
        //     <TextField select
        //       fullWidth
        //       margin="dense"
        //       variant="outlined"
        //       label="Timespan By"
        //       value={timePeriodTypeState}
        //       onChange={(ev) => {
        //         // setCategoryState(ev.target.value);
        //         // if (ev.target.value === 'HKI') {
        //         //   setGraphData(hkIslandData());
        //         // } else if (ev.target.value === 'KLN') {
        //         //   setGraphData(kowloonData());
        //         // } else {
        //         //   setGraphData(newTerritoriesData());
        //         // }
        //       }}>
        //       <MenuItem value='D'>Day</MenuItem>
        //       <MenuItem value='M'>Month</MenuItem>
        //       <MenuItem value='Y'>Year</MenuItem>
        //     </TextField>

              
                      
        //     <TextField select
        //       fullWidth
        //       margin="dense"
        //       variant="outlined"
        //       label="Region"
        //       value={categoryState}
        //       onChange={(ev) => {
        //         setCategoryState(ev.target.value);
        //         if (ev.target.value === 'HKI') {
        //           setGraphData(hkIslandData());
        //         } else if (ev.target.value === 'KLN') {
        //           setGraphData(kowloonData());
        //         } else if (ev.target.value === 'NT') {
        //           setGraphData(newTerritoriesData());
        //         } else {
        //           setGraphData(newTerritoriesData());
        //         }
        //       }}>
        //       <MenuItem value="ALL">All</MenuItem>
        //       <MenuItem value='HKI'>Hong Kong Island</MenuItem>
        //       <MenuItem value='KLN'>Kowloon</MenuItem>
        //       <MenuItem value='NT'>New Territories</MenuItem>
        //     </TextField>
        //   </div>
        // }
        // title="Total Sales Commission (Top %d Districts)"
        title = {<>{langDashboard.titleNewClientUpdates} <br />{format(langDashboard.titleTopNAndOwnDistrict, TOP_N_DISTRICT)}</>}

        //import { format } from ‘util’; string
        // format(‘xxx %d xxx…’, 1234) === ‘xxx 1234 xxx…’

      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <HorizontalBar
            data={graphData}
            options={options(langDashboard)}
            ref={reference}
          />
        </div>
      </CardContent>
      <Divider />
    </Card>
  );
};

NewClientUpdates.propTypes = {
  className: PropTypes.string
};

export default NewClientUpdates;
