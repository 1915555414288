import {
  Button, Card, CardActions, CardContent, Checkbox, Divider, FormControl, FormControlLabel,
  FormGroup, FormHelperText, Grid, InputAdornment, ListItemText, makeStyles, MenuItem, Radio, RadioGroup, Select, TextareaAutosize, TextField, TextFieldProps, Typography, withStyles
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { KeyboardDatePicker } from '@material-ui/pickers';
import clsx from 'clsx';
import { Form1DTO, PropertyStockDTO } from 'common/dto';
import { FormPresenter } from "common/hooks";
import { HandleFormHelperText } from 'common/ui';
import { genAddress, initFormKeysAndBooleanMap, isNonEmpty, limitNumberFieldLength, limitTextFieldLength, multiLang } from 'common/utils';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducers';

const regex = new RegExp(/(^\d*\.?\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/);
const NumberField = withStyles({
  root: {
    '& input': {
      textAlign: 'right',
      '-moz-appearance': 'textfield',
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  },
},
)((props: TextFieldProps) => {
  return <TextField type={"text"}{...props} inputProps={{
    ...props.inputProps,
    min: 0,
    max: Math.max(),
    inputMode: 'decimal',
    pattern: "([^0-9]*)",
    step: 0.01
  }}

    onKeyDown={e => {
      // If the key pressed is not a number or a period, nothing is printed
      if (!e.key.match(regex)) {

        e.preventDefault();

      }
      // if (!e.key.match(regex) || (e.key === '.' && String(props.value).indexOf('.') > 0) || (e.key === '.' && String(props.value).length === 0) || e.keyCode === 229 || e.key === 'Unidentified') {

      //   e.preventDefault();

      // }

    }}

    onKeyPress={e => {
      // If the key pressed is not a number or a period, nothing is printed
      if (!e.key.match(regex)) {

        e.preventDefault();

      }
    }}

  />;
});

const useStyles = makeStyles(() => ({
  root: {},
  partTitle: {
    fontWeight: 'bold',
  },
  textArea: {
    width: '100%',
    margin: 'dense',
    padding: '8px',
    fontSize: '14px'
  },
  table: {
    minWidth: 650,
  },
  datePicker: {
    width: '30%',
  },
  column0: {
    width: '5%',
  },
  partB_column1: {
    width: '20%',
  },
  partC_column1: {
    width: '20%',
  },
  numberFields: {
    width: '30%',
  },
  part2_column1: {
    width: '7%',
  },
  part2_description: {
    width: '100%',
  },
  part2_textAreaDiv: {
    paddingTop: '4px'
  },
  subTableCell: {
    borderBottomColor: '#FFFFFF',
  },
  divider: {
    backgroundColor: '#1565c0',
  },
  longTableContainer: {
    overflowY: 'auto',
  },
}));

const errorColor = '#e53935';

const trimSpace = (array: string[] | undefined) => {
  const joinStr = array?.filter(v => v != '').join(',');
  if (joinStr == '') {
    return undefined;
  }
  return joinStr;
}

export const form1Initial: Partial<Form1DTO> = {
  costOfPurchaserOptions: null,
  leaseOption: '',
  costOrEstimatedCost: 'both',
  part2_statement1: '',
  part2_statement2: '',
  part2_statement3: '',
  part2_statement4: '',
  hasCourtOrders: false,
  hasBuildingOrders: false,
  hasSlopeMaintenanceOrders: false,
  hasLease: false,
  hasMortgageOrCharge: false,
  hasRightOfRenewal: false,
  ownersIncorporationEstablished: false,
  includeRates: false,
  includeGovernmentRent: false,
  includeManagementFee: false,
  hasBreakClause: false,
  hasRentReview: false,
  hasOptionToRenew: false,
  hasOtherSpecialTerms: false,
  hasYearOfCompletion: false,
  hasUserRestrictions: false,
  informationProvidedByVendor: false,
};

interface Form1Props {
  className?: string;
  propertyStockId: string;
  form1: FormPresenter<Form1DTO>;
  multiSelectForm: FormPresenter<{ [type: string]: string[] }>;
}

const disablePrevDates = (startDates: any) => {
  const startSeconds = Date.parse(startDates);
  return (date: any) => {
    return Date.parse(date) < startSeconds;
  }
}


const Form1Dialog = (props: Form1Props) => {
  const { className, propertyStockId, form1, multiSelectForm: tagForm, ...rest } = props;
  const classes = useStyles();
  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);
  const agencyCompanyInfo = useSelector((state: IRootState) => state.systemSettings.Company.AGENCY_COMPANY_INFO);
  const { lang, langForm1, langPropertyStock, formLocaleOptions, form1SourceOptions, yesNoOptions, form1CostOfPurchaserOptions, locale } = useSelector((state: IRootState) => state.locale);

  //console.log("form1.values.propertyCategories", form1.values.propertyCategories, form1.hasError('propertyCategories'));
  const dispatch = useDispatch();

  const changePropertyCategories = (key: string) => {
    form1.updateValues('propertyCategories', form1.values.propertyCategories === key ? "" : key);
    if (key !== 'others') {
      form1.updateValues('descriptionForOthers', undefined);
    }
  };
  const bind = form1.bind;

  const [keyBooleanMap, setKeyBooleanMap] = useState(initFormKeysAndBooleanMap(form1));

  useEffect(() => {
    setKeyBooleanMap(initFormKeysAndBooleanMap(form1));
  }, [Object.keys(form1.values).length])

  // useEffect(() => {
  //   console.log('keyBooleanMap', keyBooleanMap, keyBooleanMap.get('ownerName'))
  // }, [keyBooleanMap])

  //for validation only
  useEffect(() => {
    form1.setValues({
      ...form1.values,
      costOfPurchaserOptions: (tagForm.values.costOfPurchaserOptions === [] || !isNonEmpty(tagForm.values.costOfPurchaserOptions)) ? null : tagForm.values.costOfPurchaserOptions?.join(','),
    });
    // if (!(form1.values.costOfPurchaserOptions?.includes('a') || form1.values.costOfPurchaserOptions?.includes('b') || form1.values.costOfPurchaserOptions?.includes('c') || form1.values.costOfPurchaserOptions?.includes('d'))){
    //   form1.setValues({
    //     ...form1.values,
    //     natureAndCostOfPurchaserDetail: undefined,
    //   });
    // }
    //disable other options depending on the option(s) selected
    var newDisableOption: { [key: string]: boolean } = {};
    Object.keys(form1CostOfPurchaserOptions).map(key => {
      newDisableOption[key] = determineDisableOption(key);
    })
    setDisableOption(newDisableOption);

  }, [tagForm.values.costOfPurchaserOptions]);

  //handle disable costOfPurchaserOptions
  const [disableOption, setDisableOption] = useState<{ [key: string]: boolean }>({
    'a': false, 'b': false, 'c': false, 'd': false, 'e': false, 'f': false
  });
  const determineDisableOption = (key: string) => {
    switch (key) {
      case 'a':
      case 'b':
      case 'c':
      case 'd':
        if (tagForm.values.costOfPurchaserOptions?.includes('e') || tagForm.values.costOfPurchaserOptions?.includes('f')) {
          return true;
        } else {
          return false;
        }
      case 'e':
      case 'f':
        if (tagForm.values.costOfPurchaserOptions?.includes('a') ||
          tagForm.values.costOfPurchaserOptions?.includes('b') ||
          tagForm.values.costOfPurchaserOptions?.includes('c') ||
          tagForm.values.costOfPurchaserOptions?.includes('d')) {
          return true;
        } else {
          return false;
        }
      default:
        return false;
    }
  }

  const requiredFields: { [key: string]: string[] } = {
    form: ['formLocale',],
    partA: ['propertyAddress'],
    partB1: ['ownerName', 'courtOrdersParticulars', 'buildingOrdersParticulars', 'slopeMaintenanceOrdersParticulars', 'leaseParticulars', 'mortgageOrChargeParticulars', 'dateOfObtainingB1'],
    partB2: ['partB2_option', 'saleableAreaByDepartment', 'saleableAreaFirstAssignment', 'dateOfObtainingB2'],
    partB3: [/*'yearOfCompletion', 'sourceOfB3',*/ 'dateOfObtainingB3'],
    partB4: [/*'userRestrictions', 'sourceOfB4',*/ 'dateOfObtainingB4'],
    partB5: ['unexpiredTermOfLease', 'dateOfObtainingB5'],
    partB6: ['termOfProposedLease', 'dateOfObtainingB6'],
    partC6: ['propertyCategories'],
    part2: ['part2_statement1', 'part2_statement1_particular', 'part2_statement2', 'part2_statement2_particular', 'part2_statement3', 'part2_statement3_particular',
      'part2_statement4', 'part2_statement4_particular', 'costOfPurchaserOptions', 'natureAndCostOfPurchaserDetail', 'part2_statement6_option',
      'leaseOption', 'monthlyRental', 'tenancyTermYear', 'tenancyTermMonth', 'tenancyTermDay', 'tenancyTermStart', 'tenancyTermEnd', 'specialTermsDescription', 'vendorName'],
    agentInfo: ['agentName', 'nameAndLicenceNumberOfSignatory', 'numberOfAgentStatement', 'address', 'telephoneNumber'],
  };

  const space = (locale === 'en' ? ' ' : '');
  const getPartName = (part: string) => {
    return multiLang(locale, part + langForm1.part, langForm1.part + part);
  }

  const partNameMapping = (partName: string) => {
    switch (partName) {
      case 'form': return langForm1.form;
      case 'partA': return getPartName('A');
      case 'partB1': return getPartName('B1');
      case 'partB2': return getPartName('B2');
      case 'partB3': return getPartName('B3');
      case 'partB4': return getPartName('B4');
      case 'partB5': return getPartName('B5');
      case 'partB6': return getPartName('B6');
      case 'partC6': return getPartName('C6');
      case 'part2': return langForm1.part2;
      case 'agentInfo': return langForm1.agentInfo;
    }
  }

  const missingFieldMapping: { [key: string]: string } = {
    //general
    formLocale: langForm1.captionFormLocale,
    //partA
    propertyAddress: langForm1.propertyAddress,
    //B1
    ownerName: langForm1.ownerName,
    courtOrdersParticulars: langForm1.courtOrders + space + langForm1.particulars,
    buildingOrdersParticulars: langForm1.buildingOrders + space + langForm1.particulars,
    slopeMaintenanceOrdersParticulars: langForm1.slopeMaintenanceOrders + space + langForm1.particulars,
    leaseParticulars: langForm1.lease + space + langForm1.particulars,
    mortgageOrChargeParticulars: langForm1.mortgageOrCharge + space + langForm1.particulars,
    dateOfObtainingB1: langForm1.dateOfObtainingInfo,
    //B2
    partB2_option: langForm1.titlePartB2,
    saleableAreaByDepartment: langForm1.B2_optionA,
    saleableAreaFirstAssignment: langForm1.B2_optionB,
    dateOfObtainingB2: langForm1.dateOfObtainingInfo,
    //B3
    yearOfCompletion: langForm1.titlePartB3,
    sourceOfB3: langForm1.source,
    dateOfObtainingB3: langForm1.dateOfObtainingInfo,
    //B4
    userRestrictions: langForm1.B4_description,
    sourceOfB4: langForm1.source,
    dateOfObtainingB4: langForm1.dateOfObtainingInfo,
    //B5
    unexpiredTermOfLease: langForm1.unexpiredTermOfGovLease,
    dateOfObtainingB5: langForm1.dateOfObtainingInfo,
    //B6
    termOfProposedLease: langForm1.proposedLease,
    dateOfObtainingB6: langForm1.dateOfObtainingInfo,
    //part C6
    propertyCategories: langForm1.propertyCategoriesSelect,
    //part 2
    part2_statement1: langForm1.statement + '1',
    part2_statement1_particular: langForm1.statement + '1' + space + langForm1.particulars,
    part2_statement2: langForm1.statement + '2',
    part2_statement2_particular: langForm1.statement + '2' + space + langForm1.particulars,
    part2_statement3: langForm1.statement + '3',
    part2_statement3_particular: langForm1.statement + '3' + space + langForm1.particulars,
    part2_statement4: langForm1.statement + '4',
    part2_statement4_particular: langForm1.statement + '4' + space + langForm1.particulars,
    costOfPurchaserOptions: langForm1.statement + '5',
    natureAndCostOfPurchaserDetail: langForm1.statement + '5' + space + langForm1.particulars,
    part2_statement6_option: langForm1.statement + '6',
    leaseOption: langForm1.leaseOption,
    monthlyRental: langForm1.monthlyRental,
    tenancyTermYear: langForm1.tenancyTerm + '(' + langForm1.year + ')',
    tenancyTermMonth: langForm1.tenancyTerm + '(' + langForm1.month + ')',
    tenancyTermDay: langForm1.tenancyTerm + '(' + langForm1.day + ')',
    tenancyTermStart: langForm1.dateFrom,
    tenancyTermEnd: langForm1.dateTo,
    specialTermsDescription: langForm1.otherSpecialTermsParticular,
    vendorName: langForm1.vendorName,
    //agent info
    agentName: langForm1.agentName,
    nameAndLicenceNumberOfSignatory: langForm1.nameAndLicenceNumberOfSignatory,
    numberOfAgentStatement: langForm1.numberOfAgentStatement,
    address: langForm1.address,
    telephoneNumber: langForm1.telephoneNumber,
  }

  const missingFieldList = () => {
    let resultStr: string[] = [];
    Object.keys(requiredFields).forEach(part => {
      let partMissingFields: string[] = [];
      requiredFields[part].forEach(key => {
        if (form1.hasError(key as keyof Form1DTO)) {
          partMissingFields.push(missingFieldMapping[key]);
        }
      });
      if (partMissingFields.length > 0) {
        resultStr.push(partNameMapping(part.toString()) + ": " + partMissingFields.join(', '));
      }

    });
    return resultStr.join('\n');
  }

  //Tenancy year and month calculation, year=.slice(0,4), month=.slice(5,7), day=.slice(8,10)
  const handletenancy = (start: any, end: any) => {
    if (isNonEmpty(start) && start !== '' && isNonEmpty(end) && end !== '') {
      const startDate = moment(moment(start).format('YYYY-MM-DD'));
      const endDate = moment(moment(end).format('YYYY-MM-DD')).add(1, 'day');
      const years = endDate.diff(startDate, 'years');
      const months = endDate.diff(startDate, 'months') - (years * 12);
      startDate.add(years, 'years').add(months, 'months');
      const endDay = moment(moment(end).format('YYYY-MM-DD')).add(1, 'day').date();
      const startDay = moment(moment(start).format('YYYY-MM-DD')).date();
      const lastMonthDays = moment(moment(end).format('YYYY-MM-DD')).add(1, 'day').subtract(1, 'month').startOf('month').daysInMonth();
      let days = endDay - startDay;
      if (days < 0) {
        days = days + lastMonthDays;
      }
      form1.updateValues('tenancyTermYear', years);
      form1.updateValues('tenancyTermMonth', months);
      form1.updateValues('tenancyTermDay', days);
    }
  }

  const propertyStockDto = useSelector((state: IRootState) => state.property.fetchedDetailsByPropertyStockId[props.propertyStockId]) ?? {} as Partial<PropertyStockDTO>;
  const { districtHkiOptions: districtHkiOptionsEn, districtNtOptions: districtNtOptionsEn, districtKltOptions: districtKltOptionsEn } = useSelector((state: IRootState) => state.locale._bundle['en']);
  const { districtHkiOptions: districtHkiOptionsZhHK, districtNtOptions: districtNtOptionsZhHK, districtKltOptions: districtKltOptionsZhHK } = useSelector((state: IRootState) => state.locale._bundle['zh_HK']);

  const districtEnOptions = {
    ...districtHkiOptionsEn,
    ...districtNtOptionsEn,
    ...districtKltOptionsEn,
  };
  const districtZhHkOptions = {
    ...districtHkiOptionsZhHK,
    ...districtNtOptionsZhHK,
    ...districtKltOptionsZhHK,
  };


  const propertyAddress = (locale: string) => {

    const address = genAddress(locale,
      propertyStockDto.unit?.join(',') ?? '',
      propertyStockDto.floor?.join(',') ?? '',
      propertyStockDto.block ?? '', propertyStockDto.blockEn ?? '',
      propertyStockDto.building ?? '', propertyStockDto.buildingEn ?? '',
      propertyStockDto.street ?? '', propertyStockDto.streetEn ?? '',
      (locale === 'en') ? districtEnOptions[propertyStockDto.district ?? ''] : districtZhHkOptions[propertyStockDto.district ?? '']);
    return address;
  };

  const companyDetailsLocale = (locale: string) => {
    return (locale === 'en') ? { name: agencyCompanyInfo['COMPANY_NAME_EN'], address: agencyCompanyInfo['ADDRESS_EN'] } : { name: agencyCompanyInfo['COMPANY_NAME_CN'], address: agencyCompanyInfo['ADDRESS_CN'] };
  };
  const updateCompanyCurrentLocaleDetails = (locale: string) => {
    let details = companyDetailsLocale(locale);
    form1.updateValues('agentName', details.name);
    form1.updateValues('address', details.address);
    form1.updateValues('propertyAddress', propertyAddress(locale));
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}>
      {/* 生成出售物業代理協議 (表格3) */}
      {/* <CardHeader></CardHeader> */}
      {/* <Divider /> */}
      <CardContent>
        <Grid container xs={12} spacing={1}>
          {isNonEmpty(missingFieldList()) ?
            <Grid
              item
              md={12}
              xs={12}
            >

              <div style={{ marginBottom: '5px', whiteSpace: "pre-wrap" }}>
                <Typography style={{ color: errorColor, fontSize: '13px', fontWeight: 'bold', fontStyle: 'italic' }}>{langForm1.msgInputMissingFields}</Typography>
                <Typography style={{ color: errorColor, fontSize: '13px', fontStyle: 'italic' }}>{missingFieldList()}</Typography>
              </div>
              <Divider />
            </Grid>
            : null
          }
          <Grid
            item
            sm={2}
            md={2}
            xs={12}
          >
            <Typography>{langPropertyStock.captionFormLocale}</Typography>
            <TextField select
              fullWidth
              margin="dense"
              {...bind('formLocale')}
              required
              variant="outlined"
              onChange={(event) => {
                bind('formLocale').onChange(event);
                updateCompanyCurrentLocaleDetails(event.target.value);
              }}>
              {
                Object.keys(formLocaleOptions).map((opt) => <MenuItem value={opt}>{formLocaleOptions[opt]}</MenuItem>)
              }
            </TextField>
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >
            <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>{langForm1.titlePart1}</Typography>

          </Grid>
          <Grid
            item
            md={12}
            xs={12}
          >
            <Typography className={classes.partTitle}>{langForm1.titlePartA}</Typography>

          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >
            <TextareaAutosize
              className={classes.textArea}
              rowsMin={2}
              rowsMax={2}
              placeholder={''}
              {...bind('propertyAddress')}
              onChange={(e) => {
                limitTextFieldLength(e, 255, 'propertyAddress', form1, keyBooleanMap, setKeyBooleanMap);
              }}
            />
            {form1.hasError('propertyAddress') ? <FormHelperText style={{ color: form1.hasError('propertyAddress') ? errorColor : 'inherit' }}>{langForm1.pleaseInput + langForm1.propertyAddress}</FormHelperText> : null}
            <HandleFormHelperText
              isError={keyBooleanMap.get('propertyAddress')}
              errorMessage={lang.textOverLimit}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

        </Grid>

        {/* -------------------------- part B ----------------------*/}
        <Grid container xs={12} spacing={1}>

          <Grid
            item
            md={12}
            xs={12}
          >
            <Typography className={classes.partTitle}>{langForm1.titlePartB}</Typography>

          </Grid>
          {/* 
          <Grid
            item
            md={12}
            xs={12}
          >
            <Typography>{langForm1.titlePartB1}</Typography>
          </Grid>
          <Grid
            item
            md={3}
            xs={12}
            alignItems="flex-end"
          >
            <div>
              <Typography>{langForm1.ownerName}</Typography>
            </div>
          </Grid>
          <Grid
            item
            md={9}
            xs={9}
          >
            <TextField
              fullWidth
              margin="dense"
              name="headingChi"
              // onChange={handleChange}
              required
              value={'陳大文'}
              variant="outlined"
              // InputProps={{
              //   startAdornment: <InputAdornment className={classes.langAdornment} position="start">英</InputAdornment>
              // }}
            />
          </Grid> */}




          {/* ------------------------- B1 ----------------------------- */}
          <Grid
            item
            md={12}
            xs={12}
          >
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="B1 table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.column0}>1.</TableCell>
                    <TableCell colSpan={2} align="left">{langForm1.titlePartB1}</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {/*----------- ownerName ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell className={classes.partB_column1} >{langForm1.ownerName}</TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        margin="dense"
                        name="headingChi"
                        // onChange={handleChange}
                        required
                        {...bind('ownerName')}
                        variant="outlined"
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'ownerName', form1, keyBooleanMap, setKeyBooleanMap);
                        }}
                        helperText={form1.hasError('ownerName')}
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('ownerName')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>

                  {/*----------- court orders ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell >
                      <Checkbox
                        checked={form1.values.hasCourtOrders}
                        onChange={() => {
                          if (form1.values.hasCourtOrders) {
                            form1.updateValues('courtOrdersParticulars', undefined);
                          }
                          form1.updateValues('hasCourtOrders', !form1.values.hasCourtOrders);
                        }}
                      />
                      {langForm1.courtOrders}

                    </TableCell>
                    <TableCell>
                      <TextareaAutosize
                        className={classes.textArea}
                        rowsMin={1}
                        rowsMax={2}
                        placeholder={langForm1.helperTextParticulars}
                        disabled={form1.values.hasCourtOrders === false}
                        {...bind('courtOrdersParticulars')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'courtOrdersParticulars', form1, keyBooleanMap, setKeyBooleanMap);
                        }}
                      />
                      {form1.hasError('courtOrdersParticulars') ? <FormHelperText style={{ color: form1.hasError('courtOrdersParticulars') ? errorColor : 'inherit' }}>{langForm1.pleaseInput + langForm1.particulars}</FormHelperText> : null}
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('courtOrdersParticulars')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>

                  {/*----------- building orders ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell >
                      <Checkbox
                        checked={form1.values.hasBuildingOrders}
                        onChange={() => {
                          if (form1.values.hasBuildingOrders) {
                            form1.updateValues('buildingOrdersParticulars', undefined);
                          }
                          form1.updateValues('hasBuildingOrders', !form1.values.hasBuildingOrders);
                        }}
                      />
                      {langForm1.buildingOrders}

                    </TableCell>
                    <TableCell>
                      <TextareaAutosize
                        className={classes.textArea}
                        rowsMin={1}
                        rowsMax={2}
                        placeholder={langForm1.helperTextParticulars}
                        disabled={form1.values.hasBuildingOrders === false}
                        {...bind('buildingOrdersParticulars')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'buildingOrdersParticulars', form1, keyBooleanMap, setKeyBooleanMap);
                        }}
                      />
                      {form1.hasError('buildingOrdersParticulars') ? <FormHelperText style={{ color: form1.hasError('buildingOrdersParticulars') ? errorColor : 'inherit' }}>{langForm1.pleaseInput + langForm1.particulars}</FormHelperText> : null}
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('buildingOrdersParticulars')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>

                  {/*----------- slope maintenance orders ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell >
                      <Checkbox
                        checked={form1.values.hasSlopeMaintenanceOrders}
                        onChange={() => {
                          if (form1.values.hasSlopeMaintenanceOrders) {
                            form1.updateValues('slopeMaintenanceOrdersParticulars', undefined);
                          }
                          form1.updateValues('hasSlopeMaintenanceOrders', !form1.values.hasSlopeMaintenanceOrders);
                        }}
                      />
                      {langForm1.slopeMaintenanceOrders}
                    </TableCell>
                    <TableCell>
                      <TextareaAutosize
                        className={classes.textArea}
                        rowsMin={1}
                        rowsMax={2}
                        placeholder={langForm1.helperTextParticulars}
                        disabled={form1.values.hasSlopeMaintenanceOrders === false}
                        {...bind('slopeMaintenanceOrdersParticulars')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'slopeMaintenanceOrdersParticulars', form1, keyBooleanMap, setKeyBooleanMap);
                        }}
                      />
                      {form1.hasError('slopeMaintenanceOrdersParticulars') ? <FormHelperText style={{ color: form1.hasError('slopeMaintenanceOrdersParticulars') ? errorColor : 'inherit' }}>{langForm1.pleaseInput + langForm1.particulars}</FormHelperText> : null}
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('slopeMaintenanceOrdersParticulars')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>

                  {/*----------- lease ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell >
                      <Checkbox
                        checked={form1.values.hasLease}
                        onChange={() => {
                          if (form1.values.hasLease) {
                            form1.updateValues('leaseParticulars', undefined);
                          }
                          form1.updateValues('hasLease', !form1.values.hasLease);
                        }}
                      />
                      {langForm1.lease}
                    </TableCell>
                    <TableCell>
                      <TextareaAutosize
                        className={classes.textArea}
                        rowsMin={1}
                        rowsMax={2}
                        placeholder={langForm1.helperTextParticulars}
                        disabled={form1.values.hasLease === false}
                        {...bind('leaseParticulars')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'leaseParticulars', form1, keyBooleanMap, setKeyBooleanMap);
                        }}
                      />
                      {form1.hasError('leaseParticulars') ? <FormHelperText style={{ color: form1.hasError('leaseParticulars') ? errorColor : 'inherit' }}>{langForm1.pleaseInput + langForm1.particulars}</FormHelperText> : null}
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('leaseParticulars')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>

                  {/*----------- mortgage or charge---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell >
                      <Checkbox
                        checked={form1.values.hasMortgageOrCharge}
                        onChange={() => {
                          if (form1.values.hasMortgageOrCharge) {
                            form1.updateValues('mortgageOrChargeParticulars', undefined);
                          }
                          form1.updateValues('hasMortgageOrCharge', !form1.values.hasMortgageOrCharge);
                        }}
                      />
                      {langForm1.mortgageOrCharge}

                    </TableCell>
                    <TableCell>
                      <TextareaAutosize
                        className={classes.textArea}
                        rowsMin={1}
                        rowsMax={2}
                        name="headingChi"
                        placeholder={langForm1.helperTextParticulars}
                        disabled={form1.values.hasMortgageOrCharge === false}
                        {...bind('mortgageOrChargeParticulars')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'mortgageOrChargeParticulars', form1, keyBooleanMap, setKeyBooleanMap);
                        }}
                      />
                      {form1.hasError('mortgageOrChargeParticulars') ? <FormHelperText style={{ color: form1.hasError('mortgageOrChargeParticulars') ? errorColor : 'inherit' }}>{langForm1.pleaseInput + langForm1.particulars}</FormHelperText> : null}
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('mortgageOrChargeParticulars')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>

                  {/*----------- other matters registered as encumbrances---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell >
                      {langForm1.otherMattersRegisteredAsEncumbrances}

                    </TableCell>
                    <TableCell>
                      <TextareaAutosize
                        className={classes.textArea}
                        rowsMin={1}
                        rowsMax={2}
                        placeholder=''
                        {...bind('otherMattersRegisteredAsEncumbrances')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'otherMattersRegisteredAsEncumbrances', form1, keyBooleanMap, setKeyBooleanMap);
                        }}
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('otherMattersRegisteredAsEncumbrances')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>

                  {/*----------- Date of obtaining the information of section B1 ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell >
                      {langForm1.dateOfObtainingInfo}

                    </TableCell>
                    <TableCell data-key={'field-form1-dateOfObtainingB1'}>
                      <KeyboardDatePicker autoOk={true}
                        className={classes.datePicker}
                        //disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        format={DISPLAY_DATE_FORMAT}
                        margin="dense"
                        maxDate={new Date()}
                        value={form1.values.dateOfObtainingB1 ? moment(form1.values.dateOfObtainingB1) : null}
                        onChange={(value) => {
                          form1.updateValues('dateOfObtainingB1', value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined);
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        maxDateMessage={langForm1.msgSelectionValidDate}
                      />
                      {form1.hasError('dateOfObtainingB1') ? <FormHelperText style={{ color: form1.hasError('dateOfObtainingB1') ? errorColor : 'inherit' }}>{form1.hasError('dateOfObtainingB1')}</FormHelperText> : null}


                    </TableCell>
                  </TableRow>


                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          {/* ------------------------- B2 ----------------------------- */}
          <Grid
            item
            md={12}
            xs={12}
          >
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="B2 table">
                <TableHead data-key={'field-form1-partB2_option'}>
                  <TableRow>
                    <TableCell className={classes.column0}>2.</TableCell>
                    <TableCell colSpan={2} align="left">{langForm1.titlePartB2}</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {/*----------- Source of the Floor area of the Property  ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell colSpan={2}>
                      <FormControl component="fieldset">
                        {/* <FormLabel component="legend">Gender</FormLabel> */}
                        <RadioGroup
                          value={form1.values.partB2_option ?? ''}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const val = (event.target as HTMLInputElement).value;
                            form1.updateValues('saleableAreaByDepartment', undefined);
                            form1.updateValues('saleableAreaFirstAssignment', undefined);
                            form1.updateValues('partB2_option', val);
                          }}
                        >
                          <div>
                            <FormControlLabel
                              value="optionA"
                              control={<Radio />}
                              label={'(a) ' + langForm1.B2_optionA + ':'}
                            />
                            <TextField
                              style={{}}
                              className={classes.datePicker}
                              margin="dense"
                              name="headingChi"
                              variant="outlined"
                              disabled={form1.values.partB2_option !== 'optionA'}
                              {...bind('saleableAreaByDepartment')}
                              onChange={(e) => {
                                limitTextFieldLength(e, 255, 'saleableAreaByDepartment', form1, keyBooleanMap, setKeyBooleanMap);
                              }}
                            />
                            <FormHelperText>{keyBooleanMap.get('saleableAreaByDepartment') && form1.values.partB2_option === 'optionA' && isNonEmpty(form1.values.saleableAreaByDepartment) ? lang.textOverLimit : ''}</FormHelperText>
                            <Typography></Typography>
                          </div>
                          <div>
                            <FormControlLabel
                              value="optionB"
                              control={<Radio />}
                              label={'(b) ' + langForm1.B2_optionB + ':'}
                            />
                            <TextField
                              style={{}}
                              className={classes.datePicker}
                              margin="dense"
                              name="headingChi"
                              variant="outlined"
                              disabled={form1.values.partB2_option !== 'optionB'}
                              {...bind('saleableAreaFirstAssignment')}
                              onChange={(e) => {
                                limitTextFieldLength(e, 255, 'saleableAreaFirstAssignment', form1, keyBooleanMap, setKeyBooleanMap);
                              }}
                            />
                            <FormHelperText>{keyBooleanMap.get('saleableAreaFirstAssignment') && form1.values.partB2_option === 'optionB' && isNonEmpty(form1.values.saleableAreaFirstAssignment) ? lang.textOverLimit : ''}</FormHelperText>
                            <HandleFormHelperText
                              isError={keyBooleanMap.get('landlordName') && form1.values.partB2_option === 'optionB' && isNonEmpty(form1.values.saleableAreaFirstAssignment)}
                              errorMessage={lang.textOverLimit}
                            />

                          </div>
                          <FormControlLabel
                            value="optionC"
                            control={<Radio />}
                            label={langForm1.B2_optionC}
                          />
                          <FormControlLabel value="" control={<Radio size="small" />} label='' style={{ display: 'none' }} />
                        </RadioGroup>
                      </FormControl>
                      {form1.hasError('partB2_option') ? <FormHelperText style={{ color: form1.hasError('partB2_option') ? errorColor : 'inherit' }}>{langForm1.pleaseSelect}</FormHelperText> : null}
                    </TableCell>
                  </TableRow>


                  {/*----------- Date of obtaining the information of section B2 ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell className={classes.partB_column1}>
                      {langForm1.dateOfObtainingInfo}

                    </TableCell>
                    <TableCell data-key={'field-form1-dateOfObtainingB2'}>
                      <KeyboardDatePicker autoOk={true}
                        className={classes.datePicker}
                        // InputProps={InputGroup.leftItem()}
                        //disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        format={DISPLAY_DATE_FORMAT}
                        // label={langForm1.dateOfObtainingInfo}
                        margin="dense"
                        maxDate={new Date()}
                        value={form1.values.dateOfObtainingB2 ? moment(form1.values.dateOfObtainingB2) : null}
                        onChange={(value) => {
                          form1.updateValues('dateOfObtainingB2', value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined);
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        maxDateMessage={langForm1.msgSelectionValidDate}
                      />
                      {form1.hasError('dateOfObtainingB2') ? <FormHelperText style={{ color: form1.hasError('dateOfObtainingB2') ? errorColor : 'inherit' }}>{form1.hasError('dateOfObtainingB2')}</FormHelperText> : null}

                    </TableCell>
                  </TableRow>

                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          {/* ------------------------- B3 ----------------------------- */}
          <Grid
            item
            md={12}
            xs={12}
          >
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="B3 table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.column0}>3.</TableCell>
                    <TableCell colSpan={8} align="left">{langForm1.titlePartB3}</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {/*----------- year of completion ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell style={{ width: '30%' }} colSpan={2}>
                      {langForm1.B3_description}

                    </TableCell>
                    <TableCell colSpan={2} data-key={'field-form1-yearOfCompletion'}>
                      <Checkbox
                        checked={form1.values.hasYearOfCompletion}
                        onChange={() => {
                          form1.updateValues('hasYearOfCompletion', !form1.values.hasYearOfCompletion);
                          if (form1.values.hasYearOfCompletion) {
                            form1.updateValues('yearOfCompletion', "");
                            form1.updateValues('sourceOfB3', undefined);
                          }
                        }}
                      />
                      <KeyboardDatePicker autoOk={true}
                        className={classes.datePicker}
                        //disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        format="YYYY"
                        disabled={form1.values.hasYearOfCompletion ? false : true}
                        margin="dense"
                        openTo={"year"}
                        views={['year']}
                        value={form1.values.yearOfCompletion && form1.values.hasYearOfCompletion ? moment(form1.values.yearOfCompletion) : null}
                        onChange={(_, value) => {
                          form1.setValues({
                            ...form1.values,
                            yearOfCompletion: value ?? undefined
                          });
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change year',
                        }}
                      />
                      {form1.hasError('yearOfCompletion') ? <FormHelperText style={{ color: form1.hasError('yearOfCompletion') ? errorColor : 'inherit' }}>{langForm1.pleaseInput + langForm1.titlePartB3}</FormHelperText> : null}

                    </TableCell>
                  </TableRow>

                  {/*----------- source of year of completion ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell>
                      {langForm1.source}

                    </TableCell>
                    <TableCell colSpan={4}>
                      <TextField
                        className={classes.datePicker}
                        margin="dense"
                        // required
                        select
                        disabled={form1.values.hasYearOfCompletion ? false : true}
                        variant="outlined"
                        {...bind('sourceOfB3')}
                      >
                        {
                          Object.keys(form1SourceOptions).map((key) =>
                            <MenuItem key={key} value={key}>{form1SourceOptions[key]}</MenuItem>)
                        }

                      </TextField>
                    </TableCell>
                  </TableRow>

                  {/*----------- Date of obtaining the information of section B3 ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell className={classes.partB_column1}>
                      {langForm1.dateOfObtainingInfo}

                    </TableCell>
                    <TableCell colSpan={2} data-key={'field-form1-dateOfObtainingB3'}>
                      <KeyboardDatePicker autoOk={true}
                        className={classes.datePicker}
                        //disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        format={DISPLAY_DATE_FORMAT}
                        margin="dense"
                        maxDate={new Date()}
                        value={form1.values.dateOfObtainingB3 ? moment(form1.values.dateOfObtainingB3) : null}
                        onChange={(value) => {
                          form1.updateValues('dateOfObtainingB3', value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined);
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        maxDateMessage={langForm1.msgSelectionValidDate}
                      />
                      {form1.hasError('dateOfObtainingB3') ? <FormHelperText style={{ color: form1.hasError('dateOfObtainingB3') ? errorColor : 'inherit' }}>{form1.hasError('dateOfObtainingB3')}</FormHelperText> : null}

                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

          </Grid>

          {/* ------------------------- B4 ----------------------------- */}
          <Grid
            item
            md={12}
            xs={12}
          >
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="B4 table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.column0}>4.</TableCell>
                    <TableCell colSpan={2} align="left">{langForm1.titlePartB4}</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {/*----------- user restrictions ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell className={classes.partB_column1}>
                      {langForm1.B4_description}

                    </TableCell>
                    <TableCell>
                      <Checkbox
                        style={{ marginBottom: "23px" }}
                        checked={form1.values.hasUserRestrictions}
                        onChange={() => {
                          form1.updateValues('hasUserRestrictions', !form1.values.hasUserRestrictions);
                          if (form1.values.hasUserRestrictions) {
                            form1.updateValues('userRestrictions', undefined);
                            form1.updateValues('sourceOfB4', undefined);
                          }
                        }}
                      />
                      <TextareaAutosize
                        className={classes.textArea}
                        style={{ width: '60%', marginTop: "20px" }}
                        rowsMin={1}
                        rowsMax={2}
                        disabled={form1.values.hasUserRestrictions ? false : true}
                        placeholder=''
                        {...bind('userRestrictions')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'userRestrictions', form1, keyBooleanMap, setKeyBooleanMap);
                        }}
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('userRestrictions')}
                        errorMessage={lang.textOverLimit}
                      />
                      {form1.hasError('userRestrictions') ? <FormHelperText style={{ color: form1.hasError('userRestrictions') ? errorColor : 'inherit' }}>{langForm1.pleaseInput + langForm1.B4_description}</FormHelperText> : null}

                    </TableCell>
                  </TableRow>

                  {/*----------- source of user restriction info ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell>
                      {langForm1.source}

                    </TableCell>
                    <TableCell>
                      <TextField
                        className={classes.datePicker}
                        margin="dense"
                        select
                        disabled={form1.values.hasUserRestrictions ? false : true}
                        variant="outlined"
                        {...bind('sourceOfB4')}
                      >
                        {
                          Object.keys(form1SourceOptions).filter((key) => key == 'A' || key == 'C').map((key) =>
                            <MenuItem key={key} value={key}>{form1SourceOptions[key]}</MenuItem>)
                        }

                      </TextField>
                    </TableCell>
                  </TableRow>

                  {/*----------- Date of obtaining the information of section B4 ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell >
                      {langForm1.dateOfObtainingInfo}

                    </TableCell>
                    <TableCell data-key={'field-form1-dateOfObtainingB4'}>
                      <KeyboardDatePicker autoOk={true}
                        className={classes.datePicker}
                        //disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        format={DISPLAY_DATE_FORMAT}
                        margin="dense"
                        maxDate={new Date()}
                        value={form1.values.dateOfObtainingB4 ? moment(form1.values.dateOfObtainingB4) : null}
                        onChange={(value) => {
                          form1.updateValues('dateOfObtainingB4', value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined);
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        maxDateMessage={langForm1.msgSelectionValidDate}
                      />
                      {form1.hasError('dateOfObtainingB4') ? <FormHelperText style={{ color: form1.hasError('dateOfObtainingB4') ? errorColor : 'inherit' }}>{form1.hasError('dateOfObtainingB4')}</FormHelperText> : null}

                    </TableCell>
                  </TableRow>

                </TableBody>
              </Table>
            </TableContainer>

          </Grid>

          {/* ------------------------- B5 ----------------------------- */}
          <Grid
            item
            md={12}
            xs={12}
          >
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="B5 table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.column0}>5.</TableCell>
                    <TableCell colSpan={2} align="left">{langForm1.titlePartB5}</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {/*----------- unexpired term of government lease ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell className={classes.partB_column1}>
                      {langForm1.unexpiredTermOfGovLease}

                    </TableCell>
                    <TableCell>
                      <TextField
                        className={classes.datePicker}
                        margin="dense"
                        name="headingChi"
                        variant="outlined"
                        {...bind('unexpiredTermOfLease')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'unexpiredTermOfLease', form1, keyBooleanMap, setKeyBooleanMap);
                        }}
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('unexpiredTermOfLease')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>

                  {/*----------- right of renewal ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell className={classes.partB_column1}>
                      {langForm1.rightOfRenewal}

                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={form1.values.hasRightOfRenewal}
                        onChange={() => {
                          form1.updateValues('hasRightOfRenewal', !form1.values.hasRightOfRenewal);
                        }}
                      />
                    </TableCell>
                  </TableRow>

                  {/*----------- Date of obtaining the information of section B5 ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell >
                      {langForm1.dateOfObtainingInfo}

                    </TableCell>
                    <TableCell data-key={'field-form1-dateOfObtainingB5'}>
                      <KeyboardDatePicker autoOk={true}
                        className={classes.datePicker}
                        //disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        format={DISPLAY_DATE_FORMAT}
                        margin="dense"
                        maxDate={new Date()}
                        value={form1.values.dateOfObtainingB5 ? moment(form1.values.dateOfObtainingB5) : null}
                        onChange={(value) => {
                          form1.updateValues('dateOfObtainingB5', value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined);
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        maxDateMessage={langForm1.msgSelectionValidDate}
                      />
                      {form1.hasError('dateOfObtainingB5') ? <FormHelperText style={{ color: form1.hasError('dateOfObtainingB5') ? errorColor : 'inherit' }}>{form1.hasError('dateOfObtainingB5')}</FormHelperText> : null}

                    </TableCell>
                  </TableRow>


                </TableBody>
              </Table>
            </TableContainer>

          </Grid>

          {/* ------------------------- B6 ----------------------------- */}
          <Grid
            item
            md={12}
            xs={12}
          >
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="B6 table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.column0}>6.</TableCell>
                    <TableCell colSpan={2} align="left">{langForm1.titlePartB6}</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {/*----------- term of proposed lease ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell className={classes.partB_column1}>
                      {langForm1.proposedLease}

                    </TableCell>
                    <TableCell>
                      <TextField
                        className={classes.datePicker}
                        margin="dense"
                        required
                        variant="outlined"
                        {...bind('termOfProposedLease')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'termOfProposedLease', form1, keyBooleanMap, setKeyBooleanMap);
                        }}
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('termOfProposedLease')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>

                  {/*----------- Date of obtaining the information of section B5 ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell >
                      {langForm1.dateOfObtainingInfo}

                    </TableCell>
                    <TableCell data-key={'field-form1-dateOfObtainingB6'}>
                      <KeyboardDatePicker autoOk={true}
                        className={classes.datePicker}
                        //disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        format={DISPLAY_DATE_FORMAT}
                        margin="dense"
                        maxDate={new Date()}
                        value={form1.values.dateOfObtainingB6 ? moment(form1.values.dateOfObtainingB6) : null}
                        onChange={(value) => {
                          form1.updateValues('dateOfObtainingB6', value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined);
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        maxDateMessage={langForm1.msgSelectionValidDate}
                      />
                      {form1.hasError('dateOfObtainingB6') ? <FormHelperText style={{ color: form1.hasError('dateOfObtainingB6') ? errorColor : 'inherit' }}>{form1.hasError('dateOfObtainingB6')}</FormHelperText> : null}
                    </TableCell>
                  </TableRow>

                </TableBody>
              </Table>
            </TableContainer>


          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

        </Grid>

        {/* -------------------------------- Part C -----------------------------*/}

        <Grid container xs={12} spacing={1} className={classes.longTableContainer}>
          <Grid
            item
            md={12}
            xs={12}
          >
            <Typography className={classes.partTitle}>{langForm1.titlePartC}</Typography>

          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >

            <Table className={classes.table} size="small" aria-label="Part C table">
              <TableHead>
                <TableRow style={{ display: 'none' }}>
                  <TableCell className={classes.column0}></TableCell>
                  <TableCell align="left" className={classes.partC_column1}></TableCell>
                  <TableCell align="left" ></TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {/*----------- 1. The annual Government rent ---------- */}
                <TableRow >
                  <TableCell className={classes.column0} component="th" scope="row">1</TableCell>
                  <TableCell align="left" className={classes.partC_column1} data-key={'field-form1-annualGovRent'}>
                    {langForm1.annualGovRent}
                  </TableCell>
                  <TableCell align="left">
                    <NumberField
                      className={classes.numberFields}
                      margin="dense"
                      variant="outlined"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{langForm1.HKDollar}</InputAdornment>
                      }}
                      {...bind('annualGovRent')}
                      onChange={(e) => {
                        limitNumberFieldLength(e, 'annualGovRent', form1);
                      }}
                    />
                  </TableCell>
                </TableRow>

                {/*----------- 2. The quarterly rates ---------- */}
                <TableRow >
                  <TableCell className={classes.column0} component="th" scope="row">2</TableCell>
                  <TableCell align="left" className={classes.partC_column1} data-key={'field-form1-quarterlyRates'}>
                    {langForm1.quarterlyRates}
                  </TableCell>
                  <TableCell align="left">
                    <NumberField
                      className={classes.numberFields}
                      margin="dense"
                      variant="outlined"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{langForm1.HKDollar}</InputAdornment>
                      }}
                      {...bind('quarterlyRates')}
                      onChange={(e) => {
                        limitNumberFieldLength(e, 'quarterlyRates', form1);
                      }}
                    />
                  </TableCell>
                </TableRow>

                {/*----------- 3. The monthly management fee ---------- */}
                <TableRow >
                  <TableCell className={classes.column0} component="th" scope="row">3</TableCell>
                  <TableCell align="left" className={classes.partC_column1} data-key={'field-form1-monthlyManagementFee'}>
                    {langForm1.monthlyManagementFee}
                  </TableCell>
                  <TableCell align="left">
                    <NumberField
                      className={classes.numberFields}
                      margin="dense"
                      variant="outlined"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{langForm1.HKDollar}</InputAdornment>
                      }}
                      {...bind('monthlyManagementFee')}
                      onChange={(e) => {
                        limitNumberFieldLength(e, 'monthlyManagementFee', form1);
                      }}
                    />
                  </TableCell>
                </TableRow>

                {/*----------- 4. Owners incorporation established ---------- */}
                <TableRow >
                  <TableCell className={classes.column0} component="th" scope="row">4</TableCell>
                  <TableCell align="left" className={classes.partC_column1}>
                    {langForm1.ownersIncorporationEstablished}
                  </TableCell>
                  <TableCell align="left">
                    <Checkbox
                      checked={form1.values.ownersIncorporationEstablished}
                      onChange={() => {
                        form1.updateValues('ownersIncorporationEstablished', !form1.values.ownersIncorporationEstablished);
                      }}
                    />
                  </TableCell>
                </TableRow>

                {/*----------- 5. The floor area and source of information ---------- */}
                <TableRow >
                  <TableCell className={classes.column0} component="th" scope="row">5</TableCell>
                  <TableCell align="left" className={classes.partC_column1}>
                    {langForm1.floorAreaAndSourceOfInfo}
                  </TableCell>
                  <TableCell align="left">
                    <TextField
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      {...bind('floorAreaAndSourceOfInfo')}
                      onChange={(e) => {
                        limitTextFieldLength(e, 255, 'floorAreaAndSourceOfInfo', form1, keyBooleanMap, setKeyBooleanMap);
                      }}
                    />
                    <HandleFormHelperText
                      isError={keyBooleanMap.get('floorAreaAndSourceOfInfo')}
                      errorMessage={lang.textOverLimit}
                    />
                  </TableCell>
                </TableRow>

                {/*----------- 6. Property Categories ---------- */}
                <TableRow >
                  <TableCell className={classes.column0} component="th" scope="row">6</TableCell>
                  <TableCell align="left" className={classes.partC_column1} data-key={'field-form1-propertyCategories'}>
                    {langForm1.propertyCategoriesSelect}
                    {form1.hasError('propertyCategories') ? <FormHelperText style={{ color: form1.hasError('propertyCategories') ? errorColor : 'inherit' }}>{langForm1.msgSelectAtLeastOneForPart6}</FormHelperText> : null}

                  </TableCell>
                  <TableCell align="left">
                    <FormControl component="fieldset" >
                      <RadioGroup aria-label="Property Categories" name="customized-radios"
                        value={form1.values.propertyCategories ?? ''}
                        onClick={(e) => {
                          changePropertyCategories((e.target as HTMLInputElement).value);
                        }}
                      >
                        <FormControlLabel
                          value="tenantPurchaseScheme"
                          control={<Radio />}
                          label={langForm1.tenantPurchaseScheme}
                        />
                        <FormControlLabel
                          value="homeOwnershipScheme"
                          control={<Radio />}
                          label={langForm1.homeOwnershipScheme}
                        />
                        <FormControlLabel
                          value="privateSectorParticipationScheme"
                          control={<Radio />}
                          label={langForm1.privateSectorParticipationScheme}
                        />
                        <FormControlLabel
                          value="sandwichClassHousing"
                          control={<Radio />}
                          label={langForm1.sandwichClassHousing}
                        />
                        <FormControlLabel
                          value="flatForSaleScheme"
                          control={<Radio />}
                          label={langForm1.flatForSaleScheme}
                        />
                        <FormControlLabel
                          control={<Typography />}
                          label={langForm1.propertyCategoriesRemarks} />
                        <FormControlLabel
                          value="villageTypeHouse"
                          control={<Radio />}
                          label={langForm1.villageTypeHouse}
                        />
                        <FormControlLabel
                          value="others"
                          control={<Radio />}
                          label={langForm1.others}
                        />
                        <FormControlLabel value="" control={<Radio size="small" />} label='' style={{ display: 'none' }} />
                      </RadioGroup>
                    </FormControl>

                    <TextareaAutosize
                      className={classes.textArea}
                      rowsMin={2}
                      rowsMax={2}
                      placeholder=''
                      disabled={form1.values.propertyCategories !== 'others'}
                      {...bind('descriptionForOthers')}
                      onChange={(e) => {
                        limitTextFieldLength(e, 255, 'descriptionForOthers', form1, keyBooleanMap, setKeyBooleanMap);
                      }}
                    />
                    <HandleFormHelperText
                      isError={keyBooleanMap.get('descriptionForOthers')}
                      errorMessage={lang.textOverLimit}
                    />
                    {form1.hasError('descriptionForOthers') ? <FormHelperText style={{ color: form1.hasError('descriptionForOthers') ? errorColor : 'inherit' }}>{langForm1.msgInputSelectedFields}</FormHelperText> : null}
                  </TableCell>
                </TableRow>


              </TableBody>
            </Table>

          </Grid>

          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>


        </Grid>

        {/*---------------------------------  Part 2 ------------------------------*/}


        <Grid container xs={12} spacing={1} className={classes.longTableContainer}>
          <Grid
            item
            md={12}
            xs={12}
          >
            <Typography style={{ fontSize: '16px', fontWeight: 'bold', padding: '8px' }}>{langForm1.titlePart2}</Typography>
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >
            <Table className={classes.table} size="small" aria-label="Part 2 table">
              <TableHead>
                <TableRow style={{ display: 'none' }}>
                  <TableCell className={classes.column0}></TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/*----------- 1. Structural Additions or Alterations (during period of ownership) ---------- */}
                <TableRow >
                  <TableCell className={classes.column0} component="th" scope="row">1</TableCell>

                  <TableCell className={classes.part2_column1}>
                    <TextField
                      margin="dense"
                      select
                      variant="outlined"
                      data-key={'field-form1-part2_statement1'}
                      // {...bind('part2_statement1')}
                      value={form1.values.part2_statement1}
                      onChange={(e) => {
                        if (form1.values.part2_statement1 === 'Y') {
                          form1.updateValues('part2_statement1_particular', undefined);
                        }
                        form1.updateValues('part2_statement1', e.target.value);
                      }}
                    >
                      {
                        Object.keys(yesNoOptions).filter((key) => key == 'Y' || key == 'N').map((key) =>
                          <MenuItem key={key} value={key}>{yesNoOptions[key]}</MenuItem>)
                      }

                    </TextField>
                    {form1.hasError('part2_statement1') ? <FormHelperText style={{ color: form1.hasError('part2_statement1') ? errorColor : 'inherit' }}>{langForm1.pleaseSelect}</FormHelperText> : null}
                  </TableCell>
                  <TableCell>

                    <Typography className={classes.part2_description}>{langForm1.part2_1_description}</Typography>
                    <div className={classes.part2_textAreaDiv}>
                      <TextareaAutosize
                        className={classes.textArea}
                        rowsMin={2}
                        rowsMax={2}
                        placeholder={langForm1.helperTextParticulars}
                        disabled={form1.values.part2_statement1 !== 'Y'}
                        {...bind('part2_statement1_particular')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'part2_statement1_particular', form1, keyBooleanMap, setKeyBooleanMap);
                        }}
                      />
                      {form1.hasError('part2_statement1_particular') ? <FormHelperText style={{ color: form1.hasError('part2_statement1_particular') ? errorColor : 'inherit' }}>{langForm1.pleaseInput + langForm1.particulars}</FormHelperText> : null}
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('part2_statement1_particular')}
                        errorMessage={lang.textOverLimit}
                      />
                    </div>
                  </TableCell>
                </TableRow>

                {/*----------- 2. Structural Additions or Alterations (before period of ownership) ---------- */}
                <TableRow >
                  <TableCell className={classes.column0} component="th" scope="row">2</TableCell>

                  <TableCell className={classes.part2_column1}>
                    <TextField
                      margin="dense"
                      // required
                      data-key={'field-form1-part2_statement2'}
                      select
                      variant="outlined"
                      // {...bind('part2_statement2')}
                      value={form1.values.part2_statement2}
                      onChange={(e) => {
                        if (form1.values.part2_statement2 === 'Y') {
                          form1.updateValues('part2_statement2_particular', undefined);
                        }
                        form1.updateValues('part2_statement2', e.target.value);
                      }}
                    >
                      {
                        Object.keys(yesNoOptions).map((key) =>
                          <MenuItem key={key} value={key}>{yesNoOptions[key]}</MenuItem>)
                      }

                    </TextField>
                    {form1.hasError('part2_statement2') ? <FormHelperText style={{ color: form1.hasError('part2_statement2') ? errorColor : 'inherit' }}>{langForm1.pleaseSelect}</FormHelperText> : null}
                  </TableCell>
                  <TableCell >

                    <Typography className={classes.part2_description}>{langForm1.part2_2_description}</Typography>
                    <div className={classes.part2_textAreaDiv}>
                      <TextareaAutosize
                        className={classes.textArea}
                        rowsMin={2}
                        rowsMax={2}
                        placeholder={langForm1.helperTextParticulars}
                        disabled={form1.values.part2_statement2 !== 'Y'}
                        {...bind('part2_statement2_particular')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'part2_statement2_particular', form1, keyBooleanMap, setKeyBooleanMap);
                        }}
                      />
                      {form1.hasError('part2_statement2_particular') ? <FormHelperText style={{ color: form1.hasError('part2_statement2_particular') ? errorColor : 'inherit' }}>{langForm1.pleaseInput + langForm1.particulars}</FormHelperText> : null}
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('part2_statement2_particular')}
                        errorMessage={lang.textOverLimit}
                      />

                    </div>
                  </TableCell>
                </TableRow>

                {/*----------- 3. Reinstatements, Rectification, Repairs or Improvements (during period of ownership) ---------- */}
                <TableRow >
                  <TableCell className={classes.column0} component="th" scope="row">3</TableCell>

                  <TableCell className={classes.part2_column1}>
                    <TextField
                      margin="dense"
                      // required
                      data-key={'field-form1-part2_statement3'}
                      select
                      variant="outlined"
                      // {...bind('part2_statement3')}
                      value={form1.values.part2_statement3}
                      onChange={(e) => {
                        if (form1.values.part2_statement3 === 'Y') {
                          form1.updateValues('part2_statement3_particular', undefined);
                        }
                        form1.updateValues('part2_statement3', e.target.value);
                      }}
                    >
                      {
                        Object.keys(yesNoOptions).filter((key) => key == 'Y' || key == 'N').map((key) =>
                          <MenuItem key={key} value={key}>{yesNoOptions[key]}</MenuItem>)
                      }

                    </TextField>
                    {form1.hasError('part2_statement3') ? <FormHelperText style={{ color: form1.hasError('part2_statement3') ? errorColor : 'inherit' }}>{langForm1.pleaseSelect}</FormHelperText> : null}
                  </TableCell>
                  <TableCell >

                    <Typography className={classes.part2_description}>{langForm1.part2_3_description}</Typography>
                    <div className={classes.part2_textAreaDiv}>
                      <TextareaAutosize
                        className={classes.textArea}
                        rowsMin={2}
                        rowsMax={2}
                        placeholder={langForm1.helperTextParticulars}
                        disabled={form1.values.part2_statement3 !== 'Y'}
                        {...bind('part2_statement3_particular')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'part2_statement3_particular', form1, keyBooleanMap, setKeyBooleanMap);
                        }}
                      />
                      {form1.hasError('part2_statement3_particular') ? <FormHelperText style={{ color: form1.hasError('part2_statement3_particular') ? errorColor : 'inherit' }}>{langForm1.pleaseInput + langForm1.particulars}</FormHelperText> : null}
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('part2_statement3_particular')}
                        errorMessage={lang.textOverLimit}
                      />

                    </div>
                  </TableCell>
                </TableRow>


                {/*----------- 4. Reinstatements, Rectification, Repairs or Improvements (before period of ownership) ---------- */}
                <TableRow >
                  <TableCell className={classes.column0} component="th" scope="row">4</TableCell>

                  <TableCell className={classes.part2_column1}>
                    <TextField
                      margin="dense"
                      select
                      data-key={'field-form1-part2_statement4'}
                      variant="outlined"
                      // {...bind('part2_statement4')}
                      value={form1.values.part2_statement4}
                      onChange={(e) => {
                        if (form1.values.part2_statement4 === 'Y') {
                          form1.updateValues('part2_statement4_particular', undefined);
                        }
                        form1.updateValues('part2_statement4', e.target.value);
                      }}
                    >
                      {
                        Object.keys(yesNoOptions).map((key) =>
                          <MenuItem key={key} value={key}>{yesNoOptions[key]}</MenuItem>)
                      }

                    </TextField>
                    {form1.hasError('part2_statement4') ? <FormHelperText style={{ color: form1.hasError('part2_statement4') ? errorColor : 'inherit' }}>{langForm1.pleaseSelect}</FormHelperText> : null}

                  </TableCell>
                  <TableCell>

                    <Typography className={classes.part2_description}>{langForm1.part2_4_description}</Typography>
                    <div className={classes.part2_textAreaDiv}>
                      <TextareaAutosize
                        className={classes.textArea}
                        rowsMin={2}
                        rowsMax={2}
                        placeholder={langForm1.helperTextParticulars}
                        disabled={form1.values.part2_statement4 !== 'Y'}
                        {...bind('part2_statement4_particular')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'part2_statement4_particular', form1, keyBooleanMap, setKeyBooleanMap);
                        }}
                      />
                      {form1.hasError('part2_statement4_particular') ? <FormHelperText style={{ color: form1.hasError('part2_statement4_particular') ? errorColor : 'inherit' }}>{langForm1.pleaseInput + langForm1.particulars}</FormHelperText> : null}
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('part2_statement4_particular')}
                        errorMessage={lang.textOverLimit}
                      />

                    </div>
                  </TableCell>
                </TableRow>

                {/*----------- 5. Cost of purchaser for required or proposed works ---------- */}

                <TableRow >
                  <TableCell className={classes.column0} component="th" scope="row">5</TableCell>
                  <TableCell colSpan={2} className={classes.part2_column1} data-key={'field-form1-costOfPurchaserOptions'}>
                    <Typography className={classes.part2_description}>{langForm1.part2_5_description1}</Typography>

                    <FormControl variant="outlined" margin="dense" style={{ width: '50%' }}>
                      <Select
                        multiple
                        {...tagForm.bind('costOfPurchaserOptions')}
                        renderValue={(selected) => (selected as string[]).map(key => form1CostOfPurchaserOptions[key]).join(', ')}
                      >
                        {Object.keys(form1CostOfPurchaserOptions).map(key => (
                          <MenuItem key={key} value={key} disabled={disableOption[key]} >
                            <Checkbox checked={tagForm.values.costOfPurchaserOptions!.indexOf(key) > -1} />
                            <ListItemText primary={form1CostOfPurchaserOptions[key]} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div>
                      {form1.hasError('costOfPurchaserOptions') ? <FormHelperText style={{ color: form1.hasError('costOfPurchaserOptions') ? errorColor : 'inherit' }}>{langForm1.pleaseSelect}</FormHelperText> : null}
                    </div>

                    <div >
                      <div style={{ display: 'inline-block', verticalAlign: 'middle', paddingRight: '3px' }}>
                        <div style={{ paddingTop: '25px' }}><Typography >{langForm1.part2_5_description2_v2}</Typography></div>
                      </div>
                      <div style={{ display: 'inline-block', alignItems: 'left', paddingLeft: '3px' }}>
                        <TextField
                          style={{ width: '100%', fontSize: '15px' }}
                          margin="dense"
                          // required
                          select
                          variant="outlined"
                          {...bind('costOrEstimatedCost')}
                        >
                          <MenuItem key='both' value='both'>{langForm1.both}</MenuItem>
                          <MenuItem key='costOnly' value='costOnly'>{langForm1.cost}</MenuItem>
                          <MenuItem key='estimatedCostOnly' value='estimatedCostOnly'>{langForm1.estimatedCost}</MenuItem>

                        </TextField>
                      </div>
                      <div style={{ display: 'inline-block', verticalAlign: 'middle', paddingRight: '3px' }}>
                        <div style={{ paddingTop: '25px' }}><Typography >{langForm1.part2_5_description2_v2_part2}</Typography></div>
                      </div>
                    </div>
                    {/* <div >
                      <FormControl component="fieldset" style={{}}>
                        
                        <RadioGroup aria-label="Property Categories" name="customized-radios" row>
                          <FormControlLabel value="agent" control={<Radio size="small"/>} label={langForm1.cost}/>
                          <FormControlLabel value="purchaser" control={<Radio size="small"/>} label={langForm1.estimatedCost} />
                          <FormControlLabel value="both" control={<Radio size="small"/>} label={langForm1.both}/>
                          
                        </RadioGroup>
                      </FormControl>
                    </div> */}

                    <div className={classes.part2_textAreaDiv}>
                      <TextareaAutosize
                        className={classes.textArea}
                        rowsMin={2}
                        rowsMax={2}
                        placeholder={langForm1.helperTextParticulars}
                        disabled={(form1.values.costOfPurchaserOptions?.includes('e') || form1.values.costOfPurchaserOptions?.includes('f'))}
                        {...bind('natureAndCostOfPurchaserDetail')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'natureAndCostOfPurchaserDetail', form1, keyBooleanMap, setKeyBooleanMap);
                        }}
                      />
                      {form1.hasError('natureAndCostOfPurchaserDetail') ? <FormHelperText style={{ color: form1.hasError('natureAndCostOfPurchaserDetail') ? errorColor : 'inherit' }}>{langForm1.pleaseInput + langForm1.particulars}</FormHelperText> : null}

                    </div>
                    {(form1.values.costOfPurchaserOptions?.includes('e') || form1.values.costOfPurchaserOptions?.includes('f')) ? form1.values.natureAndCostOfPurchaserDetail = undefined : null}
                    <HandleFormHelperText
                      isError={keyBooleanMap.get('natureAndCostOfPurchaserDetail')}
                      errorMessage={lang.textOverLimit}
                    />
                  </TableCell>
                </TableRow >

                {/*----------- 6. the property is to be sold with ... ---------- */}

                <TableRow >
                  <TableCell className={classes.column0} component="th" scope="row">6</TableCell>
                  <TableCell colSpan={2} className={classes.part2_column1} data-key={'field-form1-part2_statement6_option'}>
                    {form1.hasError('part2_statement6_option') ? <FormHelperText style={{ color: form1.hasError('part2_statement6_option') ? errorColor : 'inherit' }}>{langForm1.pleaseSelect}</FormHelperText> : null}

                    {/* a. To be sold with vacant possession */}
                    <FormControl component="fieldset">
                      <RadioGroup aria-label="details of property to be sold" name="customized-radios"
                        value={form1.values.part2_statement6_option ?? ''}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          const val = (event.target as HTMLInputElement).value;
                          //clear input in option B
                          if (form1.values.part2_statement6_option === 'optionB') {
                            form1.setValues({
                              ...form1.values,
                              leaseOption: '',
                              monthlyRental: undefined,
                              includeRates: false,
                              includeGovernmentRent: false,
                              includeManagementFee: false,
                              tenancyTermYear: undefined,
                              tenancyTermMonth: undefined,
                              tenancyTermDay: undefined,
                              tenancyTermStart: undefined,
                              tenancyTermEnd: undefined,
                              hasBreakClause: false,
                              hasRentReview: false,
                              hasOptionToRenew: false,
                              hasOtherSpecialTerms: false,
                              specialTermsDescription: undefined,
                            });
                          }
                          form1.updateValues('part2_statement6_option', val);
                        }}
                      >
                        <FormControlLabel
                          value="optionA" control={<Radio />}
                          label={langForm1.part2_6_optionA}
                        />


                        <div style={{}}>
                          <div style={{ display: 'inline-block' }} >
                            <FormControlLabel
                              value="optionB"
                              control={<Radio />}
                              label={langForm1.part2_6_optionB_sentence1}
                            />
                          </div>

                          <div style={{ display: 'inline-block' }} data-key={'field-form1-leaseOption'}>
                            <TextField
                              style={{ width: '100%', fontSize: '15px', minWidth: '100px', marginTop: '-9px' }}
                              margin="dense"
                              select
                              disabled={form1.values.part2_statement6_option === 'optionB' ? false : true}
                              variant="outlined"
                              {...bind('leaseOption')}
                              error={!!form1.hasError('leaseOption') && !isNonEmpty(form1.values.leaseOption)}
                              helperText={!!form1.hasError('leaseOption') && !isNonEmpty(form1.values.leaseOption) ? form1.hasError('leaseOption') : ''}
                            >
                              <MenuItem disabled key='' value=''>{langForm1.pleaseSelect}</MenuItem>
                              <MenuItem key='option1' value='option1'>{langForm1.leaseOptions1}</MenuItem>
                              <MenuItem key='option2' value='option2'>{langForm1.leaseOptions2}</MenuItem>
                              <MenuItem key='option3' value='option3'>{langForm1.leaseOptions3}</MenuItem>

                            </TextField>
                          </div>
                          <div style={{ display: 'inline-block', paddingLeft: '10px' }} >
                            <Typography >{langForm1.part2_6_optionB_sentence2}</Typography>
                          </div>
                        </div>
                        <FormControlLabel value="" control={<Radio size="small" />} label='' style={{ display: 'none' }} />
                      </RadioGroup>
                    </FormControl>
                    {/* b. To be sold subject to a lease/licence for a specific term/statutory tenancy */}
                    <div style={{ paddingLeft: '57px' }}>
                      <Table style={{ borderColor: '#FFFFFF' }} size="small">
                        <TableBody >
                          <TableRow>
                            <TableCell component="th" style={{ width: '2%' }} scope="row" className={classes.subTableCell}>{'•'}</TableCell>
                            <TableCell align="left" style={{ width: '13%' }} className={classes.subTableCell}>{langForm1.monthlyRental}</TableCell>
                            <TableCell align="left" className={classes.subTableCell}>
                              {/*  ------------------- monthly rent -------------------- */}
                              <NumberField
                                className={classes.numberFields}
                                margin="dense"
                                variant="outlined"
                                disabled={form1.values.part2_statement6_option === 'optionB' ? false : true}
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">{langForm1.HKDollar}</InputAdornment>
                                }}
                                {...bind('monthlyRental')}
                                onChange={(e) => {
                                  limitNumberFieldLength(e, 'monthlyRental', form1);
                                }}
                                onKeyPress={(e) => {
                                  if (e.key == ".") {
                                    e.preventDefault();
                                  }
                                }}
                                error={!!form1.hasError('monthlyRental') && !isNonEmpty(form1.values.monthlyRental)}
                                helperText={!!form1.hasError('monthlyRental') && !isNonEmpty(form1.values.monthlyRental) ? form1.hasError('monthlyRental') : ''}
                              />

                              <FormControl component="fieldset" style={{ paddingLeft: '10px', paddingTop: '7px' }}>
                                <FormGroup aria-label="position" row>
                                  <FormControlLabel
                                    style={{ paddingLeft: '3px' }}
                                    control={<Typography />}
                                    label={langForm1.inclusiveOf} />
                                  <FormControlLabel
                                    value="end"
                                    control={<Checkbox color="primary" />}
                                    label={langForm1.rates}
                                    labelPlacement="end"
                                    disabled={form1.values.part2_statement6_option === 'optionB' ? false : true}
                                    checked={form1.values.includeRates}
                                    onChange={() => {
                                      form1.updateValues('includeRates', !form1.values.includeRates);
                                    }}
                                  />
                                  <FormControlLabel
                                    value="end"
                                    control={<Checkbox color="primary" />}
                                    label={langForm1.governmentRent}
                                    labelPlacement="end"
                                    disabled={form1.values.part2_statement6_option === 'optionB' ? false : true}
                                    checked={form1.values.includeGovernmentRent}
                                    onChange={() => {
                                      form1.updateValues('includeGovernmentRent', !form1.values.includeGovernmentRent);
                                    }}
                                  />
                                  <FormControlLabel
                                    value="end"
                                    control={<Checkbox color="primary" />}
                                    label={langForm1.managementFee}
                                    labelPlacement="end"
                                    disabled={form1.values.part2_statement6_option === 'optionB' ? false : true}
                                    checked={form1.values.includeManagementFee}
                                    onChange={() => {
                                      form1.updateValues('includeManagementFee', !form1.values.includeManagementFee);
                                    }}
                                  />
                                </FormGroup>
                              </FormControl>

                            </TableCell>
                          </TableRow>
                          {/* ------------------------ tenancy Term ------------------*/}
                          <TableRow>
                            <TableCell component="th" style={{ width: '2%' }} scope="row" className={classes.subTableCell}>{'•'}</TableCell>
                            <TableCell align="left" className={classes.subTableCell}>{langForm1.tenancyTerm + langForm1.is}</TableCell>
                            <TableCell align="left" className={classes.subTableCell}>
                              <Grid item container md={6} sm={9} xs={5} spacing={1}>
                                <Grid
                                  item
                                  md={3}
                                  sm={6}
                                  xs={10}
                                >
                                  <TextField
                                    //style={{width: '15%', textAlign: 'right'}}
                                    margin="dense"
                                    fullWidth
                                    style={{ minWidth: 100 }}
                                    variant="outlined"
                                    disabled={true}
                                    InputProps={{
                                      endAdornment: <InputAdornment position="start">&ensp;{langForm1.year}</InputAdornment>,
                                    }}
                                    error={!!form1.hasError('tenancyTermYear') && !form1.values.tenancyTermYear}
                                    helperText={!!form1.hasError('tenancyTermYear') && !form1.values.tenancyTermYear ? form1.hasError('tenancyTermYear') : ''}
                                    {...bind('tenancyTermYear')}
                                  />
                                  {'  '}
                                  {/* <TextField
                                  style={{width: '15%'}}
                                  margin="dense"
                                  name="headingChi"
                                  label={langForm1.month}
                                  variant="outlined"
                                  {...bind('tenancyTermMonth')}
                                /> */}
                                  {/* <p>this is the {tenancyTermEnd}</p> */}
                                </Grid>
                                <Grid
                                  item
                                  md={3}
                                  sm={6}
                                  xs={10}
                                >
                                  <TextField
                                    //style={{width: '15%', textAlign: 'right'}}
                                    margin="dense"
                                    variant="outlined"
                                    style={{ minWidth: 100 }}
                                    disabled={true}
                                    InputProps={{
                                      endAdornment: <InputAdornment position="end">&ensp;{langForm1.month}</InputAdornment>,
                                    }}
                                    error={!!form1.hasError('tenancyTermMonth') && !form1.values.tenancyTermMonth}
                                    helperText={!!form1.hasError('tenancyTermMonth') && !form1.values.tenancyTermMonth ? form1.hasError('tenancyTermMonth') : ''}
                                    {...bind('tenancyTermMonth')}
                                  />

                                  {'  '}

                                </Grid>
                                <Grid
                                  item
                                  md={3}
                                  sm={6}
                                  xs={10}
                                >
                                  <TextField
                                    //style={{width: '15%', textAlign: 'right'}}
                                    margin="dense"
                                    variant="outlined"
                                    disabled={true}
                                    style={{ minWidth: 100 }}
                                    InputProps={{
                                      endAdornment: <InputAdornment position="start">&ensp;{langForm1.day}</InputAdornment>,
                                    }}
                                    error={!!form1.hasError('tenancyTermDay') && !form1.values.tenancyTermDay}
                                    helperText={!!form1.hasError('tenancyTermDay') && !form1.values.tenancyTermDay ? form1.hasError('tenancyTermDay') : ''}
                                    {...bind('tenancyTermDay')}
                                  />
                                </Grid>
                              </Grid>
                            </TableCell>

                          </TableRow>

                          <TableRow>
                            <TableCell component="th" style={{ width: '2%' }} scope="row" className={classes.subTableCell}></TableCell>
                            <TableCell align="left" className={classes.subTableCell} data-key={'field-form1-tenancyTermStart'}></TableCell>
                            <TableCell align="left" className={classes.subTableCell} data-key={'field-form1-tenancyTermEnd'}>
                              {/* <div> */}
                              <Grid item container md={12} sm={9} xs={5} spacing={1}>
                                <Grid
                                  item
                                  md={12}
                                  sm={6}
                                  xs={10}
                                >
                                  <KeyboardDatePicker autoOk={true}
                                    className={classes.datePicker}
                                    //disableToolbar
                                    disabled={form1.values.part2_statement6_option === 'optionB' ? false : true}
                                    style={{ minWidth: 180 }}
                                    variant="inline"
                                    inputVariant="outlined"
                                    format={DISPLAY_DATE_FORMAT}
                                    label={langForm1.dateFrom}
                                    margin="dense"
                                    value={form1.values.tenancyTermStart ? moment(form1.values.tenancyTermStart) : null}
                                    onChange={(value) => {
                                      form1.updateValues('tenancyTermStart', value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined);
                                      handletenancy(value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined, form1.values.tenancyTermEnd);
                                      if (value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString()?.localeCompare(form1.values.tenancyTermEnd ?? "") === 1) {
                                        form1.updateValues('tenancyTermEnd', "");
                                      }
                                    }}
                                    error={!!form1.hasError('tenancyTermStart') && !isNonEmpty(form1.values.tenancyTermStart)}
                                    helperText={!!form1.hasError('tenancyTermStart') && !isNonEmpty(form1.values.tenancyTermStart) ? form1.hasError('tenancyTermStart') : ''}
                                    KeyboardButtonProps={{
                                      'aria-label': 'change date',
                                    }}
                                  />
                                  <KeyboardDatePicker autoOk={true}
                                    className={classes.datePicker}
                                    // InputProps={InputGroup.leftItem()}
                                    //disableToolbar
                                    disabled={form1.values.part2_statement6_option === 'optionB' ? false : true}
                                    style={{ minWidth: 180 }}
                                    variant="inline"
                                    inputVariant="outlined"
                                    format={DISPLAY_DATE_FORMAT}
                                    label={langForm1.dateTo}
                                    margin="dense"
                                    shouldDisableDate={disablePrevDates(form1.values.tenancyTermStart)}
                                    value={form1.values.tenancyTermEnd ? moment(form1.values.tenancyTermEnd) : null}
                                    onChange={(value) => {
                                      form1.updateValues('tenancyTermEnd', value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined);
                                      handletenancy(form1.values.tenancyTermStart, value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined);
                                      if (value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString()?.localeCompare(form1.values.tenancyTermStart ?? "") === -1) {
                                        form1.updateValues('tenancyTermStart', "");
                                      }
                                    }}
                                    error={!!form1.hasError('tenancyTermEnd') && !isNonEmpty(form1.values.tenancyTermEnd)}
                                    helperText={!!form1.hasError('tenancyTermEnd') && !isNonEmpty(form1.values.tenancyTermEnd) ? form1.hasError('tenancyTermEnd') : ''}
                                    KeyboardButtonProps={{
                                      'aria-label': 'change date',
                                    }}
                                  />
                                  {' ' + langForm1.tenancyTermRemarks}
                                </Grid>
                              </Grid>
                              {/* </div>                                */}
                            </TableCell>
                          </TableRow>

                          {/* ---------------------------- other terms ---------------------- */}
                          <TableRow>
                            <TableCell component="th" style={{ width: '2%' }} scope="row" className={classes.subTableCell}>{'•'}</TableCell>
                            <TableCell colSpan={2} align="left" className={classes.subTableCell}>
                              <Typography>{langForm1.otherTerms}</Typography>

                              <FormControl component="fieldset" style={{ paddingLeft: '0px', paddingTop: '7px' }}>
                                <FormGroup aria-label="position" row>
                                  <FormControlLabel
                                    value="end"
                                    control={<Checkbox color="primary" />}
                                    label={langForm1.breakClause}
                                    labelPlacement="end"
                                    disabled={form1.values.part2_statement6_option === 'optionB' ? false : true}
                                    checked={form1.values.hasBreakClause}
                                    onChange={() => {
                                      form1.updateValues('hasBreakClause', !form1.values.hasBreakClause);
                                    }}
                                  />
                                  <FormControlLabel
                                    value="end"
                                    control={<Checkbox color="primary" />}
                                    label={langForm1.rentalReview}
                                    labelPlacement="end"
                                    disabled={form1.values.part2_statement6_option === 'optionB' ? false : true}
                                    checked={form1.values.hasRentReview}
                                    onChange={() => {
                                      form1.updateValues('hasRentReview', !form1.values.hasRentReview);
                                    }}
                                  />
                                  <FormControlLabel
                                    value="end"
                                    control={<Checkbox color="primary" />}
                                    label={langForm1.optionToRenew}
                                    labelPlacement="end"
                                    disabled={form1.values.part2_statement6_option === 'optionB' ? false : true}
                                    checked={form1.values.hasOptionToRenew}
                                    onChange={() => {
                                      form1.updateValues('hasOptionToRenew', !form1.values.hasOptionToRenew);
                                    }}
                                  />
                                </FormGroup>

                                <FormGroup aria-label="position" row>
                                  <FormControlLabel
                                    value="end"
                                    control={<Checkbox color="primary" />}
                                    label={langForm1.otherSpecialTerms}
                                    labelPlacement="end"
                                    disabled={form1.values.part2_statement6_option === 'optionB' ? false : true}
                                    checked={form1.values.hasOtherSpecialTerms}
                                    onChange={() => {
                                      if (form1.values.hasOtherSpecialTerms) {
                                        form1.updateValues('specialTermsDescription', undefined);
                                      }
                                      form1.updateValues('hasOtherSpecialTerms', !form1.values.hasOtherSpecialTerms);
                                    }}
                                  />

                                </FormGroup>
                              </FormControl>

                              <TextareaAutosize
                                className={classes.textArea}
                                rowsMin={2}
                                rowsMax={2}
                                style={{ marginRight: '4px' }}
                                placeholder=''
                                {...bind('specialTermsDescription')}

                                disabled={!form1.values.hasOtherSpecialTerms}
                                onChange={(e) => {
                                  limitTextFieldLength(e, 255, 'specialTermsDescription', form1, keyBooleanMap, setKeyBooleanMap);
                                }}
                              />

                              {form1.hasError('specialTermsDescription') ? <FormHelperText style={{ color: form1.hasError('specialTermsDescription') ? errorColor : 'inherit' }}>{langForm1.pleaseInput + langForm1.particulars}</FormHelperText> : null}
                              <HandleFormHelperText
                                isError={keyBooleanMap.get('specialTermsDescription')}
                                errorMessage={lang.textOverLimit}
                              />

                            </TableCell>

                          </TableRow>

                        </TableBody>
                      </Table>
                    </div>

                  </TableCell>

                </TableRow >

                {/* ------------------ Information Provided by Vendor ------------------*/}
                <TableRow>
                  <TableCell className={classes.column0} component="th" scope="row">
                    <Checkbox
                      checked={form1.values.informationProvidedByVendor}
                      onChange={() => {
                        form1.updateValues('informationProvidedByVendor', !form1.values.informationProvidedByVendor);
                        if (form1.values.informationProvidedByVendor) {
                          form1.updateValues('vendorName', "");
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell colSpan={2} className={classes.part2_column1} >
                    <Typography>{langForm1.informationProvidedByVendor}</Typography>
                    <TextField
                      className={classes.datePicker}
                      margin="dense"
                      disabled={form1.values.informationProvidedByVendor ? false : true}
                      name="headingChi"
                      variant="outlined"
                      label={langForm1.vendorName}
                      {...bind('vendorName')}
                      onChange={(e) => {
                        limitTextFieldLength(e, 255, 'vendorName', form1, keyBooleanMap, setKeyBooleanMap);
                      }}
                    />
                    <HandleFormHelperText
                      isError={keyBooleanMap.get('vendorName')}
                      errorMessage={lang.textOverLimit}
                    />

                  </TableCell>

                </TableRow>
              </TableBody>
            </Table>
          </Grid>


          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>


        </Grid> {/* end of part 2 */}

        {/*-------------------------------- agent details -------------------------------- */}

        <Grid container xs={12} spacing={1}>
          <Grid
            item
            md={12}
            xs={12}
          >
            <Table className={classes.table} size="small" aria-label="agent details table">
              <TableBody>
                {/* ------------------------ agent name ------------------------- */}
                <TableRow>
                  <TableCell component="th" style={{ width: '15%' }} scope="row" className={classes.subTableCell}>
                    {langForm1.agentName}
                  </TableCell>

                  <TableCell colSpan={2} align="left" className={classes.subTableCell}>
                    <TextField
                      className={classes.datePicker}
                      margin="dense"
                      disabled={true}
                      variant="outlined"
                      {...bind('agentName')}
                    />
                  </TableCell>
                </TableRow>

                {/* ------------------------ Name and licence number of the signatory ------------------------- */}
                <TableRow>
                  <TableCell component="th" style={{ width: '15%' }} scope="row" className={classes.subTableCell}>
                    {langForm1.nameAndLicenceNumberOfSignatory}
                  </TableCell>

                  <TableCell colSpan={2} align="left" className={classes.subTableCell}>
                    <TextField
                      className={classes.datePicker}
                      margin="dense"
                      variant="outlined"
                      {...bind('nameAndLicenceNumberOfSignatory')}
                      onChange={(e) => {
                        limitTextFieldLength(e, 255, 'nameAndLicenceNumberOfSignatory', form1, keyBooleanMap, setKeyBooleanMap);
                      }}
                    />
                    <HandleFormHelperText
                      isError={keyBooleanMap.get('nameAndLicenceNumberOfSignatory')}
                      errorMessage={lang.textOverLimit}
                    />
                  </TableCell>
                </TableRow>

                {/* ------------------------ Number of Agent’s statement of particulars of business: ------------------------- */}
                <TableRow>

                  <TableCell component="th" style={{ width: '15%' }} scope="row" className={classes.subTableCell}>
                    <div>
                      {langForm1.numberOfAgentStatement}
                    </div>
                  </TableCell>

                  <TableCell colSpan={2} align="left" className={classes.subTableCell}>
                    <TextField
                      className={classes.datePicker}
                      margin="dense"
                      disabled={true}
                      variant="outlined"
                      {...bind('numberOfAgentStatement')}
                    />
                  </TableCell>
                </TableRow>

                {/* ------------------------ Address ------------------------- */}
                <TableRow>
                  <TableCell component="th" style={{ width: '15%' }} scope="row" className={classes.subTableCell}>
                    {langForm1.address}
                  </TableCell>

                  <TableCell colSpan={2} align="left" className={classes.subTableCell}>
                    <TextareaAutosize
                      className={classes.textArea}
                      style={{ width: '30%', }}
                      disabled={true}
                      rowsMin={4}
                      rowsMax={4}
                      placeholder=''
                      {...bind('address')}
                    />
                    {form1.hasError('address') ? <FormHelperText style={{ color: form1.hasError('address') ? errorColor : 'inherit' }}>{langForm1.pleaseInput + langForm1.address}</FormHelperText> : null}
                  </TableCell>
                </TableRow>

                {/* ------------------------ Telephone number ------------------------- */}
                <TableRow>
                  <TableCell component="th" style={{ width: '15%' }} scope="row" className={classes.subTableCell}>
                    {langForm1.telephoneNumber}
                  </TableCell>

                  <TableCell colSpan={2} align="left" className={classes.subTableCell} data-key={'field-form1-telephoneNumber'} >
                    <TextField
                      className={classes.datePicker}
                      margin="dense"
                      disabled={true}
                      variant="outlined"
                      {...bind('telephoneNumber')}
                    />
                  </TableCell>
                </TableRow>

                {/* ------------------------ fax number ------------------------- */}
                <TableRow>
                  <TableCell component="th" style={{ width: '15%' }} scope="row" className={classes.subTableCell}>
                    {langForm1.faxNumber}
                  </TableCell>

                  <TableCell colSpan={2} align="left" className={classes.subTableCell}>
                    <TextField
                      className={classes.datePicker}
                      disabled={true}
                      margin="dense"
                      variant="outlined"
                      {...bind('faxNumber')}
                    />
                  </TableCell>
                </TableRow>

                {/* ------------------------ date ------------------------- */}
                {/* <TableRow>
                    <TableCell component="th"  style={{width: '15%'}} scope="row" className={classes.subTableCell}>
                        {langForm1.date}
                    </TableCell>
                    <TableCell colSpan={2} align="left" className={classes.subTableCell}>
                      <KeyboardDatePicker autoOk={true}
                          className={classes.datePicker}
                          //disableToolbar
                          variant="inline"
                          inputVariant="outlined"
                          format={DISPLAY_DATE_FORMAT}
                          margin="dense"
                          value={form1.values.date ?  moment(form1.values.date) : null}
                          onChange={(value) => { 
                            form1.updateValues('date', value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined);
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        /> 
                        {form1.hasError('date') ? <FormHelperText style={{color: form1.hasError('date') ?errorColor : 'inherit'}}>{langForm1.pleaseInput + langForm1.date}</FormHelperText> : null}  
                    </TableCell>
                  </TableRow> */}

              </TableBody>
            </Table>


          </Grid>

        </Grid>

      </CardContent>

      <CardActions style={{ justifyContent: 'flex-end', display: 'none' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (form1.values.id != undefined && form1.values.id != null) {
              dispatch({
                type: 'Form1.UpdateRequested', payload: {
                  ...form1.values,
                  costOfPurchaserOptions: (tagForm.values.costOfPurchaserOptions === [] || !isNonEmpty(tagForm.values.costOfPurchaserOptions)) ? null : trimSpace(tagForm.values.costOfPurchaserOptions),
                }
              });
            } else {
              dispatch({
                type: 'Form1.CreationRequested', payload: {
                  ...form1.values,
                  propertyStockId: propertyStockId,
                  costOfPurchaserOptions: (tagForm.values.costOfPurchaserOptions === [] || !isNonEmpty(tagForm.values.costOfPurchaserOptions)) ? null : trimSpace(tagForm.values.costOfPurchaserOptions),
                }
              });
            }
          }}

        >{lang.actionSaveForm}</Button>
        <Button variant="contained" color="primary">生成PDF檔案</Button>
      </CardActions>
    </Card >
  )
};

export default Form1Dialog;
