import {
  Button, Card, CardActions, CardContent, CardHeader, Checkbox as MuiCheckbox, Divider, FormControl, FormControlLabel, Grid, InputLabel, ListItemText, makeStyles, MenuItem, Select, Switch, TextField, Typography, useMediaQuery, useTheme
} from '@material-ui/core';
import { CardProps } from '@material-ui/core/Card';
import { TextFieldProps } from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker } from '@material-ui/pickers';
import buildingApi from 'api/buildingApi';
import { BuildingListItemDTO, TransactionSearchDTO } from 'common/dto';
import DistrictMultiSelect from 'common/elements/DistrictMultiSelect';
import MinMaxField, { BLANK_RANGE } from 'common/elements/MinMaxField';
import { useForm } from 'common/hooks';
import { HandleFormHelperText, tooltipHoc } from 'common/ui';
import { getBuildingSortForAutoComplete, getBuilingListWithoutDuplicates, getStreetListWithoutDuplicates, getStreetSortForAutoComplete, initFormKeysAndBooleanMap, limitTextFieldLength, multiLang } from 'common/utils';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';



const Checkbox = tooltipHoc(MuiCheckbox);


export interface SearchFormValues {
  // Multi Selection Criteria. 
  // Query string example: ...&usage=V,R&district=NTP,NSG&...
  usage: string[];
  status: string[];
  district: string[];
  // streetOrBuilding: string;
  street: string;
  building: string;
  level: string[];
  block: string;

  // Area & Price
  // Range values can be Infinity on each side, for whcich case it shoud be filled with -1
  // Query string example: ...&gross=-1,300&...
  gross: [number, number];
  net: [number, number];
  price: [number, number];
  rent: [number, number];

  //Transaction Related
  buyer: string;
  seller: string;
  transactionDate: (string | null)[];
  internal: boolean;
  // showOnlyCurrentUser: boolean; 
  username: string;
}

interface TransactionsSearchProps extends CardProps {
  onsearch?(values: Partial<TransactionSearchDTO>): any;
  initialcriteria?: Partial<TransactionSearchDTO>;
  isCommissionReportPage?: boolean; //if true, then it is the transaction page
}

const UITextField = (props: TextFieldProps & { fixedLabel?: boolean }) => {
  let { label, fixedLabel, ...rest } = props;
  fixedLabel = false;
  return <Fragment>
    {fixedLabel ? <Typography>{label}</Typography> : null}
    <TextField {...rest} label={!fixedLabel ? label : null} />
  </Fragment>
};

const disablePrevDates = (startDates: any) => {
  const startSeconds = Date.parse(startDates);
  return (date: any) => {
    return Date.parse(date) < startSeconds;
  }
};

const InputGroup = (props: { children: React.ReactNode[], label?: string }) => {
  const { children } = props;
  const { langPropertyStock: lang } = useSelector((state: IRootState) => state.locale);
  return (
    // <Grid container xs={12} style={{ display: 'flex' }}>
    <Grid container style={{ display: 'flex' }}>
      <Grid
        item
        style={{ flex: 1 }}
      >
        {children[0]}
      </Grid>

      <Grid item style={{ width: 40, flexShrink: 1 }}>
        <TextField
          fullWidth
          InputProps={{
            style: { borderRadius: 0, borderLeft: 0, borderRight: 0 },
          }}
          inputProps={{
            style: { paddingLeft: 0, paddingRight: 0, textAlign: 'center', borderLeft: 0, borderRight: 0 },
          }}
          disabled value={lang.to}
          variant="outlined"
          margin="dense" />
      </Grid>

      <Grid
        item
        style={{ flex: 1 }}
      // md={5}
      // xs={5}
      >
        {children[1]}
      </Grid>
    </Grid>
  );
}

InputGroup.leftItem = () => ({ style: { borderTopRightRadius: 0, borderBottomRightRadius: 0 } });
InputGroup.rightItem = () => ({ style: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } });

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  patchOutlineAutocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
      paddingLeft: 8,
    }
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

const EMPTY = {};

const TransactionsSearch = (props: TransactionsSearchProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const localeBundle = useSelector((state: IRootState) => state.locale);
  const { locale, langPropertyStock: lang, langTransaction, lang: langCommon, districtHkiOptions, districtNtOptions, districtKltOptions, levelOptions
  } = localeBundle;
  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);
  const role = useSelector((state: IRootState) => state.user.fetchedDetails);

  const isAdminOrOfficeAdmin = Object.values(role).filter(r => r.roles.filter(r => r === "ADMIN" || r === "OFFICE_ADMIN").length > 0).length > 0;
  // handling display in mobile view
  const shouldCollapse = useMediaQuery('(max-width: 599px)');

  const searchForm = useForm<TransactionSearchDTO>({
    ...props.initialcriteria ?? EMPTY,
    // username: ''
  });

  const bind = searchForm.bind;

  useEffect(() => {
    searchForm.setValues({
      ...searchForm.values,
      ...props.initialcriteria ?? EMPTY
    });

  }, [props.initialcriteria ?? EMPTY]);

  const [buildings, setBuildings] = useState<BuildingListItemDTO[]>([]);
  const [buildingListLoading, setBuildingListLoading] = useState(false);
  const [streets, setStreets] = useState<BuildingListItemDTO[]>([]);
  const [streetListLoading, setStreetListLoading] = useState(false);

  const token: string = useSelector((state: IRootState) => state.login.token ?? '');
  const fetchBuilding = (keyword: string) => {
    if (keyword.length < 2) { return; }
    setBuildingListLoading(true);
    buildingApi.getList({ building: keyword, sort: getBuildingSortForAutoComplete(locale) }, token).then(result => {
      if (result.data?.content) {
        setBuildings(getBuilingListWithoutDuplicates(result.data!.content));
        setBuildingListLoading(false);
      }
    });
  }

  const fetchStreet = (keyword: string) => {
    if (keyword.length < 2) { return; }
    setStreetListLoading(true);
    buildingApi.getList({ street: keyword, sort: getStreetSortForAutoComplete(locale) }, token).then(result => {
      if (result.data?.content) {
        setStreets(getStreetListWithoutDuplicates(result.data!.content));
        setStreetListLoading(false);
      }
    });
  }

  // const buildingNamesWithDuplicate = buildings.map((building) => building.building);
  // const buildingNames = [...new Set(buildingNamesWithDuplicate)];
  // const buildingMap: { [name: string]: BuildingListItemDTO } = {};
  // buildings.forEach((building) => { buildingMap[building.building] = building });
  const buildingNames = buildings.map((building) => multiLang(locale, building.buildingNameZh, building.buildingNameEn));

  // const streetNamesWithDuplicate = streets.map((street) => street.street);
  // const streetNames = [...new Set(streetNamesWithDuplicate)];
  // const streetMap: { [name: string]: BuildingListItemDTO } = {};
  // streets.forEach((street) => { streetMap[street.street] = street });
  const streetNames = streets.map((street) => multiLang(locale, street.streetZh, street.streetEn));

  let [] = useState({
    transaction: false
  });

  const [disabledCriterias] = useState<{ [k: string]: boolean }>({
    // rent: true,
  });

  const filteredSearchForm = () => {
    searchForm.values = {
      ...searchForm.values,
      building: searchForm.values.building?.trim(),
      block: searchForm.values.block?.trim(),
      street: searchForm.values.street?.trim(),
      lot: searchForm.values.lot?.trim(),
      landlord: searchForm.values.landlord?.trim(),
    }
    const newForm: Partial<TransactionSearchDTO> = {};
    Object
      .keys(searchForm.values)
      .filter((key) => !disabledCriterias[key])
      .forEach((key) => (newForm as any)[key] = searchForm.values[key as keyof TransactionSearchDTO]);
    return newForm;
  };

  // const [ isGross, _setIsGross ] = useState(false);
  // const setIsGross = (newVal: boolean) => {
  //   _setIsGross(newVal);
  //   // swap
  //   if (newVal) {
  //     searchForm.setValues({
  //       ...searchForm.values,
  //       gross: searchForm.values.net,
  //       net: BLANK_RANGE,
  //     });
  //   } else {
  //     searchForm.setValues({
  //       ...searchForm.values,
  //       net: searchForm.values.gross,
  //       gross: BLANK_RANGE,
  //     });
  //   }
  // }

  const isGross = searchForm.values.isGross ?? false;
  const setIsGross = (newVal: boolean) => {
    searchForm.updateValues('isGross', newVal);
    // swap
    if (newVal) {
      searchForm.modify({
        gross: searchForm.values.net,
        net: BLANK_RANGE,
      });
    } else {
      searchForm.modify({
        net: searchForm.values.gross,
        gross: BLANK_RANGE,
      });
    }
  };

  const isRent = searchForm.values.isRent ?? false;

  const [] = useState<any>({
    transactionDate: new Date()
  });

  const districtSelection = () => (
    <DistrictMultiSelect
      value={searchForm.values.district ?? []}
      onChange={(val) => searchForm.updateValues('district', val)}
    />
  );

  const streetField = () => (
    <Autocomplete
      options={streetNames}
      noOptionsText={''}
      loading={streetListLoading}
      loadingText={langCommon.msgLoading}
      freeSolo={true}
      disableClearable
      renderInput={params => (
        <TextField
          {...params}
          inputProps={{ ...params.inputProps, maxLength: 255 }}
          className={classes.patchOutlineAutocomplete}
          // error={!!form.hasError('building')}
          // helperText={form.hasError('building')}
          fullWidth
          label={lang.street}
          margin="dense"
          variant="outlined"
          onChange={(ev) => {
            setKeyBooleanMap(keyBooleanMap.set("street", ev.target.value.length === 255))
            searchForm.modify({
              street: ev.target.value
            });
            fetchStreet(ev.target.value)
          }}
          // value={form.values.building ?? ''}
          value={searchForm.values.street ?? ''}
        />
      )}
      value={searchForm.values.street ?? ''}
      onChange={(_, val) => {
        searchForm.modify({
          street: val
        });
        // if(streetMap[val]) {
        //   const { street} = streetMap[val];
        //   searchForm.modify({
        //     street: street
        //   })
        // }
      }}
    />
  );
  const buildingField = () => (
    <Autocomplete
      options={buildingNames}
      noOptionsText={''}
      loading={buildingListLoading}
      loadingText={langCommon.msgLoading}
      freeSolo={true}
      disableClearable
      renderInput={params => (
        <TextField
          {...params}
          inputProps={{ ...params.inputProps, maxLength: 255 }}
          className={classes.patchOutlineAutocomplete}
          // error={!!form.hasError('building')}
          // helperText={form.hasError('building')}
          fullWidth
          label={lang.buildingOrVillageName}
          margin="dense"
          variant="outlined"
          onChange={(ev) => {
            setKeyBooleanMap(keyBooleanMap.set("building", ev.target.value.length === 255))
            searchForm.modify({
              building: ev.target.value
            })
            fetchBuilding(ev.target.value)
          }}
          value={searchForm.values.building ?? ''}
        />
      )}
      value={searchForm.values.building ?? ''}
      onChange={(_, val) => {
        searchForm.modify({
          building: val
        })
        // if (buildingMap[val]) {
        //   const { building } = buildingMap[val];
        //   searchForm.modify({
        //     building: building
        //   })
        // }
      }}
    />
  );
  const blockFields = () => (
    <UITextField
      label={lang.block}
      {...bind('block')}
      fullWidth
      margin="dense"
      variant="outlined"
      onChange={(e) => {
        limitTextFieldLength(e, 255, 'block', searchForm, keyBooleanMap, setKeyBooleanMap);
      }}
    ></UITextField>
  );

  const lotFields = () => (
    <UITextField
      label={lang.lot}
      {...bind('lot')}
      fullWidth
      margin="dense"
      variant="outlined"
      onChange={(e) => {
        limitTextFieldLength(e, 255, 'lot', searchForm, keyBooleanMap, setKeyBooleanMap);
      }}
    ></UITextField>
  );

  const levelFields = () => (
    <FormControl variant="outlined" margin="dense" fullWidth>
      <InputLabel variant="outlined" style={{ background: '#fff' }}>{lang.level}</InputLabel>
      <Select
        multiple
        {...bind('level')}
        renderValue={(selected) => (selected as string[]).map(key => levelOptions[key]).join(', ')}
      >
        {Object.keys(levelOptions).map(key => (
          <MenuItem key={key} value={key}>
            <Checkbox checked={searchForm.values.level!.indexOf(key) > -1} />
            <ListItemText primary={levelOptions[key]} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );



  const landloredField = () => (
    <UITextField
      label={langTransaction.landlord}
      // value={searchForm.values.landlord ?? ''}
      {...bind('landlord')}
      fullWidth
      margin="dense"
      variant="outlined"
      onChange={(e) => {
        limitTextFieldLength(e, 255, 'landlord', searchForm, keyBooleanMap, setKeyBooleanMap);
      }}
    ></UITextField>
  );

  const transactionDateRange = () => (
    <div>
      <InputGroup>
        <KeyboardDatePicker autoOk={true}
          InputProps={InputGroup.leftItem()}
          // disableToolbar
          fullWidth
          variant="inline"
          inputVariant="outlined"
          format={DISPLAY_DATE_FORMAT}
          label={lang.transactionDate}
          margin="dense"
          // inputValue={searchForm.values.transactionDate?.[0] ?? ''}
          value={searchForm.values.transactionDate?.[0] ? moment(searchForm.values.transactionDate?.[0]) : null}
          onChange={(value) => {
            searchForm.setValues({
              ...searchForm.values,
              transactionDate: [value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? null, null]
            });
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />

        <KeyboardDatePicker autoOk={true}
          InputProps={InputGroup.rightItem()}
          // disableToolbar
          fullWidth
          variant="inline"
          inputVariant="outlined"
          format={DISPLAY_DATE_FORMAT}
          // label={lang.transactionDate}
          margin="dense"
          shouldDisableDate={disablePrevDates(searchForm.values.transactionDate?.[0])}
          // inputValue={searchForm.values.transactionDate?.[1] ?? ''}
          value={searchForm.values.transactionDate?.[1] ? moment(searchForm.values.transactionDate?.[1]) : null}
          onChange={(value) => {
            searchForm.setValues({
              ...searchForm.values,
              transactionDate: [searchForm.values.transactionDate?.[0] ?? null, value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? null]
            });
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </InputGroup>
    </div>
  );
  const [keyBooleanMap, setKeyBooleanMap] = useState(initFormKeysAndBooleanMap(searchForm));


  return (
    // <Grid container md={12} sm={12} xs={12} spacing={1}>
    <Grid container spacing={1}>
      <Grid item md={6} sm={12} xs={12}>
        <Card
          {...props}
          style={{ margin: 0 }}
        >
          <CardHeader title={lang.captionBasicInfo} />
          <Divider />

          <CardContent>
            <Grid container spacing={1}>
              {/* <Grid
                item
                md={3} sm={3}
                xs={6}
              >
                {usageSelection()}
              </Grid>
              <Grid
                item
                md={3} sm={3}
                xs={6}
              >
                {statusSelection()}
              </Grid> */}
              <Grid
                item
                md={4} sm={4}
                xs={6}
              >
                {districtSelection()}
              </Grid>

              <Grid
                item
                md={8} sm={8}
                xs={6}
              >
                {streetField()}
                <HandleFormHelperText
                  isError={keyBooleanMap.get('street')}
                  errorMessage={lang.textOverLimit}
                />
              </Grid>

              <Grid
                item
                md={7} sm={7}
                xs={12}
              >
                {buildingField()}
                <HandleFormHelperText
                  isError={keyBooleanMap.get('building')}
                  errorMessage={lang.textOverLimit}
                />
              </Grid>
              <Grid
                item
                md={5} sm={5}
                xs={12}
              >
                {lotFields()}
                <HandleFormHelperText
                  isError={keyBooleanMap.get('lot')}
                  errorMessage={lang.textOverLimit}
                />
              </Grid>

              <Grid
                item
                md={5} sm={5}
                xs={6}
              >
                {blockFields()}
                <HandleFormHelperText
                  isError={keyBooleanMap.get('block')}
                  errorMessage={lang.textOverLimit}
                />
              </Grid>

              <Grid
                item
                md={3} sm={3}
                xs={6}
              >
                {levelFields()}

              </Grid>

              <Grid
                item
                md={4} sm={4}
                xs={12}
              >
                {landloredField()}
                <HandleFormHelperText
                  isError={keyBooleanMap.get('landlord')}
                  errorMessage={lang.textOverLimit}
                />
              </Grid>

            </Grid>

          </CardContent>
        </Card>

      </Grid>

      <Grid item md={6} sm={12} xs={12}>
        <Card
          {...props}
          style={{ margin: 0 }}
        >
          <CardHeader title={langTransaction.titleSalesInfo} />
          <Divider />
          <CardContent>
            <Grid container spacing={1}>
              <Grid container>
                {
                  (shouldCollapse) ?
                    <Grid container item md={12} sm={12} xs={12}>
                      <Grid
                        item
                        md={2} sm={2}
                        xs={6}
                      >
                        <Typography style={{ fontWeight: 'bold', paddingTop: '10px' }}>{lang.captionArea}</Typography>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={6}
                        justify={'flex-end'}
                      >
                        <div style={{ marginLeft: theme.spacing(1), display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                          <Typography>{lang.captionNet}</Typography>
                          <Switch
                            checked={isGross}
                            onChange={(_, val) => setIsGross(val)}
                          />
                          <Typography>{lang.captionGross}</Typography>
                        </div>

                      </Grid>
                    </Grid>
                    :
                    <Grid
                      item
                      md={2} sm={2}
                      xs={6}
                    >
                      <Typography style={{ fontWeight: 'bold', paddingTop: '10px' }}>{lang.captionArea}</Typography>
                    </Grid>
                }

                <Grid item md={6} sm={6} xs={12}>
                  <MinMaxField
                    range={isGross ? searchForm.values.gross! : searchForm.values.net!}
                    onChange={(newRange) => {
                      const key = isGross ? 'gross' : 'net';
                      searchForm.setValues({
                        ...searchForm.values,
                        [key]: newRange,
                      });
                    }}
                  />

                </Grid>

                {
                  (!shouldCollapse) ?
                    <Grid item md={4} sm={4}>
                      <div style={{ marginLeft: theme.spacing(1), display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Typography>{lang.captionNet}</Typography>
                        <Switch
                          checked={isGross}
                          onChange={(_, val) => setIsGross(val)}
                        />
                        <Typography>{lang.captionGross}</Typography>
                      </div>
                    </Grid>
                    :
                    null
                }

                {
                  (shouldCollapse) ?
                    <Grid container item md={12} sm={12} xs={12}>
                      <Grid
                        item
                        md={2} sm={2}
                        xs={6}
                      >
                        <Typography style={{ fontWeight: 'bold', paddingTop: '10px' }}>{lang.captionPrice}</Typography>
                      </Grid>
                      {/* <Grid
                        container
                        item
                        xs={6}
                        justify={'flex-end'}
                      >
                        <div style={{ marginLeft: theme.spacing(1), display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                          <Typography>{lang.price}</Typography>
                          <Switch
                            checked={isRent}
                            onChange={(_, val) => setIsRent(val)}
                          />
                          <Typography>{lang.rent}</Typography>
                        </div>
                      </Grid> */}

                    </Grid>
                    :
                    <Grid
                      item
                      md={2} sm={2}
                      xs={6}
                    >
                      <Typography style={{ fontWeight: 'bold', paddingTop: '10px' }}>{lang.captionPrice}</Typography>
                    </Grid>
                }
                <Grid item md={6} sm={6} xs={12}>
                  <MinMaxField
                    range={isRent ? searchForm.values.rent! : searchForm.values.price!}
                    unit={!isRent ? (locale === 'en' ? langCommon.u1M : langCommon.u10k) : undefined}
                    onChange={(newRange) => {

                      const key = isRent ? 'rent' : 'price';
                      searchForm.modify({
                        [key]: newRange,
                      });
                    }}
                    digitLimit={isRent ? 12 : locale == 'en' ? 6 : 8}
                  />

                </Grid>

                {/* {
                  (!shouldCollapse) ?
                    <Grid item md={4} sm={4}>
                      <div style={{ marginLeft: theme.spacing(1), display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Typography>{lang.price}</Typography>
                        <Switch
                          checked={isRent}
                          onChange={(_, val) => setIsRent(val)}
                        />
                        <Typography>{lang.rent}</Typography>
                      </div>
                    </Grid>

                    : null
                } */}

              </Grid>

              {/* <Grid
                item
                md={6} sm={6}
                xs={6}
              >
                {buyerField()}
              </Grid>

              <Grid
                item
                md={6} sm={6}
                xs={6}
              >
                {sellerField()}
              </Grid> */}

              <Grid
                item
                md={12} sm={12}
                xs={12}
              >
                {transactionDateRange()}
              </Grid>

            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item md={12} sm={12} xs={12}>

        <Card
          {...props}
        >
          <form
            autoComplete="off"
            noValidate
          >
            <CardContent style={{ display: 'none' }}>
              <Grid
                container
                spacing={1}
              >

                {/* 
            <Grid item container md={9} sm={9} xs={12} spacing={1} style={{ alignContent: 'start' }}>
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
              >
                <Typography style={{ fontWeight: 'bold' }}>{lang.captionLocation}</Typography>
              </Grid>

              <Grid
                item
                md={3}
                sm={3}
                xs={12}
              >
                {districtSelection()}
              </Grid>

              <Grid
                item
                md={3}
                sm={3}
                xs={12}
              >
                {streetField()}
              </Grid>

              <Grid
                item
                md={3}
                sm={3}
                xs={12}
              >
                {buildingField()}
              </Grid>

              <Grid
                item
                md={2}
                sm={2}
                xs={12}
              >
                {blockFields()}
              </Grid>
              <Grid
                item
                md={1}
                sm={1}
                xs={12}
              >
                {levelFields()}
              </Grid>

            </Grid> */}

                {/* <Grid item md={12} sm={12} xs={12}>
              <Divider />
            </Grid>

            <Grid item container md={6} sm={6} xs={12} spacing={1}>
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
              >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Typography style={{ marginRight: theme.spacing(3), fontWeight: 'bold' }}>{lang.captionArea}</Typography>
                  <Typography>{lang.captionNet}</Typography>
                  <Switch
                    checked={isGross}
                    onChange={(_, val) => setIsGross(val)}
                  />
                  <Typography>{lang.captionGross}</Typography>
                </div>
              </Grid>

              <Grid item md={12} sm={12} xs={12}>
                <MinMaxField
                  range={isGross ? searchForm.values.gross! : searchForm.values.net!}
                  onChange={(newRange) => {
                    const key = isGross ? 'gross' : 'net';
                    searchForm.setValues({
                      ...searchForm.values,
                      [key]: newRange,
                    });
                  }}
                />
              </Grid>
            </Grid>

            <Grid item container md={6} sm={6} xs={12} spacing={1}>
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
              >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Typography style={{ marginRight: theme.spacing(3), fontWeight: 'bold' }}>{lang.captionPriceOrRent}</Typography>
                  <Typography>{lang.price}</Typography>
                  <Switch
                    checked={isRent}
                    onChange={(_, val) => setIsRent(val)}
                  />
                  <Typography>{lang.rent}</Typography>
                </div>
              </Grid>

              <Grid item md={12} sm={12} xs={12}>
                <MinMaxField
                  range={isRent ? searchForm.values.rent! : searchForm.values.price!}
                  unit={!isRent ? (locale === 'en' ? langCommon.u1M : langCommon.u10k) : undefined}
                  onChange={(newRange) => {
                    
                    const key = isRent ? 'rent' : 'price';
                    searchForm.modify({
                      [key]: newRange,
                    });
                  }}
                />
              </Grid>
            </Grid> */}

                {/* <Grid item container xs={6} spacing={1}>
              <Grid
                item
                md={12}
                xs={12}
              >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Typography style={{ paddingTop: theme.spacing(1), marginRight: theme.spacing(3), fontWeight: 'bold' }}>{lang.price}</Typography>
                  {/* <Typography>{lang.price}</Typography> 
                  <Switch
              checked={isRent}
              onChange={(_, val) => setIsRent(val)}
            />
          <Typography>{lang.rent}</Typography>
                </div>
              </Grid>

              <Grid item md={12} xs={12} style={{ paddingTop: '10px' }}>
                <MinMaxField
                  range={searchForm.values.price!}
                  // range={isRent ? searchForm.values.rent! : searchForm.values.price!}
                  onChange={(newRange) => {
                    const key = 'price';
                    // const key = isRent ? 'rent' : 'price';
                    searchForm.setValues({
                      ...searchForm.values,
                      [key]: newRange,
                    });
                  }}
                />
              </Grid>
            </Grid> */}

                <Grid item md={12} sm={12} xs={12}>
                  {/* <Grid item md={12} sm={12} xs={12}>
                <Divider />
              </Grid> */}
                  {/* <Grid item md={12} sm={12} xs={12}>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded.transaction,
                  })}
                  onClick={() => setExpanded({ ...expanded, transaction: !expanded.transaction })}
                  aria-expanded={expanded.transaction}
                  aria-label="show more">
                  <ExpandMore />
                </IconButton>
                {lang.captionMore}
              </Grid> */}
                  {/* <Collapse in={expanded.transaction} timeout="auto">
                <Grid container md={12} sm={12} xs={12} spacing={1}>
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <Typography style={{ fontWeight: 'bold' }}>{lang.captionTransaction}</Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    sm={3}
                    xs={12}
                  >
                    {buyerField()}
                  </Grid>
                  <Grid
                    item
                    md={3}
                    sm={3}
                    xs={12}
                  >
                    {sellerField()}
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sm={6}
                    xs={12}
                  >
                    {transactionDateRange()}
                  </Grid>
                  <KeyboardDatePicker autoOk={true}
                  // InputProps={InputGroup.leftItem()}
                  //disableToolbar
                  variant="inline"
                  inputVariant="outlined"
                  format={DISPLAY_DATE_FORMAT}
                  label={lang.transactionDate}
                  margin="dense"
                  inputValue={searchForm.values.transactionDate ?? ''}
                  value={searchForm.values.transactionDate ? moment(searchForm.values.transactionDate, 'DD/MM/YYYY') : null}
                  onChange={(_, value) => {
                    searchForm.setValues({
                      ...searchForm.values,
                      transactionDate: value ?? null
                    });
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  style={{ paddingTop: '4px'}}
                />
                </Grid>
              </Collapse> */}
                </Grid>
              </Grid>
            </CardContent>
          </form>


          <CardActions style={{ flexWrap: 'wrap', justifyContent: 'flex-end' }}>
            {/* <Typography>{lang.captionSearchConditions}</Typography>  */}
            <div style={{ flexGrow: 1 }} />

            <TextField select margin="dense" variant="outlined"
              {...bind('propertyIsValid')}
            // value={JSON.stringify(searchForm.values.propertyIsValid)}
            // onChange={(ev) => searchForm.updateValues('propertyIsValid', JSON.parse(ev.target.value))}
            >
              <MenuItem key={'ALL'} value={' '}>{langTransaction.captionShowAllValidity}</MenuItem>
              <MenuItem key={'TRUE'} value={'true'}>{langTransaction.captionShowValidOnly}</MenuItem>
              <MenuItem key={'FALSE'} value={'false'}>{langTransaction.captionShowInvalidOnly}</MenuItem>
            </TextField>
            <FormControlLabel control={<Checkbox checked={!!searchForm.values.internal} onChange={(_, checked) => searchForm.updateValues('internal', checked ? true : false)} />} label={lang.internal} />
            <Button variant="contained" color="primary" onClick={() => props.onsearch?.(filteredSearchForm())}>{lang.actionSearch}</Button>
          </CardActions>
        </Card>
      </Grid>

    </Grid>
  );


};

export default TransactionsSearch;