import {
  Button, Card, CardActions, CardContent, Checkbox, Divider, FormControl, FormControlLabel,
  FormGroup, FormHelperText, Grid, InputAdornment, makeStyles, MenuItem, Paper, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, TextareaAutosize, TextField, TextFieldProps, Typography, withStyles
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import clsx from 'clsx';
import { Form6DTO, Form6PropertyDTO, PropertyListItemDTO } from 'common/dto';
import { FormPresenter } from "common/hooks";
import { HandleFormHelperText } from 'common/ui';
import { genAddress, initFormKeysAndBooleanMap, isNonEmpty, limitTextFieldLength } from 'common/utils';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';


const useStyles = makeStyles(() => ({
  root: {},
  partTitle: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  textArea: {
    width: '100%',
    margin: 'dense',
    padding: '8px',
    fontSize: '14px'
  },
  table: {
    minWidth: 650,
    '& .MuiTableHead-root': {
      // wordBreak: 'keep-all',
    },
  },
  textField: {
    width: '30%',
  },
  datePicker: {
    width: '30%',
  },
  column0: {
    width: '3%',
  },
  column1: {
    width: '13%',
  },
  numberFields: {
    width: '30%',
  },
  checkboxColumn: {
    width: '5%',
  },
  subTableCell: {
    borderBottomColor: '#FFFFFF',
  },
  divider: {
    backgroundColor: '#1565c0',
  },
  infoPartTextField: {
    width: '100%',
  },
  companyInfo: {
    paddingLeft: '20px',
  },
  //for schedule 1 table
  s1Column1: {
    width: '20%',
  },
  s1Column2: {
    width: '15%',
  },
  s1Column3: {
    width: '10%',
  },
  s1Column4: {
    width: '10%',
  },
  s1Column5: {
    width: '15%',
  },
  s1Column6: {
    width: '15%',
  },
  s1Column7: {
    width: '15%',
  },
  s1Cell: {
    margin: '2px',
  }
}));


const regex = new RegExp(/(^\d*\.?\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/);
const NumberField = withStyles({
  root: {
    '& input': {
      textAlign: 'right',
      '-moz-appearance': 'textfield',
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  },
},
)((props: TextFieldProps) => {
  return <TextField type={"text"}{...props} inputProps={{
    ...props.inputProps,
    min: 0,
    max: Math.max(),
    inputMode: 'decimal',
    pattern: "([^0-9]*)",
    step: 0.01
  }}

    onKeyDown={e => {
      // If the key pressed is not a number or a period, nothing is printed
      if (!e.key.match(regex)) {

        e.preventDefault();

      }
      // if (!e.key.match(regex) || (e.key === '.' && String(props.value).indexOf('.') > 0) || (e.key === '.' && String(props.value).length === 0) || e.keyCode === 229 || e.key === 'Unidentified') {

      //   e.preventDefault();

      // }

    }}

    onKeyPress={e => {
      // If the key pressed is not a number or a period, nothing is printed
      if (!e.key.match(regex)) {

        e.preventDefault();

      }
    }}

  />;
});

const errorColor = '#e53935';

const disablePrevDates = (startDates: any) => {
  const startSeconds = Date.parse(startDates);
  return (date: any) => {
    return Date.parse(date) < startSeconds;
  }
}

export const form6Initial: Partial<Form6DTO> = {
  needDisclosure: false,
  withInterest: false,
  paymentTimeOption: 'option1',
};

export const form6Schedule1RecordInit = (selectedProperties: PropertyListItemDTO[], locale: string, districtEnOptions: { [key: string]: string }, districtZhHkOptions: { [key: string]: string }) => {
  let resultList: Form6PropertyDTO[] = [];
  selectedProperties.map((p: PropertyListItemDTO) => {
    let record = {} as Form6PropertyDTO;
    const propertyAddress = genAddress(locale, p.unit ?? '', p.floor ?? '', p.blockZh ?? '', p.blockEn ?? '', p.buildingNameZh ?? '', p.buildingNameEn ?? '', p.streetZh ?? '', p.streetEn ?? '', (locale === 'en') ? districtEnOptions[p.district ?? ''] : districtZhHkOptions[p.district ?? '']);
    // record.formId = formId;
    record.propertyStockId = String(p.id!);
    record.property = propertyAddress;
    record.dateOfInspection = undefined;
    record.notToInspect = false;
    record.waiveRight = false;
    record.agencyRelationship = 'potentiallyDualAgency';
    record.landlordCommission = '';
    record.tenantCommission = '';
    resultList.push(record);
  });
  return resultList;
  // let record = {} as Form6Schedule1RecordInterface;
  // // record.formId = formId;
  // // record.propertyStockId
  // record.property = property;
  // record.dateOfInspection = undefined;
  // record.notToInspect = false;
  // record.waiveRight = false;
  // record.agencyRelationship = '';
  // record.landlordCommission = undefined;
  // record.tenantCommission = undefined;
  // return record;
};

// export interface Form6Schedule1RecordInterface {
//   // formId: string;
//   propertyStockId: string;
//   property: string;
//   dateOfInspection?: string;
//   notToInspect: boolean;
//   waiveRight: boolean;
//   agencyRelationship: string;
//   landlordCommission?: string;
//   tenantCommission?: string;
// }

interface Form6Props {
  className?: string;
  form6: FormPresenter<Form6DTO>;
  form6Schedule1: FormPresenter<any>;
}

const Form6Dialog = (props: Form6Props) => {
  const { className, form6, form6Schedule1: form6Schedule1 } = props;

  const classes = useStyles();
  const { lang, langForm6, langPropertyStock, formLocaleOptions } = useSelector((state: IRootState) => state.locale);
  const agencyCompanyInfo = useSelector((state: IRootState) => state.systemSettings.Company.AGENCY_COMPANY_INFO);
  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);


  const schedule1Table = form6Schedule1.values.formProperty || [];

  const bind = form6.bind;

  const [keyBooleanMap, setKeyBooleanMap] = useState(initFormKeysAndBooleanMap(form6));

  useEffect(() => {
    setKeyBooleanMap(initFormKeysAndBooleanMap(form6));
  }, [Object.keys(form6.values).length])

  const requiredFields: { [key: string]: string[] } = {
    form: ['formLocale', 'tenantName', 'agentName', 'validityPeriodStart', 'validityPeriodEnd', 'interestsInProperty',],
    tenantInfo: ['eitherSignatoryInfoOrHKID', 'nameOfSignatoryOfTenant', 'positionOfSignatoryOfTenant', 'businessRegistrationCertificateNumberOfTenant', 'tenantAddress', 'tenantPhoneNumber'],
    agentInfo: ['nameAndLicenceNumberOfAgent', 'numberOfStatementOfParticularsOfBusiness', 'agentAddress', 'agentPhoneNumber'],
  };

  const partNameMapping = (partName: string) => {
    switch (partName) {
      case 'form': return langForm6.form;
      case 'tenantInfo': return langForm6.tenantInfo;
      case 'agentInfo': return langForm6.agentInfo;
    }
  }

  const missingFieldList = () => {
    let resultStr: string[] = [];
    Object.keys(requiredFields).forEach(part => {
      let partMissingFields: string[] = [];
      requiredFields[part].forEach(key => {
        let validationMsg = form6.hasError(key as keyof Form6DTO);
        if (validationMsg) {
          partMissingFields.push(validationMsg?.replace(langForm6.pleaseInput, '')?.replace(langForm6.pleaseSelect, ''));
        }
      });
      if (partMissingFields.length > 0) {
        resultStr.push(partNameMapping(part.toString()) + ": " + partMissingFields.join(', '));
      }
    });

    if (form6.hasError('invalidFormProperty')) {
      resultStr.push(langForm6.titleSchedule1);
    }
    return resultStr.join('\n');
  }

  const schedule1HasError = !!form6.hasError('invalidFormProperty');

  const companyDetailsLocale = (locale: string) => {
    return (locale === 'en') ? { name: agencyCompanyInfo['COMPANY_NAME_EN'], address: agencyCompanyInfo['ADDRESS_EN'] } : { name: agencyCompanyInfo['COMPANY_NAME_CN'], address: agencyCompanyInfo['ADDRESS_CN'] };
  };

  const { districtHkiOptions: districtHkiOptionsEn, districtNtOptions: districtNtOptionsEn, districtKltOptions: districtKltOptionsEn } = useSelector((state: IRootState) => state.locale._bundle['en']);
  const { districtHkiOptions: districtHkiOptionsZhHK, districtNtOptions: districtNtOptionsZhHK, districtKltOptions: districtKltOptionsZhHK } = useSelector((state: IRootState) => state.locale._bundle['zh_HK']);

  const districtEnOptions = {
    ...districtHkiOptionsEn,
    ...districtNtOptionsEn,
    ...districtKltOptionsEn,
  };
  const districtZhHkOptions = {
    ...districtHkiOptionsZhHK,
    ...districtNtOptionsZhHK,
    ...districtKltOptionsZhHK,
  };

  const stockCartList = useSelector((state: IRootState) => state.clients.stockCartList) ?? [];
  const form6Schedule1Id = props.form6Schedule1.values.formProperty.map((p: { propertyStockId: any; }) => p.propertyStockId.toString());
  const form6SelectedProperties = stockCartList.filter((p) => form6Schedule1Id.includes(p.id.toString()));


  const genForm6Schedule1AddressByLocale = (form6SelectedProperties: PropertyListItemDTO[], locale: string) => {
    let newFormPropertits: any[] = []
    form6SelectedProperties.forEach((p: PropertyListItemDTO) => {
      let propertyAddress = genAddress(locale, p.unit ?? '', p.floor ?? '', p.blockZh ?? '', p.blockEn ?? '', p.buildingNameZh ?? '', p.buildingNameEn ?? '', p.streetZh ?? '', p.streetEn ?? '', (locale === 'en') ? districtEnOptions[p.district ?? ''] : districtZhHkOptions[p.district ?? '']);
      let temp = { ...form6Schedule1.values.formProperty.filter((f: { propertyStockId: any; }) => f.propertyStockId.toString() === p.id.toString())[0], property: propertyAddress }
      newFormPropertits.push(temp)
    });
    form6Schedule1.updateValues('formProperty', newFormPropertits);
  };

  const updateCompanyCurrentLocaleDetails = (locale: string) => {
    let details = companyDetailsLocale(locale);
    form6.updateValues('agentName', details.name);
    form6.updateValues('agentAddress', details.address);
    genForm6Schedule1AddressByLocale(form6SelectedProperties, locale);
  }

  return (
    <Card
      // {...rest}
      className={clsx(classes.root, className)}>
      {/* 生成出售物業代理協議 (表格3) */}
      {/* <CardHeader></CardHeader> */}
      {/* <Divider /> */}
      <CardContent>
        <Grid item container xs={12} spacing={2}>
          {isNonEmpty(missingFieldList()) ?
            <Grid
              item
              md={12}
              xs={12}
            >

              <div style={{ marginBottom: '5px', whiteSpace: "pre-wrap" }}>
                <Typography style={{ color: errorColor, fontSize: '13px', fontWeight: 'bold', fontStyle: 'italic' }}>{langForm6.msgInputMissingFields}</Typography>
                <Typography style={{ color: errorColor, fontSize: '13px', fontStyle: 'italic' }}>{missingFieldList()}</Typography>
              </div>
              <Divider />
            </Grid>
            : null
          }
          <Grid
            item
            sm={2}
            md={2}
            xs={12}
          >
            <Typography>{langPropertyStock.captionFormLocale}</Typography>
            <TextField select
              fullWidth
              margin="dense"
              {...bind('formLocale')}
              required
              variant="outlined"
              onChange={(event) => {
                bind('formLocale').onChange(event);
                updateCompanyCurrentLocaleDetails(event.target.value);
              }}>
              {
                Object.keys(formLocaleOptions).map((opt) => <MenuItem value={opt}>{formLocaleOptions[opt]}</MenuItem>)
              }
            </TextField>
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >
            <Typography className={classes.partTitle} >{langForm6.formName}</Typography>
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >
            {/*------------- 1. Appointment of Agent and Validity Period of this Agreement------------ */}
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="part 1 table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.column0} data-key={'field-form6-isValidityPeriodValid'}>1.</TableCell>
                    <TableCell colSpan={2} align="left" className={classes.column1} >{langForm6.titlePart1}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/*----------- tenant name ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell className={classes.column1} >{langForm6.tenantName}</TableCell>
                    <TableCell>
                      <TextField
                        className={classes.textField}
                        margin="dense"
                        variant="outlined"
                        {...bind('tenantName')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'tenantName', form6, keyBooleanMap, setKeyBooleanMap);
                        }}
                        helperText={form6.hasError('tenantName')}
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('tenantName')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>

                  {/*----------- agent name ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell className={classes.column1} >{langForm6.agentName}</TableCell>
                    <TableCell>
                      <TextField
                        className={classes.textField}
                        margin="dense"
                        variant="outlined"
                        {...bind('agentName')}
                        disabled={true}
                      />
                    </TableCell>
                  </TableRow>

                  {/*----------- validity period ---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row" ></TableCell>
                    <TableCell className={classes.column1} data-key={'field-form6-validityPeriodStart'} >
                      {langForm6.validityPeriod}
                      <div style={{ display: 'inline-block', width: '80%' }}>
                        {form6.hasError('isValidityPeriodValid') ? <FormHelperText style={{ color: form6.hasError('isValidityPeriodValid') ? errorColor : 'inherit' }}>{form6.validationErrors?.isValidityPeriodValid}</FormHelperText> : null}
                      </div>
                    </TableCell>
                    <TableCell data-key={'field-form6-validityPeriodEnd'} >
                      <div>
                        <KeyboardDatePicker autoOk={true}
                          className={classes.datePicker}
                          // InputProps={InputGroup.leftItem()}
                          //disableToolbar
                          variant="inline"
                          inputVariant="outlined"
                          format={DISPLAY_DATE_FORMAT}
                          label={langForm6.validityPeriodStart}
                          margin="dense"
                          value={form6.values.validityPeriodStart ? moment(form6.values.validityPeriodStart) : null}
                          onChange={(value) => {
                            form6.setValues({
                              ...form6.values,
                              validityPeriodStart: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
                            });
                            // if (value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString()?.localeCompare(form6.values.validityPeriodEnd ?? "") === 1) {
                            //   form6.updateValues('validityPeriodEnd', "");
                            // }
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                        {' '}
                        {form6.values.validityPeriodStart?.length !== 20 && form6.values.validityPeriodStart?.localeCompare(form6.values.validityPeriodEnd ?? "") === 1 ? form6.values.validityPeriodEnd = "" : ""}
                        <KeyboardDatePicker autoOk={true}
                          className={classes.datePicker}
                          // InputProps={InputGroup.leftItem()}
                          //disableToolbar
                          variant="inline"
                          inputVariant="outlined"
                          format={DISPLAY_DATE_FORMAT}
                          label={langForm6.validityPeriodEnd}
                          margin="dense"
                          shouldDisableDate={disablePrevDates(form6.values.validityPeriodStart)}
                          value={form6.values.validityPeriodEnd ? moment(form6.values.validityPeriodEnd) : null}
                          onChange={(value) => {
                            form6.setValues({
                              ...form6.values,
                              validityPeriodEnd: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
                            });
                            // if (value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString()?.localeCompare(form6.values.validityPeriodStart ?? "") === -1) {
                            //   form6.updateValues('validityPeriodStart', "");
                            // }
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                        {langForm6.validityPeriodRemarks}
                      </div>
                      <div>
                        <div style={{ display: 'inline-block', width: '30%' }}>
                          {form6.hasError('validityPeriodStart') ? <FormHelperText style={{ color: form6.hasError('validityPeriodStart') ? errorColor : 'inherit' }}>{form6.validationErrors?.validityPeriodStart}</FormHelperText> : null}
                        </div>
                        <div style={{ display: 'inline-block', width: '30%' }}>
                          {form6.hasError('validityPeriodEnd') ? <FormHelperText style={{ color: form6.hasError('validityPeriodEnd') ? errorColor : 'inherit' }}>{form6.validationErrors?.validityPeriodEnd}</FormHelperText> : null}
                        </div>

                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>


            {/*------------- 5. Disclosure of Interest by Agent------------ */}
            <Grid
              item
              md={12}
              xs={12}
            >
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="part 5 table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.column0} data-key={'field-form6-interestsInProperty'}>5.</TableCell>
                      <TableCell align="left" colSpan={2} >{langForm6.titlePart5}</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell colSpan={2}>
                        <FormControl component="fieldset" style={{ paddingBottom: '4px' }}>
                          <FormGroup aria-label="position" row>
                            <FormControlLabel
                              value="end"
                              control={<Checkbox color="primary" />}
                              label={langForm6.disclosureOfInterestByAgent}
                              labelPlacement="end"
                              checked={form6.values.needDisclosure}
                              onChange={() => {
                                if (form6.values.needDisclosure) {
                                  form6.updateValues('interestsInProperty', undefined);
                                }
                                form6.updateValues('needDisclosure', !form6.values.needDisclosure);
                              }}
                            />
                          </FormGroup>
                        </FormControl>

                        <TextareaAutosize
                          className={classes.textArea}
                          rowsMin={2}
                          rowsMax={2}
                          placeholder={langForm6.helperTextParticulars}
                          disabled={form6.values.needDisclosure === false}
                          {...bind('interestsInProperty')}
                          onChange={(e) => {
                            limitTextFieldLength(e, 255, 'interestsInProperty', form6, keyBooleanMap, setKeyBooleanMap);
                          }}
                        />
                        {form6.hasError('interestsInProperty') ? <FormHelperText style={{ color: form6.hasError('interestsInProperty') ? errorColor : 'inherit' }}>{langForm6.msgInputInterestsInProperty}</FormHelperText> : null}
                        <HandleFormHelperText
                          isError={keyBooleanMap.get('interestsInProperty')}
                          errorMessage={lang.textOverLimit}
                        />
                      </TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>


            {/*----------------------- 8. Extra Terms ------------------------ */}
            <Grid
              item
              md={12}
              xs={12}
            >
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="part 13 table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.column0}>8.</TableCell>
                      <TableCell align="left" colSpan={2} >{langForm6.titlePart9}</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>

                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell colSpan={2}>
                        <Typography style={{ paddingBottom: '4px' }}> {langForm6.extraTermsCautions}</Typography>
                        <TextareaAutosize
                          className={classes.textArea}
                          rowsMin={2}
                          rowsMax={2}
                          placeholder={langForm6.helperTextParticulars}
                          {...bind('extraTerms')}
                          onChange={(e) => {
                            limitTextFieldLength(e, 255, 'extraTerms', form6, keyBooleanMap, setKeyBooleanMap);
                          }}
                        />
                        <HandleFormHelperText
                          isError={keyBooleanMap.get('extraTerms')}
                          errorMessage={lang.textOverLimit}
                        />
                      </TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
              </TableContainer>

            </Grid>

          </Grid>



        </Grid>



        {/* ---------------- info of signatory ------------------ */}
        <Grid item container xs={12} spacing={2}>

          {/* ---------tenant's info ----------- */}
          <Grid
            item
            md={6}
            xs={12}
          >
            <TableContainer component={Paper}>
              <Table size="small" aria-label="landlord info table">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2} align="left" >{langForm6.tenantInfo}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* ------------------------ ID no------------------------- */}
                  <TableRow style={{ display: 'none' }}>
                    <TableCell component="th" style={{ width: '35%' }} scope="row">
                      <Typography>{langForm6.HKID}</Typography>
                      <Typography>{langForm6.ifApplicable}</Typography>
                      {form6.hasError('eitherSignatoryInfoOrHKID') ? <FormHelperText style={{ color: form6.hasError('eitherSignatoryInfoOrHKID') ? errorColor : 'inherit' }}>{langForm6.msgInputSignatoryInfoOrHKID}</FormHelperText> : null}
                    </TableCell>

                    <TableCell align="left">
                      <TextField
                        className={classes.infoPartTextField}
                        margin="dense"
                        variant="outlined"
                        // {...bind('tenantHKID')}
                        value={form6.values.tenantHKID ?? ''}
                        onChange={(e) => {
                          if (e.target.value.length > 255) {
                            e.preventDefault();
                          } else {
                            form6.updateValues('tenantHKID', e.target.value);
                            form6.updateValues('nameOfSignatoryOfTenant', undefined);
                            form6.updateValues('positionOfSignatoryOfTenant', undefined);
                            form6.updateValues('businessRegistrationCertificateNumberOfTenant', undefined);
                          }
                        }}
                      />
                    </TableCell>
                  </TableRow>

                  {/* -------------- if tenant is a company------------------------- */}
                  <TableRow>
                    <TableCell colSpan={2} className={classes.subTableCell}>
                      <Typography>{langForm6.whereTenantIsCompany}</Typography>
                    </TableCell>

                  </TableRow>
                  {/* ----------- name ---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row" className={classes.subTableCell}>
                      <Typography className={classes.companyInfo}>{langForm6.nameOfSignatoryOfTenant}</Typography>
                    </TableCell>

                    <TableCell align="left" className={classes.subTableCell}>
                      <TextField
                        className={classes.infoPartTextField}
                        margin="dense"
                        variant="outlined"
                        // {...bind('nameOfSignatoryOfTenant')}
                        error={!!form6.hasError('nameOfSignatoryOfTenant')}
                        helperText={form6.hasError('nameOfSignatoryOfTenant')}
                        value={form6.values.nameOfSignatoryOfTenant ?? ''}
                        onChange={(e) => {
                          if (keyBooleanMap && setKeyBooleanMap) {
                            setKeyBooleanMap(keyBooleanMap.set('nameOfSignatoryOfTenant', e.target.value.length > 255))
                          }
                          if (e.target.value.length > 255) {
                            e.preventDefault();
                            form6.bind('nameOfSignatoryOfTenant').onChange({ target: { value: e.target.value.substring(0, 255) } });
                          } else {
                            form6.bind('nameOfSignatoryOfTenant').onChange(e);
                            form6.updateValues('tenantHKID', undefined);
                          }
                        }}
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('nameOfSignatoryOfTenant')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>
                  {/* ----------- position ---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row" className={classes.subTableCell}>
                      <Typography className={classes.companyInfo}>{langForm6.positionOfSignatoryOfTenant}</Typography>
                    </TableCell>

                    <TableCell align="left" className={classes.subTableCell}>
                      <TextField
                        className={classes.infoPartTextField}
                        margin="dense"
                        variant="outlined"
                        // {...bind('positionOfSignatoryOfTenant')}
                        error={!!form6.hasError('positionOfSignatoryOfTenant')}
                        helperText={form6.hasError('positionOfSignatoryOfTenant')}
                        value={form6.values.positionOfSignatoryOfTenant ?? ''}
                        onChange={(e) => {
                          if (keyBooleanMap && setKeyBooleanMap) {
                            setKeyBooleanMap(keyBooleanMap.set('positionOfSignatoryOfTenant', e.target.value.length > 255))
                          }
                          if (e.target.value.length > 255) {
                            e.preventDefault();
                            form6.bind('positionOfSignatoryOfTenant').onChange({ target: { value: e.target.value.substring(0, 255) } });
                          } else {
                            form6.bind('positionOfSignatoryOfTenant').onChange(e);
                            form6.updateValues('tenantHKID', undefined);
                          }
                        }}
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('positionOfSignatoryOfTenant')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>
                  {/* ----------- business registration certificate number ---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography className={classes.companyInfo}>{langForm6.businessRegistrationCertificateNumberOfTenant}</Typography>
                    </TableCell>

                    <TableCell align="left">
                      <TextField
                        className={classes.infoPartTextField}
                        margin="dense"
                        variant="outlined"
                        // {...bind('businessRegistrationCertificateNumberOfTenant')}
                        error={!!form6.hasError('businessRegistrationCertificateNumberOfTenant')}
                        helperText={form6.hasError('businessRegistrationCertificateNumberOfTenant')}
                        value={form6.values.businessRegistrationCertificateNumberOfTenant ?? ''}
                        onChange={(e) => {
                          if (keyBooleanMap && setKeyBooleanMap) {
                            setKeyBooleanMap(keyBooleanMap.set('businessRegistrationCertificateNumberOfTenant', e.target.value.length > 255))
                          }
                          if (e.target.value.length > 255) {
                            e.preventDefault();
                            form6.bind('businessRegistrationCertificateNumberOfTenant').onChange({ target: { value: e.target.value.substring(0, 255) } });
                          } else {
                            form6.bind('businessRegistrationCertificateNumberOfTenant').onChange(e);
                            form6.updateValues('tenantHKID', undefined);
                          }
                        }}
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('businessRegistrationCertificateNumberOfTenant')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>

                  {/* ----------- address ---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography>{langForm6.address}</Typography>
                    </TableCell>

                    <TableCell align="left">
                      <TextareaAutosize
                        className={classes.textArea}
                        style={{ width: '100%', }}
                        rowsMin={4}
                        rowsMax={4}
                        placeholder=''
                        {...bind('tenantAddress')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'tenantAddress', form6, keyBooleanMap, setKeyBooleanMap);
                        }}
                      />
                      {form6.hasError('tenantAddress') ? <FormHelperText style={{ color: form6.hasError('tenantAddress') ? errorColor : 'inherit' }}>{langForm6.msgInputAddress}</FormHelperText> : null}
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('tenantAddress')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>

                  {/* ----------- phone number---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography >{langForm6.phoneNumber}</Typography>
                    </TableCell>

                    <TableCell align="left" data-key={'field-form6-tenantPhoneNumber'} >
                      <TextField
                        className={classes.infoPartTextField}
                        margin="dense"
                        variant="outlined"
                        {...bind('tenantPhoneNumber')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'tenantPhoneNumber', form6, keyBooleanMap, setKeyBooleanMap);
                        }}
                        helperText={form6.hasError('tenantPhoneNumber')}
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('tenantPhoneNumber')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>

                  {/* ----------- fax number---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography>{langForm6.faxNumber}</Typography>
                    </TableCell>

                    <TableCell align="left">
                      <TextField
                        className={classes.infoPartTextField}
                        margin="dense"
                        variant="outlined"
                        {...bind('tenantFaxNumber')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'tenantFaxNumber', form6, keyBooleanMap, setKeyBooleanMap);
                        }}
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('tenantFaxNumber')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>

                  {/* ----------- date --------- */}
                  {/* <TableRow>
                      <TableCell component="th"  scope="row">
                          <Typography>{langForm6.date}</Typography>
                      </TableCell>

                      <TableCell  align="left">
                        <KeyboardDatePicker autoOk={true}
                           className={classes.infoPartTextField}
                          // InputProps={InputGroup.leftItem()}
                          //disableToolbar
                          variant="inline"
                          inputVariant="outlined"
                          format={DISPLAY_DATE_FORMAT} 
                          margin="dense"
                          value={form6.values.tenantDate ?  moment(form6.values.tenantDate) : null}
                          onChange={(value) => {
                              form6.setValues({
                              ...form6.values,
                              tenantDate: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
                              });
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          /> 
                          {form6.hasError('tenantDate') ? <FormHelperText style={{color: form6.hasError('tenantDate') ?errorColor : 'inherit'}}>{langForm6.msgInputDate}</FormHelperText> : null}
                      </TableCell>
                    </TableRow> */}

                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          {/* ---------agents's info ----------- */}
          <Grid
            item
            md={6}
            xs={12}
          >

            <TableContainer component={Paper}>
              <Table size="small" aria-label="agent info table">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2} align="left" >{langForm6.agentInfo}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  {/* ----------- Name and licence number of the Agent---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography >{langForm6.nameAndLicenceNumberOfAgent}</Typography>
                    </TableCell>

                    <TableCell align="left">
                      <TextField
                        className={classes.infoPartTextField}
                        margin="dense"
                        variant="outlined"
                        {...bind('nameAndLicenceNumberOfAgent')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'nameAndLicenceNumberOfAgent', form6, keyBooleanMap, setKeyBooleanMap);
                        }}
                        helperText={form6.hasError('nameAndLicenceNumberOfAgent')}
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('nameAndLicenceNumberOfAgent')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>

                  {/* ----------- Number of Agent’s statement of particulars of business---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography >{langForm6.numberOfStatementOfParticularsOfBusiness}</Typography>
                    </TableCell>

                    <TableCell align="left">
                      <TextField
                        className={classes.infoPartTextField}
                        disabled={true}
                        margin="dense"
                        variant="outlined"
                        {...bind('numberOfStatementOfParticularsOfBusiness')}
                      />
                    </TableCell>
                  </TableRow>

                  {/* ----------- address ---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography>{langForm6.address}</Typography>
                    </TableCell>

                    <TableCell align="left">
                      <TextareaAutosize
                        className={classes.textArea}
                        disabled={true}
                        style={{ width: '100%', }}
                        rowsMin={4}
                        rowsMax={4}
                        placeholder=''
                        {...bind('agentAddress')}
                      />
                      {form6.hasError('agentAddress') ? <FormHelperText style={{ color: form6.hasError('agentAddress') ? errorColor : 'inherit' }}>{langForm6.msgInputAddress}</FormHelperText> : null}
                    </TableCell>
                  </TableRow>

                  {/* ----------- phone number---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography >{langForm6.phoneNumber}</Typography>
                    </TableCell>

                    <TableCell align="left">
                      <TextField
                        className={classes.infoPartTextField}
                        disabled={true}
                        margin="dense"
                        variant="outlined"
                        {...bind('agentPhoneNumber')}
                      />
                    </TableCell>
                  </TableRow>

                  {/* ----------- fax number---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography>{langForm6.faxNumber}</Typography>
                    </TableCell>

                    <TableCell align="left">
                      <TextField
                        className={classes.infoPartTextField}
                        disabled={true}
                        margin="dense"
                        variant="outlined"
                        {...bind('agentFaxNumber')}
                      />
                    </TableCell>
                  </TableRow>

                  {/* ----------- date --------- */}
                  {/* <TableRow>
                      <TableCell component="th"  scope="row">
                          <Typography>{langForm6.date}</Typography>
                      </TableCell>

                      <TableCell  align="left">
                        <KeyboardDatePicker autoOk={true}
                           className={classes.infoPartTextField}
                          // InputProps={InputGroup.leftItem()}
                          //disableToolbar
                          variant="inline"
                          inputVariant="outlined"
                          format={DISPLAY_DATE_FORMAT} 
                          margin="dense"
                          value={form6.values.agentDate ?  moment(form6.values.agentDate) : null}
                          onChange={(value) => {
                              form6.setValues({
                              ...form6.values,
                              agentDate: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
                              });
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          /> 
                          {form6.hasError('agentDate') ? <FormHelperText style={{color: form6.hasError('agentDate') ?errorColor : 'inherit'}}>{langForm6.msgInputDate}</FormHelperText> : null}

                      </TableCell>
                    </TableRow> */}




                </TableBody>
              </Table>
            </TableContainer>

          </Grid>

          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>

        </Grid>

        {/* ---------------- Schedule 1 ------------------ */}
        <Grid item container xs={12} spacing={2}>
          <Grid
            item
            md={12}
            xs={12}
          >
            <Typography style={{ fontWeight: 'bold' }} data-key={'field-form6-invalidFormProperty'}>{langForm6.titleSchedule1}</Typography>
            {schedule1HasError ? <FormHelperText style={{ color: schedule1HasError ? errorColor : 'inherit' }}>{langForm6.msgInputSchedul1Info}</FormHelperText> : null}
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="schedule 1 table">
                <TableHead>
                  <TableRow>
                    {/* <TableCell className={classes.column0}></TableCell> */}
                    <TableCell className={classes.s1Column1} align="center" style={{ color: schedule1HasError ? errorColor : 'inherit' }}>{langForm6.column1}</TableCell>
                    <TableCell className={classes.s1Column2} align="center" style={{ color: schedule1HasError ? errorColor : 'inherit' }}>{langForm6.column2}
                      {schedule1HasError ? <div><FormHelperText style={{ color: schedule1HasError ? errorColor : 'inherit' }}>{langForm6.msgInputDateToInspect}</FormHelperText></div> : null}
                    </TableCell>
                    <TableCell className={classes.s1Column3} align="center">{langForm6.column3}</TableCell>
                    <TableCell className={classes.s1Column4} align="center">{langForm6.column4}</TableCell>
                    <TableCell className={classes.s1Column5} align="center" style={{ color: schedule1HasError ? errorColor : 'inherit' }}>{langForm6.column5}</TableCell>
                    <TableCell className={classes.s1Column6} align="center">{langForm6.column6}</TableCell>
                    <TableCell className={classes.s1Column7} align="center" style={{ color: schedule1HasError ? errorColor : 'inherit' }}>{langForm6.column7}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {schedule1Table.map((detail: Form6PropertyDTO, i: number) => (
                    <TableRow key={i}>
                      {/* <TableCell></TableCell> */}
                      {/* -------- property --------- */}
                      <TableCell padding='none'>
                        <div className={classes.s1Cell}>
                          <TextareaAutosize
                            className={classes.textArea}
                            style={{ width: '100%' }}
                            rowsMin={2}
                            rowsMax={2}
                            value={detail.property}
                            onChange={(e) => {
                              if (keyBooleanMap && setKeyBooleanMap) {
                                setKeyBooleanMap(keyBooleanMap.set('formProperty', e.target.value.length > 255))
                              }
                              if (e.target.value.length > 255) {
                                e.preventDefault();
                                const updatedDetail = { ...detail, property: e.target.value.substring(0, 255) }
                                form6Schedule1.updateValues('formProperty',
                                  form6Schedule1.values.formProperty?.map((c: Object, idx: number) => idx === i ? updatedDetail : c)
                                );
                              } else {
                                const updatedDetail = { ...detail, property: e.target.value }
                                form6Schedule1.updateValues('formProperty',
                                  form6Schedule1.values.formProperty?.map((c: Object, idx: number) => idx === i ? updatedDetail : c)
                                );
                              }
                            }}
                          />
                          {schedule1HasError && !isNonEmpty(detail.property) ? <FormHelperText style={{ color: schedule1HasError && !isNonEmpty(detail.property) ? errorColor : 'inherit' }}>{langForm6.pleaseInput}</FormHelperText> : null}
                          <HandleFormHelperText
                            isError={keyBooleanMap.get('formProperty')}
                            errorMessage={lang.textOverLimit}
                          />
                        </div>
                      </TableCell>

                      {/* -------- dateOfInspection --------- */}
                      <TableCell padding='none'>
                        <div className={classes.s1Cell}>
                          <KeyboardDatePicker autoOk={true}
                            className={classes.datePicker}
                            style={{ width: '100%' }}
                            //disableToolbar
                            variant="inline"
                            inputVariant="outlined"
                            format={DISPLAY_DATE_FORMAT}
                            // label={langForm5.validityPeriodStart}
                            margin="dense"
                            error={schedule1HasError && detail.notToInspect !== true && !isNonEmpty(detail.dateOfInspection)}
                            helperText={schedule1HasError && detail.notToInspect !== true && !isNonEmpty(detail.dateOfInspection) ? langForm6.pleaseSelect : ''}
                            value={detail.dateOfInspection ? moment(detail.dateOfInspection) : null}
                            onChange={(value) => {
                              const updatedDetail = { ...detail, dateOfInspection: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined, notToInspect: isNonEmpty(value) ? false : detail.notToInspect }
                              form6Schedule1.updateValues('formProperty',
                                form6Schedule1.values.formProperty?.map((c: Object, idx: number) => idx === i ? updatedDetail : c)
                              );
                            }}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </div>
                      </TableCell>

                      {/* -------- not to inspect --------- */}
                      <TableCell align="center">
                        <Checkbox
                          checked={detail.notToInspect}
                          onChange={() => {
                            const updatedDetail = { ...detail, notToInspect: !detail.notToInspect, dateOfInspection: detail.notToInspect === false ? undefined : detail.dateOfInspection }
                            form6Schedule1.updateValues('formProperty',
                              form6Schedule1.values.formProperty?.map((c: Object, idx: number) => idx === i ? updatedDetail : c)
                            );
                          }}
                        />
                      </TableCell>

                      {/* -------- waiveRight --------- */}
                      <TableCell align="center">
                        <Checkbox
                          checked={detail.waiveRight}
                          onChange={() => {
                            const updatedDetail = { ...detail, waiveRight: !detail.waiveRight }
                            form6Schedule1.updateValues('formProperty',
                              form6Schedule1.values.formProperty?.map((c: Object, idx: number) => idx === i ? updatedDetail : c)
                            );
                          }}
                        />
                      </TableCell>

                      {/* -------- agencyRelationShip --------- */}
                      <TableCell padding='none'>
                        <div className={classes.s1Cell}>
                          <TextField
                            className={classes.textField}
                            style={{ width: '100%' }}
                            margin="dense"
                            variant="outlined"
                            select
                            value={detail.agencyRelationship}
                            error={schedule1HasError && !isNonEmpty(detail.agencyRelationship)}
                            helperText={schedule1HasError && !isNonEmpty(detail.agencyRelationship) ? langForm6.pleaseSelect : ''}
                            onChange={(e) => {
                              const updatedDetail = { ...detail, agencyRelationship: e.target.value }
                              form6Schedule1.updateValues('formProperty',
                                form6Schedule1.values.formProperty?.map((c: Object, idx: number) => idx === i ? updatedDetail : c)
                              );
                            }}
                          >
                            <MenuItem key='singleAgency' value='singleAgency'>{langForm6.singleAgency}</MenuItem>
                            <MenuItem key='dualAgency' value='dualAgency'>{langForm6.dualAgency}</MenuItem>
                            <MenuItem key='potentiallyDualAgency' value='potentiallyDualAgency'>{langForm6.potentiallyDualAgency}</MenuItem>
                            <MenuItem key='' value='' style={{ display: 'none' }}>{''}</MenuItem>

                          </TextField>
                        </div>
                      </TableCell>

                      {/* -------- landlordCommission--------- */}
                      <TableCell padding='none'>
                        <div className={classes.s1Cell}>
                          <NumberField
                            className={classes.textField}
                            style={{ width: '100%', textAlignLast: 'end' }}
                            margin="dense"
                            variant="outlined"
                            value={detail.landlordCommission ?? ''}
                            onChange={(e) => {
                              if (!isNonEmpty(e.target.value)) {
                                e.preventDefault();
                              } else if (isNaN(parseFloat(e.target.value))) {
                                e.preventDefault();
                              } else if (e.target.value.match(/^\d*\.?\d*$/) == null) {
                                e.preventDefault();
                              } else if ((e.target.value.indexOf(".") == -1 && e.target.value.length > 12) || (e.target.value.indexOf(".") != -1 && e.target.value.indexOf(".") > 12 || (e.target.value.indexOf('.') != -1 && e.target.value.match(/\./g)!.length >= 2))) {
                                e.preventDefault();
                              } else {
                                const updatedDetail = { ...detail, landlordCommission: isNaN(parseFloat(e.target.value)) ? '' : e.target.value.indexOf(".") == -1 ? e.target.value : (e.target.value.length - (e.target.value.indexOf(".") + 1)) > 2 ? (Math.floor(parseFloat(e.target.value) * 100) / 100).toFixed(2) : e.target.value }
                                form6Schedule1.updateValues('formProperty',
                                  form6Schedule1.values.formProperty?.map((c: Object, idx: number) => idx === i ? updatedDetail : c)
                                );
                              }
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <Select
                                    defaultValue={'$'}
                                    disabled={true}
                                  >
                                    <MenuItem key='vedorCommision$' value='$'>$</MenuItem>
                                  </Select>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      </TableCell>

                      {/* -------- tenantCommission --------- */}
                      <TableCell padding='none'>
                        <div className={classes.s1Cell}>
                          <NumberField
                            className={classes.textField}
                            style={{ width: '100%', textAlignLast: 'end' }}
                            margin="dense"
                            variant="outlined"
                            value={detail.tenantCommission ?? ''}
                            error={schedule1HasError && !isNonEmpty(detail.tenantCommission)}
                            helperText={schedule1HasError && !isNonEmpty(detail.tenantCommission) ? langForm6.pleaseInput : ''}

                            onChange={(e) => {

                              if ((e.target.value.indexOf(".") == -1 && e.target.value.length > 12) || (e.target.value.indexOf(".") != -1 && e.target.value.indexOf(".") > 12 || (e.target.value.indexOf('.') != -1 && e.target.value.match(/\./g)!.length >= 2))) {
                                e.preventDefault();
                              } else {

                                const updatedDetail = { ...detail, tenantCommission: isNaN(parseFloat(e.target.value)) ? '' : e.target.value.indexOf(".") == -1 ? e.target.value : (e.target.value.length - (e.target.value.indexOf(".") + 1)) > 2 ? (Math.floor(parseFloat(e.target.value) * 100) / 100).toFixed(2) : e.target.value }
                                form6Schedule1.updateValues('formProperty',
                                  form6Schedule1.values.formProperty?.map((c: Object, idx: number) => idx === i ? updatedDetail : c)
                                );
                              }
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <Select
                                    disabled={true}
                                    defaultValue={'$'}
                                  >
                                    <MenuItem key='vedorCommision$' value='$'>$</MenuItem>
                                  </Select>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

          </Grid>


          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>
        </Grid>

        {/* ---------------- Schedule 2 ------------------ */}
        <Grid item container xs={12} spacing={2}>

          <Grid
            item
            md={12}
            xs={12}
          >
            <Typography style={{ fontWeight: 'bold' }} >{langForm6.titleSchedule2}</Typography>
          </Grid>

          {/*------------- Commission ------------ */}
          <Grid
            item
            md={12}
            xs={12}
          >
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="schedule 2 table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.column0}>1.</TableCell>
                    <TableCell align="left" colSpan={2} >{langForm6.titleCommission}</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {/*----------- payment time ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell className={classes.column1} >{langForm6.paymentTime}</TableCell>
                    <TableCell>
                      <RadioGroup defaultValue="" aria-label="payment time" name="customized-radios" row
                        value={form6.values.paymentTimeOption ?? ''}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          const val = (event.target as HTMLInputElement).value;
                          form6.updateValues('paymentTimeOption', val);
                        }}
                      >
                        <FormControlLabel value="option1" control={<Radio />} label={langForm6.paymentTimeOption1} />
                        <FormControlLabel value="option2" control={<Radio />} label={langForm6.paymentTimeOption2} />
                        <FormControlLabel value="" control={<Radio size="small" />} label='' style={{ display: 'none' }} />
                      </RadioGroup>
                      {/* {form4.hasError('paymentTimeOption') ? <FormHelperText style={{color: form4.hasError('paymentTimeOption') ?errorColor : 'inherit'}}>{langForm6.msgSelectPaymentTime}</FormHelperText> : null} */}
                    </TableCell>
                  </TableRow>


                </TableBody>
              </Table>
            </TableContainer>

            {/* ------------------------ Schedule 2 point 2 ---------------- */}
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="schedule 2 point 2 table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.column0}>2.</TableCell>
                    <TableCell align="left" >{langForm6.titleSchedulePoint2}</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell>
                      <FormControl component="fieldset" style={{ paddingLeft: '10px', paddingTop: '7px' }}>
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            control={<Checkbox color="primary" />}
                            label={langForm6.withInterest}
                            labelPlacement="end"
                            checked={form6.values.withInterest}
                            onChange={() => {
                              form6.updateValues('withInterest', !form6.values.withInterest);
                            }}
                          />
                        </FormGroup>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

          </Grid>

          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>

        </Grid>

      </CardContent>

      <CardActions style={{ justifyContent: 'flex-end', display: 'none' }}>
        <Button variant="contained" color="primary">生成PDF檔案</Button>
      </CardActions>
    </Card>
  )
};

export default Form6Dialog;
