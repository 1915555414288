import { registerPlugin } from '@capacitor/core';

export interface StatusBarIOSPlugin {
  getStatusBarHeight(): Promise<{ value: any }>;
  disableScale(): Promise<{ value: any }>;
}


const StatusBarIOSPlugin = registerPlugin<any>('StatusBarIOSPlugin');

export default StatusBarIOSPlugin;