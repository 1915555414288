// import { makeStyles } from '@material-ui/styles';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { hasPermission } from 'common/access-control';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { IRootState } from 'reducers';

const useStyles = makeStyles(theme => ({
  root: {},
  title: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const UsersToolbar = (props: any) => {
  const { className, ...rest } = props;
  const { langBuilding: lang} = useSelector((state: IRootState) => state.locale);
  const classes = useStyles();
  const history = useHistory();

  const { privileges } = useSelector((state: IRootState) => state.login);
  const canCreate = hasPermission(privileges, 'CREATE', 'BUILDING');

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <Typography
          className={classes.title}
          variant="h2"
        >
          {lang.titleBuildingSearch}
        </Typography>
        <span className={classes.spacer} />
        {/* <Button className={classes.importButton}>Import</Button>
        <Button className={classes.exportButton}>Export</Button> */}
        <Button
          color="primary"
          variant="contained"
          disabled={!canCreate}
          onClick={() => history.push('/buildings/new')}
        >
          {lang.actionNewBuilding}
        </Button>
      </div>
      {/* <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search user"
        />
      </div> */}
    </div>
  );
};

UsersToolbar.propTypes = {
  className: PropTypes.string
};

export default UsersToolbar;
