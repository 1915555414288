import { ApiResult } from 'api';
import { LoginStateDTO } from 'common/dto';

const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];

export const fetchLogin = async ({ username, password, deviceId }: { username: string, password: string, deviceId?: string }): Promise<ApiResult<LoginStateDTO>> => {
    const payload = new FormData();
    // payload.append('username', 'admin');
    // payload.append('password', 'P@ssw0rd');
    payload.append('username', username);
    payload.append('password', password);
    if (deviceId) {
        payload.append('deviceId', deviceId);
    }

    const res = fetch(`${BASE_URL}/login`, {
        method: 'POST',
        body: payload,
    });

    return (await res).json();
}

export const logout = async (token: string, deviceId?: string): Promise<ApiResult<boolean>> => {
    try {
        const payload = new FormData();
        payload.append('token', token);
        if (deviceId) {
            payload.append('deviceId', deviceId);
        }

        return await fetch(`${BASE_URL}/logout`, {
            method: 'POST',
            body: payload,
        }).then(res => res.json());
    } catch (e) {
        return { error: String(e) };
    }
}

export const renew = async (token: string): Promise<ApiResult<LoginStateDTO>> => {

    try {
        const payload = new FormData();
        payload.append('token', token);

        let result: Promise<ApiResult<LoginStateDTO>> = await fetch(`${BASE_URL}/login/renew`, {
            method: 'POST',
            body: payload,
        }).then(res => res.json());

        // if ((await result).error) {
        //     throw Error;
        // }

        return result;

    } catch (e) {
        localStorage.removeItem('impersonateUserToken');
        //store.dispatch({ type: 'Login.LogoutRequested' });
        return { error: String(e) };
    }
}

export const registerDevice = async (deviceId: string, deviceType: string, fcmRegToken: string): Promise<ApiResult<LoginStateDTO>> => {
    try {
        const payload = new FormData();
        payload.append('deviceId', deviceId);
        payload.append('deviceType', deviceType);
        payload.append('fcmRegToken', fcmRegToken);

        return await fetch(`${BASE_URL}/register-device`, {
            method: 'POST',
            body: payload,
        }).then(res => res.json());
    } catch (e) {
        return { error: String(e) };
    }
}

export const loadOptions = async (_token: string): Promise<any> => {
    try {
        return await fetch(`${BASE_URL}/options`, {
            method: 'GET',
        }).then(res => res.json());
    } catch (e) {
        return { error: String(e) };
    }
}