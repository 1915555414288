import { ApiPageResult, ApiResult } from 'api';
import { CommissionReportListItemDTO } from 'common/dto';
import { objectToQuery } from 'common/utils';

const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];

export const agentRatingCommentApi = {
	getList: async (criteria: any = {}, token: string): Promise<ApiPageResult<CommissionReportListItemDTO>> => {
		try {
			const response = await fetch(`${BASE_URL}/agentRatingCommentMgt?${objectToQuery(criteria)}`, {
				headers: { 'Authorization': `Bearer ${token}` }
			});
			return await response.json();
		} catch (e) {
			return { error: String(e) };
		}  
	},

	approveAction: async (payload: any, token: string): Promise<ApiResult<any>> => {
		const { ids } = payload;
		const formData = new FormData();
		formData.append('ids', ids);
		try {
			const response = await fetch(`${BASE_URL}/agentRatingCommentMgt/approve`, {
				method: 'PATCH', body: formData,
				headers: { 'Authorization': `Bearer ${token}` },
			});
			return await response.json();
		} catch (e) {
			return { error: String(e) };
		}  
	}, 

	rejectAction: async (payload: any, token: string): Promise<ApiResult<any>> => {
		const { ids } = payload;
		const formData = new FormData();
		formData.append('ids', ids);
		try {
			const response = await fetch(`${BASE_URL}/agentRatingCommentMgt/reject`, {
				method: 'PATCH', body: formData,
				headers: { 'Authorization': `Bearer ${token}` },
			});
			return await response.json();
		} catch (e) {
			return { error: String(e) };
		}  
	}, 

}

export default agentRatingCommentApi;