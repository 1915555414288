import { Button, Chip, Dialog, DialogActions, DialogContent, fade, InputBase, makeStyles, Theme, Typography, useTheme } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import { isNonEmpty } from "common/utils";
import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { IRootState } from "reducers";

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    district: {
      marginBottom: 10,
      marginTop: 10,
      background: '#fff'
    },
  dialogContent: {},
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: '100%',
      // [theme.breakpoints.up('sm')]: {
      //   marginLeft: theme.spacing(1),
      //   width: 'auto',
      // },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
      width: '100%'
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      // [theme.breakpoints.up('sm')]: {
      //   width: '12ch',
      //   '&:focus': {
      //     width: '20ch',
      //   },
      // },
    },
  
}));

interface SelectDistrictDialogProps {
    // form: FormPresenter<T>;
    open: boolean;
    handleClose: () => void;
    handleClickOpen: () => void;
    value: string[];
    toggleChip: (key: string) => any;
    regionAllSelected: (key: any) => any;
    toggleDistrictByRegion: (key: any) => any;
}

export function SelectDistrictDialog({ value, regionAllSelected, toggleDistrictByRegion, toggleChip, open, handleClose, handleClickOpen }: SelectDistrictDialogProps) {
  const localeBundle = useSelector((state: IRootState) => state.locale);
  const classes = useStyles();
  const { lang, districtHkiOptions, districtNtOptions, districtKltOptions, } = localeBundle;

  // const regionMap = {
  //   HKI: districtHkiOptions,
  //   KLT: districtKltOptions,
  //   NT: districtNtOptions,
  // };

  // const allDistrictOptions = {
  //   ...districtHkiOptions,
  //   ...districtKltOptions,
  //   ...districtNtOptions,
  // };

  // const regionAllSelected = (region: keyof typeof regionMap) => {
  //   const all = Object.keys(regionMap[region]);
  //   const current = Array.from(value ?? []);
  //   const selected = current.filter(n => all.indexOf(n) > -1);
  //   return selected.length === all.length;
  // };
  // const toggleDistrictByRegion = (region: keyof typeof regionMap) => {
  //   const all = Object.keys(regionMap[region]);
  //   const current = Array.from(value ?? []);
  //   const selected = current.filter(n => all.indexOf(n) > -1);
  //   if (selected.length === all.length) {
  //     // all selected, clear all
  //     onChange(current.filter(n => selected.indexOf(n) < 0));
  //   } else if (selected.length < all.length) {
  //     current.push(...all.filter(n => selected.indexOf(n) < 0));
  //     onChange(current);
  //   }
  // };
  //--------- select dialog
  const theme = useTheme();
  // const [open, setOpen] = React.useState(false);
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };
  // const toggleChip = (key: string) =>{
  //   if (value.includes(key)){
  //     onChange(value.filter(v => v !== key))
  //   }else{
  //     onChange([...value, key]);
  //   }
  // }
  //--- search district
  const { districtHkiOptions: districtHkiOptionsEn, districtNtOptions: districtNtOptionsEn, districtKltOptions: districtKltOptionsEn } = useSelector((state: IRootState) => state.locale._bundle['en']);
  const { districtHkiOptions: districtHkiOptionsZhHK, districtNtOptions: districtNtOptionsZhHK, districtKltOptions: districtKltOptionsZhHK } = useSelector((state: IRootState) => state.locale._bundle['zh_HK']);

  let searchDistrictHkiOptions: { [key: string]: string; } = {};
  let searchDistrictKltOptions: { [key: string]: string; } = {};
  let searchDistrictNtOptions: { [key: string]: string; } = {};

  for (const [key, value] of Object.entries(districtHkiOptionsEn)) {
    searchDistrictHkiOptions[value.toLowerCase()] = key;
  }
  for (const [key, value] of Object.entries(districtHkiOptionsZhHK)) {
    searchDistrictHkiOptions[value.toLowerCase()] = key;
  }
  for (const [key, value] of Object.entries(districtKltOptionsEn)) {
    searchDistrictKltOptions[value.toLowerCase()] = key;
  }
  for (const [key, value] of Object.entries(districtKltOptionsZhHK)) {
    searchDistrictKltOptions[value.toLowerCase()] = key;
  }
  for (const [key, value] of Object.entries(districtNtOptionsEn)) {
    searchDistrictNtOptions[value.toLowerCase()] = key;
  }
  for (const [key, value] of Object.entries(districtNtOptionsZhHK)) {
    searchDistrictNtOptions[value.toLowerCase()] = key;
  }

  const [keyword, setKeyword] = useState('');

  //----- districts that contain the keyword
  const districtHkiOptionsResultKey = Object.keys(searchDistrictHkiOptions)
    .filter(districtName => districtName.search(keyword) > -1)
    .map(districtName => searchDistrictHkiOptions[districtName]);
  const districtKltOptionsResultKey = Object.keys(searchDistrictKltOptions)
    .filter(districtName => districtName.search(keyword) > -1)
    .map(districtName => searchDistrictKltOptions[districtName]);
  const districtNtOptionsResultKey = Object.keys(searchDistrictNtOptions)
    .filter(districtName => districtName.search(keyword) > -1)
    .map(districtName => searchDistrictNtOptions[districtName]);

  //----- display districts === matched keyword || selected
  const displayDistrictHkiOptionsKey = Object.keys(districtHkiOptions)
    .filter(v => (isNonEmpty(keyword) ? districtHkiOptionsResultKey.includes(v) : true) || value.includes(v));

  const displayDistrictKltOptionsKey = Object.keys(districtKltOptions)
    .filter(v => (isNonEmpty(keyword) ? districtKltOptionsResultKey.includes(v) : true) || value.includes(v));

  const displayDistrictNtOptionsKey = Object.keys(districtNtOptions)
    .filter(v => (isNonEmpty(keyword) ? districtNtOptionsResultKey.includes(v) : true) || value.includes(v));

  return (<div>

    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">

      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder={lang.captionSearch}
          value={keyword}
          onChange={(e) => { setKeyword(e.target.value?.toLowerCase()); }}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'search' }} />
      </div>
      <DialogContent className={classes.dialogContent}>

        <Typography className={classes.district}>
          <div style={{ cursor: 'pointer', userSelect: 'none', fontWeight: regionAllSelected('HKI') ? 'bold' : 'normal' }} onClick={(e) => {
            setTimeout(() => toggleDistrictByRegion('HKI'), 0);
          }}>{lang.captionHKI}</div>
        </Typography>


        {displayDistrictHkiOptionsKey.map((key: string) => (
          <Chip
            key={key}
            label={districtHkiOptions[key]}
            clickable
            onClick={(e) => {
              toggleChip(key);
            }}
            size="medium"
            style={{ marginBottom: theme.spacing(.5), marginLeft: theme.spacing(.5), background: value.includes(key) ? '#444' : 'inherit' }}
            variant={value.includes(key) ? 'default' : 'outlined'}
            color={value.includes(key) ? 'primary' : 'default'} />
        ))}

        <Typography className={classes.district}>
          <div style={{ cursor: 'pointer', userSelect: 'none', fontWeight: regionAllSelected('HKI') ? 'bold' : 'normal' }} onClick={(e) => {
            setTimeout(() => toggleDistrictByRegion('KLT'), 0);
          }}>{lang.captionKLN}</div>
        </Typography>
        {displayDistrictKltOptionsKey.map((key: string) => (
          <Chip
            key={key}
            label={districtKltOptions[key]}
            clickable
            onClick={(e) => {
              toggleChip(key);
            }}
            size="medium"
            style={{ marginBottom: theme.spacing(.5), marginLeft: theme.spacing(.5), background: value.includes(key) ? '#444' : 'inherit' }}
            variant={value.includes(key) ? 'default' : 'outlined'}
            color={value.includes(key) ? 'primary' : 'default'} />
        ))}


        <Typography className={classes.district}>
          <div style={{ cursor: 'pointer', userSelect: 'none', fontWeight: regionAllSelected('HKI') ? 'bold' : 'normal' }} onClick={(e) => {
            setTimeout(() => toggleDistrictByRegion('NT'), 0);
          }}>{lang.captionNT}</div>
        </Typography>
        {displayDistrictNtOptionsKey.map((key: string) => (
          <Chip
            key={key}
            label={districtNtOptions[key]}
            clickable
            onClick={(e) => {
              toggleChip(key);
            }}
            size="medium"
            style={{ marginBottom: theme.spacing(.5), marginLeft: theme.spacing(.5), background: value.includes(key) ? '#444' : 'inherit' }}
            variant={value.includes(key) ? 'default' : 'outlined'}
            color={value.includes(key) ? 'primary' : 'default'} />
        ))}


      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {lang.actionDone}
        </Button>
      </DialogActions>
    </Dialog>
  </div>);
}
