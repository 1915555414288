import {
  Button, Card, CardActions, CardContent, Checkbox, Divider, FormControl, FormControlLabel,
  FormGroup, FormHelperText, Grid, InputAdornment, makeStyles, MenuItem, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, TextareaAutosize, TextField, TextFieldProps, Typography, withStyles
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import clsx from 'clsx';
import { Form3DTO, PropertyStockDTO } from 'common/dto';
import { FormPresenter } from "common/hooks";
import { HandleFormHelperText } from 'common/ui';
import { genAddress, initFormKeysAndBooleanMap, isNonEmpty, limitNumberFieldLength, limitTextFieldLength, restrictDecimal } from 'common/utils';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducers';

const regex = new RegExp(/(^\d*\.?\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/);
const NumberField = withStyles({
  root: {
    '& input': {
      textAlign: 'right',
      '-moz-appearance': 'textfield',
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  },
},
)((props: TextFieldProps) => {
  return <TextField type={"text"}{...props} inputProps={{
    ...props.inputProps,
    min: 0,
    max: Math.max(),
    inputMode: 'decimal',
    pattern: "([^0-9]*)",
    step: 0.01
  }}

    onKeyDown={e => {
      // If the key pressed is not a number or a period, nothing is printed
      if (!e.key.match(regex)) {

        e.preventDefault();

      }
      // if (!e.key.match(regex) || (e.key === '.' && String(props.value).indexOf('.') > 0) || (e.key === '.' && String(props.value).length === 0) || e.keyCode === 229 || e.key === 'Unidentified') {

      //   e.preventDefault();

      // }

    }}

    onKeyPress={e => {
      // If the key pressed is not a number or a period, nothing is printed
      if (!e.key.match(regex)) {

        e.preventDefault();

      }
    }}


  />;
});

const useStyles = makeStyles(() => ({
  root: {},
  partTitle: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  textArea: {
    width: '100%',
    margin: 'dense',
    padding: '8px',
    fontSize: '14px'
  },
  table: {
    minWidth: 650,
  },
  textField: {
    width: '30%',
  },
  datePicker: {
    width: '30%',
  },
  column0: {
    width: '3%',
  },
  column1: {
    width: '13%',
  },
  numberFields: {
    width: '30%',
  },
  checkboxColumn: {
    width: '5%',
  },
  subTableCell: {
    borderBottomColor: '#FFFFFF',
  },
  divider: {
    backgroundColor: '#1565c0',
  },
  infoPartTextField: {
    width: '100%',
  },
  companyInfo: {
    paddingLeft: '20px',
  },
  part6DisabledText: {
    "& .MuiFormControlLabel-label": {
      color: 'black',
    }
  }
}));

const errorColor = '#e53935';

export const form3Initial: Partial<Form3DTO> = {
  relationship: 'potentiallyDualAgency',
  vendorAcknowledgementOption: 'option2',
  commissionIn: 'percentage',
  commissionRate: 1,
  allowViewingPerson: 'both',
  passKeyToPerson: 'both',
  exclusiveAgent: false,
  allowViewing: true,
  passKeyForSafeCustody: false,
  passKeyToThirdParty: false,
  allowSubListing: true,
  allowAdvertisement: true,
  needDisclosure: false,
  withInterest: false,
  paymentTimeOption: 'option1'
};

interface Form3Props {
  className?: string;
  propertyStockId: string;
  form3: FormPresenter<Form3DTO>;
  landsearchdate: string;
}

const disablePrevDates = (startDates: any) => {
  const startSeconds = Date.parse(startDates);
  return (date: any) => {
    return Date.parse(date) < startSeconds;
  }
}

const Form3Dialog = (props: Form3Props) => {
  const { className, propertyStockId, form3, ...rest } = props;
  const { langForm3, lang, locale, langPropertyStock, formLocaleOptions } = useSelector((state: IRootState) => state.locale);
  const agencyCompanyInfo = useSelector((state: IRootState) => state.systemSettings.Company.AGENCY_COMPANY_INFO);
  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);

  const dispatch = useDispatch();
  const classes = useStyles();
  const bind = form3.bind;

  const [keyBooleanMap, setKeyBooleanMap] = useState(initFormKeysAndBooleanMap(form3));

  useEffect(() => {
    setKeyBooleanMap(initFormKeysAndBooleanMap(form3));
  }, [Object.keys(form3.values).length])

  const requiredFields: { [key: string]: string[] } = {
    form: ['formLocale', 'vendorName', 'agentName', 'propertyAddress', 'isValidityPeriodValid', 'validityPeriodStart', 'validityPeriodEnd', 'listPrice', 'interestsInProperty', 'vendorAcknowledgementOption'],
    vendorInfo: ['eitherSignatoryInfoOrHKID', 'nameOfSignatoryOfVendor', 'positionOfSignatoryOfVendor', 'businessRegistrationCertificateNumberOfVendor', 'vendorAddress', 'vendorPhoneNumber'],
    agentInfo: ['nameAndLicenceNumberOfAgent', 'numberOfStatementOfParticularsOfBusiness', 'agentAddress', 'agentPhoneNumber'],
  };

  const partNameMapping = (partName: string) => {
    switch (partName) {
      case 'form': return langForm3.form;
      case 'vendorInfo': return langForm3.vendorInfo;
      case 'agentInfo': return langForm3.agentInfo;
    }
  }

  const missingFieldList = () => {
    let resultStr: string[] = [];
    Object.keys(requiredFields).forEach(part => {
      let partMissingFields: string[] = [];
      requiredFields[part].forEach(key => {
        let validationMsg = form3.hasError(key as keyof Form3DTO);
        if (validationMsg) {
          partMissingFields.push(validationMsg?.replace(langForm3.pleaseInput, '')?.replace(langForm3.pleaseSelect, ''));
        }
      });
      if (partMissingFields.length > 0) {
        resultStr.push(partNameMapping(part.toString()) + ": " + partMissingFields.join(', '));
      }

    });
    return resultStr.join('\n');
  }

  const propertyStockDto = useSelector((state: IRootState) => state.property.fetchedDetailsByPropertyStockId[props.propertyStockId]) ?? {} as Partial<PropertyStockDTO>;
  const { districtHkiOptions: districtHkiOptionsEn, districtNtOptions: districtNtOptionsEn, districtKltOptions: districtKltOptionsEn } = useSelector((state: IRootState) => state.locale._bundle['en']);
  const { districtHkiOptions: districtHkiOptionsZhHK, districtNtOptions: districtNtOptionsZhHK, districtKltOptions: districtKltOptionsZhHK } = useSelector((state: IRootState) => state.locale._bundle['zh_HK']);

  const districtEnOptions = {
    ...districtHkiOptionsEn,
    ...districtNtOptionsEn,
    ...districtKltOptionsEn,
  };
  const districtZhHkOptions = {
    ...districtHkiOptionsZhHK,
    ...districtNtOptionsZhHK,
    ...districtKltOptionsZhHK,
  };

  const companyDetailsLocale = (locale: string) => {
    return (locale === 'en') ? { name: agencyCompanyInfo['COMPANY_NAME_EN'], address: agencyCompanyInfo['ADDRESS_EN'] } : { name: agencyCompanyInfo['COMPANY_NAME_CN'], address: agencyCompanyInfo['ADDRESS_CN'] };
  };
  const propertyAddress = (locale: string) => {

    const address = genAddress(locale,
      propertyStockDto.unit?.join(',') ?? '',
      propertyStockDto.floor?.join(',') ?? '',
      propertyStockDto.block ?? '', propertyStockDto.blockEn ?? '',
      propertyStockDto.building ?? '', propertyStockDto.buildingEn ?? '',
      propertyStockDto.street ?? '', propertyStockDto.streetEn ?? '',
      (locale === 'en') ? districtEnOptions[propertyStockDto.district ?? ''] : districtZhHkOptions[propertyStockDto.district ?? '']);
    return address;
  };

  const updateCompanyCurrentLocaleDetails = (locale: string) => {
    let details = companyDetailsLocale(locale);
    form3.updateValues('agentName', details.name);
    form3.updateValues('agentAddress', details.address);
    form3.updateValues('propertyAddress', propertyAddress(locale));
  };

  return (
    <Card {...rest}
      className={clsx(classes.root, className)}>
      {/* 生成出售物業代理協議 (表格3) */}
      {/* <CardHeader></CardHeader> */}
      {/* <Divider /> */}
      <CardContent>
        <Grid item container xs={12} spacing={2}>
          {isNonEmpty(missingFieldList()) ?
            <Grid
              item
              md={12}
              xs={12}
            >
              <div style={{ marginBottom: '5px', whiteSpace: "pre-wrap" }}>
                <Typography style={{ color: errorColor, fontSize: '13px', fontWeight: 'bold', fontStyle: 'italic' }}>{langForm3.msgInputMissingFields}</Typography>
                <Typography style={{ color: errorColor, fontSize: '13px', fontStyle: 'italic' }}>{missingFieldList()}</Typography>
              </div>
              <Divider />
            </Grid>
            : null
          }
          <Grid
            item
            sm={2}
            md={2}
            xs={12}
          >
            <Typography>{langPropertyStock.captionFormLocale}</Typography>
            <TextField select
              fullWidth
              margin="dense"
              {...bind('formLocale')}
              required
              variant="outlined"
              onChange={(event) => {
                bind('formLocale').onChange(event);
                updateCompanyCurrentLocaleDetails(event.target.value);
              }}>
              {
                Object.keys(formLocaleOptions).map((opt) => <MenuItem value={opt}>{formLocaleOptions[opt]}</MenuItem>)
              }
            </TextField>
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >
            <Typography className={classes.partTitle} >{langForm3.formName}</Typography>
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >
            {/*------------- 1. Appointment of Agent and Validity Period of this Agreement------------ */}
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="part 1 table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.column0}>1.</TableCell>
                    <TableCell colSpan={2} align="left" className={classes.column1} >{langForm3.titlePart1}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/*----------- vendor name ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell className={classes.column1} >{langForm3.vendorName}</TableCell>
                    <TableCell>
                      <TextField
                        className={classes.textField}
                        margin="dense"
                        variant="outlined"
                        {...bind('vendorName')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'vendorName', form3, keyBooleanMap, setKeyBooleanMap);
                        }}
                        helperText={form3.hasError('vendorName')}
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('vendorName')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>
                  {/*----------- agent name ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell className={classes.column1} >{langForm3.agentName}</TableCell>
                    <TableCell>
                      <TextField
                        className={classes.textField}
                        margin="dense"
                        variant="outlined"
                        disabled={true}
                        {...bind('agentName')}
                      />

                      <FormControl component="fieldset" style={{ paddingLeft: '10px', paddingTop: '7px' }}>
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            control={<Checkbox color="primary" />}
                            label={langForm3.exclusiveAgent}
                            labelPlacement="end"
                            checked={form3.values.exclusiveAgent}
                            onChange={() => {
                              form3.updateValues('exclusiveAgent', !form3.values.exclusiveAgent);
                            }}
                          />
                        </FormGroup>
                      </FormControl>
                    </TableCell>

                  </TableRow>

                  {/*----------- property address ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell className={classes.column1} >{langForm3.propertyAddress}</TableCell>
                    <TableCell>
                      <TextareaAutosize
                        className={classes.textArea}
                        style={{ width: '30%' }}
                        rowsMin={4}
                        rowsMax={5}
                        placeholder=''
                        {...bind('propertyAddress')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'propertyAddress', form3, keyBooleanMap, setKeyBooleanMap);
                        }}
                      />
                      {form3.hasError('propertyAddress') ? <FormHelperText style={{ color: form3.hasError('propertyAddress') ? errorColor : 'inherit' }}>{langForm3.pleaseInput + langForm3.propertyAddress}</FormHelperText> : null}
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('propertyAddress')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>
                  {/*----------- validity period ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row" data-key={'field-form3-isValidityPeriodValid'}></TableCell>
                    <TableCell className={classes.column1} data-key={'field-form3-validityPeriodStart'}>
                      {langForm3.validityPeriod}
                      <div style={{ display: 'inline-block', width: '80%' }}>
                        {form3.hasError('isValidityPeriodValid') ? <FormHelperText style={{ color: form3.hasError('isValidityPeriodValid') ? errorColor : 'inherit' }}>{form3.validationErrors?.isValidityPeriodValid}</FormHelperText> : null}
                      </div>
                    </TableCell>
                    <TableCell data-key={'field-form3-validityPeriodEnd'}>
                      <KeyboardDatePicker autoOk={true}
                        className={classes.datePicker}
                        //disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        format={DISPLAY_DATE_FORMAT}
                        margin="dense"
                        shouldDisableDate={disablePrevDates(props.landsearchdate)}
                        label={langForm3.validityPeriodStart}
                        value={form3.values.validityPeriodStart ? moment(form3.values.validityPeriodStart) : null}
                        onChange={(value) => {
                          form3.setValues({
                            ...form3.values,
                            validityPeriodStart: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
                          });
                          // if (value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString()?.localeCompare(form3.values.validityPeriodEnd ?? "") === 1) {
                          //   form3.updateValues('validityPeriodEnd', "");
                          // }
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                      {' '}
                      {form3.values.validityPeriodStart?.length !== 20 && form3.values.validityPeriodStart?.localeCompare(form3.values.validityPeriodEnd ?? "") === 1 ? form3.values.validityPeriodEnd = "" : ""}
                      <KeyboardDatePicker autoOk={true}
                        className={classes.datePicker}
                        // InputProps={InputGroup.leftItem()}
                        //disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        format={DISPLAY_DATE_FORMAT}
                        label={langForm3.validityPeriodEnd}
                        margin="dense"
                        shouldDisableDate={disablePrevDates(form3.values.validityPeriodStart)}
                        value={form3.values.validityPeriodEnd ? moment(form3.values.validityPeriodEnd) : null}
                        onChange={(value) => {
                          form3.setValues({
                            ...form3.values,
                            validityPeriodEnd: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
                          });
                          // if (value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString()?.localeCompare(form3.values.validityPeriodStart ?? "") === -1) {
                          //   form3.updateValues('validityPeriodStart', "");
                          // }
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                      {langForm3.validityPeriodRemarks}
                      <div>
                        <div style={{ display: 'inline-block', width: '30%' }}>
                          {form3.hasError('validityPeriodStart') ? <FormHelperText style={{ color: form3.hasError('validityPeriodStart') ? errorColor : 'inherit' }}>{form3.validationErrors?.validityPeriodStart}</FormHelperText> : null}
                        </div>
                        <div style={{ display: 'inline-block', width: '30%' }}>
                          {form3.hasError('validityPeriodEnd') ? <FormHelperText style={{ color: form3.hasError('validityPeriodEnd') ? errorColor : 'inherit' }}>{form3.validationErrors?.validityPeriodEnd}</FormHelperText> : null}
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            {/* ------------- 2. Agency Relationship and Duties of Agent ------------- */}
            <Grid
              item
              md={12}
              xs={12}
            >
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="part 2 table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.column0}>2.</TableCell>
                      <TableCell colSpan={2} align="left" data-key={'field-form3-relationship'} >{langForm3.titlePart2}</TableCell>
                      {/* <TableCell></TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell className={classes.column1}>{langForm3.relationship}
                      </TableCell>
                      <TableCell>
                        <div >
                          <FormControl component="fieldset" style={{}}>
                            {/* <FormLabel component="legend">Gender</FormLabel> */}
                            <RadioGroup aria-label="Relationships" name="customized-radios" row
                              value={form3.values.relationship ?? ''}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const val = (event.target as HTMLInputElement).value;
                                form3.updateValues('relationship', val);
                              }}
                            >
                              <FormControlLabel
                                value="singleAgency"
                                control={<Radio size="small" />}
                                label={langForm3.singleAgency}
                              />
                              <FormControlLabel
                                value="dualAgency"
                                control={<Radio size="small" />}
                                label={langForm3.dualAgency}
                              />
                              <FormControlLabel
                                value="potentiallyDualAgency"
                                control={<Radio size="small" />}
                                label={langForm3.potentiallyDualAgency}
                              />
                              <FormControlLabel value="" control={<Radio size="small" />} label='' style={{ display: 'none' }} />
                            </RadioGroup>
                          </FormControl>
                        </div>
                        {form3.hasError('relationship') ? <FormHelperText style={{ color: form3.hasError('relationship') ? errorColor : 'inherit' }}>{langForm3.msgSelectRelationship}</FormHelperText> : null}

                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/*------------- 3. List Price ------------ */}
            <Grid
              item
              md={12}
              xs={12}
            >
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="part 3 table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.column0}>3.</TableCell>
                      <TableCell align="left" className={classes.column1} >{langForm3.titlePart3}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell>{langForm3.titlePart3}
                      </TableCell>
                      <TableCell>
                        <NumberField
                          className={classes.numberFields}
                          margin="dense"
                          variant="outlined"
                          InputProps={{
                            startAdornment: <InputAdornment position="start">{langForm3.HKDollar}</InputAdornment>,
                            endAdornment: <InputAdornment position="start">{locale === 'en' ? lang.u1M : lang.u10k}</InputAdornment>,
                          }}
                          {...bind('listPrice')}
                          helperText={form3.hasError('listPrice')}
                          onChange={(e) => {
                            if (!isNonEmpty(e.target.value)) {
                              form3.updateValues('listPrice', '');
                            } else if (isNaN(parseFloat(e.target.value))) {
                              e.preventDefault();
                            } else if (e.target.value.indexOf('.') != -1 && e.target.value.match(/\./g)!.length >= 2) {
                              e.preventDefault()
                            } else {
                              if ((e.target.value.indexOf(".") != -1 && e.target.value.indexOf(".") > (locale === 'en' ? 6 : 8)) || (e.target.value.indexOf(".") == -1 && e.target.value.length > (locale === 'en' ? 6 : 8))) {
                                e.preventDefault();
                                if (e.target.value.substring(13) == "0") {
                                  form3.updateValues('listPrice', parseFloat(e.target.value).toFixed(12 - e.target.value.indexOf(".")));
                                }
                                //form.updateValues('price', (Math.floor(parseFloat(e.target.value) * 10000) / 10000).toFixed((e.target.value.length - (e.target.value.indexOf(".") + 1) > 4 ? 4 : e.target.value.length - (e.target.value.indexOf(".") + 1))));
                              }
                              else {
                                e.target.value.indexOf('.') != -1 && e.target.value.match(/\./g)!.length === 1 && e.target.value.indexOf('.') + 1 === e.target.value.length ? form3.updateValues('listPrice', e.target.value) : form3.updateValues('listPrice', restrictDecimal(e.target.value, locale));
                              }
                            }
                          }}
                        />
                      </TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>



            {/*------------- 6. Property Inspection ------------ */}
            <Grid
              item
              md={12}
              xs={12}
            >
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="part 6 table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.column0}>6.</TableCell>
                      <TableCell align="left" colSpan={2} data-key={'field-form3-isMissingPart6'}>{langForm3.titlePart6}
                        {form3.hasError('isMissingPart6') &&
                          <FormHelperText error>{form3.hasError('isMissingPart6')}</FormHelperText>
                        }
                      </TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {/* -------------------- (a) allow viewing ------------------- */}
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      {/* <TableCell className={classes.checkboxColumn}>
                        <Checkbox/>
                      </TableCell>
                      <TableCell>
                        <Typography>{langForm3.allowViewing}</Typography>
                      </TableCell> */}
                      <TableCell colSpan={2}>
                        <div >
                          <FormControl component="fieldset" style={{ display: 'inline-block', paddingTop: '8px' }}>
                            <FormGroup aria-label="position" row>
                              <FormControlLabel
                                value="end"
                                control={<Checkbox color="primary" />}
                                label={langForm3.allowViewingSentence1}
                                labelPlacement="end"
                                checked={form3.values.allowViewing}
                                onChange={() => {
                                  //reset inline dropdown selection
                                  if (form3.values.allowViewing) {
                                    form3.updateValues('allowViewingPerson', 'both');
                                  }
                                  //update checkbox value
                                  form3.updateValues('allowViewing', !form3.values.allowViewing);
                                }}
                              />

                            </FormGroup>
                          </FormControl>
                          <TextField
                            style={{ width: '20%' }}
                            margin="dense"
                            // required
                            select
                            variant="outlined"
                            disabled={form3.values.allowViewing === false}
                            {...bind('allowViewingPerson')}
                          >
                            <MenuItem value='both'>{langForm3.bothAgentAndPurchaser}</MenuItem>
                            <MenuItem value='agent'>{langForm3.agent}</MenuItem>
                            <MenuItem value='purchaser'>{langForm3.purchaser}</MenuItem>

                          </TextField>
                          <div style={{ display: 'inline-block', paddingTop: '20px', paddingLeft: '6px' }}>
                            {langForm3.allowViewingSentence2}
                          </div>
                        </div>
                        {/* show radio button */}
                        {/* <div style={{paddingLeft: '30px'}}>
                          <FormControl component="fieldset" style={{}}>
                           
                            <RadioGroup defaultValue="both" aria-label="Property Categories" name="customized-radios" row>
                              <FormControlLabel value="agent" control={<Radio size="small"/>} label={langForm3.agent} />
                              <FormControlLabel value="purchaser" control={<Radio size="small"/>} label={langForm3.purchaser} />
                              <FormControlLabel value="both" control={<Radio size="small"/>} label={langForm3.bothAgentAndPurchaser} />
                              
                            </RadioGroup>
                          </FormControl>
                        </div> */}
                      </TableCell>
                    </TableRow>

                    {/* -------------------- (b) Pass key for safe custody  ------------------- */}
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      {/* <TableCell className={classes.checkboxColumn}>
                        <Checkbox/>
                      </TableCell>
                      <TableCell>
                        <Typography>{langForm3.passKeyForSafeCustody}</Typography>
                      </TableCell> */}
                      <TableCell colSpan={2}>
                        <FormControl component="fieldset">
                          <FormGroup aria-label="position" row>
                            <FormControlLabel
                              value="end"
                              control={<Checkbox color="primary" />}
                              label={langForm3.passKeyForSafeCustody}
                              labelPlacement="end"
                              checked={form3.values.passKeyForSafeCustody}
                              onChange={() => {
                                form3.updateValues('passKeyForSafeCustody', !form3.values.passKeyForSafeCustody);
                              }}
                            />
                          </FormGroup>
                        </FormControl>
                      </TableCell>
                    </TableRow>

                    {/* -------------------- (c) Pass key to third party  ------------------- */}
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      {/* <TableCell className={classes.checkboxColumn}>
                        <Checkbox/>
                      </TableCell>
                      <TableCell>
                        <Typography>{langForm3.passKeyToThirdParty}</Typography>
                      </TableCell> */}
                      <TableCell colSpan={2}>
                        <div >
                          <FormControl component="fieldset" style={{ display: 'inline-block', paddingTop: '8px' }}>
                            <FormGroup aria-label="position" row>
                              <FormControlLabel
                                value="end"
                                control={<Checkbox color="primary" />}
                                label={langForm3.passKeyToThirdPartySentence1}
                                labelPlacement="end"
                                checked={form3.values.passKeyToThirdParty}
                                onChange={() => {
                                  //reset inline dropdown selection
                                  if (form3.values.passKeyToThirdParty) {
                                    form3.updateValues('passKeyToPerson', 'both');
                                  }
                                  //update checkbox value
                                  form3.updateValues('passKeyToThirdParty', !form3.values.passKeyToThirdParty);
                                }}
                              />

                            </FormGroup>
                          </FormControl>
                          <TextField
                            style={{ width: '20%' }}
                            margin="dense"
                            // required
                            select
                            variant="outlined"
                            disabled={form3.values.passKeyToThirdParty === false}
                            {...bind('passKeyToPerson')}
                          >
                            <MenuItem value='both'>{langForm3.bothEstateAgentOrPerson}</MenuItem>
                            <MenuItem value='estateAgent'>{langForm3.estateAgent}</MenuItem>
                            <MenuItem value='otherPerson'>{langForm3.otherPerson}</MenuItem>

                          </TextField>
                          <div style={{ display: 'inline-block', paddingTop: '20px', paddingLeft: '6px' }}>
                            {langForm3.passKeyToThirdPartySentence2}
                          </div>
                        </div>
                        {/* approach 1: radio button */}
                        {/* <div>
                          <FormControl component="fieldset">
                            <FormGroup aria-label="position" row>
                              <FormControlLabel
                                value="end"
                                control={<Checkbox color="primary" />}
                                label={langForm3.passKeyToThirdParty}
                                labelPlacement="end"
                              />
                            </FormGroup>
                          </FormControl>
                          
                        </div>
                        <div style={{paddingLeft: '30px'}}>
                          <FormControl component="fieldset" style={{}}>
                           
                            <RadioGroup defaultValue="both" aria-label="Property Categories" name="customized-radios" row>
                              <FormControlLabel value="estateAgentOnly" control={<Radio size="small"/>} label={langForm3.estateAgent} />
                              <FormControlLabel value="personOnly" control={<Radio size="small"/>} label={langForm3.otherPerson} />
                              <FormControlLabel value="both" control={<Radio size="small"/>} label={langForm3.bothEstateAgentOrPerson} />
                              
                            </RadioGroup>
                          </FormControl>
                        </div> */}
                        {/* end approach 1: radio button */}
                      </TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/*------------- 7. Sub-listing ------------ */}
            <Grid
              item
              md={12}
              xs={12}
            >
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="part 7 table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.column0}>7.</TableCell>
                      <TableCell align="left" colSpan={2} >{langForm3.titlePart7}</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>

                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell colSpan={2}>
                        <FormControl component="fieldset">
                          <FormGroup aria-label="position" row>
                            <FormControlLabel
                              classes={{ disabled: classes.part6DisabledText }}
                              value="end"
                              control={<Checkbox color="primary" />}
                              label={langForm3.allowSubListing}
                              labelPlacement="end"
                              checked={form3.values.allowSubListing}
                              //disabled={true} 
                              onChange={() => {
                                form3.updateValues('allowSubListing', !form3.values.allowSubListing);
                              }}
                            />
                          </FormGroup>
                        </FormControl>
                      </TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/*------------- 8. Advertising------------ */}
            <Grid
              item
              md={12}
              xs={12}
            >
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="part 8 table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.column0}>8.</TableCell>
                      <TableCell align="left" colSpan={2} >{langForm3.titlePart8}</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>

                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell colSpan={2}>
                        <FormControl component="fieldset">
                          <FormGroup aria-label="position" row>
                            <FormControlLabel
                              classes={{ disabled: classes.part6DisabledText }}
                              value="end"
                              control={<Checkbox color="primary" />}
                              label={langForm3.allowAdvertisement}
                              labelPlacement="end"
                              checked={form3.values.allowAdvertisement}
                              // disabled={true} 
                              onChange={() => {
                                form3.updateValues('allowAdvertisement', !form3.values.allowAdvertisement);
                              }}
                            />
                          </FormGroup>
                        </FormControl>

                      </TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/*------------- 9. Disclosure of Interest by Agent------------ */}
            <Grid
              item
              md={12}
              xs={12}
            >
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="part 8 table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.column0}>9.</TableCell>
                      <TableCell align="left" colSpan={2} >{langForm3.titlePart9}</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell colSpan={2}>
                        <FormControl component="fieldset" style={{ paddingBottom: '4px' }}>
                          <FormGroup aria-label="position" row>
                            <FormControlLabel
                              value="end"
                              control={<Checkbox color="primary" />}
                              label={langForm3.disclosureOfInterestByAgent}
                              labelPlacement="end"
                              checked={form3.values.needDisclosure}
                              onChange={() => {
                                if (form3.values.needDisclosure) {
                                  form3.updateValues('interestsInProperty', undefined);
                                }
                                form3.updateValues('needDisclosure', !form3.values.needDisclosure);
                              }}
                            />
                          </FormGroup>
                        </FormControl>

                        <TextareaAutosize
                          className={classes.textArea}
                          rowsMin={2}
                          rowsMax={2}
                          placeholder={langForm3.helperTextParticulars}
                          disabled={form3.values.needDisclosure == false}
                          {...bind('interestsInProperty')}
                          onChange={(e) => {
                            limitTextFieldLength(e, 255, 'interestsInProperty', form3, keyBooleanMap, setKeyBooleanMap);
                          }}
                        />
                        {form3.hasError('interestsInProperty') ? <FormHelperText style={{ color: form3.hasError('interestsInProperty') ? errorColor : 'inherit' }}>{langForm3.msgInputInterestsInProperty}</FormHelperText> : null}
                        <HandleFormHelperText
                          isError={keyBooleanMap.get('interestsInProperty')}
                          errorMessage={lang.textOverLimit}
                        />
                      </TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/*------------- 10. Acknowledgement by Vendor ------------ */}
            <Grid
              item
              md={12}
              xs={12}
            >

              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="part 10 table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.column0}>10.</TableCell>
                      <TableCell align="left" colSpan={2} >{langForm3.titlePart10}</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell align="left" colSpan={2} data-key={'field-form3-vendorAcknowledgementOption'}>
                        <FormControl component="fieldset">
                          <RadioGroup aria-label="vendor acknowledgement" name="customized-radios"
                            value={form3.values.vendorAcknowledgementOption ?? ''}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              const val = (event.target as HTMLInputElement).value;
                              form3.updateValues('vendorAcknowledgementOption', val);
                            }}
                          >
                            <FormControlLabel
                              value="option1"
                              control={<Radio size="small" />}
                              label={langForm3.vendorAcknowledgement1}
                            />
                            <FormControlLabel
                              value="option2"
                              control={<Radio size="small" />}
                              label={langForm3.vendorAcknowledgement2}
                            />
                            <FormControlLabel value="" control={<Radio size="small" />} label='' style={{ display: 'none' }} />
                          </RadioGroup>
                        </FormControl>
                        {form3.hasError('vendorAcknowledgementOption') ? <FormHelperText style={{ color: form3.hasError('vendorAcknowledgementOption') ? errorColor : 'inherit' }}>{langForm3.msgSelectVendorAcknowledgementOption}</FormHelperText> : null}

                      </TableCell>
                    </TableRow>

                    {/*------------- (a) Has received Property Information Form ------------ */}
                    {/* <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell colSpan={2}>
                        <FormControl component="fieldset">
                          <FormGroup aria-label="position" row>
                            <FormControlLabel
                              value="end"
                              control={<Checkbox color="primary" />}
                              label={langForm3.vendorAcknowledgement1}
                              labelPlacement="end"
                            />
                          </FormGroup>
                        </FormControl>

                      </TableCell>
                    </TableRow> */}

                    {/*------------- (b) Has agreed to receive Property Information Form before entering into a binding agreement ------------ */}
                    {/* <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell colSpan={2}>
                        <FormControl component="fieldset">
                          <FormGroup aria-label="position" row>
                            <FormControlLabel
                              value="end"
                              control={<Checkbox color="primary" />}
                              label={langForm3.vendorAcknowledgement2}
                              labelPlacement="end"
                            />
                          </FormGroup>
                        </FormControl>

                      </TableCell>
                    </TableRow> */}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>


            {/*----------------------- 13. Extra Terms ------------------------ */}
            <Grid
              item
              md={12}
              xs={12}
            >
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="part 13 table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.column0}>13.</TableCell>
                      <TableCell align="left" colSpan={2} >{langForm3.titlePart13}</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>

                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell colSpan={2}>
                        <Typography style={{ paddingBottom: '4px' }}> {langForm3.extraTermsCautions}</Typography>
                        <TextareaAutosize
                          className={classes.textArea}
                          rowsMin={2}
                          rowsMax={2}
                          placeholder={langForm3.helperTextParticulars}
                          {...bind('extraTerms')}
                          onChange={(e) => {
                            limitTextFieldLength(e, 255, 'extraTerms', form3, keyBooleanMap, setKeyBooleanMap);
                          }}
                        />
                        <HandleFormHelperText
                          isError={keyBooleanMap.get('extraTerms')}
                          errorMessage={lang.textOverLimit}
                        />
                      </TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
              </TableContainer>

            </Grid>

          </Grid>

          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>

        </Grid>

        {/* ---------------- Schedule 2 ------------------ */}
        <Grid item container xs={12} spacing={2}>

          <Grid
            item
            md={12}
            xs={12}
          >
            <Typography style={{ fontWeight: 'bold' }} >{langForm3.titleSchedule2}</Typography>
          </Grid>

          {/*------------- Commission ------------ */}
          <Grid
            item
            md={12}
            xs={12}
          >
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="schedule 2 table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.column0}>1.</TableCell>
                    <TableCell align="left" colSpan={2} >{langForm3.titleCommission}</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {/*----------- commission in ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell className={classes.column1} >{langForm3.commission}</TableCell>
                    <TableCell>
                      <div style={{ width: '20%', display: 'inline-block' }}>
                        <TextField
                          style={{ width: '100%' }}
                          margin="dense"
                          // required
                          label={langForm3.commissionIn}
                          select
                          // value={form3.values.commissionIn ?? ''}
                          {...bind('commissionIn')}
                          variant="outlined"
                        // onChange={(e)=> {
                        //   var commissionAmountInput = document.getElementById("commissionAmountInput");
                        //   var commissionPercentageInput = document.getElementById("commissionPercentageInput");
                        //   var fakeInputBox = document.getElementById("fakeInputBox");
                        //   if (commissionAmountInput ==  null || commissionPercentageInput == null || fakeInputBox == null){
                        //     return;
                        //   }
                        //   form3.updateValues('commissionIn', e.target.value);
                        //   if (e.target.value == 'amount') {
                        //     commissionAmountInput.style.display = "inline-block";
                        //     commissionPercentageInput.style.display = "none";
                        //     fakeInputBox.style.display = "none";
                        //    } else if (e.target.value == 'percentage') {
                        //     commissionPercentageInput.style.display = "inline-block";
                        //     commissionAmountInput.style.display = "none";
                        //     fakeInputBox.style.display = "none";
                        //   } else{
                        //     return;
                        //   }
                        // }}
                        >
                          <MenuItem selected={true} value='amount'>{langForm3.amount}</MenuItem>
                          <MenuItem value='percentage'>{langForm3.percentage}</MenuItem>
                          <MenuItem style={{ display: 'none' }} key='' value=''></MenuItem>
                        </TextField>
                      </div>
                      {' '}
                      {/* ------- fake text field -------- */}
                      <div id='fakeInputBox' style={{ display: form3.values.commissionIn == 'amount' || form3.values.commissionIn == 'percentage' ? 'none' : 'inline-block', width: '20%' }}>
                        <TextField
                          margin="dense"
                          variant="outlined" />
                      </div>
                      {/* ------- amount --------- */}
                      <div id='commissionAmountInput' style={{ display: form3.values.commissionIn == 'amount' ? 'inline-block' : 'none', width: '20%' }}>
                        <NumberField
                          margin="dense"
                          variant="outlined"
                          InputProps={{
                            startAdornment: <InputAdornment position="start">{langForm3.HKDollar}</InputAdornment>
                          }}
                          {...bind('commissionAmount')}
                          onChange={(e) => {
                            limitNumberFieldLength(e, 'commissionAmount', form3);
                          }}
                        />
                      </div>
                      {/* ------- percentage --------- */}
                      <div id='commissionPercentageInput' style={{ display: form3.values.commissionIn == 'percentage' ? 'inline-block' : 'none', width: '20%' }}>
                        <NumberField
                          margin="dense"
                          variant="outlined"
                          InputProps={{
                            endAdornment: <InputAdornment position="start">{'%'}</InputAdornment>,
                          }}
                          {...bind('commissionRate')}
                          onChange={(e) => {
                            limitNumberFieldLength(e, 'commissionRate', form3);
                          }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>

                  {/*----------- payment time ---------- */}
                  <TableRow >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell className={classes.column1} >{langForm3.paymentTime}</TableCell>
                    <TableCell>
                      <RadioGroup defaultValue="" aria-label="Payment Time" name="customized-radios" row
                        value={form3.values.paymentTimeOption ?? ''}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          const val = (event.target as HTMLInputElement).value;
                          form3.updateValues('paymentTimeOption', val);
                        }}
                      >
                        <FormControlLabel
                          value="option1"
                          control={<Radio />}
                          label={langForm3.paymentTimeOption1}
                        />
                        <FormControlLabel
                          value="option2"
                          control={<Radio />}
                          label={langForm3.paymentTimeOption2}
                        />
                        <FormControlLabel value="" control={<Radio size="small" />} label='' style={{ display: 'none' }} />
                      </RadioGroup>
                      {form3.hasError('paymentTimeOption') ? <FormHelperText style={{ color: form3.hasError('paymentTimeOption') ? errorColor : 'inherit' }}>{langForm3.msgSelectPaymentTime}</FormHelperText> : null}

                    </TableCell>
                  </TableRow>


                </TableBody>
              </Table>
            </TableContainer>


            {/* ------------------------ Schedule 2 point 2 ---------------- */}
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="schedule 2 point 2 table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.column0}>2.</TableCell>
                    <TableCell align="left" >{langForm3.titlePart2Point2}</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell>
                      <FormControl component="fieldset" style={{}}>
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            control={<Checkbox color="primary" />}
                            label={langForm3.withInterest}
                            labelPlacement="end"
                            checked={form3.values.withInterest}
                            onChange={() => {
                              form3.updateValues('withInterest', !form3.values.withInterest);
                            }}
                          />
                        </FormGroup>
                      </FormControl>
                      {form3.hasError('withInterest') ? <FormHelperText style={{ color: form3.hasError('withInterest') ? errorColor : 'inherit' }}>{langForm3.msgSelectWithInterest}</FormHelperText> : null}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

          </Grid>

          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>

        </Grid>

        {/* ---------------- info of signatory ------------------ */}
        <Grid item container xs={12} spacing={2}>

          {/* ---------Vendor's info ----------- */}
          <Grid
            item
            md={6}
            xs={12}
          >
            <TableContainer component={Paper}>
              <Table size="small" aria-label="vendor info table">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2} align="left" >{langForm3.vendorInfo}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* ------------------------ ID no------------------------- */}
                  <TableRow style={{ display: 'none' }}>
                    <TableCell component="th" style={{ width: '35%' }} scope="row">
                      <Typography>{langForm3.HKID}</Typography>
                      <Typography>{langForm3.ifApplicable}</Typography>
                      {form3.hasError('eitherSignatoryInfoOrHKID') ? <FormHelperText style={{ color: form3.hasError('eitherSignatoryInfoOrHKID') ? errorColor : 'inherit' }}>{langForm3.msgInputSignatoryInfoOrHKID}</FormHelperText> : null}
                    </TableCell>

                    <TableCell align="left">
                      <TextField
                        className={classes.infoPartTextField}
                        margin="dense"
                        variant="outlined"
                        // {...bind('vendorHKID')}
                        value={form3.values.vendorHKID ?? ''}
                        onChange={(e) => {
                          form3.updateValues('vendorHKID', e.target.value);
                          form3.updateValues('nameOfSignatoryOfVendor', undefined);
                          form3.updateValues('positionOfSignatoryOfVendor', undefined);
                          form3.updateValues('businessRegistrationCertificateNumberOfVendor', undefined);
                        }}
                      />
                    </TableCell>
                  </TableRow>

                  {/* -------------- if vendor is a company------------------------- */}
                  <TableRow>
                    <TableCell colSpan={2} className={classes.subTableCell}>
                      <Typography>{langForm3.whereVendorIsCompany}</Typography>
                    </TableCell>

                  </TableRow>
                  {/* ----------- name ---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row" className={classes.subTableCell}>
                      <Typography className={classes.companyInfo}>{langForm3.nameOfSignatoryOfVendor}</Typography>
                    </TableCell>

                    <TableCell align="left" className={classes.subTableCell}>
                      <TextField
                        className={classes.infoPartTextField}
                        margin="dense"
                        variant="outlined"
                        // {...bind('nameOfSignatoryOfVendor')}
                        error={!!form3.hasError('nameOfSignatoryOfVendor')}
                        helperText={form3.hasError('nameOfSignatoryOfVendor')}
                        value={form3.values.nameOfSignatoryOfVendor ?? ''}
                        onChange={(e) => {
                          if (keyBooleanMap && setKeyBooleanMap) {
                            setKeyBooleanMap(keyBooleanMap.set('nameOfSignatoryOfVendor', e.target.value.length > 255))
                          }
                          if (e.target.value.length > 255) {
                            e.preventDefault();
                            form3.bind('nameOfSignatoryOfVendor').onChange({ target: { value: e.target.value.substring(0, 255) } });
                          } else {
                            form3.bind('nameOfSignatoryOfVendor').onChange(e);
                            form3.updateValues('vendorHKID', undefined);
                          }
                        }}
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('nameOfSignatoryOfVendor')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>
                  {/* ----------- position ---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row" className={classes.subTableCell}>
                      <Typography className={classes.companyInfo}>{langForm3.positionOfSignatoryOfVendor}</Typography>
                    </TableCell>

                    <TableCell align="left" className={classes.subTableCell}>
                      <TextField
                        className={classes.infoPartTextField}
                        margin="dense"
                        variant="outlined"
                        // {...bind('positionOfSignatoryOfVendor')}
                        error={!!form3.hasError('positionOfSignatoryOfVendor')}
                        helperText={form3.hasError('positionOfSignatoryOfVendor')}
                        value={form3.values.positionOfSignatoryOfVendor ?? ''}
                        onChange={(e) => {
                          if (keyBooleanMap && setKeyBooleanMap) {
                            setKeyBooleanMap(keyBooleanMap.set('positionOfSignatoryOfVendor', e.target.value.length > 255))
                          }
                          if (e.target.value.length > 255) {
                            e.preventDefault();
                            form3.bind('positionOfSignatoryOfVendor').onChange({ target: { value: e.target.value.substring(0, 255) } });
                          } else {
                            form3.bind('positionOfSignatoryOfVendor').onChange(e);
                            form3.updateValues('vendorHKID', undefined);
                          }
                        }
                        }
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('positionOfSignatoryOfVendor')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>
                  {/* ----------- business registration certificate number ---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography className={classes.companyInfo}>{langForm3.businessRegistrationCertificateNumberOfVendor}</Typography>
                    </TableCell>

                    <TableCell align="left">
                      <TextField
                        className={classes.infoPartTextField}
                        margin="dense"
                        variant="outlined"
                        // {...bind('businessRegistrationCertificateNumberOfVendor')}
                        error={!!form3.hasError('businessRegistrationCertificateNumberOfVendor')}
                        helperText={form3.hasError('businessRegistrationCertificateNumberOfVendor')}
                        value={form3.values.businessRegistrationCertificateNumberOfVendor ?? ''}
                        onChange={(e) => {
                          if (keyBooleanMap && setKeyBooleanMap) {
                            setKeyBooleanMap(keyBooleanMap.set('businessRegistrationCertificateNumberOfVendor', e.target.value.length > 255))
                          }
                          if (e.target.value.length > 255) {
                            e.preventDefault();
                            form3.bind('businessRegistrationCertificateNumberOfVendor').onChange({ target: { value: e.target.value.substring(0, 255) } });
                          } else {
                            form3.bind('businessRegistrationCertificateNumberOfVendor').onChange(e);
                            form3.updateValues('vendorHKID', undefined);
                          }
                        }}
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('businessRegistrationCertificateNumberOfVendor')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>

                  {/* ----------- address ---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography>{langForm3.address}</Typography>
                    </TableCell>

                    <TableCell align="left">
                      <TextareaAutosize
                        className={classes.textArea}
                        style={{ width: '100%', }}
                        rowsMin={4}
                        rowsMax={4}
                        placeholder=''
                        {...bind('vendorAddress')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'vendorAddress', form3, keyBooleanMap, setKeyBooleanMap);
                        }}
                      />
                      {form3.hasError('vendorAddress') ? <FormHelperText style={{ color: form3.hasError('vendorAddress') ? errorColor : 'inherit' }}>{langForm3.pleaseInput + langForm3.vendorAddress}</FormHelperText> : null}
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('vendorAddress')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>

                  {/* ----------- phone number---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography >{langForm3.phoneNumber}</Typography>
                    </TableCell>

                    <TableCell align="left" data-key={'field-form3-vendorPhoneNumber'} >
                      <TextField
                        className={classes.infoPartTextField}
                        margin="dense"
                        variant="outlined"
                        {...bind('vendorPhoneNumber')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'vendorPhoneNumber', form3, keyBooleanMap, setKeyBooleanMap);
                        }}
                        helperText={form3.hasError('vendorPhoneNumber')}
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('vendorPhoneNumber')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>

                  {/* ----------- fax number---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography>{langForm3.faxNumber}</Typography>
                    </TableCell>

                    <TableCell align="left">
                      <TextField
                        className={classes.infoPartTextField}
                        margin="dense"
                        variant="outlined"
                        {...bind('vendorFaxNumber')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'vendorFaxNumber', form3, keyBooleanMap, setKeyBooleanMap);
                        }}
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('vendorFaxNumber')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>

                  {/* ----------- date --------- */}
                  {/* <TableRow>
                      <TableCell component="th"  scope="row">
                          <Typography>{langForm3.date}</Typography>
                      </TableCell>

                      <TableCell  align="left">
                        <KeyboardDatePicker autoOk={true}
                           className={classes.infoPartTextField}
                          // InputProps={InputGroup.leftItem()}
                          //disableToolbar
                          variant="inline"
                          inputVariant="outlined"
                          format={DISPLAY_DATE_FORMAT}
                          margin="dense"
                          value={form3.values.vendorDate ? moment(form3.values.vendorDate) : null}
                          onChange={(value) => {
                            form3.setValues({
                              ...form3.values,
                              vendorDate: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
                            });
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          /> 
                          {form3.hasError('vendorDate') ? <FormHelperText style={{color: form3.hasError('vendorDate') ? errorColor : 'inherit'}}>{langForm3.msgInputDate}</FormHelperText> : null}
                      </TableCell>
                    </TableRow> */}

                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          {/* ---------agents's info ----------- */}
          <Grid
            item
            md={6}
            xs={12}
          >

            <TableContainer component={Paper}>
              <Table size="small" aria-label="agent info table">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2} align="left" >{langForm3.agentInfo}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  {/* ----------- Name and licence number of the Agent---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography >{langForm3.nameAndLicenceNumberOfAgent}</Typography>
                    </TableCell>

                    <TableCell align="left">
                      <TextField
                        className={classes.infoPartTextField}
                        margin="dense"
                        variant="outlined"
                        {...bind('nameAndLicenceNumberOfAgent')}
                        onChange={(e) => {
                          limitTextFieldLength(e, 255, 'nameAndLicenceNumberOfAgent', form3, keyBooleanMap, setKeyBooleanMap);
                        }}
                      />
                      <HandleFormHelperText
                        isError={keyBooleanMap.get('nameAndLicenceNumberOfAgent')}
                        errorMessage={lang.textOverLimit}
                      />
                    </TableCell>
                  </TableRow>

                  {/* ----------- Number of Agent’s statement of particulars of business---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography >{langForm3.numberOfStatementOfParticularsOfBusiness}</Typography>
                    </TableCell>

                    <TableCell align="left">
                      <TextField
                        className={classes.infoPartTextField}
                        disabled={true}
                        margin="dense"
                        variant="outlined"
                        {...bind('numberOfStatementOfParticularsOfBusiness')}
                      />
                    </TableCell>
                  </TableRow>

                  {/* ----------- address ---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography>{langForm3.address}</Typography>
                    </TableCell>

                    <TableCell align="left">
                      <TextareaAutosize
                        className={classes.textArea}
                        style={{ width: '100%', }}
                        disabled={true}
                        rowsMin={4}
                        rowsMax={4}
                        placeholder=''
                        {...bind('agentAddress')}
                      />
                      {form3.hasError('agentAddress') ? <FormHelperText style={{ color: form3.hasError('agentAddress') ? errorColor : 'inherit' }}>{langForm3.msgInputAddress}</FormHelperText> : null}
                    </TableCell>
                  </TableRow>

                  {/* ----------- phone number---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography >{langForm3.phoneNumber}</Typography>
                    </TableCell>

                    <TableCell align="left">
                      <TextField
                        className={classes.infoPartTextField}
                        disabled={true}
                        margin="dense"
                        variant="outlined"
                        {...bind('agentPhoneNumber')}
                      />
                    </TableCell>
                  </TableRow>

                  {/* ----------- fax number---------- */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography>{langForm3.faxNumber}</Typography>
                    </TableCell>

                    <TableCell align="left">
                      <TextField
                        className={classes.infoPartTextField}
                        disabled={true}
                        margin="dense"
                        variant="outlined"
                        {...bind('agentFaxNumber')}
                      />
                    </TableCell>
                  </TableRow>

                  {/* ----------- date --------- */}
                  {/* <TableRow>
                      <TableCell component="th"  scope="row">
                          <Typography>{langForm3.date}</Typography>
                      </TableCell>

                      <TableCell  align="left">
                        <KeyboardDatePicker autoOk={true}
                           className={classes.infoPartTextField}
                          // InputProps={InputGroup.leftItem()}
                          //disableToolbar
                          variant="inline"
                          inputVariant="outlined"
                          format={DISPLAY_DATE_FORMAT}
                          margin="dense"
                          value={form3.values.agentDate ?  moment(form3.values.agentDate) : null}
                          onChange={(value) => {
                            form3.setValues({
                              ...form3.values,
                              agentDate: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
                            });
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          /> 
                          {form3.hasError('agentDate') ? <FormHelperText style={{color: form3.hasError('agentDate') ? errorColor : 'inherit'}}>{langForm3.msgInputDate}</FormHelperText> : null}
                      </TableCell>
                    </TableRow> */}




                </TableBody>
              </Table>
            </TableContainer>

          </Grid>
        </Grid>


        {/* <Grid
            item
            md={4}
            xs={12}
          >
            <Typography>賣方(業主)姓名</Typography>
            <TextField
              fullWidth
              margin="dense"
              name="headingChi"
              // onChange={handleChange}
              required
              value={'陳大文'}
              variant="outlined"
              // InputProps={{
              //   startAdornment: <InputAdornment className={classes.langAdornment} position="start">英</InputAdornment>
              // }}
            />
          </Grid>

          <Grid
            item
            md={4}
            xs={12}
          >
            <Typography>地產代理簽署人姓名</Typography>
            <TextField
              fullWidth
              margin="dense"
              name="headingChi"
              // onChange={handleChange}
              required
              value={'陽光物業顧問'}
              variant="outlined"
              // InputProps={{
              //   startAdornment: <InputAdornment className={classes.langAdornment} position="start">英</InputAdornment>
              // }}
            />
          </Grid>

          <Grid
            item
            md={4}
            xs={12}
          >
            <Typography>放盤價</Typography>
            <NumberField
              fullWidth
              margin="dense"
              name="headingChi"
              // onChange={handleChange}
              required
              value={'3,400,000'}
              variant="outlined"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
            />
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >
            <Typography>物業</Typography>
            <TextField
              fullWidth
              margin="dense"
              name="headingChi"
              // onChange={handleChange}
              required
              value={'大埔頭路18號太湖花園一期B座3樓B室'}
              variant="outlined"
              // InputProps={{
              //   startAdornment: <InputAdornment className={classes.langAdornment} position="start">英</InputAdornment>
              // }}
            />
          </Grid>

          <Grid
            item
            md={3}
            xs={12}
          >
            <Typography>協議有效期由</Typography>
            <TextField
              fullWidth
              margin="dense"
              name="headingChi"
              // onChange={handleChange}
              required
              value={'01/01/2020'}
              variant="outlined"
              // InputProps={{
              //   startAdornment: <InputAdornment className={classes.langAdornment} position="start">英</InputAdornment>
              // }}
            />
          </Grid>

          <Grid
            item
            md={3}
            xs={12}
          >
            <Typography>至</Typography>
            <TextField
              fullWidth
              multiline rows="1"
              margin="dense"
              name="headingChi"
              // onChange={handleChange}
              required
              value={'02/01/2020'}
              variant="outlined"
            />
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >
            <Typography>視察物業</Typography>
            <FormGroup>
              <FormControlLabel control={<Checkbox checked value="checkedC" />} label="賣方同意容許代理/買方於雙方議定的時間查看物業" />
              <FormControlLabel control={<Checkbox checked value="checkedC" />} label="賣方同意將物業的鎖匙交由代理妥為保管，以供查看物業之用" />
              <FormControlLabel control={<Checkbox value="checkedC" />} label="賣方授權代理將鎖匙交予其他地產代理/人士，以供查看物業之用" />
            </FormGroup>
            {/* <FormControlLabel control={<Checkbox value="checkedC" />} label="" />
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >
            <Typography>分銷放盤</Typography>
            <FormControlLabel control={<Checkbox value="checkedC" />} label="賣方授權代理將物業分銷放盤" />
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >
            <Typography>廣告宣傳</Typography>
            <FormControlLabel control={<Checkbox checked value="checkedC" />} label="賣方授權代理發出關於物業的廣告" />
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >
            <Typography>代理須披露權益</Typography>
            <TextField
              fullWidth
              multiline rows="1"
              placeholder="如有，請述明"
              margin="dense"
              name="headingChi"
              // onChange={handleChange}
              required
              value={''}
              variant="outlined"
            />
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >
            <Typography>額外條款</Typography>
            <TextField
              fullWidth
              multiline rows="1"
              placeholder="如有，請述明"
              margin="dense"
              name="headingChi"
              // onChange={handleChange}
              required
              value={''}
              variant="outlined"
            />
          </Grid>

          <Grid
            item
            md={4}
            xs={12}
          >
            <Typography>佣金支付於</Typography>
            <TextField select
              fullWidth
              margin="dense"
              variant="outlined">
              <MenuItem value="1">簽署買賣協議時</MenuItem>
              <MenuItem value="2">物業交易完成時</MenuItem>
            </TextField>
          </Grid>

          <Grid
            item
            md={4}
            xs={12}
          >
            <Typography>佣金形式</Typography>
            <TextField select
              fullWidth
              margin="dense"
              variant="outlined"
              onChange={(e) => setCommissionMethod(e.target.value)}
              value={commissionMethod}>
              <MenuItem value="1">實際數額</MenuItem>
              <MenuItem value="2">物業成交價的百分比</MenuItem>
            </TextField>
          </Grid>

          <Grid
            item
            md={4}
            xs={12}
          >
            <Typography>佣金</Typography>
            <NumberField
              fullWidth
              placeholder=""
              margin="dense"
              name="headingChi"
              // onChange={handleChange}
              required
              disabled={!commissionMethod}
              // value={''}
              variant="outlined"
              InputProps={{
                startAdornment: commissionMethod === '1' ? <InputAdornment position="start">$</InputAdornment> : null,
                endAdornment: commissionMethod === '2' ?  <InputAdornment position="end">%</InputAdornment> : null,
              }}
            />
          </Grid> */}

      </CardContent>

      <CardActions style={{ justifyContent: 'flex-end', display: 'none' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (form3.values.id != undefined && form3.values.id != null) {
              dispatch({
                type: 'Form3.UpdateRequested', payload: {
                  ...form3.values,
                }
              });
            } else {

              dispatch({
                type: 'Form3.CreationRequested', payload: {
                  ...form3.values,
                  propertyStockId: propertyStockId,
                }
              });
            }
          }}

        >{lang.actionSaveForm}</Button>
        <Button variant="contained" color="primary">生成PDF檔案</Button>
      </CardActions>
    </Card>
  )
};

export default Form3Dialog;
