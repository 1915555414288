import { ApiPageResult, ApiResult } from 'api';
import { BuildingAliasDTO, BuildingDetailDTO, BuildingListItemDTO } from 'common/dto';
import { objectToQuery } from 'common/utils';

const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];

export const buildingApi = {
  getList: async (criteria: any = {}, token: string): Promise<ApiPageResult<BuildingListItemDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/buildings?${objectToQuery(criteria)}`, {
        headers: { 'Authorization': `Bearer ${token}`}
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  getDetail: async (id: string, token: string): Promise<ApiResult<BuildingDetailDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/buildings/${id}`, {
        headers: { 'Authorization': `Bearer ${token}`}
      })
      return await response.json();
    } catch(e) {
      return { error: String(e) };
    }
  }, 

  add: async (building: BuildingDetailDTO, token: string): Promise<ApiResult<BuildingDetailDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/buildings`, {
        method: 'POST', body: JSON.stringify(building),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  merge: async (buildingIdA: number, buildingIdB: number, addToAlias: boolean, token: string): Promise<ApiResult<BuildingDetailDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/buildings/merge`, {
        method: 'POST', body: JSON.stringify({ buildingIdA, buildingIdB, addToAlias }),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  update: async(building: BuildingDetailDTO, token: string): Promise<ApiResult<BuildingDetailDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/buildings/${building.id}`, {
        method: 'POST', body: JSON.stringify(building),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  createBuildingRequest: async (description: any, token: string): Promise<ApiResult<any>> => {
    const payload = new FormData();
    payload.append('district', description.district);
    payload.append('streetName', description.streetName);
    payload.append('buildingName', description.buildingName);

    try {
      const response = await fetch(`${BASE_URL}/buildings/building-master-creation-request`, {
        method: 'POST', body: payload,
        headers: { 'Authorization': `Bearer ${token}` }
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  getAliasList: async (buildingId: string | number, token: string): Promise<ApiResult<any>> => {
    try {
      const response = await fetch(`${BASE_URL}/buildings/${buildingId}/aliases`, {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${token}` }
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  addAlias: async (alias: BuildingAliasDTO, token: string): Promise<ApiResult<any>> => {
    try {
      const response = await fetch(`${BASE_URL}/buildings/${alias.buildingId}/aliases`, {
        method: 'POST', body: JSON.stringify(alias),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  deleteAlias: async (buildingId: string, aliasId: string, token: string): Promise<ApiResult<any>> => {
    try {
      const response = await fetch(`${BASE_URL}/buildings/${buildingId}/aliases/${aliasId}`, {
        method: 'DELETE',
        headers: { 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  }
};

export default buildingApi;