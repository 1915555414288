import { ApiError, ApiResult } from "api";
import jobMonitorApi from "api/jobMonitorApi";
import { JobDTO } from "common/dto";
import { IRootState, JobMonitorActions } from "reducers";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { PASSagaContext } from "sagas";
import { apiTaskWrapper } from "./saga-commons";

export function* watchJobMonitorFetchRequested(context: PASSagaContext) {
  yield takeLatest(
    'JobMonitor.FetchRequested',
    apiTaskWrapper(fetchJobs),
    context,
  );
}

export function* fetchJobs(context: PASSagaContext, action: Extract<JobMonitorActions, { type: 'JobMonitor.FetchRequested' }>) {
  const token: string = yield select((state: IRootState) => state.login.token ?? '');
  
  const result: ApiResult<JobDTO[]> = yield call(jobMonitorApi.getJobs, token);

  if (!result.error) {
    yield put<JobMonitorActions>({ type: 'JobMonitor.DataUpdated', payload: result.data! });
  } else {
    throw ApiError.of(result.error);
  }

}