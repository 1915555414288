import { CardActions, CardHeader, CircularProgress, DialogContent } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';
import SignaturePad from 'signature_pad';

const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];

const useSignaturePad = (canvasRef: React.MutableRefObject<HTMLCanvasElement | null>, onSignature: (blobUrl: string) => any) => {
  // const canvasRef = useRef<HTMLCanvasElement>(null);
  // let _canvas: HTMLCanvasElement | null = null;
  // const canvasRef = (c: HTMLCanvasElement | null) => {
  //   _canvas = c;
  //   if (_canvas) {
  //     signaturePad.current = new SignaturePad(_canvas, { minWidth: 3.5, maxWidth: 6.5 });
  //   } else {
  //     signaturePad.current = undefined;
  //   }
  // }

  let signaturePad = useRef<SignaturePad>();
  if (!signaturePad.current && canvasRef.current) {
    signaturePad.current = new SignaturePad(canvasRef.current, { minWidth: 3.5, maxWidth: 6.5 });
  }

  const dataURLToBlob = (dataURL: any) => {
    const parts = dataURL.split(';base64,');
    const contentType = parts[0].split(":")[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
  
    for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
  
    return new Blob([uInt8Array], { type: contentType });
  };

  return {
    signaturePadRef: signaturePad,
    saveButton: () => ({
      onClick: () => {
        const dataURL = signaturePad.current?.toDataURL();
        if (!signaturePad.current?.isEmpty() && dataURL) {
          onSignature(window.URL.createObjectURL(dataURLToBlob(dataURL)));
        }
      }
    }),
    canvas: () => ({
      ref: canvasRef,
    }),
    clear: () => {
      signaturePad.current?.clear();
    },
  }
};

interface GenericSigningDialogProps {
  open: boolean;
  signSpecs: { title: React.ReactNode, name: string }[];
  onClose: () => any;
  onSigned: (signs: { [key: string]: string }, setStatus: (state: 'signing' | 'generating' | 'success') => any) => any;
  isClientSignPage?: boolean;
  handleViewFormPdf? : ()=> void;
}

const GenericSigningDialog = ({ open, onClose, onSigned, signSpecs, isClientSignPage, handleViewFormPdf } : GenericSigningDialogProps) => {
  const { langSignDialog, lang } = useSelector((state: IRootState) => state.locale);
  const { langSignDialog:langSignDialogEn } = useSelector((state: IRootState) => state.locale._bundle['en']);

  const [ signedFormUrl ] = useState('');
  const [ signs, setSigns ] = useState<{ [key: string]: string }>({}); // data urls
  const [ signSpecIdx, setSignSpecIdx ] = useState(0);
  const [ status, setStatus ] = useState<'signing' | 'generating' | 'success'>('signing');
  
  const reset = () => {
    setSigns({});
    setSignSpecIdx(0);
    setStatus('signing');
    signaturePadRef.current?.clear();
  }

  // #endregion Form Signing Related
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const signaturePadBinding = useSignaturePad(canvasRef, async (dataUrl) => {
    const updatedSigns = { ...signs, [signSpecs[signSpecIdx].name]: dataUrl };
    setSigns(updatedSigns);
    if (signSpecIdx < signSpecs.length - 1) {
      setSignSpecIdx(signSpecIdx + 1);
      signaturePadRef.current?.clear();
    } else {
      // setStatus('generating');

      onSigned(updatedSigns, setStatus);

      // if (result.error) {
      //   setStatus('signing');
      // } else {
      //   setSignedFormUrl(result.data);
      //   setStatus('success');
      // }

    }
  });

  const signaturePadRef = signaturePadBinding.signaturePadRef;
  // useLayoutEffect(() => {
  //   signaturePadRef.current?.clear();
  // }, [ signSpecIdx, signaturePadRef ]);

  const signaturePanel = () => <div>
    <div style={{ display: 'flex' }}>
      <CardHeader style={{whiteSpace: 'pre-line'}}
        title={signSpecs[signSpecIdx]?.title}
        subheader={langSignDialog.msgSigningTips + (isClientSignPage ? '\n' + langSignDialogEn.msgSigningTips : '')} 
      />
      <div style={{ flexGrow: 2 }} />
      <CardActions>
        {isClientSignPage ?
          <Button
            color="primary"
            variant="outlined"
            onClick={handleViewFormPdf}
            >
              {langSignDialog.actionPreviewForm + ' ' +  langSignDialogEn.actionPreviewForm}
          </Button>
          : null}
        <Button
          color="primary"
          variant="outlined"
          {...signaturePadBinding.saveButton()}
        >
          {signSpecIdx < signSpecs.length - 1 ? lang.actionConfirm :langSignDialog.actionConfirmAndSign + (isClientSignPage ? langSignDialogEn.actionConfirmAndSign : '')}
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => signaturePadBinding.clear()}
        >
          {langSignDialog.actionClear + (isClientSignPage ? langSignDialogEn.actionClear : '')}
        </Button>
        {isClientSignPage ? null : 
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              onClose();
              reset();
            }}
          >
            {lang.actionCancel}
          </Button>
        }
      </CardActions>
    </div>
    <canvas
      style={{ background: '#ffffbb' }}
      // key={`sig-canvas-${signSpecIdx}`}
      ref={canvasRef}
      width={window.innerWidth * 0.9}
      height={window.innerWidth * 0.9 * 9/16}
    />
  </div>;

  const loadingPanel = () => <CardHeader
    avatar={<CircularProgress />}
    title={langSignDialog.msgGenerating}
  />;

  const successPanel = () => <div>
    <CardHeader
      subheader={langSignDialog.msgGenerated}
      title={langSignDialog.titleGenerated}
    />

    <CardActions> 
      <Button
        color="primary"
        variant="outlined"
        {...{
          component: 'a',
          href: `${BASE_URL}/files/${signedFormUrl}`,
          target: '_blank',
        } as any}
      >
        {langSignDialog.actionDownloadDoc}
      </Button>
      <Button
        color="primary"
        variant="outlined"
        onClick={() => { onClose(); reset(); }}
      >
        {langSignDialog.actionGoBack}
      </Button>
      {/* <a ref={aRef} href="" download=""/> */}
    </CardActions>
  </div>;

  


  return <Dialog
    keepMounted
    open={open}
    fullScreen
    // onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogContent>
      {{
        signing: signaturePanel,
        generating: loadingPanel,
        success: successPanel,
      }[status]()}
    </DialogContent>
  </Dialog>;
};

export default GenericSigningDialog;