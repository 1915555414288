import buildingApi from 'api/buildingApi';
import propertyApi from "api/propertyApi";
import { BuildingListItemDTO, CheckDuplicatedPropertyStockResultDTO, ClientFileMap, PropertyFileMap, PropertyMultiValueMap, PropertyStockDTO, TransactionDetailDTO } from "common/dto";
import NavigationBlockerDialog from "common/elements/NavigationBlockerDialog";
import { useForm } from "common/hooks";
import { isNonEmpty, priceFromView, priceToView } from 'common/utils';
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router";
import { IRootState, PASDispatch } from "reducers";
import WarningDialogForCreatingExisingPropertyStock from "./components/WarningDialogForCreatingExisingPropertyStock";
import propertyFormValidations from "./property-validations";
import PropertyDetail from "./PropertyDetail";


const EMPTY = {};

export default function PropertyCreatePage() {
  const dispatch = useDispatch() as PASDispatch;

  const location = useLocation();
  const history = useHistory();

  const transactionId = location.state?.transactionId ?? '';
  const buildingId = location.state?.buildingId ?? '';
  const floors = location.state?.floor ?? [];
  const units = location.state?.unit ?? [];
  const { locale, lang, langPropertyStock } = useSelector((state: IRootState) => state.locale);

  // const [ prevTransactionId, setPrevTransactionId ] = useState('');

  const transactionDto = useSelector((state: IRootState) => state.transaction.fetchedDetails[transactionId!]) ?? EMPTY as Partial<TransactionDetailDTO>;
  const token: string = useSelector((state: IRootState) => state.login.token ?? '');

  const stockForm = useForm<PropertyStockDTO>({
    room: 0,
    suite: 0,
    helperRoom: 0,
    bathroom: 0,
    balcony: 0,
    toilet: 0,
    backDoor: 0,
    balconySizes: []
  }, {
    validations: propertyFormValidations(langPropertyStock, lang, true),
  });

  const tagForm = useForm<PropertyMultiValueMap>({
    VIEW: [],
    OTHER_FEATURES: [],
    SYMBOL: [],
    LIVING_ROOM: [],
    CLUB_HOUSE_FACILITIES: [],
    OTHERS: [],
    BALCONY_SIZES: [],
  });
  const fileForm = useForm<PropertyFileMap>({
    PHOTO: [],
    FLOOR_PLAN: [],
    LAND_SEARCH: [],
    SA_SEARCH: [],
    OP: [],
    OTHERS: [],
    COMPANY_SEARCH: [],
    FORM_1: [],
    FORM_2: [],
    FORM_3: [],
    FORM_5: [],
    PROVISIONAL_AGREEMENT: [],
    SUPPLEMENTAL_DOCUMENT: [],
    CLOSE_TRNX_SUPP: []
  } as PropertyFileMap);

  const clientFileForm = useForm<ClientFileMap>({
    FORM_4: [],
    FORM_6: [],
  } as ClientFileMap);

  // Request API again when the id param chenged
  // useEffect(() => {
  //   dispatch({ type: 'Property.FetchRequested', payload: { id: id ?? '' } });
  // }, [ id ]);

  const previousLocale = useRef(locale);
  useLayoutEffect(() => {
    if (stockForm.values.price) {
      stockForm.updateValues('price', priceToView(
        priceFromView(stockForm.values.price, previousLocale.current),
        locale,
      ));
    }

    if (stockForm.values.bottomPrice) {
      stockForm.updateValues('bottomPrice', priceToView(
        priceFromView(stockForm.values.bottomPrice, previousLocale.current),
        locale,
      ));
    }
    previousLocale.current = locale;
  }, [locale]);

  const [floorUnitOptions, setFloorUnitOptions] = useState<BuildingListItemDTO['floorUnits']>([]); // for property creation from transaction copying

  useEffect(() => {
    if (isNonEmpty(buildingId) && isNonEmpty(transactionDto)) {
      dispatch({ type: 'Layout.MaskPresentRequested' });
      buildingApi.getDetail(buildingId, token).then(result => {
        if (result.data) {
          const { id: buildingId, ...b } = result.data;
          stockForm.setValues({
            ...stockForm.values,
            buildingId,
            ...b,
            usage: transactionDto.usage,
            building: b.buildingNameZh,
            buildingEn: b.buildingNameEn,
            street: transactionDto.streetZh,
            streetEn: transactionDto.streetEn,
            lot: transactionDto.lot,
            block: b.blockZh,
            blockEn: b.blockEn,
            primarySchoolNet: b.primarySchoolNet,
            secondarySchoolNet: b.secondarySchoolNet,
            floor: floors,
            unit: units,
            developer: b.developer ?? '',
          });

          tagForm.setValues({
            ...tagForm.values,
            CLUB_HOUSE_FACILITIES: b.clubHouseFacilities ?? [],
            OTHERS: b.others ?? []
          });

          setFloorUnitOptions(b.floorUnits ?? []);
        } else if (result.error) {
          throw new Error(result.error);
        }

        history.replace({
          state: {
            buildingId: null,
            floors: null,
            units: null
          }
        });

        dispatch({ type: 'Layout.MaskDismissRequested' });
      }).catch(err => {
        console.error(err);
        dispatch({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgNetworkError } });
        dispatch({ type: 'Layout.MaskDismissRequested' });
      });
    }

  }, [transactionDto]);

  // useEffect(() => {
  //   dispatch({ type: 'BuildingList.FetchRequested', payload: { } as any});
  // }, [ 1 ]);

  //warning dialog
  const [openWarningDialog, setOpenWarningDialog] = React.useState(false);
  const handleConfirm = () => {
    setOpenWarningDialog(false);
    doSave();
  }

  const assembleFormToDto = () => {
    const updatedResult = {
      ...stockForm.values,
        status: stockForm.values.price && stockForm.values.rent ? 'BOTH' :
          stockForm.values.price ? 'SALES' :
            stockForm.values.rent ? 'RENT' : 'PENDING',
        // Price unit transformation
        price: stockForm.values.price ? priceFromView(stockForm.values.price, locale) : 0,
        bottomPrice: stockForm.values.bottomPrice ? priceFromView(stockForm.values.bottomPrice, locale) : 0,
        features: Object.keys(tagForm.values)
          .map(type => tagForm.values[type as keyof PropertyMultiValueMap]
            ?.map(value => ({ type, value })))
          .reduce((a, b) => (a ?? []).concat(b ?? []), []),
        attachments: Object.keys(fileForm.values)
          .map(type => fileForm.values[type as keyof PropertyFileMap])
          .reduce((a, b) => (a ?? []).concat(b ?? []), []),
        hasGarden: isNonEmpty(stockForm.values.gardenArea),
        hasRooftop: isNonEmpty(stockForm.values.rooftopArea),
        balcony: stockForm.values.balconySizes?.filter(v => isNonEmpty(v))?.length ?? 0,
        balconySizes: stockForm.values.balconySizes?.filter(v => isNonEmpty(v)),
        developer: stockForm.values.developer ?? '',
    } as Record<string, any>;
    Object.keys(updatedResult).forEach(key => {
      if (typeof updatedResult[key as keyof PropertyStockDTO] === "string" && updatedResult[key as keyof PropertyStockDTO].trim() === "") {
        updatedResult[key as keyof PropertyStockDTO] = null;
      }
    });
    return (updatedResult as PropertyStockDTO);
  }

  const doSave = () => {
    dispatch({
      type: 'Property.CreationRequested', payload: assembleFormToDto()
    });
  }

  const [checkResult, setCheckResult] = React.useState<Partial<CheckDuplicatedPropertyStockResultDTO>>(EMPTY as CheckDuplicatedPropertyStockResultDTO);
  const getCheckDuplicateResult = () => {
    if (isNonEmpty(stockForm.values.buildingId)) {
      if ((stockForm.values.hasFloorDefinitions === true && !isNonEmpty(stockForm.values.floor))
        || (stockForm.values.hasUnitDefinitions === true && !isNonEmpty(stockForm.values.unit))
      ) {
        return;
      }
      stockForm.updateValues('isCreatingDuplicatedPropertyStock', undefined);
      stockForm.updateValues('isCreatingDuplicatedCompanyStock', undefined);
      //call api
      dispatch({ type: 'Layout.MaskPresentRequested' });
      propertyApi.checkDuplicatedPropertyStock({
        id: stockForm.values.id,
        buildingId: stockForm.values.buildingId,
        usage: stockForm.values.usage,
        district: stockForm.values.district,
        block: stockForm.values.block,
        blockEn: stockForm.values.blockEn,
        street: stockForm.values.street,
        streetEn: stockForm.values.streetEn,
        lot: stockForm.values.lot,
        building: stockForm.values.building,
        buildingEn: stockForm.values.buildingEn,
        floor: stockForm.values.floor ?? [],
        unit: stockForm.values.unit ?? [],
      } as PropertyStockDTO, token ?? '').then(result => {
        if (result.data) {
          setOpenWarningDialog((result.data.hasPendingForApprovalOrIsOpen || result.data.duplicated) ?? false);
          if (!result.data.duplicated) {
            dispatch({ type: 'Layout.MaskDismissRequested' }); //if duplicated, dismiss when dialog open
          }
          setCheckResult(result.data);
          stockForm.updateValues('isCreatingDuplicatedPropertyStock', result.data.duplicated);
          stockForm.updateValues('isCreatingDuplicatedCompanyStock', result.data.duplicated && result.data.companyStock);
        } else {
          setCheckResult(EMPTY);
          dispatch({ type: 'Layout.MaskDismissRequested' });
        }
        dispatch({ type: 'Layout.MaskDismissRequested' });
      });
    }
  }

  const [shoudBlockNaigation, setShoudBlockNaigation] = useState(true);

  return <div>
    <NavigationBlockerDialog matchesUrl={`/properties/new`} unsaved={shoudBlockNaigation} goBack />
    <WarningDialogForCreatingExisingPropertyStock
      form={stockForm}
      openDialog={openWarningDialog}
      setOpenDialog={setOpenWarningDialog}
      handleConfirm={handleConfirm}
      checkResult={checkResult}
    />
    <PropertyDetail creating getCheckDuplicateResult={getCheckDuplicateResult} onSave={() => {
      //handle create duplicated stock
      if (stockForm.values.isCreatingDuplicatedPropertyStock) {
        if (!stockForm.values.isCreatingDuplicatedCompanyStock) {
          stockForm.validate();
        }
        setOpenWarningDialog(true);
        return;
      }
      //normal flow
      if (!stockForm.validate()) {
        window.scrollTo(0, 0);
        return;
      }
      setShoudBlockNaigation(false);
      doSave();
    }} form={stockForm} tagForm={tagForm} fileForm={fileForm} clientFileForm={clientFileForm} floorUnitOptions={floorUnitOptions} unsaved={true} checkResult={checkResult} /></div>;
}